"use strict";
/**
 * Edit disruption declaration controller.
 *
 * @param {object} disruptionService - Disruption service.
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $state - $state.
 * @param {object} productService - Product service.
 */

EditDisruptionDeclarationController.$inject = ["disruptionService", "$scope", "$stateParams", "$rootScope", "$state", "productService"];

function EditDisruptionDeclarationController(disruptionService, $scope, $stateParams, $rootScope, $state, productService) {
  $scope.pagination = {
    order: "-start_date",
    limit: 10,
    page: 1
  };
  $scope.isEditMode = false;
  $scope.disruption = {};
  $scope.disruption.products = [];
  $scope.create = create;
  $scope.productSearchConfig = {
    search: ["idProducts"],
    event: "addProductInDisruption",
    tag: "configProductSellout",
    autoLaunchEvent: true
  };
  loader();
  /**
   * The loading function
   */

  function loader() {
    getDisruption($stateParams.id);
  }
  /**
   * Get disruption.
   *
   * @param {number} id -
   */


  function getDisruption(id) {
    if (id && $state.current.name === "app.disruption.declaration.edit") {
      $scope.isEditMode = true;
      disruptionService.read({
        id: id
      }).then(function (disruption) {
        if (disruption.start_date) {
          disruption.start_date = new Date(disruption.start_date);
        }

        if (disruption.end_date) {
          disruption.end_date = new Date(disruption.end_date);
        }

        $scope.disruption = disruption;
      });
    }
  }

  $rootScope.$on("addProductInDisruption", function (event, data) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    } // getProducts with query results


    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        $scope.disruption.product_id = product.id;
        $scope.disruption.product = product;
      });
    });
  });
  /**
   * Create.
   */

  function create() {
    if ($scope.isEditMode) {
      disruptionService.update($scope.disruption).then(function () {
        $state.go("app.disruption.declaration");
      });
    } else {
      disruptionService.create($scope.disruption).then(function () {
        $state.go("app.disruption.declaration");
      });
    }
  }
}

angular.module("app.disruption").controller("editDisruptionDeclarationController", EditDisruptionDeclarationController);