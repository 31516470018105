"use strict";
/**
 * Communication service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

CommunicationService.$inject = ["apiClient"];

function CommunicationService(apiClient) {
  return {
    /**
     * Get merchandising plans.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getMerchandisingPlans: function getMerchandisingPlans(data) {
      var query = {
        includes: data.includes ? data.includes : null
      };
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/merchandising-plans"), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get plvs.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getPlvs: function getPlvs(data) {
      return apiClient.get("/plvs/shared", data).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.users-laboratory").service("communicationService", CommunicationService);