"use strict";
/**
 * Run core block.
 *
 * @param {object} fuseGenerator - Fuse generator.
 */

runBlock.$inject = ["fuseGenerator"];

function runBlock(fuseGenerator) {
  /**
   * Generate extra classes based on registered themes so we
   * can use same colors with non-angular-material elements
   */
  fuseGenerator.generate();
}

angular.module("app.core").run(runBlock);