"use strict";
/**
 * Fabric shapes factory.
 *
 * @param {object} fabricWindow - Fabric window.
 * @returns {object}
 */

FabricShapes.$inject = ["fabricWindow"];

function FabricShapes(fabricWindow) {
  return {
    shapes: [{
      name: "rectangle",
      object: new fabricWindow.Rect({
        type: "rect",
        width: 50,
        height: 50,
        fill: "#ffffff",
        stroke: null,
        strokeWidth: 1,
        strokeDashArray: null,
        strokeLineCap: "butt",
        strokeLineJoin: "miter",
        strokeMiterLimit: 10,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        clipTo: null,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        globalCompositeOperation: "source-over",
        transformMatrix: null,
        skewX: 0,
        skewY: 0,
        rx: 0,
        ry: 0,
        model: "shapes"
      })
    }, {
      name: "circle",
      object: new fabricWindow.Circle({
        type: "circle",
        width: 100,
        height: 100,
        fill: "#ffffff",
        stroke: null,
        strokeWidth: 1,
        strokeDashArray: null,
        strokeLineCap: "butt",
        strokeLineJoin: "miter",
        strokeMiterLimit: 10,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        clipTo: null,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        globalCompositeOperation: "source-over",
        transformMatrix: null,
        skewX: 0,
        skewY: 0,
        radius: 50,
        startAngle: 0,
        endAngle: 6.283185307179586,
        model: "shapes"
      })
    }, {
      name: "triangle",
      object: new fabricWindow.Triangle({
        type: "triangle",
        width: 50,
        height: 50,
        fill: "#ffffff",
        stroke: null,
        strokeWidth: 1,
        strokeDashArray: null,
        strokeLineCap: "butt",
        strokeLineJoin: "miter",
        strokeMiterLimit: 10,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        clipTo: null,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        globalCompositeOperation: "source-over",
        transformMatrix: null,
        skewX: 0,
        skewY: 0,
        model: "shapes"
      })
    }],
    background: new fabricWindow.Rect({
      type: "rect",
      originX: "left",
      originY: "top",
      fill: "#ffffff",
      stroke: null,
      strokeWidth: 1,
      strokeDashArray: null,
      strokeLineCap: "butt",
      strokeLineJoin: "miter",
      strokeMiterLimit: 10,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      flipX: false,
      flipY: false,
      opacity: 0.8,
      shadow: null,
      visible: true,
      clipTo: null,
      backgroundColor: "",
      fillRule: "nonzero",
      paintFirst: "fill",
      globalCompositeOperation: "source-over",
      transformMatrix: null,
      skewX: 0,
      skewY: 0,
      rx: 0,
      ry: 0
    }),
    priceShapes: [{
      object1: new fabricWindow.Circle({
        type: "circle",
        originX: "left",
        originY: "top",
        width: 100,
        height: 100,
        fill: "white",
        stroke: null,
        strokeWidth: 1,
        strokeDashArray: null,
        strokeLineCap: "butt",
        strokeLineJoin: "miter",
        strokeMiterLimit: 10,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        clipTo: null,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        transformMatrix: null,
        skewX: 0,
        skewY: 0,
        radius: 50,
        startAngle: 0,
        endAngle: 6.283185307179586
      })
    }, {
      object2: new fabricWindow.Circle({
        type: "circle",
        originX: "left",
        originY: "top",
        width: 100,
        height: 100,
        fill: "black",
        stroke: null,
        strokeWidth: 1,
        strokeDashArray: null,
        strokeLineCap: "butt",
        strokeLineJoin: "miter",
        strokeMiterLimit: 10,
        scaleX: 1,
        scaleY: 1,
        angle: 0,
        flipX: false,
        flipY: false,
        opacity: 1,
        shadow: null,
        visible: true,
        clipTo: null,
        backgroundColor: "",
        fillRule: "nonzero",
        paintFirst: "fill",
        transformMatrix: null,
        skewX: 0,
        skewY: 0,
        radius: 25,
        startAngle: 0,
        endAngle: 6.283185307179586
      })
    }]
  };
}

angular.module("app.generator").factory("fabricShapes", FabricShapes);