"use strict";
/**
 * Patient detail controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} customerService - Customer service.
 * @param {object} patientAppointmentsService - Patient appointment service.
 * @param {object} notifyUserService - Notify user service
 */

PatientBeneficiaryDetailsController.$inject = ["$scope", "$stateParams", "$state", "$mdDialog", "customerService", "patientAppointmentsService", "notifyUserService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function PatientBeneficiaryDetailsController($scope, $stateParams, $state, $mdDialog, customerService, patientAppointmentsService, notifyUserService) {
  $scope.loading = true;
  $scope.handlePatientSelected = handlePatientSelected;
  $scope.searchPatient = searchPatient;
  $scope.goToVaccination = goToVaccination;
  $scope.beneficiary;
  $scope.detailsTitle = {
    title: {
      text: "Profil de ",
      back: {
        state: null
      }
    }
  };
  loader();
  /**
   * Loader function.
   *
   * @returns {void}
   */

  function loader() {
    getBeneficiaryInformation();
  }
  /**
   * Search patient.
   *
   * @param {string} searchText - The search text query.
   *
   * @returns {Promise}
   */


  function searchPatient(searchText) {
    if (!searchText) {
      return Promise.resolve(null);
    }

    return customerService.getCustomersBySearch(searchText).then(function (result) {
      var patients = result.data;
      return patients.map(function (patient) {
        return [patient].concat(_toConsumableArray(patient.beneficiaries));
      }).flat();
    });
  }
  /**
   * Redirect to the selected patient profile.
   *
   * @param {object} selectedPatient - The selected patient.
   */


  function handlePatientSelected(selectedPatient) {
    if (!selectedPatient.customer) {
      $state.go("app.patients.details", {
        patientId: selectedPatient.id
      });
    } else {
      $state.go(".", {
        patientId: selectedPatient.customer.id,
        beneficiaryId: selectedPatient.id
      });
    }
  }
  /**
   * Get a patient profile.
   *
   * @returns {object}
   */


  function getBeneficiaryInformation() {
    return Promise.all([readCustomerBeneficiary(), readBeneficiaryPrescriptions(), readBeneficiaryCarts(), readBeneficiaryOrders(), readNextAppointment()])["catch"](function () {
      notifyUserService.showDangerToast("Une erreur a été rencontrée, veuillez réessayer.");
    })["finally"](function () {
      $scope.loading = false;
      $scope.$apply();
    });
  }
  /**
   * Read customer information and put it in the $scope.
   *
   * @returns {Promise}
   */


  function readCustomerBeneficiary() {
    return customerService.readCustomerBeneficiary($stateParams.patientId, $stateParams.beneficiaryId).then(function (beneficiary) {
      $scope.beneficiary = beneficiary;
      $scope.detailsTitle.title.text = $scope.detailsTitle.title.text + beneficiary.information.name;
    });
  }
  /**
   * Read the next customer's appointment.
   *
   * @returns {Promise}
   */


  function readNextAppointment() {
    var params = {
      limit: 1,
      beneficiary_id: $stateParams.beneficiaryId
    };
    return patientAppointmentsService.indexCustomerAppointments($stateParams.patientId, params).then(function (appointments) {
      if (appointments.length > 0) {
        $scope.appointment = _objectSpread(_objectSpread({}, appointments[0]), {}, {
          start_at: moment(appointments[0].start_at)
        });
      }
    });
  }
  /**
   * Read the prescriptions of the beneficiary.
   *
   * @returns {Promise}
   */


  function readBeneficiaryPrescriptions() {
    return customerService.getCustomerPrescriptions($stateParams.patientId, {
      limit: 3,
      beneficiary_id: $stateParams.beneficiaryId
    }).then(function (prescriptions) {
      $scope.prescriptions = prescriptions.data.map(function (prescription) {
        return _objectSpread(_objectSpread({}, prescription), {}, {
          created_at: moment(prescription.created_at).format("DD MMM YYYY")
        });
      });
    });
  }
  /**
   * Read the carts of a beneficiary.
   *
   * @returns {Promise}
   */


  function readBeneficiaryCarts() {
    return customerService.getCustomerCarts($stateParams.patientId, {
      limit: 3,
      beneficiary_id: $stateParams.beneficiaryId
    }).then(function (carts) {
      $scope.carts = carts.data;
    });
  }
  /**
   * Read beneficiary orders and put it in the $scope.
   *
   * @returns {Promise}
   */


  function readBeneficiaryOrders() {
    return customerService.getCustomerOrders($stateParams.patientId, {
      limit: 4,
      beneficiary_id: $stateParams.beneficiaryId
    }).then(function (orders) {
      $scope.orders = orders.data;
    });
  }
  /**
   * Redirect to the vaccination page.
   */


  function goToVaccination() {
    $state.go("app.patients.details.beneficiaries.details.vaccination");
  }
}

angular.module("app.patients").controller("patientBeneficiaryDetailsController", PatientBeneficiaryDetailsController);