"use strict";
/**
 * Controller to manage challenge sellout.
 *
 * @param {object} $scope - Controller scope.
 * @param {object} $state - Module state.
 * @param {object} $stateParams - State parameters.
 * @param {object} $rootScope - Root scope.
 * @param {object} $mdDialog - Material design dialog.
 * @param {string} apiurl - API URL.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} environmentService - Environment service.
 * @param {object} selloutFactory - Sellout factory.
 * @param {object} selloutService - Sellout service.
 * @param {object} groupingService - Grouping service.
 * @param {object} laboratoryService - Laboratory service.
 * @param {object} rewardsService - Rewards service.
 * @param {object} productService - Product service.
 * @param {object} healthcenterService - Healthcenter service.
 * @param {object} sessionStorageService - Session storage service.
 */

ChallengeSelloutDetailController.$inject = ["$scope", "$state", "$stateParams", "$rootScope", "$mdDialog", "apiurl", "notifyUserService", "environmentService", "selloutFactory", "selloutService", "groupingService", "laboratoryService", "rewardsService", "productService", "healthcenterService", "sessionStorageService"];

function ChallengeSelloutDetailController($scope, $state, $stateParams, $rootScope, $mdDialog, apiurl, notifyUserService, environmentService, selloutFactory, selloutService, groupingService, laboratoryService, rewardsService, productService, healthcenterService, sessionStorageService) {
  DetailTeaserController.$inject = ["$mdDialog", "$scope", "teasers"];
  var offerId = 1;
  $scope.token = sessionStorageService.getToken(); // Product search config

  $scope.productSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    event: "addProductInSellOut",
    tag: "configProductSellout",
    autoLaunchEvent: true
  }; // Laboratory search config

  $scope.laboratorySearchConfig = {
    event: "addLaboSellout",
    search: ["laboratory.id"],
    tag: "laboratorySellout",
    autoLaunchEvent: true
  }; // Participant search config

  $scope.participantSearchConfig = {
    event: "addParticipantsInSellout",
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
    tag: "participant",
    autoLaunchEvent: true
  };
  $scope.select = false;
  $scope.referenceSales = [];
  $scope.detailedTeaser = [];
  $scope.pharmaFromGroupForDetail = []; // Challenge

  $scope.challenge = {
    name: "",
    description: "",
    imagePath: null,
    laboratories: [],
    startDate: null,
    limitActivationDate: null,
    endDate: null,
    products: [],
    participants: [],
    unitRewards: [],
    objectiveRewards: [],
    isSynchronized: false
  };
  $scope.unitReward = {};

  if ($stateParams.healthcenterId && $stateParams.healthcenterName) {
    $scope.pharmaPosted = {
      id: $stateParams.healthcenterId,
      name: $stateParams.healthcenterName,
      entityTypeId: 2
    };
    $scope.challenge.participants.push($scope.pharmaPosted);
  }

  $scope.isValidStep1 = false;
  $scope.isValidStep2 = false;
  $scope.isValidStep3 = false;
  $scope.isValidStep4 = false;
  $scope.isStepValid = isStepValid;
  $scope.challengeId = $stateParams.id;
  $scope.readOffer = readOffer(offerId);
  $scope.getCurrentAccess = getCurrentAccess();
  $scope.getChallenge = getChallenge();
  $scope.createChallenge = createChallenge;
  $scope.updateChallenge = updateChallenge; // Date

  $scope.setDefaultPeriod = setDefaultPeriod;
  $scope.setReferencePeriod = setReferencePeriod;
  $scope.calculateEndDate = calculateEndDate; // Laboratory

  $scope.laboratoryText = null; // Product

  $scope.products = [];
  $scope.removeProduct = removeProduct; // Participant

  $scope.participants = [];
  $scope.removeParticipant = removeParticipant;
  $scope.participantDatatable = {
    limit: 10,
    page: 1
  }; // Durations

  $scope.durations = [{
    id: 1,
    name: "1 mois",
    number: 1,
    type: "months"
  }, {
    id: 2,
    name: "2 mois",
    number: 2,
    type: "months"
  }, {
    id: 3,
    name: "3 mois",
    number: 3,
    type: "months"
  }, {
    id: 4,
    name: "6 mois",
    number: 6,
    type: "months"
  }, {
    id: 5,
    name: "1 an",
    number: 1,
    type: "years"
  }];
  $scope.challenge.duration = $scope.durations[0]; // Reward

  $scope.addUnitReward = addUnitReward;
  $scope.removeUnitReward = removeUnitReward;
  $scope.addObjectiveReward = addObjectiveReward;
  $scope.removeObjectiveReward = removeObjectiveReward;
  $scope.setSelectedRewardType = setSelectedRewardType;
  $scope.getRewardTypes = getRewardTypes();
  $scope.getRewardObjectiveModes = getRewardObjectiveModes();
  $scope.resetReward = resetReward;
  $scope.isObjectiveModeUsable = false; // Point

  $scope.points = 0;
  $scope.pointScale = 100;
  $scope.pointRatio = 1.2;
  $scope.convertPointToMoney = convertPointToMoney;
  $scope.convertMoneyToPoints = convertMoneyToPoints; // Form

  $scope.submit = submit;
  $scope.getReferenceDatas = getReferenceDatas;
  setDefaultPeriod();
  setReferencePeriod();
  /**
   * Watchers.
   */

  $scope.$watch("$scope.challenge.laboratories.length", function () {
    $scope.productSearchConfig.must = {};
  });
  $scope.$on("addParticipantsInSellout", function (event, query) {
    var queryPharma = {};
    queryPharma.type = {};
    var groups = [];
    var queryGroups = {};
    queryGroups.type = {};
    queryGroups.type.groups = [];

    var _loop = function _loop(type) {
      if (type !== "groups") {
        if (Object.prototype.hasOwnProperty.call(query, type)) {
          if (typeof queryPharma.type[type] === "undefined") {
            queryPharma.type[type] = [];
          }

          angular.forEach(query[type], function (obj) {
            if (type === "uga") {
              queryPharma.type[type].push(obj.name);
            } else {
              queryPharma.type[type].push(obj.id);
            }
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(query, type)) {
        angular.forEach(query[type], function (obj) {
          groups.push(obj);
          queryGroups.type.groups.push(obj.id);
        });
      }
    };

    for (var type in query) {
      _loop(type);
    } // getPharmas with query results


    healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      angular.forEach(groups, function (group) {
        group.entityTypeId = 3;
        $scope.challenge.participants.push(group);
      });
      angular.forEach(pharmas, function (pharma) {
        if (!_.find($scope.challenge.participants, function (participant) {
          return pharma.id === participant.id;
        })) {
          pharma.entityTypeId = 2;
          $scope.challenge.participants.push(pharma);
        }

        if (!_.find($scope.pharmaFromGroupForDetail, function (participant) {
          return pharma.id === participant.id;
        })) {
          pharma.entityTypeId = 2;
          $scope.pharmaFromGroupForDetail.push(pharma);
        }
      });
    }).then(function () {
      $scope.isObjectiveModeUsable = isObjectiveModeUsable($scope.challenge.participants);
    });
    healthcenterService.getHealthcentersByGeo(queryGroups).then(function (groups) {
      angular.forEach(groups, function (pharma) {
        if (!_.find($scope.pharmaFromGroupForDetail, function (participant) {
          return pharma.id === participant.id;
        })) {
          pharma.entityTypeId = 2;
          $scope.pharmaFromGroupForDetail.push(pharma);
        }
      });
    });
  });
  $scope.$on("addLaboSellout", function (event, result) {
    if ($scope.challenge.laboratories.length > 0) {
      angular.forEach(result.laboratory, function (labo) {
        if ($scope.challenge.laboratories.indexOf(labo) === -1) {
          $scope.challenge.laboratories.push(labo);
        }
      });
    } else {
      $scope.challenge.laboratories = result.laboratory;
    }
  });
  $rootScope.$on("formsPrestation", function (forms, data) {
    var confirm;

    switch (data.$name) {
      case "productSelloutStep":
        if ($scope.challenge.products.length === 0) {
          confirm = $mdDialog.alert();
          confirm.title("Produits").textContent("Vous n'avez ajouté aucun produit à votre challenge, n'oubliez pas de cliquer sur le bouton 'Ajouter' en bas du moteur de recherche").targetEvent(event).ok("Ok");
          $mdDialog.show(confirm);
        }

        break;

      case "participantSelloutStep":
        if ($scope.challenge.participants.length === 0 && $scope.challenge.isSynchronized === false) {
          confirm = $mdDialog.alert();
          confirm.title("Participants").textContent("Vous n'avez ajouté aucun participant à votre challenge, n'oubliez pas de cliquer sur le bouton 'Ajouter' en bas du moteur de recherche").targetEvent(event).ok("Ok");
          $mdDialog.show(confirm);
        }

        break;

      case "rewardsForm":
        if ($scope.rewardType) {
          confirm = $mdDialog.alert();
          confirm.title("Participants").textContent("Vous n'avez pas ajouté la récompense").targetEvent(event).ok("Ok");
          $mdDialog.show(confirm);
        }

        break;
    }
  });
  $rootScope.$on("addProductInSellOut", function (event, data) {
    var queryProduct = {};

    var _loop2 = function _loop2(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop2(type);
    } // getProducts with query results


    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.challenge.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.challenge.products.push(product);
        }
      });
    });
  });
  $scope.$watch("$scope.objectiveRewardStartDate", function () {
    if ($scope.access) {
      getReferenceDatas(true);
    }
  });
  $scope.$watch("$scope.objectiveRewardEndDate", function () {
    if ($scope.access) {
      getReferenceDatas(true);
    }
  });
  $scope.$watch("challenge.isSynchronized", function () {
    var participants = $scope.challenge.participants;
    $scope.isObjectiveModeUsable = isObjectiveModeUsable(participants);
  });
  /** Get current access. */

  function getCurrentAccess() {
    environmentService.getEnvironment().then(function (access) {
      $scope.access = access;
      $scope.isLaboratory = isLaboratory(access);
    });
  }
  /**
   * Set current selected reward type.
   *
   * @param {object} rewardType - Selected reward type.
   */


  function setSelectedRewardType(rewardType) {
    $scope.rewardType = rewardType;
  }
  /**
   * Read offer.
   *
   * @param {number} offerId - The offer ID to read.
   */


  function readOffer(offerId) {
    selloutService.readOffer(offerId).then(function (response) {
      var offer = response;
      $scope.remunerations = offer.remunerations;
      $scope.objectives = offer.objectives;
      $scope.objectiveReward = $scope.objectives;

      if ($scope.objectives.length === 1) {
        $scope.objectiveReward = {};
        $scope.objectiveReward.objective = $scope.objectives[0];
      }
    });
  }
  /** Get reward types. */


  function getRewardTypes() {
    rewardsService.getRewardTypes().then(function (response) {
      $scope.rewardTypes = response;
    });
  }
  /** Get reward objective modes. */


  function getRewardObjectiveModes() {
    rewardsService.getRewardObjectiveModes().then(function (response) {
      $scope.rewardObjectiveModes = response;
    });
  }
  /**
   * Has entity type participant.
   *
   * @param {Array} participants - The participants array.
   * @param {number} entityTypeId - The id of the entity type to check.
   *
   * @returns {boolean} True if there is at least one participant of given type, else false.
   */


  function hasEntityTypeParticipant(participants, entityTypeId) {
    var hasEntityTypeParticipant = false;
    angular.forEach(participants, function (participant) {
      if (participant.entityTypeId === entityTypeId) {
        hasEntityTypeParticipant = true;
      }
    });
    return hasEntityTypeParticipant;
  }
  /**
   * Is objective mode usable.
   *
   * @param {Array} participants - The participants array.
   *
   * @returns {boolean} True if objective mode is usable else false.
   */


  function isObjectiveModeUsable(participants) {
    return !hasEntityTypeParticipant(participants, 2) && hasEntityTypeParticipant(participants, 3) || !hasEntityTypeParticipant(participants, 2) && $scope.challenge.isSynchronized;
  }
  /**
   * Reset reward.
   *
   * @param {object} form - Form to update.
   */


  function resetReward(form) {
    $scope.unitReward = {};
    $scope.objectiveReward = {};
    form.$setPristine();
    form.$setUntouched();
    $scope.rewardType = null;
  }
  /**
   * Check if is laboratory.
   *
   * @param {object} access - The current user access.
   *
   * @returns {boolean} Check if access is a laboratory.
   */


  function isLaboratory(access) {
    if (access.accessPointType === 1) {
      $scope.laboratoryName = access.entity.name;
      $scope.challenge.laboratories.push({
        id: access.entity.id
      });
      $scope.laboratorySearchConfig.must = {
        "laboratory.id": [access.entity.id]
      };
      return true;
    }

    return false;
  }
  /** Get challenge. */


  function getChallenge() {
    var id = $scope.challengeId;

    if (!id) {
      return;
    }

    selloutService.read(id).then(function (challenge) {
      var startDate = new Date(challenge.startDate);
      var endDate = new Date(challenge.endDate);
      var laboratories = [];
      angular.forEach(challenge.laboratories, function (element) {
        laboratories.push({
          id: element.laboratory.id,
          name: element.laboratory.name
        });
      });
      var imagePath = challenge.imagePath ? apiurl + "/images/" + challenge.imagePath + "?w=1200&token=" + $scope.token : null;
      var products = [];
      var productIds = [];
      angular.forEach(challenge.products, function (element) {
        if (element.presentation) {
          products.push({
            id: element.presentation.id,
            name: element.presentation.name
          });
          productIds.push(element.presentation.id);
        }
      });

      if ($scope.access) {
        getReferenceDatas(true);
      }

      var participants = [];
      angular.forEach(challenge.participants, function (element) {
        participants.push({
          id: element.entityId,
          name: element.entity.name,
          entityTypeId: element.entityTypeId
        });
      });
      var unitRewards = [];
      angular.forEach(challenge.unitRewards, function (reward) {
        var teaser = selloutFactory.generateUnitTeaser(reward.gain, reward.remuneration);
        unitRewards.push({
          gain: reward.gain,
          rewardRemunerationId: reward.rewardRemunerationId,
          remuneration: reward.remuneration,
          teaser: teaser
        });
      });
      var objectiveRewards = [];
      var healthcenterIds = [];
      var groupingIds = [];

      if (challenge.participants.length) {
        angular.forEach(challenge.participants, function (participant) {
          if (participant.entityTypeId === 2) {
            healthcenterIds.push(participant.id);
          } else {
            groupingIds.push(participant.id);
          }
        });
      }

      angular.forEach(challenge.objectiveRewards, function (reward) {
        if ($scope.access.accessPointType === 1) {
          laboratoryService.calculateSalesTotal({
            startDate: reward.startDate,
            endDate: reward.endDate,
            productIds: productIds,
            groupingIds: groupingIds,
            healthcenterIds: healthcenterIds
          }).then(function (response) {
            $scope.referenceSales[reward.id] = response.sales;
            $scope.detailedTeaser[reward.id] = selloutFactory.generateDetailedObjectiveTeaser(reward.gain, reward.remuneration, reward.goal, reward.objective, reward.startDate, reward.endDate, reward.objectiveMode, $scope.referenceSales[reward.id].detail, participants);
          });
        } else if ($scope.access.accessPointType === 3) {
          groupingService.calculateSalesTotal({
            startDate: reward.startDate,
            endDate: reward.endDate,
            productIds: productIds,
            groupingIds: groupingIds,
            healthcenterIds: healthcenterIds
          }).then(function (response) {
            $scope.referenceSales[reward.id] = response.sales;
            $scope.detailedTeaser[reward.id] = selloutFactory.generateDetailedObjectiveTeaser(reward.gain, reward.remuneration, reward.goal, reward.objective, reward.startDate, reward.endDate, reward.objectiveMode, $scope.referenceSales[reward.id].detail, participants);
          });
        }

        var teaser = selloutFactory.generateObjectiveTeaser(reward.gain, reward.remuneration, reward.goal, reward.objective, reward.startDate, reward.endDate, reward.objectiveMode);
        objectiveRewards.push({
          gain: reward.gain,
          rewardRemunerationId: reward.rewardRemunerationId,
          remuneration: reward.remuneration,
          goal: reward.goal,
          rewardObjectiveId: reward.rewardObjectiveId,
          objective: reward.objective,
          teaser: teaser,
          startDate: reward.startDate ? new Date(reward.startDate) : null,
          endDate: reward.endDate ? new Date(reward.endDate) : null
        });
      });
      $scope.challenge = {
        name: challenge.name,
        startDate: startDate,
        endDate: endDate,
        description: challenge.description,
        imagePath: imagePath,
        laboratories: laboratories,
        products: products,
        participants: participants,
        unitRewards: unitRewards,
        objectiveRewards: objectiveRewards,
        isPublished: challenge.isPublished,
        isSynchronized: challenge.isSynchronized
      };
    });
  }
  /**
   * Remove product from selection.
   *
   * @param {number} productId - The id of the product to remove.
   */


  function removeProduct(productId) {
    var products = $scope.challenge.products;
    angular.forEach(products, function (product, index) {
      if (product.id === productId) {
        products.splice(index, 1);
      }
    });
  }
  /**
   * Remove participant from selection.
   *
   * @param {number} participantId - The id of the participant to remove.
   */


  function removeParticipant(participantId) {
    var participants = $scope.challenge.participants;
    angular.forEach(participants, function (participant, index) {
      if (participant.id === participantId) {
        participants.splice(index, 1);
      }
    });
    $scope.isObjectiveModeUsable = isObjectiveModeUsable(participants);
  }
  /**
   * Add unit reward.
   *
   * @param {object} form - The form to reset.
   */


  function addUnitReward(form) {
    var unitReward = $scope.unitReward; // Force by unit points remuneration

    unitReward.remuneration = $scope.remunerations[1];
    unitReward.teaser = selloutFactory.generateUnitTeaser(unitReward.gain, unitReward.remuneration);
    $scope.challenge.unitRewards.push(unitReward);
    resetReward(form);
  }
  /**
   * Remove unit reward
   *
   * @param {number} index - The index to remove.
   */


  function removeUnitReward(index) {
    $scope.challenge.unitRewards.splice(index, 1);
  }
  /**
   * Add objective reward.
   *
   * @param {object} form - The form to reset.
   */


  function addObjectiveReward(form) {
    var objectiveReward = $scope.objectiveReward;
    var startDate = $scope.objectiveRewardStartDate;
    var endDate = $scope.objectiveRewardEndDate;
    objectiveReward.gain = objectiveReward.remuneration.isPoint ? objectiveReward.points : objectiveReward.gain;
    objectiveReward.teaser = selloutFactory.generateObjectiveTeaser(objectiveReward.gain, objectiveReward.remuneration, objectiveReward.goal, objectiveReward.objective, startDate, endDate, objectiveReward.mode);
    objectiveReward.startDate = moment(startDate).format("YYYY-MM-DD");
    objectiveReward.endDate = moment(endDate).format("YYYY-MM-DD");
    $scope.challenge.objectiveRewards.push(objectiveReward);
    getReferenceDatas();
    resetReward(form);
  }
  /**
   * Remove objective reward.
   *
   * @param {number} index - The index to remove.
   */


  function removeObjectiveReward(index) {
    $scope.challenge.objectiveRewards.splice(index, 1);
  }
  /**
   * show objective reward details.
   *
   * @param {number} index - The index of the list.
   */


  $scope.showDetailList = function (index) {
    $mdDialog.show({
      locals: {
        teasers: $scope.challenge.objectiveRewards[index].detailedTeaser
      },
      controller: DetailTeaserController,
      templateUrl: "app/main/sellout/detail/detail-teasers.tmpl.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  };
  /**
   * Format data.
   *
   * @returns {object} Formatted data.
   */


  function formatData() {
    var startDate = moment($scope.challenge.startDate).format("YYYY-MM-DD");
    var limitActivationDate = moment($scope.challenge.limitActivationDate).format("YYYY-MM-DD");
    var endDate = moment($scope.challenge.endDate).format("YYYY-MM-DD");
    var fileId = $scope.challenge.file ? $scope.challenge.file.id : null;
    var laboratories = [];
    angular.forEach($scope.challenge.laboratories, function (laboratory) {
      laboratories.push({
        laboratoryId: laboratory.id
      });
    });
    var products = [];
    angular.forEach($scope.challenge.products, function (product) {
      products.push({
        presentationId: product.id
      });
    });
    var participants = [];
    angular.forEach($scope.challenge.participants, function (participant) {
      participants.push({
        entityTypeId: participant.entityTypeId,
        entityId: participant.id
      });
    });
    var unitRewards = [];
    angular.forEach($scope.challenge.unitRewards, function (unitReward) {
      unitRewards.push({
        rewardRemunerationId: unitReward.remuneration.id,
        gain: unitReward.gain
      });
    });
    var objectiveRewards = [];
    angular.forEach($scope.challenge.objectiveRewards, function (objectiveReward) {
      var reward = {
        rewardRemunerationId: objectiveReward.remuneration.id,
        gain: objectiveReward.gain,
        rewardObjectiveId: objectiveReward.objective.id,
        rewardObjectiveModeId: objectiveReward.mode ? objectiveReward.mode.id : 2,
        // 2 : Individuel à la pharmacie
        goal: objectiveReward.goal
      };

      if (objectiveReward.objective.isGrowth) {
        reward.startDate = objectiveReward.startDate;
        reward.endDate = objectiveReward.endDate;
      }

      objectiveRewards.push(reward);
    });

    if ($scope.challenge.isPublished) {
      return {
        name: $scope.challenge.name,
        description: $scope.challenge.description,
        fileId: fileId
      };
    }

    return {
      name: $scope.challenge.name,
      description: $scope.challenge.description,
      fileId: fileId,
      laboratories: laboratories,
      startDate: startDate,
      limitActivationDate: limitActivationDate,
      endDate: endDate,
      products: products,
      participants: participants,
      unitRewards: unitRewards,
      objectiveRewards: objectiveRewards,
      isSynchronized: $scope.challenge.isSynchronized
    };
  }
  /**
   * Create a challenge.
   *
   * @param {object} data - The data of the challenge to create.
   * @param {boolean} publish - Publish automatically the challenge
   */


  function createChallenge(data, publish) {
    if (publish) {
      data.publish = true;
    }

    selloutService.create(data).then(function () {
      notifyUserService.showSuccessToast("Le challenge est ajouté");
      goToList();
      $scope.submitting = false;
    });
  }
  /**
   * Update a challenge.
   *
   * @param {number} id - The id of the challenge to update.
   * @param {object} data - The datas of the challenge to update.
   * @param {boolean} publish - True if challenge must be published, else false.
   */


  function updateChallenge(id, data, publish) {
    if (publish) {
      data.publish = true;
    }

    selloutService.update(id, data).then(function () {
      notifyUserService.showSuccessToast("Le challenge est modifié");
      goToList();
      $scope.submitting = false;
    });
  }
  /**
   * Convert point to money.
   *
   * @param {number} point - The point amount to convert.
   *
   * @returns {number} The money amount converted.
   */


  function convertPointToMoney(point) {
    var money = point / $scope.pointScale * $scope.pointRatio;
    var round = money.toFixed(2);
    return Number(round);
  }
  /**
   * Convert money to point.
   *
   * @param {number} money - The point amount to convert.
   *
   * @returns {number} The point amount converted.
   */


  function convertMoneyToPoints(money) {
    var point = money / $scope.pointRatio * $scope.pointScale;
    var round = point.toFixed(2);
    return Number(round);
  }
  /** Go to challenge list. */


  function goToList() {
    $state.go("app.sellout");
  }

  $scope.list = function () {
    $state.go("app.sellout");
  };
  /**
   * Submit sellout.
   *
   * @param {boolean} publish - Is the sellout is auto published.
   */


  function submit(publish) {
    $scope.submitting = true;
    var data = formatData();
    $scope.challengeId ? $scope.updateChallenge($scope.challengeId, data, publish) : $scope.createChallenge(data, publish);
  }
  /**
   * Check step validation.
   *
   * @param {number} index - The index of the step.
   *
   * @returns {boolean} True if step is valid else false.
   */


  function isStepValid(index) {
    switch (index) {
      case 0:
        if (!$scope.challenge.name && !$scope.challenge.description && !$scope.challenge.endDate && !$scope.challenge.laboratories.length) {
          return false;
        }

        break;

      case 1:
        if (!$scope.challenge.products.length) {
          return false;
        }

        break;

      case 2:
        if (!$scope.challenge.participants.length) {
          return false;
        }

        break;

      case 3:
        if (!$scope.challenge.rewards.units.length && !$scope.challenge.rewards.objectives.length) {
          return false;
        }

        break;
    }

    return true;
  }
  /** Set default period. */


  function setDefaultPeriod() {
    $scope.challenge.startDate = new Date();
    $scope.challenge.limitActivationDate = new Date();
    $scope.challenge.endDate = new Date();
    $scope.challenge.limitActivationDate.setMonth($scope.challenge.startDate.getMonth());
    $scope.challenge.endDate.setMonth($scope.challenge.startDate.getMonth() + 1);
  }
  /** Calculate end date. */


  function calculateEndDate() {
    var duration = $scope.challenge.duration;
    var startDate = $scope.challenge.startDate;
    $scope.challenge.endDate = moment(startDate).add(duration.number, duration.type).toDate();
    setReferencePeriod();
  }
  /** Set reference period. */


  function setReferencePeriod() {
    $scope.startDate = $scope.challenge.startDate.valueOf();
    $scope.limitActivationDate = $scope.challenge.limitActivationDate.valueOf();
    $scope.endDate = $scope.challenge.endDate.valueOf();
    $scope.objectiveRewardStartDate = moment($scope.challenge.startDate).subtract(1, "years");
    $scope.objectiveRewardEndDate = moment($scope.challenge.endDate).subtract(1, "years");
  }
  /**
   * Watchers.
   */


  $scope.$watch("$scope.challenge.products.length", function (length) {
    if (length > 0) {
      if ($scope.access) {
        getReferenceDatas(true);
      }
    } else if ($scope.access) {
      getReferenceDatas();
    }
  });
  /**
   * Get reference data.
   *
   * @param {boolean} loadTotalSales - True if total sales must be fetched, else false.
   **/

  function getReferenceDatas(loadTotalSales) {
    var productIds = [];
    var healthcenterIds = [];
    var groupingIds = [];

    if ($scope.challenge.products.length) {
      angular.forEach($scope.challenge.products, function (product) {
        productIds.push(product.id);
      });
    }

    if ($scope.challenge.participants.length) {
      angular.forEach($scope.challenge.participants, function (participant) {
        if (participant.entityTypeId === 2) {
          healthcenterIds.push(participant.id);
        } else {
          groupingIds.push(participant.id);
        }
      });
    }

    var objectiveRewardStartDate = moment($scope.objectiveRewardStartDate).format("YYYY-MM-DD");
    var objectiveRewardEndDate = moment($scope.objectiveRewardEndDate).format("YYYY-MM-DD");

    if ($scope.access.accessPointType === 1) {
      if (loadTotalSales) {
        laboratoryService.calculateSalesTotal({
          startDate: objectiveRewardStartDate,
          endDate: objectiveRewardEndDate,
          productIds: productIds,
          groupingIds: groupingIds,
          healthcenterIds: healthcenterIds
        }).then(function (response) {
          $scope.referenceSalesTmp = response.sales;
        });
      } else {
        angular.forEach($scope.challenge.objectiveRewards, function (reward) {
          laboratoryService.calculateSalesTotal({
            startDate: objectiveRewardStartDate,
            endDate: objectiveRewardEndDate,
            productIds: productIds,
            groupingIds: groupingIds,
            healthcenterIds: healthcenterIds
          }).then(function (response) {
            $scope.referenceSales[reward.id] = response.sales;
            var participantsForTeaser = $scope.challenge.participants;

            if ($scope.challenge.participants.length === 1 && $scope.challenge.participants[0].type === "groups") {
              participantsForTeaser = $scope.pharmaFromGroupForDetail;
            }

            reward.detailedTeaser = selloutFactory.generateDetailedObjectiveTeaser(reward.gain, reward.remuneration, reward.goal, reward.objective, reward.startDate, reward.endDate, reward.objectiveMode, $scope.referenceSales[reward.id].detail, participantsForTeaser);
          });
        });
      }
    } else if ($scope.access.accessPointType === 3) {
      if (loadTotalSales) {
        groupingService.calculateSalesTotal({
          startDate: objectiveRewardStartDate,
          endDate: objectiveRewardEndDate,
          productIds: productIds
        }).then(function (response) {
          $scope.referenceSalesTmp = response.sales;
        });
      } else {
        angular.forEach($scope.challenge.objectiveRewards, function (reward) {
          groupingService.calculateSalesTotal({
            startDate: reward.startDate,
            endDate: reward.endDate,
            productIds: productIds
          }).then(function (response) {
            $scope.referenceSales[reward.id] = response.sales;
            reward.detailedTeaser = selloutFactory.generateDetailedObjectiveTeaser(reward.gain, reward.remuneration, reward.goal, reward.objective, reward.startDate, reward.endDate, reward.objectiveMode, $scope.referenceSales[reward.id].detail, $scope.pharmaFromGroupForDetail);
          });
        });
      }
    }
  }
  /**
   * Controller to show teasers.
   *
   * @param {object} $mdDialog - Material design dialog.
   * @param {object} $scope - Controller scope.
   * @param {Array} teasers - teasers.
   */


  function DetailTeaserController($mdDialog, $scope, teasers) {
    $scope.teasers = teasers;

    $scope.hide = function () {
      $mdDialog.hide();
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };
  }
}

angular.module("app.sellout").controller("ChallengeSelloutDetailController", ChallengeSelloutDetailController);