"use strict";
/**
 * The app-main-header directive.
 *
 * @returns {object}
 */

function AppMainHeader() {
  return {
    restrict: "E",
    scope: {
      variant: "@variant",
      icon: "@icon",
      tooltip: "@tooltip",
      tooltipDirection: "@tooltipDirection",
      state: "@state",
      stateCallBack: "=stateCallBack"
    },
    templateUrl: "app/main/directives/app-main-header/appMainHeader.html",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appMainHeader", AppMainHeader);