"use strict";
/**
 * Dashboard laboratories controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} bricksService - Bricks service.
 * @param {object} environmentService - Environment service.
 */

dashboardLaboratoriesController.$inject = ["$scope", "$state", "bricksService", "environmentService"];

function dashboardLaboratoriesController($scope, $state, bricksService, environmentService) {
  $scope.goToGuests = goToGuests;
  $scope.goToTeams = goToTeams;
  loader();
  /**
   * Loader.
   */

  function loader() {
    loaderCurrentBrick();
    loaderCurrentEnvironment();
  }
  /**
   * Load current environment.
   */


  function loaderCurrentEnvironment() {
    environmentService.getEnvironment().then(function (environment) {
      $scope.currentEnv = environment;
    });
  }
  /**
   * Load current brick.
   */


  function loaderCurrentBrick() {
    bricksService.getCurrentBrick().then(function (brick) {
      $scope.brick = brick;
    });
  }
  /**
   * Go to guests.
   */


  function goToGuests() {
    $state.go("app.guest");
  }
  /**
   * Go to teams.
   */


  function goToTeams() {
    $state.go("app.entityAdmin");
  }
}

angular.module("app.dashboard").controller("dashboardLaboratoriesController", dashboardLaboratoriesController);