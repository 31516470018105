"use strict";
/**
 * The app-select directive.
 *
 * @returns {object}
 */

function AppSelect() {
  return {
    restrict: "E",
    scope: {
      variant: "@variant",
      placeholder: "@placeholder",
      options: "=options",
      event: "=event"
    },
    templateUrl: "app/main/directives/app-select/appSelect.html",
    replace: true,
    transclude: true,
    controllerAs: "vm",
    controller: ["$scope", "$rootScope", function controller($scope, $rootScope) {
      $scope.selectedItem;
      $scope.changeValue = changeValue;
      /**
       * Change value.
       */

      function changeValue() {
        if ($scope.selectedItem === "default") {
          $rootScope.$broadcast($scope.event, []);
        } else {
          $rootScope.$broadcast($scope.event, JSON.parse($scope.selectedItem));
        }
      }
    }]
  };
}

angular.module("app.directives").directive("appSelect", AppSelect);