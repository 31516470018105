"use strict";
/**
 * Dashboard groups sellout controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {string} apiurl - API url
 * @param {object} selloutService - Sellout service
 */

dashboardGroupsSelloutsController.$inject = ["$scope", "$state", "apiurl", "selloutService"];

function dashboardGroupsSelloutsController($scope, $state, apiurl, selloutService) {
  loader();
  $scope.goToDetail = goToDetail;
  $scope.imageFallback = "assets/images/sellouts/default-challenge-1080x600.jpg";
  $scope.apiurl = apiurl;
  $scope.token = window.sessionStorage.token;
  /**
   * loader
   * to load each method inside
   */

  function loader() {
    $scope.dashboardLoading = true;
    getChallenges();
  }
  /**
   * Get challenges
   */


  function getChallenges() {
    selloutService.get({
      limit: 5
    }).then(function (response) {
      if (response.sellouts) {
        $scope.selloutChallenges = updateChallengeForDisplay(response.sellouts);
      }

      $scope.dashboardLoading = false;
    });
  }
  /**
   * Update challenges for display
   *
   * @param {Array} list -
   * @returns {Array}
   */


  function updateChallengeForDisplay(list) {
    var today = new Date();
    list.forEach(function (currentValue) {
      var start = new Date(currentValue.startDate);
      var end = new Date(currentValue.endDate);

      if (today < start) {
        currentValue.status = "coming";
      } else if (today >= start && today <= end) {
        currentValue.status = "ongoing";
      } else if (today > end) {
        currentValue.status = "finished";
      }

      currentValue.startDate = new Date(currentValue.startDate);
      currentValue.endDate = new Date(currentValue.endDate);
      currentValue.image = currentValue.imagePath ? $scope.apiurl + "/images/" + currentValue.imagePath + "?w=160&h=90&fit=crop&sharp=10&token=" + $scope.token : $scope.imageFallback;
    });
    return list;
  }
  /**
   * Go to detail.
   *
   * @param {number} id - Sellout ID
   */


  function goToDetail(id) {
    $state.go("app.sellout.report", {
      id: id
    });
  }
}

angular.module("app.dashboard").controller("dashboardGroupsSelloutsController", dashboardGroupsSelloutsController);