"use strict";
/**
 * Patient gender filter directive.
 *
 * @returns {object}
 */

function PatientGenderFilterDirective() {
  return {
    restrict: "E",
    templateUrl: "app/main/patients-search/filters/gender/patient.gender.filter.html"
  };
}

angular.module("apodis").directive("patientGenderFilter", PatientGenderFilterDirective);