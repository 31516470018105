"use strict";
/**
 * PLV service.
 *
 * @param {object} apiClient -
 * @returns {object}
 */

var _excluded = ["id"],
    _excluded2 = ["id"];
PlvsService.$inject = ["apiClient"];

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function PlvsService(apiClient) {
  var service = {
    /**
     * load
     *
     * @returns {Promise}
     */
    load: function load() {
      var includes = {
        includes: ["access", "share.entityTypes", "share.organizations.morphable", "format", "orientation,template"]
      };
      return apiClient.get("/plvs", includes).then(function (result) {
        return result.data;
      });
    },

    /**
     * getThumbs
     *
     * @param {Array} plvs -
     * @returns {Promise}
     */
    getThumbs: function getThumbs(plvs) {
      return plvs.forEach(function (plv) {
        service.open(plv).then(function (file) {
          plv.file = file;
        });
      });
    },

    /**
     * loadShared
     *
     * @returns {Promise}
     */
    loadShared: function loadShared() {
      var includes = {
        includes: ["shareable"]
      };
      return apiClient.get("/plvs/shared", includes).then(function (result) {
        return result.data;
      });
    },

    /**
     * create
     *
     * @param {object} data -
     * @returns {Promise}
     */
    create: function create(data) {
      return apiClient.post("/plvs", data).then(function (result) {
        return result.data;
      });
    },

    /**
     * read
     *
     * @param {number} id - The plv id
     * @returns {Promise}
     */
    read: function read(_ref) {
      var id = _ref.id;
      var includes = {
        includes: ["file", "access", "share.entityTypes", "share.organizations.morphable", "format", "orientation", "template"]
      };
      return apiClient.get("/plvs/".concat(id), includes).then(function (result) {
        return result.data;
      });
    },

    /**
     * update
     *
     * @param {object} params -
     * @param {number} params.id - The plv id
     * @param {object} params.content - The plv content
     * @returns {Promise}
     */
    update: function update(_ref2) {
      var id = _ref2.id,
          content = _objectWithoutProperties(_ref2, _excluded);

      var includes = {
        includes: ["access", "share.entityTypes", "share.organizations.morphable", "format", "orientation", "template"]
      };
      return apiClient.put("/plvs/".concat(id), content, includes).then(function (result) {
        return result.data;
      });
    },

    /**
     * open
     *
     * @param {number} id - The plv id
     * @returns {Promise}
     */
    open: function open(_ref3) {
      var id = _ref3.id;
      return apiClient.get("/plvs/".concat(id, "/file")).then(function (result) {
        return result.data;
      });
    },

    /**
     * save
     *
     * @param {object} params -
     * @param {number} params.id - The plv id
     * @param {object} params.content - The plv content
     * @returns {Promise}
     */
    save: function save(_ref4) {
      var id = _ref4.id,
          content = _objectWithoutProperties(_ref4, _excluded2);

      return apiClient.put("/plvs/".concat(id, "/file"), content, {
        includes: ["shares"],
        wrappers: {
          response: null
        }
      });
    },

    /**
     * export
     *
     * @param {number} plvId - The plv id
     * @param {string} type - The type of export
     * @param {object} plvFile - The plv file
     * @returns {Promise}
     */
    "export": function _export(plvId, type, plvFile) {
      return apiClient.post("/plvs/".concat(plvId, "/export/").concat(type), plvFile);
    },

    /**
     * exportVariant
     *
     * @param {number} plvId - The plv id
     * @param {string} type - The type of export
     * @param {string} variant - The variant of DPI
     * @param {object} plvFile - The plv file
     * @returns {Promise}
     */
    exportVariant: function exportVariant(plvId, type, variant, plvFile) {
      return apiClient.post("/plvs/".concat(plvId, "/export/").concat(type, "/").concat(variant), plvFile, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * delete
     *
     * @param {number} id - The plv id
     * @returns {Promise}
     */
    "delete": function _delete(_ref5) {
      var id = _ref5.id;
      return apiClient["delete"]("/plvs/".concat(id));
    },

    /**
     * Share a plv.
     *
     * @param {number} id - The plv id  to share.
     * @param {object} shareWith - Object containing ids of entity types
     *      and organizations the plv will be shared with.
     *
     * @returns {Promise}
     */
    share: function share(id, shareWith) {
      var includes = {
        includes: ["entityTypes", "organizations.morphable"]
      };
      return apiClient.post("/plvs/".concat(id, "/share"), {
        data: shareWith
      }, includes).then(function (result) {
        return result.data;
      });
    }
  };
  return service;
}

angular.module("app.generator").service("plvsService", PlvsService);