"use strict";
/**
 * Disruption declaration controller.
 *
 * @param {object} disruptionService - Disruption service.
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 */

DisruptionDeclarationController.$inject = ["disruptionService", "$scope", "$state"];

function DisruptionDeclarationController(disruptionService, $scope, $state) {
  $scope.pagination = {
    order: "-start_date",
    limit: 10,
    page: 1
  };
  $scope.create = create;
  $scope.edit = edit;
  loader();
  /**
   * The loading function
   */

  function loader() {
    getDisruptions();
  }
  /**
   * get disruptions
   */


  function getDisruptions() {
    disruptionService.get().then(function (disruptions) {
      $scope.disruptions = disruptions.map(function (disruption) {
        if (disruption.start_date) {
          disruption.start_date = new Date(disruption.start_date);
        }

        if (disruption.end_date) {
          disruption.end_date = new Date(disruption.end_date);
        }

        return disruption;
      });
    });
  }
  /**
   * Go to edit state
   *
   * @param {number} id - ID.
   */


  function edit(id) {
    $state.go("app.disruption.declaration.edit", {
      id: id
    });
  }
  /**
   * Go to creation state
   */


  function create() {
    $state.go("app.disruption.declaration.creation");
  }
}

angular.module("app.disruption").controller("disruptionDeclarationController", DisruptionDeclarationController);