"use strict";
/**
 * To be made configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

ToBeMadeConfig.$inject = ["$stateProvider"];

function ToBeMadeConfig($stateProvider) {
  $stateProvider.state("app.toBeMade", {
    url: "/prochainement",
    bodyClass: "statistiques",
    views: {
      "content@app": {
        templateUrl: "app/main/toBeMade/toBeMade.html",
        controller: "ToBeMadeController as vm"
      }
    }
  }).state("app.toBeMade.downPrice", {
    url: "/decrochage-prix/prochainement",
    bodyClass: "statistiques",
    views: {
      "content@app": {
        templateUrl: "app/main/toBeMade/toBeMade.html",
        controller: "ToBeMadeController as vm"
      }
    }
  }).state("app.toBeMade.fidelityCard", {
    url: "/carte-fidelite/prochainement",
    bodyClass: "statistiques",
    views: {
      "content@app": {
        templateUrl: "app/main/toBeMade/toBeMade.html",
        controller: "ToBeMadeController as vm"
      }
    }
  }).state("app.toBeMade.myAccount", {
    url: "/mon-compte/prochainement",
    bodyClass: "statistiques",
    views: {
      "content@app": {
        templateUrl: "app/main/toBeMade/toBeMade.html",
        controller: "ToBeMadeController as vm"
      }
    }
  }).state("app.toBeMade.internetSite", {
    url: "/gestion-site-internet/prochainement",
    bodyClass: "statistiques",
    views: {
      "content@app": {
        templateUrl: "app/main/toBeMade/toBeMade.html",
        controller: "ToBeMadeController as vm"
      }
    }
  }).state("app.toBeMade.chat", {
    url: "/messagerie/prochainement",
    bodyClass: "statistiques",
    views: {
      "content@app": {
        templateUrl: "app/main/toBeMade/toBeMade.html",
        controller: "ToBeMadeController as vm"
      }
    }
  }).state("app.toBeMade.shareFiles", {
    url: "/fichiers-partages/prochainement",
    bodyClass: "statistiques",
    views: {
      "content@app": {
        templateUrl: "app/main/toBeMade/toBeMade.html",
        controller: "ToBeMadeController as vm"
      }
    }
  }).state("app.statistics.toBeMade", {
    url: "/prochainement",
    bodyClass: "statistiques",
    views: {
      "tabContent@app": {
        templateUrl: "app/main/toBeMade/toBeMade.html",
        controller: "ToBeMadeController as vm"
      }
    }
  }).state("app.statistics.wholesalerAudit.toBeMade", {
    url: "/prochainement",
    bodyClass: "statistiques",
    views: {
      "tabContent@app.statistics.wholesalerAudit": {
        templateUrl: "app/main/toBeMade/toBeMade.html",
        controller: "ToBeMadeController as vm"
      }
    }
  });
}

angular.module("app.toBeMade", []).config(ToBeMadeConfig);