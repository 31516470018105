"use strict";
/**
 * Controller to manage audit reassort.
 *
 * @param {object} $stateParams - State parameters.
 * @param {object} $mdDialog - Dialog material design.
 * @param {object} $scope - Controller scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} healthcenterService - Healthcenter service.
 * @param {object} analysisService - Analysis service.
 */

HealthcenterReassortAuditController.$inject = ["$stateParams", "$mdDialog", "$scope", "StatisticsQueryService", "environmentService", "healthcenterService", "analysisService"];

function HealthcenterReassortAuditController($stateParams, $mdDialog, $scope, StatisticsQueryService, environmentService, healthcenterService, analysisService) {
  SellinOfferModalController.$inject = ["$scope", "$mdDialog", "$state", "laboratory", "healthcenters", "products", "numberOfProducts", "quantityOfAllProducts", "totalPrice"];
  DetailStocksProductGraphTabController.$inject = ["$q", "$mdDialog", "$scope", "product"];
  var vm = this;
  vm.loading = true;
  vm.isLoaded = false;
  vm.configSearch = {
    event: "stockReassortFilterChange",
    search: []
  };
  vm.usrquery = StatisticsQueryService.query;
  var currentEnvironment = environmentService.getEnvironment();
  currentEnvironment.then(function (env) {
    vm.currentEnv = env;
    var lab = {
      id: vm.currentEnv.entityId,
      name: vm.currentEnv.entity.name,
      type: "laboratory"
    };

    if (vm.currentEnv.accessPointType === 1 && vm.usrquery.must.laboratory.selected.length === 0 && !_.find(vm.usrquery.should.laboratory.selected, function (labo) {
      return labo.id === vm.currentEnv.entityId;
    })) {
      vm.usrquery.must.laboratory.selected.push(lab);
    }
  });
  vm.colors = ["#fab231", "#faeb4c", "#b7d787", "#89b74e", "#85caa0", "#9bd8df", "#218b9a"];
  $scope.currentTableConfig = {
    currentPage: 1,
    size: 10,
    order: "needs.calcul.quantity_propal"
  };
  init();
  /**
   * Initialize healthcenter details.
   *
   * @returns {object} Healthcenters details promise.
   */

  function init() {
    return healthcenterService.getDetails({
      id: $stateParams.id
    }).then(function (response) {
      vm.hc = response.healthcenter;

      if (!_.find(vm.usrquery.must.pharmas.selected, function (pharma) {
        return pharma.cip === vm.hc.cip;
      }) && vm.usrquery.should.pharmas.selected.length === 0) {
        vm.usrquery.must.pharmas.selected = [];
        vm.usrquery.must.pharmas.selected.push({
          cip: vm.hc.cip,
          name: vm.hc.name,
          type: "pharmas"
        });
      }

      if (vm.currentEnv.accessPointType === 1 && vm.usrquery.must.laboratory.selected.length === 0 && !_.find(vm.usrquery.should.laboratory.selected, function (labo) {
        return labo.id === vm.currentEnv.entityId;
      })) {
        var lab = {
          id: vm.currentEnv.entityId,
          name: vm.currentEnv.entity.name,
          type: "laboratory"
        };
        vm.usrquery.must.laboratory.selected.push(lab);
      }

      return StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
        vm.query = query;
        return launchSearch(vm.query, true);
      });
    });
  }
  /**
   * Perform stocks search.
   *
   * @returns {object} Search promise.
   */


  function launchSearch() {
    return analysisService.getLaboratoryStockByHealthcenter(vm.hc.cip, vm.query).then(function (response) {
      vm.detailStocksProducts = response.hits.hits;
      vm.detailStocksGeneral = response.aggregations;
      angular.forEach(vm.detailStocksProducts, function (product) {
        var value = Math.ceil(product._source.needs.calcul.quantity_propal / 60 * (60 + product._source.product.laboratory_delivery || 0));

        if (product._source.product.packing && value % product._source.product.packing !== 0 && product._source.needs.calcul.order_needs.toFixed(0) !== 0) {
          value = value + product._source.product.packing - value % product._source.product.packing;
        }

        vm.values[product._source.product.presentation] = value;
        vm.prices[product._source.product.presentation] = product._source.product.price;
        vm.totalPrice[product._source.product.presentation] = (product._source.product.price * vm.values[product._source.product.presentation]).toFixed(2);
      });
      vm.isLoaded = true;
      vm.loading = false;
    });
  }

  vm.goDetail = function (ev, id) {
    var product = _.find(vm.detailStocksProducts, function (det) {
      return det._id.split("_")[1] === id.toString();
    })._source;

    $mdDialog.show({
      locals: {
        product: product
      },
      controller: DetailStocksProductGraphTabController,
      templateUrl: "app/main/healthcenters/detail/tabs/stocks-product-graph.tmpl.html",
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true
    });
  };

  vm.getGeneric = function (product) {
    return sumAllProduct(product);
  };

  vm.getCategory = function (product) {
    var needsCategory = 0;
    angular.forEach(product.category_data.hits.hits, function (prod) {
      needsCategory += prod._source.product.prop.calcul.quantity_propal;
    });
    return needsCategory;
  };

  vm.sumOrderNetMuliple = function (products) {
    var orderNetMuliple = 0;
    angular.forEach(products, function (data) {
      orderNetMuliple += data.calcul.orderNetMuliple;
    });
    return orderNetMuliple;
  };

  vm.addOrDeleteFromCart = function (prod) {
    if (prod.toCart === true) {
      vm.cart[prod._source.product.presentation] = {
        quantity: prod._source.needs.calcul.quantity_propal
      };
    } else {
      vm.cart[prod._source.product.presentation] = {
        quantity: 0
      };
    }
  };
  /**
   * Set sellin offer.
   */


  vm.setSellinOffer = function () {
    var quantityFields = document.getElementsByClassName("stock-quantity");
    var products = [];
    var productId = 0;
    var productCip = "";
    var productName = "";
    var productQuantity = 0;
    var productPrice = 0;
    var numberOfProducts = 0;
    var quantityOfAllProducts = 0;
    var totalPrice = 0;
    angular.forEach(quantityFields, function (quantityField) {
      if (!isNaN(quantityField.value) && quantityField.value > 0) {
        productId = quantityField.id.replace("product-stock-quantity-", "");
        productCip = document.getElementById("product-stock-cip-" + productId).value;
        productName = document.getElementById("product-stock-name-" + productId).value;
        productQuantity = parseInt(quantityField.value);
        productPrice = parseFloat(document.getElementById("product-stock-price-" + productId).value);
        numberOfProducts++;
        quantityOfAllProducts += productQuantity;
        totalPrice += productQuantity * productPrice;
        products.push({
          id: parseInt(productId),
          cip: productCip,
          name: productName,
          quantity: productQuantity
        });
      }
    });
    $mdDialog.show({
      locals: {
        laboratory: {
          id: vm.currentEnv.entityId,
          name: vm.currentEnv.entity.name
        },
        healthcenters: {
          id: $stateParams.id,
          entityTypeId: 2,
          name: vm.hc.name,
          cip: vm.hc.cip
        },
        products: products,
        numberOfProducts: numberOfProducts,
        quantityOfAllProducts: quantityOfAllProducts,
        totalPrice: totalPrice
      },
      controller: SellinOfferModalController,
      templateUrl: "app/main/statistics/healthcenter-audit/views/includes/modals/sellinOffer.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  };

  vm.values = {};
  vm.prices = {};
  vm.totalPrice = {};

  vm.changePropalQuantities = function (id) {
    vm.totalPrice[id] = (vm.prices[id] * vm.values[id]).toFixed(2);
  };
  /*
       ** Events
       */


  $scope.$on("stockReassortFilterChange", function (event, query) {
    vm.loading = true;
    StatisticsQueryService.buildquery(query, query.date).then(function (query) {
      vm.query = query;
      return launchSearch();
    });
  });
  /**
   * Controller for managing sellin modal.
   *
   * @param {object} $scope - The controller scope.
   * @param {object} $mdDialog - Material design modal.
   * @param {object} $state - Module state.
   * @param {object} laboratory - Sellin laboratory.
   * @param {object} healthcenters - Sellin targeted healthcenters.
   * @param {object} products - Sellin products.
   * @param {object} numberOfProducts - Number of products concerned.
   * @param {object} quantityOfAllProducts - Quantity of products.
   * @param {object} totalPrice - Total products price.
   */

  function SellinOfferModalController($scope, $mdDialog, $state, laboratory, healthcenters, products, numberOfProducts, quantityOfAllProducts, totalPrice) {
    $scope.numberOfProducts = numberOfProducts;
    $scope.quantityOfAllProducts = quantityOfAllProducts;
    $scope.totalPrice = totalPrice;
    /**
     * Close prompt (mdDialog).
     */

    $scope.closePrompt = function () {
      $mdDialog.hide();
    };
    /**
     * Go to sellin offers with pre-loaded informations
     */


    $scope.goToSellinOffer = function () {
      $mdDialog.hide();
      $state.go("app.sellinOffer.new", {
        name: $scope.sellinOffer.name,
        description: $scope.sellinOffer.description,
        laboratory: laboratory,
        targets: [healthcenters],
        products: products
      });
    };
  }
  /**
   * Controller for stocks detail product graph.
   *
   * @param {object} $q - Service for running functions asynchronously.
   * @param {object} $mdDialog - Material design dialog.
   * @param {object} $scope - Controller scope.
   * @param {object} product - Product to detailed.
   */


  function DetailStocksProductGraphTabController($q, $mdDialog, $scope, product) {
    var vm = this;
    $scope.loading = true;
    $scope.dataChart;
    $scope.product = product;
    $scope.typeGraph = "Générique";
    $scope.lines = {};
    $scope.sumAllProduct = sumAllProduct(product);
    vm.deferredChart = $q.defer();
    $scope.amChartOptions = {
      data: vm.deferredChart.promise,
      type: "serial",
      theme: "light",
      autoMarginOffset: 5,
      categoryField: "range30days",
      mouseWheelScrollEnabled: true,
      thousandsSeparator: " ",
      titles: [{
        text: "Ventes sur 12 mois",
        size: 16
      }],
      legend: {
        enabled: true
      },
      chartScrollbar: {
        enabled: true
      },
      valueAxes: [{
        stackType: "regular",
        axisAlpha: 0.5,
        gridAlpha: 0
      }],
      categoryAxis: {
        gridPosition: "start",
        parseDates: false
      },
      graphs: [],
      "export": {
        enabled: true
      }
    };
    /**
     * Process data for charts.
     *
     * @param {object} dataChart - Data to process.
     */

    function processDataForAmCharts(dataChart) {
      var lines = [];
      angular.forEach(dataChart.hits.hits, function (product) {
        if (product._source.needs && product._source.needs.calcul.sale_amount_average_month_weighting !== 0) {
          var addProduct = {
            type: "column",
            title: product._source.product.presentation_name,
            valueField: product._source.product.presentation,
            labelText: "[[value]]",
            balloonText: product._source.product.presentation_name + " :[[value]]",
            fillAlphas: 0.8,
            lineAlpha: 0.2
          };
          $scope.amChartOptions.graphs.push(addProduct);
        }
      });

      var _loop = function _loop(index) {
        var lineMonth = {};
        lineMonth.range30days = "-" + index + " mois";
        angular.forEach(dataChart.hits.hits, function (product) {
          if (product._source.needs && product._source.needs.calcul.sale_amount_average_month_weighting !== 0) {
            lineMonth[product._source.product.presentation] = product._source.needs.sales[index].volume;
          }
        });
        lines.push(lineMonth);
      };

      for (var index = 1; index < 12; index++) {
        _loop(index);
      }

      $scope.lines = lines;
      lines.reverse();
      vm.deferredChart.resolve(lines);
      $scope.loading = false;
    }

    var query = {};

    if (product.product.category && product.product.category.level5 !== 0) {
      var categoryId = "";
      categoryId = product.product.category.level5;
      query.must = {
        categoryMulti: [categoryId]
      };
      $scope.typeGraph = "Catégories";

      if (product.product.presentation_gen_group) {
        $scope.typeGraph = "Génériques";
        query.must = {
          generic: [product.product.presentation_gen_group]
        };
      }

      $scope.valueWithDelivery = Math.ceil(product.needs.calcul.quantity_propal / 60 * (60 + product.product.laboratory_delivery) - product.needs.calcul.order_needs);
      $scope.productToOrdered = Math.ceil(product.needs.calcul.order_needs + (product.product.packing - $scope.valueWithDelivery % product.product.packing));
      analysisService.getLaboratoryStockByHealthcenter(product.organization.cip, query).then(function (response) {
        $scope.dataChart = response;
        processDataForAmCharts(response);
      });
    }

    $scope.hide = function () {
      $mdDialog.hide();
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };
  }
  /**
   * Sum all product.
   *
   * @returns {object} Products sums by type.
   */


  function sumAllProduct() {
    return {
      orderNeedsNegative: 0,
      saleAmountAverageMonthWeighting: 0,
      stock: 0,
      stockAvailableDays: 0,
      stockAvailableDaysTrue: 0
    };
  }
}

angular.module("app.healthcenterAudit").controller("ReassortAuditController", HealthcenterReassortAuditController);