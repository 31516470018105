"use strict";
/**
 * Dashboard laboratories real stock diagram controller.
 *
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} StocksQueryService - Stocks query service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} $timeout - $timeout.
 * @param {object} homeLaboratoryAnalysisService - Home laboratory analysis service.
 * @param {object} analysisService - Analysis service.
 */

DashboardLaboratoriesRealStocksDiagramController.$inject = ["$state", "$stateParams", "$scope", "environmentService", "StocksQueryService", "StatisticsQueryService", "$timeout", "homeLaboratoryAnalysisService", "analysisService"];

function DashboardLaboratoriesRealStocksDiagramController($state, $stateParams, $scope, environmentService, StocksQueryService, StatisticsQueryService, $timeout, homeLaboratoryAnalysisService, analysisService) {
  var _this = this;

  var groupListLaboGroup = [{
    value: 0,
    title: "Pharmacies"
  }];
  var groupListPharma = [{
    value: 1,
    title: "Laboratoires",
    type: "lab",
    filterId: "id"
  }, {
    value: 6,
    title: "Gammes",
    type: "range",
    filterId: "id"
  }, {
    value: 7,
    title: "Catégories"
  }, {
    value: 8,
    title: "Segments"
  }, {
    value: 9,
    title: "Produits"
  }];
  var psd3 = psd3 || {};
  var eur = d3.locale({
    decimal: ".",
    thousands: " ",
    grouping: [3],
    currency: ["", "€"],
    dateTime: "%a %b %e %X %Y",
    date: "%m/%d/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  });
  var brdBp = [{
    dataset: null,
    label: "Votre Recherche"
  }];
  var dirtyReturn = false;
  $scope.LabID = 0;
  $scope.loading = true;
  $scope.loadingChart = true;
  $scope.dataChartSerial = [];
  $scope.initiated = false;
  $scope.stepPosition = 0;
  $scope.stepNumberLine = 10;
  $scope.colors = ["#fab231", "#faeb4c", "#b7d787", "#89b74e", "#85caa0", "#9bd8df", "#218b9a"];
  $scope.rawValues = StatisticsQueryService.values;
  $scope.usrStockReal = StatisticsQueryService.query;
  $scope.dataset = [];
  $scope.form = {
    isBlurred: false,
    nbEl: {
      min: 2,
      max: 25,
      model: 5
    },
    by: "quantity",
    values: [{
      lbl: "Laboratoires",
      val: "product.laboratory",
      checked: true,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Produits",
      val: "product.presentation",
      checked: true,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Segments",
      val: "product.segment.value",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 1",
      val: "product.category.level0",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 2",
      val: "product.category.level1",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 3",
      val: "product.category.level2",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 4",
      val: "product.category.level3",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 5",
      val: "product.category.level4",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }]
  };
  $scope.tooltip = {
    config: {
      adjustX: "-65px",
      adjustY: "-45px",
      color: "#CCC",
      backgroundColor: "rgba(68, 68, 68, 0.85)"
    },
    display: function display(data) {
      var innerCircle = 0;
      angular.forEach($scope.elasticdata.data, function (data) {
        innerCircle += data.value;
      });
      angular.forEach($scope.elasticdata.data, function (data) {
        data.total = innerCircle;
        angular.forEach(data.drilldown, function (_data) {
          _data.total = data.value;
        });
      });
      var tooltip = document.getElementById(_this.tooltipId);
      tooltip.style.position = "fixed";
      tooltip.style.marginTop = this.config.adjustY;
      tooltip.style.color = this.config.color;
      tooltip.style.backgroundColor = this.config.backgroundColor;
      tooltip.innerHTML = this.format(data.data); // hide

      tooltip.classList.remove("psd3Hidden");
      d3.event.target.addEventListener("mousemove", function (event) {
        var divIdContent = angular.element(document.querySelector("#content"))[0];
        tooltip.style.left = event.clientX + "px";
        tooltip.style.top = event.clientY + divIdContent.scrollTop + "px";
      });
    },
    format: function format(data) {
      var unit; // display unit type

      if ($scope.form.by === "price_ht") {
        unit = eur.numberFormat("$,.2f")(data.value);
      } else {
        unit = d3.format(",.3")(data.value) + " unités";
      }

      return "<table class=\"\">" + "<thead><tr md-row><th>Champ</th><th>Valeur</th></tr></thead>" + "<tbody><tr md-row><td md-cell>" + data.label + "</td><td md-cell>" + unit + "</td></tr></tbody>" + "</table>";
    }
  };
  $scope.goToState = goToState;
  psd3.Graph = Graph;
  psd3.Pie = Pie;
  psd3.Pie.prototype = Object.create(psd3.Graph.prototype);
  psd3.Pie.prototype.constructor = psd3.Pie;
  psd3.Pie.prototype.findMaxDepth = findMaxDepth;
  psd3.Pie.prototype.setHeading = setHeading;
  psd3.Pie.prototype.mouseover = mouseover;
  psd3.Pie.prototype.mouseout = mouseout;
  psd3.Pie.prototype.drawPie = drawPie;
  psd3.Pie.prototype.customArcTween = customArcTween;
  psd3.Pie.prototype.textTransform = textTransform;
  psd3.Pie.prototype.textTitle = textTitle;
  psd3.Pie.prototype.draw = draw;
  psd3.Pie.prototype.reDrawPie = reDrawPie;
  /**
   * Loader.
   */

  function loader() {
    initTab();
    environmentService.getEnvironment().then(function (environment) {
      $scope.currentEnv = environment;
      $scope.LabID = environment.entityId;
      angular.forEach($scope.form.values, function (item) {
        if (typeof item.rankNeeded !== "undefined") {
          item.canEclate = item.rights.indexOf(environment.accessPointType) > -1 && item.rankNeeded.indexOf(environment.rankId) > -1;
        } else {
          item.canEclate = item.rights.indexOf(environment.accessPointType) > -1;
        }
      });

      if (!_.find($scope.usrStockReal.must.laboratory.selected, function (labo) {
        return labo.id === environment.entityId;
      })) {
        $scope.usrStockReal.must.laboratory.selected.push({
          id: environment.entityId,
          name: environment.entity.name
        });
      }

      StatisticsQueryService.buildquery($scope.usrStockReal, $scope.usrStockReal.date).then(function (query) {
        processStocksQuery(query, true);
      });
    });
  }
  /**
   * Go to state.
   *
   * @param {string} state -
   */


  function goToState(state) {
    $state.go(state);
  }
  /**
   * Initialize tab.
   */


  function initTab() {
    if ($scope.initiated === false) {
      environmentService.getEnvironment().then(function (environment) {
        $scope.env = environment;

        if (environment.accessPointType === 2) {
          $scope.groupByList = groupListPharma;
          $scope.groupByChart = $scope.groupByList[0];
        } else {
          $scope.groupByList = groupListLaboGroup;
          $scope.groupByChart = $scope.groupByList[0];
        }

        $scope.usrStockquery = StocksQueryService.query;
        $scope.stockQuery = StocksQueryService.buildQuery($scope.usrStockquery);
        $scope.LabID = environment.entityId;

        if (environment.entity) {
          launchStatisticsQueries(false);
        }

        $scope.initiated = true;
      });
    }
  }
  /**
   * loaderSalesAnalysis to init sales analysis request
   *
   * @param {object} query -
   * @param {object} brdcast -
   *
   * @returns {Promise}
   */


  function loaderSalesAnalysis(query, brdcast) {
    if (!query.must.laboratory || query.must.laboratory.length === 0) {
      query.must.laboratory = [];
      query.must.laboratory.push($scope.env.entityId);
    }

    return salesByGeo(query, brdcast);
  }
  /**
   * salesByGeo to init sales analysis for
   * map by geo loacation
   *
   * @param {object} query -
   *
   * @returns {Promise}
   */


  function salesByGeo(query) {
    query.startDatetime = moment().month(0).date(0).format("YYYY-MM-DD");
    query.endDatetime = moment().format("YYYY-MM-DD");
    return homeLaboratoryAnalysisService.topByGeo(query).then(function (response) {
      $scope.topGeo = response.aggregations;
    }, function (response) {
      // eslint-disable-next-line no-console
      console.error(response);
      $scope.salesLoading = false;
    });
  }
  /**
   * Launch statistics queries.
   *
   * @param {boolean} brdcast -
   */


  function launchStatisticsQueries(brdcast) {
    // On ne peut lancer les requetes de statistiques
    // seulement lorsque l'objet access est disponible et valide.
    StatisticsQueryService.queryRebase(); // on recupere une query vierge pour ne pas interferer sur les queries
    // de statistics

    $scope.usrquery = StatisticsQueryService.query;
    $scope.usrquery.date = {
      from: moment().subtract(12, "months"),
      to: moment()
    };
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      $scope.query.interval = "1M";
      loaderSalesAnalysis($scope.query, brdcast);
    });
  }
  /**
   * Process stock query.
   */


  function processStocksQuery() {
    $timeout(function () {
      $scope.loading = true;
      angular.element(document.getElementById("realStockHome")).html("");
      StatisticsQueryService.buildquery($scope.usrStockReal, $scope.usrStockReal.date).then(function (query) {
        $scope.query = query;
        $scope.query.buckets = getBuckets();
        analysisService.getStockList($scope.query).then(function (response) {
          $scope.loading = false;
          $scope.elasticdata = response;
          parseData();
          $scope.mShareloading = true;
        }, function (response) {
          // eslint-disable-next-line no-console
          console.error(response);
          $scope.loading = false;
        });
      });
    }, 1500);
  }
  /**
   * Get buckets.
   *
   * @returns {Array}
   */


  function getBuckets() {
    return $scope.form.values.filter(function (data) {
      return data.model && data.checked;
    }).map(function (data) {
      return {
        term: data.val,
        size: $scope.form.nbEl.model
      };
    });
  }
  /**
   * Get toal.
   *
   * @param {Array} data -
   * @param {number} total -
   */


  function getTotal(data, total) {
    angular.forEach(data, function (item) {
      item.total = total;

      if (item.drilldown.length > 0) {
        getTotal(item.drilldown, item.value);
      }
    });
  }
  /**
   * Parse data.
   */


  function parseData() {
    angular.forEach($scope.elasticdata.data, function (item) {
      if (item.drilldown && item.drilldown.length > 0) {
        getTotal(item.drilldown, item.value);
      }
    });
    $scope.config = {
      containerId: "realStockHome",
      width: angular.element(document.getElementById("realStockHome")).width(),
      height: angular.element(document.getElementById("realStockHome")).height(),
      data: $scope.elasticdata.data,
      label: function label() {
        return "";
      },
      value: "value",
      inner: "drilldown",
      tooltip: function tooltip(item) {
        var unit;

        if ($scope.form.by === "price_ht") {
          unit = eur.numberFormat("$,.2f")(item.value);
        } else {
          unit = d3.format(",.3")(item.value) + " unités";
        }

        return "<table class=\"\">" + "<thead><tr md-row><th>Champ</th><th>Valeur</th></tr></thead><tbody><tr md-row>" + "<td md-cell>" + item.label + "</td><td md-cell>" + unit + "</td></tr></tbody></table>";
      },
      transition: "none",
      transitionDuration: 0,
      donutRadius: 50,
      gradient: false,
      labelColor: "white",
      colors: d3.scale.category20c(),
      // getColors(),
      stroke: "#eee",
      strokeWidth: 1,
      drilldownTransition: "none",
      drilldownTransitionDuration: 0
    };
    var pie = document.getElementById($scope.config.containerId);

    if (pie) {
      $scope.pie = new psd3.Pie($scope.config);
    }

    $scope.form.isBlurred = false;
  }
  /**
   * Graph.
   *
   * @param {object} config - Configuration.
   */


  function Graph(config) {
    this.config = config;
    this.defaults = {
      width: angular.element(document.getElementById("chartStockContainer")).width(),
      height: angular.element(document.getElementById("chartStockContainer")).height(),
      value: "value",
      inner: "inner",
      label: function label(item) {
        return item.data.value;
      },
      tooltip: function tooltip(item) {
        if (_this.config.value !== undefined) {
          return item[_this.config.value];
        }

        return item.value;
      },
      transition: "linear",
      transitionDuration: 1000,
      donutRadius: 0,
      gradient: false,
      colors: d3.scale.category20(),
      labelColor: "black",
      drilldownTransition: "linear",
      drilldownTransitionDuration: 0,
      stroke: "white",
      strokeWidth: 2,
      highlightColor: "orange"
    };

    for (var property in this.defaults) {
      if (Object.prototype.hasOwnProperty.call(this.defaults, property)) {
        if (!Object.prototype.hasOwnProperty.call(config, property)) {
          config[property] = this.defaults[property];
        }
      }
    }
  }
  /**
   * Pie chart.
   *
   * @param {object} config - Configuration chart.
   */


  function Pie(config) {
    psd3.Graph.call(this, config);
    this.zoomStack = [];
    var pos = "top";

    if (this.config.heading !== undefined && this.config.heading.pos !== undefined) {
      pos = this.config.heading.pos;
    }

    if (pos === "top") {
      this.setHeading();
    }

    this.drawPie(config.data);

    if (pos === "bottom") {
      this.setHeading();
    }
  }
  /**
   * Find max depth.
   *
   * @param {Array} dataset - Dataset.
   *
   * @returns {number}
   */


  function findMaxDepth(dataset) {
    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return 0;
    }

    var currentLevel;
    var maxOfInner = 0;

    for (var index = 0; index < dataset.length; index++) {
      // eslint-disable-next-line no-invalid-this
      var maxInnerLevel = this.findMaxDepth( // eslint-disable-next-line no-invalid-this
      dataset[index][this.config.inner]);

      if (maxOfInner < maxInnerLevel) {
        maxOfInner = maxInnerLevel;
      }
    }

    currentLevel = 1 + maxOfInner;
    return currentLevel;
  }
  /**
   * Set heading.
   */


  function setHeading() {
    // eslint-disable-next-line no-invalid-this
    if (this.config.heading !== undefined) {
      d3 // eslint-disable-next-line no-invalid-this
      .select("#" + this.config.containerId).append("div").style("text-align", "center") // eslint-disable-next-line no-invalid-this
      .style("width", String(this.config.width) + "px").style("padding-top", "20px").style("padding-bottom", "20px").append("strong") // eslint-disable-next-line no-invalid-this
      .text(this.config.heading.text);
    }
  }
  /**
   * Mouse over.
   *
   * @param {object} item -
   */


  function mouseover(item) {
    $scope.tooltip.display(item); // over effect

    d3.select(item.path).style("fill", _this.config.highlightColor);
  }
  /**
   * Mouse out.
   *
   * @param {object} item -
   */


  function mouseout(item) {
    d3.select("#" + _this.tooltipId).classed("psd3Hidden", true);
    d3.select(item.path).style("fill", item.fill);
  }
  /**
   * Draw pie.
   *
   * @param {Array} dataset -
   */


  function drawPie(dataset) {
    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return;
    } // eslint-disable-next-line no-invalid-this


    var _this = this;

    _this.arcIndex = 0;
    _this.width = 100;
    _this.height = 100;
    var svg = d3.select("#" + _this.config.containerId).append("svg").attr("id", _this.config.containerId + "_svg").attr("width", "100%").attr("height", "80%").attr("viewBox", "0 0 1500 990").attr("preserveAspectRatio", "xMinYMin meet").append("g");
    _this.tooltipId = _this.config.containerId + "_tooltip";
    var tooltipDiv = d3.select("#" + _this.config.containerId).append("div").attr("id", _this.tooltipId).attr("class", "psd3Hidden psd3Tooltip");
    tooltipDiv.append("p").append("span").attr("id", "value").text("100%");
    var labelBp = d3.select("#chartLabelContainer");
    labelBp.html("");

    for (var index = 0; index < brdBp.length; index++) {
      labelBp.append("span").text(brdBp[index].label);

      if (brdBp[index + 1]) {
        labelBp.append("md-icon").attr("class", "mh-8 icon s16 icon-chevron-right").attr("md-font-icon", "icon-chevron-right");
      }
    }

    var returnBp = d3.select("#chartReturnBtnContainer");

    if (brdBp.length > 1) {
      if (!dirtyReturn) {
        returnBp.append("span").text("Retour").attr("class", "returnPsdBrd md-accent-fg mt-8");
        dirtyReturn = true;
      }
    } else {
      returnBp.html("");
      dirtyReturn = false;
    } // to contain pie circle


    var radius;

    if (_this.config.width > _this.config.height) {
      radius = _this.config.width / 2;
    } else {
      radius = _this.config.height / 2;
    }

    var innerRadius = _this.config.donutRadius;

    var maxDepth = _this.findMaxDepth(dataset);

    var outerRadius = innerRadius + (radius - innerRadius) / maxDepth;
    var radiusDelta = outerRadius - innerRadius;

    _this.draw(svg, radius, dataset, dataset, dataset.length, innerRadius, outerRadius, radiusDelta, 0, 360 * 22 / 7 / 180, [0, 0]);
  }
  /**
   * Custom arc tween.
   *
   * @param {object} item -
   *
   * @returns {Function}
   */


  function customArcTween(item) {
    var start = {
      startAngle: item.startAngle,
      endAngle: item.startAngle
    };
    var interpolate = d3.interpolate(start, item);
    return function (data) {
      return item.arc(interpolate(data));
    };
  }
  /**
   * Text transform.
   *
   * @param {object} item -
   *
   * @returns {string}
   */


  function textTransform(item) {
    return "translate(" + item.arc.centroid(item) + ")";
  }
  /**
   * Text title.
   *
   * @param {object} item -
   *
   * @returns {object}
   */


  function textTitle(item) {
    return item.data[_this.config.value];
  }
  /**
   * Draw.
   *
   * @param {object} svg - SVG.
   * @param {number} totalRadius - Total radius.
   * @param {Array} dataset - Dataset.
   * @param {Array} originalDataset - Original dataset.
   * @param {number} originalDatasetLength - Orginal dataset length.
   * @param {number} innerRadius - Inner radius.
   * @param {number} outerRadius - Outer radius.
   * @param {number} radiusDelta - Radius delta.
   * @param {number} startAngle - Start angle.
   * @param {number} endAngle - End angle.
   * @param {Array} parentCentroid - Parent centroid.
   */


  function draw(svg, totalRadius, dataset, originalDataset, originalDatasetLength, innerRadius, outerRadius, radiusDelta, startAngle, endAngle, parentCentroid) {
    // eslint-disable-next-line no-invalid-this
    var _this = this;

    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return;
    }

    brdBp[0].dataset = originalDataset;

    psd3.Pie.prototype.textText = function (item) {
      return _this.config.label(item);
    };

    var pie = d3.layout.pie();
    pie.sort(null);
    pie.value(function (item) {
      return item[_this.config.value];
    });
    pie.startAngle(startAngle).endAngle(endAngle);
    $scope.searchCorrespondTo = {
      "product.laboratory": "laboratory",
      "product.segment.value": "segment",
      "product.presentation": "product"
    };
    var arc = d3.svg.arc().innerRadius(innerRadius).outerRadius(outerRadius); // Set up groups

    _this.arcIndex = _this.arcIndex + 1;
    var clazz = "arc" + _this.arcIndex;

    var storeMetadataWithArc = function storeMetadataWithArc(item) {
      // eslint-disable-next-line no-invalid-this
      item.path = this; // eslint-disable-next-line no-invalid-this

      item.fill = this.fill;
      item.arc = arc;
      item.length = dataset.length;
    };

    var arcs = svg.selectAll("g." + clazz).data(pie(dataset)).enter().append("g").attr("class", "arc " + clazz).attr("transform", "translate(" + totalRadius + "," + totalRadius + ")");
    var gradient = svg.append("svg:defs").append("svg:linearGradient").attr("id", "gradient_" + _this.arcIndex).attr("x1", "0%").attr("y1", "0%").attr("x2", "100%").attr("y2", "100%").attr("spreadMethod", "pad");
    var startColor;
    var endColor;

    if (_this.config.gradient) {
      var index = 2 * _this.arcIndex;
      var endIndex = index + 1;
      startColor = _this.config.colors(index);
      endColor = _this.config.colors(endIndex);
    } else {
      // eslint-disable-next-line no-invalid-this
      startColor = endColor = _this.config.colors(this.arcIndex);
    }

    gradient.append("svg:stop").attr("offset", "0%").attr("stop-color", startColor).attr("stop-opacity", 1);
    gradient.append("svg:stop").attr("offset", "100%").attr("stop-color", endColor).attr("stop-opacity", 1); // Draw arc paths

    var paths = arcs.append("path").attr("fill", "url(" + $stateParams.brickSlug + "/dashboard#gradient_" + _this.arcIndex + ")").style("stroke", _this.config.stroke).style("stroke-width", _this.config.strokeWidth);
    paths.on("mouseover", _this.mouseover);
    paths.on("mouseout", _this.mouseout);
    paths.each(storeMetadataWithArc);
    paths.transition().duration(_this.config.transitionDuration).delay(_this.config.transitionDuration * (_this.arcIndex - 1)).ease(_this.config.transition).attrTween("d", _this.customArcTween); // Labels

    arcs.append("text").attr("x", function () {
      return parentCentroid[0];
    }).attr("y", function () {
      return parentCentroid[1];
    }).transition().ease(_this.config.transition).duration(_this.config.transitionDuration).delay(_this.config.transitionDuration * (_this.arcIndex - 1)).attr("transform", function (item) {
      var translate = [];
      translate[0] = arc.centroid(item)[0] - parentCentroid[0];
      translate[1] = arc.centroid(item)[1] - parentCentroid[1];
      return "translate(" + translate + ")";
    }).attr("text-anchor", "middle").text(_this.textText).style("fill", _this.config.labelColor).attr("title", _this.textTitle);

    for (var _index = 0; _index < dataset.length; _index++) {
      if (dataset[_index][_this.config.inner] !== undefined) {
        _this.draw(svg, totalRadius, dataset[_index][_this.config.inner], originalDataset, originalDatasetLength, innerRadius + radiusDelta, outerRadius + radiusDelta, radiusDelta, paths.data()[_index].startAngle, paths.data()[_index].endAngle, arc.centroid(paths.data()[_index]));
      }
    }
  }
  /**
   * Re draw pie.
   *
   * @param {Array} data - Data.
   * @param {object} ds - Ds.
   */


  function reDrawPie(data, ds) {
    var tmp = [];
    d3.select("#" + _this.tooltipId).remove();
    d3.select("#" + _this.config.containerId + "_svg").transition().ease(_this.config.drilldownTransition).duration(_this.config.drilldownTransitionDuration).style("height", 0).remove().each("end", function () {
      if (data.length === 1) {
        tmp = _this.zoomStack.pop();
      } else {
        tmp.push(data.data);

        _this.zoomStack.push(ds);
      }

      _this.drawPie(tmp);
    });
  }

  loader();
}

angular.module("app.dashboard").controller("dashboardLaboratoriesRealStocksDiagramController", DashboardLaboratoriesRealStocksDiagramController);