"use strict";
/**
 * Auth LGO service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

AuthLgoService.$inject = ["apiClient"];

function AuthLgoService(apiClient) {
  return {
    /**
     * Get.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/lgo/list", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get by Id.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getById: function getById(params) {
      return apiClient.get("/lgo/get/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.auth").service("authLgoService", AuthLgoService);