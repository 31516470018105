"use strict";
/**
 * Event detail controller.
 *
 * @param {object} eventService - Event service.
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $window - $window.
 */

eventDetailController.$inject = ["eventService", "$scope", "$stateParams", "$window"];

function eventDetailController(eventService, $scope, $stateParams, $window) {
  $scope.goBack = goBack;
  $scope.openFile = openFile;
  loader();
  /**
   * The loading function
   */

  function loader() {
    getEvent();
  }
  /**
   * Retrieve event
   *
   * @returns {Promise}
   */


  function getEvent() {
    var query = {
      id: $stateParams.id
    };
    return eventService.read(query).then(function (response) {
      handleEvent(response);
      return response;
    });
  }
  /**
   * Go back function
   */


  function goBack() {
    $window.history.back();
  }
  /**
   * Manage event
   *
   * @param {object} event - Event.
   */


  function handleEvent(event) {
    $scope.event = event;
    $scope.event.type = event.event_type;
    $scope.event.start = new Date(event.startDate);
    $scope.event.end = new Date(event.endDate);
    $scope.event.event_type_id = event.event_type ? event.event_type.id : 1;

    if (!_.isUndefined(event.custom)) {
      $scope.event.products = _.isUndefined(event.custom.products) ? [] : event.custom.products;
      $scope.event.imagePath = _.isUndefined(event.custom.image_path) ? false : event.custom.image_path;
      $scope.event.imageName = _.isUndefined(event.custom.image_name) ? false : event.custom.image_name;
      $scope.event.filePath = _.isUndefined(event.custom.file_path) ? false : event.custom.file_path;
      $scope.event.fileName = _.isUndefined(event.custom.file_name) ? false : event.custom.file_name;
      $scope.event.organizations = _.isUndefined(event.custom.organizations) ? false : event.custom.organizations;
    }
  }
  /**
   * Download the file
   */


  function openFile() {
    eventService.downloadFile({
      id: $stateParams.id
    }).then(function (file) {
      // eslint-disable-next-line no-undef
      saveAs(new Blob([file.file], {}), $scope.event.fileName);
    });
  }
}

angular.module("app.events").controller("eventDetailController", eventDetailController);