"use strict";
/**
 * Sellin offer controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $mdToast - $mdToast.
 * @param {object} sessionStorageService - Session storage service.
 * @param {string} apiurl - API URL.
 * @param {object} environmentService - Environment service.
 * @param {object} sellinOfferService - Sellin offer service.
 */

SellinOfferController.$inject = ["$scope", "$state", "$mdDialog", "$mdToast", "sessionStorageService", "apiurl", "environmentService", "sellinOfferService"];

function SellinOfferController($scope, $state, $mdDialog, $mdToast, sessionStorageService, apiurl, environmentService, sellinOfferService) {
  showSellinOfferCtrl.$inject = ["$scope", "$mdDialog", "sellin", "sellinOfferService"];
  $scope.tabs = [{
    id: 1,
    name: "Dashboard",
    method: loadAccess,
    slug: "dashboard",
    active: true,
    isActive: true
  }, {
    id: 2,
    name: "Acceptées",
    method: loadAccepted,
    slug: "accepted",
    isActive: true
  }, {
    id: 3,
    name: "Refusées",
    method: loadRefused,
    slug: "refused",
    isActive: true
  }];
  $scope.addSellinOffer = addSellinOffer;
  $scope.accept = accept;
  $scope.refuse = refuse;
  $scope.deleteSellinOffer = deleteSellinOffer;
  $scope.publishSellinOffer = publishSellinOffer;
  $scope.editSellinOffer = editSellinOffer;
  $scope.openTab = openTab;
  $scope.showSellinOffer = showSellinOffer;
  $scope.viewSellinOffer = viewSellinOffer;
  $scope.limitOptions = [5, 10, 15];
  $scope.apiurl = apiurl;
  $scope.tokenV2 = "&token=" + sessionStorageService.getToken();
  $scope.query = {
    order: "-startDate",
    limit: 15,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.loadingAwaiting = true;
  $scope.loadingToDo = true;
  $scope.loadingAccepted = true;
  $scope.loadingRefused = true;
  loader();
  /**
   *
   * loader
   * this method init forEach tabs
   * and load loadAccess() method
   */

  function loader() {
    loadAccess();
    var mainTab = angular.element(document).find("#dashboard")[0];
    /**
     * To display block the first tab
     */

    $scope.tabs.forEach(function (tab) {
      mainTab.style.display = "block";

      if (tab.slug === mainTab.id && tab.method) {
        tab.method();
      }
    });
  }
  /**
   *
   * loadAccess
   * this method was init on init controller
   * used for load some loader if accessPointType === given
   * and disabled tab.id = 2 if accepointType == 2 "healthcenter"
   *
   * @returns {Promise}
   */


  function loadAccess() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      loadTabsRegardingEntityType($scope.currentAccess.accessPointType);
      return response;
    });
  }
  /**
   * Load the default tab regarding the entity type
   *
   * @param {number} entityTypeId - The entity type id
   */


  function loadTabsRegardingEntityType(entityTypeId) {
    if (entityTypeId !== 2) {
      loadAwaiting();
    }

    if (entityTypeId === 2) {
      loadToDo();
    }
  }
  /**
   * loadToDo
   *
   * @returns {Promise}
   */


  function loadToDo() {
    return sellinOfferService.getToDo().then(function (response) {
      $scope.loadingToDo = false;
      $scope.todoSellinOffers = response;
      return response;
    });
  }
  /**
   * loadAwaiting sellinOffer
   *
   * @returns {Promise}
   */


  function loadAwaiting() {
    return sellinOfferService.getAwaiting().then(function (response) {
      $scope.loadingAwaiting = false;
      $scope.awaitingSellinOffers = response;
      return response;
    });
  }
  /**
   * loadAccepted
   *
   * @returns {Promise}
   */


  function loadAccepted() {
    return sellinOfferService.getAccepted().then(function (response) {
      $scope.loadingAccepted = false;
      $scope.acceptedSellinOffers = response;
      return response;
    });
  }
  /**
   * loadRefused
   *
   * @returns {Promise}
   */


  function loadRefused() {
    return sellinOfferService.getRefused().then(function (response) {
      $scope.loadingRefused = false;
      $scope.refusedSellinOffers = response;
      return response;
    });
  }
  /**
   * accept
   *
   * @param {object} sellinOffer - Sellin offer.
   *
   * @returns {Promise}
   */


  function accept(sellinOffer) {
    return sellinOfferService.accept(sellinOffer).then(function (response) {
      $scope.currentsSellinOffers = response;
      loadTabsRegardingEntityType($scope.currentAccess.accessPointType);
      return response;
    });
  }
  /**
   * refuse
   *
   * @param {object} sellinOffer - Sellin offer.
   *
   * @returns {Promise}
   */


  function refuse(sellinOffer) {
    return sellinOfferService.refuse(sellinOffer).then(function (response) {
      $scope.currentsSellinOffers = response;
      loadTabsRegardingEntityType($scope.currentAccess.accessPointType);
      return response;
    });
  }
  /**
   * Delete sellin offer.
   *
   * @param {object} event - Event.
   * @param {object} sellinOffer - Sellin offer.
   */


  function deleteSellinOffer(event, sellinOffer) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain de vouloir supprimer l'offre sell-in « " + sellinOffer.name + " » ?").ariaLabel("suppression offre sell-in").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      sellinOfferService["delete"](sellinOffer).then(function () {
        $mdToast.show($mdToast.simple().textContent("L'offre sell-in est supprimée").position("top right"));
        loadAwaiting();
      });
    });
  }
  /**
   * Publish sellin offer.
   *
   * @param {object} event - Event.
   * @param {object} sellinOffer - Sellin offer.
   */


  function publishSellinOffer(event, sellinOffer) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Publication").textContent("Êtes-vous certain de vouloir publier l'offre sell-in « " + sellinOffer.name + " » ?").ariaLabel("publication offre sell-in").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      createSellinOffer(sellinOffer, true);
    });
  }
  /**
   * Create sellinOffer
   *
   * @param {object} sellinOffer - The Sellin offer
   * @param {boolean} toBePublished - To be published or not
   *
   * @returns {Promise}
   */


  function createSellinOffer(sellinOffer, toBePublished) {
    if (toBePublished) {
      sellinOffer.toBePublished = true;
    }

    return sellinOfferService.update(sellinOffer).then(function () {
      $mdToast.show($mdToast.simple().textContent("Le challenge sell-in est ajouté").position("top right"));
      loader();
    });
  }
  /**
   * Publish sellin offer.
   *
   * @param {object} event - Event.
   * @param {object} sellinOffer - Sellin offer.
   */


  function editSellinOffer(event, sellinOffer) {
    event.stopPropagation();
    $state.go("app.sellinOffer.edit", {
      id: sellinOffer.id
    });
  }
  /**
   * Show sellin offer controller.
   *
   * @param {object} $scope - $scope.
   * @param {object} $mdDialog - $mdDialog.
   * @param {object} sellin - Sellin.
   * @param {object} sellinOfferService - Sellin offer service.
   */


  function showSellinOfferCtrl($scope, $mdDialog, sellin, sellinOfferService) {
    $scope.cancel = cancel;
    loader();
    /**
     * loader
     *
     * @returns {Promise}
     */

    function loader() {
      return sellinOfferService.read(sellin).then(function (response) {
        $scope.curentSellin = response;
        return response;
      });
    }
    /**
     * cancel
     */


    function cancel() {
      $mdDialog.cancel();
    }
  }
  /**
   * Show sellin offer.
   *
   * @param {object} sellin - Sellin.
   */


  function showSellinOffer(sellin) {
    $mdDialog.show({
      controller: showSellinOfferCtrl,
      templateUrl: "app/main/sellin-offer/views/includes/detail/resume.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      locals: {
        sellin: sellin
      }
    });
  }
  /**
   * addSellinOffer
   * to change state
   */


  function addSellinOffer() {
    $state.go("app.sellinOffer.new");
  }
  /**
   * viewSellinOffer
   * to change state with param id
   *
   * @param {object} sellinOffer - Sellin offer.
   */


  function viewSellinOffer(sellinOffer) {
    $state.go("app.sellinOffer.detail", {
      id: sellinOffer.id
    });
  }
  /**
   * openTab
   *
   * @param {object} myTab -
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";

        if (tab.method) {
          tab.method();
        }
      } else {
        tab.active = false;
      }
    });
  }
}

angular.module("app.sellinOffer").controller("sellinOfferController", SellinOfferController);