"use strict";
/**
 * Universe range service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

UniverseRangeService.$inject = ["apiClient"];

function UniverseRangeService(apiClient) {
  return {
    /**
     * Get all ranges.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/laboratory/range", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a range.
     *
     * @param {number} rangeId - Range ID.
     *
     * @returns {Promise}
     */
    read: function read(rangeId) {
      return apiClient.get("/laboratory/range/".concat(rangeId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a range.
     *
     * @param {object} range - Range.
     *
     * @returns {Promise}
     */
    create: function create(range) {
      return apiClient.post("/laboratory/range", range, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a range.
     *
     * @param {number} rangeId - Range ID.
     * @param {object} range - Range.
     *
     * @returns {Promise}
     */
    update: function update(rangeId, range) {
      return apiClient.put("/laboratory/range/".concat(rangeId), range, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a range.
     *
     * @param {number} rangeId - Range ID.
     *
     * @returns {Promise}
     */
    "delete": function _delete(rangeId) {
      return apiClient["delete"]("/laboratory/range/".concat(rangeId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get products.
     *
     * @param {number} rangeId - Range ID.
     *
     * @returns {Promise}
     */
    getProducts: function getProducts(rangeId) {
      return apiClient.get("/laboratory/range/".concat(rangeId, "/product/get"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Assign products to a range.
     *
     * @param {number} rangeId - Range ID.
     * @param {Array} productsId - List of products ID.
     *
     * @returns {Promise}
     */
    assignProducts: function assignProducts(rangeId, productsId) {
      return apiClient.post("/laboratory/range/".concat(rangeId, "/product/assign"), {
        productsId: productsId
      }, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.laboratory-universe-range").service("universeRangeService", UniverseRangeService);