"use strict";
/**
 * Dashboard laboratories Goumy diagram controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} StocksQueryService - Stocks query service.
 * @param {object} labPie - Laboratory pie chart configuration.
 * @param {object} labSerial - Laboratory serial chart configuration.
 * @param {object} analysisService - Analysis service.
 */

dashboardLaboratoriesGoumyDiagramController.$inject = ["$scope", "environmentService", "StocksQueryService", "labPie", "labSerial", "analysisService"];

function dashboardLaboratoriesGoumyDiagramController($scope, environmentService, StocksQueryService, labPie, labSerial, analysisService) {
  var groupListLaboGroup = [{
    value: 0,
    title: "Pharmacies"
  }];
  var groupListPharma = [{
    value: 1,
    title: "Laboratoires",
    type: "lab",
    filterId: "id"
  }, {
    value: 6,
    title: "Gammes",
    type: "range",
    filterId: "id"
  }, {
    value: 7,
    title: "Catégories"
  }, {
    value: 8,
    title: "Segments"
  }, {
    value: 9,
    title: "Produits"
  }];
  $scope.LabID = 0;
  $scope.loading = true;
  $scope.loadingChart = true;
  $scope.dataChartSerial = [];
  $scope.initiated = false;
  $scope.amChartOptionsPie0w90 = labPie;
  $scope.amChartOptionsSerial = labSerial;
  /**
   * Loader.
   */

  function loader() {
    initTab();
  }
  /**
   * Update chart.
   */


  function updateChart() {
    $scope.loadingChart = true;
    $scope.amChartOptionsSerial.data = $scope.dataChartSerial.slice($scope.stepPosition, $scope.stepPosition + $scope.stepNumberLine);
    $scope.loadingChart = false;
  }
  /**
   * Initialize tab.
   */


  function initTab() {
    if ($scope.initiated === false) {
      environmentService.getEnvironment().then(function (environment) {
        $scope.env = environment;

        if (environment.accessPointType === 2) {
          $scope.groupByList = groupListPharma;
          $scope.groupByChart = $scope.groupByList[0];
        } else {
          $scope.groupByList = groupListLaboGroup;
          $scope.groupByChart = $scope.groupByList[0];
        }

        $scope.usrStockquery = StocksQueryService.query;
        $scope.stockQuery = StocksQueryService.buildQuery($scope.usrStockquery);
        $scope.LabID = environment.entityId;
        launchSearch($scope.stockQuery);
        $scope.initiated = true;
      });
    }
  }
  /**
   * Launch search.
   *
   * @param {object} obj -
   */


  function launchSearch() {
    var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    analysisService.getLaboratoryStockByGroup($scope.labID, $scope.groupByChart.value, obj).then(function (response) {
      $scope.loading = false;
      $scope.detailStocksProducts = response.hits.hits;
      $scope.detailStocksGeneral = response.aggregations;
      processDataForAmCharts();
    }, function (response) {
      $scope.loading = false;
      $scope.loadingChart = false; // eslint-disable-next-line no-console

      console.error(response);
    });
  }
  /**
   * Process data for AmCharts.
   */


  function processDataForAmCharts() {
    var colors = [{
      title: "< 10 jours",
      color: "#ff2c00"
    }, {
      title: "Entre 10 et 20 jours",
      color: "#ffca28"
    }, {
      title: "Entre 21 et 30 jours",
      color: "#ffe082"
    }, {
      title: "Entre 31 et 60 jours",
      color: "#ffecb3"
    }, {
      title: "Entre 61 et 90 jours",
      color: "#b3e5fc"
    }, {
      title: "> 90 jours",
      color: "#29b6f6"
    }, {
      title: "Stock dormant",
      color: "#4a148c"
    }];
    var dataRangeGrossAmount0w60 = [];
    var dataRangeGrossAmount60w90 = [];
    var dataRangeGrossAmount0w90 = [];
    angular.forEach($scope.detailStocksGeneral.range_days.buckets, function (bucket) {
      var nbRange = bucket.key;
      var data = {};

      if (nbRange < 4) {
        data = {
          range30days: "-" + nbRange + " mois",
          amount: bucket.needs_amount.value,
          color: colors[nbRange].color,
          title: colors[nbRange].title
        };
        dataRangeGrossAmount0w60.push(data);
        dataRangeGrossAmount0w90.push(data);
      } else {
        data = {
          range30days: "-" + nbRange + " mois",
          amount: bucket.order_amount.value,
          color: colors[nbRange].color,
          title: colors[nbRange].title
        };
        dataRangeGrossAmount60w90.push(data);
        dataRangeGrossAmount0w90.push(data);
      }
    });
    $scope.dataRangeGrossAmount0w90 = dataRangeGrossAmount0w90;
    $scope.dataRangeGrossAmount0w60 = dataRangeGrossAmount0w60;
    $scope.dataRangeGrossAmount60w90 = dataRangeGrossAmount60w90;
    $scope.amChartOptionsPie0w90.data = dataRangeGrossAmount0w90;
    var dataChartSerial = [];
    angular.forEach($scope.detailStocksGeneral.datas.buckets, function (dataHc) {
      var name = dataHc.key;
      var id = dataHc.id.buckets[0].key;
      var sumOrderAmount = 0;
      var serialLine = {
        healthcenter: name,
        id: id,
        range1: 0,
        range1Pc: 0,
        range2: 0,
        range3: 0,
        range4: 0,
        range5: 0,
        range6: 0,
        range7: 0
      };
      angular.forEach(dataHc.range_days.buckets, function (dataRange) {
        var nbRange = dataRange.key + 1;
        var strRange = "range" + nbRange;

        if (nbRange < 4) {
          sumOrderAmount += dataRange.needs_amount.value;
          serialLine[strRange] = dataRange.needs_amount.value;
        } else {
          sumOrderAmount += dataRange.order_amount.value;
          serialLine[strRange] = dataRange.order_amount.value;
        }
      });

      if (sumOrderAmount !== 0) {
        serialLine.range1Pc = serialLine.range1 / sumOrderAmount * 100;
      }

      dataChartSerial.push(serialLine);
    });
    dataChartSerial.sort(function (left, right) {
      return left.range1 - right.range1;
    });
    dataChartSerial.reverse();
    $scope.stepPosition = 0;
    $scope.stepMax = dataChartSerial.length;
    $scope.dataChartSerial = dataChartSerial;
    $scope.amChartOptionsSerial.data = dataChartSerial.slice($scope.stepPosition, $scope.stepNumberLine);
    updateChart();
    $scope.loading = false;
    $scope.loadingChart = false;
  }

  loader();
}

angular.module("app.dashboard").controller("dashboardLaboratoriesGoumyDiagramController", dashboardLaboratoriesGoumyDiagramController);