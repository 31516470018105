"use strict";
/**
 * Contract management service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

ContractManagementService.$inject = ["apiClient"];

function ContractManagementService(apiClient) {
  return {
    /**
     * Create validation.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    createValidation: function createValidation(params) {
      return apiClient.post("/contracts/versions/".concat(params.version, "/validations"), params, {
        includes: ["user", "organization", "version.contract", "status"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.validation;
      });
    },

    /**
     * Update validation.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    updateValidation: function updateValidation(params) {
      return apiClient.patch("/contracts/validations/".concat(params.id), params, {
        includes: ["version.contract", "status"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.validation;
      });
    },

    /**
     * Get unvalidated.
     *
     * @returns {Promise}
     */
    getUnvalidated: function getUnvalidated() {
      return apiClient.get("/contracts/unvalidated", {
        includes: ["version"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.contracts;
      });
    },

    /**
     * Get accepted.
     *
     * @returns {Promise}
     */
    getAccepted: function getAccepted() {
      return apiClient.get("/contracts/accepted", {
        includes: ["version"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.contracts;
      });
    },

    /**
     * Get refused.
     *
     * @returns {Promise}
     */
    getRefused: function getRefused() {
      return apiClient.get("/contracts/refused", {
        includes: ["version.lastValidation"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.contracts;
      });
    }
  };
}

angular.module("app.contractManagement").service("contractManagementService", ContractManagementService);