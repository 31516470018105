"use strict";
/**
 * Events timeline controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $filter - $filter.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} eventService - Event service.
 * @param {object} eventFactory - Event factory.
 * @param {object} environmentService - Environment service.
 * @param {object} $timeout - The angular $timeout object.
 */

EventsTimelineController.$inject = ["$scope", "$state", "$filter", "$mdDialog", "eventService", "eventFactory", "environmentService", "$timeout"];

function EventsTimelineController($scope, $state, $filter, $mdDialog, eventService, eventFactory, environmentService, $timeout) {
  $scope.goToEvent = goToEvent;
  $scope.getEvents = getEvents;
  $scope.onClick = onClick;
  $scope.toggle = toggle;
  $scope.exists = exists;
  $scope.editEvent = editEvent;
  $scope.deleteEvent = deleteEvent;
  $scope.switchView = switchView;
  $scope.checkAll = checkAll;
  $scope.manageEvents = manageEvents;
  $scope.displayType = "list";
  $scope.eventTypeName = eventFactory.eventTypeName;
  $scope.eventListingOptions = eventFactory.eventListingOptions;
  $scope.query = eventFactory.query;
  $scope.options = eventFactory.options;
  $scope.ctrl = {};
  $scope.ctrl.typesSelected = [];
  $scope.ctrl.allChecked = false;
  $scope.eventsColors = {};
  $scope.today = new Date();
  /* config object */

  $scope.uiConfig = {
    calendar: {
      lang: "fr",
      height: "450px",
      editable: true,
      header: {
        left: "title today prev,next",
        center: "month basicWeek basicDay agendaDay",
        right: ""
      },
      buttonText: {
        today: "Aujourd'hui",
        month: "Mois",
        basicWeek: "Semaine",
        basicDay: "Jour",
        agendaDay: "Agenda"
      },
      views: {
        month: {
          titleFormat: "DD MMMM YYYY"
        },
        basicWeek: {
          titleFormat: "DD MMMM YYYY"
        },
        basicDay: {
          titleFormat: "DD MMMM YYYY"
        },
        agendaWeek: {
          titleFormat: "DD MMMM YYYY"
        },
        agendaDay: {
          titleFormat: "DD MMMM YYYY"
        },
        agenda: {
          eventLimit: 50
        }
      },
      axisFormat: "H:mm",
      timeFormat: {
        agenda: "H:mm{ - H:mm}"
      },
      eventLimit: false,
      eventTextColor: "black",
      allDayText: "Toute la journée",
      eventLimitText: "Plus",
      eventClick: goToEvent,
      eventMouseover: function eventMouseover(calEvent, jsEvent) {
        var elems = angular.element(document).find("." + calEvent.className[0]);

        for (var index = 0; elems.length > index; index++) {
          elems[index].style.backgroundColor = calEvent.lightColor;
        }

        showTooltip(jsEvent, calEvent);
      },
      eventMouseout: function eventMouseout(calEvent) {
        var elems = angular.element(document).find("." + calEvent.className[0]);

        for (var index = 0; elems.length > index; index++) {
          elems[index].style.backgroundColor = calEvent.darkColor;
        }

        $scope.tooltip.remove();
      }
    }
  };
  $scope.calendarOptions = eventFactory.calendarOptions;
  $scope.eventFilters = false;
  loader();
  /**
   * The loader function
   */

  function loader() {
    $scope.loading = true;
    $scope.today = new Date();
    environmentService.getEnvironment().then(function (env) {
      $scope.env = env;
      var typesSelectedName = eventFactory.ctrl.typesSelectedName;

      if ($scope.env.accessPointType !== 2) {
        $scope.ctrl.typesSelectedName = eventFactory.ctrl.typesSelectedNameGroupLabo.concat(typesSelectedName);
      } else {
        $scope.ctrl.typesSelectedName = eventFactory.ctrl.typesSelectedNameHealthcenter.concat(typesSelectedName);
      }

      getEvents();
    });
  }
  /**
   * Retrieve the events
   *
   * @returns {Promise}
   */


  function getEvents() {
    $scope.loading = true;
    $scope.calendarOptions.loading = true;
    $scope.eventListingOptions.loading = true;
    $scope.events = [];
    var types = $scope.ctrl.typesSelectedName.map(function (type) {
      return type.value;
    });
    return eventService.getEvents({
      type: types.join(",")
    }).then(function (results) {
      $scope.eventsSaved = results;

      if ($scope.displayType === "list") {
        manageEventsForList();
      }

      if ($scope.displayType === "calendar") {
        manageEventsForCalendar();
      }

      $scope.loading = false;
    });
  }
  /**
   * Switch view.
   *
   * @param {string} type - Type.
   */


  function switchView(type) {
    $scope.displayType = type;
    $scope.today = new Date();
    $scope.calendarOptions.loading = true;
    $scope.eventListingOptions.loading = true;
    var typesSelectedName = eventFactory.ctrl.typesSelectedName;

    if ($scope.env.accessPointType !== 2) {
      $scope.ctrl.typesSelectedName = eventFactory.ctrl.typesSelectedNameGroupLabo.concat(typesSelectedName);
    } else {
      $scope.ctrl.typesSelectedName = eventFactory.ctrl.typesSelectedNameHealthcenter.concat(typesSelectedName);
    }

    if (!$scope.loading) {
      manageEvents();
    }
  }
  /**
   * Do anything you want with the click event
   *
   * @param {object} event - Event.
   */


  function onClick(event) {
    if (event.what === "item") {
      goToEvent(event);
    }
  }
  /**
   * Check all.
   */


  function checkAll() {
    $scope.ctrl.typesSelectedName.forEach(function (filter) {
      filter.checked = $scope.ctrl.allChecked;
    });
    manageEvents();
  }
  /**
   * Manage events.
   */


  function manageEvents() {
    if ($scope.displayType === "list") {
      manageEventsForList();
    } else {
      manageEventsForCalendar();
    }
  }
  /**
   * Manage events for list.
   *
   * @returns {object}
   */


  function manageEventsForList() {
    $scope.eventListingOptions.loading = true;
    var checked = $scope.ctrl.typesSelectedName.filter(function (type) {
      return type.checked;
    }).map(function (type) {
      return type.value;
    });
    $scope.events = [];
    $scope.eventsSaved.events.forEach(function (event) {
      event.title = event.name;
      event.start = new Date(event.start);
      event.end = new Date(event.end);
      event.custom = event.custom_event;
      event.color = event.lightColor;
      event.event_type_id = event.event_type ? event.event_type.id : 1;
      event.event_type = event.type;
      _.find($scope.ctrl.typesSelectedName, function (type) {
        return type.value === event.type;
      }).hasEvents = true;

      if (checked.indexOf(event.event_type) > -1) {
        $scope.events.push(event);
      }
    });
    $scope.eventListingOptions.loading = false;
    return $scope.events;
  }
  /**
   * Manage events for calendar.
   */


  function manageEventsForCalendar() {
    $scope.calendarOptions.loading = true;
    var checked = $scope.ctrl.typesSelectedName.filter(function (type) {
      return type.checked;
    }).map(function (type) {
      return type.value;
    });
    var tmpEvents = [];
    $scope.events = {};
    $scope.myEvents = {};
    $scope.eventTypeName = _.keys(eventFactory.eventTypeName).forEach(function (key) {
      eventFactory.eventTypeName[key].color = null;
    });
    $scope.eventTypeName = eventFactory.eventTypeName;
    $timeout(function () {
      angular.forEach($scope.eventsSaved.events, function (event) {
        if (event.start) {
          event.color = event.darkColor;
          event.title = event.name;
          event.start = moment(event.start);
          event.end = moment(event.end);
          event.custom = event.custom_event;
          event.className = event.title.replace(/[^a-z0-9]+/gi, "a").replace(/ /g, "-") + event.id;

          if (event.type === "birthday") {
            event.start = new Date(event.start).setFullYear(new Date().getFullYear());
            event.allDay = true;
            event.end = new Date(event.end).setFullYear(new Date().getFullYear());
          }

          if (checked.indexOf(event.event_type) > -1) {
            $scope.eventTypeName[event.type].color = event.darkColor;
            tmpEvents.push(event);
          }
        }
      });
      $scope.events = {
        events: tmpEvents
      };
      $scope.myEvents = $scope.events.events;
      $scope.calendarOptions.loading = false;
    }, 800);
  }
  /**
   * Get dialog content.
   *
   * @param {object} event - Event.
   *
   * @returns {string}
   */


  function getDialogContent(event) {
    var switchType = event.type;
    var content = event.name;
    var date = "";

    if (event.end) {
      date = " du ";
    } else {
      date = " le ";
    }

    date += $filter("date")(new Date(event.start), "dd MMMM");

    if (event.end) {
      date += " au " + $filter("date")(new Date(event.end), "dd MMMM");
    }

    switch (switchType) {
      case "sellout":
      case "sellout-participation":
        return "Challenge Equipe " + content + date;

      case "prestation":
      case "prestation-participation":
        return "Mise en avant " + content + date;

      case "formation":
        return "Formation " + content + date;

      case "tv-ad":
        return "Campagne télé " + content + date;

      case "radio-ad":
        return "Campagne radio " + content + date;

      case "press-ad":
        return "Campagne presse " + content + date;

      case "poster-ad":
        return "Campagne affichage " + content + date;

      case "medical-visit":
        return "Visite médicale " + content + date;

      case "animation":
        return "Animation " + content + date;

      case "commercial_appointment":
        return "Rendez-vous commercial " + content + date;

      case "meeting":
        return "Réunion " + content + date;

      case "basic-event":
        return "Evénement " + content + date;

      case "emailing":
        return "Emailing " + content + date;

      case "product-launch":
        return "Lancement produits " + content + date;

      case "digital-campaign":
        return "Campagne digitale " + content + date;

      case "batches-recall":
        return "Retrait de lot " + content + date;

      case "sellin-offer":
      case "sellin-offer-participation":
        return "Offre sellin " + content + date;

      case "sellin-challenge":
      case "sellin-challenge-participation":
        return "Challenge sellin " + content + date;

      case "birthday":
        return "Anniversaire de " + content + " le " + $filter("date")(new Date(event.start), "dd MMMM");
    }

    return "";
  }
  /**
   * show tooltip
   *
   * @param {object} event - Event.
   * @param {object} calEvent - Calendar event.
   *
   */


  function showTooltip(event, calEvent) {
    var element = document.createElement("div");
    $scope.tooltip = element;
    element.style.position = "relative";
    element.style.zIndex = 99998;
    element.style.display = "block";
    var divIdContent = angular.element(document.querySelector("#content"))[0];
    var posLeft = event.clientX - 300 < 0 ? 0 : event.clientX - 300;

    if (event.clientX + 300 > event.view.innerWidth) {
      posLeft = event.clientX - 1000;
    }

    element.style.left = posLeft + "px";
    var topY = 200;
    element.style.top = event.clientY - topY + divIdContent.scrollTop + "px";
    var id = "#calendarView";
    angular.element(document).find(id).prepend(element);
    var compiled = document.createElement("div");
    element.append(compiled);
    compiled.style.zIndex = 99999;
    compiled.style.left = "50px";
    compiled.style.top = "0px";
    compiled.style.display = "block";
    compiled.style.position = "absolute";
    var tooltipClass = "tooltipCalendar";
    compiled.innerHTML = "<div class=" + tooltipClass + ">" + getDialogContent(calEvent) + "</div>";
    $scope.tooltipIsShown = true;
  }
  /**
   * Go the the selected event
   *
   * @param {object} event - Event.
   */


  function goToEvent(event) {
    var switchType = event.type;
    var id = event.id;

    switch (switchType) {
      case "sellout":
        $state.go("app.sellout.report", {
          id: id
        });
        break;

      case "sellout-participation":
        $state.go("app.UserSelloutHome.detail", {
          id: id
        });
        break;

      case "prestation":
        $state.go("app.prestation.detail", {
          id: id
        });
        break;

      case "prestation-participation":
        $state.go("app.UserPrestationDetail", {
          id: id
        });
        break;

      case "sellin-offer":
      case "sellin-offer-participation":
        $state.go("app.sellinOffer.detail", {
          id: id
        });
        break;

      case "sellin-challenge":
      case "sellin-challenge-participation":
        $state.go("app.sellinChallenge.detail", {
          id: id
        });
        break;

      case "birthday":
        break;

      default:
        delete event.source;
        $state.go("app.calendar.detail", {
          event: JSON.stringify(event),
          id: id
        });
        break;
    }
  }
  /**
   * Go to edit state
   *
   * @param {object} event - Event.
   */


  function editEvent(event) {
    $state.go("app.calendar.edit", {
      event: JSON.stringify(event),
      id: event.id
    });
  }
  /**
   * Delete an event
   *
   * @param {object} event - Event.
   */


  function deleteEvent(event) {
    var confirm = $mdDialog.confirm().title("Souhaitez-vous supprimer cet événement ?").ariaLabel("Supprimer un événement").ok("Oui").cancel("Non");
    $mdDialog.show(confirm).then(function () {
      eventService["delete"](event).then(function () {
        getEvents();
      });
    });
  }
  /**
   * toggle filter
   *
   * @param {object} item - Item.
   * @param {Array} list - List.
   */


  function toggle(item, list) {
    var idx = list.indexOf(item);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    getEvents();
  }
  /**
   * filter exists
   *
   * @param {object} item - Item.
   * @param {Array} list - List.
   *
   * @returns {boolean}
   */


  function exists(item, list) {
    return list.indexOf(item) > -1;
  }
}

angular.module("app.events").controller("eventsTimelineController", EventsTimelineController);