"use strict";
/**
 * Apodis content directive.
 *
 * @returns {object}
 */

function ApodisContent() {
  return {
    restrict: "E",
    scope: {
      config: "=config"
    },
    templateUrl: "app/main/directives/apodis-content/apodisContent.html",
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("apodisContent", ApodisContent);