"use strict";
/**
 * Market shares evolution controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} salesAnalysisMarketSharesEvolutionService -
 *      Sales analysis market shares evolution service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 */

MarketSharesEvolutionController.$inject = ["$scope", "salesAnalysisMarketSharesEvolutionService", "StatisticsQueryService", "environmentService"];

function MarketSharesEvolutionController($scope, salesAnalysisMarketSharesEvolutionService, StatisticsQueryService, environmentService) {
  $scope.query = StatisticsQueryService.query;
  $scope.config = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct", "category"],
    searchPlaceHolder: "Effectuez une recherche pour sélectionner le marché à analyser...",
    event: "searchProductMarketShare"
  };
  $scope.loaderOptions = {
    loading: true,
    loader: "Récupération de vos données...",
    empty: "Aucune donnée n’est disponible"
  };
  $scope.loaderComparisonOptions = {
    loading: true,
    loader: "Récupération de vos données...",
    empty: "Aucune donnée n’est disponible"
  };
  $scope.aggByOptions = [{
    name: "Produits",
    agg: "product.presentation"
  }, {
    name: "Segments",
    agg: "segmentProduct"
  }, {
    name: "Laboratoires",
    agg: "product.laboratory"
  }, getCategoryForAgg(0), getCategoryForAgg(1), getCategoryForAgg(2), getCategoryForAgg(3), getCategoryForAgg(4)];
  $scope.unitOptions = [{
    name: "Quantité",
    unit: "unit"
  }, {
    name: "CA HT",
    unit: "sales"
  }];
  $scope.unitOptionModel = "sales";
  $scope.unitOptionLabel = "€";
  $scope.aggBy = "product.laboratory";
  $scope.getMarketSharesEvolution = getMarketSharesEvolution;
  $scope.changeUnit = changeUnit;
  $scope.rangeSelected = rangeSelected;
  $scope.rangeComparisonSelected = rangeComparisonSelected;
  /**
   * Get market shares evolution.
   *
   * @param {boolean} comparison - Comparison.
   */

  function getMarketSharesEvolution(comparison) {
    $scope.loaderOptions.loading = true;

    if (comparison) {
      $scope.loaderComparisonOptions.loading = true;
    }

    $scope.query.date = {
      from: moment($scope.startDate, "DD-MM-YYYY"),
      to: moment($scope.endDate, "DD-MM-YYYY")
    };
    StatisticsQueryService.buildquery($scope.query, $scope.query.date).then(function (query) {
      query.aggBy = $scope.aggBy;
      salesAnalysisMarketSharesEvolutionService.get(query).then(function (data) {
        $scope.localData = data.local;
        $scope.localPieOptions = _.clone($scope.pieOptions);
        $scope.localPieOptions.data = _.sortBy($scope.localData, function (datum) {
          return -datum[$scope.unitOptionModel];
        });
        $scope.nationalData = data.national;
        $scope.nationalPieOptions = _.clone($scope.pieOptions);
        $scope.nationalPieOptions.data = _.sortBy($scope.nationalData, function (datum) {
          return -datum[$scope.unitOptionModel];
        });
        $scope.loaderOptions.loading = false;

        if (comparison) {
          getMarketSharesEvolutionComparison();
        } else {
          getEvolutionData();
        }
      });
    });
  }
  /**
   * getMarketSharesEvolutionComparison
   */


  function getMarketSharesEvolutionComparison() {
    $scope.loaderComparisonOptions.loading = true;
    $scope.query.date = {
      from: moment($scope.comparisonStartDate, "DD-MM-YYYY"),
      to: moment($scope.comparisonEndDate, "DD-MM-YYYY")
    };
    StatisticsQueryService.buildquery($scope.query, $scope.query.date).then(function (queryComparison) {
      queryComparison.aggBy = $scope.aggBy;
      salesAnalysisMarketSharesEvolutionService.get(queryComparison).then(function (comparisonData) {
        $scope.localComparisonData = comparisonData.local;
        $scope.localComparisonOptions = _.clone($scope.pieOptions);
        $scope.localComparisonOptions.data = _.sortBy($scope.localComparisonData, function (datum) {
          return -datum[$scope.unitOptionModel];
        });
        $scope.nationalComparisonData = comparisonData.national;
        $scope.nationalComparisonOptions = _.clone($scope.pieOptions);
        $scope.nationalComparisonOptions.data = _.sortBy($scope.nationalComparisonData, function (datum) {
          return -datum[$scope.unitOptionModel];
        });
        $scope.loaderComparisonOptions.loading = false;
        getEvolutionData();
      });
    });
  }
  /**
   * Global options for the charts
   */


  $scope.pieOptions = {
    type: "pie",
    theme: "light",
    startDuration: 0,
    margin: 25,
    pullOutRadius: 10,
    mouseWheelScrollEnabled: true,
    balloonText: "[[title]]: [[percents]]% ([[value]] " + $scope.unitOptionLabel + ")",
    thousandsSeparator: " ",
    unit: " EUR HT",
    valueField: $scope.unitOptionModel,
    titleField: "name",
    colorField: "color",
    precision: 0,
    labelsEnabled: false,
    groupPercent: 1,
    groupedTitle: "Autre ",
    legend: {
      enabled: false,
      position: "right"
    },
    deepWatch: true,
    listeners: [{
      event: "rollOverSlice",
      method: function method(graph) {
        $scope.activeItemId = graph.dataItem.dataContext.id;
        $scope.$apply();
      }
    }]
  };
  /**
   * The loading function
   */

  function loader() {
    $scope.loading = true;
    $scope.loadingComparison = true;
    var startDate = moment($scope.query.date.from).format("DD MMM YYYY");
    var endDate = moment($scope.query.date.to).format("DD MMM YYYY");
    $scope.startDate = moment($scope.query.date.from);
    $scope.startDateLabel = new Date($scope.startDate);
    $scope.endDate = moment($scope.query.date.to);
    $scope.endDateLabel = new Date($scope.endDate);
    var startDateComparisonPeriod = moment($scope.query.date.from).subtract(1, "year").format("DD MMM YYYY");
    var endDateComparisonPeriod = moment($scope.query.date.to).subtract(1, "year").format("DD MMM YYYY");
    $scope.comparisonStartDate = moment($scope.query.date.from).subtract(1, "year");
    $scope.comparisonEndDate = moment($scope.query.date.to).subtract(1, "year");
    $scope.comparisonStartDateLabel = new Date($scope.comparisonStartDate);
    $scope.comparisonEndDateLabel = new Date($scope.comparisonEndDate);
    $scope.dateLabel = startDate + " Au " + endDate;
    $scope.dateLabelComparison = startDateComparisonPeriod + " Au " + endDateComparisonPeriod;
    environmentService.getEnvironment().then(function (env) {
      $scope.env = env;

      if (env.accessPointType === 3) {
        $scope.aggByOptions.push({
          name: "Pharmacies",
          agg: "organization.id"
        });
      }

      getMarketSharesEvolution(true);
    });
  }
  /**
   * Range selected.
   *
   * @param {object} range - Range.
   */


  function rangeSelected(range) {
    $scope.loaderOptions.loading = true;
    var startDate = moment(range.startDate, "DD MMM YYYY").format("DD-MM-YYYY");
    var endDate = moment(range.endDate, "DD MMM YYYY").format("DD-MM-YYYY");
    $scope.startDate = startDate;
    $scope.startDateLabel = new Date(moment($scope.startDate, "DD-MM-YYYY").format("MM-DD-YYYY"));
    $scope.endDate = endDate;
    $scope.endDateLabel = new Date(moment($scope.endDate, "DD-MM-YYYY").format("MM-DD-YYYY"));
    $scope.dateLabel = startDate + " Au " + endDate;
    getMarketSharesEvolution();
  }
  /**
   * On change comparison date
   *
   * @param {object} range - Range.
   */


  function rangeComparisonSelected(range) {
    $scope.loaderComparisonOptions.loading = true;
    var startDateComparisonPeriod = moment(range.startDate, "DD MMM YYYY").format("DD-MM-YYYY");
    var endDateComparisonPeriod = moment(range.endDate, "DD MMM YYYY").format("DD-MM-YYYY");
    $scope.dateLabelComparison = startDateComparisonPeriod + " Au " + endDateComparisonPeriod;
    $scope.comparisonStartDate = startDateComparisonPeriod;
    $scope.comparisonEndDate = endDateComparisonPeriod;
    $scope.comparisonStartDateLabel = new Date(moment($scope.comparisonStartDate, "DD-MM-YYYY").format("MM-DD-YYYY"));
    $scope.comparisonEndDateLabel = new Date(moment($scope.comparisonEndDate, "DD-MM-YYYY").format("MM-DD-YYYY"));
    getMarketSharesEvolutionComparison();
  }
  /**
   * Create array for evolution analysis
   */


  function getEvolutionData() {
    $scope.evolutionData = [];
    $scope.total = {
      sales: 0,
      unit: 0
    };
    $scope.totalComparison = {
      sales: 0,
      unit: 0
    };
    $scope.totalNational = {
      sales: 0,
      unit: 0
    };
    $scope.totalNationalComparison = {
      sales: 0,
      unit: 0
    };
    angular.forEach($scope.localComparisonData, function (comparisonDatum) {
      $scope.totalComparison.sales += comparisonDatum.sales;
      $scope.totalComparison.unit += comparisonDatum.unit;
    });
    $scope.localData.forEach(function (datum) {
      var comparisonDatum = _.find($scope.localComparisonData, function (comparisonDatum) {
        return datum.id === comparisonDatum.id;
      });

      $scope.total.sales += datum.sales;
      $scope.total.unit += datum.unit;
      $scope.evolutionData.push({
        id: datum.id,
        color: datum.color,
        name: datum.name,
        sales: datum.sales,
        unit: datum.unit,
        comparison: {
          sales: comparisonDatum ? comparisonDatum.sales : 0,
          unit: comparisonDatum ? comparisonDatum.unit : 0
        }
      });
    });
    $scope.nationalEvolutionData = [];
    angular.forEach($scope.nationalComparisonData, function (comparisonDatum) {
      $scope.totalNationalComparison.sales += comparisonDatum.sales;
      $scope.totalNationalComparison.unit += comparisonDatum.unit;
    });
    $scope.nationalData.forEach(function (datum) {
      var comparisonDatum = _.find($scope.nationalComparisonData, function (comparisonDatum) {
        return datum.id === comparisonDatum.id;
      });

      $scope.totalNational.sales += datum.sales;
      $scope.totalNational.unit += datum.unit;
      $scope.nationalEvolutionData.push({
        id: datum.id,
        color: datum.color,
        name: datum.name,
        sales: datum.sales,
        unit: datum.unit,
        comparison: {
          sales: comparisonDatum ? comparisonDatum.sales : 0,
          unit: comparisonDatum ? comparisonDatum.unit : 0
        }
      });
    });
  }
  /**
   * On change unit
   */


  function changeUnit() {
    $scope.unitOptionLabel = "unités";

    if ($scope.unitOptionModel === "sales") {
      $scope.unitOptionLabel = "€";
    }

    $scope.pieOptions.valueField = $scope.unitOptionModel;
    $scope.pieOptions.balloonText = "[[title]]: [[percents]]% ([[value]] " + $scope.unitOptionLabel + ")";
    $scope.localPieOptions.valueField = $scope.unitOptionModel;
    $scope.localPieOptions.balloonText = "[[title]]: [[percents]]% ([[value]] " + $scope.unitOptionLabel + ")";
    $scope.nationalPieOptions.valueField = $scope.unitOptionModel;
    $scope.nationalPieOptions.balloonText = "[[title]]: [[percents]]% ([[value]] " + $scope.unitOptionLabel + ")";
    $scope.localComparisonOptions.valueField = $scope.unitOptionModel;
    $scope.localComparisonOptions.balloonText = "[[title]]: [[percents]]% ([[value]] " + $scope.unitOptionLabel + ")";
    $scope.nationalComparisonOptions.valueField = $scope.unitOptionModel;
    $scope.nationalComparisonOptions.balloonText = "[[title]]: [[percents]]% ([[value]] " + $scope.unitOptionLabel + ")";
  }

  loader();
  $scope.$on("searchProductMarketShare", function () {
    $scope.loaderOptions.loading = true;
    $scope.loaderComparisonOptions.loading = true;
    getMarketSharesEvolution(true);
  });
  /**
   * Get category for aggregations.
   *
   * @param {number} currentLevel - Current category level.
   *
   * @returns {{agg: string, name: string}}
   */

  function getCategoryForAgg(currentLevel) {
    var humanLevel = parseInt(currentLevel) + 1;
    return {
      agg: "product.category.level" + currentLevel,
      name: "Catégorie de Niveau " + humanLevel
    };
  }
}

angular.module("app.statistics.salesAnalysis").controller("marketSharesEvolutionController", MarketSharesEvolutionController);