"use strict";
/**
 * Entity admin main controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} entityAdminTeamsService - Entity admin teams service.
 */

entityAdminMainController.$inject = ["$scope", "$state", "$mdDialog", "notifyUserService", "entityAdminTeamsService"];

function entityAdminMainController($scope, $state, $mdDialog, notifyUserService, entityAdminTeamsService) {
  createTeamController.$inject = ["$scope", "$mdDialog", "entityAdminTeamsService", "notifyUserService"];

  /**
   * Declaration of public methods
   */
  $scope.editTeam = editTeam;
  $scope.deleteTeam = deleteTeam;
  $scope.createTeam = createTeam;
  $scope.loadTeams = loadTeams;
  $scope.goToAccesses = goToAccesses;
  $scope.goToAuthorizations = goToAuthorizations;
  $scope.goToMenus = goToMenus;
  $scope.setBackgroundColor = setBackgroundColor;
  $scope.setIcon = setIcon;
  /**
   * Declaration of public objects
   */

  $scope.query = {
    order: "-startDate",
    limit: 10,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.loadTeams = {
    loading: true,
    loader: "Récupération des équipes",
    empty: "Vous n'avez aucune équipe."
  };
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * Loader.
   */

  function loader() {
    loadTeams();
  }
  /**
   * Load teams.
   *
   * @returns {Promise}
   */


  function loadTeams() {
    return entityAdminTeamsService.getTeams().then(function (teams) {
      teams.forEach(function (team) {
        entityAdminTeamsService.getBricksByTeam(team).then(function (bricks) {
          team.bricks = bricks;
        });
      });
      $scope.loadTeams.loading = false;
      $scope.teams = teams;
    });
  }
  /**
   * Create team.
   */


  function createTeam() {
    $mdDialog.show({
      controller: createTeamController,
      templateUrl: "app/main/entityAdmin/views/includes/modals/createTeam/createTeam.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true
    }).then(function () {// do nothing
    }, function () {
      loadTeams();
    });
  }
  /**
   * Create team controller.
   *
   * @param {object} $scope - $scope.
   * @param {object} $mdDialog - $mdDialog.
   * @param {object} entityAdminTeamsService - Entity admin teams service.
   * @param {object} notifyUserService - Notify user service.
   */


  function createTeamController($scope, $mdDialog, entityAdminTeamsService, notifyUserService) {
    $scope.save = save;
    $scope.cancel = cancel;
    /**
     * Save.
     *
     * @param {object} form - Form.
     */

    function save(form) {
      var myTeam = {
        name: form.name.$modelValue,
        description: form.description.$modelValue
      };
      entityAdminTeamsService.create(myTeam).then(function (team) {
        notifyUserService.showSimpleToast("L'équipe " + team.name + " a bien été créée.");
        $mdDialog.cancel();
        editTeam({}, team);
      });
    }
    /**
     * Cancel.
     */


    function cancel() {
      $mdDialog.cancel();
    }
  }
  /**
   * Edit team.
   *
   * @param {object} event - Event.
   * @param {object} team - Team.
   */


  function editTeam(event, team) {
    goToAccesses(team);
  }
  /**
   * Delete team.
   *
   * @param {object} event - Event.
   * @param {object} team - Team.
   */


  function deleteTeam(event, team) {
    entityAdminTeamsService["delete"](team).then(function () {
      notifyUserService.showSimpleToast("L'équipe " + team.name + " a bien été supprimée.");
    }).then(loadTeams);
  }
  /**
   * Go to accesses.
   *
   * @param {object} team - Team.
   */


  function goToAccesses(team) {
    $state.go("app.entityAdmin.team", {
      id: team.id,
      slug: "accesses"
    });
  }
  /**
   * Go to authorizations.
   *
   * @param {object} team - Team.
   */


  function goToAuthorizations(team) {
    $state.go("app.entityAdmin.team", {
      id: team.id,
      slug: "authorizations"
    });
  }
  /**
   * Go to menus.
   *
   * @param {object} team - Team.
   */


  function goToMenus(team) {
    $state.go("app.entityAdmin.team", {
      id: team.id,
      slug: "menus"
    });
  }
  /**
   * Set background color.
   *
   * @param {object} brick - Brick.
   *
   * @returns {object}
   */


  function setBackgroundColor(brick) {
    return {
      "background-color": brick.color
    };
  }
  /**
   * Set icon.
   *
   * @param {object} brick - Brick.
   * @returns {string}
   */


  function setIcon(brick) {
    return brick.icon;
  }
}

angular.module("app.entityAdmin").controller("entityAdminMainController", entityAdminMainController);