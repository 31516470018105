"use strict";
/**
 * Healthcenter audit stock distributor service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

HealthcenterAuditStockDistributorService.$inject = ["apiClient"];

function HealthcenterAuditStockDistributorService(apiClient) {
  return {
    /**
     * Post.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    post: function post(params) {
      return apiClient.post("/distributor/availability/healthcenter/".concat(params.healthcenter), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read wholesaler.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readWholeSaler: function readWholeSaler(params) {
      return apiClient.get("/wholesalers/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.wholesaler;
      });
    },

    /**
     * Read warehouse.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readWareHouse: function readWareHouse(params) {
      return apiClient.get("/warehouses/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.warehouse;
      });
    }
  };
}

angular.module("app.healthcenterStockAudit").service("healthcenterAuditStockDistributorService", HealthcenterAuditStockDistributorService);