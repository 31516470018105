"use strict";
/**
 * Wholesaler catalog price list edit controller.
 *
 * @param {object} $scope - Scope
 * @param {object} $state - State
 * @param {object} $stateParams - State params
 * @param {object} notifyUserService - Notify user service
 * @param {object} wholesalerCatalogService - Wholesaler catalog service
 * @param {object} environmentService - Environment service
 */

WholesalerCatalogPriceListEdit.$inject = ["$scope", "$state", "$stateParams", "notifyUserService", "wholesalerCatalogService", "environmentService"];

function WholesalerCatalogPriceListEdit($scope, $state, $stateParams, notifyUserService, wholesalerCatalogService, environmentService) {
  $scope.priceListId = $stateParams.id;
  $scope.priceList = {
    name: "",
    shortDescription: "",
    wholesalerCatalogId: $stateParams.wholesalerCatalogId,
    freePortColdProduct: 0,
    freePortAmbientProduct: 0,
    monthlyPriceWithoutVat: 0
  };
  $scope.goToList = goToList;
  $scope.createPriceList = createPriceList;
  $scope.updatePriceList = updatePriceList;
  $scope.submit = submit;
  loader();
  /**
   * The loader function.
   */

  function loader() {
    environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      getPriceList();
    });
  }
  /**
   * Get PriceList.
   */


  function getPriceList() {
    if ($stateParams.id) {
      var priceList = {
        wholesalerId: $scope.currentAccess.entityId,
        wholesalerCatalogId: $stateParams.wholesalerCatalogId,
        id: $stateParams.id
      };
      wholesalerCatalogService.readPriceList(priceList).then(function (response) {
        $scope.priceList = response;
        $scope.priceList.wholesalerId = $scope.currentAccess.entityId;
        $scope.priceList.wholesalerCatalogId = $stateParams.wholesalerCatalogId;
      });
    } else {
      $scope.priceList.wholesalerId = $scope.currentAccess.entityId;
      $scope.priceList.wholesalerCatalogId = $stateParams.wholesalerCatalogId;
    }
  }
  /**
   * Submit priceList.
   */


  function submit() {
    $scope.priceListId ? updatePriceList() : createPriceList();
  }
  /**
   * Create priceList.
   */


  function createPriceList() {
    wholesalerCatalogService.createPriceList($scope.priceList).then(function () {
      notifyUserService.showSuccessToast("L'offre de prix a bien été créée.");
      goToList();
    });
  }
  /**
   * Update priceList.
   */


  function updatePriceList() {
    wholesalerCatalogService.updatePriceList($scope.priceList).then(function () {
      notifyUserService.showSuccessToast("L'offre de prix a bien été mise à jour.");
      goToList();
    });
  }
  /**
   * Go to price list.
   */


  function goToList() {
    $state.go("app.wholesaler.catalogs.priceList", {
      id: $stateParams.wholesalerCatalogId
    });
  }
}

angular.module("wholesaler.catalog").controller("wholesalerCatalogPriceListEdit", WholesalerCatalogPriceListEdit);