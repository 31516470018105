"use strict";
/**
 * Wholesaler catalog edit controller.
 *
 * @param {object} $scope - Scope
 * @param {object} $state - State
 * @param {object} $stateParams - State params
 * @param {object} notifyUserService - Notify user service
 * @param {object} wholesalerCatalogService - Wholesaler catalog service
 * @param {object} environmentService - Environment service
 */

WholesalerCatalogEdit.$inject = ["$scope", "$state", "$stateParams", "notifyUserService", "wholesalerCatalogService", "environmentService"];

function WholesalerCatalogEdit($scope, $state, $stateParams, notifyUserService, wholesalerCatalogService, environmentService) {
  $scope.catalogId = $stateParams.id;
  $scope.catalog = {
    name: ""
  };
  $scope.goToList = goToList;
  $scope.createCatalog = createCatalog;
  $scope.updateCatalog = updateCatalog;
  $scope.submit = submit;
  loader();
  /**
   * Initialize
   */

  function loader() {
    environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      getCatalog();
    });
  }
  /**
   * Get the catalog
   */


  function getCatalog() {
    if ($stateParams.id) {
      var catalog = {
        wholesalerId: $scope.currentAccess.entityId,
        id: $stateParams.id
      };
      wholesalerCatalogService.readCatalog(catalog).then(function (response) {
        $scope.catalog = response;
        $scope.catalog.wholesalerId = $scope.currentAccess.entityId;
      });
    } else {
      $scope.catalog.wholesalerId = $scope.currentAccess.entityId;
    }
  }
  /**
   * Submit catalog.
   */


  function submit() {
    $scope.catalogId ? updateCatalog() : createCatalog();
  }
  /**
   * Create catalog.
   *
   * @returns {Promise} The promise
   */


  function createCatalog() {
    return wholesalerCatalogService.createCatalog($scope.catalog).then(function () {
      notifyUserService.showSuccessToast("Le catalogue a bien été créé.");
      goToList();
    });
  }
  /**
   * Update catalog.
   *
   * @returns {Promise} The promise
   */


  function updateCatalog() {
    return wholesalerCatalogService.updateCatalog($scope.catalog).then(function () {
      notifyUserService.showSuccessToast("Le catalogue a bien mis à jour.");
      goToList();
    });
  }
  /**
   * Go to catalog list.
   */


  function goToList() {
    $state.go("app.wholesaler.catalogs");
  }
}

angular.module("wholesaler.catalog").controller("wholesalerCatalogEdit", WholesalerCatalogEdit);