"use strict";
/**
 * Controller for healthcenter detail.
 *
 * @param {object} $scope - $scope.
 * @param {object} healthcenterAuditHcDetailsService - The healthcenter audit detail service.
 * @param {object} disruptionService - The disruption service.
 * @param {object} $stateParams - The $stateParams object.
 * @param {object} $state - The $state object.
 * @param {object} $window - The $window object.
 */

DetailHealthcenterOfferMonitoringController.$inject = ["$scope", "healthcenterAuditHcDetailsService", "disruptionService", "$stateParams", "$state", "$window"];

function DetailHealthcenterOfferMonitoringController($scope, healthcenterAuditHcDetailsService, disruptionService, $stateParams, $state, $window) {
  $scope.goBack = goBack;
  $scope.seeDetailOrder = seeDetailOrder;
  $scope.paginationOrder = {
    order: "startDate",
    page: 1,
    limit: 50
  };

  if ($stateParams.pharma) {
    $scope.orders = $stateParams.pharma.emergencySellinOffers;
  } else {
    $window.history.back();
  }

  $scope.isDetail = true;
  $scope.pagination = {
    limit: 10,
    page: 1,
    order: "-_source.datetime"
  };
  $scope.paginationPharmaml = {
    order: "-date",
    page: 1,
    limit: 50
  };
  loader();
  /**
   * see detail of order.
   *
   * @param {object} order - The order.
   */

  function seeDetailOrder(order) {
    $state.go("app.disruption.offersMonitoring.orderDetail", {
      id: order.id,
      order: order,
      selectedTab: $stateParams.selectedTab,
      pharma: $stateParams.pharma,
      healthcenterId: $stateParams.id
    });
  }
  /**
   * The loading function.
   */


  function loader() {
    healthcenterAuditHcDetailsService.hCDetail($stateParams.id).then(function (pharma) {
      $scope.pharma = pharma.healthcenter;
    });
    disruptionService.getPharmamlsByLabo($stateParams.id).then(function (pharmamls) {
      $scope.pharmamls = pharmamls;
    });
  }
  /**
   * Return to the offers monitoring page
   */


  function goBack() {
    $state.go("app.disruption.offersMonitoring", {
      selectedTab: $stateParams.selectedTab
    });
  }
}

angular.module("app.disruption").controller("detailHealthcenterOfferMonitoringController", DetailHealthcenterOfferMonitoringController);