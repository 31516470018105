"use strict";

/**
 * Order factory.
 *
 * @returns {object}
 */
function OrderFactory() {
  var orderFactory = {};
  orderFactory.statusMapping = {
    pending: "En attente",
    accepted: "Acceptée",
    rejected: "Refusée",
    available: "Disponible",
    delivered: "Délivrée"
  };
  orderFactory.typeMapping = {
    renewal: "Renouvellement",
    new_prescription: "Nouvelle ordonnance"
  };
  orderFactory.predefinedComments = ["Les médicaments de votre ordonnance sont prêts. Vous pouvez vous rendre à votre pharmacie " + "pour les récupérer. Pensez à votre carte vitale.", "Votre commande est prête, elle vous attend à la pharmacie. Pour gagner du temps, rendez vous en caisse “click & collect”"];
  return orderFactory;
}

angular.module("app.patients.factories").factory("orderFactory", OrderFactory);