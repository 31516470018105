"use strict";
/**
 * Statistics generic audit configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

StatisticsGenericauditConfig.$inject = ["$stateProvider"];

function StatisticsGenericauditConfig($stateProvider) {
  $stateProvider.state("app.statistics.genericaudit", {
    url: "/audit-generiques",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/genericaudit/title.html",
        controller: "StatisticsController as vm"
      },
      "statisticsContent@app.statistics.genericaudit": {
        templateUrl: "app/main/statistics/genericaudit/genericaudit.html",
        controller: "StatsGenericAuditController as vm"
      },
      "tabContent@app.statistics.genericaudit": {
        templateUrl: "app/main/statistics/genericaudit/catalog/catalog.html",
        controller: "StatisticsGenericCatalogController as vm"
      }
    },
    bodyClass: "dashboard"
  }).state("app.statistics.genericaudit.potential", {
    url: "/potentiel",
    views: {
      "tabContent@app.statistics.genericaudit": {
        templateUrl: "app/main/statistics/genericaudit/potential/potential.html",
        controller: "StatisticsGenericPotentialController as vm"
      }
    }
  }).state("app.statistics.genericaudit.ideal", {
    url: "/ideal",
    views: {
      "tabContent@app.statistics.genericaudit": {
        templateUrl: "app/main/statistics/genericaudit/ideal/ideal.html",
        controller: "StatisticsGenericIdealController as vm"
      }
    }
  }).state("app.statistics.genericaudit.substitution", {
    url: "/taux-de-substitution",
    views: {
      "tabContent@app.statistics.genericaudit": {
        templateUrl: "app/main/statistics/genericaudit/substitution/substitution.html",
        controller: "StatisticsGenericSubstitutionController as vm"
      }
    }
  });
}

angular.module("app.statistics.genericaudit", []).config(StatisticsGenericauditConfig);