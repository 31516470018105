"use strict";
/**
 * Controller to show challenge sellout report.
 *
 * @param {object} $scope - Controller scope.
 * @param {object} $state - Module state.
 * @param {object} $stateParams - State parameters.
 * @param {object} $mdDialog - Material design dialog.
 * @param {string} apiurl - API base url.
 * @param {object} sessionStorageService - Session storage service.
 * @param {object} selloutFactory - Sellout Factory.
 * @param {object} selloutReportService - Sellout report service.
 * @param {object} segmentService - Segment service.
 */

ChallengeSelloutReportController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "apiurl", "sessionStorageService", "selloutFactory", "selloutReportService", "segmentService"];

function ChallengeSelloutReportController($scope, $state, $stateParams, $mdDialog, apiurl, sessionStorageService, selloutFactory, selloutReportService, segmentService) {
  $scope.goToList = goToList;
  $scope.salesOnePharmacyForThisSellout = salesOnePharmacyForThisSellout;
  $scope.salesAllPharmaciesForThisSellout = salesAllPharmaciesForThisSellout;
  $scope.graphData = [];
  $scope.graphDetails = [];
  $scope.apiurl = apiurl;
  $scope.token = sessionStorageService.getToken();
  $scope.openPointsDetail = openPointsDetail;
  $scope.tableConfig = {
    page: 1,
    limit: 15
  };
  $scope.getPendingParticipants = getPendingParticipants;
  $scope.getAcceptedParticipants = getAcceptedParticipants;
  $scope.getRefusedParticipants = getRefusedParticipants;
  /** Loader. */

  (function loader() {
    readSellout().then(countParticipants).then(getAcceptedParticipants).then(getRefusedParticipants).then(getPendingParticipants).then(readChartData).then(makeChart);
  })();
  /**
   * Read sellout.
   *
   * @returns {Promise} Sellout promise.
   */


  function readSellout() {
    return selloutReportService.readSellout({
      id: $stateParams.id
    }).then(function (sellout) {
      $scope.unitTeasers = formatUnitRewards(sellout.unitRewards);
      $scope.objectiveTeasers = formatObjectiveRewards(sellout.objectiveRewards);
      $scope.sellout = sellout;
    });
  }
  /**
   * Count participants.
   *
   * @returns {Promise} Participants count promise.
   */


  function countParticipants() {
    return selloutReportService.countParticipants({
      id: $stateParams.id
    }).then(function (count) {
      $scope.count = count;
    });
  }
  /**
   * Get pending participants.
   *
   * @param {number} page - The number of the page.
   * @param {number} limit - The number of results.
   *
   * @returns {Promise} Pending participants promise.
   */


  function getPendingParticipants(page, limit) {
    return selloutReportService.getPendingParticipants({
      id: $stateParams.id,
      page: page || $scope.tableConfig.page,
      limit: limit || $scope.tableConfig.limit
    }).then(function (pendingParticipants) {
      $scope.pendingParticipants = pendingParticipants;
    });
  }
  /**
   * Get accepted participants.
   *
   * @param {number} page - The number of the page.
   * @param {number} limit - The number of results.
   *
   * @returns {Promise}
   */


  function getAcceptedParticipants(page, limit) {
    return selloutReportService.getAcceptedParticipants({
      id: $stateParams.id,
      page: page || $scope.tableConfig.page,
      limit: limit || $scope.tableConfig.limit
    }).then(function (acceptedParticipants) {
      $scope.acceptedParticipants = acceptedParticipants;
      $scope.acceptedParticipants.data = $scope.acceptedParticipants.data.map(function (participant) {
        if (participant.distribution && participant.distribution.slug === "individual") {
          participant.performance.gain.point.given = 0;
          participant.performance.children.forEach(function (child) {
            participant.performance.gain.point.given += child.gain.point.given;
          });
        }

        return participant;
      });
    });
  }
  /**
   * Get refused participants.
   *
   * @param {number} page - The number of the page.
   * @param {number} limit - The number of results.
   *
   * @returns {Promise} Participants who refused the sellout promise.
   */


  function getRefusedParticipants(page, limit) {
    return selloutReportService.getRefusedParticipants({
      id: $stateParams.id,
      page: page || $scope.tableConfig.page,
      limit: limit || $scope.tableConfig.limit
    }).then(function (refusedParticipants) {
      $scope.refusedParticipants = refusedParticipants;
    });
  }
  /**
   * Format unit rewards.
   *
   * @param {Array} unitRewards - The unit rewards to format.
   *
   * @returns {Array} Formated rewards.
   */


  function formatUnitRewards(unitRewards) {
    var formatedRewards = [];
    angular.forEach(unitRewards, function (reward) {
      formatedRewards.push(selloutFactory.generateUnitTeaser(reward.gain, reward.remuneration));
    });
    return formatedRewards;
  }
  /**
   * Format objective rewards.
   *
   * @param {Array} objectiveRewards - The objective rewards to format.
   *
   * @returns {Array} Formated rewards
   */


  function formatObjectiveRewards(objectiveRewards) {
    var formatedRewards = [];
    angular.forEach(objectiveRewards, function (reward) {
      formatedRewards.push(selloutFactory.generateObjectiveTeaser(reward.gain, reward.remuneration, reward.goal, reward.objective, reward.entity, reward.startDate, reward.endDate));
    });
    return formatedRewards;
  }
  /** Go to list. */


  function goToList() {
    $state.go("app.sellout");
  }
  /**
   * Go to a pharmacy detail.
   *
   * @param {object} pharmacy - The pharmacy object.
   */


  function salesOnePharmacyForThisSellout(pharmacy) {
    segmentService.readBySellout({
      selloutId: $stateParams.id,
      statusId: 1
    }).then(function (response) {
      var startDate = moment($scope.sellout.startDate).format("DD/MM/YYYY");
      var endDate = moment($scope.sellout.endDate).format("DD/MM/YYYY");
      var searchParameters = {
        startDate: startDate,
        endDate: endDate,
        pharmas: [{
          id: pharmacy.id,
          name: pharmacy.name,
          cip: pharmacy.organization.morphable.cip
        }]
      }; // If a segment product is found

      if (response.segmentProduct) {
        searchParameters.segment = [{
          segmentTypeId: response.segmentProduct.segmentTypeId,
          id: response.segmentProduct.id,
          name: response.segmentProduct.name
        }];
      } else {
        // Otherwise, we set the products as parameters
        searchParameters.products = $scope.sellout.products;
      } // Go to sales analysis for this sellout and this pharmacy


      $state.go("app.statistics.salesAnalysis.sandBoxLineAtcl", searchParameters);
    });
  }
  /**
   * Go to all pharmacies detail.
   *
   * @param {number} status - Status id.
   */


  function salesAllPharmaciesForThisSellout(status) {
    segmentService.readBySellout({
      selloutId: $stateParams.id,
      statusId: status
    }).then(function (response) {
      var startDate = moment($scope.sellout.startDate).format("DD/MM/YYYY");
      var endDate = moment($scope.sellout.endDate).format("DD/MM/YYYY");
      var searchParameters = {
        startDate: startDate,
        endDate: endDate
      }; // Initialization of the segment array if needed

      if (response.segmentProduct || response.segmentHealthcenter) {
        searchParameters.segment = [];
      } // If a segment product is found


      if (response.segmentProduct) {
        searchParameters.segment.push({
          segmentTypeId: response.segmentProduct.segment_type_id,
          id: response.segmentProduct.id,
          name: response.segmentProduct.name
        });
      } else {
        // Otherwise, we set the products as parameters
        searchParameters.products = $scope.sellout.products;
      } // If a segment healthcenter is found


      if (response.segmentHealthcenter) {
        searchParameters.segment.push({
          segmentTypeId: response.segmentHealthcenter.segment_type_id,
          id: response.segmentHealthcenter.id,
          name: response.segmentHealthcenter.name
        });
      } else {
        var pharmas = [];
        var userSellout = $scope.tableAccepted;

        if (status === 2) {
          userSellout = $scope.tableRefused;
        }

        angular.forEach(userSellout, function (pharma) {
          pharmas.push({
            id: pharma.id,
            name: pharma.name,
            cip: pharma.morphable.cip
          });
        });
        searchParameters.pharmas = pharmas;
      } // Go to sales analysis for this sellout and this pharmacy


      $state.go("app.statistics.salesAnalysis.sandBoxLineAtcl", searchParameters);
    });
  }
  /**
   * Open points detail.
   *
   * @param {Array} operators - The operators list.
   */


  function openPointsDetail(operators) {
    $mdDialog.show({
      controller: ["$scope", function controller($scope) {
        $scope.operators = operators;

        $scope.close = function () {
          $mdDialog.cancel();
        };
      }],
      templateUrl: "app/main/sellout/report/detail/operator.html"
    });
  }
  /**
   * Read chart data.
   *
   * @returns {Promise} Chart data promise.
   */


  function readChartData() {
    return selloutReportService.readChart({
      id: $stateParams.id
    }).then(function (chartData) {
      $scope.chartData = chartData;
      return chartData;
    });
  }
  /**
   * Make chart.
   *
   * @param {object} chartData - Chart data.
   */


  function makeChart(chartData) {
    $scope.chart = {
      type: "serial",
      legend: {
        enabled: true,
        useGraphSettings: true
      },
      theme: "none",
      marginLeft: 60,
      autoMarginOffset: 20,
      mouseWheelZoomEnabled: true,
      dataDateFormat: "YYYY-MM-DD",
      valueAxes: [{
        id: "v1",
        axisAlpha: 0,
        position: "left",
        ignoreAxisWidth: true,
        title: "Unité(s) vendue(s)"
      }],
      balloon: {
        borderThickness: 1,
        shadowAlpha: 0
      },
      graphs: [{
        type: "smoothedLine",
        balloon: {
          drop: true,
          adjustBorderColor: false,
          color: "#ffffff"
        },
        bullet: "round",
        bulletBorderAlpha: 1,
        bulletColor: "#FFFFFF",
        bulletSize: 5,
        hideBulletsCount: 50,
        lineThickness: 2,
        useLineColorForBulletBorder: true,
        valueField: "previous",
        fillAlphas: 0.1,
        title: "Ventes sur l’année précédente",
        balloonText: "[[value]] unité(s) vendue(s)"
      }, {
        type: "smoothedLine",
        balloon: {
          drop: true,
          adjustBorderColor: false,
          color: "#ffffff"
        },
        bullet: "round",
        bulletBorderAlpha: 1,
        bulletColor: "#FFFFFF",
        bulletSize: 5,
        hideBulletsCount: 50,
        lineThickness: 2,
        useLineColorForBulletBorder: true,
        valueField: "current",
        fillAlphas: 0.1,
        title: "Ventes sur l’année courante",
        balloonText: "[[value]] unité(s) vendue(s)"
      }],
      categoryField: "date",
      categoryAxis: {
        parseDates: true,
        dashLength: 1,
        minorGridEnabled: true
      },
      dataProvider: chartData
    };
  }
}

angular.module("app.sellout").controller("ChallengeSelloutReportController", ChallengeSelloutReportController);