"use strict";
/**
 * Documentation overall controller.
 *
 * @param {object} $scope - $scope.
 */

DocumentationOverallController.$inject = ["$scope"];

function DocumentationOverallController($scope) {
  $scope.value = 15;
  $scope.valueNeg = -15;
}

angular.module("app.documentation").controller("documentationOverallController", DocumentationOverallController);