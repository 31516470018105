"use strict";
/**
 * Generic service.
 *
 * @param {object} apiClient - The api client.
 * @returns {object}
 */

SubstitutionPageService.$inject = ["apiClient"];

function SubstitutionPageService(apiClient) {
  return {
    /**
     * Get.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/analysis/substitution/global").then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics.genericaudit").service("substitutionPageService", SubstitutionPageService);