"use strict";
/**
 * Segment products controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} segmentService - Segment service.
 * @param {object} productService - Product service.
 * @param {object} environmentService - Environment service.
 * @param {object} $state - $state.
 * @param {object} notifyUserService - Notify user service.
 */

SegmentProductsController.$inject = ["$scope", "$stateParams", "segmentService", "productService", "environmentService", "$state", "notifyUserService"];

function SegmentProductsController($scope, $stateParams, segmentService, productService, environmentService, $state, notifyUserService) {
  $scope.segmentId = $stateParams.id;
  $scope.segment = {};
  var productIds = [];
  $scope.onPaginationChange = onPaginationChange;
  $scope.searchProducts = searchProducts;
  $scope.deleteProductFromSegment = deleteProductFromSegment;
  $scope.createSegment = createSegment;
  $scope.submit = submit;
  $scope.query = {
    productOrder: "product.name",
    limit: 10,
    page: 1,
    total: null,
    search: ""
  };
  $scope.loaderSegmentsDetailOptions = {
    loading: true,
    loader: "Récupération du segment...",
    empty: "Aucune donnée n'est disponible"
  };
  $scope.segmentProductSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "category.level4.id"],
    event: "addProductToSegmentFromSearch",
    tag: "addProductToSegmentFromSearch",
    submitLabel: "Choisir ces produits",
    searchPlaceHolder: "Rechercher les produits à ajouter au segment"
  };
  $scope.importProductConfig = {
    entityName: "SegmentProduct",
    callbackEvent: "addProductToSegmentFromImport"
  };

  $scope.goToList = function () {
    $state.go("app.segments", {
      type: 2
    });
  };

  $scope.limitOptions = [5, 10, 15];
  /**
   * Prepare healthcenters
   *
   * @param {object} products - Products.
   * @param {string} keyId - Key ID.
   *
   * @returns {Promise}
   */

  function prepareProducts(products, keyId) {
    var nextProductsId = products.map(function (product) {
      return parseInt(product[keyId]);
    });

    if (!$scope.segmentId) {
      productIds = productIds.concat(nextProductsId);
      return getProductInformation(productIds);
    }

    var params = {
      id: $scope.segment.id,
      productIds: nextProductsId
    };
    return segmentService.addProductsToSegment(params).then(getSegmentProducts);
  }
  /**
   * On healthcenter search validation, get healthcenters information.
   */


  $scope.$on("addProductToSegmentFromSearch", function (event, query) {
    var queryProduct = {};
    queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(query, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(query[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in query) {
      _loop(type);
    } // get healthcenter information with query results


    getProductInformationByType(queryProduct).then(function (products) {
      return prepareProducts(products, "id");
    });
  });
  /**
   * On healthcenter import validation, get healthcenters information.
   */

  $scope.$on("addProductToSegmentFromImport", function (event, products) {
    prepareProducts(products, Object.keys(products[0])[0]);
  });
  /**
   * On pagination change.
   */

  function onPaginationChange() {
    if ($scope.segmentId) {
      getSegmentProducts();
    } else {
      getProductInformation(productIds);
    }
  }
  /**
   *
   * Initialization of the laoder.
   */


  loader();
  /**
   *
   * loader to load each method inside.
   */

  function loader() {
    getCurrentAccess();

    if ($scope.segmentId) {
      getSegment().then(getSegmentProducts);
    } else {
      $scope.segment.segmentTypeId = 2;
    }
  }
  /**
   * Get the selected segment.
   *
   * @returns {Promise}
   */


  function getSegment() {
    if ($scope.segmentId) {
      $scope.loaderSegmentsDetailOptions.loading = true;
      var param = {
        id: $scope.segmentId
      };
      return segmentService.read(param).then(function (response) {
        $scope.segment = response;
        $scope.loaderSegmentsDetailOptions.loading = false;
      });
    }

    return Promise.reject();
  }
  /**
   * Gets the current access.
   */


  function getCurrentAccess() {
    environmentService.getEnvironment().then(function (access) {
      $scope.entityId = access.entity.id;
      $scope.accessPointType = access.accessPointType;

      if ($scope.accessPointType === 2) {
        $scope.segment.segmentTypeId = 2;
      }
    });
  }
  /**
   * Get products from the segment.
   */


  function getSegmentProducts() {
    $scope.loaderSegmentsDetailOptions.loading = true;

    if ($scope.segment.id && $scope.segment.segmentTypeId === 2) {
      $scope.loaderSegmentsDetailOptions.loading = true;
      var params = {
        id: $scope.segmentId,
        page: $scope.query.page,
        limit: $scope.query.limit,
        search: $scope.query.search
      };
      segmentService.getProducts(params).then(function (response) {
        $scope.segment.products = response.data;
        $scope.query.total = response.meta.pagination.total;
        $scope.loaderSegmentsDetailOptions.loading = false;
      });
    }
  }
  /**
   *  Create the segment.
   *
   * @param {object} segment - Segment.
   *
   * @returns {Promise}
   */


  function createSegment(segment) {
    return segmentService.create(segment).then(function () {
      notifyUserService.showSuccessToast("Le segment a bien été créé.");
      $scope.goToList();
    }, function () {
      notifyUserService.showDangerToast("Erreur lors de la création du segment, veuillez réessayer.");
    });
  }
  /**
   * Submit segment form
   */


  function submit() {
    var data = formatData();
    $scope.isSaving = true;
    $scope.createSegment(data).then(function () {
      $scope.isSaving = false;
    });
  }
  /**
   * Format data before saving
   *
   * @returns {object}
   */


  function formatData() {
    return {
      name: $scope.segment.name,
      segment_type_id: $scope.segment.segmentTypeId,
      entity_id: $scope.entityId,
      access_point_type: $scope.accessPointType,
      products: productIds
    };
  }
  /**
   * Delete the product from segment.
   *
   * @param {event} event - The event.
   * @param {object} product - The product.
   */


  function deleteProductFromSegment(event, product) {
    // Delete
    if (!$scope.segmentId) {
      productIds.splice(productIds.indexOf(product.id), 1);
      $scope.query.search = "";
      getProductInformation(productIds);
    } else {
      segmentService.productDelete({
        segmentId: $scope.segmentId,
        productId: product.id
      }).then(function () {
        getSegmentProducts();
      });
    }
  }
  /**
   * Search products in the segment.
   *
   * @returns {Promise}
   */


  function searchProducts() {
    return $scope.segmentId ? getSegmentProducts() : $scope.query.search.length ? getProductInformation([]) : getProductInformation(productIds);
  }
  /**
   * Get product information from ElasticSearch.
   *
   * @param {object} queryProduct - Query for ElasticSearch.
   * @returns {Promise} List of products.
   */


  function getProductInformationByType(queryProduct) {
    return productService.postPresentationByType(queryProduct).then(function (products) {
      if (!$scope.segment.products) {
        $scope.segment.products = [];
      } // Store in array the product list to make it easier to test


      var productArray = [];
      $scope.segment.products.map(function (item) {
        return productArray.push(item.product.id);
      }); // generating the same object than the database

      angular.forEach(products, function (productItem) {
        // If the product is not attached to the segment yet
        if (productItem && productArray.indexOf(productItem.id) === -1) {
          $scope.segment.products.push({
            product: {
              id: productItem.id,
              name: productItem.name,
              code: getCodeAttribute(productItem),
              laboratory: {
                name: productItem.laboratory
              }
            }
          });
        }
      });
      return products;
    });
  }
  /**
   * Get code attribute.
   *
   * @param {object} product - The product.
   * @returns {string|null}
   */


  function getCodeAttribute(product) {
    var code = null;

    if (product.id_13) {
      code = product.id_13;
    } else if (product.ean_13) {
      code = product.ean_13;
    } else if (product.id_7) {
      code = product.id_7;
    } else if (product.cip) {
      code = product.cip;
    }

    return code;
  }
  /**
   * Get product information.
   *
   * @param {Array} ids - Product Ids
   * @returns {Promise} Product information
   */


  function getProductInformation(ids) {
    if (!$scope.segment.products) {
      $scope.segment.products = [];
    }

    $scope.loaderSegmentsDetailOptions.loading = true;
    var finalIds = ids.slice(($scope.query.page - 1) * $scope.query.limit, $scope.query.page * $scope.query.limit);
    var params = {
      ids: finalIds,
      search: $scope.query.search
    };
    return productService.getPresentationById(params).then(function (response) {
      $scope.segment.products = [];
      $scope.query.total = productIds.length; // generating the same object than the database

      angular.forEach(response, function (productItem) {
        if (productIds.indexOf(productItem.id) !== -1) {
          $scope.segment.products.push({
            product: {
              id: productItem.id,
              name: productItem.name,
              code: getCodeAttribute(productItem),
              laboratory: productItem.laboratory
            }
          });
        }
      });
      $scope.loaderSegmentsDetailOptions.loading = false;
      return response;
    });
  }
}

angular.module("app.segment").controller("segmentProductsController", SegmentProductsController);