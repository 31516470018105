"use strict";
/**
 * Markdown directive.
 *
 * @returns {object}
 */

function MarkdownDirective() {
  return {
    restrict: "A",
    link: MarkdownLink
  };
}
/**
 *  Markdown link.
 *
 * @param {object} scope - The angular scope object.
 * @param {object} element - The HTML element.
 * @param {object} attrs - The attributes.
 */


function MarkdownLink(scope, element, attrs) {
  /**
   * Parse the markdown.
   **/
  function renderMarkdown() {
    var h1 = /# (.*$)/gim;
    var h2 = /## (.*$)/gim;
    var h3 = /### (.*$)/gim;
    var h4 = /#### (.*$)/gim;
    var bold = /\*\*(\S(.*?\S)?)\*\*/gm;
    var ul = /\n\s?\*\s*(.*)/g;
    var image = /!\[(.*?)\]\((.*?)\)/gim;
    var link = /\[(.*?)\]\((.*?)\)/gim;
    var lineBreak = /\n$/gim;
    var htmlText = scope.$eval(attrs.markdown).replace(h4, "<h4>$1</h4>").replace(h3, "<h3>$1</h3>").replace(h2, "<h2>$1</h2>").replace(h1, "<h1>$1</h1>").replace(bold, "<strong>$1</strong>").replace(ul, "<ul><li>$1</li></ul>").replace(lineBreak, "<br />").replace(image, "<img alt='$1' src='$2' />").replace(link, "<a href='$2'>$1</a>");
    element.html(htmlText);
  }

  renderMarkdown();
}

angular.module("app.patients.directives").directive("markdown", MarkdownDirective);