"use strict";
/**
 * Laboratory branding edit universe controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $q - $q.
 * @param {object} sessionStorageService - Session storage service.
 * @param {string} apiurl - API URL.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} environmentService - Environment service.
 * @param {object} laboratoryRangeService - Laboratory range service.
 * @param {object} productService - Product service.
 */

AddRangeController.$inject = ["$scope", "$mdDialog", "range", "currentAccess", "laboratoryRangeService"];
LaboratoryBrandingEditUniversController.$inject = ["$rootScope", "$scope", "$stateParams", "$state", "$mdDialog", "$q", "sessionStorageService", "apiurl", "notifyUserService", "environmentService", "laboratoryRangeService", "productService"];

function LaboratoryBrandingEditUniversController($rootScope, $scope, $stateParams, $state, $mdDialog, $q, sessionStorageService, apiurl, notifyUserService, environmentService, laboratoryRangeService, productService) {
  /**
   *
   * Declaration of public methods
   */
  $scope.openTab = openTab;
  $scope.addRange = addRange;
  $scope.goToBranding = goToBranding;
  $scope.goToRange = goToRange;
  $scope.updateRange = updateRange;
  $scope.deleteRange = deleteRange;
  $scope.addSlide = addSlide;
  $scope.updateSlide = updateSlide;
  $scope.addSlideImage = addSlideImage;
  $scope.addLogoImage = addLogoImage;
  $scope.addBackgroundImage = addBackgroundImage;
  $scope.addBackgroundMobileImage = addBackgroundMobileImage;
  $scope.deleteSlide = deleteSlide;
  $scope.editProduct = editProduct;
  $scope.deleteProduct = deleteProduct;
  $scope.save = save;
  $scope.deleteLogo = deleteLogo;
  $scope.deleteBackground = deleteBackground;
  $scope.deleteBackgroundMobile = deleteBackgroundMobile;
  /**
   *
   * Declaration of public objects
   */

  $scope.tabs = [{
    id: 1,
    name: "Charte graphique",
    slug: "graphical-charter",
    active: true,
    isActive: true
  }, {
    id: 2,
    name: "Gestion du carrousel",
    slug: "slideshow",
    active: false,
    isActive: true
  }, {
    id: 3,
    name: "Gestion des gammes",
    slug: "ranges",
    active: false,
    isActive: true
  }];
  $scope.rangesOptions = {
    loading: true,
    loader: "Récupération des gammes de l'univers"
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.query = {
    order: "-startDate",
    limit: 10,
    page: 1
  };
  $scope.brandingOptions = {
    loading: true,
    loader: "Récupération de la charte graphique"
  };
  $scope.productsOptions = {
    loading: true,
    loader: "Récupération des produits"
  };
  $scope.optionsPickerBg = {
    type: 2,
    history: false,
    genericPalette: false,
    palette: false
  };
  $scope.token = sessionStorageService.getToken();
  $scope.apiurl = apiurl;
  /**
   *
   * Initialization of the loader
   */

  loader();
  /**
   *
   * Load each method inside
   *
   * @function loader
   */

  function loader() {
    loadTabs();
    loaderEnvironment().then(function () {
      loaderRange();
    });
  }
  /**
   *
   * LoadTabs
   * Init first tab and hide others
   *
   * @function loadTabs
   */


  function loadTabs() {
    var mainTab = angular.element(document).find("#graphical-charter")[0];
    /**
     *
     * To display block the first tab
     */

    $scope.tabs.forEach(function (tab) {
      mainTab.style.display = "block";

      if (tab.slug === mainTab.id && tab.method) {
        tab.active = true;
        tab.method();
      }
    });
  }
  /**
   *
   * Load current environment
   * Set $scope.currentAccess
   *
   * @function loaderEnvironment
   * @returns {Promise}
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      $scope.config = {
        must: {
          "laboratory.id": [response.entity.id]
        },
        search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
        event: "addProductUnivers",
        tag: "productUnivers",
        autoLaunchEvent: false
      };
      return response;
    });
  }
  /**
   *
   * Load current range
   * Cast event $broadcast -> "slideshow:refresh" with "response.sliders"
   *
   * @function loaderRange
   * @returns {Promise}
   */


  function loaderRange() {
    var query = {
      id: $stateParams.universId,
      laboratoryId: $scope.currentAccess.entityId
    };
    return laboratoryRangeService.read(query).then(function (response) {
      $scope.range = response.range;
      $scope.branding = response.range.branding;

      if ($scope.branding) {
        $rootScope.$broadcast("slideshow:refresh", response.range.branding.sliders);
      }

      $scope.ranges = response.range.childrenRanges;
      $scope.brandingOptions.loading = false;

      if ($scope.range.products.length <= 0) {
        $scope.productsOptions = {
          loading: false,
          empty: "Aucun produit"
        };
      }

      if ($scope.ranges.length > 0) {
        $scope.rangesOptions.loading = false;
        $scope.productsOptions.loading = false;
      } else {
        $scope.rangesOptions = {
          loading: false,
          empty: "Aucune gamme"
        };
      }

      return response;
    });
  }
  /**
   * Open modal create univers
   *
   * @param {object} range - Range.
   */


  function addRange(range) {
    var prompt = $mdDialog.prompt({
      controller: "addRangeController",
      templateUrl: "app/main/laboratory/branding/views/includes/modals/addRange.html",
      parent: angular.element(document.body),
      locals: {
        range: range,
        currentAccess: $scope.currentAccess
      }
    });
    $mdDialog.show(prompt).then(function (response) {
      if (response) {
        loaderRange(response);
        notifyUserService.showSuccessToast("La gamme « " + response.name + " » a été ajoutée.");
      }
    });
  }
  /**
   *
   * Update range
   *
   * @function updateRange
   * @returns {Promise}
   */


  function updateRange() {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      id: $scope.range.id,
      range: $scope.range
    };
    return laboratoryRangeService.update(query).then(function (response) {
      $scope.range = response.range;
      $scope.branding = response.range.branding;
      $scope.ranges = response.range.childrenRanges;
      notifyUserService.showSuccessToast("Vitrine mise à jour");
      return response;
    });
  }
  /**
   *
   * Update branding
   *
   * @function updateBranding
   * @returns {Promise}
   */


  function updateBranding() {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      rangeId: $scope.range.id,
      brandingId: $scope.branding.id,
      branding: $scope.branding
    };
    return laboratoryRangeService.updateBranding(query).then(function (response) {
      $scope.branding = response;
      notifyUserService.showSuccessToast("La charte graphique de l'univers a été mise à jour.");
      return response;
    });
  }
  /**
   *
   * Delete target range
   *
   * @param {object} range -
   */


  function deleteRange(range) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression définitive de la gamme « " + range.name + " » ?").ariaLabel("Suppression de la gamme").ok("Oui").cancel("Non")).then(function () {
      var query = {
        id: range.id,
        laboratoryId: $scope.currentAccess.entityId
      };
      $scope.ranges.splice($scope.ranges.findIndex(function (item) {
        return item.id === range.id;
      }), 1);
      return laboratoryRangeService["delete"](query).then(function () {
        loaderRange();
        notifyUserService.showSuccessToast("La gamme « " + range.name + " » a été supprimée.");
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Add logo image
   *
   * @param {object} file - File.
   */


  function addLogoImage(file) {
    if (!_.isUndefined(file) && !_.isNull(file)) {
      $scope.branding.logoId = file.id;
      updateBranding();
    }
  }
  /**
   * Add background image
   *
   * @param {object} file - File.
   */


  function addBackgroundImage(file) {
    if (!_.isUndefined(file) && !_.isNull(file)) {
      $scope.branding.backgroundId = file.id;
      updateBranding();
    }
  }
  /**
   * Add background mobile image
   *
   * @param {object} file -
   */


  function addBackgroundMobileImage(file) {
    if (!_.isUndefined(file) && !_.isNull(file)) {
      $scope.branding.backgroundMobileId = file.id;
      save();
    }
  }
  /**
   * Add slide
   *
   * @returns {Promise}
   */


  function addSlide() {
    var slide = {
      legend: "",
      position: $scope.branding.sliders ? $scope.branding.sliders.length + 1 : 0
    };
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      rangeId: $scope.range.id,
      slide: slide
    };
    return laboratoryRangeService.createSlider(query).then(function (response) {
      loaderRange();
      notifyUserService.showSuccessToast("Slide ajouté");
      return response;
    });
  }
  /**
   * Add file "image" to target slide
   *
   * @param {object} file - File.
   * @param {object} slide - Slide.
   */


  function addSlideImage(file, slide) {
    if (!_.isUndefined(file) && !_.isNull(file) && !_.isUndefined(slide) && !_.isNull(slide)) {
      slide.fileId = file.id;
      updateSlide(slide);
    }
  }
  /**
   * Update target slide
   *
   * @param {object} slide - Slide.
   *
   * @returns {Promise}
   */


  function updateSlide(slide) {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      rangeId: $scope.range.id,
      id: slide.id,
      slide: slide
    };
    return laboratoryRangeService.updateSlider(query).then(function (response) {
      loaderRange();
      notifyUserService.showSuccessToast("Slide mis à jour");
      return response;
    });
  }
  /**
   * Delete target slide
   * Splice $scope.branding.sliders
   *
   * @param {object} slide - Slide.
   * @param {number} index - Index.
   */


  function deleteSlide(slide, index) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression définitive de la page du carrousel ?").ariaLabel("Suppression de la page du carrousel").ok("Oui").cancel("Non")).then(function () {
      var query = {
        laboratoryId: $scope.currentAccess.entityId,
        rangeId: $scope.range.id,
        id: slide.id
      };
      $scope.branding.sliders.splice(index, 1);
      return laboratoryRangeService.deleteSlider(query).then(function () {
        notifyUserService.showSuccessToast("Slide supprimé");
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Add product to range
   *
   * @param {object} productIds - Product IDs.
   *
   * @returns {Promise}
   */


  function addProduct(productIds) {
    var query = {
      id: $stateParams.universId,
      laboratoryId: $scope.currentAccess.entityId,
      productIds: productIds
    };
    return laboratoryRangeService.assignProduct(query).then(function (response) {
      loaderRange();
      notifyUserService.showSuccessToast("Produit ajouté");
      return response;
    });
  }
  /**
   * Go to "app.laboratory-products.edit"
   *
   * @param {object} product - Product.
   */


  function editProduct(product) {
    $state.go("app.laboratory-products.edit", {
      id: product.id
    });
  }
  /**
   * Delete target slide
   *
   * @param {object} event - Event.
   * @param {object} product - Product.
   * @param {number} index - Index.
   */


  function deleteProduct(event, product, index) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression de l\"association du produit ?").ariaLabel("Suppression du produit de l\"univers").ok("Oui").cancel("Non")).then(function () {
      var query = {
        id: $stateParams.universId,
        laboratoryId: $scope.currentAccess.entityId,
        product: product
      };
      $scope.range.products.splice(index, 1);
      return laboratoryRangeService.unassignProduct(query).then(function (response) {
        loaderRange();
        notifyUserService.showSuccessToast("Produit supprimé");
        return response;
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   *
   * Save $scope.branding
   * Cast event $broadcast -> "slideshow:refresh" with "response.sliders"
   *
   * @function save
   * @returns {Promise}
   */


  function save() {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      rangeId: $scope.range.id,
      brandingId: $scope.branding.id,
      branding: $scope.branding
    };
    /**
     * Update range branding
     *
     * @function updateBranding
     * @returns {Promise}
     */

    function updateBranding() {
      return laboratoryRangeService.updateBranding(query).then(function (response) {
        $scope.branding = response;
        notifyUserService.showSuccessToast("La charte graphique de la vitrine a été mise à jour.");
        $rootScope.$broadcast("slideshow:refresh", response.sliders);
        return response;
      });
    }

    return $q.all([updateBranding(), updateRange()]);
  }
  /**
   *
   * Open target tab
   *
   * @param {object} myTab -
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";

        if (tab.method) {
          tab.method();
        }
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Go to "app.laboratory-branding.univers.range"
   *
   * @param {object} range - Range.
   */


  function goToRange(range) {
    $state.go("app.laboratory-branding.univers.range", {
      universId: $scope.range.id,
      rangeId: range.id,
      univers: $scope.range,
      range: range
    });
  }
  /**
   *
   * Go to "app.laboratory-branding"
   *
   * @function goToBranding
   */


  function goToBranding() {
    $state.go("app.laboratory-branding");
  }
  /**
   *
   * Delete branding logo
   *
   * @function deleteLogo
   * @returns {Promise}
   */


  function deleteLogo() {
    $scope.branding.logoId = null;
    notifyUserService.showSuccessToast("Le logo a bien été supprimé.");
    return save();
  }
  /**
   *
   * Delete branding background
   *
   * @function deleteBackground
   * @returns {Promise}
   */


  function deleteBackground() {
    $scope.branding.backgroundId = null;
    notifyUserService.showSuccessToast("Le fond de page a bien été supprimé.");
    return save();
  }
  /**
   *
   * Delete branding background mobile.
   *
   * @function deleteBackgroundMobile
   * @returns {Promise}
   */


  function deleteBackgroundMobile() {
    $scope.branding.backgroundMobileId = null;
    notifyUserService.showSuccessToast("Le fond de page mobile a bien été supprimé.");
    return save();
  }

  $scope.$on("addProductUnivers", function (event, args) {
    event.preventDefault();
    var productIds = [];
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(args, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        args[type].forEach(function (myProduct) {
          var hasProduct = _.find($scope.range.products, function (product) {
            return product.id === myProduct.id;
          });

          if (!hasProduct) {
            queryProduct[type].push(myProduct.id);
          }
        });
      }
    };

    for (var type in args) {
      _loop(type);
    } // getProducts with query results


    productService.postPresentationByType(queryProduct).then(function (products) {
      products.forEach(function (product) {
        var hasProduct = _.find($scope.range.products, function (prod) {
          return prod.id === product.id;
        });

        if (!hasProduct) {
          productIds.push(product.id);
        }
      });
      return productIds;
    }).then(function (myProductsId) {
      if (myProductsId.length) {
        addProduct(myProductsId);
      }
    });
  });
}
/**
 * Add range controller.
 *
 * @param {object} $scope - Scope
 * @param {object} $mdDialog - MD Dialog
 * @param {object} range - Range
 * @param {object} currentAccess - Current access
 * @param {object} laboratoryRangeService - Laboratory range service
 */


function AddRangeController($scope, $mdDialog, range, currentAccess, laboratoryRangeService) {
  $scope.close = close;
  $scope.save = save;
  $scope.laboratoryRange = {
    name: "",
    description: ""
  };
  /**
   * Valid the form.
   *
   * @returns {boolean} Valid or not
   */

  $scope.validForm = function () {
    return $scope.laboratoryRange.name.length;
  };
  /**
   * Close modal.
   */


  function close() {
    $mdDialog.hide();
  }
  /**
   * Save range.
   */


  function save() {
    $scope.laboratoryRange.laboratoryId = currentAccess.entityId;
    $scope.laboratoryRange.parent_range_id = range.id;
    laboratoryRangeService.create($scope.laboratoryRange).then(function (response) {
      $mdDialog.hide(response);
    });
  }
}

angular.module("app.laboratory").controller("laboratoryBrandingEditUniversController", LaboratoryBrandingEditUniversController).controller("addRangeController", AddRangeController);