"use strict";

ApodisConfig.$inject = ["$httpProvider", "$mdThemingProvider", "$mdDateLocaleProvider"];
angular.module("apodis").config(ApodisConfig);
/** @ngInject */

function ApodisConfig($httpProvider, $mdThemingProvider, $mdDateLocaleProvider) {
  moment.locale("fr");
  $httpProvider.interceptors.push("authInterceptor");
  $mdThemingProvider.theme("darkseven").primaryPalette("fuse-blue").accentPalette("light-blue");
  $mdThemingProvider.theme("tealseven").primaryPalette("grey", {
    "default": "200",
    "hue-1": "600",
    "hue-2": "700",
    "hue-3": "A100"
  }).accentPalette("blue").warnPalette("red");
  $mdThemingProvider.theme("greenseven").primaryPalette("green", {
    "default": "700",
    "hue-1": "600",
    "hue-2": "500",
    "hue-3": "A100"
  }).accentPalette("light-green");
  $mdThemingProvider.theme("docs-dark", "default").primaryPalette("blue").dark();

  Number.prototype.formatMoney = function (n, x, s, c) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
        num = this.toFixed(Math.max(0, ~~n));
    return (c ? num.replace(".", c) : num).replace(new RegExp(re, "g"), "$&" + (s || ","));
  }; // md-datepicker format FR 'DD/MM/YYYY'


  $mdDateLocaleProvider.formatDate = function (date) {
    var m = moment(date).format("DD/MM/YYYY");

    if (m === "Invalid date") {
      return null;
    }

    return m;
  };

  $mdDateLocaleProvider.parseDate = function (dateString) {
    var m = moment(dateString, "DD/MM/YYYY", true);
    return m.isValid() ? m.toDate() : new Date(NaN);
  };
}