"use strict";
/**
 * Color service.
 *
 * @returns {object}
 */

function colorService() {
  /**
   * Convert color from number to RGB.
   *
   * @param {number} number - Number.
   *
   * @returns {string}
   */
  function intToRGB(number) {
    return (number & 0x00FFFFFF).toString(16).toUpperCase().padStart(6, "0");
  }
  /**
   * Get hash code.
   *
   * @param {string} str - String.
   *
   * @returns {number}
   */


  function hashCode(str) {
    var hash = 0;

    for (var index = 0; index < str.length; index++) {
      hash = str.charCodeAt(index) + ((hash << 5) - hash);
    }

    return hash;
  }

  return {
    colorsArray: ["#3366CC", "#DC3912", "#FF9900", "#109618", "#3B3EAC", "#0099C6", "#DD4477", "#66AA00", "#468966", "#FFF0A5", "#FFB03B", "#B64926", "#8E2800", "#AAAA11", "#6633CC", "#E67300", "#8B0707", "#329262", "#5574A6", "#5B39C3", "#F4F3D8", "#9FDDC4", "#7CD9B6", "#C07C6E", "#D76B6D", "#0B5079", "#406057", "#579191", "#A87565", "#DCCEC9", "#233A59", "#F2B33D", "#F29A2E", "#8C5637", "#D95C14", "#5C2849", "#A73E5C", "#EC4863", "#FF733F", "#FFD285", "#415857", "#ADF3EF"],
    purpleFunc: function purpleFunc(index) {
      var purples = ["#2E0854", "#7F00FF", "#551A8B", "#7D26CD", "#8A2BE2", "#912CEE", "#9B30FF", "#71637D", "#694489", "#4B0082", "#BF5FFF", "#A020F0", "#5E2D79", "#72587F", "#BDA0CB", "#6B238E", "#68228B", "#9932CD", "#9932CC", "#9A32CD", "#B23AEE", "#660198", "#AA00FF", "#BF3EFF", "#820BBB", "#9400D3", "#FDF8FF", "#5C246E", "#7A378B", "#B452CD", "#BA55D3", "#D15FEE", "#CC00FF", "#E066FF", "#816687", "#8F5E99", "#91219E", "#754C78", "#A74CAB", "#8B7B8B", "#8B668B", "#CDB5CD", "#D8BFD8", "#4F2F4F", "#9F5F9F", "#CC99CC", "#CD96CD", "#8E388E", "#EED2EE", "#DDA0DD", "#ECC8EC", "#EAADEA", "#DB70DB", "#EEAEEE", "#EE82EE", "#800080", "#8B008B", "#990099", "#CD00CD", "#EE00EE", "#FF00FF", "#FF00FF", "#FFBBFF", "#FFE1FF", "#8B4789", "#CD69C9", "#DA70D6", "#FF83FA", "#EE7AE9", "#9C6B98", "#871F78", "#D3BECF", "#B272A6", "#584E56", "#FF00CC", "#683A5E", "#B5509C", "#DCA2CD", "#8E236B", "#CC3299", "#B62084", "#FF00AA", "#FF1CAE", "#8B1C62", "#CD2990", "#D02090", "#C71585", "#EE30A7", "#FF34B3", "#FF6EC7", "#F6A4D5", "#F7B3DA", "#543948", "#D4318C", "#8B0A50", "#CD1076", "#EE1289", "#FF1493", "#9D6B84", "#8B3A62", "#872657", "#DE85B1", "#551033", "#FF007F", "#FF69B4", "#FF6EB4", "#A46582", "#EE6AA7", "#B6316C", "#8B2252", "#CD3278", "#EE3A8C", "#FF3E96", "#CD6090", "#862A51", "#FF0066", "#8B8386", "#E0427F", "#FF92BB", "#A2627A", "#B03060", "#EEE0E5", "#CDC1C5", "#CD6889", "#DB7093", "#F6CCDA", "#FF82AB", "#FFF0F5", "#8B475D", "#EE799F", "#EAB5C5", "#A5435C", "#8B636C", "#CD919E", "#EEA9B8", "#FFB5C5", "#DC143C", "#F54D70", "#FF0033", "#F6A8B6", "#DB2645", "#FFC0CB", "#E79EA9", "#EEA2AD", "#99182C", "#E31230", "#FFADB9", "#FFB6C1", "#8B5F65", "#422C2F", "#CD8C95", "#DB9EA6", "#FFAEB9", "#CC4E5C", "#55141C", "#BB2A3C", "#C82536", "#AF1E2D", "#B81324", "#E32636", "#FA1D2F", "#F6C9CC", "#B0171F", "#EB5E66", "#D0A9AA", "#D41A1F", "#F64D54", "#FF030D", "#551011", "#E32E30", "#E33638", "#9E0508", "#E35152"];
      return purples[index];
    },
    getColorFromString: function getColorFromString(color) {
      return "#" + intToRGB(hashCode(color));
    }
  };
}

angular.module("app.services").factory("colorService", colorService);