"use strict";
/**
 * Search bar service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

SearchBarService.$inject = ["apiClient"];

function SearchBarService(apiClient) {
  return {
    /**
     * Search engine.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getSearchResults: function getSearchResults(params) {
      return apiClient.post("/searchbar/autocompletefilters", params, {
        parameters: params,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search events engine.
     *
     * @param {object} params - healthcenterId
     * @returns {Promise}
     */
    getSearchEvents: function getSearchEvents(params) {
      return apiClient.get("/events/search", {
        includes: ["eventType"],
        parameters: params,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.directives").service("searchBarService", SearchBarService);