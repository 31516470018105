"use strict";
/**
 * Amplitude service.
 *
 * @param {string} amplitudeAPIKey - API key for amplitude constant
 * @returns {object}
 */

AmplitudeService.$inject = ["amplitudeAPIKey"];

function AmplitudeService(amplitudeAPIKey) {
  /**
   * The amplitude project.
   *
   * @type {object}
   */
  // eslint-disable-next-line no-undef
  var project = amplitude.getInstance();
  /**
   * Init amplitude.
   */

  function init() {
    if (amplitudeAPIKey) {
      project.init(amplitudeAPIKey);
    }
  }
  /**
   * Identify user.
   *
   * @param {string} userId - The user Id.
   * @param {object} userProperties - The user properties.
   */


  function identifyUser(userId, userProperties) {
    if (amplitudeAPIKey) {
      project.setUserId(userId);
      project.setUserProperties(userProperties);
    }
  }
  /**
   * Log event.
   *
   * @param {string} eventName - Name of the event to log.
   * @param {object} params - Params of the event to log.
   */


  function logEvent(eventName, params) {
    if (amplitudeAPIKey) {
      project.logEvent(eventName, params);
    }
  }

  return {
    init: init,
    logEvent: logEvent,
    identifyUser: identifyUser
  };
}

angular.module("app.services").factory("amplitudeService", AmplitudeService);