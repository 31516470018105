"use strict";
/**
 * Apodis content title directive.
 *
 * @returns {object}
 */

ApodisContentTitleController.$inject = ["$scope", "$state", "$window"];

function ApodisContentTitle() {
  return {
    restrict: "E",
    scope: {
      config: "=config"
    },
    templateUrl: "app/main/directives/apodis-content-title/apodisContentTitle.html",
    transclude: true,
    controller: ApodisContentTitleController,
    controllerAs: "vm"
  };
}
/**
 * Apodis content title container.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $window - $window.
 */


function ApodisContentTitleController($scope, $state, $window) {
  /**
   * Declaration of public methods
   */
  $scope.goTo = goTo;
  $scope.goBack = goBack;
  $scope.handleTitleButtonPress = handleTitleButtonPress;
  /**
   * Go To $scope.config.button.state
   *
   * @returns {Promise}
   */

  function goTo() {
    return $state.go($scope.config.button.state);
  }
  /**
   * Go back to $scope.config.back.state.
   *
   * @returns {Promise}
   */


  function goBack() {
    if ($scope.config.back.paramId) {
      return $state.go($scope.config.back.state, {
        id: $scope.config.back.paramId
      });
    }

    return $state.go($scope.config.back.state);
  }
  /**
   * Redirect to $scope.config.title.back.state.
   * If provided state is null, go back in history.
   *
   * @param {object} $event - The $event angular object.
   */


  function handleTitleButtonPress($event) {
    $event.stopPropagation();

    if (!$scope.config.title.back.state) {
      $window.history.back();
    } else {
      $state.go($scope.config.title.back.state);
    }
  }
}

angular.module("app.directives").directive("apodisContentTitle", ApodisContentTitle);