"use strict";
/** @ngInject */

/**
 * The wholesaler category config.
 *
 * @param {object} $stateProvider - The state provider
 */

WholesalerCategoryConfiguration.$inject = ["$stateProvider"];

function WholesalerCategoryConfiguration($stateProvider) {
  $stateProvider.state("app.wholesaler.categories", {
    url: "/categories",
    views: {
      "content@app": {
        templateUrl: "app/main/wholesaler/category/views/list/list.html",
        controller: "wholesalerCategoryListController"
      }
    }
  }).state("app.wholesaler.categories.new", {
    url: "/creation",
    views: {
      "content@app": {
        templateUrl: "app/main/wholesaler/category/views/edit/edit.html",
        controller: "wholesalerCategoryEditController"
      }
    }
  }).state("app.wholesaler.categories.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/wholesaler/category/views/edit/edit.html",
        controller: "wholesalerCategoryEditController"
      }
    }
  });
}

angular.module("wholesaler.category", []).config(WholesalerCategoryConfiguration);