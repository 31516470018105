"use strict";
/**
 * Grouping members configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

GroupingMembersConfig.$inject = ["$stateProvider"];

function GroupingMembersConfig($stateProvider) {
  $stateProvider.state("app.groupingMembers", {
    url: "/equipe",
    views: {
      "content@app": {
        templateUrl: "app/main/grouping/members/grouping.members.html",
        controller: "groupingMembersController"
      },
      "tabContent@app.groupingMembers": {
        templateUrl: "app/main/grouping/members/list/grouping.members.list.html",
        controller: "groupingMembersListController"
      }
    }
  }).state("app.groupingMembers.list", {
    url: "/liste",
    views: {
      "tabContent@app.groupingMembers": {
        templateUrl: "app/main/grouping/members/list/grouping.members.list.html",
        controller: "groupingMembersListController"
      }
    }
  }).state("app.groupingMembers.map", {
    url: "/carte",
    views: {
      "tabContent@app.groupingMembers": {
        templateUrl: "app/main/grouping/members/map/grouping.members.map.html",
        controller: "groupingMembersMapController"
      }
    }
  }).state("app.groupingMembers.history", {
    url: "/historique",
    views: {
      "tabContent@app.groupingMembers": {
        templateUrl: "app/main/grouping/members/history/grouping.members.history.html",
        controller: "groupingMembersHistoryController"
      }
    }
  }).state("app.groupingMembers.purchases", {
    url: "/groupement/suivi-achats",
    views: {
      "content@app": {
        templateUrl: "app/main/grouping/members/purchases/grouping.members.purchases.html",
        controller: "groupingMembersPurchasesController"
      }
    }
  });
}

angular.module("app.grouping").config(GroupingMembersConfig);