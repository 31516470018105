"use strict";
/**
 * App list content directive.
 *
 * @returns {object}
 */

function AppListContent() {
  return {
    restrict: "E",
    template: "<div class='app-list-content' ng-transclude></div>",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appListContent", AppListContent);