"use strict";
/**
 * Autocomplete filter service.
 *
 * @param {object} searchBarService - Search bar service.
 *
 * @returns {object}
 */

autocompleteFiltersService.$inject = ["searchBarService"];

function autocompleteFiltersService(searchBarService) {
  var service = {};
  /*
       ** Filters (Select Options) Definitions
       */

  service.definitionsDisplay = {
    pharmas: {
      title: "Pharmacies",
      color: "#45bdd6",
      icon: "icon-hospital",
      backgroundColor: "#80deea",
      accessPointRight: [1, 3]
    },
    pharmasNotApodis: {
      title: "Pharmacies (Non Apodis)",
      color: "#45bdd6",
      icon: "icon-hospital",
      backgroundColor: "#80deea",
      accessPointRight: [1, 3]
    },
    laboratory: {
      title: "Laboratoires",
      color: "#00b0ff",
      icon: "icon-beaker-outline",
      backgroundColor: "#80d8ff",
      accessPointRight: [1, 2, 3]
    },
    product: {
      title: "Produits",
      color: "#ef6c00",
      icon: "icon-cube-outline",
      backgroundColor: "#fb8c00",
      accessPointRight: [1, 2, 3]
    },
    ranges: {
      title: "Gammes",
      color: "#651fff",
      icon: "icon-blur-linear",
      backgroundColor: "#b388ff",
      accessPointRight: [1, 2, 3]
    },
    // Segments disabled by https://github.com/LaCentralePharma/Apodis-Front/issues/1474

    /*
        segment: {
            title: "Segments",
            color: "#2979ff",
            icon: "icon-format-list-numbers",
            backgroundColor: "#82b1ff",
            accessPointRight: [1, 2, 3]
        },
        */
    segmentHealthcenter: {
      title: "Segments pharmacies",
      color: "#368A30",
      icon: "icon-group",
      backgroundColor: "#368A30",
      accesPointRight: [1, 3]
    },
    segmentProduct: {
      title: "Segments produits",
      color: "#B78178",
      icon: "icon-group",
      backgroundColor: "#B78178",
      accesPointRight: [1, 3]
    },
    category: {
      title: "Catégories Ospharm",
      color: "#e64a19",
      icon: "icon-label-outline",
      backgroundColor: "#ff7043",
      accessPointRight: [1, 2, 3]
    },
    categoriesLaboratoryProduct: {
      title: "Catégories produits",
      color: "#d1e6db",
      icon: "icon-label-outline",
      backgroundColor: "#134a80",
      accessPointRight: [1]
    },
    categoriesLaboratoryHealthcenter: {
      title: "Catégories pharmacies",
      color: "#d1e6db",
      icon: "icon-label-outline",
      backgroundColor: "#10802e",
      accessPointRight: [1]
    },
    region: {
      title: "Régions",
      color: "#afb42b",
      icon: "icon-earth",
      backgroundColor: "#c0ca33",
      accessPointRight: [1, 3]
    },
    departement: {
      title: "Départements",
      color: "#009688",
      icon: "icon-earth",
      backgroundColor: "#80cbc4",
      accessPointRight: [1, 3]
    },
    uga: {
      title: "UGA",
      color: "#0097a7",
      icon: "icon-earth",
      backgroundColor: "#00acc1",
      accessPointRight: [1, 3]
    },
    city: {
      title: "Villes",
      color: "#4a4644",
      icon: "icon-city",
      backgroundColor: "#a1887f",
      accessPointRight: [1, 3]
    },
    groups: {
      title: "Groupements",
      color: "#00e5ff",
      icon: "icon-cube-unfolded",
      backgroundColor: "#84ffff",
      accessPointRight: [1]
    },
    generic: {
      title: "Groupe Générique",
      color: "#3498db",
      icon: "icon-cube-unfolded",
      backgroundColor: "#3498db",
      accessPointRight: [1, 2, 3]
    },
    wholesaler: {
      title: "Grossistes",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    warehouse: {
      title: "Agences",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    user: {
      title: "Utilisateurs",
      color: "#6E6E6E",
      icon: "icon-account",
      backgroundColor: "#6E6E6E",
      accessPointRight: [2]
    },
    basique: {
      value: "basic-event",
      title: "Événements",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497"
    },
    formation: {
      value: "formation",
      title: "Formations",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    campagnetele: {
      value: "tv-ad",
      title: "Campagnes télé",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    campagneradio: {
      value: "radio-ad",
      title: "Campagnes radio",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    campagnepresse: {
      value: "press-ad",
      title: "Campagnes presse",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    campagnedaffichage: {
      value: "poster-ad",
      title: "Campagnes d'affichage",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    visitemedicale: {
      value: "medical-visit",
      title: "Visites médicale",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    animation: {
      value: "animation",
      title: "Animations",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    rendezvouscommercial: {
      value: "commercial_appointment",
      title: "Rendez-vous commerciaux",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    reunion: {
      value: "meeting",
      title: "Réunions",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    emailing: {
      value: "emailing",
      title: "Emailing",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    },
    lancementproduit: {
      value: "product-launch",
      title: "Lancement produits",
      color: "#40A497",
      icon: "icon-cube-unfolded",
      backgroundColor: "#40A497",
      accessPointRight: [1, 2, 3]
    }
  };
  /*
       ** Autocomplete model, list to push selected filter in and filter
       */

  if (typeof service.autocomplete === "undefined") {
    service.autocomplete = {
      model: {
        input: {
          tag: ""
        },
        isDisabled: false,
        selectedItem: null,
        minLength: "0",
        itemText: null,
        displayKey: null,
        placeholder: "",
        tag: "tag"
      },
      selectedFilter: "",
      selectedFilterObject: null
    };
  }
  /**
   * Model query
   */


  var querySkeleton = {
    region: {
      pluralize: "e",
      name: "Région",
      slug: "geo.region.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "Region",
      accessPointRight: [1, 3]
    },
    departement: {
      pluralize: "",
      name: "Département",
      slug: "geo.departement.number",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "Departement",
      accessPointRight: [1, 3]
    },
    uga: {
      pluralize: "e",
      name: "UGA",
      slug: "geo.uga",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "UGA",
      accessPointRight: [1, 3]
    },
    city: {
      pluralize: "e",
      name: "Ville",
      slug: "geo.city.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "City",
      accessPointRight: [1, 3]
    },
    pharmas: {
      pluralize: "e",
      name: "Pharmacies",
      slug: "idOrgas",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "Healthcenter",
      accessPointRight: [1, 3]
    },
    groups: {
      pluralize: "e",
      name: "Groupements",
      slug: "group.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "Groups",
      accessPointRight: [1]
    },
    product: {
      pluralize: "",
      name: "Produit",
      slug: "idProducts",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "Products",
      accessPointRight: [1, 2, 3]
    },
    // Segments disabled by https://github.com/LaCentralePharma/Apodis-Front/issues/1474

    /*
        segment: {
            pluralize: "",
            name: "Segment",
            slug: "segment.value",
            selected: [],
            atclSelected: null,
            filter: "",
            belongs: "quali",
            atcl: "Segments",
            accessPointRight: [1, 2, 3]
        },
        */
    segmentHealthcenter: {
      pluralize: "",
      name: "SegmentPharma",
      slug: "segmentHealthcenter.value",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "SegmentsPharma",
      accesPointRight: [1, 3]
    },
    segmentProduct: {
      pluralize: "",
      name: "Segment",
      slug: "segmentProduct.value",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "SegmentsProduct",
      accesPointRight: [1, 3]
    },
    laboratory: {
      pluralize: "",
      name: "Laboratoire",
      slug: "laboratory.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "Laboratory",
      accessPointRight: [1, 2, 3]
    },
    category: {
      pluralize: "s",
      name: "Classification",
      slug: "category.levelX.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "Category",
      accessPointRight: [1, 2, 3]
    },
    categoriesLaboratoryProduct: {
      pluralize: "s",
      name: "categoriesLaboratoryProduct",
      slug: "categoriesLaboratoryProduct.levelX.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "categoriesLaboratoryProduct",
      accessPointRight: [1]
    },
    categoriesLaboratoryHealthcenter: {
      pluralize: "s",
      name: "categoriesLaboratoryHealthcenter",
      slug: "categoriesLaboratoryHealthcenter.levelX.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "categoriesLaboratoryHealthcenter",
      accessPointRight: [1]
    },
    ranges: {
      pluralize: "s",
      name: "Gamme",
      slug: "range.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      accessPointRight: [1, 2, 3]
    },
    generic: {
      pluralize: "s",
      name: "Générique",
      slug: "generic.id",
      selected: [],
      belongs: "quali",
      accessPointRight: [1, 2, 3]
    },
    wholesaler: {
      pluralize: "s",
      name: "Grossiste",
      slug: "wholesaler.id",
      atclSelected: true,
      filter: "",
      selected: [],
      belongs: "quali",
      accessPointRight: [1, 2, 3]
    },
    warehouse: {
      pluralize: "s",
      name: "Agence",
      slug: "warehouse.warehouse_code",
      selected: [],
      belongs: "quali",
      accessPointRight: [1, 2, 3]
    },
    user: {
      pluralize: "s",
      name: "Utilisateur",
      slug: "user.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "user",
      accessPointRight: [2]
    }
  };
  service.query = {
    must: JSON.parse(JSON.stringify(querySkeleton)),
    should: JSON.parse(JSON.stringify(querySkeleton))
  };

  service.getAll = function (opt) {
    var q = service.autocomplete.model.input[opt.tag];
    var post = {
      q: q
    };
    post.request_types = [];

    if (opt.requestType && opt.requestType.indexOf("all") === -1) {
      post.request_types = opt.requestType;
    }

    if (opt.must !== null) {
      post.must = opt.must;
    }

    if (opt.geoForce !== null) {
      post.geoForce = opt.geoForce;
    }

    if (opt.isFilteredOnSanteSecure !== null) {
      post.isFilteredOnSanteSecure = opt.isFilteredOnSanteSecure;
    }

    if (opt.length > 0) {
      post.length = opt.length;
    }

    return searchBarService.getSearchResults(post).then(function (response) {
      return formatResponse(response);
    }, function () {
      return null;
    });
  }; // Segments disabled by https://github.com/LaCentralePharma/Apodis-Front/issues/1474


  var layoutSearch = {
    product: ["product", "laboratory",
    /* "segment",*/
    "segmentProduct", "ranges", "categoriesLaboratoryProduct", "category", "generic", "wholesaler", "warehouse"],
    orga: ["pharmas", "pharmasNotApodis", "groups", "city", "region", "segmentHealthcenter", "categoriesLaboratoryHealthcenter", "departement", "uga", "user"],
    both: ["pharmas", "pharmasNotApodis", "groups", "city", "region", "categoriesLaboratoryHealthcenter", "departement", "uga", "product", "laboratory",
    /* "segment",*/
    "segmentProduct", "segmentHealthcenter", "ranges", "categoriesLaboratoryProduct", "category", "generic", "wholesaler", "warehouse", "user"]
  };
  /**
   * Format response.
   *
   * @param {object} datas - Data.
   *
   * @returns {object}
   */

  function formatResponse(datas) {
    if (datas.pharmas && datas.pharmas.length > 0) {
      angular.forEach(datas.pharmas, function (pharma) {
        pharma.cip = pharma.meta.substr(6, 7);
      });
    }

    var ret = {};

    _.each(layoutSearch.both, function (idx) {
      if (typeof datas[idx] !== "undefined") {
        ret[idx] = datas[idx];
      }
    });

    return ret;
  }

  return service;
}

angular.module("apodis").factory("autocompleteFiltersService", autocompleteFiltersService);