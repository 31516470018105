"use strict";
/**
 * Auth sponsorships service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

AuthSponsorshipsService.$inject = ["apiClient"];

function AuthSponsorshipsService(apiClient) {
  return {
    /**
     * Get code.
     *
     * @returns {Promise}
     */
    getCode: function getCode() {
      return apiClient.get("/sponsorships/code", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get by code.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getByCode: function getByCode(params) {
      return apiClient.get("/sponsorships/code/".concat(params.code), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get godsons.
     *
     * @returns {Promise}
     */
    getGodsons: function getGodsons() {
      return apiClient.get("/sponsorships/godsons", {
        includes: ["healthcenter"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get sponsor.
     *
     * @returns {Promise}
     */
    getSponsor: function getSponsor() {
      return apiClient.get("/sponsorships/sponsor", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.auth").service("authSponsorshipsService", AuthSponsorshipsService);