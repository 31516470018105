"use strict";
/**
 * Detail stocks tab controller.
 *
 * @param {object} $mdDialog - The angular mdDialog object.
 * @param {object} $scope - The angular $scope object.
 * @param {object} StatisticsQueryService - The statistics query service
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 * @param {object} $state - The angular $state object.
 * @param {object} stocksService - The stocks service.
 */

StocksReassortController.$inject = ["$mdDialog", "$scope", "StatisticsQueryService", "environmentService", "analysisService", "$state", "stocksService"];
DetailStocksProductGraphTabController.$inject = ["$q", "$mdDialog", "$scope", "product", "currentTableConfig", "analysisService"];

function StocksReassortController($mdDialog, $scope, StatisticsQueryService, environmentService, analysisService, $state, stocksService) {
  $scope.loading = true;
  $scope.values = {};
  $scope.totalPrice = {};
  $scope.prices = {};
  $scope.changeProposal = changeProposal;
  $scope.launchSearch = launchSearch;
  $scope.selectedTabs = 0;
  $scope.configSearch = {
    event: "stockReassortFilterChange",
    search: []
  };
  $scope.usrquery = StatisticsQueryService.query;
  environmentService.getEnvironment().then(function (env) {
    $scope.currentEnv = env;

    if ($scope.currentEnv.accessPointType === 1 && $scope.usrquery.must.laboratory.selected.length === 0) {
      $scope.usrquery.must.laboratory.selected.push($scope.currentEnv.entity);
    }
  });

  $scope.removeFilter = function (obj, list) {
    var index = list.indexOf(obj);

    if (index !== -1) {
      list.splice(index, 1);
      $scope.loading = true;
      $scope.usrquery = StatisticsQueryService.query;
      StatisticsQueryService.buildQuery($scope.usrquery, $scope.usrquery.date).then(function (query) {
        $scope.query = query;
        launchSearch();
        getMicroDisruption();
      });
    }
  };

  $scope.rawValues = StatisticsQueryService.values;
  /**
   * general functions for checkboxes management.
   */

  $scope.toggleCb = toggleCb;
  $scope.indexByLabel = indexByLabel;
  $scope.existsFilter = existsFilter;
  $scope.removeFilter = removeFilter;
  /**
   * Check or uncheck the checkbox
   *
   * @param {object} item - The item.
   * @param {Array} list - The list.
   */

  function toggleCb(item, list) {
    var idx = $scope.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      launchSearch();
      getMicroDisruption();
      $scope.loading = true;
    });
  }
  /**
   * Change period of proposal
   */


  function changeProposal() {
    $scope.query.reassortDays = $scope.reassort.proposal;
    getMicroDisruption();
    angular.forEach($scope.detailStocksProducts, function (product) {
      var value = Math.ceil(product._source.needs.calcul.quantity_propal / 60 * (60 + product._source.product.laboratory_delivery || 0));

      if (product._source.product.packing && value % product._source.product.packing !== 0 && product._source.needs.calcul.order_needs.toFixed(0) !== 0) {
        value = value + (product._source.product.packing - value % product._source.product.packing);
      }

      var orderProposal = value / 60 * $scope.reassort.proposal;

      if (orderProposal > parseInt(orderProposal) + $scope.reassort.round) {
        orderProposal = Math.ceil(orderProposal);
      } else {
        orderProposal = Math.floor(orderProposal);
      }

      $scope.values[product._source.product.presentation] = orderProposal;
    });
  }
  /**
   * Index the filters by label
   *
   * @param {string} name - The name.
   * @param {Array} list - The list.
   * @returns {number}
   */


  function indexByLabel(name, list) {
    if (!name || !list) {
      return -1;
    }

    for (var index = 0, lemgth = list.length; index < lemgth; index++) {
      if (list[index].label === name) {
        return index;
      }
    }

    return -1;
  }
  /**
   * Check if filter exists
   *
   * @param {string} name -
   * @param {Array} list -
   * @returns {boolean}
   */


  function existsFilter(name, list) {
    return $scope.indexByLabel(name, list) > -1;
  }
  /**
   * Filters Labels Management
   *
   * @param {object} obj - Object.
   * @param {Array} list - List.
   */


  function removeFilter(obj, list) {
    var index = list.indexOf(obj);

    if (index !== -1) {
      list.splice(index, 1);
      $scope.loading = true;
      StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
        $scope.query = query;
        launchSearch();
        getMicroDisruption();
      });
    }
  }

  $scope.reassort = {
    proposal: 60,
    round: 0.5
  };
  $scope.currentTableConfig = {
    currentPage: 1,
    size: 10,
    order: "needs.calcul.quantity_propal"
  };
  loader();
  /**
   * The loading function
   */

  function loader() {
    $scope.usrquery = StatisticsQueryService.query;
    environmentService.getEnvironment().then(function (env) {
      $scope.hc = env.entity;
      return StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
        $scope.query = query;

        if (env.accessPointType === 2) {
          launchSearch();
        }

        $scope.environment = env;

        if (env.accessPointType === 1 && $scope.usrquery.must.laboratory.selected.length === 0) {
          var lab = {
            id: env.entityId,
            name: env.entity.name,
            type: "laboratory"
          };
          $scope.usrquery.must.laboratory.selected.push(lab);
        }

        StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
          $scope.query = query;
          $scope.query.stockDaysType = ">=";
          $scope.query.stockDays = 60;
          $scope.query.reassortDays = 30;
          $scope.query.surstockValue = null;
          getMicroDisruption();
        });
      });
    });
  }
  /**
   * Launch the request
   *
   * @returns {Promise}
   */


  function launchSearch() {
    $scope.loading = true;
    $scope.detailStocksGeneral = [];
    $scope.query.tableConfig = $scope.currentTableConfig;
    return analysisService.getLaboratoryStockByHealthcenter($scope.hc.cip, $scope.query).then(function (response) {
      $scope.detailStocksProducts = response.hits.hits;
      $scope.totalNumber = response.hits.total;
      $scope.detailStocksGeneral = response.aggregations;
      angular.forEach($scope.detailStocksProducts, function (product) {
        var value = Math.ceil(product._source.needs.calcul.quantity_propal / 60 * (60 + product._source.product.laboratory_delivery || 0));

        if (product._source.product.packing && value % product._source.product.packing !== 0 && product._source.needs.calcul.order_needs.toFixed(0) !== 0) {
          value = value + (product._source.product.packing - value % product._source.product.packing);
        }

        $scope.values[product._source.product.presentation] = value / 60 * $scope.reassort.proposal;
        $scope.prices[product._source.product.presentation] = product._source.product.price;
        $scope.totalPrice[product._source.product.presentation] = (product._source.product.price * $scope.values[product._source.product.presentation]).toFixed(2);
      });
      $scope.loading = false;
      return $scope.detailStocksProducts;
    });
  }
  /**
   * Go to detail
   *
   * @param {object} ev - The event.
   * @param {number} id - The id.
   */


  $scope.goDetail = function (ev, id) {
    var product = _.find($scope.detailStocksProducts, function (det) {
      return det._source.product.presentation.toString() === id.toString();
    })._source;

    $mdDialog.show({
      locals: {
        product: product,
        currentTableConfig: $scope.currentTableConfig
      },
      controller: DetailStocksProductGraphTabController,
      templateUrl: "app/main/statistics/stocks/views/reassort/stocks-product-graph.tmpl.html",
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true
    });
  };
  /**
   * Get the generic of the product
   *
   * @returns {object}
   */


  $scope.getGeneric = function () {
    return sumAllProduct();
  };
  /**
   * Get category needs for a product
   *
   * @param {object} product - The product.
   * @returns {number}
   */


  $scope.getCategory = function (product) {
    var needsCategory = 0;
    angular.forEach(product.category_data.hits.hits, function (prod) {
      needsCategory += prod._source.product.prop.calcul.quantity_propal;
    });
    return needsCategory;
  };
  /**
   * Make a net sum
   *
   * @param {Array} products - The products.
   * @returns {number}
   */


  $scope.sumOrderNetMuliple = function (products) {
    var orderNetMuliple = 0;
    angular.forEach(products, function (data) {
      orderNetMuliple += data.calcul.orderNetMuliple;
    });
    return orderNetMuliple;
  };
  /**
   * Change the quantity
   *
   * @param {number} id - The id.
   */


  $scope.changePropalQuantities = function (id) {
    $scope.totalPrice[id] = ($scope.prices[id] * $scope.values[id]).toFixed(2);
  };
  /*
       ** Events
       */


  $scope.$on("stockReassortFilterChange", function () {
    $scope.loading = true;
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      launchSearch();
      getMicroDisruption();
    });
  });
  $scope.configSearch = {
    event: "microDisruptionFilterChange",
    search: []
  };
  $scope.rawValues = StatisticsQueryService.values;
  $scope.getLimits = getLimits;
  $scope.getMicroDisruption = getMicroDisruption;
  $scope.filterDisruption = filterDisruption;
  $scope.removeFilter = removeFilter;
  $scope.goToHealthcenterAudit = goToHealthcenterAudit;
  $scope.addLaboToFilter = addLaboToFilter;
  $scope["export"] = exportCsv;
  $scope.optionsPagination = {
    page: 1,
    limit: 10,
    order: "-_source.quantity"
  };
  $scope.loaderMicroDisruptions = {
    loading: true,
    loader: "Chargement de vos ruptures",
    empty: "Aucune donnée"
  };
  /**
   * Get limits of the filters
   *
   * @param {Array} array - The array to delimite.
   * @returns {Array}
   */

  function getLimits(array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  }
  /**
   * get micro disruption
   */


  function getMicroDisruption() {
    $scope.loaderMicroDisruptions.loading = true;
    stocksService.getMicroDisruption({
      data: $scope.query
    }).then(function (disruptions) {
      $scope.disruptions = disruptions.disruptions;
      $scope.disruptionsSaved = disruptions.disruptions;
      $scope.totalSurstockAmount = disruptions.totalSurstockAmount;
      $scope.totalReassortAmount = disruptions.totalReassortAmount;
      $scope.loaderMicroDisruptions.loading = false;
    });
  }
  /**
   * filter the disruptions
   */


  function filterDisruption() {
    $scope.disruptions = [];
    $scope.disruptionsSaved.forEach(function (disruption) {
      if ($scope.query.stockDaysType === ">=" && disruption.needsDay.min >= $scope.query.stockDays || $scope.query.stockDaysType === "<=" && disruption.needsDay.min <= $scope.query.stockDays) {
        $scope.disruptions.push(disruption);
      }
    });
  }
  /**
   * Go to healthcenter audit.
   *
   * @param {object} disruption - The disruption object.
   */


  function goToHealthcenterAudit(disruption) {
    if ($scope.environment.accessPointType === 1) {
      $state.go("app.healthcenterAudit.detail.reassort", {
        id: disruption.key
      });
    }
  }
  /**
   * Export.
   */


  function exportCsv() {
    stocksService.exportCsv($scope.query).then(function (data) {
      // eslint-disable-next-line no-undef
      saveAs(new Blob([data.csv], {
        type: "text/csv;charset=UTF-8"
      }), "export.csv");
    });
  }
  /**
   * Listen to the search bar event
   */


  $scope.$on("microDisruptionFilterChange", function () {
    $scope.loaderMicroDisruptions.loading = true;
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      getMicroDisruption();
    });
  });
  /**
   * Add labo to query search.
   *
   * @param {object} disruption - The disruption object.
   */

  function addLaboToFilter(disruption) {
    $scope.usrquery.must.laboratory.selected.push({
      name: disruption.entity.name,
      id: disruption.key,
      type: "labo"
    });
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      launchSearch();
      getMicroDisruption();
      $scope.selectedTabs = 1;
    });
  }
}
/**
 * Detail stocks product graph tab controller.
 *
 * @param {object} $q - $q.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $scope - $scope.
 * @param {object} product - Product.
 * @param {object} currentTableConfig - Current table configuration.
 * @param {object} analysisService - Analysis service.
 */


function DetailStocksProductGraphTabController($q, $mdDialog, $scope, product, currentTableConfig, analysisService) {
  $scope.loading = true;
  $scope.product = product;
  $scope.typeGraph = "Générique";
  $scope.lines = {};
  $scope.sumAllProduct = sumAllProduct(product);
  $scope.deferredChart = $q.defer();
  $scope.amChartOptions = {
    data: $scope.deferredChart.promise,
    type: "serial",
    theme: "light",
    autoMarginOffset: 5,
    categoryField: "range30days",
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    titles: [{
      text: "Ventes sur 12 mois",
      size: 16
    }],
    legend: {
      enabled: true
    },
    chartScrollbar: {
      enabled: true
    },
    valueAxes: [{
      stackType: "regular",
      axisAlpha: 0.5,
      gridAlpha: 0
    }],
    categoryAxis: {
      gridPosition: "start",
      parseDates: false
    },
    graphs: [],
    "export": {
      enabled: true
    }
  };
  /**
   * Manage data for the chart
   *
   * @param {object} dataChart -
   */

  function processDataHistoForAmCharts(dataChart) {
    var lines = [];
    angular.forEach(dataChart.hits.hits, function (product) {
      if (product._source.needs && product._source.needs.calcul.sale_amount_average_month_weighting !== 0) {
        var addProduct = {
          type: "column",
          title: product._source.product.presentation_name,
          valueField: product._source.product.presentation,
          labelText: "[[value]]",
          balloonText: product._source.product.presentation_name + " :[[value]]",
          fillAlphas: 0.8,
          lineAlpha: 0.2
        };
        $scope.amChartOptions.graphs.push(addProduct);
      }
    });

    var _loop = function _loop(index) {
      var lineMonth = {};
      lineMonth.range30days = "-" + index + " mois";
      angular.forEach(dataChart.hits.hits, function (product) {
        if (product._source.needs && product._source.needs.calcul.sale_amount_average_month_weighting !== 0) {
          lineMonth[product._source.product.presentation] = product._source.needs.sales[index].volume;
        }
      });
      lines.push(lineMonth);
    };

    for (var index = 1; index < 12; index++) {
      _loop(index);
    }

    $scope.lines = lines;
    lines.reverse();
    $scope.deferredChart.resolve(lines);
    $scope.loading = false;
  }

  var query = {};

  if (product.product.category && product.product.category.level5 !== 0) {
    var categoryId = product.product.category.level5;
    query.must = {
      categoryMulti: [categoryId]
    };
    $scope.typeGraph = "Catégories";

    if (product.product.presentation_gen_group) {
      $scope.typeGraph = "Génériques";
      query.must = {
        generic: [product.product.presentation_gen_group]
      };
    }

    $scope.valueWithDelivery = Math.ceil(product.needs.calcul.quantity_propal / 60 * (60 + product.product.laboratory_delivery) - product.needs.calcul.order_needs);
    $scope.productToOrdered = Math.ceil(product.needs.calcul.order_needs + (product.product.packing - $scope.valueWithDelivery % product.product.packing));
    query.tableConfig = currentTableConfig;
    analysisService.getLaboratoryStockByHealthcenter(product.organization.cip, query).then(function (response) {
      $scope.dataChart = response;
      processDataHistoForAmCharts(response);
    });
  }

  $scope.hide = function () {
    $mdDialog.hide();
  };

  $scope.cancel = function () {
    $mdDialog.cancel();
  };
}
/**
 * Make a sum
 *
 * @returns {object}
 */


function sumAllProduct() {
  return {
    orderNeedsNegative: 0,
    saleAmountAverageMonthWeighting: 0,
    stock: 0,
    stockAvailableDays: 0,
    stockAvailableDaysTrue: 0
  };
}

angular.module("app.statistics.stocks").controller("stocksReassortController", StocksReassortController);