/* global tinymce */
"use strict";
/**
 * User laboratory edit controller.
 *
 * @param {string} apiurl - API URL.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} $mdToast - $mdToast.
 * @param {object} DTOptionsBuilder - DTOptionsBuilder.
 * @param {object} $compile - $compile.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} analysisService - Analysis service.
 * @param {object} laboratoryBrandingService - Laboratory branding service.
 */

UserLaboratoryEditController.$inject = ["apiurl", "$stateParams", "$scope", "environmentService", "$mdToast", "DTOptionsBuilder", "$compile", "StatisticsQueryService", "$mdDialog", "analysisService", "laboratoryBrandingService"];
EditDetailStocksProductGraphTabController.$inject = ["$q", "$mdDialog", "$scope", "product", "analysisService"];

function UserLaboratoryEditController(apiurl, $stateParams, $scope, environmentService, $mdToast, DTOptionsBuilder, $compile, StatisticsQueryService, $mdDialog, analysisService, laboratoryBrandingService) {
  var vm = this;
  vm.token = window.sessionStorage.token;
  vm.apiurl = apiurl;
  vm.slider = [];
  vm.currentEnv = environmentService.getEnvironment();
  vm.token = window.sessionStorage.token;
  vm.laboratory = $stateParams.laboratory;

  vm.updateLaboratoryBranding = function () {
    var postData = {
      laboratoryId: vm.getLaboratoryBranding.laboratoryId,
      groupmentCondition: vm.getLaboratoryBranding.groupmentCondition,
      brandingId: vm.getLaboratoryBranding.id
    };
    laboratoryBrandingService.update(postData).then(function () {
      $mdToast.show($mdToast.simple().textContent("Les consignes ont été mises à jour").position("top right"));
    });
  };

  $scope.tinymceOptions = {
    selector: "textarea#groupment",
    height: 100,
    menubar: false,
    plugins: "link image code advlist autolink lists link image charmap hr anchor visualblocks visualchars code fullscreen media save table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools codesample ",
    toolbar: " styleselect | fontselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | media | forecolor backcolor | textcolor colorpicker",
    image_advtab: true,
    font_formats: "Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;AkrutiKndPadmini=Akpdmi-n",
    fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
    skin: "lightgray",
    theme: "modern"
  };
  $scope.selected = [];
  $scope.limitOptions = [5, 10, 15];
  $scope.query = {
    order: "name",
    limit: 5,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.filters = [];
  $scope.$watch("filter.search", function (newValue) {
    if (newValue !== undefined) {
      $scope.filters = newValue.split(" ");
    }
  });
  /**
   * Create row.
   *
   * @param {object} row - Row.
   */

  function createdRow(row) {
    // Recompiling so we can bind Angular directive to the DT
    $compile(angular.element(row).contents())($scope);
  }

  vm.values = {};
  vm.totalPrice = {};
  /**
   * Launch search.
   *
   * @returns {Promise}
   */

  function launchSearch() {
    return analysisService.getLaboratoryStockByHealthcenter(vm.env.entity.cip, vm.query).then(function (response) {
      vm.detailStocksProducts = response.hits.hits;
      vm.detailStocksGeneral = response.aggregations;
      angular.forEach(vm.detailStocksProducts, function (item) {
        if (item._source.product.laboratory_delivery) {
          item._source.needs.calcul.quantity_propal = Math.ceil(item._source.needs.calcul.quantity_propal / 60 * (60 + item._source.product.laboratory_delivery));
        }

        if (item._source.product.packing && item._source.needs.calcul.quantity_propal % item._source.product.packing !== 0 && item._source.needs.calcul.order_needs.toFixed(0) !== 0) {
          item._source.needs.calcul.quantity_propal = item._source.product.packing + (item._source.product.packing - item._source.product.packing % item._source.product.packing);
        }

        vm.totalPrice[item._source.product.presentation] = (item._source.product.price * item._source.needs.calcul.quantity_propal).toFixed(2);
      });
      vm.isLoaded = true;
      return vm.detailStocksProducts;
    });
  }

  vm.changeCartQuantity = function (item) {
    vm.totalPrice[item._source.product.presentation] = (item._source.product.price * item._source.needs.calcul.quantity_propal).toFixed(2);
  };

  vm.goDetail = function (ev, product) {
    $mdDialog.show({
      locals: {
        product: product
      },
      controller: EditDetailStocksProductGraphTabController,
      templateUrl: "app/main/healthcenters/detail/tabs/stocks-product-graph.tmpl.html",
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true
    });
  };
  /**
   * Initialize.
   */


  function init() {
    laboratoryBrandingService.read({
      laboratoryId: $stateParams.id
    }).then(function (response) {
      vm.getLaboratoryBranding = response.laboratoryBranding;

      if (vm.getLaboratoryBranding) {
        if (vm.getLaboratoryBranding.background && vm.getLaboratoryBranding.background.path) {
          vm.laboBkg = vm.apiurl + "/filemanager/image/render?path=" + vm.getLaboratoryBranding.background.path + "&w=1200&token=" + vm.getLaboratoryBranding.background.token;
        }

        if (vm.getLaboratoryBranding && vm.getLaboratoryBranding.logo && vm.getLaboratoryBranding.logo.path) {
          vm.laboLogo = vm.apiurl + "/filemanager/image/render?path=" + vm.getLaboratoryBranding.logo.path + "&w=150&token=" + vm.getLaboratoryBranding.logo.token;
        }

        if (vm.getLaboratoryBranding.commercialCondition) {
          vm.commercialCondition = new tinymce.html.Serializer().serialize(new tinymce.html.DomParser().parse(vm.getLaboratoryBranding.commercialCondition));
        }

        if (vm.getLaboratoryBranding.restockingCondition) {
          vm.restockingCondition = new tinymce.html.Serializer().serialize(new tinymce.html.DomParser().parse(vm.getLaboratoryBranding.restockingCondition));
        }
      }

      vm.currentEnv = environmentService.getEnvironment();
      vm.currentEnv.then(function (env) {
        vm.env = env;
        vm.access = env.accessPointType;

        if (vm.getLaboratoryBranding && env.accessPointType === 2) {
          vm.groupmentCondition = new tinymce.html.Serializer().serialize(new tinymce.html.DomParser().parse(vm.getLaboratoryBranding.groupmentCondition));
        }

        vm.usrquery = StatisticsQueryService.query;

        if (!_.find(vm.usrquery.must.pharmas.selected, function (pharma) {
          return pharma.cip === vm.env.entity.cip;
        })) {
          vm.usrquery.must.pharmas.selected.push({
            cip: vm.env.entity.cip
          });
        }

        vm.usrquery.must.laboratory.selected.push({
          id: $stateParams.id
        });
        return StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
          vm.query = query;
          vm.dtOptions = angular.merge(DTOptionsBuilder.fromFnPromise(launchSearch()).withOption("createdRow", createdRow));
          StatisticsQueryService.queryRebase();
        });
      });
    });
  }

  init();
}
/**
 * Edit detail stocks product graph tab controller.
 *
 * @param {object} $q - $q.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $scope - $scope.
 * @param {object} product - Product.
 * @param {object} analysisService - Analysis service.
 */


function EditDetailStocksProductGraphTabController($q, $mdDialog, $scope, product, analysisService) {
  var vm = this;
  $scope.loading = true;
  $scope.dataChart;
  $scope.product = product;
  $scope.typeGraph = "Générique";
  $scope.lines = {};
  $scope.sumAllProduct = sumAllProduct();
  vm.deferredChart = $q.defer();
  $scope.amChartOptions = {
    data: vm.deferredChart.promise,
    type: "serial",
    theme: "light",
    autoMarginOffset: 5,
    categoryField: "range30days",
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    titles: [{
      text: "Ventes sur 12 mois",
      size: 16
    }],
    legend: {
      enabled: true
    },
    chartScrollbar: {
      enabled: true
    },
    valueAxes: [{
      stackType: "regular",
      axisAlpha: 0.5,
      gridAlpha: 0
    }],
    categoryAxis: {
      gridPosition: "start",
      parseDates: false
    },
    graphs: [],
    "export": {
      enabled: true
    }
  };
  /**
   * Process data for AmCharts.
   *
   * @param {object} dataChart - Data chart.
   */

  function processDataForAmCharts(dataChart) {
    var lines = [];
    angular.forEach(dataChart.hits.hits, function (product) {
      if (product._source.needs && product._source.needs.calcul.sale_amount_average_month_weighting !== 0) {
        var addProduct = {
          type: "column",
          title: product._source.product.presentation_name,
          valueField: product._source.product.presentation,
          labelText: "[[value]]",
          balloonText: product._source.product.presentation_name + " :[[value]]",
          fillAlphas: 0.8,
          lineAlpha: 0.2
        };
        $scope.amChartOptions.graphs.push(addProduct);
      }
    });

    var _loop = function _loop(index) {
      var lineMonth = {};
      lineMonth.range30days = "-" + index + " mois"; // eslint-disable-next-line no-loop-func

      angular.forEach(dataChart.hits.hits, function (product) {
        if (product._source.needs && product._source.needs.calcul.sale_amount_average_month_weighting !== 0) {
          lineMonth[product._source.product.presentation] = product._source.needs.sales[index].volume;
        }
      });
      lines.push(lineMonth);
    };

    for (var index = 1; index < 12; index++) {
      _loop(index);
    }

    $scope.lines = lines;
    lines.reverse();
    vm.deferredChart.resolve(lines);
    $scope.loading = false;
  }

  var query = {};

  if (product.product.category && product.product.category.level5 !== 0) {
    var categoryId = product.product.category.level5;
    query.must = {
      categoryMulti: [categoryId]
    };
    $scope.typeGraph = "Catégories";

    if (product.product.presentation_gen_group) {
      $scope.typeGraph = "Génériques";
      query.must = {
        generic: [product.product.presentation_gen_group]
      };
    }

    $scope.valueWithDelivery = Math.ceil(product.needs.calcul.quantity_propal / 60 * (60 + product.product.laboratory_delivery) - product.needs.calcul.order_needs);
    $scope.productToOrdered = Math.ceil(product.needs.calcul.order_needs + (product.product.packing - $scope.valueWithDelivery % product.product.packing));
    analysisService.getLaboratoryStockByHealthcenter(product.organization.cip, query).then(function (response) {
      $scope.dataChart = response;
      processDataForAmCharts(response);
    });
  }

  $scope.hide = function () {
    $mdDialog.hide();
  };

  $scope.cancel = function () {
    $mdDialog.cancel();
  };
}
/**
 * Sum all product.
 *
 * @returns {object}
 */


function sumAllProduct() {
  return {
    orderNeedsNegative: 0,
    saleAmountAverageMonthWeighting: 0,
    stock: 0,
    stockAvailableDays: 0,
    stockAvailableDaysTrue: 0
  };
}

angular.module("app.users-laboratory-edit", []).controller("UserLaboratoryEditController", UserLaboratoryEditController);