"use strict";
/**
 * Dashboard laboratories top wholesalers yesterday controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} wholesalersService - Wholesalers service.
 * @param {object} environmentService - Environment service.
 */

DashboardLaboratoriesTopWholesalersYesterdayController.$inject = ["$scope", "wholesalersService", "environmentService"];

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function DashboardLaboratoriesTopWholesalersYesterdayController($scope, wholesalersService, environmentService) {
  $scope.loading = true;
  var yesterday = moment().subtract(1, "d");
  $scope.yesterdayDisplay = yesterday.format("dddd DD MMMM");
  $scope.quantityTotal = 0;
  $scope.caTotal = 0;
  $scope.wholesalers = [];
  environmentService.getEnvironment().then(function (environment) {
    var params = {
      id: environment.entityId,
      date: yesterday.format("YYYY-MM-DD")
    };
    return wholesalersService.getTopSupplying(params);
  }).then(function (response) {
    if (_typeof(response) === "object") {
      $scope.quantityTotal = response.quantityTotal ? response.quantityTotal.value : 0;
      $scope.caTotal = response.caTotal ? response.caTotal.value : 0;
      $scope.wholesalers = response.wholesalers ? response.wholesalers.buckets : [];
    }

    $scope.loading = false;
  });
}

angular.module("app.dashboard").controller("dashboardLaboratoriesTopWholesalersYesterdayController", DashboardLaboratoriesTopWholesalersYesterdayController);