"use strict";
/**
 * Partners configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

PartnersConfig.$inject = ["$stateProvider"];

function PartnersConfig($stateProvider) {
  $stateProvider.state("app.partners", {
    url: "/partenaires",
    views: {
      "content@app": {
        templateUrl: "app/main/partners/partners.html",
        controller: "partnersController"
      }
    }
  });
}

angular.module("app.partners", []).config(PartnersConfig).constant("partnersConfig", {
  baseDir: "app/main/partners"
});