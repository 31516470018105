"use strict";
/**
 * Branding service.
 *
 * @param {object} apiClient - API client.
 * @param {object} dataModelBranding - Data model branding.
 * @param {object} apodisThemingFactory - Apodis theming factory.
 * @param {object} $rootScope - $rootScope.
 *
 * @returns {object}
 */

BrandingService.$inject = ["apiClient", "dataModelBranding", "apodisThemingFactory", "$rootScope"];

function BrandingService(apiClient, dataModelBranding, apodisThemingFactory, $rootScope) {
  return {
    /**
     * Read.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/groupings/".concat(params.groupingId, "/branding"), {
        includes: ["grouping", "sliders", "template"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      }).then(function (result) {
        if (!result.groupingBranding) {
          return new dataModelBranding();
        }

        return result.groupingBranding;
      });
    },

    /**
     * Post.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    post: function post(params) {
      return apiClient.post("/groupings/".concat(params.groupingId, "/branding"), params, {
        includes: ["grouping", "sliders", "template"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.groupingBranding;
      });
    },

    /**
     * Update.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/groupings/".concat(params.groupingId, "/branding/").concat(params.id), params, {
        includes: ["grouping", "sliders", "template"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      }).then(function (result) {
        $rootScope.environment.entity.branding = result.groupingBranding;
        apodisThemingFactory.generateDefault($rootScope.environment);
        return result.groupingBranding;
      });
    },

    /**
     * Delete.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/groupings/".concat(params.groupingId, "/branding"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.groupingBranding;
      });
    },

    /**
     * Get conditions.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getConditions: function getConditions(params) {
      return apiClient.get("/groupings/".concat(params.groupingId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.conditions;
      });
    },

    /**
     * Read conditions.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readConditions: function readConditions(params) {
      return apiClient.get("/groupings/".concat(params.groupingId, "/laboratories/").concat(params.laboratoryId, "/conditions/").concat(params.conditionId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.conditions;
      });
    },

    /**
     * Create conditions.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    createConditions: function createConditions(params) {
      return apiClient.post("/groupings/".concat(params.groupingId, "/laboratories/").concat(params.laboratoryId, "/conditions"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.conditions;
      });
    },

    /**
     * Update conditions.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    updateConditions: function updateConditions(params) {
      return apiClient.put("/groupings/conditions/".concat(params.conditionsId), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.conditions;
      });
    },

    /**
     * Delete conditions.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    deleteConditions: function deleteConditions(params) {
      return apiClient["delete"]("/gorupings/conditions/".concat(params.conditionsId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.conditions;
      });
    }
  };
}

angular.module("app.grouping").service("brandingService", BrandingService);