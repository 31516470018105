"use strict";
/**
 * Impact analysis configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

ImpactAnalysisConfig.$inject = ["$stateProvider"];

function ImpactAnalysisConfig($stateProvider) {
  $stateProvider.state("app.impactAnalysis", {
    url: "/analyse_impact",
    views: {
      "content@app": {
        templateUrl: "app/main/impactAnalysis/view/list/impactAnalysis.list.html",
        controller: "impactAnalysisListController"
      }
    }
  }).state("app.impactAnalysis.create", {
    url: "/nouveau",
    views: {
      "content@app": {
        templateUrl: "app/main/impactAnalysis/view/create/impactAnalysis.create.html",
        controller: "impactAnalysisCreateController"
      }
    }
  }).state("app.impactAnalysis.edit", {
    url: "/edition/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/impactAnalysis/view/create/impactAnalysis.create.html",
        controller: "impactAnalysisCreateController"
      }
    }
  }).state("app.impactAnalysis.details", {
    url: "/details/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/impactAnalysis/view/details/impactAnalysis.details.html",
        controller: "impactAnalysisDetailsController"
      }
    }
  });
}

angular.module("app.impactAnalysis", []).config(ImpactAnalysisConfig);