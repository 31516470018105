"use strict";
/**
 * Top 10 growth laboratory controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} groupingDashboardCapService - Grouping dashboard cap service.
 */

Top10GrowthLaboController.$inject = ["$scope", "groupingDashboardCapService"];

function Top10GrowthLaboController($scope, groupingDashboardCapService) {
  $scope.dashboardLoading = true;
  loader();
  /**
   * Loader.
   */

  function loader() {
    getTop10();
  }
  /**
   * Retrieve the data
   */


  function getTop10() {
    $scope.dashboardLoading = true;
    groupingDashboardCapService.getTop10Growth().then(function (top10) {
      $scope.top10Sellin = top10.sellin.top;
      $scope.top10Sellout = top10.sellout.top;
      $scope.flop10Sellin = top10.sellin.flop;
      $scope.flop10Sellout = top10.sellout.flop;
      $scope.boughtsByLabo = top10.buyingCAByLabo;
      $scope.dashboardLoading = false;
    });
  }
}

angular.module("app.dashboard").controller("top10GrowthLaboController", Top10GrowthLaboController);