"use strict";
/**
 * Sales area management controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 */

SalesAreaManagementController.$inject = ["$scope", "$state"];

function SalesAreaManagementController($scope, $state) {
  var states = ["app.salesAreaManagement.list", "app.salesAreaManagement.profitability"];
  $scope.tabSelected = states.indexOf($state.current.name);

  if ($scope.tabSelected === -1) {
    $scope.tabSelected = isChild($state.current.name);
  }
  /**
   * Is child.
   *
   * @param {string} stateName - State name.
   *
   * @returns {number}
   */


  function isChild(stateName) {
    var parentState = stateName.split(".").slice(0, 3).join(".");
    return states.indexOf(parentState);
  }
}

angular.module("app.salesAreaManagement").controller("salesAreaManagementController", SalesAreaManagementController);