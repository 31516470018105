"use strict";
/**
 * Dashboard pharmacies membership controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $window - $window.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} accessService - Access service.
 */

dashboardPharmaciesMembershipController.$inject = ["$scope", "$window", "$mdDialog", "accessService"];

function dashboardPharmaciesMembershipController($scope, $window, $mdDialog, accessService) {
  $scope.edenred = [];
  /** Get edenred accounts. **/

  accessService.getEdenredAccounts().then(function (response) {
    $scope.edenred = response.points;
  });

  $scope.openVideo = function () {
    $mdDialog.show({
      templateUrl: "app/main/home/dialogs/videoIncentive.tmpl.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  };
  /**
   * Open membership catalog.
   *
   * @param {object} account - The account to open.
   */


  $scope.openMembershipCatalog = function (account) {
    var url = "http://membership-program.apodispharma.com/AutoLogin.aspx?login=" + account.login + "&password=" + account.password + "&countrycode=fr&lang=fr-fr";
    $window.open(url, "_blank");
  };
}

angular.module("app.dashboard").controller("dashboardPharmaciesMembershipController", dashboardPharmaciesMembershipController);