"use strict";
/**
 * The app-infinite-scroll directive.
 *
 * @returns {object}
 */

AppInfiniteScrollController.$inject = ["$compile"];

function AppInfiniteScrollDirective() {
  return {
    restrict: "A",
    scope: {
      callback: "&isCallback",
      completed: "=isCompleted"
    },
    controller: AppInfiniteScrollController,
    link: AppInfiniteScrollLink
  };
}
/**
 * Infinite scroll controller.
 *
 * @param {object} $compile - $compile.
 */


function AppInfiniteScrollController($compile) {
  this.$compile = $compile;
}
/**
 * Infinite scroll link.
 *
 * @param {object} $scope - $scope.
 * @param {object} $element - $element.
 * @param {object} $attrs - $attrs.
 * @param {object} $ctrl - $ctrl.
 */


function AppInfiniteScrollLink($scope, $element, $attrs, $ctrl) {
  setupInfiniteScroll();
  /**
   * Add a spinner at the end of container and listen to scroll events
   */

  function setupInfiniteScroll() {
    var spinner = $ctrl.$compile("<infinite-scroll-spinner></infinite-scroll-spinner>")($scope);
    $element.append(spinner);
    $element.bind("scroll", handleInfiniteScroll);
    setTimeout(function () {
      toggleSpinner($scope.completed);
      $scope.$apply();
    }, 100);
  }
  /**
   * Call parent's callback if user has scrolled to the bottom of the container
   */


  function handleInfiniteScroll() {
    if (!$scope.completed && isSpinnerAboveTheFold()) {
      $scope.callback();
    }
  }
  /**
   * Check if the the spinner is above the fold, i.e. visible by the user
   *
   * @returns {boolean}
   */


  function isSpinnerAboveTheFold() {
    var container = $element;
    var spinner = angular.element(document).find("infinite-scroll-spinner");
    var theFold = container.offset().top + container.height();
    var spinnerBottom = spinner.offset().top + spinner.height();
    return spinnerBottom <= theFold;
  }
  /**
   * Hide or Show the spinner depending if there is still progressive data to load
   *
   * @param {boolean} completed -
   */


  function toggleSpinner(completed) {
    $scope.$broadcast("infinite-scroll-completed", completed);
  }

  $scope.$watch("completed", toggleSpinner);
}

angular.module("app.directives").directive("infiniteScroll", AppInfiniteScrollDirective);