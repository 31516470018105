"use strict";
/**
 * Controller to manage direct offer.
 *
 * @param {object} $scope - Controller scope.
 * @param {object} $stateParams - State parameters.
 * @param {object} $state - Module state.
 * @param {object} environmentService - Environment service.
 * @param {object} directOfferService - Direct offer service.
 * @param {object} notifyUserService - User notification service.
 * @param {object} $mdDialog - Material design dialog.
 * @param {object} $mdToast - Material design toast.
 * @param {object} $window - $window
 * @param {string} apiurl - The api url.
 */

DirectOfferHomeController.$inject = ["$scope", "$stateParams", "$state", "environmentService", "directOfferService", "notifyUserService", "$mdDialog", "$mdToast", "$window", "apiurl"];

function DirectOfferHomeController($scope, $stateParams, $state, environmentService, directOfferService, notifyUserService, $mdDialog, $mdToast, $window, apiurl) {
  $scope.loading = true;
  $scope.keys = Object.keys;
  $scope.openTab = openTab;
  $scope.createFranco = createFranco;
  $scope.editFranco = editFranco;
  $scope.deleteFile = deleteFile;
  $scope.openFile = openFile;
  $scope.directOfferParameters = {};
  $scope.createOrUpdateDirectOfferParameters = createOrUpdateDirectOfferParameters;
  $scope.sendDirectOfferNotificationsSummary = sendDirectOfferNotificationsSummary;
  $scope.getDirectOfferNotifications = getDirectOfferNotifications;
  $scope.getLaboratoryPriceRanges = getLaboratoryPriceRanges;
  $scope.createPriceRange = createPriceRange;
  $scope.editPriceRange = editPriceRange;
  $scope.deletePriceRange = deletePriceRange;
  $scope.orders = [];
  $scope.selected = [];
  $scope.getPharmaMlParameters = getPharmaMlParameters;
  $scope.createPharmaMlParameter = createPharmaMlParameter;
  $scope.deletePharmaMlParameters = deletePharmaMlParameters;
  $scope.editPharmaMlParameter = editPharmaMlParameter;
  $scope.setSoldOutAlertParameters = setSoldOutAlertParameters;
  $scope.notifications = [];
  $scope.notificationsSummarySent = false;
  $scope.importConfig = {
    entityName: "LaboratoryHealthcenterPharmamlParameter"
  };
  $scope.query = {
    order: "name",
    limit: 10,
    page: 1
  };
  $scope.tabs = [{
    id: 1,
    name: " Boutique espace Marque",
    method: getDirectOfferParameters,
    slug: "direct-offer-parameters",
    active: true,
    isActive: true
  }, {
    id: 2,
    name: "Gestion des francos",
    method: getLaboratoryFrancos,
    slug: "franco-list",
    isActive: true
  }, {
    id: 3,
    name: "Gestion des tarifs",
    method: getLaboratoryPriceRanges,
    slug: "prices-list",
    isActive: true
  }, {
    id: 4,
    name: "Commandes",
    method: getShopLaboratoryOrders,
    slug: "orders-list",
    isActive: true
  }, {
    id: 5,
    name: "Idenfifiants Pharma ML",
    method: getPharmaMlParameters,
    slug: "pharmaml-parameters-list",
    isActive: true
  }, {
    id: 6,
    name: "Gestion de l’alerte",
    method: getSoldOutAlertParameters,
    slug: "soldout-alert",
    isActive: true
  }];
  $scope.directOfferCommunicationChannels = [{
    isSendByEmail: 0,
    title: "Pharma ML"
  }, {
    isSendByEmail: 1,
    title: "Email"
  }];
  $scope.tinymceOptions = {
    height: 300,
    selector: "textarea",
    // change this value according to your HTML
    plugins: "code visualblocks",
    code_dialog_width: 600,
    content_css: ["//fonts.googleapis.com/css?family=Lato:300,300i,400,400i", "//www.tinymce.com/css/codepen.min.css"],
    style_formats: [{
      title: "Headers",
      items: [{
        title: "h1",
        block: "h1"
      }, {
        title: "h2",
        block: "h2"
      }, {
        title: "h3",
        block: "h3"
      }, {
        title: "h4",
        block: "h4"
      }, {
        title: "h5",
        block: "h5"
      }, {
        title: "h6",
        block: "h6"
      }]
    }, {
      title: "Blocks",
      items: [{
        title: "p",
        block: "p"
      }, {
        title: "div",
        block: "div"
      }, {
        title: "pre",
        block: "pre"
      }]
    }, {
      title: "Containers",
      items: [{
        title: "section",
        block: "section",
        wrapper: true,
        merge_siblings: false
      }, {
        title: "article",
        block: "article",
        wrapper: true,
        merge_siblings: false
      }, {
        title: "blockquote",
        block: "blockquote",
        wrapper: true
      }, {
        title: "hgroup",
        block: "hgroup",
        wrapper: true
      }, {
        title: "aside",
        block: "aside",
        wrapper: true
      }, {
        title: "figure",
        block: "figure",
        wrapper: true
      }]
    }],
    visualblocks_default_state: true,
    end_container_on_empty_block: true,
    theme: "modern",
    toolbar1: "visualblocks | code | formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
    image_advtab: true,
    templates: [{
      title: "Test template 1",
      content: "Test 1"
    }, {
      title: "Test template 2",
      content: "Test 2"
    }]
  };
  var defaultToDate = new Date();
  var defaultFromDate = new Date();
  defaultFromDate.setDate(defaultToDate.getDate() - 7);
  $scope.notificationFilters = {
    fromDate: defaultFromDate,
    toDate: defaultToDate
  };
  $scope.laboratory = {};
  loader();
  /**
   * Loader
   */

  function loader() {
    loadAccess().then(function () {
      // open the right tab
      if ($stateParams.activeTab) {
        openTab($scope.tabs[$stateParams.activeTab]);
      } else {
        openTab($scope.tabs[0]);
      }

      getDirectOfferNotifications();
    });
  }
  /**
   * loadAccess
   *
   * @returns {Promise}
   */


  function loadAccess() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.access = response;
      return response;
    });
  }
  /**
   * Open the tab
   *
   * @param {object} myTab - The tab to open
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";
        tab.method();
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Get the direct offer parameters
   *
   * @returns {Promise} The direct offer parameters
   */


  function getDirectOfferParameters() {
    $scope.loading = true;
    var laboratory = {
      laboratoryId: $scope.access.entityId
    };
    return directOfferService.getDirectOfferParameters(laboratory).then(function (response) {
      $scope.directOfferParameters = response;
      $scope.laboratory = response[0] ? response[0].laboratory : {};

      if (response && response.isSendByEmail !== null) {
        $scope.directOfferParameters.communicationChannel = $scope.directOfferCommunicationChannels[response.isSendByEmail];
      } else {
        $scope.directOfferParameters = {
          laboratoryId: $scope.access.entityId
        };
      }

      $scope.loading = false;
      return directOfferService.getDirectOfferShops();
    }).then(function (shops) {
      $scope.directOfferShops = [];
      shops.forEach(function (shop) {
        var tmpShop = $scope.directOfferParameters.find(function (laboratoryShop) {
          return shop.id === laboratoryShop.laboratoryDirectOfferShopId;
        });

        if (!shop.laboratory) {
          shop.laboratory = {};
        }

        if (tmpShop) {
          shop.hasShop = true;
        }

        var mergedLaboratoryShop = Object.assign(shop, tmpShop);

        if (mergedLaboratoryShop.isSendByEmail !== null) {
          mergedLaboratoryShop.communicationChannel = $scope.directOfferCommunicationChannels[mergedLaboratoryShop.isSendByEmail];
        }

        $scope.directOfferShops.push(mergedLaboratoryShop);
      });
    });
  }
  /**
   * Create or update the direct offer parameters
   *
   * @param {object} labo - The laboratory.
   *
   * @returns {Promise} The direct offer parameters
   */


  function createOrUpdateDirectOfferParameters(labo) {
    $scope.directOfferShops.forEach(function (shop) {
      if (shop.communicationChannel) {
        shop.isSendByEmail = shop.communicationChannel.isSendByEmail;
      }

      if (shop.hasShop) {
        shop.laboratoryDirectOfferShopId = shop.laboratoryDirectOfferShop ? shop.laboratoryDirectOfferShop.id : shop.id;
        shop.laboratory.hasDirectOfferShop = true;
      }
    });
    var params = {
      laboratory: labo,
      shops: $scope.directOfferShops,
      laboratoryId: $scope.access.entityId
    };
    return directOfferService.updateDirectOfferParameters(params).then(function (response) {
      notifyUserService.showSuccessToast("Les paramètres ont bien été enregistrés.");
      return response;
    });
  }
  /**
   * Get a product list with prices of the wholesaler for the laboratory
   *
   * @returns {Promise} The products
   */


  function getLaboratoryFrancos() {
    $scope.loading = true;
    var laboratory = {
      laboratoryId: $scope.access.entityId
    };
    return directOfferService.getFrancos(laboratory).then(function (response) {
      $scope.francos = response;
      $scope.loading = false;
      return response;
    });
  }
  /**
   * Open the franco creation form
   *
   * @param {number} laboratoryId - The laboratory identifier
   */


  function createFranco(laboratoryId) {
    $state.go("app.directOffer.francoEdit", {
      laboratoryId: laboratoryId
    });
  }
  /**
   * Open the franco modification form
   *
   * @param {number} laboratoryId - The laboratory identifier
   * @param {number} francoId - The franco identifier
   */


  function editFranco(laboratoryId, francoId) {
    $state.go("app.directOffer.francoEdit", {
      laboratoryId: laboratoryId,
      id: francoId
    });
  }
  /**
   * Confirm box for deletion of a franco
   *
   * @param {event} event - The event
   * @param {number} francoId - The franco identifier
   */


  $scope.confirmDeleteFranco = function (event, francoId) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain(e) de vouloir supprimer ce franco ?").ariaLabel("suppression du franco").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      deleteFranco(francoId);
    });
  };
  /**
   * Delete one franco
   *
   * @param {number} francoId - The franco identifier
   *
   * @returns {Promise} Franco deletion promise.
   */


  function deleteFranco(francoId) {
    var franco = {
      laboratoryId: $scope.access.entityId,
      id: francoId
    };
    return directOfferService.deleteFranco(franco).then(function () {
      $mdToast.show($mdToast.simple().textContent("Le franco a été correctement supprimé.").position("top right"));
      getLaboratoryFrancos();
    });
  }
  /**
   * Get price ranges.
   *
   * @returns {Promise} The price ranges.
   */


  function getLaboratoryPriceRanges() {
    $scope.loading = true;
    var laboratory = {
      laboratoryId: $scope.access.entityId
    };
    return directOfferService.getPriceRanges(laboratory).then(function (response) {
      $scope.priceRanges = response;
      $scope.loading = false;
    });
  }
  /**
   * Open the price range creation form
   *
   * @param {number} laboratoryId - The laboratory identifier
   */


  function createPriceRange(laboratoryId) {
    $state.go("app.directOffer.priceRangeEdit", {
      laboratoryId: laboratoryId
    });
  }
  /**
   * Open the price range modification form
   *
   * @param {number} laboratoryId - The laboratory identifier
   * @param {number} priceRangeId - The price range identifier
   */


  function editPriceRange(laboratoryId, priceRangeId) {
    $state.go("app.directOffer.priceRangeEdit", {
      laboratoryId: laboratoryId,
      id: priceRangeId
    });
  }
  /**
   * Delete a laboratory product price
   *
   * @param {number} priceRangeId - The price range ID
   *
   * @returns {Promise} The deleted product price
   */


  function deletePriceRange(priceRangeId) {
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain(e) de vouloir supprimer cette gamme tarifaire ?").ariaLabel("suppression de gamme tarifaire").ok("Continuer").cancel("Annuler");
    return $mdDialog.show(confirm).then(function () {
      $scope.loading = true;
      var priceRange = {
        laboratoryId: $scope.access.entityId,
        id: priceRangeId
      };
      return directOfferService.deletePriceRange(priceRange).then(function (response) {
        $scope.loading = false;
        getLaboratoryPriceRanges();
        return response;
      });
    });
  }
  /**
   * Get the order list
   *
   * @returns {Promise} The orders
   */


  function getShopLaboratoryOrders() {
    $scope.loading = true;
    var laboratory = {
      laboratoryId: $scope.access.entityId
    };
    return directOfferService.getShopLaboratoryOrders(laboratory).then(function (response) {
      response.map(function (currentValue) {
        currentValue.orderDateTime = new Date(currentValue.orderDate.date);
        return currentValue;
      });
      $scope.orders = response;
      $scope.loading = false;
      return response;
    });
  }
  /**
   * Get pharmaml parameters.
   *
   * @returns {Promise} The pharmaml parameters.
   */


  function getPharmaMlParameters() {
    $scope.loading = true;
    var laboratory = {
      laboratoryId: $scope.access.entityId
    };
    return directOfferService.getPharmaMlParameters(laboratory, $scope.query.page, $scope.query.limit).then(function (response) {
      $scope.pharmaMlParameters = response.data;
      $scope.pharmaMlParametersTotal = response.meta.pagination.total;
      $scope.loading = false;
      return response;
    });
  }
  /**
   * Open the pharmaMl parameter creation form
   *
   * @param {number} laboratoryId - The laboratory identifier
   */


  function createPharmaMlParameter(laboratoryId) {
    $state.go("app.directOffer.pharmaMlParameterEdit", {
      laboratoryId: laboratoryId
    });
  }
  /**
   * Delete the pharmaMl parameters.
   *
   * @param {number} laboratoryId - The laboratory identifier
   */


  function deletePharmaMlParameters(laboratoryId) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain(e) de vouloir supprimer les identifiants pharmaml ?").ariaLabel("suppression des pharmaml").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      $scope.loading = true;
      directOfferService.deletePharmaMlParameters({
        laboratoryId: laboratoryId
      }).then(function () {
        $scope.pharmaMlParameters = [];
        $scope.loading = false;
      });
    });
  }
  /**
   * Open the pharmaMl parameter modification form
   *
   * @param {number} laboratoryId - The laboratory identifier
   * @param {number} pharmaMlParameterId - The pharmaMl parameter identifier
   */


  function editPharmaMlParameter(laboratoryId, pharmaMlParameterId) {
    $state.go("app.directOffer.pharmaMlParameterEdit", {
      laboratoryId: laboratoryId,
      id: pharmaMlParameterId
    });
  }
  /**
   * Get soldout alert parameters
   *
   * @returns {Promise} The soldout alert parameters
   */


  function getSoldOutAlertParameters() {
    $scope.loading = true;
    var laboratory = {
      laboratoryId: $scope.access.entityId
    };
    return directOfferService.getSoldOutAlertParameters(laboratory).then(function (response) {
      $scope.soldOutAlertParameters = response;
      $scope.loading = false;
      return response;
    });
  }
  /**
   * set soldout alert parameters
   *
   * @returns {Promise} The soldout alert parameters
   */


  function setSoldOutAlertParameters() {
    $scope.loading = true;
    $scope.soldOutAlertParameters.laboratoryId = $scope.access.entityId;
    return directOfferService.setSoldOutAlertParameters($scope.soldOutAlertParameters).then(function (response) {
      $mdToast.show($mdToast.simple().textContent("Les paramètres pour l'alerte rupture ont bien été pris en compte.").position("top right"));
      $scope.soldOutAlertParameters = response;
      $scope.loading = false;
      return response;
    });
  }
  /**
   * Send direct offer notifications summary to current laboratory commissioners.
   *
   * @returns {Promise} The request promise.
   */


  function sendDirectOfferNotificationsSummary() {
    $scope.notificationsSummarySent = true;
    var laboratory = {
      laboratoryId: $scope.access.entityId
    };
    return directOfferService.sendDirectOfferNotificationsSummary(laboratory, $scope.notificationFilters).then(function () {
      $mdToast.showSimple("Le rapport a été envoyé aux délégués.");
    });
  }
  /**
   * Fetch direct offer notifications.
   *
   * @returns {Promise} Direct offer notifications promise.
   */


  function getDirectOfferNotifications() {
    var laboratory = {
      laboratoryId: $scope.access.entityId
    };
    $scope.notificationsPromise = directOfferService.getDirectOfferNotificationsSummary(laboratory, $scope.notificationFilters).then(function (notifications) {
      $scope.notifications = notifications;
      $scope.notificationsSummarySent = false;
    });
    return $scope.notificationsPromise;
  }
  /**
   * Delete the file
   */


  function deleteFile() {
    $scope.laboratory.cgvPath = null;
    $scope.laboratory.cgv = null;
  }
  /**
   * Open the file.
   */


  function openFile() {
    $window.open(apiurl + "/" + $scope.laboratory.cgvPath, "_blank");
  }
}

angular.module("app.directOffer").controller("directOfferHomeController", DirectOfferHomeController);