"use strict";
/**
 * Shop Laboratory service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

ShopLaboratoryService.$inject = ["apiClient"];

function ShopLaboratoryService(apiClient) {
  return {
    /**
     * Get shop laboratory orders.
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    getLaboratoryOrders: function getLaboratoryOrders(params) {
      return apiClient.get("/shop/laboratories/".concat(params.laboratoryId, "/orders")).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.laboratory").service("shopLaboratoryService", ShopLaboratoryService);