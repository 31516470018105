"use strict";
/**
 * Prestation form controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdToast - $mdToast.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $rootScope - $rootScope.
 * @param {string} apiurl - API URL.
 * @param {object} environmentService - Environment service.
 * @param {object} prestationConfig - Prestation configuration.
 * @param {object} prestationService - Prestation service.
 * @param {object} productService - Product service.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} healthcenterService - Healthcenter service.
 * @param {object} sessionStorageService - Session storage service.
 */

PrestationFormController.$inject = ["$scope", "$state", "$stateParams", "$mdToast", "$mdDialog", "$rootScope", "apiurl", "environmentService", "prestationConfig", "prestationService", "productService", "laboratoriesService", "healthcenterService", "sessionStorageService"];

function PrestationFormController($scope, $state, $stateParams, $mdToast, $mdDialog, $rootScope, apiurl, environmentService, prestationConfig, prestationService, productService, laboratoriesService, healthcenterService, sessionStorageService) {
  $scope.prestationConfig = prestationConfig;
  var vm = this;
  vm.apiurl = apiurl;
  vm.token = sessionStorageService.getToken();
  vm.objectiveRewardGoal = 1;
  vm.objectiveRewardType = {}; // Prestation

  vm.prestation = {
    name: "",
    description: "",
    file: {},
    laboratoryId: "",
    laboratories: [],
    startDate: "",
    endDate: "",
    products: [],
    participants: [],
    rewards: {
      objectives: []
    },
    isSynchronized: false
  };

  if ($stateParams.healthcenterId && $stateParams.healthcenterName) {
    vm.pharmaPosted = {
      id: $stateParams.healthcenterId,
      name: $stateParams.healthcenterName
    };
    vm.prestation.participants.push(vm.pharmaPosted);
  }

  vm.getCurrentAccess = getCurrentAccess();
  vm.prestationId = $stateParams.id;
  vm.getPrestation = getPrestation();
  vm.createPrestation = createPrestation;
  vm.updatePrestation = updatePrestation;
  vm.isStepValid = isStepValid;
  vm.isProductStepSkipped = false;
  vm.skipProductStep = skipProductStep; // Date

  vm.setStartDate = setStartDate;
  vm.setEndDate = setEndDate; // Laboratory

  vm.laboratoryText = null;
  vm.laboratorySearch = laboratorySearch; // Product

  vm.products = [];
  vm.removeProduct = removeProduct;
  vm.productDatatable = {
    limit: 10,
    page: 1
  }; // Participant

  vm.participants = [];
  vm.removeParticipant = removeParticipant;
  vm.participantDatatable = {
    limit: 10,
    page: 1
  }; // Durations

  vm.durations = [{
    id: 1,
    name: "1 mois",
    number: 1,
    type: "months"
  }, {
    id: 2,
    name: "2 mois",
    number: 2,
    type: "months"
  }, {
    id: 3,
    name: "3 mois",
    number: 3,
    type: "months"
  }, {
    id: 4,
    name: "6 mois",
    number: 6,
    type: "months"
  }, {
    id: 5,
    name: "1 an",
    number: 1,
    type: "years"
  }]; // Reward

  vm.objectiveRewards = [];
  vm.addObjectiveReward = addObjectiveReward;
  vm.removeObjectiveReward = removeObjectiveReward; // Recommendation

  vm.recommendation = {};
  vm.prestation.recommendations = [];
  vm.addRecommendation = addRecommendation;
  vm.removeRecommendation = removeRecommendation;
  vm.removeFileRecommendation = removeFileRecommendation; // Point

  vm.points = 0;
  vm.pointScale = 100;
  vm.pointRatio = 1.2;
  vm.convertObjectiveGainToPoints = convertObjectiveGainToPoints; // Form

  vm.submit = submit;
  vm.transformChip = transformChip;
  vm.loading = false;
  /** Get current access. */

  function getCurrentAccess() {
    environmentService.getEnvironment().then(function (access) {
      vm.access = access;
      vm.isLaboratory = isLaboratory(access);
    });
  }
  /**
   * Check if is laboratory.
   *
   * @param {object} access - The current user access.
   *
   * @returns {boolean}
   */


  function isLaboratory(access) {
    if (access.accessPointType === 1) {
      vm.laboratoryName = access.entity.name;
      vm.prestation.laboratoryId = access.entity.id;
      vm.productPrestationSearchConfig.must = {
        "laboratory.id": [access.entity.id]
      };
      return true;
    }

    return false;
  }
  /**
   * Get prestation
   */


  function getPrestation() {
    var id = vm.prestationId;

    if (id) {
      prestationService.read(id).then(function (prestation) {
        var startDate = new Date(prestation.startDatetime);
        var endDate = new Date(prestation.endDatetime);
        var imagePath = apiurl + "/images/" + prestation.imagePath + "?w=1200&token=" + vm.token;
        vm.prestation = {
          name: prestation.name,
          startDate: startDate,
          endDate: endDate,
          description: prestation.description,
          laboratory: prestation.laboratory,
          imagePath: imagePath,
          participants: extractParticipants(prestation.participants),
          products: extractProducts(prestation.products),
          rewards: extractRewards(prestation.rewards),
          recommendations: prestation.recommendations,
          isSynchronized: prestation.isSynchronized,
          isPublished: prestation.isPublished
        };
        vm.addRecoTest = prestation.recommendations.length ? 1 : 2;
        vm.addRewardTest = prestation.rewards.length ? 1 : 2;
      });
    }
  }
  /**
   * Return products formated from prestation
   *
   * @param {Array} products - Products.
   *
   * @returns {Array}
   */


  function extractProducts(products) {
    return products.map(function (product) {
      return {
        id: product.presentation.id,
        name: product.presentation.name,
        cip: product.presentation.code
      };
    });
  }
  /**
   * Return participants formated from prestation
   *
   * @param {Array} participants - Participants.
   *
   * @returns {Array}
   */


  function extractParticipants(participants) {
    return participants.map(function (participant) {
      return {
        id: participant.id,
        organizationId: participant.organization.id,
        name: participant.organization.morphable.name
      };
    });
  }
  /**
   * Return rewards formated from prestation
   *
   * @param {Array} rewards - Rewards.
   *
   * @returns {Array}
   */


  function extractRewards(rewards) {
    var prestationRewards = [];
    prestationRewards.objectives = [];
    angular.forEach(rewards, function (reward) {
      prestationRewards.objectives.push({
        entityTypeId: 2,
        rewardRemunerationId: reward.rewardRemunerationId,
        amount: reward.amount,
        gain: reward.amount,
        objective: reward.objective,
        goal: reward.objective
      });
      vm.objectiveRewardGain = reward.amount;
      vm.objectiveRewardsTeaser = "Gagnez " + reward.amount + " " + vm.objectiveRewardRemuneration.label + " pour " + reward.objective + " photo(s) prise(s)";
    });
    return prestationRewards;
  }
  /**
   * Search laboratory
   */


  vm.configLabo = {
    event: "addLaboPrestation",
    search: ["laboratory.id"],
    tag: "laboratoryPrestation",
    limits: {
      laboratory: 1
    }
  };
  /**
   * Event
   */

  $scope.$on("addLaboPrestation", function (event, query) {
    vm.laboratoryName = query.laboratory[0].name;
    vm.prestation.laboratoryId = query.laboratory[0].id;
    vm.prestation.laboratory = query.laboratory[0];
  });
  /**
   * Search laboratory
   *
   * @param {string} search - Search.
   *
   * @returns {Promise}
   */

  function laboratorySearch(search) {
    var query = "";

    if (search) {
      query += "query=" + search;
    }

    return laboratoriesService.getLabosList(query).then(function (response) {
      return response.laboratory;
    });
  }
  /**
   * Search products
   */


  vm.productPrestationSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    event: "addProductPrestation",
    tag: "configProductPrestation",
    autoLaunchEvent: true
  };
  /**
   * Event
   */

  $scope.$on("addProductPrestation", function (event, query) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(query, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(query[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in query) {
      _loop(type);
    }

    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find(vm.prestation.products, function (prod) {
          return product.id === prod.id;
        })) {
          vm.prestation.products.push(product);
        }
      });
    });
  });
  /**
   * Search participants
   */

  vm.participantPrestationSearchConfig = {
    event: "addParticipantsPrestation",
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
    tag: "participant",
    autoLaunchEvent: true
  };
  /**
   * Event
   */

  $scope.$on("addParticipantsPrestation", function (event, query) {
    var queryPharma = {};
    queryPharma.type = {};
    var groups = [];

    var _loop2 = function _loop2(type) {
      if (type !== "groups") {
        if (Object.prototype.hasOwnProperty.call(query, type)) {
          if (typeof queryPharma.type[type] === "undefined") {
            queryPharma.type[type] = [];
          }

          angular.forEach(query[type], function (obj) {
            if (type === "uga") {
              queryPharma.type[type].push(obj.name);
            } else {
              queryPharma.type[type].push(obj.id);
            }
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(query, type)) {
        angular.forEach(query[type], function (obj) {
          groups.push(obj);
        });
      }
    };

    for (var type in query) {
      _loop2(type);
    }

    healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      angular.forEach(pharmas, function (pharma) {
        if (!_.find(vm.prestation.participants, function (participant) {
          return pharma.id === participant.id;
        })) {
          pharma.entityTypeId = 2;
          vm.prestation.participants.push(pharma);
        }
      });
      angular.forEach(groups, function (group) {
        if (!_.find(vm.prestation.participants, function (participant) {
          return group.id === participant.id;
        })) {
          group.entityTypeId = 3;
          vm.prestation.participants.push(group);
        }
      });
    });
  });
  /**
   * Watchers.
   */

  $scope.$watch("vm.prestation.laboratory", function () {
    if (vm.prestation.laboratory) {
      var laboratoryIds = [vm.prestation.laboratory.id];
      vm.productPrestationSearchConfig.must = {
        "laboratory.id": laboratoryIds
      };
    } else if (!vm.isLaboratory) {
      vm.productPrestationSearchConfig.must = {};
    }
  });
  /**
   * Remove product from selection
   *
   * @param {number} productId - Product ID.
   */

  function removeProduct(productId) {
    var products = vm.prestation.products;
    angular.forEach(products, function (product, index) {
      if (product.id === productId) {
        products.splice(index, 1);
      }
    });
  }
  /**
   * Remove participant from selection
   *
   * @param {number} participantOrganizationId - Participant organization ID.
   */


  function removeParticipant(participantOrganizationId) {
    var participants = vm.prestation.participants;
    angular.forEach(participants, function (participant, index) {
      if (participant.organizationId === participantOrganizationId) {
        participants.splice(index, 1);
      }
    });
  }
  /**
   * Add objective reward
   */


  function addObjectiveReward() {
    var gain = vm.objectiveRewardGain;
    var remuneration = vm.objectiveRewardRemuneration;
    var goal = vm.objectiveRewardGoal;
    var value = vm.objectiveRewardGain;
    var objective = vm.objectiveRewardType;

    if (vm.addRewardTest === 1) {
      vm.objectiveRewards.push({
        teaser: "Gagnez " + value + " " + remuneration.label + " pour " + goal + " photo prise"
      });
      vm.prestation.rewards.objectives.push({
        gain: gain,
        rewardRemunerationId: remuneration.id,
        goal: goal,
        rewardObjectiveId: objective.id
      });
    }
  }
  /**
   * Remove objective reward
   *
   * @param {number} index - Index.
   */


  function removeObjectiveReward(index) {
    vm.objectiveRewards.splice(index, 1);
    vm.prestation.rewards.objectives.splice(index, 1);
  }

  vm.remove = function () {
    vm.prestation.file.id = false;
  };
  /**
   * Add recommendation
   *
   * @param {object} form - Form.
   */


  function addRecommendation(form) {
    vm.prestation.recommendations.push(vm.recommendation);
    vm.recommendation = {};
    form.$setPristine();
    form.$setUntouched();
  }
  /**
   * Remove recommendation
   *
   * @param {number} index - Index.
   */


  function removeRecommendation(index) {
    vm.prestation.recommendations.splice(index, 1);
  }
  /**
   * Remove file recommendation
   */


  function removeFileRecommendation() {
    delete vm.recommendation.file;
  }
  /**
   * Format data
   *
   * @returns {object}
   */


  function formatData() {
    var startDate = moment(vm.prestation.startDate);
    var endDate = moment(vm.prestation.endDate);
    var products = [];
    var participants = [];
    var rewards = [];
    var recommendations = [];
    var laboratoryId = vm.prestation.laboratoryId;
    angular.forEach(vm.prestation.products, function (product) {
      products.push({
        presentationId: product.id
      });
    });
    angular.forEach(vm.prestation.participants, function (participant) {
      participants.push({
        organization_id: participant.organizationId || participant.organization_id
      });
    });
    angular.forEach(vm.prestation.rewards.objectives, function (reward) {
      rewards.push({
        entityTypeId: 2,
        rewardRemunerationId: reward.rewardRemunerationId,
        amount: reward.gain,
        objective: reward.goal
      });
    });
    angular.forEach(vm.prestation.recommendations, function (recommendation) {
      var fileId = recommendation.file ? recommendation.file.id : recommendation.fileId;
      recommendations.push({
        id: recommendation.id,
        name: recommendation.name,
        description: recommendation.description,
        fileId: fileId
      });
    });
    return {
      name: vm.prestation.name,
      description: vm.prestation.description,
      fileId: vm.prestation.file ? vm.prestation.file.id : null,
      laboratoryId: laboratoryId,
      startDatetime: startDate.format("YYYY-MM-DD"),
      endDatetime: endDate.format("YYYY-MM-DD"),
      products: products,
      participants: participants,
      rewards: rewards,
      recommendations: recommendations,
      isSynchronized: vm.prestation.isSynchronized
    };
  }
  /**
   * Create prestation
   *
   * @param {boolean} isPublished - Enable automatic publishing.
   */


  function createPrestation(isPublished) {
    var data = formatData();
    data.isPublished = isPublished || false;
    prestationService.create(data).then(function () {
      $mdToast.show($mdToast.simple().textContent("La mise en avant est ajoutée").position("top right"));
      vm.loading = false;
      vm.goToList();
    });
  }
  /**
   * Update prestation
   *
   * @param {boolean} isPublished - Enable automatic publishing.
   */


  function updatePrestation(isPublished) {
    var data = formatData();
    data.isPublished = isPublished || false;
    prestationService.update(vm.prestationId, data).then(function () {
      $mdToast.show($mdToast.simple().textContent("La mise en avant est modifiée").position("top right"));
      vm.loading = false;
      vm.goToList();
    });
  }
  /**
   * Set start date
   */


  function setStartDate() {
    vm.prestation.startDate = new Date();
  }
  /**
   * Set end date
   */


  function setEndDate() {
    var duration = vm.prestation.duration;
    var now = new Date(vm.prestation.startDate);
    var date = moment(now);
    date.add(duration.number, duration.type);
    vm.prestation.endDate = date.toDate();
  }
  /**
   * Convert objective gain to points
   */


  function convertObjectiveGainToPoints() {
    vm.objectiveRewardPoints = Math.round(vm.objectiveRewardGain * vm.pointScale / vm.pointRatio);
  }
  /**
   * Convert objective gain to euros
   */


  vm.convertObjectiveGainToEuros = function () {
    var money = vm.objectiveRewardGain / vm.pointScale * vm.pointRatio;
    vm.objectiveRewardEuros = money.toFixed(2);
    var remuneration = vm.objectiveRewardRemuneration;
    var goal = vm.objectiveRewardGoal;
    var value = vm.objectiveRewardGain;
    vm.objectiveRewardsTeaser = "Gagnez " + value + " " + remuneration.label + " pour " + goal + " photo prise";
  };
  /**
   * Go to prestation list
   */


  vm.goToList = function () {
    $state.go("app.prestation.list");
  };
  /**
   * Submit
   *
   * @param {boolean} isPublished - Enable automatic publishing.
   */


  function submit(isPublished) {
    vm.loading = true;
    vm.prestationId ? vm.updatePrestation(isPublished) : vm.createPrestation(isPublished);
  }
  /**
   * Open recommendation.
   *
   * @param {object} recommendation - Recommendation.
   */


  vm.openRecommendation = function (recommendation) {
    window.open(apiurl + "/filemanager/file/show?path=" + recommendation.file.path + "&token=" + recommendation.file.token);
  };
  /**
   * Return the proper object when the append is called
   *
   * @param {object|string} chip - Chip.
   *
   * @returns {object}
   */


  function transformChip(chip) {
    // If it is an object, it's already a known chip
    if (angular.isObject(chip)) {
      return chip;
    } // Otherwise, create a new one


    return {
      name: chip,
      type: "new"
    };
  }

  $rootScope.$on("formsPrestation", function (forms, data) {
    switch (data.$name) {
      case "recoStep":
        if (typeof vm.recommendation.name !== "undefined" || typeof vm.recommendation.desc !== "undefined") {
          var confirmRecoStep = $mdDialog.confirm();
          confirmRecoStep.title("Recommandation").textContent("Vous avez oublié d'ajouter la recommandation, souhaitez-vous l’ajouter?").targetEvent(event).ok("Ajouter").cancel("Ne pas ajouter");
          $mdDialog.show(confirmRecoStep).then(function () {
            vm.addRecommendation(data);
          });
        }

        break;

      case "participantStep":
        if (vm.prestation.participants.length === 0 && !vm.prestation.isSynchronized) {
          var confirmParticipantStep = $mdDialog.confirm();
          confirmParticipantStep.title("Participants").textContent("Vous n'avez ajouté aucun participant à votre mise en avant, n'oubliez pas de cliquer sur le bouton 'Ajouter' en bas du moteur de recherche").targetEvent(event).ok("Continuer").cancel("Annuler");
          $mdDialog.show(confirmParticipantStep);
        }

        break;

      case "rewardStep":
        vm.prestation.rewards.objectives = [];
        addObjectiveReward();
        break;
    }
  });
  /**
   * Check step validation
   *
   * @param {number} index - Index.
   *
   * @returns {boolean}
   */

  function isStepValid(index) {
    switch (index) {
      case 0:
        if (!vm.prestation.name && !vm.prestation.description && !vm.prestation.endDate) {
          return false;
        }

        break;

      case 1:
        if (!vm.recommendation) {
          return false;
        }

        break;

      case 2:
        if (!vm.prestation.products.length) {
          return false;
        }

        break;

      case 3:
        if (!vm.prestation.participants.length) {
          return false;
        }

        break;

      case 4:
        if (!vm.remunerations) {
          return false;
        }

        break;
    }

    return true;
  }
  /**
   * Skip the product step.
   *
   * @param {number} index - The current step index.
   */


  function skipProductStep(index) {
    if (index === 2 && !vm.prestation.products.length) {
      vm.isProductStepSkipped = true;
    }
  }
}

angular.module("app.prestation").controller("PrestationFormController", PrestationFormController);