"use strict";
/**
 * Top healthcenters list controller.
 *
 * @param {object} $scope - Scope.
 * @param {object} groupingDashboardCapService - Grouping dashboard cap service.
 */

TopHealthcentersListController.$inject = ["$scope", "groupingDashboardCapService"];

function TopHealthcentersListController($scope, groupingDashboardCapService) {
  var $topHealthcentersLimit = 10;
  $scope.topHealthcenters = [];
  $scope.loading = false;
  loader();
  /**
   * Loader.
   */

  function loader() {
    $scope.loading = true;
    groupingDashboardCapService.getTopCustomerHealthcenters($topHealthcentersLimit).then(function (topHealthcenters) {
      $scope.topHealthcenters = topHealthcenters;
    })["finally"](function () {
      $scope.loading = false;
    });
  }
}

angular.module("app.dashboard").controller("topHealthcentersListController", TopHealthcentersListController);