"use strict";
/**
 * Medication summary patient controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} medicationSummaryService - medicationSummaryService.
 * @param {object} $state - $state.
 */

MedicationSummaryPatientController.$inject = ["$scope", "medicationSummaryService", "$state"];

function MedicationSummaryPatientController($scope, medicationSummaryService, $state) {
  $scope.getMedicationSummary = getMedicationSummary();
  $scope.goToStepState = goToStepState;
  $scope.mainTitle = {
    title: {
      text: "Bilan de médication de ",
      back: {
        state: "app.patients.medicationSummary",
        tooltip: "Retour à la fiche du patient"
      }
    }
  };
  $scope.title = {
    icon: "icon-format-list-bulleted",
    text: "Suivi de votre patient en 5 étapes",
    type: "2"
  };
  /**
   * Get a medication summary for a given patient
   *
   * @returns {object}
   */

  function getMedicationSummary() {
    return medicationSummaryService.getMedicationSummary().then(function (medicationSummary) {
      $scope.medicationSummary = medicationSummary;
      $scope.patient = medicationSummary.patient;
      $scope.mainTitle.title.text = $scope.mainTitle.title.text + medicationSummary.patient.firstName + " " + medicationSummary.patient.name;
      $scope.mainTitle.title.back.paramId = $scope.patient.id;
      return medicationSummary;
    });
  }
  /**
   * Go to step state.
   *
   * @param {object} step - Step.
   */


  function goToStepState(step) {
    $state.go(step.state);
  }
}

angular.module("app.patients").controller("medicationSummaryPatientController", MedicationSummaryPatientController);