"use strict";
/**
 * Reassort audit controller.
 *
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $scope - $scope.
 * @param {object} DTColumnBuilder - DTColumnBuilder.
 * @param {object} DTOptionsBuilder - DTOptionsBuilder.
 * @param {object} $compile - $compile.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} healthcenterService - Healthcenter service.
 * @param {object} analysisService - Analysis service.
 */

ReassortAuditController.$inject = ["$stateParams", "$mdDialog", "$scope", "DTColumnBuilder", "DTOptionsBuilder", "$compile", "StatisticsQueryService", "environmentService", "healthcenterService", "analysisService"];
SellinOfferModalController.$inject = ["$scope", "$mdDialog", "$state", "laboratory", "healthcenters", "products", "numberOfProducts", "quantityOfAllProducts", "totalPrice"];
DetailStocksProductGraphTabController.$inject = ["$q", "$mdDialog", "$scope", "product", "analysisService"];

function ReassortAuditController($stateParams, $mdDialog, $scope, DTColumnBuilder, DTOptionsBuilder, $compile, StatisticsQueryService, environmentService, healthcenterService, analysisService) {
  var vm = this;
  vm.loading = true;
  vm.isLoaded = false;
  vm.configSearch = {
    event: "stockReassortFilterChange",
    search: []
  };
  vm.usrquery = StatisticsQueryService.query;
  var currentEnvironment = environmentService.getEnvironment();
  currentEnvironment.then(function (env) {
    vm.currentEnv = env;
    var lab = {
      id: vm.currentEnv.entityId,
      name: vm.currentEnv.entity.name,
      type: "laboratory"
    };

    if (vm.currentEnv.accessPointType === 1 && vm.usrquery.must.laboratory.selected.length === 0 && !_.find(vm.usrquery.should.laboratory.selected, function (labo) {
      return labo.id === vm.currentEnv.entityId;
    })) {
      vm.usrquery.must.laboratory.selected.push(lab);
    } // Init here to get all variables defined


    vm.dtOptions = angular.merge(DTOptionsBuilder.fromFnPromise(init()).withOption("createdRow", createdRow), dtOptions);
  });
  vm.colors = ["#fab231", "#faeb4c", "#b7d787", "#89b74e", "#85caa0", "#9bd8df", "#218b9a"];
  vm.dtInstance = {};
  vm.dtOptions = {
    dom: "rt<'bottom'<'left'<'length'l>><'right'<'info'i><'pagination'p>>>",
    columnDefs: [{
      targets: [2, 3, 4, 5, 6, 8, 9],
      filterable: false,
      sortable: true,
      className: "dt-right"
    }, {
      targets: 7,
      filterable: false,
      sortable: false
    }],
    language: {
      sZeroRecords: "Aucun élément trouvé",
      sLengthMenu: "Afficher _MENU_ éléments",
      sInfo: "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
      sInfoEmpty: "Affichage de 0 à 0 sur 0 éléments",
      sInfoFiltered: "(filtré sur un total de _MAX_ éléments)",
      oPaginate: {
        sNext: "Suivant",
        sPrevious: "Précédent"
      }
    },
    pagingType: "simple",
    lengthMenu: [10, 20, 30, 50, 100, 500, 1000, 5000],
    pageLength: 10,
    responsive: false
  };
  /**
   * Initialize.
   *
   * @returns {Promise}
   */

  function init() {
    return healthcenterService.getDetails({
      id: $stateParams.id
    }).then(function (response) {
      vm.hc = response.healthcenter;

      if (!_.find(vm.usrquery.must.pharmas.selected, function (pharma) {
        return pharma.cip === vm.hc.cip;
      }) && vm.usrquery.should.pharmas.selected.length === 0) {
        vm.usrquery.must.pharmas.selected = [];
        vm.usrquery.must.pharmas.selected.push({
          cip: vm.hc.cip,
          name: vm.hc.name,
          type: "pharmas"
        });
      }

      if (vm.currentEnv.accessPointType === 1 && vm.usrquery.must.laboratory.selected.length === 0 && !_.find(vm.usrquery.should.laboratory.selected, function (labo) {
        return labo.id === vm.currentEnv.entityId;
      })) {
        var lab = {
          id: vm.currentEnv.entityId,
          name: vm.currentEnv.entity.name,
          type: "laboratory"
        };
        vm.usrquery.must.laboratory.selected.push(lab);
      }

      return StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
        vm.query = query;
        return launchSearch(vm.query, true);
      });
    });
  }
  /**
   * Launch search.
   *
   * @returns {Promise}
   */


  function launchSearch() {
    return analysisService.getLaboratoryStockByHealthcenter(vm.hc.cip, vm.query).then(function (response) {
      vm.detailStocksProducts = response.hits.hits;
      vm.detailStocksGeneral = response.aggregations;
      refreshColumn();
      vm.isLoaded = true;
      vm.loading = false;
      return vm.detailStocksProducts;
    });
  }

  vm.goDetail = function (ev, id) {
    var product = _.find(vm.detailStocksProducts, function (det) {
      return det._source.product.presentation === id.toString();
    })._source;

    $mdDialog.show({
      locals: {
        product: product
      },
      controller: DetailStocksProductGraphTabController,
      templateUrl: "app/main/healthcenters/detail/tabs/stocks-product-graph.tmpl.html",
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true
    });
  };

  vm.getGeneric = function (product) {
    return sumAllProduct(product);
  };

  vm.getCategory = function (product) {
    var needsCategory = 0;
    angular.forEach(product.category_data.hits.hits, function (prod) {
      needsCategory += prod._source.product.prop.calcul.quantity_propal;
    });
    return needsCategory;
  };

  vm.sumOrderNetMultiple = function (products) {
    var orderNetMuliple = 0;
    angular.forEach(products, function (data) {
      orderNetMuliple += data.calcul.orderNetMuliple;
    });
    return orderNetMuliple;
  };

  vm.addOrDeleteFromCart = function (prod) {
    if (prod.toCart === true) {
      vm.cart[prod._source.product.presentation] = {
        quantity: prod._source.needs.calcul.quantity_propal
      };
    } else {
      vm.cart[prod._source.product.presentation] = {
        quantity: 0
      };
    }
  };

  vm.setSellinOffer = function () {
    var quantityFields = document.getElementsByClassName("stock-quantity");
    var products = [];
    var productId = 0;
    var productCip = "";
    var productName = "";
    var productQuantity = 0;
    var productPrice = 0;
    var numberOfProducts = 0;
    var quantityOfAllProducts = 0;
    var totalPrice = 0;
    angular.forEach(quantityFields, function (quantityField) {
      if (!isNaN(quantityField.value) && quantityField.value > 0) {
        productId = quantityField.id.replace("product-stock-quantity-", "");
        productCip = document.getElementById("product-stock-cip-" + productId).value;
        productName = document.getElementById("product-stock-name-" + productId).value;
        productQuantity = parseInt(quantityField.value);
        productPrice = parseFloat(document.getElementById("product-stock-price-" + productId).value);
        numberOfProducts++;
        quantityOfAllProducts += productQuantity;
        totalPrice += productQuantity * productPrice;
        products.push({
          id: parseInt(productId),
          cip: productCip,
          name: productName,
          quantity: productQuantity
        });
      }
    });
    $mdDialog.show({
      locals: {
        laboratory: {
          id: vm.currentEnv.entityId,
          name: vm.currentEnv.entity.name
        },
        healthcenters: {
          id: $stateParams.id,
          entityTypeId: 2,
          name: vm.hc.name,
          cip: vm.hc.cip
        },
        products: products,
        numberOfProducts: numberOfProducts,
        quantityOfAllProducts: quantityOfAllProducts,
        totalPrice: totalPrice
      },
      controller: SellinOfferModalController,
      templateUrl: "app/main/statistics/healthcenter-audit/views/includes/modals/sellinOffer.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  };

  var dtOptions = {
    dom: "rt<'bottom'<'left'<'length'l>><'right'<'info'i><'pagination'p>>>",
    columnDefs: [{
      targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      filterable: true,
      sortable: true
    }],
    initComplete: function initComplete() {
      var api = this.api();
      var searchBox = angular.element("body").find("#searchTextProd"); // Bind an external input as a table wide search box

      if (searchBox.length > 0) {
        searchBox.on("keyup", function (event) {
          api.search(event.target.value).draw();
        });
      }
    },
    language: {
      sZeroRecords: "Aucun élément trouvé",
      sLengthMenu: "Afficher _MENU_ éléments",
      sInfo: "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
      sInfoEmpty: "Affichage de 0 à 0 sur 0 élément",
      sInfoFiltered: "(filtré sur un total de _MAX_ éléments)",
      oPaginate: {
        sNext: "Suivant",
        sPrevious: "Précédent"
      }
    },
    pagingType: "simple",
    lengthMenu: [10, 20, 30, 50, 100],
    pageLength: 10,
    responsive: false
  };
  /**
   * Created row.
   *
   * @param {object} row - Row.
   */

  function createdRow(row) {
    // Recompiling so we can bind Angular directive to the DT
    $compile(angular.element(row).contents())($scope);
  }

  vm.values = {};
  vm.prices = {};
  vm.totalPrice = {};
  /**
   * Refresh column.
   */

  function refreshColumn() {
    vm.dtColumns = [DTColumnBuilder.newColumn("laboName").renderWith(function (data, type, row) {
      return "  <td>" + row._source.product.laboratory_name + "</td>";
    }).withClass("flex"), DTColumnBuilder.newColumn(null).renderWith(function (data, type, row) {
      var id = row._source.product.presentation;
      return "  <td>" + "<input type='hidden' id='product-stock-cip-" + row._source.product.presentation + "' value='" + row._source.product.code[0].value + "' />" + "<input type='hidden' id='product-stock-name-" + row._source.product.presentation + "' value='" + row._source.product.presentation_name + "' />" + "<a  style='color:#29b6f6; cursor:pointer' ng-click='vm.goDetail($event," + id + ")'>" + row._source.product.presentation_name + "</a>" + "</td>";
    }), DTColumnBuilder.newColumn("quantity").renderWith(function (data, type, row) {
      var sales = 0;
      var stockDays = 0;

      if (row._source.needs) {
        sales = row._source.needs.calcul.sale_amount_average_day_weighting;
        stockDays = row._source.needs.calcul.stock_available_days.toFixed(0);
      }

      return "<td title='Nombre de jours de stock avant rupture : (Stock disponible / " + "Ventes pondérées par jour) (" + row._source.quantity + " / " + sales + " = (" + stockDays + ")'>" + "<span class='text-boxed range-category-" + row._source.needs.calcul.range_category + "'>" + stockDays + "</span>" + "</td> ";
    }), DTColumnBuilder.newColumn(null).renderWith(function (data, type, row) {
      return "   <td title='Stock disponible à l'instant : {{prod._source.quantity}}'>" + row._source.quantity.toFixed(0) + " </td>";
    }), DTColumnBuilder.newColumn(null).renderWith(function (data, type, row) {
      return "<td title='Ventes mensuelles pondérées : (Ventes pondérées par jour x 30) (" + row._source.needs.calcul.sale_amount_average_day_weighting + " x 30 = " + row._source.needs.calcul.sale_amount_average_month_weighting + ")'>" + row._source.needs.calcul.sale_amount_average_month_weighting.toFixed(0) + "</td>";
    }), DTColumnBuilder.newColumn(null).renderWith(function (data, type, row) {
      return "<td title='Besoins pour 2 mois : (Ventes mensuelles x 2 - Stock disponible) (" + row._source.needs.calcul.sale_amount_average_month_weighting + " x 2 - " + row._source.quantity + " = " + row._source.needs.calcul.order_needs.toFixed(2) + ")'>" + row._source.needs.calcul.order_needs.toFixed(0) + "</td>";
    }), DTColumnBuilder.newColumn(null).renderWith(function (data, type, row) {
      var value = 0;

      if (row._source.product.laboratory_delivery) {
        value = row._source.product.laboratory_delivery;
      }

      return "<td>" + value + "</td>";
    }), DTColumnBuilder.newColumn(null).renderWith(function (data, type, row) {
      var value = 1;

      if (row._source.product.packing) {
        value = row._source.product.packing;
      }

      return "<td>" + value + "</td>";
    }), DTColumnBuilder.newColumn(null).renderWith(function (data, type, row) {
      var value = 0;

      if (row._source.product.category_generic_data) {
        value = row._source.product.category_generic_data.quantity_propal.value.toFixed(0);
      }

      return "<td>" + value + "</td>";
    }), DTColumnBuilder.newColumn(null).renderWith(function (data, type, row) {
      var value = Math.ceil(row._source.needs.calcul.quantity_propal / 60 * (60 + row._source.product.laboratory_delivery || 0));

      if (row._source.product.packing && value % row._source.product.packing !== 0 && row._source.needs.calcul.order_needs.toFixed(0) !== 0) {
        value = value + (row._source.product.packing - value % row._source.product.packing);
      }

      vm.values[row._source.product.presentation] = value;
      return "<td>" + "<input type='number' id='product-stock-quantity-" + row._source.product.presentation + "' ng-model='vm.values[" + row._source.product.presentation + "]' value='" + value + "' ng-change='vm.changePropalQuantities(" + row._source.product.presentation + ")'" + " class='stock-quantity' min='0' />" + "<input type='hidden' id='product-stock-price-" + row._source.product.presentation + "' value='" + row._source.product.price + "' />" + "</td>";
    }), DTColumnBuilder.newColumn(null).renderWith(function (data, type, row) {
      vm.prices[row._source.product.presentation] = row._source.product.price;
      return "<td>" + row._source.product.price + "€</td>";
    }), DTColumnBuilder.newColumn(null).renderWith(function (data, type, row) {
      vm.totalPrice[row._source.product.presentation] = (row._source.product.price * vm.values[row._source.product.presentation]).toFixed(2);
      return "<td ng-model='vm.totalPrice[" + row._source.product.presentation + "]'>" + "{{vm.totalPrice[" + row._source.product.presentation + "]}} €" + "</td>";
    })];
  }

  vm.changePropalQuantities = function (id) {
    vm.totalPrice[id] = (vm.prices[id] * vm.values[id]).toFixed(2);
  };
  /*
   * Events
   */


  $scope.$on("stockReassortFilterChange", function () {
    vm.loading = true;
    vm.dtInstance.changeData(init());
  });
}
/**
 * Sellin offer modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $state - $state.
 * @param {object} laboratory - Laboratory.
 * @param {Array} healthcenters - Healthcenters.
 * @param {Array} products - Products.
 * @param {number} numberOfProducts - Number of products.
 * @param {number} quantityOfAllProducts - Quantity of all products.
 * @param {number} totalPrice - Total price.
 */


function SellinOfferModalController($scope, $mdDialog, $state, laboratory, healthcenters, products, numberOfProducts, quantityOfAllProducts, totalPrice) {
  $scope.numberOfProducts = numberOfProducts;
  $scope.quantityOfAllProducts = quantityOfAllProducts;
  $scope.totalPrice = totalPrice;
  /**
   * Close prompt (mdDialog)
   */

  $scope.closePrompt = function () {
    $mdDialog.hide();
  };
  /**
   * Go to sellin offers with pre-loaded informations
   */


  $scope.goToSellinOffer = function () {
    $mdDialog.hide();
    $state.go("app.sellinOffer.new", {
      name: $scope.sellinOffer.name,
      description: $scope.sellinOffer.description,
      laboratory: laboratory,
      targets: [healthcenters],
      products: products
    });
  };
}
/**
 * Detail stocks product graph tab controller.
 *
 * @param {object} $q - $q.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $scope - $scope.
 * @param {object} product - Product.
 * @param {object} analysisService - Analysis service.
 */


function DetailStocksProductGraphTabController($q, $mdDialog, $scope, product, analysisService) {
  var vm = this;
  $scope.loading = true;
  $scope.dataChart;
  $scope.product = product;
  $scope.typeGraph = "Générique";
  $scope.lines = {};
  $scope.sumAllProduct = sumAllProduct(product);
  vm.deferredChart = $q.defer();
  $scope.amChartOptions = {
    data: vm.deferredChart.promise,
    type: "serial",
    theme: "light",
    autoMarginOffset: 5,
    categoryField: "range30days",
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    titles: [{
      text: "Ventes sur 12 mois",
      size: 16
    }],
    legend: {
      enabled: true
    },
    chartScrollbar: {
      enabled: true
    },
    valueAxes: [{
      stackType: "regular",
      axisAlpha: 0.5,
      gridAlpha: 0
    }],
    categoryAxis: {
      gridPosition: "start",
      parseDates: false
    },
    graphs: [],
    "export": {
      enabled: true
    }
  };
  /**
   * Process data for AmCharts.
   *
   * @param {object} dataChart - Data chart.
   */

  function processDataForAmCharts(dataChart) {
    var lines = [];
    angular.forEach(dataChart.hits.hits, function (product) {
      if (product._source.needs && product._source.needs.calcul.sale_amount_average_month_weighting !== 0) {
        var addProduct = {
          type: "column",
          title: product._source.product.presentation_name,
          valueField: product._source.product.presentation,
          labelText: "[[value]]",
          balloonText: product._source.product.presentation_name + " :[[value]]",
          fillAlphas: 0.8,
          lineAlpha: 0.2
        };
        $scope.amChartOptions.graphs.push(addProduct);
      }
    });

    var _loop = function _loop(index) {
      var lineMonth = {};
      lineMonth.range30days = "-" + index + " mois";
      angular.forEach(dataChart.hits.hits, function (product) {
        if (product._source.needs && product._source.needs.calcul.sale_amount_average_month_weighting !== 0) {
          lineMonth[product._source.product.presentation] = product._source.needs.sales[index].volume;
        }
      });
      lines.push(lineMonth);
    };

    for (var index = 1; index < 12; index++) {
      _loop(index);
    }

    $scope.lines = lines;
    lines.reverse();
    vm.deferredChart.resolve(lines);
    $scope.loading = false;
  }

  var query = {};

  if (product.product.category && product.product.category.level5 !== 0) {
    var categoryId = product.product.category.level5;
    query.must = {
      categoryMulti: [categoryId]
    };
    $scope.typeGraph = "Catégories";

    if (product.product.presentation_gen_group) {
      $scope.typeGraph = "Génériques";
      query.must = {
        generic: [product.product.presentation_gen_group]
      };
    }

    $scope.valueWithDelivery = Math.ceil(product.needs.calcul.quantity_propal / 60 * (60 + product.product.laboratory_delivery) - product.needs.calcul.order_needs);
    $scope.productToOrdered = Math.ceil(product.needs.calcul.order_needs + (product.product.packing - $scope.valueWithDelivery % product.product.packing));
    analysisService.getLaboratoryStockByHealthcenter(product.organization.cip, query).then(function (response) {
      $scope.dataChart = response;
      processDataForAmCharts(response);
    });
  }

  $scope.hide = function () {
    $mdDialog.hide();
  };

  $scope.cancel = function () {
    $mdDialog.cancel();
  };
}
/**
 * Sum all prodcut.
 *
 * @returns {object}
 */


function sumAllProduct() {
  var sumAllProduct = {
    orderNeedsNegative: 0,
    saleAmountAverageMonthWeighting: 0,
    stock: 0,
    stockAvailableDays: 0,
    stockAvailableDaysTrue: 0
  };
  return sumAllProduct;
}

angular.module("app.healthcenterAudit").controller("ReassortAuditStockController", ReassortAuditController);