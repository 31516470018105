"use strict";
/**
 * The app-blockquotes directive.
 *
 * @returns {object}
 */

function AppBlockquotes() {
  return {
    restrict: "E",
    scope: {
      icon: "@icon",
      title: "@title"
    },
    templateUrl: "app/main/directives/app-blockquotes/appBlockquotes.html",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appBlockquotes", AppBlockquotes);