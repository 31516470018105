"use strict";
/**
 * Fabric canvas.
 *
 * @param {object} fabricWindow - Fabric window.
 * @param {object} $rootScope - $rootScope.
 *
 * @returns {object}
 */

FabricCanvas.$inject = ["fabricWindow", "$rootScope"];

function FabricCanvas(fabricWindow, $rootScope) {
  var factory = {
    canvasId: null,
    element: null,
    canvas: null,
    setElement: function setElement(element) {
      factory.element = element;
      $rootScope.$broadcast("canvas:element:selected");
    },
    createCanvas: function createCanvas() {
      factory.canvasId = "fabric-canvas-" + createId();
      factory.element.attr("id", factory.canvasId);
      factory.canvas = new fabricWindow.Canvas(factory.canvasId);
      $rootScope.$broadcast("canvas:created");
      return factory.canvas;
    },
    getCanvas: function getCanvas() {
      return factory.canvas;
    },
    getCanvasId: function getCanvasId() {
      return factory.canvasId;
    }
  };
  /**
   * createId
   *
   * @returns {number}
   */

  function createId() {
    return Math.floor(Math.random() * 10000);
  }

  return factory;
}

angular.module("app.generator").factory("fabricCanvas", FabricCanvas);