"use strict";
/**
 * Dashboard analysis service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

DashboardAnalysisService.$inject = ["apiClient"];

function DashboardAnalysisService(apiClient) {
  return {
    /**
     * Get sales figures.
     *
     * @returns {Promise}
     */
    getSalesFigures: function getSalesFigures() {
      return apiClient.get("/analysis/laboratory/dashboard", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get average shopping cart dashboard.
     *
     * @returns {Promise}
     */
    getAverageShoppingCartDashboard: function getAverageShoppingCartDashboard() {
      return apiClient.get("/analysis/laboratory/dashboard/averageShoppingCart", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top healthcenters.
     *
     * @returns {Promise}
     */
    getTopHealthcenters: function getTopHealthcenters() {
      return apiClient.get("/analysis/laboratory/dashboard/topHealthcenters", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get sellout statistics.
     *
     * @returns {Promise}
     */
    getSelloutStatistics: function getSelloutStatistics() {
      return apiClient.get("/analysis/laboratory/dashboard/sellout", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.responses;
      });
    },

    /**
     * Get prestations statistics.
     *
     * @returns {Promise}
     */
    getPrestationsStatistics: function getPrestationsStatistics() {
      return apiClient.get("/analysis/laboratory/dashboard/prestations", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.responses;
      });
    },

    /**
     * Get map by UGA statistics.
     *
     * @returns {Promise}
     */
    getMapByUGAStats: function getMapByUGAStats() {
      return apiClient.get("/analysis/laboratory/dashboard/map", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.dashboard").service("dashboardAnalysisService", DashboardAnalysisService);