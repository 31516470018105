"use strict";
/**
 * @ngInject
 *
 * @param {object} $state - The State
 * @param {object} $stateParams - The StateParams
 * @param {object} $scope - The Scope
 * @param {object} environmentService - The environment service.
 * @param {object} notifyUserService - The NotifyUserService
 * @param {object} laboratoriesService - Laboratories service.
 */

UserLaboratoryEditController.$inject = ["$state", "$stateParams", "$scope", "environmentService", "notifyUserService", "laboratoriesService"];

function UserLaboratoryEditController($state, $stateParams, $scope, environmentService, notifyUserService, laboratoriesService) {
  /**
   * Declaration of public methods
   */
  $scope.toSave = toSave;
  $scope.deleteCondition = deleteCondition;
  $scope.goToDetails = goToDetails;
  /**
   * Declaration of public objects
   */

  $scope.apodisContentTitle = {
    title: {
      text: "Laboratoire : ",
      back: {
        state: "app.usersLaboratory",
        tooltip: "Revenir à la sélection du laboratoire"
      }
    }
  };
  $scope.apodisContainerTitle = {
    icon: "icon-comment-text",
    text: "Gestion des conditions de groupement"
  };
  $scope.tinymceOptions = {
    height: 300,
    selector: "textarea",
    // change this value according to your HTML
    plugins: "code visualblocks",
    code_dialog_width: 600,
    content_css: ["//fonts.googleapis.com/css?family=Lato:300,300i,400,400i", "//www.tinymce.com/css/codepen.min.css"],
    style_formats: [{
      title: "Headers",
      items: [{
        title: "h1",
        block: "h1"
      }, {
        title: "h2",
        block: "h2"
      }, {
        title: "h3",
        block: "h3"
      }, {
        title: "h4",
        block: "h4"
      }, {
        title: "h5",
        block: "h5"
      }, {
        title: "h6",
        block: "h6"
      }]
    }, {
      title: "Blocks",
      items: [{
        title: "p",
        block: "p"
      }, {
        title: "div",
        block: "div"
      }, {
        title: "pre",
        block: "pre"
      }]
    }, {
      title: "Containers",
      items: [{
        title: "section",
        block: "section",
        wrapper: true,
        merge_siblings: false
      }, {
        title: "article",
        block: "article",
        wrapper: true,
        merge_siblings: false
      }, {
        title: "blockquote",
        block: "blockquote",
        wrapper: true
      }, {
        title: "hgroup",
        block: "hgroup",
        wrapper: true
      }, {
        title: "aside",
        block: "aside",
        wrapper: true
      }, {
        title: "figure",
        block: "figure",
        wrapper: true
      }]
    }],
    visualblocks_default_state: false,
    end_container_on_empty_block: false,
    theme: "modern",
    toolbar1: "visualblocks | code | formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
    image_advtab: true,
    templates: [{
      title: "Test template 1",
      content: "Test 1"
    }, {
      title: "Test template 2",
      content: "Test 2"
    }]
  };
  $scope.hasCondition = false;
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * Loader
   *
   * Load each method inside
   */

  function loader() {
    loaderEnvironment().then(function () {
      return getWholesalerLaboratory();
    });
  }
  /**
   * Set $scope.currentAccess
   *
   * @returns {Promise} promise
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      return response;
    });
  }
  /**
   * Get laboratory
   *
   * @returns {Promise} promise
   */


  function getWholesalerLaboratory() {
    var query = {
      wholesalerId: $stateParams.wholesalerId,
      wholesalerLaboratoryId: $stateParams.wholesalerLaboratoryId
    };
    return laboratoriesService.getWholesalerLaboratoryConditions(query).then(function (response) {
      $scope.wholesalerLaboratoryConditions = response;
      $scope.apodisContentTitle.title.text = $scope.apodisContentTitle.title.text + $scope.wholesalerLaboratoryConditions.wholesalerLaboratory.label;
      $scope.hasCondition = response.conditions ? response.conditions : false;
    });
  }
  /**
   * To Save
   *
   * @param {boolean} hasCondition - if laboratory has condition
   */


  function toSave(hasCondition) {
    hasCondition ? update() : save();
  }
  /**
   * Save Grouping Conditions
   *
   * @returns {Promise} promise
   */


  function save() {
    var query = {
      wholesalerId: $stateParams.wholesalerId,
      wholesalerLaboratoryId: $stateParams.wholesalerLaboratoryId,
      conditions: $scope.wholesalerLaboratoryConditions
    };
    return laboratoriesService.createWholesalerLaboratoryCondition(query).then(function (response) {
      $scope.wholesalerLaboratoryConditions = response;
      $scope.hasCondition = true;
      notifyUserService.showSimpleToast("Les conditions de votre groupement ont été créées pour le laboratoire : " + $scope.wholesalerLaboratoryConditions.wholesalerLaboratory.label, {
        color: "success",
        hideDelay: 1000
      });
      return response;
    });
  }
  /**
   * Update Grouping Conditions
   *
   * @returns {Promise} promise
   */


  function update() {
    var query = {
      wholesalerLaboratoryConditionId: $scope.wholesalerLaboratoryConditions.id,
      conditions: $scope.wholesalerLaboratoryConditions
    };
    return laboratoriesService.updateWholesalerLaboratoryCondition(query).then(function (response) {
      $scope.hasCondition = true;
      notifyUserService.showSimpleToast("Les conditions de votre groupement ont été mises à jour pour le laboratoire : " + $scope.wholesalerLaboratoryConditions.wholesalerLaboratory.label, {
        color: "success",
        hideDelay: 1000
      });
      return response;
    });
  }
  /**
   * Delete Grouping Conditions
   *
   * @param {object} wholesalerLaboratoryCondition - the laboratory condition
   *
   * @returns {Promise} promise
   */


  function deleteCondition(wholesalerLaboratoryCondition) {
    var query = {
      wholesalerLaboratoryConditionId: wholesalerLaboratoryCondition.id
    };
    return laboratoriesService.deleteWholesalerLaboratoryCondition(query).then(function (response) {
      var query = {
        wholesalerId: $stateParams.wholesalerId
      };
      $state.go("app.usersLaboratory", query);
      return response;
    });
  }
  /**
   * Go To Details
   *
   * @param {boolean} hasCondition - if laboratory has condition
   */


  function goToDetails(hasCondition) {
    if (!hasCondition) {
      save().then(function (response) {
        $state.go("app.usersLaboratory.preview", {
          id: $scope.laboratory.id,
          laboratory: $scope.laboratory
        });
        return response;
      });
    }

    update().then(function (response) {
      $state.go("app.usersLaboratory.preview", {
        id: $scope.laboratory.id,
        laboratory: $scope.laboratory
      });
      return response;
    });
  }
}

angular.module("app.users-laboratory").controller("userLaboratoryEditController", UserLaboratoryEditController);