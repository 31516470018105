"use strict";
/**
 * Controller for patient checkout ard, in pharmacy dashboard.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} customerService - The customer service.
 */

dashboardPharmaciesPatientCheckoutController.$inject = ["$scope", "$state", "customerService"];

function dashboardPharmaciesPatientCheckoutController($scope, $state, customerService) {
  $scope.loading = true;
  $scope.searchText;
  $scope.search = search;
  $scope.patients = null;
  $scope.redirectToPatientDetail = redirectToPatientDetail;
  loader();
  /**
   * The loader function
   */

  function loader() {
    customerService.getCustomerCheckouts().then(function (checkouts) {
      $scope.patients = manageCheckouts(checkouts.data);
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Manage checkouts for rendering.
   *
   * @param {Array} checkouts - The checkouts to manage.
   *
   * @returns {Array}
   */


  function manageCheckouts(checkouts) {
    return checkouts.map(function (patient) {
      patient.checkout.date = new Date(patient.checkout.date);
      return patient;
    });
  }
  /**
   * Clear fields and re-fetch checkouts.
   *
   * @returns {void}
   */


  function clear() {
    $scope.searchText = null;
    $scope.patients = null;
    loader();
  }
  /**
   * Search for a patient checkout.
   *
   * @param {object} $event - The angular $event object.
   * @param {string} searchText - The search text.
   *
   * @returns {void}
   */


  function search($event, searchText) {
    if (!searchText && $event.keyCode === 8) {
      clear();
      return;
    }

    if (searchText.length < 3) {
      return;
    }

    customerService.customerCheckoutsSearch(searchText).then(function (checkouts) {
      $scope.patients = manageCheckouts(checkouts.data);
    });
  }
  /**
   * Redirect to the patient detail.
   *
   * @param {object} patient - The patient.
   *
   * @returns {void}
   */


  function redirectToPatientDetail(patient) {
    $state.go("app.patients.details", {
      patientId: patient.id
    });
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesPatientCheckoutController", dashboardPharmaciesPatientCheckoutController);