"use strict";
/**
 * Guest service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

GuestService.$inject = ["apiClient"];

function GuestService(apiClient) {
  return {
    /**
     * Available.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    available: function available(params) {
      return apiClient.post("/guests/available", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Invite.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    invite: function invite(params) {
      return apiClient.post("/guests/invite", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.guest;
      });
    }
  };
}

angular.module("app.guest").service("guestService", GuestService);