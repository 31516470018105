"use strict";
/**
 * Custom event create.
 *
 * @param {object} eventService - Event service.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {string} apiurl - API URL.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $window - $window.
 * @param {object} environmentService - Environment service.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} productService - Product service.
 * @param {object} healthcenterService - Healthcenter service.
 * @param {object} sessionStorageService - Session storage service.
 */

CustomEventCreate.$inject = ["eventService", "$rootScope", "$scope", "apiurl", "$state", "$stateParams", "$window", "environmentService", "notifyUserService", "productService", "healthcenterService", "sessionStorageService"];

function CustomEventCreate(eventService, $rootScope, $scope, apiurl, $state, $stateParams, $window, environmentService, notifyUserService, productService, healthcenterService, sessionStorageService) {
  loader();
  $scope.checkAll = checkAll;
  $scope.createEvent = createEvent;
  $scope.save = save;
  $scope.updateEvent = updateEvent;
  $scope.goBack = goBack;
  $scope.deleteFromList = deleteFromList;
  $scope.deleteProducts = deleteProducts;
  $scope.deleteTargets = deleteTargets;
  $scope.deleteImage = deleteImage;
  $scope.deleteFile = deleteFile;
  $scope.apiurl = apiurl;
  $scope.token = sessionStorageService.getToken();
  $scope.event = {};
  $scope.addProduct = false;
  $scope.addTargets = false;
  $scope.addFileQuestion = false;
  $scope.addImageQuestion = false;
  $scope.addVideoQuestion = false;
  $scope.allTargets = false;
  $scope.newEventTitle = {
    title: {
      text: "Ajout d'un événement",
      back: {
        state: "app.calendar",
        tooltip: "Retour au calendrier"
      }
    }
  };
  $scope.titleForm1 = {
    text: "Informations de l'événement",
    icon: "icon-calendar-text",
    type: "2"
  };
  $scope.titleForm2 = {
    text: "Options de l'événement",
    icon: "icon-calendar-plus",
    type: "2"
  };
  $scope.eventCreateProductSearch = {
    event: "addProductsInEvents",
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    searchPlaceHolder: "Chercher un produit à ajouter",
    tag: "configProductEventCration",
    autoLaunchEvent: true
  };
  $scope.eventCreateOrgaSearch = {
    event: "addOrganizationsInEvents",
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
    searchPlaceHolder: "Chercher une organisation à ajouter",
    tag: "organizationevent",
    autoLaunchEvent: true
  };
  /**
   * The loading function
   */

  function loader() {
    loaderEnvironment();
    getEventTypes();

    if ($stateParams.id) {
      getEvent();
      $scope.buttonLabel = "Modifier";
      return;
    }

    $scope.buttonLabel = "Créer";
    $scope.start_date = new Date();
    $scope.end_date = new Date();
  }
  /**
   * Load current environment
   *
   * @returns {Promise}
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.environment = response;
      return response;
    });
  }
  /**
   * Retrieve event.
   *
   * @returns {Promise}
   */


  function getEvent() {
    var query = {
      id: $stateParams.id
    };
    return eventService.read(query).then(function (response) {
      $scope.event = response;
      $scope.newEventTitle = {
        title: {
          text: "Édition de l'événement " + response.title,
          back: {
            state: "app.calendar",
            tooltip: "Retour au calendrier"
          }
        }
      };
      generateEvent(response);
      return response;
    });
  }
  /**
   * Generate event.
   *
   * @param {object} event - Event.
   */


  function generateEvent(event) {
    $scope.event.type = event.event_type;
    $scope.event.start = new Date(event.startDate);
    $scope.event.end = new Date(event.endDate);
    $scope.event.isPublic = $scope.event.isPublic === 1;
    $scope.event.event_type_id = event.eventType ? event.eventType.id : 1;
    $scope.start_date = new Date($scope.event.start);
    $scope.end_date = new Date($scope.event.end);

    if (!_.isUndefined(event.custom)) {
      var custom = event.custom;
      $scope.event.products = _.isUndefined(custom.products) ? [] : custom.products;
      $scope.event.organizations = _.isUndefined(custom.organizations) ? [] : custom.organizations;
      $scope.event.imagePath = _.isUndefined(custom.image_path) ? false : custom.image_path;
      $scope.event.imageName = _.isUndefined(custom.image_name) ? false : custom.image_name;
      $scope.event.filePath = _.isUndefined(custom.file_path) ? false : custom.file_path;
      $scope.event.fileName = _.isUndefined(custom.file_name) ? false : custom.file_name;
      $scope.event.videoReference = _.isUndefined(custom.video_reference) ? false : custom.video_reference;
      $scope.event.organizations = custom.organizations.map(function (organization) {
        organization.pivot.is_public ? $scope.allTargets = true : $scope.allTargets = false;
        return {
          name: organization.morphable.name,
          entityTypeId: organization.entity_type_id,
          id: organization.morphable_id,
          is_public: organization.pivot.is_public
        };
      });
      $scope.event.products.length > 0 ? $scope.addProduct = true : $scope.addProduct = false;
      $scope.addTargets = $scope.event.organizations.length > 0;
      $scope.addImageQuestion = Boolean($scope.event.imagePath);
      $scope.event.filePath ? $scope.addFileQuestion = true : $scope.addFileQuestion = false;
      $scope.addVideoQuestion = $scope.event.videoReference ? true : false;
    }
  }
  /**
   * checkAll
   *
   * To set organizations.isPublic
   *
   * @param {Array} allTargets - All targets.
   */


  function checkAll(allTargets) {
    $scope.event.organizations.forEach(function (organization) {
      organization.is_public = allTargets;
    });
  }
  /**
   * Get event types.
   *
   * @returns {Promise}
   */


  function getEventTypes() {
    return eventService.getEventTypes().then(function (response) {
      $scope.eventTypes = response;
    });
  }
  /**
   * Create an event.
   *
   * @returns {Promise}
   */


  function createEvent() {
    $scope.event.start_date = moment($scope.start_date).format("YYYY-MM-DD");
    $scope.event.end_date = moment($scope.end_date).format("YYYY-MM-DD");
    $scope.event.is_public = $scope.event.isPublic;
    return eventService.create($scope.event).then(function (response) {
      $scope.event = response;
      notifyUserService.showSuccessToast("L'événement « " + response.title + " » a bien été créé.");
      return response;
    });
  }
  /**
   * Update the event.
   *
   * @returns {Promise}
   */


  function updateEvent() {
    $scope.event.start_date = moment($scope.start_date).format("YYYY-MM-DD");
    $scope.event.end_date = moment($scope.end_date).format("YYYY-MM-DD");
    $scope.event.is_public = $scope.event.isPublic;
    return eventService.update($scope.event).then(function (response) {
      $scope.event = response;
      notifyUserService.showSuccessToast("L'événement « " + response.title + " » a bien été modifié.");
      return response;
    });
  }
  /**
   * The switch function for creating or updating the event
   */


  function save() {
    if ($scope.event.id) {
      updateEvent().then(function () {
        $window.history.back();
      });
    } else {
      createEvent().then(function () {
        $state.go("app.calendar.timeline");
      });
    }
  }
  /**
   * the go back function
   */


  function goBack() {
    $window.history.back();
  }
  /**
   * Delete from a given list
   *
   * @param {Array} list -
   * @param {number} index -
   */


  function deleteFromList(list, index) {
    list.splice(index, 1);
  }
  /**
   * Delete all the products
   *
   * @param {boolean} actionFromButton -
   */


  function deleteProducts(actionFromButton) {
    if ($scope.addProduct === false || actionFromButton) {
      $scope.event.products = [];
    }
  }
  /**
   * Delete all the targets
   *
   * @param {boolean} actionFromButton -
   */


  function deleteTargets(actionFromButton) {
    if ($scope.addTargets === false || actionFromButton) {
      $scope.event.organizations = [];
    }
  }
  /**
   * Delete the image
   *
   * @param {boolean} actionFromButton -
   */


  function deleteImage(actionFromButton) {
    if ($scope.addImageQuestion === false || actionFromButton) {
      $scope.event.image = null;
      $scope.event.imagePath = null;
    }
  }
  /**
   * Delete the file
   *
   * @param {boolean} actionFromButton -
   */


  function deleteFile(actionFromButton) {
    if ($scope.addFileQuestion === false || actionFromButton) {
      $scope.event.file = [];
      $scope.event.filePath = [];
    }
  }
  /**
   * The searchbar event for the products
   */


  $rootScope.$on("addProductsInEvents", function (event, data) {
    var queryProduct = {};

    if (_.isUndefined($scope.event.products)) {
      $scope.event.products = [];
    }

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    } // getProducts with query results


    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.event.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.event.products.push(product);
        }
      });
    });
  });
  /**
   * The searchbar event for the organizations
   */

  $rootScope.$on("addOrganizationsInEvents", function (event, data) {
    if (!_.isArray($scope.event.organizations)) {
      $scope.event.organizations = [];
    }

    var queryPharma = {};
    queryPharma.type = {};
    var groups = [];
    var queryGroups = {};
    queryGroups.type = {};
    queryGroups.type.groups = [];

    var _loop2 = function _loop2(type) {
      if (type !== "groups") {
        if (Object.prototype.hasOwnProperty.call(data, type)) {
          if (typeof queryPharma.type[type] === "undefined") {
            queryPharma.type[type] = [];
          }

          angular.forEach(data[type], function (obj) {
            if (type === "uga") {
              queryPharma.type[type].push(obj.name);
            } else {
              queryPharma.type[type].push(obj.id);
            }
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(data, type)) {
        angular.forEach(data[type], function (obj) {
          groups.push(obj);
          queryGroups.type.groups.push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop2(type);
    } // getPharmas with query results


    healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      angular.forEach(groups, function (group) {
        group.is_public = false;
        group.entityTypeId = 3;
        $scope.event.organizations.push(group);
      });
      angular.forEach(pharmas, function (pharma) {
        pharma.is_public = false;

        if (!_.find($scope.event.organizations, function (organization) {
          return pharma.id === organization.id;
        })) {
          pharma.entityTypeId = 2;
          $scope.event.organizations.push(pharma);
        }
      });
    });
  }); // Reset video field

  $scope.$watch("addVideoQuestion", function (value) {
    if (!value) {
      $scope.event.videoReference = "";
    }
  });
}

angular.module("app.events").controller("customEventCreate", CustomEventCreate);