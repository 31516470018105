"use strict";
/**
 * Stocks query service.
 *
 * @param {object} $rootScope - $rootScope.
 *
 * @returns {object}
 */

StocksQueryService.$inject = ["$rootScope"];

function StocksQueryService($rootScope) {
  return {
    saveStocksQuery: function saveStocksQuery(obj) {
      this.query = obj;
      $rootScope.$broadcast("statistics:stocksearch", obj);
    },
    query: {
      section: {
        //
        // range: {
        //     pluralize: '',
        //     name: 'Gamme',
        //     slug: 'range',
        //     selected: [],
        //     atclSelected: null,
        //     filter: '',
        //     tab: 'quali'
        // },
        product: {
          pluralize: "",
          name: "Produit",
          slug: "product",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "quali"
        },
        segment: {
          pluralize: "",
          name: "Segment",
          slug: "segment",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "quali"
        },
        region: {
          pluralize: "e",
          name: "Région",
          slug: "region",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "geo"
        },
        departement: {
          pluralize: "",
          name: "Département",
          slug: "dprmt",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "geo"
        },
        uga: {
          pluralize: "e",
          name: "UGA",
          slug: "uga",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "geo"
        },
        ville: {
          pluralize: "e",
          name: "Ville",
          slug: "ville",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "geo"
        },
        grpmt: {
          pluralize: "",
          name: "Groupement",
          slug: "grpmt",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "quali"
        },
        pharmas: {
          pluralize: "e",
          name: "Pharmacies",
          slug: "pharmacies",
          selected: [],
          filter: "",
          tab: "geo"
        },
        stocks: {
          pluralize: "",
          name: "Stocks",
          slug: "stocks",
          min: null,
          max: null,
          filter: "",
          tab: "quanti"
        },
        ca: {
          pluralize: "",
          name: "Chiffre d'affaires",
          slug: "ca",
          min: null,
          max: null,
          filter: "",
          tab: "quanti"
        },
        lab: {
          pluralize: "",
          name: "Laboratoire",
          slug: "lab",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "quali"
        },
        "class": {
          pluralize: "e",
          name: "Classification",
          slug: "class",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "quali"
        },
        gen: {
          pluralize: "",
          name: "Générique",
          slug: "gen",
          selected: [],
          tab: "quali"
        }
      }
    },
    pristineQuery: {
      section: {
        // range: {
        //     pluralize: '',
        //     name: 'Gamme',
        //     slug: 'range',
        //     selected: [],
        //     atclSelected: null,
        //     filter: '',
        //     tab: 'quali'
        // },
        segment: {
          pluralize: "",
          name: "Segment",
          slug: "segment",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "quali"
        },
        product: {
          pluralize: "",
          name: "Produit",
          slug: "product",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "quali"
        },
        region: {
          pluralize: "e",
          name: "Région",
          slug: "region",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "geo"
        },
        departement: {
          pluralize: "",
          name: "Département",
          slug: "dprmt",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "geo"
        },
        uga: {
          pluralize: "e",
          name: "UGA",
          slug: "uga",
          selected: [],
          filter: "",
          tab: "geo"
        },
        ville: {
          pluralize: "e",
          name: "Ville",
          slug: "ville",
          selected: [],
          filter: "",
          tab: "geo"
        },
        grpmt: {
          pluralize: "",
          name: "Groupement",
          slug: "grpmt",
          selected: [],
          filter: "",
          tab: "quali"
        },
        pharmas: {
          pluralize: "e",
          name: "Pharmacies",
          slug: "pharmacies",
          selected: [],
          filter: "",
          tab: "geo"
        },
        stocks: {
          pluralize: "",
          name: "Stocks",
          slug: "stocks",
          min: null,
          max: null,
          filter: "",
          tab: "quanti"
        },
        lab: {
          pluralize: "",
          name: "Laboratoire",
          slug: "lab",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "quali"
        },
        "class": {
          pluralize: "e",
          name: "Classification",
          slug: "class",
          selected: [],
          atclSelected: null,
          filter: "",
          tab: "quali"
        },
        gen: {
          pluralize: "",
          name: "Générique",
          slug: "gen",
          selected: [],
          tab: "quali"
        }
      }
    },
    buildQuery: function buildQuery(usrquery) {
      var obj = {};

      if (usrquery.section.region !== undefined && usrquery.section.region.selected !== undefined && usrquery.section.region.selected.length) {
        obj.region = usrquery.section.region.selected.map(function (currentValue) {
          return currentValue.id;
        });
      }

      if (usrquery.section.departement !== undefined && usrquery.section.departement.selected !== undefined && usrquery.section.departement.selected.length) {
        obj.departement = usrquery.section.departement.selected.map(function (currentValue) {
          return currentValue.id;
        });
      }

      if (usrquery.section.uga !== undefined && usrquery.section.uga.selected !== undefined && usrquery.section.uga.selected.length) {
        obj.uga = usrquery.section.uga.selected.map(function (currentValue) {
          return currentValue.uga.toLowerCase();
        });
      }

      if (usrquery.section.grpmt !== undefined && usrquery.section.grpmt.selected !== undefined && usrquery.section.grpmt.selected.length) {
        obj.groupement = usrquery.section.grpmt.selected.map(function (currentValue) {
          return parseInt(currentValue.id);
        });
      }

      if (usrquery.section.pharmas !== undefined && usrquery.section.pharmas.selected !== undefined && usrquery.section.pharmas.selected.length) {
        obj.healthcenter = usrquery.section.pharmas.selected.map(function (currentValue) {
          return currentValue.cip;
        });
      }

      if (usrquery.section.ville !== undefined && usrquery.section.ville.selected !== undefined && usrquery.section.ville.selected.length) {
        obj.city = usrquery.section.ville.selected.map(function (currentValue) {
          return currentValue.id;
        });
      }

      if (usrquery.section.lab.selected.length) {
        obj.laboratory = usrquery.section.lab.selected.map(function (currentValue) {
          if (currentValue.id) {
            return currentValue.id;
          }

          return "000000";
        });
      }

      if (usrquery.section["class"].selected.length) {
        obj.category = usrquery.section["class"].selected.map(function (currentValue) {
          return currentValue.id;
        });
      }

      if (usrquery.section.gen.selected.length) {
        obj.prodType = usrquery.section.gen.selected.map(function (currentValue) {
          return currentValue.value;
        });
      }

      if (usrquery.section.stocks.min || usrquery.section.stocks.max) {
        obj.stockrange = {};
      }

      if (usrquery.section.stocks.min) {
        obj.stockrange.gte = usrquery.section.stocks.min;
      }

      if (usrquery.section.stocks.max) {
        obj.stockrange.lte = usrquery.section.stocks.max;
      }

      if (usrquery.section.segment.selected.length) {
        obj.segment = usrquery.section.segment.selected.map(function (currentValue) {
          return currentValue.id;
        });
      }

      if (usrquery.section.product.selected.length) {
        obj.product = usrquery.section.product.selected.map(function (currentValue) {
          return currentValue.id;
        });
      }

      return obj;
    }
  };
}

angular.module("app.statistics").factory("StocksQueryService", StocksQueryService);