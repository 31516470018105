"use strict";
/**
 * Impact analysis service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

ImpactAnalysisService.$inject = ["apiClient"];

function ImpactAnalysisService(apiClient) {
  return {
    /**
     * Get.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/impact-analyses", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/impact-analyses/".concat(params.id), {
        includes: ["products", "targets", "market", "controlSegment", "events"]
      });
    },

    /**
     * Read report.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readReport: function readReport(params) {
      return apiClient.get("/impact-analyses/".concat(params.id, "/report"), {
        includes: ["products", "targets", "market", "controlSegment", "events"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    create: function create(params) {
      return apiClient.post("/impact-analyses", params, {
        includes: ["products", "targets", "market", "controlSegment", "events"]
      });
    },

    /**
     * Update.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/impact-analyses/".concat(params.id), params, {
        includes: ["products", "targets", "market", "controlSegment", "events"]
      });
    },

    /**
     * Delete.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/impact-analyses/".concat(params.id));
    }
  };
}

angular.module("app.impactAnalysis").service("impactAnalysisService", ImpactAnalysisService);