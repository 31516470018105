"use strict";
/** @ngInject */

/**
 * Sales Analysis service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

salesAnalysisSandBoxLinesAtclService.$inject = ["apiClient"];

function salesAnalysisSandBoxLinesAtclService(apiClient) {
  return {
    /**
     * Chart.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    chart: function chart(params) {
      return apiClient.post("/analysis/sales", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Export.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    "export": function _export(params) {
      return apiClient.post("/analysis/sales/export", params, {
        transformResponse: function transformResponse(response) {
          return {
            csv: response
          };
        },
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics.salesAnalysis").service("salesAnalysisSandBoxLinesAtclService", salesAnalysisSandBoxLinesAtclService);