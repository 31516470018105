"use strict";
/**
 * The sellin offer service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

SellinOfferService.$inject = ["apiClient"];

function SellinOfferService(apiClient) {
  return {
    /**
     * Create.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    create: function create(data) {
      return apiClient.post("/sellin-offers", data, {
        wrappers: {
          response: "sellinOffer"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    update: function update(data) {
      return apiClient.put("/sellin-offers/".concat(data.id), data, {
        wrappers: {
          response: "sellinOffer"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get todo.
     *
     * @returns {Promise}
     */
    getToDo: function getToDo() {
      return apiClient.get("/sellin-offers/todo", {
        isPrefixed: true,
        wrappers: {
          response: "sellinOffers"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get awaiting.
     *
     * @returns {Promise}
     */
    getAwaiting: function getAwaiting() {
      return apiClient.get("/sellin-offers/awaiting", {
        isPrefixed: true,
        wrappers: {
          response: "sellinOffers"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get todo by laboratory ID.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getToDoByLaboId: function getToDoByLaboId(data) {
      return apiClient.get("/healthcenter/sellin-offers/todo/laboratories/".concat(data.laboId), {
        wrappers: {
          response: "sellinOffers"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get awaiting by laboratory ID.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getAwaitingByLaboId: function getAwaitingByLaboId(data) {
      return apiClient.get("/sellin-offers/awaiting/laboratories/".concat(data.laboId), {
        wrappers: {
          response: "sellinOffers"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    read: function read(data) {
      return apiClient.get("/sellin-offers/".concat(data.id), {
        wrappers: {
          response: "sellinOffer"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get accepted.
     *
     * @returns {Promise}
     */
    getAccepted: function getAccepted() {
      return apiClient.get("/sellin-offers/accepted", {
        isPrefixed: true,
        wrappers: {
          response: "sellinOffers"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get refused.
     *
     * @returns {Promise}
     */
    getRefused: function getRefused() {
      return apiClient.get("/sellin-offers/refused", {
        isPrefixed: true,
        wrappers: {
          response: "sellinOffers"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get accepted by laboratory ID.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getAcceptedByLaboId: function getAcceptedByLaboId(data) {
      return apiClient.get("/sellin-offers/accepted/laboratories/".concat(data.laboId), {
        wrappers: {
          response: "sellinOffers"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get refused by laboratory ID.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getRefusedByLaboId: function getRefusedByLaboId(data) {
      return apiClient.get("/healthcenter/sellin-offers/refused/laboratories/".concat(data.laboId)).then(function (response) {
        return response.sellinOffers;
      });
    },

    /**
     * Validate an offer for a specific healthcenter.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    validateHealthcenterSellinOffer: function validateHealthcenterSellinOffer(data) {
      return apiClient.post("/shop/healthcenters/".concat(data.healthcenterId, "/sellinOffer/").concat(data.id, "/validateOrderSellin"), data, {
        wrappers: {
          response: "sellinOffer"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Accept a sellin offer by laboratory ID.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    accept: function accept(data) {
      return apiClient.put("/sellin-offers/".concat(data.id, "/accept/laboratories/").concat(data.laboId), data, {
        wrappers: {
          response: "sellinOffer"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Refuse a sellin offer by laboratory ID.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    refuse: function refuse(data) {
      return apiClient.put("/sellin-offers/".concat(data.id, "/refuse"), data, {
        wrappers: {
          response: "sellinOffer"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    "delete": function _delete(data) {
      return apiClient["delete"]("/sellin-offers/".concat(data.id), {
        wrappers: {
          response: "sellinOffer"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get price for healthcenters and products.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getPriceForHealthcentersAndProducts: function getPriceForHealthcentersAndProducts(data) {
      return apiClient.post("/healthcenters/getPriceForHealthcentersAndProducts", data, {
        wrappers: {
          response: "products"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get sellin offer for specific healthcenter.
     *
     * @param {string} sellinOfferId - The sellin offer id.
     * @param {string} healthcenterId - The healthcenter id.
     *
     * @returns {Promise}
     */
    getSellinOfferByIdForHealthcenter: function getSellinOfferByIdForHealthcenter(sellinOfferId, healthcenterId) {
      return apiClient.get("/sellin-offers/" + sellinOfferId + "/healthcenters/" + healthcenterId).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get cgv file to download.
     *
     * @param {string} filePath - File path.
     *
     * @returns {Promise}
     */
    downloadCgvFile: function downloadCgvFile(filePath) {
      return apiClient.get("/files/" + filePath).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.sellinOffer").service("sellinOfferService", SellinOfferService);