"use strict";
/**
 * Healthcenter audit configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

HealthcenterAuditConfig.$inject = ["$stateProvider"];

function HealthcenterAuditConfig($stateProvider) {
  $stateProvider.state("app.healthcenterAudit", {
    url: "/audit-pharmacie",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/healthcenter-audit/views/audit/healthcenter-audit.html",
        controller: "HealthcenterAuditController as vm"
      }
    }
  }).state("app.healthcenterAudit.detail", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/healthcenter-audit/views/detail/healthcenter-audit-detail.html",
        controller: "HealthcenterAuditDetailController as vm"
      }
    }
  }).state("app.healthcenterAudit.detail.general", {
    url: "/general",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/statistics/healthcenter-audit/views/detail/general/healthcenter-audit-generalInfos.html",
        controller: "HealthcenterAuditGeneralInfosController"
      }
    }
  }).state("app.healthcenterAudit.detail.salesEvolution", {
    url: "/courbe",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/statistics/salesAnalysis/views/sandbox-lines-atcl/sandbox-lines-atcl.html",
        controller: "StatsSandboxLinesAtclController as vm"
      }
    }
  }).state("app.healthcenterAudit.detail.marketShare", {
    url: "/parts-de-marche",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/statistics/salesAnalysis/views/marketShare/marketShare.html",
        controller: "MarketShareController as vm"
      }
    }
  }).state("app.healthcenterAudit.detail.mdl", {
    url: "/mdl",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/statistics/marge/smoothed-degressive-margin/mdl/mdl.html",
        controller: "MdlController as vm"
      }
    }
  }).state("app.healthcenterAudit.detail.realStock", {
    url: "/stock-reel",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/statistics/stocks/views/stock/stock.html",
        controller: "StatisticsStockController as vm"
      }
    }
  }).state("app.healthcenterAudit.detail.goumy", {
    url: "/valorisation-des-stocks",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/statistics/stocks/stat/stat.html",
        controller: "StatisticsStatStockController as vm"
      }
    }
  }).state("app.healthcenterAudit.detail.reassort", {
    url: "/reassort",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/statistics/healthcenter-audit/views/detail/reassortAudit/reassortAudit.html",
        controller: "ReassortAuditController as vm"
      }
    }
  }).state("app.healthcenterAudit.detail.sellout", {
    url: "/challenge",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/sellout/list/list.html",
        controller: "ChallengeSelloutListController as vm"
      }
    }
  }).state("app.healthcenterAudit.detail.prestation", {
    url: "/mise-en-avant",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/prestation/list/list.html",
        controller: "PrestationListController"
      }
    }
  }).state("app.healthcenterAudit.detail.sellinChallenge", {
    url: "/challenge-sellin",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/sellin-challenge/views/list/sellinChallenges.html",
        controller: "sellinChallengeController"
      }
    }
  }).state("app.healthcenterAudit.detail.sellinOffer", {
    url: "/offre-sellin",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/sellin-offer/views/list/sellinOffers.html",
        controller: "sellinOfferController"
      }
    }
  }).state("app.healthcenterAudit.detail.averageShoppingCart", {
    url: "/panier-moyen",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/statistics/averageShoppingCart/views/averageShoppingCart.html",
        controller: "averageShoppingCartController"
      }
    }
  }).state("app.healthcenterAudit.detail.distributor", {
    url: "/distributor",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/statistics/productAnalysis/views/distributor/distributor.html",
        controller: "distributorController"
      }
    }
  }).state("app.healthcenterAudit.detail.supplyingAnalysis", {
    url: "/commandes",
    views: {
      "tabContent@app.healthcenterAudit.detail": {
        templateUrl: "app/main/statistics/stocks/supplying/supplying.html",
        controller: "SupplyingController as vm"
      }
    }
  });
}

angular.module("app.healthcenterAudit", []).config(HealthcenterAuditConfig);