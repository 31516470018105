"use strict";
/**
 * Certification modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} data - Data.
 */

PatientCertificationModalController.$inject = ["$scope", "$mdDialog", "customerService", "patientId"];

function PatientCertificationModalController($scope, $mdDialog, customerService, patientId) {
  $scope.close = close;
  $scope.print = print;
  $scope.token;
  $scope.loading = true;
  loader();
  /**
   * Loader function.
   * Create a certification.
   */

  function loader() {
    $scope.loading = true;
    customerService.createCertification(patientId).then(function (data) {
      $scope.token = data.token;
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Prints the QR code.
   */


  function print() {
    var qrcodeContainer = angular.element(document).find(".qrcode-container")[0];
    var printWindow = window.open();
    printWindow.document.write("<html><body>".concat(qrcodeContainer.innerHTML, "</body></html>"));
    var source = angular.element(document).find("qrcode > canvas")[0];
    var destination = angular.element(printWindow.document).find("canvas")[0];
    destination.getContext("2d").drawImage(source, 0, 0);
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }
  /**
   * Closes the modal.
   */


  function close() {
    $mdDialog.hide();
  }
}

angular.module("app.patients").controller("patientCertificationModalController", PatientCertificationModalController);