"use strict";
/**
 * Cart content modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} prescription - The prescription renewal.
 * @param {object} renewalScheduleService - renewalScheduleService.
 * @param {object} dateTimeService - dateTimeService.
 * @param {object} notifyUserService - notifyUserService.
 */

PrescriptionScheduleContentModalController.$inject = ["$scope", "$state", "$mdDialog", "prescription", "renewalScheduleService", "dateTimeService", "notifyUserService"];

function PrescriptionScheduleContentModalController($scope, $state, $mdDialog, prescription, renewalScheduleService, dateTimeService, notifyUserService) {
  $scope.close = close;
  $scope.addRenewalDates = addRenewalDates;
  $scope.removeRenewalDate = removeRenewalDates;
  $scope.createRenewalSchedule = createRenewalSchedule;
  $scope.displayRenewalDate = displayRenewalDate;
  $scope["delete"] = deleteConfirmation;
  $scope.submit = submit;
  $scope.minDate = moment().add(1, "days");
  $scope.forms;
  $scope.renewal = prescription;
  $scope.renewalDates = {
    dates: [],
    endDate: null
  };
  loader();
  /**
   * Load the renewal plan when available.
   */

  function loader() {
    if (prescription.renewal_plan.id) {
      renewalScheduleService.getRenewalSchedulePlanDetails(prescription.renewal_plan.id).then(function (renewalDetails) {
        $scope.renewalDates.dates = renewalDetails.renewal_dates;
        $scope.renewalDates.endDate = renewalDetails.end_at;
      });
    }
  }
  /**
   * Submit the renewal plan.
   */


  function submit() {
    $scope.forms.renewalForm.$setSubmitted();

    if ($scope.forms.renewalForm.$valid) {
      prescription.renewal_plan.id ? updateRenewalSchedule(prescription.renewal_plan.id, $scope.renewalDates.endDate, $scope.renewalDates.dates) : createRenewalSchedule(prescription.key, $scope.renewalDates.endDate, $scope.renewalDates.dates);
    }
  }
  /**
   * Add a new renewal date to a prescription.
   *
   * @param {Date} newDate - The new renewal date.
   */


  function addRenewalDates(newDate) {
    var dateToAdd = dateTimeService.toDateTimeString(newDate);

    if (!$scope.renewalDates.dates.find(function (date) {
      return date.renew_at === dateToAdd;
    })) {
      $scope.renewalDates.dates.push({
        renew_at: dateToAdd
      });
    }
  }
  /**
   * Remove a renewal date from a prescription.
   *
   * @param {Date} dateToRemove - The renewal date to remove.
   */


  function removeRenewalDates(dateToRemove) {
    $scope.renewalDates.dates = $scope.renewalDates.dates.filter(function (date) {
      return date.renew_at !== dateTimeService.toDateTimeString(dateToRemove);
    });
  }
  /**
   * Format a date in order to display it.
   *
   * @param {string} date - The date to convert.
   *
   * @returns {string}
   */


  function displayRenewalDate(date) {
    return moment(date).format("DD MMMM YYYY");
  }
  /**
   * Create a new renewal schedule for a specific prescription.
   *
   * @param {string} prescription_key - The prescription key.
   * @param {Date} end_at - The date prescription expires.
   * @param {Array} dates - An array that contains renewal dates.
   */


  function createRenewalSchedule(prescription_key, end_at, dates) {
    var endOfPlan = dateTimeService.toDateTimeString(end_at);
    renewalScheduleService.createRenewalSchedulePlan(prescription_key, endOfPlan, dates).then(function () {
      close();
      notifyUserService.showSuccessToast("Le plan de renouvellement a été créé avec succès.");
      $state.reload();
    })["catch"](function () {
      return notifyUserService.showDangerToast("Le plan de renouvellement n'a pas pu être crée.");
    });
  }
  /**
   * Update an existing renewal schedule.
   *
   * @param {number} id - The renewal schedule id.
   * @param {Date} end_at - The date prescription expires.
   * @param {Array} dates - An array that contains renewal dates.
   */


  function updateRenewalSchedule(id, end_at, dates) {
    var endOfPlan = dateTimeService.toDateTimeString(end_at);
    renewalScheduleService.updateRenewalSchedulePlan(id, endOfPlan, dates).then(function () {
      close();
      notifyUserService.showSuccessToast("Le plan de renouvellement a été mis à jour avec succès.");
      $state.reload();
    })["catch"](function () {
      return notifyUserService.showDangerToast("Le plan de renouvellement n'a pas pu être mis à jour.");
    });
  }
  /**
   * Delete the renewal schedule.
   */


  function deleteRenewalSchedule() {
    renewalScheduleService.deleteRenewalSchedulePlan(prescription.renewal_plan.id).then(function () {
      close();
      notifyUserService.showSuccessToast("Le plan de renouvellement a été supprimé.");
      $state.reload();
    })["catch"](function () {
      return notifyUserService.showDangerToast("Le plan de renouvellement n'a pas pu être supprimé.");
    });
  }
  /**
   * Ask for user the delete confirmation.
   */


  function deleteConfirmation() {
    var message = "Vous êtes sur le point de supprimer un plan de renouvellement, continuer ?";
    var confirm = $mdDialog.confirm().title("Etes-vous sûr ?").multiple(true).textContent(message).ariaLabel("Confirmation").ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(deleteRenewalSchedule, $mdDialog.close);
  }
  /**
   * Close the modal
   */


  function close() {
    $mdDialog.hide();
  }
}

angular.module("app.patients").controller("prescriptionScheduleController", PrescriptionScheduleContentModalController);