"use strict";
/**
 * Oauth main controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} oauthService - Oauth service.
 * @param {object} oauthConfig - Oauth config.
 */

OauthMainController.$inject = ["$scope", "$mdDialog", "notifyUserService", "oauthService", "oauthConfig"];

function OauthMainController($scope, $mdDialog, notifyUserService, oauthService, oauthConfig) {
  createOauthClientController.$inject = ["$scope", "$mdDialog", "oauthService"];
  $scope.oauthClients = [];
  $scope.oauthClientName = "";
  $scope.oauthClientNameError = false;
  $scope.keysOptions = {
    loading: true,
    loader: "Récupération de vos clés...",
    empty: "Vous n'avez pas encore créé de clé."
  };
  $scope.createOauthClient = createOauthClient;
  $scope.confirmDeleteOauthClient = confirmDeleteOauthClient;
  loader();
  /**
   * Loader.
   */

  function loader() {
    loadOauthClients();
  }
  /**
   * Load oAuth clients.
   */


  function loadOauthClients() {
    oauthService.read().then(function (oauthClients) {
      $scope.keysOptions.loading = false;
      $scope.oauthClients = oauthClients;
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue lors du chargement de vos clés.");
    });
  }
  /**
   * Display create oAuth client form.
   */


  function createOauthClient() {
    $mdDialog.show({
      controller: createOauthClientController,
      templateUrl: oauthConfig.baseDir + "/views/includes/modals/oauth.modals.createOauthClient.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    }).then(function () {// do nothing
    }, loader);
  }
  /**
   * Controller for create oAuth client form.
   *
   * @param  {object} $scope - $scope.
   * @param  {object} $mdDialog - $mdDialog.
   * @param  {object} oauthService - Oauth service.
   */


  function createOauthClientController($scope, $mdDialog, oauthService) {
    $scope.save = save;
    $scope.closeModal = closeModal;
    $scope.checkName = checkName;
    /**
     * Validate oAuth client name.
     *
     * @param {string} oauthClientName - Oauth client name.
     */

    function checkName(oauthClientName) {
      $scope.checker = oauthClientName && oauthClientName.length !== 0;
    }
    /**
     * Create an oAuth client.
     *
     * @param {object} form - Form.
     */


    function save(form) {
      var oauthClientParams = {
        name: form.name.$modelValue
      };
      oauthService.create(oauthClientParams).then(function (oauthClient) {
        notifyUserService.showSuccessToast("La clé « " + oauthClient.name + " » a bien été créée.");
        addOauthClient(oauthClient);
        closeModal();
      });
    }
    /**
     * Close the modal.
     */


    function closeModal() {
      $mdDialog.cancel();
    }
  }
  /**
   * Add oAuth client to list.
   *
   * @param {object} oauthClient - Oauth client.
   */


  function addOauthClient(oauthClient) {
    $scope.oauthClients.push(oauthClient);
  }
  /**
   * Display confirmation for deleting oAuth client.
   *
   * @param {object} event - Event.
   * @param {object} oauthClient - Oauth client.
   */


  function confirmDeleteOauthClient(event, oauthClient) {
    event.stopPropagation();
    var confirmDialog = $mdDialog.confirm().title("Confirmer la suppression de la clé « " + oauthClient.name + " »").textContent("Êtes-vous certin de vouloir supprimer la clé « " + oauthClient.name + " » ?").ok("Confirmer").cancel("Annuler");
    $mdDialog.show(confirmDialog).then(function () {
      deleteOauthClient(oauthClient);
    });
  }
  /**
   * Delete an oAuth client.
   *
   * @param {object} oauthClient - Oauth client.
   */


  function deleteOauthClient(oauthClient) {
    oauthService["delete"](oauthClient).then(function () {
      notifyUserService.showSuccessToast("La clé « " + oauthClient.name + " » a bien été supprimée.");
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue lors de la suppression de la clé « " + oauthClient.name + " »");
    }).then(loader);
  }
}

angular.module("app.oauth").controller("oauthMainController", OauthMainController);