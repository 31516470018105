"use strict";
/**
 * Fabric mask factory.
 *
 * @returns {object}
 */

function FabricMask() {
  var factory = {
    /**
     * Create mask of tagert object
     *
     * @param {object} canvas -
     * @param {number} scaleFactor -
     * @param {object} JSONExportProperties -
     * @param {object} object -
     * @param {object} callback -
     *
     * @returns {Promise}
     */
    createMask: function createMask(canvas, scaleFactor, JSONExportProperties, object, callback) {
      return new Promise(function (resolve) {
        var container = angular.element(document).find(".canvas-container");

        if (object && container.length > 0) {
          var mask = container[0].appendChild(document.createElement("div"));
          mask.setAttribute("id", object.id);
          mask.setAttribute("class", "mask");
          resolve(factory.setObjectToMask(canvas, JSONExportProperties, scaleFactor, object, callback));
        }
      });
    },

    /**
     * Set object to mask
     *
     * @param {object} canvas -
     * @param {number} scaleFactor -
     * @param {object} object -
     * @param {object} mask -
     *
     * @returns {Promise}
     */
    setMaskEventListener: function setMaskEventListener(canvas, scaleFactor, object, mask) {
      factory.setMaskPosition(canvas, scaleFactor, object, mask);
      object.on("moving", function () {
        return factory.setMaskPosition(canvas, scaleFactor, object, mask);
      });
      object.on("scaling", function () {
        return factory.setMaskPosition(canvas, scaleFactor, object, mask);
      });
      object.on("rotating", function () {
        return factory.setMaskPosition(canvas, scaleFactor, object, mask);
      });
      return mask;
    },

    /**
     * Set object to mask
     *
     * @param {object} canvas -
     * @param {object} JSONExportProperties -
     * @param {number} scaleFactor -
     * @param {object} object -
     * @param {object} callback -
     *
     * @returns {Promise}
     */
    setObjectToMask: function setObjectToMask(canvas, JSONExportProperties, scaleFactor, object, callback) {
      var mask = document.getElementById(object.id);
      var z_index = 0;
      angular.fromJson(JSON.stringify(canvas.toJSON(JSONExportProperties))).objects.forEach(function (currentObject, index) {
        if (currentObject.id === object.id) {
          z_index = index;
        }
      });
      mask.style.border = "1px";
      mask.style.outlineStyle = "dashed";
      mask.style.outlineColor = "#3498db";
      mask.style.zIndex = "'900" + parseInt(z_index) + "'";
      mask.style.position = "absolute";
      mask.style.cursor = "move";
      mask.style.display = "flex";
      mask.style.justifyContent = "center";
      mask.style.alignItems = "center";
      factory.dragElement(canvas, scaleFactor, object, mask, callback);
      return mask;
    },

    /**
     * Get mask of target object
     *
     * @param {object} object -
     *
     * @returns {object} The mask of target object
     */
    getMask: function getMask(object) {
      return angular.element(document).find("#" + object.id);
    },

    /**
     * Set mask position of target object
     *
     * @param {object} canvas -
     * @param {number} scaleFactor -
     * @param {object} object -
     * @param {object} mask -
     *
     * @returns {Promise}
     */
    setMaskPosition: function setMaskPosition(canvas, scaleFactor, object, mask) {
      return new Promise(function (resolve, reject) {
        if (_.isUndefined(object) || _.isUndefined(mask)) {
          reject();
        }

        var currentObj = canvas.getObjects().filter(function (obj) {
          return obj.id === object.id;
        });

        if (canvas.zoomInCounter > 0) {
          mask.style.left = currentObj[0].left * Math.pow(scaleFactor, canvas.zoomInCounter) + "px";
          mask.style.top = currentObj[0].top * Math.pow(scaleFactor, canvas.zoomInCounter) + "px";
          mask.style.width = currentObj[0].width * currentObj[0].scaleX * Math.pow(scaleFactor, canvas.zoomInCounter) + "px";
          mask.style.height = currentObj[0].height * currentObj[0].scaleY * Math.pow(scaleFactor, canvas.zoomInCounter) + "px";
          mask.style.transform = "rotate(" + currentObj[0].angle + "deg)";
          mask.style.transformOrigin = "top left";
          mask.style.opacity = currentObj[0].visible ? "1" : "0";
        }

        if (canvas.zoomOutCounter > 0) {
          mask.style.left = currentObj[0].left / Math.pow(scaleFactor, canvas.zoomOutCounter) + "px";
          mask.style.top = currentObj[0].top / Math.pow(scaleFactor, canvas.zoomOutCounter) + "px";
          mask.style.width = currentObj[0].width * currentObj[0].scaleX / Math.pow(scaleFactor, canvas.zoomOutCounter) + "px";
          mask.style.height = currentObj[0].height * currentObj[0].scaleY / Math.pow(scaleFactor, canvas.zoomOutCounter) + "px";
          mask.style.transform = "rotate(" + currentObj[0].angle + "deg)";
          mask.style.transformOrigin = "top left";
          mask.style.opacity = currentObj[0].visible ? "1" : "0";
        }

        if (canvas.zoomInCounter === 0 && canvas.zoomOutCounter === 0) {
          mask.style.left = currentObj[0].left + "px";
          mask.style.top = currentObj[0].top + "px";
          mask.style.width = currentObj[0].width * currentObj[0].scaleX + "px";
          mask.style.height = currentObj[0].height * currentObj[0].scaleY + "px";
          mask.style.transform = "rotate(" + currentObj[0].angle + "deg)";
          mask.style.transformOrigin = "top left";
          mask.style.opacity = currentObj[0].visible ? "1" : "0";
        }

        resolve(mask);
      });
    },

    /**
     * Set mask scalling
     *
     * @param {object} canvas -
     * @param {number} scaleFactor -
     * @param {object} object -
     * @param {object} mask -
     *
     * @returns {Promise}
     */
    setMaskScalling: function setMaskScalling(canvas, scaleFactor, object, mask) {
      return new Promise(function (resolve, reject) {
        if (_.isUndefined(object) || _.isUndefined(mask)) {
          reject();
        }

        var currentObj = canvas.getObjects().filter(function (obj) {
          return obj.id === object.id;
        });

        if (canvas.zoomInCounter > 0) {
          mask.style.width = currentObj[0].width * currentObj[0].scaleX * Math.pow(scaleFactor, canvas.zoomInCounter) + "px";
          mask.style.height = currentObj[0].height * currentObj[0].scaleY * Math.pow(scaleFactor, canvas.zoomInCounter) + "px";
        }

        if (canvas.zoomOutCounter > 0) {
          mask.style.width = currentObj[0].width / currentObj[0].scaleX / Math.pow(scaleFactor, canvas.zoomOutCounter) + "px";
          mask.style.height = currentObj[0].height / currentObj[0].scaleY / Math.pow(scaleFactor, canvas.zoomOutCounter) + "px";
        }

        if (canvas.zoomInCounter === 0 && canvas.zoomOutCounter === 0) {
          mask.style.width = currentObj[0].width * currentObj[0].scaleX + "px";
          mask.style.height = currentObj[0].height * currentObj[0].scaleY + "px";
        }

        resolve(mask);
      });
    },

    /**
     * dragElement to drag element with canvas object
     *
     * @param {object} canvas -
     * @param {number} scaleFactor -
     * @param {object} object -
     * @param {object} mask -
     * @param {object} callback -
     */
    dragElement: function dragElement(canvas, scaleFactor, object, mask, callback) {
      var pos1 = 0;
      var pos2 = 0;
      var pos3 = 0;
      var pos4 = 0;
      document.getElementById(mask.id) ? document.getElementById(mask.id).onmousedown = dragMouseDown : mask.onmousedown = dragMouseDown;
      /**
       * Drag mouse down.
       *
       * @param {object} event - The mask event
       */

      function dragMouseDown(event) {
        var nextEvent = event || window.event;
        nextEvent.preventDefault();
        pos3 = nextEvent.clientX;
        pos4 = nextEvent.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
      }
      /**
       * Close drag element
       *
       * @param {object} event - The mask event
       */


      function elementDrag(event) {
        var nextEvent = event || window.event;
        nextEvent.preventDefault();

        if (canvas.zoomInCounter > 0) {
          pos1 = pos3 - nextEvent.clientX;
          pos2 = pos4 - nextEvent.clientY;
          pos3 = nextEvent.clientX;
          pos4 = nextEvent.clientY;
          mask.style.top = mask.offsetTop - pos2 + "px";
          mask.style.left = mask.offsetLeft - pos1 + "px";
          object.top = object.top - pos2 / Math.pow(scaleFactor, canvas.zoomInCounter);
          object.left = object.left - pos1 / Math.pow(scaleFactor, canvas.zoomInCounter);
        }

        if (canvas.zoomOutCounter > 0) {
          pos1 = pos3 - nextEvent.clientX;
          pos2 = pos4 - nextEvent.clientY;
          pos3 = nextEvent.clientX;
          pos4 = nextEvent.clientY;
          mask.style.top = mask.offsetTop - pos2 + "px";
          mask.style.left = mask.offsetLeft - pos1 + "px";
          object.top = object.top - pos2 * Math.pow(scaleFactor, canvas.zoomOutCounter);
          object.left = object.left - pos1 * Math.pow(scaleFactor, canvas.zoomOutCounter);
        }

        if (canvas.zoomInCounter === 0) {
          pos1 = pos3 - nextEvent.clientX;
          pos2 = pos4 - nextEvent.clientY;
          pos3 = nextEvent.clientX;
          pos4 = nextEvent.clientY;
          mask.style.top = mask.offsetTop - pos2 + "px";
          mask.style.left = mask.offsetLeft - pos1 + "px";
          object.top = object.top - pos2;
          object.left = object.left - pos1;
        }

        callback();
      }
      /**
       * Close drag element
       */


      function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
        canvas.setActiveObject(object);
      }
    }
  };
  return factory;
}

angular.module("app.generator").factory("fabricMask", FabricMask);