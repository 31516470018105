"use strict";
/**
 * Real marge controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} $scope - $scope.
 * @param {object} realMargeService - Real marge service.
 * @param {object} realMargeFactory - Real marge factory.
 */

RealMargeController.$inject = ["$rootScope", "StatisticsQueryService", "$scope", "realMargeService", "realMargeFactory"];

function RealMargeController($rootScope, StatisticsQueryService, $scope, realMargeService, realMargeFactory) {
  var vm = this;
  vm.tabSelected = "infos";
  $scope.parseFloat = parseFloat;
  vm.activeOpacity = {
    infos: "redOpacity",
    analysis: "amberOpacity",
    contrib: "blueOpacity",
    simu: "greenOpacity",
    variation: "yellowOpacity"
  };
  vm.activeClass = {
    infos: "red-200-bg",
    analysis: "amber-200-bg",
    contrib: "blue-100-bg",
    simu: "light-green-200-bg",
    variation: "yellow-200-bg"
  };
  $scope.loaderRealMargeOptions = {
    loading: false,
    loader: "Récupération de vos données...",
    empty: "Aucune donnée n'est disponible"
  };
  vm.configRealMarge = {
    event: "realMargeFilterChange",
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct", "category"],
    searchPlaceHolder: "Rechercher un produit, un laboratoire, un segment ...",
    tag: "configProductMarge",
    showBadge: true
  };
  vm.rawValues = StatisticsQueryService.values;

  vm.getLimits = function (array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  };

  vm.indexByLabel = function (name, list) {
    if (!name || !list) {
      return -1;
    }

    return list.findIndex(function (item) {
      return item.label === name;
    });
  };

  vm.existsFilter = function (name, list) {
    return vm.indexByLabel(name, list) > -1;
  };

  vm.toggleCb = function (item, list) {
    vm.productsMarge = [];
    vm.productsMargeSave = [];
    var idx = vm.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      vm.margeQuery = query;
      vm.getMarges();
    });
  };

  vm.csvHeaders = ["CIP", "Nom", "Laboratoire", "Quantités vendues", "Prix Fabricant HT", "Remise %", "Prix d'achat net HT", "Prix de vente HT", "CA", "Portion de quantité", "Marge en € à la boîte", "% de CA", "Marge en €", "Portion de la marge", "Marge en %", "Total marge si 100% des ventes", "Variation CA", "Variation de marge", "Variation de marge en €"];
  vm.csvFileName = "Export-Simulation-Marge.csv";
  vm.isEdited = false;
  StatisticsQueryService.queryRebase();
  vm.usrquery = StatisticsQueryService.query;
  StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
    vm.margeQuery = query;
    var dateDebut = moment(vm.usrquery.date.from).format("DD-MMM-YYYY");
    var dateFin = moment(vm.usrquery.date.to).format("DD-MMM-YYYY");
    vm.dateLabel = dateDebut + " Au " + dateFin;
  });
  $scope.query = {
    limit: 5,
    page: 1
  };

  vm.getMarges = function () {
    $scope.loaderRealMargeOptions.loading = true;
    realMargeService.get(vm.margeQuery).then(function (datas) {
      vm.productsMarge = datas.map(function (data) {
        data.discountEdit = false;

        if (data.discount === 0) {
          data.discount = null;
        }

        data.name = data.product.name;
        return data;
      });
      vm.productsMargeSave = JSON.parse(JSON.stringify(vm.productsMarge));
      vm.totalNumberQt = _.reduce(datas, function (memo, product) {
        return memo + product.quantity;
      }, 0);
      vm.totalNumberCa = _.reduce(datas, function (memo, product) {
        return memo + product.price_sell_TTC * product.quantity;
      }, 0);
      vm.totalEuro = _.reduce(datas, function (memo, product) {
        return memo + product.product.buyingPrice * product.quantity;
      }, 0);
      vm.totalDiscount = _.reduce(datas, function (memo, product) {
        return memo + (product.product.buyingPrice * product.discount || 0) / 100 * product.quantity;
      }, 0);
      vm.totalMargeEuros = vm.totalEuro - vm.totalDiscount;
      vm.totalNumberQtSave = vm.totalNumberQt;
      vm.totalNumberCaSave = vm.totalNumberCa;
      vm.totalMargeEurosSave = vm.totalMargeEuros;
      realMargeFactory.setGlobalVar(vm.totalNumberQt, vm.totalNumberCa, vm.totalMargeEuros);
      $scope.loaderRealMargeOptions.loading = false;
    });
  };
  /*
       ** Date Filter
       */


  vm.rangeSelected = function (date) {
    vm.usrquery.date.from = moment(date.startDate, "DD-MM-YYYY");
    vm.usrquery.date.to = moment(date.endDate, "DD-MM-YYYY");
    vm.productsMarge = [];
    vm.productsMargeSave = [];
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      vm.margeQuery = query;
      vm.getMarges();
    });
  };
  /**
   * Change total datas
   *
   * @param {object} line - Line.
   * @param {object} lineSave - Line save.
   */


  vm.changeRow = function (line, lineSave) {
    vm.totalNumberQt = _.reduce(vm.productsMarge, function (memo, product) {
      return memo + parseInt(product.quantity);
    }, 0);
    vm.totalNumberQtSave = vm.totalNumberQt;
    vm.totalNumberCa = _.reduce(vm.productsMarge, function (memo, product) {
      return memo + parseFloat(product.price_sell_TTC) * parseInt(product.quantity);
    }, 0);
    vm.totalNumberCaSave = vm.totalNumberCa;
    vm.totalMargeEuros = _.reduce(vm.productsMarge, function (memo, product) {
      return memo + (parseFloat(product.price_sell_TTC) - (parseFloat(product.product.buyingPrice) - (parseFloat(product.product.buyingPrice) * parseFloat(product.discount) || 0) / 100)) * parseInt(product.quantity);
    }, 0);
    vm.totalMargeEurosSave = vm.totalMargeEuros;
    realMargeFactory.setGlobalVar(vm.totalNumberQt, vm.totalNumberCa, vm.totalMargeEuros);
    realMargeFactory.changeRow(line);
    realMargeFactory.changeRow(lineSave);
  };
  /**
   * Delete a product from the analysis
   *
   * @param {number} index -
   */


  vm.deleteRow = function (index) {
    var productToDelete = _.find(vm.usrquery.must.product.selected, function (product) {
      vm.productsMarge[index].id = product.id;
      return vm.productsMarge[index].id;
    });

    if (productToDelete) {
      var indexDeletedProduct = vm.usrquery.must.product.selected.indexOf(productToDelete);

      if (indexDeletedProduct >= 0) {
        vm.usrquery.must.product.selected.splice(indexDeletedProduct, 1);
      }
    }

    vm.productsMarge.splice(index, 1);
    vm.productsMargeSave.splice(index, 1);
    vm.totalNumberQt = _.reduce(vm.productsMarge, function (memo, product) {
      return memo + parseInt(product.quantity);
    }, 0);
    vm.totalNumberCa = _.reduce(vm.productsMarge, function (memo, product) {
      return memo + parseFloat(product.price_sell_TTC) * parseInt(product.quantity);
    }, 0);
    vm.totalMargeEuros = _.reduce(vm.productsMarge, function (memo, product) {
      return memo + (product.price_sell_TTC - product.product.buyingPrice - (product.product.buyingPrice * product.discount || 0) / 100) * product.quantity;
    }, 0);
    vm.totalNumberQtSave = vm.totalNumberQt;
    vm.totalNumberCaSave = vm.totalNumberCa;
    vm.totalMargeEurosSave = vm.totalMargeEuros;
  };
  /**
   * Add all selected filters to the list
   */


  $rootScope.$on("realMargeFilterChange", function (event, data) {
    vm.productsMarge = [];

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        angular.forEach(data[type], function (obj) {
          vm.usrquery.must[type].selected.push(obj);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    }

    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      vm.margeQuery = query;
      vm.getMarges();
    });
  });

  vm.exportSimulation = function (simu) {
    var exports = [];
    var baseArray = vm.productsMargeSave;
    var margeTotalEuro = vm.totalMargeEurosSave;
    var totalQuantity = vm.totalNumberQtSave;
    var totalCa = vm.totalNumberCaSave;

    if (simu) {
      baseArray = vm.productsMarge;
      margeTotalEuro = vm.totalMargeEuros;
      totalQuantity = vm.totalNumberQt;
      totalCa = vm.totalNumberCa;
    }

    angular.forEach(baseArray, function (marge) {
      exports.push({
        cip: marge.product.id_7,
        name: marge.product.name,
        laboName: marge.product.laboratory.name,
        quantity: marge.quantity,
        pfht: marge.product.buyingPrice,
        discount: marge.discount,
        buyingPrice: (marge.product.buyingPrice - (marge.product.buyingPrice * marge.discount || 0) / 100).toFixed(2),
        sellPrice: marge.price_sell_HT.toFixed(2),
        ca: (marge.price_sell_TTC * marge.quantity).toFixed(2),
        quantityPercentage: (marge.quantity / totalQuantity * 100).toFixed(2),
        packingMarge: (marge.price_sell_TTC - (marge.product.buyingPrice - (marge.product.buyingPrice * marge.discount || 0) / 100)).toFixed(2),
        CApercentage: (marge.price_sell_TTC * marge.quantity / totalCa * 100).toFixed(2),
        euroMarge: ((marge.price_sell_TTC - (marge.product.buyingPrice - marge.product.buyingPrice * (marge.discount || 0) / 100)) * marge.quantity).toFixed(2),
        margePercentage: ((marge.price_sell_TTC - (marge.product.buyingPrice - marge.product.buyingPrice * (marge.discount || 0) / 100)) * marge.quantity / margeTotalEuro * 100).toFixed(2),
        unitPercentageMarge: ((marge.price_sell_TTC - (marge.product.buyingPrice - (marge.product.buyingPrice * marge.discount || 0) / 100)) / marge.price_sell_TTC * 100).toFixed(2),
        margeAllSales: ((marge.price_sell_TTC - (marge.product.buyingPrice - (marge.product.buyingPrice * marge.discount || 0) / 100)) * totalQuantity).toFixed(2),
        CaMovement: ((marge.price_sell_TTC * totalQuantity - totalCa) / totalCa * 100).toFixed(2),
        margeMovement: Number((((marge.price_sell_TTC - (marge.product.buyingPrice - (marge.product.buyingPrice * marge.discount || 0) / 100)) * totalQuantity - margeTotalEuro) / margeTotalEuro).toFixed(2)),
        euroMargeMovement: ((marge.price_sell_TTC - (marge.product.buyingPrice - (marge.product.buyingPrice * marge.discount || 0) / 100)) * totalQuantity - margeTotalEuro).toFixed(2)
      });
    });
    return exports;
  };

  vm.showNameSearch = function () {
    vm.nameSearch = !vm.nameSearch;
  };

  vm.showLaboNameSearch = function () {
    vm.labonameSearch = !vm.labonameSearch;
  };
}

angular.module("app.statistics.realMarge").controller("RealMargeController", RealMargeController);