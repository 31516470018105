"use strict";
/**
 * Team service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

TeamService.$inject = ["apiClient"];

function TeamService(apiClient) {
  return {
    /**
     * Get operators code.
     *
     * @returns {Promise}
     */
    getOperatorsCode: function getOperatorsCode() {
      return apiClient.get("/access/operators", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get rank list.
     *
     * @returns {Promise}
     */
    getRankList: function getRankList() {
      return apiClient.get("/access/rank-list", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get my team.
     *
     * @returns {Promise}
     */
    getMyTeam: function getMyTeam() {
      return apiClient.get("/access/team", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update access.
     *
     * @param {object} data -
     *
     * @returns {Promise}
     */
    updateAccess: function updateAccess(data) {
      return apiClient.put("/access/update", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get pending requests.
     *
     * @returns {Promise}
     */
    getPendingRequests: function getPendingRequests() {
      return apiClient.get("/access/pending-requests", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.teammanager").service("teamService", TeamService);