"use strict";
/**
 * Users transactions configuration.
 *
 * @param {object} $stateProvider - $stateProvider
 */

UsersTransactionsConfig.$inject = ["$stateProvider"];

function UsersTransactionsConfig($stateProvider) {
  $stateProvider.state("app.UserTransactionsHome", {
    url: "/users/transactions",
    views: {
      "content@app": {
        templateUrl: "app/main/users-transactions/users-transactions.html",
        controller: "UserTransactionsController"
      }
    }
  });
}

angular.module("app.users-transactions", []).config(UsersTransactionsConfig);