"use strict";
/**
 * PatientAdministrationProductsPathologyController
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} pathologyService - The pathology service.
 * @param {object} notifyUserService - The notify user service.
 */

PatientAdministrationCustomerAssociationsPathologyController.$inject = ["$scope", "$mdDialog", "pathologyService", "notifyUserService"];

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function PatientAdministrationCustomerAssociationsPathologyController($scope, $mdDialog, pathologyService, notifyUserService) {
  $scope.associations = [];
  $scope.currentAssociation = {
    url: "",
    name: ""
  };
  $scope.addAssociation = addCurrentAssociation;
  $scope.deleteAssociation = deleteAssociation;
  loader();
  /**
   * Loader function.
   */

  function loader() {
    getAssociations();
  }
  /**
   * Get the customer associations attached to this pathology.
   */


  function getAssociations() {
    pathologyService.getCustomerAssociationsForPathology($scope.selectedPathology.id).then(function (associations) {
      $scope.associations = associations;
    });
  }
  /**
   * Sync the associations to the current selected pathology.
   *
   * @param {Array} associations - The urls of the associations to sync.
   *
   * @returns {Promise}
   */


  function syncAssociations(associations) {
    return pathologyService.syncCustomerAssociationsToPathology($scope.selectedPathology.id, associations);
  }
  /**
   * Add the current association.
   *
   * @param {string} association - The association url.
   */


  function addCurrentAssociation(association) {
    var associations = [].concat(_toConsumableArray($scope.associations), [association]);
    syncAssociations(associations).then(function () {
      $scope.associations = associations;
      notifyUserService.showSuccessToast("L'association a bien été liée à la pathologie.");
      $scope.currentAssociation = {};
    });
  }
  /**
   * Delete the association from the segment.
   *
   * @param {string} association - The association.
   */


  function deleteAssociation(association) {
    var associations = $scope.associations;
    associations.splice(associations.indexOf(association), 1);
    syncAssociations(associations).then(function () {
      $scope.associations = associations;
      notifyUserService.showSuccessToast("L'association a bien été supprimée de la pathologie.");
    });
  }
}

angular.module("app.patient-administration").controller("patientAdministrationCustomerAssociationsPathologyController", PatientAdministrationCustomerAssociationsPathologyController);