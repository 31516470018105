"use strict";
/**
 * Apodis brick search directive.
 *
 * @returns {object}
 */

ApodisBrickSearchController.$inject = ["$rootScope", "$scope", "bricksService"];

function ApodisBrickSearch() {
  return {
    restrict: "E",
    templateUrl: "app/main/directives/apodis-brick-search/apodisBrickSearch.html",
    transclude: true,
    controller: ApodisBrickSearchController
  };
}
/**
 * Apodis brick search controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} bricksService - Bricks service.
 */


function ApodisBrickSearchController($rootScope, $scope, bricksService) {
  /**
   * Declaration of public methods
   */
  $scope.search = search;
  $scope.goTo = goTo;
  /**
   * Declaration of public objects
   */

  $scope.mySearch = "";
  $scope.menus = [];
  /**
   * Initialization of the directive
   */

  getBricks();
  /**
   * Construct results by search
   */

  function search() {
    if ($scope.mySearch.length > 0) {
      $scope.results = $scope.menus.filter(function (menu) {
        menu.brick.border_color = "transparent transparent transparent" + menu.brick.color;
        return menu.name.match(new RegExp($scope.mySearch, "i"));
      });
    } else {
      $scope.results = [];
    }
  }
  /**
   * Get bricks and create new object menu with brick inside
   *
   * @returns {Promise}
   */


  function getBricks() {
    return bricksService.getBricks().then(function (response) {
      $scope.bricks = response;
      response.map(function (brick) {
        return brick.menus.map(function (menu) {
          return Object.assign({}, menu, {
            brick: brick
          });
        });
      }).forEach(function (menus) {
        menus.forEach(function (menu) {
          $scope.menus.push(menu);
        });
      });
      return response;
    });
  }
  /**
   * Reset mySearch and result.
   *
   * @param {object} menu - Menu.
   */


  function goTo(menu) {
    $scope.mySearch = "";
    $scope.results = [];
    $rootScope.$broadcast("sideNavConstruct:with-menu", menu);
  }
}

angular.module("app.directives").directive("apodisBrickSearch", ApodisBrickSearch);