"use strict";
/**
 * Statistics products configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

StatisticsProductsConfig.$inject = ["$stateProvider"];

function StatisticsProductsConfig($stateProvider) {
  $stateProvider.state("app.statistics.products", {
    url: "/analyse-produits",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/productAnalysis/title.html",
        controller: "StatisticsController as vm"
      },
      "statisticsContent@app.statistics.products": {
        templateUrl: "app/main/statistics/productAnalysis/views/home/productAnalysisHome.html",
        controller: "StatsProductHomeController as vm"
      },
      "tabContent@app.statistics.products": {
        templateUrl: "app/main/statistics/productAnalysis/views/productPrice/productAnalysis.html",
        controller: "StatsProductController as vm"
      }
    }
  }).state("app.statistics.products.price", {
    url: "/prix",
    views: {
      "tabContent@app.statistics.products": {
        templateUrl: "app/main/statistics/productAnalysis/views/productPrice/productAnalysis.html",
        controller: "StatsProductController as vm"
      }
    }
  }).state("app.statistics.products.agePyramide", {
    url: "/pyramide",
    views: {
      "tabContent@app.statistics.products": {
        templateUrl: "app/main/statistics/productAnalysis/views/agePyramide/agePyramide.html",
        controller: "AgePyramideController as vm"
      }
    }
  }).state("app.statistics.products.supplying", {
    url: "/appro",
    views: {
      "tabContent@app.statistics.products": {
        templateUrl: "app/main/statistics/stocks/supplying/supplying.html",
        controller: "SupplyingController as vm"
      }
    }
  }).state("app.statistics.products.distributor", {
    url: "/distributor",
    views: {
      "tabContent@app.statistics.products": {
        templateUrl: "app/main/statistics/productAnalysis/views/distributor/distributor.html",
        controller: "distributorController"
      }
    }
  }).state("app.statistics.products.observance", {
    url: "/observance",
    views: {
      "tabContent@app.statistics.products": {
        templateUrl: "app/main/statistics/productAnalysis/views/observance/observance.html",
        controller: "ObservanceController as vm"
      }
    }
  }).state("app.statistics.products.pharmacovigilance", {
    url: "/pharmacovigilance",
    views: {
      "tabContent@app.statistics.products": {
        templateUrl: "app/main/statistics/productAnalysis/views/pharmacovigilance/pharmacovigilance.html",
        controller: "PharmacovigilanceController as vm"
      }
    }
  }).state("app.statistics.products.topSalesProduct", {
    url: "/prix-segments",
    views: {
      "tabContent@app.statistics.products": {
        templateUrl: "app/main/statistics/productAnalysis/views/topSalesProduct/topSalesProduct.html",
        controller: "topSalesProductController"
      }
    }
  });
}

angular.module("app.statistics.products").config(StatisticsProductsConfig);