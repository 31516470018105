/* eslint-disable no-invalid-this */
"use strict";
/**
 * Format sellin challenge factory.
 *
 * @param {object} $q - $q.
 * @returns {object}
 */

FormatSellinChallenge.$inject = ["$q"];

function FormatSellinChallenge($q) {
  /**
   * Constructor to format a sellinChallenge
   *
   * @param {object} challenge -
   */
  function format(challenge) {
    var products = [];
    var targets = [];
    var steps = [];

    if (challenge) {
      products = challenge.products.map(function (product) {
        return {
          presentationId: product.id,
          requestedQuantity: product.requestedQuantity ? product.requestedQuantity : null,
          groupId: product.groupId ? product.groupId : null
        };
      });
      targets = challenge.targets.map(function (target) {
        return {
          id: target.id,
          organizationId: target.organizationId,
          name: target.name,
          type: target.type
        };
      });
      steps = challenge.steps;
    }

    this.name = challenge ? challenge.name : "";
    this.description = challenge ? challenge.description : "";
    this.toBePublished = challenge ? challenge.toBePublished : false;
    this.organizationId = challenge ? challenge.organizationId : null;
    this.laboratoryId = challenge ? challenge.laboratoryId : 0;
    this.wholesalerId = challenge ? challenge.wholesalerId : null;
    this.type = challenge ? challenge.type : "";
    this.isAllTargetAllowed = challenge ? challenge.isAllTargetAllowed : false;
    this.isForApodis = challenge ? challenge.isForApodis : false;
    this.isForWholesalers = challenge ? challenge.isForWholesalers : false;
    this.isPerPharmacy = challenge ? challenge.isPerPharmacy : null;
    this.isDiscountedByStepProduct = challenge ? challenge.isDiscountedByStepProduct : false;
    this.hasMinimumQuantityByProductGroup = challenge ? challenge.hasMinimumQuantityByProductGroup : false;
    this.fileId = !challenge ? null : challenge.file ? challenge.file.id : null;
    this.startDate = challenge ? moment(challenge.startDate).format("YYYY-MM-DD") : new Date();
    this.limitActivationDate = challenge && challenge.type.id === 2 ? moment(challenge.limitActivationDate).format("YYYY-MM-DD") : null;
    this.endDate = challenge ? moment(challenge.endDate).format("YYYY-MM-DD") : new Date();
    this.groups = challenge ? challenge.groups : [];
    this.products = products;
    this.targets = targets;
    this.steps = steps;
  }
  /**
   * format.prototype.build
   *
   * @param {object} challenge - A sellin challenge
   * @returns {Promise}
   */


  format.prototype.build = function (challenge) {
    return $q(function (resolve) {
      resolve(new format(challenge));
    });
  };
  /**
   * Return the constructor function
   * format
   */


  return format;
}

angular.module("app.sellinChallenge").factory("formatSellinChallenge", FormatSellinChallenge);