"use strict";
/**
 * Documentation configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

DocumentationConfig.$inject = ["$stateProvider"];

function DocumentationConfig($stateProvider) {
  $stateProvider.state("app.overall", {
    url: "/globaux",
    views: {
      "content@app": {
        templateUrl: "app/main/documentation/views/overall/overall.html",
        controller: "documentationOverallController"
      }
    }
  }).state("app.buttons", {
    url: "/boutons",
    views: {
      "content@app": {
        templateUrl: "app/main/documentation/views/buttons/buttons.html",
        controller: "documentationButtonsController"
      }
    }
  }).state("app.filters", {
    url: "/filtres",
    views: {
      "content@app": {
        templateUrl: "app/main/documentation/views/filters/filters.html",
        controller: "documentationFiltersController"
      }
    }
  }).state("app.titles", {
    url: "/titres",
    views: {
      "content@app": {
        templateUrl: "app/main/documentation/views/titles/titles.html",
        controller: "documentationTitlesController"
      }
    }
  }).state("app.widgets", {
    url: "/widgets",
    views: {
      "content@app": {
        templateUrl: "app/main/documentation/views/widgets/widgets.html",
        controller: "documentationWidgetsController"
      }
    }
  }).state("app.lists", {
    url: "/lists",
    views: {
      "content@app": {
        templateUrl: "app/main/documentation/views/lists/lists.html",
        controller: "documentationListsController"
      }
    }
  });
}

angular.module("app.documentation", []).config(DocumentationConfig);