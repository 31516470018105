"use strict";
/**
 * Merchandising plan main service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

MerchandisingPlanMainService.$inject = ["apiClient"];

function MerchandisingPlanMainService(apiClient) {
  return {
    /**
     * Get merchandising plans.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/merchandising-plans").then(function (response) {
        return response.data;
      });
    },

    /**
     * Create merchandising plan.
     *
     * @param {object} params - Params.
     *
     * @returns {Promise}
     */
    create: function create(params) {
      return apiClient.post("/merchandising-plans", {
        data: params
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update merchandising plan.
     *
     * @param {object} params - Params.
     *
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.patch("/merchandising-plans/".concat(params.merchandisingPlanId), {
        data: params
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete merchandising plan.
     *
     * @param {object} params - Params.
     *
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/merchandising-plans/".concat(params.merchandisingPlanId), {
        data: params
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.merchandising-plan").service("merchandisingPlanMainService", MerchandisingPlanMainService);