"use strict";
/**
 * Map controller.
 *
 * @param {object} $state - $state.
 * @param {object} environmentService - Environment service.
 * @param {object} teamService - Team service.
 */

MapController.$inject = ["$state", "environmentService", "teamService"];

function MapController($state, environmentService, teamService) {
  var vm = this;
  /**
   * Initialize tab.
   */

  function initTab() {
    var myLatLng = {
      lat: 47.6468754,
      lng: 2.3242131
    };
    vm.markers = [];
    var mapOptions = {
      center: new google.maps.LatLng(myLatLng),
      zoom: 4,
      minZoom: 6,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_CENTER
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      addressControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      fullscreenControl: true
    };
    vm.map = new google.maps.Map(document.getElementById("map_members"), mapOptions);
    teamService.getMyTeam().then(function (response) {
      response.team.forEach(function (item) {
        var label = null;

        if (!item.validated) {
          label = {
            text: "!",
            color: "#fff",
            fontWeight: "bold"
          };
        }

        var marker = new google.maps.Marker({
          position: {
            lat: item.entity.address.latitude,
            lng: item.entity.address.longitude
          },
          label: label,
          animation: google.maps.Animation.DROP,
          map: vm.map
        });
        var content = "<strong>" + item.entity.name + "</strong>" + "<p>" + item.entity.address.streetLine1 + "</p>";

        if (item.entity.address.streetLine2) {
          content += "<p>" + item.entity.address.streetLine2 + "</p>";
        }

        content += "<p>" + item.entity.address.postalCode + "&nbsp;" + item.entity.address.cityName + "</p>";

        if (!item.validated) {
          var link = $state.get("app.teammanager.members");
          content += "<a href='" + link.url + "'>Cette pharmacie ne fait pas encore partie du groupement.</a>";
        }

        var infowindow = new google.maps.InfoWindow({
          content: content
        });
        marker.addListener("click", function () {
          infowindow.open(vm.map, marker);
        });
      });
    });
    vm.loading = false;
  }

  var currentEnvironment = environmentService.getEnvironment();
  currentEnvironment.then(function (env) {
    vm.currentEnv = env;
    initTab();
  });
}

angular.module("app.teammanager").controller("MapController", MapController);