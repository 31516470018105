"use strict";
/**
 * Patients events controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} eventFactory - Event factory.
 * @param {object} eventService - Events service.
 * @param {object} patientEventService - Patient event service.
 * @param {object} $filter - filter.
 * @param {object} $state - $state.
 * @param {object} $timeout - $timeout.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} notifyUserService - Notify user service.
 */

PatientsEventsController.$inject = ["$scope", "environmentService", "eventFactory", "eventService", "patientEventService", "$filter", "$state", "$timeout", "$mdDialog", "notifyUserService"];
PatientEventModalController.$inject = ["$scope", "$mdDialog", "data"];

function PatientsEventsController($scope, environmentService, eventFactory, eventService, patientEventService, $filter, $state, $timeout, $mdDialog, notifyUserService) {
  $scope.events = null;
  $scope.groupedEvents = null;
  $scope.createEvent = createEvent;
  $scope.openPatientEventModal = openPatientEventModal;
  $scope.deleteEvent = deleteEvent;
  $scope.calendarOptions = eventFactory.calendarOptions;
  $scope.uiConfig = {
    calendar: {
      lang: "fr",
      header: {
        left: "title today prev,next",
        center: "",
        right: "month basicWeek basicDay agendaDay"
      },
      buttonText: {
        today: "Aujourd'hui",
        month: "Mois",
        basicWeek: "Semaine",
        basicDay: "Jour",
        agendaDay: "Agenda"
      },
      views: {
        month: {
          titleFormat: "DD MMMM YYYY"
        },
        basicWeek: {
          titleFormat: "DD MMMM YYYY"
        },
        basicDay: {
          titleFormat: "DD MMMM YYYY"
        },
        agendaWeek: {
          titleFormat: "DD MMMM YYYY"
        },
        agendaDay: {
          titleFormat: "DD MMMM YYYY"
        },
        agenda: {
          eventLimit: 50
        },
        axisFormat: "H:mm",
        timeFormat: {
          agenda: "H:mm{ - H:mm}"
        },
        eventLimit: false,
        eventTextColor: "black",
        allDayText: "Toute la journée",
        eventLimitText: "Plus",
        eventClick: openPatientEventModal
      }
    }
  };
  loader();
  /**
   * The loader function.
   */

  function loader() {
    getEvents();
  }
  /**
   * Retrieve the events.
   *
   * @returns {void}
   */


  function getEvents() {
    $scope.calendarOptions.loading = true;
    patientEventService.getEvents().then(function (response) {
      $scope.eventsSaved = response.data;
      manageEvent();
    });
  }
  /**
   * Delete an event.
   *
   * @param {object} event - The event.
   */


  function deleteEvent(event) {
    var confirm = $mdDialog.confirm().title("Souhaitez-vous supprimer cet événement ?").ariaLabel("Supprimer un événement").ok("Oui").cancel("Non");
    $mdDialog.show(confirm).then(function () {
      eventService["delete"](event).then(function (response) {
        notifyUserService.showSuccessToast("L'événement « " + response.event.title + " » a bien été supprimé.");
        getEvents();
      });
    });
  }
  /**
   * Manage events.
   *
   * @returns {void}
   */


  function manageEvent() {
    $scope.calendarOptions.loading = true;
    var tmpEvents = [];
    $timeout(function () {
      angular.forEach($scope.eventsSaved, function (event) {
        if (event.start_date) {
          event.color = "#4BBECF";
          event.start = moment(event.start_date);
          event.end = moment(event.end_date);
          event.className = event.title.replace(/[^a-z0-9]+/gi, "a").replace(/ /g, "-") + event.id;
          tmpEvents.push(event);
        }
      });
      $scope.events = {
        events: tmpEvents
      };
      $scope.eventsList = splitedEvents($scope.events.events);
      $scope.calendarOptions.loading = false;
      return $scope.events;
    }, 800);
  }
  /**
   * Split the events depending if the event is incoming or is ongoing.
   *
   * @param {object[]} events - The events
   *
   * @returns {{incoming: *, current: *}}
   */


  function splitedEvents(events) {
    var incomingEvents = events.filter(function (event) {
      return event.start > moment();
    });
    var currentEvents = events.filter(function (event) {
      return event.start < moment() && event.end > moment();
    });
    return {
      current: currentEvents,
      incoming: groupEventsByMonth(incomingEvents)
    };
  }
  /**
   * Group events by month.
   *
   * @param {object[]} events - The events to group.
   *
   * @returns {Array|null}
   */


  function groupEventsByMonth(events) {
    if (!events) {
      return null;
    }

    var groups = events.reduce(function (previous, current) {
      var month = current.start.format("dddd-MM-YYYY").split("-")[1];
      previous[month] ? previous[month].data.push(current) : previous[month] = {
        group: moment(month).format("MMMM"),
        data: [current]
      };
      return previous;
    }, {});
    return Object.keys(groups).map(function (value) {
      return groups[value];
    });
  }
  /**
   * Open patient event details modal
   *
   * @param {object} event - The event data.
   */


  function openPatientEventModal(event) {
    $mdDialog.show({
      controller: PatientEventModalController,
      locals: {
        data: event,
        eventService: eventService,
        notifyUserService: notifyUserService
      },
      scope: $scope,
      preserveScope: true,
      templateUrl: "app/main/patients/views/events-list/modals/patient-event-modal.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  }
  /**
   * Redirect to event creation page.
   */


  function createEvent() {
    $state.go("app.patients.events.create");
  }
}
/**
 * Patient event modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} data - Data.
 */


function PatientEventModalController($scope, $mdDialog, data) {
  $scope.close = close;
  $scope.data = data;
  /**
   * Close the modal.
   */

  function close() {
    $mdDialog.hide();
  }
}

angular.module("app.patients").controller("patientsEventsController", PatientsEventsController);