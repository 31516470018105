"use strict";
/**
 * Rewards service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

RewardsService.$inject = ["apiClient"];

function RewardsService(apiClient) {
  return {
    /**
     * Get rewards types.
     *
     * @returns {Promise}
     */
    getRewardTypes: function getRewardTypes() {
      return apiClient.get("/reward/type", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.rewardTypes;
      });
    },

    /**
     * Get reward distributions.
     *
     * @returns {Promise}
     */
    getRewardDistributions: function getRewardDistributions() {
      return apiClient.get("/reward/distribution", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.rewardDistributions;
      });
    },

    /**
     * Get reward objectives modes.
     *
     * @returns {Promise}
     */
    getRewardObjectiveModes: function getRewardObjectiveModes() {
      return apiClient.get("/reward/objective/mode", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.rewardObjectiveModes;
      });
    }
  };
}

angular.module("app.services").service("rewardsService", RewardsService);