"use strict";
/**
 * MDL service.
 *
 * @param {object} apiClient - API client service
 * @returns {object}
 */

MdlService.$inject = ["apiClient"];

function MdlService(apiClient) {
  return {
    /**
     * For a period.
     *
     * @param {object} data -
     * @returns {Promise}
     */
    forAPeriod: function forAPeriod(data) {
      return apiClient.post("/mdl/getSmoothedDegressiveMargin", data).then(function (response) {
        return response.data;
      });
    },

    /**
     * Estimated.
     *
     * @returns {Promise}
     */
    estimated: function estimated() {
      return apiClient.get("/mdl/getEstimatedSmoothedDegressiveMargin").then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics").service("mdlService", MdlService);