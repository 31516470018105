"use strict";
/**
 * Entity admin teams service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

EntityAdminTeamsService.$inject = ["apiClient"];

function EntityAdminTeamsService(apiClient) {
  return {
    /**
     * Get teams.
     *
     * @returns {Promise}
     */
    getTeams: function getTeams() {
      return apiClient.get("/teams", {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.teams;
      });
    },

    /**
     * Get team by Id.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getTeamById: function getTeamById(params) {
      return apiClient.get("/teams/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.team;
      });
    },

    /**
     * Get accesses.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getAccesses: function getAccesses(params) {
      return apiClient.get("/teams/".concat(params.id, "/accesses"), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.accesses;
      });
    },

    /**
     * Get authorizations.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getAuthorizations: function getAuthorizations(params) {
      return apiClient.get("/teams/".concat(params.id, "/authorizations"), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.authorizations;
      });
    },

    /**
     * Get menus.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getMenus: function getMenus(params) {
      return apiClient.get("/teams/".concat(params.id, "/menus"), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.menus;
      });
    },

    /**
     * Create.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    create: function create(params) {
      return apiClient.post("/teams", params, {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.team;
      });
    },

    /**
     * Update.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/teams/".concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.team;
      });
    },

    /**
     * Delete.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/teams/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.team;
      });
    },

    /**
     * Get bricks by team.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getBricksByTeam: function getBricksByTeam(params) {
      return apiClient.get("/bricks/teams/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.bricks;
      });
    }
  };
}

angular.module("app.entityAdmin").service("entityAdminTeamsService", EntityAdminTeamsService);