"use strict";
/**
 * Disruption partners controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} disruptionPartnersService - The disruption partners service.
 * @param {string} apiurl - The api url.
 * @param {object} disruptionService - The disruption service.
 */

DisruptionPartnersController.$inject = ["$scope", "disruptionPartnersService", "apiurl", "disruptionService"];

function DisruptionPartnersController($scope, disruptionPartnersService, apiurl, disruptionService) {
  $scope.disruptionPartners = [];
  $scope.activePartner = {};
  $scope.seeInfo = seeInfo;
  $scope.rangeSelected = rangeSelected;
  $scope.loaderDisruptionsOptions = {
    loading: true,
    loader: "Chargement des pharmaML négatifs",
    empty: "Aucun pharmaML négatif."
  };
  $scope.pagination = {
    limit: 10,
    page: 1,
    order: "-_source.datetime"
  };
  load();
  /**
   * Load the page.
   */

  function load() {
    disruptionPartnersService.get().then(function (partners) {
      $scope.disruptionPartners = partners.map(function (partner) {
        var logo = partner.morphable.branding.logo;
        partner.logoPath = apiurl + "/images/" + logo.path + "?token=" + logo.token;
        return partner;
      });
    });
  }
  /**
   * See partner infos.
   *
   * @param {object} partner - The partner clicked.
   */


  function seeInfo(partner) {
    $scope.activePartner = partner;
    var params = {
      startDatetime: moment().subtract(1, "year").format("YYYY-MM-DD"),
      endDatetime: moment().format("YYYY-MM-DD"),
      partnerLaboratory: $scope.activePartner.morphable.id,
      withNotifications: true
    };
    $scope.dateLabel = moment(params.startDatetime).format("DD-MMM-YYYY") + " Au " + moment(params.endDatetime).format("DD-MMM-YYYY");
    disruptionService.getNegativePharmaML(params).then(function (negativePharmaML) {
      $scope.negativePharmaMLs = negativePharmaML.hits.hits;
      $scope.loaderDisruptionsOptions.loading = false;
    });
  }
  /**
   * Range selected.
   *
   * @param {object} date - the range date.
   */


  function rangeSelected(date) {
    var params = {
      startDatetime: moment(date.startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      endDatetime: moment(date.endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      partnerLaboratory: $scope.activePartner.morphable.id
    };
    $scope.dateLabel = moment(params.startDatetime).format("DD-MMM-YYYY") + " Au " + moment(params.endDatetime).format("DD-MMM-YYYY");
    disruptionService.getNegativePharmaML(params).then(function (negativePharmaML) {
      $scope.negativePharmaMLs = negativePharmaML.hits.hits;
      $scope.loaderDisruptionsOptions.loading = false;
    });
  }
}

angular.module("app.disruption").controller("disruptionPartnersController", DisruptionPartnersController);