"use strict";
/**
 * Dashboard pharmacies sellout prestation events week controller.
 *
 * @param {object} $scope - $scope.
 */

DashboardPharmaciesSelloutPrestationsEventsWeekController.$inject = ["$scope"];

function DashboardPharmaciesSelloutPrestationsEventsWeekController($scope) {
  $scope.options = {
    type: "sellout-participation,prestation-participation",
    start: moment().format("YYYY-MM-DD"),
    end: moment().add(7, "days").format("YYYY-MM-DD")
  };
}

angular.module("app.dashboard").controller("dashboardPharmaciesSelloutPrestationsEventsWeekController", DashboardPharmaciesSelloutPrestationsEventsWeekController);