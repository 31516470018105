"use strict";
/**
 * Usersprofile configuration.
 *
 * @param {object} $stateProvider - The angular $stateProvider object.
 */

UserProfileConfig.$inject = ["$stateProvider"];

function UserProfileConfig($stateProvider) {
  $stateProvider.state("app.pages_user_profile", {
    url: "/mon-profil",
    views: {
      "content@app": {
        templateUrl: "app/main/users/profile.html",
        controller: "UserProfileController as vm"
      }
    },
    bodyClass: "user-profile"
  });
}

angular.module("app.users", []).config(UserProfileConfig);