"use strict";
/**
 * Dashboard pharmacies top laboratory controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} analysisService - Analysis service.
 */

DashboardPharmaciesTopLaboratoryController.$inject = ["$scope", "StatisticsQueryService", "analysisService"];

function DashboardPharmaciesTopLaboratoryController($scope, StatisticsQueryService, analysisService) {
  $scope.loading = true;
  loader();
  /**
   * Loader.
   */

  function loader() {
    launchStatisticsQuery();
  }
  /**
   * Launch stastitics query.
   */


  function launchStatisticsQuery() {
    $scope.usrquery = JSON.parse(JSON.stringify(StatisticsQueryService.pristineQuery));
    $scope.query = StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date);
    $scope.query.then(function () {
      getTopParapharmacieLab($scope.usrquery);
    });
  }
  /**
   * Get top parapharmacie laboratory.
   *
   * @param {object} query -
   */


  function getTopParapharmacieLab(query) {
    query.date.from = moment().month(0).date(0);
    query.date.to = moment();
    query.must.category.selected.push({
      id: 1403
    });
    StatisticsQueryService.buildquery(query, query.date).then(analysisService.getTop5Laboratory).then(function (data) {
      $scope.topLaboPara = data.aggregations.salesLaboratory.buckets[0];
      query.must.category.selected = [];
      query.must.tva.selected.push({
        value: 8
      });
      return StatisticsQueryService.buildquery(query, query.date);
    }).then(analysisService.getTop5Laboratory).then(function (data) {
      $scope.topLaboOtc = data.aggregations.salesLaboratory.buckets[0];
      query.must.tva.selected = [];
      query.must.category.selected = [];
      $scope.loading = false;
    });
  }
}

angular.module("app.home").controller("dashboardPharmaciesTopLaboratoryController", DashboardPharmaciesTopLaboratoryController);