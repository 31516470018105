"use strict";
/**
 * Search bar events directive.
 *
 * @returns {object}
 */

SearchBarEventsController.$inject = ["$scope", "$rootScope", "autocompleteFiltersService", "searchBarService"];

function SearchBarEvents() {
  return {
    restrict: "E",
    scope: {
      config: "@config",
      select: "@select",
      lengthSelected: "@lengthSelected"
    },
    templateUrl: "app/main/directives/search-bar/events/searchBar.html",
    transclude: true,
    controller: SearchBarEventsController
  };
}
/**
 * Search bar events controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} autocompleteFiltersService - Autocomplete filters service.
 * @param {object} searchBarService - Search bar service.
 */


function SearchBarEventsController($scope, $rootScope, autocompleteFiltersService, searchBarService) {
  $scope.configSearchBar = JSON.parse($scope.config);
  $scope.selectedFilterResult = {};
  $scope.autocomplete = autocompleteFiltersService.autocomplete;
  $scope.definitionsDisplay = autocompleteFiltersService.definitionsDisplay;

  $scope.searchTextChange = function () {
    var params = {
      q: $scope.autocomplete.model.input.events
    };
    $scope.resultsFromAllStats = {};

    if ($scope.autocomplete.model.input.events.length > 3) {
      $scope.loading = true;
      searchBarService.getSearchEvents(params).then(function (datas) {
        $scope.resultsFromAllStats = formatResponse(datas.event);
        $scope.searchTypesCount = Object.keys($scope.resultsFromAllStats).length;
        $scope.loading = false;
      });
    }
  }; // Allow to set checkbox checked by default


  $scope.selectedDefault = function (name) {
    return $scope.configSearchBar.search.indexOf(name) !== -1;
  };

  $scope.splitResult = function (array) {
    if (array.length >= 10) {
      return 1;
    }

    return 0;
  };

  $scope.flexResult = function (array) {
    if (array.length >= 10) {
      return 50;
    }

    return 100;
  };
  /**
   * Format response.
   *
   * @param {Array} results -
   * @returns {object}
   */


  function formatResponse(results) {
    var resultsFormatted = {};
    results.forEach(function (result) {
      var key = sanitizeName(result.eventType.name);

      if (!resultsFormatted[key]) {
        resultsFormatted[key] = [];
      }

      resultsFormatted[key].push(result);
    });
    return resultsFormatted;
  }
  /**
   * Sanitize name.
   *
   * @param {string} name -
   * @returns {string}
   */


  function sanitizeName(name) {
    var slug = name.trim().toLowerCase();
    var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaaaeeeeiiiioooouuuunc------";

    for (var index = 0, length = from.length; index < length; index++) {
      slug = slug.replace(new RegExp(from.charAt(index), "g"), to.charAt(index));
    }

    return slug.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "") // collapse whitespaces and replace by dash
    .replace(/-+/g, "") // collapse dashes
    .replace(/^-+/, "") // trim dashes from start
    .replace(/-+$/, ""); // trim dashes from end
  } // Add or remove a filter element from a checkbox


  $scope.selectedCheckBoxToggle = function (res, nameType) {
    if (typeof $scope.selectedFilterResult[nameType] === "undefined") {
      $scope.selectedFilterResult[nameType] = [];
    }

    if ($scope.limitsByType && $scope.limitsByType[nameType] > 0) {
      $scope.selectedFilterResult[nameType] = [];
      $scope.selectedFilterResult[nameType][res.id] = res;
    } else if (typeof $scope.selectedFilterResult[nameType][res.id] === "undefined") {
      $scope.selectedFilterResult[nameType][res.id] = res;
    } else {
      delete $scope.selectedFilterResult[nameType][res.id];

      if (Object.keys($scope.selectedFilterResult[nameType]).length === 0) {
        delete $scope.selectedFilterResult[nameType];
      }
    }

    $scope.isPharmacyAlone = false;

    if ($scope.selectedFilterResult[nameType] && Object.keys($scope.selectedFilterResult[nameType]).length === 1) {
      $scope.isPharmacyAlone = true;
    }

    if ($scope.configSearchBar.autoLaunchEvent) {
      $scope.results = [];
      angular.forEach($scope.selectedFilterResult, function (dataType, nameType) {
        // add new filters
        angular.forEach(dataType, function (res) {
          if (typeof $scope.results[nameType] === "undefined") {
            $scope.results[nameType] = [];
          }

          $scope.results[nameType].push(res);
        });
      });
      $rootScope.$broadcast($scope.configSearchBar.event, $scope.results);
    }
  }; // Allow to enable or disable a checkbox by taking into account the selected elements


  $scope.selectedCheckBoxExists = function (res, nameType) {
    return !(typeof $scope.selectedFilterResult[nameType] === "undefined" || typeof $scope.selectedFilterResult[nameType][res.id] === "undefined");
  };

  $scope.selectedFilterResultCount = function () {
    return Object.keys($scope.selectedFilterResult).length;
  }; // Transfert filter data


  $scope.applyFilter = function () {
    $scope.resultsFromAllStats = [];
    angular.forEach($scope.selectedFilterResult, function (dataType, nameType) {
      // Add new filters
      angular.forEach(dataType, function (res) {
        if (typeof $scope.resultsFromAllStats[nameType] === "undefined") {
          $scope.resultsFromAllStats[nameType] = [];
        }

        $scope.resultsFromAllStats[nameType].push(res);
      });
    });
    $rootScope.$broadcast($scope.configSearchBar.event, $scope.resultsFromAllStats);
    $scope.selectedFilterResult = {};
    $scope.autocomplete.model.input[$scope.tag] = "";
  };
  /*
      ** Filters Labels Management
      */


  $scope.removeFilter = function (obj, nameType) {
    $scope.filtersArray.splice($scope.filtersArray.indexOf(obj), 1);
    var typeFilter = ["must"];
    angular.forEach(typeFilter, function (typeFilterName) {
      var indexUsrQuery = $scope.usrquery[typeFilterName][nameType].selected.indexOf(obj);

      if (indexUsrQuery !== -1) {
        $scope.usrquery[typeFilterName][nameType].selected.splice(indexUsrQuery, 1);
      }
    });

    if (typeof $scope.selectedFilterResult[nameType] !== "undefined" && typeof $scope.selectedFilterResult[nameType][obj.id] !== "undefined") {
      // Remove the object from the current research - searchbar
      $scope.selectedFilterResult[nameType].splice([obj.id], 1);
    }

    $rootScope.$broadcast($scope.config.event, $scope.usrquery);
  };

  $scope.removeAllFilter = function (what) {
    $scope.autocomplete.model.input[$scope.tag] = "";
    angular.forEach($scope.usrquery[what], function (item, index) {
      angular.forEach(item.selected, function (selected) {
        if (typeof $scope.selectedFilterResult[index] !== "undefined" && typeof $scope.selectedFilterResult[index][selected.id] !== "undefined") {
          // Remove the object from the current research - searchbar
          $scope.filtersArray.splice($scope.filtersArray.indexOf(selected), 1);
          delete $scope.selectedFilterResult[index][selected.id];
        }
      });

      if (item.selected) {
        item.selected = [];
      }
    });
    $rootScope.$broadcast($scope.config.event, $scope.usrquery);
  };

  $scope.sizeOf = function (obj) {
    return Object.keys(obj).length;
  };
}

angular.module("app.directives").directive("searchBarEvents", SearchBarEvents);