"use strict";
/**
 * Go back directive.
 *
 * @returns {object}
 */

goBackController.$inject = ["$scope", "$window"];

function GoBackDirective() {
  return {
    restrict: "E",
    templateUrl: "app/main/directives/goBack/goBack.tmpl.html",
    controller: goBackController
  };
}
/**
 * Go back controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $window - $window.
 */


function goBackController($scope, $window) {
  /**
   * Go back in history
   */
  $scope.goBack = function () {
    $window.history.back();
  };
}

angular.module("app.directives").directive("goBack", GoBackDirective);