"use strict";
/**
 * Controller for Laboratory Disruption.
 *
 * @param {object} $scope - The scope.
 * @param {object} $state - The state.
 * @param {object} $stateParams - The state.
 * @param {object} disruptionService - The disruption service.
 * @param {object} StatisticsQueryService - The statistic query service.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} environmentService - Environment service.
 */

LaboratoryDisruptionController.$inject = ["$scope", "$state", "$stateParams", "disruptionService", "StatisticsQueryService", "laboratoriesService", "environmentService"];

function LaboratoryDisruptionController($scope, $state, $stateParams, disruptionService, StatisticsQueryService, laboratoriesService, environmentService) {
  $scope.pagination = {
    limit: 25,
    page: 1,
    order: "-order.sum"
  };
  $scope.loaderDisruptionsOptions = {
    loading: true,
    loader: "Récupération des laboratoires en rupture...",
    empty: "Aucune donnée sur les laboratoires en rupture."
  };
  $scope.goDetail = goDetail;
  $scope.rangeSelected = rangeSelected;
  $scope.getLaboratoryDisruption = getLaboratoryDisruption;
  loader();
  /**
   * The loader
   */

  function loader() {
    StatisticsQueryService.queryRebase();
    $scope.usrQuery = StatisticsQueryService.query;
    $scope.usrQuery.date = {
      from: moment().subtract(1, "month"),
      to: moment()
    };
    var rangeDate = {
      startDate: moment().date(1).format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    };
    $scope.usrQuery.date.from = moment().date(1).format("YYYY-MM-DD");
    $scope.usrQuery.date.to = moment().format("YYYY-MM-DD");
    $scope.dateLabel = moment(rangeDate.startDate).format("DD-MMM-YYYY") + " Au " + moment(rangeDate.endDate).format("DD-MMM-YYYY");
    $scope.loaderDisruptionsOptions.loading = true;
    getLaboratoryDisruption();
    getEnvironment();
  }
  /**
   * Get environment.
   *
   * @returns {Promise} promise
   */


  function getEnvironment() {
    return environmentService.getEnvironment().then(function () {
      $scope.isGrouping = environmentService.isGrouping();
      return $scope.isGrouping ? getWholesalerLaboratories() : mapPartners();
    });
  }
  /**
   * Get laboratories.
   */


  function getWholesalerLaboratories() {
    var query = {
      wholesalerId: $stateParams.wholesalerId
    };
    laboratoriesService.getWholesalerLaboratories(query).then(function (wholesalerLaboratories) {
      $scope.wholesalerLaboratories = wholesalerLaboratories;
      $scope.loadingLaboratories.loading = false;
    });
  }
  /**
   * Date range selected.
   *
   * @param {object} date - The date range.
   */


  function rangeSelected(date) {
    $scope.loaderDisruptionsOptions.loading = true;
    $scope.laboratoryDisruptions = [];
    $scope.usrQuery.date.from = moment(date.startDate, "DD-MM-YYYY");
    $scope.usrQuery.date.to = moment(date.endDate, "DD-MM-YYYY");
    $scope.dateLabel = moment(date.startDate).format("DD-MMM-YYYY") + " Au " + moment(date.endDate).format("DD-MMM-YYYY");
    getLaboratoryDisruption();
  }
  /**
   * Get the laboratory disruption.
   */


  function getLaboratoryDisruption() {
    StatisticsQueryService.buildquery($scope.usrQuery, $scope.usrQuery.date).then(function (queryBuilt) {
      queryBuilt.sort = $scope.pagination;
      var params = {
        query: JSON.stringify(queryBuilt)
      };
      disruptionService.getDisruptionByLaboratory(params).then(function (response) {
        $scope.loaderDisruptionsOptions.loading = false;
        $scope.laboratoryDisruptions = response.aggregations.laboratory.buckets;
        $scope.count = response.aggregations.count_distinct ? response.aggregations.count_distinct.value : 0;
      });
    });
  }
  /**
   * Go to detail view.
   *
   * @param {object} laboratoryDisruption - The laboratory disruption.
   */


  function goDetail(laboratoryDisruption) {
    $state.go("app.disruption.laboratory.detail", {
      laboId: laboratoryDisruption.laboratoryId
    });
  }
  /**
   * Map partners.
   */


  function mapPartners() {
    $scope.partners = [];
    laboratoriesService.getDisruptionLaboratories().then(function (laboratories) {
      laboratories.data.forEach(function (_ref) {
        var _ref$morphable = _ref.morphable,
            laboratoryId = _ref$morphable.id,
            name = _ref$morphable.name;
        return setBranding({
          laboratoryId: laboratoryId,
          name: name
        });
      });
    });
  }
  /**
   * Set branding.
   *
   * @param {object} partner - instance of partner.
   */


  function setBranding(partner) {
    var query = {
      laboratoryId: partner.laboratoryId
    };
    laboratoriesService.readMinimalBranding(query).then(function (branding) {
      return $scope.partners.push(Object.assign(partner, branding));
    });
  }
}

angular.module("app.disruption").controller("laboratoryDisruptionController", LaboratoryDisruptionController);