"use strict";
/**
 * Wholesalers logos.
 *
 * @returns {Array}
 */

function WholeSalersLogos() {
  return [{
    id: 204,
    path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z"
  }];
}

angular.module("wholesalersLogos.factory", []).factory("wholesalersLogos", WholeSalersLogos);