"use strict";
/**
 * Admin Sante Secure controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $filter - $filter.
 * @param {object} santeService - Sante service.
 * @param {object} environmentService - Environment service.
 * @param {string} alliadisSanteSecure - Alliadis install command.
 * @param {string} alliadisUninstallSanteSecure -Alliadis uninstall command.
 */

AdminSanteSecureController.$inject = ["$scope", "$rootScope", "$filter", "santeService", "environmentService", "alliadisSanteSecure", "alliadisUninstallSanteSecure"];

function AdminSanteSecureController($scope, $rootScope, $filter, santeService, environmentService, alliadisSanteSecure, alliadisUninstallSanteSecure) {
  $scope.tabs = [{
    id: 1,
    name: "Téléchargement",
    method: "",
    slug: "download",
    data: "",
    active: true,
    isActive: true
  }, {
    /*
       id: 2,
       name: "Configuration",
       method: "",
       slug: "config",
       data: "",
       isActive: true
    },{ */
    id: 3,
    name: "Monitoring",
    method: "",
    slug: "monitoring",
    data: "",
    isActive: true
  }];
  $scope.serverInstallLgoId = [4, 16, 20];
  $rootScope.showLeftMenuToggle = false;
  $rootScope.showBricksMenu = false;
  $rootScope.showLeftMenu = true;
  $scope.alliadisSanteSecure = alliadisSanteSecure;
  $scope.alliadisUninstallSanteSecure = alliadisUninstallSanteSecure;
  $scope.copy = copy;
  $scope.date = {
    startDate: moment().format("DD-MM-YYYY"),
    endDate: moment().format("DD-MM-YYYY")
  };
  $scope.interval = [{
    value: "1m",
    name: "1 minute"
  }, {
    value: "5m",
    name: "5 minutes"
  }, {
    value: "10m",
    name: "10 minutes"
  }, {
    value: "30m",
    name: "30 minutes"
  }, {
    value: "1h",
    name: "1 heure"
  }, {
    value: "2h",
    name: "2 heures"
  }, {
    value: "1d",
    name: "1 jour"
  }, {
    value: "7d",
    name: "7 jours"
  }];
  $scope.selectedInterval = $scope.interval[3].value;
  $scope.getSanteSecureAdmin = getSanteSecureAdmin;
  $scope.postStateAdmin = postStateAdmin;
  $scope.postReindexAdmin = postReindexAdmin;
  $scope.rangeDateSelected = rangeDateSelected;
  $scope.openTab = openTab;
  $scope.copy = copy;
  loader();
  /**
   * Loader.
   */

  function loader() {
    /**
     * To display block the first tab
     */
    $scope.tabs.forEach(function () {
      angular.element(document).find("#download")[0].style.display = "block";
    });
    environmentService.getEnvironment().then(function (environment) {
      $scope.env = environment;

      if ($scope.env.entity.lgo === 2) {
        $scope.tabs.push({
          id: 4,
          name: "Désinstallation",
          method: "",
          slug: "uninstall",
          data: "",
          active: $scope.env.entity.lgo === 2,
          isActive: $scope.env.entity.lgo === 2
        });
      }

      $scope.mustBeOnServer = $scope.serverInstallLgoId.indexOf($scope.env.entity.lgo) > -1;
      rangeDateSelected($scope.date);
    });
  }
  /**
   * Get Sante Secure admin.
   *
   * @returns {Promise}
   */


  function getSanteSecureAdmin() {
    var request = {
      date: $scope.date,
      interval: $scope.selectedInterval
    };
    return santeService.get(request).then(function (response) {
      $scope.data = response;
      $scope.data.healthcenter.last_reindex_date = $filter("date")(new Date($scope.data.healthcenter.last_reindex_date), "dd/MM/yyyy HH:mm:ss");
      $scope.data.healthcenter.last_state_date = $filter("date")(new Date($scope.data.healthcenter.last_state_date), "dd/MM/yyyy HH:mm:ss");
      $scope.data.healthcenter.lastLgoImport = $filter("date")(new Date($scope.data.healthcenter.lastLgoImport), "dd/MM/yyyy HH:mm:ss");
      $scope.data.healthcenter.lastLgoPing = $filter("date")(new Date($scope.data.healthcenter.lastLgoPing), "dd/MM/yyyy HH:mm:ss");
      return response;
    });
  }
  /**
   * Post state admin.
   *
   * @returns {Promise}
   */


  function postStateAdmin() {
    var request = {
      state: $scope.data.healthcenter.state
    };
    return santeService.postState(request);
  }
  /**
   * Post reindex admin.
   *
   * @returns {Promise}
   */


  function postReindexAdmin() {
    var request = {
      reindex: $scope.data.healthcenter.reindex
    };
    return santeService.postReIndex(request);
  }
  /**
   * Range date selected.
   *
   * @param {object} dates - Dates.
   */


  function rangeDateSelected(dates) {
    $scope.date = {
      from: moment(dates.startDate, "DD-MM-YYYY"),
      fromFormat: moment(dates.startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      to: moment(dates.endDate, "DD-MM-YYYY"),
      toFormat: moment(dates.endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
    };
    $scope.dateLabel = $scope.date.from.format("DD-MMM-YYYY") + " Au " + $scope.date.to.format("DD-MMM-YYYY");
    getSanteSecureAdmin();
  }
  /**
   * Open tab.
   *
   * @param {object} currentTab - Current tab.
   */


  function openTab(currentTab) {
    var tabs = document.getElementsByClassName("tab");

    for (var index = 0; index < tabs.length; index++) {
      tabs[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (currentTab.id === tab.id) {
        currentTab.active = true;
        document.getElementById(currentTab.slug).style.display = "block";
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Copy to the clipboard
   *
   * @param {string} commandLine - Command line.
   */


  function copy(commandLine) {
    var body = document.getElementsByTagName("body")[0];
    var tempInput = document.createElement("INPUT");
    body.appendChild(tempInput);
    tempInput.setAttribute("value", commandLine);
    tempInput.select();
    document.execCommand("copy");
    body.removeChild(tempInput);
  }
}

angular.module("app.admin").controller("AdminSanteSecureController", AdminSanteSecureController);