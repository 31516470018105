"use strict";
/**
 * Laboratory branding service.
 *
 * @param {object} apiClient - API client.
 * @param {object} apodisThemingFactory - Apodis theming factory.
 * @param {object} $rootScope - $rootScope.
 *
 * @returns {object}
 */

LaboratoryBrandingService.$inject = ["apiClient", "apodisThemingFactory", "$rootScope"];

function LaboratoryBrandingService(apiClient, apodisThemingFactory, $rootScope) {
  return {
    /**
     * read
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/branding"), {
        includes: ["logo", "background", "backgroundMobile", "sliders.file", "parentRanges.branding.logo", "parentRanges.branding.background", "parentRanges.childrenRanges.branding.logo", "parentRanges.childrenRanges.branding.background"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.laboratoryBranding;
      });
    },

    /**
     * getAll
     *
     * @returns {Promise}
     */
    getAll: function getAll() {
      return apiClient.get("/laboratories/brandings", {
        includes: ["logo", "background", "backgroundMobile", "sliders.file", "parentRanges.branding.logo", "parentRanges.branding.background", "parentRanges.childrenRanges.branding.logo", "parentRanges.childrenRanges.branding.background"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * create
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    create: function create(params) {
      return apiClient.post("/laboratories/".concat(params.laboratoryId, "/branding"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.laboratoryBranding;
      });
    },

    /**
     * update
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/laboratories/".concat(params.laboratoryId, "/branding/").concat(params.brandingId), params, {
        includes: ["logo", "background", "backgroundMobile", "sliders.file", "parentRanges.branding.logo", "parentRanges.branding.background", "parentRanges.childrenRanges.branding.logo", "parentRanges.childrenRanges.branding.background"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      }).then(function (result) {
        $rootScope.environment.entity.branding = result.laboratoryBranding;
        apodisThemingFactory.generateDefault($rootScope.environment);
        return result.laboratoryBranding;
      });
    },

    /**
     * readSlider
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    readSlider: function readSlider(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/branding/").concat(params.brandingId, "/sliders/").concat(params.sliderId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.slider;
      });
    },

    /**
     * getSlider
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    getSlider: function getSlider(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/branding/").concat(params.brandingId, "/sliders"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.sliders;
      });
    },

    /**
     * createSlider
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    createSlider: function createSlider(params) {
      return apiClient.post("/laboratories/".concat(params.laboratoryId, "/branding/").concat(params.brandingId, "/sliders"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.slider;
      });
    },

    /**
     * updateSlider
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    updateSlider: function updateSlider(params) {
      return apiClient.put("/laboratories/".concat(params.laboratoryId, "/branding/").concat(params.brandingId, "/sliders/").concat(params.sliderId), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.slider;
      });
    },

    /**
     * deleteSlider
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    deleteSlider: function deleteSlider(params) {
      return apiClient["delete"]("/laboratories/".concat(params.laboratoryId, "/branding/").concat(params.brandingId, "/sliders/").concat(params.sliderId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.slider;
      });
    }
  };
}

angular.module("app.laboratory").service("laboratoryBrandingService", LaboratoryBrandingService);