"use strict";
/**
 * Patient prescriptions list controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $mdDialog - The $mdDialog object.
 * @param {object} customerService - The customer service.
 * @param {object} notifyUserService - The notify user service.
 */

PatientPrescriptionsListController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "customerService", "notifyUserService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function PatientPrescriptionsListController($scope, $state, $stateParams, $mdDialog, customerService, notifyUserService) {
  $scope.loading = true;
  $scope.prescriptions = null;
  $scope.query = {
    limit: 5,
    after_key: null,
    page: 1
  };
  $scope.handlePagination = handlePaginationChange;
  $scope.redirectToDosagePlan = redirectToDosagePlan;
  $scope.openRenewalSchedule = openRenewalSchedule;
  $scope.redirectToPrescriptionDetails = redirectToPrescriptionDetails;
  $scope.paginationLoading = false;
  $scope.prescriptionsTitle = {
    title: {
      text: "Liste des ordonnances de ",
      back: {
        state: null,
        tooltip: "Retour aux details du patient."
      }
    }
  };
  var current_page = 1;
  var after_keys = {
    1: null
  };
  loader();
  /**
   * Load the patient prescriptions.
   *
   * @returns {void}
   */

  function loader() {
    var params = {
      limit: $scope.query.limit
    };
    getPrescriptions(params).then(function () {
      if ($stateParams.beneficiaryId) {
        return customerService.readCustomerBeneficiary($stateParams.patientId, $stateParams.beneficiaryId);
      }

      return customerService.readCustomer($stateParams.patientId);
    }).then(function (customerInformation) {
      $scope.prescriptionsTitle.title.text += customerInformation.information.name;
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue, veuillez réessayer.");
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Fetch the prescriptions and store the after_key (elastic search pagination)
   *
   * @param {object} query - The query params.
   *
   * @returns {Promise}
   */


  function getPrescriptions(query) {
    var params = {
      limit: query.limit,
      after: query.after_key,
      beneficiary_id: $stateParams.beneficiaryId || 0
    };
    return customerService.getCustomerPrescriptions($stateParams.patientId, params).then(function (response) {
      $scope.prescriptions = response.data.map(function (prescription) {
        return _objectSpread(_objectSpread({}, prescription), {}, {
          created_at: new Date(prescription.created_at)
        });
      });
      $scope.query.after_key = response.meta.after_key;
      after_keys[$scope.query.page + 1] = response.meta.after_key;
    });
  }
  /**
   * On pagination change, fetch the prescriptions.
   *
   * @param {number} page - The current page number.
   *
   * @returns {Promise}
   */


  function handlePaginationChange(page) {
    $scope.paginationLoading = true;

    if (isPreviousPage(page)) {
      $scope.query.after_key = after_keys[page];
      $scope.query.page--;
    } else {
      $scope.query.page++;
    }

    current_page = page;
    return getPrescriptions($scope.query)["finally"](function () {
      $scope.paginationLoading = false;
    });
  }
  /**
   * Determine whether the new page is a previous page.
   *
   * @param {number} page - The new page.
   *
   * @returns {boolean}
   */


  function isPreviousPage(page) {
    return page < current_page;
  }
  /**
   * Redirect to the dosage plan.
   *
   * @param {object} $event - The $event object.
   * @param {object} prescription - The prescription.
   */


  function redirectToDosagePlan($event, prescription) {
    $event.stopPropagation();
    $state.go("app.patients.details.prescription.dosage", {
      prescriptionId: prescription.id,
      dosageId: prescription.dosage_plan.id
    });
  }
  /**
   * Open renewal schedule modal
   *
   * @param {object} $event - The $event object.
   * @param {object} prescription - The prescription.
   */


  function openRenewalSchedule($event, prescription) {
    $event.stopPropagation();
    $mdDialog.show({
      controller: "prescriptionScheduleController",
      locals: {
        prescription: prescription
      },
      templateUrl: "app/main/patients/components/prescription-schedule/prescription-schedule.html",
      targetEvent: event,
      clickOutsideToClose: true,
      multiple: true,
      skipHide: true
    });
  }
  /**
   * Redirect to the prescription details screen.
   *
   * @param {object} prescription - The prescription.
   */


  function redirectToPrescriptionDetails(prescription) {
    $state.go("app.patients.details.prescription", {
      prescriptionId: prescription.id
    });
  }
}

angular.module("app.patients").controller("patientPrescriptionsListController", PatientPrescriptionsListController);