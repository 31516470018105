"use strict";
/**
 * Dashboard helathcenter weak disponibility laboratories controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} wholesalersService - Wholesalers service.
 */

DashboardHealthcenterWeakDisponibilityLaboratoriesController.$inject = ["$scope", "wholesalersService"];

function DashboardHealthcenterWeakDisponibilityLaboratoriesController($scope, wholesalersService) {
  $scope.loaderWeakDisponiblityLaboratories = {
    loading: true,
    loader: "Récupération des laboratoires en rupture de stock",
    empty: "Aucun laboratoire en rupture de stock"
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    $scope.loading = true;
    getWeakDisponibilityLaboratory();
  }
  /**
   * Get wak disponibility laboratory.
   */


  function getWeakDisponibilityLaboratory() {
    wholesalersService.getWeakDisponibilityLaboratory().then(function (response) {
      $scope.weakDisponibilityLaboratories = response;
      $scope.loaderWeakDisponiblityLaboratories.loading = false;
    });
  }
}

angular.module("app.dashboard").controller("dashboardHealthcenterWeakDisponibilityLaboratoriesController", DashboardHealthcenterWeakDisponibilityLaboratoriesController);