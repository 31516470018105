"use strict";
/**
 * Events configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

EventsConfig.$inject = ["$stateProvider"];

function EventsConfig($stateProvider) {
  $stateProvider.state("app.calendar", {
    url: "/calendrier",
    views: {
      "content@app": {
        templateUrl: "app/main/events/views/home/home.html",
        controller: "eventsCalendarHomeController"
      }
    }
  }).state("app.calendar.timeline", {
    url: "/timeline",
    views: {
      "calendarContent@app.calendar": {
        templateUrl: "app/main/events/views/timeline/timeline.html",
        controller: "eventsTimelineController"
      }
    }
  }).state("app.calendar.detail", {
    url: "/detail/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/events/views/detail/detail.html",
        controller: "eventDetailController"
      }
    },
    params: {
      event: null
    }
  }).state("app.calendar.create", {
    url: "/nouveau",
    views: {
      "content@app": {
        templateUrl: "app/main/events/views/custom-event-create/customEventCreate.html",
        controller: "customEventCreate"
      }
    },
    params: {
      event: null
    }
  }).state("app.calendar.edit", {
    url: "/edition/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/events/views/custom-event-create/customEventCreate.html",
        controller: "customEventCreate"
      }
    },
    params: {
      event: null
    }
  });
}

angular.module("app.events", []).config(EventsConfig);