"use strict";
/**
 * Organization service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

OrganizationService.$inject = ["apiClient"];

function OrganizationService(apiClient) {
  return {
    /**
     * Get correspondences.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getCorrespondences: function getCorrespondences(params) {
      return apiClient.post("/organizations/getCorrespondences", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read an organization’s bank detail.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readBankDetail: function readBankDetail(params) {
      return apiClient.get("/organizations/".concat(params.organizationId, "/bank-detail"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update an organization’s bank detail.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    updateBankDetail: function updateBankDetail(params) {
      return apiClient.put("/organizations/".concat(params.organizationId, "/bank-detail"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create an organization’s address.
     *
     * @param {object} organizationAddress - The organization address
     * @returns {Promise}
     */
    createAddress: function createAddress(organizationAddress) {
      return apiClient.post("/organizations/addresses", organizationAddress);
    },

    /**
     * Read an organization’s address.
     *
     * @returns {Promise}
     */
    readAddress: function readAddress() {
      return apiClient.get("/organizations/addresses/first");
    },

    /**
     * Update an organization’s address.
     *
     * @param {object} organizationAddress - The organization address
     * @returns {Promise}
     */
    updateAddress: function updateAddress(organizationAddress) {
      return apiClient.put("/organizations/addresses/".concat(organizationAddress.id), organizationAddress);
    }
  };
}

angular.module("app.organization").service("organizationService", OrganizationService);