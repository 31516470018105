"use strict";
/**
 * Dashboard pharmacy substitution controller.
 *
 * @param {object} $state - The angular $state object.
 * @param {object} $scope - The angular $scope object.
 * @param {object} substitutionService - The substitution service.
 * @param {object} environmentService - Environment service.
 */

dashboardPharmaciesSubstitutionRateController.$inject = ["$state", "$scope", "substitutionService", "environmentService"];

function dashboardPharmaciesSubstitutionRateController($state, $scope, substitutionService, environmentService) {
  $scope.loading = true;
  $scope.detailRate = [];
  $scope.currentEnv = environmentService.getEnvironment();
  var PRINCEPS_ID = "1";
  var GENERIC_ID = "2";
  var PERIOD_NAME = ["Mois en cours", "Mois dernier", "Du " + moment().subtract(1, "year").format("DD/MM/YYYY") + " au " + moment().format("DD/MM/YYYY")]; // Methods

  $scope.goToState = function (state) {
    $state.go(state);
  };

  loader();
  /**
   * The loader function.
   */

  function loader() {
    processSubstitutionQuery();
  }
  /**
   * Parse the data.
   */


  function parseData() {
    var retPri = 0;
    var retGen = 0;
    var substitutionData = [];
    $scope.elasticdatasSubstitution.forEach(function (period, index) {
      substitutionData[index] = {
        date: PERIOD_NAME[index],
        generic: 0,
        princeps: 0,
        operators: {}
      };
      period.aggregations.groupName.buckets.forEach(function (group) {
        group.operator.buckets.forEach(function (operator) {
          if (!substitutionData[index].operators[operator.key.replace(" ", "")]) {
            substitutionData[index].operators[operator.key.replace(" ", "")] = {
              operator: operator.key,
              generic: 0,
              princeps: 0
            };
          }

          operator.prodType.buckets.forEach(function (productGenType) {
            if (productGenType.key === GENERIC_ID) {
              substitutionData[index].generic += productGenType.quantity.value;
              substitutionData[index].operators[operator.key.replace(" ", "")].generic += productGenType.quantity.value;
            } else if (productGenType.key === PRINCEPS_ID) {
              substitutionData[index].princeps += productGenType.quantity.value;
              substitutionData[index].operators[operator.key.replace(" ", "")].princeps += productGenType.quantity.value;
            }
          });
        });
      });
    });
    $scope.chartOptions.data = substitutionData;
    $scope.genRateGloabl = retGen;
    $scope.prinRateGloabl = retPri;
  }
  /**
   * The chart initialization.
   */


  function initChartSubstitution() {
    $scope.chartOptions = {
      type: "serial",
      data: [],
      startDuration: 0,
      marginTop: 40,
      marginRight: 60,
      legend: {
        enabled: true
      },
      valueAxes: [{
        id: "ca",
        axisAlpha: 0,
        stackType: "100%",
        position: "left",
        gridAlpha: 0.1,
        axisColor: "#9CCC66",
        lineColor: "#9CCC66",
        labelFunction: function labelFunction(value) {
          return d3.format(".2s")(value) + "%";
        }
      }],
      graphs: [{
        balloonFunction: function balloonFunction(graphDataItem) {
          var html = "<p style='background-color: #9CCC66'>" + graphDataItem.dataContext.generic + " U</p>" + "<table>" + "<thead>" + "<th>Opérateurs</th>" + "<th>Génériques</th>" + "<th>Princeps</th>" + "</thead><tbody>";

          _.keys(graphDataItem.dataContext.operators).forEach(function (key) {
            var operator = graphDataItem.dataContext.operators[key];
            html += "<tr><td>" + operator.operator + "</td><td>" + operator.generic + " U" + "</td></tr>";
          });

          html += "</tbody></table>";
          return html;
        },
        fillAlphas: 0.9,
        fontSize: 20,
        labelText: "[[percents]]%",
        lineAlpha: 0.5,
        title: "Génériques",
        type: "column",
        fillColors: "#9CCC66",
        lineColor: "#9CCC66",
        color: "white",
        valueField: "generic"
      }, {
        balloonFunction: function balloonFunction(graphDataItem) {
          var html = "<p style='background-color: #0B98A8'>" + graphDataItem.dataContext.princeps + " U</p>" + "<table>" + "<thead>" + "<th>Opérateurs</th>" + "<th>Princeps</th>" + "</thead><tbody>";

          _.keys(graphDataItem.dataContext.operators).forEach(function (key) {
            var operator = graphDataItem.dataContext.operators[key];
            html += "<tr><td>" + operator.operator + "</td><td>" + operator.princeps + " U" + "</td></tr>";
          });

          html += "</tbody></table>";
          return html;
        },
        fillAlphas: 0.9,
        fontSize: 20,
        labelText: "[[percents]]%",
        lineAlpha: 0.5,
        fillColors: "#0B98A8",
        lineColor: "#0B98A8",
        title: "Princeps",
        type: "column",
        valueField: "princeps"
      }],
      categoryField: "date",
      categoryAxis: {
        parseDates: false,
        categoryBalloonEnabled: false
      },
      "export": {
        enabled: true,
        menu: [{
          "class": "export-main",
          menu: [{
            format: "PNG",
            title: "Exporter au format PNG"
          }, {
            format: "JPG",
            title: "Exporter au format JPG"
          }]
        }]
      }
    };
    parseData();
  }
  /**
   * Process the query.
   */


  function processSubstitutionQuery() {
    substitutionService.getSubstitutionWithOperatorDetail().then(function (response) {
      $scope.elasticdatasSubstitution = response.data;
      initChartSubstitution();
      $scope.loading = false;
    });
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesSubstitutionRateController", dashboardPharmaciesSubstitutionRateController);