"use strict";
/**
 * Healthcenter members service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

HealthcenterMembersService.$inject = ["apiClient"];

function HealthcenterMembersService(apiClient) {
  return {
    /**
     * Get members.
     *
     * @returns {Promise}
     */
    getMembers: function getMembers() {
      return apiClient.get("/healthcenters/members", {
        includes: ["user"]
      });
    }
  };
}

angular.module("app.healthcenters").service("healthcenterMembersService", HealthcenterMembersService);