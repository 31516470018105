"use strict";
/**
 * The patient prescription services.
 *
 * @param {object} hdsApiClient - The HDS Api client service.
 *
 * @returns {object}
 */

PatientPrescriptionService.$inject = ["hdsApiClient"];

function PatientPrescriptionService(hdsApiClient) {
  return {
    /**
     * Get prescriptions for the current connected healthcenter.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    getPrescriptions: function getPrescriptions(params) {
      return hdsApiClient("GET", "/prescriptions", {
        params: params
      });
    }
  };
}

angular.module("app.patients.services").service("patientPrescriptionService", PatientPrescriptionService);