"use strict";
/**
 * Auth user service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

AuthUserService.$inject = ["apiClient"];

function AuthUserService(apiClient) {
  return {
    /**
     * Read by hash.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readByHash: function readByHash(params) {
      return apiClient.get("/users/read/".concat(params.base64Email), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Register.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    register: function register(params) {
      return apiClient.post("/users", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Register guest.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    registerGuest: function registerGuest(params) {
      return apiClient.post("/users/register", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.user;
      });
    }
  };
}

angular.module("app.auth").service("authUserService", AuthUserService);