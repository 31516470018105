"use strict";
/**
 * Dashboard grouping healthcenter members list controller.
 *
 * @param {object} $scope - Scope.
 * @param {object} groupingMembersGroupingService - Grouping members service.
 * @param {object} sellinChallengeService - Sellin challenge service.
 */

DashboardGroupHealthcenterMembersListController.$inject = ["$scope", "groupingMembersGroupingService", "sellinChallengeService"];

function DashboardGroupHealthcenterMembersListController($scope, groupingMembersGroupingService, sellinChallengeService) {
  $scope.loading = true;
  $scope.limitOptions = [10, 25, 50];
  $scope.pagination = {
    current_page: 1,
    per_page: 10,
    order: ""
  };
  $scope.members = [];
  $scope.membersDetails = {};
  $scope.loadingMembersDetails = [];
  $scope.showMembersDetails = [];
  $scope.getGroupingMembers = getGroupingMembers;
  $scope.toggleMemberDetails = toggleMemberDetails;
  $scope.showMembersDetails = [];
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.query = {
    order: "-name",
    limit: 15,
    page: 1
  };
  loader();
  /**
   * Loader
   */

  function loader() {
    getGroupingMembers();
  }
  /**
   * Get grouping members.
   */


  function getGroupingMembers() {
    var parameters = [{
      name: "page",
      value: $scope.pagination.current_page
    }, {
      name: "limit",
      value: $scope.pagination.per_page
    }, {
      name: "search",
      value: ""
    }];
    groupingMembersGroupingService.getMembers(parameters).then(function (response) {
      $scope.pagination = response.meta.pagination;
      $scope.members = response.data;
      $scope.loading = false;
    });
  }
  /**
   * Toggle member details.
   *
   * @param {object} member - Member.
   */


  function toggleMemberDetails(member) {
    if ($scope.showMembersDetails.indexOf(member.id) === -1) {
      showMemberDetails(member);
    } else {
      hideMemberDetails(member);
    }
  }
  /**
   * Show members details.
   *
   * @param {object} member - Member.
   */


  function showMemberDetails(member) {
    $scope.showMembersDetails.push(member.id);

    if (!$scope.membersDetails[member.id] && !$scope.loadingMembersDetails[member.id]) {
      loadMembersDetails(member);
    }
  }
  /**
   * Hide members details.
   *
   * @param {object} member - Member.
   */


  function hideMemberDetails(member) {
    var indexToRemove = $scope.showMembersDetails.indexOf(member.id);

    if (indexToRemove === -1) {
      return;
    }

    $scope.showMembersDetails.splice(indexToRemove, 1);
  }
  /**
   * Load member details.
   *
   * @param {object} member - Member.
   *
   * @returns {Promise}
   */


  function loadMembersDetails(member) {
    $scope.loadingMembersDetails[member.id] = true;
    $scope.membersDetails[member.id] = {};
    var topLaboratoriesLimit = 5;
    return Promise.all([groupingMembersGroupingService.getMemberWholesalerPurchases(member), groupingMembersGroupingService.getMemberTopLaboratoriesPurchases(member, topLaboratoriesLimit), sellinChallengeService.getHealthcenterCurrentSellins(member)]).then(function (data) {
      $scope.membersDetails[member.id].topLaboratories = data[1];
      var sellins = data[2].reduce(function (sellinsById, sellin) {
        sellinsById[sellin.id] = sellin;
        return sellinsById;
      }, {});
      $scope.membersDetails[member.id].sellins = sellins;
      var sellinsWithProgressIds = []; // Progress is needed only for recovery sellin

      for (var id in sellins) {
        if (Object.prototype.hasOwnProperty.call(sellins, id)) {
          var sellin = $scope.membersDetails[member.id].sellins[id];

          if (sellin.type.id === 1) {
            sellinsWithProgressIds.push(sellin.id);
          }
        }
      }

      return sellinChallengeService.getHealthcenterSellinsProgress(member, sellinsWithProgressIds);
    }).then(function (sellinsProgress) {
      for (var sellinId in sellinsProgress) {
        if (Object.prototype.hasOwnProperty.call(sellinsProgress, sellinId)) {
          $scope.membersDetails[member.id].sellins[sellinId].progress = sellinsProgress[sellinId];
        }
      }
    })["finally"](function () {
      $scope.$apply(function () {
        $scope.loadingMembersDetails[member.id] = false;
      });
    });
  }
}

angular.module("app.dashboard").controller("dashboardGroupHealthcenterMembersListController", DashboardGroupHealthcenterMembersListController);