"use strict";
/**
 * User sellout detail healthcenter controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $window - $window.
 * @param {string} apiurl - API URL.
 * @param {object} environmentService - Environment service.
 * @param {object} selloutFactory - Sellout factory.
 * @param {object} userSelloutService - User sellout service.
 * @param {object} notifyUserService - Notify user service.
 */

UserSelloutDetailHealthcenterController.$inject = ["$scope", "$stateParams", "$mdDialog", "$window", "apiurl", "environmentService", "selloutFactory", "userSelloutService", "notifyUserService"];

function UserSelloutDetailHealthcenterController($scope, $stateParams, $mdDialog, $window, apiurl, environmentService, selloutFactory, userSelloutService, notifyUserService) {
  RefuseSelloutController.$inject = ["$scope", "$mdDialog", "sellout"];
  AcceptSelloutController.$inject = ["$scope", "$mdDialog", "environmentService", "rewardsService", "sellout"];
  var selloutId = $stateParams.id;
  var imageFallback = "assets/images/sellouts/default-challenge-1080x600.jpg";
  $scope.loading = true;
  $scope.userSellout = {};
  $scope.scoreSellout = {};
  $scope.totalGainPoints = 0;
  $scope.gotoList = gotoList;
  $scope.acceptSellout = acceptSellout;
  $scope.refuseSellout = refuseSellout;
  loader();
  /**
   * Loader.
   */

  function loader() {
    getEnvironment().then(getSingleChallenge).then(getScoreChallenge);
  }
  /**
   * getEnvironment to get current environment
   *
   * @returns {Promise}
   */


  function getEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.access = response;
      $scope.isHolder = $scope.access.accessPointType === 2 && ($scope.access.rankId === 1 || $scope.access.rankId === 2 || $scope.access.isTechnicalReferent === 1);
      return response;
    });
  }
  /**
   * Get a single challenge.
   *
   * @returns {Promise}
   */


  function getSingleChallenge() {
    return userSelloutService.read({
      id: selloutId
    }).then(function (response) {
      $scope.userSellout = response;
      $scope.userSellout.sellout.image = challengeImage($scope.userSellout.sellout);
      $scope.now = new Date();
      $scope.start = new Date($scope.userSellout.sellout.startDate);
      $scope.end = new Date($scope.userSellout.sellout.endDate);
      $scope.loading = false;
      return response;
    });
  }
  /**
   * Get sellout score.
   *
   * @returns {Promise}
   */


  function getScoreChallenge() {
    return userSelloutService.readScore(selloutId).then(function (response) {
      $scope.scoreSellout = response.score;
      $scope.totalGainPoints = $scope.scoreSellout.gainHealthcenter || $scope.scoreSellout.gainOperator;
      $scope.loading = false;
      $scope.scoreSellout.unitRewards.map(function (unitReward) {
        unitReward.teaser = selloutFactory.generateUnitTeaser(unitReward.reward.gain, unitReward.reward.remuneration);
        return unitReward.teaser;
      });
      $scope.scoreSellout.objectiveRewards.map(function (objectiveReward) {
        objectiveReward.teaser = selloutFactory.generateObjectiveTeaser(objectiveReward.reward.gain, objectiveReward.reward.remuneration, objectiveReward.reward.objective.isPercent ? objectiveReward.quantityNeeded : objectiveReward.reward.goal, objectiveReward.reward.objective, objectiveReward.reward.startDate, objectiveReward.reward.endDate, objectiveReward.reward.objectiveMode);
        return objectiveReward.teaser;
      });
    });
  }
  /**
   * Build challenge image path.
   *
   * @param {object} sellout - The sellout object.
   *
   * @returns {string} The image path.
   */


  function challengeImage(sellout) {
    return sellout.imagePath ? "".concat(apiurl, "/images/").concat(sellout.imagePath) : imageFallback;
  }
  /**
   * Goto to challenges list.
   */


  function gotoList() {
    $window.history.back();
  }
  /**
   * acceptSellout to accept user sellout
   *
   * @param {object} event -
   * @param {object} sellout -
   */


  function acceptSellout(event, sellout) {
    $mdDialog.show({
      controller: AcceptSelloutController,
      templateUrl: "app/main/users-sellout/views/dialogs/accept.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        sellout: sellout
      },
      clickOutsideToClose: true
    }).then(loader);
  }
  /**
   * refuseSellout to refuse user sellout
   *
   * @param {object} event -
   * @param {object} sellout -
   */


  function refuseSellout(event, sellout) {
    $mdDialog.show({
      controller: RefuseSelloutController,
      templateUrl: "app/main/users-sellout/views/dialogs/refuse.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        sellout: sellout
      },
      clickOutsideToClose: true
    }).then(loader);
  }
  /**
   * Accept sellout controller.
   *
   * @param {object} $scope - $scope.
   * @param {object} $mdDialog - $mdDialog.
   * @param {object} environmentService - Environment service.
   * @param {object} rewardsService - Rewards service.
   * @param {object} sellout - Sellout.
   */


  function AcceptSelloutController($scope, $mdDialog, environmentService, rewardsService, sellout) {
    /**
     * Declaration of public methods
     */
    $scope.close = close;
    $scope.validate = validate;
    /**
     * Declaration of public objects
     */

    $scope.tile = sellout;
    $scope.loadingDistributions = {
      loading: true,
      loader: "Récupération des types de récompenses",
      empty: "Vous n'avez pas de type de récompenses."
    };
    loader();
    /**
     * Loader.
     */

    function loader() {
      getEnvironment().then(getRewardDistribution);
    }
    /**
     *
     * Get current environment
     *
     * @returns {Promise}
     */


    function getEnvironment() {
      return environmentService.getEnvironment().then(function (response) {
        $scope.environment = response;
        return response;
      });
    }
    /**
     * Get reward distribution.
     *
     * @returns {Promise}
     */


    function getRewardDistribution() {
      return rewardsService.getRewardDistributions().then(function (response) {
        $scope.loadingDistributions.loading = false;
        $scope.distributions = response;
        return response;
      });
    }
    /**
     * Validate.
     *
     * @returns {Promise}
     */


    function validate() {
      sellout.rewardDistributionId = $scope.tile.rewardDistributionId;
      return userSelloutService.accept(event, sellout).then(function (response) {
        notifyUserService.showSimpleToast("Le challenge « " + sellout.name + " » a été accepté", {
          color: "success",
          hideDelay: 2000
        });
        return response;
      }).then(function (response) {
        $mdDialog.hide(sellout);
        return response;
      });
    }
    /**
     * Close.
     */


    function close() {
      $mdDialog.cancel();
    }
  }
  /**
   * Refuse sellout controller.
   *
   * @param {object} $scope -
   * @param {object} $mdDialog -
   * @param {object} sellout - Sellout.
   */


  function RefuseSelloutController($scope, $mdDialog, sellout) {
    $scope.validate = validate;
    $scope.close = close;
    /**
     * hide
     */

    function validate() {
      sellout.comments = $scope.comments;
      userSelloutService.refuse(event, sellout).then(function (response) {
        notifyUserService.showSimpleToast("Le challenge « " + sellout.name + " » a été refusé", {
          color: "success",
          hideDelay: 2000
        });
        return response;
      }).then(function (response) {
        $mdDialog.hide(sellout);
        return response;
      });
    }
    /**
     * cancel
     */


    function close() {
      $mdDialog.cancel();
    }
  }
}

angular.module("app.users-sellout").controller("userSelloutDetailHealthcenterController", UserSelloutDetailHealthcenterController);