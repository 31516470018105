"use strict";
/**
 * Sales area profitability detail controller.
 *
 * @param {object} salesAreaManagementService - Sales area management service.
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 */

SalesAreaProfitabilityDetailController.$inject = ["salesAreaManagementService", "$scope", "$stateParams"];

function SalesAreaProfitabilityDetailController(salesAreaManagementService, $scope, $stateParams) {
  /**
   * Order && filter the profitabilities
   */
  $scope.selected = [];
  $scope.limitOptions = [5, 10, 15];
  $scope.query = {
    order: "-startDate",
    limit: 15,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.titleConfig = {
    title: {
      icon: "icon-cart",
      text: "Rentabilité",
      back: {
        state: "app.salesAreaManagement.list"
      }
    }
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    getProfitability($stateParams.id);
  }
  /**
   * Get profitability.
   *
   * @param {number} id - ID.
   */


  function getProfitability(id) {
    $scope.loading = true;
    salesAreaManagementService.getProfitability({
      id: id
    }).then(function (profitability) {
      angular.forEach(profitability[id], function (prof) {
        if (prof.start_date) {
          prof.start_date = new Date(prof.start_date);
          prof.end_date = new Date(prof.end_date);
        }
      });
      $scope.profitabilities = profitability[id];
      $scope.titleConfig.title.text = "Rentabilité " + $scope.profitabilities.type + " " + $scope.profitabilities.name;
      $scope.loading = false;
    });
  }
}

angular.module("app.salesAreaManagement").controller("salesAreaProfitabilityDetailController", SalesAreaProfitabilityDetailController);