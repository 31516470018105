"use strict";
/**
 * Statistics configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

StatisticsConfig.$inject = ["$stateProvider"];

function StatisticsConfig($stateProvider) {
  $stateProvider.state("app.statistics", {
    "abstract": true,
    url: "/statistiques",
    bodyClass: "statistiques",
    group: false
  });
}

angular.module("app.statistics", ["app.statistics.genericaudit", "app.statistics.products", "app.statistics.salesAnalysis", "app.statistics.operator-sales", "app.statistics.wholesalerAudit", "app.groupmentAudit", "app.statistics.averageShoppingCartAnalysis"]).config(StatisticsConfig);