"use strict";
/**
 * Home laboratory analysis service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

homeLaboratoryAnalysisService.$inject = ["apiClient"];

function homeLaboratoryAnalysisService(apiClient) {
  return {
    /**
     * Sales.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    sales: function sales(params) {
      return apiClient.post("/analysis/sales", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Top by geo.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    topByGeo: function topByGeo(params) {
      return apiClient.post("/analysis/getTopByGeo", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.home").service("homeLaboratoryAnalysisService", homeLaboratoryAnalysisService);