"use strict";
/**
 * The patient pathology service.
 *
 * @param {object} hdsApiClient - The HDS Api client service.
 * @param {object} apiClient - The api client.
 *
 * @returns {object}
 */

PatientPathologyService.$inject = ["hdsApiClient", "apiClient"];

function PatientPathologyService(hdsApiClient, apiClient) {
  return {
    /**
     * Index pathologies.
     *
     * @param {Array} parameters - The query params.
     *
     * @returns {Promise}
     */
    indexPathologies: function indexPathologies(parameters) {
      return apiClient.get("/pathologies", {
        wrappers: {
          response: null
        },
        parameters: parameters
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Attach a pathology to a customer.
     *
     * @param {number} customerId - The customer id.
     * @param {number} pathologyId - The pathology id.
     *
     * @returns {Promise}
     */
    attachCustomerPathology: function attachCustomerPathology(customerId, pathologyId) {
      return hdsApiClient("POST", "/customers/".concat(customerId, "/pathologies/").concat(pathologyId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Detach a pathology from a customer.
     *
     * @param {number} customerId - The customer id.
     * @param {number} pathologyId - The pathology id.
     *
     * @returns {Promise}
     */
    detachCustomerPathology: function detachCustomerPathology(customerId, pathologyId) {
      return hdsApiClient("DELETE", "/customers/".concat(customerId, "/pathologies/").concat(pathologyId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a pathology.
     *
     * @param {number} pathologyId - The pathology id.
     *
     * @returns {Promise}
     */
    readPathology: function readPathology(pathologyId) {
      return hdsApiClient("GET", "/pathologies/".concat(pathologyId, "/information")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a pathology entry.
     *
     * @param {number} pathologyId - The pathology id.
     * @param {number} entryId - The entry id.
     *
     * @returns {Promise}
     */
    readPathologyEntry: function readPathologyEntry(pathologyId, entryId) {
      return hdsApiClient("GET", "/pathologies/".concat(pathologyId, "/information/entries/").concat(entryId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a pathology advice.
     *
     * @param {number} pathologyId - The pathology id.
     * @param {string} advice - The advice.
     *
     * @returns {Promise}
     */
    createPathologyAdvice: function createPathologyAdvice(pathologyId, advice) {
      var data = {
        content: advice
      };
      return hdsApiClient("POST", "/healthcenter/pathologies/".concat(pathologyId, "/advice"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a pathology advice.
     *
     * @param {number} pathologyId - The pathology id.
     * @param {string} advice - The advice.
     *
     * @returns {Promise}
     */
    updatePathologyAdvice: function updatePathologyAdvice(pathologyId, advice) {
      var data = {
        content: advice
      };
      return hdsApiClient("PATCH", "/healthcenter/pathologies/".concat(pathologyId, "/advice"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a pathology advice.
     *
     * @param {number} pathologyId - The pathology id.
     *
     * @returns {Promise}
     */
    readPathologyAdvice: function readPathologyAdvice(pathologyId) {
      return hdsApiClient("GET", "/healthcenter/pathologies/".concat(pathologyId, "/advice")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Index healthcenter pathology products.
     *
     * @param {number} pathologyId - The pathology id.
     *
     * @returns {Promise}
     */
    indexHealthcenterPathologyProducts: function indexHealthcenterPathologyProducts(pathologyId) {
      return hdsApiClient("GET", "/healthcenter/pathologies/".concat(pathologyId, "/products")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Attach healthcenter products to a pathology.
     *
     * @param {number} pathologyId - The pathology id.
     * @param {Array} productIds - The product ids.
     *
     * @returns {Promise}
     */
    attachHealthcenterPathologyProducts: function attachHealthcenterPathologyProducts(pathologyId, productIds) {
      var data = {
        product_ids: productIds
      };
      return hdsApiClient("POST", "/healthcenter/pathologies/".concat(pathologyId, "/products"), {
        data: data
      });
    },

    /**
     * Get customers for pathology.
     *
     * @param {number} pathologyId - The pathology id.
     *
     * @returns {Promise}
     */
    getCustomersForPathology: function getCustomersForPathology(pathologyId) {
      return hdsApiClient("GET", "/healthcenter/pathologies/".concat(pathologyId, "/customers")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Sync customers for pathology.
     *
     * @param {number} pathologyId - The pathology id.
     * @param {Array} customerIds - The customer ids.
     *
     * @returns {Promise}
     */
    syncCustomersForPathology: function syncCustomersForPathology(pathologyId, customerIds) {
      var data = {
        customer_ids: customerIds
      };
      return hdsApiClient("POST", "/healthcenter/pathologies/".concat(pathologyId, "/customers"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.patients.services").service("patientPathologyService", PatientPathologyService);