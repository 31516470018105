"use strict";
/**
 * Current sellins controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} sellinChallengeService - Sellin challenge service.
 */

CurrentSellinChallengesController.$inject = ["$scope", "environmentService", "sellinChallengeService"];

function CurrentSellinChallengesController($scope, environmentService, sellinChallengeService) {
  $scope.loadTabContent = loadTabContent;
  var SELLIN_TYPES = {
    RECOVERY: 1,
    MARKET_OPENING: 2,
    MIXING: 3
  };
  loadCurrentEnvironment();
  /**
   * Load current environment.
   *
   * @returns {Promise}
   */

  function loadCurrentEnvironment() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.currentAccess = access;
    });
  }

  $scope.tabs = [{
    label: "Offre de rattrapage collective",
    sellins: [],
    loading: true,
    searchFilters: {
      isPermanent: false,
      isPerPharmacy: false,
      typeId: SELLIN_TYPES.RECOVERY
    }
  }, {
    label: "Offre de rattrapage individuelle",
    sellins: [],
    loading: true,
    searchFilters: {
      isPermanent: false,
      isPerPharmacy: true,
      typeId: SELLIN_TYPES.RECOVERY
    }
  }, {
    label: "Ouverture de marché",
    sellins: [],
    loading: true,
    searchFilters: {
      typeId: SELLIN_TYPES.MARKET_OPENING
    }
  }, {
    label: "Offre mensuelle",
    sellins: [],
    loading: true,
    searchFilters: {
      isPermanent: false,
      isPerPharmacy: false,
      typeId: SELLIN_TYPES.MIXING
    }
  }];
  /**
   * Load tab content.
   *
   * @param {object} tab - Tab to load.
   */

  function loadTabContent(tab) {
    if (!tab.loading) {
      return;
    }

    var currentMonth = new Date().getMonth() + 1;
    var searchFilters = {
      months: [currentMonth],
      type: tab.searchFilters
    };
    sellinChallengeService.search(searchFilters).promise.then(function (data) {
      tab.sellins = data.sellins;
      tab.loading = false;
    });
  }
}

angular.module("app.dashboard").controller("currentSellinChallengesController", CurrentSellinChallengesController);