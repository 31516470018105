"use strict";
/**
 * Search bar advanced directive.
 *
 * @returns {object}
 */

SearchBarAdvancedController.$inject = ["$scope", "$rootScope", "StatisticsQueryService", "autocompleteFiltersService", "environmentService", "$state"];

function SearchBarAdvanced() {
  return {
    restrict: "E",
    scope: {
      config: "@config",
      select: "@select"
    },
    templateUrl: "app/main/directives/search-bar/advanced/searchBar.html",
    transclude: true,
    controller: SearchBarAdvancedController,
    controllerAs: "vm"
  };
}
/**
 * Serach bar advanced controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} autocompleteFiltersService - Autocomplete filters service.
 * @param {object} environmentService - Environment service.
 * @param {object} $state - $state.
 */


function SearchBarAdvancedController($scope, $rootScope, StatisticsQueryService, autocompleteFiltersService, environmentService, $state) {
  var vm = this;
  vm.config = JSON.parse($scope.config);
  vm.select = JSON.parse($scope.select);
  vm.service = vm.config.service;
  vm.search = vm.config.search;
  vm.geoForce = vm.config.geoForce || false;
  vm.currentState = $state.current.name;
  vm.must = [];
  vm.should = [];
  vm.startDragFromMust = false;
  vm.startDragFromShould = false;

  if (vm.search) {
    vm.selectedFilter = vm.search;
  }

  vm.limitsByType = vm.config.limits;
  vm.definitionsDisplay = autocompleteFiltersService.definitionsDisplay;
  vm.resultsFromAllStats = [];
  vm.autocomplete = autocompleteFiltersService.autocomplete;
  vm.selectedObjChange = autocompleteFiltersService.selectedObjChange;
  vm.selectedFilterResult = {};
  vm.filtersToSend = [];

  if (vm.selectedFilter.length === 0) {
    vm.selectedFilter = {
      all: true
    };
  }

  vm.usrquery = StatisticsQueryService.query;

  if (typeof vm.config.tag !== "undefined") {
    vm.tag = vm.config.tag;
    vm.autocomplete.model.input[vm.tag] = "";
  } else {
    vm.tag = "tag";
  }

  vm.requestType = [];
  vm.filtersArray = []; // If no custom placeholder set, then set to default

  if (!vm.config.searchPlaceHolder) {
    vm.config.searchPlaceHolder = "Rechercher...";
  }

  if (typeof vm.config.search === "undefined" || vm.config.search.length === 0) {
    vm.config.search = [];
    vm.filters = [{
      all: true
    }];
  }

  var currentEnvironment = environmentService.getEnvironment();
  currentEnvironment.then(function (env) {
    vm.currentEnv = env;
    angular.forEach(vm.usrquery.should, function (sh) {
      if (sh.belongs === "geo" && sh.accessPointRight && sh.accessPointRight.indexOf(vm.currentEnv.accessPointType) > -1) {
        vm.hasGeoOption = true;
      }

      if (sh.belongs === "quali" && sh.accessPointRight && sh.accessPointRight.indexOf(vm.currentEnv.accessPointType) > -1) {
        vm.hasQualiOption = true;
      }
    });
  });

  vm.searchTextChange = function () {
    var requestType = generateRequestType();
    requestType.tag = vm.tag;

    if (vm.autocomplete.model.input[vm.tag].length > 2) {
      vm.loading = true;
      vm.resultsFromAllStats = {};

      if (vm.geoForce) {
        requestType.geoForce = vm.geoForce;
      }

      vm.searchCacheQuery = autocompleteFiltersService.getAll(requestType).then(function (datas) {
        vm.resultsFromAllStats = datas;
        vm.hasResults = _.size(datas);
        vm.loading = false;
      });
    } else {
      vm.resultsFromAllStats = {};
    }
  }; // Permet de cocher les checkbox par défault


  vm.selectedDefault = function (name) {
    return vm.config.search.indexOf(name) !== -1;
  };

  vm.splitResult = function (array) {
    if (array.length >= 10) {
      return 1;
    }

    return 0;
  };

  vm.flexResult = function (array) {
    if (array.length >= 10) {
      return 50;
    }

    return 100;
  };

  vm.getResultLength = function () {
    var index = 0;
    angular.forEach(vm.resultsFromAllStats, function (item) {
      if (item.length > 0) {
        index++;
      }
    });

    if (index < 3) {
      return 100;
    }

    return 32;
  };

  vm.findMore = function (nameType) {
    var nmeType = nameType;

    if (nameType === "pharmas") {
      nmeType = "idOrgas";
      vm.selectedFilter = [nmeType];
    } else if (nameType === "product") {
      nmeType = "idProducts";
      vm.selectedFilter = [nmeType];
    } else {
      vm.selectedFilter = [vm.usrquery.should[nmeType].slug];
    }

    vm.searchTextChange();
  }; // Retourne true si un élément dans le selected est selectionné


  vm.countFilterSelected = function () {
    var index = vm.selectedFilter.length;

    if (vm.selectedFilter.indexOf("all")) {
      index--;
    }

    return index > 0;
  };
  /**
   * Generate request type.
   *
   * @returns {object}
   */


  function generateRequestType() {
    // Si c"est le all de coché en dernier le initialiser avec uniquement all
    if (vm.selectedFilter.length - 1 === vm.selectedFilter.indexOf("all")) {
      vm.selectedFilter = ["all"];
    }

    if (vm.selectedFilter.length === 2 && vm.selectedFilter.indexOf("all") !== -1) {
      vm.selectedFilter.splice(vm.selectedFilter.indexOf("all"), 1);
    }

    return {
      requestType: vm.selectedFilter
    };
  } // Ajoute ou supprime un élément du filtre depuis une checkbox


  vm.selectedCheckBoxToggle = function (res, nameType) {
    if (typeof vm.selectedFilterResult[nameType] === "undefined") {
      vm.selectedFilterResult[nameType] = [];
    }

    if (vm.limitsByType && vm.limitsByType[nameType] > 0) {
      vm.selectedFilterResult[nameType] = [];
      vm.selectedFilterResult[nameType][res.id] = res;
    } else if (typeof vm.selectedFilterResult[nameType][res.id] === "undefined") {
      vm.selectedFilterResult[nameType][res.id] = res;
    } else {
      delete vm.selectedFilterResult[nameType][res.id];

      if (Object.keys(vm.selectedFilterResult[nameType]).length === 0) {
        delete vm.selectedFilterResult[nameType];
      }
    }
  }; // Permet d’activer ou non la checkbox suivant les éléments déjà selectionnés


  vm.selectedCheckBoxExists = function (res, nameType) {
    return !(typeof vm.selectedFilterResult[nameType] === "undefined" || typeof vm.selectedFilterResult[nameType][res.id] === "undefined");
  };

  vm.defined = undefined;

  vm.hasFilters = function () {
    if (typeof vm.defined === "undefined") {
      // eslint-disable-next-line consistent-return
      angular.forEach(vm.usrquery.should, function (should) {
        if (typeof should.selected !== "undefined" && should.selected.length > 0) {
          vm.defined = true;
          return true;
        } else if (typeof vm.defined === "undefined") {
          return false;
        }
      });
    }

    if (typeof vm.defined === "undefined") {
      // eslint-disable-next-line consistent-return
      angular.forEach(vm.usrquery.must, function (must) {
        if (typeof must.selected !== "undefined" && must.selected.length > 0) {
          vm.defined = true;
          return true;
        } else if (typeof vm.defined === "undefined") {
          return false;
        }
      });
    }
  }; // Calcul le nombre de filtre actif


  vm.selectedCount = function () {
    var count = 0;
    var typeFilter = ["must", "should"];
    angular.forEach(typeFilter, function (typeFilterName) {
      angular.forEach(vm.usrquery[typeFilterName], function (obj) {
        if (obj.selected && obj.selected.length) {
          count += obj.selected.length;
        }
      });
      angular.forEach(vm.usrquery[typeFilterName], function (obj) {
        if (typeof obj.selected !== "undefined" && typeof obj.selected.length !== "undefined") {
          count += obj.selected.length;
        }
      });
    });
    return count;
  };

  vm.selectedFilterResultCount = function () {
    return Object.keys(vm.selectedFilterResult).length;
  }; // Transfert les données du filtre


  vm.applyFilter = function (what) {
    vm.resultsFromAllStats = [];
    var other = "should";

    if (what === "should") {
      other = "must";
    }

    angular.forEach(vm.selectedFilterResult, function (dataType, nameType) {
      // Ajoute les nouveaux filtres
      if (vm.limitsByType && vm.limitsByType[nameType] === 1) {
        Object.keys(dataType).forEach(function (res) {
          if (vm.filtersArray.indexOf(res) === -1) {
            vm.filtersArray.push(res);
            vm.usrquery.must[nameType].selected = [];
            vm.usrquery.must[nameType].selected.push(dataType[res]);
          }
        });
      } else {
        Object.keys(dataType).forEach(function (res) {
          var indexUsrQuery = vm.usrquery[what][nameType].selected.indexOf(dataType[res]);

          if (vm.filtersArray.indexOf(dataType[res]) === -1 && vm.usrquery[other][nameType].selected.indexOf(dataType[res]) === -1 && indexUsrQuery === -1) {
            vm.filtersArray.push(dataType[res]);
            vm.usrquery[what][nameType].selected.push(dataType[res]);
          }
        });
      }
    });
    $rootScope.$broadcast(vm.config.event, vm.usrquery);
    vm.autocomplete.model.input[vm.tag] = "";
  };
  /*
       ** Filters Labels Management
       */


  vm.removeFilter = function (obj, nameType) {
    vm.filtersArray.splice(vm.filtersArray.indexOf(obj), 1);
    var typeFilter = ["must", "should"];
    angular.forEach(typeFilter, function (typeFilterName) {
      var indexUsrQuery = vm.usrquery[typeFilterName][nameType].selected.indexOf(obj);

      if (indexUsrQuery !== -1) {
        vm.usrquery[typeFilterName][nameType].selected.splice(indexUsrQuery, 1);
      }
    });

    if (typeof vm.selectedFilterResult[nameType] !== "undefined" && typeof vm.selectedFilterResult[nameType][obj.id] !== "undefined") {
      delete vm.selectedFilterResult[nameType][obj.id];

      if (Object.keys(vm.selectedFilterResult[nameType]).length === 0) {
        delete vm.selectedFilterResult[nameType];
      }
    }

    $rootScope.$broadcast(vm.config.event, vm.usrquery);
  };

  vm.removeAllFilter = function (what) {
    vm.autocomplete.model.input[vm.tag] = "";
    angular.forEach(vm.usrquery[what], function (item, index) {
      angular.forEach(item.selected, function (selected) {
        if (typeof vm.selectedFilterResult[index] !== "undefined" && typeof vm.selectedFilterResult[index][selected.id] !== "undefined") {
          vm.filtersArray.splice(vm.filtersArray.indexOf(selected), 1);
          delete vm.selectedFilterResult[index][selected.id];
        }
      });

      if (item.selected) {
        item.selected = [];
      }
    });
    $rootScope.$broadcast(vm.config.event, vm.usrquery);
  };

  vm.startShouldCallback = function (item) {
    var target = angular.element(item.target);
    initialMargins.top = target.css("margin-top");
    initialMargins.left = target.css("margin-left");
    vm.startDragFromMust = false;
    vm.startDragFromShould = true;
    $scope.$apply();
  };

  vm.startMustCallback = function (item) {
    var target = angular.element(item.target);
    initialMargins.top = target.css("margin-top");
    initialMargins.left = target.css("margin-left");
    vm.startDragFromMust = true;
    vm.startDragFromShould = false;
    $scope.$apply();
  };

  var initialMargins = {
    top: 0,
    left: 0
  };

  vm.stopCallback = function (event) {
    angular.element(event.target).css({
      position: "initial",
      "margin-top": initialMargins.top,
      "margin-left": initialMargins.left
    });
    vm.startDragFromMust = false;
    vm.startDragFromShould = false;
    $scope.$apply();
  };

  vm.dragCallback = function (event) {
    var target = angular.element(event.target);
    var parentPosition = target.closest(".search-bar-content").offset();
    target.css({
      position: "fixed",
      "margin-top": parentPosition.top,
      "margin-left": parentPosition.left
    });
  };

  vm.dropOnMustCallback = function (event, ui) {
    var obj = ui.draggable.scope().dndDragItem;

    if (obj.type.indexOf("category") > -1) {
      obj.type = "category";
    }

    if (obj.type.indexOf("categoriesLaboratoryProduct") > -1) {
      obj.type = "categoriesLaboratoryProduct";
    }

    if (obj.type.indexOf("categoriesLaboratoryHealthcenter") > -1) {
      obj.type = "categoriesLaboratoryHealthcenter";
    }

    if (vm.usrquery.must[obj.type].selected.indexOf(obj) === -1) {
      vm.usrquery.must[obj.type].selected.push(obj);

      if (vm.usrquery.should[obj.type].selected.indexOf(obj) > -1) {
        vm.usrquery.should[obj.type].selected.splice(vm.usrquery.should[obj.type].selected.indexOf(obj), 1);
      }
    }

    $rootScope.$broadcast(vm.config.event, vm.usrquery);
  };

  vm.dropOnShouldCallback = function (event, ui) {
    var obj = ui.draggable.scope().dndDragItem;

    if (obj.type.indexOf("category") > -1) {
      obj.type = "category";
    }

    if (obj.type.indexOf("categoriesLaboratoryProduct") > -1) {
      obj.type = "categoriesLaboratoryProduct";
    }

    if (obj.type.indexOf("categoriesLaboratoryHealthcenter") > -1) {
      obj.type = "categoriesLaboratoryHealthcenter";
    }

    if (vm.usrquery.should[obj.type].selected.indexOf(obj) === -1) {
      vm.usrquery.should[obj.type].selected.push(obj);

      if (vm.usrquery.must[obj.type].selected.indexOf(obj) > -1) {
        vm.usrquery.must[obj.type].selected.splice(vm.usrquery.must[obj.type].selected.indexOf(obj), 1);
      }
    }

    $rootScope.$broadcast(vm.config.event, vm.usrquery);
  };

  vm.sizeOf = function (obj) {
    return Object.keys(obj).length;
  };

  $scope.$on("autocompleteFiltersService:modelChange", function (event, data) {
    vm.autocomplete = data;
  });
}

angular.module("app.directives").directive("searchBar", SearchBarAdvanced);