"use strict";
/**
 * Member analysis controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} colorService - Color service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} salesAnalysisMemberService - Sales analysis member service.
 * @param {object} environmentService - Environment service.
 * @param {object} teamService - Team service.
 */

MemberAnalysisController.$inject = ["$scope", "$mdDialog", "colorService", "StatisticsQueryService", "salesAnalysisMemberService", "environmentService", "teamService"];

function MemberAnalysisController($scope, $mdDialog, colorService, StatisticsQueryService, salesAnalysisMemberService, environmentService, teamService) {
  var vm = this;
  vm.loading = true;
  vm.config = {
    event: "memberAnalysisFilterChange",
    search: []
  };
  vm.datasOk = false;
  vm.aggregateBy = null;
  vm.aggBySelect = StatisticsQueryService.aggBy;
  var currentEnvironment = environmentService.getEnvironment();
  currentEnvironment.then(function (env) {
    vm.currentEnv = env;
    vm.usrquery = StatisticsQueryService.query;
    vm.dateLabel = moment(vm.usrquery.date.from).format("DD-MMM-YYYY") + " Au " + moment(vm.usrquery.date.to).format("DD-MMM-YYYY");
  });
  /**
   * Select a new date range in the selector
   *
   * @param {string} date -
   */

  vm.rangeSelected = function (date) {
    vm.usrquery.date.from = moment(date.startDate, "DD-MM-YYYY");
    vm.usrquery.date.to = moment(date.endDate, "DD-MM-YYYY");
    vm.getStats(vm.healthcenterIds);
  };

  vm.rawValues = StatisticsQueryService.values;
  /**
   * Limits the display of the filters
   *
   * @param {Array} array -
   *
   * @returns {Array}
   */

  vm.getLimits = function (array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  };
  /**
   * Action called when a checkbox is checked or unchecked
   *
   * @param {object} item -
   * @param {Array} list -
   */


  vm.toggleCb = function (item, list) {
    var idx = vm.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    vm.getStats(vm.healthcenterIds);
  };
  /**
   * Reorders the filters
   *
   * @param {string} name -
   * @param {Array} list -
   *
   * @returns {number}
   */


  vm.indexByLabel = function (name, list) {
    if (!name || !list) {
      return -1;
    }

    for (var index = 0, length = list.length; index < length; index++) {
      if (list[index].label === name) {
        return index;
      }
    }

    return -1;
  };
  /**
   * Check if the filter exists
   *
   * @param {string} name -
   * @param {Array} list -
   *
   * @returns {boolean}
   */


  vm.existsFilter = function (name, list) {
    return vm.indexByLabel(name, list) > -1;
  };
  /**
   * Return the healthcenters of the groups
   */


  (vm.getGroupMembers = function () {
    vm.loading = true;
    teamService.getMyTeam().then(function (response) {
      if (response.success) {
        vm.team = response.team;
        vm.healthcenterIds = [];
        angular.forEach(vm.team, function (healthcenter) {
          vm.healthcenterIds.push(healthcenter.entity.id);
        });
        vm.getStats(vm.healthcenterIds);
      }
    });
  })();
  /**
   * Get stats of the array of ids
   *
   * @param {Array} ids -
   */

  vm.getStats = function (ids) {
    vm.loading = true;
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      vm.query = query;
      vm.query.healthcenterIds = ids;
      vm.query.aggBy = vm.aggregateBy;
      salesAnalysisMemberService.getStats(vm.query).then(function (stats) {
        vm.healthcentersStats = {};
        angular.forEach(stats, function (healthcenter) {
          vm.healthcentersStats[healthcenter.id] = {};
          vm.healthcentersStats[healthcenter.id].name = healthcenter.healthcenter.name;
          vm.healthcentersStats[healthcenter.id].datas = [];
          angular.forEach(healthcenter.aggBy, function (aggBy) {
            vm.healthcentersStats[healthcenter.id].datas.push({
              id: aggBy.name,
              sales: parseFloat(aggBy.sales),
              color: colorService.getColorFromString(aggBy.name)
            });
          });
          vm.datasOk = true;
        });
        vm.loading = false;
      });
    });
  };

  vm.aggBySelect = StatisticsQueryService.aggBy;
  vm.aggByName = vm.aggBySelect[0].title + " " + vm.aggBySelect[0].label;
  vm.aggBy = {};
  vm.aggBy.model = JSON.stringify(vm.aggBySelect[0]);

  vm.openDialogEclatement = function (event) {
    MemberAnalysisDialogEclatementController.$inject = ["$scope", "$mdDialog", "items", "aggBy"];
    var parentEl = angular.element(document.body);
    $mdDialog.show({
      parent: parentEl,
      targetEvent: event,
      templateUrl: "app/main/statistics/template/eclatement.tmpl.html",
      locals: {
        items: vm.aggBySelect,
        aggBy: vm.aggBy
      },
      controller: MemberAnalysisDialogEclatementController
    });
    /**
     * Dialog eclatement controller.
     *
     * @param {object} $scope - $scope.
     * @param {object} $mdDialog - $mdDialog.
     * @param {Array} items - Items.
     * @param {string} aggBy - Aggregate by.
     */

    function MemberAnalysisDialogEclatementController($scope, $mdDialog, items, aggBy) {
      /**
       * Chunk.
       *
       * @param {Array} arr -
       * @param {number} size -
       *
       * @returns {Array}
       */
      function chunk(arr, size) {
        var newArr = [];

        for (var index = 0; index < arr.length; index += size) {
          newArr.push(arr.slice(index, index + size));
        }

        return newArr;
      }

      $scope.items = chunk(items, 8);
      $scope.aggBy = aggBy; // eslint-disable-next-line consistent-return

      $scope.canEclate = function (item) {
        if (typeof vm.currentEnv === "undefined") {
          currentEnvironment.then(function (env) {
            vm.currentEnv = env;

            if (typeof item.rankNeeded !== "undefined") {
              return item.rights.indexOf(vm.currentEnv.accessPointType) > -1 && item.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
            }

            if (item.rights.indexOf("Administrator") > -1) {
              return vm.currentEnv.user.role === "Administrator";
            }

            return item.rights.indexOf(vm.currentEnv.accessPointType) > -1;
          });
        } else if (typeof item.rankNeeded !== "undefined") {
          return item.rights.indexOf(vm.currentEnv.accessPointType) > -1 && item.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
        } else {
          if (item.rights.indexOf("Administrator") > -1) {
            return vm.currentEnv.user.role === "Administrator";
          }

          return item.rights.indexOf(vm.currentEnv.accessPointType) > -1;
        }
      };

      $scope.flexResult = function (array) {
        if (array.length >= 10) {
          return 50;
        }

        return 100;
      };

      $scope.eclateBy = function (agg) {
        var parsedAgg = JSON.parse(agg);
        vm.aggregateBy = parsedAgg.val;
        vm.aggBy = {};
        vm.aggBy.model = agg;
        vm.aggByName = parsedAgg.title + (parsedAgg.label ? " " + parsedAgg.label : "");

        if (vm.aggregateBy === "none") {
          vm.display100Percent = 0;
        }

        vm.loading = true;
        StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (env) {
          vm.query = env;
          vm.getStats();
          $mdDialog.hide();
        });
      };
    }
  };
  /**
   * Event send by searchBar
   */


  $scope.$on("memberAnalysisFilterChange", function () {
    vm.loading = true;
    vm.getStats(vm.healthcenterIds);
  });
}

angular.module("app.statistics.salesAnalysis").controller("MemberAnalysisController", MemberAnalysisController);