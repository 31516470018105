"use strict";
/**
 * Controller to manage alert report.
 *
 * @param {object} $scope - The controller scope.
 * @param {object} notifyUserService - The notification service.
 * @param {object} environmentService - The environment service.
 * @param {object} alertReportService - The alert report service.
 * @param {object} productService - The product service.
 * @param {object} healthcenterService - The healthcenter service.
 * @param {object} $window - The window object.
 * @param {object} apiurl - The apiurl.
 * @param {object} wholesalerService - The wholesaler service.
 */

DisruptionAlertReportController.$inject = ["$scope", "notifyUserService", "environmentService", "alertReportService", "productService", "healthcenterService", "$window", "apiurl", "wholesalerService"];

function DisruptionAlertReportController($scope, notifyUserService, environmentService, alertReportService, productService, healthcenterService, $window, apiurl, wholesalerService) {
  $scope.tabs = [{
    id: 1,
    name: "Paramétrage",
    slug: "alert-report",
    active: true,
    isActive: true
  }];
  $scope.days = [{
    daySlug: "monday",
    dayId: 1,
    checked: false,
    frenchName: "Lundi"
  }, {
    daySlug: "tuesday",
    dayId: 2,
    checked: false,
    frenchName: "Mardi"
  }, {
    daySlug: "wednesday",
    dayId: 3,
    checked: false,
    frenchName: "Mercredi"
  }, {
    daySlug: "thursday",
    dayId: 4,
    checked: false,
    frenchName: "Jeudi"
  }, {
    daySlug: "friday",
    dayId: 5,
    checked: false,
    frenchName: "Vendredi"
  }, {
    daySlug: "saturday",
    dayId: 6,
    checked: false,
    frenchName: "Samedi"
  }];
  $scope.select = false;
  $scope.downloadLoader = false;
  $scope.productSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    event: "addProducts",
    autoLaunchEvent: true,
    tag: "configDisruptionAlertReportProduct",
    searchPlaceHolder: "Rechercher des produits"
  };
  $scope.warehousesSearchConfig = {
    search: ["warehouse.id"],
    event: "addWarehouse",
    autoLaunchEvent: true,
    tag: "configDisruptionAlertReportWarehouse",
    searchPlaceHolder: "Rechercher des agences"
  };
  $scope.geographicalZonesSearchConfig = {
    search: ["geo.departement.number", "geo.region.id", "geo.uga"],
    event: "addZones",
    autoLaunchEvent: true,
    tag: "configDisruptionAlertReportGeographicalZones",
    searchPlaceHolder: "Rechercher des régions, départements, uga"
  };
  $scope.productQuery = {
    order: "name",
    limit: 15,
    page: 1
  };
  $scope.geographicalZonesQuery = {
    order: "name",
    limit: 15,
    page: 1
  };
  $scope.warehouseQuery = {
    order: "name",
    limit: 15,
    page: 1
  };
  $scope.wholesalerQuery = {
    order: "name",
    limit: 15,
    page: 1
  };
  $scope.paginationLabel = {
    page: "Page :",
    rowsPerPage: "Lignes par page :",
    of: "sur"
  };
  $scope.alert = {};
  $scope.alert.days = [];
  $scope.alert.geographicalZones = [];
  $scope.readAlert = readAlert;
  $scope.saveAlert = saveAlert;
  $scope.sendReportNow = sendReportNow;
  $scope.downloadReport = downloadReport;
  $scope.removeProduct = removeProduct;
  $scope.removeGeographicalZones = removeGeographicalZones;
  $scope.removeWarehouse = removeWarehouse;
  $scope.removeWholesaler = removeWholesaler;
  $scope.addDaysToAlert = addDaysToAlert;
  $scope.handleWholesalerSelected = handleWholesalerSelected;
  loader();
  /** Loader */

  function loader() {
    loadAccess().then(function () {
      openTab($scope.tabs[0]);
      getWholesalers();
      readAlert();
    });
  }
  /**
   * loadAccess
   *
   * @returns {Promise}
   */


  function loadAccess() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.access = response;
      return response;
    });
  }
  /**
   * Get wholesalers.
   */


  function getWholesalers() {
    wholesalerService.getAll().then(function (wholesalers) {
      $scope.wholesalers = wholesalers;
    });
  }
  /**
   * Handle wholesaler select.
   *
   * @param {object} wholesaler - The wholesaler.
   */


  function handleWholesalerSelected(wholesaler) {
    $scope.alert.wholesalers = [wholesaler];
  }
  /**
   * Open the tab
   *
   * @param {object} myTab - The tab to open
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Remove a product.
   *
   * @param {number} index - The index of the element to remove.
   */


  function removeProduct(index) {
    $scope.alert.products.splice(index, 1);
  }
  /**
   * Remove a healthcenter.
   *
   * @param {number} index - The index of the element to remove.
   */


  function removeGeographicalZones(index) {
    $scope.alert.geographicalZones.splice(index, 1);
  }
  /**
   * Remove a wholesaler.
   *
   * @param {number} index - The index of the element to remove.
   */


  function removeWholesaler(index) {
    $scope.alert.wholesalers.splice(index, 1);
  }
  /**
   * Remove a warehouse.
   *
   * @param {number} index - The index of the element to remove.
   */


  function removeWarehouse(index) {
    $scope.alert.warehouses.splice(index, 1);
  }
  /** Read disruption alert. */


  function readAlert() {
    alertReportService.read($scope.access.entityId).then(function (alert) {
      $scope.alert = alert;
      alert.days.forEach(function (alertDay) {
        $scope.days.forEach(function (day) {
          if (alertDay.daySlug === day.daySlug) {
            day.checked = true;
          }
        });
      });
    });
  }
  /** Save disruption alert. */


  function saveAlert() {
    if ($scope.alert.isForAllProducts) {
      $scope.alert.products = [];
    }

    if ($scope.alert.isForAllHealthcenters) {
      $scope.alert.healthcenters = [];
    }

    if ($scope.alert.isForAllGeographicalZones) {
      $scope.alert.geographicalZones = [];
    }

    alertReportService.update($scope.access.entityId, $scope.alert).then(function () {
      notifyUserService.showSuccessToast("Les paramètres ont bien été enregistrés.");
    });
  }
  /** Send report now. */


  function sendReportNow() {
    notifyUserService.showSuccessToast("Le rapport est en cours de génération et sera envoyé d'ici quelques instants.");
    alertReportService.send($scope.access.entityId);
  }
  /** Download report. */


  function downloadReport() {
    $scope.downloadLoader = true;
    notifyUserService.showSuccessToast("Le rapport est en cours de génération et sera téléchargé d'ici quelques instants.");

    if ($scope.alert.isForAllProducts) {
      $scope.alert.products = [];
    }

    if ($scope.alert.isForAllHealthcenters) {
      $scope.alert.healthcenters = [];
    }

    if ($scope.alert.isForAllGeographicalZones) {
      $scope.alert.geographicalZones = [];
    }

    alertReportService.update($scope.access.entityId, $scope.alert).then(function () {
      alertReportService.download($scope.access.entityId).then(function (url) {
        $window.open(apiurl + "/files" + url);
        $scope.downloadLoader = false;
      });
    });
  }
  /**
   * Add days to the alert.
   *
   * @param {object} day - The day to add or delete.
   */


  function addDaysToAlert(day) {
    day.checked = !day.checked;

    if (day.checked) {
      $scope.alert.days.push(day);
    } else {
      $scope.alert.days.splice($scope.alert.days.indexOf(day), 1);
    }
  }

  $scope.$on("addProducts", function (event, data) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    }

    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.alert.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.alert.products.push(product);
        }
      });
    });
  });
  $scope.$on("addZones", function (event, data) {
    angular.forEach(Object.keys(data), function (key) {
      var type = data[key];
      type.forEach(function (datum) {
        $scope.alert.geographicalZones.push({
          typeSlug: key,
          geographicalZoneId: datum.id,
          geographicalZoneName: datum.name
        });
      });
    });
  });
  $scope.$on("addWarehouse", function (event, data) {
    data.warehouse.forEach(function (warehouse) {
      $scope.alert.warehouses.push(warehouse);
    });
  });
}

angular.module("app.disruption").controller("disruptionAlertReportController", DisruptionAlertReportController);