"use strict";
/**
 * User edit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 * @param {object} userAccessPointsAdminService - User access points admin service.
 * @param {object} usersAdminService - Users admin service.
 */

UserEditController.$inject = ["$scope", "$stateParams", "$state", "userAccessPointsAdminService", "usersAdminService"];

function UserEditController($scope, $stateParams, $state, userAccessPointsAdminService, usersAdminService) {
  $scope.accessPointTypeIds = {
    pharmas: 2,
    groups: 3,
    laboratory: 1
  };
  $scope.configAdminUser = {
    event: "addAccessUser",
    search: ["idOrgas", "group.id", "laboratory.id"],
    tag: "accesUser"
  };
  $scope.tabs = [{
    id: 1,
    name: "Gérer le compte",
    slug: "account",
    active: true,
    isActive: true
  }, {
    id: 2,
    name: "Gérer les accès",
    slug: "accesses",
    isActive: true
  }];
  $scope.send = send;
  $scope.sendAndBack = sendAndBack;
  $scope.deleteAccess = deleteAccess;
  $scope.updateAccess = updateAccess;
  $scope.goBack = goBack;
  $scope.openTab = openTab;
  loader();
  /**
   * getUser
   * init getUser and getRanks methods
   */

  function loader() {
    /**
     * To display block the first tab
     */
    $scope.tabs.forEach(function () {
      angular.element(document).find("#account")[0].style.display = "block";
    });

    if (!$stateParams.id) {
      $scope.title = "Créer un utilisateur";
      $scope.editMode = false;
    }

    getUser();
    getRanks();
  }
  /**
   * Get user.
   *
   * @returns {Promise}
   */


  function getUser() {
    return usersAdminService.getById($stateParams).then(function (response) {
      $scope.user = response;
      $scope.title = "Modification de l'utilisateur : " + $scope.user.familyBirth + " " + $scope.user.given;
      $scope.editMode = true;
      getUserRank(response);
      return response;
    });
  }
  /**
   * Get user rank.
   *
   * @param {object} user - User.
   *
   * @returns {Promise}
   */


  function getUserRank(user) {
    return userAccessPointsAdminService.get(user).then(function (response) {
      $scope.laboratories = [];
      $scope.pharmacies = [];
      $scope.groupings = [];
      response.forEach(function (access) {
        switch (access.accessPointType) {
          case 1:
            $scope.laboratories.push(access);
            break;

          case 2:
            $scope.pharmacies.push(access);
            break;

          case 3:
            $scope.groupings.push(access);
            break;
        }
      });
      $scope.user.accesses = response;
      return response;
    });
  }
  /**
   * Get ranks.
   *
   * @returns {Promise}
   */


  function getRanks() {
    return userAccessPointsAdminService.getRankList().then(function (response) {
      $scope.rankList = response;
      return response;
    });
  }
  /**
   * Get ranks.
   *
   * @returns {Promise}
   */


  function modifyUser() {
    $scope.user.role = "User";
    return usersAdminService.put($scope.user).then(function (response) {
      $scope.loading = false;
      return response;
    });
  }
  /**
   * Create user.
   *
   * @returns {Promise}
   */


  function createUser() {
    return usersAdminService.post($scope.user).then(function (response) {
      $scope.loading = false;
      return response;
    });
  }
  /**
   * send
   * to init modifyUser promise if user
   * else init createUser promise.
   *
   * @param {object} user - User.
   */


  function send(user) {
    if (user) {
      modifyUser(user).then(function (response) {
        $scope.user = response;
      });
    } else {
      createUser();
    }
  }
  /**
   * sendAndBack
   * to init modifyUser promise if user
   * else init createUser promise.
   *
   * @param {object} user - User.
   */


  function sendAndBack(user) {
    if (user) {
      modifyUser(user).then(function (response) {
        $scope.user = response;
        goBack();
      });
    } else {
      createUser().then(function () {
        goBack();
      });
    }
  }
  /**
   * Delete access.
   *
   * @param {object} access - Access.
   * @param {object} index - Index.
   *
   * @returns {Promise}
   */


  function deleteAccess(access, index) {
    $scope.user.accesses.splice(index, 1);

    if (access.userId) {
      return userAccessPointsAdminService["delete"](access).then(function (response) {
        return response;
      });
    }

    return Promise.resolve();
  }
  /**
   * Update access.
   *
   * @param {object} access - Access.
   */


  function updateAccess(access) {
    if ($scope.user.id && access.id) {
      userAccessPointsAdminService.update(access);
    } else if ($scope.user.id) {
      userAccessPointsAdminService.post(access).then(function (response) {
        // eslint-disable-next-line no-param-reassign
        access = response;
      });
    }
  }
  /**
   * goBack
   * to return to the users list
   */


  function goBack() {
    $state.go("app.users");
  }
  /**
   * openTab.
   *
   * @param {object} myTab - Current tab.
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";
      } else {
        tab.active = false;
      }
    });
  }

  $scope.$on("addAccessUser", function (event, query) {
    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(query, type)) {
        angular.forEach(query[type], function (obj) {
          obj.type = type;

          if ($scope.user.id) {
            obj.userId = $scope.user.id;
          }

          obj.entityId = obj.id;
          obj.id = null;
          obj.accessPointType = $scope.accessPointTypeIds[type];
          $scope.user.accesses.push(obj);
        });
      }
    };

    for (var type in query) {
      _loop(type);
    }
  });
}

angular.module("app.admin").controller("UserEditController", UserEditController);