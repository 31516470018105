"use strict";
/**
 * Sales graph directive.
 *
 * @returns {object}
 */

salesGraphController.$inject = ["$scope", "$q", "StatisticsQueryService", "environmentService", "analysisService"];

function SalesGraph() {
  return {
    restrict: "E",
    scope: {
      labId: "@",
      pharmaId: "@",
      priceOption: "@"
    },
    templateUrl: "app/main/directives/stats/salesGraph/salesGraphs.tmpl.html",
    controller: salesGraphController
  };
}
/**
 * Sales graphd controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $q - $q.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */


function salesGraphController($scope, $q, StatisticsQueryService, environmentService, analysisService) {
  $scope.deferredChart = $q.defer();
  $scope.salesGraphs = {
    type: "serial",
    data: $scope.deferredChart.promise,
    startDuration: 0,
    marginTop: 40,
    marginRight: 60,
    decimalSeparator: ",",
    thousandSeparator: " ",
    legend: {
      useGraphSettings: true,
      marginTop: 20,
      spacing: 500,
      labelWidth: 250,
      marginLeft: 50,
      marginRight: 50,
      markerLabelGap: 50,
      valueFunction: function valueFunction(graphDataItem) {
        if (!graphDataItem.values) {
          return "";
        }

        return d3.format(".2s")(graphDataItem.values.value.toFixed(0));
      }
    },
    valueAxes: [{
      id: "ca",
      axisAlpha: 1,
      position: "left",
      gridAlpha: 0.1,
      axisColor: "#FF6600",
      title: "CA HT",
      labelFunction: function labelFunction(value) {
        return d3.format(".2s")(value) + "€";
      }
    }, {
      id: "qty",
      axisAlpha: 1,
      position: "right",
      gridAlpha: 0,
      axisColor: "#673AB7",
      title: "Quantité",
      labelFunction: function labelFunction(value) {
        return d3.format(".2s")(value);
      }
    }],
    graphs: [{
      id: "g1",
      valueField: "sales",
      lineColor: "#FF6600",
      valueAxis: "ca",
      title: "Chiffre d’affaires HT",
      bullet: "round",
      bulletBorderColor: "#FFFFFF",
      bulletBorderAlpha: 1,
      bulletBorderThickness: 2,
      animationPlayed: false,
      type: "smoothedLine",
      dashLengthField: "dashLengthLine",
      balloon: {
        drop: false,
        adjustBorderColor: true,
        color: "#212121"
      },
      balloonFunction: function balloonFunction(graphDataItem) {
        return "CA HT : " + d3.format(".2s")(graphDataItem.values.value) + "€";
      }
    }, {
      id: "g2",
      valueField: "salesLastYear",
      valueAxis: "ca",
      title: "Chiffre d’affaires HT N-1",
      lineColor: "#FF6600",
      bullet: "round",
      bulletBorderColor: "#FFFFFF",
      bulletBorderAlpha: 1,
      bulletBorderThickness: 2,
      animationPlayed: false,
      type: "smoothedLine",
      dashLength: 10,
      balloon: {
        drop: false,
        adjustBorderColor: true,
        color: "#212121"
      },
      balloonFunction: function balloonFunction(graphDataItem) {
        return "CA HT n-1 : " + d3.format(".2s")(graphDataItem.values.value) + "€";
      }
    }, {
      id: "g3",
      valueField: "qty",
      valueAxis: "qty",
      title: "Quantité",
      bullet: "round",
      bulletBorderColor: "#FFFFFF",
      bulletBorderAlpha: 1,
      bulletBorderThickness: 2,
      animationPlayed: false,
      type: "smoothedLine",
      lineColor: "#673AB7",
      balloon: {
        drop: false,
        adjustBorderColor: true,
        color: "#212121"
      },
      balloonFunction: function balloonFunction(graphDataItem) {
        return "Quantité : " + d3.format(".2s")(graphDataItem.values.value) + " unités";
      }
    }, {
      id: "g4",
      valueField: "qtyLastYear",
      valueAxis: "qty",
      title: "Quantité N-1",
      bullet: "round",
      bulletBorderColor: "#FFFFFF",
      bulletBorderAlpha: 1,
      bulletBorderThickness: 2,
      animationPlayed: false,
      type: "smoothedLine",
      dashLength: 10,
      lineColor: "#673AB7",
      balloon: {
        drop: false,
        adjustBorderColor: true,
        color: "#212121"
      },
      balloonFunction: function balloonFunction(graphDataItem) {
        return "Quantité n-1 : " + d3.format(".2s")(graphDataItem.values.value) + " unités";
      }
    }],
    chartCursor: {
      cursorColor: "#888888",
      cursorAlpha: 0.5,
      categoryBalloonDateFormat: "MMM YYYY",
      fullWidth: false
    },
    categoryField: "date",
    categoryAxis: {
      gridColor: "#F2F2F2",
      gridAlpha: 0.3,
      dateFormats: [{
        period: "fff",
        format: "JJ:NN:SS"
      }, {
        period: "ss",
        format: "JJ:NN:SS"
      }, {
        period: "mm",
        format: "JJ:NN"
      }, {
        period: "hh",
        format: "JJ:NN"
      }, {
        period: "DD",
        format: "DD MMM"
      }, {
        period: "WW",
        format: "DD MMM"
      }, {
        period: "MM",
        format: "MMM YYYY"
      }, {
        period: "YYYY",
        format: "MMM YYYY"
      }],
      parseDates: false,
      minorGridAlpha: 0.1,
      minorGridEnabled: true,
      axisAlpha: 0,
      tickLength: 0
    },
    "export": {
      enabled: true
    }
  };
  $scope.usrquery = JSON.parse(JSON.stringify(StatisticsQueryService.query));
  $scope.usrquery.date = {
    from: moment().subtract(12, "months"),
    to: moment()
  };

  if (!$scope.priceOption) {
    $scope.priceOption = "amount_excl_tax";
  }

  environmentService.getEnvironment().then(function (env) {
    $scope.currentEnv = env;
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;

      if ($scope.currentEnv.accessPointType === 1) {
        $scope.query.must.laboratory = [$scope.labId];
      }

      if ($scope.pharmaId) {
        $scope.query.must.healthcenter = [$scope.pharmaId];
      }

      $scope.query.interval = "1M";
      analysisService.getSales($scope.query).then(function (response) {
        $scope.usrquery.dateLastYear = {
          from: moment().subtract(2, "years"),
          to: moment().subtract(1, "years")
        };
        StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.dateLastYear).then(function (query) {
          $scope.query = query;

          if ($scope.currentEnv.accessPointType === 1) {
            $scope.query.must.laboratory = [$scope.labId];
          }

          if ($scope.pharmaId) {
            $scope.query.must.healthcenter = [$scope.pharmaId];
          }

          $scope.query.interval = "1M";
          analysisService.getSales(query).then(function (responseLastYear) {
            var lines = [];
            angular.forEach(response.aggregations.sales.buckets, function (data, index) {
              var obj = {
                date: moment(data.key).format("MM/YYYY")
              };
              obj.sales = data[$scope.priceOption].value;
              obj.qty = data.quantity.value;
              obj.salesLastYear = responseLastYear.aggregations.sales.buckets[index].amount_excl_tax.value;
              obj.qtyLastYear = responseLastYear.aggregations.sales.buckets[index].quantity.value; // eslint-disable-next-line no-invalid-this

              this.push(obj);
            }, lines);
            $scope.deferredChart.resolve(lines);
          }, function (response) {
            $scope.deferredChart.reject(response);
          });
        });
      });
    });
  });
}

angular.module("app.directives").directive("salesGraph", SalesGraph);