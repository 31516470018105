"use strict";
/**
 * Laboratory branding main controller.
 *
 * @param {object} $state - $state.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} sessionStorageService - Session storage service.
 * @param {string} apiurl - API URL.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} environmentService - Environment service.
 * @param {object} laboratoryBrandingService - Laboratory branding service.
 * @param {object} laboratoryRangeService - Laboratory range service.
 */

AddUniversController.$inject = ["$scope", "$mdDialog", "currentAccess", "laboratoryRangeService"];
LaboratoryBrandingMainController.$inject = ["$state", "$rootScope", "$scope", "$mdDialog", "sessionStorageService", "apiurl", "notifyUserService", "environmentService", "laboratoryBrandingService", "laboratoryRangeService"];

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function LaboratoryBrandingMainController($state, $rootScope, $scope, $mdDialog, sessionStorageService, apiurl, notifyUserService, environmentService, laboratoryBrandingService, laboratoryRangeService) {
  /**
   * Declaration of public methods
   */
  $scope.createBranding = createBranding;
  $scope.addUnivers = addUnivers;
  $scope.deleteUnivers = deleteUnivers;
  $scope.goToUnivers = goToUnivers;
  $scope.openTab = openTab;
  $scope.addSlide = addSlide;
  $scope.updateSlide = updateSlide;
  $scope.addSlideImage = addSlideImage;
  $scope.addLogoImage = addLogoImage;
  $scope.addBackgroundImage = addBackgroundImage;
  $scope.addBackgroundMobileImage = addBackgroundMobileImage;
  $scope.deleteSlide = deleteSlide;
  $scope.save = save;
  $scope.isDefined = isDefined;
  $scope.deleteLogo = deleteLogo;
  $scope.deleteBackground = deleteBackground;
  $scope.deleteBackgroundMobile = deleteBackgroundMobile;
  /**
   * Declaration of public objects
   */

  $scope.brandingOptions = {
    loading: true,
    loader: "Récupération de votre vitrine."
  };
  $scope.brandingUniversesOptions = {
    loading: true,
    loader: "Récupération des univers de votre vitrine"
  };
  $scope.productsOptions = {
    loading: true,
    loader: "Récupération des produits"
  };
  $scope.tabs = [{
    id: 1,
    name: "Charte graphique",
    slug: "branded-space",
    active: true,
    isActive: true
  }, {
    id: 2,
    name: "Gestion du carrousel",
    slug: "slideshow",
    active: false,
    isActive: true
  }, {
    id: 3,
    name: "Gestion des conditions",
    slug: "conditions",
    active: false,
    isActive: true
  }, {
    id: 4,
    name: "Gestion des univers",
    slug: "universes",
    active: false,
    isActive: true
  }];
  $scope.optionsPickerBg = {
    type: 2,
    history: false,
    genericPalette: false,
    palette: false
  };
  $scope.token = sessionStorageService.getToken();
  $scope.apiurl = apiurl;
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * Load each method inside
   */

  function loader() {
    loadTabs();
    loaderEnvironment();
  }
  /**
   *
   * LoadTabs
   * Init first tab and hide others
   */


  function loadTabs() {
    var mainTab = angular.element(document).find("#branded-space")[0];
    /**
     * To display block the first tab
     */

    $scope.tabs.forEach(function (tab) {
      mainTab.style.display = "block";

      if (tab.slug === mainTab.id && tab.method) {
        tab.active = true;
        tab.method();
      }
    });
  }
  /**
   * Load current environment
   *
   * @returns {Promise}
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      loaderBranding();
      return response;
    });
  }
  /**
   * Load laboratory branding
   *
   * @returns {Promise}
   */


  function loaderBranding() {
    var query = {
      laboratoryId: $scope.currentAccess.entityId
    };
    return laboratoryBrandingService.read(query).then(function (response) {
      $scope.brandingOptions.loading = false;
      $scope.brandingUniversesOptions.loading = false;
      $scope.productsOptions.loading = false;

      if (!_.isUndefined(response)) {
        $scope.branding = response;
        $scope.universes = response.parentRanges;

        if ($scope.universes.length === 0) {
          $scope.brandingUniversesOptions = {
            empty: "Aucun univers"
          };
        }

        $rootScope.$broadcast("slideshow:refresh", response.sliders);
        return response;
      }

      createBranding();
      return response;
    });
  }
  /**
   * Create branding
   *
   * @returns {Promise}
   */


  function createBranding() {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      laboratoryBranding: {
        logoId: null,
        backgroundId: null,
        backgroundMobileId: null,
        commercialCondition: null,
        restockingCondition: null,
        primaryColor: null,
        secondaryColor: null
      }
    };
    return laboratoryBrandingService.create(query).then(function (response) {
      $scope.branding = response;
      notifyUserService.showSuccessToast("Vitrine créée.");
      return response;
    });
  }
  /**
   *
   * Save $scope.branding
   * Cast event $broadcast -> 'slideshow:refresh' with 'response.sliders'
   *
   * @returns {Promise}
   */


  function save() {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      brandingId: $scope.branding.id,
      branding: $scope.branding
    };
    return laboratoryBrandingService.update(query).then(function (response) {
      $scope.branding = response;
      notifyUserService.showSuccessToast("La charte graphique de la vitrine a été mise à jour.");
      $rootScope.$broadcast("slideshow:refresh", response.sliders);
      return response;
    });
  }
  /**
   * Delete target univers
   *
   * @param {object} univers -
   */


  function deleteUnivers(univers) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression définitive de l'univers « " + univers.name + " » ?").ariaLabel("Suppression de l'univers").ok("Oui").cancel("Non")).then(function () {
      var query = {
        laboratoryId: $scope.currentAccess.entityId,
        id: univers.id
      };
      $scope.universes.splice($scope.universes.findIndex(function (item) {
        return item.id === univers.id;
      }), 1);
      return laboratoryRangeService["delete"](query).then(function () {
        loaderBranding();
        notifyUserService.showSuccessToast("L'univers « " + univers.name + " » a été supprimé.");
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Open modal create univers
   */


  function addUnivers() {
    var prompt = $mdDialog.prompt({
      controller: "addUniversController",
      templateUrl: "app/main/laboratory/branding/views/includes/modals/addUnivers.html",
      parent: angular.element(document.body),
      locals: {
        currentAccess: $scope.currentAccess
      }
    });
    $mdDialog.show(prompt).then(function (response) {
      loaderBranding();

      if (response) {
        notifyUserService.showSuccessToast("L'univers « " + response.name + " » a été créé");
      }
    });
  }
  /**
   * Add logo image
   *
   * @param {object} file -
   */


  function addLogoImage(file) {
    if (!_.isUndefined(file) && !_.isNull(file)) {
      $scope.branding.logoId = file.id;
      save();
    }
  }
  /**
   * Add background image
   *
   * @param {object} file -
   */


  function addBackgroundImage(file) {
    if (!_.isUndefined(file) && !_.isNull(file)) {
      $scope.branding.backgroundId = file.id;
      save();
    }
  }
  /**
   * Add background mobile image
   *
   * @param {object} file -
   */


  function addBackgroundMobileImage(file) {
    if (!_.isUndefined(file) && !_.isNull(file)) {
      $scope.branding.backgroundMobileId = file.id;
      save();
    }
  }
  /**
   * Add slide
   *
   * @returns {Promise}
   */


  function addSlide() {
    var slide = {
      legend: "",
      position: $scope.branding.sliders ? $scope.branding.sliders.length + 1 : 0
    };
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      brandingId: $scope.branding.id,
      slide: slide
    };
    return laboratoryBrandingService.createSlider(query).then(function (response) {
      loaderBranding();
      notifyUserService.showSuccessToast("Slide ajouté");
      return response;
    });
  }
  /**
   * Add slide image
   *
   * @param {object} file -
   * @param {object} slide -
   */


  function addSlideImage(file, slide) {
    if (!_.isUndefined(file) && !_.isNull(file) && !_.isUndefined(slide) && !_.isNull(slide)) {
      slide.fileId = file.id;
      updateSlide(slide);
    }
  }
  /**
   * Update slide
   *
   * @param {object} slide -
   *
   * @returns {Promise}
   */


  function updateSlide(slide) {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      brandingId: $scope.branding.id,
      sliderId: slide.id,
      slide: slide
    };
    return laboratoryBrandingService.updateSlider(query).then(function (response) {
      loaderBranding();
      notifyUserService.showSuccessToast("Slide mis à jour");
      return response;
    });
  }
  /**
   * Delete target slide
   * Splice $scope.branding.sliders
   *
   * @param {object} slide -
   * @param {number} index -
   */


  function deleteSlide(slide, index) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression définitive de la page du carrousel ?").ariaLabel("Suppression de la page du carrousel").ok("Oui").cancel("Non")).then(function () {
      var query = {
        laboratoryId: $scope.currentAccess.entityId,
        brandingId: $scope.branding.id,
        sliderId: slide.id
      };
      $scope.branding.sliders.splice(index, 1);
      return laboratoryBrandingService.deleteSlider(query).then(function (response) {
        loaderBranding();
        notifyUserService.showSuccessToast("Slide supprimé");
        return response;
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Check if property is defined
   *
   * @param {object} brandingProperty -
   *
   * @returns {boolean}
   */


  function isDefined(brandingProperty) {
    return _typeof(brandingProperty) !== undefined;
  }
  /**
   * Open target tab
   *
   * @param {object} myTab -
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";

        if (tab.method) {
          tab.method();
        }
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Go to 'app.laboratory-branding.univers'
   *
   * @param {object} univers -
   */


  function goToUnivers(univers) {
    $state.go("app.laboratory-branding.univers", {
      universId: univers.id,
      univers: univers
    });
  }
  /**
   * Delete branding logo
   *
   * @returns {Promise}
   */


  function deleteLogo() {
    $scope.branding.logoId = null;
    notifyUserService.showSuccessToast("Le logo a bien été supprimé.");
    return save();
  }
  /**
   * Delete branding background
   *
   * @returns {Promise}
   */


  function deleteBackground() {
    $scope.branding.backgroundId = null;
    notifyUserService.showSuccessToast("Le fond de page a bien été supprimé.");
    return save();
  }
  /**
   * Delete branding background mobile
   *
   * @returns {Promise}
   */


  function deleteBackgroundMobile() {
    $scope.branding.backgroundMobileId = null;
    notifyUserService.showSuccessToast("Le fond de page a bien été supprimé.");
    return save();
  }
}
/**
 * Add univers controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} currentAccess - Current access.
 * @param {object} laboratoryRangeService - Laboratory range service.
 */


function AddUniversController($scope, $mdDialog, currentAccess, laboratoryRangeService) {
  $scope.close = close;
  $scope.save = save;
  $scope.laboratoryUnivers = {
    name: "",
    description: ""
  };
  /**
   * Valid the form.
   *
   * @returns {boolean} Valid or not
   */

  $scope.validForm = function () {
    return $scope.laboratoryUnivers.name.length;
  };
  /**
   * Close modal.
   */


  function close() {
    $mdDialog.hide();
  }
  /**
   * Save the universe.
   *
   * @returns {Promise} The promise.
   */


  function save() {
    $scope.laboratoryUnivers.laboratoryId = currentAccess.entityId;
    return laboratoryRangeService.create($scope.laboratoryUnivers).then(function () {
      $mdDialog.hide($scope.laboratoryUnivers);
    });
  }
}

angular.module("app.laboratory").controller("laboratoryBrandingMainController", LaboratoryBrandingMainController).controller("addUniversController", AddUniversController);