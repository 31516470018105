"use strict";
/**
 * Patients controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} searchPatientService - Search patient service.
 * @param {object} $state - $state.
 */

PatientsController.$inject = ["$scope", "searchPatientService", "$state"];

function PatientsController($scope, searchPatientService, $state) {
  $scope.searchPatient = searchPatient;
  $scope.selectPatient = selectPatient;
  $scope.searchText = {};
  $scope.title = {
    title: {
      icon: "icon-people",
      text: "Recherchez un patient pour accéder à son dossier"
    }
  };
  /**
   * Search a patient by name fisrtname No SS
   *
   * @returns {object}
   */

  function searchPatient() {
    var search = $scope.searchText.text;

    if (search) {
      return searchPatientService.getSearchPatients(search).then(function (patients) {
        $scope.patients = patients;
        return patients;
      });
    }

    return Promise.resolve();
  }
  /**
   * Click on a patient in search bar
   *
   * @param {object} patient - Patient.
   */


  function selectPatient(patient) {
    $scope.searchText.text = "";
    angular.element(".md-scroll-mask").remove();
    $state.go("app.patients.details", {
      patientId: patient.id
    });
  }
}

angular.module("app.patients").controller("patientsController", PatientsController);