"use strict";
/**
 * Deep linking service.
 *
 * @param {object} $state - The angular $state object.
 *
 * @returns {object}
 */

deepLinkService.$inject = ["$state"];

function deepLinkService($state) {
  /**
   * Redirect to the proper page.
   *
   * @param {object} deepLink - The deep link object base64 encoded.
   */
  function redirectToDeepLink(deepLink) {
    try {
      var jsonDeepLink = JSON.parse(atob(deepLink));
      $state.go(jsonDeepLink.state, jsonDeepLink.params);
    } catch (exception) {
      $state.go("app.home");
    }
  }
  /**
   * Check if a key is a deep link key.
   * A deep link key is JSON serializable containing 'key' and 'state' key
   *
   * @param {string} key - The key to test
   *
   * @returns {boolean}
   */


  function isDeepLinkingKey(key) {
    try {
      var keyObject = JSON.parse(atob(key));

      if (!keyObject.state) {
        return false;
      }
    } catch (exception) {
      return false;
    }

    return true;
  }

  return {
    redirectToDeepLink: redirectToDeepLink,
    isDeepLinkingKey: isDeepLinkingKey
  };
}

angular.module("app.services").factory("deepLinkService", deepLinkService);