"use strict";
/**
 * Grouping transmission service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

GroupingTransmissionService.$inject = ["apiClient"];

function GroupingTransmissionService(apiClient) {
  return {
    /**
     * Get last transmission.
     *
     * @returns {Promise}
     */
    getLastTransmission: function getLastTransmission() {
      return apiClient.get("/grouping/last-transmission", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.dashboard").service("groupingTransmissionService", GroupingTransmissionService);