"use strict";
/**
 * Sellin challenge controller.
 *
 * @param {object} $scope - Scope.
 * @param {object} $state - State.
 * @param {object} environmentService - Environment service.
 * @param {object} sellinChallengeService - Sellin challenge service.
 * @param {object} $location - Location service.
 */

SellinChallengeController.$inject = ["$scope", "$state", "environmentService", "sellinChallengeService", "$location"];

function SellinChallengeController($scope, $state, environmentService, sellinChallengeService, $location) {
  var ENTITY_TYPE = {
    LABORATORY: 1,
    HEALTHCENTER: 2,
    GROUPING: 3,
    WHOLESALER: 5
  };
  var SELLIN_TYPES = {
    RECOVERY: 1,
    MARKET_OPENING: 2,
    MIXIN: 3
  };
  $scope.sellins = [];
  $scope.filters = {};
  $scope.toggleOption = toggleOption;
  $scope.searchRequest = null;
  $scope.searchOptionsRequest = null;
  $scope.goToCreate = goToCreate;
  $scope.ENTITY_TYPE = ENTITY_TYPE;
  $scope.groupedFilters = {};
  $scope.page = 1;
  $scope.goToPage = goToPage;
  $scope.pagination = {};
  $scope.canCreateSellinChallenge = canCreateSellinChallenge;
  $scope.$on("duplicate", reloadContent);
  $scope.$on("accept", reloadContent);
  $scope.$on("refuse", reloadContent);
  loader();
  /**
   * Load page data.
   */

  function loader() {
    loadCurrentEnvironment().then(function () {
      buildFilters();
      updateCountAwaitingSellins();
      performSearch();
    });
  }
  /**
   * Reload page content.
   */


  function reloadContent() {
    performSearch();
    updateCountAwaitingSellins();
  }
  /**
   * Update count awaiting sellins for grouping.
   */


  function updateCountAwaitingSellins() {
    if ($scope.currentAccess.accessPointType !== ENTITY_TYPE.GROUPING) {
      return;
    }

    sellinChallengeService.countGroupingAwaitingSellins().then(function (count) {
      var validationWaitingOptionIndex = 4;

      if (!count) {
        $scope.filters.status.options[validationWaitingOptionIndex].badge = null;
      } else {
        $scope.filters.status.options[validationWaitingOptionIndex].badge = count;
      }
    });
  }
  /**
   * Load current environment.
   *
   * @returns {Promise}
   */


  function loadCurrentEnvironment() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.currentAccess = access;
    });
  }

  var monthsLabels = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  /**
   * Build search filters.
   */

  function buildFilters() {
    $scope.filters.status = {
      label: "Statut",
      multiple: false,
      required: true,
      options: [{
        label: "Tous",
        value: null,
        selected: !$location.search().awaiting
      }, {
        label: "Accepté",
        value: "accepted"
      }, {
        label: "Refusé",
        value: "refused"
      }, {
        label: "En attente de validation labo",
        value: "waiting-laboratory-validation"
      }, {
        label: "À valider",
        value: "waiting-target-validation",
        selected: $location.search().awaiting
      }]
    };

    if ($scope.currentAccess.accessPointType === ENTITY_TYPE.GROUPING || $scope.currentAccess.accessPointType === ENTITY_TYPE.WHOLESALER) {
      $scope.filters.laboratories = {
        label: "Laboratoires",
        multiple: true,
        limit: 12,
        options: []
      };
    }

    if ($scope.currentAccess.accessPointType === ENTITY_TYPE.LABORATORY || $scope.currentAccess.accessPointType === ENTITY_TYPE.WHOLESALER) {
      $scope.filters.groupings = {
        label: "Groupement",
        multiple: true,
        limit: 12,
        options: []
      };
    }

    $scope.filters.type = {
      label: "Type",
      multiple: false,
      required: true,
      options: [{
        label: "Tous",
        value: null,
        selected: true
      }, {
        label: "Offre de rattrapage collective",
        value: {
          isPermanent: false,
          isPerPharmacy: false,
          typeId: SELLIN_TYPES.RECOVERY
        }
      }, {
        label: "Offre de rattrapage individuelle",
        value: {
          isPermanent: false,
          isPerPharmacy: true,
          typeId: SELLIN_TYPES.RECOVERY
        }
      }, {
        label: "Ouverture de marché",
        value: {
          typeId: SELLIN_TYPES.MARKET_OPENING
        }
      }, {
        label: "Offre mensuelle",
        value: {
          isPermanent: false,
          isPerPharmacy: false,
          typeId: SELLIN_TYPES.MIXIN
        }
      }]
    };
    var currentMonth = new Date().getMonth();
    var currentYear = new Date().getFullYear();
    $scope.filters.year = {
      label: "Périodes",
      type: "select",
      options: [{
        value: currentYear,
        label: currentYear,
        selected: true
      }]
    };
    $scope.filters.year.selectedValue = $scope.filters.year.options[0];
    $scope.filters.months = {
      label: "Périodes",
      multiple: true,
      options: [{
        value: null,
        label: "Tous",
        selected: $scope.currentAccess.accessPointType === ENTITY_TYPE.WHOLESALER || $location.search().awaiting
      }, {
        selected: $scope.currentAccess.accessPointType !== ENTITY_TYPE.WHOLESALER && !$location.search().awaiting,
        value: currentMonth + 1,
        label: monthsLabels[currentMonth]
      }]
    };
    groupFiltersByLabel();
  }
  /**
   * Perform sellins search.
   */


  function performSearch() {
    if ($scope.searchRequest) {
      $scope.searchRequest.cancel();
    }

    if ($scope.searchOptionsRequest) {
      $scope.searchOptionsRequest.cancel();
    }

    var criteria = buildSearchCriteria();
    $scope.searchRequest = sellinChallengeService.search(criteria, $scope.page);
    $scope.searchRequest.promise.then(function (data) {
      var sellins = data.sellins;
      $scope.pagination = data.pagination;
      $scope.sellins = sellins.map(function (sellin) {
        sellin.awaitingValidation = false;

        if ($scope.currentAccess.accessPointType === ENTITY_TYPE.GROUPING && sellin.organization.id !== $scope.currentAccess.organizationId) {
          for (var index = 0; index < sellin.targets.length; index++) {
            var target = sellin.targets[index];

            if (target.organizationId === $scope.currentAccess.organizationId && !target.isAccepted && !target.isRefused) {
              sellin.awaitingValidation = true;
              break;
            }
          }
        } else if ($scope.currentAccess.accessPointType === ENTITY_TYPE.LABORATORY) {
          sellin.awaitingValidation = !sellin.isAccepted && !sellin.isRefused;
        }

        return sellin;
      });
      $scope.searchRequest = null;
    });
    $scope.searchOptionsRequest = sellinChallengeService.getSearchOptions(criteria);
    $scope.searchOptionsRequest.promise.then(function (searchOptions) {
      buildMonthsOptions(searchOptions.months, criteria.months);
      buildYearOptions(searchOptions.year, criteria.year);
      buildOptions(searchOptions.laboratories, criteria.laboratories, "laboratories");
      buildOptions(searchOptions.groupings, criteria.groupings, "groupings");
    });
  }
  /**
   * Build months options.
   *
   * @param {object} options - Options.
   * @param {object} selectedMonths - Selected months.
   */


  function buildMonthsOptions(options, selectedMonths) {
    $scope.filters.months.options = [{
      value: null,
      label: "Tous",
      selected: selectedMonths.length === 0 || selectedMonths[0] === null
    }];
    $scope.filters.months.options = $scope.filters.months.options.concat(options.map(function (month) {
      var option = {
        label: monthsLabels[month - 1],
        value: month
      };
      option.selected = selectedMonths.indexOf(month) !== -1;
      return option;
    }));
  }
  /**
   * Build year options.
   *
   * @param {Array} options - Options.
   * @param {number} selectedYear - Current selected year.
   */


  function buildYearOptions(options, selectedYear) {
    $scope.filters.year.options = options.map(function (year) {
      return {
        label: year,
        value: year,
        selected: parseInt(year) === parseInt(selectedYear)
      };
    });

    if (options.indexOf(selectedYear) === -1) {
      $scope.filters.year.options.push({
        label: selectedYear,
        value: selectedYear,
        selected: true
      });
    }

    var selectedOption = $scope.filters.year.options.filter(function (option) {
      return option.selected;
    })[0];
    $scope.filters.year.selectedValue = selectedOption;
  }
  /**
   * Build options.
   *
   * @param {Array} options - Options.
   * @param {Array} selectedOptions - Current selected months.
   * @param {string} filterName - Filter name.
   */


  function buildOptions(options, selectedOptions, filterName) {
    if (!$scope.filters[filterName]) {
      return;
    }

    $scope.filters[filterName].options = options;

    for (var index = 0; index < $scope.filters[filterName].options.length; index++) {
      var option = $scope.filters[filterName].options[index];
      option.selected = selectedOptions.indexOf(option.value) !== -1;
    }

    $scope.filters[filterName].options.unshift({
      selected: selectedOptions.length === 0 || selectedOptions[0] === null,
      value: null,
      label: "Tous"
    });
  }
  /**
   * Toggle option.
   *
   * @param {object} filter - Filter.
   * @param {object} option - Option to toggle.
   */


  function toggleOption(filter, option) {
    $scope.page = 1;
    var currentSelectedOptions = filter.options.filter(function (option) {
      return option.selected;
    });
    var selectedValue = option;

    if (!selectedValue) {
      selectedValue = filter.selectedValue;
    }

    if (!selectedValue) {
      return;
    }

    var selected = !selectedValue.selected;

    if (selected && !filter.multiple) {
      filter.options.forEach(function (option) {
        option.selected = false;
      });
    }

    if (filter.required && !selected && currentSelectedOptions.length === 1) {
      return;
    }

    var emptyOptions = filter.options.filter(function (option) {
      return option.value === null;
    });
    var emptyOption = undefined;

    if (emptyOptions.length) {
      emptyOption = emptyOptions[0];
    }

    selectedValue.selected = selected;

    if (!emptyOption) {
      performSearch();
      return;
    }

    if (!selected && currentSelectedOptions.length === 1) {
      emptyOption.selected = true;
      filter.selectedValue = emptyOption;
    } else if (selectedValue !== emptyOption) {
      emptyOption.selected = false;
    }

    if (emptyOption.selected) {
      var otherOptions = filter.options.filter(function (option) {
        return option.value !== null;
      });

      for (var index = 0; index < otherOptions.length; index++) {
        otherOptions[index].selected = false;
      }
    }

    performSearch();
  }
  /**
   * Build search filters criteria from user inputs.
   *
   * @returns {object}
   */


  function buildSearchCriteria() {
    var searchCriteria = {};

    for (var filterName in $scope.filters) {
      if (Object.prototype.hasOwnProperty.call($scope.filters, filterName)) {
        var filter = $scope.filters[filterName];
        searchCriteria[filterName] = filter.multiple ? [] : null;

        for (var index = 0; index < filter.options.length; index++) {
          var option = filter.options[index];

          if (!option.selected) {
            continue;
          }

          if (!filter.multiple) {
            searchCriteria[filterName] = option.value;
            break;
          } else if (option.value !== null) {
            searchCriteria[filterName].push(option.value);
          }
        }
      }
    }

    return searchCriteria;
  }
  /**
   * Group filters by label.
   */


  function groupFiltersByLabel() {
    $scope.groupedFilters = {};

    for (var filterName in $scope.filters) {
      if (Object.prototype.hasOwnProperty.call($scope.filters, filterName)) {
        var filter = $scope.filters[filterName];

        if (!$scope.groupedFilters[filter.label]) {
          $scope.groupedFilters[filter.label] = [];
        }

        $scope.groupedFilters[filter.label].push(filter);
      }
    }
  }
  /**
   * Test if current user can create a challenge sellin.
   *
   * @returns {boolean}
   */


  function canCreateSellinChallenge() {
    return $scope.currentAccess && [ENTITY_TYPE.GROUPING, ENTITY_TYPE.LABORATORY, ENTITY_TYPE.WHOLESALER].indexOf($scope.currentAccess.accessPointType) !== -1;
  }
  /**
   * Go to search page.
   *
   * @param {number} page - Page to go.
   */


  function goToPage(page) {
    if ($scope.page !== page) {
      $scope.page = page;
      performSearch();
    }
  }
  /**
   * Go to create sellin.
   */


  function goToCreate() {
    $state.go("app.sellinChallenge.new");
  }
}

angular.module("app.sellinChallenge").controller("sellinChallengeController", SellinChallengeController);