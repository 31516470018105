"use strict";
/**
 * Segment service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

var _excluded = ["includes", "id"],
    _excluded2 = ["id"];
SegmentService.$inject = ["apiClient"];

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function SegmentService(apiClient) {
  return {
    /**
     * Get.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/segments", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.segments;
      });
    },

    /**
     * Read.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/segments/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.segment;
      });
    },

    /**
     * Read by sellout.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readBySellout: function readBySellout(params) {
      return apiClient.get("/segments/sellout/".concat(params.selloutId, "/").concat(params.statusId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    create: function create(params) {
      return apiClient.post("/segments", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/segments/".concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.segment;
      });
    },

    /**
     * Delete.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/segments/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.segment;
      });
    },

    /**
     * Healthcenter delete.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    healthcenterDelete: function healthcenterDelete(params) {
      return apiClient["delete"]("/segments/".concat(params.segmentId, "/healthcenters/").concat(params.healthcenterId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.segment;
      });
    },

    /**
     * Product delete.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    productDelete: function productDelete(params) {
      return apiClient["delete"]("/segments/".concat(params.segmentId, "/products/").concat(params.productId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get product by codes.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getProductByCodes: function getProductByCodes(params) {
      return apiClient.post("/product/specialitypresentation/presentationByCodes", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get healthcenters from the segment.
     *
     * @param {object} data - Data.
     * @param {object} data.includes - Includes.
     * @param {number} data.id - ID.
     * @param {object} data.params - Params.
     *
     * @returns {Promise}
     */
    getHealthcenters: function getHealthcenters(_ref) {
      var includes = _ref.includes,
          id = _ref.id,
          params = _objectWithoutProperties(_ref, _excluded);

      var parameters = Object.keys(params).filter(function (param) {
        return params[param];
      }).map(function (param) {
        return {
          name: param,
          value: params[param]
        };
      });
      return apiClient.get("/segments/".concat(id, "/healthcenters"), {
        includes: includes,
        parameters: parameters,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get products from the segment.
     *
     * @param {object} data - Data.
     * @param {number} data.id - ID.
     * @param {object} data.params - Params.
     *
     * @returns {Promise}
     */
    getProducts: function getProducts(_ref2) {
      var id = _ref2.id,
          params = _objectWithoutProperties(_ref2, _excluded2);

      var parameters = Object.keys(params).filter(function (param) {
        return params[param];
      }).map(function (param) {
        return {
          name: param,
          value: params[param]
        };
      });
      return apiClient.get("/segments/".concat(id, "/products"), {
        parameters: parameters,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Add healthcenters to segment.
     *
     * @param {object} data - Data.
     * @param {number} data.id - ID.
     * @param {Array} data.healthcenterIds - Healthcenters ID.
     *
     * @returns {Promise}
     */
    addHealthcentersToSegment: function addHealthcentersToSegment(_ref3) {
      var id = _ref3.id,
          healthcenterIds = _ref3.healthcenterIds;
      return apiClient.post("/segments/".concat(id, "/healthcenters"), {
        healthcenterIds: healthcenterIds
      });
    },

    /**
     * Add products to segment.
     *
     * @param {object} data - Data.
     * @param {number} data.id - ID.
     * @param {Array} data.productIds - Products ID.
     *
     * @returns {Promise}
     */
    addProductsToSegment: function addProductsToSegment(_ref4) {
      var id = _ref4.id,
          productIds = _ref4.productIds;
      return apiClient.post("/segments/".concat(id, "/products"), {
        productIds: productIds
      });
    },

    /**
     * Get segments by healthcenter.
     *
     * @param {number} healthcenterId - healthcenter ID.
     *
     * @returns {Promise}
     */
    getSegmentsByHealthcenter: function getSegmentsByHealthcenter(healthcenterId) {
      return apiClient.get("/segments/healthcenter/".concat(healthcenterId));
    },

    /**
     * Get segment tags.
     *
     * @returns {Promise}
     */
    getSegmentTags: function getSegmentTags() {
      return apiClient.get("/segments/tags").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get segment qualification.
     *
     * @param {number} healthcenterId - healthcenter ID.
     *
     * @returns {Promise}
     */
    getHealthcenterQualification: function getHealthcenterQualification(healthcenterId) {
      return apiClient.get("/segments/tag/qualification/".concat(healthcenterId)).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.segment").service("segmentService", SegmentService);