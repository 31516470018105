"use strict";
/**
 * The app-list directive.
 *
 * @returns {object}
 */

function AppList() {
  return {
    restrict: "EA",
    template: "<div class='app-list md-white-bg' ng-transclude></div>",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appList", AppList);