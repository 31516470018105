"use strict";
/**
 * Controller for loyalty card edit.
 *
 * @param {object} $scope - Scope
 * @param {object} $rootScope - Root scope
 * @param {object} $state - State
 * @param {object} $stateParams - State params
 * @param {object} $mdDialog - MD dialog
 * @param {object} notifyUserService - Notify user service
 * @param {object} environmentService - Environment service
 * @param {object} healthcenterService - Healthcenter service
 * @param {object} loyaltyCardService - Loyalty card service
 * @param {object} productService - Product service.
 */

LoyaltyCardEditController.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "$mdDialog", "notifyUserService", "environmentService", "healthcenterService", "loyaltyCardService", "productService"];

function LoyaltyCardEditController($scope, $rootScope, $state, $stateParams, $mdDialog, notifyUserService, environmentService, healthcenterService, loyaltyCardService, productService) {
  $scope.select = false;
  $scope.submitting = false; // Product search config

  $scope.productSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct", "category"],
    event: "addProductsInLoyaltyCard",
    tag: "configProductLoyaltyCard",
    autoLaunchEvent: true,
    select: true,
    must: {}
  }; // Target search config

  $scope.targetSearchConfig = {
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
    event: "addTargetsInLoyaltyCard",
    tag: "target",
    autoLaunchEvent: true
  }; // Durations

  $scope.durations = [{
    id: 1,
    name: "12 mois",
    number: 1,
    type: "years"
  }, {
    id: 2,
    name: "18 mois",
    number: 18,
    type: "months"
  }, {
    id: 3,
    name: "2 ans",
    number: 2,
    type: "years"
  }, {
    id: 4,
    name: "5 ans",
    number: 5,
    type: "years"
  }, {
    id: 5,
    name: "Permanent",
    number: 50,
    type: "years"
  }];
  $scope.objectiveTypes = [{
    id: 1,
    name: "Quantité à atteindre"
  }, {
    id: 2,
    name: "Montant d'achat"
  }];
  $scope.objectiveRewardTypes = [{
    id: 1,
    name: "Produit gratuit"
  }, {
    id: 4,
    name: "Remise en euro sur le prochain achat"
  }, {
    id: 5,
    name: "Points"
  }]; // Set the objective point type to search for loyalty card with such objective

  var OBJECTIVE_POINT_TYPE = 5;
  $scope.defaultDurationDays = 365;
  $scope.goToList = goToList;
  $scope.isValidObjective1 = false;
  $scope.isValidObjective2 = false;
  $scope.isValidObjective3 = false;
  $scope.isValidObjective4 = false;
  $scope.loyaltyCardId = $stateParams.id;
  $scope.getLoyaltyCard = getLoyaltyCard;
  $scope.createLoyaltyCard = createLoyaltyCard;
  $scope.updateLoyaltyCard = updateLoyaltyCard;
  $scope.setDefaultPeriod = setDefaultPeriod;
  $scope.calculateEndDate = calculateEndDate;
  $scope.hasPointsObjective = false;
  $scope.products = [];
  $scope.removeProduct = removeProduct;
  $scope.targets = [];
  $scope.removeTarget = removeTarget;
  $scope.objective = {};
  $scope.addObjective = addObjective;
  $scope.removeObjective = removeObjective;
  $scope.resetObjectiveForm = resetObjectiveForm;
  $scope.animation = {};
  $scope.addAnimation = addAnimation;
  $scope.removeAnimation = removeAnimation;
  $scope.resetAnimationForm = resetAnimationForm;
  $scope.submit = submit;
  loader();
  /**
   * Loader of the controller.
   *
   * @returns {Promise} The promise
   */

  function loader() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.access = access;
      getLoyaltyCard();

      if ($scope.access.accessPointType === 1) {
        $scope.productSearchConfig.must = {
          "laboratory.id": [access.entityId]
        };
      }

      return access;
    });
  }
  /**
   * Get loyalty card.
   */


  function getLoyaltyCard() {
    if ($stateParams.id) {
      var loyaltyCard = {
        id: $stateParams.id
      };
      loyaltyCardService.read(loyaltyCard).then(function (response) {
        var loyaltyCard = response;
        var products = [];
        var targets = [];
        loyaltyCard.products.forEach(function (element) {
          if (element.product) {
            products.push({
              id: element.product.id,
              cip: element.product.code,
              name: element.product.name
            });
          }
        });
        loyaltyCard.targets.forEach(function (element) {
          targets.push({
            id: element.organization.morphable_id,
            name: element.organization.morphable.name,
            cip: element.organization.morphable.cip ? element.organization.morphable.cip : null,
            organizationId: element.organization.id,
            entityTypeId: element.organization.entity_type_id
          });
        }); // Search for point objectives.

        loyaltyCard.objectives.forEach(function (element) {
          if (element.rewardType.id === OBJECTIVE_POINT_TYPE) {
            $scope.hasPointsObjective = true;
          }
        });
        loyaltyCard.startDate = new Date(loyaltyCard.startDateEnglish);
        loyaltyCard.endDate = new Date(loyaltyCard.endDateEnglish);
        loyaltyCard.products = products;
        loyaltyCard.targets = targets;
        $scope.loyaltyCard = loyaltyCard;
      });
    } else {
      // Default values for loyalty card
      $scope.loyaltyCard = {
        name: "",
        description: "",
        file: {},
        startDate: "",
        hasInfiniteLifetime: true,
        durationDays: $scope.defaultDurationDays,
        endDate: "",
        products: [],
        targets: [],
        objectives: [],
        animations: [],
        duration: $scope.durations[0]
      };
      setDefaultPeriod();
    }

    resetAnimationForm();
  }
  /**
   * Submit loyaltyCard.
   */


  function submit() {
    $scope.submitting = true;
    var loyaltyCard = formatData();
    $scope.loyaltyCardId ? $scope.updateLoyaltyCard(loyaltyCard) : $scope.createLoyaltyCard(loyaltyCard);
  }
  /**
   * Create loyaltyCard.
   *
   * @param {object} loyaltyCard - The loyalty card
   *
   * @returns {Promise} The promise
   */


  function createLoyaltyCard(loyaltyCard) {
    return loyaltyCardService.create(loyaltyCard).then(function (response) {
      notifyUserService.showSuccessToast("La carte de fidélité est ajoutée.");
      goToList();
      return response;
    });
  }
  /**
   * Update loyaltyCard.
   *
   * @param {object} loyaltyCard - The loyalty card
   *
   * @returns {Promise} The promise
   */


  function updateLoyaltyCard(loyaltyCard) {
    loyaltyCard.id = $stateParams.id;
    return loyaltyCardService.update(loyaltyCard).then(function (response) {
      notifyUserService.showSuccessToast("La carte de fidélité est modifiée.");
      goToList();
      return response;
    });
  }
  /**
   * Format the data.
   *
   * @returns {object} The formatted loyalty card
   */


  function formatData() {
    var startDate = moment($scope.loyaltyCard.startDate).format("YYYY-MM-DD");
    var endDate = moment($scope.loyaltyCard.endDate).format("YYYY-MM-DD");
    var products = $scope.loyaltyCard.products.map(function (product) {
      return {
        presentation_id: product.id
      };
    });
    var targets = $scope.loyaltyCard.targets.map(function (target) {
      return {
        organization_id: target.organizationId,
        entity_type_id: target.entityTypeId,
        entity_id: target.entityId
      };
    });
    var objectives = $scope.loyaltyCard.objectives.map(function (objective) {
      return {
        reward: {
          type_id: objective.rewardType.id,
          value: objective.rewardValue || 1
        },
        type_id: objective.type.id,
        value: objective.objectiveValue
      };
    });
    return {
      name: $scope.loyaltyCard.name,
      description: $scope.loyaltyCard.description,
      organization_id: $scope.access.organization_id,
      has_infinite_lifetime: $scope.loyaltyCard.hasInfiniteLifetime,
      duration_days: $scope.loyaltyCard.durationDays,
      file_id: $scope.loyaltyCard.file ? $scope.loyaltyCard.file.id : null,
      start_date: startDate,
      end_date: endDate,
      products: products,
      targets: targets,
      objectives: objectives
    };
  }
  /**
   * Remove product from selection.
   *
   * @param {number} productId - The id of the product to remove.
   */


  function removeProduct(productId) {
    var products = $scope.loyaltyCard.products;
    angular.forEach(products, function (product, index) {
      if (product.id === productId) {
        products.splice(index, 1);
      }
    });
  }
  /**
   * Remove target from selection.
   *
   * @param {number} targetId - The id of the target to remove.
   */


  function removeTarget(targetId) {
    var targets = $scope.loyaltyCard.targets;
    angular.forEach(targets, function (target, index) {
      if (target.id === targetId) {
        targets.splice(index, 1);
      }
    });
  }
  /**
   * Add the objective to the objectives array.
   *
   * @param {object} objective - The objective
   */


  function addObjective(objective) {
    $scope.loyaltyCard.objectives.push(objective);
    $scope.loyaltyCard.objectives.sort(function (left, right) {
      return right.objectiveValue - left.objectiveValue;
    });

    if (objective.rewardType.id === OBJECTIVE_POINT_TYPE) {
      $scope.hasPointsObjective = true;
    }

    resetObjectiveForm();
  }
  /**
   * Remove the objective from the objectives array.
   *
   * @param {number} index - The index to remove.
   */


  function removeObjective(index) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression de l'objectif ?").ariaLabel("Suppression de l'objectif ").ok("Oui").cancel("Non")).then(function () {
      $scope.loyaltyCard.objectives.splice(index, 1);
      notifyUserService.showSuccessToast("L'objectif a été supprimé.");
      $scope.hasPointsObjective = false;
      $scope.loyaltyCard.objectives.forEach(function (element) {
        if (element.rewardType.id === OBJECTIVE_POINT_TYPE) {
          $scope.hasPointsObjective = true;
        }
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Reset the objective form.
   */


  function resetObjectiveForm() {
    if ($scope.objectiveForm) {
      $scope.$setUntouched();
    }

    $scope.objective = {
      type: $scope.objectiveTypes[0],
      objectiveValue: null,
      rewardType: $scope.objectiveRewardTypes[0],
      discountValue: null
    };
  }
  /**
   * Add the animation to the animations array.
   *
   * @param {object} animation - The animation
   */


  function addAnimation(animation) {
    $scope.loyaltyCard.animations.push(animation);
    $scope.loyaltyCard.animations.sort(function (left, right) {
      return right.coefficient - left.coefficient;
    });
    resetAnimationForm();
  }
  /**
   * Remove the animation from the animations array.
   *
   * @param {number} index - The index to remove.
   */


  function removeAnimation(index) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression de l'animation ?").ariaLabel("Suppression de l'animation ").ok("Oui").cancel("Non")).then(function () {
      $scope.loyaltyCard.animations.splice(index, 1);
      notifyUserService.showSuccessToast("L'animation a été supprimée.");
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Reset the animation form.
   */


  function resetAnimationForm() {
    if ($scope.animationForm) {
      $scope.$setUntouched();
    }

    $scope.animation = {
      coefficient: null,
      isForAllProducts: true
    };
  }
  /**
   * Set default period.
   */


  function setDefaultPeriod() {
    $scope.loyaltyCard.startDate = moment().toDate();
    $scope.loyaltyCard.endDate = moment().add(12, "month").toDate();
  }
  /**
   * Calculate end date.
   */


  function calculateEndDate() {
    var duration = $scope.loyaltyCard.duration;
    var startDate = moment($scope.loyaltyCard.startDate);
    startDate.add(duration.number, duration.type);
    $scope.loyaltyCard.endDate = startDate.toDate();
  }
  /**
   * Go to loyaltyCard list.
   */


  function goToList() {
    $state.go("app.loyaltyCard");
  } // Adding products to the loyaltyCard


  $rootScope.$on("addProductsInLoyaltyCard", function (event, data) {
    var queryProduct = {};

    var _loop = function _loop(typeOfData) {
      if (Object.prototype.hasOwnProperty.call(data, typeOfData)) {
        if (typeof queryProduct[typeOfData] === "undefined") {
          queryProduct[typeOfData] = [];
        }

        angular.forEach(data[typeOfData], function (obj) {
          queryProduct[typeOfData].push(obj.id);
        });
      }
    };

    for (var typeOfData in data) {
      _loop(typeOfData);
    } // getProducts with query results


    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.loyaltyCard.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.loyaltyCard.products.push(product);
        }
      });
    });
  }); // Adding targets to the loyaltyCard

  $scope.$on("addTargetsInLoyaltyCard", function (event, query) {
    var queryHealthcenter = {
      type: {}
    };

    var _loop2 = function _loop2(typeOfTarget) {
      if (Object.prototype.hasOwnProperty.call(query, typeOfTarget)) {
        if (typeof queryHealthcenter.type[typeOfTarget] === "undefined") {
          queryHealthcenter.type[typeOfTarget] = [];
        }

        angular.forEach(query[typeOfTarget], function (obj) {
          if (typeOfTarget === "uga") {
            queryHealthcenter.type[typeOfTarget].push(obj.name);
          } else {
            queryHealthcenter.type[typeOfTarget].push(obj.id);
          }
        });
      }
    };

    for (var typeOfTarget in query) {
      _loop2(typeOfTarget);
    } // Get healthcenters (geographic search)


    healthcenterService.getHealthcentersByGeo(queryHealthcenter).then(function (healthcenters) {
      angular.forEach(healthcenters, function (healthcenter) {
        if (!_.find($scope.loyaltyCard.targets, function (participant) {
          return healthcenter.organizationId === participant.organizationId;
        })) {
          healthcenter.type = "healthcenter";
          $scope.loyaltyCard.targets.push(healthcenter);
        }
      });
    });
  });
}

angular.module("app.loyaltyCard").controller("loyaltyCardEditController", LoyaltyCardEditController);