"use strict";
/**
 * Real marge factory.
 *
 * @returns {object}
 */

function RealMargeFactory() {
  var totalNumberQtSave;
  var totalNumberCaSave;
  var totalMargeEurosSave;
  return {
    setGlobalVar: function setGlobalVar(numberQtSave, numberCaSave, margeEurosSave) {
      totalNumberQtSave = numberQtSave;
      totalNumberCaSave = numberCaSave;
      totalMargeEurosSave = margeEurosSave;
    },
    changeRow: function changeRow(line) {
      line.pfht = line.product.buyingPrice;
      line.buyingPrice = (line.product.buyingPrice - (line.product.buyingPrice * line.discount || 0) / 100).toFixed(2);
      line.sellPrice = line.price_sell_HT.toFixed(2);
      line.ca = (line.price_sell_TTC * line.quantity).toFixed(2);
      line.quantityPercentage = (line.quantity / totalNumberQtSave * 100).toFixed(2);
      line.packingline = (line.price_sell_TTC - (line.product.buyingPrice - (line.product.buyingPrice * line.discount || 0) / 100)).toFixed(2);
      line.CApercentage = (line.price_sell_TTC * line.quantity / totalNumberCaSave * 100).toFixed(2);
      line.euroline = ((line.price_sell_TTC - (line.product.buyingPrice - line.product.buyingPrice * (line.discount || 0) / 100)) * line.quantity).toFixed(2);
      line.linePercentage = ((line.price_sell_TTC - (line.product.buyingPrice - line.product.buyingPrice * (line.discount || 0) / 100)) * line.quantity / totalMargeEurosSave * 100).toFixed(2);
      line.unitPercentageline = ((line.price_sell_TTC - (line.product.buyingPrice - (line.product.buyingPrice * line.discount || 0) / 100)) / line.price_sell_TTC * 100).toFixed(2);
      line.lineAllSales = ((line.price_sell_TTC - (line.product.buyingPrice - (line.product.buyingPrice * line.discount || 0) / 100)) * totalNumberQtSave).toFixed(2);
      line.CaMovement = ((line.price_sell_TTC * totalNumberQtSave - totalNumberCaSave) / totalNumberCaSave * 100).toFixed(2);
      line.lineMovement = Number((((line.price_sell_TTC - (line.product.buyingPrice - (line.product.buyingPrice * line.discount || 0) / 100)) * totalNumberQtSave - totalMargeEurosSave) / totalMargeEurosSave).toFixed(2));
      line.eurolineMovement = ((line.price_sell_TTC - (line.product.buyingPrice - (line.product.buyingPrice * line.discount || 0) / 100)) * totalNumberQtSave - totalMargeEurosSave).toFixed(2);
      return line;
    }
  };
}

angular.module("app.statistics.realMarge").factory("realMargeFactory", RealMargeFactory);