"use strict";
/**
 * Admin users controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} usersAdminService - Users admin service.
 */

AdminUsersController.$inject = ["$scope", "$state", "usersAdminService"];

function AdminUsersController($scope, $state, usersAdminService) {
  $scope.loading = true;
  $scope.search = "";
  $scope.options = {
    order: "id",
    totalRecords: 100,
    limit: 10,
    page: 1
  };
  $scope.data = {};
  $scope.getUsers = getUsers;
  $scope.editUser = editUser;
  $scope.loading = true;
  $scope.page = 1;
  loader();
  /**
   * loader
   * init getUser method
   */

  function loader() {
    getUsers();
  }
  /**
   * Get users.
   *
   * @returns {Promise}
   */


  function getUsers() {
    return usersAdminService.get().then(function (response) {
      $scope.loading = false;
      $scope.users = response;
      $scope.options.totalRecords = response.length;
      return response;
    });
  }
  /**
   * editUser
   * to return to the users list
   *
   * @param {object} user - User.
   */


  function editUser(user) {
    $state.go("app.users.edit", {
      id: user.id
    });
  }
}

angular.module("app.admin").controller("AdminUsersController", AdminUsersController);