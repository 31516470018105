"use strict";
/**
 * Medication summary factory.
 *
 * @returns {object}
 */

function MedicationSummaryFactory() {
  var factory = {};
  factory.appointments = [{
    id: 1,
    name: "Brunet",
    firstName: "Thomas",
    dateTime: "10/12/2018 08:00"
  }, {
    id: 2,
    name: "Ortuani",
    firstName: "Steeve",
    dateTime: "11/12/2018 08:00"
  }, {
    id: 3,
    name: "Mathot",
    firstName: "Dimitri",
    dateTime: "12/12/2018 10:00"
  }, {
    id: 4,
    name: "Héquet",
    firstName: "Stéphane",
    dateTime: "13/12/2018 14:00"
  }, {
    id: 5,
    name: "Doe",
    firstName: "John",
    dateTime: "10/12/2018 08:00"
  }];
  factory.patientsBySteps = {
    eligible: [{
      id: 1,
      name: "Brunet",
      firstName: "Thomas",
      dateTime: "10/12/2018 08:00"
    }, {
      id: 2,
      name: "Ortuani",
      firstName: "Steeve",
      dateTime: "11/12/2018 08:00"
    }],
    step1: [{
      id: 3,
      name: "Mathot",
      firstName: "Dimitri",
      dateTime: "12/12/2018 10:00"
    }, {
      id: 4,
      name: "Héquet",
      firstName: "Stéphane",
      dateTime: "13/12/2018 14:00"
    }],
    step2: [],
    step3: [],
    step4: [{
      id: 5,
      name: "Doe",
      firstName: "John",
      dateTime: "10/12/2018 08:00"
    }],
    step5: []
  };
  factory.eligiblePatient = [{
    id: 1,
    name: "Pinsker",
    firstName: "Jennifer",
    securitySocialNumber: "XXXXXXXXXXXX",
    phoneNumber: "05458796321",
    status: {
      id: 1,
      name: "Elligible"
    }
  }, {
    id: 2,
    name: "Pinsker",
    firstName: "Jennifer",
    securitySocialNumber: "XXXXXXXXXXXX",
    phoneNumber: "05458796321",
    status: {
      id: 2,
      name: "Recueil OK"
    }
  }, {
    id: 3,
    name: "Pinsker",
    firstName: "Jennifer",
    securitySocialNumber: "XXXXXXXXXXXX",
    phoneNumber: "05458796321",
    status: {
      id: 3,
      name: "Analyse OK"
    }
  }, {
    id: 4,
    name: "Pinsker",
    firstName: "Jennifer",
    securitySocialNumber: "XXXXXXXXXXXX",
    phoneNumber: "05458796321",
    status: {
      id: 1,
      name: "Elligible"
    }
  }, {
    id: 5,
    name: "Pinsker",
    firstName: "Jennifer",
    securitySocialNumber: "XXXXXXXXXXXX",
    phoneNumber: "05458796321",
    status: {
      id: 1,
      name: "Elligible"
    }
  }, {
    id: 6,
    name: "Pinsker",
    firstName: "Jennifer",
    securitySocialNumber: "XXXXXXXXXXXX",
    phoneNumber: "05458796321",
    status: {
      id: 1,
      name: "Elligible"
    }
  }, {
    id: 7,
    name: "Pinsker",
    firstName: "Jennifer",
    securitySocialNumber: "XXXXXXXXXXXX",
    phoneNumber: "05458796321",
    status: {
      id: 1,
      name: "Elligible"
    }
  }, {
    id: 8,
    name: "Pinsker",
    firstName: "Jennifer",
    securitySocialNumber: "XXXXXXXXXXXX",
    phoneNumber: "05458796321",
    status: {
      id: 1,
      name: "Elligible"
    }
  }, {
    id: 9,
    name: "Pinsker",
    firstName: "Jennifer",
    securitySocialNumber: "XXXXXXXXXXXX",
    phoneNumber: "05458796321",
    status: {
      id: 1,
      name: "Elligible"
    }
  }];
  factory.medicationSummary = {
    patient: {
      id: 1,
      name: "Brunet",
      firstName: "Thomas"
    },
    steps: [{
      id: 1,
      name: "Bulletin d'adhésion",
      validated: true,
      state: "app.patients.medicationSummary.patient.membership",
      membership: {}
    }, {
      id: 1,
      name: "Entretien de recueil",
      validated: false
    }, {
      id: 1,
      name: "Analyse du pharmacien et médecin",
      validated: false
    }, {
      id: 1,
      name: "Entretien conseil",
      validated: false
    }, {
      id: 1,
      name: "Entretien de suivi",
      validated: false
    }]
  };
  return factory;
}

angular.module("app.patients.factories.medicationSummaryFactory").factory("medicationSummaryFactory", MedicationSummaryFactory);