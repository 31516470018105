"use strict";
/**
 * Laboratory product edit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $sce - $sce.
 * @param {string} apiurl - API URL.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} environmentService - The environment service.
 * @param {object} laboratoryProductsService - Laboratory products service.
 * @param {object} productService - Product service.
 */

LaboratoryProductEditController.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "$sce", "apiurl", "notifyUserService", "environmentService", "laboratoryProductsService", "productService"];

function LaboratoryProductEditController($scope, $rootScope, $state, $stateParams, $sce, apiurl, notifyUserService, environmentService, laboratoryProductsService, productService) {
  $scope.tinymceOptions = {
    menubar: "edit insert view",
    plugins: "lists",
    toolbar: "styleselect | bold italic | bullist",
    skin: "lightgray",
    theme: "modern",
    style_formats: [{
      title: "Heading 1",
      format: "h1"
    }, {
      title: "Heading 2",
      format: "h2"
    }, {
      title: "Heading 3",
      format: "h3"
    }]
  };
  $scope.image = {};
  $scope.imageSource = "";
  $scope.nextProduct = {};
  $scope.loading = true;
  $scope.containerTitleConfig = {
    title: {
      icon: "icon-file-presentation-box",
      text: "Personnalisation du produit",
      back: {
        state: "app.laboratory-products",
        tooltip: "Retour à la gestion de mes produits"
      }
    }
  };
  $scope.productSearchConfig = {
    event: "productSearch",
    search: ["idProducts", "range.id", "generic.id", "segmentProduct", "category.levelX.id"],
    tag: "linkedProductsAdded",
    geoForce: true,
    searchPlaceHolder: "Ajouter un produit...",
    autoLaunchEvent: false
  };
  $scope.query = {
    order: "name",
    limit: 10,
    page: 1,
    total: null,
    search: ""
  };
  $scope.goToProductsList = goToProductsList;
  $scope.saveProduct = saveProduct;
  $scope.setImage = setImage;
  $scope.removeImage = removeImage;
  $scope.deleteProduct = deleteProduct;
  $scope.videoUrl = null;
  loader();
  /**
   * Loader.
   */

  function loader() {
    loadProduct();
    environmentService.getEnvironment().then(function (access) {
      $scope.productSearchConfig.must = {
        "laboratory.id": [access.entityId]
      };
    });
  }
  /**
   * Load product.
   *
   * @returns {Promise}
   */


  function loadProduct() {
    return laboratoryProductsService.read($stateParams.id).then(setTitle).then(prepareProduct).then(prepareImage).then(function () {
      $scope.loading = false;
    });
  }
  /**
   * Set container title with product.
   *
   * @param {object} product - Product.
   *
   * @returns {object}
   */


  function setTitle(product) {
    var name = product.override ? product.override.name || product.name : product.name;
    $scope.containerTitleConfig.title.text += " " + name;
    return product;
  }
  /**
   * Prepare product's data for the view.
   *
   * @param {object} product - The product.
   *
   * @returns {object}
   */


  function prepareProduct(product) {
    var override = product.override;
    $scope.nextProduct = {
      productId: product.id,
      code: product.code,
      originalName: product.name,
      name: override ? override.name || product.name : product.name,
      buyingPrice: override ? override.buyingPrice || product.buyingPrice : product.buyingPrice,
      delivrance: product.delivrance ? product.delivrance.name : "-",
      genericType: product.genericType ? product.genericType.name : "-",
      refund: product.refund.name,
      laboratoryAdvice: override ? override.laboratoryAdvice : "",
      shortDescription: override ? override.shortDescription : "",
      pharmacistDescription: override ? override.pharmacistDescription : "",
      consumerDescription: override ? override.consumerDescription : "",
      image: product.photoPath,
      ingredients: override ? override.ingredients : "",
      linkedProducts: override ? override.linkedProducts || [] : [],
      videoPath: override ? override.videoPath : null,
      onTheMarket: product.onTheMarket === 1
    };
    return product;
  }
  /**
   * Prepare product's image for the view.
   *
   * @param {object} product - The product.
   *
   * @returns {object}
   */


  function prepareImage(product) {
    if (product.photoPath) {
      $scope.imageSource = apiurl + "/images/" + product.photoPath;
    }

    return product;
  }
  /**
   * Save product.
   *
   * @returns {Promise}
   */


  function saveProduct() {
    var product = $scope.nextProduct;
    $scope.nextProduct.linkedProductIds = product.linkedProducts.length ? product.linkedProducts.map(function (product) {
      return product.id;
    }) : [];
    return laboratoryProductsService.update(product).then(function () {
      var name = product.name ? product.name : product.originalName;
      notifyUserService.showSuccessToast("La personnalisation du produit « " + name + " » est enregistrée.");
      goToProductsList();
    });
  }
  /**
   * Set product's image.
   */


  function setImage() {
    $scope.nextProduct.image = $scope.image.id;
    $scope.imageSource = $scope.image.source;
  }
  /**
   * Remove product's image.
   */


  function removeImage() {
    $scope.image = {};
    $scope.nextProduct.image = "";
    $scope.imageSource = "";
  }
  /**
   * Get products from search bar.
   *
   * @param {object} event - The searchbar event.
   * @param {object} data - The searchbar data.
   *
   * @returns {Promise<[]>}
   */


  function getProductsFromSearchBar(event, data) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    }

    return productService.postPresentationByType(queryProduct).then(function (products) {
      var result = [];
      angular.forEach(products, function (product) {
        if (!_.find(result, function (prod) {
          return product.id === prod.id;
        })) {
          result.push(product);
        }
      });
      return result;
    });
  }
  /**
   * Delete the product from the linked products.
   *
   * @param {object} product - The product to delete.
   */


  function deleteProduct(product) {
    $scope.nextProduct.linkedProducts = $scope.nextProduct.linkedProducts.filter(function (linkedProduct) {
      return product.id !== linkedProduct.id;
    });
  }

  $rootScope.$on("productSearch", function (event, data) {
    if (_.isUndefined($scope.productSearch)) {
      $scope.nextProduct.linkedProducts = [];
    }

    getProductsFromSearchBar(event, data).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.nextProduct.linkedProducts, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.nextProduct.linkedProducts.push(product);
        }
      });
    });
  });
  $scope.$watch("nextProduct.videoPath", function (newValue) {
    if (newValue) {
      $scope.videoUrl = $sce.trustAsResourceUrl(newValue.replace("watch?v=", "embed/"));
    }
  });
  /**
   * Go to products listing.
   */

  function goToProductsList() {
    $state.go("app.laboratory-products");
  }
}

angular.module("app.laboratory-products").controller("LaboratoryProductEditController", LaboratoryProductEditController);