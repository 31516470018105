"use strict";
/**
 * Grouping audit configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

GroupmentAuditConfig.$inject = ["$stateProvider"];

function GroupmentAuditConfig($stateProvider) {
  $stateProvider.state("app.groupmentAudit", {
    url: "/audit-groupement",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/groupmentAudit/views/list/list.html",
        controller: "GroupmentAuditController"
      }
    }
  }).state("app.groupmentAudit.detail", {
    url: "/:groupId",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/groupmentAudit/views/detail/detail.html",
        controller: "GroupmentAuditDetailController"
      }
    }
  }).state("app.groupmentAudit.detail.generalInfos", {
    url: "/general",
    views: {
      "tabContent@app.groupmentAudit.detail": {
        templateUrl: "app/main/statistics/groupmentAudit/views/generalInfos/generalInfos.html",
        controller: "GroupmentAuditGeneralInfosController"
      }
    }
  }).state("app.groupmentAudit.detail.salesEvolution", {
    url: "/courbe",
    views: {
      "tabContent@app.groupmentAudit.detail": {
        templateUrl: "app/main/statistics/salesAnalysis/views/sandbox-lines-atcl/sandbox-lines-atcl.html",
        controller: "StatsSandboxLinesAtclController as vm"
      }
    }
  }).state("app.groupmentAudit.detail.marketShare", {
    url: "/parts-de-marche",
    views: {
      "tabContent@app.groupmentAudit.detail": {
        templateUrl: "app/main/statistics/salesAnalysis/views/marketShare/marketShare.html",
        controller: "MarketShareController as vm"
      }
    }
  }).state("app.groupmentAudit.detail.realStock", {
    url: "/stock-reel",
    views: {
      "tabContent@app.groupmentAudit.detail": {
        templateUrl: "app/main/statistics/stocks/views/stock/stock.html",
        controller: "StatisticsStockController as vm"
      }
    }
  }).state("app.groupmentAudit.detail.goumy", {
    url: "/valorisation-des-stocks",
    views: {
      "tabContent@app.groupmentAudit.detail": {
        templateUrl: "app/main/statistics/stocks/stat/stat.html",
        controller: "StatisticsStatStockController as vm"
      }
    }
  }).state("app.groupmentAudit.detail.sellout", {
    url: "/challenge",
    views: {
      "tabContent@app.groupmentAudit.detail": {
        templateUrl: "app/main/sellout/list/list.html",
        controller: "ChallengeSelloutListController as vm"
      }
    }
  }).state("app.groupmentAudit.detail.prestation", {
    url: "/mise-en-avant",
    views: {
      "tabContent@app.groupmentAudit.detail": {
        templateUrl: "app/main/prestation/list/list.html",
        controller: "PrestationListController as vm"
      }
    }
  }).state("app.groupmentAudit.detail.sellin", {
    url: "/challenge-sellin",
    views: {
      "tabContent@app.groupmentAudit.detail": {
        templateUrl: "app/main/sellin-challenge/views/list/sellinChallenges.html",
        controller: "sellinsController"
      }
    }
  });
}

angular.module("app.groupmentAudit", []).config(GroupmentAuditConfig);