"use strict";
/**
 * Healthcenter stock audit general infos controller.
 *
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $q - $q.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} healthcenterAuditHcDetailsService - Healthcenter audit details service.
 * @param {object} healthCenterAuditAnalysisService - Healthcenter audit analysis service.
 * @param {object} environmentService - Environment service.
 */

HealthcenterStockAuditGeneralInfosController.$inject = ["$state", "$stateParams", "$q", "StatisticsQueryService", "healthcenterAuditHcDetailsService", "healthCenterAuditAnalysisService", "environmentService"];

function HealthcenterStockAuditGeneralInfosController($state, $stateParams, $q, StatisticsQueryService, healthcenterAuditHcDetailsService, healthCenterAuditAnalysisService, environmentService) {
  var vm = this;
  vm.cartTotal = 0;
  vm.vatTotal = 0;
  vm.goBack = goBack;
  vm.vatSplitDatas = $q.defer();
  vm.vatSplit = {
    type: "pie",
    theme: "light",
    decimalSeparator: ",",
    thousandsSeparator: " ",
    data: vm.vatSplitDatas.promise,
    valueField: "sales",
    titleField: "vat",
    labelsEnabled: false,
    legend: {
      enabled: true,
      valueFunction: function valueFunction(data) {
        return Math.round(data.dataContext.roundedPercent * 100) / 100;
      }
    },
    balloon: {
      fixedPosition: true
    },
    "export": {
      enabled: true
    }
  };
  vm.vatCorrespondTo = {
    5: "Tva à 2,10%",
    9: "Tva à 20%",
    8: "Tva à 10%",
    3: "Tva à 5,5%",
    2: "Tva à 19,6%"
  };

  if (typeof $stateParams.id === "undefined") {
    goBack();
  }
  /**
   * Loader.
   */


  function loader() {
    healthcenterAuditHcDetailsService.hCDetail($stateParams.id).then(function (pharma) {
      vm.ids = [pharma.healthcenter.id];
      vm.pharma = pharma.healthcenter;
      environmentService.getEnvironment().then(function (response) {
        vm.currentEnv = response;
        getStats();
        vm.relaunchSS = lastLgoImport(response.entity.lastLgoImport);
      });
    });
  }
  /**
   * Last LGO import.
   *
   * @param {string} lastLgoImport - Last LGO import date.
   *
   * @returns {boolean}
   */


  function lastLgoImport(lastLgoImport) {
    vm.lastSynchro = moment().diff(moment(lastLgoImport), "days");
    return moment(lastLgoImport) <= moment().subtract(2, "days");
  }
  /**
   * Go back.
   */


  function goBack() {
    $state.go("app.healthcenterAudit");
  }
  /**
   * Get stats.
   */


  function getStats() {
    StatisticsQueryService.queryRebase();
    var dateEnd = moment();
    var dateStart = moment().subtract(12, "month");
    var dateEndCopy = dateEnd;
    var dateStartCopy = dateStart;
    vm.auditQuery = StatisticsQueryService.query;
    vm.auditQuery.date = {
      from: dateStart,
      to: dateEnd
    };
    vm.cartTotalForLabo = 0;
    vm.numberofDays = dateEndCopy.diff(dateStartCopy, "days");
    vm.auditQuery.must.laboratory.selected = [];
    statisticLoader(vm.auditQuery, vm.auditQuery.date);
  }
  /**
   * Statistic loader.
   */


  function statisticLoader() {
    StatisticsQueryService.buildquery(vm.auditQuery, vm.auditQuery.date).then(function (query) {
      vm.healthcenterQuery = query;
      vm.healthcenterQuery.must.healthcenter = [parseInt(vm.pharma.cip)];
      healthCenterAuditAnalysisService.sales(vm.healthcenterQuery).then(function (sales) {
        vm.CA12LastMonths = sales.aggregations.amount_excl_tax.value;
        angular.forEach(sales.aggregations.sales.buckets, function (value) {
          vm.cartTotal += value.cartTotal.value;
        });

        if (vm.currentEnv.accessPointType === 1) {
          vm.healthcenterQuery.must.laboratory = [vm.currentEnv.entityId];
        }

        if (vm.currentEnv.accessPointType === 3) {
          vm.healthcenterQuery.must.groups = [vm.currentEnv.entityId];
        }

        return $q.all([dailySalesLabo(vm.healthcenterQuery), salesByOperator(vm.healthcenterQuery), vatSplitSales(vm.healthcenterQuery), salesByOperator2(vm.healthcenterQuery), salesGlobalByOperator(vm.healthcenterQuery)]);
      });
    });
  }
  /**
   * Daily sales laboratory.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function dailySalesLabo(query) {
    return healthCenterAuditAnalysisService.sales(query).then(function (salesLabo) {
      vm.CALaboratory12LastMonths = salesLabo.aggregations.amount_excl_tax.value;
      angular.forEach(salesLabo.aggregations.sales.buckets, function (valueLabo) {
        vm.cartTotalForLabo += valueLabo.cartTotal.value;
      });
    });
  }
  /**
   * Sales by operator.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function salesByOperator(query) {
    query.aggby = "item.operator.id";
    return healthCenterAuditAnalysisService.sales(query).then(function (salesByOperator) {
      vm.CALaboratoryByOperator = salesByOperator.aggregations.aggby.buckets;
      vm.globalLaboSales = salesByOperator.aggregations.amount_excl_tax.value;
    });
  }
  /**
   * VAT split sales.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function vatSplitSales(query) {
    vm.healthcenterQuery.aggby = null;
    vm.healthcenterQuery.must.healthcenter = [parseInt(vm.pharma.cip)];
    return healthCenterAuditAnalysisService.vatSplit(query).then(function (sales) {
      var data = [];
      angular.forEach(sales.aggregations.vat.buckets, function (ca) {
        data.push({
          vat: vm.vatCorrespondTo[ca.key],
          sales: ca.sales.value
        });
        vm.vatTotal += ca.sales.value;
      });
      angular.forEach(data, function (datum) {
        datum.roundedPercent = datum.sales / vm.vatTotal * 100;
      });
      vm.vatSplitDatas.resolve(data);
    });
  }
  /**
   * Sales by operator 2.
   *
   * @returns {Promise}
   */


  function salesByOperator2() {
    vm.healthcenterQuery.aggby = "item.operator.id";
    return healthCenterAuditAnalysisService.sales(vm.healthcenterQuery).then(function (salesByOperator) {
      vm.CALaboratoryByOperator = salesByOperator.aggregations.aggby.buckets;
      vm.globalLaboSales = salesByOperator.aggregations.amount_excl_tax.value;
      vm.auditQuery.must.laboratory.selected = [];
    });
  }
  /**
   * Sales global by operator.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function salesGlobalByOperator(query) {
    vm.healthcenterQuery.aggby = "item.operator.id";
    vm.healthcenterQuery.must.healthcenter = [parseInt(vm.pharma.cip)];
    return healthCenterAuditAnalysisService.sales(query).then(function (salesGlobalByOperator) {
      vm.CAGlobalByOperator = [];
      angular.forEach(salesGlobalByOperator.aggregations.aggby.buckets, function (op) {
        vm.CAGlobalByOperator[op.key] = op.amount_excl_tax.value;
      });
    });
  }

  loader();
}

angular.module("app.healthcenterAudit").controller("healthcenterStockAuditGeneralInfosController", HealthcenterStockAuditGeneralInfosController).filter("parseHours", function () {
  return function (text) {
    return text ? text.replace(":", "H").substring(0, 5) : "";
  };
});