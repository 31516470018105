"use strict";
/**
 * The loyalty card service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object} Available routes of service
 */

LoyaltyCardService.$inject = ["apiClient"];

function LoyaltyCardService(apiClient) {
  return {
    /**
     * Get the loyalty cards.
     *
     * @returns {Promise} The promise
     */
    get: function get() {
      var query = {
        includes: ["organization.morphable"]
      };
      return apiClient.get("/loyalty-cards", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a loyalty card.
     *
     * @param {object} data - The loyalty card
     *
     * @returns {Promise} The promise
     */
    create: function create(data) {
      return apiClient.post("/loyalty-cards", {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a loyalty card.
     *
     * @param {object} data - The loyalty card
     *
     * @returns {Promise} The promise
     */
    read: function read(data) {
      var query = {
        includes: ["file", "organization.morphable", "targets.organization.morphable", "products", "objectives", "animations"]
      };
      return apiClient.get("/loyalty-cards/".concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get performances of a loyalty card.
     *
     * @param {object} data - The loyalty card
     *
     * @returns {Promise} The promise
     */
    getPerformances: function getPerformances(data) {
      return apiClient.get("/loyalty-cards/".concat(data.id, "/performances")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get consumers profiles of a loyalty card.
     *
     * @param {object} data - The loyalty card
     *
     * @returns {Promise} The promise
     */
    getConsumersProfiles: function getConsumersProfiles(data) {
      return apiClient.get("/loyalty-cards/".concat(data.id, "/consumers-profiles")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get loyalty card progress by member.
     *
     * @param {object} loyaltyCard - The loyalty card.
     *
     * @returns {Promise} The promise
     */
    getProgress: function getProgress(loyaltyCard) {
      return apiClient.get("/loyalty-cards/".concat(loyaltyCard.id, "/progress")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a loyalty card.
     *
     * @param {object} data - The loyalty card
     *
     * @returns {Promise} The promise
     */
    update: function update(data) {
      return apiClient.put("/loyalty-cards/".concat(data.id), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a loyalty card.
     *
     * @param {object} data - The loyalty card
     *
     * @returns {Promise} The promise
     */
    "delete": function _delete(data) {
      return apiClient["delete"]("/loyalty-cards/".concat(data.id)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the loyalty card members.
     *
     * @param {object} data - The loyalty card
     *
     * @returns {Promise} The promise
     */
    getMembers: function getMembers(data) {
      return apiClient.get("/loyalty-cards/".concat(data.loyaltyCardId, "/members")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a loyalty card member.
     *
     * @param {object} data - The loyalty card member
     *
     * @returns {Promise} The promise
     */
    createMember: function createMember(data) {
      return apiClient.post("/loyalty-cards/".concat(data.loyaltyCardId, "/members"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a loyalty card member.
     *
     * @param {object} data - The loyalty card member
     *
     * @returns {Promise} The promise
     */
    readMember: function readMember(data) {
      var query = {
        includes: ["loyaltyCard.objectives"]
      };
      return apiClient.get("/loyalty-cards/".concat(data.loyaltyCardId, "/members/").concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a loyalty card member.
     *
     * @param {object} data - The loyalty card member
     *
     * @returns {Promise} The promise
     */
    updateMember: function updateMember(data) {
      return apiClient.put("/loyalty-cards/".concat(data.loyaltyCardId, "/members/").concat(data.id), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * List loyalty card member sales.
     *
     * @param {object} loyaltyCard - Loyalty card.
     * @param {object} loyaltyCardMember - Loyalty card member.
     *
     * @returns {Promise}
     */
    listLoyaltyCardMemberSales: function listLoyaltyCardMemberSales(loyaltyCard, loyaltyCardMember) {
      var query = {
        includes: ["product"]
      };
      return apiClient.get("/loyalty-cards/".concat(loyaltyCard.id, "/members/").concat(loyaltyCardMember.id, "/sales"), query).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.loyaltyCard").service("loyaltyCardService", LoyaltyCardService);