"use strict";
/**
 * Patient appointments controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdPanel - $mdPanel
 * @param {object} $timeout - Angular $timeout object.
 * @param {object} appointmentsFactory - The appointments factory.
 * @param {object} eventService - The event service.
 * @param {object} uiCalendarConfig - UiCalendarConfig object.
 * @param {object} patientAppointmentsService - patientAppointmentService
 */

PatientsAppointmentsController.$inject = ["$scope", "$mdPanel", "$timeout", "appointmentsFactory", "eventService", "uiCalendarConfig", "patientAppointmentsService"];

function PatientsAppointmentsController($scope, $mdPanel, $timeout, appointmentsFactory, eventService, uiCalendarConfig, patientAppointmentsService) {
  var panelRef = null;
  $scope.loading = false;
  $scope.appointmentsTitle = {
    title: {
      text: "Mes RDV patients",
      back: null
    }
  };
  $scope.events = [];
  $scope.eventList = {};
  $scope.eventSource = [$scope.events];
  $scope.uiConfig = appointmentsFactory.calendarConfig;
  $scope.uiConfig.calendar.eventClick = handleAppointmentClick;
  $scope.openAppointment = handleAppointmentClick;
  $scope.getEventForAppointment = getEventForAppointment;
  $scope.uiConfig.calendar.eventRender = eventRender;

  $scope.uiConfig.calendar.select = function (start, end, element) {
    openPanel(start, end.clone().subtract("1", "day"), element.target);
  };

  $scope.openPanel = function () {
    openPanel(moment(), null, null);
  };

  loadData();
  /**
   * Load the events.
   *
   * @returns {void}
   */

  function loadData() {
    closePanel();
    Promise.all([loadEvents(), loadAppointments()]).then(function () {
      return refreshCalendarData();
    });
  }
  /**
   * Load events.
   *
   * @returns {Promise}
   */


  function loadEvents() {
    return eventService.getPatientAppointmentEvents().then(function (categories) {
      var events = categories.events.flatMap(function (categorie) {
        return categorie.segments;
      });
      $scope.events = transformEventsForCalendar(events);
      refreshCalendarData();
    });
  }
  /**
   * Load appointments
   *
   * @returns {Promise}
   */


  function loadAppointments() {
    return patientAppointmentsService.index().then(function (appointments) {
      $scope.appointments = patientAppointmentsService.transformAppointmentsForCalendar(appointments);
      $scope.appointmentList = splitAppointments($scope.appointments);
      refreshCalendarData();
    });
  }
  /**
   * Event rendering.
   *
   * @param {object} event - The event to render.
   * @param {object} element - The DOM element.
   */


  function eventRender(event, element) {
    if (element.hasClass("appointment")) {
      var appointmentEvent = getEventForAppointment(event);
      var dottClass = event.accepted_at ? "accepted" : event.refused_at || event.deleted_at ? "refused" : null;

      if (event.deleted_at) {
        element.css("borderColor", "red");
      } else {
        element.css("borderColor", appointmentEvent ? appointmentEvent.darkColor : null);
      }

      element.find(".fc-time").prepend("<label class='mr-5 " + dottClass + "'>&#x25CF;</label>");
    }
  }
  /**
   * Find the corresponding event for an appointment.
   *
   * @param {object} appointment - The appointment.
   *
   * @returns {null|object}
   */


  function getEventForAppointment(appointment) {
    if (!appointment.event) {
      return null;
    }

    return $scope.events.find(function (event) {
      return event.id === appointment.event.id;
    });
  }
  /**
   * On the event creation panel.
   *
   * @param {moment.Moment} startDate - The startDate object.
   * @param {moment.Moment} endDate - The endDate object.
   * @param {object} target - The targeted element.
   * @param {object} event - The optional event to show for update.
   */


  function openPanel(startDate, endDate, target) {
    var event = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    var position = $mdPanel.newPanelPosition();

    if (target) {
      position = position.relativeTo(target).addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);
    } else {
      position = position.absolute().center();
    }

    var panelConfig = {
      locals: {
        startDate: startDate,
        endDate: endDate,
        callback: loadData,
        onPanelClose: closePanel,
        event: event
      },
      attachTo: angular.element(document.body),
      controller: "patientCreateAppointmentController",
      controllerAs: "vm",
      disableParentScroll: true,
      templateUrl: "app/main/patients/components/patient-create-appointment/patient-create-appointment.html",
      hasBackdrop: false,
      autoWrap: false,
      position: position,
      clickOutsideToClose: true
    };
    $mdPanel.open(panelConfig).then(function (ref) {
      panelRef = ref;
    });
  }
  /**
   * Close the panel.
   *
   * @param {object} $event - Angular $event object.
   */


  function closePanel($event) {
    if ($event) {
      $event.stopPropagation();
    }

    panelRef && panelRef.close();
  }
  /**
   * Transform events for calendar.
   *
   * @param {Array} events - The events to transform.
   *
   * @returns {Array} The transformed events.
   */


  function transformEventsForCalendar(events) {
    return events.filter(function (event) {
      return event.start;
    }).map(function (event) {
      event.color = event.darkColor;
      event.title = event.name;
      event.startCopy = event.start;
      event.endCopy = event.end;
      event.start = moment(event.start);
      event.end = moment(event.end).add("1", "day");
      event.custom = event.custom_event;
      event.className = event.title.replace(/[^a-z0-9]+/gi, "a").replace(/ /g, "-") + event.id + " pointer";
      event.type = "calendar";
      event.allDay = true;
      return event;
    });
  }
  /**
   * Split appointments in order to get deleted, unhandled, incoming appointments.
   *
   * @param {Array} appointments - The appointments.
   *
   * @returns {object}
   */


  function splitAppointments(appointments) {
    var deleted = appointments.filter(function (appointment) {
      return appointment.deleted_at;
    });
    var unhandled = appointments.filter(function (appointment) {
      return !appointment.accepted_at && !appointment.refused_at && !deleted.includes(appointment) && appointment.start > moment();
    });
    var incoming = appointments.filter(function (appointment) {
      return appointment.start > moment() && !unhandled.includes(appointment) && !deleted.includes(appointment);
    });
    return {
      deleted: deleted,
      incoming: incoming,
      unhandled: unhandled
    };
  }
  /**
   * Refresh the event source.
   */


  function refreshCalendarData() {
    uiCalendarConfig.calendars.calendar.fullCalendar("removeEvents");
    uiCalendarConfig.calendars.calendar.fullCalendar("addEventSource", $scope.events);
    uiCalendarConfig.calendars.calendar.fullCalendar("addEventSource", $scope.appointments);
  }
  /**
   * Handle appointment click.
   *
   * @param {object} event - The event.
   * @param {object} element - The DOM element.
   */


  function handleAppointmentClick(event, element) {
    openAppointmentDetailsPanel(element.currentTarget, event);
  }
  /**
   * Open the details of an appointment.
   *
   * @param {object} target - The DOM target.
   * @param {object} appointment - The appointment object.
   */


  function openAppointmentDetailsPanel(target, appointment) {
    var position = $mdPanel.newPanelPosition().relativeTo(target).addPanelPosition($mdPanel.xPosition.ALIGN_START, $mdPanel.yPosition.BELOW);
    var panelConfig = {
      locals: {
        event: appointment,
        onPanelClose: closePanel,
        callback: loadData,
        handleEdit: handleEdit,
        target: target
      },
      attachTo: angular.element(document.body),
      controller: "patientAppointmentDetailsController",
      controllerAs: "vm",
      disableParentScroll: true,
      templateUrl: "app/main/patients/views/appointments/modals/appointment-details/patients.appointment-details.html",
      hasBackdrop: false,
      autoWrap: false,
      position: position,
      clickOutsideToClose: true
    };
    $mdPanel.open(panelConfig).then(function (ref) {
      panelRef = ref;
    });
  }
  /**
   * Handle the edition of an event.
   *
   * @param {object} event - The event to edit.
   * @param {object} target - HTML target.
   */


  function handleEdit(event, target) {
    closePanel();
    openPanel(null, null, target, event);
  }
}

angular.module("app.patients").controller("patientsAppointmentsController", PatientsAppointmentsController);