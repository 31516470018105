"use strict";

angular.module("apodis", ["app.core", "app.navigation", "app.entityAdmin", "app.guest", "app.services", "app.home", "app.auth", "app.toBeMade", "app.users", "app.organization", "app.grouping", "app.users-prestation", "app.users-sellout", "app.users-transactions", "app.healthcenters", "app.loyaltyCard", "app.statistics", "app.category", "app.directives", "app.factories", "app.commercialOffer", "app.directOffer", "app.prestation", "app.sellout", "app.sellinChallenge", "app.sellinOffer", "app.file-manager", "app.statistics.stocks", "app.teammanager", "app.healthcenterAudit", "app.healthcenterStockAudit", "app.admin", "app.legal", "app.dashboard", "app.rangePicker", "app.logMessage", "app.users-laboratory", "app.laboratory", "app.wholesaler", "app.generator", "app.segment", "app.events", "app.partners", "app.salesAreaManagement", "app.disruption", "exceptionOverwrite", "app.laboratory-filter", "app.oauth", "app.contractManagement", "app.patients", "app.patient-administration", "app.pack", "app.merchandising-plan", "app.impactAnalysis", "app.contacts", "app.documentation", "app.patients-search", "app.instantDiscountVoucher", "app.administration",
/*
    ** External modules
    */
"ui.select", // Select 2
"app.ui.calendar", "ui.carousel", "datatables", "datatables.fixedcolumns", "datatables.scroller", "md.data.table", // Material Data Table
"base64", // Base64
"ngFileUpload", // File Upload
"dndLists", // Drag & Drop list
"flow", // Real File Upload w/ dnd for file manager
"angular-intro", // Introduction
"siyfion.sfTypeahead", // Typeahead - autocomplete avec multiple datasets
"masonry", // Masonry
"angular-flexslider", // Slider dashboard
"ngSanitize", // Dependencies for ngCSV
"ngCsv", // CSV export for arrays and objects
"ngDragDrop", "nvd3", "angularFileUpload", "ui.tinymce", "mdColorPicker", "ui.tree"]);