"use strict";
/**
 * Member graph directive.
 *
 * @returns {object}
 */

MemberGraphController.$inject = ["$scope"];

function memberGraph() {
  return {
    restrict: "E",
    scope: {
      datas: "@",
      healthcenterId: "@",
      healthcenterName: "@"
    },
    template: "<div layout='column' class='widget-group white-bg'>" + "         <div layout='row' flex='100' layout-wrap>" + "     <!-- WIDGET 1 -->" + "     <ms-widget flippable='false' layout='column' flex>" + "     <!-- Front -->" + "     <ms-widget-front class='p-16 white-bg'>" + "        <div class=' border-bottom '" + "     layout='row' layout-align='space-between center' layout-wrap=''>" + "         <div class='pv-8 h4 font-weight-500 color-text'>" + "        <md-icon style='line-height: 14px !important;' md-font-icon='icon-chart-arc'" + "     class='icon mr-8 s18 md-accent'></md-icon>" + "         {{healthcenterName}}" + "     </div>" + "     </div>" + "     <div class='white-fg' layout='row' flex ng-if='legendData.length > 0'>" + "         <div layout='column' flex='45'>" + "        <am-chart id='{{pieId}}' options='pieOptions' height='500px'" + "     width='100%'></am-chart>" + "         </div>" + "         <div layout='column' flex='45'>" + "        <am-chart id='{{serialId}}' options='columnOptions' height='500px'" + "     width='90%'></am-chart>" + "         </div>" + "         </div>" + "        <div id='{{pieId + serialId}}'" + "     style='height : 100px!important; margin: 5px 0 20px 0;position: relative;'></div>" + "         </ms-widget-front>" + "         <!-- / Front -->" + "         </ms-widget>" + "         </div>" + "         <!-- / WIDGET 1 -->" + "     </div>",
    controller: MemberGraphController
  };
}
/**
 * Member graph controller.
 *
 * @param {object} $scope - $scope.
 */


function MemberGraphController($scope) {
  /**
   * Initialize.
   */
  function init() {
    $scope.pieId = "pie" + $scope.healthcenterId;
    $scope.serialId = "serial" + $scope.healthcenterId;
    var datas = JSON.parse($scope.datas);
    $scope.legendData = datas.map(function (data) {
      return {
        title: data.id,
        color: data.color
      };
    });
    $scope.pieOptions = {
      type: "pie",
      decimalSeparator: ",",
      thousandsSeparator: " ",
      precision: 0,
      startDuration: 0,
      theme: "light",
      data: datas,
      valueField: "sales",
      titleField: "id",
      colorField: "color",
      labelsEnabled: false,
      balloonText: "[[title]]: <b>[[value]] €</b>",
      balloon: {
        fixedPosition: true
      },
      "export": {
        enabled: true
      }
    };
    $scope.columnOptions = {
      type: "serial",
      decimalSeparator: ",",
      thousandsSeparator: " ",
      precision: 0,
      startDuration: 0,
      data: datas,
      valueAxes: [{
        gridColor: "#FFFFFF",
        gridAlpha: 0.2,
        dashLength: 0
      }],
      graphs: [{
        balloonText: "[[category]]: <b>[[value]] €</b>",
        fillAlphas: 0.8,
        lineAlpha: 0.2,
        type: "column",
        valueField: "sales",
        colorField: "color"
      }],
      chartCursor: {
        categoryBalloonEnabled: false
      },
      categoryField: "id",
      categoryAxis: {
        gridPosition: "start",
        gridAlpha: 0,
        tickPosition: "start",
        tickLength: 20,
        parseDates: false,
        labelsEnabled: false
      },
      legend: {
        marginTop: 16,
        valueAlign: "left",
        divId: $scope.pieId + $scope.serialId,
        generateFromData: true,
        data: $scope.legendData
      },
      "export": {
        enabled: true
      }
    };
  }

  $scope.$watch("datas", function () {
    init();
  });
}

angular.module("app.directives").directive("memberGraph", memberGraph);