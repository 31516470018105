"use strict";
/**
 * Dashboard laboratories prestations push controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 */

DashboardLaboratoriesPrestationsPushController.$inject = ["$scope", "$state"];

function DashboardLaboratoriesPrestationsPushController($scope, $state) {
  $scope.goToPrestations = function () {
    $state.go("app.prestation.dashboard");
  };
}

angular.module("app.dashboard").controller("dashboardLaboratoriesPrestationsPushController", DashboardLaboratoriesPrestationsPushController);