"use strict";
/**
 * Instant discount voucher detail controller.
 *
 * @param {object} instantDiscountVoucherService - Instant discount voucher service.
 * @param {string} apiurl - API URL.
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdDialog - $mdDialog.
 */

InstantDiscountVoucherDetailController.$inject = ["instantDiscountVoucherService", "apiurl", "$scope", "$stateParams", "$mdDialog"];

function InstantDiscountVoucherDetailController(instantDiscountVoucherService, apiurl, $scope, $stateParams, $mdDialog) {
  $scope.loading = false;
  loadInstantDiscountVoucher();
  /**
   * Load current editing instant discount voucher data an id was given.
   */

  function loadInstantDiscountVoucher() {
    if (!$stateParams.id) {
      return;
    }

    $scope.loading = true;
    $scope.reachedStepsCounts = [];
    $scope.targetedHealthcenters = {};
    $scope.triggerProducts = {};
    $scope.apiurl = apiurl;
    $scope.showSales = showSales;
    var queryParameters = {
      id: $stateParams.id
    };
    instantDiscountVoucherService.read(queryParameters).then(function (instantDiscountVoucher) {
      $scope.instantDiscountVoucher = instantDiscountVoucher;
      $scope.instantDiscountVoucher.startDate = new Date(instantDiscountVoucher.startDate);
      $scope.instantDiscountVoucher.endDate = new Date(instantDiscountVoucher.endDate);
      $scope.instantDiscountVoucher.productsTrigger = $scope.instantDiscountVoucher.productsTrigger.map(function (product) {
        product.cip = product.cip7 || product.cip13;
        $scope.triggerProducts[product.id] = product;
        return product;
      });
      $scope.instantDiscountVoucher.productsReward = $scope.instantDiscountVoucher.productsReward.map(function (product) {
        product.cip = product.cip7 || product.cip13;
        return product;
      });
      instantDiscountVoucher.targets.forEach(function (target) {
        if (target.entity_type_id === 2) {
          $scope.targetedHealthcenters[target.morphable.id] = target.morphable;
        } else {
          $scope.targetedHealthcenters.healthcenters.forEach(function (healthcenter) {
            $scope.targetedHealthcenters[healthcenter.id] = healthcenter;
          });
        }
      });
      $scope.loading = false;
    }).then(function () {
      instantDiscountVoucherService.getPerformance(queryParameters).then(function (data) {
        $scope.reachedStepsCounts = data.reachedStepsCounts;
      });
      instantDiscountVoucherService.getDetailsByHealthcenters(queryParameters).then(function (data) {
        $scope.reachedStepsCountsByHealthcenters = data.reachedStepsCountsByHealthcenters;
      });
      instantDiscountVoucherService.getDetailsByProducts(queryParameters).then(function (data) {
        $scope.salesByProducts = data.salesByProducts;
      });
    });
    /**
     * Show sales modal for a healthcenter given by its ID.
     *
     * @param {number} healthcenterId - Healthcenter ID.
     */

    function showSales(healthcenterId) {
      $mdDialog.show({
        templateUrl: "app/main/instant-discount-voucher/views/includes/modals/sales.html",
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        controller: ["$scope", function ($scope) {
          $scope.sales = {};

          $scope.close = function () {
            $mdDialog.hide();
          };

          $scope.loading = true;
          instantDiscountVoucherService.getTriggeringSales(queryParameters, healthcenterId).then(function (data) {
            $scope.sales = data.triggeringSales;
            $scope.loading = false;
          });
        }]
      });
    }
  }
}

angular.module("app.instantDiscountVoucher").controller("InstantDiscountVoucherDetailController", InstantDiscountVoucherDetailController);