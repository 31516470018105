"use strict";
/**
 * Statistics stocks configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

StatisticsStocksConfig.$inject = ["$stateProvider"];

function StatisticsStocksConfig($stateProvider) {
  $stateProvider.state("app.statistics.stocks", {
    url: "/stocks",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/stocks/title.html",
        controller: "StatisticsController as vm"
      },
      "statisticsContent@app.statistics.stocks": {
        templateUrl: "app/main/statistics/stocks/stocks.html",
        controller: "StatsStocksController as vm"
      },
      "tabContent@app.statistics.stocks": {
        templateUrl: "app/main/statistics/stocks/stat/stat.html",
        controller: "StatisticsStatStockController as vm"
      }
    },
    bodyClass: "dashboard"
  }).state("app.statistics.stocks.stock", {
    url: "/statistiques",
    views: {
      "tabContent@app.statistics.stocks": {
        templateUrl: "app/main/statistics/stocks/views/stock/stock.html",
        controller: "StatisticsStockController as vm"
      }
    }
  }).state("app.statistics.stocks.map", {
    url: "/carte",
    views: {
      "tabContent@app.statistics.stocks": {
        templateUrl: "app/main/statistics/stocks/map/map.html",
        controller: "StatisticsMapStockController as vm"
      }
    }
  }).state("app.statistics.stocks.stat", {
    url: "/pharmacies",
    views: {
      "tabContent@app.statistics.stocks": {
        templateUrl: "app/main/statistics/stocks/stat/stat.html",
        controller: "StatisticsStatStockController as vm"
      }
    },
    params: {
      segment: null,
      id: null
    }
  }).state("app.statistics.stocks.needs", {
    url: "/stocks",
    views: {
      tabContent: {
        templateUrl: "app/main/statistics/stocks/views/reassort/stocks.html",
        controller: "stocksReassortController as vm"
      }
    }
  }).state("app.statistics.stocks.supplying", {
    url: "/appro",
    views: {
      "tabContent@app.statistics.stocks": {
        templateUrl: "app/main/statistics/stocks/supplying/supplying.html",
        controller: "SupplyingController as vm"
      }
    }
  }).state("app.statistics.stocks.inventory", {
    url: "/inventaire",
    views: {
      "tabContent@app.statistics.stocks": {
        templateUrl: "app/main/statistics/stocks/views/inventory/inventory.html",
        controller: "inventoryController"
      }
    }
  }).state("app.statistics.stocks.microDisruption", {
    url: "/micro-ruptures",
    views: {
      "tabContent@app.statistics.stocks": {
        templateUrl: "app/main/statistics/stocks/views/microDisruption/microDisruption.html",
        controller: "microDisruptionController"
      }
    }
  });
}

angular.module("app.statistics.stocks", []).config(StatisticsStocksConfig);