"use strict";
/**
 * Prestation service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

PrestationService.$inject = ["apiClient"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function PrestationService(apiClient) {
  return {
    /**
     * Generate reward teaser.
     *
     * @param {object} reward - Reward.
     *
     * @returns {string}
     */
    generateRewardTeaser: function generateRewardTeaser(reward) {
      return reward.amount + " " + reward.remuneration.label + " pour " + reward.objective + " photo(s) prise(s)";
    },

    /**
     * Get all prestations.
     *
     * @param {number} page - The number of the page to show.
     * @param {object} filter - The filter object.
     * @returns {Promise} Promise object represents the client request.
     */
    get: function get(page, filter) {
      return apiClient.get("/prestations", {
        parameters: _objectSpread({
          page: page
        }, filter),
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get all prestations by healthcenter.
     *
     * @param {number} healthcenterId - The healthcenter id.
     *
     * @returns {Promise}
     */
    getByHealthcenter: function getByHealthcenter(healthcenterId) {
      return apiClient.get("/prestation/healthcenters/".concat(healthcenterId), {
        includes: ["members"]
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create prestation.
     *
     * @param {object} prestation - Prestation.
     *
     * @returns {Promise}
     */
    create: function create(prestation) {
      return apiClient.post("/prestation", prestation, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read prestation.
     *
     * @param {number} prestationId - Prestation ID.
     *
     * @returns {Promise}
     */
    read: function read(prestationId) {
      return apiClient.get("/prestations/".concat(prestationId), {
        includes: ["rewards", "recommendations", "products.presentation", "user_prestation_photos", "participants.organization"]
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read prestation without members.
     *
     * @param {number} prestationId - Prestation ID.
     *
     * @returns {Promise}
     */
    readWithoutMembers: function readWithoutMembers(prestationId) {
      return apiClient.get("/prestation/".concat(prestationId), {
        wrappers: {
          response: null
        },
        includes: ["recommendations.file"]
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read accepted members.
     *
     * @param {number} prestationId - The ID of the prestation.
     * @param {object} pagination - The pagination container.
     *
     * @returns {Promise}
     */
    readAcceptedMembers: function readAcceptedMembers(prestationId, pagination) {
      return apiClient.get("/prestation/".concat(prestationId, "/accepted?page=").concat(pagination.page, "&limit=").concat(pagination.limit), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read refused members.
     *
     * @param {number} prestationId - The ID of the prestation.
     * @param {object} pagination - The pagination container.
     *
     * @returns {Promise}
     */
    readRefusedMembers: function readRefusedMembers(prestationId, pagination) {
      return apiClient.get("/prestation/".concat(prestationId, "/refused?page=").concat(pagination.page, "&limit=").concat(pagination.limit), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read pending members.
     *
     * @param {number} prestationId - The ID of the prestation.
     * @param {object} pagination - The pagination container.
     *
     * @returns {Promise}
     */
    readPendingMembers: function readPendingMembers(prestationId, pagination) {
      return apiClient.get("/prestation/".concat(prestationId, "/pending?page=").concat(pagination.page, "&limit=").concat(pagination.limit), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a prestation.
     *
     * @param {number} prestationId - Prestation ID.
     * @param {object} prestation - Prestation.
     *
     * @returns {Promise}
     */
    update: function update(prestationId, prestation) {
      return apiClient.put("/prestation/".concat(prestationId), prestation, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a preststion.
     *
     * @param {number} prestationId - Prestation ID.
     *
     * @returns {Promise}
     */
    "delete": function _delete(prestationId) {
      return apiClient["delete"]("/prestation/".concat(prestationId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Publish a prestation.
     *
     * @param {number} prestationId - Prestation ID.
     *
     * @returns {Promise}
     */
    publish: function publish(prestationId) {
      return apiClient.put("/prestation/".concat(prestationId, "/publish"), {}, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get all benefit photos.
     *
     * @param {number} prestationId - Prestation ID.
     *
     * @returns {Promise}
     */
    getAllBenefitPhotos: function getAllBenefitPhotos(prestationId) {
      return apiClient.get("/prestation/".concat(prestationId, "/photo"), {
        includes: ["stocks.presentation", "organization.morphable"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Accept a benefit photo.
     *
     * @param {number} photoId - Photo ID.
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    acceptBenefitPhoto: function acceptBenefitPhoto(photoId, data) {
      return apiClient.put("/prestation/photo/".concat(photoId, "/accept"), data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Refuse a benefit photo.
     *
     * @param {number} photoId - Photo ID.
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    refuseBenefitPhoto: function refuseBenefitPhoto(photoId, data) {
      return apiClient.put("/prestation/photo/".concat(photoId, "/refuse"), data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get all prestation years.
     *
     * @returns {Promise} Promise object represents the client request.
     */
    getYears: function getYears() {
      return apiClient.get("/prestations/years").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get all prestation months.
     *
     * @returns {Promise} Promise object represents the client request.
     */
    getMonths: function getMonths() {
      return apiClient.get("/prestations/months").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get all prestation statuses.
     *
     * @returns {Promise} Promise object represents the client request.
     */
    getStatuses: function getStatuses() {
      return apiClient.get("/prestations/statuses").then(function (response) {
        return response.data;
      });
    },

    /**
     * Read prestations statistic.
     *
     * @returns {Promise} Promise object represents the client request.
     */
    readStatistic: function readStatistic() {
      return apiClient.get("/prestations/statistic").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get all prestation photos.
     *
     * @param {object} filter - The filter object.
     * @returns {Promise} Promise object represents the client request.
     */
    getPhotos: function getPhotos(filter) {
      return apiClient.get("/prestations/photos", {
        parameters: filter
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get all prestation participations.
     *
     * @param {number} page - The number of the page to show.
     * @param {object} filter - The filter object.
     * @returns {Promise} Promise object represents the client request.
     */
    getParticipations: function getParticipations(page, filter) {
      return apiClient.get("/prestations/participations", {
        parameters: _objectSpread({
          page: page
        }, filter),
        includes: ["organization"]
      }).then(function (response) {
        return response;
      });
    },

    /**
     * Accept all photos of a participation.
     *
     * @param {number} id - The ID of the participation.
     * @returns {Promise} Promise object represents the client request.
     */
    acceptParticipationPhotos: function acceptParticipationPhotos(id) {
      return apiClient.patch("/prestations/participations/".concat(id, "/photos"), {
        data: {
          status: 1
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Refuse all photos of a participation.
     *
     * @param {number} id - The ID of the participation.
     * @param {string} comments - The reason for refusal.
     * @param {boolean} isNotMatching - The photo match to recommendations?
     * @returns {Promise} Promise object represents the client request.
     */
    refuseParticipationPhotos: function refuseParticipationPhotos(id, comments, isNotMatching) {
      return apiClient.patch("/prestations/participations/".concat(id, "/photos"), {
        data: {
          status: 2,
          comments: comments,
          isNotMatching: isNotMatching
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Accept all photos.
     *
     * @returns {Promise} Promise object represents the client request.
     */
    acceptPhotos: function acceptPhotos() {
      return apiClient.patch("/prestations/participations/photos", {
        data: {
          status: 1
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Refuse all photos.
     *
     * @param {string} comments - The reason for refusal.
     * @param {boolean} isNotMatching - The photo match to recommendations?
     * @returns {Promise} Promise object represents the client request.
     */
    refusePhotos: function refusePhotos(comments, isNotMatching) {
      return apiClient.patch("/prestations/participations/photos", {
        data: {
          status: 2,
          comments: comments,
          isNotMatching: isNotMatching
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get all prestation names.
     *
     * @returns {Promise} Promise object represents the client request.
     */
    getNames: function getNames() {
      return apiClient.get("/prestations/names").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get all prestations from photos.
     *
     * @param {object} filter - The filter object.
     * @returns {Promise} Promise object represents the client request.
     */
    getFromPhotos: function getFromPhotos(filter) {
      return apiClient.get("/prestations/photos/prestations", {
        parameters: filter,
        includes: ["user_prestations.organization", "user_prestations.photos.stocks.presentation"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.prestation").service("prestationService", PrestationService);