"use strict";
/**
 * Main controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $state - $state.
 * @param {object} noRedirectStates - noRedirectStates.
 * @param {object} sidenavMenuFactory - sidenavMenuFactory.
 * @param {object} bricksService - Bricks service.
 * @param {object} environmentService - Environment service.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} apodisThemingFactory - apodisThemingFactory.
 * @param {object} accessService - Access service.
 * @param {object} authService - Auth service.
 */

MainController.$inject = ["$scope", "$rootScope", "$state", "noRedirectStates", "sidenavMenuFactory", "bricksService", "environmentService", "$mdDialog", "apodisThemingFactory", "accessService", "authService"];

function MainController($scope, $rootScope, $state, noRedirectStates, sidenavMenuFactory, bricksService, environmentService, $mdDialog, apodisThemingFactory, accessService, authService) {
  $scope.currentState = $state.current.name;
  $scope.cguModalOpen = false;
  /**
   * Loader.
   */

  function loader() {
    if (authService.isAuthenticated()) {
      loadAccess();
    } else {
      apodisThemingFactory.generateDefault();
    }
  }
  /**
   * Load access.
   *
   * @returns {Promise}
   */


  function loadAccess() {
    return environmentService.getEnvironment().then(function (environment) {
      apodisThemingFactory.generateDefault(environment);
      $scope.currentEnv = environment;
      $scope.hasCapContract = hasCapContract(environment);
      return environment;
    }).then(function (environment) {
      bricksService.getBricks().then(function (bricks) {
        var options = angular.fromJson(window.sessionStorage.getItem("payload")).options;
        $rootScope.hasBricksMenus = options.indexOf("bricks-menus") > -1;
        $rootScope.hasDelegateBricksMenus = options.indexOf("bricks-menus") > -1 && $scope.environment.accessPointType === 1 && $scope.environment.rankId === 15;

        if (bricks.length !== 0) {
          if ($scope.hasBricksMenus && $state.current.name === "app.home") {
            $rootScope.showBricksMenu = true;
            $rootScope.showLeftMenu = false;
          } else if (noRedirectStates.indexOf($scope.currentState) === -1) {
            switch ($scope.currentState) {
              case "app.filemanager":
                $rootScope.showBricksMenu = false;
                $rootScope.showLeftMenu = false;
                $rootScope.showLeftMenuToggle = !$scope.hasBricksMenus;
                break;

              default:
                $rootScope.showBricksMenu = false;
                $rootScope.showLeftMenu = true;
                break;
            }
          }
        } else {
          $scope.noBricks = true;
        }
      });
      return environment;
    });
  }
  /**
   * hasCapContract
   * to check if entity has its own property
   * wholesalerId
   *
   * @param {object} currentEnv - current environment
   *
   * @returns {boolean}
   */


  function hasCapContract(currentEnv) {
    return currentEnv.entity.wholesalerId;
  }
  /**
   * switchStateFromMenuHome
   *
   * @param {object} event - event
   * @param {object} state - state
   */


  $scope.$on("switchStateFromMenuHome", function (event, state) {
    $scope.menuItems = sidenavMenuFactory.menuGroupItems;
    $scope.itemActive = state;
    $state.go(state);
  });
  /**
   * accessService:switchEnv
   */

  $rootScope.$on("EnvironmentService.switchEnvironment:done", function () {
    if ($state.current.url !== "/identification") {
      loader();
    }
  });
  /**
   * $viewContentAnimationEnded
   *
   * @param {object} event - event
   */

  $scope.$on("$viewContentAnimationEnded", function (event) {
    if (event.targetScope.$id === $scope.$id) {
      $rootScope.$broadcast("msSplashScreen::remove");
      $scope.currentState = $state.current.name;

      if ($state.current.url !== "/identification") {
        loader();
      }
    }
  });
  $rootScope.$on("goHome", function () {
    $rootScope.showBricksMenu = true;
    $state.go("app.home", {}, {
      reload: true
    });
  });
  /**
   * $stateChangeSuccess
   *
   * @param {object} event - event
   * @param {object} toState - toState
   */

  $rootScope.$on("$stateChangeSuccess", function (event, toState) {
    $scope.currentState = toState.name;
  });
  /**
   * $rootScope.$on
   * sideNavConstruct
   * createMenu
   */

  $rootScope.$on("sideNavConstruct", function () {
    $rootScope.showBricksMenu = false;
    $rootScope.showLeftMenu = true;
  });

  $scope.mustAcceptCGU = function (userAccess) {
    if ($scope.cguModalOpen === false) {
      $scope.cguModalOpen = true;
      $mdDialog.show({
        templateUrl: "app/main/termsOfService/acceptTermsOfService.tmpl.html",
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        locals: {
          userAccess: userAccess
        },
        controller: ["$window", "userAccess", function ($window, userAccess) {
          // eslint-disable-next-line no-invalid-this
          var vm = this;
          vm.cguModalOpen = true;

          vm.openPdf = function () {
            if (userAccess.rankId !== 2 && userAccess.rankId !== 1) {
              $window.open("../assets/files/cgu_employee.pdf");
            } else {
              $window.open("../assets/files/cgu_pharmacist.pdf");
            }
          };

          vm.acceptCgu = function () {
            accessService.acceptCgu().then(function () {
              $mdDialog.hide();
            });
          };
        }],
        controllerAs: "vm"
      });
    }
  };
}

angular.module("apodis").controller("MainController", MainController);