"use strict";
/**
 * Category service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

CategoryService.$inject = ["apiClient"];

function CategoryService(apiClient) {
  return {
    /**
     * Get.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.get("/laboratories/categories/".concat(params.type), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * State.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    state: function state(params) {
      return apiClient.get("/laboratories/categories/".concat(params.type, "/state"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/laboratories/categories/".concat(params.type), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.category").service("categoryService", CategoryService);