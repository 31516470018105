"use strict";
/**
 * Notification callback service.
 *
 * @param {object} $state - $state.
 *
 * @returns {Function}
 */

NotificationCallbackService.$inject = ["$state"];

function NotificationCallbackService($state) {
  return function (url) {
    var tmp = url.slug.split(".");
    var type = tmp[0];
    var value = tmp[1] || null;

    switch (type) {
      case "prestation":
        $state.go("app.UserPrestationDetail", {
          id: value
        });
        break;

      case "sellout":
        $state.go("app.UserSelloutHome.detail", {
          id: value
        });
        break;

      case "calendar":
        $state.go("app.calendarEvent", {
          id: value
        });
        break;

      case "groupmentManager":
        $state.go("app.teammanager.members");
        break;

      case "healthcenter":
        $state.go("app.healthcenters_detail", {
          id: value
        });
        break;
    }
  };
}

angular.module("apodis").factory("notificationCallbackService", NotificationCallbackService);