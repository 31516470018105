"use strict";
/**
 * Register pharmedicom controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} authService - Auth service.
 * @param {object} $mdToast - $mdToast.
 * @param {object} authUserService - Auth user service.
 * @param {object} $location - $location.
 * @param {object} authHcAccessService - Auth healthcenter access service.
 * @param {object} authLgoService - Auth LGO service.
 * @param {object} $window - $window.
 * @param {object} $filter - $filter.
 */

RegisterPharmedicomController.$inject = ["$scope", "authService", "$mdToast", "authUserService", "$location", "authHcAccessService", "authLgoService", "$window", "$filter"];

function RegisterPharmedicomController($scope, authService, $mdToast, authUserService, $location, authHcAccessService, authLgoService, $window, $filter) {
  $scope.passwordComparison = passwordComparison;
  $scope.register = register;
  $scope.isFormOk = isFormOk;
  $scope.backToAuth = backToAuth;
  $scope.validateLgo = validateLgo;
  var groupDefault = [{
    id: "none",
    name: "Indépendant"
  }, {
    id: 0,
    name: "Autre groupement"
  }];
  loader();
  /**
   * Validate LGO.
   *
   * @param {number} lgo - LGO ID.
   */

  function validateLgo(lgo) {
    if (lgo !== 2) {
      showCustomToast();
    }
  }
  /**
   * Format user from url
   *
   * @param {object} search - Search.
   *
   * @returns {object}
   */


  function formatUser(search) {
    var params = {
      cip: search.cip
    };
    return authHcAccessService.getHealthcenterByCip(params).then(function (pharma) {
      return {
        name: search.name,
        firstName: search.firstName,
        email: search.email,
        pharmaCip: search.cip,
        pharma: pharma,
        password: "",
        parrainage: "pharmedicom",
        type: "pharmacist",
        access: {
          accessPointType: 2,
          entityId: pharma.id,
          rankId: 1,
          operatorCode: null,
          active: 1,
          name: pharma.name
        }
      };
    });
  }
  /**
   * loader
   * to init each method inside
   * on init controller
   */


  function loader() {
    authService.destroyCredentials();
    formatUser($location.search()).then(function (user) {
      $scope.user = user;
      getLgoList();
      loadGroups();
    });
  }
  /**
   * passwordComparison
   * to compare passwords inputs
   *
   * @param {string} password - Password.
   *
   * @returns {boolean}
   */


  function passwordComparison(password) {
    if ($scope.user) {
      $scope.alertPasswordLength = $scope.user.password ? $scope.user.password.length < 6 : false;
      return Boolean(password && $scope.user.password && !$scope.user.password.localeCompare(password));
    }

    return false;
  }
  /**
   * Register an user and login.
   */


  function register() {
    if (isFormOk()) {
      var params = {
        email: $scope.user.email,
        name: $scope.user.name,
        firstName: $scope.user.firstName,
        pwd: $scope.user.password,
        password: $scope.user.password,
        pharmaCip: $scope.user.cip,
        pharma: $scope.user.pharma,
        lgo: $scope.user.lgo,
        groupmentId: $scope.user.groupmentId,
        parrainage: null,
        type: "pharmacist",
        access: {
          accessPointType: 2,
          entityId: $scope.user.pharma.id,
          rankId: 1,
          operatorCode: null,
          active: 1,
          name: $scope.user.pharma.name
        }
      };
      authUserService.register(params);
    }
  }
  /**
   * Retrieve lgo list
   */


  function getLgoList() {
    authLgoService.get().then(function (response) {
      $scope.lgos = response.lgo;
    });
  }
  /**
   * Retrieve groups
   */


  function loadGroups() {
    authHcAccessService.searchGroupsHc().then(function (response) {
      $scope.groupmentList = groupDefault.concat($filter("orderBy")(response.groups, "name"));
    });
  }
  /**
   * Is the registration form valid.
   *
   * @returns {boolean}
   */


  function isFormOk() {
    return !$scope.user || $scope.user.email && passwordComparison($scope.passwordRepeat) && $scope.user.name && $scope.user.firstName;
  }
  /**
   * backToAuth
   * go to authenticate $state
   * with logout to prevent
   * credentials into localSotrage
   */


  function backToAuth() {
    authService.logout();
  }

  $scope.helpUrl = "../assets/files/install-apodis-pharmacist.pdf";
  /**
   * Show custom toast.
   */

  function showCustomToast() {
    $scope.isDlgOpen = true;
    $mdToast.show({
      hideDelay: 0,
      position: "top left",
      templateUrl: "app/main/auth/views/register/help.html",
      locals: {
        url: $scope.helpUrl
      },
      controller: ["$scope", "url", function controller($scope, url) {
        $scope.openGuide = function () {
          $window.open(url);
        };

        $scope.closeToast = function () {
          $mdToast.hide();
        };
      }]
    });
  }
}

angular.module("app.auth").controller("registerPharmedicomController", RegisterPharmedicomController);