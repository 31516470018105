"use strict";

angular.module("apodis").directive("notificationSlug", ["NotificationService", function (NotificationService) {
  // TODO : faire le template html
  return {
    restrict: "AEC",
    scope: {
      notificationSlug: "@",
      notificationCallback: "="
    },
    template: "<md-menu md-position-mode=\"left bottom\" ng-if=\"nb\" >" + "<md-button class=\"user-button notification-button\" ng-click=\"$mdOpenMenu()\" aria-label=\"Notifications\">" + "<div layout=\"row\" layout-align=\"space-between center\">" + "<md-icon ng-if=\"nb\"  md-font-icon=\"icon-bell-outline\" class=\"icon s24 md-teal md-accent-primary\" hide-xs></md-icon>" + "<span class=\"badge white-fg\" style=\"background-color: red;font-size:12px; border-radius:50%;line-height:16px;text-align:center;width:16px;height:16px;display:inline-block;\">{{nb}}</span>" + "</div>" + "<md-tooltip md-direction=\"bottom\">Notifications</md-tooltip>" + "</md-button>" + "<md-menu-content width=\"3\" class=\"notificationsMenuContent\">" + "<md-menu-item class=\"md-indent\" ng-repeat=\"item in list\" ng-click=\"read(item)\">" + "<md-button>{{item.title}}</md-button>" + "</md-menu-item>" + "<md-menu-item class=\"md-indent md-accent-bg\" ng-click=\"deleteall()\"><md-button class=\"deleteAll\" >Marquer comme lues<md-icon  md-font-icon=\"icon-close\" " + "class=\"icon s16 md-accent-primary\">" + "</md-icon></md-button>" + "</md-menu-item>" + "</md-menu-content>" + "</md-menu>",
    link: function link(scope) {
      scope.nb = 0;
      scope.list = []; // delete

      scope.read = function (item) {
        NotificationService.read(item, scope.notificationCallback);
      };

      scope.deleteall = function () {
        NotificationService.deleteall();
      };

      scope.$watch(function () {
        return NotificationService.get();
      }, function () {
        var notif = NotificationService.find(scope.notificationSlug);
        scope.nb = notif.length;
        scope.list = notif;
      });
    }
  };
}]);