"use strict";
/**
 * Purchases from wholesaler controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} groupingDashboardCapService - Grouping dashboard cap service.
 */

PurchasesFromWholesalerController.$inject = ["$scope", "groupingDashboardCapService"];

function PurchasesFromWholesalerController($scope, groupingDashboardCapService) {
  $scope.capDashboardLoading = true;
  $scope.purchasesByProductTypeLoading = true;
  $scope.purchasesGenericOrPrincepsLoading = true;
  $scope.purchasesFromWholesalerLoading = true;
  $scope.filterClicked = null;
  $scope.query = {
    order: "-totalPriceAfterDiscount",
    limit: 50,
    limitOptions: [10, 25, 50, 100, 200, 500],
    page: 1
  };
  var firstDayOfYear = new Date();
  firstDayOfYear.setDate(1);
  firstDayOfYear.setMonth(0);
  $scope.quantityOfPurchases = {};
  $scope.periodsOfPurchase = [];
  $scope.salesSourceFilters = [{
    slug: "sagitta",
    name: "Sagitta"
  }, {
    slug: "courtier",
    name: "Courtier"
  }];
  $scope.salesProductTypeFilters = [{
    slug: "princeps",
    name: "Princeps"
  }, {
    slug: "generics",
    name: "Génériques"
  }];
  $scope.salesSourceFiltersEvent = "salesSourceFiltersEvent";
  $scope.salesProductTypeFiltersEvent = "salesProductTypeFiltersEvent";
  $scope.dashboardGroupCap = {
    startDate: firstDayOfYear,
    endDate: new Date()
  };
  $scope.loader = loader;
  $scope.getPurchasesFromWholesaler = getPurchasesFromWholesaler;
  $scope.showAll = showAll;
  $scope.regroupingChoices = [{
    slug: "laboratory",
    label: "Laboratoire"
  }, {
    slug: "healthcenter",
    label: "Pharmacie"
  }, {
    slug: "order",
    label: "Commande"
  }, {
    slug: "product",
    label: "Produit"
  }];
  $scope.statisticPeriods = [{
    slug: "year",
    label: "An"
  }, {
    slug: "month",
    label: "Mois"
  }];
  $scope.regrouping = {
    period: $scope.statisticPeriods[0],
    firstChoice: $scope.regroupingChoices[1],
    secondChoice: $scope.regroupingChoices[3]
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    $scope.capDashboardLoading = true;

    if ($scope.dashboardGroupCap.startDate && $scope.dashboardGroupCap.endDate) {
      getPurchasesByProductType();
      getPurchasesByGenericOrPrinceps();
      getPurchasesFromWholesaler();
    }
  }
  /**
   * Show all the purchases.
   */


  function showAll() {
    $scope.purchasesFromWholesaler = $scope.purchasesFromWholesalerSaved;
    $scope.currentFilterOn = $scope.regrouping.firstChoice.slug;
    $scope.filterClicked = null;
  }
  /**
   * Get purchases by product type.
   *
   * @returns {Promise} The promise
   */


  function getPurchasesByProductType() {
    $scope.purchasesByProductTypeLoading = true;
    return groupingDashboardCapService.getPurchasesByType($scope.dashboardGroupCap).then(function (purchasesByType) {
      $scope.purchasesByType = purchasesByType;
      $scope.purchasesByProductTypeLoading = false;
    });
  }
  /**
   * Get purchases by generic or princeps.
   *
   * @returns {Promise} The promise
   */


  function getPurchasesByGenericOrPrinceps() {
    $scope.purchasesGenericOrPrincepsLoading = true;
    return groupingDashboardCapService.getPurchasesByGenericOrPrinceps($scope.dashboardGroupCap).then(function (data) {
      $scope.purchasesByGenericOrPrinceps = data.purchases;
      $scope.genericOrPrinceps = data.genericOrPrinceps;
      $scope.purchasesGenericOrPrincepsLoading = false;
    });
  }
  /**
   * Get purchases from wholesaler
   *
   * @param {object} purchase - The purchase element, or undefined
   *
   * @returns {Promise} The promise
   */


  function getPurchasesFromWholesaler(purchase) {
    $scope.purchasesFromWholesalerLoading = true;
    var searchFor = $scope.dashboardGroupCap; // Set default regrouping column

    searchFor.regroupingColumn = $scope.regroupingChoices[1];
    searchFor.regroupingPeriod = $scope.regrouping.period.slug;

    if (typeof purchase !== "undefined") {
      searchFor.regroupingColumn = $scope.regrouping.secondChoice.slug;

      if (purchase.productId) {
        searchFor.productId = purchase.productId;
        $scope.filterClicked = "pour le produit \"" + purchase.productName + "\"";
      } else if (purchase.orderId) {
        searchFor.orderId = purchase.orderId;
        $scope.filterClicked = "pour la commande n°" + purchase.orderId;
      } else if (purchase.laboratoryId) {
        searchFor.laboratoryId = purchase.laboratoryId;
        $scope.filterClicked = "pour le laboratoire \"" + purchase.laboratoryName + "\"";
      } else {
        searchFor.healthcenterId = purchase.healthcenterId;
        $scope.filterClicked = "pour la pharmacie \"" + purchase.healthcenterName + "\"";
      }
    } else {
      searchFor.regroupingColumn = $scope.regrouping.firstChoice.slug;
      $scope.filterClicked = null;
    }

    $scope.currentFilterOn = searchFor.regroupingColumn;
    return groupingDashboardCapService.getPurchasesFromWholesaler(searchFor).then(function (purchases) {
      $scope.quantityOfPurchases = {};
      $scope.periodsOfPurchase = [];
      var formattedPurchases = [];
      var periods = [];
      angular.forEach(purchases, function (purchaseOfType, purchaseType) {
        $scope.quantityOfPurchases[purchaseType] = 0;
        formattedPurchases[purchaseType] = [];
        angular.forEach(purchaseOfType, function (purchase) {
          $scope.quantityOfPurchases[purchaseType]++; // For the order regrouping type, add an formatted date for order by

          if (searchFor.regroupingColumn === "order") {
            purchase.orderDateEnglish = purchase.orderDate.substring(6, 10) + "-" + purchase.orderDate.substring(3, 5) + "-" + purchase.orderDate.substring(0, 2);
          } // Test periods of purchase


          if (purchase.purchasesByPeriod) {
            periods = Object.keys(purchase.purchasesByPeriod);

            if (periods.length > 0) {
              for (var counter = 0; counter < periods.length; counter++) {
                if ($scope.periodsOfPurchase.indexOf(periods[counter]) === -1 && periods[counter].trim() !== "") {
                  $scope.periodsOfPurchase.push(periods[counter]);
                }
              }
            }
          }

          formattedPurchases[purchaseType].push(purchase);
        });
      }); // If no period, set a default one

      if ($scope.periodsOfPurchase.length === 0) {
        $scope.periodsOfPurchase = [""];
      }

      $scope.purchasesFromWholesaler = formattedPurchases;
      $scope.capDashboardLoading = false;
      $scope.purchasesFromWholesalerLoading = false;

      if (typeof purchase === "undefined") {
        $scope.purchasesFromWholesalerSaved = formattedPurchases;
      }
    });
  }
  /**
   * Event on sales source filter
   *
   * @param {object} event - The event
   * @param {object} data - The data
   */


  $scope.$on($scope.salesSourceFiltersEvent, function (event, data) {
    event.preventDefault();
    $scope.dashboardGroupCap.salesSourceFilter = data;
  });
  /**
   * Event on sales product type filter
   *
   * @param {object} event - The event
   * @param {object} data - The data
   */

  $scope.$on($scope.salesProductTypeFiltersEvent, function (event, data) {
    event.preventDefault();
    $scope.dashboardGroupCap.salesProductTypeFilter = data;
  });
}

angular.module("app.dashboard").controller("purchasesFromWholesalerController", PurchasesFromWholesalerController);