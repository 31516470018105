"use strict";
/**
 * grouping members purchases controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} bricksService - Bricks service.
 * @param {object} environmentService - Environment service.
 */

GroupingMembersPurchasesController.$inject = ["$scope", "$state", "bricksService", "environmentService"];

function GroupingMembersPurchasesController($scope, $state, bricksService, environmentService) {
  $scope.goToGuests = goToGuests;
  $scope.goToTeams = goToTeams;
  $scope.openTab = openTab;
  $scope.tabs = [{
    id: 1,
    name: "Achats CAP",
    slug: "dashboardCap",
    isActive: true
  }, {
    id: 2,
    name: "Dashboard Princeps / Génériques",
    slug: "dashboardSagittaCourtier",
    isActive: true
  }, {
    id: 3,
    name: "Dashboard Apodis",
    slug: "dashboardApodis",
    isActive: true
  }];
  loader();
  /**
   * Loader.
   */

  function loader() {
    loaderCurrentBrick();
    loaderCurrentEnv();
  }
  /**
   * Open tab.
   *
   * @param {object} currentTab - Current tab.
   */


  function openTab(currentTab) {
    var dashboardGroupingMaCap = document.getElementById("grouping-ma-cap");

    if (!dashboardGroupingMaCap) {
      // todo this controller is executed on each grouping and healthcenter dashboard
      return;
    }

    var tabs = document.getElementById("grouping-ma-cap").getElementsByClassName("tab");

    for (var index = 0; index < tabs.length; index++) {
      tabs[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (currentTab.id === tab.id) {
        currentTab.active = true;
        var currentTabSelected = document.getElementById(currentTab.slug);

        if (currentTabSelected) {
          currentTabSelected.style.display = "block";
        }

        if (tab.method) {
          tab.method();
        }
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Load current environment.
   */


  function loaderCurrentEnv() {
    environmentService.getEnvironment().then(function (environment) {
      $scope.currentEnv = environment;
    });
  }
  /**
   * Load current brick.
   */


  function loaderCurrentBrick() {
    bricksService.getCurrentBrick().then(function (brick) {
      $scope.brick = brick;
      setTimeout(function () {
        openTab($scope.tabs[0]);
      }, 2000);
    });
  }
  /**
   * Go to guests.
   */


  function goToGuests() {
    $state.go("app.guest");
  }
  /**
   * Go to teams.
   */


  function goToTeams() {
    $state.go("app.entityAdmin");
  }
}

angular.module("app.grouping").controller("groupingMembersPurchasesController", GroupingMembersPurchasesController);