"use strict";
/**
 * Sellin challenge edit controller.
 *
 * @param {object} $scope - Controller scope.
 * @param {object} $state - Module state.
 * @param {object} $stateParams - State parameters.
 * @param {object} $rootScope - Root scope.
 * @param {object} $mdDialog - Material design dialog.
 * @param {object} sellinChallengeService - Sellin challenge service.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} environmentService - Environment service.
 * @param {object} formatSellinChallenge - Sellin challenge formatter.
 * @param {object} notifyUserService - User notification service.
 * @param {object} healthcenterService - Healthcenter service.
 * @param {object} productService - Product service.
 * @param {object} sessionStorageService - Session storage service.
 */

SellinChallengeEditController.$inject = ["$scope", "$state", "$stateParams", "$rootScope", "$mdDialog", "sellinChallengeService", "laboratoriesService", "environmentService", "formatSellinChallenge", "notifyUserService", "healthcenterService", "productService", "sessionStorageService"];

function SellinChallengeEditController($scope, $state, $stateParams, $rootScope, $mdDialog, sellinChallengeService, laboratoriesService, environmentService, formatSellinChallenge, notifyUserService, healthcenterService, productService, sessionStorageService) {
  $scope.token = sessionStorageService.getToken();
  $scope.select = true;
  $scope.laboratory = {
    id: 0,
    name: ""
  };
  $scope.wholesalerId = null;
  $scope.createLoading = false;
  $scope.limitOptions = [25, 50, 100, 250];
  $scope.pagination = {
    current_page: 1,
    per_page: 25
  }; // Laboratory search config

  $scope.laboratorySearchConfig = {
    search: ["laboratory.id"],
    event: "addLaboratorySellinChallenge",
    tag: "laboratorySellinChallenge",
    limits: {
      laboratory: 1
    }
  }; // Product search config

  $scope.productSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    event: "addProductsInSellinChallenge",
    tag: "configProductSellinChallenge",
    autoLaunchEvent: true,
    must: {
      "laboratory.id": []
    }
  }; // Target search config

  $scope.targetSearchConfig = {
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id", "standaloneGrouping"],
    event: "addTargetsInSellinChallenge",
    tag: "target",
    autoLaunchEvent: true
  }; // Durations

  $scope.durations = [{
    id: 1,
    name: "1 mois",
    number: 1,
    type: "months"
  }, {
    id: 2,
    name: "2 mois",
    number: 2,
    type: "months"
  }, {
    id: 3,
    name: "3 mois",
    number: 3,
    type: "months"
  }, {
    id: 4,
    name: "6 mois",
    number: 6,
    type: "months"
  }, {
    id: 5,
    name: "1 an",
    number: 1,
    type: "years"
  }, {
    id: 6,
    name: "Permanent",
    number: 50,
    type: "years"
  }];
  $scope.sellinChallengeTypes = [{
    id: 1,
    name: "Objectif d'achat par palier avec rattrapage de remise pour atteinte d'objectif quantitatif",
    description: "Ceci est la description de l'Objectif d'achat par palier avec rattrapage de remise pour atteinte d'objectif quantitatif",
    image: "assets/images/sellin-challenge/step-discount-catchup.svg",
    imageWhite: "assets/images/sellin-challenge/step-discount-catchup-white.svg"
  }, {
    id: 2,
    name: "Ouverture de marché",
    description: "Ceci est la description de l'Ouverture de marché",
    image: "/assets/images/sellin-challenge/market-opening.svg",
    imageWhite: "assets/images/sellin-challenge/market-opening-white.svg"
  }, {
    id: 3,
    name: "Remises par paliers à la ligne ou panachées",
    description: "Ceci est la description de la remise par paliers à la ligne ou panachées",
    image: "/assets/images/sellin-challenge/step-discount.svg",
    imageWhite: "assets/images/sellin-challenge/step-discount-white.svg"
  }];
  $scope.stepTypes = [{
    id: 1,
    name: "Quantité à atteindre"
  }, {
    id: 2,
    name: "Montant d'achat"
  }];
  $scope.stepDiscountTypes = [{
    id: 1,
    name: "Remplace la remise de base"
  }, {
    id: 2,
    name: "S'additionne à la remise de base"
  }]; // SellinChallenge

  $scope.sellinChallenge = {
    name: "",
    description: "",
    wholesalerId: null,
    type: null,
    file: {},
    isAllTargetAllowed: false,
    isForApodis: false,
    isForWholesalers: true,
    isPerPharmacy: true,
    isDiscountedByStepProduct: false,
    hasMinimumQuantityByProductGroup: false,
    startDate: "",
    limitActivationDate: "",
    endDate: "",
    products: [],
    targets: [],
    steps: [],
    groups: [],
    duration: null
  };

  if ($stateParams.wholesalerId) {
    $scope.sellinChallenge.wholesalerId = $stateParams.wholesalerId;
  }

  $scope.goToList = goToList;
  $scope.isValidStep1 = false;
  $scope.isValidStep2 = false;
  $scope.isValidStep3 = false;
  $scope.isValidStep4 = false;
  $scope.sellinChallengeId = $stateParams.id;
  $scope.getSellinChallenge = getSellinChallenge;
  $scope.createSellinChallenge = createSellinChallenge;
  $scope.updateSellinChallenge = updateSellinChallenge;
  $scope.addStep = addStep;
  $scope.removeStep = removeStep;
  $scope.resetStepForm = resetStepForm;
  $scope.setDefaultPeriod = setDefaultPeriod;
  $scope.calculateEndDate = calculateEndDate;
  $scope.validType = validType;
  $scope.products = [];
  $scope.removeProduct = removeProduct; // Targets

  $scope.targets = [];
  $scope.removeTarget = removeTarget;
  $scope.targetDatatable = {
    limit: 10,
    page: 1
  };
  $scope.submit = submit;
  $scope.createProductGroup = createProductGroup;
  $scope.cancelProductGroupCreation = cancelProductGroupCreation;
  $scope.submitProductGroup = submitProductGroup;
  loader();
  /** Initialize */

  function loader() {
    resetStepForm();
    loaderEnvironment();
  }
  /**
   * Load environment.
   *
   * @returns {Promise} Environment promise.
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.access = access;

      if ($scope.access.accessPointType === 1) {
        $scope.productSearchConfig.must = {
          "laboratory.id": [access.entityId]
        };
      } // Filter by wholesaler if necessary


      if ($stateParams.wholesalerId) {
        $scope.productSearchConfig.must["wholesaler.id"] = [$stateParams.wholesalerId];
        $scope.wholesalerId = $stateParams.wholesalerId;
      }

      $scope.getSellinChallenge();
      return access;
    });
  }
  /** Get SellinChallenge. */


  function getSellinChallenge() {
    if ($stateParams.id) {
      var sellinChallenge = {
        id: $stateParams.id
      };
      sellinChallengeService.read(sellinChallenge).then(function (response) {
        $scope.sellinChallenge = response; // Format dates and sellin challenge type

        $scope.sellinChallenge.startDate = new Date(response.startDate);
        $scope.sellinChallenge.limitActivationDate = new Date(response.limitActivationDate);
        $scope.sellinChallenge.endDate = new Date(response.endDate);
        $scope.sellinChallenge.sellinChallengeType = $scope.sellinChallengeTypes.find(function (element) {
          return element.id === response.type.id;
        }); // Format the products collection

        $scope.sellinChallenge.products = response.products.filter(function (element) {
          return element.product;
        }).map(function (sellinChallengeProduct) {
          return {
            id: sellinChallengeProduct.productId,
            name: sellinChallengeProduct.product.name,
            requestedQuantity: sellinChallengeProduct.requestedQuantity,
            cip: sellinChallengeProduct.product.code,
            sellinChallengeProductId: sellinChallengeProduct.id
          };
        }); // Format the targets collection

        $scope.sellinChallenge.targets = response.targets.map(function (sellinChallengeTarget) {
          return {
            organizationId: sellinChallengeTarget.organizationId,
            name: sellinChallengeTarget.target.morphable.name,
            type: sellinChallengeTarget.target.morphable_type,
            cip: sellinChallengeTarget.target.morphable_type === "healthcenter" ? sellinChallengeTarget.target.morphable.cip : ""
          };
        }); // Format the steps collection if needed

        if ($scope.sellinChallenge.isDiscountedByStepProduct) {
          var correspondingProducts;
          var productId;
          angular.forEach($scope.sellinChallenge.steps, function (step) {
            // If there are step products
            if (step.stepProducts) {
              step.minimumQuantityByProduct = {};
              step.discountByProduct = {};
              angular.forEach(step.stepProducts, function (stepProduct) {
                // Find the product by his sellin challenge product ID
                correspondingProducts = $scope.sellinChallenge.products.filter(function (element) {
                  return element.sellinChallengeProductId === stepProduct.productId;
                }); // If at least one product is found, get its ID

                if (correspondingProducts.length > 0) {
                  productId = correspondingProducts[0].id;
                  step.minimumQuantityByProduct[productId] = stepProduct.requestedQuantity ? stepProduct.requestedQuantity : "";
                  step.discountByProduct[productId] = stepProduct.discountPercentage ? parseFloat(stepProduct.discountPercentage) : "";
                }
              });
            } // If there are step groups


            if (step.stepGroups) {
              step.minimumQuantityByGroup = {};
              angular.forEach(step.stepGroups, function (stepGroup) {
                step.minimumQuantityByGroup[stepGroup.groupId] = stepGroup.requestedQuantity ? stepGroup.requestedQuantity : "";
              });
            }
          });
        } // Filter on wholesaler if defined


        if (response.wholesalerId) {
          $scope.productSearchConfig.must["wholesaler.id"] = [response.wholesalerId];
        } // Associate the sellin challenge products with groups if necessary


        if ($scope.sellinChallenge.hasMinimumQuantityByProductGroup) {
          angular.forEach($scope.sellinChallenge.groups, function (group) {
            angular.forEach(group.products, function (productGroup) {
              angular.forEach($scope.sellinChallenge.products, function (product) {
                if (productGroup.sellinChallengeProductId === product.sellinChallengeProductId) {
                  product.groupId = group.id;
                }
              });
            });
          });
        } // Apply filter on laboratory if owner is not a laboratory


        if (response.organization.morphable_type !== "laboratory") {
          $scope.laboratory = {
            id: response.laboratory.id,
            name: response.laboratory.name
          };
          $scope.productSearchConfig.must = {
            "laboratory.id": [response.laboratory.id]
          };
        }

        return $scope.sellinChallenge.laboratory;
      }).then(function (laboratory) {
        getRelatedLaboratoryIds(laboratory).then(function (response) {
          $scope.productSearchConfig.must = {
            "laboratory.id": response
          };
        });
      });
    } else if ($stateParams.name) {
      // If the name and other parameters has been given
      $scope.laboratory = {
        id: $stateParams.laboratoryId,
        name: ""
      };
      $scope.sellinChallenge = {
        name: $stateParams.name,
        description: $stateParams.description,
        wholesalerId: null,
        startDate: new Date(),
        type: $scope.sellinChallengeTypes[0],
        file: {},
        isAllTargetAllowed: false,
        isForApodis: false,
        isForWholesalers: true,
        isDiscountedByStepProduct: false,
        hasMinimumQuantityByProductGroup: false,
        products: $stateParams.products,
        groups: [],
        targets: $stateParams.targets,
        steps: []
      };

      if ($stateParams.wholesalerId) {
        $scope.sellinChallenge.wholesalerId = $stateParams.wholesalerId;
      }
    }
  }
  /**
   * Get related laboratory ids.
   *
   * @param {object} laboratory - The laboratory
   *
   * @returns {Array} Array of Ids
   */


  function getRelatedLaboratoryIds(laboratory) {
    return laboratoriesService.getRelatedLaboratoryIds(laboratory).then(function (response) {
      return response.map(function (element) {
        return element.id;
      });
    });
  }
  /**
   * Submit sellinChallenge.
   *
   * @param {boolean} toBePublished - If the sellinChallenge is auto published.
   *
   * @returns {Promise} The promise
   */


  function submit(toBePublished) {
    toBePublished ? $scope.sellinChallenge.toBePublished = toBePublished : $scope.sellinChallenge.toBePublished = false;
    return formatSellinChallenge.prototype.build($scope.sellinChallenge).then(function (sellinChallenge) {
      sellinChallenge.organizationId = $scope.access.organizationId;
      sellinChallenge.laboratoryId = $scope.access.accessPointType !== 1 ? $scope.laboratory.id : $scope.access.entityId;
      return sellinChallenge;
    }).then(function (sellinChallenge) {
      !$scope.sellinChallengeId ? createSellinChallenge(sellinChallenge) : updateSellinChallenge(sellinChallenge);
      return sellinChallenge;
    });
  }
  /**
   * Create sellinChallenge.
   *
   * @param {object} sellinChallenge - The sellin challenge
   *
   * @returns {Promise} The promise
   */


  function createSellinChallenge(sellinChallenge) {
    $scope.createLoading = true;
    return sellinChallengeService.create(sellinChallenge).then(function (response) {
      $scope.createLoading = false;
      notifyUserService.showSimpleToast("Le challenge sell-in est ajouté", {
        color: "success",
        hideDelay: 1000
      });
      goToList();
      return response;
    });
  }
  /**
   * Update sellinChallenge.
   *
   * @param {object} sellinChallenge - The sellin challenge
   *
   * @returns {Promise} The promise
   */


  function updateSellinChallenge(sellinChallenge) {
    $scope.createLoading = true;
    sellinChallenge.id = $stateParams.id;
    return sellinChallengeService.update(sellinChallenge).then(function (response) {
      notifyUserService.showSimpleToast("Le challenge sell-in est modifié", {
        color: "success",
        hideDelay: 1000
      });
      goToList();
      return response;
    });
  }
  /**
   * Remove product from selection.
   *
   * @param {number} productId - The id of the product to remove.
   */


  function removeProduct(productId) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression du produit ?").ariaLabel("Suppression du produit").ok("Oui").cancel("Non")).then(function () {
      $scope.sellinChallenge.products = $scope.sellinChallenge.products.filter(function (product) {
        return product.id !== productId;
      });
      notifyUserService.showSimpleToast("Le produit a été supprimé.", {
        color: "success",
        hideDelay: 1000
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Remove target from selection.
   *
   * @param {number} targetId - The id of the target to remove.
   */


  function removeTarget(targetId) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression de la cible ?").ariaLabel("Suppression de la cible").ok("Oui").cancel("Non")).then(function () {
      $scope.sellinChallenge.targets = $scope.sellinChallenge.targets.filter(function (target) {
        return target.id !== targetId;
      });
      notifyUserService.showSimpleToast("La cible a été supprimée.", {
        color: "success",
        hideDelay: 1000
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Add the step to the steps array
   */


  function addStep() {
    var step = {
      type: $scope.step.type,
      stepValue: $scope.step.stepValue,
      discountType: $scope.step.discountType,
      discountValue: $scope.step.discountValue > 100 ? 100 : $scope.step.discountValue
    }; // If no step inserted yet, insert the step

    if ($scope.sellinChallenge.steps.length === 0) {
      $scope.sellinChallenge.steps.push(step);
    } else {
      // Otherwise, we need to order the steps
      var inserted = false;

      for (var index = 0; index < $scope.sellinChallenge.steps.length && !inserted; index++) {
        if (step.stepValue < $scope.sellinChallenge.steps[index].stepValue) {
          $scope.sellinChallenge.steps.splice(index, 0, step);
          inserted = true;
        }
      } // If not inserted (the value is higher than all others)


      if (!inserted) {
        $scope.sellinChallenge.steps.push(step);
      }
    }

    resetStepForm();
  }
  /**
   * Remove the step from the steps array
   *
   * @param {number} index - The index to remove.
   */


  function removeStep(index) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression de l'objectif ?").ariaLabel("Suppression de l'objectif").ok("Oui").cancel("Non")).then(function () {
      $scope.sellinChallenge.steps.splice(index, 1);
      notifyUserService.showSimpleToast("L'objectif a été supprimé.", {
        color: "success",
        hideDelay: 1000
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Reset the step form
   */


  function resetStepForm() {
    if ($scope.stepForm) {
      $scope.$setUntouched();
    }

    $scope.step = {
      type: $scope.stepTypes[0],
      stepValue: null,
      discountType: $scope.stepDiscountTypes[0],
      discountValue: null
    };
  }
  /** Set default period. */


  function setDefaultPeriod() {
    $scope.sellinChallenge.startDate = moment().toDate();
    $scope.sellinChallenge.limitActivationDate = moment().toDate();
    $scope.sellinChallenge.endDate = moment().add(1, "month").toDate();
  }
  /** Calculate end date. */


  function calculateEndDate() {
    var duration = $scope.sellinChallenge.duration;
    var startDate = moment($scope.sellinChallenge.startDate);
    startDate.add(duration.number, duration.type);
    startDate.subtract(1, "days");
    $scope.sellinChallenge.endDate = startDate.toDate();
  }
  /** Go to sellinChallenge list. */


  function goToList() {
    if ($scope.access.accessPointType === 5) {
      $state.go("app.sellinChallenge");
    } else {
      $state.go("app.sellinChallenge", {
        activeTab: $scope.createLoading ? 1 : false
      });
    }
  }
  /**
   * Valid type.
   *
   * @param {object} type - New sellin challenge type.
   */


  function validType(type) {
    $scope.sellinChallenge.type = type;

    if (type.id !== 1) {
      $scope.sellinChallenge.isPerPharmacy = true;
    }
  } // Select laboratory for this sellinChallenge if grouping


  $scope.$on("addLaboratorySellinChallenge", function (event, result) {
    if (result.laboratory && result.laboratory[0]) {
      $scope.laboratory = result.laboratory[0];
      $scope.sellinChallenge.products = [];
      $scope.productSearchConfig.must = {
        "laboratory.id": [$scope.laboratory.id]
      };
    } else {
      $scope.laboratory = {
        id: 0,
        name: ""
      };
      $scope.sellinChallenge.products = [];
      $scope.productSearchConfig.must = {
        "laboratory.id": []
      };
    }
  }); // Adding products to the sellinChallenge

  $rootScope.$on("addProductsInSellinChallenge", function (event, data) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    } // getProducts with query results


    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.sellinChallenge.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.sellinChallenge.products.push(product);
        }
      });
      getProductsInformations();
    });
  }); // Adding targets to the sellinChallenge

  $scope.$on("addTargetsInSellinChallenge", function (event, query) {
    var queryHealthcenter = {
      type: {}
    };

    var _loop2 = function _loop2(type) {
      if (type !== "groups") {
        if (Object.prototype.hasOwnProperty.call(query, type)) {
          if (typeof queryHealthcenter.type[type] === "undefined") {
            queryHealthcenter.type[type] = [];
          }

          angular.forEach(query[type], function (obj) {
            if (type === "uga") {
              queryHealthcenter.type[type].push(obj.name);
            } else {
              queryHealthcenter.type[type].push(obj.id);
            }
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(query, type)) {
        angular.forEach(query[type], function (grouping) {
          if (!_.find($scope.sellinChallenge.targets, function (element) {
            return grouping.organization_id === element.organizationId;
          })) {
            var groupObj = {
              id: grouping.id,
              name: grouping.name,
              organizationId: grouping.organization_id,
              type: "grouping"
            };
            $scope.sellinChallenge.targets.push(groupObj);
          }
        });
      }
    };

    for (var type in query) {
      _loop2(type);
    } // Get healthcenters (geographic search)


    healthcenterService.getHealthcentersByGeo(queryHealthcenter).then(function (healthcenters) {
      angular.forEach(healthcenters, function (healthcenter) {
        if (!_.find($scope.sellinChallenge.targets, function (participant) {
          return healthcenter.organizationId === participant.organizationId;
        })) {
          healthcenter.type = "healthcenter";
          $scope.sellinChallenge.targets.push(healthcenter);
        }
      });
      getProductsInformations();
    });
  });
  /**
   * Get products informations,
   */

  function getProductsInformations() {
    var healthcenterIds = $scope.sellinChallenge.targets.filter(function (target) {
      return target.id === 2;
    }).map(function (target) {
      return parseInt(target.id);
    });
    var productIds = $scope.sellinChallenge.products.map(function (product) {
      return product.id;
    }); // If products and healthcenters have been chosen

    if (healthcenterIds.length > 0 && productIds.length > 0) {
      sellinChallengeService.getPriceForHealthcentersAndProducts({
        healthcenterIds: healthcenterIds,
        productIds: productIds
      }).then(function (filledProducts) {
        // Fill the list of products with prices
        angular.forEach(filledProducts, function (filledProduct) {
          angular.forEach($scope.sellinChallenge.products, function (product) {
            if (product.id === filledProduct.productId) {
              product.percentageOfDiscount = filledProduct.percentageOfDiscount;
            }
          });
        });
      });
    }
  }
  /**
   * Create product group
   */


  function createProductGroup() {
    $scope.productGroup = {
      name: ""
    };
    $scope.isEditingProductGroup = true;
  }
  /**
   * Cancel the product group creation
   */


  function cancelProductGroupCreation() {
    $scope.isEditingProductGroup = false;
  }
  /**
   * Submit the product group creation
   */


  function submitProductGroup() {
    var groupId = Math.floor(Math.random() * Math.floor(1000));
    angular.forEach($scope.sellinChallenge.products, function (product) {
      if (product.isInProductGroup) {
        $scope.productGroup.id = groupId;
        product.isInProductGroup = false;
        product.groupId = groupId;
      }
    });
    $scope.sellinChallenge.groups.push($scope.productGroup);
    $scope.productGroup = {};
    $scope.isEditingProductGroup = false;
  }
}

angular.module("app.sellinChallenge").controller("sellinChallengeEditController", SellinChallengeEditController);