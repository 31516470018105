"use strict";
/**
 * Analysis service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

AnalysisService.$inject = ["apiClient"];

function AnalysisService(apiClient) {
  return {
    /**
     * Search product sales.
     *
     * @param {object} data -
     *
     * @returns {Promise}
     */
    searchProductSales: function searchProductSales(data) {
      return apiClient.post("/analysis/product", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search product observance.
     *
     * @param {object} data -
     *
     * @returns {Promise}
     */
    searchProductObservance: function searchProductObservance(data) {
      return apiClient.post("/analysis/observance", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search product pharmacovigilance.
     *
     * @param {object} data -
     *
     * @returns {Promise}
     */
    searchProductPharmacovigilance: function searchProductPharmacovigilance(data) {
      return apiClient.post("/analysis/pharmacovigilance", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search product supplying.
     *
     * @param {object} data -
     *
     * @returns {Promise}
     */
    searchProductSupplying: function searchProductSupplying(data) {
      return apiClient.post("/analysis/supplying", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Product supplying provider.
     *
     * @param {string} code -
     * @param {object} data -
     *
     * @returns {Promise}
     */
    productSupplyingProvider: function productSupplyingProvider(code, data) {
      return apiClient.post("/analysis/supplying/provider/".concat(code), data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top 5 laboratories.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getTop5Laboratory: function getTop5Laboratory(data) {
      return apiClient.post("/analysis/top5Laboratory", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get stock list.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getStockList: function getStockList(data) {
      return apiClient.post("/analysis/stocklist/", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get laboratory stock map.
     *
     * @param {object} data -
     *
     * @returns {Promise}
     */
    getLaboratoryStockMap: function getLaboratoryStockMap(data) {
      return apiClient.post("/analysis/stock/laboratory/map", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get laboratoris stock by group.
     *
     * @param {number} laboratoryId -
     * @param {number} group -
     * @param {object} data -
     *
     * @returns {Promise}
     */
    getLaboratoryStockByGroup: function getLaboratoryStockByGroup(laboratoryId, group, data) {
      return apiClient.post("/analysis/stock/laboratory/".concat(laboratoryId, "/").concat(group), data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get laboratories stocks by healthcenter.
     *
     * @param {string} cip - Healthcenter's CIP.
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getLaboratoryStockByHealthcenter: function getLaboratoryStockByHealthcenter(cip, data) {
      return apiClient.post("/analysis/stock/healthcenter/product/".concat(cip), data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search laboratories.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    searchLaboratories: function searchLaboratories(data) {
      return apiClient.post("/analysis/laboratory", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get age pyramide.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getAgePyramide: function getAgePyramide(data) {
      return apiClient.post("/analysis/agePyramide", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get marge.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getMarge: function getMarge(data) {
      return apiClient.post("/analysis/marge", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search substitute.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    searchSubstitute: function searchSubstitute(data) {
      return apiClient.post("/analysis/generics/substitution", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search generic.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    searchGeneric: function searchGeneric(data) {
      return apiClient.post("/analysis/generics", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search theoric generic.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    searchTheoricGeneric: function searchTheoricGeneric(data) {
      return apiClient.post("/analysis/generics/theoric", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search potential generic.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    searchPotentialGeneric: function searchPotentialGeneric(data) {
      return apiClient.post("/analysis/generics/potential", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get sales.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getSales: function getSales(data) {
      return apiClient.post("/analysis/sales", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.services").service("analysisService", AnalysisService);