"use strict";

DosageController.$inject = ["$scope", "$mdDialog", "$stateParams", "$state", "$window", "customerService", "patientDosageService", "notifyUserService", "dosageFactory", "dateTimeService"];
DosageNewProductController.$inject = ["$scope", "$mdDialog", "validationCallback"];

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Dosage controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $state - The angular $state object.
 * @param {object} $window - The angular $window object.
 * @param {object} customerService - The customer service.
 * @param {object} patientDosageService - The patient dosage service.
 * @param {object} notifyUserService - The notify user service.
 * @param {object} dosageFactory - The dosage factory.
 * @param {object} dateTimeService - The date time service object.
 */
function DosageController($scope, $mdDialog, $stateParams, $state, $window, customerService, patientDosageService, notifyUserService, dosageFactory, dateTimeService) {
  $scope.close = close;
  $scope.addDays = addDays;
  $scope.addProduct = addProduct;
  $scope.validateDosages = validateDosages;
  $scope.handleProductsAdded = handleProductsAdded;
  $scope.loading = true;
  $scope.posologyBlueprint = null;
  $scope.bluePrintChanged = blueprintChanged;
  $scope.dosageForms = null;
  $scope.dosageInstructions = null;
  $scope.getSelectedText = getSelectedText;
  $scope.selectEndAtChange = selectEndAtChange;
  $scope.slugFromHour = slugFromHour;
  $scope.submit = submit;
  $scope["delete"] = deleteConfirmation;
  $scope.prescription = null;
  $scope.patterns = null;
  $scope.addColumn = addColumn;
  $scope.blueprint = {};
  $scope.forms = {};
  $scope.title = {
    title: {
      text: "Plan de posologie",
      back: {
        state: "app.patients.details",
        tooltip: "Retour au profil"
      }
    }
  };
  $scope.doseHours = generateHours();
  $scope.isEdit = Boolean($stateParams.dosageId);
  $scope.removePosology = removePosology;
  loader();
  /**
   * Load the given prescription renewal.
   *
   * @returns {void}
   */

  function loader() {
    loadForms();
    loadInstructions();
    loadFrequencies();
    customerService.readPrescription($stateParams.patientId, $stateParams.prescriptionId).then(function (response) {
      $scope.prescription = _objectSpread(_objectSpread({}, response.data), {}, {
        created_at: new Date(response.data.created_at)
      });

      if ($stateParams.dosageId) {
        return readDosagePlan();
      }

      return null;
    }).then(function (dosagePlan) {
      $scope.dosagePlan = dosagePlan ? manageImportedDosageForDosagePlan(dosagePlan.dosages) : managePrescriptionProductsForDosagePlan($scope.prescription.products);
      $scope.maxDosageTimesLength = maxDosageTimesLength($scope.dosagePlan);
      return loadPatterns();
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Submit the dosage form.
   *
   * @returns {void}
   */


  function submit() {
    validateDosages();
    $scope.forms.posologyForm.$setSubmitted();

    if (!$scope.forms.posologyForm.$valid) {
      return;
    }

    var data = {
      dosages: sanitizeDosages($scope.dosagePlan)
    };

    if (!$scope.isEdit) {
      data.prescription_key = $scope.prescription.key;
      patientDosageService.createDosagePlan(data).then(function (createdDosage) {
        notifyUserService.showSuccessToast("La plan de posologie a bien été créé");
        $state.go(".", {
          dosageId: createdDosage.data.id
        });
      })["catch"](function () {
        notifyUserService.showDangerToast("Une erreur est survenue, veuillez réessayer");
      });
    } else {
      patientDosageService.updateDosagePlan($stateParams.dosageId, data).then(function () {
        notifyUserService.showSuccessToast("La plan de posologie a bien été mis à jour");
      })["catch"](function () {
        notifyUserService.showDangerToast("Une erreur est survenue, veuillez réessayer");
      });
    }
  }
  /**
   * Manage the prescription products for the dosage plan.
   *
   * @param {Array} products - The products to manage.
   *
   * @returns {Array}
   */


  function managePrescriptionProductsForDosagePlan(products) {
    var filterProducts = products.filter(function (product) {
      return product.id !== 0;
    });
    return filterProducts.map(function (element) {
      return dosageFactory.getDefaultDosage(element.name, element.id);
    });
  }
  /**
   * Manage the loaded dosage plan for the dosage table.
   *
   * @param {Array} dosages - The dosages.
   *
   * @returns {Array}
   */


  function manageImportedDosageForDosagePlan(dosages) {
    var maxColumnLength = maxDosageTimesLength(dosages);
    return dosages.map(function (dosage) {
      if (dosage.times.length < maxColumnLength) {
        var difference = maxColumnLength - dosage.times.length;

        for (var index = 0; index < difference; index++) {
          dosage.times.push({
            time: null,
            dose: null
          });
        }
      }

      return dosageFactory.loadDosage(dosage);
    });
  }
  /**
   * Load the different dosage forms.
   *
   * @returns {void}
   */


  function loadForms() {
    patientDosageService.getDosageForms().then(function (dosages) {
      $scope.dosageForms = dosages.data;
    });
  }
  /**
   * Load the different dosage instructions.
   */


  function loadInstructions() {
    patientDosageService.getDosageInstructions().then(function (instructions) {
      $scope.dosageInstructions = instructions.data;
    });
  }
  /**
   * Load dosage plan pattern.
   */


  function loadPatterns() {
    patientDosageService.getDosagePlans({
      prescription_key: $scope.prescription.key
    }).then(function (result) {
      $scope.patterns = result.data;
    });
  }
  /**
   * Remove a posology row from dosage plan.
   *
   * @param {object} posology - The posology to remove.
   */


  function removePosology(posology) {
    $scope.dosagePlan = $scope.dosagePlan.filter(function (posologyRow) {
      return posologyRow.product_id !== posology.product_id;
    });
  }
  /**
   * Sanitize provided dosages by stripping useless keys.
   *
   * @param {object} dosages - The dosages.
   *
   * @returns {Array}
   */


  function sanitizeDosages(dosages) {
    return dosages.map(function (dosage) {
      var returnedDosage = _objectSpread({}, dosage);

      delete returnedDosage.name;
      returnedDosage.start_at = dateTimeService.toDateTimeString(returnedDosage.start_at);
      returnedDosage.end_at = returnedDosage.end_at ? dateTimeService.toDateTimeString(returnedDosage.end_at) : null;
      returnedDosage.times = returnedDosage.times.filter(function (time) {
        return time.dose;
      });
      return returnedDosage;
    });
  }
  /**
   * Custom validations of dosages.
   *
   * @returns {void}
   */


  function validateDosages() {
    $scope.dosagePlan.forEach(function (dosage, index) {
      validateDosageTimes(dosage, index);
      validateDosageEndAtDate(dosage, index);
    });
  }
  /**
   * Validate the dosage times.
   * A least one dosage time per row must be specified.
   *
   * @param {object} dosage - The dosage row.
   * @param {number} index - The row index.
   *
   * returns {void}
   */


  function validateDosageTimes(dosage, index) {
    var emptyDosage = 0;
    dosage.times.forEach(function (time) {
      if (!time.dose || isNaN(time.dose)) {
        emptyDosage++;
      }
    });

    if (emptyDosage >= dosage.times.length) {
      $scope.forms["line_".concat(index)].dosageTimes.$setValidity("atLeastOne", false);
    } else {
      $scope.forms["line_".concat(index)].dosageTimes.$setValidity("atLeastOne", true);
    }
  }
  /**
   * Validate the end at date of a dosage row.
   *
   * @param {object} dosage - The dosage.
   * @param {number} index - The row index.
   *
   * @returns {void}
   */


  function validateDosageEndAtDate(dosage, index) {
    if (!dosage.end_at) {
      $scope.forms["line_".concat(index)].end_at.$setValidity("required", false);
    } else {
      $scope.forms["line_".concat(index)].end_at.$setValidity("required", true);
    }
  }
  /**
   * On blueprint selected, copy for products that matchs the $scope object the different key.
   *
   * @returns {void}
   */


  function blueprintChanged() {
    $scope.blueprint.value.dosages.forEach(function (bluePrintProductDosagePlan) {
      var index = $scope.dosagePlan.findIndex(function (productDosage) {
        return productDosage.product_id === bluePrintProductDosagePlan.product.id;
      });

      if (index !== -1) {
        $scope.dosagePlan[index] = _objectSpread(_objectSpread({}, $scope.dosagePlan[index]), {}, {
          times: bluePrintProductDosagePlan.times,
          dosage_form_id: bluePrintProductDosagePlan.form.id,
          dosage_frequency_id: bluePrintProductDosagePlan.frequency.id,
          dosage_instruction_id: bluePrintProductDosagePlan.instruction.id,
          comment: bluePrintProductDosagePlan.comment
        });
      }
    });
    $scope.maxDosageTimesLength = maxDosageTimesLength($scope.dosagePlan);
  }
  /**
   * Load the different dosage frequencies.
   */


  function loadFrequencies() {
    patientDosageService.getDosageFrequencies().then(function (frequency) {
      $scope.dosageFrequencies = frequency.data;
    });
  }
  /**
   * Get the text to display for the end_at <md-select>.
   *
   * @param {object} dosage - The dosage.
   *
   * @returns {string}
   */


  function getSelectedText(dosage) {
    if (!dosage.end_at) {
      return "";
    }

    return moment(dosage.end_at).format("DD/MM/YYYY");
  }
  /**
   * Compute the end_at key when the selected value change.
   *
   * @param {object} dosage - The dosage.
   *
   * @returns {void}
   */


  function selectEndAtChange(dosage) {
    if (!dosage.end_at) {
      return;
    }

    if (dosage.end_at === -1) {
      dosage.end_at = dosage.start_at;
      dosage.openCalendar = true;
    } else {
      dosage.end_at = addDays(dosage.start_at, dosage.end_at);
    }
  }
  /**
   * Add a new dosage column.
   *
   * @returns {void}
   */


  function addColumn() {
    $scope.maxDosageTimesLength++;
    $scope.dosagePlan = $scope.dosagePlan.map(function (dosage) {
      return _objectSpread(_objectSpread({}, dosage), {}, {
        times: [].concat(_toConsumableArray(dosage.times), [{
          time: null,
          dose: null
        }])
      });
    });
  }
  /**
   * Add days to a given date.
   *
   * @param {object} date - The date in moment format.
   * @param {number} days - Number of days to add.
   *
   * @returns {object}
   */


  function addDays(date, days) {
    return moment(date).add(days, "days").toDate();
  }
  /**
   * Get the slug from a hour.
   *
   * @param {string} hour - The hour.
   *
   * @returns {string}
   */


  function slugFromHour(hour) {
    var slug = hour;
    Object.keys(dosageFactory.dosageTimeMapping).forEach(function (key) {
      if (dosageFactory.dosageTimeMapping[key].value === hour) {
        slug = dosageFactory.dosageTimeMapping[key].slug;
      }
    });
    return slug;
  }
  /**
   * Get the max length of dosage times.
   *
   * @param {Array} dosages - The dosages.
   *
   * @returns {number}
   */


  function maxDosageTimesLength(dosages) {
    return Math.max.apply(Math, _toConsumableArray(dosages.map(function (dosage) {
      return dosage.times.length;
    })));
  }
  /**
   * Add a new product to the dosage plan.
   *
   * @returns {void}
   */


  function addProduct() {
    $mdDialog.show({
      controller: DosageNewProductController,
      templateUrl: "app/main/patients/views/dosage/modals/dosage-new-product.html",
      targetEvent: event,
      clickOutsideToClose: true,
      multiple: true,
      skipHide: true,
      locals: {
        validationCallback: $scope.handleProductsAdded
      }
    });
  }
  /**
   * Generate hours.
   *
   * @returns {Array}
   */


  function generateHours() {
    var items = [];
    new Array(24).fill().forEach(function (value, index) {
      items.push(moment({
        hour: index
      }).format("HH:mm"));
    });
    return items;
  }
  /**
   * Close the modal.
   */


  function close() {
    $mdDialog.hide();
  }
  /**
   * Handle when a product is added to the dosage plan.
   *
   * @param {Array} products - The products to add.
   *
   * @returns {void}
   */


  function handleProductsAdded(products) {
    products.forEach(function (product) {
      $scope.dosagePlan.push(dosageFactory.getDefaultDosage(product.name, product.id, $scope.maxDosageTimesLength));
    });
  }
  /**
   * Read the given dosage plan passed in $state params.
   *
   * @returns {Promise}
   */


  function readDosagePlan() {
    return patientDosageService.readDosagePlan($stateParams.dosageId).then(function (response) {
      return response.data;
    });
  }
  /**
   * Ask confirmation for deletion.
   */


  function deleteConfirmation() {
    var message = "Vous êtes sur le point de supprimer un plan de posologie, continuer ?";
    var confirm = $mdDialog.confirm().title("Etes-vous sûr ?").multiple(true).textContent(message).ariaLabel("Confirmation").ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(deleteDosagePlan, $mdDialog.close);
  }
  /**
   * Delete the dosage plan.
   */


  function deleteDosagePlan() {
    patientDosageService.deleteDosagePlan($stateParams.dosageId).then(function () {
      notifyUserService.showSuccessToast("La plan de posologie a bien été mis à jour");
      $window.history.back();
    })["catch"](function () {
      return notifyUserService.showDangerToast("Une erreur est survenue lors de la suppression du plan de posologie");
    });
  }
}
/**
 * Dosage new product controller.
 *
 * @param {object} $scope - The angular scope object.
 * @param {object} $mdDialog - The $mdDialog object.
 * @param {Function} validationCallback - The validation callback.
 */


function DosageNewProductController($scope, $mdDialog, validationCallback) {
  $scope.close = close;
  $scope.productSearchConfig = {
    search: ["idProducts"],
    event: "addProductInDosage",
    tag: "addProductInDosage",
    searchPlaceHolder: "Rechercher un produit..."
  };
  /**
   * Close the modal.
   */

  function close() {
    $mdDialog.hide();
  }
  /**
   * Validate the product selection and close the modal.
   */


  $scope.$on("addProductInDosage", function (event, searchusrQuery) {
    validationCallback(searchusrQuery.product);
    $scope.close();
  });
}

angular.module("app.patients").controller("dosageController", DosageController);