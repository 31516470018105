"use strict";
/**
 * Fabric window factory.
 *
 * @param {object} $window - $window.
 * @returns {object}
 */

FabricWindow.$inject = ["$window"];

function FabricWindow($window) {
  return $window.fabric3;
}

angular.module("app.generator").factory("fabricWindow", FabricWindow);