"use strict";
/**
 * Observance controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */

ObservanceController.$inject = ["$scope", "StatisticsQueryService", "$mdDialog", "environmentService", "analysisService"];
ObservanceDetailStatController.$inject = ["$scope", "$mdDialog", "data", "title"];

function ObservanceController($scope, StatisticsQueryService, $mdDialog, environmentService, analysisService) {
  var vm = this;
  vm.searchByEnv = {
    1: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id", "idOrgas"],
    2: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id"],
    3: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id"]
  };
  vm.noProduct = true;
  vm.name = {
    "geo.region.id": "Region",
    "geo.departement.id": "Département",
    "geo.uga": "UGA",
    "group.id": "Groupement"
  };
  $scope.amChartOptionsObsPieTopPresentation10 = {
    data: [],
    type: "pie",
    theme: "light",
    startDuration: 0,
    marginTop: 40,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    pullOutRadius: 10,
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    unit: " Unités",
    categoryField: "name",
    valueField: "count",
    titleField: "name",
    precision: 0,
    balloon: {
      fixedPosition: true
    },
    legend: {
      position: "right",
      valueText: "[[value]]"
    },
    "export": {
      enabled: true
    }
  };
  $scope.amChartOptionsObsPieTopCategory10 = {
    data: [],
    type: "pie",
    theme: "light",
    startDuration: 0,
    marginTop: 40,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10,
    pullOutRadius: 10,
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    unit: " Unités",
    categoryField: "name",
    valueField: "count",
    titleField: "name",
    precision: 0,
    balloon: {
      fixedPosition: true
    },
    legend: {
      position: "right",
      valueText: "[[value]]"
    },
    "export": {
      enabled: true
    }
  };
  vm.usrProductquery = StatisticsQueryService.query;

  if (vm.usrProductquery.must.laboratory.selected.length > 0) {
    vm.usrProductquery.must.laboratory.selected = [];
  }

  environmentService.getEnvironment().then(function (env) {
    vm.currentEnv = env;
    vm.config = {
      event: "productAnalysisFilterChange",
      search: vm.searchByEnv[vm.currentEnv.accessPointType],
      limits: {
        product: 1
      },
      geoForce: true,
      showBadge: true
    };

    if (vm.noProduct === false) {
      vm.processQuery();
    }

    if (vm.usrProductquery.must.product.selected.length === 1) {
      vm.noProduct = false;
      vm.processQuery();
    }
  });
  /*
       ** Date Filter
       */

  vm.rangeSelected = function (date) {
    vm.usrProductquery.date.from = moment(date.startDate, "DD-MM-YYYY");
    vm.usrProductquery.date.to = moment(date.endDate, "DD-MM-YYYY");
    vm.processQuery(vm.usrProductquery);
  };

  vm.processQuery = function () {
    vm.markers = [];
    vm.loadingProduct = true;

    if (vm.usrProductquery.must.product.selected.length === 0) {
      vm.noProduct = true;
    } else {
      vm.noProduct = false;
      vm.productSelected = vm.usrProductquery.must.product.selected[0];
    }

    StatisticsQueryService.buildquery(vm.usrProductquery, vm.usrProductquery.date).then(function (query) {
      $scope.amChartOptionsObsPieTopPresentation10.data = [];
      $scope.amChartOptionsObsPieTopCategory10.data = [];
      vm.query = query;
      vm.dateLabel = moment(vm.usrProductquery.date.from).format("DD-MMM-YYYY") + " Au " + moment(vm.usrProductquery.date.to).format("DD-MMM-YYYY");
      vm.query.type = "observance";
      analysisService.searchProductPharmacovigilance(vm.query).then(function (response) {
        vm.datas = response;
        var presentations = response.datas.presentation.slice(0, 10);
        var categories = response.datas.category.slice(0, 10);
        $scope.amChartOptionsObsPieTopPresentation10.data = presentations;
        $scope.amChartOptionsObsPieTopCategory10.data = categories;
        vm.loadingProduct = false;
      });
      analysisService.searchProductObservance(vm.query).then(function (response) {
        vm.datasObservance = response.datas;
        vm.loadingProduct = false;
      });
    });
  };

  $scope.$on("productAnalysisFilterChange", function (event, searchusrProductquery) {
    vm.loadingProduct = true;
    angular.forEach(_.keys(searchusrProductquery), function (obj) {
      angular.forEach(searchusrProductquery[obj], function (data) {
        if (obj === "product") {
          vm.usrProductquery.must[obj].selected = [data];
        } else if (vm.usrProductquery.must[obj] && vm.usrProductquery.must[obj].selected.indexOf(data) === -1) {
          vm.usrProductquery.must[obj].selected.push(data);
        }
      });
    });
    vm.noProduct = vm.usrProductquery.must.product.selected.length === 0;

    if (vm.noProduct === false) {
      vm.processQuery();
    }

    vm.loadingProduct = false;
  });

  vm.goDetail = function (data, title) {
    $mdDialog.show({
      locals: {
        data: data,
        title: title
      },
      controller: ObservanceDetailStatController,
      templateUrl: "app/main/statistics/productAnalysis/views/pharmacovigilance/pharmacovigilance.tmpl.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  };
}
/**
 * Observance detail statistics controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} data - Data.
 * @param {string} title - Title.
 */


function ObservanceDetailStatController($scope, $mdDialog, data, title) {
  $scope.data = data;
  $scope.title = title;

  $scope.hide = function () {
    $mdDialog.hide();
  };

  $scope.cancel = function () {
    $mdDialog.cancel();
  };
}

angular.module("app.statistics.products").controller("ObservanceController", ObservanceController);