"use strict";
/**
 * Marker generator.
 *
 * @param {object} $q - $q.
 * @param {Array} wholesalersLogos - Wholesaler logos..
 *
 * @returns {object}
 */

MarkerGenerator.$inject = ["$q", "wholesalersLogos"];

function MarkerGenerator($q, wholesalersLogos) {
  var model = {
    id: null,
    path: "M 46.977003,126.64334 C 46.693972,125.95584 40.813862,120.20567 36.603071,114.98067 11.655836,81.858372 -16.158365,51.082905 16.319943,13.682837 30.700637,-0.21083367 48.43303,-1.0034227 66.662563,5.4726973 117.9922,35.174601 80.828906,83.627914 56.427079,115.48067 l -9.450076,11.16267 z M 62.417383,75.872046 C 96.654166,51.387445 70.185413,4.2391813 32.569429,19.913013 21.585178,25.769872 16.134954,35.960547 15.944071,47.980664 c -0.524495,11.693153 5.685418,21.471037 15.526227,27.460808 7.055481,3.840074 10.157178,4.533661 18.145697,4.057654 5.177622,-0.308516 8.161127,-1.153847 12.801388,-3.62708 z",
    fillColor: "white",
    fillOpacity: 1,
    strokeColor: "white",
    strokeWeight: 1,
    url: null
  };
  /**
   * Constructor, with class name
   *
   * @param {object} options - Options.
   *
   * @returns {object}
   */

  function marker(options) {
    if (options.svg) {
      for (var logo in wholesalersLogos) {
        if (Object.prototype.hasOwnProperty.call(wholesalersLogos, logo)) {
          model.id = options.id ? options.id : model.id;
          model.path = options.path ? options.path : model.path;
          model.fillColor = options.fillColor ? options.fillColor : model.fillColor;
          model.fillOpacity = options.fillOpacity ? options.fillOpacity : model.fillOpacity;
          model.strokeColor = options.strokeColor ? options.strokeColor : model.strokeColor;
          model.strokeWeight = options.strokeWeight ? options.strokeWeight : model.strokeWeight;
          model.scaledSize = options.scaledSize ? new google.maps.Size(options.scaledSize[0], options.scaledSize[1]) : new google.maps.Size(32, 32);
          model.anchor = options.anchor ? new google.maps.Point(options.anchor[0], options.anchor[1]) : new google.maps.Point(0, 0);

          if (options.id === wholesalersLogos[logo].id) {
            model.path = wholesalersLogos[logo].path ? wholesalersLogos[logo].path : model.path;
            return model;
          }

          return model;
        }
      }
    }

    if (options) {
      model.id = options.id ? options.id : model.id;
      model.path = options.path ? options.path : model.path;
      model.fillColor = options.fillColor ? options.fillColor : model.fillColor;
      model.fillOpacity = options.fillOpacity ? options.fillOpacity : model.fillOpacity;
      model.strokeColor = options.strokeColor ? options.strokeColor : model.strokeColor;
      model.strokeWeight = options.strokeWeight ? options.fillOpacity : model.strokeWeight;
      model.scaledSize = options.scaledSize ? new google.maps.Size(options.scaledSize[0], options.scaledSize[1]) : new google.maps.Size(32, 32);
      model.anchor = options.anchor ? new google.maps.Point(options.anchor[0], options.anchor[1]) : new google.maps.Point(0, 0);
      return model;
    }

    return model;
  }
  /**
   * marker.prototype.build
   *
   * @param {object} options - Options.
   *
   * @returns {Promise}
   */


  marker.prototype.build = function (options) {
    return $q(function (resolve) {
      resolve(new marker(options));
    });
  };
  /**
   * Return the constructor function
   * marker
   */


  return marker;
}

angular.module("markerGenerator.factory", []).factory("markerGenerator", MarkerGenerator);