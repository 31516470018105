"use strict";
/**
 * Service to manage disruption partners.
 *
 * @param {object} apiClient - The API client.
 * @returns {object} The disruption partners service.
 */

DisruptionPartnersService.$inject = ["apiClient"];

function DisruptionPartnersService(apiClient) {
  return {
    /**
     * Get disruption partners.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/partnerships/apodis-disruption/laboratory", {
        includes: ["morphable.branding.logo"]
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.disruption").service("disruptionPartnersService", DisruptionPartnersService);