"use strict";
/**
 * The patient dosage service.
 *
 * @param {object} hdsApiClient - The HDS Api client.
 *
 * @returns {object}
 */

PatientDosageService.$inject = ["hdsApiClient"];

function PatientDosageService(hdsApiClient) {
  return {
    /**
     * Get the dosage forms.
     *
     * @returns {Promise}
     */
    getDosageForms: function getDosageForms() {
      return hdsApiClient("GET", "/dosages/forms");
    },

    /**
     * Get the dosage frequencies.
     *
     * @returns {Promise}
     */
    getDosageFrequencies: function getDosageFrequencies() {
      return hdsApiClient("GET", "/dosages/frequencies");
    },

    /**
     * Get the dosage instructions.
     *
     * @returns {Promise}
     */
    getDosageInstructions: function getDosageInstructions() {
      return hdsApiClient("GET", "/dosages/instructions");
    },

    /**
     * Create a dosage plan.
     *
     * @param {object} dosagePlan - The dosage plan.
     *
     * @returns {Promise}
     */
    createDosagePlan: function createDosagePlan(dosagePlan) {
      var options = {
        data: dosagePlan
      };
      return hdsApiClient("POST", "/dosages/plans", options);
    },

    /**
     * Get all dosages plan.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    getDosagePlans: function getDosagePlans(params) {
      return hdsApiClient("GET", "/dosages/plans", {
        params: params
      });
    },

    /**
     * Read a given dosage plan.
     *
     * @param {number} dosagePlanId - The dosage plan id.
     *
     * @returns {Promise}
     */
    readDosagePlan: function readDosagePlan(dosagePlanId) {
      return hdsApiClient("GET", "/dosages/plans/".concat(dosagePlanId));
    },

    /**
     * Update a dosage plan.
     *
     * @param {number} dosagePlanId - The dosage plan id.
     * @param {object} data - The data.
     *
     * @returns {Promise}
     */
    updateDosagePlan: function updateDosagePlan(dosagePlanId, data) {
      return hdsApiClient("PATCH", "/dosages/plans/".concat(dosagePlanId), {
        data: data
      });
    },

    /**
     * Delete a dosage plan.
     *
     * @param {number} dosagePlanId - The dosage plan id.
     *
     * @returns {Promise}
     */
    deleteDosagePlan: function deleteDosagePlan(dosagePlanId) {
      return hdsApiClient("DELETE", "/dosages/plans/".concat(dosagePlanId));
    }
  };
}

angular.module("app.patients.services").service("patientDosageService", PatientDosageService);