"use strict";
/**
 * Admin configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

AdminConfig.$inject = ["$stateProvider"];

function AdminConfig($stateProvider) {
  $stateProvider.state("app.santesecure", {
    url: "/admin/sante-secure",
    views: {
      "content@app": {
        templateUrl: "app/main/admin/santeSecure/views/santeSecure.html",
        controller: "AdminSanteSecureController"
      }
    },
    params: {
      type: 1
    }
  }).state("app.users", {
    url: "/admin/users",
    views: {
      "content@app": {
        templateUrl: "app/main/admin/users/views/list/users.html",
        controller: "AdminUsersController as vm"
      }
    }
  }).state("app.users.edit", {
    url: "/admin/users/edit/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/admin/users/views/edit/userEdit.html",
        controller: "UserEditController as vm"
      }
    },
    params: {
      user: null
    }
  });
}

angular.module("app.admin", []).config(AdminConfig);