"use strict";
/**
 * Laboratory products controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {string} apiurl - API URL.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} environmentService - Environment service.
 * @param {object} laboratoryProductsService - Laboratory products service.
 * @param {object} sessionStorageService - Session storage service.
 */

LaboratoryProductsController.$inject = ["$scope", "$state", "apiurl", "$mdDialog", "notifyUserService", "environmentService", "laboratoryProductsService", "sessionStorageService"];

function LaboratoryProductsController($scope, $state, apiurl, $mdDialog, notifyUserService, environmentService, laboratoryProductsService, sessionStorageService) {
  /**
   * Declaration of public methods
   */
  $scope.loaderProducts = loaderProducts;
  $scope.editProduct = editProduct;
  $scope.confirmDeleteCustomizedProduct = confirmDeleteCustomizedProduct;
  $scope.deleteCustomizedProduct = deleteCustomizedProduct;
  /**
   * Declaration of public objects
   */

  $scope.apiurl = apiurl;
  $scope.token = sessionStorageService.getToken();
  $scope.search = {
    terms: ""
  };
  $scope.pagination = {
    current_page: 1,
    per_page: 25
  };
  $scope.paginationLabels = {
    page: "Page :",
    rowsPerPage: "Lignes par page :",
    of: "sur"
  };
  $scope.products = [];
  $scope.importConfig = {
    entityName: "ProductLaboratory"
  };
  $scope.exportConfig = {
    entityName: "Product",
    fileType: "xlsx",
    fileName: "Export_liste_produits",
    isAllowedColumnChoice: false,
    defaultFilter: {}
  };
  $scope.productsOptions = {
    loading: true,
    loader: "Chargement des produits",
    empty: "Aucun produit"
  };
  $scope.title = {
    title: {
      icon: "icon-file-presentation-box",
      text: "Gestion de mes produits"
    }
  };
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * loader to launch each method inside
   */

  function loader() {
    loaderEnvironment();
    loaderProducts();
  }
  /**
   * loaderEnvironment
   *
   * @returns {Promise}
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.exportConfig.defaultFilter.laboratory_id = response.entityId;
      return response;
    });
  }
  /**
   * Gets all products.
   *
   * @returns {Promise}
   */


  function loaderProducts() {
    $scope.productsOptions.loading = true;
    return laboratoryProductsService.get($scope.pagination, $scope.search.terms).then(function (response) {
      $scope.products = response.data.map(prepareProduct);
      $scope.pagination = response.meta.pagination;
      $scope.productsOptions.loading = false;
      return response;
    });
  }
  /**
   * Prepare product to display.
   *
   * @param {object} product -
   *
   * @returns {object}
   */


  function prepareProduct(product) {
    var defaultDescription = "<p>Aucune description pour l'instant.</p>";
    product.notice = product.notice ? product.notice.html : "<p>Aucune notice pour l'instant.</p>";
    product.pharmacistDescription = product.override ? product.override.pharmacistDescription || defaultDescription : defaultDescription;
    product.consumerDescription = product.override ? product.override.consumerDescription || defaultDescription : defaultDescription;
    product.infos = false;
    return product;
  }
  /**
   * Get product name from override if defined.
   *
   * @param {object} product -
   *
   * @returns {string}
   */


  function getProductName(product) {
    return product.override ? product.override.name || product.name : product.name;
  }
  /**
   * Open edition form.
   *
   * @param {object} product -
   */


  function editProduct(product) {
    $state.go("app.laboratory-products.edit", {
      id: product.id
    });
  }
  /**
   * Confirm box for deletion of a product
   *
   * @param {object} event - The event
   * @param {object} product - The product
   */


  function confirmDeleteCustomizedProduct(event, product) {
    var name = getProductName(product);
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain de vouloir supprimer la personnalisation du produit « " + name + " » ?").ariaLabel("suppression de la personnalisation produit").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      deleteCustomizedProduct(product);
    });
  }
  /**
   * Delete one customized product
   *
   * @param {object} product - The product
   *
   * @returns {Promise}
   */


  function deleteCustomizedProduct(product) {
    return laboratoryProductsService["delete"](product.id).then(function () {
      var name = getProductName(product);
      notifyUserService.showSuccessToast("La personnalisation du produit « " + name + " » est supprimée.");
    }).then(loaderProducts);
  }
}

angular.module("app.laboratory-products").controller("laboratoryProductsController", LaboratoryProductsController);