"use strict";
/**
 * Documentation titles controller.
 *
 * @param {object} $scope - $scope.
 */

DocumentationTitlesController.$inject = ["$scope"];

function DocumentationTitlesController($scope) {
  $scope.clickTest = function () {
    // eslint-disable-next-line no-console
    console.log("It's running ON !!!");
  };
}

angular.module("app.documentation").controller("documentationTitlesController", DocumentationTitlesController);