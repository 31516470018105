"use strict";
/**
 * Users laboratory menu.
 *
 * @returns {object}
 */

function UsersLaboratoryMenu() {
  var menus = [{
    name: "Espace marque",
    state: "app.usersLaboratory.branding"
  }, {
    name: "Catalogue",
    state: "app.usersLaboratory.branding.catalog"
  }, {
    name: "Mes offres commerciales",
    state: "app.usersLaboratory.branding.commercialOffers"
  }, {
    name: "Communication",
    state: "app.usersLaboratory.branding.communication"
  }, {
    name: "Mes statistiques",
    state: "app.usersLaboratory.branding.statistics"
  }, {
    name: "Mes paramètres",
    state: "app.usersLaboratory.branding.settings"
  }];
  return {
    get: function get() {
      return menus;
    }
  };
}

angular.module("app.users-laboratory.factories").factory("usersLaboratoryMenu", UsersLaboratoryMenu);