"use strict";
/**
 * Condition of use controller.
 *
 * @param {object} $window - $window.
 */

ConditionOfUseController.$inject = ["$window"];

function ConditionOfUseController($window) {
  var vm = this;

  vm.openPdfEmployee = function () {
    $window.open("../assets/files/cgu_employee.pdf");
  };

  vm.openPdfPharmacist = function () {
    $window.open("../assets/files/cgu_pharmacist.pdf");
  };
}

angular.module("app.legal").controller("ConditionOfUseController", ConditionOfUseController);