"use strict";
/**
 * PLV templates service.
 *
 * @param {object} apiClient - API client.
 * @returns {object}
 */

PlvsTemplatesService.$inject = ["apiClient"];

function PlvsTemplatesService(apiClient) {
  return {
    /**
     * load
     *
     * @returns {Promise}
     */
    load: function load() {
      return apiClient.get("/plvs/templates").then(function (result) {
        return result.data;
      });
    },

    /**
     * read
     *
     * @param {number} id - The template id
     * @returns {Promise}
     */
    read: function read(_ref) {
      var id = _ref.id;
      return apiClient.get("/plvs/templates/".concat(id)).then(function (result) {
        return result.data;
      });
    },

    /**
     * template
     *
     * @param {number} id - The template id
     * @returns {Promise}
     */
    template: function template(_ref2) {
      var id = _ref2.id;
      return apiClient.get("/plvs/templates/".concat(id, "/show")).then(function (result) {
        return result.data;
      });
    }
  };
}

angular.module("app.generator").service("plvsTemplatesService", PlvsTemplatesService);