"use strict";
/**
 * Apodis widget title directive.
 *
 * @returns {object}
 */

function ApodisWidgetTitle() {
  return {
    restrict: "E",
    scope: {
      config: "=config"
    },
    templateUrl: "app/main/directives/apodis-widget-title/apodisWidgetTitle.html",
    transclude: true,
    controller: function controller() {// do nothing
    },
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("apodisWidgetTitle", ApodisWidgetTitle);