"use strict";
/**
 * Grouping members list controller.
 *
 * @param {object} $scope - Scope
 * @param {object} $mdDialog - MD Dialog
 * @param {object} notifyUserService - Notify user service
 * @param {object} groupingMembersGroupingService - Grouping members service
 */

GroupingMembersListController.$inject = ["$scope", "$mdDialog", "notifyUserService", "groupingMembersGroupingService"];

function GroupingMembersListController($scope, $mdDialog, notifyUserService, groupingMembersGroupingService) {
  $scope.loading = true;
  $scope.limitOptions = [10, 25, 50, 100, 250, 500];
  $scope.pagination = {
    currentPage: 1,
    limit: 10
  };
  $scope.query = {
    search: ""
  };
  $scope.members = [];
  $scope.getGroupingMembers = getGroupingMembers;
  $scope.showConfirmRequest = showConfirmRequest;
  loader();
  /**
   * Loader.
   */

  function loader() {
    getGroupingMembers();
  }
  /**
   * Load grouping members.
   */


  function getGroupingMembers() {
    $scope.loading = true;
    var parameters = [{
      name: "page",
      value: $scope.pagination.currentPage
    }, {
      name: "limit",
      value: $scope.pagination.limit
    }, {
      name: "search",
      value: $scope.query.search
    }];
    groupingMembersGroupingService.getMembers(parameters).then(function (response) {
      $scope.pagination.total = response.meta.pagination.total;
      $scope.members = response.data;
      $scope.loading = false;
    });
  }
  /**
   * Show confirm request.
   *
   * @param {object} member - The member
   * @param {boolean} toDelete - To delete or not
   */


  function showConfirmRequest(member, toDelete) {
    if (!toDelete) {
      // Appending dialog to document.body to cover sidenav in docs app
      var confirm = $mdDialog.confirm().title("Valider la demande").textContent("En acceptant, vous permettrez à cette officine de rejoindre votre groupement.").targetEvent(member).parent(angular.element(document.body)).ok("Accepter la demande et enregistrer").cancel("Refuser la demande");
      $mdDialog.show(confirm).then(function () {
        acceptRequest(member);
        getGroupingMembers();
      }, function () {
        refuseRequest(member);
        getGroupingMembers();
      });
    } else {
      // Appending dialog to document.body to cover sidenav in docs app
      var _confirm = $mdDialog.confirm().title("Supprimer la pharmacie").textContent("En validant, vous supprimez cette officine de votre groupement.").targetEvent(member).parent(angular.element(document.body)).ok("Supprimer la pharmacie").cancel("Annuler");

      $mdDialog.show(_confirm).then(function () {
        deleteMember(member);
        getGroupingMembers();
      });
    }
  }
  /**
   * Accept request.
   *
   * @param {object} member - The member
   */


  function acceptRequest(member) {
    groupingMembersGroupingService.acceptMember({
      healthcenterId: member.id
    }).then(function () {
      notifyUserService.showSimpleToast(member.name + " fait maintenant partie de votre groupement.");
    });
  }
  /**
   * Refuse request.
   *
   * @param {object} member - The member
   */


  function refuseRequest(member) {
    groupingMembersGroupingService.refuseMember({
      healthcenterId: member.id
    }).then(function () {
      notifyUserService.showSimpleToast("Vous avez refusé la demande de " + member.name);
    });
  }
  /**
   * Delete member.
   *
   * @param {object} member - The member
   */


  function deleteMember(member) {
    groupingMembersGroupingService.deleteMember({
      healthcenterId: member.id
    }).then(function () {
      notifyUserService.showSimpleToast(member.name + " ne fait maintenant plus partie de votre groupement.");
    });
  }
}

angular.module("app.grouping").controller("groupingMembersListController", GroupingMembersListController);