"use strict";
/**
 * Dashboard pharmacies sponsorship controller.
 *
 * @param {object} authSponsorshipsService - Auth sponsorships service.
 * @param {object} $scope - $scope.
 */

dashboardPharmaciesSponsorshipController.$inject = ["authSponsorshipsService", "$scope"];

function dashboardPharmaciesSponsorshipController(authSponsorshipsService, $scope) {
  $scope.loading = true;
  $scope.godsons = [];
  authSponsorshipsService.getGodsons().then(function (response) {
    $scope.godsons = response.godsons;
    $scope.loading = false;
  });
}

angular.module("app.dashboard").controller("dashboardPharmaciesSponsorshipController", dashboardPharmaciesSponsorshipController);