"use strict";
/**
 * Grouping markers logos factory.
 *
 * @returns {object}
 */

function GroupingsMarkersLogos() {
  return {
    apodis: {
      "default": "/assets/images/logos/apodis_marker_default.png",
      available: "/assets/images/logos/apodis_marker.png",
      unavailable: "/assets/images/logos/apodis_marker.png"
    },
    "default": [{
      id: 235,
      logo: "/assets/images/groupings/markers/default/235-dl-sante.png"
    }, {
      id: 212,
      logo: "/assets/images/groupings/markers/default/212-l4f.png"
    }, {
      id: 231,
      logo: "/assets/images/groupings/markers/default/231-pda-pharma.png"
    }, {
      id: 226,
      logo: "/assets/images/groupings/markers/default/226-reseau-pp.png"
    }, {
      id: 230,
      logo: "/assets/images/groupings/markers/default/230-simply-pharma.png"
    }, {
      id: 178,
      logo: "/assets/images/groupings/markers/default/178-unipharm.png"
    }],
    available: [{
      id: 235,
      logo: "/assets/images/groupings/markers/available/235-dl-sante.png"
    }, {
      id: 212,
      logo: "/assets/images/groupings/markers/available/212-l4f.png"
    }, {
      id: 231,
      logo: "/assets/images/groupings/markers/available/231-pda-pharma.png"
    }, {
      id: 226,
      logo: "/assets/images/groupings/markers/available/226-reseau-pp.png"
    }, {
      id: 230,
      logo: "/assets/images/groupings/markers/available/230-simply-pharma.png"
    }, {
      id: 178,
      logo: "/assets/images/groupings/markers/available/178-unipharm.png"
    }],
    unavailable: [{
      id: 235,
      logo: "/assets/images/groupings/markers/unavailable/235-dl-sante.png"
    }, {
      id: 212,
      logo: "/assets/images/groupings/markers/unavailable/212-l4f.png"
    }, {
      id: 231,
      logo: "/assets/images/groupings/markers/unavailable/231-pda-pharma.png"
    }, {
      id: 226,
      logo: "/assets/images/groupings/markers/unavailable/226-reseau-pp.png"
    }, {
      id: 230,
      logo: "/assets/images/groupings/markers/unavailable/230-simply-pharma.png"
    }, {
      id: 178,
      logo: "/assets/images/groupings/markers/unavailable/178-unipharm.png"
    }]
  };
}

angular.module("app.grouping").factory("groupingsMarkersLogos", GroupingsMarkersLogos);