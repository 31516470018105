"use strict";
/**
 * Merchandising plan file service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

MerchandisingPlanFileService.$inject = ["apiClient"];

function MerchandisingPlanFileService(apiClient) {
  return {
    /**
     * Get merchandising plan files.
     *
     * @param {object} params - Params.
     *
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.get("/merchandising-plans/".concat(params.merchandisingPlanId, "/files"), {
        data: params
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create merchandising plan file.
     *
     * @param {object} params - Params.
     *
     * @returns {Promise}
     */
    create: function create(params) {
      return apiClient.post("/merchandising-plans/".concat(params.merchandisingPlanId, "/files"), {
        data: params
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete merchandising plan file.
     *
     * @param {object} params - Params.
     *
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/merchandising-plans/".concat(params.merchandisingPlanId, "/files/").concat(params.merchandisingPlanFileId), {
        data: params
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.merchandising-plan").service("merchandisingPlanFileService", MerchandisingPlanFileService);