"use strict";
/**
 * Wholesaler configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

WholesalerConfiguration.$inject = ["$stateProvider"];

function WholesalerConfiguration($stateProvider) {
  $stateProvider.state("app.wholesaler", {
    url: "/grossistes",
    views: {
      "content@app": {
        templateUrl: "app/main/wholesaler/views/listing/listing.html",
        controller: "wholesalerListing"
      }
    }
  });
}

angular.module("app.wholesaler", ["wholesaler.factories", "wholesaler.category", "wholesaler.catalog"]).config(WholesalerConfiguration);