"use strict";
/**
 * Partners service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

PartnersService.$inject = ["apiClient"];

function PartnersService(apiClient) {
  return {
    /**
     * Get.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/partners").then(function (_ref) {
        var data = _ref.data;
        return data;
      });
    }
  };
}

angular.module("app.partners").service("partnersService", PartnersService);