"use strict";
/**
 * Events list directive.
 *
 @returns {object}
 */

eventsListDirectiveCtrl.$inject = ["$scope", "$state", "eventService"];

function EventsListDirective() {
  return {
    restrict: "E",
    templateUrl: "app/main/events/directive/list-events/eventsList.html",
    scope: {
      type: "@type",
      startDate: "@start",
      endDate: "@end"
    },
    controller: eventsListDirectiveCtrl
  };
}
/**
 * Events list directive controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} eventService - Event service.
 */


function eventsListDirectiveCtrl($scope, $state, eventService) {
  $scope.loading = true;
  loader();
  $scope.goToEvent = goToEvent;
  /**
   * Loader.
   */

  function loader() {
    $scope.today = new Date();
    getEvents();
  }
  /**
   * Retrieve the events
   */


  function getEvents() {
    $scope.loading = true;
    eventService.getEvents({
      type: $scope.type,
      from: $scope.startDate,
      to: $scope.endDate
    }).then(function (results) {
      $scope.events = results.events;
      $scope.loading = false;
    });
  }
  /**
   * Go to event.
   *
   * @param {string} type - Type.
   * @param {number} id - ID.
   */


  function goToEvent(type, id) {
    switch (type) {
      case "sellout":
        $state.go("app.sellout.edit", {
          id: id
        });
        break;

      case "sellout-participation":
        $state.go("app.UserSelloutHome.detail", {
          id: id
        });
        break;

      case "prestation":
        $state.go("app.prestation.edit", {
          id: id
        });
        break;

      case "prestation-participation":
        $state.go("app.UserPrestationDetail", {
          id: id
        });
        break;

      case "sellin-offer":
        $state.go("app.sellinOffer.detail", {
          id: id
        });
        break;

      case "sellin-challenge":
        $state.go("app.sellinChallenge.detail", {
          id: id
        });
        break;

      case "birthday":
        break;
    }
  }

  $scope.$watchGroup(["type", "start", "end"], function () {
    getEvents();
  });
}

angular.module("app.events").directive("eventsList", EventsListDirective);