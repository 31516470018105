"use strict";
/**
 * Healthcenter audit geoloc service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

healthCenterAuditGeolocService.$inject = ["apiClient"];

function healthCenterAuditGeolocService(apiClient) {
  return {
    /**
     * Pharmas by geo.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    pharmasByGeo: function pharmasByGeo(params) {
      return apiClient.post("/healthcenters/geo/search", params, {
        includes: ["grouping"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.healthcenterAudit").service("healthCenterAuditGeolocService", healthCenterAuditGeolocService);