"use strict";
/**
 * Controller for dashboard group healthcenter members map.
 *
 * @param {object} groupingMembersGroupingService - Grouping members service
 * @param {object} gmap - Google map service
 */

DashboardGroupHealthcenterMembersMapController.$inject = ["groupingMembersGroupingService", "gmap"];

function DashboardGroupHealthcenterMembersMapController(groupingMembersGroupingService, gmap) {
  var domMap = document.getElementById("groupingDashboardMembersMap");
  var googleMap;
  loader();
  /**
   * Loader.
   */

  function loader() {
    loadMap();
  }
  /**
   * Load map.
   */


  function loadMap() {
    var myLatLng = {
      lat: 47.6468754,
      lng: 2.3242131
    };
    var mapOptions = {
      center: new google.maps.LatLng(myLatLng),
      zoom: 4,
      gestureHandling: "cooperative",
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: google.maps.ControlPosition.LEFT_TOP
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM
      },
      fullscreenControl: false,
      styles: gmap
    };
    googleMap = new google.maps.Map(domMap, mapOptions);
    loadMembers();
  }
  /**
   * Load all members of grouping.
   *
   * @returns {Promise} The members.
   */


  function loadMembers() {
    return groupingMembersGroupingService.getAllMembers().then(function (members) {
      members.forEach(function (member) {
        if (member.address) {
          var content = "<strong>" + member.name + "</strong>" + "<p>" + member.address.streetLine1 + "</p>";

          if (member.address.streetLine2) {
            content += "<p>" + member.address.streetLine2 + "</p>";
          }

          content += "<p>" + member.address.postalCode + "&nbsp;" + member.address.cityName + "</p>";
          var marker = new google.maps.Marker({
            position: {
              lat: member.address.latitude,
              lng: member.address.longitude
            },
            animation: google.maps.Animation.DROP,
            map: googleMap
          });

          if (!member.isGroupingValidated) {
            content += "<a href='/equipe/liste'>Cette pharmacie ne fait pas encore partie du groupement.</a>";
            marker.setLabel({
              text: "!",
              color: "#fff",
              fontWeight: "bold"
            });
          }

          var infoWindow = new google.maps.InfoWindow({
            content: content
          });
          marker.addListener("click", function () {
            infoWindow.open(googleMap, marker);
          });
        }
      });
      return members;
    });
  }
}

angular.module("app.dashboard").controller("dashboardGroupHealthcenterMembersMapController", DashboardGroupHealthcenterMembersMapController);