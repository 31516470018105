"use strict";
/**
 * Prestation benefit detail controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} $timeout - The angular $timeout object.
 * @param {object} mapService - The map service.
 * @param {object} $window - The angular $window object.
 * @param {string} apiurl - API URL.
 * @param {object} prestationService - Prestation service.
 */

PrestationBenefitDetailController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "$timeout", "mapService", "$window", "apiurl", "prestationService"];

function PrestationBenefitDetailController($scope, $state, $stateParams, $mdDialog, $timeout, mapService, $window, apiurl, prestationService) {
  DialogController.$inject = ["$scope", "$mdDialog", "locals"];
  $scope.token = window.sessionStorage.token;
  $scope.apiurl = apiurl;
  $scope.openSales = openSales;
  $scope.statusFiltered = "all";
  var distanceMarker;

  if ($stateParams.picture === null) {
    $state.go("app.prestation.listing", {
      id: $stateParams.id
    });
  } else {
    $scope.prestationPicture = $stateParams.picture;
    $scope.healthcenter = $stateParams.picture[0].organization.morphable;
    angular.forEach($scope.prestationPicture, function (distancePhoto, index) {
      var photoLatLng = {
        lat: distancePhoto.latitude,
        lng: distancePhoto.longitude
      };
      var markerLatLng = new google.maps.LatLng({
        lat: distancePhoto.organization.morphable.address.latitude,
        lng: distancePhoto.organization.morphable.address.longitude
      });
      distanceMarker = google.maps.geometry.spherical.computeDistanceBetween(markerLatLng, new google.maps.LatLng(photoLatLng.lat, photoLatLng.lng));
      distancePhoto.outside = false;
      distancePhoto.distanceMarker = distanceMarker;

      if (distanceMarker > 500) {
        distancePhoto.outside = true;
        $scope.classWarning = "warningImg";
      }

      $scope.distancePrestation = $scope.prestationPicture[index] = distancePhoto;
      $scope.statusObjTotal = {
        prestationAccept: 0,
        prestationRefused: 0
      }; // Status counter

      switch (true) {
        case distancePhoto.status === 1:
          $scope.statusObjTotal.prestationAccept++;
          break;

        case distancePhoto.status === 2:
          $scope.statusObjTotal.prestationRefused++;
          break;
      }
    });
  }
  /**
   * Get single benefit.
   */


  function getSingleBenefit() {
    $scope.markers = [];
    $scope.photo = [];
    $scope.photos = [];
    prestationService.readWithoutMembers($stateParams.id).then(function (response) {
      $scope.readPrestation = response.prestation;
      $scope.productIds = response.prestation.products.map(function (product) {
        return {
          id: product.presentation.id
        };
      });
      $scope.dates = {
        startDate: new Date(response.prestation.startDatetime),
        endDate: moment(new Date(response.prestation.endDatetime))
      };
      recommendation($scope.readPrestation);
      openMap($scope.prestationPicture);
    });
  }
  /**
   * Accept the picture.
   *
   * @param {object} picture - The picture.
   */


  $scope.accept = function (picture) {
    prestationService.acceptBenefitPhoto(picture.id, {
      isNotMatching: 0,
      comments: null
    }).then(function (response) {
      $scope.acceptPicture = response.photo; // refresh after action

      picture.status = response.photo.status;
    });
  };
  /**
   * Refuse the picture.
   *
   * @param {object} picture - The picture.
   * @param {string} comments - The comments.
   */


  $scope.refuse = function (picture, comments) {
    prestationService.refuseBenefitPhoto(picture.id, {
      status: 2,
      comments: comments,
      isNotMatching: picture.isNotMatching
    }).then(function (response) {
      $scope.refusedPicture = response.photo; // refresh after action

      picture.status = response.photo.status;
    });
  };
  /**
   * Go to map state in history.
   */


  $scope.gotoMap = function () {
    $window.history.back();
  };
  /**
   * Open a recommendation.
   *
   * @param {object} recommendation - The recommendation to open.
   */


  $scope.openRecommendation = function (recommendation) {
    window.open($scope.apiurl + "/filemanager/file/show?path=" + recommendation.file.path + "&token=" + recommendation.file.token);
  };
  /**
   * Recommandation.
   *
   * @param {object} prestation - Prestation.
   */


  function recommendation(prestation) {
    $scope.openRecommendations = function (ev) {
      $mdDialog.show({
        controller: DialogController,
        templateUrl: "app/main/prestation/map/detail/dialog/recommendation.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        openFrom: "left",
        locals: {
          prestation: prestation
        }
      });
    };
  }
  /**
   * Open map.
   *
   * @param {object} map - Map.
   */


  function openMap(map) {
    $scope.openMap = function (ev, picture) {
      $mdDialog.show({
        controller: DialogController,
        templateUrl: "app/main/prestation/map/detail/dialog/map.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        openFrom: "left",
        locals: {
          map: map,
          picture: picture
        }
      });
    };
  }
  /**
   * Dialog controller.
   *
   * @param {object} $scope - $scope
   * @param {object} $mdDialog - $mdDialog
   * @param {object} locals - Locals.
   */


  function DialogController($scope, $mdDialog, locals) {
    $scope.locals = locals;

    $scope.hide = function () {
      $mdDialog.hide();
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $timeout(function () {
      mapService.initialize(locals.picture, $scope.prestationPicture);
    }, 200);
  }

  getSingleBenefit();
  /**
   * Open stock.
   *
   * @param {object} event - The event object.
   * @param {Array} stocks - The stocks container.
   */

  $scope.openStock = function (event, stocks) {
    $mdDialog.show({
      controller: ["$scope", "$mdDialog", function controller($scope, $mdDialog) {
        $scope.stocks = stocks;

        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      }],
      templateUrl: "app/main/prestation/map/detail/dialog/stock.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true
    });
  };
  /**
   * Open stock.
   *
   * @param {object} event - The event object.
   */


  function openSales(event) {
    $mdDialog.show({
      controller: ["$scope", "$mdDialog", "productIds", "healthcenter", "dates", "StatisticsQueryService", "salesAnalysisSandBoxLinesAtclService", function controller($scope, $mdDialog, productIds, healthcenter, dates, StatisticsQueryService, salesAnalysisSandBoxLinesAtclService) {
        $scope.loadingSales = true;
        var salesGraphs = {
          type: "serial",
          data: [],
          startDuration: 0,
          marginTop: 40,
          marginRight: 60,
          legend: {
            useGraphSettings: true,
            marginTop: 20
          },
          valueAxes: [{
            id: "sales",
            axisAlpha: 1,
            position: "left",
            gridAlpha: 0.1,
            axisColor: "#9AD8E0",
            labelFunction: function labelFunction(value) {
              return d3.format(".2s")(value) + " unités";
            }
          }, {
            id: "salesLastYear",
            axisAlpha: 1,
            position: "left",
            gridAlpha: 0,
            axisColor: "#0B98A8",
            offset: 60,
            labelFunction: function labelFunction(value) {
              return d3.format(".2s")(value);
            }
          }],
          graphs: [],
          chartCursor: {
            cursorColor: "#888888",
            cursorAlpha: 0.5,
            categoryBalloonDateFormat: "DD MMM YYYY",
            fullWidth: false
          },
          categoryField: "date",
          categoryAxis: {
            gridColor: "#F2F2F2",
            gridAlpha: 0.3,
            parseDates: true,
            minorGridAlpha: 0.1,
            minorGridEnabled: true
          },
          "export": {
            enabled: true,
            menu: [{
              "class": "export-main",
              menu: [{
                format: "PNG",
                title: "Exporter au format PNG"
              }, {
                format: "JPG",
                title: "Exporter au format JPG"
              }]
            }]
          }
        };
        $scope.usrquery = StatisticsQueryService.query;
        $scope.usrquery.date.from = moment(dates.startDate).format("YYYY-MM-DD");
        $scope.usrquery.date.to = moment(dates.endDate).format("YYYY-MM-DD");
        $scope.usrquery.must.product.selected = productIds;
        $scope.usrquery.must.pharmas.selected = [{
          cip: healthcenter.cip,
          name: healthcenter.name,
          type: "pharmas"
        }];
        var graphs = [];
        StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
          $scope.query = query;
          $scope.query.interval = "1w";
          $scope.query.aggby = "product.presentation";
          salesAnalysisSandBoxLinesAtclService.chart(query).then(function (response) {
            response.aggregations.aggby.buckets.forEach(function (product) {
              graphs.push({
                title: product.key,
                data: product.key,
                key_id: product.key_id,
                bucket: product.sales.buckets,
                type: "smoothedLine"
              });
            });
            var lines = [];
            var ids = [];
            angular.forEach(response.aggregations.sales.buckets, function (data, index) {
              var obj = {
                date: data.key
              };
              angular.forEach(graphs, function (data2) {
                if (ids.length !== graphs.length) {
                  ids.push(data2.key_id);
                }

                obj[data2.data] = data2.bucket[index].quantity.value;
              });
              lines.push(obj);
            });
            salesGraphs.data = lines;
            salesGraphs.graphs = graphs;
            $scope.salesGraphs = salesGraphs;
            $scope.loadingSales = false;
            return response;
          });
        });

        $scope.cancel = function () {
          $mdDialog.cancel();
        };
      }],
      locals: {
        productIds: $scope.productIds,
        healthcenter: $scope.healthcenter,
        dates: $scope.dates
      },
      templateUrl: "app/main/prestation/map/detail/dialog/sales.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true
    });
  }
}

angular.module("app.prestation").controller("PrestationBenefitDetailController", PrestationBenefitDetailController);