"use strict";
/**
 * Product service.
 *
 * @param {object} apiClient - API client.
 */

ProductService.$inject = ["apiClient"];

function ProductService(apiClient) {
  return {
    /**
     * Get presentation.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getPresentation: function getPresentation(params) {
      return apiClient.get("/product/presentation/detail/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get presentation by id.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getPresentationById: function getPresentationById(params) {
      var parameters = Object.keys(params).filter(function (param) {
        return params[param];
      }).map(function (param) {
        if (Array.isArray(params[param])) {
          var name = "".concat(param, "[]");
          return params[param].map(function (value) {
            return {
              name: name,
              value: value
            };
          });
        }

        return {
          name: param,
          value: params[param]
        };
      }).flat();
      return apiClient.get("/products/specialitypresentation", {
        parameters: parameters
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Post presentation by type.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    postPresentationByType: function postPresentationByType(params) {
      return apiClient.post("/product/specialitypresentation/presentationByType", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.services").service("productService", ProductService);