"use strict";
/**
 * Sales analysis market shares evolution service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

SalesAnalysisMarketSharesEvolutionService.$inject = ["apiClient"];

function SalesAnalysisMarketSharesEvolutionService(apiClient) {
  return {
    /**
     * Get.
     *
     * @param {object} params - Params.
     *
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.post("/analysis/market-shares-evolution", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics.salesAnalysis").service("salesAnalysisMarketSharesEvolutionService", SalesAnalysisMarketSharesEvolutionService);