"use strict";
/**
 * Grouping members history controller.
 *
 * @param {object} $scope - Scope
 * @param {object} groupingMembersGroupingService - Grouping members service
 */

GroupingMembersHistoryController.$inject = ["$scope", "groupingMembersGroupingService"];

function GroupingMembersHistoryController($scope, groupingMembersGroupingService) {
  $scope.loading = true;
  $scope.limitOptions = [10, 25, 50];
  $scope.pagination = {
    current_page: 1,
    per_page: 10
  };
  $scope.getGroupingHistoryActions = getGroupingHistoryActions;
  loader();
  /**
   * Loader.
   */

  function loader() {
    getGroupingHistoryActions();
  }
  /**
   * Get grouping history actions.
   */


  function getGroupingHistoryActions() {
    groupingMembersGroupingService.getGroupingHistoryActions($scope.pagination).then(function (history) {
      $scope.pagination = history.meta.pagination;
      $scope.history = history.data.map(function (record) {
        return record.actionType.isHealthcenterSource ? getHealthcenterRecord(record) : getGroupingRecord(record);
      });
      $scope.loading = false;
    });
  }
  /**
   * Get healthcenter record.
   *
   * @param {object} record - The record
   *
   * @returns {object} The formatted healthcenter record
   */


  function getHealthcenterRecord(record) {
    var icon = {};
    var description = "La pharmacie " + record.healthcenter.name + " ";

    if (record.actionType.action === "join") {
      description += "rejoint votre groupement.";
      icon = {
        "class": "icon-map-marker-radius",
        style: {
          color: "#1FA055"
        }
      };
    } else if (record.actionType.action === "leave") {
      description += "quitte votre groupement.";
      icon = {
        "class": "icon-import",
        style: {
          color: "#A91101"
        }
      };
    }

    return {
      owner: record.user.given + " " + record.user.familyBirth,
      icon: icon,
      description: description,
      date: getDate(new Date(record.actionDate))
    };
  }
  /**
   * Get grouping record.
   *
   * @param {object} record - The record
   *
   * @returns {object} The formatted grouping record
   */


  function getGroupingRecord(record) {
    var icon = {};
    var description = "Votre groupement (" + record.grouping.name + ") ";

    if (record.actionType.action === "accept_request") {
      description += "accepte la pharmacie " + record.healthcenter.name + ".";
      icon = {
        "class": "icon-bookmark-check",
        style: {
          color: "#1FA055"
        }
      };
    } else if (record.actionType.action === "reject_request") {
      description += "refuse la pharmacie " + record.healthcenter.name + ".";
      icon = {
        "class": "icon-close-circle",
        style: {
          color: "#A91101"
        }
      };
    }

    return {
      owner: record.grouping.name,
      icon: icon,
      description: description,
      date: getDate(new Date(record.actionDate))
    };
  }
  /**
   * Get date.
   *
   * @param {Date} date - The date
   *
   * @returns {Date}
   */


  function getDate(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }
}

angular.module("app.grouping").controller("groupingMembersHistoryController", GroupingMembersHistoryController);