"use strict";
/**
 * Branding slider controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $mdToast - $mdToast.
 * @param {string} apiurl - API URL.
 * @param {object} sessionStorageService - Session storage service.
 * @param {object} brandingSliderService - Branding slider service.
 * @param {object} dataModelBrandingSlide - Data model branding slide.
 */

BrandingSliderController.$inject = ["$scope", "$state", "$mdToast", "apiurl", "sessionStorageService", "brandingSliderService", "dataModelBrandingSlide"];

function BrandingSliderController($scope, $state, $mdToast, apiurl, sessionStorageService, brandingSliderService, dataModelBrandingSlide) {
  /**
   * Declaration of public methods
   */
  $scope.back = back;
  $scope.save = save;
  $scope.addSlide = addSlide;
  $scope.deleteSlide = deleteSlide;
  $scope.apiurl = apiurl;
  $scope.token = sessionStorageService.getToken();
  /**
   * Declaration of public objects
   */

  $scope.myImage = {};
  $scope.tinymceOptions = {
    height: 300,
    selector: "textarea",
    // change this value according to your HTML
    plugins: "code visualblocks",
    code_dialog_width: 600,
    content_css: ["//fonts.googleapis.com/css?family=Lato:300,300i,400,400i", "//www.tinymce.com/css/codepen.min.css"],
    style_formats: [{
      title: "Headers",
      items: [{
        title: "h1",
        block: "h1"
      }, {
        title: "h2",
        block: "h2"
      }, {
        title: "h3",
        block: "h3"
      }, {
        title: "h4",
        block: "h4"
      }, {
        title: "h5",
        block: "h5"
      }, {
        title: "h6",
        block: "h6"
      }]
    }, {
      title: "Blocks",
      items: [{
        title: "p",
        block: "p"
      }, {
        title: "div",
        block: "div"
      }, {
        title: "pre",
        block: "pre"
      }]
    }, {
      title: "Containers",
      items: [{
        title: "section",
        block: "section",
        wrapper: true,
        merge_siblings: false
      }, {
        title: "article",
        block: "article",
        wrapper: true,
        merge_siblings: false
      }, {
        title: "blockquote",
        block: "blockquote",
        wrapper: true
      }, {
        title: "hgroup",
        block: "hgroup",
        wrapper: true
      }, {
        title: "aside",
        block: "aside",
        wrapper: true
      }, {
        title: "figure",
        block: "figure",
        wrapper: true
      }]
    }],
    visualblocks_default_state: true,
    end_container_on_empty_block: true,
    theme: "modern",
    toolbar1: "visualblocks | code | formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
    image_advtab: true,
    templates: [{
      title: "Test template 1",
      content: "Test 1"
    }, {
      title: "Test template 2",
      content: "Test 2"
    }]
  };
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * loader to load each method inside
   */

  function loader() {
    getSlider({
      groupingBrandingId: $state.params.groupingBrandingId,
      id: $state.params.id
    });
  }
  /**
   * Get slider.
   *
   * @param {object} slider -
   * @returns {Promise}
   */


  function getSlider(slider) {
    return brandingSliderService.read(slider).then(function (response) {
      $scope.mySlider = response;
      return response;
    });
  }
  /**
   * Save.
   */


  function save() {
    $scope.mySlider.slides.forEach(function (slide) {
      slide.groupingBrandingSliderId = $scope.mySlider.id;
      slide.groupingId = $state.params.groupingId;

      if (slide.file) {
        slide.fileId = slide.file.id;
      }

      brandingSliderService.putSlide(slide).then(function () {
        $mdToast.show($mdToast.simple().textContent("Slider mis à jour").position("top right"));
      });
    });
  }
  /**
   * back
   * to go back on app.branding state
   */


  function back() {
    $state.go("app.branding");
  }
  /**
   * addSlide
   * to add slider.slide on mySlider
   *
   * @returns {Promise}
   */


  function addSlide() {
    return brandingSliderService.postSlide(new dataModelBrandingSlide($scope.mySlider.id)).then(function (response) {
      $scope.mySlider.slides.push(response);
      $mdToast.show($mdToast.simple().textContent("Slide ajouté au slider").position("top right"));
    });
  }
  /**
   * Delete slide.
   *
   * @param {object} slide -
   * @returns {Promise}
   */


  function deleteSlide(slide) {
    slide.groupingBrandingSliderId = $scope.mySlider.id;
    slide.groupingId = $state.params.groupingId;
    return brandingSliderService.deleteSlide(slide).then(function (response) {
      $scope.mySlider.slides.splice($scope.mySlider.slides.findIndex(function (item) {
        return item.id === slide.id;
      }), 1);
      $mdToast.show($mdToast.simple().textContent("Slide supprimé du slider").position("top right"));
      return response;
    });
  }
}

angular.module("app.grouping").controller("brandingSliderController", BrandingSliderController);