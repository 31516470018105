"use strict";
/**
 * Medication summary list controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} medicationSummaryService - Medication summary service.
 * @param {object} $state - $state.
 */

MedicationSummaryListController.$inject = ["$scope", "medicationSummaryService", "$state"];

function MedicationSummaryListController($scope, medicationSummaryService, $state) {
  $scope.getPatients = getPatients();
  $scope.goToPatientMedicationSummary = goToPatientMedicationSummary;
  $scope.query = {
    order: "-name",
    limit: 5,
    page: 1
  };
  $scope.title = {
    title: {
      text: "Liste des patients",
      back: {
        state: "app.patients.medicationSummary",
        tooltip: "Retour à la recherche d'un patient"
      }
    }
  };
  /**
   * Get eligible patient for medication summary.
   *
   * @returns {Promise}
   */

  function getPatients() {
    return medicationSummaryService.getEligiblePatient().then(function (patients) {
      $scope.patients = patients;
      return patients;
    });
  }
  /**
   * Go to the detail of a medication summary
   *
   * @param {object} patient - Patient.
   */


  function goToPatientMedicationSummary(patient) {
    $state.go("app.patients.medicationSummary.patient", {
      id: patient.id
    });
  }
}

angular.module("app.patients").controller("medicationSummaryListController", MedicationSummaryListController);