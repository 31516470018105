"use strict";
/**
 * The plv-thumbnail directive.
 *
 * @returns {object}
 */

PlvThumbnailModalController.$inject = ["$scope", "$mdDialog", "apiurl", "plvsService", "options"];
PlvThumbnailController.$inject = ["$rootScope", "$scope", "$state", "$mdDialog"];

function PlvThumbnail() {
  return {
    restrict: "E",
    scope: {
      // Button options
      buttonTitle: "@buttonTitle",
      buttonIcon: "@buttonIcon",
      buttonTooltip: "@buttonTooltip",
      buttonVariant: "@buttonVariant",
      // Modal options
      modalIcon: "@modalIcon",
      modalTitle: "@modalTitle",
      modalLoader: "=modalLoader",
      modalVariant: "=modalVariant",
      // Thumbnail options
      thumbnailIcon: "@thumbnailIcon",
      thumbnailTooltip: "@thumbnailTooltip",
      thumbnailVariant: "@thumbnailVariant",
      // Thumbnail title options
      thumbnailTitle: "@thumbnailTitle",
      thumbnailTitleVariant: "@thumbnailTitleVariant",
      // Thumbnail description options
      thumbnailDescription: "@thumbnailDescription",
      thumbnailDescriptionVariant: "@thumbnailDescriptionVariant",
      // Thumbnail image options
      thumbnailImage: "@thumbnailImage",
      thumbnailImageVariant: "@thumbnailImageVariant",
      // Thumbnail hover options
      thumbnailHover: "@thumbnailHover",
      thumbnailHoverIcon: "@thumbnailHoverIcon",
      thumbnailHoverVariant: "@thumbnailHoverVariant",
      thumbnailHoverTransclude: "@thumbnailHoverTransclude",
      thumbnailHoverEvent: "=thumbnailHoverEvent",
      // Thumbnail hover active options
      thumbnailHoverActive: "@thumbnailHoverActive",
      thumbnailHoverActiveVariant: "@thumbnailHoverActiveVariant",
      thumbnailHoverActiveIcon: "@thumbnailHoverActiveIcon",
      thumbnailHoverActiveTransclude: "@thumbnailHoverActiveTransclude"
    },
    templateUrl: "app/main/generator/directives/plv-thumbnail/button.html",
    controller: PlvThumbnailController
  };
}
/**
 * PLV thumbnail controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $mdDialog - $mdDialog.
 */


function PlvThumbnailController($rootScope, $scope, $state, $mdDialog) {
  var options = $scope;
  var previousState = $state.current.name.replace("app.", "");
  var currentState = previousState.replace(".", "/");
  $scope.open = open;
  /**
   * open the modal
   */

  function open() {
    $mdDialog.show({
      controller: PlvThumbnailModalController,
      templateUrl: "app/main/generator/directives/plv-thumbnail/modal.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        options: options,
        formatTypes: $scope.myRequest,
        currentState: currentState
      },
      clickOutsideToClose: true
    }).then(function (response) {
      return $rootScope.$broadcast("app.modal:confirm", response);
    });
  }
}
/**
 * PLV thumbnail modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {string} apiurl - API URL.
 * @param {object} plvsService - Plvs service.
 * @param {object} options - Options.
 */


function PlvThumbnailModalController($scope, $mdDialog, apiurl, plvsService, options) {
  $scope.selected = [];
  $scope.options = options;
  $scope.cancel = cancel;
  $scope.confirm = confirm;
  $scope.toggleActive = toggleActive;
  $scope.options.modalLoader.loading = true;
  loader();
  /**
   * loader
   * To call each method inside
   */

  function loader() {
    getPlvs().then(getThumbs);
  }
  /**
   * Get plvs
   *
   * @returns {Promise}
   */


  function getPlvs() {
    return plvsService.load().then(function (plvs) {
      plvs.forEach(function (plv) {
        plv.active = false;
      });
      $scope.plvs = plvs;
      $scope.options.modalLoader.loading = false;
      return plvs;
    });
  }
  /**
   * Get plvs thumbs
   */


  function getThumbs() {
    var type = "thumbs";
    var variant = "small";
    $scope.plvs.forEach(function (plv) {
      plvsService.open(plv).then(function (file) {
        return plvsService.exportVariant(plv.id, type, variant, file).then(function (response) {
          plv.thumb = "".concat(apiurl, "/files/").concat(response.full_path);
        });
      });
    });
  }
  /**
   * cancel
   */


  function cancel() {
    $scope.selected = [];
    $mdDialog.cancel($scope.selected);
  }
  /**
   * Toggle active plv
   *
   * @param {object} plv - The target plv
   * @returns {Array} The array of selected plv
   */


  function toggleActive(plv) {
    plv.active = !plv.active;

    if (!plv.active) {
      return $scope.selected.splice($scope.selected.findIndex(function (select) {
        return select.id === plv.id;
      }), 1);
    }

    return $scope.selected.push(plv);
  }
  /**
   * confirm
   */


  function confirm() {
    $mdDialog.hide($scope.selected);
    $scope.selected = [];
  }
}

angular.module("app.generator").directive("plvThumbnail", PlvThumbnail);