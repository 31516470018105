"use strict";
/**
 * Date time service.
 *
 * @returns {object}
 */

function dateTimeService() {
  /**
   * Convert the date to an iso string.
   * If no date specified, return the current date time.
   *
   * @param {Date|moment|string} date - The date.
   *
   * @returns {string}
   */
  function toDateTimeString() {
    var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
    var SQLFormat = "YYYY-MM-DD HH:mm:ss";
    return moment(date).format(SQLFormat);
  }
  /**
   * Get a human readable date range.
   *
   * @param {moment.Moment} startDate - The start of the range.
   * @param {moment.Moment} endDate - The end of the range.
   */


  function rangeForHuman(startDate, endDate) {
    if (startDate.isSame(endDate, "days")) {
      var dayPart = startDate.format("dddd DD MMMM");

      if (startDate.isSame(endDate)) {
        return dayPart;
      }

      return "".concat(dayPart, " ").concat(startDate.format("HH:mm"), " \xE0 ").concat(endDate.format("HH:mm"));
    }

    if (startDate.isSame(endDate, "months")) {
      return startDate.format("ddd DD") + " - " + endDate.format("ddd DD MMMM");
    }

    return startDate.format("ddd DD MMMM") + " - " + endDate.format("ddd DD MMMM");
  }

  return {
    toDateTimeString: toDateTimeString,
    rangeForHuman: rangeForHuman
  };
}

angular.module("app.services").factory("dateTimeService", dateTimeService);