"use strict";
/**
 * Impact analysis list controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} impactAnalysisService - Impact analysis service.
 */

ImpactAnalysisListController.$inject = ["$scope", "$state", "impactAnalysisService"];

function ImpactAnalysisListController($scope, $state, impactAnalysisService) {
  $scope.limitOptions = [5, 10, 15];
  $scope.query = {
    order: "-startDate",
    limit: 10,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.loaderAnalyses = {
    loading: true,
    loader: "Récupération des analyses en cours",
    empty: "Aucune analyse trouvée"
  };
  $scope.create = create;
  $scope.edit = edit;
  $scope.details = details;
  $scope.deleteAnalysis = deleteAnalysis;
  loader();
  /**
   * Loader.
   */

  function loader() {
    getAnalyses();
  }
  /**
   * Get all analyses.
   *
   * @returns {Promise}
   */


  function getAnalyses() {
    $scope.loaderAnalyses.loading = true;
    $scope.analyses = [];
    return impactAnalysisService.get().then(function (response) {
      $scope.analyses = response.map(function (impactAnalysis) {
        impactAnalysis.start_date = new Date(impactAnalysis.start_date);
        impactAnalysis.end_date = new Date(impactAnalysis.end_date);
        return impactAnalysis;
      });
      $scope.loaderAnalyses.loading = false;
      return response;
    });
  }
  /**
   * Go to create $state.
   *
   * @param {object} event - Event.
   */


  function create(event) {
    event.preventDefault();
    $state.go("app.impactAnalysis.create");
  }
  /**
   * Go to edit $state.
   *
   * @param {object} event - Event.
   * @param {object} analysis - Analysis.
   */


  function edit(event, analysis) {
    event.preventDefault();
    $state.go("app.impactAnalysis.edit", {
      id: analysis.id
    });
  }
  /**
   * Go to details $state.
   *
   * @param {object} event - Event.
   * @param {object} analysis - Analysis.
   */


  function details(event, analysis) {
    event.preventDefault();
    $state.go("app.impactAnalysis.details", {
      id: analysis.id
    });
  }
  /**
   * Delete target analysis
   *
   * @param {object} event - Event.
   * @param {object} analysis - Analysis.
   *
   * @returns {Promise} init getAnalyses method
   */


  function deleteAnalysis(event, analysis) {
    event.preventDefault();
    return impactAnalysisService["delete"](analysis).then(getAnalyses);
  }
}

angular.module("app.impactAnalysis").controller("impactAnalysisListController", ImpactAnalysisListController);