"use strict";
/**
 * Controller for loyalty card member edit.
 *
 * @param {object} $scope - Scope.
 * @param {object} $state - State.
 * @param {object} $stateParams - State params.
 * @param {object} $mdToast - MD toast.
 * @param {object} environmentService - Environment service.
 * @param {object} customerService - Customer service.
 * @param {object} loyaltyCardService - Loyalty card service.
 */

LoyaltyCardMemberEditController.$inject = ["$scope", "$state", "$stateParams", "$mdToast", "environmentService", "customerService", "loyaltyCardService"];

function LoyaltyCardMemberEditController($scope, $state, $stateParams, $mdToast, environmentService, customerService, loyaltyCardService) {
  $scope.select = false; // LoyaltyCardMember

  $scope.loyaltyCardMember = {
    loyaltyCardId: $stateParams.loyaltyCardId,
    patientHash: "",
    startDate: ""
  };
  $scope.isLoyaltyCardWithPoints = false;
  $scope.goToList = goToList;
  $scope.loyaltyCardMemberId = $stateParams.id;
  $scope.createLoyaltyCardMember = createLoyaltyCardMember;
  $scope.updateLoyaltyCardMember = updateLoyaltyCardMember;
  $scope.submit = submit;
  $scope.searchPatient = searchPatient;
  $scope.handlePatientSelected = handlePatientSelected; // Store the point reward type ID to check if there are points as reward

  var pointRewardTypeId = 5;
  loader();
  /**
   * Loader of the controller.
   *
   * @returns {Promise} The promise
   */

  function loader() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.access = access;
      getLoyaltyCard();
      getLoyaltyCardMember();
      return access;
    });
  }
  /**
   * Get loyalty card member.
   */


  function getLoyaltyCardMember() {
    if ($stateParams.id > 0) {
      var loyaltyCardMember = {
        loyaltyCardId: $stateParams.loyaltyCardId,
        id: $stateParams.id
      };
      loyaltyCardService.readMember(loyaltyCardMember).then(function (response) {
        var loyaltyCardMember = response;
        loyaltyCardMember.startDate = new Date(loyaltyCardMember.startDate);
        loyaltyCardMember.endDate = new Date(loyaltyCardMember.endDate);
        $scope.loyaltyCardMember = loyaltyCardMember;
      });
    } else {
      $scope.loyaltyCardMember = {
        loyaltyCardId: $stateParams.loyaltyCardId
      };
    }
  }
  /**
   * Get loyalty card.
   */


  function getLoyaltyCard() {
    var loyaltyCard = {
      id: $stateParams.loyaltyCardId
    };
    loyaltyCardService.read(loyaltyCard).then(function (response) {
      $scope.loyaltyCard = response; // If the card has objectives, get if there are points rewards or not

      if (response.objectives) {
        response.objectives.forEach(function (objective) {
          if (objective.rewardType.id === pointRewardTypeId) {
            $scope.isLoyaltyCardWithPoints = true;
          }
        });
      }
    });
  }
  /**
   * Submit loyalty card member.
   */


  function submit() {
    var loyaltyCardMember = formatData();
    $scope.loyaltyCardMemberId ? $scope.updateLoyaltyCardMember(loyaltyCardMember) : $scope.createLoyaltyCardMember(loyaltyCardMember);
  }
  /**
   * Create loyalty card member.
   *
   * @param {object} loyaltyCardMember - The loyalty card
   *
   * @returns {Promise} The promise
   */


  function createLoyaltyCardMember(loyaltyCardMember) {
    return loyaltyCardService.createMember(loyaltyCardMember).then(function (response) {
      $mdToast.show($mdToast.simple().textContent("La carte fidélité du patient est générée.").position("top right"));
      goToList();
      return response;
    });
  }
  /**
   * Update loyalty card member.
   *
   * @param {object} loyaltyCardMember - The loyalty card
   *
   * @returns {Promise} The promise
   */


  function updateLoyaltyCardMember(loyaltyCardMember) {
    loyaltyCardMember.id = $stateParams.id;
    return loyaltyCardService.updateMember(loyaltyCardMember).then(function (response) {
      $mdToast.show($mdToast.simple().textContent("La carte de fidélité du patient est modifiée.").position("top right"));
      goToList();
      return response;
    });
  }
  /**
   * Format the data
   *
   * @returns {object} The formatted loyalty card member
   */


  function formatData() {
    var startDate = moment($scope.loyaltyCardMember.startDate).format("YYYY-MM-DD");
    return {
      loyaltyCardId: $scope.loyaltyCardMember.loyaltyCardId,
      patientId: $scope.selectedPatient.id,
      amountOfPoints: $scope.loyaltyCardMember.amountOfPoints ? $scope.loyaltyCardMember.amountOfPoints : 0,
      startDate: startDate
    };
  }
  /**
   * Search a patient by name fisrtname No SS
   *
   * @param {string} searchText - The search text.
   *
   * @returns {object}
   */


  function searchPatient(searchText) {
    if (searchText) {
      return customerService.getCustomersBySearch(searchText).then(function (customers) {
        $scope.total = customers.meta.total;
        return customerService.extractPatientsFromCustomers(customers);
      });
    }

    return Promise.resolve();
  }
  /**
   * Handle patient selected.
   *
   * @param {object} selectedPatient - The selected patient.
   */


  function handlePatientSelected(selectedPatient) {
    $scope.selectedPatient = selectedPatient;
  }
  /**
   * Go to loyaltyCardMember list.
   */


  function goToList() {
    $state.go("app.loyaltyCard");
  }
}

angular.module("app.loyaltyCard").controller("loyaltyCardMemberEditController", LoyaltyCardMemberEditController);