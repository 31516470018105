"use strict";
/**
 * Create patient event controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $state - $state.
 * @param {string} apiurl - API URL.
 * @param {object} eventService - The events service.
 * @param {object} productService - The product service.
 * @param {object} notifyUserService - The notify user service.
 * @param {object} patientEventService - The patient event service.
 * @param {object} dateTimeService - The date time service.
 */

PatientCreateEventController.$inject = ["$scope", "$rootScope", "$state", "apiurl", "eventService", "productService", "notifyUserService", "patientEventService", "dateTimeService"];

function PatientCreateEventController($scope, $rootScope, $state, apiurl, eventService, productService, notifyUserService, patientEventService, dateTimeService) {
  $scope.event = {};
  $scope.newEventTitle = {
    title: {
      text: "Création d'un événement",
      back: {
        state: "app.patients.events",
        tooltip: "Retour au calendrier"
      }
    }
  };
  $scope.colorPickerOptions = {
    label: "Sélectionnez la couleur de l'événement",
    random: true,
    openOnInput: true
  };
  $scope.availableHours = generateHours(9, 21);
  $scope.color = "#214B63";
  $scope.hour = {};
  $scope.startDate = moment();
  $scope.endDate = moment();
  $scope.minDate = $scope.startDate.clone().toDate();
  $scope.handleSelectedDateRange = handleSelectedDateRange;
  $scope.isValidEvent = isValidEvent;
  $scope.createEvent = createEvent;
  $scope.startDateChange = startDateChange;
  $scope.endDateChange = endDateChange;
  $scope.products = [];
  $scope.deleteProductFromList = deleteProductFromList;
  $scope.goBack = goBack;
  $scope.eventCreateProductSearch = {
    event: "addProductsInPatientEvent",
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    searchPlaceHolder: "Chercher un produit à ajouter",
    tag: "configProductEventCration",
    autoLaunchEvent: true
  };
  $scope.form = {};
  /**
   * Handles selected date range.
   *
   * @param {object} date - The date range.
   */

  function handleSelectedDateRange(date) {
    $scope.startDate = moment(date.startDate, "DD-MM-YYYY");
    $scope.endDate = moment(date.endDate, "DD-MM-YYYY");
  }
  /**
   * Determine whether the event scope is valid.
   *
   * @returns {boolean}
   */


  function isValidEvent() {
    if ($scope.startDate && $scope.endDate && $scope.event.title && $scope.event.description) {
      return true;
    }

    return false;
  }
  /**
   * Generates a hours array.
   *
   * @param {number} from - Hour from.
   * @param {number} to - Hour to.
   *
   * @returns {Array}
   */


  function generateHours(from, to) {
    var items = [];

    for (var index = from; index < to; index++) {
      items.push(moment({
        hour: index
      }));
    }

    return items;
  }
  /**
   * Create the event.
   *
   * @returns {void}
   */


  function createEvent() {
    $scope.form.createPatientEventForm.$setSubmitted();

    if (!$scope.form.createPatientEventForm.$valid) {
      return;
    }

    var startDateTime = $scope.startDate.set({
      hour: $scope.hour.from ? $scope.hour.from.format("HH") : null,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    var endDateTime = $scope.endDate.set({
      hour: $scope.hour.to ? $scope.hour.to.format("HH") : null,
      minute: 0,
      second: 0,
      millisecond: 0
    }).format();
    var event = {
      title: $scope.event.title,
      description: $scope.event.description,
      start_date: dateTimeService.toDateTimeString(startDateTime),
      end_date: dateTimeService.toDateTimeString(endDateTime),
      event_type_id: 15,
      is_public: true,
      image: $scope.event.image,
      products: $scope.products
    };
    eventService.create(event).then(function (response) {
      notifyUserService.showSuccessToast("L'événement « " + response.title + " » a bien été créé.");
      patientEventService.sendPushNotification(response);
      goBack();
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur c'est produite, veuillez réessayer.");
    });
  }
  /**
   * Delete a product from the product list.
   *
   * @param {Array} list - The product list
   * @param {number} index - The index to delete
   */


  function deleteProductFromList(list, index) {
    list.splice(index, 1);
  }

  $rootScope.$on("addProductsInPatientEvent", function (event, data) {
    var queryProduct = {};

    if (_.isUndefined($scope.products)) {
      $scope.products = [];
    }

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    }

    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.products.push(product);
        }
      });
    });
  });
  /**
   * Go back to the events list.
   */

  function goBack() {
    $state.go("app.patients.events");
  }
  /**
   * When start date change, update the end date if it's before the start.
   *
   * @param {object} date - The updated date.
   */


  function startDateChange(date) {
    var momentDate = moment(date);
    $scope.startDate = momentDate;

    if (momentDate.isAfter($scope.endDate)) {
      $scope.endDate = momentDate.clone();
    }

    $scope.minDate = date;
  }
  /**
   * When endDate change, cast the value to moment value.
   *
   * @param {object} date - The updated date.
   */


  function endDateChange(date) {
    $scope.endDate = moment(date);
  }
}

angular.module("app.patients").controller("createPatientsEventController", PatientCreateEventController);