"use strict";
/**
 * Patient purchase filter directive.
 *
 * @returns {object}
 */

PatientPurchaseFilterController.$inject = ["$scope", "productService"];

function PatientPurchaseFilterDirective() {
  return {
    restrict: "E",
    templateUrl: "app/main/patients-search/filters/purchase/patient.purchase.filter.html",
    controller: PatientPurchaseFilterController
  };
}
/**
 * Patient purchase filter controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} productService - Product service.
 */


function PatientPurchaseFilterController($scope, productService) {
  $scope.removeProduct = removeProduct;
  $scope.startDate = null;
  $scope.endDate = null;
  $scope.products = [];
  var tag = "purchaseFilterTag" + $scope.index;
  var event = "purchaseFilterEvent" + $scope.index;
  $scope.searchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "category", "segmentProduct"],
    tag: tag,
    event: event,
    autoLaunchEvent: true,
    searchPlaceHolder: "Rechercher des produits"
  };
  $scope.$on(event, function (event, data) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    }

    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.products.push(product);
        }
      });
    });
  });
  $scope.$watchCollection("products", function (value) {
    if (value) {
      $scope.filter.data.productIds = $scope.products.map(function (product) {
        return product.id;
      });
    }
  });
  $scope.$watch("startDate", function (value) {
    if (value) {
      $scope.filter.data.startDate = moment(value).format("YYYY-MM-DD");
    } else {
      $scope.filter.data.startDate = null;
    }
  });
  $scope.$watch("endDate", function (value) {
    if (value) {
      $scope.filter.data.endDate = moment(value).format("YYYY-MM-DD");
    } else {
      $scope.filter.data.startDate = null;
    }
  });
  /**
   * Remove product.
   *
   * @param {number} index - Index.
   */

  function removeProduct(index) {
    $scope.products.splice(index, 1);
  }
}

angular.module("apodis").directive("patientPurchaseFilter", PatientPurchaseFilterDirective);