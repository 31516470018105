"use strict";
/**
 * Order reject modal controller.
 *
 * @param {object} $scope - $scope
 * @param {object} $mdDialog - $mdDialog
 * @param {object} $timeout - The angular $timeout object.
 * @param {object} order - The order object.
 * @param {object} customerService - The customerService.
 * @param {object} dateTimeService - The dateTimeService.
 * @param {object} notifyUserService - The notify user service.
 */

OrderRejectModalController.$inject = ["$scope", "$mdDialog", "$timeout", "order", "customerService", "dateTimeService", "notifyUserService"];

function OrderRejectModalController($scope, $mdDialog, $timeout, order, customerService, dateTimeService, notifyUserService) {
  $scope.close = close;
  $scope.rejectOrder = rejectOrder;
  $scope.isLoading = false;
  $scope.comment;
  $scope.order = order;
  /**
   * Rejects a prescription renew.
   *
   * @returns {void}
   */

  function rejectOrder() {
    if ($scope.isLoading) {
      return;
    }

    $scope.isLoading = true;
    var params = {
      rejected_at: dateTimeService.toDateTimeString(),
      operator_comment: $scope.comment
    };
    customerService.updateOrder($scope.order.id, params).then(function (response) {
      $scope.order.status = response.data.status;
      $scope.order.rejected_at = response.data.rejected_at;
      $scope.operator_comment = response.data.operator_comment;
      notifyUserService.showSuccessToast("La commande a bien été mise à jour");
      close();
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue");
    })["finally"](function () {
      $scope.isLoading = false;
    });
  }
  /**
   * Closes the modal.
   *
   * @returns {void}
   */


  function close() {
    $mdDialog.hide();
  }
}

angular.module("app.patients").controller("orderRejectModalController", OrderRejectModalController);