"use strict";
/**
 * Merchandising plan configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

MerchandisingPlanConfig.$inject = ["$stateProvider"];

function MerchandisingPlanConfig($stateProvider) {
  $stateProvider.state("app.merchandisingPlanMain", {
    url: "/merchandising-plans",
    views: {
      "content@app": {
        templateUrl: "app/main/merchandising-plan/views/main/merchandising-plan.main.html",
        controller: "merchandisingPlanMainController"
      }
    }
  }).state("app.merchandisingPlanFile", {
    url: "/merchandising-plans/:merchandisingPlanId/files",
    views: {
      "content@app": {
        templateUrl: "app/main/merchandising-plan/views/file/merchandising-plan.file.html",
        controller: "merchandisingPlanFileController"
      }
    },
    params: {
      merchandisingPlan: null
    }
  });
}

angular.module("app.merchandising-plan", []).config(MerchandisingPlanConfig);