"use strict";
/**
 * Search bar without checkboxes.
 *
 * @returns {object}
 */

SearchBarWithoutCheckboxesController.$inject = ["$scope", "$rootScope", "autocompleteFiltersService", "environmentService", "StatisticsQueryService"];

function SearchBarWithoutCheckboxes() {
  return {
    restrict: "E",
    scope: {
      config: "@config",
      select: "@select",
      lengthSelected: "@lengthSelected"
    },
    templateUrl: "app/main/directives/search-bar/searchBarWithoutCheckboxes/searchBarWithoutCheckboxes.html",
    transclude: true,
    controller: SearchBarWithoutCheckboxesController,
    controllerAs: "vm"
  };
}
/**
 * Search bar simple controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} autocompleteFiltersService - Autocomplete filters service.
 * @param {object} environmentService - Environment service.
 * @param {object} StatisticsQueryService - Statistics query service.
 */


function SearchBarWithoutCheckboxesController($scope, $rootScope, autocompleteFiltersService, environmentService, StatisticsQueryService) {
  var vm = this;
  vm.config = JSON.parse($scope.config);

  if (typeof $scope.select !== "undefined") {
    vm.select = JSON.parse($scope.select);
  } else {
    vm.select = true;
  }

  vm.service = vm.config.service;
  vm.search = vm.config.search;
  vm.limitsByType = vm.config.limits;
  $scope.definitionsDisplay = autocompleteFiltersService.definitionsDisplay;
  $scope.resultsFromAllStats = [];
  $scope.autocomplete = autocompleteFiltersService.autocomplete;
  $scope.selectedObjChange = autocompleteFiltersService.selectedObjChange;
  $scope.selectedFilterResult = {};
  $scope.usrquery = StatisticsQueryService.query;
  $scope.searchTypesCount = 0;
  $scope.viewAttributes = {
    searchPlaceHolder: "Rechercher une pharmacie..."
  };
  $scope.searchTextChange = searchTextChange;
  $scope.selectedCheckBoxToggle = selectedCheckBoxToggle;

  if (typeof vm.config.tag !== "undefined") {
    vm.tag = vm.config.tag;
    $scope.autocomplete.model.input[vm.tag] = "";
  } else {
    vm.tag = "tag";
  }
  /**
   * Get options.
   *
   * @returns {object}
   */


  function getOptions() {
    var geoForce = false;
    /** Get only healthcenters */

    var requestType = ["idOrgas"];
    var must = null;
    var length = {
      length: 30
    };
    return {
      requestType: requestType,
      must: must,
      length: length,
      tag: vm.tag,
      geoForce: geoForce,
      isFilteredOnSanteSecure: vm.config.isFilteredOnSanteSecure
    };
  }
  /**
   * Search on text change.
   */


  function searchTextChange() {
    if ($scope.autocomplete.model.input[vm.tag].length > 2) {
      $scope.loading = true;
      $scope.resultsFromAllStats = {};
      $scope.searchCacheQuery = autocompleteFiltersService.getAll(getOptions()).then(function (datas) {
        $scope.resultsFromAllStats = datas;
        $scope.searchTypesCount = Object.keys($scope.resultsFromAllStats).length;
        $scope.loading = false;
      });
    } else {
      $scope.resultsFromAllStats = {};
      $scope.searchTypesCount = Object.keys($scope.resultsFromAllStats).length;
    }
  }
  /**
   * Active link by toggle on result.
   *
   * @param {object} res - The res.
   * @param {string} nameType - The name type.
   */


  function selectedCheckBoxToggle(res, nameType) {
    if (typeof $scope.selectedFilterResult[nameType] === "undefined") {
      $scope.selectedFilterResult[nameType] = [];
    }

    if (vm.limitsByType && vm.limitsByType[nameType] > 0) {
      if (typeof $scope.selectedFilterResult[nameType][res.id] !== "undefined") {
        $scope.selectedFilterResult[nameType] = [];
      } else {
        if (vm.limitsByType.combined === true) {
          angular.forEach($scope.selectedFilterResult, function (selectedFilter, key) {
            $scope.selectedFilterResult[key] = [];
          });
        }

        $scope.selectedFilterResult[nameType] = [];
        $scope.selectedFilterResult[nameType][res.id] = res;
      }
    } else if (typeof $scope.selectedFilterResult[nameType][res.id] === "undefined") {
      $scope.selectedFilterResult[nameType][res.id] = res;
    } else {
      delete $scope.selectedFilterResult[nameType][res.id];

      if (Object.keys($scope.selectedFilterResult[nameType]).length === 0) {
        delete $scope.selectedFilterResult[nameType];
      }
    }

    if (vm.config.autoLaunchEvent) {
      $scope.results = [];
      angular.forEach($scope.selectedFilterResult, function (dataType, nameType) {
        // Ajoute les nouveaux filtres
        angular.forEach(dataType, function (res) {
          if (typeof $scope.results[nameType] === "undefined") {
            $scope.results[nameType] = [];
          }

          $scope.results[nameType].push(res);
          $scope.usrquery.must[nameType].selected.push(res);
        });
      });
      $rootScope.$broadcast(vm.config.event, $scope.results);
    }
  }

  $scope.$on("autocompleteFiltersService:modelChange", function (event, data) {
    $scope.autocomplete = data;
  });
  $scope.$watch("config", function (newValue) {
    vm.config = JSON.parse(newValue);
  });
}

angular.module("app.directives").directive("searchBarWithoutCheckboxes", SearchBarWithoutCheckboxes);