"use strict";
/**
 * Laboratories average shopping cart service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

LaboratoriesAverageShoppingCartService.$inject = ["apiClient"];

function LaboratoriesAverageShoppingCartService(apiClient) {
  return {
    /**
     * Get.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.post("/analysis/average-shopping-cart", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.dashboard").service("laboratoriesAverageShoppingCartService", LaboratoriesAverageShoppingCartService);