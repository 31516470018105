"use strict";
/**
 * The app-modal directive.
 *
 * @returns {object}
 */

function AppModal() {
  return {
    restrict: "EA",
    scope: {
      variant: "@variant"
    },
    template: "<md-dialog id='appModal'><div class='app-modal' ng-transclude></div></md-dialog>",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appModal", AppModal);