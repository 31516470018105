"use strict";
/**
 * The app-icon directive.
 *
 * @returns {object}
 */

function AppIcon() {
  return {
    restrict: "E",
    scope: {
      icon: "@icon",
      tooltip: "@tooltip"
    },
    templateUrl: "app/main/directives/app-icon/appIcon.html",
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appIcon", AppIcon);