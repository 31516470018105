"use strict";
/**
 * PatientAdministrationPathologyAdministration controller.
 *
 * @param {object} $scope - $scope object.
 * @param {object} pathologyService - The pathology service.
 */

PatientAdministrationPathologyAdministrationController.$inject = ["$scope", "pathologyService"];

function PatientAdministrationPathologyAdministrationController($scope, pathologyService) {
  $scope.selectedPathologyChange = selectedPathologyChange;
  $scope.pathologies = loadPathologies;
  /**
   * Load pathologies.
   *
   * @param {string} search - Search text.
   *
   * @returns {Promise}
   */

  function loadPathologies(search) {
    var params = [];

    if (search) {
      params = [{
        name: "search",
        value: search
      }];
    }

    return pathologyService.indexPathologies(params).then(function (response) {
      return response;
    });
  }
  /**
   * Handle selected pathology change.
   *
   * @param {object} pathology - The selected pathology.
   *
   * @returns {void}
   */


  function selectedPathologyChange(pathology) {
    $scope.selectedPathology = pathology;
  }
}

angular.module("app.patient-administration").controller("patientAdministrationPathologyAdministrationController", PatientAdministrationPathologyAdministrationController);