"use strict";
/**
 * User laboratory branding catalog controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $q - $q.
 * @param {string} apiurl - API URL.
 * @param {object} laboratoryNeedService - Laboratory need service.
 * @param {object} cartService - Cart service.
 * @param {object} sessionStorageService - Session storage service.
 */

UserLaboratoryBrandingCatalogController.$inject = ["$scope", "$rootScope", "$stateParams", "$q", "apiurl", "laboratoryNeedService", "cartService", "sessionStorageService"];

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function UserLaboratoryBrandingCatalogController($scope, $rootScope, $stateParams, $q, apiurl, laboratoryNeedService, cartService, sessionStorageService) {
  /**
   * Declaration of public objects
   */
  $scope.apiurl = apiurl;
  $scope.token = sessionStorageService.getToken();
  $scope.query = {
    order: "-name",
    limit: 10,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.loadingProducts = {
    loading: true,
    loader: "Récupération des produits",
    empty: "Aucun produit dans cette gamme"
  };
  $scope.universeEvent = "universeSelected";
  $scope.rangeEvent = "rangeSelected";
  $scope.products = [];
  /**
   * Get products of target rqnge.
   *
   * @param {object} range - Range.
   *
   * @returns {Promise}
   */

  function getProducts(range) {
    var queryCart = {
      laboratoryId: $stateParams.laboratoryId,
      healthcenterId: $rootScope.environment.entity.id
    };
    var queryProducts = {
      laboratoryId: $stateParams.laboratoryId,
      rangeId: range.id
    };
    $scope.loadingProducts.loader = "Nous recherchons les produits";
    return $q.all([cartService.getCart(queryCart), laboratoryNeedService.getByRange(queryProducts)]).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          cart = _ref2[0],
          products = _ref2[1];

      range.productsQuantity = products.length;
      $scope.products = products.map(function (product) {
        angular.merge(product, product.laboratoryShopPrice);
        var productLine = cart.lines.find(function (line) {
          return line.product.id === product.productId;
        });
        product.quantity = productLine ? productLine.quantity : 0;
        return product;
      });
      $scope.loadingProducts.loading = false;
      return products;
    });
  }
  /**
   * Get products for ranges.
   *
   * @param {Array} ranges - Ranges.
   */


  function getProductsForEachRanges(ranges) {
    ranges.map(function (range) {
      return range.childrenRanges ? range.childrenRanges : range;
    }).flat().forEach(getProducts);
  }
  /**
   * Update by event.
   */


  function updateByEvent() {
    if (!$rootScope.currentUniverse) {
      getProductsForEachRanges($scope.branding.parentRanges);
    }

    if ($rootScope.currentUniverse && !$rootScope.currentRange) {
      getProductsForEachRanges($rootScope.currentUniverse.childrenRanges);
    }

    if ($rootScope.currentRange) {
      getProducts($rootScope.currentRange);
    }
  }

  $rootScope.$watch("laboratoryBranding", function (branding) {
    if (branding) {
      $scope.branding = branding;
      getProductsForEachRanges(branding.parentRanges);
    }
  });
  $rootScope.$on("universeSelected", function (event, universe) {
    $scope.products = [];
    $rootScope.currentRange = null;

    if (!universe) {
      $rootScope.currentUniverse = null;
      getProductsForEachRanges($scope.branding.parentRanges);
      return;
    }

    $rootScope.currentUniverse = universe;
    universe.childrenRanges.forEach(getProducts);
  });
  $rootScope.$on("rangeSelected", function (event, range) {
    $scope.products = [];
    $rootScope.currentRange = range;

    if (!range) {
      $rootScope.currentRange = null;

      if (!$rootScope.currentUniverse) {
        $rootScope.currentUniverse = null;
        return null;
      }

      $rootScope.currentUniverse.childrenRanges.forEach(getProducts);
      return getProductsForEachRanges($scope.branding.parentRanges);
    }

    return getProducts(range);
  });
  $rootScope.$on("cart:clear", function () {
    $scope.products.forEach(function (product) {
      product.quantity = 0;
    });
  });
  $rootScope.$on("cart:update", updateByEvent);
  $rootScope.$on("cart:delete", updateByEvent);
}

angular.module("app.users-laboratory").controller("userLaboratoryBrandingCatalogController", UserLaboratoryBrandingCatalogController);