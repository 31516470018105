"use strict";
/**
 * Healthcenter stock audit detail controller.
 *
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $window - $window.
 * @param {object} healthcenterService - Healthcenter service.
 */

HealthcenterStockAuditDetailController.$inject = ["StatisticsQueryService", "environmentService", "$state", "$stateParams", "$window", "healthcenterService"];

function HealthcenterStockAuditDetailController(StatisticsQueryService, environmentService, $state, $stateParams, $window, healthcenterService) {
  var vm = this;

  if (typeof $stateParams.id === "undefined") {
    vm.goBack();
  }

  vm.goBack = function () {
    $window.history.back();
  };

  healthcenterService.getDetails({
    id: $stateParams.id
  }).then(function (pharma) {
    vm.pharma = pharma.healthcenter;
    environmentService.getEnvironment().then(function (env) {
      vm.currentEnv = env;
      StatisticsQueryService.queryRebase();

      switch ($state.current.name) {
        case "app.healthcenterAudit.detail.general":
          vm.selected = 0;
          break;

        case "app.healthcenterAudit.detail.goumy":
          vm.selected = 1;
          break;

        case "app.healthcenterAudit.detail.realStock":
          vm.selected = 2;
          break;

        case "app.healthcenterAudit.detail.reassort":
          vm.selected = 3;
          break;

        case "app.healthcenterAudit.detail.purchases":
          vm.selected = 4;
          break;

        case "app.healthcenterAudit.detail.distributor":
          vm.selected = 5;
          break;

        default:
          vm.selected = 0;
      }
    });
  });
}

angular.module("app.healthcenterStockAudit").controller("healthcenterStockAuditDetailController", HealthcenterStockAuditDetailController);