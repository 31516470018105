"use strict";
/**
 * Patient create appointment controller.
 *
 * @param {object} $scope - Angular $scope object.
 * @param {object} notifyUserService - The notify user service.
 * @param {object} appointmentsFactory -  - The appointments factory.
 * @param {object} customerService - The customer service.
 * @param {object} teamService - The team service.
 * @param {object} eventService - The event service.
 * @param {object} dateTimeService - The datetime service.
 * @param {moment.Moment} startDate - The startDate.
 * @param {moment.Moment} endDate - The endDate.
 * @param {object} patientAppointmentsService - The patient appointment service.
 * @param {object} patientEventService - The patient event service.
 * @param {object} environmentService - The environment service.
 * @param {object} healthcenterService - The healthcenter service.
 * @param {Function} callback - The callback function.
 * @param {object} event - The event for update.
 */

PatientCreateAppointmentController.$inject = ["$scope", "notifyUserService", "appointmentsFactory", "customerService", "teamService", "eventService", "dateTimeService", "startDate", "endDate", "patientAppointmentsService", "patientEventService", "environmentService", "healthcenterService", "callback", "event"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function PatientCreateAppointmentController($scope, notifyUserService, appointmentsFactory, customerService, teamService, eventService, dateTimeService, startDate, endDate, patientAppointmentsService, patientEventService, environmentService, healthcenterService, callback, event) {
  var vm = this;
  $scope.appointmentTypes = appointmentsFactory.eventTypes;
  $scope.handleAppointmentTypeSelected = handleAppointmentTypeSelected;
  $scope.handlePatientSelected = handlePatientSelected;
  $scope.submit = submit;
  $scope.bookingDuration = appointmentsFactory.bookingDuration;
  $scope.searchPatient = searchPatient;
  $scope.rangeForHuman = dateTimeService.rangeForHuman;
  $scope.loadTeam = loadTeam;
  $scope.times = generateTimes();
  $scope.exists = exists;
  $scope.handleCustomerChecked;
  $scope.appointment = {
    frequency: null
  };
  var healthcenterOpening;
  initialize();
  /**
   * Initialization function.
   */

  function initialize() {
    if (event) {
      $scope.appointment = initializeEvent(event);
    } else {
      $scope.appointment.startDate = startDate ? startDate.toDate() : new Date();
      $scope.appointment.endDate = endDate ? endDate.toDate() : null;
      $scope.appointment.bookingDuration = 15;
      $scope.appointment.customers = [];
    }

    loadTeam();
    loadOpening();
  }
  /**
   * Load healthcenter opening hours.
   */


  function loadOpening() {
    environmentService.getEnvironment().then(function (environment) {
      healthcenterService.getOpening(environment.entityId).then(function (opening) {
        healthcenterOpening = opening;
      });
    });
  }
  /**
   * Handle appointment type selected.
   *
   * @param {object} type - The type object.
   */


  function handleAppointmentTypeSelected(type) {
    $scope.appointment.typeId = type.id;
  }
  /**
   * Initialize event for editing.
   *
   * @param {object} event - The event id.
   *
   * @returns {object}
   */


  function initializeEvent(event) {
    var startTime = moment(event.startCopy).format("HH:mm");
    var endTime = moment(event.endCopy).format("HH:mm");
    return {
      startDate: event.start.toDate(),
      endDate: event.end.clone().subtract(1, "day").toDate(),
      startTime: startTime,
      endTime: endTime,
      title: event.title,
      description: event.description,
      typeId: event.event_type ? event.event_type.id : null,
      bookingDuration: event.booking_duration,
      operator: event.custom_event ? event.custom_event.manager : null
    };
  }
  /**
   * Submit the form.
   *
   * @returns {Promise|null}
   */


  function submit() {
    $scope.forms.appointmentForm.$setSubmitted();

    if (!$scope.forms.appointmentForm.$valid) {
      return null;
    }

    var event = formatEvent();

    if (!healthcenterOpening.success) {
      notifyUserService.showDangerToast("Vous devez remplir vos horaires d'ouverture afin de créer des événements à créneau réservable");
      return null;
    }

    if (vm.event) {
      return update(event);
    }

    return createEvent(event);
  }
  /**
   * Update the event.
   *
   * @param {object} event - The updated event.
   *
   * @returns {Promise}
   */


  function update(event) {
    var updatedEvent = _objectSpread(_objectSpread({}, event), {}, {
      id: vm.event.id
    });

    return eventService.update(updatedEvent).then(function () {
      callback();
    });
  }
  /**
   * Load the current healthcenter team.
   *
   * @returns {Promise<void>}
   */


  function loadTeam() {
    return teamService.getMyTeam().then(function (teams) {
      $scope.operators = teams.team.map(function (teamMember) {
        return teamMember.user;
      });
    });
  }
  /**
   * Search patient.
   *
   * @param {string} searchText - The search text query.
   *
   * @returns {Promise}
   */


  function searchPatient(searchText) {
    if (!searchText || searchText < 3) {
      return Promise.resolve(null);
    }

    return customerService.getCustomersBySearch(searchText).then(function (result) {
      var patients = result.data;
      return patients.map(function (patient) {
        return {
          id: patient.id,
          name: patient.information.name
        };
      });
    });
  }
  /**
   * Redirect to the selected patient profile.
   *
   * @param {object} patient - The selected patient.
   */


  function handlePatientSelected(patient) {
    if (!$scope.appointment.customers || !$scope.appointment.customers.length) {
      $scope.appointment.customers = [patient];
    }

    var customerIndex = $scope.appointment.customers.indexOf(patient);

    if (customerIndex !== -1) {
      $scope.appointment.customers.splice(customerIndex, 1);
    } else {
      $scope.appointment.customers.push(patient);
    }
  }
  /**
   * Format event before creation.
   *
   * @returns {object}
   */


  function formatEvent() {
    var startTime = moment($scope.appointment.startTime, "HH:mm");
    var startDateTime = $scope.appointment.startDate.setHours(startTime ? startTime.hours() : null, startTime ? startTime.minutes() : null);
    var endTime = moment($scope.appointment.endTime, "HH:mm");
    var endDateTime = $scope.appointment.endDate.setHours(endTime ? endTime.hours() : null, endTime ? endTime.minutes() : null);
    return {
      title: $scope.appointment.title,
      description: $scope.appointment.description,
      start_date: dateTimeService.toDateTimeString(startDateTime),
      end_date: dateTimeService.toDateTimeString(endDateTime),
      manager_id: $scope.appointment.operator ? $scope.appointment.operator.id : null,
      event_type_id: $scope.appointment.typeId,
      is_public: true,
      bookable: true,
      booking_duration: $scope.appointment.bookingDuration
    };
  }
  /**
   * Create the event.
   *
   * @returns {Promise}
   */


  function createEvent(event) {
    return eventService.create(event).then(function (response) {
      notifyUserService.showSuccessToast("L'évènement a bien été créé.");
      patientEventService.sendPushNotification(response);
      callback();
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur s'est produite, veuillez réessayer.");
    });
  }
  /**
   * Check if a given customer exists in the customers appointment array.
   *
   * @param {object} customer - The customer to check.
   *
   * @returns {boolean}
   */


  function exists(customer) {
    if (!$scope.appointment.customers || !$scope.appointment.customers.length) {
      return false;
    }

    return $scope.appointment.customers.indexOf(customer) !== -1;
  }
  /**
   * Generate times array.
   *
   * @returns {Array}
   */


  function generateTimes() {
    return new Array(24).fill(0).flatMap(function (value, index) {
      return [moment({
        hour: index
      }), moment({
        hour: index,
        minute: 30
      })];
    });
  }
}

angular.module("app.patients.components").controller("patientCreateAppointmentController", PatientCreateAppointmentController);