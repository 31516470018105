"use strict";
/**
 * Purchases by healthcenter controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} groupingDashboardCapService - Grouping dashboard cap service.
 */

PurchasesByHealthcenterController.$inject = ["$scope", "groupingDashboardCapService"];

function PurchasesByHealthcenterController($scope, groupingDashboardCapService) {
  $scope.dashboardLoading = true;
  $scope.pagination = {
    current_page: 1,
    per_page: 10,
    limit_options: [10, 25, 50, 100, 200]
  };
  $scope.pharmaClicked = null;
  $scope.goToDetailProduct = goToDetailProduct;
  $scope.showAll = showAll;
  loader();
  /**
   * Loader.
   */

  function loader() {
    getPurchasesByHealthcenter();
  }
  /**
   * Show all the purchases.
   */


  function showAll() {
    $scope.purchases = $scope.purchasesSaved;
    $scope.pharmaClicked = null;
  }
  /**
   * Retrieve the data.
   */


  function getPurchasesByHealthcenter() {
    $scope.dashboardLoading = true;
    groupingDashboardCapService.getPurchasesByHealthcenter().then(function (purchases) {
      if (purchases) {
        $scope.purchases = purchases.aggregations.pharmacy.buckets;
        $scope.purchasesSaved = purchases.aggregations.pharmacy.buckets;
      } else {
        $scope.purchases = [];
        $scope.purchasesSaved = [];
      }

      $scope.dashboardLoading = false;
    });
  }
  /**
   * Show the detail product
   *
   * @param {object} purchase - Purchase.
   */


  function goToDetailProduct(purchase) {
    if (purchase.products) {
      $scope.pharmaClicked = purchase.key;
      $scope.purchases = purchase.products.buckets;
    }
  }
}

angular.module("app.dashboard").controller("purchasesByHealthcenterController", PurchasesByHealthcenterController);