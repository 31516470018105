"use strict";
/**
 * Patient orders list controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} orderFactory - The order factory.
 * @param {object} customerService - The customer service.
 * @param {object} notifyUserService - The notify user service.
 */

PatientOrdersListController.$inject = ["$scope", "$state", "$stateParams", "orderFactory", "customerService", "notifyUserService"];

function PatientOrdersListController($scope, $state, $stateParams, orderFactory, customerService, notifyUserService) {
  $scope.ordersTitle = {
    title: {
      text: "Liste des commandes de ",
      back: {
        state: null,
        tooltip: "Retour aux details du patient."
      }
    }
  };
  $scope.query = {
    limit: 5,
    limitOptions: [5, 10, 15],
    page: 1,
    total: null
  };
  $scope.orders = null;
  $scope.loading = true;
  $scope.typeMapping = orderFactory.typeMapping;
  $scope.getOrders = getOrders;
  $scope.redirectToOrderDetails = redirectToOrderDetails;
  loader();
  /**
   * Loader function.
   */

  function loader() {
    if ($stateParams.beneficiaryId) {
      getBeneficiaryName();
    }

    getOrders().then(function () {
      if ($scope.orders.length && !$stateParams.beneficiaryId) {
        $scope.ordersTitle.title.text += $scope.orders[0].customer.information.name;
      }
    });
  }
  /**
   * Get orders and put it in the $scope.
   *
   * @returns {Promise}
   */


  function getOrders() {
    var params = {
      page: $scope.query.page,
      limit: $scope.query.limit,
      beneficiary_id: $stateParams.beneficiaryId || 0
    };
    return customerService.getCustomerOrders($stateParams.patientId, params).then(function (response) {
      $scope.orders = response.data;
      $scope.query.total = response.meta.total;
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue, veuillez réessayer");
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Get the beneficiary name and update the title config.
   *
   * @returns {Promise<void>}
   */


  function getBeneficiaryName() {
    return customerService.readCustomerBeneficiary($stateParams.patientId, $stateParams.beneficiaryId).then(function (beneficiary) {
      $scope.ordersTitle.title.text += beneficiary.information.name;
    });
  }
  /**
   * Redirect to the order details screen.
   *
   * @param {object} order - The order.
   */


  function redirectToOrderDetails(order) {
    $state.go("app.patients.details.orders.details", {
      orderId: order.id
    });
  }
}

angular.module("app.patients").controller("patientsOrdersListController", PatientOrdersListController);