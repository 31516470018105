"use strict";
/**
 * Prestation list controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $mdToast - $mdToast.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $stateParams - $stateParams.
 * @param {object} prestationService - The prestation service.
 */

PrestationListController.$inject = ["$scope", "$state", "$mdToast", "$mdDialog", "$stateParams", "prestationService"];

function PrestationListController($scope, $state, $mdToast, $mdDialog, $stateParams, prestationService) {
  $scope.query = {
    limit: 15,
    page: 1
  };
  $scope.filter = {
    year: "",
    month: "",
    status: "",
    healthcenter_id: $stateParams.id || ""
  };
  $scope.get = get;
  $scope.show = show;
  $scope.edit = edit;
  $scope.create = create;
  $scope.publish = publish;
  $scope.destroy = destroy;
  $scope.showPhotos = showPhotos;
  $scope.setMonthFilter = setMonthFilter;
  $scope.$watch("filter", function () {
    return get();
  }, true);
  prestationService.getYears().then(function (years) {
    $scope.years = years;
  });
  prestationService.getMonths().then(function (months) {
    $scope.months = months;
  });
  prestationService.getStatuses().then(function (statuses) {
    $scope.statuses = statuses;
  });
  /**
   * Get all prestations.
   *
   * @returns {Promise} Promise object represents the client request.
   */

  function get() {
    return prestationService.get($scope.query.page, $scope.filter).then(function (response) {
      $scope.prestation = response;
    });
  }
  /**
   * Set month filter.
   *
   * @param {number} monthId - The ID of the month.
   */


  function setMonthFilter(monthId) {
    $scope.filter.month = monthId || "";
  }
  /** Create a prestation. */


  function create() {
    $state.go("app.prestation.new");
  }
  /**
   * Show a prestation.
   *
   * @param {object} prestation - The prestation to show.
   */


  function show(prestation) {
    $state.go("app.prestation.detail", {
      id: prestation.id
    });
  }
  /**
   * Edit a prestation.
   *
   * @param {object} prestation - The prestation to edit.
   */


  function edit(prestation) {
    $state.go("app.prestation.edit", {
      id: prestation.id
    });
  }
  /**
   * Publish a prestation.
   *
   * @param {object} prestation - The prestation to publish.
   */


  function publish(prestation) {
    var confirm = $mdDialog.confirm();
    confirm.title("Publier la mise en avant").textContent("\xCAtes-vous certain de vouloir publier la mise en avant \xAB ".concat(prestation.name, " \xBB ?")).ariaLabel("Publier la mise en avant").ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      return prestationService.publish(prestation.id);
    }).then(function () {
      $mdToast.show($mdToast.simple().textContent("La mise en avant est publiée").position("top right"));
      get();
    });
  }
  /**
   * Destroy a prestation.
   *
   * @param {object} prestation - The prestation to destroy.
   */


  function destroy(prestation) {
    var confirm = $mdDialog.confirm();
    confirm.title("Supprimer une mise en avant").textContent("\xCAtes-vous certain de vouloir supprimer la mise en avant \xAB ".concat(prestation.name, " \xBB ?")).ariaLabel("Supprimer la mise en avant").ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      return prestationService["delete"](prestation.id);
    }).then(function () {
      $mdToast.show($mdToast.simple().textContent("La mise en avant est supprimée").position("top right"));
      get();
    });
  }
  /**
   * Show photos of a prestation.
   *
   * @param {object} prestation - The prestation object.
   */


  function showPhotos(prestation) {
    $state.go("app.prestation.photo", {
      id: prestation.id,
      healthcenter_id: $scope.filter.healthcenter_id
    });
  }
}

angular.module("app.prestation").controller("PrestationListController", PrestationListController);