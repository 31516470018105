"use strict";
/**
 * Laboratory disruption detail controller.
 *
 * @param {object} disruptionService - Disruption service.
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} $stateParams - $stateParams.
 * @param {object} environmentService - Environment service.
 * @param {object} laboratoriesService - Laboratory service.
 * @param {object} sellinOfferService - Sellin offer service.
 * @param {object} apiurl - The api URL.
 * @param {object} $state - The app state.
 */

LaboratoryDisruptionDetailController.$inject = ["disruptionService", "$scope", "StatisticsQueryService", "$stateParams", "environmentService", "laboratoriesService", "sellinOfferService", "apiurl", "$state"];

function LaboratoryDisruptionDetailController(disruptionService, $scope, StatisticsQueryService, $stateParams, environmentService, laboratoriesService, sellinOfferService, apiurl, $state) {
  $scope.pagination = {
    limit: 10,
    page: 1,
    order: "order.sum"
  };
  $scope.paginationDisruptionInfo = {
    order: "-start_date",
    limit: 10,
    page: 1
  };
  $scope.loaderOptions = {
    loading: true,
    loader: "Chargement des informations ruptures",
    empty: "Aucune rupture déclarée"
  };
  $scope.loaderOrdersOption = {
    loading: true,
    loader: "Chargement des commandes",
    empty: "Aucune commande en cours"
  };
  $scope.loaderPharmaMlsOption = {
    loading: true,
    loader: "Chargement des Pharmas ML",
    empty: "Aucun pharma ml de disponible."
  };
  $scope.loaderSellinOffersOption = {
    loading: true,
    loader: "Chargement des offres sellin",
    empty: "Aucune offre sellin de disponible pour cette catégorie."
  };
  $scope.usrQuery = StatisticsQueryService.query;
  $scope.usrQuery.date = {
    from: moment().subtract(1, "month"),
    to: moment()
  };
  $scope.missingPharmaMlActive = false;
  $scope.allPharmaMlActive = false;
  $scope.currentEnv = environmentService.getEnvironment();
  $scope.laboratoryDisruptionPresentation = "Aucune présentation n'a été enregistrée pour ce laboratoire.";
  $scope.laboratory = "-";
  $scope.getMissingPharmaMLs = getMissingPharmaMLs;
  $scope.getPharmaMLs = getPharmaMLs;
  $scope.goToSellinOfferDetails = goToSellinOfferDetails;
  loader();
  /**
   * The loading function
   */

  function loader() {
    getCurrentEnv();
    getEmergencyByLaboratory();
    getDisruptionPresentation();
    getPharmaMLs();
    getBranding();
  }
  /**
   * Get current environment.
   */


  function getCurrentEnv() {
    $scope.currentEnv.then(function (env) {
      $scope.environment = env;
      $scope.currentOrganizationId = env.organizationId;
      disruptionService.getShopLaboratoryOrdersByHealthcenter($stateParams.laboId, env.organizationId).then(function (orders) {
        orders.forEach(function (order, key) {
          var totalHT = 0;
          order.lines.forEach(function (line) {
            totalHT += line.priceWithoutVat;
          });
          orders[key].totalHT = totalHT;
        });
        $scope.orders = orders;
        $scope.loaderOrdersOption.loading = false;
      });
    });
  }
  /**
   * Get laboratory"s branding with conditions.
   */


  function getBranding() {
    var query = {
      laboratoryId: $stateParams.laboId
    };
    laboratoriesService.readBrandingWithoutProducts(query).then(function (response) {
      if (response) {
        $scope.branding = response;
        $scope.laboratoryBranding = response;
        return response;
      }

      return null;
    });
  }
  /**
   * getLaboratoryDetails.
   */


  function getDisruptionPresentation() {
    disruptionService.getDisruptionPresentation($stateParams.laboId).then(function (laboratory) {
      $scope.laboratoryDisruptionPresentation = laboratory.data;
      $scope.loaderOptions.loading = false;
    });
  }
  /**
   * Get pharma mls missing or not, from one laboratory.
   */


  function getPharmaMLs() {
    var params = {
      must: {
        laboratory: [$stateParams.laboId]
      },
      from: 0,
      size: 100
    };
    $scope.pharmaMls = [];
    $scope.loaderPharmaMlsOption.loading = true;
    $scope.allPharmaMlActive = false;
    disruptionService.getPharmamlsListing(params).then(function (response) {
      $scope.pharmaMls = response;
      $scope.allPharmaMlActive = true;
      $scope.loaderPharmaMlsOption.loading = false;
      $scope.missingPharmaMlActive = false;
    });
  }
  /**
   * Get pharma mls missing, from one laboratory.
   */


  function getMissingPharmaMLs() {
    var params = {
      must: {
        laboratory: [$stateParams.laboId]
      },
      from: 0,
      size: 100
    };
    $scope.pharmaMls = [];
    $scope.loaderPharmaMlsOption.loading = true;
    $scope.missingPharmaMlActive = false;
    disruptionService.getPharmamlsListing(params).then(function (response) {
      var pharmaMlMissing = [];
      response.forEach(function (pharmaMl) {
        if (pharmaMl.quantity.missing > 0) {
          pharmaMlMissing.push(pharmaMl);
        }
      });
      $scope.pharmaMls = pharmaMlMissing;
      $scope.missingPharmaMlActive = true;
      $scope.allPharmaMlActive = false;
      $scope.loaderPharmaMlsOption.loading = false;
    });
  }
  /**
   * getEmergencyByLaboratory.
   */


  function getEmergencyByLaboratory() {
    disruptionService.getEmergencyByLaboratory($stateParams.laboId, 0).then(function (response) {
      $scope.sellinOffers = response.data;
      $scope.loaderSellinOffersOption.loading = false;
    });
  }
  /**
   * @param sellinOfferId - The sellin offer id
   */


  function goToSellinOfferDetails(sellinOfferId) {
    $state.go("app.commercialOffer.sellin_offers_detail", {
      id: sellinOfferId
    });
  }
}

angular.module("app.disruption").controller("laboratoryDisruptionDetailController", LaboratoryDisruptionDetailController);