"use strict";

AdministrationTermsService.$inject = ["apiClient"];

/**
 * Service for terms.
 *
 * @param {object} apiClient - The api client.
 *
 * @return {object}
 */
function AdministrationTermsService(apiClient) {
  return {
    /**
     * Read a term from his slug.
     *
     * @param {string} slug - The term slug.
     *
     * @returns {Promise}
     */
    read: function read(slug) {
      return apiClient.get("/terms/".concat(slug)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a term.
     *
     * @param {object} term - The term to update.
     *
     * @returns {Promise}
     */
    update: function update(term) {
      return apiClient.patch("/terms/".concat(term.slug), {
        data: term
      }, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      }, {
        data: term
      });
    }
  };
}

angular.module("app.administration.services").service("administrationTermService", AdministrationTermsService);