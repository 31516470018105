"use strict";

InstantDiscountVoucherListController.$inject = ["$scope", "$state", "$mdDialog", "environmentService", "instantDiscountVoucherService"];
angular.module("app.instantDiscountVoucher").controller("InstantDiscountVoucherListController", InstantDiscountVoucherListController);
/** @ngInject */

/**
 * Instant discount voucher controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} environmentService - The environment service.
 * @param {object} instantDiscountVoucherService - The instant discount voucher service.
 */

function InstantDiscountVoucherListController($scope, $state, $mdDialog, environmentService, instantDiscountVoucherService) {
  $scope.access = null;
  getCurrentAccess();
  $scope.openTab = openTab;
  $scope.addInstantDiscountVoucher = addInstantDiscountVoucher;
  $scope.editInstantDiscountVoucher = editInstantDiscountVoucher;
  $scope.detailsInstantDiscountVoucher = detailsInstantDiscountVoucher;
  $scope.publishInstantDiscountVoucher = publishInstantDiscountVoucher;
  $scope.canEdit = canEdit;
  $scope.canUserAcceptOrRefuse = canUserAcceptOrRefuse;
  $scope.acceptInstantDiscountVoucher = acceptInstantDiscountVoucher;
  $scope.refuseInstantDiscountVoucher = refuseInstantDiscountVoucher;
  $scope.tabs = [{
    id: 1,
    name: "À valider",
    method: loadAwaiting,
    slug: "awaiting",
    isActive: true
  }, {
    id: 2,
    name: "En cours",
    method: loadCurrent,
    slug: "current",
    isActive: false
  }, {
    id: 3,
    name: "À venir",
    method: loadFuture,
    slug: "future",
    isActive: false
  }, {
    id: 4,
    name: "Terminés",
    method: loadFinished,
    slug: "finished",
    isActive: false
  }];
  $scope.loading = false;
  $scope.instantDiscountVouchers = [];
  $scope.currentTab = $scope.tabs[0];
  openTab($scope.tabs[0]);
  $scope.entityTypesAllowedToEdit = [1, 3];
  /**
   * Fetch current user access.
   */

  function getCurrentAccess() {
    environmentService.getEnvironment().then(function (access) {
      $scope.access = access;
    });
  }
  /**
   * Load awaiting instant discount vouchers.
   *
   * @returns {Promise}
   */


  function loadAwaiting() {
    return instantDiscountVoucherService.getAwaiting().then(function (instantDiscountVouchers) {
      $scope.instantDiscountVouchers = instantDiscountVouchers;
    });
  }
  /**
   * Load current instant discount vouchers.
   *
   * @returns {Promise}
   */


  function loadCurrent() {
    return instantDiscountVoucherService.getCurrent().then(function (instantDiscountVouchers) {
      $scope.instantDiscountVouchers = instantDiscountVouchers;
    });
  }
  /**
   * Load future instant discount vouchers.
   *
   * @returns {Promise}
   */


  function loadFuture() {
    return instantDiscountVoucherService.getFuture().then(function (instantDiscountVouchers) {
      $scope.instantDiscountVouchers = instantDiscountVouchers;
    });
  }
  /**
   * Load finished instant discount vouchers.
   *
   * @returns {Promise}
   */


  function loadFinished() {
    return instantDiscountVoucherService.getFinished().then(function (instantDiscountVouchers) {
      $scope.instantDiscountVouchers = instantDiscountVouchers;
    });
  }
  /**
   * Load an display a tab content.
   *
   * @param {object} tab - Tab to open.
   */


  function openTab(tab) {
    $scope.tabs.forEach(function (currentTab) {
      currentTab.isActive = tab.id === currentTab.id;
    });
    $scope.loading = true;
    tab.method().then(function () {
      $scope.loading = false;
      $scope.currentTab = tab;
    });
  }
  /**
   * Go to add instant discount voucher state.
   */


  function addInstantDiscountVoucher() {
    $state.go("app.instantDiscountVoucher.new");
  }
  /**
   * Go to edit instant discount voucher state.
   *
   * @param {object} instantDiscountVoucher - Instant discount voucher to edit.
   */


  function editInstantDiscountVoucher(instantDiscountVoucher) {
    $state.go("app.instantDiscountVoucher.edit", {
      id: instantDiscountVoucher.id
    });
  }
  /**
   * Go to instant discount voucher state details.
   *
   * @param {object} instantDiscountVoucher - Instant discount voucher to show.
   */


  function detailsInstantDiscountVoucher(instantDiscountVoucher) {
    $state.go("app.instantDiscountVoucher.detail", {
      id: instantDiscountVoucher.id
    });
  }
  /**
   * Test if current user can edit displayed instant discount vouchers.
   *
   * @param {object} instantDiscountVoucher - The instant discount voucher to test.
   *
   * @returns {boolean}
   */


  function canEdit(instantDiscountVoucher) {
    var tabsWithEditableInstantDiscountVouchers = ["awaiting", "current", "future"];
    return tabsWithEditableInstantDiscountVouchers.indexOf($scope.currentTab.slug) !== -1 && $scope.access && $scope.entityTypesAllowedToEdit.indexOf($scope.access.accessPointType) !== -1 && $scope.access.organizationId === instantDiscountVoucher.sponsor.id;
  }
  /**
   * Publish instant discount voucher after user confirmation.
   *
   * @param {object} instantDiscountVoucher - Instant discount voucher to publish.
   */


  function publishInstantDiscountVoucher(instantDiscountVoucher) {
    var confirm = $mdDialog.confirm();
    confirm.title("Publication").textContent("\xCAtes-vous certain de vouloir publier\n                le bon de r\xE9duction \xAB ".concat(instantDiscountVoucher.name, " \xBB ?")).ariaLabel("Publier le bon de réduction immédiat").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      instantDiscountVoucherService.publish({
        id: instantDiscountVoucher.id
      }).then(function () {
        instantDiscountVoucher.isPublished = true;
      });
    });
  }
  /**
   * Test if a user can accept or refuse an instant discount voucher.
   *
   * @param {object} instantDiscountVoucher - Instant discount voucher to test.
   *
   * @returns {boolean}
   */


  function canUserAcceptOrRefuse(instantDiscountVoucher) {
    if (!instantDiscountVoucher.targets) {
      return false;
    }

    return $scope.access.accessPointType === 3 && _.any(instantDiscountVoucher.targets, function (target) {
      return $scope.access.organizationId === target.id && target.hasAccepted === null;
    });
  }
  /**
   * Accept an instant discount voucher.
   *
   * @param {object} instantDiscountVoucher - Instant discount voucher to accept.
   *
   * @returns {Promise}
   */


  function acceptInstantDiscountVoucher(instantDiscountVoucher) {
    _.filter(instantDiscountVoucher.targets, function (target) {
      return $scope.access.organizationId === target.id;
    })[0].hasAccepted = true;
    return instantDiscountVoucherService.accept({
      id: instantDiscountVoucher.id
    }).then(function () {
      openTab($scope.currentTab);
    });
  }
  /**
   * Refuse an instant discount voucher.
   *
   * @param {object} instantDiscountVoucher - instant discount voucher to refuse.
   *
   * @returns {Promise}
   */


  function refuseInstantDiscountVoucher(instantDiscountVoucher) {
    _.filter(instantDiscountVoucher.targets, function (target) {
      return $scope.access.organizationId === target.id;
    })[0].hasAccepted = false;
    return instantDiscountVoucherService.refuse({
      id: instantDiscountVoucher.id
    }).then(function () {
      openTab($scope.currentTab);
    });
  }
}