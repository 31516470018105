"use strict";
/**
 * Dashboard group sales evolution controller.
 *
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 * @param {object} ChartSettingsService - Chart settings service.
 * @param {object} environmentService - Environment service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} $rootScope - $rootScope.
 * @param {object} apodisLiveService - Apodis live service.
 * @param {object} analysisService - Analysis service.
 */

dashboardGroupSalesEvolutionController.$inject = ["$state", "$scope", "ChartSettingsService", "environmentService", "StatisticsQueryService", "$rootScope", "apodisLiveService", "analysisService"];

function dashboardGroupSalesEvolutionController($state, $scope, ChartSettingsService, environmentService, StatisticsQueryService, $rootScope, apodisLiveService, analysisService) {
  $scope.loading = true;
  loader();
  /**
   * Loader.
   */

  function loader() {
    environmentService.getEnvironment().then(function (environment) {
      $scope.env = environment;
      launchStatisticsQueries();
    });
  }

  $scope.goToState = goToState;
  $scope.refreshSalesWidget = refreshSalesWidget;
  $scope.applyDatePreset = applyDatePreset;
  /**
   * Go to state.
   *
   * @param {string} state -
   */

  function goToState(state) {
    $state.go(state);
  }

  var salesServiceName;
  /**
   * On sales data.
   *
   * @param {object} dataSales - Data sales.
   */

  function onSalesData(dataSales) {
    $scope.productSalesLiveId = dataSales.product.id;
    $scope.serialUnitSalesOptions.data[$scope.serialUnitSalesOptions.data.length - 1].qty += dataSales.item.quantity;
    $scope.serialCASalesOptions.data[$scope.serialCASalesOptions.data.length - 1].sales += dataSales.item.price.sell.total_ht;
    $scope.$apply();
  }
  /**
   * Launch socket.
   */


  function launchSocket() {
    salesServiceName = "sales_grouping_" + $scope.env.entity.id;

    if (typeof apodisLiveService.socket._callbacks["$" + salesServiceName] === "undefined") {
      // Handler not present, install now
      apodisLiveService.register({
        service: salesServiceName
      });
    }

    apodisLiveService.socket.on(salesServiceName, onSalesData);
  }

  $rootScope.$on("$stateChangeStart", function () {
    apodisLiveService.unregister(salesServiceName, onSalesData);
  });
  /**
   * Apply date preset.
   *
   * @param {number} sub - Sub.
   * @param {string} type - Type.
   */

  function applyDatePreset(sub, type) {
    initSalesAnalysisChart();
    var time = StatisticsQueryService.datePresetFunc(sub, type);
    $scope.usrquery.date = {
      from: time.from,
      to: time.to
    };
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      processSalesAnalysisQuery($scope.query);
    });
  }
  /**
   * Initialiaz analysis chart.
   */


  function initSalesAnalysisChart() {
    $scope.salesLoading = true;
    $scope.serialUnitSalesOptions = getUnitSalesAnalysisChartOptions();
    $scope.serialCASalesOptions = getCASalesAnalysisChartOptions();
    $scope.serialUnitSalesOptions.legend.valueWidth = 100;
    $scope.serialUnitSalesOptions.legend.valueWidth = 100;
    $scope.serialCASalesOptions.legend.valueAlign = "left";
    $scope.serialCASalesOptions.legend.valueAlign = "left";
  }
  /**
   * Process sales analysis query.
   *
   * @param {object} query - Query.
   */


  function processSalesAnalysisQuery(query) {
    query.interval = "1M";
    analysisService.getSales(query).then(function (response) {
      $scope.salesLoading = false;
      $scope.elasticdata = response;
      processSalesData();
    });
  }
  /**
   * Merge graph objects.
   *
   * @param {object} graphs -
   * @returns {object}
   */


  function mergeGraphObjects(graphs) {
    var ngraphs = [];
    angular.forEach(graphs, function (data) {
      var obj = angular.extend(data, ChartSettingsService.getLineGraphObj()); // eslint-disable-next-line no-invalid-this

      this.push(obj);
    }, ngraphs);
    return ngraphs;
  }
  /**
   * Get unit sales analysis chart options.
   *
   * @returns {object}
   */


  function getUnitSalesAnalysisChartOptions() {
    var salesGraphs = {
      type: "serial",
      data: [],
      startDuration: 0,
      marginTop: 40,
      marginRight: 60,
      legend: {
        useGraphSettings: true,
        marginTop: 20
      },
      valueAxes: [{
        id: "qty",
        axisAlpha: 1,
        position: "left",
        gridAlpha: 0.1,
        axisColor: "#673AB7",
        labelFunction: function labelFunction(value) {
          return d3.format(".2s")(value);
        }
      }],
      graphs: [{
        id: "g3",
        valueField: "qty",
        valueAxis: "qty",
        title: "Quantité",
        animationPlayed: false,
        lineColor: "#673AB7",
        balloon: {
          drop: false,
          adjustBorderColor: true,
          color: "#212121"
        },
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " unités";
        }
      }],
      chartCursor: {
        cursorColor: "#888888",
        cursorAlpha: 0.5,
        categoryBalloonDateFormat: "DD MMM YYYY",
        fullWidth: false
      },
      categoryField: "date",
      categoryAxis: {
        gridColor: "#F2F2F2",
        gridAlpha: 0.3,
        dateFormats: [{
          period: "fff",
          format: "JJ:NN:SS"
        }, {
          period: "ss",
          format: "JJ:NN:SS"
        }, {
          period: "mm",
          format: "JJ:NN"
        }, {
          period: "hh",
          format: "JJ:NN"
        }, {
          period: "DD",
          format: "DD MMM"
        }, {
          period: "WW",
          format: "DD MMM"
        }, {
          period: "MM",
          format: "MMM YYYY"
        }, {
          period: "YYYY",
          format: "MMM YYYY"
        }],
        parseDates: true,
        minorGridAlpha: 0.1,
        minorGridEnabled: true
      },
      "export": {
        enabled: false
      }
    };
    salesGraphs = angular.extend(salesGraphs, ChartSettingsService.getLineObj());
    salesGraphs.graphs = mergeGraphObjects(salesGraphs.graphs);

    salesGraphs.legend.valueFunction = function (graphDataItem) {
      if (graphDataItem && graphDataItem.graph) {
        var currentUnit = graphDataItem.graph.id === "g3" ? " unités" : "€";
        var value = Math.round(graphDataItem.values.value).formatMoney(0, 3, " ", ", ") + currentUnit;
        return value;
      }

      return "";
    };

    return salesGraphs;
  }
  /**
   * Get CA sales analysis chart options.
   *
   * @returns {object}
   */


  function getCASalesAnalysisChartOptions() {
    var salesGraphs = {
      type: "serial",
      data: [],
      startDuration: 0,
      marginTop: 40,
      marginRight: 60,
      legend: {
        useGraphSettings: true,
        marginTop: 20
      },
      valueAxes: [{
        id: "ca",
        axisAlpha: 1,
        position: "left",
        gridAlpha: 0.1,
        axisColor: "#67B7DC",
        labelFunction: function labelFunction(value) {
          return d3.format(".2s")(value) + "€";
        }
      }],
      graphs: [{
        id: "g1",
        valueField: "sales",
        valueAxis: "ca",
        title: "Chiffre d'affaires HT",
        animationPlayed: false,
        balloon: {
          drop: false,
          adjustBorderColor: true,
          color: "#212121"
        },
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + "€";
        }
      }],
      chartCursor: {
        cursorColor: "#888888",
        cursorAlpha: 0.5,
        categoryBalloonDateFormat: "DD MMM YYYY",
        fullWidth: false
      },
      categoryField: "date",
      categoryAxis: {
        gridColor: "#F2F2F2",
        gridAlpha: 0.3,
        dateFormats: [{
          period: "fff",
          format: "JJ:NN:SS"
        }, {
          period: "ss",
          format: "JJ:NN:SS"
        }, {
          period: "mm",
          format: "JJ:NN"
        }, {
          period: "hh",
          format: "JJ:NN"
        }, {
          period: "DD",
          format: "DD MMM"
        }, {
          period: "WW",
          format: "DD MMM"
        }, {
          period: "MM",
          format: "MMM YYYY"
        }, {
          period: "YYYY",
          format: "MMM YYYY"
        }],
        parseDates: true,
        minorGridAlpha: 0.1,
        minorGridEnabled: true
      },
      "export": {
        enabled: false
      }
    };
    salesGraphs = angular.extend(salesGraphs, ChartSettingsService.getLineObj());
    salesGraphs.graphs = mergeGraphObjects(salesGraphs.graphs);

    salesGraphs.legend.valueFunction = function (graphDataItem) {
      if (graphDataItem && graphDataItem.graph) {
        var currentUnit = graphDataItem.graph.id === "g3" ? " unités" : "€";
        var value = Math.round(graphDataItem.values.value).formatMoney(0, 3, " ", ", ") + currentUnit;
        return value;
      }

      return "";
    };

    return salesGraphs;
  }
  /**
   * Process sales data.
   */


  function processSalesData() {
    var linesUnit = [];
    var linesCA = [];
    angular.forEach($scope.elasticdata.aggregations.sales.buckets, function (data) {
      var objUnit = {
        date: data.key
      };
      var objCA = {
        date: data.key
      };
      objUnit.qty = data.quantity.value;
      objCA.sales = data.amount_excl_tax.value;
      linesUnit.push(objUnit);
      linesCA.push(objCA);
    });
    $scope.serialUnitSalesOptions.data = linesUnit;
    $scope.serialCASalesOptions.data = linesCA;
    $scope.loading = false;
    launchSocket();
  }
  /**
   * Launch statistics queries.
   */


  function launchStatisticsQueries() {
    $scope.usrquery = angular.copy(StatisticsQueryService.pristineQuery);
    initSalesAnalysisChart();
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      processSalesAnalysisQuery($scope.query);
    });
  }
  /**
   * Refresh sales widget.
   */


  function refreshSalesWidget() {
    initSalesAnalysisChart();
    $scope.usrquery = angular.copy(StatisticsQueryService.pristineQuery);
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      processSalesAnalysisQuery($scope.query, true);
    });
  }
}

angular.module("app.dashboard").controller("dashboardGroupSalesEvolutionController", dashboardGroupSalesEvolutionController);