"use strict";
/**
 * Negative pharmaML controller.
 *
 * @param {object} disruptionService - Disruption service.
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 */

NegativePharmaMLController.$inject = ["disruptionService", "$scope", "StatisticsQueryService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function NegativePharmaMLController(disruptionService, $scope, StatisticsQueryService) {
  $scope.pagination = {
    limit: 10,
    page: 1,
    order: "-_source.datetime"
  };
  $scope.loaderDisruptionsOptions = {
    loading: true,
    loader: "Chargement de vos ruptures",
    empty: "Aucune rupture"
  };
  $scope.config = {
    event: "negativePharmaMLFilterChange",
    search: [],
    geoForce: true,
    showBadge: true,
    searchPlaceHolder: "Effectuer une recherche pour choisir les données à analyser"
  };
  $scope.usrQuery = StatisticsQueryService.query;
  $scope.usrQuery.date = {
    from: moment().subtract(1, "month"),
    to: moment()
  };
  loader();
  $scope.rangeSelected = rangeSelected;
  $scope.getNegativePharmaML = getNegativePharmaML;
  /**
   * Range selected.
   *
   * @param {object} date - Date.
   */

  function rangeSelected(date) {
    $scope.usrQuery.date.from = moment(date.startDate, "DD-MM-YYYY");
    $scope.usrQuery.date.to = moment(date.endDate, "DD-MM-YYYY");
    getNegativePharmaML();
  }
  /**
   * getNegativePharmaML
   */


  function getNegativePharmaML() {
    $scope.negativePharmaMLs = [];
    $scope.loaderDisruptionsOptions.loading = true;
    StatisticsQueryService.buildquery($scope.usrQuery, $scope.usrQuery.date).then(function (queryBuilt) {
      var params = _objectSpread({
        query: JSON.stringify(queryBuilt)
      }, $scope.pagination);

      disruptionService.getNegativePharmaML(params).then(function (negativePharmaML) {
        $scope.negativePharmaMLs = negativePharmaML.hits.hits;
        $scope.totalPharmaMls = negativePharmaML.hits.total;
        $scope.loaderDisruptionsOptions.loading = false;
      });
    });
  }
  /**
   * The loading function
   */


  function loader() {
    var rangeDate = {
      startDate: moment().date(1).format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    };
    $scope.usrQuery.date.from = moment().date(1).format("YYYY-MM-DD");
    $scope.usrQuery.date.to = moment().format("YYYY-MM-DD");
    $scope.dateLabel = moment(rangeDate.startDate).format("DD-MMM-YYYY") + " Au " + moment(rangeDate.endDate).format("DD-MMM-YYYY");
    $scope.loaderDisruptionsOptions.loading = true;
    getNegativePharmaML();
  }

  $scope.$on("negativePharmaMLFilterChange", function (event, searchUsrProductquery) {
    $scope.pagination.page = 1;
    angular.forEach(_.keys(searchUsrProductquery), function (obj) {
      angular.forEach(searchUsrProductquery[obj], function (data) {
        if (obj === "product") {
          $scope.usrQuery.must[obj].selected = [data];
        } else if ($scope.usrQuery.must[obj] && $scope.usrQuery.must[obj].selected.indexOf(data) === -1) {
          $scope.usrQuery.must[obj].selected.push(data);
        }
      });
    });
    getNegativePharmaML();
  });
}

angular.module("app.disruption").controller("negativePharmaMLController", NegativePharmaMLController);