"use strict";
/**
 * User prestation controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $q - $q.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} usersPrestationService - Users prestation service.
 * @param {object} notifyUserService - Notify user service.
 * @param {string} apiurl - API URL.
 */

UserPrestationController.$inject = ["$scope", "$state", "$q", "$mdDialog", "usersPrestationService", "notifyUserService", "apiurl"];

function UserPrestationController($scope, $state, $q, $mdDialog, usersPrestationService, notifyUserService, apiurl) {
  /**
   * Declaration of public methods
   */
  $scope.initialize = initialize;
  $scope.getComeupPrestations = getComeupPrestations;
  $scope.getFinishedPrestations = getFinishedPrestations;
  $scope.getOngoingPrestations = getOngoingPrestations;
  $scope.getPendingPrestations = getPendingPrestations;
  $scope.getRefusedPrestations = getRefusedPrestations;
  $scope.goToDetail = goToDetail;
  $scope.acceptPrestation = acceptPrestation;
  $scope.refusePrestation = refusePrestation;

  $scope.openCardMenu = function ($mdOpenMenu, ev) {
    $mdOpenMenu(ev);
  };
  /**
   * Declaration of private objects
   */


  var imageFallback = "assets/images/prestations/default-1080x600.jpg";
  /**
   * Declaration of public objects
   */

  $scope.comeupTitle = {
    icon: "icon-calendar-plus",
    text: "Prestations à venir",
    type: "3"
  };
  $scope.finishedTitle = {
    icon: "icon-calendar-check-multiple",
    text: "Prestations terminées",
    type: "3"
  };
  $scope.ongoingTitle = {
    icon: "icon-calendar",
    text: "Prestations en cours",
    type: "3"
  };
  $scope.pendingTitle = {
    icon: "icon-calendar-clock",
    text: "Prestations à valider",
    type: "3"
  };
  $scope.refusedTitle = {
    icon: "icon-calendar-remove",
    text: "Prestations refusées",
    type: "3"
  };
  $scope.globalLoader = {
    loading: true,
    loader: "Récupération des prestations",
    empty: "Vous n'avez aucune prestation.",
    count: []
  };
  $scope.comeupLoader = {
    loading: true,
    loader: "Récupération des prestations à venir",
    empty: "Vous n'avez aucune prestation à venir."
  };
  $scope.finishedLoader = {
    loading: true,
    loader: "Récupération des prestations terminées",
    empty: "Vous n'avez aucune prestation terminée."
  };
  $scope.ongoingLoader = {
    loading: true,
    loader: "Récupération des prestations en cours",
    empty: "Vous n'avez aucune prestation en cours."
  };
  $scope.pendingLoader = {
    loading: true,
    loader: "Récupération des prestations à valider",
    empty: "Vous n'avez aucune prestation à valider."
  };
  $scope.refusedLoader = {
    loading: true,
    loader: "Récupération des prestations refusées",
    empty: "Vous n'avez aucune prestation refusée."
  };
  $scope.comeupLastLoader = {
    loading: true,
    loader: "Récupération des prestations à venir",
    empty: "Vous n'avez aucune prestation à venir."
  };
  $scope.finishedLastLoader = {
    loading: true,
    loader: "Récupération des prestations terminées",
    empty: "Vous n'avez aucune prestation terminée."
  };
  $scope.ongoingLastLoader = {
    loading: true,
    loader: "Récupération des prestations en cours",
    empty: "Vous n'avez aucune prestation en cours."
  };
  $scope.pendingLastLoader = {
    loading: true,
    loader: "Récupération des prestations à valider",
    empty: "Vous n'avez aucune prestation à valider."
  };
  $scope.refusedLastLoader = {
    loading: true,
    loader: "Récupération des prestations refusées",
    empty: "Vous n'avez aucune prestation refusée."
  };
  var actionsTileButtons = {
    detail: {
      label: "Détails",
      onClick: function onClick(tile) {
        $state.go(generateState(), {
          id: tile.id
        });
      },
      htmlClass: "full md-accent-bg md-white",
      icon: "icon-eye",
      tooltip: "Détails de la mise en avant"
    },
    "delete": {
      label: "Supprimer",
      htmlClass: "left md-red-bg",
      onClick: deleteTile,
      icon: "icon-close",
      tooltip: "Supprimer de la mise en avant"
    },
    accept: {
      label: "Accepter",
      htmlClass: "left md-green-bg",
      onClick: acceptTile,
      icon: "icon-check",
      tooltip: "Accepter de la mise en avant"
    },
    refuse: {
      label: "Refuser",
      htmlClass: "left md-red-bg",
      onClick: refuseTile,
      icon: "icon-close",
      tooltip: "Refuser de la mise en avant"
    }
  };
  /**
   * Initialization
   */

  initialize();
  /**
   * Initialize.
   *
   * @returns {Promise}
   */

  function initialize() {
    $scope.globalPrestations = [];
    $scope.globalLoader.loading = true;
    return $q.all([getComeupPrestations(), getFinishedPrestations(), getOngoingPrestations(), getPendingPrestations(), getRefusedPrestations()]).then(function (responses) {
      $scope.globalPrestations = responses;
      $scope.globalLoader.count = responses.reduce(function (accu, current) {
        return accu + current.length;
      }, 0) ? [0] : [];
      $scope.globalLoader.loading = false;
      return responses;
    });
  }
  /**
   * Get comeup prestations.
   *
   * @returns {Promise}
   */


  function getComeupPrestations() {
    $scope.comeupPrestations = [];
    $scope.comeupPrestationsLast = [];
    $scope.comeupLoader.loading = true;
    $scope.comeupLastLoader.loading = true;
    return usersPrestationService.filter({
      moment: "comeup",
      status: 1
    }).then(function (response) {
      $scope.comeupPrestations = parsePrestation(response);
      $scope.comeupPrestationsLast = extractLasts($scope.comeupPrestations);
      $scope.comeupPrestationConfigTiles = {
        tiles: parseToTiles($scope.comeupPrestations),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.details],
        filter: "Que cherchez-vous ?"
      };
      $scope.comeupPrestationLastConfigTiles = {
        tiles: parseToTiles($scope.comeupPrestationsLast),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.details],
        filter: "Que cherchez-vous ?"
      };
      $scope.comeupLoader.loading = false;
      $scope.comeupLastLoader.loading = false;
      return response;
    });
  }
  /**
   * Get finished prestations.
   *
   * @returns {Promise}
   */


  function getFinishedPrestations() {
    $scope.finishedPrestations = [];
    $scope.finishedPrestationsLast = [];
    $scope.finishedLoader.loading = true;
    $scope.finishedLastLoader.loading = true;
    return usersPrestationService.filter({
      moment: "finished",
      status: 1
    }).then(function (response) {
      $scope.finishedPrestations = parsePrestation(response);
      $scope.finishedPrestationsLast = extractLasts($scope.finishedPrestations);
      $scope.finishedPrestationConfigTiles = {
        tiles: parseToTiles($scope.finishedPrestations),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.details],
        filter: "Que cherchez-vous ?"
      };
      $scope.finishedPrestationLastConfigTiles = {
        tiles: parseToTiles($scope.finishedPrestationsLast),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.details],
        filter: "Que cherchez-vous ?"
      };
      $scope.finishedLoader.loading = false;
      $scope.finishedLastLoader.loading = false;
      return response;
    });
  }
  /**
   * Get ongoing prestations.
   *
   * @returns {Promise}
   */


  function getOngoingPrestations() {
    $scope.ongoingPrestations = [];
    $scope.ongoingPrestationsLast = [];
    $scope.ongoingLoader.loading = true;
    $scope.ongoingLastLoader.loading = true;
    return usersPrestationService.filter({
      moment: "ongoing",
      status: 1
    }).then(function (response) {
      $scope.ongoingPrestations = parsePrestation(response);
      $scope.ongoingPrestationsLast = extractLasts($scope.ongoingPrestations);
      $scope.ongoingPrestationConfigTiles = {
        tiles: parseToTiles($scope.ongoingPrestations),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.details],
        filter: "Que cherchez-vous ?"
      };
      $scope.ongoingPrestationLastConfigTiles = {
        tiles: parseToTiles($scope.ongoingPrestationsLast),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.details],
        filter: "Que cherchez-vous ?"
      };
      $scope.ongoingLoader.loading = false;
      $scope.ongoingLastLoader.loading = false;
      return response;
    });
  }
  /**
   * Get pending prestations.
   *
   * @returns {Promise}
   */


  function getPendingPrestations() {
    $scope.pendingPrestations = [];
    $scope.pendingPrestationsLast = [];
    $scope.pendingLoader.loading = true;
    $scope.pendingLastLoader.loading = true;
    return usersPrestationService.filter({
      moment: "unfinished",
      status: 0
    }).then(function (response) {
      $scope.pendingPrestations = parsePrestation(response);
      $scope.pendingPrestationsLast = extractLasts($scope.pendingPrestations);
      $scope.pendingPrestationConfigTiles = {
        tiles: parseToTiles($scope.pendingPrestations),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.accept, actionsTileButtons.refuse],
        filter: "Que cherchez-vous ?"
      };
      $scope.pendingPrestationLastConfigTiles = {
        tiles: parseToTiles($scope.pendingPrestationsLast),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.accept, actionsTileButtons.refuse],
        filter: "Que cherchez-vous ?"
      };
      $scope.pendingLoader.loading = false;
      $scope.pendingLastLoader.loading = false;
      return response;
    });
  }
  /**
   * Get refused prestations.
   *
   * @returns {Promise}
   */


  function getRefusedPrestations() {
    $scope.refusedPrestations = [];
    $scope.refusedPrestationsLast = [];
    $scope.refusedLoader.loading = true;
    $scope.refusedLastLoader.loading = true;
    return usersPrestationService.filter({
      status: 2
    }).then(function (response) {
      $scope.refusedPrestations = parsePrestation(response);
      $scope.refusedPrestationsLast = extractLasts($scope.refusedPrestations);
      $scope.refusedPrestationConfigTiles = {
        tiles: parseToTiles($scope.refusedPrestations),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.details],
        filter: "Que cherchez-vous ?"
      };
      $scope.refusedPrestationLastConfigTiles = {
        tiles: parseToTiles($scope.refusedPrestationsLast),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.details],
        filter: "Que cherchez-vous ?"
      };
      return response;
    }).then(function (response) {
      $scope.refusedLoader.loading = false;
      $scope.refusedLastLoader.loading = false;
      return response;
    });
  }
  /**
   * Parse prestations.
   *
   * @param {Array} prestations - The prestations to parse.
   *
   * @returns {Array} The parsed prestations.
   */


  function parsePrestation(prestations) {
    return prestations.map(function (prestation) {
      var today = new Date();
      var start = new Date(prestation.prestation.startDate);
      var end = new Date(prestation.prestation.endDate); // Check status

      if (today < start) {
        prestation.prestation.status = "coming";
      } else if (today >= start && today <= end) {
        prestation.prestation.status = "ongoing";
      } else if (today > end) {
        prestation.prestation.status = "finished";
      } // Check image


      prestation.prestation.image = prestation.prestation.imagePath ? apiurl + "/images/" + prestation.prestation.imagePath : imageFallback;
      return prestation;
    });
  }
  /**
   * Extract last prestations.
   *
   * @param {Array} prestations - The prestations to extract.
   *
   * @returns {Array} The extracted prestations.
   */


  function extractLasts(prestations) {
    return prestations.slice(0, 4);
  }
  /**
   * Got to prestation detail.
   *
   * @param {number} prestationId - The id of the prestation.
   */


  function goToDetail(prestationId) {
    $state.go("app.UserPrestationHome.detail", {
      id: prestationId
    });
  }
  /**
   * Accept a prestation.
   *
   * @param {object} prestation - The prestation to accept.
   *
   * @returns {Promise} Prestation acceptation promise.
   */


  function acceptPrestation(prestation) {
    return usersPrestationService.accept(prestation).then(function (response) {
      if (response) {
        initialize();
      }

      return response;
    });
  }
  /**
   * Refuse a prestation.
   *
   * @param {object} prestation - The prestation to refuse.
   *
   * @returns {Promise} Prestation refusal promise.
   */


  function refusePrestation(prestation) {
    return usersPrestationService.refuse(prestation).then(function (response) {
      if (response) {
        initialize();
      }

      return response;
    });
  }
  /**
   * Parse to tiles prestations.
   *
   * @param {Array} prestations - Prestations to parse.
   *
   * @returns {Array} Parsed prestations.
   */


  function parseToTiles(prestations) {
    return prestations.map(function (prestation) {
      return {
        id: prestation.prestation.id,
        entityId: prestation.entityId,
        entityTypeId: prestation.entityTypeId,
        organizationId: prestation.prestation.organization_id,
        organizationTypeId: prestation.prestation.organization ? prestation.prestation.organization.entity_type_id : 0,
        organization: prestation.prestation.organization,
        name: prestation.prestation.name,
        subName: prestation.prestation.organization ? prestation.prestation.organization.morphable.name : "inconnu",
        description: prestation.prestation.description,
        image: prestation.prestation.imagePath ? apiurl + "/images/" + prestation.prestation.imagePath : imageFallback,
        isPublished: prestation.prestation.isPublished,
        rewardDistributionId: prestation.rewardDistributionId,
        startDate: prestation.prestation.startDateTime,
        endDate: prestation.prestation.endDateTime,
        createdAt: prestation.prestation.createdAt,
        updatedAt: prestation.prestation.updatedAt
      };
    });
  }
  /**
   * Generate state by currentEnv.accessPointType
   *
   * @returns {string} Generated state.
   */


  function generateState() {
    return "app.UserPrestationHome.detail";
  }
  /**
   * delete a tile
   *
   * @param {object} tile - Tile to delete.
   * @param {object} event - Triggered event.
   */


  function deleteTile(tile, event) {
    $mdDialog.show({
      templateUrl: "app/main/users-prestation/views/dialogs/accept.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        tile: tile
      },
      clickOutsideToClose: true,
      controller: ["$scope", "$mdDialog", "tile", function controller($scope, $mdDialog, tile) {
        /**
         * Declaration of public methods
         */
        $scope.close = close;
        $scope.validate = validate;
        /**
         * Declaration of public objects
         */

        $scope.tile = tile;
        /**
         * hide
         */

        function validate() {
          $mdDialog.cancel(tile);
        }
        /**
         * cancel
         */


        function close() {
          $mdDialog.cancel();
        }
      }]
    }).then(function (response) {
      if (response) {
        usersPrestationService.accept(event, response).then(function () {
          notifyUserService.showSimpleToast("La prestation « " + response.name + " » a été acceptée", {
            color: "success",
            hideDelay: 2000
          });
          return response;
        });
      }
    }).then(initialize);
  }
  /**
   * accept a tile
   *
   * @param {object} tile - Tile to accept.
   * @param {object} event - Triggered event.
   */


  function acceptTile(tile, event) {
    $mdDialog.show({
      templateUrl: "app/main/users-prestation/views/dialogs/accept.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        tile: tile
      },
      clickOutsideToClose: true,
      controller: ["$scope", "$mdDialog", "tile", function controller($scope, $mdDialog, tile) {
        /**
         * Declaration of public methods
         */
        $scope.close = close;
        $scope.validate = validate;
        /**
         * Declaration of public objects
         */

        $scope.tile = tile;
        /**
         * hide
         */

        function validate() {
          $mdDialog.hide(tile);
        }
        /**
         * cancel
         */


        function close() {
          $mdDialog.cancel();
        }
      }]
    }).then(function (response) {
      if (response) {
        usersPrestationService.accept(event, response).then(function () {
          notifyUserService.showSimpleToast("La prestation « " + response.name + " » a été acceptée", {
            color: "success",
            hideDelay: 2000
          });
          return response;
        });
      }
    }).then(initialize);
  }
  /**
   * refuse a tile
   *
   * @param {object} tile - Tile to refuse.
   * @param {object} event - Triggered event.
   */


  function refuseTile(tile, event) {
    $mdDialog.show({
      templateUrl: "app/main/users-prestation/views/dialogs/refuse.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        tile: tile
      },
      clickOutsideToClose: true,
      controller: ["$scope", "$mdDialog", "tile", function controller($scope, $mdDialog, tile) {
        /**
         * Declaration of public methods
         */
        $scope.validate = validate;
        $scope.close = close;
        /**
         * Declaration of public objects
         */

        $scope.tile = tile;
        /**
         * hide
         */

        function validate() {
          tile.comments = $scope.comments;
          $mdDialog.hide(tile);
        }
        /**
         * cancel
         */


        function close() {
          $mdDialog.cancel();
        }
      }]
    }).then(function (response) {
      if (response) {
        usersPrestationService.refuse(event, tile).then(function () {
          notifyUserService.showSimpleToast("La prestation « " + tile.name + " » a été refusée", {
            color: "success",
            hideDelay: 2000
          });
          return response;
        });
      }
    }).then(initialize);
  }
}

angular.module("app.users-prestation").controller("UserPrestationController", UserPrestationController);