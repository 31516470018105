"use strict";
/**
 * Laboratory need service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

LaboratoryNeedService.$inject = ["apiClient"];

function LaboratoryNeedService(apiClient) {
  return {
    /**
     * get
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/ranges/products/needs"), {
        includes: ["laboratoryPriceRanges.laboratoryPrice"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * get by range
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    getByRange: function getByRange(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.rangeId, "/products/needs"), {
        includes: ["laboratoryPriceRanges.laboratoryPrice"]
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.laboratory").service("laboratoryNeedService", LaboratoryNeedService);