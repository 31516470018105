"use strict";
/**
 * Prestation photo controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $window - $window.
 * @param {object} prestationConfig - The prestation configuration.
 * @param {object} prestationService - The prestation service.
 */

PrestationPhotoController.$inject = ["$scope", "$mdDialog", "$stateParams", "$window", "prestationConfig", "prestationService"];

function PrestationPhotoController($scope, $mdDialog, $stateParams, $window, prestationConfig, prestationService) {
  $scope.filter = {
    id: $stateParams.id || "",
    photo_status: $stateParams.photo_status || "",
    organization_id: $stateParams.organization_id || "",
    healthcenter_id: $stateParams.healthcenter_id || ""
  };
  $scope.getPrestations = getPrestations;
  $scope.setPrestationFilter = setPrestationFilter;
  $scope.acceptParticipationPhotos = acceptParticipationPhotos;
  $scope.refuseParticipationPhotos = refuseParticipationPhotos;
  $scope.acceptPhotos = acceptPhotos;
  $scope.refusePhotos = refusePhotos;
  $scope.onCarouselInit = onCarouselInit;
  $scope.onCarouselAfterChange = onCarouselAfterChange;
  $scope.showStock = showStock;
  $scope.goBack = goBack;
  $scope.stocks = [];
  $scope.$watch("filter", function () {
    return getPrestations();
  }, true);
  prestationService.getNames().then(function (response) {
    $scope.names = response;
  });
  /**
   * Get all prestations.
   *
   * @returns {Promise} Promise object represents the client request.
   */

  function getPrestations() {
    return prestationService.getFromPhotos($scope.filter).then(function (response) {
      $scope.prestation = response;
    });
  }
  /**
   * Set prestation filter.
   *
   * @param {number} prestationId - The ID of the prestation.
   */


  function setPrestationFilter(prestationId) {
    $scope.filter.id = prestationId || "";
  }
  /**
   * Accept all participation photos.
   *
   * @param {number} id - The ID of the participation.
   */


  function acceptParticipationPhotos(id) {
    var confirm = $mdDialog.confirm().title("Confirmez-vous l’acceptation des photos ?").textContent("Toutes les photos en attente de la pharmacie pour cette mise en avant seront acceptées.").ariaLabel("Accepter les photos de la pharmacie").ok("Oui").cancel("Non");
    $mdDialog.show(confirm).then(function () {
      prestationService.acceptParticipationPhotos(id).then(function () {
        return getPrestations();
      });
    });
  }
  /**
   * Refuse all participation photos.
   *
   * @param {number} id - The ID of the participation.
   */


  function refuseParticipationPhotos(id) {
    $mdDialog.show({
      controller: ["$scope", "$mdDialog", function controller($scope, $mdDialog) {
        $scope.title = "Confirmez-vous le refus des photos ?";
        $scope.description = "Toutes les photos en attente de la pharmacie pour cette mise en avant seront refusées.";

        $scope.cancel = function () {
          return $mdDialog.cancel();
        };

        $scope.validate = function () {
          prestationService.refuseParticipationPhotos(id, $scope.comments, $scope.isNotMatching).then(function () {
            return getPrestations();
          }).then(function () {
            return $mdDialog.hide();
          });
        };
      }],
      templateUrl: prestationConfig.baseDir + "/photo/refuse.html"
    });
  }
  /**
   * Accept all photos.
   */


  function acceptPhotos() {
    var confirm = $mdDialog.confirm().title("Confirmez-vous l’acceptation de toutes les photos ?").textContent("Toutes les photos en attente de toutes les mises en avant seront acceptées.").ariaLabel("Accepter toutes les photos").ok("Oui").cancel("Non");
    $mdDialog.show(confirm).then(function () {
      prestationService.acceptPhotos().then(function () {
        return getPrestations();
      });
    });
  }
  /**
   * Refuse all photos.
   */


  function refusePhotos() {
    $mdDialog.show({
      controller: ["$scope", "$mdDialog", function controller($scope, $mdDialog) {
        $scope.title = "Confirmez-vous le refus de toutes les photos ?";
        $scope.description = "Toutes les photos en attente de toutes les mises en avant seront refusées.";

        $scope.cancel = function () {
          return $mdDialog.cancel();
        };

        $scope.validate = function () {
          prestationService.refusePhotos($scope.comments, $scope.isNotMatching).then(function () {
            return getPrestations();
          }).then(function () {
            return $mdDialog.hide();
          });
        };
      }],
      templateUrl: prestationConfig.baseDir + "/photo/refuse.html"
    });
  }
  /**
   * On carousel init.
   *
   * @param {object} participation - The participation object.
   */


  function onCarouselInit(participation) {
    setStock(0, participation);
  }
  /**
   * On carousel after change.
   *
   * @param {number} currentSlide - The index of the current slide.
   * @param {object} participation - The participation object.
   */


  function onCarouselAfterChange(currentSlide, participation) {
    setStock(currentSlide, participation);
  }
  /**
   * On carousel after change.
   *
   * @param {number} slide - The index of the slide to show.
   * @param {object} participation - The participation object.
   */


  function setStock(slide, participation) {
    $scope.stocks[participation.id] = participation.photos[slide].stocks;
  }
  /**
   * Show stock.
   *
   * @param {object} participation - The participation object.
   */


  function showStock(participation) {
    $mdDialog.show({
      controller: ["$scope", "$mdDialog", "stocks", function controller($scope, $mdDialog, stocks) {
        $scope.stocks = stocks;

        $scope.cancel = function () {
          return $mdDialog.cancel();
        };
      }],
      locals: {
        stocks: $scope.stocks[participation.id]
      },
      templateUrl: prestationConfig.baseDir + "/photo/stock.html"
    });
  }
  /**
   * goBack
   * to return to back page
   */


  function goBack() {
    $window.history.back();
  }
}

angular.module("app.prestation").controller("PrestationPhotoController", PrestationPhotoController);