"use strict";
/**
 * Controller for patient events card, in pharmacy dashboard.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} customerService - The customer service.
 * @param {object} patientEventService - The patient event service.
 * @param {object} eventFactory - The event factory.
 * @param {object} patientAppointmentsService - The patient appointment service.
 */

dashboardPharmaciesPatientEventsController.$inject = ["$scope", "$state", "customerService", "patientEventService", "eventFactory", "patientAppointmentsService"];

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function dashboardPharmaciesPatientEventsController($scope, $state, customerService, patientEventService, eventFactory, patientAppointmentsService) {
  $scope.loading = true;
  $scope.events = null;
  $scope.getEventDateLabel = getEventDateLabel;
  $scope.incomingEvents = null;
  $scope.redirectToEvents = redirectToEvents;
  $scope.calendarOptions = eventFactory.calendarOptions;
  $scope.calendarSources = [];
  $scope.uiConfig = {
    calendar: {
      lang: "fr",
      eventRender: eventRender
    },
    buttonText: {
      today: "Aujourd'hui",
      month: "Mois",
      basicWeek: "Semaine",
      basicDay: "Jour",
      agendaDay: "Agenda"
    }
  };
  loader();
  /**
   * The loader function
   */

  function loader() {
    Promise.all([loadEvents(), loadAppointments()])["finally"](function () {
      $scope.loading = false;
      $scope.$apply();
    });
  }
  /**
   * Event rendering.
   *
   * @param {object} event - The event to render.
   * @param {object} element - The DOM element.
   */


  function eventRender(event, element) {
    if (element.hasClass("appointment")) {
      element.find(".fc-title").prepend("<label class='mr-5 dott'>&#x25CF;</label>");
    }
  }
  /**
   * Load the appointments.
   *
   * @returns {Promise}
   */


  function loadAppointments() {
    return patientAppointmentsService.index().then(function (appointments) {
      var monthName = function monthName(item) {
        return moment(item.start_at).format("DD/MM/YYYY");
      };

      var reducedAppointments = _.groupBy(appointments, monthName);

      $scope.calendarSources.push(createAppointmentCountEvents(reducedAppointments));
    });
  }
  /**
   * Create calendar events for appointment.
   *
   * @param {object} groupedAppointments - Appointments grouped by date.
   */


  function createAppointmentCountEvents(groupedAppointments) {
    var events = [];
    Object.entries(groupedAppointments).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      var event = {
        title: value.length + " RDV",
        allDay: true,
        start: moment(key, "DD/MM/YYYY"),
        sort: "0",
        className: "appointment"
      };
      events.push(event);
    });
    return events;
  }
  /**
   * Load the events.
   *
   * @returns {Promise}
   */


  function loadEvents() {
    return patientEventService.getEvents().then(function (events) {
      $scope.calendarSources.push(manageEventForCalendar(events.data));
      var incomingEvents = events.data.filter(function (event) {
        return moment(event.start_date) > moment();
      }).sort(function (event1, event2) {
        return moment(event2.start_date) - moment(event2.start_date);
      });

      if (incomingEvents.length) {
        $scope.incomingEvent = incomingEvents[0];
      }
    });
  }
  /**
   * Manage events to be handled by the calendar.
   *
   * @param {Array} events - The events to manage.
   *
   * @returns {Array}
   */


  function manageEventForCalendar(events) {
    return events.map(function (event) {
      event.start = moment(event.start_date);
      event.end = moment(event.end_date);
      event.color = "#4BBECF";
      event.className = event.title.replace(/[^a-z0-9]+/gi, "a").replace(/ /g, "-") + event.id;
      event.sort = "1";
      return event;
    });
  }
  /**
   * Get the event date label.
   *
   * @param {object} event - The event.
   *
   * @returns {string}
   */


  function getEventDateLabel(event) {
    if (moment(event.start_date).isSame(moment(event.end_date), "day")) {
      return "Le ".concat(moment(event.start_date).format("dddd D MMMM"), " \xE0 ").concat(moment(event.start_date).format("HH:mm"));
    }

    return "Du ".concat(event.start.format("dddd D MMMM"), " au ").concat(event.end.format("dddd D MMMM YYYY"));
  }
  /**
   * Redirect to the events list page.
   *
   * @returns {void}
   */


  function redirectToEvents() {
    $state.go("app.patients.events");
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesPatientEventsController", dashboardPharmaciesPatientEventsController);