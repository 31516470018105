"use strict";
/**
 * Pharmacy stock status controller.
 *
 * @param {object} $state - The angular $state object.
 * @param {object} $scope - The angular $scope object
 * @param {object} StocksQueryService - the stocks query service.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */

dashboardPharmaciesStockStatusController.$inject = ["$state", "$scope", "StocksQueryService", "environmentService", "analysisService"];

function dashboardPharmaciesStockStatusController($state, $scope, StocksQueryService, environmentService, analysisService) {
  $scope.LabID = 0;
  $scope.loading = true;
  var groupListPharma = [{
    value: 1,
    title: "Laboratoires",
    type: "lab",
    filterId: "id"
  }, {
    value: 6,
    title: "Gammes",
    type: "range",
    filterId: "id"
  }, {
    value: 7,
    title: "Catégories"
  }, {
    value: 8,
    title: "Segments"
  }, {
    value: 9,
    title: "Produits"
  }];
  $scope.today = new Date();
  loader();
  /**
   * The loader function.
   */

  function loader() {
    initTab();
  }

  $scope.currentEnv = environmentService.getEnvironment(); // Methods

  $scope.goToState = function (state) {
    $state.go(state);
  }; // START goumy


  $scope.amChartOptionsPie0w90 = {
    data: [],
    type: "pie",
    theme: "light",
    startDuration: 0,
    marginTop: 5,
    marginBottom: 0,
    marginLeft: 5,
    marginRight: 5,
    pullOutRadius: 10,
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    unit: " EUR HT",
    categoryField: "title",
    valueField: "amount",
    colorField: "color",
    titleField: "title",
    precision: 0,
    balloon: {
      fixedPosition: true
    },
    legend: {
      divId: "legenddivGamme"
    },
    "export": {
      enabled: true,
      menu: [{
        "class": "export-main",
        menu: [{
          format: "PNG",
          title: "Export chart to PNG"
        }, {
          format: "JPG",
          title: "Export chart to JPG"
        }]
      }]
    }
  };
  /**
   * Init the queries.
   */

  function initTab() {
    $scope.groupByList = groupListPharma;
    $scope.groupByChart = $scope.groupByList[0];
    $scope.usrquery = StocksQueryService.query;
    $scope.query = StocksQueryService.buildQuery($scope.usrquery);
    launchSearch($scope.query, true);
  }
  /**
   * Launch search.
   *
   * @param {object} obj - Query.
   */


  function launchSearch() {
    var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    analysisService.getLaboratoryStockByGroup($scope.LabID, 1, obj).then(function (response) {
      $scope.loading = false;
      $scope.detailStocksProducts = response.hits.hits;
      $scope.detailStocksGeneral = response.aggregations;
      processDataForAmCharts();
    });
  }
  /**
   * Process data for the chart.
   */


  function processDataForAmCharts() {
    var colors = [{
      title: "< 10 jours",
      color: "#56C2D7"
    }, {
      title: "Entre 10 et 20 jours",
      color: "#56C2D7"
    }, {
      title: "Entre 20 et 30 jours",
      color: "#A2D8E0"
    }, {
      title: "Entre 30 et 60 jours",
      color: "#A2CC62"
    }, {
      title: "Entre 60 et 90 jours",
      color: "#CAE3B7"
    }, {
      title: "> 90 jours",
      color: "#444444"
    }, {
      title: "Stock dormant",
      color: "#4D86F6"
    }];
    var dataRangeGrossAmount0w90 = [];
    angular.forEach($scope.detailStocksGeneral.range_days.buckets, function (bucket) {
      var nbRange = bucket.key;

      if (nbRange < 4) {
        var data = {
          range30days: "-" + nbRange + " mois",
          amount: bucket.needs_amount.value,
          color: colors[nbRange].color,
          title: colors[nbRange].title
        };
        dataRangeGrossAmount0w90.push(data);
      } else {
        var _data = {
          range30days: "-" + nbRange + " mois",
          amount: bucket.order_amount.value,
          color: colors[nbRange].color,
          title: colors[nbRange].title
        };
        dataRangeGrossAmount0w90.push(_data);
      }
    });
    $scope.amChartOptionsPie0w90.data = dataRangeGrossAmount0w90;
    $scope.loading = false;
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesStockStatusController", dashboardPharmaciesStockStatusController);