"use strict";
/**
 * PatientAdministrationProductsPathologyController
 *
 * @param {object} $scope - $scope.
 * @param {object} administrationPatientUserService - The administration user service.
 */

PatientAdministrationUsersController.$inject = ["$scope", "administrationPatientUserService"];

function PatientAdministrationUsersController($scope, administrationPatientUserService) {
  $scope.query = {
    order: "information.name",
    limit: 10,
    page: 1,
    total: null,
    search: ""
  };
  $scope.loading = false;
  loadUsers();
  /**
   * Load users.
   *
   * @returns {Promise}
   */

  function loadUsers() {
    var params = {
      certification_status: "pending"
    };
    $scope.loading = true;
    return administrationPatientUserService.index(params).then(function (response) {
      $scope.query.total = response.data.length;
      $scope.users = response.data;
      $scope.loading = false;
    });
  }
}

angular.module("app.patient-administration").controller("patientAdministrationUsersController", PatientAdministrationUsersController);