"use strict";

StatisticsSalesAnalysisConfig.$inject = ["$stateProvider"];
angular.module("app.statistics.salesAnalysis", []).config(StatisticsSalesAnalysisConfig);
/** @ngInject */

/**
 * Statistics sales analysis config.
 *
 * @param {object} $stateProvider - The angular $stateProvider object
 */

function StatisticsSalesAnalysisConfig($stateProvider) {
  $stateProvider.state("app.statistics.salesAnalysis", {
    url: "/analyse-ventes",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/salesAnalysis/title.html",
        controller: "StatisticsController as vm"
      },
      "statisticsContent@app.statistics.salesAnalysis": {
        templateUrl: "app/main/statistics/salesAnalysis/salesAnalysis.html",
        controller: "salesAnalysisController as vm"
      },
      "tabContent@app.statistics.salesAnalysis": {
        templateUrl: "app/main/statistics/salesAnalysis/views/sandbox-lines-atcl/sandbox-lines-atcl.html",
        controller: "StatsSandboxLinesAtclController as vm"
      }
    },
    bodyClass: "dashboard"
  }).state("app.statistics.salesAnalysis.sandBoxLineAtcl", {
    url: "/courbe",
    views: {
      "tabContent@app.statistics.salesAnalysis": {
        templateUrl: "app/main/statistics/salesAnalysis/views/sandbox-lines-atcl/sandbox-lines-atcl.html",
        controller: "StatsSandboxLinesAtclController as vm"
      }
    },
    params: {
      startDate: null,
      endDate: null,
      products: null,
      pharmas: null,
      segment: null
    }
  }).state("app.statistics.salesAnalysis.topLabo", {
    url: "/top",
    views: {
      "tabContent@app.statistics.salesAnalysis": {
        templateUrl: "app/main/statistics/salesAnalysis/topAnalysis/topAnalysis.html",
        controller: "topAnalysisController"
      }
    }
  }).state("app.statistics.salesAnalysis.marketShare", {
    url: "/parts-de-marche",
    views: {
      "tabContent@app.statistics.salesAnalysis": {
        templateUrl: "app/main/statistics/salesAnalysis/views/marketShare/marketShare.html",
        controller: "MarketShareController as vm"
      }
    },
    bodyClass: "dashboard",
    params: {
      segment: null
    }
  }).state("app.statistics.salesAnalysis.memberAnalysis", {
    url: "/analyse-adherents",
    views: {
      "tabContent@app.statistics.salesAnalysis": {
        templateUrl: "app/main/statistics/salesAnalysis/memberAnalysis/memberAnalysis.html",
        controller: "MemberAnalysisController as vm"
      }
    },
    bodyClass: "dashboard",
    params: {
      segment: null
    }
  }).state("app.statistics.salesAnalysis.groupingMdl", {
    url: "/analyse-mdl",
    views: {
      "tabContent@app.statistics.salesAnalysis": {
        templateUrl: "app/main/statistics/salesAnalysis/groupingMdl/groupingMdl.html",
        controller: "MargeAuditGroupingController as vm"
      }
    },
    bodyClass: "dashboard"
  }).state("app.statistics.salesAnalysis.marketSharesEvolution", {
    url: "/evolution-parts-de-marche",
    views: {
      "tabContent@app.statistics.salesAnalysis": {
        templateUrl: "app/main/statistics/salesAnalysis/views/market-shares-evolution/marketSharesEvolution.html",
        controller: "marketSharesEvolutionController"
      }
    },
    bodyClass: "dashboard"
  }).state("app.statistics.wholesalerPurchasesAnalysis", {
    url: "/analyse-achats-grossistes",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/salesAnalysis/views/" + "wholesaler-purchases-analysis/wholesalerPurchasesAnalysis.html",
        controller: "wholesalerPurchasesAnalysisController"
      }
    },
    bodyClass: "dashboard"
  }).state("app.statistics.wholesalerPurchasesAnalysis.detail", {
    url: "/:id/:startDate/:endDate",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/salesAnalysis/views/" + "wholesaler-purchases-analysis/detail/detail.html",
        controller: "detailWholesalerPurchasesAnalysisController"
      }
    },
    params: {
      wholesalerPurchaseAnalysis: null
    },
    bodyClass: "dashboard"
  });
}