"use strict";
/**
 * Patient prescription details controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $mdDialog - The $mdDialog.
 * @param {object} customerService - The customer service.
 * @param {object} notifyUserService - The notify user service.
 */

PatientsPrescriptionDetailsController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "customerService", "notifyUserService"];

function PatientsPrescriptionDetailsController($scope, $state, $stateParams, $mdDialog, customerService, notifyUserService) {
  $scope.loading = true;
  $scope.redirectToPrescriptions = redirectToPrescriptions;
  $scope.prescription = null;
  $scope.redirectToDosagePlan = redirectToDosagePlan;
  $scope.openRenewalSchedule = openRenewalSchedule;
  $scope.prescriptionDetailsTitle = {
    title: {
      text: "Ordonnance de ",
      back: {
        state: null
      }
    }
  };
  loader();
  /**
   * Fetch the prescription passed in $stateParams.
   */

  function loader() {
    customerService.readPrescription($stateParams.patientId, $stateParams.prescriptionId).then(function (response) {
      var prescription = response.data;
      prescription.created_at = new Date(prescription.created_at);
      $scope.prescription = prescription;
      $scope.prescriptionDetailsTitle.title.text += $scope.prescription.beneficiary ? $scope.prescription.beneficiary.information.name : $scope.prescription.customer.information.name;
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue lors du chargement de l'ordonnance");
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Redirect to the prescriptions list.
   */


  function redirectToPrescriptions() {
    $state.go("app.patients.details.prescriptions");
  }
  /**
   * Open renewal schedule modal
   */


  function openRenewalSchedule() {
    $mdDialog.show({
      controller: "prescriptionScheduleController",
      locals: {
        prescription: $scope.prescription
      },
      templateUrl: "app/main/patients/components/prescription-schedule/prescription-schedule.html",
      targetEvent: event,
      clickOutsideToClose: true,
      multiple: true,
      skipHide: true
    });
  }
  /**
   * Redirect to the dosage plan.
   */


  function redirectToDosagePlan() {
    $state.go("app.patients.details.prescription.dosage", {
      dosageId: $scope.prescription.dosage_plan.id
    });
  }
}

angular.module("app.patients").controller("patientPrescriptionDetailsController", PatientsPrescriptionDetailsController);