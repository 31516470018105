"use strict";
/**
 * User prestation detail controller.
 *
 * @param {object} $stateParams - $stateParams.
 * @param {string} apiurl - API URL.
 * @param {object} usersPrestationService - Users prestation service.
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $timeout - $timeout.
 * @param {object} mapService - Map service.
 */

UserPrestationDetailController.$inject = ["$stateParams", "apiurl", "usersPrestationService", "$state", "$scope", "$mdDialog", "$timeout", "mapService"];

function UserPrestationDetailController($stateParams, apiurl, usersPrestationService, $state, $scope, $mdDialog, $timeout, mapService) {
  DialogController.$inject = ["$scope", "$mdDialog", "locals"];
  var vm = this;
  var prestationId = $stateParams.id;
  var imageFallback = "assets/images/sellouts/default-challenge-1080x600.jpg";
  vm.token = window.sessionStorage.token;
  vm.apiurl = apiurl;
  vm.loading = false;
  vm.initialize = init();
  vm.acceptPrestation = acceptPrestation;
  vm.refusePrestation = refusePrestation;
  vm.openRecommendation = openRecommendation;
  var distanceMarker;
  /**
   * Initialize.
   */

  function init() {
    readPrestation();
  }
  /** Read a prestation. */


  function readPrestation() {
    vm.loading = true;
    usersPrestationService.read(prestationId).then(function (prestation) {
      vm.userPrestation = prestation;
      vm.now = new Date();
      vm.start = new Date(prestation.prestation.startDatetime);
      vm.end = new Date(prestation.prestation.endDatetime);
      vm.image = prestation.prestation.imagePath ? vm.apiurl + "/images/" + prestation.prestation.imagePath : imageFallback;
      /** Read a prestation picture. */

      usersPrestationService.readPhoto($stateParams.id).then(function (response) {
        vm.readUserPrestationPicture = response.photos;
        openMap(vm.readUserPrestationPicture);
        vm.statusTotal = {
          prestationAccept: 0,
          prestationRefused: 0
        }; // calculate distance healthcenter vs picture

        angular.forEach(vm.readUserPrestationPicture, function (distancePhoto, index) {
          // Status counter
          switch (true) {
            case distancePhoto.status === 1:
              vm.statusTotal.prestationAccept++;
              break;

            case distancePhoto.status === 2:
              vm.statusTotal.prestationRefused++;
              break;
          }

          var photoLatLng = {
            lat: distancePhoto.latitude,
            lng: distancePhoto.longitude
          };
          var markerLatLng = new google.maps.LatLng({
            lat: distancePhoto.organization.morphable.address.latitude,
            lng: distancePhoto.organization.morphable.address.longitude
          });
          distanceMarker = google.maps.geometry.spherical.computeDistanceBetween(markerLatLng, new google.maps.LatLng(photoLatLng.lat, photoLatLng.lng));
          distancePhoto.outside = false;
          distancePhoto.distanceMarker = distanceMarker;

          if (distanceMarker > 100) {
            distancePhoto.outside = true;
            $scope.classWarning = "warningImg";
          }

          vm.distancePrestation = distancePhoto;
          vm.readUserPrestationPicture[index] = distancePhoto;
        });
      });
      vm.loading = false;
    });
  }
  /**
   * Accept a prestation.
   *
   * @param {object} event - The event object.
   * @param {object} prestation - The prestation to accept.
   */


  function acceptPrestation(event, prestation) {
    usersPrestationService.accept(event, prestation).then(function (response) {
      if (response) {
        init();
      }
    });
  }
  /**
   * Refuse a prestation.
   *
   * @param {object} event - The event object.
   * @param {object} prestation - The prestation to refuse.
   */


  function refusePrestation(event, prestation) {
    $mdDialog.show({
      templateUrl: "app/main/users-prestation/views/dialogs/refuse.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        tile: prestation
      },
      clickOutsideToClose: true,
      controller: ["$scope", "$mdDialog", "tile", function controller($scope, $mdDialog, tile) {
        /**
         * Declaration of public methods
         */
        $scope.validate = validate;
        $scope.close = close;
        /**
         * Declaration of public objects
         */

        $scope.tile = tile;
        /**
         * hide
         */

        function validate() {
          tile.comments = $scope.comments;
          $mdDialog.hide(tile);
        }
        /**
         * cancel
         */


        function close() {
          $mdDialog.cancel();
        }
      }]
    }).then(function (response) {
      if (response) {
        usersPrestationService.refuse(event, prestation).then(function (response) {
          if (response) {
            init();
          }
        });
      }
    });
  }
  /**
   * Open a recommendation.
   *
   * @param {object} recommendation - The recommendation to open.
   */


  function openRecommendation(recommendation) {
    window.open(vm.apiurl + "/images/" + recommendation.filePath);
  }
  /**
   * Open map.
   *
   * @param {object} map - Map.
   */


  function openMap(map) {
    $scope.openMap = function (ev, picture) {
      $mdDialog.show({
        controller: DialogController,
        templateUrl: "app/main/prestation/map/detail/dialog/map.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        openFrom: "left",
        locals: {
          map: map,
          picture: picture
        }
      });
    };
  }
  /**
   * Dialog controller.
   *
   * @param {object} $scope - $scope.
   * @param {object} $mdDialog - $mdDialog
   * @param {object} locals - Locals.
   */


  function DialogController($scope, $mdDialog, locals) {
    $scope.locals = locals;

    $scope.hide = function () {
      $mdDialog.hide();
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $timeout(function () {
      mapService.initialize(locals.picture, vm.prestationPicture);
    }, 200);
  }

  vm.gotoList = function () {
    $state.go("app.UserPrestationHome");
  };
}

angular.module("app.users-prestation-detail", []).controller("UserPrestationDetailController", UserPrestationDetailController);