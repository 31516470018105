"use strict";
/**
 * Auth configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

AuthConfig.$inject = ["$stateProvider"];

function AuthConfig($stateProvider) {
  $stateProvider.state("app.pages_auth_register", {
    url: "/nouveau-compte",
    views: {
      "main@": {
        templateUrl: "app/core/layouts/content-only.html",
        controller: "MainController as vm"
      },
      "content@app.pages_auth_register": {
        templateUrl: "app/main/auth/views/register/register.html",
        controller: "RegisterController as vm"
      }
    },
    bodyClass: "register"
  }).state("app.pages_auth_login", {
    url: "/identification?deep-link",
    views: {
      "main@": {
        templateUrl: "app/core/layouts/content-only.html",
        controller: "MainController as vm"
      },
      "content@app.pages_auth_login": {
        templateUrl: "app/main/auth/views/login/login.html",
        controller: "LoginController as vm"
      }
    },
    bodyClass: "login"
  }).state("app.pages_auth_login.as", {
    url: "/as?key",
    views: {
      "main@": {
        templateUrl: "app/core/layouts/content-only.html",
        controller: "MainController as vm"
      },
      "content@app.pages_auth_login.as": {
        templateUrl: "app/main/auth/views/login/login.html",
        controller: "LoginController as vm"
      }
    },
    bodyClass: "login"
  }).state("app.pages_auth_forgot_password", {
    url: "/forgot-password",
    views: {
      "main@": {
        templateUrl: "app/core/layouts/content-only.html",
        controller: "MainController as vm"
      },
      "content@app.pages_auth_forgot_password": {
        templateUrl: "app/main/auth/views/forgot-password/forgot-password.html",
        controller: "ForgotPasswordController as vm"
      }
    },
    bodyClass: "forgot-password"
  }).state("app.pages_auth_change_forgot_password", {
    url: "/change-forgot-password/:code",
    views: {
      "main@": {
        templateUrl: "app/core/layouts/content-only.html",
        controller: "MainController as vm"
      },
      "content@app.pages_auth_change_forgot_password": {
        templateUrl: "app/main/auth/views/change-forgot-password/change-forgot-password.html",
        controller: "ChangeForgotPasswordController as vm"
      }
    },
    bodyClass: "change-forgot-password"
  }).state("app.pages_auth_guest_pharmedicom", {
    url: "/guest_pharmedicom",
    views: {
      "main@": {
        templateUrl: "app/main/auth/views/registerPharmedicom/guest.html",
        controller: "registerPharmedicomController"
      }
    }
  }).state("app.pages_auth_guest", {
    url: "/guest/:b64",
    views: {
      "main@": {
        templateUrl: "app/main/auth/views/guest/guest.html",
        controller: "guestController"
      }
    }
  }).state("app.confirm", {
    url: "/confirm",
    views: {
      "main@": {
        templateUrl: "app/main/auth/views/confirm-mail-modification/confirm-mail-modification.html",
        controller: "confirmMailModificationController"
      }
    }
  });
}

angular.module("app.auth", []).config(AuthConfig);