"use strict";
/**
 * Controller for wholesalers top.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} disruptionService - The disruption service.
 */

WholesalersTopController.$inject = ["$scope", "disruptionService"];

function WholesalersTopController($scope, disruptionService) {
  loader();
  $scope.analysisPeriod = "year";
  $scope.changeOptions = changeOptions;
  /**
   * The loading function.
   */

  function loader() {
    disruptionService.getWholesalersTop().then(function (data) {
      $scope.data = data;
      $scope.wholesalerTopChartOptions = getOptions(data.aggregations[$scope.analysisPeriod]);
    });
  }
  /**
   * Change the values in the chart.
   *
   * @param {string} period - The period to analyze.
   */


  function changeOptions(period) {
    $scope.analysisPeriod = period;
    $scope.wholesalerTopChartOptions = getOptions($scope.data.aggregations[period]);
  }
  /**
   * Get chart options.
   *
   * @param {Array} data - The data to show in the graph.
   *
   * @returns {object}
   */


  function getOptions(data) {
    return {
      type: "serial",
      theme: "none",
      legend: {
        useGraphSettings: true,
        position: "bottom"
      },
      data: getData(data),
      thousandsSeparator: " ",
      precision: 0,
      valueAxes: [{
        axisAlpha: 0,
        gridCount: 10,
        position: "left",
        title: "Taux de rupture"
      }],
      startDuration: 0,
      graphs: getGraphs(data),
      chartCursor: {
        enabled: true,
        avoidBalloonOverlapping: false,
        balloonPointerOrientation: "vertical",
        oneBalloonOnly: true,
        cursorAlpha: 0,
        cursorColor: "#003D56"
      },
      categoryField: "month",
      categoryAxis: {
        gridPosition: "start",
        axisAlpha: 0,
        fillAlpha: 0.05,
        fillColor: "#000000",
        gridAlpha: 0,
        position: "top"
      },
      "export": {
        enabled: true,
        position: "bottom-right"
      }
    };
  }
  /**
   * Get data.
   *
   * @param {Array} period - The data to parse.
   * @returns {Array}
   */


  function getData(period) {
    var data = period.wholesalers.buckets[0].months.buckets.map(function (bucket) {
      return {
        month: bucket.key_as_string
      };
    });
    angular.forEach(period.wholesalers.buckets, function (bucket) {
      angular.forEach(bucket.months.buckets, function (month) {
        var currentMonth = data.find(function (datum) {
          return datum.month === month.key_as_string;
        });

        if (month.orderedQuantity) {
          currentMonth[bucket.key] = month.orderedQuantity.value !== 0 ? month.undeliveredQuantity.value / month.orderedQuantity.value * 100 : 0;
        }
      });
    });
    return data;
  }
  /**
   * Get graphs.
   *
   * @param {Array} data - The data to parse.
   * @returns {Array}
   */


  function getGraphs(data) {
    return data.wholesalers.buckets.map(function (bucket) {
      return {
        balloonText: "[[category]]: [[value]] %",
        title: bucket.wholesalerName,
        valueField: bucket.key
      };
    });
  }
}

angular.module("app.dashboard").controller("wholesalersTopController", WholesalersTopController);