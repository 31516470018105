"use strict";
/**
 * Healthcenter audit detail controller.
 *
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $window - $window.
 * @param {object} healthcenterService - Healthcenter service.
 */

HealthcenterAuditDetailController.$inject = ["StatisticsQueryService", "environmentService", "$state", "$stateParams", "$window", "healthcenterService"];

function HealthcenterAuditDetailController(StatisticsQueryService, environmentService, $state, $stateParams, $window, healthcenterService) {
  var vm = this;

  if (typeof $stateParams.id === "undefined") {
    vm.goBack();
  }

  vm.goBack = function () {
    $window.history.back();
  };

  healthcenterService.getDetails({
    id: $stateParams.id
  }).then(function (pharma) {
    vm.pharma = pharma.healthcenter;
    environmentService.getEnvironment().then(function (env) {
      vm.currentEnv = env;
      StatisticsQueryService.queryRebase();
      vm.isPharmaceuticalDelegate = env.accessPointType === 1 && env.rankId === 15;

      switch ($state.current.name) {
        case "app.healthcenterAudit.detail.general":
          vm.selected = 0;
          break;

        case "app.healthcenterAudit.detail.salesEvolution":
          vm.selected = 1;
          break;

        case "app.healthcenterAudit.detail.marketShare":
          vm.selected = 2;
          break;

        case "app.healthcenterAudit.detail.goumy":
          vm.selected = 3;
          break;

        case "app.healthcenterAudit.detail.realStock":
          vm.selected = 4;
          break;

        case "app.healthcenterAudit.detail.distributor":
          vm.selected = 5;
          break;

        case "app.healthcenterAudit.detail.reassort":
          vm.selected = 6;
          break;

        case "app.healthcenterAudit.detail.sellout":
        case "app.healthcenterAudit.detail.supplyingAnalysis":
          vm.selected = 7;
          break;

        case "app.healthcenterAudit.detail.prestation":
          vm.selected = 8;
          break;

        case "app.healthcenterAudit.detail.sellin":
          vm.selected = 9;
          break;

        case "app.healthcenterAudit.detail.formation":
          vm.selected = 10;
          break;

        case "app.healthcenterAudit.detail.visit":
          vm.selected = 11;
          break;

        case "app.healthcenterAudit.detail.clientService":
          vm.selected = 12;
          break;

        case "app.healthcenterAudit.detail.chat":
          vm.selected = 13;
          break;

        case "app.healthcenterAudit.detail.visitePrep":
          vm.selected = 14;
          break;

        case "app.healthcenterAudit.detail.doc":
          vm.selected = 15;
          break;

        default:
          vm.selected = 0;
      }
    });
  });
}

angular.module("app.healthcenterAudit").controller("HealthcenterAuditDetailController", HealthcenterAuditDetailController);