"use strict";
/**
 * The app-card directive.
 *
 * @returns {object}
 */

function AppCard() {
  return {
    restrict: "E",
    scope: {
      variant: "@variant"
    },
    templateUrl: "app/main/directives/app-card/appCard.html",
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appCard", AppCard);