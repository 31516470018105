"use strict";
/**
 * Laboratory filter service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

LaboratoryFilterService.$inject = ["apiClient"];

function LaboratoryFilterService(apiClient) {
  return {
    /**
     * Get.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.get("/organizations/".concat(params.organizationId, "/laboratories"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Post.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    post: function post(params) {
      return apiClient.post("/organizations/".concat(params.organizationId, "/laboratories/").concat(params.laboratoryId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/organizations/".concat(params.organizationId, "/laboratories/").concat(params.laboratoryId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.laboratory-filter").service("laboratoryFilterService", LaboratoryFilterService);