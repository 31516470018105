"use strict";
/**
 * Home controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} accessService - Access service.
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} bricksService - Bricks service.
 * @param {object} $state - $state.
 * @param {object} healthcenterService - Healthcenter service.
 */

HomeController.$inject = ["$rootScope", "$mdDialog", "accessService", "$scope", "environmentService", "bricksService", "$state", "healthcenterService"];

function HomeController($rootScope, $mdDialog, accessService, $scope, environmentService, bricksService, $state, healthcenterService) {
  dialogLgoCtrl.$inject = ["authLgoService", "$scope", "environmentService", "$mdDialog"];
  var vm = this;
  $rootScope.showBricksMenu = true;
  $rootScope.showLeftMenuToggle = true;
  vm.loading = true;
  vm.lgoModalVsible = false;
  loader();
  /**
   * loader
   * to init each methods inside
   */

  function loader() {
    getCurrentUser();
  }
  /**
   * getCurrentUser
   *
   * @returns {Promise}
   */


  function getCurrentUser() {
    return environmentService.getEnvironment().then(function (response) {
      if (response) {
        vm.env = response;
        vm.user = response.user;
        var options = angular.fromJson(window.sessionStorage.getItem("payload")).options;
        $scope.hasBricksMenus = options.indexOf("bricks-menus") > -1;
        bricksService.getBricks().then(function (bricks) {
          if (bricks.length === 1 || bricks.length && !$scope.hasBricksMenus) {
            $rootScope.showBricksMenu = false;
            $rootScope.showLeftMenu = true;
            bricksService.setCurrentBrick(bricks[0]);
            $rootScope.$broadcast("sideNavConstruct", bricks[0]);
            $state.go("app.dashboard", {
              brickSlug: bricks[0].slug.replace(".", "_")
            });
          }

          accessService.isHcUnplugged(response.accessPointType, response.rankId).then(function (isUnplugged) {
            $rootScope.showLeftMenu = !isUnplugged && $rootScope.showLeftMenu;
            $rootScope.showLeftMenuToggle = !isUnplugged && $rootScope.showLeftMenuToggle;
            vm.hcUnplugged = isUnplugged;
          });
          vm.admin = vm.user.role === "Administrator";
          vm.hideDash = false;

          if (response.accessPointType === 2 && (response.rankId === 1 || response.rankId === 2) && !vm.env.entity.lgo && !vm.lgoModalVsible) {
            showLgoDialog();
          }
        });
      } else {
        vm.hideDash = true;
      }

      return response;
    });
  }
  /**
   * Show LGO dialog.
   */


  function showLgoDialog() {
    if (!vm.lgoModalVsible) {
      vm.lgoModalVsible = true;
      $mdDialog.show({
        controller: dialogLgoCtrl,
        templateUrl: "app/main/home/dialogs/Lgo.tmpl.html",
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        escapeToClose: false
      }).then(function () {
        vm.lgoModalVsible = false;

        if (vm.env.entity.lastLgoImport === null) {
          $state.go("app.santesecure");
        }
      });
    }
  }
  /**
   * Dialog LGO controller.
   *
   * @param {object} authLgoService - Auth LGO service.
   * @param {object} $scope - $scope.
   * @param {object} environmentService - Environment service.
   * @param {object} $mdDialog - $mdDialog.
   */


  function dialogLgoCtrl(authLgoService, $scope, environmentService, $mdDialog) {
    $scope.lgo = {
      id: null
    };
    $scope.affectedLgo = affectedLgo;
    /**
     * Get LGO.
     */

    function getLgo() {
      authLgoService.get().then(function (response) {
        $scope.lgoList = response.lgo;
      });
    }

    getLgo();
    /**
     * Affect LGO.
     */

    function affectedLgo() {
      environmentService.getEnvironment().then(function (env) {
        $scope.env = env;
        healthcenterService.affectLgo({
          id: $scope.env.entity.id,
          lgo: $scope.lgo.id
        }).then(function () {
          $mdDialog.hide();
        });
      });
    }
  }
}

angular.module("app.home").controller("HomeController", HomeController).filter("capitalize", function () {
  return function (input) {
    return input ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : "";
  };
});