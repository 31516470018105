"use strict";
/**
 * User profile controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $q - $q.
 * @param {object} accessService - Access service.
 * @param {object} environmentService - Environment service.
 * @param {object} $scope - $scope.
 * @param {object} authProfileService - Auth profile service.
 * @param {object} authSponsorshipsService - Auth sponsorships service.
 * @param {object} healthcenterService - Healthcenter service.
 */

UserProfileController.$inject = ["$rootScope", "$q", "accessService", "environmentService", "$scope", "authProfileService", "authSponsorshipsService", "healthcenterService"];

function UserProfileController($rootScope, $q, accessService, environmentService, $scope, authProfileService, authSponsorshipsService, healthcenterService) {
  $rootScope.showBricksMenu = false;
  $rootScope.showLeftMenu = false;
  $scope.genres = [{
    val: false,
    display: "Masculin"
  }, {
    val: true,
    display: "Féminin"
  }]; // Pwd change

  $scope.npwd = {
    old: "",
    "new": ""
  };
  $scope.pwdnewconfirm = ""; // Form Messages

  $scope.generalFormSuccess = false;
  $scope.generalFormError = false;
  $scope.secuFormSuccess = false;
  $scope.secuFormError = false;
  $scope.updateMe = updateMe;
  $scope.updateMyPwd = updateMyPwd;
  $scope.updateAnAddress = updateAnAddress;
  $scope.userEmailIsAvailable = userEmailIsAvailable;
  $scope.userEmail = {
    message: "",
    isUnavailable: true,
    isModified: false,
    hasSpace: false,
    availabilityInProgress: false
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    $scope.loading = true;
    loaderCurrentUser();
    loaderCurrentEnv();
    loaderSponsorCode();
    loaderSponsor();
    loaderSponsorGodson();
    userEmailIsAvailable();
  }
  /**
   * Current user loading
   *
   * @returns {Promise}
   */


  function loaderCurrentUser() {
    return accessService.getCurrent().then(function (response) {
      if (response.user.birthTime) {
        response.user.birthTime = new Date(moment(response.user.birthTime, "YYYY-MM-DD"));
      }

      $scope.user = response.user;
      $scope.oldEmail = $scope.user.email;
      $scope.loading = false;
      return response;
    });
  }
  /**
   * Current environment loading
   *
   * @returns {Promise}
   */


  function loaderCurrentEnv() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentEnv = response;
    });
  }
  /**
   * Sponsor code loading
   */


  function loaderSponsorCode() {
    authSponsorshipsService.getCode().then(function (response) {
      $scope.sponsorShipCode = response.sponsorCode;
    });
  }
  /**
   * Sponsors loading
   */


  function loaderSponsor() {
    authSponsorshipsService.getSponsor().then(function (response) {
      if (response.sponsor) {
        healthcenterService.getDetails({
          id: response.sponsor.sponsorHealthcenterId
        }).then(function (hcsponsor) {
          $scope.sponsor = hcsponsor.healthcenter.name;
        });
      }
    });
  }
  /**
   * Sponsor godsons loading
   */


  function loaderSponsorGodson() {
    authSponsorshipsService.getGodsons().then(function (response) {
      $scope.godsons = [];

      if (response.godsons) {
        _.each(response.godsons, function (godson) {
          $scope.godsons.push(godson.healthcenter);
        });
      }
    });
  }
  /**
   * Check if form is valid.
   *
   * @param {boolean} valid - Valid.
   */


  function formState(valid) {
    $scope.generalFormError = !valid;
    $scope.generalFormSuccess = Boolean(valid);
  }
  /**
   * Validate the form.
   *
   * @returns {object}
   */


  function validForm() {
    var user = angular.copy($scope.user);
    user.gender = JSON.parse(user.genderSelected).val;

    if (user.birthTime) {
      var birthTime = moment(user.birthTime);

      if (!birthTime.isValid()) {
        return false;
      }

      user.birthTime = birthTime.format("YYYY-MM-DD");
    }

    return user;
  }
  /**
   * Update the current user.
   *
   * @returns {Promise}
   */


  function updateMe() {
    var user = validForm();

    if (user !== false) {
      return accessService.putMe(user).then(function (response) {
        formState(true);
        return response;
      }, function () {
        formState(false);
      });
    }

    formState(false);
    return $q.when(null);
  }
  /**
   * Update tue current user address
   *
   * @param {number} userId - User ID.
   * @param {object} address - Address.
   *
   * @returns {Promise}
   */


  function updateAnAddress(userId, address) {
    var newAddress = {
      id: userId,
      address: address
    };
    return accessService.putAddressByUserId(newAddress).then(function () {// do nothing
    }, function (response) {
      if (response.status === 404) {
        address.typeId = 0;
        postAnAddress(userId, address);
      }
    });
  }
  /**
   * Create an address
   *
   * @param {number} userId - User ID.
   * @param {object} address - Address.
   *
   * @returns {Promise}
   */


  function postAnAddress(userId, address) {
    var newAddress = {
      id: userId,
      address: address
    };
    return accessService.postAddressByUserId(newAddress);
  }
  /**
   * Update my password.
   *
   * @returns {Promise}
   */


  function updateMyPwd() {
    if ($scope.npwd["new"] !== $scope.pwdnewconfirm) {
      // eslint-disable-next-line no-alert
      alert("Passwords must be a match");
      return Promise.reject();
    }

    if (!$scope.npwd.old.length || !$scope.npwd["new"].length) {
      // eslint-disable-next-line no-alert
      alert("Passwords cannot be empty");
      return Promise.reject();
    }

    return accessService.putPassword($scope.npwd).then(function () {
      $scope.secuFormError = false;
      $scope.secuFormSuccess = true;
    }, function (response) {
      $scope.secuFormError = true;
      $scope.secuFormSuccess = false;
      $scope.secuFormErrorMessage = response.error.message;
    });
  }
  /**
   * Check if new email is available.
   *
   * @param {string} email -
   */


  function userEmailIsAvailable(email) {
    if ($scope.oldEmail !== email) {
      $scope.userEmail.isModified = true;
      $scope.userEmail.availabilityInProgress = true;

      if (email && email.length >= 5) {
        $scope.emailHasSpace = $scope.user.email.indexOf(" ") > -1;
        $scope.userEmail.hasSpace = $scope.emailHasSpace;
        authProfileService.availableMail(email).then(function (response) {
          $scope.userEmail.isUnavailable = !response.success;
          $scope.userEmail.message = response.message;
          $scope.userEmail.availabilityInProgress = false;
        });
      } else {
        $scope.userEmail.message = "Veuillez renseigner un email valide.";
        $scope.userEmail.isUnavailable = true;
      }
    } else {
      $scope.userEmail.isUnavailable = false;
    }
  }
}

angular.module("app.users").controller("UserProfileController", UserProfileController);