"use strict";
/**
 * Detail general tab controller.
 *
 * @param {object} $state - $state.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $timeout - $timeout.
 * @param {object} $animate - $animate.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} environmentService - Environment service.
 * @param {object} organizationService - Organization service.
 * @param {object} healthcenterService - Healthcenter service.
 * @param {object} healthcenterGroupingService - Healthcenter grouping service.
 */

DetailGeneralTabController.$inject = ["$state", "$rootScope", "$scope", "$timeout", "$animate", "$mdDialog", "environmentService", "organizationService", "healthcenterService", "healthcenterGroupingService"];

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function DetailGeneralTabController($state, $rootScope, $scope, $timeout, $animate, $mdDialog, environmentService, organizationService, healthcenterService, healthcenterGroupingService) {
  var vm = this;
  $scope.updateOrganizationAddress = updateOrganizationAddress;
  $scope.cancelOrganizationAddressForm = cancelOrganizationAddressForm;
  $scope.mediaCallback = mediaCallback;
  $scope.deleteMedia = deleteMedia;
  environmentService.getEnvironment().then(function (environment) {
    vm.currentEnv = environment;
  }).then(function () {
    getHealthcenter();
  }).then(function () {
    readOrganizationBankDetail();
  }); // EDIT MODE

  $scope.edit = {
    general: {
      opn: false,
      cpy: null,
      progress: false,
      selector: "genTools",
      success: successGen
    },
    opening: {
      opn: false,
      cpy: null,
      progress: false,
      selector: "opnTools",
      regex: /([0-9]{2})[:]([0-9]{2})$/i,
      success: successOpns
    },
    ids: {
      opn: false,
      cpy: null,
      progress: false,
      selector: "idsTools",
      success: successIds
    },
    groups: {
      opn: false,
      cpy: null,
      progress: false,
      selector: "grpTools",
      filter: "",
      error: ""
    },
    picture: {
      opn: false,
      cpy: null
    }
  };
  $scope.addressParameter = {
    opened: false,
    progressing: false,
    copy: null
  }; // LOADING

  vm.loading = true;
  /**
   * Get healhtnceter.
   */

  function getHealthcenter() {
    healthcenterService.getDetails({
      id: vm.currentEnv.entityId
    }).then(function (response) {
      vm.hc = response.healthcenter;
      $scope.healthcenterAddress = response.healthcenter.address;
      initTab();
      vm.loading = false;
    });
  }
  /** Read organization bank detail. */


  function readOrganizationBankDetail() {
    organizationService.readBankDetail({
      organizationId: vm.currentEnv.organization.id
    }).then(function (response) {
      vm.bank = response.data;
      vm.bankCopy = angular.copy(vm.bank);
    });
  } // INTERNAL FUNCTIONS

  /**
   * Remove secs.
   *
   * @param {Array} list - List.
   *
   * @returns {object}
   */


  function removeSecs(list) {
    var ret = {};
    angular.forEach(list, function (value, key) {
      if (typeof value === "string" && /^(([0-9]{2})[:]){2}([0-9]{2})$/i.test(value)) {
        ret[key] = value ? new Date(moment(value.substring(0, 5), "HH:mm")) : "";
      } else {
        ret[key] = value;
      }
    }, ret);
    return ret;
  }
  /**
   * On media added, ask for confirmation, and then post it.
   *
   * @param {object} value - The selected media.
   */


  function mediaCallback(value) {
    var confirmGroup = $mdDialog.confirm().title("Voulez vous vraiment poursuivre ?").textContent("Cette action change l'image de votre pharmacie et impactera l'application patient. Continuez ?").ok("Oui je comprends").cancel("Non");
    $mdDialog.show(confirmGroup).then(function () {
      var params = {
        filemanager_file_id: value.id
      };
      healthcenterService.postMedia(vm.hc.id, params).then(function (media) {
        $scope.edit.picture.cpy.push(media);
        $scope.edit.picture.opn = false;
      });
    }, function () {
      $scope.edit.picture.opn = false;
    });
  }
  /**
   * Delete a healthcenter media.
   *
   * @param {object} media - The media.
   */


  function deleteMedia(media) {
    var confirmModal = $mdDialog.confirm().title("Voulez vous vraiment poursuivre ?").textContent("Cette action supprime l'image de votre pharmacie et impactera l'application patient. Continuez ?").ok("Oui je comprends").cancel("Non");
    $mdDialog.show(confirmModal).then(function () {
      healthcenterService.deleteMedia(media.id).then(function (deleted) {
        if (deleted) {
          $scope.edit.picture.cpy = $scope.edit.picture.cpy.filter(function (healthcenterMedia) {
            return healthcenterMedia.id !== media.id;
          });
        }
      });
    }, function () {
      $scope.edit.picture.opn = false;
    });
  }
  /**
   * Add secs.
   *
   * @param {Array} list - List.
   * @returns {object}
   */


  function addSecs(list) {
    var ret = {};
    angular.forEach(list, function (value, key) {
      if (value && _typeof(value) === _typeof(new Date())) {
        ret[key] = moment(value).format("HH:mm:ss");
      } else {
        ret[key] = value;
      }
    }, ret);
    return ret;
  }
  /**
   * Intialiswe tab.
   */


  function initTab() {
    $scope.edit.general.cpy = {
      name: vm.hc.name,
      url: vm.hc.url
    };
    $scope.addressParameter.copy = angular.copy($scope.healthcenterAddress);
    $scope.edit.groups.cpy = angular.copy(vm.hc.group);
    $scope.edit.picture.cpy = angular.copy(vm.hc.media);
    $scope.edit.opening.cpy = removeSecs(angular.copy(vm.hc.opening[0]));
    $scope.edit.ids.cpy = {
      cip: vm.hc.cip,
      siret: vm.hc.siret,
      finessHealthcenter: vm.hc.finessHealthcenter,
      finessLegal: vm.hc.finessLegal
    };

    if (vm.hc && $scope.healthcenterAddress && $scope.healthcenterAddress.longitude && $scope.healthcenterAddress.latitude) {
      vm.hcMap = {
        center: {
          latitude: $scope.healthcenterAddress.latitude,
          longitude: $scope.healthcenterAddress.longitude
        },
        zoom: 6,
        marker: {
          id: 0,
          coords: {
            latitude: $scope.healthcenterAddress.latitude,
            longitude: $scope.healthcenterAddress.longitude
          }
        }
      };
    }
  } // ANIMATION

  /**
   * Blink toolbar.
   *
   * @param {object} target - Target.
   * @param {string} cls - CSS class.
   */


  function blink_toolbar(target, cls) {
    var $target = angular.element(document.getElementById(target));
    $animate.addClass($target, cls).then();
    $target.one("webkitAnimationEnd oanimationend msAnimationEnd animationend", function () {
      $timeout(function () {
        $animate.removeClass($target, cls);
      });
    });
  }
  /**
   * Update the organization address
   *
   * @returns {Promise} The address modified or not
   */


  function updateOrganizationAddress() {
    $scope.addressParameter.progressing = true;
    return organizationService.updateAddress($scope.addressParameter.copy).then(function (response) {
      blink_toolbar("addrTools", "success-blink");
      $scope.healthcenterAddress = response.data;
      $scope.addressParameter.progressing = false;
      $scope.addressParameter.opened = false;
    }, function () {
      blink_toolbar("addrTools", "failure-blink");
      $scope.addressParameter.progressing = false;
      $scope.addressParameter.opened = true;
    });
  }
  /**
   * Cancel organization address form.
   */


  function cancelOrganizationAddressForm() {
    $scope.addressParameter.opened = false;
    $scope.addressParameter.copy = angular.copy($scope.healthcenterAddress);
  }
  /**
   * Update healthcenter opening.
   *
   * @param {object} obj - Object.
   */


  function updateHcOpng(obj) {
    healthcenterService.updateOpening(obj.cpy.id, addSecs(obj.cpy)).then(function () {
      blink_toolbar(obj.selector, "success-blink");
      obj.progress = false;
      obj.success();
    })["catch"](function () {
      blink_toolbar(obj.selector, "failure-blink");
      obj.progress = false;
    });
  }
  /**
   * Update healthcenter.
   *
   * @param {object} obj - Object.
   */


  function updateHc(obj) {
    healthcenterService.updateDetails(vm.hc.id, obj.cpy).then(function () {
      blink_toolbar(obj.selector, "success-blink");
      obj.progress = false;
      obj.success();
    })["catch"](function () {
      blink_toolbar(obj.selector, "failure-blink");
      obj.progress = false;
    });
  }
  /**
   * Update bank.
   */


  function updateBank() {
    var data = {
      organizationId: vm.currentEnv.organization.id,
      info: vm.bankCopy
    };
    organizationService.updateBankDetail(data).then(function (response) {
      $scope.edit.bank.progress = false;
      $scope.edit.bank.opn = false;
      vm.bank = response.data;
    });
  }
  /**
   * Post healthcenter openings.
   *
   * @param {object} obj - Object.
   */


  function postHcOpng(obj) {
    obj.cpy.healthcenter_id = vm.hc.id;
    healthcenterService.setOpening(vm.hc.id, addSecs(obj.cpy)).then(function () {
      blink_toolbar(obj.selector, "success-blink");
      obj.progress = false;
      obj.success();
    })["catch"](function () {
      blink_toolbar(obj.selector, "failure-blink");
      obj.progress = false;
    });
  } // SUCESS CALLBACKS

  /**
   * Success general.
   */


  function successGen() {
    vm.hc = angular.merge(vm.hc, $scope.edit.general.cpy);
    $scope.edit.general.opn = !$scope.edit.general.opn;
  }
  /**
   * Success IDs.
   */


  function successIds() {
    vm.hc = angular.merge(vm.hc, $scope.edit.ids.cpy);
    $scope.edit.ids.opn = !$scope.edit.ids.opn;
  }
  /**
   * Success openings.
   */


  function successOpns() {
    if (vm.hc.opening.length) {
      vm.hc.opening[0] = angular.merge(vm.hc.opening[0], $scope.edit.opening.cpy);
    } else {
      vm.hc.opening[0] = $scope.edit.opening.cpy;
    }

    $scope.edit.opening.opn = !$scope.edit.opening.opn;
  }

  vm.getGrpResponse = function () {
    var name = "";

    if ($scope.edit.groups.filter) {
      name = $scope.edit.groups.filter;
    }

    return healthcenterGroupingService.search(name).then(function (response) {
      return response.groups ? response.groups : response;
    })["catch"](function () {
      return "";
    });
  };

  vm.selectedGrpChange = function (groupment) {
    if (!$scope.edit.groups.filter) {
      return;
    }

    var confirmGroup = $mdDialog.confirm().title("Voulez vous vraiment poursuivre ?").textContent("Cette action change le groupement. En poursuivant, vous acceptez de perdre les offres commerciales de votre groupement actuel.").ok("Oui je comprends").cancel("Non");
    $mdDialog.show(confirmGroup).then(function () {
      healthcenterGroupingService.assign({
        entityId: groupment.id
      }).then(function (response) {
        $scope.edit.groups.filter = "";
        $scope.edit.groups.opn = false;
        vm.hc.groupment.name = response.name;
        vm.hc.groupment.validated = false;
        var obj = $scope.edit.groups;
        blink_toolbar(obj.selector, "success-blink");
        obj.opn = false;
        obj.progress = false; // Solve mask bug with autocomplete

        angular.element(".md-scroll-mask").remove();
      });
    }, function () {
      // Cancel
      $scope.edit.groups.filter = "";
      var obj = $scope.edit.groups;
      blink_toolbar(obj.selector, "success-blink");
      obj.opn = false;
      obj.progress = false;
    });
  }; // UPDATE METHODS


  vm.updateGen = function () {
    $scope.edit.general.progress = true;
    updateHc($scope.edit.general);
  };

  vm.updateIds = function () {
    $scope.edit.ids.progress = true;
    updateHc($scope.edit.ids);
  };

  vm.updateOpns = function () {
    $scope.edit.opening.progress = true;

    if (!vm.hc.opening.length) {
      postHcOpng($scope.edit.opening);
    } else {
      updateHcOpng($scope.edit.opening);
    }
  };

  vm.updateBank = function () {
    $scope.edit.bank.progress = true;
    updateBank();
  }; // CANCEL METHODS


  vm.cancelGen = function () {
    $scope.edit.general.opn = !$scope.edit.general.opn;
    $scope.edit.general.cpy = {
      name: vm.hc.name,
      url: vm.hc.url
    };
  };

  vm.cancelIds = function () {
    $scope.edit.ids.opn = !$scope.edit.ids.opn;
    $scope.edit.ids.cpy = {
      cip: vm.hc.cip,
      siret: vm.hc.siret,
      finessHealthcenter: vm.hc.finessHealthcenter,
      finessLegal: vm.hc.finessLegal
    };
  };

  vm.cancelGrps = function () {
    $scope.edit.groups.opn = !$scope.edit.groups.opn;
    $scope.edit.groups.cpy = angular.copy(vm.hc.group);
    $scope.edit.groups.filter = "";
  };

  vm.cancelOpns = function () {
    $scope.edit.opening.opn = !$scope.edit.opening.opn;
    $scope.edit.opening.cpy = removeSecs(angular.copy(vm.hc.opening[0]));
  };

  vm.cancelBank = function () {
    $scope.edit.bank.opn = !$scope.edit.bank.opn;
    vm.bankCopy = angular.copy(vm.bank);
  }; // METHODS


  vm.goBack = function () {
    if ($rootScope.previousState) {
      $state.go($rootScope.previousState);
    }
  };

  vm.leaveGrp = function () {
    var message = "Votre pharmacie ne sera plus affili\xE9e au groupement ".concat(vm.hc.groupment.name, ".\n                En poursuivant, vous acceptez de perdre les offres commerciales de votre groupement actuel.");
    var confirm = $mdDialog.confirm().title("Etes-vous sûr ?").textContent(message).ariaLabel("Confirmation").ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      healthcenterGroupingService.leave().then(function () {
        vm.hc.groupment = {
          id: null,
          name: null,
          validated: null
        };
        var obj = $scope.edit.groups;
        blink_toolbar(obj.selector, "success-blink");
        obj.opn = false;
        obj.progress = false; // Solve mask bug with autocomplete field

        angular.element(".md-scroll-mask").remove();
      });
    });
  };
}
/**
 * Parse hours filter.
 *
 * @returns {Function}
 */


function ParseHoursFilter() {
  return function (text) {
    return text ? text.replace(":", "H") : "";
  };
}

angular.module("app.healthcenters").controller("DetailGeneralTabController", DetailGeneralTabController).filter("parseHours", ParseHoursFilter);