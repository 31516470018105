"use strict";
/**
 * Chart settings service.
 *
 * @returns {object}
 */

function ChartSettingsService() {
  var service = {};
  service.graphs = {
    theme: {
      list: [{
        lbl: "Dégradé Simple",
        val: "none",
        background: "#FFFFFF",
        colors: ["#FF0F00", "#FF6600", "#FF9E01", "#FCD202", "#F8FF01", "#B0DE09", "#04D215", "#0D8ECF", "#0D52D1", "#2A0CD0", "#8A0CCF", "#CD0D74", "#754DEB", "#DDDDDD", "#999999", "#333333", "#000000", "#57032A", "#CA9726", "#990000", "#4B0C25"]
      }, {
        lbl: "Lumineux",
        val: "light",
        background: "#FFFFFF",
        colors: AmCharts.themes.light.AmCoordinateChart.colors
      }, {
        lbl: "Sombre",
        val: "dark",
        background: "#3f3f4f",
        colors: AmCharts.themes.dark.AmCoordinateChart.colors
      }, {
        lbl: "Motifs",
        val: "patterns",
        background: "#FFFFFF",
        urlBg: true,
        colors: AmCharts.themes.patterns.AmCoordinateChart.patterns
      }]
    },
    line: {
      theme: "light",
      animate: true,
      smoothed: true,
      thickness: 2,
      bulletThickns: 8,
      dataLegend: true
    },
    pie: {
      theme: "light",
      innerRadius: 35,
      angle: 0,
      depth: 0
    },
    bar: {
      theme: "light",
      animate: true,
      lineAlpha: 0.7,
      fillAlpha: 0.9
    }
  };

  service.getLineObj = function () {
    return {
      addClassNames: service.graphs.line.animate,
      theme: service.graphs.line.theme,
      mouseWheelScrollEnabled: true,
      mouseWheelZoomEnabled: false,
      legend: {
        useGraphSettings: true,
        marginTop: 10
      }
    };
  };

  service.getLineGraphObj = function () {
    return {
      bulletSize: service.graphs.line.bulletThickns,
      lineThickness: service.graphs.line.thickness,
      type: service.graphs.line.smoothed ? "smoothedLine" : "line"
    };
  };

  service.getPieObj = function () {
    return {
      innerRadius: service.graphs.pie.innerRadius + "%",
      theme: service.graphs.pie.theme,
      depth3D: service.graphs.pie.depth,
      angle: service.graphs.pie.angle,
      mouseWheelScrollEnabled: true
    };
  };

  service.getBarObj = function () {
    return {
      addClassNames: service.graphs.bar.animate,
      theme: service.graphs.bar.theme
    };
  };

  service.sortDataByAttribute = function (array, attribute) {
    array.sort(function (left, right) {
      return parseInt(right[attribute]) - parseInt(left[attribute]);
    });
    return array;
  };

  return service;
}

angular.module("app.services").factory("ChartSettingsService", ChartSettingsService);