"use strict";
/**
 * Dashboard group plvs controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdToast - $mdToast.
 * @param {object} plvsService - Plvs service.
 * @param {object} filemanagerService - Filemanager service.
 */

DashboardGroupPlvsController.$inject = ["$scope", "$mdToast", "plvsService", "filemanagerService"];

function DashboardGroupPlvsController($scope, $mdToast, plvsService, filemanagerService) {
  loader();
  $scope.documentLoading = true;
  $scope.sharersLoading = true;
  $scope.documents = [];
  $scope.sharedDocuments = [];
  /**
   * Loader.
   */

  function loader() {
    loaderDocuments();
    loaderSharersTypes();
  }
  /**
   * Loader documents.
   *
   * @returns {Promise}
   */


  function loaderDocuments() {
    return plvsService.load().then(function (response) {
      $scope.documents = response;
      $scope.documentLoading = false;
      return response;
    })["catch"](function () {
      $mdToast.show($mdToast.simple().textContent("Une erreur est survenue lors du chargement de vos plvs").position("top right"));
    });
  }
  /**
   * Loader sharers types.
   *
   * @returns {Promise}
   */


  function loaderSharersTypes() {
    return filemanagerService.loadSharersTypes().then(function (response) {
      $scope.types = response;
      response.forEach(function (type) {
        loaderSharers(type);
      });
      $scope.sharersLoading = false;
      return response;
    });
  }
  /**
   * Loader sharers.
   *
   * @param {string} type - Type.
   *
   * @returns {Promise}
   */


  function loaderSharers(type) {
    return filemanagerService.loadSharers(type).then(function (response) {
      $scope.sharers = response;
      response.forEach(function (sharer) {
        var query = {
          type_id: type.id,
          entity_id: sharer.id,
          name: sharer.name
        };
        loaderSharingFiles(query);
        $scope.sharersLoading = false;
      });
      return response;
    });
  }
  /**
   * Loader sharing files.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function loaderSharingFiles(query) {
    return filemanagerService.loadSharingFiles(query).then(function (response) {
      response.forEach(function (file) {
        file.entity_name = query.name;
        $scope.sharedDocuments.push(file);
      });
      return response;
    });
  }
}

angular.module("app.dashboard").controller("dashboardGroupPlvsController", DashboardGroupPlvsController);