"use strict";
/**
 * Wholesaler catalogs controller.
 *
 * @param {object} $scope - Scope
 * @param {object} $state - State
 * @param {object} wholesalerCatalogService - Wholesaler catalog service
 * @param {object} environmentService - Environment service
 */

WholesalerCatalogs.$inject = ["$scope", "$state", "wholesalerCatalogService", "environmentService"];

function WholesalerCatalogs($scope, $state, wholesalerCatalogService, environmentService) {
  $scope.loading = true;
  $scope.getWholesalerCatalogs = getWholesalerCatalogs;
  $scope.createWholesalerCatalog = createWholesalerCatalog;
  $scope.editWholesalerCatalog = editWholesalerCatalog;
  $scope.showWholesalerCatalog = showWholesalerCatalog;
  loader();
  /**
   * The loading function
   */

  function loader() {
    environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      getWholesalerCatalogs();
    });
  }
  /**
   * Get products of the wholesaler
   *
   * @returns {Promise} The promise
   */


  function getWholesalerCatalogs() {
    $scope.loading = true;
    return wholesalerCatalogService.getWholesalerCatalogs({
      wholesalerId: $scope.currentAccess.entityId
    }).then(function (wholesalerCatalogs) {
      $scope.wholesalerCatalogs = wholesalerCatalogs;
      $scope.loading = false;
    });
  }
  /**
   * Create a new catalog - go to form
   */


  function createWholesalerCatalog() {
    $state.go("app.wholesaler.catalogs.new");
  }
  /**
   * Update a catalog - go to form
   *
   * @param {number} catalogId - The wholesaler catalog ID
   */


  function editWholesalerCatalog(catalogId) {
    $state.go("app.wholesaler.catalogs.edit", {
      id: catalogId
    });
  }
  /**
   * Show the wholesaler catalog content
   *
   * @param {number} catalogId - The wholesaler catalog id
   */


  function showWholesalerCatalog(catalogId) {
    $state.go("app.wholesaler.catalogs.show", {
      id: catalogId
    });
  }
}

angular.module("wholesaler.catalog").controller("wholesalerCatalogs", WholesalerCatalogs);