"use strict";
/**
 * Sellin challenge negotiate controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $mdToast - $mdToast.
 * @param {string} apiurl - API URL.
 * @param {object} sellinChallengeService - Sellin challenge service.
 * @param {object} environmentService - Environment service.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} sessionStorageService - Session storage service.
 */

SellinChallengeNegotiateController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "$mdToast", "apiurl", "sellinChallengeService", "environmentService", "notifyUserService", "sessionStorageService"];

function SellinChallengeNegotiateController($scope, $state, $stateParams, $mdDialog, $mdToast, apiurl, sellinChallengeService, environmentService, notifyUserService, sessionStorageService) {
  $scope.apiurl = apiurl;
  $scope.token = sessionStorageService.getToken();
  $scope.sellinChallengeTypes = [{
    id: 1,
    name: "Objectif d'achat par palier avec rattrapage de remise pour atteinte d'objectif quantitatif",
    description: "Ceci est la description de l'Objectif d'achat par palier avec rattrapage de remise pour atteinte d'objectif quantitatif",
    image: "assets/images/sellin-challenge/step-discount-catchup.svg",
    imageWhite: "assets/images/sellin-challenge/step-discount-catchup-white.svg"
  }, {
    id: 2,
    name: "Ouverture de marché",
    description: "Ceci est la description de l'Ouverture de marché",
    image: "/assets/images/sellin-challenge/market-opening.svg",
    imageWhite: "assets/images/sellin-challenge/market-opening-white.svg"
  }, {
    id: 3,
    name: "Remises par paliers à la ligne ou panachées",
    description: "Ceci est la description de la remise par paliers à la ligne ou panachées",
    image: "/assets/images/sellin-challenge/step-discount.svg",
    imageWhite: "assets/images/sellin-challenge/step-discount-white.svg"
  }];
  $scope.stepTypes = [{
    id: 1,
    name: "Quantité à atteindre"
  }, {
    id: 2,
    name: "Montant d'achat"
  }];
  $scope.stepDiscountTypes = [{
    id: 1,
    name: "Remplace la remise de base"
  }, {
    id: 2,
    name: "S'additionne à la remise de base"
  }];
  $scope.negotiation = {
    refusedBecause: ""
  };
  $scope.loading = true;
  $scope.goToList = goToList;
  $scope.sellinChallengeId = $stateParams.id;
  $scope.getSellinChallenge = getSellinChallenge;
  $scope.addStep = addStep;
  $scope.removeStep = removeStep;
  $scope.resetStepForm = resetStepForm;
  $scope.submit = submit;
  loader();
  /** Initialize */

  function loader() {
    loaderEnvironment();
    resetStepForm();
  }
  /**
   * Loader environment.
   *
   * @returns {Promise}
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.access = access;
      getSellinChallenge();
      return access;
    });
  }
  /** Get SellinChallenge. */


  function getSellinChallenge() {
    if ($stateParams.id) {
      $scope.loading = true;
      var sellinChallenge = {
        id: $stateParams.id
      };
      sellinChallengeService.readForNegotiation(sellinChallenge).then(function (response) {
        $scope.sellinChallenge = response;
        $scope.sellinChallenge.products = response.products.filter(function (element) {
          return element.product;
        }).map(function (product) {
          return {
            id: product.product.id,
            name: product.product.name,
            requestedQuantity: product.requestedQuantity,
            cip: product.product.code
          };
        });
        $scope.sellinChallenge.targets = response.targets.map(function (target) {
          return {
            id: target.entityId,
            name: target.target.name,
            entityTypeId: target.entityTypeId,
            type: target.type
          };
        });
        $scope.sellinChallenge.startDate = new Date(response.startDate);
        $scope.sellinChallenge.endDate = new Date(response.endDate);
        $scope.sellinChallenge.sellinChallengeType = $scope.sellinChallengeTypes.find(function (element) {
          return element.id === response.type.id;
        });
        $scope.sellinChallenge.currentSteps = $scope.sellinChallenge.steps;
        $scope.sellinChallenge.steps = [];
        $scope.loading = false;
        return $scope.sellinChallenge.laboratory;
      });
    }
  }
  /**
   * Submit sellinChallenge.
   *
   * @returns {Promise}
   */


  function submit() {
    var sellinChallenge = {
      id: $scope.sellinChallenge.id,
      steps: $scope.sellinChallenge.steps,
      refusedBecause: $scope.negotiation.refusedBecause
    };
    return sellinChallengeService.negotiate(sellinChallenge).then(function (response) {
      $mdToast.show($mdToast.simple().textContent("Votre proposition a bien été enregistrée.").position("top right"));
      goToList();
      return response;
    });
  }
  /**
   * Add the step to the steps array
   */


  function addStep() {
    var step = {
      type: $scope.step.type,
      stepValue: $scope.step.stepValue,
      discountType: $scope.step.discountType,
      discountValue: $scope.step.discountValue > 100 ? 100 : $scope.step.discountValue
    }; // If no step inserted yet, insert the step

    if ($scope.sellinChallenge.steps.length === 0) {
      $scope.sellinChallenge.steps.push(step);
    } else {
      // Otherwise, we need to order the steps
      var inserted = false;

      for (var index = 0; index < $scope.sellinChallenge.steps.length && !inserted; index++) {
        if (step.stepValue < $scope.sellinChallenge.steps[index].stepValue) {
          $scope.sellinChallenge.steps.splice(index, 0, step);
          inserted = true;
        }
      } // If not inserted (the value is higher than all others)


      if (!inserted) {
        $scope.sellinChallenge.steps.push(step);
      }
    }

    resetStepForm();
  }
  /**
   * Remove the step from the steps array
   *
   * @param {number} index - The index to remove.
   */


  function removeStep(index) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression de l'objectif ?").ariaLabel("Suppression de l'objectif").ok("Oui").cancel("Non")).then(function () {
      $scope.sellinChallenge.steps.splice(index, 1);
      notifyUserService.showSimpleToast("L'objectif a été supprimé.", {
        color: "success",
        hideDelay: 1000
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Reset the step form
   */


  function resetStepForm() {
    $scope.step = {
      type: $scope.stepTypes[0],
      stepValue: null,
      discountType: $scope.stepDiscountTypes[0],
      discountValue: null
    };
  }
  /** Go to sellinChallenge list. */


  function goToList() {
    $state.go("app.sellinChallenge");
  }
}

angular.module("app.sellinChallenge").controller("sellinChallengeNegotiateController", SellinChallengeNegotiateController);