"use strict";
/** @ngInject */

/**
 * Sales analysis controller.
 *
 * @param {object} $rootScope - The angular $rootscope object.
 * @param {object} $scope - $the angular $scope object.
 * @param {object} $q - the $angular $q object.
 * @param {object} environmentService - The environment service.
 * @param {object} salesAnalysisSandBoxLinesAtclService - The sales Analysis service.
 * @param {object} ChartSettingsService - The chart settings service.
 * @param {object} StatisticsQueryService - The statistics query service.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $mdMenu - The angular $mdMenu object.
 * @param {object} $compile - The angular $compile object.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} healthcenterAuditHcDetailsService - The healthcenter audit service.
 * @param {object} groupmentAuditDetailService - The groupment audit service.
 * @param {object} productService - Product service.
 */

StatsSandboxLinesAtclController.$inject = ["$rootScope", "$scope", "$q", "environmentService", "salesAnalysisSandBoxLinesAtclService", "ChartSettingsService", "StatisticsQueryService", "$state", "$stateParams", "$mdMenu", "$compile", "$mdDialog", "healthcenterAuditHcDetailsService", "groupmentAuditDetailService", "productService"];

function StatsSandboxLinesAtclController($rootScope, $scope, $q, environmentService, salesAnalysisSandBoxLinesAtclService, ChartSettingsService, StatisticsQueryService, $state, $stateParams, $mdMenu, $compile, $mdDialog, healthcenterAuditHcDetailsService, groupmentAuditDetailService, productService) {
  var vm = this;
  /**
   * hide date on this page, it"s displayed differently here
   */

  vm.isDateHiddenInInclude = true;
  vm.query = {};
  vm.percentageDisplay = "default";
  vm.noOperatorCode = false;
  vm.display100Percent = "0";
  /**
   * Config Search BAR
   *
   * search : slugified terms of the query in the autocomplete service.
   */

  vm.config = {
    event: "salesAnalysisFilterChange",
    search: []
  };
  vm.rawValues = StatisticsQueryService.values;
  $scope.ganttIsShown = false;
  $scope.currentState = $state.current.name;
  /**
   * On range date selected.
   *
   * @param {object} date - The date object
   */

  vm.rangeSelected = function (date) {
    vm.usrquery.date.from = moment(date.startDate, "DD-MM-YYYY");
    vm.usrquery.date.to = moment(date.endDate, "DD-MM-YYYY");
    $scope.timetableStartDate = vm.usrquery.date.from.format("YYYY-MM-DD");
    $scope.timetableEndDate = vm.usrquery.date.from.format("YYYY-MM-DD");
    autoSearch();
  };

  $scope.showGantt = showGantt;
  /**
   * General functions for checkboxes.
   */

  vm.toggleCb = toggleCb;
  vm.indexByLabel = indexByLabel;
  vm.existsFilter = existsFilter; // line curve flag for n-1

  vm.n1Curve = false;
  /**
   * Toggle checkboxes.
   *
   * @param {object} item - The checkbox clicked.
   * @param {Array} list - The array of checkboxes objects.
   * @returns {boolean}
   */

  function toggleCb(item, list) {
    if (vm.genQuery) {
      return false;
    }

    var idx = vm.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    autoSearch();
    return true;
  }
  /**
   * Index by label.
   *
   * @param {string} name - The name to sort on.
   * @param {Array} list - The list of the labels to sort.
   * @returns {number}
   */


  function indexByLabel(name, list) {
    if (!name || !list) {
      return -1;
    }

    for (var index = 0, len = list.length; index < len; index++) {
      if (list[index].label === name) {
        return index;
      }
    }

    return -1;
  }
  /**
   * Exists filter
   *
   * @param {string} name - The name to sort on.
   * @param {Array} list - The list of the labels to sort.
   * @returns {boolean}
   */


  function existsFilter(name, list) {
    if (vm.genQuery) {
      return false;
    }

    return vm.indexByLabel(name, list) > -1;
  }
  /**
   *   init ATCL with current select
   *   on selected item get correct list to put in for all selected
   */


  function autoSearch() {
    vm.loading = true;
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (env) {
      vm.query = env;
      init();
      launchQuery(true);
    });
  } // Data


  vm.aggregateBy = "none";
  vm.interval = {
    model: "1w",
    values: [{
      label: "Jour",
      value: "1d",
      displayPattern: "DD MMM YYYY"
    }, {
      label: "Semaine",
      value: "1w",
      displayPattern: "semaine W YYYY"
    }, {
      label: "Mois",
      value: "1M",
      displayPattern: "MMM YYYY"
    }]
  };
  /**
   * Get limits.
   *
   * @param {Array} array - The array to divide.
   * @returns {Array}
   */

  vm.getLimits = function (array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  }; // Methods


  vm.reloadNewChart = reloadNewChart;
  vm.addRemoveCurve = addRemoveCurve;
  vm.removeFilter = removeFilter;
  vm.noresult = false;
  vm.loading = true;
  vm.studiedNumber = "CA";
  vm.legendBalloon = "SM";
  init();
  var currentEnvironment = environmentService.getEnvironment();
  currentEnvironment.then(function (env) {
    vm.currentEnv = env;
    vm.usrquery = StatisticsQueryService.query; // We preselect the laboratory if the current env is a laboratory one

    if (vm.currentEnv.accessPointType === 1) {
      var laboratory = {
        id: vm.currentEnv.entityId,
        name: vm.currentEnv.entity.name,
        type: "laboratory"
      };

      var laboratoryMust = _.find(vm.usrquery.must.laboratory.selected, function (labo) {
        return labo.id === laboratory.id;
      });

      if (!laboratoryMust) {
        laboratoryMust = _.find(vm.usrquery.should.laboratory.selected, function (labo) {
          return labo.id === laboratory.id;
        });
      }

      if (!laboratoryMust) {
        vm.usrquery.must.laboratory.selected.push(laboratory);
      }
    } // We preselect the grouping if the current env is a grouping one


    if (vm.currentEnv.accessPointType === 3) {
      var grouping = {
        id: vm.currentEnv.entityId,
        name: vm.currentEnv.entity.name,
        type: "groups"
      };

      var groupingMust = _.find(vm.usrquery.must.groups.selected, function (group) {
        return group.id === grouping.id;
      });

      if (!groupingMust) {
        groupingMust = _.find(vm.usrquery.should.groups.selected, function (group) {
          return group.id === grouping.id;
        });
      }

      if (!groupingMust) {
        vm.usrquery.must.groups.selected.push(grouping);
      }
    }

    manageStateParams().then(function () {
      launchQuery(true);
    });
  });
  var myCustomMenu = angular.element("<div class='md-open-menu-container md-whiteframe-z2'>" + "<md-menu-content>" + "<md-menu-item ng-if='vm.frontTypes[vm.aggregateBy]' >" + "<button ng-click='vm.addToFilter($event)' class='md-button'>" + " <span md-menu-align-target>Ajouter aux filtres</span>" + "</button>" + "</md-menu-item>" + "<md-menu-item  >" + "<button ng-click='vm.hideAction($event)'  class='md-button'> " + "<span md-menu-align-target>Cacher cette courbe</span>" + "</button>" + "</md-menu-item>" + "<md-menu-item  >" + "<button ng-click='vm.showOnlyAction($event)'  class='md-button'>" + " <span md-menu-align-target>N\"afficher que cette courbe</span>" + "</button>" + "</md-menu-item>" + "<md-menu-item  >" + "<button ng-click='vm.showOthersAction($event)'  class='md-button'>" + " <span md-menu-align-target>Réafficher les autres courbes</span>" + "</button>" + "</md-menu-item>" + "</md-menu-content>" + "</div>");
  var RightClickMenuCtrl = {
    open: function open(event, data) {
      vm.legendLabelClicked = data;
      $mdMenu.show({
        scope: $scope,
        mdMenuCtrl: RightClickMenuCtrl,
        element: $compile(myCustomMenu)($scope),
        target: event.target // used for where the menu animates out of

      });
    },
    close: function close() {
      $mdMenu.hide();
    },
    offsets: function offsets() {
      return {
        top: 0,
        left: 0
      };
    }
  };
  /**
   * Hide action
   *
   * @param {object} event - The click event.
   * @returns {boolean}
   */

  vm.hideAction = function (event) {
    event.stopImmediatePropagation();
    var chart = vm.legendLabelClicked.chart;

    for (var index = 0; index < chart.graphs.length; index++) {
      if (vm.legendLabelClicked.id === chart.graphs[index].id) {
        chart.hideGraph(chart.graphs[index]);
      }
    } // return false so that default action is canceled


    return false;
  };
  /**
   * Show only action.
   *
   * @param {object} event - The click event.
   * @returns {boolean}
   */


  vm.showOnlyAction = function (event) {
    event.stopImmediatePropagation();
    var chart = vm.legendLabelClicked.chart;

    for (var index = 0; index < chart.graphs.length; index++) {
      if (vm.legendLabelClicked.id === chart.graphs[index].id) {
        chart.showGraph(chart.graphs[index]);
      } else {
        chart.hideGraph(chart.graphs[index]);
      }
    } // return false so that default action is canceled


    return false;
  };
  /**
   * Show others action.
   *
   * @param {object} event -
   * @returns {boolean}
   */


  vm.showOthersAction = function (event) {
    event.stopImmediatePropagation();
    var chart = vm.legendLabelClicked.chart;

    for (var index = 0; index < chart.graphs.length; index++) {
      chart.showGraph(chart.graphs[index]);
    } // return false so that default action is canceled


    return false;
  };
  /**
   * Add to filters.
   *
   * @param {object} event -
   */


  vm.addToFilter = function (event) {
    event.stopImmediatePropagation();
    vm.aggByName = vm.aggBySelect[0].title + " " + vm.aggBySelect[0].label;
    vm.usrquery.must[vm.legendClicked.type].selected.push(vm.legendClicked);
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      vm.query = query;
      vm.aggregateBy = "none";
      init();
      launchQuery(true);
    });
  };
  /**
   * Init function.
   *
   * @returns {boolean}
   */


  function init() {
    vm.frontTypes = {
      "product.laboratory": "laboratory",
      "product.presentation": "product",
      "organization.id": "pharmas",
      "organization.geo.region": "region",
      "organization.geo.departement": "departement",
      "organization.geo.uga": "uga",
      "organization.geo.city": "city",
      "organization.group.id": "groups",
      "product.segment.value": "segment",
      segmentHealthcenter: "segmentHealthcenter",
      segmentProduct: "segmentProduct",
      "category.levelX.id": "category",
      "product.range": "ranges"
    };
    vm.deferredChart = $q.defer(); // temp object before merging with user pref

    var graphOptions = calcChartOptions();
    vm.amChartOptions = angular.extend(graphOptions, ChartSettingsService.getLineObj());
    vm.amChartOptions.legend.marginTop = 16;
    vm.amChartOptions.legend.valueAlign = "left";
    var currentUnit = "";

    if (vm.aggregateBy !== "none") {
      currentUnit = vm.studiedNumber === "CA" ? "€" : " boites"; // If we ask for percent instead of numbers, override the currentUnit

      if (vm.display100Percent === "1") {
        currentUnit = "%";
      }

      vm.amChartOptions.legend.valueFunction = function (graphDataItem) {
        var value = "";

        if (graphDataItem.values && !isNaN(graphDataItem.values.value)) {
          value = Math.round(graphDataItem.values.value).formatMoney(0, 3, " ", ", ") + currentUnit;
        }

        return value;
      };

      vm.amChartOptions.legend.clickLabel = function (type, dataItem) {
        var chart = type.chart;
        var id = 0;

        for (var index = 0; index < chart.graphs.length; index++) {
          if (type.id === chart.graphs[index].id) {
            id = vm.ids[index];
          }
        } // If it is a pharmacy, we extract CIP from title


        var cip = "";

        if (vm.frontTypes[vm.aggregateBy] === "pharmas") {
          var regExpExtractCIP = new RegExp("([0-9]{7})", "g");
          var healthcenterCIP = type.title.match(regExpExtractCIP)[0];
          cip = healthcenterCIP;
        }

        vm.legendClicked = {
          id: parseInt(id),
          name: type.title,
          type: vm.frontTypes[vm.aggregateBy],
          cip: cip
        };
        RightClickMenuCtrl.open(dataItem, type);
      };

      vm.amChartOptions.legend.clickMarker = function (type, dataItem) {
        var chart = type.chart;
        var id;

        for (var index = 0; index < chart.graphs.length; index++) {
          if (type.id === chart.graphs[index].id) {
            id = vm.ids[index];
          }
        }

        vm.legendClicked = {
          id: parseInt(id),
          name: type.title,
          type: vm.frontTypes[vm.aggregateBy]
        };
        RightClickMenuCtrl.open(dataItem, type);
      };

      return false;
    }

    if (vm.display100Percent === "1") {
      currentUnit = vm.studiedNumber === "CA" ? "€" : " boites"; // If we ask for percent instead of numbers, override the currentUnit

      if (vm.display100Percent === "1") {
        currentUnit = "%";
      }
      /**
       * Value function.
       *
       * @param {object} graphDataItem -
       * @returns {string}
       */


      vm.amChartOptions.legend.valueFunction = function (graphDataItem) {
        if (!graphDataItem.values) {
          return "";
        }

        return graphDataItem.values.value.toFixed(2) + currentUnit;
      };
      /**
       * Label function.
       *
       * @param {number} value -
       * @returns {string}
       */


      vm.amChartOptions.valueAxes[0].labelFunction = function (value) {
        return value.toFixed(2) + currentUnit;
      };
    } else if (vm.display100Percent === "0") {
      currentUnit = vm.studiedNumber === "CA" ? "€" : " boites"; // If we ask for percent instead of numbers, override the currentUnit

      if (vm.display100Percent === "1") {
        currentUnit = "%";
      }
      /**
       * Value function.
       *
       * @param {object} graphDataItem -
       * @returns {string}
       */


      vm.amChartOptions.legend.valueFunction = function (graphDataItem) {
        if (!graphDataItem.values) {
          return "";
        }

        return Math.round(graphDataItem.values.value).formatMoney(0, 3, " ", ", ") + currentUnit;
      };
      /**
       * Label function.
       *
       * @param {number} value -
       * @returns {string}
       */


      vm.amChartOptions.valueAxes[0].labelFunction = function (value) {
        return d3.format(".2s")(value) + currentUnit;
      };
    }

    return true;
  }
  /**
   * Manage state params.
   *
   * @returns {Promise}
   */


  function manageStateParams() {
    var inCall = false;
    return $q(function (resolve) {
      if ($stateParams.products) {
        vm.usrquery.must.product.selected = [];
        angular.forEach($stateParams.products, function (productName, productId) {
          if (vm.usrquery.must.product.selected.indexOf({
            id: productId,
            name: productName,
            type: "product"
          }) === -1) {
            vm.usrquery.must.product.selected.push({
              id: productId,
              name: productName,
              type: "product"
            });
          }
        });
      }

      if ($stateParams.pharmas) {
        var pharmaInfo = {};
        vm.usrquery.must.pharmas.selected = [];
        angular.forEach($stateParams.pharmas, function (pharma) {
          pharmaInfo = {
            id: pharma.id,
            name: pharma.name,
            type: "pharmas",
            cip: pharma.cip
          };

          if (vm.usrquery.must.pharmas.selected.indexOf(pharma) === -1) {
            vm.usrquery.must.pharmas.selected.push(pharmaInfo);
          }
        });
      } // Apply filter if direct access from segments list (healthcenters and products)


      if ($stateParams.segment) {
        vm.usrquery.must.segmentHealthcenter.selected = [];
        vm.usrquery.must.segmentProduct.selected = [];
        var segmentHealthcenter = {};
        var segmentProduct = {};
        angular.forEach($stateParams.segment, function (segment) {
          if (segment.segmentTypeId === 1) {
            segmentHealthcenter = {
              id: segment.id,
              name: segment.name,
              type: "segmentHealthcenter"
            };

            if (vm.usrquery.must.segmentHealthcenter.selected.indexOf(segmentHealthcenter) === -1) {
              vm.usrquery.must.segmentHealthcenter.selected.push(segmentHealthcenter);
            }
          }

          if (segment.segmentTypeId === 2) {
            segmentProduct = {
              id: segment.id,
              name: segment.name,
              type: "segmentProduct"
            };

            if (vm.usrquery.must.segmentProduct.selected.indexOf(segmentProduct) === -1) {
              vm.usrquery.must.segmentProduct.selected.push(segmentProduct);
            }
          }
        });
      }

      if ($stateParams.id) {
        inCall = true;
        healthcenterAuditHcDetailsService.hCDetail($stateParams.id).then(function (pharma) {
          var pharmaInfos = {
            id: pharma.healthcenter.id,
            name: pharma.healthcenter.name,
            type: "pharmas",
            cip: pharma.healthcenter.cip
          };
          vm.usrquery.must.pharmas.selected = [pharmaInfos];
          resolve(vm.usrquery);
        });
      }

      if ($stateParams.groupId) {
        inCall = true;
        groupmentAuditDetailService.one($stateParams).then(function (groupment) {
          $scope.group = groupment[0].grouping;
          var groupInfos = {
            id: $scope.group.id,
            name: $scope.group.name,
            type: "groups"
          };
          vm.usrquery.must.groups.selected = [groupInfos];
          resolve(vm.usrquery);
        });
      }

      if (!inCall) {
        resolve(vm.usrquery);
      }
    });
  }
  /**
   * Process query
   *
   * @param {object} query -
   * @returns {boolean}
   */


  function processQuery(query) {
    query.interval = vm.interval.model;
    query.studiedNumber = vm.studiedNumber; // Permet d"exporter les données au format CSV

    if (query["export"]) {
      if (vm.aggregateBy !== "none") {
        query.aggby = vm.aggregateBy;
      }

      query.lastYearData = vm.n1Curve;
      callApiSalesSearchExport(query).then(function (response) {
        vm.loading = false; // eslint-disable-next-line no-undef

        saveAs(new Blob([response.csv], {
          type: "text/csv;charset=UTF-8"
        }), "export.csv");
      });
      return true;
    }

    if (vm.aggregateBy !== "none") {
      query.aggby = vm.aggregateBy;
      callApiSalesSearch(query).then(function (response) {
        vm.elasticdata = response;
        processDataForAmCharts();
        vm.loading = false;
      });
    } else {
      secondaryQuery(query);
    }

    return true;
  }
  /**
   * Call api and send a promise back
   *
   * @param {object} query -
   *
   * @returns {Promise}
   */


  function callApiSalesSearch(query) {
    return salesAnalysisSandBoxLinesAtclService.chart(query).then(function (response) {
      return response;
    });
  }
  /**
   * Call api and send a promise back
   *
   * @param {object} query - The query params to pass to the API.
   *
   * @returns {Promise}
   */


  function callApiSalesSearchExport(query) {
    return salesAnalysisSandBoxLinesAtclService["export"](query).then(function (response) {
      return response;
    });
  }
  /**
   * Send the query and the same query shifted by one year
   *
   * @param {object} query - The query params to pass to the API.
   */


  function secondaryQuery(query) {
    callApiSalesSearch(query).then(function (response) {
      vm.elasticdata = response;
      query.startDatetime = moment(vm.usrquery.date.from).subtract(1, "year").format("YYYY-MM-DD");
      query.endDatetime = moment(vm.usrquery.date.to).subtract(1, "year").format("YYYY-MM-DD");
      callApiSalesSearch(query).then(function (responseN1) {
        vm.elasticdataN1 = responseN1;
        vm.loading = false;
        processDataForAmCharts();
      });
    });
  }
  /**
   * Launch Query
   *
   * @param {boolean} fetchData - Boolean to know if we have to retrieve data from the API.
   * @param {boolean} fileExport - Boolean to know if we have to retrieve file from the API.
   */


  function launchQuery(fetchData, fileExport) {
    var startDate = null;
    var endDate = null;

    if ($stateParams.startDate) {
      startDate = moment($stateParams.startDate, "DD/MM/YYYY").format("DD-MM-YYYY");
      vm.usrquery.date.from = moment(startDate, "DD-MMM-YYYY").format("YYYY-MM-DD");
    } else {
      startDate = moment(vm.usrquery.date.from).format("DD-MM-YYYY");
    }

    if ($stateParams.startDate) {
      endDate = moment($stateParams.endDate, "DD/MM/YYYY").format("DD-MM-YYYY");
      vm.usrquery.date.to = moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    } else {
      endDate = moment(vm.usrquery.date.to).format("DD-MM-YYYY");
    }

    vm.dateLabel = startDate + " Au " + endDate;

    if (vm.currentEnv.accessPointType === 2 && vm.currentEnv.rankId !== 1 && vm.currentEnv.rankId !== 2 && !_.find(vm.usrquery.must.operator.selected, function (operator) {
      return operator.id === vm.currentEnv.operatorCode;
    })) {
      if (vm.currentEnv.operatorCode !== null) {
        vm.noOperatorCode = false;
        vm.usrquery.must.operator.selected.push({
          id: vm.currentEnv.operatorCode
        });
      } else {
        vm.noOperatorCode = true;
      }
    }

    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date, fileExport).then(function (query) {
      $scope.timetableStartDate = moment(query.startDatetime).format("YYYY-MM-DD");
      $scope.timetableEndDate = moment(query.endDatetime).format("YYYY-MM-DD");
      vm.query = query;

      if (fetchData) {
        processQuery(vm.query);
      } else {
        processDataForAmCharts();
      }
    });
  }
  /**
   * Process data for chart.
   */


  function processDataForAmCharts() {
    vm.totalNumberQt = vm.elasticdata.aggregations.quantity.value;
    vm.totalNumberCa = vm.elasticdata.aggregations.amount_excl_tax.value;
    vm.healthcentersCount = vm.elasticdata.aggregations.healthcenters_count.value;
    vm.totalNumberQtN1 = vm.elasticdataN1.aggregations.quantity.value;
    vm.totalNumberCaN1 = vm.elasticdataN1.aggregations.amount_excl_tax.value;
    vm.progressionQt = vm.elasticdata.aggregations.quantity.value / vm.elasticdataN1.aggregations.quantity.value * 100 - 100;
    vm.progressionCa = vm.elasticdata.aggregations.amount_excl_tax.value / vm.elasticdataN1.aggregations.amount_excl_tax.value * 100 - 100;

    if (vm.aggregateBy === "none") {
      if (vm.studiedNumber === "CA") {
        if (vm.n1Curve) {
          vm.graphs = [{
            data: "value",
            title: "Total des Ventes"
          }, {
            data: "n1",
            title: "Total des Ventes à N-1"
          }];
        } else {
          vm.graphs = [{
            data: "value",
            title: "Total des Ventes"
          }];
        }
      } else if (vm.n1Curve) {
        vm.graphs = [{
          data: "value",
          title: "Quantité Totale",
          bucket: vm.elasticdata.aggregations.sales.buckets
        }, {
          data: "n1",
          title: "Quantité Totale à N-1"
        }];
      } else {
        vm.graphs = [{
          data: "value",
          title: "Quantité Totale",
          bucket: vm.elasticdata.aggregations.sales.buckets
        }];
      }
    } else {
      defineAmChartsLabs();
    } // Translate Data to AmCharts Graphs Objects


    vm.amChartOptions.graphs = defineAmChartsGraphs();

    if (vm.aggregateBy === "none") {
      resolveLines();
    } else {
      resolveAggLines();
    }
  }
  /**
   * Convert agg elastic search into graphs lines
   */


  function resolveAggLines() {
    var lines = [];
    vm.ids = [];
    angular.forEach(vm.elasticdata.aggregations.sales.buckets, function (data, index) {
      var obj = {
        date: data.key
      };
      angular.forEach(vm.graphs, function (data2) {
        if (vm.ids.length !== vm.graphs.length) {
          vm.ids.push(data2.key_id);
        }

        if (vm.studiedNumber === "CA") {
          obj[data2.data] = data2.bucket[index].amount_excl_tax.value;
        } else {
          obj[data2.data] = data2.bucket[index].quantity.value;
        }
      });
      lines.push(obj);
    });
    vm.deferredChart.resolve(lines);
  }
  /**
   * Convert elastic search into graph line
   */


  function resolveLines() {
    var lines = [];
    angular.forEach(vm.elasticdata.aggregations.sales.buckets, function (data, index) {
      var lineDot = {
        date: data.key
      };
      lineDot.value = data.amount_excl_tax.value;

      if (vm.studiedNumber === "QT") {
        lineDot.value = data.quantity.value;
      }

      if (typeof vm.elasticdataN1 !== "undefined") {
        if (vm.elasticdataN1.aggregations.sales.buckets[index]) {
          if (new Date(vm.elasticdataN1.aggregations.sales.buckets[index].key_as_string) >= new Date("2016-01-01")) {
            lineDot.n1 = vm.elasticdataN1.aggregations.sales.buckets[index].amount_excl_tax.value;
          } else {
            lineDot.n1 = 0;
          }

          if (vm.studiedNumber === "QT") {
            if (new Date(vm.elasticdataN1.aggregations.sales.buckets[index].key_as_string) >= new Date("2016-01-01")) {
              lineDot.n1 = vm.elasticdataN1.aggregations.sales.buckets[index].quantity.value;
            } else {
              lineDot.n1 = 0;
            }
          }
        }
      }

      lines.push(lineDot);
    });
    vm.deferredChart.resolve(lines);
  }

  vm.intervalChange = intervalChange;
  vm.studiedNumberChange = studiedNumberChange;
  /**
   * Calc chart option.
   *
   * @returns {object}
   */

  function calcChartOptions() {
    var valueAxes = [];

    if (vm.display100Percent === "1" || vm.display100Percent === "0") {
      valueAxes = [{
        axisAlpha: 0,
        position: "left",
        gridAlpha: 0.1
      }];
    } else {
      valueAxes = [{
        stackType: "100%",
        gridAlpha: 0.07,
        position: "left",
        title: "percent"
      }];
    }

    var axeDatePattern = getIntervalObjectFromVal(vm.interval.model);
    var graphOptions = {
      type: "serial",
      data: vm.deferredChart.promise,
      startDuration: 0,
      marginTop: 0,
      marginRight: 80,
      valueAxes: valueAxes,
      graphs: [],
      chartScrollbar: {
        color: "#424242",
        gridAlpha: 0,
        scrollbarHeight: 55,
        backgroundAlpha: 0,
        selectedBackgroundAlpha: 0.2,
        graphFillAlpha: 0,
        autoGridCount: true,
        selectedGraphFillAlpha: 0,
        graphLineAlpha: 0.2,
        selectedGraphLineAlpha: 1
      },
      chartCursor: {
        cursorColor: "#888888",
        cursorAlpha: 0.5,
        valueLineEnabled: true,
        valueLineBalloonEnabled: true,
        valueLineAlpha: 0.5,
        categoryBalloonDateFormat: axeDatePattern.displayPattern,
        fullWidth: false
      },
      categoryField: "date",
      categoryAxis: {
        gridColor: "#F2F2F2",
        gridAlpha: 0.3,
        dateFormats: [{
          period: "fff",
          format: "JJ:NN:SS"
        }, {
          period: "ss",
          format: "JJ:NN:SS"
        }, {
          period: "mm",
          format: "JJ:NN"
        }, {
          period: "hh",
          format: "JJ:NN"
        }, {
          period: "DD",
          format: "DD MMM"
        }, {
          period: "WW",
          format: "DD MMM"
        }, {
          period: "MM",
          format: "MMM YYYY"
        }, {
          period: "YYYY",
          format: "MMM YYYY"
        }],
        parseDates: true,
        minorGridAlpha: 0.1,
        minorGridEnabled: true
      },
      "export": {
        enabled: true,
        menu: [{
          "class": "export-main",
          menu: [{
            label: "Télécharger",
            menu: ["PNG", "JPG", "PDF"]
          }]
        }]
      },
      panEventsEnabled: false
    };
    return graphOptions;
  }
  /**
   * Get interval object from val.
   *
   * @param {object} val - The interval to find.
   * @returns {object}
   */


  function getIntervalObjectFromVal(val) {
    var patternPos = vm.interval.values.map(function (value) {
      return value.value;
    }).indexOf(val);
    return vm.interval.values[patternPos];
  }
  /**
   * Define amchart graphs.
   *
   * @returns {Array}
   */


  function defineAmChartsGraphs() {
    var graphObject = {
      bullet: "round",
      bulletBorderColor: "#FFFFFF",
      bulletBorderAlpha: 1,
      bulletBorderThickness: 2,
      animationPlayed: false
    };
    var graph = {
      fillAlphas: 0.5,
      lineAlpha: 0.5,
      ballonText: "[[percents]]"
    };

    if (vm.legendBalloon === "LG") {
      graphObject.balloon = {
        fillAlpha: 1,
        fillColor: "#FFFFFF",
        shadowAlpha: 0.12,
        shadowColor: "#000000",
        borderThickness: 1,
        borderColor: "#CCCCCC",
        borderAlpha: 1
      };
      graph.balloon = {
        fillAlpha: 1,
        fillColor: "#FFFFFF",
        shadowAlpha: 0.12,
        shadowColor: "#000000",
        borderThickness: 1,
        borderColor: "#CCCCCC",
        borderAlpha: 1
      };
    } else if (vm.legendBalloon === "SM") {
      graphObject.balloon = {
        adjustBorderColor: false,
        color: "#ffffff"
      };
      graph.balloon = {
        adjustBorderColor: false,
        color: "#ffffff"
      };
    } else {
      graphObject.balloon = {
        enabled: false
      };
      graph.balloon = {
        enabled: false
      };
    }

    var ret = [];
    var currentUnit = vm.studiedNumber === "CA" ? "&euro;" : " boites"; // If we ask for percent instead of numbers, override the currentUnit

    if (vm.display100Percent === "1") {
      currentUnit = "%";
    }

    angular.forEach(vm.graphs, function (data, index) {
      var tmp = {};

      if (vm.display100Percent === "2") {
        tmp = angular.copy(graph);
      } else {
        tmp = angular.copy(graphObject);
      }

      tmp.valueField = vm.graphs[index].data;
      tmp.id = "g" + (index + 1);
      tmp.title = vm.graphs[index].title;

      tmp.balloonFunction = function (graphDataItem) {
        var value = Math.round(graphDataItem.values.value).formatMoney(0, 3, " ", ", ") + currentUnit;
        return "<span style='font-size:14px;'>" + value + "</span>";
      }; // Merge User graph Settings


      var mergedGraph = angular.extend(tmp, ChartSettingsService.getLineGraphObj());
      ret.push(mergedGraph);
    });
    return ret;
  }
  /**
   * Switch mode.
   */


  vm.switchMode = function () {
    refreshCharts(false);
  };
  /**
   * Interval change.
   */


  function intervalChange() {
    refreshCharts(true);
  }
  /**
   * Studied number change.
   */


  function studiedNumberChange() {
    refreshCharts(true);
  }
  /**
   * Reload new chart.
   */


  function reloadNewChart() {
    refreshCharts(false);
  }
  /**
   * Add or remove a curve.
   */


  function addRemoveCurve() {
    vm.n1Curve = !vm.n1Curve;
    refreshCharts(true);
  }
  /**
   * Refresh the chart.
   *
   * @param {boolean} reloadQuery -
   */


  function refreshCharts(reloadQuery) {
    init();
    launchQuery(reloadQuery);
    vm.loading = false;
  }
  /**
   * Define amchart labs.
   */


  function defineAmChartsLabs() {
    vm.graphs = [];

    if (vm.display100Percent === "1") {
      var tot = [];
      angular.forEach(vm.elasticdata.aggregations.aggby.buckets, function (data) {
        angular.forEach(data.sales.buckets, function (data2, index) {
          if (typeof tot[index] === "undefined") {
            tot[index] = {
              amount_excl_tax: 0,
              quantity: 0
            };
          }

          tot[index].amount_excl_tax += data2.amount_excl_tax.value;
          tot[index].quantity += data2.quantity.value;
        });
      });
      angular.forEach(vm.elasticdata.aggregations.aggby.buckets, function (data) {
        var datas = [];
        angular.forEach(data.sales.buckets, function (bucket, index) {
          datas.push({
            amount_excl_tax: {
              value: bucket.amount_excl_tax.value > 0 ? bucket.amount_excl_tax.value / tot[index].amount_excl_tax * 100 : 0
            },
            quantity: {
              value: bucket.quantity.value > 0 ? bucket.quantity.value / tot[index].quantity * 100 : 0
            },
            key: bucket.key,
            key_as_string: bucket.key_as_string
          });
        });
        vm.graphs.push({
          title: data.key,
          data: data.key,
          key_id: data.key_id,
          bucket: datas
        });
      });
    } else if (vm.display100Percent === "0") {
      angular.forEach(vm.elasticdata.aggregations.aggby.buckets, function (data) {
        vm.graphs.push({
          title: data.key,
          data: data.key,
          key_id: data.key_id,
          bucket: data.sales.buckets
        });
      });
    } else {
      angular.forEach(vm.elasticdata.aggregations.aggby.buckets, function (data) {
        vm.graphs.push({
          title: data.key,
          data: data.key,
          key_id: data.key_id,
          bucket: data.sales.buckets,
          fillAlphas: 0.5,
          lineAlpha: 0.5
        });
        vm.graphs.reverse();
      });
    }
  }
  /**
   * Remove filter.
   *
   * @param {object} obj -
   * @param {Array} list -
   */


  function removeFilter(obj, list) {
    var index = list.indexOf(obj);

    if (index !== -1) {
      list.splice(index, 1);
      vm.loading = true;
      StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (env) {
        vm.query = env;
        init();
        launchQuery(true);
      });
    }
  }
  /**
   * Data aggregations.
   */


  vm.aggregateBy = "none";
  vm.aggByChange = aggByChange;
  /**
   * AggBy change.
   */

  function aggByChange() {
    if (vm.aggregateBy === "none") {
      vm.display100Percent = "0";
    }

    $rootScope.$broadcast("linechart:aggregateByChangeFromChart", vm.aggregateBy);
    vm.loading = true;
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (env) {
      vm.query = env;
      init();
      launchQuery(true);
    });
  }

  vm.aggBySelect = StatisticsQueryService.aggBy;
  vm.aggByName = vm.aggBySelect[0].title + " " + vm.aggBySelect[0].label;
  vm.aggBy = {};
  vm.aggBy.model = JSON.stringify(vm.aggBySelect[0]);
  /**
   * Check if can eclate.
   *
   * @param {object} type - The type of aggregation.
   * @returns {boolean|boolean}
   */

  vm.canEclate = function (type) {
    if (typeof vm.currentEnv === "undefined") {
      currentEnvironment.then(function (env) {
        vm.currentEnv = env;

        if (typeof type.rankNeeded !== "undefined") {
          return type.rights.indexOf(vm.currentEnv.accessPointType) > -1 && type.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
        } else if (type.rights.indexOf("Administrator") > -1) {
          return vm.currentEnv.user.role === "Administrator";
        }

        return type.rights.indexOf(vm.currentEnv.accessPointType) > -1;
      });
    } else if (typeof type.rankNeeded !== "undefined") {
      return type.rights.indexOf(vm.currentEnv.accessPointType) > -1 && type.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
    } else if (type.rights.indexOf("Administrator") > -1) {
      return vm.currentEnv.user.role === "Administrator";
    }

    return type.rights.indexOf(vm.currentEnv.accessPointType) > -1;
  };
  /**
   * Open dialog.
   *
   * @param {object} event - The click event.
   */


  vm.openDialogEclatement = function (event) {
    DialogEclatementController.$inject = ["$scope", "$mdDialog", "items", "aggBy", "currentState"];
    var parentEl = angular.element(document.body);
    $mdDialog.show({
      parent: parentEl,
      targetEvent: event,
      templateUrl: "app/main/statistics/template/eclatement.tmpl.html",
      locals: {
        items: vm.aggBySelect,
        aggBy: vm.aggBy,
        currentState: $scope.currentState
      },
      controller: DialogEclatementController
    });
    /**
     * Dialog eclatement controller.
     *
     * @param {object} $scope - The scope.
     * @param {object} $mdDialog - $mdDialog service
     * @param {object} items - The items to show in the dialog.
     * @param {object} aggBy - The current aggBy selected
     * @param {object} currentState - The current state.
     */

    function DialogEclatementController($scope, $mdDialog, items, aggBy, currentState) {
      /**
       * Chunk
       *
       * @param {Array} arr - The array to chunk.
       * @param {number} size - Numer to chunk by.
       * @returns {Array}
       */
      function chunk(arr, size) {
        var newArr = [];

        for (var index = 0; index < arr.length; index += size) {
          newArr.push(arr.slice(index, index + size));
        }

        return newArr;
      }

      $scope.items = chunk(items, 8);
      $scope.currentState = currentState;
      $scope.aggBy = aggBy;
      /**
       * Can eclate or not.
       *
       * @param {object} type - The type to aggregate by.
       * @returns {boolean}
       */

      $scope.canEclate = function (type) {
        if (typeof vm.currentEnv === "undefined") {
          currentEnvironment.then(function (env) {
            vm.currentEnv = env;

            if (typeof type.rankNeeded !== "undefined") {
              return type.rights.indexOf(vm.currentEnv.accessPointType) > -1 && type.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
            }

            if (type.rights.indexOf("Administrator") > -1) {
              return vm.currentEnv.user.role === "Administrator";
            }

            return type.rights.indexOf(vm.currentEnv.accessPointType) > -1;
          });
        } else if (typeof type.rankNeeded !== "undefined") {
          return type.rights.indexOf(vm.currentEnv.accessPointType) > -1 && type.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
        } else if (type.rights.indexOf("Administrator") > -1) {
          return vm.currentEnv.user.role === "Administrator";
        }

        return type.rights.indexOf(vm.currentEnv.accessPointType) > -1;
      };
      /**
       * Flex results.
       *
       * @param {Array} array - An array of results.
       * @returns {number}
       */


      $scope.flexResult = function (array) {
        if (array.length >= 10) {
          return 50;
        }

        return 100;
      };
      /**
       * Eclate by
       *
       * @param {string} agg - The selected aggregation.
       */


      $scope.eclateBy = function (agg) {
        var parsedAgg = JSON.parse(agg);
        vm.aggregateBy = parsedAgg.val;
        vm.aggBy = {};
        vm.aggBy.model = agg;
        vm.aggByName = parsedAgg.title + (parsedAgg.label ? " " + parsedAgg.label : "");

        if (vm.aggregateBy === "none") {
          vm.display100Percent = "0";
        }

        $rootScope.$broadcast("linechart:aggregateByChangeFromChart", vm.aggregateBy);
        vm.loading = true;
        StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (env) {
          vm.query = env;
          init();
          launchQuery(true);
          $mdDialog.hide();
        });
      };
    }
  };
  /**
   * Export
   */


  vm["export"] = function () {
    vm.loading = true;
    launchQuery(true, true);
  };
  /**
   * Show Gantt
   */


  function showGantt() {
    $scope.ganttIsShown = !$scope.ganttIsShown;
  }

  $scope.$on("autocompleteFiltersService:modelChange", function (event, data) {
    vm.autocomplete = data;
  });
  $scope.$on("graph-settings:change", function (event, data) {
    if (!vm.loading) {
      vm.reloadNewChart(data);
    }
  });
  $scope.$on("salesAnalysisFilterChange", function () {
    vm.loading = true;
    var queryTypes = ["must", "should"];
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      var queryProduct = {};
      var productsPresentation = [];
      queryTypes.forEach(function (queryType) {
        var _loop = function _loop(type) {
          if (Object.prototype.hasOwnProperty.call(query[queryType], "type")) {
            if (typeof queryProduct[type] === "undefined") {
              queryProduct[type] = [];
            }

            angular.forEach(query[queryType][type], function (obj) {
              queryProduct[type].push(obj);
            });
          }
        };

        for (var type in query[queryType]) {
          _loop(type);
        } // getProducts with query results


        productService.postPresentationByType(queryProduct).then(function (products) {
          angular.forEach(products, function (product) {
            if (!_.find(productsPresentation, function (prod) {
              return product.id === prod.id;
            })) {
              productsPresentation.push(product.id);
            }
          });
          $scope.ganttFilters = productsPresentation;
        });
      });
      vm.query = query;
      init();
      launchQuery(true);
    });
  });
  $scope.$on("filterByEvent", function (event, data) {
    vm.loading = true;
    var products = [];
    vm.usrquery = StatisticsQueryService.query;
    vm.usrquery.date.from = moment(data.start, "YYYY-MM-DD");
    vm.usrquery.date.to = moment(data.end, "YYYY-MM-DD");
    vm.usrquery.must.product.selected = [];
    angular.forEach(data.products, function (product) {
      var prod = product.product ? product.product : product.presentation ? product.presentation : product;
      products.push(prod.id);
      vm.usrquery.must.product.selected.push({
        id: prod.id,
        name: prod.name,
        type: "product"
      });
    });
    $scope.ganttFilters = products;
    angular.forEach(data.organizations, function (orga) {
      if (orga.morphable_type === "healthcenter") {
        vm.usrquery.must.pharmas.selected.push({
          cip: orga.morphable.cip,
          name: orga.morphable.name,
          type: "pharmas"
        });
      }

      if (orga.morphable_type === "grouping") {
        vm.usrquery.must.groups.selected.push({
          id: orga.morphable.id,
          name: orga.morphable.name,
          type: "groups"
        });
      }
    });
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      vm.query = query;
      init();
      processQuery(vm.query);
    });
  });
}

angular.module("app.statistics.salesAnalysis").controller("StatsSandboxLinesAtclController", StatsSandboxLinesAtclController);