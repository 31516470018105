"use strict";
/**
 * Wholesaler service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

WholesalerService.$inject = ["apiClient"];

function WholesalerService(apiClient) {
  return {
    /**
     * List of wholesalers.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/wholesalers", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.wholesalers;
      });
    },

    /**
     * List of all the wholesalers.
     *
     * @returns {Promise}
     */
    getAll: function getAll() {
      return apiClient.get("/wholesalers/all", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.wholesalers;
      });
    },

    /**
     * A single wholesaler.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/wholesalers/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.wholesaler;
      });
    },

    /**
     * List of the wholesaler warehouse healthcenters connected.
     *
     * @returns {Promise}
     */
    getConnectedWholesalerWarehouseHealthcenters: function getConnectedWholesalerWarehouseHealthcenters() {
      return apiClient.get("/wholesalers/warehouses/healthcenters", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get all warehouses.
     *
     * @returns {Promise}
     */
    getAllWarehouses: function getAllWarehouses() {
      return apiClient.get("/wholesalers/warehouses/all", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.wholesalerWarehouses;
      });
    },

    /**
     * List of the wholesaler warehouse.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getWarehouseList: function getWarehouseList(params) {
      return apiClient.get("/whoelsalers/".concat(params.id, "/warehouses"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.wholesalerWarehouses;
      });
    },

    /**
     * List of the wholesaler warehouse healthcenter.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    updateWholesalerWarehouseHealthcenter: function updateWholesalerWarehouseHealthcenter(params) {
      return apiClient.put("/wholesalers/".concat(params.wholesalerId, "/warehouses/").concat(params.warehouseId, "/healthcenters/").concat(params.wholesalerWarehouseHealthcenterId), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Link a healthcenter and a wholesaler warehouse by default.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    setDefaultWholesalerWarehouseHealthcenter: function setDefaultWholesalerWarehouseHealthcenter(params) {
      return apiClient.patch("/wholesalers/".concat(params.wholesalerId, "/warehouses/").concat(params.warehouseId, "/healthcenters/").concat(params.wholesalerWarehouseHealthcenterId, "/default"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the sales of the wholesaler for the current profile.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getSales: function getSales(params) {
      return apiClient.get("/wholesalers/".concat(params.id, "/sales"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.wholesalerSales;
      });
    },

    /**
     * Get filtered sales for a wholesaler.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getFilteredSales: function getFilteredSales(params) {
      return apiClient.post("/wholesalers/".concat(params.id, "/sales"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.wholesalerSales;
      });
    },

    /**
     * Get the products of the wholesaler for the current laboratory.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getProducts: function getProducts(params) {
      return apiClient.get("/laboratory/wholesalers/".concat(params.id, "/products"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.wholesalerProducts;
      });
    },

    /**
     * Get the products stock of the wholesaler for the current laboratory.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getStocks: function getStocks(params) {
      return apiClient.get("/laboratory/wholesalers/".concat(params.id, "/stocks"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.wholesalerStocks;
      });
    },

    /**
     * Get the data for the logistician dashboard.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getDashboard: function getDashboard(params) {
      return apiClient.get("/wholesalers/".concat(params.id, "/dashboard")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get global boughts.
     *
     * @param {object} params -
     * @param {object} parameters -
     * @returns {Promise}
     */
    getGlobalBoughts: function getGlobalBoughts(params, parameters) {
      return apiClient.get("/wholesalers/".concat(params.id, "/globalBoughts"), {
        parameters: parameters
      });
    }
  };
}

angular.module("app.wholesaler").service("wholesalerService", WholesalerService);