"use strict";
/**
 * Evolution marker directive.
 *
 * @returns {object}
 */

function EvolutionMarker() {
  return {
    restrict: "A",
    transclude: false,
    compile: function compile() {
      return {
        post: function post(scope, iElem, iAttrs) {
          if (parseFloat(iAttrs.value) > 0) {
            iElem.prepend("<md-icon md-font-icon=\"icon-trending-up\"\n" + "class=\"s20 trending-up icon-trending-up material-icons\"\n" + "aria-hidden=\"true\"></md-icon>");
          }

          if (parseFloat(iAttrs.value) < 0) {
            iElem.prepend("<md-icon md-font-icon=\"icon-trending-down\"\n" + "class=\"s20 trending-down icon-trending-down material-icons\"\n" + "aria-hidden=\"true\"></md-icon>");
          }

          if (parseFloat(iAttrs.value) === 0) {
            iElem.prepend("<md-icon md-font-icon=\"icon-trending-neutral\"\n" + "class=\"s20 trending-neutral icon-trending-neutral material-icons\"\n" + "aria-hidden=\"true\"></md-icon>");
          }
        }
      };
    }
  };
}

angular.module("app.directives").directive("evolutionMarker", EvolutionMarker);