"use strict";
/**
 * Controller for wholesalers top.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} disruptionService - The disruption service.
 */

UndeliveredTurnoverController.$inject = ["$scope", "disruptionService"];

function UndeliveredTurnoverController($scope, disruptionService) {
  $scope.genericType = 1;
  $scope.target = "healthcenter";
  $scope.loading = true;
  $scope.changeType = changeType;
  $scope.changeTarget = changeTarget;
  loader();
  /**
   * The loading function.
   */

  function loader() {
    var params = [{
      name: "genericType",
      value: $scope.genericType
    }, {
      name: "target",
      value: $scope.target
    }];
    disruptionService.getUndeliveredTurnover(params).then(function (data) {
      $scope.undeliveredTurnoverChartOptions = getOptions(data);
      $scope.loading = false;
    });
  }
  /**
   * Change the generic type.
   *
   * @param {number} type - The generic type id.
   */


  function changeType(type) {
    $scope.genericType = type;
    var params = [{
      name: "genericType",
      value: type
    }, {
      name: "target",
      value: $scope.target
    }];
    disruptionService.getUndeliveredTurnover(params).then(function (data) {
      $scope.undeliveredTurnoverChartOptions = getOptions(data);
      $scope.loading = false;
    });
  }
  /**
   * Change the target.
   *
   * @param {string} target - The target to analyze.
   */


  function changeTarget(target) {
    $scope.target = target;
    var params = [{
      name: "genericType",
      value: $scope.genericType
    }];

    if (target === "healthcenter") {
      params.push({
        name: "target",
        value: target
      });
    }

    disruptionService.getUndeliveredTurnover(params).then(function (data) {
      $scope.undeliveredTurnoverChartOptions = getOptions(data);
      $scope.loading = false;
    });
  }
  /**
   * Get chart options.
   *
   * @param {Array} data - The data to show in the graph.
   */


  function getOptions(data) {
    return {
      type: "serial",
      theme: "light",
      data: getData(data),
      thousandsSeparator: " ",
      precision: 0,
      valueAxes: [{
        axisAlpha: 0,
        position: "left",
        title: "CA total"
      }],
      graphs: [{
        fillAlphas: 0.9,
        lineAlpha: 0.2,
        type: "column",
        valueField: "caTotal",
        balloonText: "<div style='font-size:11px;text-align:center'>[[category]] : <br><b>[[value]] EUR</b></div>"
      }],
      chartCursor: {
        enabled: true,
        avoidBalloonOverlapping: false,
        balloonPointerOrientation: "vertical",
        oneBalloonOnly: true,
        cursorAlpha: 0,
        cursorColor: "#003D56"
      },
      categoryField: "laboratoryName",
      categoryAxis: {
        labelsEnabled: false,
        title: "Laboratoires",
        parseDates: false
      }
    };
  }
  /**
   * Get data.
   *
   * @param {Array} data - The data to parse.
   * @returns {Array}
   */


  function getData(data) {
    return data.map(function (datum) {
      return {
        laboratoryName: datum.key,
        caTotal: datum.caTotal.value
      };
    }).splice(0, 5);
  }
}

angular.module("app.dashboard").controller("undeliveredTurnoverController", UndeliveredTurnoverController);