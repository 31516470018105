"use strict";
/**
 * Sales analysis market share service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

salesAnalysisMarketShareService.$inject = ["apiClient"];

function salesAnalysisMarketShareService(apiClient) {
  return {
    /**
     * Load.
     *
     * @param {object} params - Params.
     *
     * @returns {Promise}
     */
    load: function load(params) {
      return apiClient.post("/analysis/megadonut", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics.salesAnalysis").service("salesAnalysisMarketShareService", salesAnalysisMarketShareService);