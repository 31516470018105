"use strict";
/**
 * Current sellins controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - Application state.
 * @param {object} sellinChallengeService - Sellin challenge service.
 */

AwaitingSellinChallengesNotificationController.$inject = ["$scope", "$state", "sellinChallengeService"];

function AwaitingSellinChallengesNotificationController($scope, $state, sellinChallengeService) {
  $scope.awaitingSellinsCount = 0;
  $scope.goToAwaitingSellins = goToAwaitingSellins;
  $scope.goToSellinChallenges = goToSellinChallenges;
  loader();
  /**
   * Loader.
   */

  function loader() {
    $scope.loading = true;
    sellinChallengeService.countGroupingAwaitingSellins().then(function (count) {
      $scope.awaitingSellinsCount = count;
    });
  }
  /**
   * Go to awaiting sellins.
   */


  function goToAwaitingSellins() {
    $state.go("app.sellinChallenge", {
      awaiting: 1
    });
  }
  /**
   * Go the sellin challenges.
   */


  function goToSellinChallenges() {
    $state.go("app.sellinChallenge");
  }
}

angular.module("app.dashboard").controller("awaitingSellinChallengesNotificationController", AwaitingSellinChallengesNotificationController);