"use strict";
/**
 * Bug report directive.
 *
 * @returns {object}
 */

BugReportController.$inject = ["$scope", "$mdDialog", "$mdToast", "environmentService", "bugReportService"];

function BugReportDirective() {
  return {
    restrict: "E",
    scope: {
      title: "@title",
      content: "@content",
      type: "@type"
    },
    templateUrl: "app/main/directives/log/sendBugReport.html",
    controller: BugReportController
  };
}
/**
 * Bug report controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $mdToast - $mdToast.
 * @param {object} environmentService - Environment service.
 * @param {object} bugReportService - Bug report service
 */


function BugReportController($scope, $mdDialog, $mdToast, environmentService, bugReportService) {
  sendMessageController.$inject = ["scope"];
  // eslint-disable-next-line no-invalid-this
  var vm = this;
  vm.bug = {};

  $scope.openModalBug = function (ev) {
    $mdDialog.show({
      controller: sendMessageController,
      templateUrl: "app/main/directives/log/sendMessageInput.html",
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true
    });
  };
  /**
   * Send message controller for the dialog.
   *
   * @param {object} scope - The scope of the dialog.
   */


  function sendMessageController(scope) {
    scope.sendMessage = function () {
      environmentService.getEnvironment().then(function (env) {
        vm.bug.message = scope.message;
        vm.bug.subject = scope.subject;
        vm.bug.content = $scope.content;
        vm.bug.type = $scope.type;
        vm.bug.healthcenter_id = env.entityId;
        vm.bug.user_id = env.user.id;
        bugReportService.create(vm.bug).then(function () {
          var message = "Votre message a bien été envoyé.";
          $mdToast.show($mdToast.simple().textContent(message).position("top right").hideDelay(3000));
          $mdDialog.hide();
        });
      });
    };
  }
}

angular.module("app.logMessage", []).directive("logMessage", BugReportDirective);