"use strict";
/**
 * The wholesaler service controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} wholesalerService - The wholesaler service.
 */

WholesalerListing.$inject = ["$scope", "wholesalerService"];

function WholesalerListing($scope, wholesalerService) {
  $scope.loaderWholesalers = {
    loading: true,
    loader: "Récupération des grossistes",
    empty: "Aucun grossiste"
  };
  loader();
  $scope.wholesalers = [];
  $scope.setWarehouseForWholesaler = setWarehouseForWholesaler;
  $scope.selectDefaultWarehouse = selectDefaultWarehouse;
  $scope.setRankForWholesaler = setRankForWholesaler;
  /**
   * The loading function
   */

  function loader() {
    getConnectedWholesalerWarehouseHealthcenters();
  }
  /**
   * Return the wholesalers of the healthcenter connected
   */


  function getConnectedWholesalerWarehouseHealthcenters() {
    wholesalerService.getConnectedWholesalerWarehouseHealthcenters().then(function (response) {
      $scope.wholesalerWarehouseHealthcenters = response;
      $scope.wholesalers = response.sort(function (wholesalerA, wholesalerB) {
        return (wholesalerA.rank === null) - (wholesalerB.rank === null) || wholesalerA.rank - wholesalerB.rank;
      });
      $scope.loaderWholesalers.loading = false;
    }).then(getWholesalersWarehouses);
  }
  /**
   * Return the wholesalers warehouses availables of each wholesaler warehouse healthcenter
   */


  function getWholesalersWarehouses() {
    wholesalerService.getAllWarehouses({}).then(function (wholesalerWarehouses) {
      // For each wholesaler warehouse healthcenter, provide the available warehouses
      angular.forEach($scope.wholesalerWarehouseHealthcenters, function (wholesalerWarehouseHealthcenter) {
        wholesalerWarehouseHealthcenter.warehouses = []; // For each warehouse, extract those of the whole

        angular.forEach(wholesalerWarehouses, function (warehouse) {
          if (wholesalerWarehouseHealthcenter.wholesalerId === warehouse.wholesalerId) {
            wholesalerWarehouseHealthcenter.warehouses.push(warehouse);

            if (wholesalerWarehouseHealthcenter.wholesalerWarehouseId === warehouse.id) {
              wholesalerWarehouseHealthcenter.warehouse = warehouse;
            }
          }
        });
      });
    });
  }
  /**
   * Select the warehouse for a given wholesaler
   *
   * @param {object} wholesalerWarehouseHealthcenter - The wholesaler warehouse healthcenter
   * @param {number} warehouseId - The warehouse ID
   * @param {number} rank - The rank.
   */


  function setWarehouseForWholesaler(wholesalerWarehouseHealthcenter, warehouseId, rank) {
    wholesalerService.updateWholesalerWarehouseHealthcenter({
      wholesalerId: wholesalerWarehouseHealthcenter.wholesalerId,
      warehouseId: warehouseId,
      wholesalerWarehouseHealthcenterId: wholesalerWarehouseHealthcenter.id,
      rank: rank
    });
  }
  /**
   * Select the default warehouse for a given wholesaler
   *
   * @param {object} wholesalerWarehouseHealthcenter - The wholesaler warehouse healthcenter
   * @param {number} warehouseId - The warehouse ID
   */


  function selectDefaultWarehouse(wholesalerWarehouseHealthcenter, warehouseId) {
    wholesalerService.setDefaultWholesalerWarehouseHealthcenter({
      wholesalerId: wholesalerWarehouseHealthcenter.wholesalerId,
      warehouseId: warehouseId,
      wholesalerWarehouseHealthcenterId: wholesalerWarehouseHealthcenter.id
    }).then(function (wholesalerWarehouseHealthcenters) {
      $scope.wholesalerWarehouseHealthcenters = wholesalerWarehouseHealthcenters;
      getWholesalersWarehouses();
    });
  }
  /**
   * Set rank for wholesaler.
   *
   * @param {number} rank - The rank.
   * @param {object} wholesaler - The wholesaler.
   */


  function setRankForWholesaler(rank, wholesaler) {
    $scope.wholesalers[rank] = wholesaler;
  }
}

angular.module("app.wholesaler").controller("wholesalerListing", WholesalerListing);