"use strict";
/**
 * Guest configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 * @param {object} guestConfig -
 */

GuestConfig.$inject = ["$stateProvider", "guestConfig"];

function GuestConfig($stateProvider, guestConfig) {
  $stateProvider.state("app.guest", {
    url: "/invitations",
    views: {
      "content@app": {
        templateUrl: guestConfig.baseDir + "/views/main/guest.main.html",
        controller: "guestMainController"
      }
    }
  });
}

angular.module("app.guest").config(GuestConfig).constant("guestConfig", {
  baseDir: "app/main/guest"
});