"use strict";

RenewalScheduleService.$inject = ["hdsApiClient"];

/**
 * The renewalSchedule service.
 *
 * @param {object} hdsApiClient - The HDS API Client service.
 *
 * @returns {object}
 */
function RenewalScheduleService(hdsApiClient) {
  return {
    /**
     * Get the index of the renewal schedule plan.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise<number>}
     */
    getRenewalSchedulePlan: function getRenewalSchedulePlan() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return hdsApiClient("GET", "/prescriptions/renewals/plans", {
        params: params
      });
    },

    /**
     * Get details of a specific renewal schedule plan.
     *
     * @param {number} id - Id of the renewal schedule plan.
     *
     * @returns {Promise<any>}
     */
    getRenewalSchedulePlanDetails: function getRenewalSchedulePlanDetails(id) {
      return hdsApiClient("GET", "/prescriptions/renewals/plans/".concat(id)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a new renewal schedule plan.
     *
     * @param {string} prescription_key - The prescription key.
     * @param {Date} end_at - The date prescription expires.
     * @param {Array} dates - An array that contains renewal dates.
     *
     * @returns {Promise<any>}
     */
    createRenewalSchedulePlan: function createRenewalSchedulePlan(prescription_key, end_at, dates) {
      var data = {
        prescription_key: prescription_key,
        end_at: end_at,
        dates: dates
      };
      return hdsApiClient("POST", "/prescriptions/renewals/plans", {
        data: data
      });
    },

    /**
     * Update an existing renewal schedule plan.
     *
     * @param {number} id - Id of the schedule plan to update.
     * @param {Date} end_at - The new date when the prescription expires.
     * @param {Array} dates - An array that contains renewal dates.
     *
     * @returns {Promise<any>}
     */
    updateRenewalSchedulePlan: function updateRenewalSchedulePlan(id, end_at, dates) {
      var data = {
        end_at: end_at,
        dates: dates
      };
      return hdsApiClient("PATCH", "/prescriptions/renewals/plans/".concat(id), {
        data: data
      });
    },

    /**
     * Delete a renewal schedule plan.
     *
     * @param {number} renewalScheduleId - The renewal schedule plan id.
     *
     * @returns {Promise}
     */
    deleteRenewalSchedulePlan: function deleteRenewalSchedulePlan(renewalScheduleId) {
      return hdsApiClient("DELETE", "/prescriptions/renewals/plans/".concat(renewalScheduleId));
    }
  };
}

angular.module("app.patients.services").service("renewalScheduleService", RenewalScheduleService);