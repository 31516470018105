"use strict";
/**
 * Statistics wholesaler audit configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

StatisticsWholesalerAuditConfig.$inject = ["$stateProvider"];

function StatisticsWholesalerAuditConfig($stateProvider) {
  $stateProvider.state("app.statistics.marge", {
    url: "/analyse-marge",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/statistics.html",
        controller: "StatisticsController as vm"
      },
      "statisticsContent@app.statistics.marge": {
        templateUrl: "app/main/statistics/marge/real-marge/views/realMarge.html",
        controller: "RealMargeController as vm"
      }
    },
    bodyClass: "dashboard"
  }).state("app.statistics.wholesalerAudit", {
    url: "/audit-grossiste",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/statistics.html",
        controller: "StatisticsController as vm"
      },
      "statisticsContent@app.statistics.wholesalerAudit": {
        templateUrl: "app/main/statistics/marge/real-marge/views/realMarge.html",
        controller: "RealMargeController as vm"
      }
    },
    bodyClass: "dashboard"
  });
}

angular.module("app.statistics.wholesalerAudit", ["app.statistics.realMarge", "app.statistics.smoothed-degressive-margin"]).config(StatisticsWholesalerAuditConfig);