"use strict";

ApodisTileController.$inject = ["$scope", "$state"];

/**
 * Apodis tile directive.
 *
 * @returns {object}
 */
function ApodisTile() {
  return {
    restrict: "E",
    scope: {
      config: "=config",
      myFilter: "@filter",
      statusFiltered: "@statusFiltered"
    },
    templateUrl: "app/main/directives/apodis-tile/apodisTile.html",
    transclude: true,
    controller: ApodisTileController
  };
}
/**
 * Apodis tile controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 */


function ApodisTileController($scope, $state) {
  $scope.tiles = $scope.config.tiles;
  $scope.editState = $scope.config.states.edit;
  $scope.showState = $scope.config.states.show;
  /**
   * Declaration of public methods
   */

  $scope.editTile = editTile;
  $scope.showTile = showTile;
  /**
   * Edit tile.
   *
   * @param {object} tile - Tile to edit.
   *
   * @returns {Promise} Edit state promise.
   */

  function editTile(tile) {
    return $state.go($scope.editState, {
      id: tile.id
    });
  }
  /**
   * Show tile.
   *
   * @param {object} tile - Tile to show.
   *
   * @returns {Promise} Show state promise.
   */


  function showTile(tile) {
    return $state.go($scope.showState, {
      id: tile.id
    });
  }
}

angular.module("app.directives").directive("apodisTile", ApodisTile);