"use strict";
/**
 * Statistics generic catalog controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $filter - $filter.
 * @param {object} $compile - $compile.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} DTOptionsBuilder - DTOptionsBuilder.
 * @param {object} DTColumnBuilder - DTColumnBuilder.
 * @param {object} $timeout - $timeout.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 * @param {object} healthcenterService - Healthcenter service.
 */

StatisticsGenericCatalogController.$inject = ["$scope", "$filter", "$compile", "StatisticsQueryService", "DTOptionsBuilder", "DTColumnBuilder", "$timeout", "environmentService", "analysisService", "healthcenterService"];

function StatisticsGenericCatalogController($scope, $filter, $compile, StatisticsQueryService, DTOptionsBuilder, DTColumnBuilder, $timeout, environmentService, analysisService, healthcenterService) {
  var searchWithLaboName = {
    ARRW: "Arrow générique",
    SNDZ: "Sandoz",
    MYLA: "Mylan",
    TEVA: "Teva",
    BIOG: "Biogaran",
    EGLA: "EG Labo",
    ZENT: "Zentiva",
    RPG: "RANBAXY PHARMACIE GENERIQUES",
    CRST: "Cristers",
    DOLI: "Doliprane",
    UPSA: "Upsa",
    AUTRES: "Autres"
  };
  var notInPrestationValues = [];
  var defaultQuery = JSON.parse(JSON.stringify(StatisticsQueryService.pristineSubstituteQuery));
  var moleculesToShowBeforeFilter = [];
  var moleculesToShow = [];
  $scope.zentivaId = 6443;
  $scope.configCatalogGeneric = {
    event: "pharmacySearched",
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
    tag: "participant",
    searchPlaceHolder: "Rechercher des pharmacies"
  };
  $scope.catalogLoaderOptions = {
    loading: true,
    loader: "Récupération de vos ventes génériques...",
    empty: "Vous n'avez aucune donnée de vente."
  };
  $scope.filteredHealthcenters = [];
  $scope.canExport = false; // Handle the order by

  $scope.sortInformation = {
    ascTotal: 0,
    ascQuantity: 0,
    ascTotalClass: "sorting",
    ascQuantityClass: "sorting"
  };
  $scope.exportHeaders = ["Nom", "Cip7", "Cip13", "Prix", "Quantité Totale", "Labo", "Quantité", "Remise", "Prix remisé", "Total acheté"];
  $scope.genericAuditQuery = {};
  $scope.changeParacetamol = changeParacetamol;
  $scope.prestationEuroChange = prestationEuroChange;
  $scope.prestationPercentChange = prestationPercentChange;
  $scope.prestationBrutPercentChange = prestationBrutPercentChange;
  $scope.genericAuditData = {};
  $scope.dtInstance = {};
  $scope.rangeSelected = rangeSelected;
  $scope.exportExcel = exportExcel;
  $scope.deleteHealthcenterFromFilter = deleteHealthcenterFromFilter;
  $scope.filterByLaboratoryCatalog = filterByLaboratory;
  $scope.sortByQuantity = sortByQuantity;
  $scope.sortByTotalPrice = sortByTotalPrice;
  loader();
  /**
   * The loader
   */

  function loader() {
    environmentService.getEnvironment().then(function (env) {
      $scope.currentEnv = env;
      $scope.canExport = canExport();
    }); // Build the elastic search query

    var dateStart = moment().month(0).date(1);
    var dateEnd = moment();
    defaultQuery.date = {
      from: new Date(dateStart),
      to: new Date(dateEnd)
    };
    defaultQuery.lab.selected = [];
    StatisticsQueryService.buildSubstituteQuery(defaultQuery).then(function (query) {
      $scope.genericAuditQuery = query;
    }); // Set the date label

    $scope.dateLabel = dateStart.format("DD-MMM-YYYY") + " Au " + dateEnd.format("DD-MMM-YYYY");
    buildColumns();
  }
  /**
   * Build query and change data in DT instance
   */


  function buildQueryAndChangeData() {
    StatisticsQueryService.buildSubstituteQuery(defaultQuery).then(function (query) {
      $scope.genericAuditQuery = query;
      $scope.dtInstance.changeData(processQuery());
    });
  }
  /**
   * Euro amount of prestation changed
   *
   * @param {object} laboratory - The laboratory informations
   */


  function prestationEuroChange(laboratory) {
    var notInPrestation = notInPrestationValues[laboratory.quadri];

    if (notInPrestation === undefined) {
      notInPrestation = 0;
    }

    laboratory.prestationPercent = parseFloat((laboratory.prestationEuro / (laboratory.totalDiscountPrice + notInPrestation) * 100).toFixed(2));
    laboratory.remisePercent = parseFloat(((1 - (laboratory.totalDiscountPrice + notInPrestation - laboratory.prestationEuro) / laboratory.totalPrice) * 100).toFixed(2));
    laboratory.remiseEuro = laboratory.remisePercent / 100 * laboratory.totalPrice;
    laboratory.globalRemise = laboratory.totalPrice - laboratory.totalDiscountPrice + parseFloat(laboratory.prestationEuro);
    getTotalForLaboratories();
  }
  /**
   * Percentage amount of prestation changed
   *
   * @param {object} laboratory - The laboratory informations
   */


  function prestationPercentChange(laboratory) {
    var notInPrestation = notInPrestationValues[laboratory.quadri];

    if (notInPrestation === undefined) {
      notInPrestation = 0;
    }

    if (laboratory.prestationPercent > 100) {
      laboratory.prestationPercent = 100;
      prestationPercentChange(laboratory);
      return;
    }

    if (laboratory.prestationPercent < 0) {
      laboratory.prestationPercent = 0;
      prestationPercentChange(laboratory);
      return;
    }

    laboratory.prestationEuro = parseFloat((laboratory.prestationPercent / 100 * (laboratory.totalDiscountPrice + notInPrestation)).toFixed(2));
    laboratory.remisePercent = parseFloat(((1 - (laboratory.totalDiscountPrice + notInPrestation - laboratory.prestationEuro) / laboratory.totalPrice) * 100).toFixed(2));
    laboratory.remiseEuro = laboratory.remisePercent / 100 * laboratory.totalPrice;
    laboratory.globalRemise = laboratory.totalPrice - laboratory.totalDiscountPrice + parseFloat(laboratory.prestationEuro);
    getTotalForLaboratories();
  }
  /**
   * Percentage brut amount of prestation changed
   *
   * @param {object} laboratory - The laboratory informations
   */


  function prestationBrutPercentChange(laboratory) {
    var notInPrestation = notInPrestationValues[laboratory.quadri];

    if (notInPrestation === undefined) {
      notInPrestation = 0;
    }

    laboratory.errorBrut = laboratory.remisePercent < laboratory.discount;
    laboratory.prestationEuro = parseFloat(laboratory.remisePercent / 100 * laboratory.totalPrice - (laboratory.totalPrice - (laboratory.totalDiscountPrice + notInPrestation))).toFixed(2);
    laboratory.remiseEuro = parseFloat(laboratory.totalPrice - (laboratory.totalDiscountPrice + notInPrestation) + parseFloat(laboratory.prestationEuro)).toFixed(2);
    laboratory.prestationPercent = parseFloat(laboratory.prestationEuro / (laboratory.totalDiscountPrice + notInPrestation) * 100).toFixed(2);
    laboratory.globalRemise = laboratory.totalPrice - laboratory.totalDiscountPrice + parseFloat(laboratory.prestationEuro);
    getTotalForLaboratories();
  }
  /**
   * Add input models to laboratories from elastic search.
   *
   * @param {Array} laboratories - The laboratories informations
   *
   * @returns {Array}
   */


  function addInputModelsToLabs(laboratories) {
    return laboratories.map(function (currentValue) {
      currentValue.prestationPercent = 0;
      currentValue.prestationEuro = 0;
      currentValue.remisePercent = parseFloat((1 - (currentValue.totalDiscountPrice - currentValue.prestationEuro) / currentValue.totalPrice) * 100).toFixed(2);
      currentValue.remiseEuro = currentValue.remisePercent / 100 * currentValue.totalPrice;
      currentValue.globalRemise = currentValue.totalPrice - currentValue.totalDiscountPrice + parseFloat(currentValue.prestationEuro);
      return currentValue;
    });
  }
  /**
   * Get the total for all laboratories
   */


  function getTotalForLaboratories() {
    var allLaboratoriesTotal = {
      totalPrice: 0,
      totalDiscountPrice: 0,
      remiseEuro: 0
    };
    angular.forEach($scope.genericAuditData.laboratory, function (value) {
      allLaboratoriesTotal.totalPrice += parseFloat(value.totalPrice);
      allLaboratoriesTotal.totalDiscountPrice += parseFloat(value.totalDiscountPrice);
      allLaboratoriesTotal.remiseEuro += parseFloat(value.remiseEuro);
    });
    allLaboratoriesTotal.discount = parseFloat((allLaboratoriesTotal.totalPrice - allLaboratoriesTotal.totalDiscountPrice) / allLaboratoriesTotal.totalPrice * 100).toFixed(2);
    allLaboratoriesTotal.remisePercent = allLaboratoriesTotal.remiseEuro / allLaboratoriesTotal.totalPrice * 100;
    $scope.allLaboratoriesTotal = allLaboratoriesTotal;
  }
  /**
   * Process the query
   *
   * @returns {Promise} The promise
   */


  function processQuery() {
    $scope.genericAuditData = {};
    moleculesToShowBeforeFilter = [];
    moleculesToShow = [];
    var query = $scope.genericAuditQuery;
    buildColumns();
    return analysisService.searchGeneric(query).then(function (response) {
      // Set additional default data for the form
      response.laboratory = addInputModelsToLabs(response.laboratory);
      $scope.genericAuditData = response;
      $scope.genericAuditData.group = $filter("filter")($scope.genericAuditData.group, {
        price: "!!"
      });
      getTotalForLaboratories();
      var tmpDatas = [];
      angular.forEach($scope.genericAuditData.group, function (group) {
        var prodTmp = {};
        prodTmp.quantity = 0;
        prodTmp.totalDiscountPrice = 0;
        var toPush = false;
        var idx = [];
        var productTmp = [];
        var tmpTotalDiscountPrice = 0;
        var tmpTotalQuantity = 0;
        angular.forEach(group.product, function (prod, index) {
          prod.laboratoryName = searchWithLaboName[prod.quadri];

          if (prod.quadri === "AUTRES") {
            toPush = true;
            prodTmp.quantity += prod.quantity;
            prodTmp.quadri = prod.quadri;
            prodTmp.discountPrice = prod.discountPrice;
            prodTmp.totalDiscountPrice += prod.totalDiscountPrice;
            prodTmp.color = prod.color;
            idx.push(index);
          } else {
            productTmp.push(prod);
          }

          tmpTotalDiscountPrice += prod.totalDiscountPrice;
          tmpTotalQuantity += prod.quantity;
        });
        group.totalToSortOn = tmpTotalDiscountPrice;
        group.quantity = tmpTotalQuantity;

        if (toPush) {
          productTmp.push(prodTmp);
          group.product = productTmp;
        }

        tmpDatas.push(group);
      });
      moleculesToShowBeforeFilter = tmpDatas;
      $scope.catalogLoaderOptions.loading = false;
      moleculesToShow = tmpDatas;
      return moleculesToShow;
    });
  }
  /**
   * Change the paracetamol filter value
   */


  function changeParacetamol() {
    moleculesToShow = [];
    $scope.catalogLoaderOptions.loading = true;
    defaultQuery.paracetamol.selected = $scope.genericAuditQuery.paracetamol;
    buildQueryAndChangeData();
  }
  /**
   * Filter by laboratory
   *
   * @param {object} laboratory - The laboratory informations
   */


  function filterByLaboratory(laboratory) {
    if ($scope.currentEnv.entityId !== $scope.zentivaQuadri) {
      $scope.dtInstance.changeData(new Promise(function (resolve) {
        moleculesToShow = []; // eslint-disable-next-line consistent-return

        moleculesToShow = _.filter(moleculesToShowBeforeFilter, function (mol) {
          if (mol.product.length > 0) {
            return _.find(mol.product, function (prod) {
              return prod.quadri === laboratory.quadri;
            });
          }
        });
        $scope.sortInformation.ascQuantity = 0;
        $scope.sortInformation.ascTotal = 0;
        resolve(moleculesToShow);
      }));
    }
  }
  /**
   * Build the excel export
   *
   * @returns {Array} The export data
   */


  function exportExcel() {
    var exportData = [];

    _.each(moleculesToShow, function (molecule) {
      _.each(molecule.product, function (prod) {
        if (prod.price) {
          exportData.push({
            name: molecule.name,
            cip7: prod.cip7,
            cip13: prod.cip13,
            price: prod.price.toString().replace(",", " ").replace(".", ","),
            totalQuantity: molecule.quantity,
            labo: prod.quadri,
            quantity: prod.quantity,
            discount: prod.discount,
            discountedPrice: prod.discountPrice.toString().replace(",", " ").replace(".", ","),
            totalPrice: prod.totalDiscountPrice.toString().replace(",", " ").replace(".", ",")
          });
        }
      });
    });

    return exportData;
  }
  /**
   * Launched when a date range is selected
   *
   * @param {object} date - The date object
   */


  function rangeSelected(date) {
    $scope.catalogLoaderOptions.loading = true;
    defaultQuery.date.from = moment(date.startDate, "DD-MM-YYYY");
    defaultQuery.date.to = moment(date.endDate, "DD-MM-YYYY");
    buildQueryAndChangeData();
  }
  /**
   * Sort by total price
   */


  function sortByTotalPrice() {
    $scope.sortInformation.ascTotal = changeOrder($scope.sortInformation.ascTotal);
    $scope.dtInstance.changeData(new Promise(function (resolve) {
      // eslint-disable-next-line consistent-return
      moleculesToShow = _.sortBy(moleculesToShow, function (product) {
        if ($scope.sortInformation.ascTotal === 1) {
          return parseFloat(product.totalToSortOn);
        } else if ($scope.sortInformation.ascTotal === -1) {
          return -parseFloat(product.totalToSortOn);
        }
      });
      resolve(moleculesToShow);
    }));
  }
  /**
   * Sort by quantity
   */


  function sortByQuantity() {
    $scope.sortInformation.ascQuantity = changeOrder($scope.sortInformation.ascQuantity);
    $scope.dtInstance.changeData(new Promise(function (resolve) {
      // eslint-disable-next-line consistent-return
      moleculesToShow = _.sortBy(moleculesToShow, function (product) {
        if ($scope.sortInformation.ascQuantity === 1) {
          return parseFloat(product.quantity);
        } else if ($scope.sortInformation.ascQuantity === -1) {
          return -parseFloat(product.quantity);
        }
      });
      resolve(moleculesToShow);
    }));
  }
  /**
   * Change the order
   *
   * @param {number} asc - Asc or not
   *
   * @returns {number} The new order
   */


  function changeOrder(asc) {
    return asc === 1 ? -1 : 1;
  }
  /**
   * Launch the search for pharmacies
   *
   * @param {object} event - The event
   * @param {object} query - The query
   *
   */


  $scope.$on("pharmacySearched", function (event, query) {
    var queryPharma = {
      type: {}
    };

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(query, type)) {
        if (typeof queryPharma.type[type] === "undefined") {
          queryPharma.type[type] = [];
        } // eslint-disable-next-line no-loop-func


        angular.forEach(query[type], function (obj) {
          if (type === "uga") {
            queryPharma.type[type].push(obj.name);
          } else {
            queryPharma.type[type].push(obj.id);
          }
        });
      }
    };

    for (var type in query) {
      _loop(type);
    }

    queryPharma.full = true; // get healthcenters with query results

    healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      angular.forEach(pharmas, function (pharma) {
        if (pharma._source) {
          defaultQuery.must.pharmas.selected.push(pharma._source);
          $scope.filteredHealthcenters.push(pharma._source);
        } else {
          defaultQuery.must.pharmas.selected.push(pharma);
          $scope.filteredHealthcenters.push(pharma);
        }
      });
      StatisticsQueryService.buildSubstituteQuery(defaultQuery).then(function (query) {
        $scope.genericAuditQuery = query;
        $scope.dtInstance.changeData(processQuery());
      });
    });
  });
  /**
   * Delete a healthcenter from the filter
   *
   * @param {number} index - The index in array
   */

  function deleteHealthcenterFromFilter(index) {
    defaultQuery.must.pharmas.selected.splice(index, 1);
    $scope.filteredHealthcenters.splice(index, 1);
    StatisticsQueryService.buildSubstituteQuery(defaultQuery).then(function (query) {
      $scope.genericAuditQuery = query;
      $scope.dtInstance.changeData(processQuery());
    });
  }
  /**
   * DT options for the instance
   */


  var dtOptions = {
    dom: "rt<'bottom'<'left'<'length'l>><'right'<'info'i><'pagination'p>>>",
    order: [],
    columnDefs: [{
      targets: [0],
      filterable: true,
      sortable: true
    }, {
      targets: [9],
      filterable: true,
      visible: false
    }, {
      targets: [1, 2, 3, 5, 6],
      filterable: true,
      sortable: true,
      className: "dt-right"
    }, {
      targets: [7, 4],
      filterable: true,
      sortable: false,
      className: "dt-right"
    }],
    initComplete: function initComplete() {
      var api = this.api();
      var searchBox = angular.element("body").find("#searchTextMol"); // Bind an external input as a table wide search box

      if (searchBox.length > 0) {
        searchBox.on("keyup", function (event) {
          api.search(event.target.value).draw();
        });
      }
    },
    drawCallback: function drawCallback() {
      $timeout(function () {
        if ($scope.sortInformation.ascQuantity !== 0) {
          if ($scope.sortInformation.ascQuantity === 1) {
            $scope.sortInformation.ascQuantityClass = "sorting_asc";
          } else if ($scope.sortInformation.ascQuantity === -1) {
            $scope.sortInformation.ascQuantityClass = "sorting_desc";
          }

          $scope.sortInformation.ascTotalClass = "sorting";
        } else {
          $scope.sortInformation.ascQuantityClass = "sorting";
        }

        if ($scope.sortInformation.ascTotal !== 0) {
          if ($scope.sortInformation.ascTotal === 1) {
            $scope.sortInformation.ascTotalClass = "sorting_asc";
          } else if ($scope.sortInformation.ascTotal === -1) {
            $scope.sortInformation.ascTotalClass = "sorting_desc";
          }

          $scope.sortInformation.ascQuantityClass = "sorting";
        } else {
          $scope.sortInformation.ascTotalClass = "sorting";
        }
      }, 0);
    },
    language: {
      sZeroRecords: "Aucun élément trouvé",
      sLengthMenu: "Afficher _MENU_ éléments",
      sInfo: "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
      sInfoEmpty: "Affichage de 0 à 0 sur 0 éléments",
      sInfoFiltered: "(filtré sur un total de _MAX_ éléments)",
      oPaginate: {
        sNext: "Suivant",
        sPrevious: "Précédent"
      }
    },
    pagingType: "simple",
    lengthMenu: [10, 20, 30, 50, 100],
    pageLength: 10,
    responsive: true
  };
  /* Define the dt options of the table */

  $scope.dtOptions = angular.merge(DTOptionsBuilder.fromFnPromise(processQuery).withOption("createdRow", createdRow), dtOptions);
  /**
   * Recompile the created row to bind angular directive to the DT
   *
   * @param {object} row - The row
   */

  function createdRow(row) {
    $compile(angular.element(row).contents())($scope);
  }
  /** Build columns. */


  function buildColumns() {
    $scope.dtColumns = [// Generic group name
    DTColumnBuilder.newColumn("name").withClass("flex").renderWith(function (data, type, row) {
      var message = JSON.stringify(row).replace(/"/g, "'");
      return "" + "<td>" + "<span>" + data + "</span>" + "<log-message content='" + message + "'" + " type='generic'></log-message>" + "</td>";
    }), // Buying price
    DTColumnBuilder.newColumn("price").withClass("dt-right").renderWith(function (data, type, row) {
      return $filter("currency")(row.price);
    }), // Total quantity
    DTColumnBuilder.newColumn("quantity").withClass("dt-right"), // Laboratories
    DTColumnBuilder.newColumn(null).withClass("dt-right").renderWith(function (data, type, row) {
      var content = "";
      angular.forEach(row.product, function (val) {
        content += "<div class='multiple-labs'>";
        content += "  " + " <span class='text-boxed' style='color:#fff; background:" + val.color + ";'>" + val.quadri + "</span> ";
        content += "</div>";
      });
      return content;
    }), // CIP
    DTColumnBuilder.newColumn(null).withClass("dt-left tableColumn").renderWith(function (data, type, row) {
      var content = "";
      angular.forEach(row.product, function (val) {
        content += "" + "<div class='multiple-labs'>" + val.cip13 + "<md-tooltip md-direction='top'>" + val.name + "</md-tooltip>" + "</div>";
      });
      return content;
    }), // Quantity
    DTColumnBuilder.newColumn(null).withClass("dt-right tableColumn").renderWith(function (data, type, row) {
      var content = "";
      angular.forEach(row.product, function (val) {
        content += "" + "<div class='multiple-labs'>" + val.quantity + "</div>";
      });
      return content;
    }), // Discount percentage
    DTColumnBuilder.newColumn(null).withClass("dt-right tableColumn tableDiscount").renderWith(function (data, type, row) {
      var content = "";
      angular.forEach(row.product, function (val) {
        var discount = Object.prototype.hasOwnProperty.call(val, "discount") ? val.discount + "%" : "-";
        content += "" + "<div class='multiple-labs'>" + discount + "</div>";
      });
      return content;
    }), // Discount price
    DTColumnBuilder.newColumn(null).withClass("dt-right tableColumn").renderWith(function (data, type, row) {
      var content = "";
      angular.forEach(row.product, function (val) {
        content += "" + "<div class='multiple-labs'>" + $filter("currency")(val.discountPrice) + "</div>";
      });
      return content;
    }), // Total bought
    DTColumnBuilder.newColumn(null).withClass("dt-right tableColumn").renderWith(function (data, type, row) {
      var content = "";
      angular.forEach(row.product, function (val) {
        content += "" + "<div class='multiple-labs'>" + $filter("currency")(val.totalDiscountPrice) + "</div>";
      });
      return content;
    }), DTColumnBuilder.newColumn(null).withClass("dt-right tableColumn ").renderWith(function (data, type, row) {
      var content = "";
      angular.forEach(row.product, function (val) {
        content += "" + "<div class='multiple-labs'>" + val.laboratoryName + "</div>";
      });
      return content;
    })];
  }
  /**
   * Allow Export only for some IDs - do not delete
   *
   * @returns {boolean} Can export or not
   */


  function canExport() {
    var authorizedIds = [63, 57, 43, 90];
    return authorizedIds.indexOf($scope.currentEnv.userId) > -1;
  }
}

angular.module("app.statistics.genericaudit").controller("StatisticsGenericCatalogController", StatisticsGenericCatalogController);