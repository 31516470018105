"use strict";
/**
 * Branding configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

BrandingConfiguration.$inject = ["$stateProvider"];

function BrandingConfiguration($stateProvider) {
  $stateProvider.state("app.branding", {
    url: "/groupement/vitrine",
    views: {
      "content@app": {
        templateUrl: "app/main/grouping/branding/views/main/branding.main.html",
        controller: "brandingMainController"
      }
    },
    reloadOnSearch: false
  }).state("app.branding.slider", {
    url: "/:groupingId/:groupingBrandingId/slider/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/grouping/branding/views/slider/branding.slider.html",
        controller: "brandingSliderController"
      }
    }
  });
}

angular.module("app.grouping", []).config(BrandingConfiguration);