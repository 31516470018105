"use strict";
/**
 * Sidenav menu factory.
 *
 * @returns {object}
 */

function SidenavMenuFactory() {
  var dashboard = {
    name: "Dashboard",
    icon: "icon-view-dashboard",
    state: "app.dashboard"
  };
  /**
   * Get dashboard.
   *
   * @returns {Promise}
   */

  function getDashboard() {
    return Promise.resolve(dashboard);
  }

  return {
    getDashboard: getDashboard
  };
}

angular.module("app.navigation").factory("sidenavMenuFactory", SidenavMenuFactory);