"use strict";
/**
 * Dashboard healthcenter weak disponibility products controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} wholesalersService - Wholesalers service.
 */

DashboardHealthcenterWeakDisponibilityProductsController.$inject = ["$scope", "wholesalersService"];

function DashboardHealthcenterWeakDisponibilityProductsController($scope, wholesalersService) {
  $scope.loaderWholesalersDisponibilityProducts = {
    loading: true,
    loader: "Récupération des produits en rupture de stock",
    empty: "Aucun produit en rupture de stock"
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    $scope.loading = true;
    getWeakDisponibilityProduct();
  }
  /**
   * Get weak disponibility product.
   */


  function getWeakDisponibilityProduct() {
    wholesalersService.getWeakDisponibilityProduct().then(function (response) {
      $scope.weakDisponibilityProduct = response;
      $scope.loaderWholesalersDisponibilityProducts.loading = false;
    });
  }
}

angular.module("app.dashboard").controller("dashboardHealthcenterWeakDisponibilityProductsController", DashboardHealthcenterWeakDisponibilityProductsController);