"use strict";
/**
 * Statistics average shopping cart analysis configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

StatisticsAverageShoppingCartAnalysisConfig.$inject = ["$stateProvider"];

function StatisticsAverageShoppingCartAnalysisConfig($stateProvider) {
  $stateProvider.state("app.statistics.averageShoppingCartAnalysis", {
    url: "/panier-moyen",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/averageShoppingCart/views/title.html",
        controller: "StatisticsController as vm"
      },
      "statisticsContent@app.statistics.averageShoppingCartAnalysis": {
        templateUrl: "app/main/statistics/averageShoppingCart/views/averageShoppingCart.html",
        controller: "averageShoppingCartController"
      }
    },
    bodyClass: "dashboard"
  });
}

angular.module("app.statistics.averageShoppingCartAnalysis", []).config(StatisticsAverageShoppingCartAnalysisConfig);