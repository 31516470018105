"use strict";
/**
 * Pack subscription service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

PackSubscriptionService.$inject = ["apiClient"];

function PackSubscriptionService(apiClient) {
  return {
    read: function read(params) {
      return apiClient.get("/packs/".concat(params.packSlug, "/subscription"), {
        includes: ["pack", "access"]
      }).then(function (response) {
        return response.data;
      });
    },
    isExpired: function isExpired(params) {
      return apiClient.get("/packs/".concat(params.packSlug, "/subscription/expired")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Check if the user has subscribed to the pack.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    hasSubscribed: function hasSubscribed(params) {
      return apiClient.get("/packs/".concat(params.packSlug, "/subscription/hasSubscribed")).then(function (response) {
        return response.data;
      });
    },
    subscribe: function subscribe(params) {
      return apiClient.post("/packs/".concat(params.packSlug, "/subscription"), {
        data: params
      }, {
        includes: ["pack", "access"]
      }).then(function (response) {
        return response.data;
      });
    },
    unsubscribe: function unsubscribe(params) {
      return apiClient["delete"]("/packs/".concat(params.packSlug, "/subscription"), {
        includes: ["pack", "access"]
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.pack").service("packSubscriptionService", PackSubscriptionService);