"use strict";
/**
 * Controller to manage categories.
 *
 * @param {object} $scope - Controller scope.
 * @param {object} $state - Module state.
 * @param {object} $stateParams - State parameters.
 * @param {object} $mdDialog - Material design dialog.
 * @param {object} $interval - Interval service.
 * @param {object} categoryService - Category service.
 * @param {object} notifyUserService - User notification service.
 */

CategoryController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "$interval", "categoryService", "notifyUserService"];

function CategoryController($scope, $state, $stateParams, $mdDialog, $interval, categoryService, notifyUserService) {
  /**
   * Declaration of public objects
   */
  $scope.heightDiv = angular.element("#categoryContainer")[0].offsetHeight - 70;
  $scope.data = [];
  $scope.listItems = [];
  $scope.state = {
    count_is_not_indexed: null
  };
  $scope.notIndexedTotal = 0;
  $scope.selectedTab = $stateParams.type === "product" ? 0 : 1;
  $scope.importConfig = {
    entityName: "LaboratoryCategory" + $stateParams.type,
    callbackEvent: "addToCategoryFromImport",
    allowChunks: false
  };
  $scope.exportConfig = {
    entityName: "LaboratoryCategory" + $stateParams.type,
    fileType: "xlsx",
    fileName: "Export_Categories_" + $stateParams.type,
    availableColumns: {},
    defaultFilter: {
      type: $stateParams.type
    }
  };

  if ($stateParams.type === "product") {
    $scope.exportConfig.availableColumns = {
      error: "Erreur",
      id_7: "Produit (CIP 7)",
      id_13: "Produit (CIP 13)",
      ean_13: "Produit (EAN)",
      nameApodis: "Libellé du produit dans Apodis",
      nameImport: "Libellé du produit lors de l'importation",
      category1: "Catégorie niveau 1",
      category2: "Catégorie niveau 2",
      category3: "Catégorie niveau 3",
      category4: "Catégorie niveau 4",
      category5: "Catégorie niveau 5"
    };
  } else {
    $scope.exportConfig.availableColumns = {
      error: "Erreur",
      cip: "CIP",
      nameApodis: "Libellé de la pharmacie dans Apodis",
      nameImport: "Libellé de la pharmacie de l'importation",
      category1: "Catégorie niveau 1",
      category2: "Catégorie niveau 2",
      category3: "Catégorie niveau 3",
      category4: "Catégorie niveau 4",
      category5: "Catégorie niveau 5"
    };
  }

  $scope.button = {
    save: false
  };
  $scope.treeOptions = {};
  var refreshState = undefined;
  refreshState = getRefreshState();
  /**
   * Declaration of public methods
   */

  $scope.getLength = getLength;
  $scope.getLengthError = getLengthError;
  $scope.getLengthIndexed = getLengthIndexed;
  $scope.getCategories = getCategories;
  $scope.removeItem = removeItem;
  $scope.toggleItem = toggleItem;
  $scope.newSubItem = newSubItem;
  $scope.dropItem = dropItem;
  $scope.allowDropItem = allowDropItem;
  $scope.importData = importData;
  $scope.saveData = saveData;
  $scope.showItems = showItems;
  $scope.categoryType = categoryType;
  loader();
  /**
   * loader
   */

  function loader() {
    getCategories();
  }
  /**
   * getLength
   *
   * @param {object} nodes - Nodes.
   * @param {number} currentTotal - Current total of nodes.
   *
   * @returns {number} Nodes length.
   */


  function getLength(nodes, currentTotal) {
    var total = !currentTotal ? 0 : currentTotal;
    nodes["nodes_" + $stateParams.type].forEach(function (nodeChildren) {
      if (nodeChildren["nodes_" + $stateParams.type].length > 0) {
        total += nodeChildren[$stateParams.type + "s"].length;
        total = getLength(nodeChildren, total);
      } else {
        total += nodeChildren[$stateParams.type + "s"].length;
      }

      return total;
    });
    total += nodes[$stateParams.type + "s"].length;
    return total;
  }
  /**
   * getLengthError
   *
   * @param {object} nodes - Nodes.
   * @param {number} currentTotal - Current total.
   *
   * @returns {number} Error nodes length.
   */


  function getLengthError(nodes, currentTotal) {
    var total = !currentTotal ? 0 : currentTotal;
    nodes["nodes_" + $stateParams.type].forEach(function (nodeChildren) {
      if (nodeChildren["nodes_" + $stateParams.type].length > 0) {
        nodeChildren[$stateParams.type + "s"].forEach(function (item) {
          if (!item[$stateParams.type]) {
            total += 1;
          }
        });
        total = getLengthError(nodeChildren, total);
      } else {
        nodeChildren[$stateParams.type + "s"].forEach(function (item) {
          if (!item[$stateParams.type]) {
            total += 1;
          }
        });
      }

      return total;
    });
    nodes[$stateParams.type + "s"].forEach(function (item) {
      if (!item[$stateParams.type]) {
        total += 1;
      }
    });
    return total;
  }
  /**
   * getLengthIndexed
   *
   * @param {object} nodes - Nodes.
   * @param {number} currentTotal - Current total.
   *
   * @returns {number} Length of index nodes.
   */


  function getLengthIndexed(nodes, currentTotal) {
    var total = !currentTotal ? 0 : currentTotal;
    nodes["nodes_" + $stateParams.type].forEach(function (nodeChildren) {
      if (nodeChildren["nodes_" + $stateParams.type].length > 0) {
        nodeChildren[$stateParams.type + "s"].forEach(function (item) {
          if (item[$stateParams.type] && !item.is_indexed) {
            total += 1;
          }
        });
        total = getLengthIndexed(nodeChildren, total);
      } else {
        nodeChildren[$stateParams.type + "s"].forEach(function (item) {
          if (item[$stateParams.type] && !item.is_indexed) {
            total += 1;
          }
        });
      }

      return total;
    });
    nodes[$stateParams.type + "s"].forEach(function (item) {
      if (item[$stateParams.type] && !item.is_indexed) {
        total += 1;
      }
    });
    return total;
  }
  /**
   * removeItem
   *
   * @param {object} node - Node to remove.
   */


  function removeItem(node) {
    node.remove();
  }
  /**
   * toggleItem
   *
   * @param {object} node - node to toggle.
   */


  function toggleItem(node) {
    node.toggle();
  }
  /**
   * newSubItem
   *
   * @param {object} node - Parent node of new sub item..
   */


  function newSubItem(node) {
    $scope.button.save = true;
    var nodeData = node.$modelValue;
    var defaultNode = {
      id: nodeData.id * 10 + nodeData["nodes_" + $stateParams.type].length,
      name: nodeData.name + "." + (nodeData["nodes_" + $stateParams.type].length + 1)
    };
    defaultNode["nodes_" + $stateParams.type] = [];
    nodeData["nodes_" + $stateParams.type].push(defaultNode);
  }
  /**
   * dropItem
   *
   * @param {object} event - Triggered event.
   */


  function dropItem(event) {
    event.preventDefault();
  }
  /**
   * allowDropItem
   *
   * @param {object} event - Triggered event.
   */


  function allowDropItem(event) {
    event.preventDefault();
  }
  /**
   * importData
   *
   * @param {object} data - Data to import.
   *
   * @returns {Array} Imported data.
   */


  function importData(data) {
    return [createTree(data)];
  }
  /**
   * createNode
   *
   * @param {string} name - Node name.
   * @param {object} category - Node category.
   *
   * @returns {object} Created node.
   */


  function createNode(name, category) {
    var categoryNew = category.find(function (item) {
      return item.name.trim().toLowerCase() === name.trim().toLowerCase();
    });

    if (!categoryNew) {
      var defaultDocument = {
        name: name
      };
      defaultDocument["nodes_" + $stateParams.type] = [];
      defaultDocument[$stateParams.type + "s"] = [];
      categoryNew = category[category.push(defaultDocument) - 1];
    }

    return categoryNew;
  }
  /**
   * extractCategories
   *
   * @param {object} item - Item to extract.
   *
   * @returns {Array} Extracted categories.
   */


  function extractCategories(item) {
    var categories = [];
    angular.forEach(Object.keys(item).sort(), function (name) {
      var categoryName = name.match(/category[1-9]/g);

      if (categoryName && item[name] && item[name].toString().trim() !== "") {
        categories.push({
          level: name,
          name: item[name].toString().trim()
        });
      }
    });

    if (categories.length === 0) {
      categories.push({
        level: "category1",
        name: "Aucune catégorie"
      });
    }

    return categories;
  }
  /**
   * createTree
   *
   * @param {object} items - Tree items.
   *
   * @returns {object} Created tree.
   */


  function createTree(items) {
    var level0 = {
      name: "Mes catégories"
    };
    level0["nodes_" + $stateParams.type] = [];
    level0[$stateParams.type + "s"] = [];
    angular.forEach(items, function (item) {
      var levelTmp = level0;
      var categories = extractCategories(item);
      var categoriesLength = categories.length - 1;
      angular.forEach(categories, function (category, index) {
        levelTmp = createNode(category.name, levelTmp["nodes_" + $stateParams.type]);

        if (index === categoriesLength) {
          var defaultItem = {};

          if ($stateParams.type === "product") {
            defaultItem[$stateParams.type] = {
              name: item.name,
              id: item.id,
              id_7: item.id_7,
              id_13: item.id_13,
              ean_13: item.ean_13
            };
          } else {
            defaultItem[$stateParams.type] = {
              name: item.name,
              id: item.id,
              cip: item.cip
            };
          }

          levelTmp[$stateParams.type + "s"].push(defaultItem);
        }
      });
    });
    return level0;
  }
  /**
   * saveData
   */


  function saveData() {
    $scope.button.save = false;
    $scope.loaderCategories = {
      loading: true,
      loader: "Veuillez patienter pendant l'enregistrement.",
      empty: "Aucune catégorie trouvée"
    };
    $scope.data.type = $stateParams.type;
    categoryService.update($scope.data).then(function (response) {
      $scope.data = response;
      $scope.notIndexedTotal = getLengthIndexed($scope.data[0], 0);
      $scope.loaderCategories.loading = false;
      $scope.state.count_is_not_indexed = null;
      getRefreshState();
    }, function () {
      $scope.loaderCategories.loading = false;
      notifyUserService.showDangerToast("Erreur lors de la mise à jour, veuillez réessayer.");
    });
  }
  /**
   * showItems
   *
   * @param {object} node - Node to show.
   */


  function showItems(node) {
    $scope.query = {
      order: "name",
      limit: 10,
      page: 1
    };
    $scope.categorySelected = node.name;
    $scope.listItems = node[$stateParams.type + "s"];
  }
  /**
   * categoryType
   *
   * @param {string} type - Category type.
   */


  function categoryType(type) {
    if ($scope.button.save) {
      $mdDialog.show({
        templateUrl: "app/main/category/views/dialogSaveAs.tmpl.html",
        clickOutsideToClose: true,
        locals: {
          type: type
        },
        controller: ["type", "$scope", function (type, $scope) {
          $scope.save = function () {
            $mdDialog.hide();
            saveData();
            $state.go("app.categories." + type, {
              type: type
            });
          };

          $scope.exit = function () {
            $state.go("app.categories." + type, {
              type: type
            });
            $mdDialog.hide();
          };
        }]
      });
    } else {
      $state.go("app.categories." + type, {
        type: type
      });
    }
  }
  /**
   * getCategories
   *
   * @returns {Promise} Categories promise.
   */


  function getCategories() {
    $scope.loaderCategories = {
      loading: true,
      loader: "Récupération des catégories",
      empty: "Aucune catégorie trouvée"
    };
    $scope.button.save = false;
    $scope.data = [];
    return categoryService.get({
      type: $stateParams.type
    }).then(function (response) {
      $scope.state.count_is_not_indexed = null;
      getState();
      $scope.data = response;

      if ($scope.data[0]) {
        $scope.notIndexedTotal = getLengthIndexed($scope.data[0], 0);
      }

      $scope.loaderCategories.loading = false;
    });
  }
  /**
   * getRefreshState
   *
   * @returns {Promise} Refresh state promise.
   */


  function getRefreshState() {
    $interval.cancel(refreshState);
    return $interval(getState, 5000);
  }
  /**
   * getState
   */


  function getState() {
    if ($scope.state.count_is_not_indexed !== 0) {
      categoryService.state({
        type: $stateParams.type
      }).then(function (response) {
        $scope.state = response;
      });
    }
  }
  /**
   * addToCategoryFromImport
   */


  $scope.$on("addToCategoryFromImport", function (event, items) {
    $scope.loaderCategories = {
      loading: true,
      loader: "Veuillez patienter pendant l'importation des données.",
      empty: "Aucune catégorie trouvée"
    };
    $scope.data = [];
    $scope.data = importData(items);
    $scope.notIndexedTotal = getLengthIndexed($scope.data[0], 0);
    $scope.button.save = true;
    $scope.loaderCategories.loading = true;
  });
  /**
   * listen on DOM destroy (removal) event, and cancel the next UI update
   * to prevent updating time after the DOM element was removed.
   */

  $scope.$on("$destroy", function () {
    $interval.cancel(refreshState);
  });
}

angular.module("app.category").controller("categoryController", CategoryController);