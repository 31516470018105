"use strict";
/**
 * Patient search controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} patientsSearchService - Patients search service.
 */

PatientSearchController.$inject = ["$scope", "$mdDialog", "patientsSearchService"];

function PatientSearchController($scope, $mdDialog, patientsSearchService) {
  $scope.addFilter = addFilter;
  $scope.removeFilter = removeFilter;
  $scope.submit = submit;
  $scope.total = 0;
  $scope.loading = false;
  $scope.filters = [];
  $scope.availableFilters = [{
    label: "Sexe",
    value: "gender"
  }, {
    label: "Âge",
    value: "age"
  }, {
    label: "Achats",
    value: "purchase"
  }];
  $scope.$watch("filters", function () {
    submit();
  }, true);
  /**
   * Add a filter.
   *
   * @param {string} name - The name of the filter to add.
   */

  function addFilter(name) {
    var data = prepareFilterData(name);
    $scope.filters.push({
      name: name,
      isEnabled: true,
      data: data
    });
    $scope.filter = null;
  }
  /**
   * Remove a filter.
   *
   * @param {number} index - The index of the filter to remove.
   */


  function removeFilter(index) {
    var confirm = $mdDialog.confirm().title("Confirmez-vous la suppression du filtre ?").ariaLabel("Supprimer un filtre").ok("Oui").cancel("Non");
    $mdDialog.show(confirm).then(function () {
      $scope.filters.splice(index, 1);
    });
  }
  /** Submit. */


  function submit() {
    $scope.loading = true;
    patientsSearchService.search({
      data: $scope.filters
    }).then(function (response) {
      if (response.error) {
        $mdDialog.show($mdDialog.alert().title("Erreur").textContent(response.error).ariaLabel("Erreur").ok("Fermer"));
      } else {
        $scope.patientLimit = response.meta.patient.limit;
        $scope.patientCount = response.meta.patient.count;
        $scope.healthcenterCount = response.meta.healthcenter.count;
        $scope.loading = false;
      }
    });
  }
  /**
   * Prepare filter data.
   *
   * @param {string} name - The name of the filter to prepare.
   *
   * @returns {object} The prepared data.
   */


  function prepareFilterData(name) {
    switch (name) {
      case "gender":
        return {
          value: null
        };

      case "age":
        return {
          from: {
            value: null,
            type: "year"
          },
          to: {
            value: null,
            type: "year"
          }
        };

      case "purchase":
        return {
          values: []
        };
    }

    return {};
  }
}

angular.module("app.patients-search").controller("patientSearchController", PatientSearchController);