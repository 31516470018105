"use strict";
/**
 * The sellin challenge service.
 *
 * @param {object} apiClient - API client.
 * @param {object} $q - $q.
 *
 * @returns {object}
 */

SellinChallengeService.$inject = ["apiClient", "$q"];

function SellinChallengeService(apiClient, $q) {
  var SELLIN_STEP_TYPES = {
    BY_QUANTITY: 1,
    BY_AMOUNT: 2
  };
  return {
    /**
     * Create a sellin challenge.
     *
     * @param {object} data - The sellin challenge.
     *
     * @returns {Promise}
     */
    create: function create(data) {
      var query = {
        includes: ["groups", "groups.products", "organization.morphable", "targets.target.morphable", "steps.stepGroups", "steps.stepProducts"]
      };
      return apiClient.post("/sellin-challenges", {
        data: data
      }, query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Duplicate a sellin.
     *
     * @param {number} sellinChallengeId - sellinChallengeId
     * @returns {Promise}
     */
    duplicate: function duplicate(sellinChallengeId) {
      return apiClient.post("/sellin-challenges/".concat(sellinChallengeId, "/duplicate")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a sellin challenge.
     *
     * @param {object} data - The sellin challenge.
     *
     * @returns {Promise}
     */
    read: function read(data) {
      var query = {
        includes: ["groups", "groups.products", "organization.morphable", "targets.target.morphable", "targets.target.morphable.grouping", "targets.target.morphable.healthcenters", "steps.stepGroups", "steps.stepProducts"],
        data: data
      };
      return apiClient.get("/sellin-challenges/".concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a sellin challenge.
     *
     * @param {object} data - The sellin challenge.
     *
     * @returns {Promise}
     */
    update: function update(data) {
      var query = {
        includes: ["groups", "groups.products", "organization.morphable", "targets.target.morphable", "steps.stepGroups", "steps.stepProducts"]
      };
      return apiClient.put("/sellin-challenges/".concat(data.id), {
        data: data
      }, query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a sellin challenge.
     *
     * @param {object} data - The sellin challenge.
     *
     * @returns {Promise}
     */
    "delete": function _delete(data) {
      return apiClient["delete"]("/sellin-challenges/".concat(data.id)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Negotiate a sellin challenge.
     *
     * @param {object} data - The sellin challenge.
     *
     * @returns {Promise}
     */
    negotiate: function negotiate(data) {
      return apiClient.put("/sellin-challenges/".concat(data.id, "/negotiate"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Accept a sellin challenge.
     *
     * @param {object} data - The sellin challenge.
     *
     * @returns {Promise}
     */
    accept: function accept(data) {
      return apiClient.put("/sellin-challenges/".concat(data.id, "/accept"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Refuse a sellin challenge.
     *
     * @param {object} data - The sellin challenge.
     *
     * @returns {Promise}
     */
    refuse: function refuse(data) {
      return apiClient.put("/sellin-challenges/".concat(data.id, "/refuse"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Publish a sellin challenge.
     *
     * @param {object} data - The sellin challenge.
     *
     * @returns {Promise}
     */
    publish: function publish(data) {
      var query = {
        includes: ["groups", "groups.products", "organization.morphable", "targets.target.morphable", "steps.stepGroups", "steps.stepProducts"],
        data: data
      };
      return apiClient.put("/sellin-challenges/".concat(data.id, "/publish"), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read for negotiation a sellin challenge.
     *
     * @param {object} data - The sellin challenge.
     *
     * @returns {Promise}
     */
    readForNegotiation: function readForNegotiation(data) {
      var query = {
        includes: ["targets.target.healthcenters", "stepHistories", "stepHistories.organization", "stepHistories.organization.morphable"]
      };
      return apiClient.get("/sellin-challenges/".concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the current sellin challenges.
     *
     * @returns {Promise}
     */
    currents: function currents() {
      var query = {
        includes: ["organization.morphable"],
        isPrefixed: true
      };
      return apiClient.get("/sellin-challenges", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the to do sellin challenges.
     *
     * @returns {Promise}
     */
    toDo: function toDo() {
      var query = {
        includes: ["organization.morphable"],
        isPrefixed: true
      };
      return apiClient.get("/sellin-challenges/todo", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the awaiting sellin challenges.
     *
     * @returns {Promise}
     */
    getAwaiting: function getAwaiting() {
      var query = {
        includes: ["organization.morphable"],
        isPrefixed: true
      };
      return apiClient.get("/sellin-challenges/awaiting", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the future sellin challenges.
     *
     * @returns {Promise}
     */
    future: function future() {
      var query = {
        includes: ["organization.morphable"],
        isPrefixed: true
      };
      return apiClient.get("/sellin-challenges/future", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the accepted sellin challenges.
     *
     * @returns {Promise}
     */
    accepted: function accepted() {
      var query = {
        includes: ["organization.morphable"],
        isPrefixed: true
      };
      return apiClient.get("/sellin-challenges/accepted", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the refused sellin challenges.
     *
     * @returns {Promise}
     */
    refused: function refused() {
      var query = {
        includes: ["organization.morphable"],
        isPrefixed: true
      };
      return apiClient.get("/sellin-challenges/refused", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the finished sellin challenges.
     *
     * @returns {Promise}
     */
    finished: function finished() {
      var query = {
        includes: ["organization.morphable"],
        isPrefixed: true
      };
      return apiClient.get("/sellin-challenges/past", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get sales by sellin challenge ID.
     *
     * @param {object} data - The data.
     * @param {string} additionalInclude - Additional include.
     *
     * @returns {Promise}
     */
    getSalesBySellinChallengeId: function getSalesBySellinChallengeId(data, additionalInclude) {
      var query = {
        includes: []
      };

      if (additionalInclude !== "") {
        query.includes.push(additionalInclude);
      }

      return apiClient.get("/wholesalers/sales/sellin-challenges/".concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get sales evolution by sellin challenge ID.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getSalesEvolutionBySellinChallengeId: function getSalesEvolutionBySellinChallengeId(data) {
      return apiClient.get("/wholesalers/sales/evolution/sellin-challenges/".concat(data.id)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get price for healthcenters and products.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getPriceForHealthcentersAndProducts: function getPriceForHealthcentersAndProducts(data) {
      return apiClient.post("/healthcenters/getPriceForHealthcentersAndProducts", {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Count grouping awaiting sellins.
     *
     * @returns {Promise}
     */
    countGroupingAwaitingSellins: function countGroupingAwaitingSellins() {
      return apiClient.get("/grouping/sellin-challenges/count-awaiting").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get healthcenter current sellins.
     *
     * @param {object} healthcenter - Healthcenter.
     *
     * @returns {Promise}
     */
    getHealthcenterCurrentSellins: function getHealthcenterCurrentSellins(healthcenter) {
      var query = {
        isPrefixed: true
      };
      return apiClient.get("/sellin-challenges/current/healthcenters/".concat(healthcenter.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get healthcenter sellins progress.
     *
     * @param {object} healthcenter - Healthcenter.
     * @param {Array} sellinIds - Sellin IDs.
     *
     * @returns {Promise}
     */
    getHealthcenterSellinsProgress: function getHealthcenterSellinsProgress(healthcenter, sellinIds) {
      return apiClient.post("/sellin-challenges/healthcenters/".concat(healthcenter.id, "/progress"), {
        sellinIds: sellinIds
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get sellin progress.
     *
     * @param {object} sellin - Sellin.
     *
     * @returns {Promise}
     */
    getSellinProgress: function getSellinProgress(sellin) {
      return apiClient.get("/sellin-challenges/".concat(sellin.id, "/progress")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get sellin healthcenter accepted target.
     *
     * @param {object} sellin - Sellin.
     *
     * @returns {Promise}
     */
    getSellinHealthcenterAcceptedTargets: function getSellinHealthcenterAcceptedTargets(sellin) {
      return apiClient.get("/sellin-challenges/".concat(sellin.id, "/healthcenter-targets")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search sellins by filters.
     *
     * @param {object} filters - Filters to apply.
     * @param {number} page - Page.
     *
     * @returns {object}
     */
    search: function search(filters, page) {
      var canceller = $q.defer();
      var promise = apiClient.post("/sellin-challenges/search", {
        filters: filters,
        page: page
      }, {
        timeout: canceller.promise,
        includes: ["targets"]
      }).then(function (response) {
        return {
          sellins: response.data,
          pagination: response.meta.pagination
        };
      });
      return {
        promise: promise,
        cancel: function cancel() {
          canceller.resolve();
        }
      };
    },

    /**
     * Get sellins search options.
     *
     * @param {object} filters - Filters to apply.
     *
     * @returns {object}
     */
    getSearchOptions: function getSearchOptions(filters) {
      var canceller = $q.defer();
      var promise = apiClient.post("/sellin-challenges/search-options", {
        filters: filters
      }, {
        timeout: canceller.promise
      }).then(function (response) {
        return response.data;
      });
      return {
        promise: promise,
        cancel: function cancel() {
          canceller.resolve();
        }
      };
    },

    /**
     * Get sellin step targets.
     *
     * @param {object} sellin - Sellin.
     *
     * @returns {Promise}
     */
    getSellinStepTargets: function getSellinStepTargets(sellin) {
      return apiClient.get("/sellin-challenges/".concat(sellin.id, "/sellin-challenge-step-targets")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Generate teaser for the steps.
     *
     * @param {object} step - The step.
     *
     * @returns {string} The teaser.
     */
    generateStepTeaser: function generateStepTeaser(step) {
      var teaser = step.step_value;

      if (step.sellin_challenge_step_type_id === 1) {
        teaser += " unité(s) achetée(s) : ";
      } else if (step.sellin_challenge_step_type_id === 2) {
        teaser += "€ d'achat : ";
      }

      if (step.sellin_challenge_step_discount_type_id === 2) {
        teaser += " + ";
      }

      teaser += Number(step.discount_value) + "% de remise";
      return teaser;
    },

    /**
     * Get step progress rate ratio.
     *
     * @param {object} step - Sellin step.
     * @param {object} progressValues - Progress quantity and amount.
     *
     * @returns {number}
     */
    getStepProgressRate: function getStepProgressRate(step, progressValues) {
      var progress = step.type.id === SELLIN_STEP_TYPES.BY_QUANTITY ? progressValues.quantity : progressValues.amount;

      if (progress >= step.stepValue) {
        return 1;
      }

      return progress / step.stepValue;
    }
  };
}

angular.module("app.sellinChallenge").service("sellinChallengeService", SellinChallengeService);