"use strict";

UsersLaboratoryConfig.$inject = ["$stateProvider"];
angular.module("app.users-laboratory", ["app.users-laboratory-edit", "app.users-laboratory.factories"]).config(UsersLaboratoryConfig);
/** @ngInject */

/**
 * Config for users laboratory.
 *
 * @param {object} $stateProvider - The angular $stateProvider object.
 */

function UsersLaboratoryConfig($stateProvider) {
  $stateProvider.state("app.usersLaboratory", {
    url: "/wholesalers/:wholesalerId/laboratoires",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory/users-laboratory/views/main/main.html",
        controller: "userLaboratoryMainController"
      }
    }
  }).state("app.usersLaboratory.edit", {
    url: "/:wholesalerLaboratoryId",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory/users-laboratory/views/edit/edit.html",
        controller: "userLaboratoryEditController"
      }
    }
  }).state("app.usersLaboratory.branding", {
    url: "/:laboratoryId/branding",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory/users-laboratory/views/branding/branding.html",
        controller: "userLaboratoryBrandingController"
      }
    }
  }).state("app.usersLaboratory.branding.catalog", {
    url: "/catalog"
  }).state("app.usersLaboratory.branding.commercialOffers", {
    url: "/commercial-offers"
  }).state("app.usersLaboratory.branding.communication", {
    url: "/communication"
  }).state("app.usersLaboratory.branding.statistics", {
    url: "/statistics"
  }).state("app.usersLaboratory.branding.settings", {
    url: "/settings"
  }).state("app.usersLaboratory.preview", {
    url: "/:laboratoryId/preview",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory/users-laboratory/views/details/details.html",
        controller: "usersLaboratoryDetailsController"
      }
    },
    params: {
      laboratory: null
    },
    bodyClass: "laboratoryContent"
  });
}