"use strict";
/**
 * Dashboard group top healthcenter controller.
 *
 * @param {object} $scope - $scope.
 * @param {string} apiurl - API URL.
 * @param {object} sessionStorageService - Session storage service.
 */

dashboardGroupTopHealthcenterController.$inject = ["$scope", "apiurl", "sessionStorageService"];

function dashboardGroupTopHealthcenterController($scope, apiurl, sessionStorageService) {
  $scope.loading = true;
  var token = sessionStorageService.getToken();
  initializeTopHealthcentersChart();
  /** Initialize top healthcenters chart. */

  function initializeTopHealthcentersChart() {
    $scope.topHealthcenters = {
      type: "serial",
      theme: "light",
      dataLoader: {
        url: apiurl + "/grouping/dashboard/healthcenters/top?token=" + token,
        showCurtain: false,
        postProcess: function postProcess(data) {
          $scope.loading = false;
          return data.healthcenters;
        }
      },
      valueAxes: [{
        gridColor: "#FFFFFF",
        gridAlpha: 0.2,
        dashLength: 0
      }],
      gridAboveGraphs: true,
      startDuration: 1,
      graphs: [{
        balloonFunction: function balloonFunction(graphDataItem) {
          return "CA HT : " + d3.format(".2s")(graphDataItem.values.value) + " €";
        },
        fillAlphas: 0.8,
        lineAlpha: 0.2,
        type: "column",
        valueField: "amount"
      }],
      chartCursor: {
        categoryBalloonEnabled: false,
        cursorAlpha: 0,
        zoomable: false
      },
      categoryField: "name",
      categoryAxis: {
        gridPosition: "start",
        gridAlpha: 0,
        tickPosition: "start",
        tickLength: 20,
        autoWrap: true
      },
      responsive: {
        enabled: true
      }
    };
  }
}

angular.module("app.dashboard").controller("dashboardGroupTopHealthcenterController", dashboardGroupTopHealthcenterController);