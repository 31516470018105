"use strict";
/**
 * Dashboard laboratories stocks state chart controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} StocksQueryService - Stocks query service.
 * @param {object} labSerial - Laboratory serial chart configuration.
 * @param {object} analysisService - Analysis service.
 */

DashboardLaboratoriesStocksStateChartController.$inject = ["$scope", "environmentService", "StocksQueryService", "labSerial", "analysisService"];

function DashboardLaboratoriesStocksStateChartController($scope, environmentService, StocksQueryService, labSerial, analysisService) {
  var groups = [{
    value: 0,
    title: "Pharmacies"
  }, {
    value: 9,
    title: "Produits"
  }];
  var laboratoryId = 0;
  $scope.loading = true;
  $scope.amChartOptionsSerialHealthcenters = angular.copy(labSerial);
  $scope.amChartOptionsSerialProducts = angular.copy(labSerial);
  /**
   * Loader.
   */

  function loader() {
    environmentService.getEnvironment().then(function (environment) {
      laboratoryId = environment.entityId;
      var stockQuery = StocksQueryService.buildQuery(StocksQueryService.query);
      launchSearch(stockQuery, groups[0].value, $scope.amChartOptionsSerialHealthcenters);
      launchSearch(stockQuery, groups[1].value, $scope.amChartOptionsSerialProducts);
    });
  }
  /**
   * Launch search.
   *
   * @param {object} query -
   * @param {number} group -
   * @param {object} amChartOptions -
   */


  function launchSearch() {
    var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var group = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var amChartOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    analysisService.getLaboratoryStockByGroup(laboratoryId, group, query).then(function (response) {
      processDataForAmCharts(amChartOptions, response.aggregations);
      $scope.loading = false;
    });
  }
  /**
   * Process data for AmCharts.
   *
   * @param {object} amChartOptions - AmCharts options.
   * @param {object} data - Data.
   */


  function processDataForAmCharts(amChartOptions, data) {
    var dataChartSerial = [];
    angular.forEach(data.datas.buckets, function (dataHc) {
      var id = dataHc.id.buckets[0].key;
      var sumOrderAmount = 0;
      var serialLine = {
        healthcenter: dataHc.key,
        id: id,
        range1: 0,
        range1Pc: 0,
        range2: 0,
        range3: 0,
        range4: 0,
        range5: 0,
        range6: 0,
        range7: 0
      };
      angular.forEach(dataHc.range_days.buckets, function (dataRange) {
        var nbRange = dataRange.key + 1;
        var strRange = "range" + nbRange;

        if (nbRange < 4) {
          sumOrderAmount += dataRange.needs_amount.value;
          serialLine[strRange] = dataRange.needs_amount.value;
        } else {
          sumOrderAmount += dataRange.order_amount.value;
          serialLine[strRange] = dataRange.order_amount.value;
        }
      });

      if (sumOrderAmount !== 0) {
        serialLine.range1Pc = serialLine.range1 / sumOrderAmount * 100;
      }

      dataChartSerial.push(serialLine);
    });
    dataChartSerial.sort(function (left, right) {
      return left.range1 - right.range1;
    });
    dataChartSerial.reverse();
    amChartOptions.data = dataChartSerial.slice(0, 10);
  }

  loader();
}

angular.module("app.dashboard").controller("dashboardLaboratoriesStocksStateChartController", DashboardLaboratoriesStocksStateChartController);