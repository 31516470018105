"use strict";
/**
 * Cumulated purchases controller.
 *
 * @param {object} $scope - Scope.
 * @param {object} groupingDashboardCapService - Grouping Dashboard cap service.
 */

CumulatedPurchasesChartController.$inject = ["$scope", "groupingDashboardCapService"];

function CumulatedPurchasesChartController($scope, groupingDashboardCapService) {
  $scope.currentYear = new Date().getFullYear();
  $scope.chart = {
    type: "serial",
    theme: "light",
    valueAxes: [{
      gridColor: "#000000",
      gridAlpha: 0.2,
      dashLength: 0
    }],
    gridAboveGraphs: true,
    startDuration: 1,
    graphs: [{
      balloonFunction: function balloonFunction(graphDataItem) {
        var value = graphDataItem.values.value.toFixed(2);
        return "CA HT : ".concat(value, " \u20AC");
      },
      fillAlphas: 0.8,
      lineAlpha: 0.2,
      type: "column",
      valueField: "amount"
    }],
    categoryAxis: {
      parseDates: true,
      dateFormats: [{
        period: "MM",
        format: "MMM YYYY"
      }, {
        period: "YYYY",
        format: "MMM YYYY"
      }],
      minPeriod: "MM"
    },
    categoryField: "month"
  };
  groupingDashboardCapService.getCumulatedPurchasesByMonth().then(function (data) {
    $scope.chart.dataProvider = buildCumulatedPurchasesChartData(data);
  });
  /**
   * Build cumulated purchases chart data.
   *
   * @param {object} cumulatedPurchases - Cumulated purchases amount by month.
   *
   * @returns {object}
   */

  function buildCumulatedPurchasesChartData(cumulatedPurchases) {
    var chartData = [];

    for (var month in cumulatedPurchases) {
      if (Object.prototype.hasOwnProperty.call(cumulatedPurchases, month)) {
        chartData.push({
          month: new Date().setMonth(month - 1),
          amount: cumulatedPurchases[month].total_amount_after_discount
        });
      }
    }

    return chartData;
  }
}

angular.module("app.dashboard").controller("cumulatedPurchasesChartController", CumulatedPurchasesChartController);