"use strict";
/**
 * Sellin offer edit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdToast - $mdToast.
 * @param {object} $rootScope - $rootScope.
 * @param {object} sessionStorageService - Session storage service.
 * @param {object} sellinOfferService - Sellin offer service.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 * @param {object} productService - Product service.
 * @param {object} healthcenterService - Healthcenter service.
 */

SellinOfferEditController.$inject = ["$scope", "$state", "$stateParams", "$mdToast", "$rootScope", "sessionStorageService", "sellinOfferService", "environmentService", "analysisService", "productService", "healthcenterService"];

function SellinOfferEditController($scope, $state, $stateParams, $mdToast, $rootScope, sessionStorageService, sellinOfferService, environmentService, analysisService, productService, healthcenterService) {
  $scope.token = sessionStorageService.getToken();
  $scope.select = false;
  $scope.laboratory = {
    id: 0,
    name: ""
  };
  $scope.wholesalerId = null;
  $scope.titleConfig = {
    title: {
      icon: "icon-cart-fill",
      text: "...",
      back: {
        state: "app.sellinOffer",
        tooltip: "Retour à mes offres sell-in"
      }
    }
  }; // Laboratory search config

  $scope.laboratorySearchConfig = {
    event: "addLaboratorySellinOffer",
    search: ["laboratory.id"],
    tag: "laboratorySellinOffer",
    limits: {
      laboratory: 1
    }
  }; // Product search config

  $scope.productSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    event: "addProductsInSellinOffer",
    tag: "configProductSellinOffer",
    autoLaunchEvent: true,
    must: {
      "laboratory.id": []
    }
  }; // Target search config

  $scope.targetSearchConfig = {
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id", "standaloneGrouping"],
    event: "addTargetsInSellinOffer",
    tag: "target",
    autoLaunchEvent: true
  }; // Durations

  $scope.durations = [{
    id: 1,
    name: "1 mois",
    number: 1,
    type: "months"
  }, {
    id: 2,
    name: "2 mois",
    number: 2,
    type: "months"
  }, {
    id: 3,
    name: "3 mois",
    number: 3,
    type: "months"
  }, {
    id: 4,
    name: "6 mois",
    number: 6,
    type: "months"
  }, {
    id: 5,
    name: "1 an",
    number: 1,
    type: "years"
  }, {
    id: 6,
    name: "Permanent",
    number: 50,
    type: "years"
  }];
  $scope.defaultPaymentTermDays = 30; // SellinOffer

  $scope.sellinOffer = {
    name: "",
    description: "",
    wholesalerId: null,
    file: {},
    startDate: "",
    paymentTermDays: $scope.defaultPaymentTermDays,
    isQuantityAlterable: false,
    isForApodis: true,
    isForWholesalers: false,
    endDate: "",
    products: [],
    targets: [],
    duration: $scope.durations[0]
  };

  if ($stateParams.wholesalerId) {
    $scope.sellinOffer.wholesalerId = $stateParams.wholesalerId;
  }

  $scope.query = {
    order: "name",
    limit: 10,
    page: 1
  };
  $scope.goToList = goToList;
  $scope.isValidStep1 = false;
  $scope.isValidStep2 = false;
  $scope.isValidStep3 = false;
  $scope.isValidStep4 = false;
  $scope.sellinOfferId = $stateParams.id;
  $scope.getSellinOffer = getSellinOffer;
  $scope.createSellinOffer = createSellinOffer;
  $scope.updateSellinOffer = updateSellinOffer;
  $scope.setDefaultPeriod = setDefaultPeriod;
  $scope.calculateEndDate = calculateEndDate;
  $scope.products = [];
  $scope.removeProduct = removeProduct; // Targets

  $scope.targets = [];
  $scope.removeTarget = removeTarget;
  $scope.targetDatatable = {
    limit: 10,
    page: 1
  };
  $scope.submit = submit;
  $scope.calculatePricesAndTotal = calculatePricesAndTotal;
  $scope.getRecommendationsForSellinOfferProducts = getRecommendationsForSellinOfferProducts; // Total of products

  $scope.quantityOfProducts = 0;
  $scope.turnoverAmount = 0;
  $scope.quantityByProductType = [];
  $scope.turnoverAmountByProductType = [];
  loader();
  /** Initialize */

  function loader() {
    setDefaultPeriod();
    getSellinOffer();
    loaderEnvironment();
  }
  /**
   * Loader environment.
   *
   * @returns {Promise}
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.access = access;

      if ($scope.access.accessPointType === 1) {
        $scope.productSearchConfig.must = {
          "laboratory.id": [access.entityId]
        };
      } // Filter by wholesaler if necessary


      if ($stateParams.wholesalerId) {
        $scope.productSearchConfig.must["wholesaler.id"] = [$stateParams.wholesalerId];
        $scope.wholesalerId = $stateParams.wholesalerId;
      }

      return access;
    });
  }
  /**
   * Get the sellin offer.
   */


  function getSellinOffer() {
    if ($stateParams.id) {
      var sellinOffer = {
        id: $stateParams.id
      };
      sellinOfferService.read(sellinOffer).then(function (response) {
        var sellinOffer = response;
        var products = [];
        var targets = [];

        if (sellinOffer.entityTypeId !== 1) {
          $scope.laboratory = {
            id: sellinOffer.laboratory.id,
            name: sellinOffer.laboratory.name
          };
        }

        sellinOffer.products.forEach(function (element) {
          if (element.product) {
            products.push({
              id: element.product.id,
              cip: element.product.code,
              name: element.product.name,
              quantity: element.quantity,
              pfht: element.pfht,
              percentageOfDiscount: element.percentageOfDiscount,
              priceAfterDiscountWithoutVat: element.priceAfterDiscountWithoutVat,
              totalPriceAfterDiscountWithoutVat: element.totalPriceAfterDiscountWithoutVat
            });
          }
        });
        sellinOffer.targets.forEach(function (element) {
          targets.push({
            id: element.entityId,
            name: element.target.name,
            cip: element.target.cip ? element.target.cip : null,
            entityTypeId: element.entityTypeId
          });
        }); // Filter on wholesaler if defined

        if (sellinOffer.wholesalerId) {
          $scope.productSearchConfig.must["wholesaler.id"] = [sellinOffer.wholesalerId];
        }

        $scope.sellinOffer = {
          name: sellinOffer.name,
          description: sellinOffer.description,
          wholesalerId: sellinOffer.wholesalerId,
          file: sellinOffer.file,
          isPerPharmacy: sellinOffer.isPerPharmacy,
          isQuantityAlterable: sellinOffer.isQuantityAlterable,
          isForApodis: sellinOffer.isForApodis,
          isForWholesalers: sellinOffer.isForWholesalers,
          paymentTermDays: sellinOffer.paymentTermDays,
          startDate: new Date(sellinOffer.startDate),
          endDate: new Date(sellinOffer.endDate),
          products: products,
          targets: targets
        };
        $scope.titleConfig.title.text = sellinOffer.name;
        calculatePricesAndTotal();
      });
    } else if ($stateParams.name) {
      // If the name and other parameters has been given
      $scope.laboratory = {
        id: $stateParams.laboratoryId,
        name: ""
      };
      $scope.sellinOffer = {
        name: $stateParams.name,
        description: $stateParams.description,
        wholesalerId: null,
        isQuantityAlterable: false,
        isForApodis: true,
        isForWholesalers: false,
        paymentTermDays: $scope.defaultPaymentTermDays,
        startDate: new Date(),
        products: $stateParams.products,
        targets: $stateParams.targets
      };
      $scope.titleConfig.title.text = $scope.sellinOffer.name;

      if ($stateParams.wholesalerId) {
        $scope.sellinOffer.wholesalerId = $stateParams.wholesalerId;
      }

      getProductsInformations();
    } else {
      $scope.titleConfig.title.text = "Création de mon offre sell-in";
    }
  }
  /**
   * Submit sellinOffer.
   *
   * @param {boolean} toBePublished - If the sellinOffer is auto published.
   */


  function submit(toBePublished) {
    var sellinOffer = formatData();
    $scope.sellinOfferId ? $scope.updateSellinOffer(sellinOffer, toBePublished) : $scope.createSellinOffer(sellinOffer, toBePublished);
  }
  /**
   * Create sellinOffer.
   *
   * @param {object} sellinOffer - The Sellin offer
   * @param {boolean} toBePublished - To be published or not
   *
   * @returns {Promise}
   */


  function createSellinOffer(sellinOffer, toBePublished) {
    if (toBePublished) {
      sellinOffer.toBePublished = true;
    }

    return sellinOfferService.create(sellinOffer).then(function () {
      $mdToast.show($mdToast.simple().textContent("Le challenge sell-in est ajouté.").position("top right"));
      goToList();
    });
  }
  /**
   * Update sellinOffer.
   *
   * @param {object} sellinOffer - The Sellin offer
   * @param {boolean} toBePublished - To be published or not
   *
   * @returns {Promise}
   */


  function updateSellinOffer(sellinOffer, toBePublished) {
    sellinOffer.id = $stateParams.id;

    if (toBePublished) {
      sellinOffer.toBePublished = true;
    }

    return sellinOfferService.update(sellinOffer).then(function (response) {
      $mdToast.show($mdToast.simple().textContent("Le challenge sell-in est modifié.").position("top right"));
      goToList();
      return response;
    });
  }
  /**
   * Format the data.
   *
   * @returns {object}
   */


  function formatData() {
    var startDate = moment($scope.sellinOffer.startDate).format("YYYY-MM-DD");
    var endDate = moment($scope.sellinOffer.endDate).format("YYYY-MM-DD");
    var products = [];
    var targets = [];
    var laboratoryId = 0;
    var wholesalerId = null;
    $scope.sellinOffer.products.forEach(function (product) {
      products.push({
        presentationId: product.id,
        quantity: product.quantity,
        pfht: product.pfht,
        percentageOfDiscount: product.percentageOfDiscount,
        priceAfterDiscountWithoutVat: product.priceAfterDiscountWithoutVat,
        totalPriceAfterDiscountWithoutVat: product.totalPriceAfterDiscountWithoutVat
      });
    });
    $scope.sellinOffer.targets.forEach(function (target) {
      targets.push({
        entityTypeId: target.entityTypeId,
        entityId: target.id,
        type: target.type
      });
    }); // We get the laboratory id

    if ($scope.access.accessPointType === 1) {
      laboratoryId = $scope.access.entityId;
    } else {
      laboratoryId = $scope.laboratory.id;
    }

    if ($scope.sellinOffer.wholesalerId) {
      wholesalerId = $scope.sellinOffer.wholesalerId;
    }

    return {
      name: $scope.sellinOffer.name,
      description: $scope.sellinOffer.description,
      entityTypeId: $scope.access.accessPointType,
      entityId: $scope.access.entityId,
      laboratoryId: laboratoryId,
      wholesalerId: wholesalerId,
      isQuantityAlterable: $scope.sellinOffer.isQuantityAlterable,
      isForApodis: $scope.sellinOffer.isForApodis,
      isForWholesalers: $scope.sellinOffer.isForWholesalers,
      paymentTermDays: $scope.sellinOffer.paymentTermDays,
      fileId: $scope.sellinOffer.file ? $scope.sellinOffer.file.id : null,
      startDate: startDate,
      endDate: endDate,
      products: products,
      targets: targets
    };
  }
  /**
   * Remove product from selection.
   *
   * @param {number} productId - The id of the product to remove.
   */


  function removeProduct(productId) {
    var products = $scope.sellinOffer.products;
    angular.forEach(products, function (product, index) {
      if (product.id === productId) {
        products.splice(index, 1);
      }
    });
  }
  /**
   * Remove target from selection.
   *
   * @param {number} targetId - The id of the target to remove.
   */


  function removeTarget(targetId) {
    var targets = $scope.sellinOffer.targets;
    angular.forEach(targets, function (target, index) {
      if (target.id === targetId) {
        targets.splice(index, 1);
      }
    });
  }
  /**
   * Set default period
   */


  function setDefaultPeriod() {
    $scope.sellinOffer.startDate = moment().toDate();
    $scope.sellinOffer.endDate = moment().add(1, "month").toDate();
  }
  /**
   * Calculate end date.
   */


  function calculateEndDate() {
    var duration = $scope.sellinOffer.duration;
    var startDate = moment($scope.sellinOffer.startDate);
    startDate.add(duration.number, duration.type);
    startDate.subtract(1, "days");
    $scope.sellinOffer.endDate = startDate.toDate();
  }
  /**
   * Go to sellinOffer list.
   */


  function goToList() {
    $state.go("app.sellinOffer");
  } // Select laboratory for this sellinOffer if grouping


  $scope.$on("addLaboratorySellinOffer", function (event, result) {
    if (result.laboratory && result.laboratory[0]) {
      $scope.laboratory = result.laboratory[0];
      $scope.sellinOffer.products = [];
      $scope.productSearchConfig.must = {
        "laboratory.id": [$scope.laboratory.id]
      };
    } else {
      $scope.laboratory = {
        id: 0,
        name: ""
      };
      $scope.sellinOffer.products = [];
      $scope.productSearchConfig.must = {
        "laboratory.id": []
      };
    }
  }); // Adding products to the sellinOffer

  $rootScope.$on("addProductsInSellinOffer", function (event, data) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    } // getProducts with query results


    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.sellinOffer.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.sellinOffer.products.push(product);
        }
      });
      getProductsInformations();
    });
  }); // Adding targets to the sellinOffer

  $scope.$on("addTargetsInSellinOffer", function (event, query) {
    var queryPharma = {
      type: {}
    };
    var groups = [];

    var _loop2 = function _loop2(type) {
      if (type !== "groups") {
        if (Object.prototype.hasOwnProperty.call(query, type)) {
          if (typeof queryPharma.type[type] === "undefined") {
            queryPharma.type[type] = [];
          }

          angular.forEach(query[type], function (obj) {
            if (type === "uga") {
              queryPharma.type[type].push(obj.name);
            } else {
              queryPharma.type[type].push(obj.id);
            }
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(query, type)) {
        angular.forEach(query[type], function (group) {
          if (!_.find($scope.sellinOffer.targets, function (element) {
            return group.id === element.id && element.entityTypeId === 3;
          })) {
            group.entityTypeId = 3;
            $scope.sellinOffer.targets.push(group);
          }

          groups.push(group);
        });
      }
    };

    for (var type in query) {
      _loop2(type);
    } // getPharmas with query results


    healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      angular.forEach(pharmas, function (pharma) {
        if (!_.find($scope.sellinOffer.targets, function (participant) {
          return pharma.id === participant.id;
        })) {
          pharma.entityTypeId = 2;
          $scope.sellinOffer.targets.push(pharma);
        }
      });
      getProductsInformations();
    });
  });
  /**
   * Get products informations,
   */

  function getProductsInformations() {
    // Price set by default to 0
    angular.forEach($scope.sellinOffer.products, function (product) {
      product.pfht = 0;
      product.percentageOfDiscount = 0;
      product.percentageOfMarginWaiver = 0;
    });
    var healthcenterIds = $scope.sellinOffer.targets.filter(function (target) {
      return target.entityTypeId === 2;
    }).map(function (target) {
      return target.id;
    });
    var productIds = $scope.sellinOffer.products.map(function (product) {
      return product.id;
    }); // If products and healthcenters have been chosen

    if (healthcenterIds.length > 0 && productIds.length > 0) {
      sellinOfferService.getPriceForHealthcentersAndProducts({
        healthcenterIds: healthcenterIds,
        productIds: productIds
      }).then(function (filledProducts) {
        // Fill the list of products with prices
        angular.forEach(filledProducts, function (filledProduct) {
          angular.forEach($scope.sellinOffer.products, function (product) {
            if (product.id === filledProduct.productId) {
              product.pfht = filledProduct.manufacturerPriceWithoutVat;
              product.percentageOfDiscount = filledProduct.percentageOfDiscount;
              product.percentageOfMarginWaiver = 0;

              if (!product.pfht) {
                product.pfht = 0;
              }
            }
          });
        }); // For one healthcenter, get the recommended quantity

        if (healthcenterIds.length === 1) {
          getRecommendationsForSellinOfferProducts();
        } else {
          calculatePricesAndTotal();
        }
      });
    }
  }
  /**
   * Calculate prices and totals of the price form.
   */


  function calculatePricesAndTotal() {
    var quantityOfProducts = 0;
    var turnoverAmount = 0;
    angular.forEach($scope.sellinOffer.products, function (product) {
      product.priceAfterDiscountWithoutVat = product.pfht * (1 - product.percentageOfDiscount / 100);

      if (product.quantity && product.quantity > 0) {
        product.totalPriceAfterDiscountWithoutVat = product.priceAfterDiscountWithoutVat * product.quantity;
        quantityOfProducts += product.quantity;
        turnoverAmount += product.totalPriceAfterDiscountWithoutVat;
      }
    });
    $scope.quantityOfProducts = quantityOfProducts;
    $scope.turnoverAmount = turnoverAmount;
  }
  /**
   * Get recommended quantity for sellin offer products.
   */


  function getRecommendationsForSellinOfferProducts() {
    var button = document.getElementById("getRecommendedQuantityButton");
    button.setAttribute("disabled", "disabled"); // Recommended quantity by default to 0

    angular.forEach($scope.sellinOffer.products, function (product) {
      product.quantity = 0;
    });
    var healthcenterCips = $scope.sellinOffer.targets.filter(function (target) {
      return target.entityTypeId === 2;
    }).map(function (target) {
      return target.cip;
    });
    var productIds = $scope.sellinOffer.products.map(function (product) {
      return product.id;
    });

    if (healthcenterCips.length > 0) {
      var healthcenterCip = healthcenterCips[0]; // Get the days to be recommended

      var days = parseInt($scope.sellinOffer.paymentTermDays);

      if (isNaN(days) || days < 1 || days > 120) {
        days = parseInt($scope.defaultPaymentTermDays);
      } // Get the recommendations for 60 days (period by default)


      analysisService.getLaboratoryStockByHealthcenter(healthcenterCip, {
        must: {
          product: productIds
        },
        should: {},
        startDatetime: moment().subtract(1, "year").format("YYYY-MM-DD"),
        endDatetime: moment().format("YYYY-MM-DD")
      }).then(function (response) {
        var productsStockInfo = response.hits.hits;
        var productId = 0;
        var quantity1Day = 0;
        var quantity60Days = 0;
        var quantityPeriod = 0; // For each product, get the recommendation

        angular.forEach(productsStockInfo, function (productStockInfo) {
          productId = productStockInfo._source.product.presentation;
          quantity60Days = parseFloat(productStockInfo._source.needs.calcul.quantity_propal);
          quantity1Day = quantity60Days / 60;
          quantityPeriod = Math.round(quantity1Day * days); // Set the quantity for each product

          angular.forEach($scope.sellinOffer.products, function (product) {
            if (product.id === productId) {
              product.quantity = quantityPeriod;
            }
          });
        });
        calculatePricesAndTotal();
        button.removeAttribute("disabled");
      });
    }
  }
}

angular.module("app.sellinOffer").controller("sellinOfferEditController", SellinOfferEditController);