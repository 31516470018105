"use strict";
/**
 * Dashboard group market shares controller.
 *
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} analysisService - Analysis service.
 */

dashboardGroupMarketSharesController.$inject = ["$state", "$scope", "StatisticsQueryService", "analysisService"];

function dashboardGroupMarketSharesController($state, $scope, StatisticsQueryService, analysisService) {
  $scope.loading = true;
  $scope.goToState = goToState;
  loader();
  /**
   * Loader.
   */

  function loader() {
    launchStatisticsQueries();
  }
  /**
   * Go to state.
   *
   * @param {string} state - State.
   */


  function goToState(state) {
    $state.go(state);
  }
  /**
   * Process laboratories market share query.
   *
   * @param {object} query - Query.
   */


  function processLabsMarketShareQuery(query) {
    analysisService.searchLaboratories(query).then(function (response) {
      $scope.marketSharesData = response.aggregations.laboratories.buckets;
      $scope.totalQuantity = response.aggregations.quantity.value;
      $scope.loading = false;
    });
  }
  /**
   * Launch statistics queries.
   */


  function launchStatisticsQueries() {
    StatisticsQueryService.queryRebase();
    $scope.usrquery = angular.copy(StatisticsQueryService.pristineQuery);
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      processLabsMarketShareQuery($scope.query);
    });
  }
}

angular.module("app.home").controller("dashboardGroupMarketSharesController", dashboardGroupMarketSharesController);