"use strict";
/**
 * User laboratory branding settings controller.
 *
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} healthcenterMembersService - Healthcenter members service.
 * @param {object} userLaboratoryBrandingService - User laboratory branding service.
 * @param {object} environmentService - Environment service.
 */

UserLaboratoryBrandingSettingsController.$inject = ["$state", "$scope", "$rootScope", "$stateParams", "laboratoriesService", "healthcenterMembersService", "userLaboratoryBrandingService", "environmentService"];

function UserLaboratoryBrandingSettingsController($state, $scope, $rootScope, $stateParams, laboratoriesService, healthcenterMembersService, userLaboratoryBrandingService, environmentService) {
  $scope.goBack = goBack;
  $scope.authorize = authorize;
  $scope.changeReassortConfiguration = changeReassortConfiguration;
  $scope.goToBrandingState = goToBrandingState;
  $scope.reassortConfiguration = {
    daysCount: 0,
    needsRounding: 0
  };
  /**
   * Declaration of public objects
   */

  $scope.loadingOperators = {
    loading: true,
    loader: "Récupération des opérateurs de votre pharmacie",
    empty: "Aucun opérateur"
  };
  $scope.loadingReassortProposalConfig = {
    loading: true,
    loader: "Récupération de votre configuration de réassort",
    empty: "Aucune configuration"
  };
  /**
   * go back function
   */

  function goBack() {
    $state.go("app.usersLaboratory.branding");
  }

  loader();
  /**
   * Get operators of the healthcenter
   *
   * @returns {Promise}
   */

  function getOperators() {
    return healthcenterMembersService.getMembers().then(function (members) {
      $scope.operators = members.map(function (member) {
        member.canSeeLaboBranding = member.brandings.indexOf($scope.branding.id) > -1;
        $scope.loadingOperators.loading = false;
        return member;
      });
    });
  }
  /**
   * Get the reassort proposal configuration
   *
   * @returns {Promise}
   */


  function getReassortConfig() {
    var query = {
      healthcenterId: $scope.currentAccess.entityId,
      laboratoryId: $stateParams.laboratoryId
    };
    return userLaboratoryBrandingService.readReassortConfiguration(query).then(function (reassortConfig) {
      $scope.reassortConfiguration = reassortConfig;
      $scope.loadingReassortProposalConfig.loading = false;
      return reassortConfig;
    });
  }
  /**
   * Loading function
   *
   * @returns {Promise}
   */


  function loader() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
    }).then(getBranding).then(getOperators).then(getReassortConfig);
  }
  /**
   * Get laboratory's branding with conditions
   *
   * @returns {Promise}
   */


  function getBranding() {
    var query = {
      laboratoryId: $stateParams.laboratoryId
    };
    return laboratoriesService.readBranding(query).then(function (response) {
      if (response) {
        $scope.branding = response;
        $scope.branding.menus = [{
          name: "Espace marque",
          state: "app.usersLaboratory.branding",
          id: $stateParams.laboratoryId
        }, {
          name: "Les produits"
        }, {
          name: "Les gammes"
        }, {
          name: "Les univers"
        }, {
          name: "Les préconisations"
        }];
      }

      return response;
    });
  }
  /**
   * Authorize the operator
   *
   * @param {object} operator -
   */


  function authorize(operator) {
    var params = {
      laboratoryId: $stateParams.laboratoryId,
      accessId: operator.id,
      isAllowed: operator.canSeeLaboBranding
    };
    userLaboratoryBrandingService.authorize(params);
  }
  /**
   * goToBrandingState
   * Go to branding state
   *
   * @param {object} menu -
   */


  function goToBrandingState(menu) {
    $state.go(menu.state, menu);
  }
  /**
   * change reassort days count and needs rounding
   */


  function changeReassortConfiguration() {
    var params = {
      healthcenterId: $scope.currentAccess.entityId,
      laboratoryId: $stateParams.laboratoryId,
      daysCount: $scope.reassortConfiguration.daysCount,
      needsRounding: $scope.reassortConfiguration.needsRounding
    };
    userLaboratoryBrandingService.changeReassortConfiguration(params);
  }

  $rootScope.$watch("laboratoryBranding", function (branding) {
    if (branding) {
      $scope.branding = branding;
    }
  });
}

angular.module("app.users-laboratory").controller("userLaboratoryBrandingSettingsController", UserLaboratoryBrandingSettingsController);