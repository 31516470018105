"use strict";
/**
 * Patient cart details controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} customerService - The customer service.
 * @param {object} notifyUserService - The notify user service.
 */

PatientCartDetailsController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "customerService", "notifyUserService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function PatientCartDetailsController($scope, $state, $stateParams, $mdDialog, customerService, notifyUserService) {
  $scope.cartTitle = {
    title: {
      text: "Historique des achats",
      back: {
        state: null,
        tooltip: "Retour au détail patient"
      }
    }
  };
  $scope.cart = null;
  $scope.loading = true;
  $scope.redirectToPrescription = redirectToPrescription;
  $scope.openSourceModal = openSourceModal;
  loader();
  /**
   * Loader function.
   */

  function loader() {
    customerService.readCart($stateParams.patientId, $stateParams.cartId).then(function (response) {
      $scope.cart = response.data;
      var mappedSales = $scope.cart.sales.map(function (sale) {
        if (sale.product.speciality_id === 0) {
          return _objectSpread(_objectSpread({}, sale), {}, {
            product: _objectSpread(_objectSpread({}, sale.product), {}, {
              name: "Honoraire"
            })
          });
        }

        return sale;
      });
      $scope.groupedSales = groupSalesByPrescriptions(mappedSales);
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue, veuillez réessayer.");
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Groups the sales by prescriptions.
   *
   * @param {Array} sales - The sales.
   *
   * @returns {Array}
   */


  function groupSalesByPrescriptions(sales) {
    return sales.reduce(function (previous, current) {
      (previous[current.prescription_key] = previous[current.prescription_key] || []).push(current);
      return previous;
    }, {});
  }
  /**
   * Redirect to a given prescription.
   *
   * @param {string} prescriptionKey - The prescription key.
   */


  function redirectToPrescription(prescriptionKey) {
    $state.go("app.patients.details.prescription", {
      prescriptionId: prescriptionKey
    });
  }
  /**
   * Open the source modal.
   *
   * @param {object} source - The source to display.
   */


  function openSourceModal(source) {
    var output = JSON.stringify(source, null, 2);
    $mdDialog.show({
      template: "<pre>".concat(output, "</pre>"),
      clickOutsideToClose: true
    });
  }
}

angular.module("app.patients").controller("patientsCartDetailsController", PatientCartDetailsController);