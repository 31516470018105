"use strict";
/**
 * Guest controller.
 *
 * @param {object} $state - $state.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} $scope - $scope.
 * @param {object} authService - Auth service.
 * @param {object} authUserService - Auth user service.
 */

GuestController.$inject = ["$state", "notifyUserService", "$scope", "authService", "authUserService"];

function GuestController($state, notifyUserService, $scope, authService, authUserService) {
  $scope.passwordComparison = passwordComparison;
  $scope.register = register;
  $scope.isFormOk = isFormOk;
  $scope.backToAuth = backToAuth;
  loader();
  $scope.user = {};
  /**
   * loader
   * to init each method inside
   * on init controller
   */

  function loader() {
    authService.destroyCredentials();
    getB64Params();
  }
  /**
   * getB64Params
   * to get guest data
   */


  function getB64Params() {
    if ($state.params.b64) {
      var params = {
        base64Email: $state.params.b64
      };
      authUserService.readByHash(params).then(function (response) {
        $scope.user = response;
      });
    }
  }
  /**
   * passwordComparison
   * to compare passwords inputs.
   *
   * @param {string} password - Password.
   *
   * @returns {boolean}
   */


  function passwordComparison(password) {
    $scope.alertPasswordLength = $scope.user.password ? $scope.user.password.length < 6 : false;
    return Boolean(password && $scope.user.password && !$scope.user.password.localeCompare(password));
  }
  /**
   * Register an user and login.
   */


  function register() {
    if (isFormOk()) {
      var params = {
        email: $scope.user.email,
        familyBirth: $scope.user.name,
        given: $scope.user.firstName,
        password: $scope.user.password
      };
      authUserService.registerGuest(params).then(auth, handleRegistrationErrors);
    }
  }
  /**
   * Handle registration errors.
   *
   * @param  {object} error - Error.
   */


  function handleRegistrationErrors(error) {
    var messages = {
      "default": "Une erreur est survenue pendant l'enregistrement, veuillez vérifier les informations du formulaire.",
      "You must provide a valid email and a password": "Veuillez vérifier les champs du formulaire.",
      "User already registered": "Cette adresse email est déjà enregistrée."
    };
    var message = messages[error.data.message] ? messages[error.data.message] : messages["default"];
    notifyUserService.showDangerToast(message);
  }
  /**
   * auth
   * init authService.login method
   *
   * @returns {Promise}
   */


  function auth() {
    var params = {
      name: $scope.user.email,
      password: $scope.user.password
    };
    return authService.login(params).then(function (result) {
      $scope.error401 = false;
      var payload = JSON.parse(window.sessionStorage.getItem("payload"));
      payload.options = result.data.options;
      window.sessionStorage.setItem("payload", JSON.stringify(payload));
      $state.go("app.home");
    }, function (err) {
      if (err.status === 401) {
        notifyUserService.showSimpleToast("Email ou mot de passe incorrect, veuillez réessayer.");
      } else {
        notifyUserService.showSimpleToast("Site en maintenance, veuillez réessayer plus tard.");
      }
    });
  }
  /**
   * Is the registration form valid.
   *
   * @returns {boolean}
   */


  function isFormOk() {
    return !$scope.user || $scope.user.email && passwordComparison($scope.passwordRepeat) && $scope.user.name && $scope.user.firstName;
  }
  /**
   * backToAuth
   * go to authenticate $state
   * with logout to prevent
   * credentials into localSotrage
   */


  function backToAuth() {
    authService.logout();
  }
}

angular.module("app.auth").controller("guestController", GuestController);