"use strict";
/**
 * Administration configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

AdministrationConfig.$inject = ["$stateProvider"];

function AdministrationConfig($stateProvider) {
  $stateProvider.state("app.administration", {}).state("app.administration.gdrp", {
    url: "/administration/RGPD",
    views: {
      "content@app": {
        templateUrl: "app/main/administration/views/terms/administration.terms.html",
        controller: "AdministrationTermsController"
      }
    }
  }).state("app.administration.faq", {
    url: "/administration/FAQ",
    views: {
      "content@app": {
        templateUrl: "app/main/administration/views/faq/administration.faq.html",
        controller: "AdministrationFaqController"
      }
    }
  });
}

angular.module("app.administration", ["app.administration.services"]).config(AdministrationConfig);