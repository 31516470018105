"use strict";
/**
 * Patient pathologies list controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} patientPathologyService - The patient pathology service.
 */

PatientPathologiesListController.$inject = ["$scope", "$state", "patientPathologyService"];

function PatientPathologiesListController($scope, $state, patientPathologyService) {
  $scope.pathologies = null;
  $scope.loading = true;
  $scope.selectLetter = selectLetter;
  $scope.getPathologies = getPathologies;
  $scope.pathologiesContainLetter = pathologiesContainLetter;
  $scope.redirectToPathologyDetails = redirectToPathologyDetails;
  $scope.pathologiesListTitle = {
    title: {
      text: "Mes conseils",
      back: {
        state: null
      }
    }
  };
  var alphabetString = "abcdefghijklmnopqrstuvxyz";
  $scope.alphabet = alphabetString.toUpperCase().split("");
  $scope.selectedLetter = "a";
  loader();
  /**
   * Loader function.
   */

  function loader() {
    getPathologies().then(function (pathologies) {
      $scope.pathologies = pathologies;
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Get the pathologies.
   *
   * @param {string|null} search - Query search text.
   *
   * @returns {Promise}
   */


  function getPathologies() {
    var search = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var params = [];

    if (search) {
      params = [{
        name: "search",
        value: search
      }];
    }

    return patientPathologyService.indexPathologies(params);
  }
  /**
   * Select a letter.
   *
   * @param {string} letter - The selected letter.
   */


  function selectLetter(letter) {
    $scope.selectedLetter = letter;
  }
  /**
   * Determine if at least one pathology start with a given letter.
   *
   * @param {string} letter - The letter.
   *
   * @returns {boolean}
   */


  function pathologiesContainLetter(letter) {
    return $scope.pathologies.findIndex(function (pathology) {
      return pathology.name.substring(0, 1).toUpperCase() === letter.toUpperCase();
    }) !== -1;
  }
  /**
   * Redirect to the pathology details.
   *
   * @param {object} pathology - The pathology.
   */


  function redirectToPathologyDetails(pathology) {
    $state.go("app.patients.pathologies.details", {
      pathologyId: pathology.id
    });
  }
}

angular.module("app.patients").filter("startWithLetter", function () {
  return function (items, letter) {
    var filtered = [];
    var letterMatch = new RegExp(letter, "i");
    items.forEach(function (item) {
      if (letterMatch.test(item.name.substring(0, 1))) {
        filtered.push(item);
      }
    });
    return filtered;
  };
});
angular.module("app.patients").controller("patientPathologiesListController", PatientPathologiesListController);