"use strict";
/**
 * User laboratory branding commercial offers controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $stateParams - $stateParams.
 * @param {string} apiurl - API URL.
 * @param {object} offersService - Offers service.
 * @param {object} sellinOfferService - Sellin offer service.
 * @param {object} $mdDialog - The $mdDialog object.
 */

OfferModalController.$inject = ["$scope", "$timeout", "offer", "branding", "$mdDialog", "selloutFactory", "environmentService", "userSelloutService", "offersService", "notifyUserService"];
UserLaboratoryBrandingCommercialOffersController.$inject = ["$scope", "$rootScope", "$stateParams", "apiurl", "offersService", "sellinOfferService", "$mdDialog"];

function UserLaboratoryBrandingCommercialOffersController($scope, $rootScope, $stateParams, apiurl, offersService, sellinOfferService, $mdDialog) {
  /**
   * Declaration of public methods
   */
  $scope.accept = accept;
  $scope.refuse = refuse;
  $scope.showOffer = showOffer;
  $scope.filterTheSellins = filterTheSellins;
  $scope.isUnfiltered = isUnfiltered;
  /**
   * Declaration of public objects
   */

  $scope.apiurl = apiurl;
  $scope.offerTypeEvent = "offers:category";
  $scope.offerStatusEvent = "offers:type";
  $scope.brandingLoaded = false;
  $scope.currentType = null;
  $scope.currentOffer = null;
  $scope.sellinsFiltered = [];
  $scope.sellinFilters = [{
    name: "Ouverture de marché",
    id: 2
  }, {
    name: "Offres promotionnelles",
    id: 3
  }];
  $scope.offersCategories = [{
    name: "Promotions",
    category: "sellin-challenges",
    method: offersService.get,
    includes: ["file,products,steps.stepGroups,steps.stepProducts"],
    methods: [{
      method: "comeup",
      name: "A venir"
    }, {
      method: "ongoing",
      name: "En cours"
    }, {
      method: "finished",
      name: "Terminés"
    }],
    offers: {}
  }, {
    name: "Offres d'achat",
    category: "sellin-offers",
    method: offersService.get,
    includes: ["file"],
    methods: [{
      method: "todo",
      name: "A faire"
    }, {
      method: "accepted",
      name: "Acceptés"
    }, {
      method: "refused",
      name: "Refusés"
    }],
    offers: {}
  }, {
    name: "Challenges",
    category: "sellout",
    method: offersService.get,
    includes: ["sellout.file,performance"],
    methods: [{
      method: "ongoing",
      name: "En cours"
    }, {
      method: "comeup",
      name: "A venir"
    }, {
      method: "finished",
      name: "Terminés"
    }],
    offers: {}
  }, {
    name: "Mises en avant",
    category: "prestation",
    method: offersService.get,
    includes: ["prestation.file,prestation.products"],
    methods: [{
      method: "ongoing",
      name: "En cours"
    }, {
      method: "comeup",
      name: "A venir"
    }, {
      method: "finished",
      name: "Terminés"
    }],
    offers: {}
  }];
  $scope.sellinChallenges = $scope.offersCategories["sellin-challenges"];
  $scope.sellinOffers = $scope.offersCategories["sellin-offers"];
  $scope.sellouts = $scope.offersCategories.sellout;
  $scope.prestations = $scope.offersCategories.prestation;
  /**
   * Get offers.
   */

  function getOffers() {
    var query = {
      laboratoryId: $stateParams.laboratoryId
    };

    var _loop = function _loop(offer) {
      if (Object.prototype.hasOwnProperty.call($scope.offersCategories, offer)) {
        query.category = $scope.offersCategories[offer].category;
        query.includes = $scope.offersCategories[offer].includes;
        $scope.offersCategories[offer].methods.forEach(function (obj) {
          obj.loading = true;
          query.type = obj.method;
          offersService.get(query).then(function (offers) {
            $scope.offersCategories[offer].offers[obj.method] = offers.map(function (myOffer) {
              var source = myOffer[$scope.offersCategories[offer].category] && myOffer[$scope.offersCategories[offer].category].imagePath ? "".concat(apiurl, "/images/").concat(myOffer[$scope.offersCategories[offer].category].imagePath) : "assets/images/sellouts/default-challenge-1080x600.jpg";
              var endDate = myOffer.endDate ? myOffer.endDate : myOffer[$scope.offersCategories[offer].category].endDate ? myOffer[$scope.offersCategories[offer].category].endDate : myOffer[$scope.offersCategories[offer].category].endDatetime;
              return Object.assign(myOffer, {
                source: source,
                category: $scope.offersCategories[offer].category,
                offer_type: obj.method,
                method: obj,
                isActive: true,
                daysLeft: moment(endDate).diff(moment(), "days")
              });
            });
            obj.loading = false;
          });
        });
      }
    };

    for (var offer in $scope.offersCategories) {
      _loop(offer);
    }
  }
  /**
   * Filter the sellins.
   *
   * @param {object} filter - The filter.
   */


  function filterTheSellins(filter) {
    if ($scope.sellinsFiltered.indexOf(filter.id) !== -1) {
      $scope.sellinsFiltered.splice($scope.sellinsFiltered.indexOf(filter.id), 1);
    } else {
      $scope.sellinsFiltered.push(filter.id);
    }

    $scope.filterStyles[filter.id] = {
      color: $scope.sellinsFiltered.indexOf(filter.id) !== -1 ? "white" : $scope.branding.primaryColor,
      "background-color": $scope.sellinsFiltered.indexOf(filter.id) === -1 ? "white" : $scope.branding.primaryColor
    };
  }
  /**
   * Check if is filtered or not.
   *
   * @param {object} offer - The offer.
   *
   * @returns {boolean}
   */


  function isUnfiltered(offer) {
    return $scope.sellinsFiltered.length === 0 || $scope.sellinsFiltered.indexOf(offer.type.id) !== -1;
  }
  /**
   * Accept.
   *
   * @param {object} offer - the target offer.
   *
   * @returns {Promise}
   */


  function accept(offer) {
    var query = {
      id: offer.id
    };
    return sellinOfferService.accept(query);
  }
  /**
   * Refuse.
   *
   * @param {object} offer - the target offer.
   *
   * @returns {Promise}
   */


  function refuse(offer) {
    var query = {
      id: offer.id
    };
    return sellinOfferService.refuse(query);
  }
  /**
   * Set current category.
   *
   * @param {object} offerCategory - Offer category.
   */


  function setCurrentCategory(offerCategory) {
    $scope.categorySelected = true;
    $scope.currentOffer = offerCategory;
    $scope.currentCategory = offerCategory.category;
  }
  /**
   * Set current type.
   *
   * @param {object} offerType - Offer type.
   */


  function setCurrentType(offerType) {
    $scope.typeSelected = true;
    $scope.offersCategories[$scope.currentCategory].offers.forEach(function (offer) {
      offer.isActive = offer.method.method === offerType.method;
    });
  }
  /**
   * Show offer.
   *
   * @param {object} offer - The target offer.
   * @param {object} event - The event.
   */


  function showOffer(offer, event) {
    var dialog = {
      templateUrl: "app/main/laboratory/users-laboratory/views/branding/includes/commercialOffers/includes/" + offer.category + ".html",
      locals: {
        offer: offer,
        branding: $scope.branding
      },
      parent: angular.element(document.body),
      controller: OfferModalController,
      event: event,
      clickOutsideToClose: true
    };
    $mdDialog.show(dialog);
  }

  $rootScope.$watch("laboratoryBranding", function (branding) {
    if (branding) {
      $scope.brandingLoaded = true;
      $scope.branding = branding;
      $scope.filterStyles = {
        2: {
          color: $scope.branding.primaryColor,
          "background-color": "white"
        },
        3: {
          color: $scope.branding.primaryColor,
          "background-color": "white"
        }
      };
      getOffers();
    }
  });
  $rootScope.$on($scope.offerTypeEvent, function (event, offerCategory) {
    event.preventDefault();
    $scope.categorySelected = false;
    $scope.typeSelected = false;
    $scope.currentOffer = [];

    if (offerCategory) {
      setCurrentCategory(offerCategory);
    }
  });
  $rootScope.$on($scope.offerStatusEvent, function (event, offerType) {
    event.preventDefault();
    $scope.typeSelected = false;

    if (offerType) {
      setCurrentType(offerType);
    }
  });
}
/**
 * @param {object} $scope - The angular $scope object.
 * @param {object} $timeout - The angular $timeout object.
 * @param {object} offer - The offer.
 * @param {object} branding - The branding.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} selloutFactory - The sellout factory.
 * @param {object} environmentService - The environment service.
 * @param {object} userSelloutService - The user sellout service.
 * @param {object} offersService - The offers service.
 * @param {object} notifyUserService - The notify user service.
 */


function OfferModalController($scope, $timeout, offer, branding, $mdDialog, selloutFactory, environmentService, userSelloutService, offersService, notifyUserService) {
  RefuseSelloutController.$inject = ["$scope", "$mdDialog", "sellout", "notifyUserService"];
  AcceptSelloutController.$inject = ["$scope", "$mdDialog", "environmentService", "rewardsService", "sellout", "notifyUserService"];
  $scope.loadingBrandingOptions = {
    loading: true,
    loader: "Récupération des données"
  };
  loader();
  /**
   * The loading function.
   */

  function loader() {
    getEnvironment().then(function (env) {
      $scope.environment = env;

      if (offer.category === "sellout") {
        getSingleChallenge(offer.sellout.id).then(getScoreChallenge(offer.sellout.id));
      } else if (offer.category === "sellin-challenges") {
        $scope.modal = initModal();
        $scope.modal.sellin = offer;
        $scope.productsByGroup = groupProductsByGroups(offer.products, offer.hasMinimumQuantityByProductGroup ? offer.groups : []);
      }
    });
  }

  offer.total = {
    quantity: 0,
    amount: 0
  };
  offer.totalSellin = false;
  $scope.loading = true;
  $scope.offer = offer;
  $scope.branding = branding;
  $scope.close = close;
  $scope.nom = new Date();
  $scope.acceptSellout = acceptSellout;
  $scope.refuseSellout = refuseSellout;
  $scope.getProductPrices = getProductsPrice;
  $scope.manageCart = manageCart;
  $scope.getMaximumSellinDiscountByStep = getMaximumSellinDiscountByStep;
  /**
   * Cose the dialog.
   */

  function close() {
    $mdDialog.hide();
  }
  /**
   * Manage cart.
   *
   * @param {Array} products - The products.
   * @param {object} product - The product.
   */


  function manageCart(products, product) {
    product.loading = true;
    $scope.offer.totalSellin = false;
    var quantity = {};
    products.forEach(function (product) {
      quantity[product.productId] = product.quantity;
    });
    var params = {
      laboratoryId: $scope.branding.laboratoryId,
      healthcenterId: $scope.environment.entityId,
      products: quantity
    };
    offersService.manageCart(params).then(function (cart) {
      notifyUserService.showSimpleToast("Vos produits ont été ajoutés au panier.", {
        color: "success",
        hideDelay: 2000
      });
      adjustPrices(products, cart);
      product.loading = false;
      $scope.offer.totalSellin = true;
    });
  }
  /**
   * Get products price.
   *
   * @param {Array} products - The products.
   *
   * @returns {Promise}
   */


  function getProductsPrice(products) {
    var quantity = {};
    products.forEach(function (product) {
      quantity[product.productId] = product.quantity ? product.quantity : 1;
    });
    var params = {
      laboratoryId: $scope.branding.laboratoryId,
      healthcenterId: $scope.environment.entityId,
      products: quantity
    };
    return offersService.getProductsPrice(params).then(function (prices) {
      return prices;
    });
  }
  /**
   * Handle prices.
   *
   * @param {Array} products - The products.
   * @param {object} prices - The prices.
   */


  function handlePrices(products, prices) {
    products.forEach(function (product) {
      product.price = prices[product.productId];
    });
    totalSellin($scope.offer);
  }
  /**
   * Adjust prices.
   *
   * @param {Array} products - The products.
   * @param {object} prices - The prices.
   */


  function adjustPrices(products, prices) {
    prices.forEach(function (price) {
      products.find(function (product) {
        return product.productId === price.productId;
      }).price = price;
    });
    totalSellin($scope.offer);
  }
  /**
   * Handle cart.
   *
   * @param {Array} cartLines - The lines of the cart.
   */


  function handleCart(cartLines) {
    cartLines.forEach(function (line) {
      $scope.offer.products.find(function (product) {
        return product.productId === line.productId;
      }).quantity = line.quantity;
    });
    totalSellin($scope.offer);
  }
  /**
   * Get options for modal that display sellin details.
   *
   * @returns {object}
   */


  function initModal() {
    var params = {
      laboratoryId: branding.laboratoryId,
      healthcenterId: $scope.environment.entityId,
      includes: ["lines.product"]
    };
    offersService.getCart(params).then(function (cart) {
      if (cart.lines.length) {
        handleCart(cart.lines);
      }
    });
    getProductsPrice($scope.offer.products).then(function (prices) {
      handlePrices($scope.offer.products, prices);
    });
    var modal = {
      sellin: {},
      loader: false,
      toAdd: [],
      action: {
        adding: false,
        products: []
      },
      lastDiscountActivated: 0,
      errors: false,
      messages: []
    };
    modal.sellin.salesIsLoaded = false;
    return modal;
  }
  /**
   * getEnvironment to get current environment
   *
   * @returns {Promise}
   */


  function getEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.isHolder = response.accessPointType === 2 && (response.rankId === 1 || response.rankId === 2 || response.isTechnicalReferent === 1);
      return response;
    });
  }
  /**
   * Get a single challenge.
   *
   * @param {number} selloutId - The sellout id.
   *
   * @returns {Promise}
   */


  function getSingleChallenge(selloutId) {
    return userSelloutService.read({
      id: selloutId
    }).then(function (response) {
      $scope.userSellout = response;
      $scope.now = new Date();
      $scope.start = new Date($scope.userSellout.sellout.startDate);
      $scope.end = new Date($scope.userSellout.sellout.endDate);
      $scope.loading = false;
      return response;
    });
  }
  /**
   * Get sellout score.
   *
   * @param {number} selloutId - The sellout id.
   *
   * @returns {Promise}
   */


  function getScoreChallenge(selloutId) {
    return userSelloutService.readScore(selloutId).then(function (response) {
      $scope.scoreSellout = response.score;
      $scope.totalGainPoints = $scope.scoreSellout.gainHealthcenter || $scope.scoreSellout.gainOperator;
      $scope.loading = false;
      $scope.scoreSellout.unitRewards.map(function (unitReward) {
        unitReward.teaser = selloutFactory.generateUnitTeaser(unitReward.reward.gain, unitReward.reward.remuneration);
        return unitReward.teaser;
      });
      $scope.scoreSellout.objectiveRewards.map(function (objectiveReward) {
        objectiveReward.teaser = selloutFactory.generateObjectiveTeaser(objectiveReward.reward.gain, objectiveReward.reward.remuneration, objectiveReward.reward.objective.isPercent ? objectiveReward.quantityNeeded : objectiveReward.reward.goal, objectiveReward.reward.objective, objectiveReward.reward.startDate, objectiveReward.reward.endDate, objectiveReward.reward.objectiveMode);
        return objectiveReward.teaser;
      });
      $scope.loading = false;
    });
  }
  /**
   * Return products grouped by group.
   *
   * @param {object} products - Products to group.
   * @param {object} groups - Groups regrouping products.
   *
   * @returns {Array}
   */


  function groupProductsByGroups(products, groups) {
    var productsByGroup = {};
    var productWithoutGroups = [];

    for (var productId in products) {
      if (products[productId]) {
        var product = products[productId];
        product.quantity = 0;
        product.percentageOfDiscount = 0;
        product.priceWithoutTaxAfterDiscount = product.product.buyingPrice * (100 - product.percentageOfDiscount) / 100;
        var productHasGroup = false;

        for (var index in groups) {
          if (groups[index]) {
            var group = groups[index];

            if (!productsByGroup[group.id]) {
              productsByGroup[group.id] = {
                group: group,
                products: []
              };
            }

            if (isProductInGroup(productId, group)) {
              productsByGroup[group.id].products.push(product);
              productHasGroup = true;
            }
          }
        }

        if (!productHasGroup) {
          productWithoutGroups.push(product);
        }
      }
    }

    var productsByGroupValues = Object.values(productsByGroup);
    productsByGroupValues.push({
      group: null,
      products: productWithoutGroups
    });
    return productsByGroupValues;
  }
  /**
   * Test is product given by its ID is in the group.
   *
   * @param {number} productId - The ID of product to test.
   * @param {object} group - Group Groupe to test.
   *
   * @returns {boolean}
   */


  function isProductInGroup(productId, group) {
    for (var index in group.sellinProducts) {
      if (group.sellinProducts[index]) {
        var productGroup = group.sellinProducts[index];

        if (productGroup.product && productGroup.product.productId === productId) {
          return true;
        }
      }
    }

    return false;
  }
  /**
   * Return the maximum discount of step.
   *
   * @param {object} sellin - The sellin.
   * @param {object} step - The step from which the maximum discount must
   *      be returned.
   *
   * @returns {number}
   */


  function getMaximumSellinDiscountByStep(sellin, step) {
    if (!sellin.isDiscountedByStepProduct) {
      return step.discountValue;
    }

    var max = 0;

    for (var index in step.products) {
      if (step.products[index]) {
        var product = step.products[index];

        if (!product.discountPercentage && step.discountValue > max) {
          max = step.discountValue;
        } else if (product.discountPercentage > max) {
          max = product.discountPercentage;
        }
      }
    }

    return max;
  }
  /**
   * Recalculate the total amount and quantity of the sellin.
   *
   * @param {object} sellin - The sellin.
   */


  function totalSellin(sellin) {
    // Depending on the step activated, recalculate or not
    $scope.offer.total.quantity = 0;
    $scope.offer.total.amount = 0;
    $scope.offer.total.productsQuantityByGroup = {};
    $scope.offer.total.productsQuantityByProduct = {};
    angular.forEach(sellin.products, function (product) {
      if (product.price) {
        var quantity = product.quantity ? product.quantity : 0;
        $scope.offer.total.quantity += quantity;
        $scope.offer.total.amount += product.price.priceDiscountedWithoutVat * quantity;

        if (!$scope.offer.total.productsQuantityByProduct[product.productId]) {
          $scope.offer.total.productsQuantityByProduct[product.productId] = 0;
        }

        $scope.offer.total.productsQuantityByProduct[product.productId] += product.quantity;

        if (sellin.hasMinimumQuantityByProductGroup) {
          for (var groupIndex in sellin.groups) {
            if (sellin.groups[groupIndex]) {
              var group = sellin.groups[groupIndex];

              if (!$scope.offer.total.productsQuantityByGroup[group.id]) {
                $scope.offer.total.productsQuantityByGroup[group.id] = 0;
              }

              if (isProductInGroup(product.productId, group)) {
                $scope.offer.total.productsQuantityByGroup[group.id] += product.quantity;
              }
            }
          }
        }
      }
    });
    $scope.offer.totalSellin = true;
  }
  /**
   * acceptSellout to accept user sellout
   *
   * @param {object} event -
   * @param {object} sellout -
   */


  function acceptSellout(event, sellout) {
    $mdDialog.show({
      controller: AcceptSelloutController,
      templateUrl: "app/main/users-sellout/views/dialogs/accept.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        sellout: sellout
      },
      clickOutsideToClose: true
    }).then($mdDialog.hide);
  }
  /**
   * refuseSellout to refuse user sellout
   *
   * @param {object} event -
   * @param {object} sellout -
   */


  function refuseSellout(event, sellout) {
    $mdDialog.show({
      controller: RefuseSelloutController,
      templateUrl: "app/main/users-sellout/views/dialogs/refuse.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        tile: sellout
      },
      clickOutsideToClose: true
    }).then($mdDialog.hide);
  }
  /**
   * Accept sellout controller.
   *
   * @param {object} $scope - $scope.
   * @param {object} $mdDialog - $mdDialog.
   * @param {object} environmentService - Environment service.
   * @param {object} rewardsService - Rewards service.
   * @param {object} sellout - Sellout.
   * @param {object} notifyUserService - The notify user service.
   */


  function AcceptSelloutController($scope, $mdDialog, environmentService, rewardsService, sellout, notifyUserService) {
    /**
     * Declaration of public methods
     */
    $scope.close = close;
    $scope.validate = validate;
    /**
     * Declaration of public objects
     */

    $scope.tile = sellout;
    $scope.loadingDistributions = {
      loading: true,
      loader: "Récupération des types de récompenses",
      empty: "Vous n'avez pas de type de récompenses."
    };
    loader();
    /**
     * Loader.
     */

    function loader() {
      getEnvironment().then(getRewardDistribution);
    }
    /**
     *
     * Get current environment
     *
     * @returns {Promise}
     */


    function getEnvironment() {
      return environmentService.getEnvironment().then(function (response) {
        $scope.environment = response;
        return response;
      });
    }
    /**
     * Get reward distribution.
     *
     * @returns {Promise}
     */


    function getRewardDistribution() {
      return rewardsService.getRewardDistributions().then(function (response) {
        $scope.loadingDistributions.loading = false;
        $scope.distributions = response;
        return response;
      });
    }
    /**
     * Validate.
     *
     * @returns {Promise}
     */


    function validate() {
      sellout.rewardDistributionId = $scope.tile.rewardDistributionId;
      return userSelloutService.accept(event, sellout).then(function (response) {
        notifyUserService.showSimpleToast("Le challenge « " + sellout.name + " » a été accepté", {
          color: "success",
          hideDelay: 2000
        });
        return response;
      }).then(function (response) {
        $mdDialog.hide(sellout);
        return response;
      });
    }
    /**
     * Close.
     */


    function close() {
      $mdDialog.cancel();
    }
  }
  /**
   * Refuse sellout controller.
   *
   * @param {object} $scope - The angular $scope object.
   * @param {object} $mdDialog - The angulat $mdDialog object.
   * @param {object} sellout - Sellout.
   * @param {object} notifyUserService - The notifyUser service.
   */


  function RefuseSelloutController($scope, $mdDialog, sellout, notifyUserService) {
    $scope.validate = validate;
    $scope.close = close;
    /**
     * hide
     */

    function validate() {
      sellout.comments = $scope.comments;
      userSelloutService.refuse(event, sellout).then(function (response) {
        notifyUserService.showSimpleToast("Le challenge « " + sellout.name + " » a été refusé", {
          color: "success",
          hideDelay: 2000
        });
        return response;
      }).then(function (response) {
        $mdDialog.hide(sellout);
        return response;
      });
    }
    /**
     * cancel
     */


    function close() {
      $mdDialog.cancel();
    }
  }
}

angular.module("app.users-laboratory").controller("userLaboratoryBrandingCommercialOffersController", UserLaboratoryBrandingCommercialOffersController).controller("offerModalController", OfferModalController);