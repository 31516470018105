"use strict";
/**
 * Dashboard controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} accessService - Access service.
 * @param {string} alliadisSanteSecure - Alliadis install command.
 */

dashboardController.$inject = ["$scope", "environmentService", "accessService", "alliadisSanteSecure"];

function dashboardController($scope, environmentService, accessService, alliadisSanteSecure) {
  $scope.alliadisSanteSecure = alliadisSanteSecure;
  $scope.isGroup = false;
  $scope.isLaboratory = false;
  $scope.isPharmacy = false;
  $scope.isCompany = false;
  $scope.copy = copy;
  loader();
  /**
   * Loader.
   */

  function loader() {
    loaderEnv();
  }
  /**
   * Load environment.
   *
   * @returns {Promise}
   */


  function loaderEnv() {
    return environmentService.getEnvironment().then(function (environment) {
      $scope.env = environment;

      switch (environment.accessPointType) {
        case 1:
          $scope.isLaboratory = true;
          break;

        case 2:
          $scope.isPharmacy = true;
          break;

        case 3:
          $scope.isGroup = true;
          break;

        case 6:
          $scope.isCompany = true;
          break;
      }

      $scope.env = environment;
      accessService.isHcUnplugged(environment.accessPointType, environment.rankId).then(function (isUnplugged) {
        $scope.hcUnplugged = isUnplugged;
        lgoWarning(environment);
      });
      return environment;
    });
  }

  var imagePath = "/assets/images/install/";
  $scope.imagePath = {
    one: imagePath + "step-one.jpg",
    two: imagePath + "step-two.jpg",
    three: imagePath + "step-three.jpg"
  };
  /**
   * LGO warning.
   *
   * @param {object} environment - Environment.
   */

  function lgoWarning(environment) {
    $scope.compatibleLgo = [2, 11, 21, 16, 4, 10, 1, 19, 20];

    if ((window.navigator.userAgent.indexOf("Win16") !== -1 || window.navigator.userAgent.indexOf("Windows 95") !== -1 || window.navigator.userAgent.indexOf("Win95") !== -1 || window.navigator.userAgent.indexOf("Windows_95") !== -1 || window.navigator.userAgent.indexOf("Windows 98") !== -1 || window.navigator.userAgent.indexOf("Win98") !== -1 || window.navigator.userAgent.indexOf("Windows 2000") !== -1 || window.navigator.userAgent.indexOf("Windows NT 5.") !== -1 || window.navigator.userAgent.indexOf("Mac OS X") !== -1) && environment.entity.lgo === 11) {
      var mac = window.navigator.userAgent.indexOf("Mac OS X") !== -1 ? " Les Mac OS X ne supporte pas encore le logiciel" : "";
      $scope.warningLgo = "Votre système d'exploitation est incompatible. Celui-ci doit être un Windows supérieur à Windows XP." + mac;
    } else if ($scope.compatibleLgo.indexOf(environment.entity.lgo) === -1) {
      $scope.warningLgo = "Votre logiciel de gestion officinale n’est pas encore compatible avec Sante Secure. Nous travaillons actuellement sur cette évolution et nous vous tiendrons informés dès que vous pourrez procéder à l’installation de Sante Secure.";
    } else {
      $scope.lgoCompatibility = true;

      if (environment.entity.lgo === 21) {
        $scope.warningLgo = "Assurez-vous d\"être sur le serveur Winpharma de votre officine pour l\"installation de notre logiciel Santé Secure.";
      } else if (environment.entity.lgo !== 11) {
        $scope.warningLgo = "Nous vous invitons à poursuivre l’installation de Sante Secure sur " + "l’ordinateur serveur de votre officine. Cette condition est requise uniquement lors de " + "l’inscription du pharmacien titulaire. Vos prochaines connexions pourront se faire depuis " + "n’importe quel ordinateur de votre pharmacie.";
      }
    }
  }
  /**
   * Copy to the clipboard.
   *
   * @param {string} commandLine - Command line.
   */


  function copy(commandLine) {
    var body = document.getElementsByTagName("body")[0];
    var tempInput = document.createElement("INPUT");
    body.appendChild(tempInput);
    tempInput.setAttribute("value", commandLine);
    tempInput.select();
    document.execCommand("copy");
    body.removeChild(tempInput);
  }
}

angular.module("app.dashboard").controller("dashboardController", dashboardController);