"use strict";
/**
 * The hour picker component.
 */

HourPickerController.$inject = ["$scope"];
var HourPickerComponent = {
  templateUrl: "app/main/directives/hour-picker/hour-picker.html",
  controller: HourPickerController,
  bindings: {
    value: "=ngModel",
    range: "<?",
    durationLabel: "@?",
    allDayLabel: "@?",
    onChange: "&?"
  },
  controllerAs: "vm"
};
/**
 * HourPickerController.
 *
 * @param {object} $scope - $scope.
 */

function HourPickerController($scope) {
  var vm = this;
  $scope.isAllDay = false;
  $scope.handleAddHour = handleAddHour;
  $scope.handleSubtractHour = handleSubtractHour;
  $scope.handleSelectedHour = handleSelectedHour;
  $scope.handleIsAllDay = handleIsAllDay;
  $scope.hourIsInRange = hourIsInRange;

  vm.$onInit = function () {
    $scope.availableHours = generateHours(9, 21);

    if (vm.range && !vm.value) {
      vm.value = {};
    }
  };
  /**
   * Handle when a hour is selected.
   *
   * @param {moment} hour - The selected hour.
   *
   * @returns {void}
   */


  function handleSelectedHour(hour) {
    if (vm.range) {
      vm.value.from = hour;
      vm.value.to = hour;
    } else {
      vm.value = hour;
    }

    vm.onChange();
  }
  /**
   * Determines whether the given hour is in the hour range.
   *
   * @param {object} hour - The hour.
   *
   * @returns {boolean}
   */


  function hourIsInRange(hour) {
    return vm.value.from <= hour && hour <= vm.value.to;
  }
  /**
   * Handle when the is all day checkbox is pressed.
   *
   * @returns {void}
   */


  function handleIsAllDay() {
    $scope.isAllDay = !$scope.isAllDay;

    if ($scope.isAllDay) {
      vm.value.from = $scope.availableHours[0];
      vm.value.to = $scope.availableHours[$scope.availableHours.length - 1];
    } else {
      vm.value.from = null;
      vm.value.to = null;
    }
  }
  /**
   * Adds one hour to the hour range.
   *
   * @returns {void}
   */


  function handleAddHour() {
    if (!vm.value.to && !vm.value.from) {
      return;
    }

    if (!vm.value.to) {
      vm.value.to = vm.value.from.clone().add(1, "hour");
    } else if (vm.value.to < $scope.availableHours[$scope.availableHours.length - 1]) {
      vm.value.to = vm.value.to.clone().add(1, "hours");
    }
  }
  /**
   * Subtract a hour from the hour range.
   *
   * @returns {void}
   */


  function handleSubtractHour() {
    if (vm.value.to) {
      if (vm.value.to > vm.value.from) {
        vm.value.to = vm.value.to.clone().subtract(1, "hour");
      }
    }
  }
  /**
   * Generates a hours array.
   *
   * @param {number} from - Hour from.
   * @param {number} to - Hour to.
   *
   * @returns {Array}
   */


  function generateHours(from, to) {
    var items = [];

    for (var index = from; index < to; index++) {
      items.push(moment({
        hour: index
      }));
    }

    return items;
  }
}

angular.module("app.directives").component("hourPicker", HourPickerComponent);