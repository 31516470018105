"use strict";
/**
 * Sellin challenge configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 * @param {object} sellinChallengeConfig - Sellin challenge configuration.
 */

SellinChallengeConfig.$inject = ["$stateProvider", "sellinChallengeConfig"];

function SellinChallengeConfig($stateProvider, sellinChallengeConfig) {
  $stateProvider.state("app.sellinChallenge", {
    url: "/challenges-sellin?awaiting",
    views: {
      "content@app": {
        templateUrl: sellinChallengeConfig.baseDir + "list/sellinChallenges.html",
        controller: "sellinChallengeController"
      }
    },
    params: {
      activeTab: null
    }
  }).state("app.sellinChallenge.new", {
    url: "/creation",
    views: {
      "content@app": {
        templateUrl: sellinChallengeConfig.baseDir + "edit/edit.html",
        controller: "sellinChallengeEditController"
      }
    },
    params: {
      healthcenterId: null,
      healthcenterName: null,
      name: null,
      description: null,
      laboratory: null,
      targets: null,
      products: null,
      wholesalerId: null
    }
  }).state("app.sellinChallenge.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: sellinChallengeConfig.baseDir + "edit/edit.html",
        controller: "sellinChallengeEditController"
      }
    }
  }).state("app.sellinChallenge.negotiate", {
    url: "/negociation/:id",
    views: {
      "content@app": {
        templateUrl: sellinChallengeConfig.baseDir + "negotiate/negotiate.html",
        controller: "sellinChallengeNegotiateController"
      }
    }
  }).state("app.sellinChallenge.detail", {
    url: "/detail/:id",
    views: {
      "content@app": {
        templateUrl: sellinChallengeConfig.baseDir + "detail/detail.html",
        controller: "sellinChallengeDetailController"
      }
    }
  });
}

angular.module("app.sellinChallenge", []).config(SellinChallengeConfig).constant("sellinChallengeConfig", {
  baseDir: "app/main/sellin-challenge/views/"
});