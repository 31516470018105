"use strict";
/**
 * Laboratories service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

LaboratoriesService.$inject = ["apiClient"];

function LaboratoriesService(apiClient) {
  /**
   * Wholesaler SAGITTA
   *
   * @type {number}
   */
  var wholesalerId = 89;
  return {
    /**
     * Get wholesaler id
     *
     *
     * @returns {number}
     */
    getWholesalerId: function getWholesalerId() {
      return wholesalerId;
    },

    /**
     * get
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    getWholesalerLaboratories: function getWholesalerLaboratories(params) {
      return apiClient.get("/wholesalers/".concat(params.wholesalerId, "/laboratories"), {
        includes: ["wholesaler"]
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * updateWholesalerLaboratoryFav
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    updateWholesalerLaboratoryFav: function updateWholesalerLaboratoryFav(params) {
      return apiClient.put("/wholesalers/".concat(params.wholesalerId, "/laboratories/").concat(params.wholesalerLaboratoryId, "/update/fav"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * getWholesalerLaboratoryConditions
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    getWholesalerLaboratoryConditions: function getWholesalerLaboratoryConditions(params) {
      return apiClient.get("/wholesalers/".concat(params.wholesalerId, "/laboratories/").concat(params.wholesalerLaboratoryId, "/conditions"), {
        includes: ["wholesalerLaboratory"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * getContacts
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    getContacts: function getContacts(params) {
      return apiClient.get("/laboratories/contacts/laboratory/".concat(params.id));
    },

    /**
     * getWithCondition
     *
     * @returns {Promise} promise
     */
    getWithCondition: function getWithCondition() {
      return apiClient.get("/laboratories/conditions", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.laboratories;
      });
    },

    /**
     * readConditions
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    readConditions: function readConditions(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/conditions"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.laboratory;
      });
    },

    /**
     * readBranding
     *
     * @param {object} laboratory - The laboratory.
     *
     * @returns {Promise} promise
     */
    readBranding: function readBranding(laboratory) {
      return this.getBranding(laboratory, ["laboratory", "logo", "background", "sliders", "parentRanges", "parentRanges.products", "parentRanges.branding", "parentRanges.branding.logo", "parentRanges.branding.background", "parentRanges.branding.sliders", "parentRanges.childrenRanges.products", "parentRanges.childrenRanges.branding", "parentRanges.childrenRanges.branding.logo", "parentRanges.childrenRanges.branding.background", "parentRanges.childrenRanges.branding.sliders"]);
    },

    /**
     * getBranding
     *
     * @param {object} laboratory - The laboratory.
     * @param {Array} includes - Includes to add to the query.
     *
     * @returns {Promise} promise
     */
    getBranding: function getBranding(laboratory, includes) {
      return apiClient.get("/laboratories/".concat(laboratory.laboratoryId, "/branding"), {
        includes: includes,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.laboratoryBranding;
      });
    },

    /**
     * readMinimalBranding
     *
     * @param {object} laboratory - The laboratory.
     *
     * @returns {Promise} promise
     */
    readMinimalBranding: function readMinimalBranding(laboratory) {
      return this.getBranding(laboratory, ["laboratory", "logo"]);
    },

    /**
     * readBrandingWithoutProducts
     *
     * @param {object} laboratory - The laboratory.
     *
     * @returns {Promise} promise
     */
    readBrandingWithoutProducts: function readBrandingWithoutProducts(laboratory) {
      return this.getBranding(laboratory, ["laboratory.organization", "logo", "background", "sliders", "parentRanges", "parentRanges.branding", "parentRanges.branding.logo", "parentRanges.branding.background", "parentRanges.branding.sliders", "parentRanges.childrenRanges.products", "parentRanges.childrenRanges.branding", "parentRanges.childrenRanges.branding.logo", "parentRanges.childrenRanges.branding.background", "parentRanges.childrenRanges.branding.sliders"]);
    },

    /**
     * read
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    read: function read(params) {
      return apiClient.get("/laboratories/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * getRelatedLaboratoryIds
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    getRelatedLaboratoryIds: function getRelatedLaboratoryIds(params) {
      return apiClient.get("/product/laboratory/".concat(params.id, "/getRelatedLaboratoryIds"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * getRange
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    getRange: function getRange(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/ranges"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * readRange
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    readRange: function readRange(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * createRange
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    createRange: function createRange(params) {
      return apiClient.post("/laboratories/".concat(params.laboratoryId, "/ranges"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * updateRange
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    updateRange: function updateRange(params) {
      return apiClient.put("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * create wholesaler laboratory conditions
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    createWholesalerLaboratoryCondition: function createWholesalerLaboratoryCondition(params) {
      return apiClient.post("/wholesalers/".concat(params.wholesalerId, "/laboratories/").concat(params.wholesalerLaboratoryId, "/conditions"), params, {
        includes: ["wholesalerLaboratory"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * update wholesaler laboratory conditions
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    updateWholesalerLaboratoryCondition: function updateWholesalerLaboratoryCondition(params) {
      return apiClient.put("/wholesalers/laboratories/conditions/".concat(params.wholesalerLaboratoryConditionId, "/update"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * delete wholesaler laboratory conditions
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    deleteWholesalerLaboratoryCondition: function deleteWholesalerLaboratoryCondition(params) {
      return apiClient["delete"]("/wholesalers/laboratories/conditions/".concat(params.wholesalerLaboratoryConditionId, "/delete"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * deleteRange
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    deleteRange: function deleteRange(params) {
      return apiClient["delete"]("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * getProductRange
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    getProductRange: function getProductRange(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id, "/products"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * assignProductRange
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    assignProductRange: function assignProductRange(params) {
      return apiClient.post("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id, "/products/assign"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * unassignProductRange
     *
     * @param {object} params - The params
     *
     * @returns {Promise} promise
     */
    unassignProductRange: function unassignProductRange(params) {
      return apiClient["delete"]("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id, "/products/unassign"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get laboratories list ??
     *
     * @param {string} query - Query.
     *
     * @returns {Promise}
     */
    getLabosList: function getLabosList(query) {
      return apiClient.get("/product/laboratory?".concat(query), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get disruption laboratories
     *
     * @returns {Promise}
     */
    getDisruptionLaboratories: function getDisruptionLaboratories() {
      return apiClient.get("/partnerships/apodis-disruption/laboratory?include=morphable", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.laboratory").service("laboratoriesService", LaboratoriesService);