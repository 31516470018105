"use strict";
/**
 * Filemanager controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $mdSidenav - $mdSidenav.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $mdToast - $mdToast.
 * @param {string} apiurl - API URL.
 * @param {object} notifyUserService - Notify user service.
 * @param {number} uploadFilesizeLimit - Upload filesize limit.
 * @param {object} filemanagerService - Filemanager service.
 * @param {object} sessionStorageService - Session storage service.
 */

FileManagerController.$inject = ["$rootScope", "$scope", "$mdSidenav", "$mdDialog", "$mdToast", "apiurl", "notifyUserService", "uploadFilesizeLimit", "filemanagerService", "sessionStorageService"];

function FileManagerController($rootScope, $scope, $mdSidenav, $mdDialog, $mdToast, apiurl, notifyUserService, uploadFilesizeLimit, filemanagerService, sessionStorageService) {
  var vm = this;
  var token = sessionStorageService.getToken();
  vm.goBack = goBack;
  vm.openFile = openFile;
  vm.browseDirectory = browseDirectory;
  vm.browseShareDirectory = browseShareDirectory;
  vm.browseSharerEntityTypes = browseSharerEntityTypes;
  vm.browseSharerEntities = browseSharerEntities;
  vm.browseSharerFiles = browseSharerFiles;
  vm.toggleView = toggleView;
  vm.uploadFile = uploadFile;
  vm.selectFile = selectFile;
  vm.deleteFile = deleteFile;
  vm.fileSuccess = fileSuccess;
  vm.fileAdded = fileAdded;
  vm.downloadFile = downloadFile;
  vm.createFolder = createFolder;
  vm.toggleSidenav = toggleSidenav;
  vm.toggleDetails = toggleDetails;
  vm.toggleVisibility = toggleVisibility;
  vm.getFiles = getFiles;
  vm.getTypes = getTypes;
  vm.openShareDialog = openShareDialog;
  vm.openMoveDialog = openMoveDialog;
  vm.fileError = fileError;
  $scope.addSelectedFile = addSelectedFile;
  $scope.deleteFiles = deleteFiles; // $rootScope.showLeftMenuToggle = false;

  $rootScope.showBricksMenu = false;
  $rootScope.showLeftMenu = false;
  vm.files = [];
  vm.apiurl = apiurl;
  vm.showDetails = true;
  vm.currentView = "list";
  vm.breadcrumb = [{
    name: "Mes fichiers",
    location: "/"
  }];
  vm.shareView = false;
  vm.token = token;
  vm.ngFlowOptions = {
    target: function target() {
      return vm.apiurl + "/filemanager/upload?location=" + vm.currentLocation;
    },
    chunkSize: uploadFilesizeLimit,
    maxChunkRetries: 0,
    simultaneousUploads: 1,
    testChunks: false,
    progressCallbacksInterval: 1000,
    allowDuplicateUploads: true
  };
  vm.ngFlow = {
    flow: {}
  };
  $scope.loaderFiles = {
    loading: true,
    loader: "Récupération de vos documents...",
    empty: "Vous n'avez aucun document."
  };
  $scope.selectedFiles = [];
  loader();
  /**
   * Loader.
   */

  function loader() {
    getFiles();
    getTypes();
  }
  /**
   * Get files
   *
   * @param {string} location -
   */


  function getFiles() {
    var location = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "/";
    $scope.loaderFiles.loading = true;
    vm.files = [];
    filemanagerService.getFiles(location).then(function (response) {
      var files = response.files.map(formatFile);
      vm.files = files;
      vm.selected = vm.files[0];
      $scope.selectedFiles = [];
      $scope.loaderFiles.loading = false;
    });
  }
  /**
   * Add selected file.
   *
   * @param {object} file - File.
   */


  function addSelectedFile(file) {
    var hasFile = false;
    $scope.selectedFiles.forEach(function (item, index) {
      if (item.id === file.id) {
        hasFile = true;
        $scope.selectedFiles.splice(index, 1);
      }
    });

    if (!hasFile) {
      $scope.selectedFiles.push(file);
    }
  }
  /**
   * Format file
   *
   * @param {object} file - File.
   *
   * @returns {object}
   */


  function formatFile(file) {
    var owner = file.user.given + " " + file.user.familyBirth;
    var type = null;
    var icon = null;
    var renderEndpoint = null;
    var source = null;
    var thumb = null;
    var size = null;

    if (file.isFolder) {
      type = "Dossier";
      icon = "icon-folder";
    } else if (file.mime) {
      type = file.mime.type.name;
      icon = file.mime.type.icon;
    } else {
      type = "Inconnu";
      icon = "icon-help";
    }

    if (file.mime && file.mime.isRendable) {
      renderEndpoint = vm.apiurl + "/filemanager/image/render" + "?id=" + file.id + "&token=" + file.token;
      source = renderEndpoint + "&w=25&h=25&fit=crop&sharp=2";
      thumb = renderEndpoint + "&w=200&h=200&fit=crop&sharp=8";
    }

    size = file.humanSize ? file.humanSize : "-";
    file.owner = owner;
    file.type = type;
    file.icon = icon;
    file.source = source;
    file.thumb = thumb;
    file.size = size;
    file.checked = false;
    file.created = moment(file.createdAt).subtract(10, "days").calendar();
    file.modified = moment(file.updatedAt).subtract(10, "days").calendar();
    return file;
  }
  /**
   * Select an item
   *
   * @param {object} item -
   */


  function selectFile(item) {
    vm.selected = item;
  }
  /**
   * Open a file.
   *
   * @param {object} file -
   */


  function openFile(file) {
    window.open(vm.apiurl + "/filemanager/file/show?id=" + file.id + "&token=" + file.token);
  }
  /**
   * Download a file
   *
   * @param {object} file -
   */


  function downloadFile(file) {
    window.open(vm.apiurl + "/filemanager/file/download?path=" + file.path + "&token=" + token);
  }
  /**
   * Create a folder.
   *
   * @param {object} event -
   */


  function createFolder(event) {
    var location = vm.currentLocation;
    var dialog = $mdDialog.prompt().title("Créer un dossier").placeholder("Nom du dossier").ariaLabel("Nom du dossier").targetEvent(event).ok("Valider").cancel("Annuler");
    $mdDialog.show(dialog).then(function (name) {
      if (!name) {
        $mdToast.show($mdToast.simple().textContent("Le nom du dossier est obligatoire").position("top right"));
        return;
      }

      filemanagerService.createDirectory(location, {
        name: name
      }).then(function () {
        $mdToast.show($mdToast.simple().textContent("Le dossier « " + name + " » est ajouté").position("top right"));
        getFiles(location);
      }, function (response) {
        $mdToast.show($mdToast.simple().textContent(response.data.error.message).position("top right"));
      });
    });
  }
  /**
   * Upload file.
   */


  function uploadFile() {
    vm.ngFlow.flow.opts.headers = {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + token
    };
    vm.ngFlow.flow.upload();
  }
  /**
   * Delete a file
   *
   * @param {object} file -
   */


  function deleteFile(file) {
    if (!file) {
      return;
    }

    var label = file.isFolder ? "dossier" : "fichier";
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression définitive du " + label + " « " + file.name + " » ?").ariaLabel("Suppression").targetEvent(event).ok("Oui").cancel("Non");
    $mdDialog.show(confirm).then(function () {
      filemanagerService.deleteFile(file.path).then(function () {
        $mdToast.show($mdToast.simple().textContent("Le " + label + " « " + file.name + " » est supprimé").position("top right"));
        getFiles(vm.currentLocation);
      });
    });
  }
  /**
   * Delete selectedFiles array
   */


  function deleteFiles() {
    var text = "Confirmez-vous la suppression définitive des éléments selectionnés ?";

    if ($scope.selectedFiles.length === 1) {
      var label = $scope.selectedFiles[0].isFolder ? "dossier" : "fichier";
      text = "Confirmez-vous la suppression définitive du " + label + " « " + $scope.selectedFiles[0].name + " » ?";
    }

    var confirm = $mdDialog.confirm().title("Suppression").textContent(text).ariaLabel("Suppression").targetEvent(event).ok("Oui").cancel("Non");
    $mdDialog.show(confirm).then(function () {
      $scope.selectedFiles.forEach(function (file) {
        var label = file.isFolder ? "dossier" : "fichier";
        filemanagerService.deleteFile(file.path).then(function () {
          notifyUserService.showSimpleToast("Le " + label + " « " + file.name + " » est supprimé", {
            color: "success",
            hideDelay: 1000
          });
          getFiles(vm.currentLocation);
        });
      });
    });
  }
  /**
   * Browse a directory.
   *
   * @param {object} file -
   */


  function browseDirectory(file) {
    $scope.selectedFiles = [];
    vm.selected = null;
    setCurrentLocation(file);
    getFiles(vm.currentLocation);
  }
  /**
   * Browse the share directory.
   */


  function browseShareDirectory() {
    $scope.selectedFiles = [];
    vm.selected = null;
    setCurrentLocation({
      name: "Partagés avec moi"
    });
  }
  /** Browse sharer entity types. */


  function browseSharerEntityTypes() {
    $scope.selectedFiles = [];
    vm.selected = null;
    filemanagerService.loadSharersTypes().then(function (entityTypes) {
      vm.entityTypes = entityTypes;
      vm.breadcrumb.push({
        name: "Partagés avec moi",
        location: "/shared"
      });
      vm.shareView = true;
    });
  }
  /**
   * Browse sharer entities.
   *
   * @param {object} entityType - The entity type instance.
   */


  function browseSharerEntities(entityType) {
    $scope.selectedFiles = [];
    vm.selected = null;
    filemanagerService.loadSharers({
      id: entityType.id
    }).then(function (entities) {
      vm.entities = entities;
      vm.sharerEntityTypeId = entityType.id;
      vm.breadcrumb.push({
        name: entityType.name,
        location: "/shared/type"
      });
      vm.shareView = true;
    });
  }
  /**
   * Browse sharer files.
   *
   * @param {number} entityTypeId - The entity type ID.
   * @param {object} entity - The entity instance.
   */


  function browseSharerFiles(entityTypeId, entity) {
    $scope.selectedFiles = [];
    vm.selected = null;
    filemanagerService.loadSharingFiles({
      type_id: entityTypeId,
      entity_id: entity.id
    }).then(function (files) {
      var sharedFiles = files;
      vm.sharedFiles = sharedFiles.map(function (file) {
        return formatFile(file);
      });
      vm.breadcrumb.push({
        name: entity.name,
        location: "/shared/type/entity"
      });
      vm.shareView = true;
    });
  }
  /**
   * Set current location.
   *
   * @param {object} file -
   */


  function setCurrentLocation(file) {
    vm.currentLocation += vm.currentLocation === "/" ? file.name : "/" + file.name;
    vm.breadcrumb.push({
      name: file.name,
      location: vm.currentLocation
    });
  }
  /**
   * Get file types.
   */


  function getTypes() {
    filemanagerService.getFileTypes().then(function (response) {
      vm.types = response.types;
    });
  }
  /**
   * Go back.
   */


  function goBack() {
    vm.breadcrumb.pop();

    if (!vm.shareView) {
      getFiles(vm.previousLocation);
    }

    if (vm.breadcrumb.length === 1) {
      vm.shareView = false;
    }
  }
  /**
   * File upload success callback.
   *
   * @param {object} file -
   */


  function fileSuccess(file) {
    $mdToast.show($mdToast.simple().textContent("Le fichier « " + file.name + " » est ajouté").position("top right"));
    getFiles(vm.currentLocation);
  }
  /**
   * Validate file when added.
   *
   * @param {object} file -
   *
   * @returns {boolean}
   */


  function fileAdded(file) {
    var oneMegaBytes = 1048576; // 1 * 1024 * 1024

    var isUnderFilesize = file.size < uploadFilesizeLimit;

    if (!isUnderFilesize) {
      notifyUserService.showDangerToast("Votre fichier ne doit pas dépasser " + uploadFilesizeLimit / oneMegaBytes + "Mo.");
    }

    return isUnderFilesize;
  }
  /**
   * File upload error callback.
   *
   * @param {object} file -
   * @param {string} message -
   */


  function fileError(file, message) {
    $mdToast.show($mdToast.simple().textContent(JSON.parse(message).message).position("top right"));
  }
  /**
   * Toggle visibility
   *
   * @param {object} file -
   */


  function toggleVisibility(file) {
    filemanagerService.updateFile(file.path, {
      isPublic: file.isPublic
    });
  }
  /**
   * Toggle details
   *
   * @param {object} item -
   */


  function toggleDetails(item) {
    vm.selected = item;
    toggleSidenav("details-sidenav");
  }
  /**
   * Toggle sidenav
   *
   * @param {number} sidenavId -
   */


  function toggleSidenav(sidenavId) {
    $mdSidenav(sidenavId).toggle();
  }
  /**
   * Toggle view.
   */


  function toggleView() {
    vm.currentView = vm.currentView === "list" ? "grid" : "list";
  }
  /**
   * Open dialog for sharing.
   *
   * @param {object} file - The file to share.
   */


  function openShareDialog(file) {
    $mdDialog.show({
      controller: function controller() {
        var vm = this;
        vm.searchConfig = {
          event: "addShare",
          search: ["laboratory.id", "idOrgas", "group.id", "user"],
          tag: "share",
          searchPlaceHolder: "Rechercher des laboratoires, des groupements et des pharmacies"
        };
        vm.shares = file.shares;
        vm.deleteShare = deleteShare;
        vm.close = close;
        $scope.$on("addShare", function (event, results) {
          for (var type in results) {
            if (Object.prototype.hasOwnProperty.call(results, type)) {
              var _ret = function () {
                var entityTypeId = null;

                switch (type) {
                  case "laboratory":
                    entityTypeId = 1;
                    break;

                  case "pharmas":
                    entityTypeId = 2;
                    break;

                  case "groups":
                    entityTypeId = 3;
                    break;

                  default:
                    return "continue";
                }

                angular.forEach(results[type], function (value) {
                  if (!isShareExists(entityTypeId, value.id)) {
                    createShare(file.id, {
                      entityTypeId: entityTypeId,
                      entityId: value.id,
                      isWritable: null
                    });
                  }
                });
              }();

              if (_ret === "continue") continue;
            }
          }
        });
        /** Close the dialog. */

        function close() {
          $mdDialog.cancel();
        }
        /**
         * Is share exists.
         *
         * @param {number} entityTypeId - The entity type ID.
         * @param {number} entityId - The entity ID.
         *
         * @returns {boolean}
         */


        function isShareExists(entityTypeId, entityId) {
          var isExists = false;
          angular.forEach(vm.shares, function (share) {
            if (share.entityTypeId === entityTypeId && share.entityId === entityId) {
              isExists = true;
            }
          });
          return isExists;
        }
        /**
         * Create a share.
         *
         * @param {number} fileId - The ID of the file.
         * @param {object} data - The share data.
         */


        function createShare(fileId, data) {
          filemanagerService.createShare(fileId, data).then(function (response) {
            vm.shares.push(response.share);
          });
        }
        /**
         * Delete a share.
         *
         * @param {number} index - The index of the shares container.
         * @param {number} shareId - The ID of the share.
         */


        function deleteShare(index, shareId) {
          $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression du partage ?").ariaLabel("Suppression du partage").ok("Oui").cancel("Non")).then(function () {
            filemanagerService.deleteShare(shareId).then(function () {
              vm.shares.splice(index, 1);
            });
          }, function () {
            $mdDialog.cancel();
          });
        }
      },
      controllerAs: "vm",
      templateUrl: "app/main/file-manager/share/share.html",
      parent: angular.element(document.body)
    });
  }
  /**
   * Open dialog for moving.
   *
   * @param {object} file - The file to move.
   */


  function openMoveDialog(file) {
    $mdDialog.show({
      controller: ["$scope", function controller($scope) {
        $scope.close = close;
        $scope.validate = validate;
        filemanagerService.getFolders().then(function (folders) {
          $scope.folders = folders;
        });
        /**
         * Close.
         */

        function close() {
          $mdDialog.cancel();
        }
        /**
         * Validate.
         */


        function validate() {
          filemanagerService.moveFile(file.id, $scope.selectedFolder).then(function () {
            $mdToast.show($mdToast.simple().textContent("Le fichier ".concat(file.name, " est d\xE9plac\xE9")).position("top right"));
            getFiles(vm.currentLocation);
            $mdDialog.cancel();
          });
        }
      }],
      templateUrl: "app/main/file-manager/views/move.html",
      parent: angular.element(document.body)
    });
  }

  $scope.$watch("vm.breadcrumb.length", function () {
    vm.currentLocation = vm.breadcrumb.slice(-1)[0].location;
    vm.previousLocation = vm.breadcrumb.slice(-2)[0].location;
  });
}

angular.module("app.file-manager").controller("FileManagerController", FileManagerController);