"use strict";
/**
 * Prestation map controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $interval - $interval.
 * @param {object} $timeout - $timeout.
 * @param {string} apiurl - API URL.
 * @param {object} prestationService - Prestation service.
 */

PrestationMapController.$inject = ["$scope", "$state", "$stateParams", "$interval", "$timeout", "apiurl", "prestationService"];

function PrestationMapController($scope, $state, $stateParams, $interval, $timeout, apiurl, prestationService) {
  var vm = this;
  vm.categoryFiltered = "all";
  vm.token = window.sessionStorage.token;
  vm.apiurl = apiurl;
  vm.photoNumber = 0;
  vm.status = [];
  getAllBenefitPhoto();
  $scope.start = $interval(getAllBenefitPhoto, 10000);
  var dereg = $scope.$on("$destroy", function (item) {
    if (item.targetScope.state.current.name !== "app.prestation.map") {
      $interval.cancel($scope.start);
    }

    dereg();
  });
  vm.map;
  var overlay;
  USGSOverlay.prototype = new google.maps.OverlayView();
  /**
   * USGSOverlay.
   *
   * @param {object} bounds - Bounds.
   * @param {object} image - Image.
   * @param {object} map - Map.
   */

  function USGSOverlay(bounds, image, map) {
    // Initialize all properties.
    this.bounds_ = bounds;
    this.image_ = image;
    this.map_ = map; // Define a property to hold the image"s div. We"ll
    // actually create this div upon receipt of the onAdd()
    // method so we"ll leave it null for now.

    this.div_ = document.createElement("div"); // Explicitly call setMap on this overlay.

    this.setMap(map);
  }
  /**
   * onAdd is called when the map"s panes are ready and the overlay has been
   * added to the map.
   */


  USGSOverlay.prototype.onAdd = function () {
    var me = this;
    var div = this.div_;
    $timeout(function () {
      div.style.visibility = "hidden";
    }, 5000, this);
    div.className = "benefitPicture";
    div.style.borderStyle = "none";
    div.style.borderWidth = "0px";
    div.style.position = "absolute"; // Create the img element and attach it to the div.

    var img = document.createElement("img");
    img.className = "imageUserMap";
    img.src = this.image_;
    img.style.width = "100%";
    img.style.height = "100%";
    img.style.position = "absolute";
    div.appendChild(img); // animate new photo map

    $timeout(function () {
      if (img.className === "imageUserMap") {
        img.className = "imageUserMapBounce";
      } else {
        img.className = "imageUserMap";
      }
    }, 6500);
    this.div_ = div; // Add the element to the "overlayLayer" pane.

    var panes = this.getPanes();

    if (typeof panes.overlayImage !== "undefined") {
      panes.overlayImage.appendChild(div);
    }

    me.accessId = this.accessId; // link photo page

    google.maps.event.addDomListener(div, "click", function () {
      vm.benefitInfo(me.accessId);
    });
  };

  USGSOverlay.prototype.draw = function () {
    // We use the south-west and north-east
    // coordinates of the overlay to peg it to the correct position and size.
    // To do this, we need to retrieve the projection from the overlay.
    var overlayProjection = this.getProjection(); // Retrieve the south-west and north-east coordinates of this overlay
    // in LatLngs and convert them to pixel coordinates.
    // We"ll use these coordinates to resize the div.

    var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
    var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast()); // Resize the image"s div to fit the indicated dimensions.

    var div = this.div_;
    div.style.left = sw.x - 75 + "px";
    div.style.top = ne.y - 75 + "px";
    div.style.width = "150px";
    div.style.height = "150px";
  }; // The onRemove() method will be called automatically from the API if
  // we ever set the overlay"s map property to "null".


  USGSOverlay.prototype.onRemove = function () {
    this.div_.parentNode.removeChild(this.div_);
  }; // Set the visibility to "hidden" or "visible".


  USGSOverlay.prototype.hide = function () {
    if (this.div_) {
      // The visibility property must be a string enclosed in quotes.
      this.div_.style.visibility = "hidden";
    }
  };

  USGSOverlay.prototype.show = function () {
    if (this.div_) {
      this.div_.style.visibility = "visible";
    }
  };

  USGSOverlay.prototype.toggle = function () {
    if (this.div_) {
      if (this.div_.style.visibility === "hidden") {
        this.show();
      } else {
        this.hide();
      }
    }
  }; // Detach the map from the DOM via toggleDOM().
  // Note that if we later reattach the map, it will be visible again,
  // because the containing <div> is recreated in the overlay"s onAdd() method.


  USGSOverlay.prototype.toggleDOM = function () {
    if (this.getMap()) {
      // Note: setMap(null) calls OverlayView.onRemove()
      this.setMap(null);
    } else {
      this.setMap(this.map_);
    }
  };
  /**
   *
   */


  function initialize() {
    var myLatLng = {
      lat: 47.6468754,
      lng: 2.3242131
    };
    var mapOptions = {
      center: new google.maps.LatLng(myLatLng),
      zoom: 6,
      minZoom: 6,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_CENTER
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      addressControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      fullscreenControl: true
    };
    vm.map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions); // bounds of the France area

    var allowedBounds = new google.maps.LatLngBounds(new google.maps.LatLng(41.647109, -3.548551), new google.maps.LatLng(51.485908, 8.625279));
    var boundLimits = {
      maxLat: allowedBounds.getNorthEast().lat(),
      maxLng: allowedBounds.getNorthEast().lng(),
      minLat: allowedBounds.getSouthWest().lat(),
      minLng: allowedBounds.getSouthWest().lng()
    };
    var lastValidCenter = vm.map.getCenter();
    var newLat;
    var newLng;
    google.maps.event.addListener(vm.map, "center_changed", function () {
      var center = vm.map.getCenter();

      if (allowedBounds.contains(center)) {
        // still within valid bounds, so save the last valid position
        lastValidCenter = vm.map.getCenter();
        return;
      }

      newLat = lastValidCenter.lat();
      newLng = lastValidCenter.lng();

      if (center.lng() > boundLimits.minLng && center.lng() < boundLimits.maxLng) {
        newLng = center.lng();
      }

      if (center.lat() > boundLimits.minLat && center.lat() < boundLimits.maxLat) {
        newLat = center.lat();
      }

      vm.map.panTo(new google.maps.LatLng(newLat, newLng));
    });
  }

  initialize();
  var colorMarker = {
    validate: "green",
    refuse: "red",
    normal: "orange"
  };
  /**
   * Marker map.
   *
   * @param {object} photo - Photo.
   */

  function markerMap(photo) {
    var marker;

    if (typeof vm.photo[photo.organizationId] === "undefined") {
      vm.photo[photo.organizationId] = 0;
    }

    if (typeof vm.photos[photo.organizationId] === "undefined") {
      vm.photos[photo.organizationId] = [];
    }

    vm.photos[photo.organizationId].push(photo);
    vm.photo[photo.organizationId]++;
    var markerLatLng = new google.maps.LatLng({
      lat: photo.organization.morphable.address.latitude,
      lng: photo.organization.morphable.address.longitude
    });

    if (vm.photos[photo.organizationId].length) {
      marker = new google.maps.Marker({
        id: photo.id,
        position: markerLatLng,
        map: vm.map,
        accessId: photo.organizationId,
        category: vm.status[photo.organizationId].status,
        icon: vm.apiurl + "/prestation/icon?number=" + vm.photo[photo.organizationId] + "&color=" + colorMarker[vm.status[photo.organizationId].status] + "&token=" + vm.token
      });
    }

    if (marker) {
      if (vm.categoryFiltered === marker.category || vm.categoryFiltered === "all") {
        marker.setVisible(true);
      } else {
        marker.setVisible(false);
      }

      marker.addListener("click", function () {
        vm.benefitInfo(photo.organizationId);
      });
      marker.setMap(vm.map);
    }

    vm.markers.push(marker);
  }

  vm.filterMapPrestation = function (status) {
    for (var index = 0; index < vm.markers.length; index++) {
      // If is same category or category not picked
      if (vm.markers[index].category === status) {
        vm.markers[index].setVisible(true);
      } else if (status !== "all") {
        // Categories don't match
        vm.markers[index].setVisible(false);
      } else {
        vm.markers[index].setVisible(true);
      }
    }

    vm.categoryFiltered = status;
  };
  /**
   *
   */


  function getAllBenefitPhoto() {
    vm.photo = [];
    vm.photos = [];
    prestationService.getAllBenefitPhotos($stateParams.id).then(function (response) {
      vm.markers = [];
      vm.allBenefitPhoto = response.photos;
      vm.photoNumber = response.photos.length;
      vm.newPhoto = null; // display last photo update

      angular.forEach(vm.allBenefitPhoto, function (newPhoto) {
        vm.positionLat = newPhoto.latitude;
        vm.positionLong = newPhoto.longitude;
        var bounds = new google.maps.LatLngBounds(new google.maps.LatLng(vm.positionLat, vm.positionLong), new google.maps.LatLng(vm.positionLat, vm.positionLong)); // Insert new image map.

        var srcImage = vm.apiurl + "/user/me/prestation/photo/" + newPhoto.id + "/render?w=300&h=300&fit=crop&sharp=10&token=" + newPhoto.token; // The custom USGSOverlay object contains the USGS image,
        // the bounds of the image, and a reference to the map.

        overlay = new USGSOverlay(bounds, srcImage, vm.map);
        overlay.photoId = newPhoto.id;
        overlay.accessId = newPhoto.organizationId;

        if (newPhoto.isNew) {
          vm.newPhoto = newPhoto;
        } else {
          overlay.hide();
        }
      });
      prestationService.readWithoutMembers($stateParams.id).then(function (response) {
        vm.readPrestation = response.prestation;
        countObjectivePicture();
      });
    });
  }
  /**
   *
   */


  function countObjectivePicture() {
    vm.status = [];

    if (vm.readPrestation.rewards.length) {
      angular.forEach(vm.readPrestation.rewards, function (reward) {
        angular.forEach(vm.allBenefitPhoto, function (photo) {
          if (typeof vm.status[photo.organizationId] === "undefined") {
            vm.status[photo.organizationId] = {
              normal: 0,
              validate: 0,
              refuse: 0
            };
          }

          vm.status[photo.organizationId].status = "normal";

          if (photo.status === 1) {
            vm.status[photo.organizationId].validate++;
          }

          if (photo.status === 2) {
            vm.status[photo.organizationId].refuse++;
          }

          if (vm.status[photo.organizationId].validate >= reward.objective) {
            vm.status[photo.organizationId].status = "validate";
          } else if (vm.status[photo.organizationId].refuse > vm.status[photo.organizationId].validate && vm.status[photo.organizationId].refuse + vm.status[photo.organizationId].validate >= reward.objective) {
            vm.status[photo.organizationId].status = "refuse";
          } else {
            vm.status[photo.organizationId].status = "normal";
          }

          markerMap(photo);
        });
      });
    } else {
      angular.forEach(vm.allBenefitPhoto, function (photo) {
        if (typeof vm.status[photo.organizationId] === "undefined") {
          vm.status[photo.organizationId] = {
            normal: 0,
            validate: 0,
            refuse: 0
          };
        }

        vm.status[photo.organizationId].status = "normal";
        markerMap(photo);
      });
    }
  }

  vm.benefitInfo = function (accessId) {
    $state.go("app.prestation.benefitInfo", {
      id: $stateParams.id,
      picture: vm.photos[accessId]
    });
  };

  vm.goToList = function () {
    $state.go("app.prestation");
  };
}

angular.module("app.prestation").controller("PrestationMapController", PrestationMapController);