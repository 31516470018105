"use strict";
/**
 * Export service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

ExportService.$inject = ["apiClient"];

function ExportService(apiClient) {
  return {
    /**
     * Get available columns.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getAvailableColumns: function getAvailableColumns(data) {
      return apiClient.post("/export/availableColumns", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.directives").service("exportService", ExportService);