"use strict";
/**
 * Index controller.
 *
 * @param {object} fuseTheming - Fuse theming.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $location - $location.
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $q - $q.
 * @param {Array} allowedStates - Allowed states.
 * @param {object} authService - Authentication service.
 * @param {object} contractManagementService - Contract management service.
 * @param {object} AUTH_EVENTS - Authentication events.
 * @param {object} msNavigationService - MS navigation service.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} accessService - Access service.
 * @param {string} mapAPIKey - Google map API key.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} $sce - $sce.
 * @param {object} environmentService - Environment service.
 * @param {string} gtagKeyId - Google tag key id.
 * @param {object} $window - $window.
 * @param {string} apiurl - API URL.
 * @param {object} apodisThemingFactory - Apodisotheming factory.
 * @param {object} userSelloutService - User sellout service.
 * @param {object} amplitudeService - Amplitude service.
 * @param {string} amplitudeAPIKey - Amplitude API key.
 * @param {object} sessionStorageService - Session storage service.
 */

IndexController.$inject = ["fuseTheming", "$rootScope", "$location", "$scope", "$state", "$q", "allowedStates", "authService", "contractManagementService", "AUTH_EVENTS", "msNavigationService", "notifyUserService", "accessService", "mapAPIKey", "StatisticsQueryService", "$sce", "environmentService", "gtagKeyId", "$window", "apiurl", "apodisThemingFactory", "userSelloutService", "amplitudeService", "amplitudeAPIKey", "sessionStorageService"];

function IndexController(fuseTheming, $rootScope, $location, $scope, $state, $q, allowedStates, authService, contractManagementService, AUTH_EVENTS, msNavigationService, notifyUserService, accessService, mapAPIKey, StatisticsQueryService, $sce, environmentService, gtagKeyId, $window, apiurl, apodisThemingFactory, userSelloutService, amplitudeService, amplitudeAPIKey, sessionStorageService) {
  var vm = this;
  amplitudeService.init(amplitudeAPIKey);
  $rootScope.unvalidatedContracts = [];
  $rootScope.refusedContracts = [];
  $scope.hasRighToValidatedContract = false;
  $rootScope.showBricksMenu = true;
  $rootScope.showLeftMenuToggle = true;
  $rootScope.showLeftMenu = false;
  $scope.openPdf = openPdf;
  $scope.getUnvalidatedContracts = getUnvalidatedContracts;
  $scope.createValidation = createValidation;
  $scope.updateValidation = updateValidation;
  $rootScope.validateContractLoading = false;
  var noLeftMenuStates = ["app.santesecure", "app.filemanager"];

  if ($location.path() !== "/accueil" && noLeftMenuStates.indexOf($scope.currentState) === -1) {
    $rootScope.showBricksMenu = false;
    $rootScope.showLeftMenu = true;
  }

  var ACCEPTED_CONTRACT_STATUS = 1;
  var REFUSED_CONTRACT_STATUS = 2;
  vm.themes = fuseTheming.themes;
  vm.mapAPIKey = mapAPIKey;
  vm.gtagKeyId = gtagKeyId;
  vm.scriptGmap = $sce.trustAsResourceUrl("https://maps.google.com/maps/api/js?v=3&key=" + vm.mapAPIKey + "&libraries=geometry,places&callback=initializeGmap");
  vm.gtagScript = $sce.trustAsResourceUrl("https://www.googletagmanager.com/gtag/js?id={{vm.gtagKeyId}}"); // eslint-disable-next-line no-undef

  window.dataLayer = window.dataLayer || [];
  $scope.contractTitle = {
    icon: "icon-file-document",
    text: "Contrats à accepter",
    type: "2"
  };
  $scope.queryContracts = {
    order: "-startDate",
    limit: 10,
    page: 1
  };

  if (authService.isAuthenticated()) {
    loader();
  }
  /**
   * Loader.
   */


  function loader() {
    gtag("js", new Date());
    gtag("config", vm.gtagKeyId);
    loaderDomain();
    getEnvironment();
  }
  /**
   * Gtag.
   */


  function gtag() {
    // eslint-disable-next-line no-undef
    dataLayer.push(arguments);
  }
  /**
   * Get environment.
   *
   * @returns {Promise}
   */


  function getEnvironment() {
    return environmentService.getEnvironment().then(function (environment) {
      vm.currentEnv = environment;
      $scope.environement = environment;
      $scope.hasRighToValidatedContract = $scope.environement.rankId === 1 || $scope.environement.rankId === 2 || $scope.environement.rankId === 4 || $scope.environement.rankId === 5 || $scope.environement.rankId === 14;
      promiseArrayContracts();
    });
  }
  /**
   * Loader domain.
   */


  function loaderDomain() {
    var hostSplit = $location.$$host.split(".");
    var subdomain = hostSplit[0];

    switch (subdomain) {
      case "dlsante":
        vm.domainName = "dlsante";
        break;

      case "unipharm":
        vm.domainName = "unipharm";
        break;

      case "reseau-pnp":
        vm.domainName = "reseauPnp";
        break;

      case "pdapharma":
        vm.domainName = "pdaPharma";
        break;

      case "simplypharma":
        vm.domainName = "simplyPharma";
        break;

      default:
        vm.domainName = "apodisPharma";
    }

    window.sessionStorage.setItem("domainName", vm.domainName);
    window.sessionStorage.setItem("originalDomainName", subdomain);
  }
  /**
   * Create promises for refused and unvalidated contracts.
   *
   * @returns {Promise}
   */


  function promiseArrayContracts() {
    $rootScope.unvalidatedContracts = [];
    $rootScope.refusedContracts = [];
    return $q.all([getUnvalidatedContracts(), getRefusedContracts()]).then(function (response) {
      $rootScope.hasContractToSign = false;
      return response;
    });
  }
  /**
   * Get unvalidated contracts.
   *
   * @returns {Promise}
   */


  function getUnvalidatedContracts() {
    return contractManagementService.getUnvalidated().then(function (response) {
      if (response.length > 0) {
        $rootScope.unvalidatedContracts = response.filter(function (contract) {
          return contract.slug.match(new RegExp("cgu-", "i"));
        });
      }

      return response;
    });
  }
  /**
   * Get refused contracts.
   *
   * @returns {Promise}
   */


  function getRefusedContracts() {
    return contractManagementService.getRefused().then(function (response) {
      if (response.length > 0) {
        $rootScope.refusedContracts = response.filter(function (contract) {
          return contract.slug.match(new RegExp("cgu-", "i"));
        });
      }

      return response;
    });
  }
  /**
   * Update menu items by environment.
   *
   * @returns {Promise}
   */


  function updateMenuItemsByEnv() {
    return environmentService.getEnvironment().then(function (environment) {
      if (!$scope.myProfile) {
        $scope.myProfile = accessService.getMe();
      }

      vm.currentEnv = environment;

      if (environment) {
        var isntPlugged = Boolean(accessService.isHcUnplugged(vm.currentEnv.accessPointType, vm.currentEnv.rankId));
        $scope.myProfileAccess = {
          isLabo: vm.currentEnv.accessPointType === 1,
          isGroupement: vm.currentEnv.accessPointType === 3,
          isAdmin: vm.currentEnv.accessPointType === 4,
          isPharma: vm.currentEnv.accessPointType === 2 && (vm.currentEnv.rankId === 1 || vm.currentEnv.rankId === 2),
          isEmployee: vm.currentEnv.accessPointType === 2 && vm.currentEnv.rankId !== 1 && vm.currentEnv.rankId !== 2,
          isntPlugged: isntPlugged,
          hasLgo: environmentService.hasLgo()
        };
        updateNavigation();
      }

      return environment;
    });
  }
  /**
   * Update navigation.
   *
   *  @returns {Promise}
   */


  function updateNavigation() {
    if (sessionStorageService.getToken()) {
      return userSelloutService.filterUserSellouts({
        status: 0,
        moment: "unfinished"
      }).then(function (response) {
        if (response.sellouts && response.sellouts.length) {
          msNavigationService.saveItem("commercialoffers.userchallengessellout", {
            badge: {
              content: response.sellouts.length,
              color: "red"
            }
          });
        }

        return response;
      });
    }

    if (vm.currentEnv.accessPointType !== 1) {
      return manageTeamBefore();
    }

    return Promise.reject();
  }
  /**
   * Open the PDF contract.
   *
   * @param {object} contract -
   */


  function openPdf(contract) {
    var url = apiurl + "/contracts/versions/" + contract.version.id + "/show";
    $window.open(url);
    contract.read = true;
  }
  /**
   * Validate the contract version.
   *
   * @param {object} contract -
   * @param {boolean} boolean -
   * @returns {Promise}
   */


  function createValidation(contract, _boolean) {
    $rootScope.validateContractLoading = true;
    var status = _boolean ? ACCEPTED_CONTRACT_STATUS : REFUSED_CONTRACT_STATUS;
    var query = {
      version: contract.version.id,
      status_id: status
    };
    return contractManagementService.createValidation(query).then(function (response) {
      $rootScope.validateContractLoading = false;
      promiseArrayContracts();

      if (response.status.slug === "refused") {
        notifyUserService.showSimpleToast("Le contrat « " + contract.name + " » a été enregistré comme refusé.", {
          color: "success",
          hideDelay: 3000
        });
        return response;
      }

      if (response.status.slug === "accepted") {
        notifyUserService.showSimpleToast("Le contrat « " + response.version.contract.name + " » a été enregistré comme accepté.", {
          color: "success",
          hideDelay: 3000
        });
        $rootScope.$broadcast("contract.validate:refresh");
        return response;
      }

      return response;
    });
  }
  /**
   * Update the contract version.
   *
   * @param {object} contract -
   * @param {boolean} boolean -
   * @returns {Promise}
   */


  function updateValidation(contract, _boolean2) {
    $rootScope.validateContractLoading = true;
    var status = _boolean2 ? ACCEPTED_CONTRACT_STATUS : REFUSED_CONTRACT_STATUS;
    var query = {
      id: contract.version.lastValidation.id,
      version: contract.version.id,
      status_id: status
    };
    return contractManagementService.updateValidation(query).then(function (response) {
      $rootScope.validateContractLoading = false;
      promiseArrayContracts();

      if (response.status.slug === "refused") {
        notifyUserService.showSimpleToast("Le contrat « " + response.version.contract.name + " » a été enregistré comme refusé.", {
          color: "success",
          hideDelay: 3000
        });
        return response;
      }

      if (response.status.slug === "accepted") {
        notifyUserService.showSimpleToast("Le contrat « " + response.version.contract.name + " » a été enregistré comme accepté.", {
          color: "success",
          hideDelay: 3000
        });
        $rootScope.$broadcast("contract.validate:refresh");
        return response;
      }

      return response;
    });
  }
  /**
   * Manage team before.
   *
   * @returns {Promise}
   */


  function manageTeamBefore() {
    return accessService.getPendingRequest().then(function (pending) {
      if (!_.isEmpty(pending)) {
        if (vm.currentEnv.accessPointType === 3) {
          $state.go("app.teammanager.members");
        } else {
          $state.go("app.teammanager_members_pharma");
        }
      }

      return pending;
    });
  }

  $rootScope.$on("auth:login", function () {
    loader();
  });
  $rootScope.$on("EnvironmentService.switchEnvironment:done", function () {
    $rootScope.unvalidatedContracts = [];
    $rootScope.refusedContracts = [];
    StatisticsQueryService.queryRebase();
    updateMenuItemsByEnv();
    getEnvironment();
  });
  $rootScope.$on(AUTH_EVENTS.notAuthenticated, function () {
    authService.logout();

    if ($rootScope.state.current.name !== "app.pages_auth_login") {
      $rootScope.$root.myself.then(function () {
        if (typeof $rootScope.state !== "undefined") {
          $state.go("app.pages_auth_login");
        }
      });

      if (authService.isAuthenticated()) {
        notifyUserService.showSimpleToast("Vous avez été déconnecté(e)");
      }
    }
  });
  $rootScope.$on(AUTH_EVENTS.forbidden, function () {
    if ($rootScope.previousState) {
      $state.go($rootScope.previousState);
    } else if (allowedStates.indexOf($rootScope.state.current.name) < 0) {
      authService.logout();
      $state.go("app.pages_auth_login");
    }

    notifyUserService.showSimpleToast("Accès Interdit");
  });
  $rootScope.$on("$locationChangeSuccess", function () {
    apodisThemingFactory.generateDefault($rootScope.environment);
    $rootScope.actualLocation = $location.path();
  });
  $rootScope.$watch(function () {
    return $location.path();
  }, function (newLocation) {
    if ($rootScope.actualLocation === newLocation) {
      // eslint-disable-next-line no-console
      console.warn("Ne pas utiliser le bouton de retour du navigateur");

      if (newLocation === "/accueil") {
        $rootScope.$broadcast("closeMenu");
      }
    }
  });
}

angular.module("apodis").controller("IndexController", IndexController);