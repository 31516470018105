"use strict";
/**
 * Members controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} accessService - Access service.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $filter - $filter.
 * @param {object} $mdToast - $mdToast.
 * @param {object} $timeout - $timeout.
 * @param {object} environmentService - Environment service.
 * @param {object} teamService - Team service.
 */

DialogController.$inject = ["$scope", "$mdDialog", "environmentService"];
MembersController.$inject = ["$scope", "accessService", "$mdDialog", "$filter", "$mdToast", "$timeout", "environmentService", "teamService"];

function MembersController($scope, accessService, $mdDialog, $filter, $mdToast, $timeout, environmentService, teamService) {
  var vm = this;
  vm.dataReady = false;
  vm.getUser = false; // We need to know if the access is from Apodis Pharma (or white mark)

  vm.domainName = window.sessionStorage.getItem("domainName");
  vm.originalDomainName = window.sessionStorage.getItem("originalDomainName");
  getCurrentUserInterface(); // METHODES

  vm.saveAccess = saveAccess;
  vm.isModified = isModified;
  vm.showConfirmRequest = showConfirmRequest;
  vm.getRank = getRank;
  vm.tooltipDisplay = tooltipDisplay;
  vm.displayingAddresss = displayingAddresss;
  /**
   * Get current user interface.
   */

  function getCurrentUserInterface() {
    vm.user = accessService.getMe();
    environmentService.getEnvironment().then(function (env) {
      vm.currentEnv = env;
      vm.isEntityAdmin = accessService.isEntityAdmin(vm.currentEnv.rankId);

      if (vm.currentEnv.validated) {
        getTeam();
      } else {
        vm.team = [];
        vm.dataReady = true;
      }
    });
    vm.getUser = true;
  }
  /**
   * Get team.
   */


  function getTeam() {
    teamService.getMyTeam().then(function (response) {
      if (response.success) {
        vm.team = response.team;
        entityTypeColumn();
        vm.teamCopy = angular.copy(response.team);
        vm.awaitingMember = 0;
        vm.dataReady = true;

        _.each(vm.team, function (access) {
          if (access.rankId === 1 && access.isTechnicalReferent === 0) {
            access.isTechnicalReferent = 1;
          }

          access.modified = vm.currentEnv.accessPointType !== 2;

          if (access.validated === 0) {
            vm.awaitingMember++;
            tooltipDisplay();
          }
        });

        vm.limit = 10;
        vm.page = 1;
        vm.limitOptions = [vm.limit, 25, 50, {
          label: "Tous",
          value: function value() {
            return vm.team.length;
          }
        }];
        getRankList();

        if (vm.currentEnv.accessPointType === 2) {
          getOperatorsCode();
        }

        accessService.getPendingRequest().then(function (pending) {
          if (!_.isEmpty(pending)) {
            showDialog();
          }
        });
      }
    });
  }
  /**
   * Show dialog.
   */


  function showDialog() {
    $mdDialog.show($mdDialog.alert({
      parent: angular.element(document.body),
      templateUrl: "app/main/teammanager/members/membersModalDialog.html",
      controller: "dialogController"
    }));
  }
  /**
   * Get rank list.
   */


  function getRankList() {
    teamService.getRankList().then(function (response) {
      if (response.success) {
        vm.rankList = response.list;
      }
    });
  }
  /**
   * Get rank.
   *
   * @param {number} id - ID.
   *
   * @returns {string}
   */


  function getRank(id) {
    // eslint-disable-next-line consistent-return
    var access = _.find(vm.rankList, function (value) {
      if (value.id === id) {
        return value;
      }
    });

    if (!_.isUndefined(access)) {
      return access.title;
    }

    return "non défini";
  }
  /**
   * Get operators code.
   */


  function getOperatorsCode() {
    teamService.getOperatorsCode().then(function (response) {
      vm.operatorsCodes = [];
      angular.forEach(response.operators, function (code) {
        var isAlreadyAttributed = _.find(vm.team, function (access) {
          return access.operatorCode === code;
        });

        vm.operatorsCodes.push({
          code: code,
          isAlreadyAttributed: Boolean(isAlreadyAttributed)
        });
      });

      if (_.isEmpty(vm.operatorsCodes)) {
        vm.operatorsCodes.message = "nous n'avons pas de codes opérateurs enregistrés pour votre pharmacie";
      }
    });
  }
  /**
   * Displaying address.
   *
   * @param {object} obj -
   * @returns {string}
   */


  function displayingAddresss(obj) {
    var address = obj.streetLine1;

    if (obj.streetLine2) {
      address += "<br>!" + obj.streetLine2;
    }

    address += "<br>" + obj.postalCode + " " + obj.cityName;
    return address;
  }

  $scope.$parent.$parent.vm.pendingCount -= 1;
  /**
   * Save access.
   *
   * @param {string} key -
   */

  function saveAccess(key) {
    vm.team[key].validated = 1;
    teamService.updateAccess(vm.team[key]).then(function (response) {
      if (response.success) {
        $mdToast.show($mdToast.simple().textContent("Informations mises à jour.").position("top right"));
        vm.teamCopy.splice(key, 1, angular.copy(vm.team[key]));
        vm.team.splice(key, 1, angular.copy(vm.team[key]));
        isModified(key);
        vm.modified = false;
        entityTypeColumn(vm.currentEnv.accessPointType);
      } else {
        $mdToast.show($mdToast.simple().textContent("Les modifications n'ont pas été enregistrées.").position("top right"));
      }
    });
  }
  /**
   * Is modified.
   *
   * @param {number} key - Key.
   * @param {boolean} force - Force.
   */


  function isModified(key, force) {
    var target = force ? getTarget(key) : key;
    vm.team[target].modified = false; // We try to check if the code is already attributed

    angular.forEach(vm.operatorsCodes, function (operatorCode, key) {
      if (operatorCode.code === vm.teamCopy[target].operatorCode && operatorCode.code !== vm.team[target].operatorCode) {
        vm.operatorsCodes[key].isAlreadyAttributed = false;
      }

      if (operatorCode.code === vm.team[target].operatorCode) {
        vm.operatorsCodes[key].isAlreadyAttributed = true;
      }
    });

    _.each(vm.team[target], function (value, name) {
      if ((name === "rankId" || name === "operatorCode" || name === "isTechnicalReferent") && vm.teamCopy[target][name] !== value) {
        vm.team[target].modified = true;
        vm.modified = true;
        entityTypeColumn();
      }
    });
  }
  /**
   * Show a dialog to confirm the modification of a member.
   *
   * @param {number} key - Key.
   */


  function showConfirmRequest(key) {
    var target = getTarget(key);

    if (vm.currentEnv.accessPointType === 2) {
      if (vm.team[target].operatorCode !== null) {
        var person = $filter("capitalize")(vm.team[target].user.given) + " " + $filter("uppercase")(vm.team[target].user.familyBirth);
        var textContent = !vm.team[target].validated ? person + " rejoindra votre équipe officinale et ses informations seront mises à jour." : " les informations de " + person + " seront mises à jour.";
        var submitText = !vm.team[target].validated ? "Accepter la demande et enregistrer" : "Enregistrer"; // Appending dialog to document.body to cover sidenav in docs app

        var confirmEdit = $mdDialog.confirm().title("Enregistrer les modifications").textContent("En acceptant, " + textContent).targetEvent(target).parent(angular.element(document.body)).ok(submitText).cancel("Annuler");
        $mdDialog.show(confirmEdit).then(function () {
          saveAccess(target);
          $scope.$emit("refreshPendingCount");
        });
      } else {
        var alert = $mdDialog.alert({
          title: "Attention",
          textContent: "Vous devez ajouter un code opérateur pour le membre de votre équipe",
          ok: "Ok"
        });
        $mdDialog.show(alert)["finally"](function () {
          alert = undefined;
        });
      }
    } else {
      // Appending dialog to document.body to cover sidenav in docs app
      var confirm = $mdDialog.confirm().title("Valider la demande").textContent("En acceptant, vous permettrez à cette officine de rejoindre votre groupement.").targetEvent(target).parent(angular.element(document.body)).ok("Accepter la demande et enregistrer").cancel("Annuler");
      $mdDialog.show(confirm).then(function () {
        saveAccess(target);
        $scope.$emit("refreshPendingCount");
      });
    }
  }
  /**
   * Get target.
   *
   * @param {string} key -
   * @returns {string}
   */


  function getTarget(key) {
    if (vm.page > 1) {
      return key + vm.limit * (vm.page - 1);
    }

    return key;
  }
  /**
   * Entity type column.
   */


  function entityTypeColumn() {
    vm.columnSettings = getColumnSettings();

    _.each(vm.columnSettings, function (value, key) {
      if (_.isArray(vm.columnSettings[key].type) && _.indexOf(vm.columnSettings[key].type, vm.currentEnv.accessPointType) === -1 || !vm.isEntityAdmin && vm.columnSettings[key].admin) {
        value.visible = false;
      }
    });
  }
  /**
   * Tooltip display.
   */


  function tooltipDisplay() {
    $timeout(function () {
      vm.showTooltip = true;
    }, 2000);
    $timeout(function () {
      vm.showTooltip = false;
    }, 5000);
  }
  /**
   * Get column settings.
   *
   * @returns {Array}
   */


  function getColumnSettings() {
    return [{
      title: "Statut",
      "class": "md-cell md-column",
      type: "all",
      admin: true,
      visible: true,
      order: "validated"
    }, {
      title: EntityTypeColumnTitle(),
      "class": "md-cell md-column",
      type: [1, 3],
      admin: false,
      visible: true,
      order: "entity.name"
    }, {
      title: "Adresse",
      "class": "md-cell md-column",
      type: [1, 3],
      admin: false,
      visible: true,
      order: "entity.address"
    }, {
      title: "Prénom",
      "class": "md-cell md-column",
      type: "all",
      admin: false,
      visible: true,
      order: "user.given"
    }, {
      title: "Nom",
      "class": "md-cell md-column",
      type: "all",
      admin: false,
      visible: true,
      order: "user.familyBirth"
    }, {
      title: "Poste dans l'officine",
      "class": "md-cell md-column md-select",
      type: [2],
      admin: false,
      visible: true,
      order: false
    }, {
      title: "Equipier référent",
      "class": "md-cell md-column",
      type: "all",
      admin: false,
      visible: true,
      order: false
    }, {
      title: "Code vendeur",
      "class": "md-cell md-column",
      type: [2],
      admin: true,
      visible: true,
      order: false
    }, {
      title: "LGO/Version santé secure",
      "class": "no-padding md-cell md-column",
      type: [3],
      admin: true,
      visible: true,
      order: false
    }, {
      title: "Enregistrer/Éditer",
      "class": "md-cell md-column md-numeric",
      type: [1, 2],
      admin: true,
      visible: vm.modified,
      order: false
    }, {
      title: "Adhérent",
      "class": "no-padding md-cell md-column md-numeric",
      type: [3],
      admin: true,
      visible: true,
      order: false
    }];
  }
  /**
   * Entity type column title.
   *
   * @returns {string}
   */


  function EntityTypeColumnTitle() {
    switch (vm.currentEnv.accessPointType) {
      case 1:
        return "Groupement";

      case 3:
        return "Pharmacie";

      default:
        return "Entité";
    }
  }
}
/**
 * Dialog controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} environmentService - the environment service.
 */


function DialogController($scope, $mdDialog, environmentService) {
  $scope.closeDialog = function () {
    $mdDialog.hide();
  };

  environmentService.getEnvironment().then(function (env) {
    $scope.currentEnv = env;
  });
}

angular.module("app.teammanager").controller("MembersController", MembersController).controller("dialogController", DialogController);