"use strict";
/**
 * Sales area management list controller.
 *
 * @param {object} salesAreaManagementService - Sales area management service.
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $mdDialog - $mdDialog.
 */

SalesAreaManagementListController.$inject = ["salesAreaManagementService", "$scope", "$state", "$mdDialog"];

function SalesAreaManagementListController(salesAreaManagementService, $scope, $state, $mdDialog) {
  $scope.addSalesArea = addSalesArea;
  $scope.editSalesArea = editSalesArea;
  $scope.deleteSalesArea = deleteSalesArea;
  $scope.addProductAffectation = addProductAffectation;
  $scope.goToDetail = goToDetail;
  $scope.getLength = getLength;
  loader();
  $scope.isProductAffectationState = $state.current.name.indexOf("products") > -1;
  /**
   * Loader.
   */

  function loader() {
    getSalesAreas();
  }
  /**
   * Get sales areas
   */


  function getSalesAreas() {
    $scope.loading = true;
    salesAreaManagementService.get().then(function (salesAreas) {
      $scope.salesAreas = salesAreas.salesAreas;
      $scope.loading = false;
    });
  }
  /**
   * Go to edit state without id to add one
   */


  function addSalesArea() {
    $state.go("app.salesAreaManagement.edit");
  }
  /**
   * Edit a sales area
   *
   * @param {object} salesArea - Sales area.
   */


  function editSalesArea(salesArea) {
    salesArea.sales_area_type_id = salesArea.salesAreaType.id;
    $state.go("app.salesAreaManagement.edit", {
      salesArea: JSON.stringify(salesArea)
    });
  }
  /**
   * Go to product affectation state.
   *
   * @param {object} salesArea - Sales area.
   */


  function addProductAffectation(salesArea) {
    salesArea.sales_area_type_id = salesArea.salesAreaType.id;
    $state.go("app.salesAreaManagement.productsAffectation.edit", {
      salesArea: JSON.stringify(salesArea),
      id: salesArea.id
    });
  }
  /**
   * Delete a state area.
   *
   * @param {object} event - Event.
   */


  function deleteSalesArea(event) {
    var confirm = $mdDialog.confirm().title("Souhaitez-vous supprimer cet espace de vente ?").ariaLabel("Supprimer un espace de vente").ok("Oui").cancel("Non");
    $mdDialog.show(confirm).then(function () {
      return salesAreaManagementService["delete"](event);
    }).then(function () {
      return getSalesAreas();
    });
  }
  /**
   * Go to profitability detail.
   *
   * @param {number} id - ID.
   * @param {object} profitability - Profitability.
   */


  function goToDetail(id, profitability) {
    $state.go("app.salesAreaManagement.profitability.detail", {
      profitability: JSON.stringify(profitability),
      id: id
    });
  }
  /**
   * Get object length.
   *
   * @param {object} salesAreas - Sales areas.
   *
   * @returns {number}
   */


  function getLength(salesAreas) {
    var len = 0;
    angular.forEach(salesAreas, function (salesArea) {
      if (salesArea.id) {
        len++;
      }
    });
    return len;
  }
  /**
   * Order && filter the sales area
   */


  $scope.selected = [];
  $scope.limitOptions = [5, 10, 15];
  $scope.query = {
    order: "-startDate",
    limit: 15,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
}

angular.module("app.salesAreaManagement").controller("salesAreaManagementListController", SalesAreaManagementListController);