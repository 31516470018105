"use strict";
/**
 * Patient pathology entries controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} patientPathologyService - The patient pathology service.
 */

PatientPathologyEntriesController.$inject = ["$scope", "$state", "$stateParams", "patientPathologyService"];

function PatientPathologyEntriesController($scope, $state, $stateParams, patientPathologyService) {
  $scope.pathologyEntriesTitle = {
    title: {
      text: "Mes conseils",
      back: {
        state: null
      }
    }
  };
  $scope.loading = true;
  $scope.entry = null;
  loader();
  /**
   * Loader function.
   */

  function loader() {
    patientPathologyService.readPathologyEntry($stateParams.pathologyId, $stateParams.entryId).then(function (response) {
      $scope.entry = response;
    })["finally"](function () {
      $scope.loading = false;
    });
  }
}

angular.module("app.patients").controller("patientPathologyEntriesController", PatientPathologyEntriesController);