"use strict";
/**
 * Dashboard groups sellout prestations events week controller.
 *
 * @param {object} $scope - $scope.
 */

DashboardGroupsSelloutPrestationsEventsWeekController.$inject = ["$scope"];

function DashboardGroupsSelloutPrestationsEventsWeekController($scope) {
  $scope.options = {
    type: "sellout,prestation",
    start: new Date(),
    end: new Date().setDate(new Date().getDate() + 7)
  };
}

angular.module("app.dashboard").controller("dashboardGroupsSelloutPrestationsEventsWeekController", DashboardGroupsSelloutPrestationsEventsWeekController);