"use strict";
/**
 * Wholesaler catalog confirm.
 *
 * @param {object} $stateProvider - The state provider
 * @param {object} wholesalerCatalogConfig - Wholesaler catalog confirm
 */

WholesalerCatalogConfig.$inject = ["$stateProvider", "wholesalerCatalogConfig"];

function WholesalerCatalogConfig($stateProvider, wholesalerCatalogConfig) {
  $stateProvider.state("app.wholesaler.catalogs", {
    url: "/catalogues",
    views: {
      "content@app": {
        templateUrl: wholesalerCatalogConfig.baseDir + "catalogs/catalogs.html",
        controller: "wholesalerCatalogs"
      }
    }
  }).state("app.wholesaler.catalogs.new", {
    url: "/creation",
    views: {
      "content@app": {
        templateUrl: wholesalerCatalogConfig.baseDir + "catalog-edit/catalog-edit.html",
        controller: "wholesalerCatalogEdit"
      }
    }
  }).state("app.wholesaler.catalogs.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: wholesalerCatalogConfig.baseDir + "catalog-edit/catalog-edit.html",
        controller: "wholesalerCatalogEdit"
      }
    }
  }).state("app.wholesaler.catalogs.show", {
    url: "/:id/produits",
    views: {
      "content@app": {
        templateUrl: wholesalerCatalogConfig.baseDir + "catalog/catalog.html",
        controller: "wholesalerCatalogProducts"
      }
    }
  }).state("app.wholesaler.catalogs.stocks", {
    url: "/:id/stocks",
    views: {
      "content@app": {
        templateUrl: wholesalerCatalogConfig.baseDir + "catalog/catalog.html",
        controller: "wholesalerCatalogProducts"
      }
    }
  }).state("app.wholesaler.catalogs.priceList", {
    url: "/:wholesalerCatalogId/offre-de-prix",
    views: {
      "content@app": {
        templateUrl: wholesalerCatalogConfig.baseDir + "catalog/catalog.html",
        controller: "wholesalerCatalogProducts"
      }
    }
  }).state("app.wholesaler.catalogs.priceList.new", {
    url: "/creation",
    views: {
      "content@app": {
        templateUrl: wholesalerCatalogConfig.baseDir + "price-list-edit/price-list-edit.html",
        controller: "wholesalerCatalogPriceListEdit"
      }
    }
  }).state("app.wholesaler.catalogs.priceList.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: wholesalerCatalogConfig.baseDir + "price-list-edit/price-list-edit.html",
        controller: "wholesalerCatalogPriceListEdit"
      }
    }
  });
}

angular.module("wholesaler.catalog", []).config(WholesalerCatalogConfig).constant("wholesalerCatalogConfig", {
  baseDir: "app/main/wholesaler/catalog/views/"
});