"use strict";
/**
 * Prestation configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 * @param {object} prestationConfig - Prestation configuration.
 */

PrestationConfig.$inject = ["$stateProvider", "prestationConfig"];

function PrestationConfig($stateProvider, prestationConfig) {
  $stateProvider.state("app.prestation", {
    url: "/prestation",
    views: {
      "content@app": {
        templateUrl: prestationConfig.baseDir + "/home/home.html",
        controller: "PrestationHomeController"
      }
    }
  }).state("app.prestation.dashboard", {
    url: "/dashboard",
    views: {
      "tabContent@app.prestation": {
        templateUrl: prestationConfig.baseDir + "/dashboard/dashboard.html",
        controller: "PrestationDashboardController"
      }
    }
  }).state("app.prestation.list", {
    url: "/list",
    views: {
      "tabContent@app.prestation": {
        templateUrl: prestationConfig.baseDir + "/list/list.html",
        controller: "PrestationListController"
      }
    }
  }).state("app.prestation.participation", {
    url: "/participation?type&status&has_photo&prestation_id&prestation_status",
    views: {
      "content@app": {
        templateUrl: prestationConfig.baseDir + "/participation/participation.html",
        controller: "PrestationParticipationController"
      }
    }
  }).state("app.prestation.photo", {
    url: "/photo/prestation?id&status&photo_status&organization_id&healthcenter_id",
    views: {
      "content@app": {
        templateUrl: prestationConfig.baseDir + "/photo/photo.html",
        controller: "PrestationPhotoController"
      }
    }
  }).state("app.prestation.detail", {
    url: "/:id/detail",
    views: {
      "content@app": {
        templateUrl: prestationConfig.baseDir + "/detail/detail.html",
        controller: "PrestationDetailController"
      }
    }
  }).state("app.prestation.new", {
    url: "/nouveau",
    views: {
      "content@app": {
        templateUrl: prestationConfig.baseDir + "/form/form.html",
        controller: "PrestationFormController as vm"
      }
    },
    params: {
      healthcenterId: null,
      healthcenterName: null
    }
  }).state("app.prestation.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: prestationConfig.baseDir + "/form/form.html",
        controller: "PrestationFormController as vm"
      }
    }
  }).state("app.prestation.listing", {
    url: "/:id/listing",
    views: {
      "content@app": {
        templateUrl: prestationConfig.baseDir + "/listing/listing.html",
        controller: "prestationListingController"
      }
    }
  }).state("app.prestation.map", {
    url: "/:id/map",
    views: {
      "content@app": {
        templateUrl: prestationConfig.baseDir + "/map/map.html",
        controller: "PrestationMapController as vm"
      }
    }
  }).state("app.prestation.benefitInfo", {
    url: "/:id/photo",
    views: {
      "content@app": {
        templateUrl: prestationConfig.baseDir + "/map/detail/benefitInfo.html",
        controller: "PrestationBenefitDetailController as vm"
      }
    },
    params: {
      picture: null
    }
  });
}

angular.module("app.prestation", []).config(PrestationConfig).constant("prestationConfig", {
  baseDir: "app/main/prestation"
});