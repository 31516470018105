"use strict";
/**
 * Chart directive.
 *
 * @returns {object}
 */

function ChartDirective() {
  return {
    restrict: "E",
    replace: true,
    scope: {
      options: "=",
      height: "@",
      width: "@"
    },
    template: "<div></div>",
    link: function link(scope, element) {
      scope.id = Math.random().toString(36).substr(2, 16);
      element.attr("id", scope.id).css({
        height: scope.height,
        width: scope.width
      });
      var chart = AmCharts.makeChart(scope.id, scope.options);
      chart.invalidateSize();
    }
  };
}

angular.module("apodis").directive("chart", ChartDirective);