"use strict";
/**
 * Fabric directive.
 *
 * @returns {object}
 */

FabricController.$inject = ["$rootScope", "$scope", "$element", "fabricCanvas"];

function Fabric() {
  return {
    scope: {
      fabric: "="
    },
    controller: FabricController
  };
}
/**
 * Fabric controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $element - $element.
 * @param {object} fabricCanvas - Fabric canvas.
 */


function FabricController($rootScope, $scope, $element, fabricCanvas) {
  var _this = this;

  $rootScope.$on("initCanvasWatchers", function () {
    fabricCanvas.setElement($element);
    fabricCanvas.createCanvas();
    angular.element(document).find("#fabric-container").click(function (event) {
      if (event.target !== _this) {
        return;
      }

      $scope.fabric.deactivateAll();
    });
    /**
     * listner keypress with SHIFT
     *
     * @param {event} event - keypress
     */

    angular.element(window).keydown(function (event) {
      if ($("#image-builder")[0]) {
        var altKey = event.altKey;
        var ctrlKey = event.ctrlKey;
        var code = event.keyCode ? event.keyCode : event.which;
        var letterS = 83;
        var letterQ = 81;
        var letterT = 84;
        var letterB = 66;
        var letterF = 77;
        var letterD = 68;
        var plus = 107;
        var minus = 109;

        if (altKey && ctrlKey && code === letterS) {
          $rootScope.$broadcast("saveFabricEvent");
        }

        if (altKey && ctrlKey && code === letterQ) {
          $rootScope.$broadcast("saveQuitFabricEvent");
        }

        if (altKey && ctrlKey && code === letterT) {
          $rootScope.$broadcast("addTextFabricEvent");
        }

        if (altKey && ctrlKey && code === letterB) {
          $rootScope.$broadcast("undoFabricEvent");
        }

        if (altKey && ctrlKey && code === letterF) {
          $rootScope.$broadcast("redoFabricEvent");
        }

        if (altKey && ctrlKey && code === letterD) {
          $rootScope.$broadcast("deleteLayerFabricEvent");
        }

        if (altKey && ctrlKey && code === plus) {
          $scope.fabric.zoomPlus();
        }

        if (altKey && ctrlKey && code === minus) {
          $scope.fabric.zoomMinus();
        }
      }
    });
    angular.element("#fabric-container").mousedown(function () {
      return $rootScope.$broadcast("fabric.directive:mousedown", fabricCanvas.canvas._objects);
    });
    angular.element("#fabric-window").contextmenu(function () {
      return false;
    });
    $scope.$watch("fabric.canvasBackgroundColor", function (newVal) {
      return newVal ? $scope.fabric.setCanvasBackgroundColor(newVal) : null;
    });
    $scope.$watch("fabric.selectedObject", function (object) {
      return $rootScope.$emit("objectSelectedIntoCanvas", object);
    });
    $scope.$watch("fabric.selectedObject.text", function (newVal) {
      if (newVal && typeof newVal === "string") {
        $scope.fabric.setText(newVal);
        $scope.fabric.render();
      }
    });
    $scope.$watch("fabric.selectedObject.textAlign", function (newVal) {
      if (newVal && typeof newVal === "string") {
        $scope.fabric.setTextAlign(newVal);
        $scope.fabric.render();
      }
    });
    $scope.$watch("fabric.selectedObject.fontFamily", function (newVal) {
      if (newVal && typeof newVal === "string") {
        $scope.fabric.setFontFamily(newVal);
        $scope.fabric.render();
      }
    });
    $scope.$watch("fabric.selectedObject.fill", function (newVal) {
      if (newVal && typeof newVal === "string") {
        $scope.fabric.setFill(newVal);
        $scope.fabric.render();
      }
    });
    $scope.$watch("fabric.selectedObject.opacity", function (newVal) {
      if (newVal && typeof newVal === "string" || typeof newVal === "number") {
        $scope.fabric.setOpacity(newVal);
        $scope.fabric.render();
      }
    });
    $scope.$watch("fabric.selectedObject.fontSize", function (newVal) {
      if (newVal && typeof newVal === "string" || typeof newVal === "number") {
        $scope.fabric.setFontSize(newVal);
        $scope.fabric.render();
      }
    });
    $scope.$watch("fabric.selectedObject.lineHeight", function (newVal) {
      if (newVal && typeof newVal === "string" || typeof newVal === "number") {
        $scope.fabric.setLineHeight(newVal);
        $scope.fabric.render();
      }
    });
  });
}

angular.module("app.generator").directive("fabric", Fabric);