"use strict";
/**
 * Patient administration configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

PatientAdministrationConfig.$inject = ["$stateProvider"];

function PatientAdministrationConfig($stateProvider) {
  $stateProvider.state("app.patients-administration", {}).state("app.patients-administration.pathologies", {
    url: "/patients-administration/pathologies",
    views: {
      "content@app": {
        templateUrl: "app/main/patient-administration/views/pathology-administration/patient-administration.pathology-administration.html",
        controller: "patientAdministrationPathologyAdministrationController"
      }
    }
  }).state("app.patients-administration.users", {
    url: "/patients-administration/users",
    views: {
      "content@app": {
        templateUrl: "app/main/patient-administration/views/users/patient-administration.users.html",
        controller: "patientAdministrationUsersController"
      }
    }
  });
}

angular.module("app.patient-administration", ["app.patient-administration.services"]).config(PatientAdministrationConfig);