"use strict";
/**
 * Patients configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

PatientsConfig.$inject = ["$stateProvider"];

function PatientsConfig($stateProvider) {
  $stateProvider.state("app.patients.dashboard", {
    url: "/patients/dashboard",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/dashboard/patients.dashboard.html",
        controller: "patientsDashboardController"
      }
    }
  }).state("app.patients", {
    url: "/patients",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/list/patients.list.html",
        controller: "patientsListController"
      }
    },
    params: {
      orderStatus: null,
      booleanFilter: null,
      toCertifyFilter: null
    }
  }).state("app.patients.appointments", {
    url: "/appointments",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/appointments/patients.appointments.html",
        controller: "patientsAppointmentsController"
      }
    }
  }).state("app.patients.pathologies", {
    url: "/pathologies",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/pathologies/pathologies-list/patients.pathologiesList.html",
        controller: "patientPathologiesListController"
      }
    }
  }).state("app.patients.pathologies.details", {
    url: "/:pathologyId",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/pathologies/pathology-details/patients.pathologyDetails.html",
        controller: "patientPathologyDetailsController"
      }
    }
  }).state("app.patients.pathologies.details.advices", {
    url: "/advices",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/pathologies/pathology-advices/patients.pathologyAdvices.html",
        controller: "patientPathologyAdvicesController"
      }
    }
  }).state("app.patients.pathologies.details.entries", {
    url: "/:entryId",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/pathologies/pathology-entries/patients.pathologyEntries.html",
        controller: "patientPathologyEntriesController"
      }
    }
  }).state("app.patients.events", {
    url: "/evenements",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/events-list/patients.events.html",
        controller: "patientsEventsController"
      }
    }
  }).state("app.patients.orders", {
    url: "/orders",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/orders-list/patients.ordersList.html",
        controller: "PatientsOrderIndexController"
      }
    },
    params: {
      orderStatus: null
    }
  }).state("app.patients.details", {
    url: "/:patientId",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/details/patients.details.html",
        controller: "patientDetailsController"
      }
    }
  }).state("app.patients.details.vaccination", {
    url: "/vaccination",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/vaccination/patients.vaccination.html",
        controller: "patientVaccinationController"
      }
    }
  }).state("app.patients.details.beneficiaries", {
    url: "/beneficiaries",
    "abstract": true
  }).state("app.patients.details.beneficiaries.details", {
    url: "/:beneficiaryId",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/beneficiary-details/patients.beneficiaryDetails.html",
        controller: "patientBeneficiaryDetailsController"
      }
    }
  }).state("app.patients.details.beneficiaries.details.prescriptions", {
    url: "/prescriptions",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/prescriptions-list/patients.prescriptionsList.html",
        controller: "patientPrescriptionsListController"
      }
    }
  }).state("app.patients.details.beneficiaries.details.carts", {
    url: "/carts",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/cart-history/patients.cartHistory.html",
        controller: "patientsCartHistoryController"
      }
    }
  }).state("app.patients.details.beneficiaries.details.orders", {
    url: "/orders",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/orders-list/patients.ordersList.html",
        controller: "patientsOrdersListController"
      }
    }
  }).state("app.patients.details.beneficiaries.details.vaccination", {
    url: "/vaccination",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/vaccination/patients.vaccination.html",
        controller: "patientVaccinationController"
      }
    }
  }).state("app.patients.details.carts", {
    url: "/carts",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/cart-history/patients.cartHistory.html",
        controller: "patientsCartHistoryController"
      }
    }
  }).state("app.patients.details.carts.details", {
    url: "/:cartId",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/cart-details/patients.cartDetails.html",
        controller: "patientsCartDetailsController"
      }
    }
  }).state("app.patients.details.prescriptions", {
    url: "/prescriptions",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/prescriptions-list/patients.prescriptionsList.html",
        controller: "patientPrescriptionsListController"
      }
    }
  }).state("app.patients.details.orders", {
    url: "/orders",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/orders-list/patients.ordersList.html",
        controller: "patientsOrdersListController"
      }
    }
  }).state("app.patients.details.orders.details", {
    url: "/:orderId",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/order-details/patients.orderDetails.html",
        controller: "patientsOrderDetailsController"
      }
    }
  }).state("app.patients.details.prescription", {
    url: "/prescriptions/:prescriptionId",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/prescription-details/patients.prescriptionDetails.html",
        controller: "patientPrescriptionDetailsController"
      }
    }
  }).state("app.patients.details.prescription.dosage", {
    url: "/dosage/:dosageId",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/dosage/dosage.html",
        controller: "dosageController"
      }
    }
  }).state("app.patients.medicationSummary", {
    url: "/medication",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/medicationSummary/medicationSummary.html",
        controller: "medicationSummaryController"
      }
    }
  }).state("app.patients.medicationSummary.patientsList", {
    url: "/elligible",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/medicationSummaryList/medicationSummaryList.html",
        controller: "medicationSummaryListController"
      }
    }
  }).state("app.patients.medicationSummary.patient", {
    url: "/patient/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/medicationSummaryPatient/medicationSummaryPatient.html",
        controller: "medicationSummaryPatientController"
      }
    }
  }).state("app.patients.medicationSummary.patient.membership", {
    url: "/bulletin-adhesion",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/medicationSummaryPatientMembership/medicationSummaryPatientMembership.html",
        controller: "medicationSummaryPatientMembershipController"
      }
    }
  }).state("app.patients.events.create", {
    url: "/nouveau",
    views: {
      "content@app": {
        templateUrl: "app/main/patients/views/create-event/patients.events.create.html",
        controller: "createPatientsEventController"
      }
    }
  });
}

angular.module("app.patients", ["app.patients.factories", "app.patients.services", "app.patients.components", "app.patients.directives", "angular-timeline"]).config(PatientsConfig);