"use strict";
/**
 * Medication summary service.
 *
 * @param {object} medicationSummaryFactory - Medication summary factory.
 * @param {object} $q - $q.
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

MedicationSummaryService.$inject = ["medicationSummaryFactory", "$q", "apiClient"];

function MedicationSummaryService(medicationSummaryFactory, $q, apiClient) {
  return {
    /**
     * Read form by slug.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    readFormBySlug: function readFormBySlug(data) {
      return apiClient.get("/medication-summaries/formBySlug/".concat(data.slug), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.medicationSummaryForm;
      });
    },

    /**
     * Read.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    read: function read(data) {
      return apiClient.get("/medication-summaries/forms/".concat(data.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.medicationSummaryForm;
      });
    },

    /**
     * Get appointments.
     *
     * @returns {Promise}
     */
    getAppointments: function getAppointments() {
      return $q.when(medicationSummaryFactory.appointments);
    },

    /**
     * Get patients by steps.
     *
     * @returns {Promise}
     */
    getPatientsBySteps: function getPatientsBySteps() {
      return $q.when(medicationSummaryFactory.patientsBySteps);
    },

    /**
     * Get eligible patient.
     *
     * @returns {Promise}
     */
    getEligiblePatient: function getEligiblePatient() {
      return $q.when(medicationSummaryFactory.eligiblePatient);
    },

    /**
     * Get medication summary.
     *
     * @returns {Promise}
     */
    getMedicationSummary: function getMedicationSummary() {
      return $q.when(medicationSummaryFactory.medicationSummary);
    },

    /**
     * Get medication summary membership.
     *
     * @returns {Promise}
     */
    getMedicationSummaryMembership: function getMedicationSummaryMembership() {
      return $q.when({
        patient: medicationSummaryFactory.medicationSummary.patient,
        membership: medicationSummaryFactory.medicationSummary[0]
      });
    }
  };
}

angular.module("app.patients.services.medicationSummaryService").service("medicationSummaryService", MedicationSummaryService);