"use strict";
/**
 * Patients list controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state object.
 * @param {object} $stateParams - $stateParams object.
 * @param {object} customerService - Customer service.
 * @param {object} patientUtilitiesService - Patient utilities service.
 */

PatientsListController.$inject = ["$scope", "$state", "$stateParams", "customerService", "patientUtilitiesService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function PatientsListController($scope, $state, $stateParams, customerService, patientUtilitiesService) {
  $scope.clear = clearSearch;
  $scope.search = searchPatients;
  $scope.select = selectPatient;
  $scope.selectBeneficiary = selectBeneficiary;
  $scope.hasMorePatients = hasMorePatients;
  $scope.getMorePatients = getMorePatients;
  $scope.hasStatus = hasStatus;
  $scope.statusFilter = null;
  $scope.statusFilteredClass = statusFilteredClass;
  $scope.handleSelectedOrderStatusFilter = handleSelectedOrderStatusFilter;
  $scope.handleFuturePatientSelectedFilter = handleFuturePatientSelectedFilter;
  $scope.handleSelectedFilter = handleSelectedFilter;
  $scope.redirectToPatientOrderList = redirectToPatientOrderList;
  $scope.certify = certify;
  $scope.searchThisPatient = searchThisPatient;
  $scope.toCertifyFilter = null;
  $scope.patients = null;
  $scope.selectedRenewal = null;
  $scope.loading = false;
  $scope.nextPage = null;
  $scope.total = null;
  $scope.query = {
    limit: 50,
    page: 1,
    search: null
  };

  if ($stateParams.orderStatus) {
    $scope.statusFilter = $stateParams.orderStatus;
  }

  if ($stateParams.booleanFilter) {
    $scope.booleanFilter = $stateParams.booleanFilter;
  }

  if ($stateParams.toCertifyFilter) {
    $scope.toCertifyFilter = $stateParams.toCertifyFilter;
  }

  if ($stateParams.searchText) {
    $scope.searchText = $stateParams.searchText;
  }

  if ($scope.statusFilter || $scope.booleanFilter) {
    getPatients();
  }

  if ($scope.toCertifyFilter) {
    getFutureCustomers();
  }
  /**
   * Get patients from healthcenter's customers list.
   *
   * @param {number|null} page - The page number
   */


  function getPatients() {
    var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
    $scope.loading = true;

    var params = _objectSpread(_objectSpread({}, $scope.query), {}, {
      page: page || $scope.page
    });

    if ($scope.statusFilter) {
      params.order_status = $scope.statusFilter;
    }

    if ($scope.booleanFilter) {
      params[$scope.booleanFilter] = 1;
    }

    customerService.getCustomers(params).then(function (customers) {
      var patients = customerService.extractPatientsFromCustomers(customers);

      if (page === 1) {
        $scope.total = customers.meta.total;
        $scope.patients = patients;
      } else {
        $scope.patients = $scope.patients.concat(patients);
      }

      $scope.nextPage = customers.meta.current_page + 1;
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Open the QR code for certification.
   *
   * @param {object} $event - The angular $event object.
   * @param {number} patientId - The patient id.
   */


  function certify($event, patientId) {
    $event.stopPropagation();
    patientUtilitiesService.openCertificationModal(patientId);
  }
  /**
   * Get more patients from healthcenter's customers list
   */


  function getMorePatients() {
    if (!$scope.loading) {
      getPatients($scope.nextPage);
    }
  }
  /**
   * Extracts patients from users.
   *
   * @param {object} users - users API response.
   *
   * @returns {Array}
   */


  function extractPatientsFromUsers(users) {
    $scope.loading = false;
    return users.map(function (user) {
      return {
        id: user.id,
        name: "".concat(user.first_name, " ").concat(user.last_name),
        first_name: user.first_name,
        last_name: user.last_name,
        last_visit: null,
        onboarded: false,
        current_order: null,
        beneficiaries: user.beneficiaries
      };
    });
  }
  /**
   * Check if a patient has a pending or rejected or accepted prescription renewal status.
   *
   * @param {object} patient - The patient to check.
   *
   * @returns {boolean}
   */


  function hasStatus(patient) {
    if (!patient.current_order) {
      return false;
    }

    return patient.current_order.status === "accepted" || patient.current_order.status === "rejected" || patient.current_order.status === "pending";
  }
  /**
   * Selects a specific patient. Opens the details panel.
   *
   * @param {object} patient - The patient data.
   */


  function selectPatient(patient) {
    if (!$scope.toCertifyFilter) {
      $state.go("app.patients.details", {
        patientId: patient.id
      });
    }
  }
  /**
   * Redirect to the beneficiary details screen.
   *
   * @param {object} patient - The patient's beneficiary.
   * @param {object} beneficiary - The beneficiary.
   */


  function selectBeneficiary(patient, beneficiary) {
    $state.go("app.patients.details.beneficiaries.details", {
      patientId: patient.id,
      beneficiaryId: beneficiary.id
    });
  }
  /**
   * Searches for patients when user types in search bar.
   *
   * @param {string} searchText - The search text.
   *
   * @returns {void}
   */


  function searchPatients(searchText) {
    handleFuturePatientSelectedFilter($scope.toCertifyFilter);

    if (!searchText) {
      clearSearch();
      return;
    }

    if (searchText.length <= 2) {
      return;
    }

    $scope.patients = null;
    getPatients();
  }
  /**
   * Clears search keywords and reset patients list.
   */


  function clearSearch() {
    $scope.searchText = null;
    $scope.patients = null;
  }
  /**
   * Get the css class depending the selected filter.
   *
   * @param {string|null} status - The selected status.
   *
   * @returns {string}
   */


  function statusFilteredClass(status) {
    return $scope.statusFilter === status || $scope.booleanFilter === status || $scope.toCertifyFilter === status ? "md-accent" : null;
  }
  /**
   * On order filter selected, update the $scope.
   *
   * @param {string|null} filter - The selected filter.
   *
   * @returns {void}
   */


  function handleSelectedOrderStatusFilter(filter) {
    $scope.statusFilter = filter === $scope.statusFilter ? null : filter;

    if ($scope.statusFilter) {
      if ($scope.toCertifyFilter) {
        handleFuturePatientSelectedFilter($scope.toCertifyFilter);
      }

      getPatients();
    } else {
      clearSearch();
    }
  }
  /**
   * Handle selected filter.
   *
   * @param {string} filter - The selected filter.
   */


  function handleSelectedFilter(filter) {
    if ($scope.booleanFilter === filter) {
      $scope.booleanFilter = null;
      clearSearch();
    } else {
      handleFuturePatientSelectedFilter($scope.toCertifyFilter);
      $scope.booleanFilter = filter;
      getPatients();
    }
  }
  /**
   * Handle when the future patient filter is selected or not.
   *
   * @param {string} filter - The selected filter.
   */


  function handleFuturePatientSelectedFilter(filter) {
    if ($scope.toCertifyFilter === filter) {
      $scope.toCertifyFilter = null;
      clearSearch();
    } else {
      handleSelectedFilter($scope.booleanFilter);
      handleSelectedOrderStatusFilter(null);
      $scope.toCertifyFilter = filter;
      getFutureCustomers();
    }
  }
  /**
   * Get healthcenter's potential future customers.
   */


  function getFutureCustomers() {
    customerService.getFutureCustomers().then(function (futureCertifiedCustomers) {
      if (futureCertifiedCustomers.length > 0) {
        var patients = extractPatientsFromUsers(futureCertifiedCustomers);
        $scope.patients = patients;
      }
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Checks if there are more patients to load from backend.
   *
   * @returns {boolean}
   */


  function hasMorePatients() {
    if (!$scope.total || !$scope.patients) {
      return false;
    }

    return $scope.patients.length < $scope.total;
  }
  /**
   * Search a patient based on a search parameter.
   *
   * @param {object} $event - The angular $event object.
   * @param {string} search - The search parameter.
   *
   * @returns {void}
   */


  function searchThisPatient($event, search) {
    $event.stopPropagation();
    $scope.query.search = search;
    searchPatients($event, search);
  }
  /**
   * Redirect to the patient order list.
   *
   * @param {object} $event - The angular $event object.
   * @param {object} patient - The patient.
   */


  function redirectToPatientOrderList($event, patient) {
    $event.stopPropagation();
    $state.go("app.patients.details.orders", {
      patientId: patient.id
    });
  }
}

angular.module("app.patients").controller("patientsListController", PatientsListController);