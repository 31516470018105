"use strict";
/**
 * PLV controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $rootScope - The angular $rootScope object.
 * @param {object} $state - The angular $state object.
 * @param {object} bricksService - Bricks service.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} communicationService - Communication service.
 * @param {string} apiurl - The api url.
 * @param {object} $window - The angular $window object.
 * @param {object} plvsService - The plvs service.
 */

PLVController.$inject = ["$scope", "$rootScope", "$state", "bricksService", "laboratoriesService", "communicationService", "apiurl", "$window", "plvsService"];

function PLVController($scope, $rootScope, $state, bricksService, laboratoriesService, communicationService, apiurl, $window, plvsService) {
  $scope.goBack = goBack;
  $scope.goTo = goTo;
  $scope.goToEvent = goToEvent;
  $scope.goToState = goToState;
  $scope.goToBrandingState = goToBrandingState;
  $scope.print = print;
  $scope.fullScreen = fullScreen;
  /**
   * goBack
   */

  function goBack() {
    var query = {
      wholesalerId: laboratoriesService.getWholesalerId()
    };
    $state.go("app.usersLaboratory", query);
  }
  /**
   * Get plvs.
   */


  function getPlvs() {
    var params = {
      parameters: {
        laboratory_id: $scope.branding.laboratory.organization.id
      },
      includes: ["shareable.file,shareable.format,shareable.images,shareable.orientation,shareable.template,shareable.organizations.morphable"]
    };
    communicationService.getPlvs(params).then(function (plvs) {
      var type = "thumbs";
      var variant = "small";
      $scope.plvs = plvs.map(function (plv) {
        return plvsService.open(plv.shareable).then(function (file) {
          if (file.container) {
            return plvsService.exportVariant(plv.shareable.id, type, variant, file).then(function (response) {
              plv.source = "".concat(apiurl, "/images/").concat(response.full_path);
              return plv;
            });
          }
        });
      });
    });
  }
  /**
   * Print the plv.
   *
   * @param {object} plan - The merchandising plan.
   */


  function print(plan) {
    $scope.popup = window.open(plan.source);
    $scope.popup.onbeforeunload = closePrint;
    $scope.popup.onafterprint = closePrint;
    $scope.popup.focus(); // Required for IE

    $scope.popup.print();
  }
  /**
   * Close print window.
   */


  function closePrint() {
    if ($scope.popup) {
      $scope.popup.close();
    }
  }
  /**
   * See the plan full screen.
   *
   * @param {object} plan - The merchandising plan.
   */


  function fullScreen(plan) {
    $window.open(plan.source, "_blank");
  }
  /**
   * Go to given state
   *
   * @param {string} state - The state to go.
   */


  function goToState(state) {
    $state.go(state);
  }
  /**
   * goToBrandingState
   *
   * @param {object}  menu - The menu.
   */


  function goToBrandingState(menu) {
    $state.go(menu.state, menu);
  }
  /**
   * goTo
   *
   * @param {object}  brick - The brick.
   */


  function goTo(brick) {
    $rootScope.$broadcast("sideNavConstruct", brick);
    bricksService.setCurrentBrick(brick);
  }
  /**
   * goToEvent
   *
   * @param {object} event - The event.
   */


  function goToEvent(event) {
    $state.go("app.calendar.detail", {
      id: event.id
    });
  }

  $scope.$watch("laboratoryBranding", function (branding) {
    if (branding) {
      $scope.branding = branding;
      getPlvs();
    }
  });
}

angular.module("app.users-laboratory").controller("plvController", PLVController);