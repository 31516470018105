"use strict";
/**
 * Patient pathology remove customer modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} customers - Customers.
 * @param {Function} callback - Callback when form submitted.
 * @param {object} patientPathologyService - The patient pathology service.
 * @param {object} customerService - The customer service.
 * @param {object} notifyUserService - notifyUserService.
 */

PatientPathologyAddCustomerModalController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "customers", "callback", "patientPathologyService", "customerService", "notifyUserService"];

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function PatientPathologyAddCustomerModalController($scope, $state, $stateParams, $mdDialog, customers, callback, patientPathologyService, customerService, notifyUserService) {
  $scope.close = close;
  $scope.newCustomers = [];
  $scope.selected = [];
  $scope.searchPatient = searchPatient;
  $scope.handlePatientSelected = handlePatientSelected;
  $scope.attachSelectedCustomers = attachSelectedCustomers;
  $scope.query = {
    limit: 10,
    page: 1,
    total: customers.length
  };
  /**
   * Close the modal
   */

  function close() {
    $mdDialog.hide();
  }
  /**
   * Search patient.
   *
   * @param {string} searchText - The search text query.
   *
   * @returns {Promise}
   */


  function searchPatient(searchText) {
    if (!searchText) {
      return Promise.resolve(null);
    }

    return customerService.getCustomersBySearch(searchText).then(function (result) {
      return result.data;
    });
  }
  /**
   * Redirect to the selected patient profile.
   *
   * @param {object} selectedPatient - The selected patient.
   */


  function handlePatientSelected(selectedPatient) {
    if (selectedPatient) {
      $scope.newCustomers.push(selectedPatient);
    }
  }
  /**
   * Detach selected customers.
   */


  function attachSelectedCustomers() {
    var ids = [].concat(_toConsumableArray(customers), _toConsumableArray($scope.newCustomers)).map(function (customer) {
      return customer.id;
    });
    patientPathologyService.syncCustomersForPathology($stateParams.pathologyId, ids).then(function () {
      notifyUserService.showSuccessToast("La fiche a été ajoutée.");
      callback();
      close();
    });
  }
}

angular.module("app.patients").controller("patientPathologyAddCustomerModalController", PatientPathologyAddCustomerModalController);