"use strict";
/**
 * Statistics product controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $compile - The angular $compile object.
 * @param {object} StatisticsQueryService - The statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */

StatsProductController.$inject = ["$scope", "$compile", "StatisticsQueryService", "environmentService", "analysisService"];

function StatsProductController($scope, $compile, StatisticsQueryService, environmentService, analysisService) {
  var vm = this;
  vm.searchByEnv = {
    1: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id", "idOrgas"],
    2: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id"],
    3: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id"]
  };
  vm.noProduct = true;
  vm.name = {
    "geo.region.id": "Region",
    "geo.departement.id": "Département",
    "geo.uga": "UGA",
    "group.id": "Groupement"
  };
  vm.echantillonSelected = 0.50;
  vm.echantillonGeoSelectedName = "";
  vm.echantillonGeoSelected = "";
  vm.echantillon = [{
    value: 0.10,
    label: "0,10 €"
  }, {
    value: 0.20,
    label: "0,20 €"
  }, {
    value: 0.50,
    label: "0,50 €"
  }, {
    value: 1,
    label: "1 €"
  }, {
    value: 2,
    label: "2 €"
  }, {
    value: 5,
    label: "5 €"
  }];
  vm.hasGroup = false;
  vm.loadingProduct = true;
  vm.graphs = [];
  vm.errorNationalData = [];
  vm.geographicalDatas = {};
  vm.stepPosition = 0;
  vm.stepMax = 0;
  vm.indexMax = 0;
  vm.stepNumberLine = 10;
  vm.filteredDatas = [];
  vm.noData = false;
  vm.loadingProduct = false;
  /**
   * Create the chart.
   *
   * @param {object} data - The data.
   * @param {object} graphs - The graphs.
   */

  function createChart(data, graphs) {
    $scope.amChartOptions = {
      type: "xy",
      theme: "light",
      marginRight: 80,
      marginTop: 17,
      data: [data],
      startDuration: 0,
      graphs: graphs,
      thousandsSeparator: " ",
      decimalSeparator: ",",
      marginLeft: 46,
      marginBottom: 35,
      mouseWheelScrollEnabled: true,
      balloon: {
        fixedPosition: true
      },
      "export": {
        enabled: true
      },
      valueAxes: [{
        position: "bottom",
        axisAlpha: 0,
        dashLength: 1,
        id: "x",
        title: "Prix moyen",
        precision: 2
      }, {
        axisAlpha: 0,
        dashLength: 1,
        position: "left",
        id: "y",
        title: "Unités vendues"
      }]
    };
  }

  vm.usrQuery = StatisticsQueryService.query;
  angular.forEach(vm.usrQuery.must, function (type, key) {
    if (key !== "product") {
      type.selected = [];
    }
  });
  angular.forEach(vm.usrQuery.should, function (type, key) {
    if (key !== "product") {
      type.selected = [];
    }
  });
  /**
   * Handle api response to retrieve geographical data.
   *
   * @param {object} response - The api response to handle.
   */

  function getGeographicalDatas(response) {
    vm.geographicalDatas.national = {
      name: "National",
      amount: response.nationalAverage.aggregations.amount_incl_tax,
      quantity: response.nationalAverage.aggregations.quantity,
      pharmaNumber: response.nationalAverage.aggregations.pharmaNumber
    };
    vm.geographicalDatas.region = {
      name: "Region",
      amount: response.nationalAverage.aggregations.region.amount_incl_tax,
      quantity: response.nationalAverage.aggregations.region.quantity,
      pharmaNumber: response.nationalAverage.aggregations.region.pharmaNumber
    };
    vm.geographicalDatas.department = {
      name: "Département",
      amount: response.nationalAverage.aggregations.department.amount_incl_tax,
      quantity: response.nationalAverage.aggregations.department.quantity,
      pharmaNumber: response.nationalAverage.aggregations.department.pharmaNumber
    };
    vm.geographicalDatas.uga = {
      name: "UGA",
      amount: response.nationalAverage.aggregations.uga.amount_incl_tax,
      quantity: response.nationalAverage.aggregations.uga.quantity,
      pharmaNumber: response.nationalAverage.aggregations.uga.pharmaNumber
    };
    getErrorMargin(response);
  }

  vm.getFilteredDatas = function (datas, key) {
    vm.filteredDatas.push({
      datas: datas.data.aggregations[key],
      id: datas.name
    });
  };
  /**
   * Retrieve the data for the error margin.
   *
   * @param {object} response - The api response to get the error margin.
   */


  function getErrorMargin(response) {
    vm.errorNationalData = [{
      key: 0,
      pharmaCount: 0
    }];
    var once = true;

    if (vm.currentEnv.accessPointType === 2) {
      vm.myDatas = _.find(response.datas.aggregations.pharmacy.buckets, function (buck) {
        return parseInt(buck.key_id) === vm.currentEnv.entity.id;
      });
    }

    angular.forEach(response.nationalAverage.aggregations.price_ranges.buckets, function (range) {
      if (vm.currentEnv.accessPointType === 2 && vm.myDatas) {
        if (parseFloat(range.key) <= vm.myDatas.amount_incl_tax.value) {
          vm.myCat = range.key;
        } else if (once) {
          vm.myCatEnd = range.key;
          once = false;
        }
      }

      if (range.key === 0) {
        vm.errorNationalData = [{
          key: range.key,
          pharmaCount: range.pharmaCount.value
        }];
      } else {
        vm.errorNationalData.push({
          key: range.key,
          pharmaCount: range.pharmaCount.value
        });
      }

      vm.errorData = vm.errorNationalData;
    });
    vm.errorGroupData = [{
      key: 0,
      pharmaCount: 0
    }];
    vm.errorDatas = [];
    angular.forEach(response.datas.aggregations.price_ranges.buckets, function (range) {
      if (vm.currentEnv.accessPointType === 2) {
        if (parseFloat(range.key) <= vm.myDatas.amount_incl_tax.value) {
          vm.myCat = range.key;
        } else if (once) {
          vm.myCatEnd = range.key;
          once = false;
        }

        if (range.key === 0) {
          vm.errorDatas = [{
            key: range.key,
            pharmaCount: range.pharmaCount.value
          }];
        } else {
          vm.errorDatas.push({
            key: range.key,
            pharmaCount: range.pharmaCount.value
          });
        }

        vm.errorData = vm.errorDatas;
      }

      if (range.key === 0) {
        vm.errorGroupData = [{
          key: range.key,
          pharmaCount: range.pharmaCount.value
        }];
      } else {
        vm.errorGroupData.push({
          key: range.key,
          pharmaCount: range.pharmaCount.value
        });
      }

      vm.errorData = vm.errorGroupData;
    });
    vm.amChartErrorOptions = getErrorOptions();
  }
  /**
   * Get options for errors chart.
   *
   * @returns {object}
   */


  function getErrorOptions() {
    return {
      type: "serial",
      theme: "light",
      thousandsSeparator: " ",
      decimalSeparator: ",",
      marginTop: 0,
      marginRight: 80,
      data: vm.errorData,
      valueAxes: [{
        axisAlpha: 0,
        position: "left",
        title: "Nombre de pharmacie(s)"
      }],
      graphs: [{
        id: "g1",
        balloonText: "[[category]] €<br><b>" + "<span style='font-size:14px;'>[[value]] pharmacies</span>" + "</b>",
        fillAlphas: 0.3,
        fillColor: "#0088FF",
        lineThickness: 1,
        type: "smoothedLine",
        valueField: "pharmaCount"
      }],
      "export": {
        enabled: true
      },
      chartCursor: {
        cursorAlpha: 0,
        valueLineEnabled: true,
        valueLineBalloonEnabled: true,
        valueLineAlpha: 0.5,
        fullWidth: true
      },
      chartScrollbar: {
        gridAlpha: 0,
        color: "#888888",
        scrollbarHeight: 55,
        backgroundAlpha: 0,
        selectedBackgroundAlpha: 0.1,
        selectedBackgroundColor: "#888888",
        graphFillAlpha: 0,
        selectedGraphFillAlpha: 0,
        graphLineAlpha: 0.2,
        graphLineColor: "#c2c2c2",
        selectedGraphLineColor: "#888888",
        selectedGraphLineAlpha: 1
      },
      categoryField: "key",
      categoryAxis: {
        parseDates: false,
        title: "Prix de vente",
        guides: [{
          category: vm.myCat,
          toCategory: vm.myCatEnd,
          lineAlpha: 1,
          dashLength: 2,
          fillAlpha: 0.2,
          fillColor: "#CC0000",
          lineColor: "#CC0000",
          inside: false,
          labelRotation: 90,
          label: "Votre prix de vente"
        }]
      }
    };
  }
  /**
   * Retrieve national data from api response.
   *
   * @param {object} response - The api response.
   */


  function getNationalDatas(response) {
    vm.geographicalDatas.national = {
      name: "National",
      amount: response.nationalAverage.aggregations.amount_incl_tax,
      quantity: response.nationalAverage.aggregations.quantity,
      pharmaNumber: response.nationalAverage.aggregations.pharmaNumber
    };
  }

  vm.processQuery = function () {
    vm.markers = [];
    vm.loadingProduct = true;

    if (vm.usrQuery.must.product.selected.length === 0) {
      vm.noProduct = true;
    } else {
      vm.noProduct = false;
      vm.productSelected = vm.usrQuery.must.product.selected[0];
    }

    vm.stepPosition = 0;
    vm.stepMax = 0;
    vm.indexMax = 0;
    StatisticsQueryService.buildquery(vm.usrQuery, vm.usrQuery.date).then(function (query) {
      vm.query = query;
      vm.dateLabel = moment(vm.usrQuery.date.from).format("DD-MMM-YYYY") + " Au " + moment(vm.usrQuery.date.to).format("DD-MMM-YYYY");
      vm.query.echantillon = vm.echantillonSelected;
      analysisService.searchProductSales(vm.query).then(function (response) {
        vm.loadingProduct = false;
        vm.stepPosition = 0;
        vm.stepMax = 0;
        vm.indexMax = 0;
        vm.productAnalysisDatas = [];
        vm.averageDisplayed = [];
        vm.graphs = [];
        vm.filteredDatas = [];
        getErrorMargin(response);
        getNationalDatas(response);

        if (response.datas.aggregations.pharmacy.buckets.length > 0) {
          vm.noData = false;
          vm.maxPrice = 0;
          vm.minPrice = null;
          vm.average = {
            nb: 0,
            tot: 0
          };
          angular.forEach(response.datas.aggregations.pharmacy.buckets, function (data) {
            if (!_.isArray(vm.productAnalysisDatas)) {
              vm.productAnalysisDatas = [];
              vm.graphs = [];
            }

            if (typeof vm.averageDisplayed === "undefined") {
              vm.averageDisplayed = {
                nb: 0,
                tot: 0,
                maxPrice: 0
              };
            }

            if (data.amount_incl_tax.value !== 0) {
              var x = data.id.buckets[0].key + "x";
              var y = data.id.buckets[0].key + "y";
              var id = data.id.buckets[0].key;

              if (response.coords[id]) {
                var value = data.amount_incl_tax.value;
                vm.average.tot += value;
                vm.averageDisplayed.tot += value;
                vm.averageDisplayed.nb++;
                vm.average.nb++; // MIN & MAX PRICE

                if (vm.minPrice === null && value !== 0) {
                  vm.minPrice = value;
                }

                if (value < vm.minPrice && value !== 0) {
                  vm.minPrice = value;
                }

                if (typeof vm.averageDisplayed.minPrice === "undefined" && value !== 0) {
                  vm.averageDisplayed.minPrice = value;
                }

                if (value < vm.averageDisplayed.minPrice && value !== 0) {
                  vm.averageDisplayed.minPrice = value;
                }

                if (value > vm.averageDisplayed.maxPrice) {
                  vm.averageDisplayed.maxPrice = value;
                }

                if (value > vm.maxPrice) {
                  vm.maxPrice = value;
                }

                vm.productAnalysisDatas[x] = Math.round(data.amount_incl_tax.value * 100) / 100;
                vm.productAnalysisDatas[y] = data.quantity.value;
                vm.productAnalysisDatas[id] = data.key;
                var balloonText = vm.currentEnv.accessPointType !== 2 ? "<b> [[" + id + "]]</b> " : "" + "<br> [[y]] unités, " + value.toFixed(2) + " € ";
                vm.graphs.push({
                  balloonText: balloonText,
                  bullet: "diamond",
                  valueField: id,
                  title: vm.currentEnv.accessPointType === 3 ? data.key : "",
                  xField: x,
                  yField: y
                });
              }
            }
          });
          vm.globalAverage = vm.average.tot / vm.average.nb;
          createChart(vm.productAnalysisDatas, vm.graphs);
          vm.markers = processMarkers(response);

          if (vm.currentEnv.accessPointType === 2) {
            getGeographicalDatas(response);
          }
        } else {
          vm.noData = true;
        }
      });
    });
  }; // SEARCH FORM


  vm.selectedObjChange = function (obj, list) {
    list.selected = [];

    if (obj && list.selected.indexOf(obj) === -1) {
      list.selected.push(obj);
    }

    $scope.amChartOptions = null;
    vm.productAnalysisDatas = [];
    vm.graphs = [];
    vm.loadingProduct = false;
    vm.processQuery(vm.usrQuery);
  };
  /*
       ** Date Filter
       */


  vm.rangeSelected = function (date) {
    vm.usrQuery.date.from = moment(date.startDate, "DD-MM-YYYY");
    vm.usrQuery.date.to = moment(date.endDate, "DD-MM-YYYY");
    vm.processQuery(vm.usrQuery);
  };
  /**
   * Handle response to make markers on map.
   *
   * @param {object} response - The api response.
   *
   * @returns {object} markers - The markers.
   */


  function processMarkers(response) {
    if (vm.markers.length > 0) {
      angular.forEach(vm.markers, function (marker) {
        if (marker) {
          marker.setMap(null);
        }
      });
    }

    vm.markers = [];
    vm.bounds = new google.maps.LatLngBounds();
    var markers = [];
    var latLng = {};
    angular.forEach(response.datas.aggregations.pharmacy.buckets, function (data) {
      if (data.amount_incl_tax.value !== 0) {
        var id = data.id.buckets[0].key;

        var stock = _.find(response.stocks.aggregations.pharmacy.buckets, function (stock) {
          return parseInt(stock.key) === id;
        });

        if (response.coords[id]) {
          var coords = response.coords[id];
          var value = data.amount_incl_tax.value;
          latLng = new google.maps.LatLng(coords.geoLat, coords.geoLon);
          var marker = new google.maps.Marker({
            position: latLng
          });
          var infowindow = new google.maps.InfoWindow({});
          marker.addListener("click", function () {
            closeLastOpenedInfoWindow();
            var content = "";

            if (vm.currentEnv.accessPointType === 3 || vm.currentEnv.accessPointType === 1) {
              content = "<div><h2>" + data.key + "</h2>";

              if (data.group && data.group.lenght > 0) {
                content += "<h3>Groupement : " + data.group[1] + "</h3>";
              }
            }

            content += "<p>Quantité Vendue : " + data.quantity.value + "</p>" + "<p>Prix (moyen constaté) : " + value.toFixed(2) + " € </p>";

            if (typeof stock !== "undefined") {
              content += "<p>Stock disponible : " + stock.top_sales_hits.hits.hits[0]._source.quantity + "</p>" + "<p>Dernier prix de vente constaté : " + data.price.hits.hits[0]._source.item.price.sell.unit_ttc + " € </p>" + "<p>Dernière quantité vendue constatée : " + data.price.hits.hits[0]._source.item.quantity + "</p>";
            }

            var compiled = $compile(content)($scope);
            $scope.$apply();
            infowindow.setContent(compiled[0]);
            infowindow.open(vm.map, marker);
            vm.lastOpenedInfoWindow = infowindow;
          });
          marker.setMap(vm.map);
          vm.stepMax++;
          markers.push(marker);
          vm.bounds.extend(latLng);
        }
      }
    });
    vm.map.fitBounds(vm.bounds);
    return markers;
  }
  /**
   * Close last opened information window.
   */


  function closeLastOpenedInfoWindow() {
    if (vm.lastOpenedInfoWindow) {
      vm.lastOpenedInfoWindow.close();
    }
  }

  vm.changeErrorDatas = function () {
    if (vm.echantillonGeoSelected === "group") {
      vm.echantillonGeoSelectedName = "du Groupement";
      vm.errorData = vm.errorGroupData;
    } else {
      vm.errorData = vm.errorNationalData;
      vm.echantillonGeoSelectedName = "Nationaux";
    }

    vm.amChartErrorOptions = getErrorOptions();
  };

  environmentService.getEnvironment().then(function (env) {
    vm.currentEnv = env;
    vm.config = {
      event: "productAnalysisFilterChange",
      search: vm.searchByEnv[vm.currentEnv.accessPointType],
      limits: {
        product: 1
      },
      geoForce: true,
      showBadge: true
    };

    if (env.accessPointType !== 2 || env.groupIds && env.groupIds.length > 0) {
      vm.hasGroup = true;
    }

    if (vm.currentEnv.accessPointType === 3 || vm.currentEnv.accessPointType === 2) {
      vm.echantillonGeoSelectedName = "Groupement";
      vm.echantillonGeoSelected = "group";
    } else {
      vm.echantillonGeoSelectedName = "Nationaux";
      vm.echantillonGeoSelected = "global";
    }

    if (vm.noProduct === false) {
      vm.processQuery();
    }

    if (vm.usrQuery.must.product.selected.length === 1 && vm.hasGroup) {
      vm.noProduct = false;
      initMap();
    }
  });
  /**
   * Initialization of the map.
   */

  function initMap() {
    var myLatLng = {
      lat: 47.6468754,
      lng: 2.3242131
    };
    vm.markers = [];
    var mapOptions = {
      center: new google.maps.LatLng(myLatLng),
      zoom: 3,
      minZoom: 0,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_CENTER
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      addressControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      fullscreenControl: true
    };
    vm.map = new google.maps.Map(document.getElementById("map_productAnalysis"), mapOptions);
    vm.noresult = false;
    vm.loading = false;
    vm.usrQuery = StatisticsQueryService.query;
    StatisticsQueryService.buildquery(vm.usrQuery, vm.usrQuery.date).then(function (query) {
      vm.query = query;
      vm.processQuery(vm.query, false);
    });
  }

  $scope.$on("productAnalysisFilterChange", function (event, searchusrQuery) {
    vm.loadingProduct = true;
    angular.forEach(_.keys(searchusrQuery), function (obj) {
      angular.forEach(searchusrQuery[obj], function (data) {
        if (obj === "product") {
          vm.usrQuery.must[obj].selected = [data];
        } else if (vm.usrQuery.must[obj] && vm.usrQuery.must[obj].selected.indexOf(data) === -1) {
          vm.usrQuery.must[obj].selected.push(data);
        }
      });
    });
    vm.noProduct = vm.usrQuery.must.product.selected.length === 0;
    initMap();
    vm.loadingProduct = false;
  });
}

angular.module("app.statistics.products").controller("StatsProductController", StatsProductController);