"use strict";
/**
 * Statistics smoother degressive margin configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

StatisticsSmoothedDegressiveMarginConfig.$inject = ["$stateProvider"];

function StatisticsSmoothedDegressiveMarginConfig($stateProvider) {
  $stateProvider.state("app.statistics.smoothed-degressive-margin", {
    url: "/analyse-mdl",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/statistics.html",
        controller: "StatisticsController as vm"
      },
      "statisticsContent@app.statistics.smoothed-degressive-margin": {
        templateUrl: "app/main/statistics/marge/smoothed-degressive-margin/smoothed-degressive-margin.html",
        controller: "SmoothedDegressiveMarginController as vm"
      },
      "tabContent@app.statistics.smoothed-degressive-margin": {
        templateUrl: "app/main/statistics/marge/smoothed-degressive-margin/mdl/mdl.html",
        controller: "MdlController as vm"
      }
    },
    bodyClass: "dashboard"
  }).state("app.statistics.smoothed-degressive-margin.future", {
    url: "/future",
    views: {
      "tabContent@app.statistics.smoothed-degressive-margin": {
        templateUrl: "app/main/statistics/marge/smoothed-degressive-margin/future/future.html",
        controller: "FutureController as vm"
      }
    }
  }).state("app.statistics.wholesalerAudit.smoothed-degressive-margin", {
    url: "/analyse-mdl",
    views: {
      "tabContent@app.statistics.wholesalerAudit": {
        templateUrl: "app/main/statistics/marge/smoothed-degressive-margin/mdl/mdl.html",
        controller: "MdlController as vm"
      }
    },
    bodyClass: "dashboard"
  }).state("app.statistics.wholesalerAudit.smoothed-degressive-margin.future", {
    url: "/future",
    views: {
      "tabContent@app.statistics.wholesalerAudit.smoothed-degressive-margin": {
        templateUrl: "app/main/statistics/marge/smoothed-degressive-margin/future/future.html",
        controller: "FutureController as vm"
      }
    }
  });
}

angular.module("app.statistics.smoothed-degressive-margin", []).config(StatisticsSmoothedDegressiveMarginConfig);