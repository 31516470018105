"use strict";
/**
 * Dashboard pharmacies top laboratory operator controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */

DashboardPharmaciesTopLaboratoryOperatorController.$inject = ["$scope", "StatisticsQueryService", "environmentService", "analysisService"];

function DashboardPharmaciesTopLaboratoryOperatorController($scope, StatisticsQueryService, environmentService, analysisService) {
  $scope.loading = true;
  $scope.currentEnv = environmentService.getEnvironment();
  $scope.usrquery = JSON.parse(JSON.stringify(StatisticsQueryService.pristineQuery));
  var dateStart = moment().month(0).date(0);
  var dateEnd = moment();
  loader();
  /**
   * Loader.
   */

  function loader() {
    getCurrentEnv();
  }
  /**
   * Get current environment.
   */


  function getCurrentEnv() {
    $scope.currentEnv.then(function (env) {
      $scope.environment = env;
      $scope.usrquery.date = {
        from: new Date(dateStart),
        to: new Date(dateEnd)
      };
      $scope.usrquery.must.operator.selected.push({
        id: env.operatorCode
      });
      StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
        $scope.query = query;
        getTopParapharmacieLab($scope.usrquery);
      });
    });
  }
  /**
   * Get top parapharmacie laboratory.
   *
   * @param {object} query -
   */


  function getTopParapharmacieLab(query) {
    query.must.product.selected = [];
    query.must.category.selected.push({
      id: 1403
    });
    StatisticsQueryService.buildquery(query, query.date).then(analysisService.getTop5Laboratory).then(function (data) {
      $scope.topLaboPara = data.aggregations.salesLaboratory.buckets[0];
      query.must.category.selected = [];
      query.must.tva.selected.push({
        value: 8
      });
      return StatisticsQueryService.buildquery(query, query.date);
    }).then(analysisService.getTop5Laboratory).then(function (data) {
      $scope.topLaboOtc = data.aggregations.salesLaboratory.buckets[0];
      $scope.loading = false;
    });
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesTopLaboratoryOperatorController", DashboardPharmaciesTopLaboratoryOperatorController);