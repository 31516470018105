"use strict";
/**
 * Order schedule modal controller.
 *
 * @param {object} $scope - $scope
 * @param {object} $mdDialog - $mdDialog
 * @param {object} $timeout - The angular $timeout object.
 * @param {object} orderFactory - The order factory.
 * @param {object} order - The order object.
 * @param {object} customerService - The customerService.
 * @param {object} dateTimeService - The dateTimeService.
 * @param {object} notifyUserService - The notify user service.
 */

OrderScheduleModalController.$inject = ["$scope", "$mdDialog", "$timeout", "orderFactory", "order", "customerService", "dateTimeService", "notifyUserService"];

function OrderScheduleModalController($scope, $mdDialog, $timeout, orderFactory, order, customerService, dateTimeService, notifyUserService) {
  $scope.close = close;
  $scope.order = order;
  $scope.date;
  $scope.predefinedComments = orderFactory.predefinedComments;
  $scope.predefinedIndex = -1;
  $scope.comment;
  $scope.send = send;
  $scope.predefinedIndexChange = predefinedIndexChange;
  $scope.isLoading = false;
  $scope.minDate = moment().add(1, "d");
  $scope.selectedHour = moment().endOf("hour").add(1, "minutes");
  /**
   * Closes the modal.
   */

  function close() {
    $mdDialog.hide();
  }
  /**
   * Sends the form.
   */


  function send() {
    if ($scope.isLoading) {
      return;
    }

    $scope.isLoading = true;
    var availableAtDate = moment(($scope.date ? $scope.date.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")) + " " + $scope.selectedHour.format("HH:mm"), "YYYY-MM-DD HH:mm");
    var params = {
      accepted_at: dateTimeService.toDateTimeString(),
      available_at: dateTimeService.toDateTimeString(availableAtDate),
      operator_comment: $scope.comment
    };
    customerService.updateOrder($scope.order.id, params).then(function (response) {
      $scope.order.status = response.data.status;
      $scope.order.accepted_at = response.data.accepted_at;
      $scope.order.available_at = response.data.available_at;
      $scope.order.operator_comment = response.data.operator_comment;
      notifyUserService.showSuccessToast("La commande a été accepté.");
      close();
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue, veuillez réessayer.");
    })["finally"](function () {
      $scope.isLoading = false;
    });
  }
  /**
   * Update the comment $scope depending the selected predefined comments index.
   *
   * @param {number} index - The selected index.
   */


  function predefinedIndexChange(index) {
    $scope.predefinedIndex = index;
    $scope.comment = orderFactory.predefinedComments[index];
  }
}

angular.module("app.patients").controller("orderScheduleModalController", OrderScheduleModalController);