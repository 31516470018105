"use strict";
/**
 * Guest access service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

GuestAccessesService.$inject = ["apiClient"];

function GuestAccessesService(apiClient) {
  return {
    /**
     * Get all accesses.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/access/accessesAndGuests", {
        includes: ["rank"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.accesses;
      });
    },

    /**
     * Delete an access.
     *
     * @param {object} params - Parameters.
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/access/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.guest").service("guestAccessesService", GuestAccessesService);