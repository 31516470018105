"use strict";

angular.module("apodis").filter("age", function () {
  return function (input) {
    var birthday = new Date(input);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch

    var age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age && input) {
      return "- " + age + " ans";
    }

    return "";
  };
}).filter("capitalize", function () {
  return function (input) {
    return input ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : "";
  };
}).filter("rangeDateSellout", function () {
  return function (items, from, to) {
    if (from && to) {
      return items.filter(function (sellout) {
        return sellout.sellout.startDate > from.toISOString() && sellout.sellout.endDate < to.toISOString();
      });
    }

    return items;
  };
}).filter("selloutStatusFilter", function () {
  return function (items, status) {
    if (status === "accepted") {
      return items.filter(function (sellout) {
        return sellout.status === 1;
      });
    }

    if (status === "refused") {
      return items.filter(function (sellout) {
        return sellout.status === 2;
      });
    }

    if (status === "waiting") {
      return items.filter(function (sellout) {
        return sellout.status === 0;
      });
    }

    return items;
  };
}).filter("selloutTilesStatusFilter", function () {
  return function (items, status) {
    if (status === "accepted") {
      return items.filter(function (sellout) {
        return sellout.challengeStatus === 1;
      });
    }

    if (status === "refused") {
      return items.filter(function (sellout) {
        return sellout.challengeStatus === 2;
      });
    }

    if (status === "waiting") {
      return items.filter(function (sellout) {
        return sellout.challengeStatus === 0;
      });
    }

    return items;
  };
}).filter("filterByName", function () {
  return function (items, search) {
    if (search) {
      return items.filter(function (sellout) {
        return sellout.name.indexOf(search) > -1;
      });
    }

    return items;
  };
}).filter("rangeDate", function () {
  return function (items, from, to) {
    if (from && to) {
      return items.filter(function (item) {
        return item.startDate > from.toISOString() && item.endDate < to.toISOString();
      });
    }

    return items;
  };
}).filter("range", function () {
  return function (input, begin, total, step) {
    var parsedTotal = parseInt(total);

    for (var index = begin; index <= parsedTotal; index += step) {
      input.push(index);
    }

    return input;
  };
}).filter("AtclFilterBelongsTo", function () {
  return function (input, search) {
    if (!input) {
      return input;
    }

    if (!search) {
      return input;
    }

    var result = {};
    angular.forEach(input, function (value, key) {
      if (value.belongs === search && typeof value.atclSelected !== "undefined") {
        result[key] = value;
      }
    });
    return result;
  };
}).filter("pictureCount", function () {
  return function (pictures, status) {
    return pictures.filter(function (picture) {
      return picture.status === status;
    }).length;
  };
}).filter("raw", ["$sce", function ($sce) {
  return function (val) {
    return $sce.trustAsHtml(val);
  };
}]).filter("toDateString", function () {
  return function (date) {
    var today = moment();
    var dateMoment = moment(date, "YYYY/M");

    if (today.month() === dateMoment.month()) {
      return "Mois en cours";
    }

    var dateString = dateMoment.format("MMMM YYYY");
    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
  };
}).filter("cut", function () {
  return function (value, wordwise, max, tail) {
    if (!value) {
      return "";
    }

    var maxi = parseInt(max, 10);

    if (!maxi) {
      return value;
    }

    if (value.length <= maxi) {
      return value;
    }

    var valueModified = value.substr(0, maxi);

    if (wordwise) {
      var lastspace = valueModified.lastIndexOf(" ");

      if (lastspace !== -1) {
        // Also remove . and , so its gives a cleaner result.
        if (valueModified.charAt(lastspace - 1) === "." || valueModified.charAt(lastspace - 1) === ",") {
          lastspace = lastspace - 1;
        }

        valueModified = valueModified.substr(0, lastspace);
      }
    }

    return valueModified + (tail || " …");
  };
}).filter("round", function () {
  return function (value) {
    return Math.round(value);
  };
});