"use strict";
/**
 * Healthcenter audit general infos controller.
 *
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $q - $q.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} healthcenterAuditHcDetailsService - Healthcenter audit details service.
 * @param {object} healthCenterAuditAnalysisService - Healthcenter audit analysis service.
 * @param {object} environmentService - Environment service.
 * @param {object} segmentService - Segment service.
 * @param {object} $scope - $scope.
 * @param {object} analysisService - Analysis service.
 * @param {object} $mdDialog - The md dialog object.
 * @param {object} eventService - The events service.
 * @param {object} usersPrestationService - The user prestation service.
 */

AppointmentScheduleModalController.$inject = ["$scope", "$mdDialog", "dateTimeService", "eventService", "notifyUserService", "healthcenterId"];
HealthcenterAuditGeneralInfosController.$inject = ["$state", "$stateParams", "$q", "StatisticsQueryService", "healthcenterAuditHcDetailsService", "healthCenterAuditAnalysisService", "environmentService", "segmentService", "$scope", "analysisService", "$mdDialog", "eventService", "usersPrestationService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function HealthcenterAuditGeneralInfosController($state, $stateParams, $q, StatisticsQueryService, healthcenterAuditHcDetailsService, healthCenterAuditAnalysisService, environmentService, segmentService, $scope, analysisService, $mdDialog, eventService, usersPrestationService) {
  var COMMERCIAL_APPOINTMENT_ID = 9;
  var PRINCEPS_ID = "1";
  var GENERIC_ID = "2";
  $scope.cartTotal = 0;
  $scope.vatTotal = 0;
  $scope.goBack = goBack;
  $scope.generatePdf = generatePdf;
  $scope.createAppointment = createAppointment;
  $scope.vatSplitDatas = $q.defer();
  $scope.genericAuditQuery = {};
  $scope.futureLaunches = [];
  $scope.pastLaunches = [];
  $scope.vatSplit = {
    type: "pie",
    theme: "light",
    decimalSeparator: ",",
    thousandsSeparator: " ",
    data: $scope.vatSplitDatas.promise,
    valueField: "sales",
    titleField: "vat",
    labelsEnabled: false,
    legend: {
      enabled: true,
      valueFunction: function valueFunction(data) {
        return Math.round(data.dataContext.roundedPercent * 100) / 100;
      }
    },
    balloon: {
      fixedPosition: true
    },
    "export": {
      enabled: true
    }
  };
  $scope.vatCorrespondTo = {
    5: "Tva à 2,10%",
    9: "Tva à 20%",
    8: "Tva à 10%",
    3: "Tva à 5,5%",
    2: "Tva à 19,6%"
  };
  $scope.exhaustPaginationOption = {
    order: "-sales.value",
    limit: 10,
    page: 1
  };
  $scope.productDisruptionsPaginationOption = {
    order: "-undeliveredTurnover",
    limit: 10,
    page: 1
  };
  $scope.laboratoryProductDisruptionsPaginationOption = {
    order: "-undeliveredTurnover",
    limit: 10,
    page: 1
  };

  if (typeof $stateParams.id === "undefined") {
    goBack();
  }

  $scope.loaderContribution = {
    loading: true,
    loader: "Chargement des contributions"
  };
  $scope.loaderFutureLaunches = {
    loading: true,
    loader: "Chargement des futurs lancements"
  };
  $scope.loaderPastLaunches = {
    loading: true,
    loader: "Chargement des lancements"
  };
  $scope.loaderPerformance = {
    loading: true,
    loader: "Chargement des performances"
  };
  $scope.loaderPrestation = {
    loading: true,
    loader: "Chargement des prestations"
  };
  $scope.loaderPerformanceEvolution = {
    loading: true,
    loader: "Chargement de l'évolution"
  };
  $scope.loaderTop10Refund = {
    loading: true,
    loader: "Chargement du CA"
  };
  $scope.loaderTop10NotRefund = {
    loading: true,
    loader: "Chargement du CA"
  };
  $scope.loaderMarketSharesEvolution = {
    loading: true,
    loader: "Chargement de l'évolution des parts de marché"
  };
  $scope.loaderGenericAudit = {
    loading: true,
    loader: "Chargement de l'analyse des remises"
  };
  $scope.loaderCartStats = {
    loading: true,
    loader: "Chargement de la fréquentation"
  };
  $scope.loaderProductDisruptions = {
    loading: true,
    loader: "Chargement des ruptures"
  };
  $scope.loaderLaboratoryProductDisruptions = {
    loading: true,
    loader: "Chargement des produits en rupture"
  };
  var defaultQuery = StatisticsQueryService.substituteQuery;
  /**
   * Get next event by type.
   *
   * @param {number} typeId - The type id.
   */

  function getNextEventOfType(typeId) {
    eventService.getNextEventOfType(typeId, $stateParams.id).then(function (event) {
      event.startDate = new Date(moment(event.startDate.replace(" ", "T"), "YYYY-MM-DDThh:mm:ss"));
      $scope.nextEvent = event;
    });
  }
  /**
   * Handle performance for market shares evolution.
   *
   * @param  {object} performance - The sales performance.
   *
   * @returns {Array}
   */


  function getMarketSharesEvolution(performance) {
    var totalMarketSharesSales = 0;
    var salesCUF = 0;
    var forExtrapolation = 0;
    var marketSharesEvolution = performance.aggregations.sales.salesFiltered.months.buckets.map(function (sale, index) {
      totalMarketSharesSales += performance.aggregations.totalSalesFromBeginningOfYear.month.buckets[index].amount_excl_tax.value;

      if (totalMarketSharesSales !== 0) {
        var marketSharesSales = performance.aggregations.totalSalesFromBeginningOfYear.month.buckets[index].amount_excl_tax.value;
        salesCUF += sale.amount_excl_tax.value;
        forExtrapolation += salesCUF;
        return {
          date: new Date(sale.key),
          sales: sale.amount_excl_tax.value,
          salesCUF: salesCUF,
          marketShares: sale.amount_excl_tax.value / marketSharesSales * 100,
          marketSharesCUF: salesCUF / totalMarketSharesSales * 100,
          totalSalesCUF: totalMarketSharesSales
        };
      }

      return null;
    }).filter(Boolean);
    var firstDate = marketSharesEvolution[0].date;
    var extrapolation = salesCUF + forExtrapolation / marketSharesEvolution.length;

    for (var length = marketSharesEvolution.length; length < 12; length++) {
      salesCUF += extrapolation;
      var date = new Date(moment(firstDate).add(length, "month"));
      marketSharesEvolution.push({
        date: date,
        extrapolation: salesCUF
      });
    }

    return marketSharesEvolution;
  }
  /**
   * Get generic discount
   */


  function getGenericDiscount() {
    // Build the elastic search query
    var dateStart = moment().month(0).date(1);
    var dateEnd = moment();
    defaultQuery.date = {
      from: new Date(dateStart),
      to: new Date(dateEnd)
    };
    defaultQuery.lab.selected = [];
    defaultQuery.must = {};
    defaultQuery.must.pharmas = {};
    defaultQuery.must.pharmas.selected = [$scope.pharma];
    StatisticsQueryService.buildSubstituteQuery(defaultQuery).then(function (query) {
      $scope.genericAuditData = {};
      query.paracetamol = 0;
      return analysisService.searchGeneric(query).then(function (response) {
        $scope.genericAuditData = response;
        analysisService.searchPotentialGeneric(query).then(function (response) {
          $scope.genericPotential = {};
          response.laboratory.forEach(function (laboratory) {
            if (laboratory.quadri) {
              $scope.genericPotential[laboratory.quadri] = laboratory.totalPrice;
            }
          });
          $scope.totalGenericSales = response.totalPrice;
          $scope.loaderGenericAudit.loading = false;
        });
      });
    });
  }
  /**
   * Get top 10 presentation in exhaust.
   *
   * @param {number} healthcenterId - The healthcenter id.
   *
   * @returns {Promise}
   */


  function getTop10ExhaustPresentation(healthcenterId) {
    return healthcenterAuditHcDetailsService.getTop10ExhaustPresentation(healthcenterId).then(function (top10) {
      return top10;
    });
  }
  /**
   * Get product disruptions.
   *
   * @param {number} healthcenterId - The healthcenter id.
   *
   * @returns {Promise}
   */


  function getProductDisruptions(healthcenterId) {
    return healthcenterAuditHcDetailsService.getProductDisruptions(healthcenterId);
  }
  /**
   * Get laboratory product disruptions.
   *
   * @param {number} healthcenterId - The healthcenter id.
   *
   * @returns {Promise}
   */


  function getLaboratoryProductDisruptions(healthcenterId) {
    return healthcenterAuditHcDetailsService.getLaboratoryProductDisruptions(healthcenterId);
  }
  /**
   * Get product launches.
   *
   * @param {number} healthcenterId - The healthcenter id.
   *
   * @returns {Promise}
   */


  function getLaunches(healthcenterId) {
    return healthcenterAuditHcDetailsService.getLaunches(healthcenterId).then(function (launches) {
      launches.forEach(function (productLaunch) {
        $scope.pastLaunches.push(_objectSpread(_objectSpread({}, productLaunch), {}, {
          start_date: new Date(productLaunch.start_date),
          currentEnvTurnover: productLaunch.sales.find(function (sale) {
            return parseInt(sale.key) === $scope.currentEnv.entityId;
          }),
          marketTurnover: productLaunch.sales.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue.sales.value;
          }, 0)
        }));
      });
      return launches;
    });
  }
  /**
   * Get future launches.
   *
   * @param {number} healthcenterId - The healthcenter id.
   *
   * @returns {Promise}
   */


  function getFutureLaunches(healthcenterId) {
    return healthcenterAuditHcDetailsService.getFutureLaunches(healthcenterId).then(function (futureLaunches) {
      $scope.futureLaunches = futureLaunches.map(function (genGroup) {
        var princeps = genGroup.sales.buckets.filter(function (type) {
          return type.key === PRINCEPS_ID;
        })[0];
        var generic = genGroup.sales.buckets.filter(function (type) {
          return type.key === GENERIC_ID;
        })[0];
        return _objectSpread(_objectSpread({}, genGroup), {}, {
          princepsSales: princeps ? princeps.quantity.value : 0,
          genericSales: generic ? generic.quantity.value : 0
        });
      });
      return futureLaunches;
    });
  }
  /**
   * Get substitution.
   *
   * @param {string} healthcenterId - The healthcenter id.
   */


  function getSubstitution(healthcenterId) {
    healthcenterAuditHcDetailsService.getSubstitution(healthcenterId).then(function (data) {
      $scope.substitutionData = data.aggregations.operator.buckets.map(function (bucket) {
        if (bucket.prodType.buckets.length > 0) {
          var genericSales = bucket.prodType.buckets[0].key === "2" ? bucket.prodType.buckets[0].quantity.value : bucket.prodType.buckets[1] ? bucket.prodType.buckets[1].quantity.value : 0;
          var totalSales = bucket.prodType.buckets.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue.quantity.value;
          }, 0);
          return {
            operator: bucket.key,
            objWithRank: bucket.obj,
            substitutionRate: genericSales / totalSales * 100
          };
        }
      });
      $scope.loading = false;
    });
  }
  /**
   * Get prestation Infos.
   *
   * @param {number} healthcenterId - The healthcenter id.
   */


  function getPrestationInfos(healthcenterId) {
    usersPrestationService.getUserPrestationsByOrganization(healthcenterId).then(function (prestations) {
      $scope.prestationsCount = prestations.length;
      $scope.userPrestationPhotoCountToValidate = 0;
      $scope.userPrestationPhotoCountToTake = 0;
      prestations.forEach(function (prestation) {
        if (prestation.pendingPhotoCount) {
          $scope.userPrestationPhotoCountToValidate += prestation.pendingPhotoCount;
        } else if (!prestation.acceptedPhotoCount) {
          $scope.userPrestationPhotoCountToTake += 1;
        }
      });
      $scope.loaderPrestation.loading = false;
    });
  }
  /**
   * Get healthcenter qualification.
   *
   * @param {number} healthcenterId - The healthcenter id.
   */


  function getHealthcenterQualification(healthcenterId) {
    segmentService.getHealthcenterQualification(healthcenterId).then(function (data) {
      $scope.segmentQualification = data;
    });
  }
  /**
   * Get pharmaceutical delegate report.
   *
   * @param {string} healthcenterId - The healthcenter id.
   */


  function getPharmaceuticalDelegateReport(healthcenterId) {
    healthcenterAuditHcDetailsService.getPerformance(healthcenterId).then(function (performance) {
      $scope.performanceEvolution = performance.aggregations.sales.sales.buckets.map(function (sale, index) {
        return {
          date: new Date(sale.key),
          sales: sale.amount_excl_tax.value,
          salesLastYear: performance.aggregations.salesLastYear.salesLastYear.buckets[index].amount_excl_tax.value,
          salesEvolution: 100 - performance.aggregations.salesLastYear.salesLastYear.buckets[index].amount_excl_tax.value / sale.amount_excl_tax.value * 100,
          units: sale.quantity.value,
          unitsLastYear: performance.aggregations.salesLastYear.salesLastYear.buckets[index].quantity.value,
          unitsEvolution: 100 - performance.aggregations.salesLastYear.salesLastYear.buckets[index].quantity.value / sale.quantity.value * 100
        };
      });
      $scope.nextEvent = getNextEventOfType(COMMERCIAL_APPOINTMENT_ID);
      $scope.loaderPerformanceEvolution.loading = false;
      $scope.marketSharesEvolution = getMarketSharesEvolution(performance);
      $scope.loaderMarketSharesEvolution.loading = false;
      $scope.sales = getSales(performance);
      $scope.loaderPerformance.loading = false;
      $scope.loaderCartStats.loading = false;
    });
    getTop10ExhaustPresentation(healthcenterId).then(function (top10) {
      $scope.top10ExhaustPresentation = top10;
    });
    getProductDisruptions(healthcenterId).then(function (productDisruptions) {
      $scope.productDisruptions = productDisruptions.map(function (productDisruption) {
        if (productDisruption.disruption.missing.sum > 0) {
          return productDisruption;
        }

        return null;
      }).filter(Boolean);
    });
    getLaboratoryProductDisruptions(healthcenterId).then(function (laboratoryProductDisruptions) {
      $scope.laboratoryProductDisruptions = laboratoryProductDisruptions.map(function (laboratoryProductDisruption) {
        if (laboratoryProductDisruption.disruption.missing.sum > 0) {
          return laboratoryProductDisruption;
        }

        return null;
      }).filter(Boolean);
    });
    getGenericDiscount();
    getSubstitution(healthcenterId);
    getPrestationInfos($scope.organization.id);
    $scope.productLaunches = getLaunches(healthcenterId);
    $scope.futureProductLaunches = getFutureLaunches(healthcenterId);
    getHealthcenterQualification(healthcenterId);
  }
  /**
   * Get sales since beginning of the year.
   *
   * @param {object} sales - The sales to handle.
   *
   * @returns {object}
   */


  function getSales(sales) {
    return {
      turnoverFromJanuary: sales.aggregations.salesFromBeginningOfYear.amount_excl_tax.value,
      quantityOfCartFromJanuary: sales.aggregations.quantityOfCartFromBeginningOfYear.quantityOfCart.value,
      turnoverEvolutionFromJanuary: 100 - sales.aggregations.salesFromBeginningOfYearLastYear.amount_excl_tax.value / sales.aggregations.salesFromBeginningOfYear.amount_excl_tax.value * 100,
      quantityOfCartFromJanuaryEvolution: 100 - sales.aggregations.quantityOfCartFromBeginningOfYearLastYear.quantityOfCart.value / sales.aggregations.quantityOfCartFromBeginningOfYear.quantityOfCart.value * 100,
      turnover: sales.aggregations.salesOneYear.amount_excl_tax.value,
      quantityOfCart: sales.aggregations.quantityOfCartTotal.quantityOfCart.value,
      turnoverEvolution: 100 - sales.aggregations.salesOneYearLastYear.amount_excl_tax.value / sales.aggregations.salesOneYear.amount_excl_tax.value * 100,
      quantityOfCartEvolution: 100 - sales.aggregations.quantityOfCartTotalLastYear.quantityOfCart.value / sales.aggregations.quantityOfCartTotal.quantityOfCart.value * 100,
      quantity: sales.aggregations.salesOneYear.quantity.value,
      quantityEvolution: 100 - sales.aggregations.salesOneYearLastYear.quantity.value / sales.aggregations.salesOneYear.quantity.value * 100,
      turnoverThisMonth: sales.aggregations.salesFromBeginningOfTheMonth.amount_excl_tax.value,
      quantityOfCartThisMonth: sales.aggregations.quantityOfCartFromBeginningOfMonth.quantityOfCart.value,
      turnoverEvolutionThisMonth: 100 - sales.aggregations.salesFromBeginningOfTheMonthLastYear.amount_excl_tax.value / sales.aggregations.salesFromBeginningOfTheMonth.amount_excl_tax.value * 100,
      quantityOfCartEvolutionThisMonth: 100 - sales.aggregations.quantityOfCartFromBeginningOfMonthLastYear.quantityOfCart.value / sales.aggregations.quantityOfCartFromBeginningOfMonth.quantityOfCart.value * 100,
      quantityThisMonth: sales.aggregations.salesFromBeginningOfTheMonth.quantity.value,
      quantityEvolutionThisMonth: 100 - sales.aggregations.salesFromBeginningOfTheMonthLastYear.quantity.value / sales.aggregations.salesFromBeginningOfTheMonth.quantity.value * 100,
      quantityFromJanuary: sales.aggregations.salesFromBeginningOfYear.quantity.value,
      quantityEvolutionFromJanuary: 100 - sales.aggregations.salesFromBeginningOfYearLastYear.quantity.value / sales.aggregations.salesFromBeginningOfYear.quantity.value * 100
    };
  }
  /**
   * Get top 10 laboratories and others.
   *
   * @param {number} healthcenterId - The healthcenter id.
   */


  function getTop10Laboratories(healthcenterId) {
    healthcenterAuditHcDetailsService.getTop10Laboratories(healthcenterId).then(function (top10) {
      $scope.top10Refund = top10.refundable;
      $scope.top10NotRefund = top10.not_refundable;
      $scope.totalTop10Refund = 0;
      $scope.totalTop10RefundUnit = 0;
      $scope.totalTop10RefundLastYear = 0;
      $scope.totalTop10NotRefund = 0;
      $scope.top10Refund.forEach(function (laboSales) {
        $scope.totalTop10Refund += laboSales.sales;
        $scope.totalTop10RefundUnit += laboSales.quantity;
      });
      $scope.top10Refund.forEach(function (laboSales) {
        $scope.totalTop10RefundLastYear += laboSales.salesLastYear;
        $scope.totalTop10RefundUnitLastYear += laboSales.quantityLastYear;
      });
      $scope.top10NotRefund.forEach(function (laboSales) {
        $scope.totalTop10NotRefund += laboSales.sales;
      });
      $scope.contributionRefundOptions = setContributionRefundOptions($scope.top10Refund);
      $scope.contributionNotRefundOptions = setContributionRefundOptions($scope.top10NotRefund);
      $scope.top10Refund.push({
        laboratoryName: "Autres",
        quantity: top10.refundabletotal.sales.unit.value - $scope.totalTop10RefundUnit,
        sales: top10.refundabletotal.sales.sales.value - $scope.totalTop10Refund,
        salesLastYear: top10.refundabletotal.lastYear.sales.value - $scope.totalTop10RefundLastYear
      });
      $scope.loaderTop10Refund.loading = false;
      $scope.loaderTop10NotRefund.loading = false;
    });
  }
  /**
   * Set operators contribution options.
   *
   * @param {object} data - The data.
   *
   * @returns {object}
   */


  function setContributionRefundOptions(data) {
    return {
      type: "serial",
      theme: "light",
      rotate: true,
      marginBottom: 50,
      marginLeft: 50,
      data: data.map(function (datum) {
        return {
          laboratory: datum.laboratoryName,
          evolution: datum.sales - datum.salesLastYear
        };
      }),
      graphs: [{
        fillAlphas: 1,
        lineAlpha: 0.2,
        type: "column",
        valueField: "evolution",
        title: "Contribution à la croissance",
        clustered: false,
        balloonFunction: function balloonFunction(item) {
          return item.category + " : " + parseFloat(item.values.value).toFixed(0) + " €";
        }
      }],
      categoryField: "laboratory",
      categoryAxis: {
        gridPosition: "start",
        gridAlpha: 0.2,
        axisAlpha: 0,
        parseDates: false
      },
      valueAxes: [{
        gridAlpha: 0,
        ignoreAxisWidth: true,
        labelFunction: function labelFunction(value) {
          return parseFloat(value).toFixed(0) + " €";
        },
        guides: [{
          value: 0
        }]
      }],
      chartCursor: {
        enabled: true,
        avoidBalloonOverlapping: false,
        balloonPointerOrientation: "vertical",
        oneBalloonOnly: true,
        cursorAlpha: 0,
        cursorColor: "#003D56"
      },
      "export": {
        enabled: false
      }
    };
  }
  /**
   * Loader.
   */


  function loader() {
    healthcenterAuditHcDetailsService.hCDetail($stateParams.id).then(function (pharma) {
      $scope.ids = [pharma.healthcenter.id];
      $scope.pharma = pharma.healthcenter;
      $scope.organization = pharma.healthcenter.organization;
      environmentService.getEnvironment().then(function (response) {
        $scope.currentEnv = response;
        $scope.isPharmaceuticalDelegate = response.accessPointType === 1 && response.rankId === 15;

        if ($scope.isPharmaceuticalDelegate) {
          segmentService.getSegmentsByHealthcenter(pharma.healthcenter.id).then(function (segments) {
            $scope.segments = segments;
          });
          getPharmaceuticalDelegateReport($stateParams.id);
          getTop10Laboratories($stateParams.id);
        }

        getStats();
        $scope.relaunchSS = lastLgoImport(response.entity.lastLgoImport);
      });
    });
  }
  /**
   * Last LGO import.
   *
   * @param {string} lastLgoImport - Last LGO import.
   *
   * @returns {boolean}
   */


  function lastLgoImport(lastLgoImport) {
    $scope.lastSynchro = moment().diff(moment(lastLgoImport), "days");
    return moment(lastLgoImport) <= moment().subtract(2, "days");
  }
  /**
   * Go back.
   */


  function goBack() {
    $state.go("app.healthcenterAudit");
  }
  /**
   * Generate PDF.
   *
   * @returns {boolean}
   */


  function generatePdf() {
    return window.print();
  }
  /**
   * Create appointment.
   *
   * @param {object} event - The click event.
   */


  function createAppointment(event) {
    $mdDialog.show({
      controller: "appointmentScheduleModalController",
      scope: $scope,
      preserveScope: true,
      locals: {
        healthcenterId: $stateParams.id
      },
      templateUrl: "app/main/statistics/healthcenter-audit/views/includes/modals/schedule-appointment.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true
    });
  }
  /**
   * Get stats.
   */


  function getStats() {
    StatisticsQueryService.queryRebase();
    var dateEnd = moment();
    var dateStart = moment().subtract(12, "month");
    var dateEndCopy = dateEnd;
    var dateStartCopy = dateStart;
    $scope.auditQuery = StatisticsQueryService.query;
    $scope.auditQuery.date = {
      from: dateStart,
      to: dateEnd
    };
    $scope.cartTotalForLabo = 0;
    $scope.numberofDays = dateEndCopy.diff(dateStartCopy, "days");
    $scope.auditQuery.must.laboratory.selected = [];
    statisticLoader();
  }
  /**
   * Statistic loader.
   */


  function statisticLoader() {
    StatisticsQueryService.buildquery($scope.auditQuery, $scope.auditQuery.date).then(function (query) {
      $scope.healthcenterQuery = query;
      $scope.healthcenterQuery.must.healthcenter = [parseInt($scope.pharma.cip)];
      healthCenterAuditAnalysisService.sales($scope.healthcenterQuery).then(function (sales) {
        $scope.CA12LastMonths = sales.aggregations.amount_excl_tax.value;
        angular.forEach(sales.aggregations.sales.buckets, function (value) {
          $scope.cartTotal += value.cartTotal.value;
        });

        if ($scope.currentEnv.accessPointType === 1) {
          $scope.healthcenterQuery.must.laboratory = [$scope.currentEnv.entityId];
        }

        if ($scope.currentEnv.accessPointType === 3) {
          $scope.healthcenterQuery.must.groups = [$scope.currentEnv.entityId];
        }

        return $q.all([dailySalesLabo($scope.healthcenterQuery), salesByOperator($scope.healthcenterQuery), vatSplitSales($scope.healthcenterQuery), salesByOperator2($scope.healthcenterQuery), salesGlobalByOperator($scope.healthcenterQuery)]);
      });
    });
  }
  /**
   * Daily sales laboratory.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function dailySalesLabo(query) {
    return healthCenterAuditAnalysisService.sales(query).then(function (salesLabo) {
      $scope.CALaboratory12LastMonths = salesLabo.aggregations.amount_excl_tax.value;
      angular.forEach(salesLabo.aggregations.sales.buckets, function (valueLabo) {
        $scope.cartTotalForLabo += valueLabo.cartTotal.value;
      });
    });
  }
  /**
   * Sales by  operator.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function salesByOperator(query) {
    query.aggby = "item.operator.id";
    return healthCenterAuditAnalysisService.sales(query).then(function (salesByOperator) {
      $scope.CALaboratoryByOperator = salesByOperator.aggregations.aggby.buckets;
      $scope.globalLaboSales = salesByOperator.aggregations.amount_excl_tax.value;
    });
  }
  /**
   * VAT split sales.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function vatSplitSales(query) {
    $scope.healthcenterQuery.aggby = null;
    $scope.healthcenterQuery.must.healthcenter = [parseInt($scope.pharma.cip)];
    return healthCenterAuditAnalysisService.vatSplit(query).then(function (sales) {
      var datas = [];
      angular.forEach(sales.aggregations.vat.buckets, function (ca) {
        datas.push({
          vat: $scope.vatCorrespondTo[ca.key],
          sales: ca.sales.value
        });
        $scope.vatTotal += ca.sales.value;
      });
      angular.forEach(datas, function (data) {
        data.roundedPercent = data.sales / $scope.vatTotal * 100;
      });
      $scope.vatSplitDatas.resolve(datas);
    });
  }
  /**
   * Sales by operator 2.
   *
   * @returns {Promise}
   */


  function salesByOperator2() {
    $scope.healthcenterQuery.aggby = "item.operator.id";
    return healthCenterAuditAnalysisService.sales($scope.healthcenterQuery).then(function (salesByOperator) {
      $scope.CALaboratoryByOperator = salesByOperator.aggregations.aggby.buckets;
      $scope.globalLaboSales = salesByOperator.aggregations.amount_excl_tax.value;
      $scope.auditQuery.must.laboratory.selected = [];
    });
  }
  /**
   * Sales global by operator.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function salesGlobalByOperator(query) {
    $scope.healthcenterQuery.aggby = "item.operator.id";
    $scope.healthcenterQuery.must.healthcenter = [parseInt($scope.pharma.cip)];
    return healthCenterAuditAnalysisService.sales(query).then(function (salesGlobalByOperator) {
      $scope.CAGlobalByOperator = [];
      angular.forEach(salesGlobalByOperator.aggregations.aggby.buckets, function (op) {
        $scope.CAGlobalByOperator[op.key] = op.amount_excl_tax.value;
      });
    });
  }

  loader();
}
/**
 * Appointment schedule modal controller.
 *
 * @param {object} $scope - $scope
 * @param {object} $mdDialog - $mdDialog
 * @param {object} dateTimeService - dateTimeService
 * @param {object} eventService - The event service.
 * @param {object} notifyUserService - The notify user service.
 * @param {number} healthcenterId - The healthcenter id.
 */


function AppointmentScheduleModalController($scope, $mdDialog, dateTimeService, eventService, notifyUserService, healthcenterId) {
  $scope.close = close;
  $scope.date;
  $scope.availableHours = generateHours(9, 21);
  $scope.comment;
  $scope.handleSelectedHour = handleSelectedHour;
  $scope.send = send;
  $scope.minDate = moment().add(1, "d");
  $scope.selectedHour = moment().endOf("hour").add(1, "minutes").format("HH:mm");
  $scope.isLoading = false;
  var COMMERCIAL_APPOINTMENT_ID = 9;
  /**
   * Closes the modal.
   */

  function close() {
    $mdDialog.hide();
    $scope.hide();
  }
  /**
   * Generates a hours array.
   *
   * @param {number} from - Hour from.
   * @param {number} to - Hour to.
   * @returns {Array}
   */


  function generateHours(from, to) {
    var items = [];

    for (var index = from; index < to; index++) {
      items.push(moment({
        hour: index
      }).format("HH:mm"));
    }

    return items;
  }
  /**
   * Handles selected hour.
   *
   * @param {string} hour - The selected hour.
   */


  function handleSelectedHour(hour) {
    $scope.selectedHour = hour;
  }
  /**
   * Sends the form.
   *
   * @returns {Promise}
   */


  function send() {
    $scope.isLoading = true;
    $scope.event = {
      organizations: []
    };
    var availableAtDate = moment(($scope.date ? $scope.date.format("DD/MM/YYYY") : moment().format("L")) + " " + $scope.selectedHour, "DD/MM/YYYY HH:mm");
    $scope.event.start_date = availableAtDate;
    $scope.event.end_date = availableAtDate.add(1, "hour");
    $scope.event.is_public = false;
    $scope.event.title = "Rendez-vous commercial";
    $scope.event.description = $scope.comment;
    $scope.event.event_type_id = COMMERCIAL_APPOINTMENT_ID;
    var pharma = {};
    pharma.entityTypeId = 2;
    pharma.id = healthcenterId;
    pharma.is_public = true;
    $scope.event.organizations.push(pharma);
    return eventService.create($scope.event).then(function (response) {
      $scope.event = response;
      notifyUserService.showSuccessToast("L'événement « " + response.title + " » a bien été créé.");
      $mdDialog.hide();
      $scope.isLoading = false;
      return response;
    });
  }
}

angular.module("app.healthcenterAudit").controller("HealthcenterAuditGeneralInfosController", HealthcenterAuditGeneralInfosController).controller("appointmentScheduleModalController", AppointmentScheduleModalController).filter("parseHours", function () {
  return function (text) {
    return text ? text.replace(":", "H").substring(0, 5) : "";
  };
});