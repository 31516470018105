"use strict";
/**
 * Dashboard laboratories sellout statistics controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} dashboardAnalysisService - Dashboard analysis service.
 */

DashboardLaboratoriesSelloutStatisticsController.$inject = ["$scope", "dashboardAnalysisService"];

function DashboardLaboratoriesSelloutStatisticsController($scope, dashboardAnalysisService) {
  getSelloutStatistics();
  /**
   * Get sellout statistics.
   */

  function getSelloutStatistics() {
    dashboardAnalysisService.getSelloutStatistics().then(function (selloutStatistics) {
      $scope.selloutStatisticsAccepted = selloutStatistics[0].aggregations;
      $scope.selloutStatisticsPending = selloutStatistics[1].aggregations;
      $scope.selloutStatisticsRefused = selloutStatistics[2].aggregations;
      $scope.selloutStatisticsAcceptedLastYear = selloutStatistics[3].aggregations;
      $scope.selloutStatisticsPendingLastYear = selloutStatistics[4].aggregations;
      $scope.selloutStatisticsRefusedLastYear = selloutStatistics[5].aggregations;
    });
  }
}

angular.module("app.dashboard").controller("dashboardLaboratoriesSelloutStatisticsController", DashboardLaboratoriesSelloutStatisticsController);