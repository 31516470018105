"use strict";
/**
 * Laboratory universe range controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $mdToast - $mdToast.
 * @param {object} environmentService - Environment service.
 * @param {object} productService - Product service.
 * @param {object} universeRangeService - Universe range service.
 * @param {object} sessionStorageService - Session storage service.
 */

LaboratoryUniverseRangeEditController.$inject = ["$scope", "$mdDialog", "firstLevel", "parentRangeId", "rangeId", "universeRangeService"];
LaboratoryUniverseRangeController.$inject = ["$scope", "$mdDialog", "$mdToast", "environmentService", "productService", "universeRangeService", "sessionStorageService"];

function LaboratoryUniverseRangeController($scope, $mdDialog, $mdToast, environmentService, productService, universeRangeService, sessionStorageService) {
  var vm = this;
  vm.ranges = [];
  vm.unassignedProducts = [];
  vm.token = sessionStorageService.getToken(); // Get the laboratory id

  environmentService.getEnvironment().then(function (env) {
    vm.laboratoryId = env.entityId;
    vm.productForRangeSearchConfig.must["laboratory.id"] = [env.entityId];
  });
  vm.productForRangeSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id"],
    event: "showProductFromSearch",
    searchPlaceHolder: "Rechercher les produits à classer dans un univers ou une gamme",
    must: {
      "laboratory.id": []
    }
  };
  vm.getList = getList;
  vm.selectRange = selectRange;
  vm.deleteRange = deleteRange;
  vm.toggleAllSearchProductsCheckboxStatus = toggleAllSearchProductsCheckboxStatus;
  vm.moveCheckedProductsToRange = moveCheckedProductsToRange;
  vm.handleUnassignedProducts = handleUnassignedProducts;
  vm.deleteSearchResults = deleteSearchResults;
  vm.showCreateEditRangeForm = showCreateEditRangeForm;
  vm.getList();
  /**
   * Get the list of all ranges and unassigned products
   *
   * @function getList
   */

  function getList() {
    vm.loading = true;
    vm.selectedRangeId = null;
    vm.isSelectedRangeIdAUniverse = null;
    vm.productsInRange = null;
    universeRangeService.get().then(function (response) {
      vm.ranges = response.ranges;
      vm.unassignedProducts = response.unassignedProducts;
      vm.loading = false;
    });
  }
  /**
   * Select a range and get inside products
   *
   * @function selectRange
   * @param {number} rangeId - The range ID
   * @param {boolean} isUniverse - is Universe (otherwise, Range)
   */


  function selectRange(rangeId, isUniverse) {
    var allSelectedRanges = document.querySelectorAll("#left-panel li.range span.selected");

    for (var index = 0; index < allSelectedRanges.length; index++) {
      allSelectedRanges[index].className = allSelectedRanges[index].className.replace(/\bselected\b/, "");
    }

    vm.selectedRangeId = rangeId;
    vm.isSelectedRangeIdAUniverse = isUniverse;
    document.getElementById("range_" + rangeId).className += " selected";
    universeRangeService.getProducts(rangeId).then(function (response) {
      vm.loading = false;
      vm.productsInRange = response;
    });
  }
  /**
   * Delete a range
   *
   * @param {number} rangeId - The range identifier
   */


  function deleteRange(rangeId) {
    var confirm = $mdDialog.confirm();
    confirm.title("Univers / gamme").textContent("Etes-vous sûr(e) de vouloir supprimer cet élément ? Tous les produits perdront leur classement.").targetEvent(event).ok("Supprimer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      universeRangeService["delete"](rangeId).then(function () {
        getList();
      });
    });
  }
  /**
   * Handle unassigned Products and put them into the search results
   *
   * @function handleUnassignedProducts
   */


  function handleUnassignedProducts() {
    vm.searchProducts = [];

    for (var index = 0; index < vm.unassignedProducts.length; index++) {
      vm.searchProducts.push({
        product: vm.unassignedProducts[index]
      });
    }
  }
  /**
   * Toggle All Search Products Checkbox Status description
   *
   * @function toggleAllSearchProductsCheckboxStatus
   * @param  {boolean}                              setChecked - to be checked or not
   */


  function toggleAllSearchProductsCheckboxStatus(setChecked) {
    var allCheckboxes = document.querySelectorAll("ul.search_products > li input[type='checkbox']");

    for (var index = 0; index < allCheckboxes.length; index++) {
      allCheckboxes[index].checked = setChecked;
    }
  }
  /**
   * Move checked products to the selected range
   *
   * @function moveCheckedProductsToRange
   */


  function moveCheckedProductsToRange() {
    var selectedRange = document.querySelector("li.range span.selected");

    if (!selectedRange) {
      $mdToast.show($mdToast.simple().textContent("Veuillez tout d'abord sélectionner un univers ou une gamme dans le volet de gauche.").position("top right"));
    } else {
      var rangeId = selectedRange.getAttribute("range-id");
      var checkedProductsIds = [];
      var checkedProducts = document.querySelectorAll("ul.search_products > li input:checked");

      for (var index = 0; index < checkedProducts.length; index++) {
        checkedProductsIds.push(checkedProducts[index].value);
      }

      if (checkedProductsIds.length === 0) {
        $mdToast.show($mdToast.simple().textContent("Veuillez sélectionner les produits à classer.").position("top right"));
      } else {
        universeRangeService.assignProducts(rangeId, checkedProductsIds).then(function (response) {
          var callbackMessage = response.isUniverse ? "un univers" : "une gamme";
          $mdToast.show($mdToast.simple().textContent(response.amountAssignedProducts + " produit(s) classé(s) dans " + callbackMessage + ".").position("top right"));
          vm.loading = false;
          vm.searchProducts = null;
          getList();
        });
      }
    }
  }
  /**
   * Delete search results to make a new search
   *
   * @function deleteSearchResults
   */


  function deleteSearchResults() {
    vm.searchProducts = null;
  }
  /**
   * Show the create form for a range or a universe
   *
   * @function showCreateRangeForm
   * @param {boolean} firstLevel - first level means universe
   * @param {number} rangeId - first level means universe
   */


  function showCreateEditRangeForm(firstLevel, rangeId) {
    // Get if a parent range is selected
    var selectedRange = document.querySelector("li.range span.selected");
    var parentRangeId = 0;

    if (selectedRange && !firstLevel) {
      parentRangeId = selectedRange.getAttribute("range-id");
    }

    var prompt = $mdDialog.prompt({
      controller: LaboratoryUniverseRangeEditController,
      templateUrl: "app/main/laboratory/universe-range/modals/universe-range-create-edit.html",
      parent: angular.element(document.body),
      locals: {
        firstLevel: firstLevel,
        parentRangeId: parentRangeId,
        rangeId: rangeId
      }
    });
    $mdDialog.show(prompt).then(function () {
      vm.getList();
    });
  }
  /**
   * On product search validation, get all product Ids and name
   */


  $scope.$on("showProductFromSearch", function (event, query) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(query, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(query[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in query) {
      _loop(type);
    } // get Product by type with query results


    getProductInformation(queryProduct);
  });
  /**
   * Get product information from Elastic Search
   *
   * @param {object} queryProduct - Query for Elastic Search
   */

  function getProductInformation(queryProduct) {
    productService.postPresentationByType(queryProduct).then(function (products) {
      vm.searchProducts = products.map(function (product) {
        return {
          product: {
            id: product.id,
            name: product.name
          }
        };
      });
    });
  }
}
/**
 * Laboratory universe range edit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {number} firstLevel - First level.
 * @param {number} parentRangeId - Parent range ID.
 * @param {number} rangeId - Range ID.
 * @param {object} universeRangeService - Universe range service.
 */


function LaboratoryUniverseRangeEditController($scope, $mdDialog, firstLevel, parentRangeId, rangeId, universeRangeService) {
  var vm = this;
  $scope.firstLevel = firstLevel;
  $scope.parentRangeId = parentRangeId;
  $scope.laboratoryRange = {};
  $scope.laboratoryRange.id = rangeId;
  vm.getOne = getOne();
  /**
   * Get one range information
   *
   * @function getOne
   */

  function getOne() {
    vm.loading = true;
    universeRangeService.read($scope.laboratoryRange.id).then(function (response) {
      $scope.laboratoryRange = response.range;
      $scope.laboratoryRanges = response.ranges;

      if ($scope.laboratoryRange === null) {
        $scope.laboratoryRange = {
          parent_range_id: $scope.parentRangeId
        };
      }

      vm.loading = false;
    });
  }
  /**
   * Close prompt (mdDialog)
   *
   * @function closePrompt
   */


  $scope.closePrompt = function () {
    $mdDialog.hide();
  };
  /**
   * Save the range
   *
   * @function saveRange
   */


  $scope.saveRange = function () {
    if ($scope.laboratoryRange.id && $scope.laboratoryRange.id > 0) {
      universeRangeService.update($scope.laboratoryRange.id, {
        range: $scope.laboratoryRange
      }).then(function () {
        $mdDialog.hide();
      });
    } else {
      universeRangeService.create({
        range: $scope.laboratoryRange
      }).then(function () {
        $mdDialog.hide();
      });
    }
  };
}

angular.module("app.laboratory-universe-range").controller("LaboratoryUniverseRangeController", LaboratoryUniverseRangeController).controller("LaboratoryUniverseRangeEditController", LaboratoryUniverseRangeEditController);