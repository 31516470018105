"use strict";
/**
 * Needs by healthcenters controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} groupingDashboardCapService - Grouping dashboard cap service.
 */

NeedsByPharmasController.$inject = ["$scope", "groupingDashboardCapService"];

function NeedsByPharmasController($scope, groupingDashboardCapService) {
  $scope.colors = ["#D21E03", "#BB4507", "#D27703", "#C88B05", "#0AD247", "#00BB87", "#0A65C8"];
  $scope.pharmaClicked = null;
  $scope.hasGraphsFiltered = [];
  $scope.showAll = showAll;
  loader();
  /**
   *Loader.
   */

  function loader() {
    $scope.loading = true;
    getNeedsByPharmas();
  }
  /**
   * Show all the needs.
   */


  function showAll() {
    $scope.needsByPharmas.data = $scope.dataSaved;
    $scope.pharmaClicked = null;
  }
  /**
   * Get needs by healthcenters.
   */


  function getNeedsByPharmas() {
    groupingDashboardCapService.getNeedsByPharmas().then(function (needs) {
      $scope.detailStocksGeneral = needs;
      processDataForAmCharts();
    });
  }
  /**
   * Process data for AmCharts.
   */


  function processDataForAmCharts() {
    var dataChartSerial = [];
    angular.forEach($scope.detailStocksGeneral, function (dataHc) {
      var name = dataHc.key;
      var id = dataHc.id.buckets[0].key;
      var sumOrderAmount = 0;
      var serialLine = {
        healthcenter: name,
        id: id,
        range1: 0,
        range1Pc: 0,
        range2: 0,
        range3: 0,
        range4: 0,
        range5: 0,
        range6: 0,
        range7: 0,
        products: []
      };
      angular.forEach(dataHc.range_days.buckets, function (dataRange) {
        var nbRange = dataRange.key + 1;
        var strRange = "range" + nbRange;

        if (nbRange < 4) {
          sumOrderAmount += dataRange.needs_amount.value;
          serialLine[strRange] = dataRange.needs_amount.value;
        } else {
          sumOrderAmount += dataRange.order_amount.value;
          serialLine[strRange] = dataRange.order_amount.value;
        }
      });

      if (sumOrderAmount !== 0) {
        serialLine.range1Pc = serialLine.range1 / sumOrderAmount * 100;
      }

      angular.forEach(dataHc.product.buckets, function (productRange) {
        var name = productRange.key;
        var id = productRange.id.buckets[0].key;
        var sumOrderAmount = 0;
        var serialLineProduct = {
          healthcenter: name,
          id: id,
          range1: 0,
          range1Pc: 0,
          range2: 0,
          range3: 0,
          range4: 0,
          range5: 0,
          range6: 0,
          range7: 0
        };
        angular.forEach(productRange.range_days.buckets, function (dataRange) {
          var nbRange = dataRange.key + 1;
          var strRange = "range" + nbRange;

          if (nbRange < 4) {
            sumOrderAmount += dataRange.needs_amount.value;
            serialLineProduct[strRange] = dataRange.needs_amount.value;
          } else {
            sumOrderAmount += dataRange.order_amount.value;
            serialLineProduct[strRange] = dataRange.order_amount.value;
          }
        });

        if (sumOrderAmount !== 0) {
          serialLineProduct.range1Pc = serialLineProduct.range1 / sumOrderAmount * 100;
        }

        serialLine.products.push(serialLineProduct);
      });

      if (sumOrderAmount !== 0) {
        serialLine.range1Pc = serialLine.range1 / sumOrderAmount * 100;
      }

      dataChartSerial.push(serialLine);
    });
    $scope.dataSaved = dataChartSerial;
    $scope.needsByPharmas = {
      type: "serial",
      theme: "light",
      data: dataChartSerial,
      thousandsSeparator: " ",
      percentPrecision: 0,
      precision: 0,
      startDuration: 0,
      rotate: true,
      angle: 30,
      depth3D: 10,
      mouseWheelScrollEnabled: true,
      listeners: [{
        event: "clickGraphItem",
        method: function method(obj) {
          if ($scope.pharmaClicked === null) {
            $scope.needsByPharmas.data = obj.item.dataContext.products;
            $scope.pharmaClicked = obj.item;
            $scope.$apply();
          }
        }
      }],
      legend: {
        autoMargins: true,
        borderAlpha: 0.8,
        useGraphSettings: true,
        horizontalGap: 10,
        markerSize: 10,
        valueAlign: "left",
        valueWidth: 0,
        clickMarker: handleLegendClick,
        clickLabel: handleLegendClick
      },
      chartCursor: {
        enabled: true,
        avoidBalloonOverlapping: false,
        balloonPointerOrientation: "vertical",
        oneBalloonOnly: true
      },
      chartScrollbar: {
        enabled: false
      },
      valueAxes: [{
        stackType: "regular",
        gridColor: "#FFFFFF",
        axisAlpha: 0,
        gridAlpha: 0,
        labelsEnabled: true,
        position: "left",
        margin: 50
      }],
      graphs: [{
        hidden: $scope.hasGraphsFiltered.range1 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 1,
        title: "< 10 jours",
        type: "column",
        lineColor: $scope.colors[0],
        columnWidth: 0.70,
        valueField: "range1",
        id: "range1"
      }, {
        hidden: $scope.hasGraphsFiltered.range2 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: "Entre 10 et 20 jours",
        type: "column",
        lineColor: $scope.colors[1],
        columnWidth: 0.70,
        valueField: "range2",
        id: "range2"
      }, {
        hidden: $scope.hasGraphsFiltered.range3 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: "Entre 21 et 30 jours",
        type: "column",
        lineColor: $scope.colors[2],
        columnWidth: 0.70,
        valueField: "range3",
        id: "range3"
      }, {
        hidden: $scope.hasGraphsFiltered.range4 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: "Entre 31 et 60 jours",
        type: "column",
        lineColor: $scope.colors[3],
        columnWidth: 0.70,
        valueField: "range4",
        id: "range4"
      }, {
        hidden: $scope.hasGraphsFiltered.range5 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: "Entre 61 et 90 jours",
        type: "column",
        lineColor: $scope.colors[4],
        columnWidth: 0.70,
        valueField: "range5",
        id: "range5"
      }, {
        hidden: $scope.hasGraphsFiltered.range6 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: ">90 jours",
        type: "column",
        lineColor: $scope.colors[5],
        columnWidth: 0.70,
        valueField: "range6",
        id: "range6"
      }, {
        hidden: $scope.hasGraphsFiltered.range7 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: "Stocks dormant",
        type: "column",
        lineColor: $scope.colors[6],
        color: "white",
        columnWidth: 0.70,
        valueField: "range7",
        id: "range7"
      }],
      autoMargins: true,
      autoDisplay: true,
      categoryField: "healthcenter",
      categoryAxis: {
        gridPosition: "start",
        position: "left",
        parseDates: false,
        labelFunction: function labelFunction(valueText) {
          if (valueText.length > 30) {
            return valueText.substring(0, 30) + "...";
          }

          return valueText;
        },
        listeners: [{
          event: "rollOverItem",
          method: function method(event) {
            if ($scope.pharmaClicked === null) {
              event.target.setAttr("cursor", "pointer");
            } else {
              event.target.setAttr("cursor", "default");
            }
          }
        }, {
          event: "rollOutItem",
          method: function method(event) {
            event.target.setAttr("cursor", "default");
          }
        }]
      },
      "export": {
        enabled: true
      }
    };
    $scope.loading = false;
  }
  /**
   * Define function to handle legend clicks.
   *
   * @param {object} graph - Graph.
   *
   * @returns {boolean}
   */


  function handleLegendClick(graph) {
    var chart = graph.chart;
    var reShowAll = false;

    if ($scope.hasGraphsFiltered[graph.id] === false) {
      for (var index = 0; index < chart.graphs.length; index++) {
        chart.showGraph(chart.graphs[index]);
        $scope.hasGraphsFiltered[chart.graphs[index].id] = false;
      }

      reShowAll = true;
      $scope.hasGraphsFiltered = [];
    }

    if (reShowAll === false) {
      for (var _index = 0; _index < chart.graphs.length; _index++) {
        if (graph.id === chart.graphs[_index].id) {
          chart.showGraph(chart.graphs[_index]);
          $scope.hasGraphsFiltered[chart.graphs[_index].id] = false;
        } else {
          chart.hideGraph(chart.graphs[_index]);
          $scope.hasGraphsFiltered[chart.graphs[_index].id] = true;
        }
      }
    }

    $scope.dataChartSerial.sort(function (left, right) {
      return left[graph.valueField] - right[graph.valueField];
    });
    $scope.dataChartSerial.reverse();
    $scope.stepPosition = 0;
    $scope.stepMax = $scope.dataChartSerial.length; // return false so that default action is canceled

    return false;
  }
}

angular.module("app.dashboard").controller("needsByPharmasController", NeedsByPharmasController);