"use strict";
/**
 * Time table directive.
 *
 * @returns {object}
 */

TimeTableController.$inject = ["$scope", "$rootScope", "apiurl", "$state", "eventService", "sellinOfferService", "sellinChallengeService", "sessionStorageService", "selloutFactory", "prestationService", "usersPrestationService", "eventFactory", "environmentService", "selloutService", "userSelloutService"];

function TimetableDirective() {
  return {
    restrict: "E",
    templateUrl: "app/main/directives/timetable/timetable.html",
    scope: {
      start: "@start",
      end: "@end",
      filters: "=filters"
    },
    controller: TimeTableController
  };
}
/**
 * Time table controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {string} apiurl - API URL.
 * @param {object} $state - $state.
 * @param {object} eventService - Events service.
 * @param {object} sellinOfferService - Sellin offer service.
 * @param {object} sellinChallengeService - Sellin challenge service.
 * @param {object} sessionStorageService - Session storage service.
 * @param {object} selloutFactory - Sellout factory.
 * @param {object} prestationService - Prestation service.
 * @param {object} usersPrestationService - Users prestation service.
 * @param {object} eventFactory - Event factory.
 * @param {object} environmentService - Environment service.
 * @param {object} selloutService - Sellout service.
 * @param {object} userSelloutService - User sellout service.
 */


function TimeTableController($scope, $rootScope, apiurl, $state, eventService, sellinOfferService, sellinChallengeService, sessionStorageService, selloutFactory, prestationService, usersPrestationService, eventFactory, environmentService, selloutService, userSelloutService) {
  $scope.ctrl = {};
  $scope.ctrl.typesSelected = [];
  $scope.ctrl.typesSelectedName = eventFactory.ctrl.typesSelectedName;
  $scope.token = sessionStorageService.getToken();
  $scope.apiurl = apiurl;
  $scope.imageFallback = "assets/images/sellouts/default-challenge-1080x600.jpg";
  $scope.toggle = toggle;
  $scope.exists = exists;
  $scope.filter = filter;
  $scope.goToEvent = goToEvent;
  $scope.getEvents = getEvents;
  $scope.eventTypeName = {
    "prestation-participation": "Prestation",
    "sellout-participation": "Challenge Equipe",
    "sellin-challenge-participation": "Challenge Sell-in",
    "sellin-offer-participation": "Offre Sell-in"
  };
  $scope.typesSelected = ["prestation-participation", "sellout-participation", "sellin-challenge-participation", "sellin-offer-participation"];
  loader();
  /**
   * Loader.
   */

  function loader() {
    environmentService.getEnvironment().then(function (env) {
      $scope.env = env;

      if (env.accessPointType !== 2) {
        $scope.ctrl.typesSelectedName = $scope.ctrl.typesSelectedName.concat(eventFactory.ctrl.typesSelectedNameGroupLabo);
      } else {
        $scope.ctrl.typesSelectedName = $scope.ctrl.typesSelectedName.concat(eventFactory.ctrl.typesSelectedNameHealthcenter);
      }

      getEvents($scope.filters);
    });
  }
  /**
   * Get events.
   *
   * @param {Array|string} filters - Filters.
   */


  function getEvents(filters) {
    var types = [];
    var filterstoSend = [];

    if (_.isArray(filters)) {
      filterstoSend = filters.join(",");
    }

    $scope.ctrl.typesSelectedName.forEach(function (type) {
      if (type.checked) {
        types.push(type.value);
      }
    });

    if (types.length === 0) {
      angular.forEach($scope.ctrl.typesSelectedName, function (type) {
        types.push(type.value);
      });
    }

    eventService.getGanttEvents({
      from: $scope.start,
      to: $scope.end,
      type: types.join(","),
      filters: filterstoSend
    }).then(function (data) {
      if (data.events) {
        $scope.timeTableHeight = data.events.length * 4 >= 600 ? data.events.length * 4 + "px" : "600px";
        $scope.datas = data;
        generateCalendar(data.events);
      }
    });
  }
  /**
   * Generate calendar.
   *
   * @param {object} data -
   */


  function generateCalendar(data) {
    if (data.length) {
      AmCharts.makeChart("timetable", {
        type: "gantt",
        zoomOutText: "",
        theme: "light",
        period: "DD",
        dataDateFormat: "YYYY-MM-DD",
        columnWidth: 0.5,
        brightnessStep: 0,
        listeners: [{
          event: "clickGraphItem",
          method: function method(graphDataItem) {
            $scope.eventDetail = graphDataItem.graph.customData;

            if (graphDataItem.graph.customData.custom_event) {
              $scope.eventDetail.rewardTeasers = [$scope.eventDetail.description];
            } else {
              $scope.eventDetail.rewardTeasers = getRewardTeaser(graphDataItem.graph.customData.type, graphDataItem.graph.customData.rewards ? graphDataItem.graph.customData.rewards : graphDataItem.graph.customData.steps);
            }

            $scope.$apply();
          }
        }],
        graph: {
          fillAlphas: 1,
          lineAlpha: 1,
          lineColor: "#fff",
          balloonFunction: function balloonFunction(graphDataItem) {
            return "<b>" + graphDataItem.graph.customData.name + "</b><br />" + "Du " + moment(graphDataItem.graph.customData.start).format("DD MMMM YYYY") + " au " + moment(graphDataItem.graph.customData.end).format("DD MMMM YYYY");
          }
        },
        categoryAxis: {
          axisAlpha: 0,
          gridAlpha: 0.1,
          labelsEnabled: false
        },
        valueAxis: {
          type: "date",
          minimumDate: moment.utc($scope.start).toDate(),
          maximumDate: moment.utc($scope.end).toDate(),
          axisAlpha: 0,
          gridAlpha: 0.1,
          enabled: true,
          labelsEnabled: false
        },
        rotate: true,
        categoryField: "category",
        segmentsField: "segments",
        colorField: "darkColor",
        startDateField: "start",
        valueField: "name",
        endDateField: "end",
        dataProvider: data,
        valueScrollbar: {
          autoGridCount: true
        },
        chartCursor: {
          valueBalloonsEnabled: false,
          enabled: false,
          cursorAlpha: 0,
          oneBalloonOnly: false,
          valueLineAlpha: 0,
          valueLineBalloonEnabled: false,
          valueLineEnabled: false,
          cursorPosition: "mouse",
          zoomable: false,
          valueZoomable: false
        },
        "export": {
          enabled: false
        }
      });
    }
  }
  /**
   * Get reward teaser.
   *
   * @param {string} type -
   * @param {Array} rewards -
   * @returns {Array}
   */


  function getRewardTeaser(type, rewards) {
    var teasers = [];

    switch (type) {
      case "sellout-participation":
      case "sellout":
        teasers = rewards.map(function (reward) {
          if (reward.rewardObjectiveId) {
            return selloutFactory.generateObjectiveTeaser(reward.gain, reward.remuneration, reward.goal, reward.objective, reward.startDate, reward.endDate, reward.objectiveMode);
          }

          return selloutFactory.generateUnitTeaser(reward.gain, reward.remuneration);
        });
        break;

      case "prestation-participation":
      case "prestation":
        teasers = rewards.map(function (reward) {
          return prestationService.generateRewardTeaser(reward);
        });
        break;

      case "sellin-challenge-participation":
      case "sellin-challenge":
        teasers = rewards.map(function (reward) {
          return sellinChallengeService.generateStepTeaser(reward);
        });
        break;

      case "sellin-offer-participation":
      case "sellin-offer":
        return null;

      default:
        return rewards.description;
    }

    return teasers;
  }
  /**
   * Get prescriptor name.
   *
   * @param {object} prescriptor -
   * @returns {string}
   */


  function getPrescriptorName(prescriptor) {
    return prescriptor.organization ? prescriptor.organization.morphable.name : "pas de prescripteur";
  }
  /**
   * Get event detail.
   *
   * @param {object} event -
   * @returns {Promise}
   */


  function getEventDetail(event) {
    switch (event.type) {
      case "prestation-participation":
        return usersPrestationService.read(event.id).then(function (prestation) {
          return {
            start: event.start,
            end: event.end,
            type: event.type,
            name: prestation.userPrestation.prestation.name,
            prescriptor: getPrescriptorName(prestation.userPrestation.prestation.organization),
            file: prestation.userPrestation.prestation.file,
            reward: prestation.userPrestation.prestation.rewards,
            products: prestation.userPrestation.prestation.products
          };
        });

      case "sellout-participation":
        return userSelloutService.read({
          id: event.id
        }).then(function (userSellout) {
          return {
            start: event.start,
            end: event.end,
            type: event.type,
            name: userSellout.sellout.name,
            prescriptor: getPrescriptorName(userSellout.sellout.organization),
            file: userSellout.sellout.file,
            reward: userSellout.sellout.unitRewards,
            products: userSellout.sellout.products
          };
        });

      case "basic-event":
        return {};

      case "sellin-challenge-participation":
      case "sellin-challenge":
        return sellinChallengeService.read({
          id: event.id
        }).then(function (sellin) {
          return {
            start: event.start,
            end: event.end,
            type: event.type,
            name: sellin.name,
            prescriptor: sellin.laboratory.name,
            file: sellin.file,
            reward: sellin.steps,
            products: sellin.products
          };
        });

      case "sellin-offer":
      case "sellin-offer-participation":
        return sellinOfferService.read({
          id: event.id
        }).then(function (sellin) {
          return {
            start: event.start,
            end: event.end,
            type: event.type,
            name: sellin.name,
            prescriptor: sellin.laboratory.name,
            file: sellin.file,
            reward: sellin.steps,
            products: sellin.products
          };
        });

      case "prestation":
        return prestationService.read(event.id).then(function (_ref) {
          var prestation = _ref.prestation;
          return {
            start: event.start,
            end: event.end,
            type: event.type,
            name: prestation.name,
            prescriptor: getPrescriptorName(prestation.organization),
            file: prestation.file,
            products: prestation.products,
            organizations: prestation.participants
          };
        });

      case "sellout":
        return selloutService.read(event.id).then(function (sellout) {
          var selloutObj = sellout.sellout;
          return {
            start: event.start,
            end: event.end,
            type: event.type,
            name: selloutObj.name,
            prescriptor: getPrescriptorName(selloutObj.organization),
            file: selloutObj.file,
            products: selloutObj.products,
            organizations: sellout.participants
          };
        });

      default:
        return eventService.read({
          id: event.id
        }).then(function (event) {
          return {
            start: event.startDate,
            end: event.endDate,
            type: event.type,
            name: event.name,
            prescriptor: getPrescriptorName(event.organization),
            file: event.file,
            products: event.custom.products,
            organizations: event.custom.organizations
          };
        });
    }
  }
  /**
   * Filter.
   *
   * @param {object} event -
   */


  function filter(event) {
    getEventDetail(event).then(function (detail) {
      $rootScope.$broadcast("filterByEvent", detail);
    });
  }
  /**
   * Go the the selected event
   *
   * @param {object} event - Event.
   */


  function goToEvent(event) {
    switch (event.type) {
      case "sellout":
        $state.go("app.sellout.edit", {
          id: event.id
        });
        break;

      case "sellout-participation":
        $state.go("app.UserSelloutHome.detail", {
          id: event.id
        });
        break;

      case "prestation":
        $state.go("app.prestation.edit", {
          id: event.id
        });
        break;

      case "prestation-participation":
        $state.go("app.UserPrestationDetail", {
          id: event.id
        });
        break;

      case "sellin-offer":
        $state.go("app.sellinOffer.detail", {
          id: event.id
        });
        break;

      case "sellin-challenge":
        $state.go("app.sellinChallenge.detail", {
          id: event.id
        });
        break;

      default:
        $state.go("app.calendar.detail", {
          id: event.id
        });
        break;
    }
  }
  /**
   * toggle filter
   *
   * @param {object} item - Item.
   * @param {Array} list - List.
   */


  function toggle(item, list) {
    var idx = list.indexOf(item);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    getEvents($scope.filters);
  }
  /**
   * filter exists
   *
   * @param {object} item - Item.
   * @param {Array} list - List.
   *
   * @returns {boolean}
   */


  function exists(item, list) {
    return list.indexOf(item) > -1;
  }

  $scope.$watchGroup(["start", "end", "filters"], function () {
    getEvents($scope.filters);
  }, true);
}

angular.module("app.directives").directive("timetable", TimetableDirective);