"use strict";
/**
 * User sellout configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

UsersSelloutConfig.$inject = ["$stateProvider"];

function UsersSelloutConfig($stateProvider) {
  $stateProvider.state("app.UserSelloutHome", {
    url: "/users/sellout",
    views: {
      "content@app": {
        templateUrl: "app/main/users-sellout/views/list/users-sellout.list.html",
        controller: "userSelloutListController"
      }
    }
  }).state("app.UserSelloutHome.detail", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/users-sellout/views/detail/healthcenter/users-sellout.detail.healthcenter.html",
        controller: "userSelloutDetailHealthcenterController"
      }
    }
  }).state("app.UserSelloutHome.detail.grouping", {
    url: "/grouping",
    views: {
      "content@app": {
        templateUrl: "app/main/users-sellout/views/detail/grouping/users-sellout.detail.grouping.html",
        controller: "userSelloutDetailGroupingController"
      }
    }
  });
}

angular.module("app.users-sellout", []).config(UsersSelloutConfig);