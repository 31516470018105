"use strict";
/**
 * The posology dose picker.
 *
 * @returns {object}
 */

DosePickerController.$inject = ["$scope"];

function DosePickerDirective() {
  return {
    restrict: "E",
    require: "ngModel",
    controllerAs: "vm",
    scope: {
      model: "=ngModel"
    },
    controller: DosePickerController,
    link: DosePickerLink,
    templateUrl: "app/main/patients/directives/dose-picker/dose-picker.html"
  };
}
/**
 * PosologyDosePickerController.
 *
 * @param {object} $scope - The angular $scope object.
 */


function DosePickerController($scope) {
  var vm = this;
  $scope.add = add;
  $scope.reduce = reduce;
  /**
   * Increment the value of the picker.
   *
   * @returns {void}
   */

  function add() {
    if (!vm.model) {
      vm.model = "1";
    } else if (parseInt(vm.model) >= 1 && !isFraction(vm.model)) {
      vm.model = (parseInt(vm.model) + 1).toString();
    } else if (isFraction(vm.model)) {
      vm.model = (computeFraction(vm.model) + 0.25).toString();
    } else {
      vm.model = (parseInt(vm.model) + 0.25).toString();
    }
  }
  /**
   * Reduce the value of the picker
   *
   * @returns {void}
   */


  function reduce() {
    if (!vm.model) {
      vm.model = "1";
    } else if (parseInt(vm.model) >= 2 && !isFraction(vm.model)) {
      vm.model = (parseInt(vm.model) - 1).toString();
    } else if (isFraction(vm.model)) {
      vm.model = (computeFraction(vm.model) - 0.25).toString();
    } else if (parseInt(vm.model) > 0) {
      vm.model = (parseInt(vm.model) - 0.25).toString();
    }
  }
  /**
   * Test whether a string input is a fraction.
   *
   * @param {string} string - The string to test.
   * @returns {boolean}
   */


  function isFraction(string) {
    if (typeof string === "number") {
      return false;
    }

    var splitedArray = string.split("/");

    if (splitedArray.length !== 2) {
      return false;
    }

    return typeof parseFloat(splitedArray[0]) === "number" && typeof parseFloat(splitedArray[1]) === "number";
  }
  /**
   * Compute a fraction.
   *
   * @param {string} fraction - The fraction to compute.
   *
   * @returns {number}
   */


  function computeFraction(fraction) {
    var splitValue = fraction.split("/");
    return parseFloat(splitValue[0]) / parseFloat(splitValue[1]);
  }
}
/**
 * PosologyDosePickerLink.
 *
 * @param {object} $scope - The $scope object.
 * @param {object} $element - The element.
 * @param {object} attributes - The attributes.
 * @param {object} controller - The ngModel controller.
 */


function DosePickerLink($scope, $element, attributes, controller) {
  var dosageFactory = $element.injector().get("dosageFactory");
  $scope.$watch("vm.model", function (value) {
    controller.$setViewValue(value);
    controller.$render();
  });
  /**
   * This runs when the model gets updated on the scope directly and keeps our view in sync.
   */

  controller.$render = function () {
    if (!controller.$viewValue) {
      controller.$viewValue = "";
    }

    if (isNumber(controller.$viewValue)) {
      var parsedValue = parseFloat(controller.$viewValue.toString());

      if (Object.prototype.hasOwnProperty.call(dosageFactory.dosageMapping, parsedValue)) {
        $scope.vm.model = dosageFactory.dosageMapping[parsedValue];
      } else {
        $scope.vm.model = controller.$viewValue;
      }
    } else {
      $scope.vm.model = controller.$viewValue;
    }
  };
  /**
   * Model to view.
   */


  controller.$formatters.push(function (viewValue) {
    if (isNumber(viewValue)) {
      var parsedValue = parseFloat(viewValue.toString());

      if (Object.prototype.hasOwnProperty.call(dosageFactory.dosageMapping, parsedValue)) {
        return dosageFactory.dosageMapping[parsedValue];
      }
    }

    return viewValue;
  });
  /**
   * View to model.
   */

  controller.$parsers.push(function (viewValue) {
    if (isFraction(viewValue)) {
      return computeFraction(viewValue);
    }

    if (isNumber(viewValue)) {
      return parseFloat(viewValue);
    }

    return NaN;
  });
  /**
   * Determine whether a value is a number.
   *
   * @param {any} value - The value.
   *
   * @returns {boolean}
   */

  function isNumber(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  /**
   * Determine whether a string is a fraction.
   *
   * @param {string} value - The value
   *
   * @returns {boolean}
   */


  function isFraction(value) {
    var splittedValue = value.toString().split("/");

    if (splittedValue.length !== 2) {
      return false;
    }

    var numerator = splittedValue[0];
    var denominator = splittedValue[1];
    return isNumber(numerator) && isNumber(denominator);
  }
  /**
   * Compute a fraction.
   *
   * @param {string} fraction - The fraction to compute.
   *
   * @returns {number}
   */


  function computeFraction(fraction) {
    var splitValue = fraction.split("/");
    return parseFloat(splitValue[0]) / parseFloat(splitValue[1]);
  }
}

angular.module("app.patients.directives").directive("dosePicker", DosePickerDirective);