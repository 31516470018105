"use strict";
/**
 * Healthcenter stock audit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $compile - $compile.
 * @param {object} $state - $state.
 * @param {object} healthCenterAuditGeolocService - Healthcenter audit geoloc service.
 * @param {object} gmap - gmap.
 * @param {object} environmentService - Environment service.
 */

HealthcenterStockAuditController.$inject = ["$scope", "$compile", "$state", "healthCenterAuditGeolocService", "gmap", "environmentService"];

function HealthcenterStockAuditController($scope, $compile, $state, healthCenterAuditGeolocService, gmap, environmentService) {
  var queryPharma = {};
  $scope.configHealthcenterAudit = {
    event: "healthcenterAuditChange",
    search: ["geo.region.id", "geo.departement.number", "geo.uga", "group.id", "idOrgas"],
    tag: "healthcenterAuditChange",
    geoForce: true,
    searchPlaceHolder: "Rechercher une pharmacie..."
  };
  $scope.showAuditHealthcenter = showAuditHealthcenter;
  loader();
  /**
   * Loader.
   *
   * @returns {Promise}
   */

  function loader() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentEnv = response;
      $scope.markers = [];
      initMap();

      if ($scope.currentEnv.accessPointType === 3) {
        queryPharma.type = {};
        queryPharma.type.groups = [$scope.currentEnv.entityId];
      }

      queryPharma.full = true;
      loadPharmas(queryPharma);
    });
  }
  /**
   * Load pharmas.
   *
   * @param {object} query - Query.
   */


  function loadPharmas(query) {
    healthCenterAuditGeolocService.pharmasByGeo(query).then(processMarkers);
  }
  /**
   * Initialize map.
   */


  function initMap() {
    GmapPromise.then(function () {
      var mapOptions = {
        center: new google.maps.LatLng(47.6468754, 2.3242131),
        zoom: 4,
        minZoom: 0,
        gestureHandling: "cooperative",
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: true,
        disableDefaultUI: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.LEFT_TOP
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        fullscreenControl: false,
        styles: gmap
      };
      $scope.map = new google.maps.Map(document.getElementById("map-healthcenter-audit"), mapOptions);
    });
  }
  /**
   * Process markers.
   *
   * @param {Array} pharmacies - Pharmacies.
   *
   * @returns {Array}
   */


  function processMarkers(pharmacies) {
    if ($scope.markers.length > 0) {
      angular.forEach($scope.markers, function (marker) {
        if (marker) {
          marker.setMap(null);
        }
      });
    }

    $scope.markers = [];
    $scope.bounds = new google.maps.LatLngBounds();
    var markers = [];
    var pharmacyOwner = "";
    var pharmacyGrouping = "";
    angular.forEach(pharmacies, function (pharma) {
      var latLng = new google.maps.LatLng(pharma._source.geo.location.lat, pharma._source.geo.location.lon);
      var marker = new google.maps.Marker({
        position: latLng,
        map: $scope.map
      });
      var infowindow = new google.maps.InfoWindow({});
      marker.addListener("click", function () {
        // Get the pharmacy owner
        pharmacyOwner = "";
        angular.forEach(pharma._source.user, function (pharmacyUser) {
          // If rank id of the ower
          if (pharmacyUser.rank_id === 1) {
            pharmacyOwner = pharmacyUser.full_name;
          }
        }); // Get the pharmacy grouping

        pharmacyGrouping = "inconnu / aucun";

        if (pharma._source.group.length > 0 && pharma._source.group[0]) {
          pharmacyGrouping = pharma._source.group[0].name;
        }

        var content = "<div><h2>" + pharma._source.name + " (" + pharma._source.cip + ")</h2>" + "<div class='pharmacyAddress'>" + pharma._source.geo.city.address1 + " " + pharma._source.geo.city.address2 + "<br>" + pharma._source.geo.city.postal_code + " " + pharma._source.geo.city.name + "</div>" + "<div class='pharmacyOwner'>Titulaire : " + pharmacyOwner + "</div>" + "<div class='pharmacyGrouping'>Groupement : " + pharmacyGrouping + "</div>" + "<md-button class='md-accent-bg centered' ng-click='showAuditHealthcenter(" + pharma._source.id + ")'>Auditer cette pharmacie</md-button></div>";
        var compiled = $compile(content)($scope);
        $scope.$apply();
        infowindow.setContent(compiled[0]);
        infowindow.open($scope.map, marker);
      });
      marker.setMap($scope.map);
      $scope.bounds.extend(latLng); // eslint-disable-next-line no-invalid-this

      this.push(marker);
    }, markers);
    $scope.markers = markers;
    $scope.map.fitBounds($scope.bounds);
    return markers;
  }
  /**
   * Show audit healthcenter.
   *
   * @param {number} id - ID.
   */


  function showAuditHealthcenter(id) {
    $state.go("app.healthcenterStockAudit.detail.general", {
      id: id
    });
  }

  $scope.$on("healthcenterAuditChange", function (event, query) {
    var queryPharma = {};
    queryPharma.type = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(query, type)) {
        if (typeof queryPharma.type[type] === "undefined") {
          queryPharma.type[type] = [];
        }

        angular.forEach(query[type], function (obj) {
          if (type === "uga") {
            queryPharma.type[type].push(obj.name);
          } else {
            queryPharma.type[type].push(obj.id);
          }
        });
      }
    };

    for (var type in query) {
      _loop(type);
    }

    queryPharma.full = true;
    loadPharmas(queryPharma);
  });
}

angular.module("app.healthcenterStockAudit").controller("healthcenterStockAuditController", HealthcenterStockAuditController);