"use strict";

CustomerService.$inject = ["hdsApiClient"];

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * The customer service.
 *
 * @param {object} hdsApiClient - The HDS API Client service.
 *
 * @returns {object}
 */
function CustomerService(hdsApiClient) {
  return {
    /**
     * Reads a single customer (with patient info) from HDS architecture.
     *
     * @param {number} customerId - The ID of the customer.
     *
     * @returns {Promise}
     */
    readCustomer: function readCustomer(customerId) {
      return hdsApiClient("GET", "/customers/".concat(customerId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a customer beneficiary.
     *
     * @param {number} customerId - The related customer id.
     * @param {number} beneficiaryId - The beneficiary id.
     *
     * @returns {Promise}
     */
    readCustomerBeneficiary: function readCustomerBeneficiary(customerId, beneficiaryId) {
      return hdsApiClient("GET", "/customers/".concat(customerId, "/beneficiaries/").concat(beneficiaryId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a customer beneficiary.
     *
     * @param {number} customerId - The customer id.
     * @param {number} beneficiaryId - The beneficiary id.
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    updateCustomerBeneficiary: function updateCustomerBeneficiary(customerId, beneficiaryId, params) {
      var patchParams = {
        data: {
          declared_information: params
        }
      };
      return hdsApiClient("PATCH", "/customers/".concat(customerId, "/beneficiaries/").concat(beneficiaryId), patchParams);
    },

    /**
     * Update a customer.
     *
     * @param {number} customerId - The customer id.
     * @param {object} params - The fields to update.
     *
     * @returns {Promise}
     */
    updateCustomer: function updateCustomer(customerId, params) {
      var patchParams = {
        data: {
          declared_information: params
        }
      };
      return hdsApiClient("PATCH", "/customers/".concat(customerId), patchParams);
    },

    /**
     * Get customers list from HDS architecture.
     *
     * @param {object} params - Query params.
     *
     * @returns {Promise}
     */
    getCustomers: function getCustomers(params) {
      return hdsApiClient("GET", "/customers", {
        params: params
      });
    },

    /**
     * Get customer prescriptions.
     *
     * @param {number} customerId - The customer ID.
     * @param {object} params - The params
     *
     * @returns {Promise}
     */
    getCustomerPrescriptions: function getCustomerPrescriptions(customerId) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      return hdsApiClient("GET", "/customers/".concat(customerId, "/prescriptions"), {
        params: params
      });
    },

    /**
     * Get customer orders.
     *
     * @param {number} customerId - The customer id.
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    getCustomerOrders: function getCustomerOrders(customerId) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      return hdsApiClient("GET", "/customers/".concat(customerId, "/orders"), {
        params: params
      });
    },

    /**
     * Read an order.
     *
     * @param {number} orderId - The order id.
     *
     * @returns {Promise}
     */
    readOrder: function readOrder(orderId) {
      return hdsApiClient("GET", "/orders/".concat(orderId));
    },

    /**
     * Update an order.
     *
     * @param {number} orderId - The order id.
     * @param {object} data - Field to update.
     *
     * @returns {Promise}
     */
    updateOrder: function updateOrder(orderId, data) {
      return hdsApiClient("PATCH", "/orders/".concat(orderId), {
        data: data
      });
    },

    /**
     * Get customer's carts history.
     *
     * @param {number} customerId - The customer Id.
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    getCustomerCarts: function getCustomerCarts(customerId) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      return hdsApiClient("GET", "/customers/".concat(customerId, "/carts"), {
        params: params
      });
    },

    /**
     * Searches for customers, filtered by search keywords.
     *
     * @param {string} searchString - Last name, first name or birth year of patient.
     *
     * @returns {Promise}
     */
    getCustomersBySearch: function getCustomersBySearch(searchString) {
      var year = parseInt(searchString);
      var searchKey = isNaN(year) ? "search" : "birth_year";

      var params = _defineProperty({
        limit: 10
      }, searchKey, searchString);

      return hdsApiClient("GET", "/customers", {
        params: params
      });
    },

    /**
     * Request a new certification for a mobile app users.
     *
     * @param {number} customerId - The customer Id.
     *
     * @returns {Promise}
     */
    createCertification: function createCertification(customerId) {
      return hdsApiClient("POST", "/customers/".concat(customerId, "/certification")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a prescription.
     *
     * @param {number} customerId - The customer id of the prescription.
     * @param {string|number} prescriptionId - The prescription id.
     *
     * @returns {Promise}
     */
    readPrescription: function readPrescription(customerId, prescriptionId) {
      return hdsApiClient("GET", "/customers/".concat(customerId, "/prescriptions/").concat(prescriptionId));
    },

    /**
     * Read a given cart.
     *
     * @param {number} customerId - The customer id.
     * @param {string} cartKey - The cart key.
     *
     * @returns {Promise}
     */
    readCart: function readCart(customerId, cartKey) {
      return hdsApiClient("GET", "/customers/".concat(customerId, "/carts/").concat(cartKey));
    },

    /**
     * Read a prescription renewal.
     *
     * @param {number} prescriptionId - The prescription renewal id.
     *
     * @returns {Promise<*>}
     */
    readPrescriptionRenewal: function readPrescriptionRenewal(prescriptionId) {
      return hdsApiClient("GET", "/prescriptions/renewals/".concat(prescriptionId));
    },

    /**
     * Get the prescription renewal pdf file.
     *
     * @param {string} file - The file url.
     *
     * @returns {string}
     */
    getPrescriptionRenewalFile: function getPrescriptionRenewalFile(file) {
      return hdsApiClient("GET", file, {
        responseType: "blob"
      });
    },

    /**
     * Get customer checkouts.
     *
     * @returns {Promise}
     */
    getCustomerCheckouts: function getCustomerCheckouts() {
      return hdsApiClient("GET", "/customers/checkouts");
    },

    /**
     * Search for customer checkouts.
     *
     * @param {string} searchString - The search string.
     *
     * @returns {Promise}
     */
    customerCheckoutsSearch: function customerCheckoutsSearch(searchString) {
      var params = {
        search: searchString
      };
      return hdsApiClient("GET", "/customers/checkouts/search", {
        params: params
      });
    },

    /**
     * Get the number of certified customers.
     *
     * @returns {Promise}
     */
    certifiedCustomersCount: function certifiedCustomersCount() {
      return hdsApiClient("GET", "/customers/certifications/count").then(function (response) {
        return response.data.count;
      });
    },

    /**
     * Get a summary of customers prescription renewal.
     *
     * @returns {Promise}
     */
    getCustomersPrescriptionRenewalSummary: function getCustomersPrescriptionRenewalSummary() {
      return hdsApiClient("GET", "/orders/summary").then(function (response) {
        return response.data;
      });
    },

    /**
     * Extracts patients from customers.
     *
     * @param {object} customers - customers API response.
     *
     * @returns {Array}
     */
    extractPatientsFromCustomers: function extractPatientsFromCustomers(customers) {
      return customers.data.map(function (customer) {
        return {
          id: customer.id,
          name: customer.information.name,
          first_name: customer.information.first_name,
          last_name: customer.information.last_name,
          last_visit: customer.last_visit ? customer.last_visit.date : null,
          onboarded: customer.onboarded,
          current_order: customer.current_order,
          beneficiaries: customer.beneficiaries
        };
      });
    },

    /**
     * Get a list of user who wants to activate an account.
     *
     * @returns {Promise}
     */
    getFutureCustomers: function getFutureCustomers() {
      var params = {
        certification_status: "pending"
      };
      return hdsApiClient("GET", "/healthcenter/users", {
        params: params
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.patients.services").service("customerService", CustomerService);