/* global CalendarsService */
"use strict";

amChart.$inject = ["$q", "colorService"];

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

angular.module("apodis").directive("amChart", amChart);
/**
 * @ngInject
 * @param {object} $q - The angular $q object.
 * @param {object} colorService - The color service
 *
 * @returns {object}
 */

function amChart($q, colorService) {
  return {
    restrict: "E",
    replace: false,
    scope: {
      options: "=",
      deepWatch: "=",
      chart: "=?",
      height: "@",
      width: "@",
      id: "@",
      rolloverslice: "="
    },
    template: "<div>" + "<div class=\"amchartCalendar\"></div>" + "<div class=\"amchartGraph\"></div>" + "</div>",
    link: function link($scope, $el) {
      var id = getIdForUseInAmCharts();
      var graph = angular.element($el[0].querySelectorAll(".amchartGraph")[0]).attr("id", id);
      var graphcalendar = angular.element($el[0].querySelectorAll(".amchartCalendar")[0]).attr("id", "calendar_" + id);
      var chart;
      AmCharts.monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
      AmCharts.shortMonthNames = ["Janv", "Fév", "Mars", "Avr", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"];
      AmCharts.dayNames = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
      AmCharts.shortDayNames = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
      AmCharts.baseHref = true;
      AmCharts.mouseWheelScrollEnabled = true;
      /**
       * Handle the initialization.
       */

      function handleInit() {
        if (chart.legend) {
          chart.legend.addListener("rollOverItem", handleRollOver);
        }
      }
      /**
       * Handle the roll over.
       *
       * @param {object} elem - The element
       */


      function handleRollOver(elem) {
        var wedge = elem.dataItem.wedge.node;
        wedge.parentNode.appendChild(wedge);
      }

      var charts = [];
      /**
       * Handle hide cursor.
       */

      function handleHideCursor() {
        for (var x in charts) {
          if (charts[x].chartCursor.hideCursor) {
            charts[x].chartCursor.forceShow = false;
            charts[x].chartCursor.hideCursor(false);
          }
        }

        chart.categoryAxis.guides = [];
        chart.validateNow();
      }

      var renderChart = function renderChart(amChartOptions) {
        var opt = amChartOptions;
        opt.language = "fr";

        if (opt["export"]) {
          opt["export"].language = "fr";
        } // set height and width


        var height = $scope.height || "500px";
        var width = $scope.width || "100%";
        graph.css({
          height: height,
          width: width
        }); // instantiate new chart object

        if (opt.type === "xy") {
          chart = opt.theme ? new AmCharts.AmXYChart(AmCharts.themes[opt.theme]) : new AmCharts.AmXYChart();
        } else if (opt.type === "pie") {
          chart = opt.theme ? new AmCharts.AmPieChart(AmCharts.themes[opt.theme]) : new AmCharts.AmPieChart();
        } else if (opt.type === "funnel") {
          chart = opt.theme ? new AmCharts.AmFunnelChart(AmCharts.themes[opt.theme]) : new AmCharts.AmFunnelChart();
        } else if (opt.type === "radar") {
          chart = opt.theme ? new AmCharts.AmRadarChart(AmCharts.themes[opt.theme]) : new AmCharts.AmRadarChart();
        } else {
          chart = opt.theme ? new AmCharts.AmSerialChart(AmCharts.themes[opt.theme]) : new AmCharts.AmSerialChart();
        }

        chart.pathToImages = "/assets/vendor/amcharts/images/";

        if ($scope.rolloverslice === 1) {
          chart.addListener("init", handleInit);
          chart.addListener("rollOverSlice", function (elem) {
            handleRollOver(elem);
          });
        } // Override of the original translation


        AmCharts.translations["export"].fr["menu.label.save.image"] = "Télécharger en...";
        /** set some default values that amCharts doesnt provide **/

        $q.when(opt.data).then(function (data) {
          chart.dataProvider = data; // modify default creditsPosition

          chart.creditsPosition = "top-right";
          /**
           * generate grah properties.
           */

          function generateGraphProperties() {
            // Assign Category Axis Properties
            if (opt.categoryAxis) {
              var categoryAxis = chart.categoryAxis;

              if (categoryAxis) {
                categoryAxis.parseDates = true;
                var keys = Object.keys(opt.categoryAxis);

                for (var index = 0; index < keys.length; index++) {
                  if (!angular.isObject(opt.categoryAxis[keys[index]]) || angular.isArray(opt.categoryAxis[keys[index]])) {
                    categoryAxis[keys[index]] = opt.categoryAxis[keys[index]];
                  }
                }

                chart.categoryAxis = categoryAxis;
              }

              if (opt.categoryAxis) {
                if (opt.categoryAxis.listeners) {
                  opt.categoryAxis.listeners.forEach(function (listener) {
                    chart.categoryAxis.addListener(listener.event, listener.method);
                  });
                }
              }
            } // Create value axis

            /* if we need to create any default values, we should assign them here */


            var addValueAxis = function addValueAxis(axis) {
              var valueAxis = new AmCharts.ValueAxis();
              var keys = Object.keys(axis);

              for (var _index = 0; _index < keys.length; _index++) {
                valueAxis[keys[_index]] = axis[keys[_index]];
              }

              chart.addValueAxis(valueAxis);
            };

            if (opt.valueAxes && opt.valueAxes.length > 0) {
              for (var _index2 = 0; _index2 < opt.valueAxes.length; _index2++) {
                addValueAxis(opt.valueAxes[_index2]);
              }
            } // reusable function to create graph


            var addGraph = function addGraph(graphToAdd) {
              var graph = new AmCharts.AmGraph();

              if (graphToAdd && opt.data && opt.data.length > 0) {
                graph.valueField = graphToAdd.valueField || Object.keys(opt.data[0])[1];
              }

              graph.balloonText = "<span style=\"font-size:14px\">[[category]]: <b>[[value]]</b></span>";

              if (graphToAdd) {
                var _keys = Object.keys(graphToAdd);

                for (var _index3 = 0; _index3 < _keys.length; _index3++) {
                  graph[_keys[_index3]] = graphToAdd[_keys[_index3]];
                }
              }

              chart.addGraph(graph);
            };

            if (opt.graphs && opt.graphs.length > 0) {
              for (var _index4 = 0; _index4 < opt.graphs.length; _index4++) {
                addGraph(opt.graphs[_index4]);
              }
            } else {
              addGraph();
            }

            if (opt.type === "gantt" || opt.type === "serial" || opt.type === "xy") {
              var chartCursor = new AmCharts.ChartCursor();

              if (opt.chartCursor) {
                var keysChartCursor = Object.keys(opt.chartCursor);

                for (var _index5 = 0; _index5 < keysChartCursor.length; _index5++) {
                  if (_typeof(opt.chartCursor[keysChartCursor[_index5]]) !== "object") {
                    chartCursor[keysChartCursor[_index5]] = opt.chartCursor[keysChartCursor[_index5]];
                  }
                }

                chart.addChartCursor(chartCursor);
              }
            }

            if (opt.chartScrollbar) {
              var scrollbar = new AmCharts.ChartScrollbar();
              var keysChartScrollbar = Object.keys(opt.chartScrollbar);

              for (var _index6 = 0; _index6 < keysChartScrollbar.length; _index6++) {
                scrollbar[keysChartScrollbar[_index6]] = opt.chartScrollbar[keysChartScrollbar[_index6]];
              }

              chart.chartScrollbar = scrollbar;
            }

            if (opt.balloon) {
              chart.balloon = opt.balloon;
            }
          }
          /**
           * Generate pie properties.
           */


          function generatePieProperties() {
            if (opt.balloon) {
              chart.balloon = opt.balloon;
            }
          }

          if (opt.legend) {
            var legend = new AmCharts.AmLegend();
            var keys = Object.keys(opt.legend);

            for (var index = 0; index < keys.length; index++) {
              legend[keys[index]] = opt.legend[keys[index]];
            }

            chart.legend = legend;
          }

          if (opt.type === "pie") {
            generatePieProperties();
          } else {
            generateGraphProperties();
          }

          if (opt.titles) {
            for (var _index7 = 0; _index7 < opt.titles.length; _index7++) {
              var title = opt.titles[_index7];
              chart.addTitle(title.text, title.size, title.color, title.alpha, title.bold);
            }
          }

          if (opt["export"]) {
            chart.amExport = opt["export"];
            chart["export"] = opt["export"];
          }

          if (opt.responsive) {
            chart.responsive = opt.responsive;
          }

          if (opt.colors) {
            chart.colors = opt.colors;
          }

          if (opt.defs) {
            chart.defs = opt.defs;
          }

          if (opt.calendar && opt.calendar.ids) {
            $scope.options.withCalendar = true;
            graphcalendar.css({
              height: 100,
              width: width
            });
            var initEvent = null;
            chart.addListener("zoomed", function (event) {
              initEvent = event;
            });
            var CalsService = new CalendarsService();
            CalsService.load(opt.calendar.ids, {
              start: moment(opt.calendar.start).format("YYYY-MM-DD"),
              withevents: true,
              end: moment(opt.calendar.end).format("YYYY-MM-DD")
            }).then(function (data) {
              if (data[0] && data[0].events) {
                var calsSegments = [];
                var calsSegmentsCategory = [];
                angular.forEach(data, function (datas) {
                  angular.forEach(datas.events, function (item) {
                    calsSegments.push({
                      start: moment.utc(item.start).format(),
                      end: moment.utc(item.end).format(),
                      color: colorService.getColorFromString(item.start),
                      task: item.name
                    });
                  });
                });

                var MultiligneSegments = function MultiligneSegments(item, deep) {
                  if (!calsSegmentsCategory[deep]) {
                    calsSegmentsCategory[deep] = [];
                  }

                  var insert = true;
                  angular.forEach(calsSegmentsCategory[deep], function (segmentCategory) {
                    if (item.start >= segmentCategory.start && item.end <= segmentCategory.end || item.start <= segmentCategory.start && item.end >= segmentCategory.end || item.start <= segmentCategory.start && item.end >= segmentCategory.start || item.start <= segmentCategory.end && item.end >= segmentCategory.end) {
                      insert = false;
                      var deeper = deep + 1;
                      MultiligneSegments(item, deeper);
                    }
                  });

                  if (insert === true) {
                    calsSegmentsCategory[deep].push(item);
                  }
                };

                angular.forEach(calsSegments, function (item) {
                  MultiligneSegments(item, 0);
                });
                var categorySegments = [];
                angular.forEach(calsSegmentsCategory, function (item, key) {
                  categorySegments.push({
                    category: "Module #" + key,
                    segments: item
                  });
                });
                var calendarChart = AmCharts.makeChart("calendar_" + id, {
                  startField: "start",
                  endField: "end",
                  rotate: 1,
                  durationField: "duration",
                  dataDateFormat: "YYYY-MM-DD",
                  marginRight: 80,
                  marginLeft: 50,
                  autoMargins: false,
                  dataProvider: categorySegments,
                  mouseWheelZoomEnabled: false,
                  chartCursor: {
                    cursorColor: "#55bb76",
                    valueBalloonsEnabled: false,
                    enabled: false,
                    cursorAlpha: 0,
                    oneBalloonOnly: true,
                    valueLineAlpha: 0,
                    valueLineBalloonEnabled: false,
                    valueLineEnabled: false,
                    cursorPosition: "mouse",
                    zoomable: false,
                    valueZoomable: false
                  },
                  theme: "light",
                  valueAxis: {
                    type: "date",
                    minimumDate: moment.utc(opt.calendar.start).toDate(),
                    maximumDate: moment.utc(opt.calendar.end).toDate(),
                    axisAlpha: 0,
                    gridAlpha: 0.1,
                    enabled: false,
                    labelsEnabled: false
                  },
                  categoryAxis: {
                    axisAlpha: 0,
                    gridAlpha: 0.1,
                    labelsEnabled: false
                  },
                  type: "gantt",
                  period: "DD",
                  graph: {
                    fillAlphas: 0.3,
                    balloonFunction: function balloonFunction(data, zeta) {
                      handleHideCursor(); // on créé les guides

                      var guide = new AmCharts.Guide();
                      guide.date = moment.utc(data.values.open).toDate();
                      guide.toDate = moment.utc(data.values.value).toDate();
                      guide.lineAlpha = 1;
                      guide.lineColor = "#c44";
                      guide.balloonColor = "#c33";
                      guide.balloonText = calsSegments[zeta.index].task;
                      guide.position = "top";
                      guide.inside = true;
                      guide.labelRotation = 90;
                      chart.categoryAxis.addGuide(guide);
                      chart.validateNow();
                      return "<b>" + calsSegments[zeta.index].task + "</b> <br> " + moment(data.values.open).format("DD/MM/YYYY HH:MM") + " - " + moment(data.values.value).format("DD/MM/YYYY HH:MM");
                    }
                  },
                  columnWidth: 0.8,
                  chartScrollbar: {
                    enabled: false
                  },
                  valueScrollbar: {
                    enabled: false
                  },
                  brightnessStep: 7,
                  categoryField: "category",
                  segmentsField: "segments",
                  colorField: "color",
                  startDateField: "start",
                  zoomOutText: "",
                  endDateField: "end",
                  balloon: {
                    enabled: true,
                    fixedPosition: false
                  },
                  language: "fr",
                  "export": {
                    enabled: true
                  }
                });
                charts.push(chart);
                charts.push(calendarChart);
                chart.addListener("zoomed", function (event) {
                  calendarChart.valueAxes[0].zoomToValues(event.startDate.getTime(), event.endDate.getTime());
                });
                chart.chartCursor.addListener("onHideCursor", handleHideCursor);
                calendarChart.chartCursor.addListener("moved", function (event) {
                  chart.chartCursor.syncWithCursor(event.chart.chartCursor);
                });
                calendarChart.chartCursor.addListener("onHideCursor", handleHideCursor);
                calendarChart.valueAxes[0].zoomToValues(initEvent.startDate.getTime(), initEvent.endDate.getTime());
              }
            });
          }

          if (opt.listeners) {
            for (var _index8 = 0; _index8 < opt.listeners.length; _index8++) {
              chart.addListener(opt.listeners[_index8].event, opt.listeners[_index8].method);
            }
          }

          var addEventListeners = function addEventListeners(obj, chartObj) {
            for (var _index9 = 0; _index9 < obj.length; _index9++) {
              if (obj[_index9].listeners) {
                var listeners = obj[_index9].listeners;

                for (var listenerIndex = 0; listenerIndex < listeners.length; listenerIndex++) {
                  chartObj[_index9].addListener(listeners[listenerIndex].event, listeners[listenerIndex].method);
                }
              }
            }
          };

          var chartKeys = Object.keys(opt);

          for (var _index10 = 0; _index10 < chartKeys.length; _index10++) {
            if (_typeof(opt[chartKeys[_index10]]) !== "object" && typeof opt[chartKeys[_index10]] !== "function") {
              chart[chartKeys[_index10]] = opt[chartKeys[_index10]];
            } else if (_typeof(opt[chartKeys[_index10]]) === "object") {
              addEventListeners(opt[chartKeys[_index10]], chart[chartKeys[_index10]]);
            }
          } // WRITE


          chart.write(id);
          $scope.chart = chart;
        });
      };
      /**
       * Get id.
       *
       * @returns {string}
       */


      function getIdForUseInAmCharts() {
        var guid = function guid() {
          /**
           * function S4.
           *
           * @returns {string}
           */
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
          }

          return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
        };

        return guid();
      }

      $scope.$watch("options", function (newValue) {
        if (newValue) {
          renderChart(newValue);
        }
      }, $scope.deepWatch);
      $scope.$watch("height", function (newValue) {
        graph.css({
          height: newValue
        });
      });
    }
  };
}