"use strict";
/**
 * Distributor controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $compile - $compile.
 * @param {object} $timeout - $timeout.
 * @param {object} gmap - Google map.
 * @param {object} productAnalysisDistributorService -
 *      Product analysis distributor service.
 * @param {object} productService - Product service.
 * @param {object} wholesalerMarkersLogos - Wholesaler markers logos.
 * @param {object} $stateParams - $stateParams.
 * @param {object} healthcenterAuditHcDetailsService -
 *      Healthcenter audit details service.
 */

DistributorController.$inject = ["$rootScope", "$scope", "$compile", "$timeout", "gmap", "productAnalysisDistributorService", "productService", "wholesalerMarkersLogos", "$stateParams", "healthcenterAuditHcDetailsService"];

function DistributorController($rootScope, $scope, $compile, $timeout, gmap, productAnalysisDistributorService, productService, wholesalerMarkersLogos, $stateParams, healthcenterAuditHcDetailsService) {
  /**
   * Declaration of public methods
   */
  $scope.getDistributor = getDistributor;
  $scope.showMap = showMap;
  $scope.hideMap = hideMap;
  /**
   * Declaration of public objects
   */

  $scope.mapShown = false;
  $scope.loading = false;
  $scope.mapHasBeenShown = false;
  $scope.productSearchConfig = {
    search: ["idProducts", "range.id", "generic.id", "segmentProduct"],
    event: "addProductInDistributor",
    tag: "configProductDistributor",
    searchPlaceHolder: "Rechercher un produit..."
  };
  $scope.queryDistributor = {
    presentations: []
  };
  $scope.callError = false;
  $scope.datas = [];
  $scope.limitOptions = [5, 10, 15];
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.loaderMarkers = [{
    id: 1,
    name: "sagitta-pharma",
    geo: {
      lat: 47.4277983,
      lng: 0.6234003
    },
    path: ""
  }, {
    id: 2,
    name: "brest",
    geo: {
      lat: 48.4084597,
      lng: -4.5346199
    },
    path: ""
  }, {
    id: 3,
    name: "lille",
    geo: {
      lat: 50.6311167,
      lng: 3.0121411
    },
    path: ""
  }, {
    id: 4,
    name: "strasbourg",
    geo: {
      lat: 48.5692059,
      lng: 7.6920546
    },
    path: ""
  }, {
    id: 5,
    name: "bayonne",
    geo: {
      lat: 43.4844564,
      lng: -1.496084
    },
    path: ""
  }, {
    id: 6,
    name: "marseille",
    geo: {
      lat: 43.2804942,
      lng: 5.3104569
    },
    path: ""
  }];

  if ($stateParams.id) {
    healthcenterAuditHcDetailsService.hCDetail($stateParams.id).then(function (pharma) {
      $scope.pharma = pharma;
    });
  }
  /**
   * Process markers.
   */


  function processMarkers() {
    $scope.markers = [];

    if ($scope.markers && $scope.markers.length > 0) {
      $scope.markers.forEach(function (marker) {
        if (marker) {
          marker.setMap(null);
        }
      });
    }

    $scope.datas.result.forEach(function (res) {
      var latLng = new google.maps.LatLng(res.warehouse.geo_lat, res.warehouse.geo_lon);
      var infowindow = new google.maps.InfoWindow({});
      var error = 0;
      var content = "<div><p>" + res.warehouse.name + "</p>";
      angular.forEach(res.products, function (prod) {
        content += "<p>";

        if (typeof prod.dispo === "undefined") {
          error++;
          content += "<i class='material-icons red-500-fg' title='Erreur'>bug_report</i>" + prod.designation;
        }

        if (prod.dispo === true) {
          content += "<i class='material-icons green-500-fg' title='Disponible'>star</i>" + prod.designation;
        }

        if (prod.dispo === false) {
          error++;
          var msg = "<i class='material-icons red-500-fg' title='Non disponible'>star</i>" + prod.designation;

          if (typeof prod.message !== "undefined" && typeof prod.message.additif !== "undefined") {
            msg += "<h3>Code: " + prod.message.code + "</h3>";
            msg += "<h3>" + prod.message.additif + "</h3>";
          }

          content += msg;
        }

        content += "</p>";
      });
      content += "</div>";
      var marker;
      var wholesaler;

      if (error !== 0 || !res.products) {
        wholesaler = wholesalerMarkersLogos.unavailable.filter(function (wholesaler) {
          return wholesaler.id === res.warehouse.wholesaler_id;
        });
        marker = new google.maps.Marker({
          position: latLng,
          map: $scope.map,
          title: res.warehouse.name,
          icon: {
            url: wholesaler[0].logo,
            anchor: new google.maps.Point(32, 64),
            size: new google.maps.Size(64, 64),
            scaledSize: new google.maps.Size(64, 64)
          }
        });
      } else {
        wholesaler = wholesalerMarkersLogos.available.filter(function (wholesaler) {
          return wholesaler.id === res.warehouse.wholesaler_id;
        });
        marker = new google.maps.Marker({
          position: latLng,
          map: $scope.map,
          title: res.warehouse.name,
          icon: {
            url: wholesaler[0].logo,
            anchor: new google.maps.Point(32, 64),
            size: new google.maps.Size(64, 64),
            scaledSize: new google.maps.Size(64, 64)
          }
        });
      }

      marker.addListener("click", function () {
        var compiled = $compile(content)($scope);
        $scope.$apply();
        infowindow.setContent(compiled[0]);
        infowindow.open($scope.map, marker);
      });
      this.push(marker);
      marker.setMap($scope.map);
    }, $scope.markers);
  }
  /**
   * Show map.
   */


  function showMap() {
    $scope.mapShown = true;

    if (!$scope.mapHasBeenShown) {
      $scope.map = null;
      $scope.mapHasBeenShown = true;
      var myLatLng = {
        lat: 47.0833300,
        lng: 2.4000000
      };
      var mapOptions = {
        center: new google.maps.LatLng(myLatLng),
        zoom: 6,
        minZoom: 5,
        draggable: true,
        gestureHandling: "cooperative",
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: true,
        disableDefaultUI: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.LEFT_TOP
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        fullscreenControl: false,
        styles: gmap
      };
      $scope.map = new google.maps.Map(document.getElementById("distributorMap"), mapOptions);
      processMarkers();
    }

    $timeout(function () {
      google.maps.event.trigger($scope.map, "resize");
    }, 0);
  }
  /**
   * Hide map.
   */


  function hideMap() {
    $scope.mapShown = !$scope.mapShown;
    $scope.map = null;
    $scope.mapHasBeenShown = false;
  }
  /**
   * Get distributor.
   *
   * @returns {Promise}
   */


  function getDistributor() {
    $scope.loading = true;
    $scope.mapHasBeenShown = false;
    $scope.datas = [];
    return productAnalysisDistributorService.post($scope.queryDistributor).then(function (response) {
      $scope.datas = response.response;
      $scope.callError = response.error;
      $scope.loading = false;
      $scope.mapShown = false;
      return response;
    });
  }
  /**
   * Get product by type.
   *
   * @param {object} queryProduct -
   *
   * @returns {Promise}
   */


  function getProductByType(queryProduct) {
    return productService.postPresentationByType(queryProduct).then(function (products) {
      $scope.queryDistributor.presentations = [];
      angular.forEach(products, function (product) {
        if (!_.find($scope.queryDistributor.presentations, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.queryDistributor.presentations.push({
            id: product.id,
            name: product.name,
            cip: product.cip,
            quantity: 1
          });
        }
      });
      getDistributor();
      return products;
    });
  }
  /**
   * addProductInDistributor
   * Event on search return product
   *
   * @param {object} event -
   * @param {object} data -
   */


  $rootScope.$on("addProductInDistributor", function (event, data) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    }

    getProductByType(queryProduct);
  });
}

angular.module("app.statistics.products").controller("distributorController", DistributorController);