"use strict";
/**
 * The patient utilities service.
 *
 * @param {object} $mdDialog - The md dialog object.
 */

PatientUtilitiesService.$inject = ["$mdDialog"];

function PatientUtilitiesService($mdDialog) {
  return {
    /**
     * Convert a birth date to a human readable age.
     *
     * @param {string} birthDate - The birth date.
     * @param {string} birthDateFormat - The birth date format.
     *
     * @returns {string} -- The human readable age.
     */
    ageFromBirthDate: function ageFromBirthDate(birthDate) {
      var birthDateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "YYYY-MM-dd";
      var ageInMonth = this.numericAge(birthDate, birthDateFormat);

      if (ageInMonth < 12) {
        return "".concat(ageInMonth, " mois");
      }

      var ageInYear = Math.floor(ageInMonth / 12);
      return "".concat(ageInYear, " an").concat(ageInMonth > 1 ? "s" : "");
    },

    /**
     * Get the age in month for a given birth date.
     *
     * @param {string} birthDate - The birth date.
     * @param {string} birthDateFormat - The birth date format.
     *
     * @returns {number} Age in months.
     */
    numericAge: function numericAge(birthDate) {
      var birthDateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "YYYY-MM-dd";
      var momentBirthDate = moment(birthDate, birthDateFormat);
      return moment().diff(momentBirthDate, "months");
    },

    /**
     * Open the certification model for a given patient id.
     *
     * @param {number} patientId - The patient id.
     */
    openCertificationModal: function openCertificationModal(patientId) {
      $mdDialog.show({
        controller: "patientCertificationModalController",
        locals: {
          patientId: patientId
        },
        templateUrl: "app/main/patients/views/certification/patients.certification.html",
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
  };
}

angular.module("app.patients.services").service("patientUtilitiesService", PatientUtilitiesService);