"use strict";
/**
 * Dashboard pharmacies disruption rates controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} disruptionService - Disruption service.
 */

DashboardPharmaciesDisruptionRatesController.$inject = ["$scope", "disruptionService"];

function DashboardPharmaciesDisruptionRatesController($scope, disruptionService) {
  $scope.processQuery = processQuery;
  processQuery();
  $scope.totalProductDispoRate = 0;
  $scope.totalProductOnDisruptionDay = 0;
  $scope.totalProductRectifiedDispoRate = 0;
  /**
   * Process query.
   */

  function processQuery() {
    disruptionService.getDisruptionAnalysisDashboard().then(function (response) {
      if (!response.aggregations) {
        return;
      }

      response.aggregations.yesterday.product.buckets.forEach(function (product) {
        $scope.totalProductDispoRate += product.disruptionDayCount.value.dispoRate;
        $scope.totalProductOnDisruptionDay += product.disruptionDayCount.value.totalDisruptedDay;
        $scope.totalProductRectifiedDispoRate += product.disruptionDayCount.value.rectifiedDispoRate;
      });
      $scope.disruptionRate = 100 - $scope.totalProductDispoRate / response.aggregations.yesterday.numberOfProducts.value;
      $scope.rectifiedDisruptionRate = 100 - $scope.totalProductRectifiedDispoRate / response.aggregations.yesterday.numberOfProducts.value;
      $scope.disruptionDayCount = 100 - $scope.totalProductOnDisruptionDay / response.aggregations.yesterday.numberOfProducts.value;
    });
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesDisruptionRatesController", DashboardPharmaciesDisruptionRatesController);