"use strict";
/**
 * Patient order details controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} customerService - The customer service.
 * @param {object} notifyUserService - The notify user service.
 */

PatientCartHistoryController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "customerService", "notifyUserService"];

function PatientCartHistoryController($scope, $state, $stateParams, $mdDialog, customerService, notifyUserService) {
  $scope.historyTitle = {
    title: {
      text: "Historique des achats",
      back: {
        state: null,
        tooltip: "Retour au détail patient"
      }
    }
  };
  $scope.query = {
    limit: 10,
    after_key: null,
    page: 1
  };
  $scope.cartHistory = null;
  $scope.loading = true;
  $scope.redirectToCartDetails = redirectToCartDetails;
  $scope.handlePaginationChange = handlePaginationChange;
  var current_page = 1;
  var after_keys = {
    1: null
  };
  loader();
  /**
   * Loader function.
   */

  function loader() {
    var params = {
      limit: $scope.query.limit
    };

    if ($stateParams.beneficiaryId) {
      params.beneficiary_id = $stateParams.beneficiaryId;
    }

    getCustomerCarts({
      limit: $scope.query.limit
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue, veuillez réessayer.");
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Fetch the customer carts and put it in the $scope.
   *
   * @param {object} query - The query.
   *
   * @returns {Promise}
   */


  function getCustomerCarts(query) {
    var params = {
      limit: query.limit,
      after: query.after_key
    };

    if ($stateParams.beneficiaryId) {
      params.beneficiary_id = $stateParams.beneficiaryId;
    }

    return customerService.getCustomerCarts($stateParams.patientId, params).then(function (response) {
      $scope.cartHistory = response.data;
      $scope.query.after_key = response.meta.after_key;
      after_keys[$scope.query.page + 1] = response.meta.after_key;
    });
  }
  /**
   * Redirect to the cart details.
   *
   * @param {object} cart - The cart.
   */


  function redirectToCartDetails(cart) {
    $state.go("app.patients.details.carts.details", {
      cartId: cart.key
    });
  }
  /**
   * On pagination change, fetch the prescriptions.
   *
   * @param {number} page - The current page number.
   *
   * @returns {Promise}
   */


  function handlePaginationChange(page) {
    if (isPreviousPage(page)) {
      $scope.query.after_key = after_keys[page];
      $scope.query.page--;
    } else {
      $scope.query.page++;
    }

    current_page = page;
    return getCustomerCarts($scope.query);
  }
  /**
   * Determine whether the new page is a previous page.
   *
   * @param {number} page - The new page.
   *
   * @returns {boolean}
   */


  function isPreviousPage(page) {
    return page < current_page;
  }
}

angular.module("app.patients").controller("patientsCartHistoryController", PatientCartHistoryController);