"use strict";
/**
 * PLV type service.
 *
 * @param {object} apiClient - API client.
 * @returns {object}
 */

PlvsTypesService.$inject = ["apiClient"];

function PlvsTypesService(apiClient) {
  return {
    /**
     * getTypes
     *
     * @returns {Promise}
     */
    getTypes: function getTypes() {
      return apiClient.get("/plvs/types").then(function (result) {
        return result.data;
      });
    },

    /**
     * getByType
     *
     * @param {number} id - The type id
     * @returns {Promise}
     */
    getByTypeId: function getByTypeId(_ref) {
      var id = _ref.id;
      return apiClient.get("/plvs/types/".concat(id)).then(function (result) {
        return result.data;
      });
    }
  };
}

angular.module("app.generator").service("plvsTypesService", PlvsTypesService);