"use strict";
/**
 * Footer.
 *
 * @returns {object}
 */

FooterDirectiveController.$inject = ["$rootScope", "$scope", "$window", "apiurl", "contractManagementService"];

function footer() {
  return {
    templateUrl: "app/main/directives/footer/footer.html",
    controller: FooterDirectiveController
  };
}
/**
 * Footer directive controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $window - $window.
 * @param {string} apiurl - API URL.
 * @param {object} contractManagementService - Contract management service.
 */


function FooterDirectiveController($rootScope, $scope, $window, apiurl, contractManagementService) {
  $scope.openCgu = openCgu;
  getCguHealthcenter();
  /**
   * Get unvalidated contract.
   *
   * @returns {Promise}
   */

  function getCguHealthcenter() {
    return contractManagementService.getAccepted().then(function (response) {
      if (response.length > 0) {
        var contract = response.filter(function (contract) {
          return contract.slug === "cgu-pharmacie";
        });
        $scope.cguPharmacie = contract[0];
      }

      return response;
    });
  }
  /**
   * Open contract in new tab
   */


  function openCgu() {
    var url = apiurl + "/contracts/versions/" + $scope.cguPharmacie.version.id + "/show";
    $window.open(url);
  }

  $rootScope.$on("contract.validate:refresh", function () {
    getCguHealthcenter();
  });
}

angular.module("apodis").directive("footerDirective", footer);