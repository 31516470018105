"use strict";
/**
 * Stats product home controller.
 *
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 */

StatsProductHomeController.$inject = ["$state", "$scope", "environmentService"];

function StatsProductHomeController($state, $scope, environmentService) {
  var vm = this;
  var currentState = $state.current.name; // TABS STATES

  switch (currentState) {
    case "app.statistics.products.price":
      vm.selected = 0;
      break;

    case "app.statistics.products.agePyramide":
      vm.selected = 1;
      break;

    case "app.statistics.products.observance":
      vm.selected = 2;
      break;

    case "app.statistics.products.pharmacovigilance":
      vm.selected = 3;
      break;

    case "app.statistics.products.distributor":
      vm.selected = 4;
      break;

    default:
      vm.selected = 0;
  }

  environmentService.getEnvironment().then(function (env) {
    vm.currentEnv = env;

    if (vm.currentEnv.entity.wholesaler) {
      $scope.hasCapContract = typeof vm.currentEnv.entity.wholesaler.id !== "undefined";
    }
  });
}

angular.module("app.statistics.products").controller("StatsProductHomeController", StatsProductHomeController);