"use strict";
/**
 * Branding template service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

BrandingTemplateService.$inject = ["apiClient"];

function BrandingTemplateService(apiClient) {
  return {
    /**
     * Get.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.get("/groupings/".concat(params.groupingId, "/branding/templates"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.groupingBrandingTemplate;
      });
    },

    /**
     * Read.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/groupings/".concat(params.groupingId, "/branding/templates/").concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.groupingBrandingTemplate;
      });
    }
  };
}

angular.module("app.grouping").service("brandingTemplateService", BrandingTemplateService);