"use strict";
/**
 * Stocks service.
 *
 * @param {object} apiClient -
 * @returns {object}
 */

StocksService.$inject = ["apiClient"];

function StocksService(apiClient) {
  return {
    /**
     * Get inventory
     *
     * @param {object} query -
     * @returns {Promise}
     */
    getInventory: function getInventory(query) {
      return apiClient.post("/analysis/stock/inventory", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get MicroDisruption
     *
     * @param {object} query -
     * @returns {Promise}
     */
    getMicroDisruption: function getMicroDisruption(query) {
      return apiClient.post("/analysis/stock/micro-disruption/by-access-point", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Export Csv
     *
     * @param {object} data -
     * @returns {Promise}
     */
    exportCsv: function exportCsv(data) {
      var query = {
        transformResponse: function transformResponse(response) {
          return {
            csv: response
          };
        },
        wrappers: {
          response: null
        }
      };
      return apiClient.post("/analysis/stock/micro-disruption/by-access-point/export", {
        data: data
      }, query).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics.stocks").service("stocksService", StocksService);