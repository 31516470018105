"use strict";
/**
 * Run block.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $timeout - $timeout.
 * @param {object} $state - $state.
 * @param {Array} allowedStates - Allowed states.
 * @param {object} authService - Auth service.
 */

runBlock.$inject = ["$rootScope", "$timeout", "$state", "allowedStates", "authService"];

function runBlock($rootScope, $timeout, $state, allowedStates, authService) {
  $rootScope.currState = $state;

  if (!authService.isAuthenticated()) {
    authService.destroyCredentials();
  } // Activate loading indicator


  var stateChangeStartEvent = $rootScope.$on("$stateChangeStart", function () {
    $rootScope.loadingProgress = true;
  }); // De-activate loading indicator

  var stateChangeSuccessEvent = $rootScope.$on("$stateChangeSuccess", function () {
    $timeout(function () {
      $rootScope.loadingProgress = false;
    });
  }); // Store state in the root scope for easy access

  $rootScope.state = $state; // Cleanup

  $rootScope.$on("$destroy", function () {
    stateChangeStartEvent();
    stateChangeSuccessEvent();
  });
  $rootScope.$on("$stateChangeStart", function (event, next) {
    if (!authService.isAuthenticated() && allowedStates.indexOf(next.name) < 0) {
      event.preventDefault();
      $state.go("app.pages_auth_login");
    }
  });
}

angular.module("apodis").run(runBlock);