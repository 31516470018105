"use strict";
/**
 * Search bar simple.
 *
 * @returns {object}
 */

SearchBarSimpleController.$inject = ["$scope", "$rootScope", "autocompleteFiltersService", "environmentService", "StatisticsQueryService", "$state"];

function SearchBarSimple() {
  return {
    restrict: "E",
    scope: {
      config: "@config",
      select: "@select",
      lengthSelected: "@lengthSelected"
    },
    templateUrl: "app/main/directives/search-bar/simple/searchBar.html",
    transclude: true,
    controller: SearchBarSimpleController,
    controllerAs: "vm"
  };
}
/**
 * Search bar simple controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} autocompleteFiltersService - Autocomplete filters service.
 * @param {object} environmentService - Environment service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} $state - $state.
 */


function SearchBarSimpleController($scope, $rootScope, autocompleteFiltersService, environmentService, StatisticsQueryService, $state) {
  var vm = this;
  vm.config = JSON.parse($scope.config);

  if (typeof $scope.select !== "undefined") {
    vm.select = JSON.parse($scope.select);
  } else {
    vm.select = true;
  }

  vm.service = vm.config.service;
  vm.search = vm.config.search;
  vm.selectedFilter = [];

  if (vm.search) {
    vm.selectedFilter = vm.search;
  }

  vm.limitsByType = vm.config.limits;
  vm.definitionsDisplay = autocompleteFiltersService.definitionsDisplay;
  vm.resultsFromAllStats = [];
  vm.autocomplete = autocompleteFiltersService.autocomplete;
  vm.selectedObjChange = autocompleteFiltersService.selectedObjChange;
  vm.selectedFilterResult = {};
  vm.filtersToSend = [];
  vm.resultsLength = 30;
  vm.usrquery = StatisticsQueryService.query;
  vm.showBadge = vm.config.showBadge;
  vm.requestType = [];
  vm.filtersArray = [];
  vm.searchTypesCount = 0; // If no custom submitLabel set, then set to default

  vm.submitLabel = vm.config.submitLabel || "Ajouter à la sélection";

  if ($state.current.name === "app.healthcenterAudit") {
    vm.submitLabelPharmacy = "Auditer la pharmacie";
  } // If no custom placeholder set, then set to default


  if (!vm.config.searchPlaceHolder) {
    vm.config.searchPlaceHolder = "Rechercher...";
  }

  if (typeof vm.config.search === "undefined" || vm.config.search.length === 0) {
    vm.config.search = [];
    vm.filters = [{
      all: true
    }];
    vm.filters.all = true;
  }

  if (typeof vm.config.tag !== "undefined") {
    vm.tag = vm.config.tag;
    vm.autocomplete.model.input[vm.tag] = "";
  } else {
    vm.tag = "tag";
  }

  var currentEnvironment = environmentService.getEnvironment();
  currentEnvironment.then(function (env) {
    vm.currentEnv = env;
    angular.forEach(vm.usrquery.should, function (sh) {
      if (sh.belongs === "geo" && sh.accessPointRight && sh.accessPointRight.indexOf(vm.currentEnv.accessPointType) > -1) {
        vm.hasGeoOption = true;
      }

      if (sh.belongs === "quali" && sh.accessPointRight && sh.accessPointRight.indexOf(vm.currentEnv.accessPointType) > -1) {
        vm.hasQualiOption = true;
      }
    });
  });
  /**
   * Force a parameter to be sent with search to apply another filter.
   *
   * @returns {object} The must defined in config or null
   */

  function getMust() {
    if (vm.config.must) {
      return vm.config.must;
    }

    return null;
  }
  /**
   * Get options.
   *
   * @returns {object}
   */


  function getOptions() {
    var geoForce = false;
    angular.forEach(vm.config.search, function (item) {
      if (item.indexOf("geo") > -1) {
        geoForce = true;
      }
    });
    var requestType = generateRequestType();
    var must = getMust();
    var length = {
      length: vm.resultsLength
    };
    return {
      requestType: requestType,
      must: must,
      length: length,
      tag: vm.tag,
      geoForce: geoForce,
      isFilteredOnSanteSecure: vm.config.isFilteredOnSanteSecure
    };
  }

  vm.searchTextChange = function () {
    if (vm.autocomplete.model.input[vm.tag].length > 2) {
      vm.loading = true;
      vm.resultsFromAllStats = {};
      vm.searchCacheQuery = autocompleteFiltersService.getAll(getOptions()).then(function (datas) {
        vm.resultsFromAllStats = datas;
        vm.searchTypesCount = Object.keys(vm.resultsFromAllStats).length;
        vm.loading = false;
      });
    } else {
      vm.resultsFromAllStats = {};
      vm.searchTypesCount = Object.keys(vm.resultsFromAllStats).length;
    }
  }; // Allow to set checkbox checked by default


  vm.selectedDefault = function (name) {
    return vm.config.search.indexOf(name) !== -1;
  };

  vm.splitResult = function (array) {
    if (array.length >= 10) {
      return 1;
    }

    return 0;
  };

  vm.flexResult = function (array) {
    if (array.length >= 10) {
      return 50;
    }

    return 100;
  };

  vm.findMore = function (nameType) {
    var nmeType = nameType;

    if (nameType === "pharmas") {
      nmeType = "idOrgas";
    }

    if (nameType === "product") {
      nmeType = "idProducts";
    }

    vm.selectedFilter = [nmeType];
    vm.searchTextChange();
  }; // Retourne true si un élément dans le selected est selectionné


  vm.countFilterSelected = function () {
    var index = vm.selectedFilter.length;

    if (vm.selectedFilter.indexOf("all")) {
      index--;
    }

    return index === 1;
  };
  /**
   * Generate request type.
   *
   * @returns {object}
   */


  function generateRequestType() {
    // If "all" is the last checked, initialize filter with only "all"
    if (vm.selectedFilter.length - 1 === vm.selectedFilter.indexOf("all")) {
      vm.selectedFilter = ["all"];
    }

    if (vm.selectedFilter.length >= 2 && vm.selectedFilter.indexOf("all") !== -1) {
      vm.selectedFilter.splice(vm.selectedFilter.indexOf("all"), 1);
    }

    return vm.selectedFilter;
  } // Add or remove a filter element from a checkbox


  vm.selectedCheckBoxToggle = function (res, nameType) {
    if (typeof vm.selectedFilterResult[nameType] === "undefined") {
      vm.selectedFilterResult[nameType] = [];
    }

    if (vm.limitsByType && vm.limitsByType[nameType] > 0) {
      if (typeof vm.selectedFilterResult[nameType][res.id] !== "undefined") {
        vm.selectedFilterResult[nameType] = [];
      } else {
        if (vm.limitsByType.combined === true) {
          angular.forEach(vm.selectedFilterResult, function (selectedFilter, key) {
            vm.selectedFilterResult[key] = [];
          });
        }

        vm.selectedFilterResult[nameType] = [];
        vm.selectedFilterResult[nameType][res.id] = res;
      }
    } else if (typeof vm.selectedFilterResult[nameType][res.id] === "undefined") {
      vm.selectedFilterResult[nameType][res.id] = res;
    } else {
      delete vm.selectedFilterResult[nameType][res.id];

      if (Object.keys(vm.selectedFilterResult[nameType]).length === 0) {
        delete vm.selectedFilterResult[nameType];
      }
    }

    vm.isPharmacyAlone = false;

    if (vm.selectedFilterResult[nameType] && Object.keys(vm.selectedFilterResult[nameType]).length === 1) {
      vm.isPharmacyAlone = true;
    }

    if (vm.config.autoLaunchEvent) {
      vm.results = [];
      angular.forEach(vm.selectedFilterResult, function (dataType, nameType) {
        // Ajoute les nouveaux filtres
        angular.forEach(dataType, function (res) {
          if (typeof vm.results[nameType] === "undefined") {
            vm.results[nameType] = [];
          }

          vm.results[nameType].push(res);
          vm.usrquery.must[nameType].selected.push(res);
        });
      });
      $rootScope.$broadcast(vm.config.event, vm.results);
    }
  }; // Allow to enable or disable a checkbox by taking into account the selected elements


  vm.selectedCheckBoxExists = function (res, nameType) {
    return !(typeof vm.selectedFilterResult[nameType] === "undefined" || typeof vm.selectedFilterResult[nameType][res.id] === "undefined");
  };

  vm.selectedFilterResultCount = function () {
    return Object.keys(vm.selectedFilterResult).length;
  }; // Transfert filter data


  vm.applyFilter = function () {
    vm.resultsFromAllStats = [];
    angular.forEach(vm.selectedFilterResult, function (dataType, nameType) {
      for (var index in dataType) {
        if (Object.prototype.hasOwnProperty.call(dataType, index)) {
          if (typeof vm.resultsFromAllStats[nameType] === "undefined") {
            vm.resultsFromAllStats[nameType] = [];
          }

          vm.resultsFromAllStats[nameType].push(dataType[index]);
        }
      }
    });
    $rootScope.$broadcast(vm.config.event, vm.resultsFromAllStats);
    vm.selectedFilterResult = {};
    vm.autocomplete.model.input[vm.tag] = "";
  };
  /*
       ** Filters Labels Management
       */


  vm.removeFilter = function (obj, nameType) {
    vm.filtersArray.splice(vm.filtersArray.indexOf(obj), 1);
    var typeFilter = ["must"];
    angular.forEach(typeFilter, function (typeFilterName) {
      var indexUsrQuery = vm.usrquery[typeFilterName][nameType].selected.indexOf(obj);

      if (indexUsrQuery !== -1) {
        vm.usrquery[typeFilterName][nameType].selected.splice(indexUsrQuery, 1);
      }
    });

    if (typeof vm.selectedFilterResult[nameType] !== "undefined" && typeof vm.selectedFilterResult[nameType][obj.id] !== "undefined") {
      // Remove the object from the current research - searchbar
      vm.selectedFilterResult[nameType].splice([obj.id], 1);
    }

    $rootScope.$broadcast(vm.config.event, vm.usrquery);
  };

  vm.removeAllFilter = function (what) {
    vm.autocomplete.model.input[vm.tag] = "";
    angular.forEach(vm.usrquery[what], function (item, index) {
      angular.forEach(item.selected, function (selected) {
        if (typeof vm.selectedFilterResult[index] !== "undefined" && typeof vm.selectedFilterResult[index][selected.id] !== "undefined") {
          // Remove the object from the current research - searchbar
          vm.filtersArray.splice(vm.filtersArray.indexOf(selected), 1);
          delete vm.selectedFilterResult[index][selected.id];
        }
      });

      if (item.selected) {
        item.selected = [];
      }
    }); // vm.filtersArray = [];

    $rootScope.$broadcast(vm.config.event, vm.usrquery);
  };

  vm.sizeOf = function (obj) {
    return Object.keys(obj).length;
  };

  $scope.$on("autocompleteFiltersService:modelChange", function (event, data) {
    vm.autocomplete = data;
  });
  $scope.$watch("config", function (newValue) {
    vm.config = JSON.parse(newValue);
  });
}

angular.module("app.directives").directive("searchBarSimple", SearchBarSimple);