"use strict";
/**
 * Geoloc pharmas service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

GeolocPharmasService.$inject = ["apiClient"];

function GeolocPharmasService(apiClient) {
  return {
    /**
     * Healthcenter detail.
     *
     * @param {object} healthcenterId - Unique healthcenter ID.
     * @returns {Promise}
     */
    hCDetail: function hCDetail(healthcenterId) {
      return apiClient.get("/healthcenters/".concat(healthcenterId), {
        includes: ["address", "opening", "grouping"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.directives").service("geolocPharmasService", GeolocPharmasService);