"use strict";
/**
 * Contacts service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

ContactsService.$inject = ["apiClient"];

function ContactsService(apiClient) {
  return {
    /**
     * Get the contacts.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/laboratories/contacts?include=organization").then(function (response) {
        return response.data;
      });
    },

    /**
     * Create contacts.
     *
     * @param {object} query - Query.
     *
     * @returns {Promise}
     */
    create: function create(query) {
      return apiClient.post("/laboratories/contacts?include=uga,organization,healthcenters", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a contact.
     *
     * @param {object} query - Query.
     *
     * @returns {Promise}
     */
    read: function read(query) {
      return apiClient.get("/laboratories/contacts/".concat(query.id, "?include=uga,organization,healthcentersLight")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Retrieve contacts by healthcenter.
     *
     * @param {object} query - Query.
     *
     * @returns {Promise}
     */
    getByHealthcenter: function getByHealthcenter(query) {
      return apiClient.get("/laboratories/contacts/healthcenter/".concat(query.organizationId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a contact.
     *
     * @param {object} query - Query.
     *
     * @returns {Promise}
     */
    "delete": function _delete(query) {
      return apiClient["delete"]("/laboratories/contacts/".concat(query.id, "/delete")).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.contacts").service("contactsService", ContactsService);