"use strict";
/**
 * Sellout configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

SelloutConfig.$inject = ["$stateProvider"];

function SelloutConfig($stateProvider) {
  $stateProvider.state("app.sellout", {
    url: "/challenges-sell-out",
    views: {
      "content@app": {
        templateUrl: "app/main/sellout/list/list.html",
        controller: "ChallengeSelloutListController as vm"
      }
    }
  }).state("app.sellout.new", {
    url: "/nouveau",
    views: {
      "content@app": {
        templateUrl: "app/main/sellout/detail/detail.html",
        controller: "ChallengeSelloutDetailController as vm"
      }
    },
    params: {
      healthcenterId: null,
      healthcenterName: null
    }
  }).state("app.sellout.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/sellout/detail/detail.html",
        controller: "ChallengeSelloutDetailController as vm"
      }
    }
  }).state("app.sellout.report", {
    url: "/report/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/sellout/report/report.html",
        controller: "ChallengeSelloutReportController as vm"
      }
    }
  });
}

angular.module("app.sellout", []).config(SelloutConfig);