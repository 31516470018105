"use strict";
/**
 * Smoothed degressive margin controller.
 *
 * @param {object} $state - $state.
 */

SmoothedDegressiveMarginController.$inject = ["$state"];

function SmoothedDegressiveMarginController($state) {
  var vm = this;
  var currentState = $state.current.name; // TABS STATES

  switch (currentState) {
    case "app.statistics.smoothed-degressive-margin":
      vm.selectedIndex = 0;
      break;

    case "app.statistics.smoothed-degressive-margin.future":
      vm.selectedIndex = 1;
      break;

    default:
      vm.selectedIndex = 0;
  }
}

angular.module("app.statistics.smoothed-degressive-margin").controller("SmoothedDegressiveMarginController", SmoothedDegressiveMarginController);