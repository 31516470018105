"use strict";
/**
 * App list footer directive.
 *
 * @returns {object}
 */

function AppListFooter() {
  return {
    restrict: "E",
    template: "<div class='app-list-footer' ng-transclude></div>",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appListFooter", AppListFooter);