"use strict";
/**
 * Healthcenters configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

HealthcentersConfig.$inject = ["$stateProvider"];

function HealthcentersConfig($stateProvider) {
  $stateProvider.state("app.healthcenters_detailPharma", {
    url: "/healthcenters/ma-pharma/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/healthcenters/detail/tabs/general.html",
        controller: "DetailGeneralTabController as vm"
      }
    },
    bodyClass: "healthcenters-detail"
  }).state("app.healthcenters.sellinOffers", {
    url: "/healthcenters/offres-sellin",
    views: {
      "content@app": {
        templateUrl: "app/main/healthcenters/sellin-offers/views/list/healthcenterSellinOffers.html",
        controller: "healthcenterSellinOffersController"
      }
    }
  });
}

angular.module("app.healthcenters", []).config(HealthcentersConfig);