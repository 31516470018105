"use strict";
/**
 * Notification service.
 *
 * @param {object} $filter - $filter.
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

NotificationService.$inject = ["$filter", "apiClient"];

function NotificationService($filter, apiClient) {
  var $this = {
    load: function load() {
      return apiClient.get("/notification", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        $this.all = response.data.notifications;
        return $this.all;
      });
    },
    all: [],
    get: function get() {
      return $this.all;
    },
    find: function find(regex) {
      return $filter("regex")($this.all, "slug", regex);
    },
    deleteall: function deleteall() {
      apiClient["delete"]("/notification", {
        wrappers: {
          response: null
        }
      }).then(function () {
        $this.load();
      });
    },
    read: function read(item, callback) {
      return apiClient.get("/notification/".concat(item.id), {
        wrappers: {
          response: null
        }
      }).then(function () {
        $this.load();

        if (typeof callback === "function") {
          callback(item);
        }
      });
    }
  };
  return $this;
}

angular.module("app.services").factory("NotificationService", NotificationService).filter("regex", function () {
  return function (input, field, regex) {
    var patt = new RegExp("^" + regex + "$");
    return input.filter(function (item) {
      return patt.test(item[field]);
    });
  };
});