"use strict";
/**
 * PLV preset types service.
 *
 * @param {object} apiClient -
 * @returns {object}
 */

PlvsPresetTypesService.$inject = ["apiClient"];

function PlvsPresetTypesService(apiClient) {
  return {
    /**
     * load
     *
     * @returns {Promise}
     */
    load: function load() {
      return apiClient.get("/plvs/presets").then(function (result) {
        return result.data;
      });
    },

    /**
     * read
     *
     * @param {number} id - The preset type id
     * @returns {Promise}
     */
    read: function read(_ref) {
      var id = _ref.id;
      return apiClient.get("/plvs/presets/".concat(id)).then(function (result) {
        return result.data;
      });
    }
  };
}

angular.module("app.generator").service("plvsPresetTypesService", PlvsPresetTypesService);