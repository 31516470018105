"use strict";
/**
 * Laboratory filter configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

LaboratoryFilterConfig.$inject = ["$stateProvider"];

function LaboratoryFilterConfig($stateProvider) {
  $stateProvider.state("app.laboratory-filter", {
    url: "/laboratory-filter",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory-filter/views/main/laboratory-filter.html",
        controller: "laboratoryFilterController"
      }
    }
  });
}

angular.module("app.laboratory-filter", []).config(LaboratoryFilterConfig);