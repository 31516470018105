"use strict";
/**
 * Medication summary controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} medicationSummaryService - Medication summary service.
 * @param {object} searchPatientService - Search patient service.
 * @param {object} $state - $state.
 */

MedicationSummaryController.$inject = ["$scope", "medicationSummaryService", "searchPatientService", "$state"];

function MedicationSummaryController($scope, medicationSummaryService, searchPatientService, $state) {
  $scope.getAppointments = getAppointments();
  $scope.getPatientsBySteps = getPatientsBySteps();
  $scope.searchPatient = searchPatient;
  $scope.selectPatient = selectPatient;
  $scope.title = {
    title: {
      text: "Bilan médication",
      back: {
        state: "app.patients",
        tooltip: "Retour au dashboard patients"
      }
    },
    button: {
      icon: "icon-people",
      text: "Voir tous les patients éligibles",
      tooltip: "Voir tous les patients éligibles",
      state: "app.patients.medicationSummary.patientsList"
    }
  };
  $scope.statistiquesTitle = {
    icon: "icon-chart-pie",
    text: "Statistiques",
    type: "2"
  };
  $scope.query = {
    order: "-name",
    limit: 5,
    limit_options: [5, 10, 15],
    page: 1
  };
  /**
   * Get appointments.
   *
   * @returns {Promise}
   */

  function getAppointments() {
    return medicationSummaryService.getAppointments().then(function (appointments) {
      $scope.appointments = appointments;
      return appointments;
    });
  }
  /**
   * Get patients statistices by medication summary steps.
   *
   * @returns {Promise}
   */


  function getPatientsBySteps() {
    return medicationSummaryService.getPatientsBySteps().then(function (patientsBySteps) {
      $scope.patientsBySteps = patientsBySteps;
      return patientsBySteps;
    });
  }
  /**
   * Search a patient.
   *
   * @returns {Promise}
   */


  function searchPatient() {
    return searchPatientService.getSearchPatients().then(function (patients) {
      $scope.patients = patients;
      return patients;
    });
  }
  /**
   * Click on a search patient.
   *
   * @param {object} patient - Patient.
   */


  function selectPatient(patient) {
    angular.element(".md-scroll-mask").remove();
    $state.go("app.patients.details", {
      patientId: patient.id
    });
  }
}

angular.module("app.patients").controller("medicationSummaryController", MedicationSummaryController);