"use strict";
/**
 * Laboratory filter controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdToast - $mdToast.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} laboratoryFilterService - Laboratory filter service.
 * @param {object} environmentService - Environment service.
 */

LaboratoryFilterController.$inject = ["$scope", "$mdToast", "$mdDialog", "laboratoryFilterService", "environmentService"];

function LaboratoryFilterController($scope, $mdToast, $mdDialog, laboratoryFilterService, environmentService) {
  $scope.addLaboratory = addLaboratory;
  $scope.removeLaboratory = removeLaboratory;
  $scope.searchConfig = {
    event: "processResults",
    search: ["laboratory.id"],
    searchPlaceHolder: "Rechercher des laboratoires…",
    submitLabel: "Autoriser"
  };
  $scope.$on("processResults", function (event, results) {
    var laboratories = results.laboratory;

    if (laboratories) {
      laboratories.forEach(function (laboratory) {
        addLaboratory(laboratory);
      });
    }
  });
  /**
   * Loader.
   */

  (function loader() {
    environmentService.getEnvironment().then(function (environment) {
      $scope.environment = environment;
      getLaboratories();
    });
  })();
  /**
   * Get laboratories.
   *
   * @returns {Promise} Promise object.
   */


  function getLaboratories() {
    return laboratoryFilterService.get({
      organizationId: $scope.environment.organizationId
    }).then(function (response) {
      $scope.laboratories = response.laboratories;
    });
  }
  /**
   * Add a laboratory
   *
   * @param {object} laboratory - The laboratory to add.
   */


  function addLaboratory(laboratory) {
    laboratoryFilterService.post({
      organizationId: $scope.environment.organizationId,
      laboratoryId: laboratory.id
    }).then(function () {
      getLaboratories();
    });
  }
  /**
   * Remove a laboratory.
   *
   * @param {object} laboratory - The laboratory to remove.
   */


  function removeLaboratory(laboratory) {
    $mdDialog.show($mdDialog.confirm().title("Souhaitez-vous retirer le laboratoire « " + laboratory.name + " » du filtrage ?").ariaLabel("Retirer le laboratoire du filtrage").ok("Oui").cancel("Non")).then(function () {
      return laboratoryFilterService["delete"]({
        organizationId: $scope.environment.organizationId,
        laboratoryId: laboratory.id
      }).then(function () {
        return getLaboratories();
      }).then(function () {
        $mdToast.show($mdToast.simple().textContent("Le laboratoire « " + laboratory.name + " » est retiré."));
      });
    });
  }
}

angular.module("app.laboratory-filter").controller("laboratoryFilterController", LaboratoryFilterController);