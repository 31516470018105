"use strict";
/**
 * Dashboard lqborqtories sales evolution controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} StocksQueryService - Stocks query service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} homeSlideService - Home slide service.
 * @param {object} ChartSettingsService - Chart settings service.
 * @param {object} analysisService - Analysis service.
 */

dashboardLaboratoriesSalesEvolutionController.$inject = ["$scope", "environmentService", "StocksQueryService", "StatisticsQueryService", "homeSlideService", "ChartSettingsService", "analysisService"];

function dashboardLaboratoriesSalesEvolutionController($scope, environmentService, StocksQueryService, StatisticsQueryService, homeSlideService, ChartSettingsService, analysisService) {
  // eslint-disable-next-line no-invalid-this
  var _this = this;

  var groupListLaboGroup = [{
    value: 0,
    title: "Pharmacies"
  }];
  var groupListPharma = [{
    value: 1,
    title: "Laboratoires",
    type: "lab",
    filterId: "id"
  }, {
    value: 6,
    title: "Gammes",
    type: "range",
    filterId: "id"
  }, {
    value: 7,
    title: "Catégories"
  }, {
    value: 8,
    title: "Segments"
  }, {
    value: 9,
    title: "Produits"
  }];
  var psd3 = psd3 || {};
  var eur = d3.locale({
    decimal: ".",
    thousands: " ",
    grouping: [3],
    currency: ["", "€"],
    dateTime: "%a %b %e %X %Y",
    date: "%m/%d/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  });
  $scope.thisYear = moment().format("YYYY");
  $scope.list = false;
  $scope.LabID = 0;
  $scope.loading = true;
  $scope.loadingChart = true;
  $scope.mShareloading = true;
  $scope.dataChartSerial = [];
  $scope.sliderDealing = homeSlideService.sliderDealing;
  $scope.initiated = false;
  $scope.stepPosition = 0;
  $scope.stepMax = 0;
  $scope.stepNumberLine = 10;
  $scope.stepNumberList = [10, 20, 50, 100];
  $scope.liveSales = [];
  $scope.liveBreaks = [];
  $scope.colors = ["#fab231", "#faeb4c", "#b7d787", "#89b74e", "#85caa0", "#9bd8df", "#218b9a"];
  $scope.rawValues = StatisticsQueryService.values;
  $scope.usrStockReal = StatisticsQueryService.query;
  $scope.dateLabel = moment($scope.usrStockReal.date.from).format("DD-MMM-YYYY") + " Au " + moment($scope.usrStockReal.date.to).format("DD-MMM-YYYY");
  $scope.dataset = [];
  $scope.form = {
    isBlurred: false,
    nbEl: {
      min: 2,
      max: 25,
      model: 5
    },
    by: "quantity",
    values: [{
      lbl: "Laboratoires",
      val: "product.laboratory",
      checked: true,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Produits",
      val: "product.presentation",
      checked: true,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Segments",
      val: "product.segment.value",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 1",
      val: "product.category.level0",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 2",
      val: "product.category.level1",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 3",
      val: "product.category.level2",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 4",
      val: "product.category.level3",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 5",
      val: "product.category.level4",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }]
  };
  $scope.amChartHistoStockOptions = {
    data: [],
    type: "serial",
    theme: "light",
    startDuration: 1,
    autoMarginOffset: 5,
    categoryField: "date",
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    legend: {
      enabled: true
    },
    chartScrollbar: {
      enabled: true
    },
    categoryAxis: {
      gridPosition: "start",
      parseDates: false
    },
    graphs: [{
      type: "column",
      title: "Unités",
      valueField: "qty",
      balloonText: "Unités :[[value]]",
      fillAlphas: 0.8,
      lineAlpha: 0.2
    }],
    "export": {
      enabled: true
    }
  };
  $scope.tooltip = {
    config: {
      adjustX: "-65px",
      adjustY: "-45px",
      color: "#CCC",
      backgroundColor: "rgba(68, 68, 68, 0.85)"
    },
    display: function display(data) {
      var innerCircle = 0;
      angular.forEach($scope.elasticdata.data, function (data) {
        innerCircle += data.value;
      });
      angular.forEach($scope.elasticdata.data, function (data) {
        data.total = innerCircle;
        angular.forEach(data.drilldown, function (_data) {
          _data.total = data.value;
        });
      });
      var tooltip = document.getElementById(_this.tooltipId);
      tooltip.style.position = "fixed";
      tooltip.style.marginTop = this.config.adjustY;
      tooltip.style.color = this.config.color;
      tooltip.style.backgroundColor = this.config.backgroundColor;
      tooltip.innerHTML = this.format(data.data); // hide

      tooltip.classList.remove("psd3Hidden");
      d3.event.target.addEventListener("mousemove", function (event) {
        var divIdContent = angular.element(document.querySelector("#content"))[0];
        tooltip.style.left = event.clientX + "px";
        tooltip.style.top = event.clientY + divIdContent.scrollTop + "px";
      });
    },
    format: function format(data) {
      var unit; // display unit type

      if ($scope.form.by === "price_ht") {
        unit = eur.numberFormat("$,.2f")(data.value);
      } else {
        unit = d3.format(",.3")(data.value) + " unités";
      }

      return "<table>" + "<thead><tr md-row><th>Champ</th><th>Valeur</th></tr></thead>" + "<tbody><tr md-row><td md-cell>" + data.label + "</td><td md-cell>" + unit + "</td></tr></tbody>" + "</table>";
    }
  };
  psd3.Graph = Graph;
  /**
   * Loader.
   */

  function loader() {
    initTab();
    initSalesAnalysisChart();
  }
  /**
   * Initialize tab.
   */


  function initTab() {
    if ($scope.initiated === false) {
      environmentService.getEnvironment().then(function (environment) {
        $scope.env = environment;

        if (environment.accessPointType === 2) {
          $scope.groupByList = groupListPharma;
          $scope.groupByChart = $scope.groupByList[0];
        } else {
          $scope.groupByList = groupListLaboGroup;
          $scope.groupByChart = $scope.groupByList[0];
        }

        $scope.usrStockquery = StocksQueryService.query;
        $scope.stockQuery = StocksQueryService.buildQuery($scope.usrStockquery);
        $scope.LabID = environment.entityId;

        if (environment.entity) {
          launchStatisticsQueries();
        }

        $scope.initiated = true;
      });
    }
  }
  /**
   * Initialize sales analysis chart.
   */


  function initSalesAnalysisChart() {
    $scope.salesLoading = true;
    $scope.deferredSalesData = [];
    $scope.serialSalesOptions = getSalesAnalysisChartOptions();
    $scope.serialSalesOptions.legend.valueWidth = 100;
    $scope.serialSalesOptions.legend.valueAlign = "left";
  }
  /**
   * Merge graph objects.
   *
   * @param {Array} graphs -
   *
   * @returns {Array}
   */


  function mergeGraphObjects(graphs) {
    var ngraphs = [];
    angular.forEach(graphs, function (data) {
      var obj = angular.extend(data, ChartSettingsService.getLineGraphObj()); // eslint-disable-next-line no-invalid-this

      this.push(obj);
    }, ngraphs);
    return ngraphs;
  }
  /**
   * Get sales analysis chart options.
   *
   * @returns {object}
   */


  function getSalesAnalysisChartOptions() {
    var salesGraphs = {
      type: "serial",
      data: [],
      startDuration: 0,
      marginTop: 40,
      marginRight: 60,
      legend: {
        useGraphSettings: true,
        marginTop: 20
      },
      valueAxes: [{
        id: "ca",
        axisAlpha: 1,
        position: "left",
        gridAlpha: 0.1,
        axisColor: "#FF6600",
        labelFunction: function labelFunction(value) {
          return d3.format(".2s")(value) + "€";
        }
      }, {
        id: "qty",
        axisAlpha: 1,
        position: "left",
        gridAlpha: 0,
        axisColor: "#673AB7",
        offset: 60,
        labelFunction: function labelFunction(value) {
          return d3.format(".2s")(value);
        }
      }],
      graphs: [{
        id: "g1",
        valueField: "sales",
        valueAxis: "ca",
        title: "Ventes Totales",
        bullet: "round",
        bulletBorderColor: "#FFFFFF",
        bulletBorderAlpha: 1,
        bulletBorderThickness: 2,
        animationPlayed: false,
        balloon: {
          drop: false,
          adjustBorderColor: true,
          color: "#212121"
        },
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + "€";
        }
      }, {
        id: "g3",
        valueField: "qty",
        valueAxis: "qty",
        title: "Quantité",
        bullet: "round",
        bulletBorderColor: "#FFFFFF",
        bulletBorderAlpha: 1,
        bulletBorderThickness: 2,
        animationPlayed: false,
        lineColor: "#673AB7",
        balloon: {
          drop: false,
          adjustBorderColor: true,
          color: "#212121"
        },
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " unités";
        }
      }],
      chartCursor: {
        cursorColor: "#888888",
        cursorAlpha: 0.5,
        categoryBalloonDateFormat: "DD MMM YYYY",
        fullWidth: false
      },
      categoryField: "date",
      categoryAxis: {
        gridColor: "#F2F2F2",
        gridAlpha: 0.3,
        dateFormats: [{
          period: "fff",
          format: "JJ:NN:SS"
        }, {
          period: "ss",
          format: "JJ:NN:SS"
        }, {
          period: "mm",
          format: "JJ:NN"
        }, {
          period: "hh",
          format: "JJ:NN"
        }, {
          period: "DD",
          format: "DD MMM"
        }, {
          period: "WW",
          format: "DD MMM"
        }, {
          period: "MM",
          format: "MMM YYYY"
        }, {
          period: "YYYY",
          format: "MMM YYYY"
        }],
        parseDates: true,
        minorGridAlpha: 0.1,
        minorGridEnabled: true
      },
      "export": {
        enabled: true,
        menu: [{
          "class": "export-main",
          menu: [{
            format: "PNG",
            title: "Export chart to PNG"
          }, {
            format: "JPG",
            title: "Export chart to JPG"
          }]
        }]
      }
    };
    salesGraphs = angular.extend(salesGraphs, ChartSettingsService.getLineObj());
    salesGraphs.graphs = mergeGraphObjects(salesGraphs.graphs);

    salesGraphs.legend.valueFunction = function (graphDataItem) {
      if (graphDataItem && graphDataItem.graph) {
        var currentUnit = graphDataItem.graph.id === "g3" ? " unités" : "€";
        var value = Math.round(graphDataItem.values.value).formatMoney(0, 3, " ", ", ") + currentUnit;
        return value;
      }

      return "";
    };

    return salesGraphs;
  }
  /**
   * Process laboratories market share query.
   *
   * @param {object} query - Query.
   */


  function processLabsMarketShareQuery(query) {
    analysisService.searchLaboratories(query).then(function (response) {
      $scope.mShareloading = false;
      $scope.LabsData = response;
      processMarketShareDataForAmCharts();
    });
  }
  /**
   * Process market share data for AmCharts.
   */


  function processMarketShareDataForAmCharts() {
    var ret = [];
    var objectList = [];
    $scope.caCount = $scope.LabsData.aggregations.amount_excl_tax.value.formatMoney(0, 3, " ", ", ");
    $scope.qtyCount = $scope.LabsData.aggregations.quantity.value.formatMoney(0, 3, " ");
    angular.forEach($scope.LabsData.aggregations.laboratories.buckets, function (data) {
      var obj = {};
      obj.label = data.key;

      if ($scope.currentRange === "CA") {
        obj.value = data.amount_excl_tax.value.formatMoney(0, 3, " ", ", ") + " €";
        obj.rawvalue = Math.trunc(data.amount_excl_tax.value);
      } else {
        obj.value = data.quantity.value.formatMoney(0, 3, " ") + " unités";
        obj.rawvalue = data.quantity.value;
      }

      var tmp = angular.copy(obj);
      tmp.products = data.products;
      objectList.push(tmp); // eslint-disable-next-line no-invalid-this

      this.push(obj);
    }, ret);
    ret = ChartSettingsService.sortDataByAttribute(ret, "rawvalue");
  }
  /**
   * Launch statistics queries.
   */


  function launchStatisticsQueries() {
    // On ne peut lancer les requetes de statistiques
    // seulement lorsque l'objet access est disponible et valide.
    StatisticsQueryService.queryRebase(); // on recupere une query vierge pour ne pas interferer sur les queries de statistics

    $scope.usrquery = StatisticsQueryService.query;
    $scope.usrquery.date = {
      from: moment().subtract(12, "months"),
      to: moment()
    };
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query; // MarketShare

      processLabsMarketShareQuery($scope.query);
    });
  }
  /**
   * Graph.
   *
   * @param {object} config -
   */


  function Graph(config) {
    this.config = config;
    this.defaults = {
      width: angular.element(document.getElementById("chartStockContainer")).width(),
      height: angular.element(document.getElementById("chartStockContainer")).height(),
      value: "value",
      inner: "inner",
      label: function label(item) {
        return item.data.value;
      },
      tooltip: function tooltip(item) {
        if (_this.config.value !== undefined) {
          return item[_this.config.value];
        }

        return item.value;
      },
      transition: "linear",
      transitionDuration: 1000,
      donutRadius: 0,
      gradient: false,
      colors: d3.scale.category20(),
      labelColor: "black",
      drilldownTransition: "linear",
      drilldownTransitionDuration: 0,
      stroke: "white",
      strokeWidth: 2,
      highlightColor: "orange"
    };

    for (var property in this.defaults) {
      if (Object.prototype.hasOwnProperty.call(this.defaults, property)) {
        if (!Object.prototype.hasOwnProperty.call(config, property)) {
          config[property] = this.defaults[property];
        }
      }
    }
  }

  loader();
}

angular.module("app.dashboard").controller("dashboardLaboratoriesSalesEvolutionController", dashboardLaboratoriesSalesEvolutionController);