"use strict";
/**
 * The patient event service.
 *
 * @param {object} hdsApiClient - The HDS API Client service.
 *
 * @returns {object}
 */

PatientEventService.$inject = ["hdsApiClient"];

function PatientEventService(hdsApiClient) {
  return {
    /**
     * Get events list.
     *
     * @returns {Promise}
     */
    getEvents: function getEvents() {
      return hdsApiClient("GET", "/events");
    },

    /**
     * Send a push notification for all concerned patient.
     *
     * @param {object} event - The event
     * @returns {Promise}
     */
    sendPushNotification: function sendPushNotification(event) {
      return hdsApiClient("POST", "/events/".concat(event.id, "/notifications"));
    }
  };
}

angular.module("app.patients.services").service("patientEventService", PatientEventService);