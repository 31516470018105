"use strict";
/**
 * Sellin challenge detail controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} sellinChallengeService - Sellin challenge service.
 * @param {object} environmentService - Environment service.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} sellinChallengeConfig - Sellin challenge configuration.
 */

SellinChallengeDetailController.$inject = ["$scope", "$stateParams", "sellinChallengeService", "environmentService", "$mdDialog", "sellinChallengeConfig"];

function SellinChallengeDetailController($scope, $stateParams, sellinChallengeService, environmentService, $mdDialog, sellinChallengeConfig) {
  var ENTITY_TYPE = {
    LABORATORY: 1,
    HEALTHCENTER: 2,
    GROUPING: 3
  };
  var SELLIN_TYPE = {
    RECOVERY: 1,
    MARKET_OPENING: 3
  };
  $scope.sellinChallengeId = $stateParams.id;
  $scope.sellinChallengeStepTargets = [];
  $scope.lastStep = null;
  $scope.sales = [];
  $scope.groupedSales = {};
  $scope.salesGroupedBy = "healthcenterCip";
  $scope.currentYear = new Date().getFullYear();
  $scope.previousYear = $scope.currentYear - 1;
  $scope.salesQuantityCurrentYear = 0;
  $scope.salesQuantityPreviousYear = 0;
  $scope.participatingHealthcenterCips = [];
  $scope.healthcentersTargets = [];
  $scope.amountWithoutSellinDiscount = 0;
  $scope.amountWithSellinDiscount = 0;
  $scope.groupSalesBy = groupSalesBy;
  $scope.showProducts = showProducts;
  $scope.showTargets = showTargets;
  $scope.reachedSteps = [];
  $scope.getProgressPercent = getProgressPercent;
  $scope.ENTITY_TYPE = ENTITY_TYPE;
  $scope.isProgressDisplayed = isProgressDisplayed;
  loader();
  /**
   * Load page data.
   */

  function loader() {
    $scope.loading = true;
    loadCurrentEnvironment().then(getSellinChallenge).then(function () {
      return Promise.all([getSales(), getSellinEvolution(), getSellinHealthcenterAcceptedTargets()]);
    }).then(function () {
      $scope.loading = false;
    });
  }
  /**
   * Load current environment.
   *
   * @returns {Promise}
   */


  function loadCurrentEnvironment() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.currentAccess = access;
    });
  }
  /**
   * Get sellin challenge.
   *
   * @returns {Promise}
   */


  function getSellinChallenge() {
    return sellinChallengeService.read({
      id: $scope.sellinChallengeId
    }).then(function (sellin) {
      $scope.sellin = sellin;
      getSellinsStepTargets().then(function (sellinStepTargets) {
        $scope.sellinStepTargets = sellinStepTargets;
        sellin.steps = sellin.steps.map(function (step) {
          step.teaser = sellinChallengeService.generateStepTeaser({
            step_value: step.stepValue,
            sellin_challenge_step_type_id: step.type.id,
            sellin_challenge_step_discount_type_id: step.discountType.id,
            discount_value: step.discountValue
          });

          if (sellin.steps.length) {
            $scope.lastStep = sellin.steps[sellin.steps.length - 1];
          }

          var currentStepTargets = $scope.sellinStepTargets.filter(function (stepTarget) {
            return stepTarget.stepId === step.id;
          });

          if (currentStepTargets.length > 0) {
            step.isReached = true;
            step.reachedDate = currentStepTargets[0].dateStepReached;
            step.recoveries = currentStepTargets[0].recoveries;
          } else {
            step.isReached = false;
          }

          return step;
        });
        $scope.sellin = sellin;
        $scope.reachedSteps = sellin.steps.filter(function (step) {
          return step.isReached;
        });
      });
    });
  }
  /**
   * Get sales.
   *
   * @returns {Promise}
   */


  function getSales() {
    return sellinChallengeService.getSalesBySellinChallengeId($scope.sellin, ["product", "healthcenter"]).then(function (sales) {
      $scope.sales = sales;
      groupSalesBy($scope.salesGroupedBy);
      $scope.participatingHealthcenterCips = [];
      $scope.progressQuantity = 0;
      $scope.progressAmount = 0;
      $scope.amountWithoutSellinDiscount = 0;
      $scope.amountWithSellinDiscount = 0;

      for (var index = 0; index < sales.length; index++) {
        var sale = sales[index];
        $scope.progressQuantity += sale.quantity;
        $scope.progressAmount += sale.priceAfterDiscount;

        if ($scope.participatingHealthcenterCips.indexOf(sale.healthcenterCip) === -1) {
          $scope.participatingHealthcenterCips.push(sale.healthcenterCip);
        }

        $scope.amountWithoutSellinDiscount += calculateSalePriceWithoutSellin(sale);
        $scope.amountWithSellinDiscount += sale.priceAfterDiscount;
      }

      for (var _index = 0; _index < $scope.sellinStepTargets.length; _index++) {
        $scope.amountWithoutSellinDiscount += parseFloat($scope.sellinStepTargets[_index].couponAmount);
      } // Progress for recovery sellin


      if (($scope.sellin.type.id === SELLIN_TYPE.RECOVERY || $scope.sellin.type.id === SELLIN_TYPE.MARKET_OPENING) && !$scope.sellin.isPerPharmacy && $scope.currentAccess.accessPointType === ENTITY_TYPE.GROUPING) {
        setStepsProgressRate();
      }
    });
  }
  /**
   * Calculate sale price without sellin discount.
   *
   * @param {object} sale - Sale to proceed.
   *
   * @returns {number}
   */


  function calculateSalePriceWithoutSellin(sale) {
    var additionalDiscount = 0;
    var fixedDiscount = 0;

    for (var index = 0; index < $scope.reachedSteps.length; index++) {
      var reachedStep = $scope.reachedSteps[index];

      if (new Date(sale.orderDate) > new Date(reachedStep.reachedDate)) {
        if (!reachedStep.discountType.isAbsolute) {
          additionalDiscount = reachedStep.discountValue;
        } else {
          fixedDiscount = Math.max(0, reachedStep.discountValue - sale.baseDiscount);
        }

        sale.recoveriesLabel = "Remise directe";
      } else if ($scope.sellin.type.id === SELLIN_TYPE.RECOVERY && reachedStep.recoveries.length) {
        if (!sale.recoveriesLabel) {
          sale.recoveriesLabel = "Rattrapage";
        }

        sale.recoveriesLabel += " " + reachedStep.recoveries.map(function (recovery) {
          return recovery.recovery_invoice_number;
        }).join(" ");
      }
    }

    var discountToRemove = fixedDiscount + additionalDiscount;
    return 100 * sale.priceAfterDiscount / (100 - discountToRemove);
  }
  /**
   * Get sellin healthcenter accepted targets.
   */


  function getSellinHealthcenterAcceptedTargets() {
    sellinChallengeService.getSellinHealthcenterAcceptedTargets({
      id: $scope.sellinChallengeId
    }).then(function (targets) {
      $scope.healthcentersTargets = targets;
    });
  }
  /**
   * Set steps progress rate.
   */


  function setStepsProgressRate() {
    $scope.sellin.steps = $scope.sellin.steps.map(function (step) {
      step.progressRate = getStepProgressRate(step);
      return step;
    });
  }
  /**
   * Get step progress rate.
   *
   * @param {object} step - Sellin step.
   *
   * @returns {object}
   */


  function getStepProgressRate(step) {
    return sellinChallengeService.getStepProgressRate(step, {
      quantity: $scope.progressQuantity,
      amount: $scope.progressAmount
    });
  }
  /**
   * Get sellin evolution from previous to current year.
   */


  function getSellinEvolution() {
    sellinChallengeService.getSalesEvolutionBySellinChallengeId($scope.sellin).then(function (sales) {
      $scope.salesQuantityCurrentYear = sales.now.reduce(function (total, sale) {
        return total + sale.quantity;
      }, 0);
      $scope.salesQuantityPreviousYear = 0;

      if (sales.lastYear) {
        $scope.salesQuantityPreviousYear = sales.lastYear.reduce(function (total, sale) {
          return total + sale.quantity;
        }, 0);
      }
    });
  }
  /**
   * Get sellin step targets.
   *
   * @returns {Promise}
   */


  function getSellinsStepTargets() {
    return sellinChallengeService.getSellinStepTargets($scope.sellin);
  }
  /**
   * Group sales by given fiels.
   *
   * @param {string} field - Aggregation field.
   */


  function groupSalesBy(field) {
    if ($scope.groupedSales[field] === undefined) {
      $scope.groupedSales[field] = {};

      for (var index = 0; index < $scope.sales.length; index++) {
        var sale = $scope.sales[index];
        var fieldValue = sale[field];

        if ($scope.groupedSales[field][fieldValue] === undefined) {
          $scope.groupedSales[field][fieldValue] = [];
        }

        $scope.groupedSales[field][fieldValue].push(sale);
      }
    }

    $scope.salesGroupedBy = field;
  }
  /**
   * Show sellin products.
   */


  function showProducts() {
    var products = $scope.sellin.products.reduce(function (products, sellinProduct) {
      return products.concat([sellinProduct.product]);
    }, []);
    $mdDialog.show({
      controller: ["$scope", function controller($scope) {
        $scope.products = products;

        $scope.cancel = function () {
          return $mdDialog.cancel();
        };
      }],
      templateUrl: sellinChallengeConfig.baseDir + "includes/modal/detail/products.html"
    });
  }
  /**
   * Show sellin targets.
   */


  function showTargets() {
    var targets = $scope.sellin.targets.filter(function (sellinTarget) {
      if ($scope.currentAccess.accessPointType === ENTITY_TYPE.GROUPING && (sellinTarget.target.entity_type_id === ENTITY_TYPE.GROUPING && sellinTarget.target.morphable_id !== $scope.currentAccess.entityId || sellinTarget.target.entity_type_id === ENTITY_TYPE.HEALTHCENTER && (sellinTarget.target.morphable.grouping === undefined || sellinTarget.target.morphable.grouping.id !== $scope.currentAccess.entityId))) {
        return false;
      }

      return true;
    }).reduce(function (targets, sellinTarget) {
      sellinTarget.target.visible = true;
      return targets.concat(sellinTarget.target);
    }, []);
    $mdDialog.show({
      controller: ["$scope", "participatingHealthcenterCips", function controller($scope, participatingHealthcenterCips) {
        $scope.targets = targets;
        $scope.participatingHealthcenterCips = participatingHealthcenterCips;

        $scope.cancel = function () {
          return $mdDialog.cancel();
        };
      }],
      templateUrl: sellinChallengeConfig.baseDir + "includes/modal/detail/targets.html",
      locals: {
        participatingHealthcenterCips: $scope.participatingHealthcenterCips
      }
    });
  }
  /**
   * Get progress percent.
   *
   * @returns {number}
   */


  function getProgressPercent() {
    var progress = 0;

    if ($scope.lastStep.type.id === 1) {
      progress = Math.min($scope.progressQuantity / $scope.lastStep.stepValue * 100, 100);
    }

    if ($scope.lastStep.type.id === 2) {
      progress = Math.min($scope.progressAmount / $scope.lastStep.stepValue * 100, 100);
    }

    return progress;
  }
  /**
   * Return true if progress must be display, else false.
   *
   * @returns {boolean}
   */


  function isProgressDisplayed() {
    return $scope.sellin.type.id === SELLIN_TYPE.RECOVERY && !$scope.sellin.isPerPharmacy;
  }
}

angular.module("app.sellinChallenge").controller("sellinChallengeDetailController", SellinChallengeDetailController);