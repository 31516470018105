"use strict";
/**
 * Patient order index controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} orderFactory - The order factory.
 * @param {object} patientOrderService - The patient order service.
 * @param {object} customerService - The customer service.
 */

patientOrderIndexController.$inject = ["$scope", "$state", "$stateParams", "orderFactory", "patientOrderService", "customerService"];

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function patientOrderIndexController($scope, $state, $stateParams, orderFactory, patientOrderService, customerService) {
  $scope.ordersTitle = {
    title: {
      text: "Liste des commandes",
      back: {
        state: "app.patients"
      }
    }
  };
  $scope.loading = true;
  $scope.index = true;
  $scope.typeMapping = orderFactory.typeMapping;
  $scope.query = {
    limit: 10,
    limitOptions: [5, 10, 15],
    page: 1,
    total: null
  };
  $scope.redirectToOrderDetails = redirectToOrderDetails;
  $scope.statusFilteredClass = statusFilteredClass;
  $scope.handleSelectedFilter = handleSelectedFilter;
  $scope.searchPatientOrder = searchPatientOrder;
  $scope.handlePatientSelected = handlePatientSelected;
  $scope.statusFilter = $stateParams.orderStatus;
  loader();
  /**
   * Load data.
   */

  function loader() {
    var params = {
      page: $scope.query.page,
      limit: $scope.query.limit
    };

    if ($scope.statusFilter) {
      params.status = $scope.statusFilter;
    }

    patientOrderService.indexOrders(params).then(function (response) {
      $scope.orders = response.data;
      $scope.query.total = response.meta.total;
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Search patient order.
   *
   * @param {string} searchText - The search text query.
   *
   * @returns {Promise}
   */


  function searchPatientOrder(searchText) {
    if (!searchText) {
      return Promise.resolve(null);
    }

    return customerService.getCustomersBySearch(searchText).then(function (result) {
      var patients = result.data;
      return patients.map(function (patient) {
        patient.beneficiaries = patient.beneficiaries.map(function (beneficiary) {
          return _objectSpread(_objectSpread({}, beneficiary), {}, {
            customer_id: patient.id
          });
        });
        return [patient].concat(_toConsumableArray(patient.beneficiaries));
      }).flat();
    });
  }
  /**
   * Redirect to the selected patient profile.
   *
   * @param {object} selectedPatient - The selected patient.
   */


  function handlePatientSelected(selectedPatient) {
    if (selectedPatient.customer_id) {
      $state.go("app.patients.details.beneficiaries.details.orders", {
        patientId: selectedPatient.customer_id,
        beneficiaryId: selectedPatient.id
      });
    } else {
      $state.go("app.patients.details.orders", {
        patientId: selectedPatient.id
      });
    }
  }
  /**
   * Redirect to order details.
   *
   * @param {object} order - The order.
   */


  function redirectToOrderDetails(order) {
    var stateParams = {
      orderId: order.id,
      patientId: order.customer.id
    };
    $state.go("app.patients.details.orders.details", stateParams);
  }
  /**
   * Get the css class depending the selected filter.
   *
   * @param {string|null} status - The selected status.
   *
   * @returns {string}
   */


  function statusFilteredClass(status) {
    return $scope.statusFilter === status ? "md-accent" : "";
  }
  /**
   * On filter selected, update the $scope.
   *
   * @param {string|null} filter - The selected filter.
   *
   * @returns {void}
   */


  function handleSelectedFilter(filter) {
    $scope.statusFilter = filter === $scope.statusFilter ? null : filter;
    loader();
  }
}

angular.module("app.patients").controller("PatientsOrderIndexController", patientOrderIndexController);