"use strict";
/**
 * Patients search service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

PatientsSearchService.$inject = ["apiClient"];

function PatientsSearchService(apiClient) {
  return {
    /**
     * Search.
     *
     * @param {object} data - The request data.
     *
     * @returns {Promise}
     */
    search: function search(data) {
      return apiClient.post("/patients/search", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.patients-search").service("patientsSearchService", PatientsSearchService);