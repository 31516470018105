"use strict";
/**
 * Average shopping cart controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} averageShoppingCartService - Average shopping cart service.
 * @param {object} environmentService - Environment service.
 * @param {object} healthcenterAuditHcDetailsService - Healthcenter audit details service.
 */

AverageShoppingCartController.$inject = ["$scope", "$stateParams", "StatisticsQueryService", "averageShoppingCartService", "environmentService", "healthcenterAuditHcDetailsService"];

function AverageShoppingCartController($scope, $stateParams, StatisticsQueryService, averageShoppingCartService, environmentService, healthcenterAuditHcDetailsService) {
  $scope.config = {
    event: "averageShoppingCartFilterChange",
    search: []
  };
  $scope.query = StatisticsQueryService.query;
  $scope.query.date = {
    from: moment().month(0).date(1),
    to: moment()
  };
  var startDate = moment($scope.query.date.from).format("DD-MM-YYYY");
  var endDate = moment($scope.query.date.to).format("DD-MM-YYYY");
  $scope.dateLabel = startDate + " Au " + endDate;
  $scope.rangeSelected = rangeSelected;
  loader();
  /**
   * The loading function
   */

  function loader() {
    var inCall = false;
    environmentService.getEnvironment().then(function (env) {
      $scope.env = env;
      var entity = {
        name: env.entity.name,
        id: env.entityId
      };

      if ($stateParams.id) {
        inCall = true;
        healthcenterAuditHcDetailsService.hCDetail($stateParams.id).then(function (pharma) {
          var pharmaInfos = {
            id: pharma.healthcenter.id,
            name: pharma.healthcenter.name,
            type: "pharmas",
            cip: pharma.healthcenter.cip
          };
          $scope.query.must.pharmas.selected = [pharmaInfos];
          getAverageShoppingCartAnalysis($scope.query);
          getAverageShoppingCartAnalysisWithPrescription($scope.query);
        });
      } else if (env.entityType === 1) {
        entity.type = "laboratory";
        $scope.query.must.laboratory.selected.push(entity);
      } else if (env.entityType === 3) {
        entity.type = "groups";
        $scope.query.must.groups.selected.push(entity);
      } else if (env.entityType === 2) {
        entity.type = "pharmas";
        $scope.query.must.pharmas.selected.push(entity);
      }

      if (!inCall) {
        getAverageShoppingCartAnalysis($scope.query);
        getAverageShoppingCartAnalysisWithPrescription($scope.query);
      }
    });
  }
  /**
   * create the data for operator array
   *
   * @param {object} averageShoppingCartData - Average shopping cart data.
   */


  function makeDataByOperator(averageShoppingCartData) {
    $scope.operatorData = [];
    averageShoppingCartData.current.aggregations.byOperator.buckets.forEach(function (operator) {
      var comparisonData = _.find(averageShoppingCartData.comparison.aggregations.byOperator.buckets, function (comparisonOperatorData) {
        return comparisonOperatorData.key_id === operator.key_id;
      });

      $scope.operatorData.push({
        current: operator,
        comparison: comparisonData
      });
    });
  }
  /**
   * getAverageShoppingCartAnalysis
   *
   * @param {object} query - Query.
   */


  function getAverageShoppingCartAnalysis(query) {
    StatisticsQueryService.buildquery(query, query.date).then(function (builtQuery) {
      return averageShoppingCartService.getByOperator(builtQuery).then(function (averageShoppingCart) {
        $scope.averageShoppingCart = averageShoppingCart.current.aggregations;
        $scope.averageShoppingCartComparison = averageShoppingCart.comparison.aggregations;
        makeDataByOperator(averageShoppingCart);
      });
    });
  }
  /**
   * getAverageShoppingCartAnalysisWithPrescription
   *
   * @param {object} query - Query.
   */


  function getAverageShoppingCartAnalysisWithPrescription(query) {
    StatisticsQueryService.buildquery(query, query.date).then(averageShoppingCartService.getByOperatorWithPrescription).then(function (averageShoppingCart) {
      $scope.averageShoppingCartWithPrescription = averageShoppingCart.current.aggregations;
      $scope.averageShoppingCartComparisonWithPrescription = averageShoppingCart.comparison.aggregations;
    });
  }
  /**
   * on change date
   *
   * @param {object} range - Range.
   */


  function rangeSelected(range) {
    var startDate = moment(range.startDate).format("DD-MM-YYYY");
    var endDate = moment(range.endDate).format("DD-MM-YYYY");
    $scope.dateLabel = startDate + " Au " + endDate;
    $scope.query.date.from = moment(range.startDate, "DD-MM-YYYY");
    $scope.query.date.to = moment(range.endDate, "DD-MM-YYYY");
    getAverageShoppingCartAnalysis($scope.query);
    getAverageShoppingCartAnalysisWithPrescription($scope.query);
  }
  /**
   * Event from search bar
   */


  $scope.$on("averageShoppingCartFilterChange", function () {
    getAverageShoppingCartAnalysis($scope.query);
    getAverageShoppingCartAnalysisWithPrescription($scope.query);
  });
}

angular.module("app.statistics.averageShoppingCartAnalysis").controller("averageShoppingCartController", AverageShoppingCartController);