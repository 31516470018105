"use strict";
/**
 * Empty array directive.
 *
 * @returns {object}
 */

function emptyArray() {
  return {
    restrict: "E",
    scope: {
      options: "=options",
      data: "=data",
      emptyData: "=emptyData"
    },
    templateUrl: "app/main/directives/emptyArray/emptyArray.html",
    transclude: true,
    controller: function controller() {// do nothing
    }
  };
}

angular.module("app.directives").directive("emptyArray", emptyArray);