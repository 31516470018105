/* eslint-disable no-invalid-this */

/* global io */
"use strict";
/**
 * Apodis live service.
 *
 * @param {string} liveUrl - Live URL.
 *
 * @returns {object}
 */

apodisLiveService.$inject = ["liveUrl"];

function apodisLiveService(liveUrl) {
  var vis = this;
  this.socket = io(liveUrl);

  var _removeListener = function _removeListener(name, func) {
    vis.socket.removeListener(name, func);
  };

  this.register = function (options) {
    return vis.socket.emit("register", options);
  };

  this.unregister = function (service, func) {
    _removeListener(service, func);

    return vis.socket.emit("unregister", {
      service: service
    });
  };

  this.unregisterAll = function () {
    vis.socket.removeAllListeners();
    return vis.socket.emit("unregisterAll");
  };

  return {
    socket: this.socket,
    register: this.register,
    unregister: this.unregister,
    unregisterAll: this.unregisterAll
  };
}

angular.module("app.services").service("apodisLiveService", apodisLiveService);