"use strict";
/**
 * Healthcenter Sellin offer controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $mdToast - $mdToast.
 * @param {object} sessionStorageService - Session storage service.
 * @param {string} apiurl - API URL.
 * @param {object} environmentService - Environment service.
 * @param {object} sellinOfferService - Sellin offer service.
 */

SellinOfferDetailsController.$inject = ["$scope", "$rootScope", "$stateParams", "$state", "$mdDialog", "$mdToast", "sessionStorageService", "apiurl", "environmentService", "sellinOfferService"];
HealthcenterSellinOfferModalController.$inject = ["$scope", "$mdDialog", "$window", "apiurl", "data", "healthcenterId", "sellinOfferService"];

function SellinOfferDetailsController($scope, $rootScope, $stateParams, $state, $mdDialog, $mdToast, sessionStorageService, apiurl, environmentService, sellinOfferService) {
  $scope.loading = true;
  $scope.sellinOffer = {};
  $scope.refuse = refuse;
  $scope.openSellinOfferAcceptModal = openSellinOfferAcceptModal;
  loader();
  /**
   *
   * loader
   * this method init forEach tabs
   * and load loadAccess() method
   */

  function loader() {
    loadCurrentEnvironment().then(function () {
      loadSellinOffer();
    });
  }
  /**
   * Load current environment.
   *
   * @returns {Promise}
   */


  function loadCurrentEnvironment() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.currentAccess = access;
    });
  }
  /**
   * Load sellin offer
   *
   * @returns {Promise}
   */


  function loadSellinOffer() {
    return sellinOfferService.getSellinOfferByIdForHealthcenter($stateParams.id, $scope.currentAccess.entityId).then(function (response) {
      $scope.loading = false;
      $scope.sellinOffer = response;
      return response;
    });
  }
  /**
   * Refuse the sellin offer.
   *
   * @param {object} sellinOffer - The sellin offer to refuse.
   *
   * @returns {Promise}
   */


  function refuse(sellinOffer) {
    return sellinOfferService.refuse(sellinOffer).then(function (response) {
      $scope.loading = false;
      $scope.sellinOffer = response;
      return response;
    });
  }
  /**
   * Open sellin offer acceptation modal.
   *
   * @param {object} sellinOffer - The sellin offer data.
   */


  function openSellinOfferAcceptModal(sellinOffer) {
    $mdDialog.show({
      controller: HealthcenterSellinOfferModalController,
      locals: {
        data: sellinOffer,
        healthcenterId: $scope.currentAccess.entityId
      },
      scope: $scope,
      preserveScope: true,
      templateUrl: "app/main/commercial-offer/views/healthcenter/detail/sellinOfferAcceptationModal.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  }
}
/**
 * Sellin offer modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $window - $window.
 * @param {string} apiurl - apiURL.
 * @param {object} data - Data.
 * @param {string} healthcenterId - The healthcenter id concerned.
 * @param {object} sellinOfferService - Sellin Offer Service.
 */


function HealthcenterSellinOfferModalController($scope, $mdDialog, $window, apiurl, data, healthcenterId, sellinOfferService) {
  $scope.close = close;
  $scope.data = data;
  $scope.accept = accept;
  $scope.downloadCgv = downloadCgv;
  /**
   * Accept.
   *
   * @param {object} offer - The target offer.
   *
   * @returns {Promise}
   */

  function accept(offer) {
    var query = {
      id: offer.id,
      healthcenterId: healthcenterId
    };
    sellinOfferService.validateHealthcenterSellinOffer(query);
    var queryAccept = {
      id: offer.id,
      laboId: offer.entityId
    };
    sellinOfferService.accept(queryAccept);
    return close();
  }
  /**
   * Download CGVs.
   *
   * @param {string} cgvPath - The cgv path.
   */


  function downloadCgv(cgvPath) {
    $window.open($scope.apiurl + "/files/" + cgvPath, "_blank");
  }
  /**
   * Close the modal.
   */


  function close() {
    $mdDialog.hide();
  }
}

angular.module("app.commercialOffer").controller("sellinOfferDetailsController", SellinOfferDetailsController);