"use strict";
/**
 * Statistics service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

StatisticsService.$inject = ["apiClient"];

function StatisticsService(apiClient) {
  return {
    /**
     * Sales.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    sales: function sales(params) {
      return apiClient.post("/analysis/sales", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Vat split.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    vatSplit: function vatSplit(params) {
      return apiClient.post("/analysis/vatSplit", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Top pharma.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    topPharma: function topPharma(params) {
      return apiClient.get("/analysis/grouping/".concat(params.id, "/top/").concat(params.limit), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.services").service("statisticsService", StatisticsService);