"use strict";
/**
 * User laboratory detail controller.
 *
 * @param {object} $rootScope - The angular $rootScope object.
 * @param {object} $scope - The angular $scope object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $state - The angular $state object.
 * @param {object} sessionStorageService - Session storage service..
 * @param {string} apiurl - Th eapi url constant.
 * @param {object} environmentService - The environment service.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} StatisticsQueryService - The statistics query service.
 * @param {object} analysisService - Analysis service.
 */

UsersLaboratoryDetailsController.$inject = ["$rootScope", "$scope", "$stateParams", "$state", "sessionStorageService", "apiurl", "environmentService", "laboratoriesService", "StatisticsQueryService", "analysisService"];

function UsersLaboratoryDetailsController($rootScope, $scope, $stateParams, $state, sessionStorageService, apiurl, environmentService, laboratoriesService, StatisticsQueryService, analysisService) {
  /**
   *
   * Declaration of public methods
   */
  $scope.setRangeProducts = setRangeProducts;
  $scope.goToProduct = goToProduct;
  $scope.resetBranding = resetBranding;
  /**
   * Declaration of public objects
   */

  $scope.apiurl = apiurl;
  $scope.token = sessionStorageService.getToken();
  $scope.products = [];
  $scope.laboratoryBranding = {};
  $scope.apodisContentTitle = {
    title: {
      text: "Laboratoire : ",
      back: {
        state: "app.usersLaboratory",
        tooltip: "Revenir à la liste des laboratoires"
      }
    }
  };
  $scope.query = {
    order: "-startDate",
    limit: 10,
    limit_options: [5, 10, 25, 50, 100],
    page: 1
  };
  $scope.queryPreco = {
    order: "-startDate",
    limit: 10,
    limit_options: [5, 10, 25, 50, 100],
    page: 1
  };
  $scope.usrquery = StatisticsQueryService.query;
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * Load each method inside
   *
   * @returns {Promise}
   */

  function loader() {
    return loaderEnvironment().then(getLaboratory).then(getBranding).then(function (response) {
      if ($scope.currentAccess.entity.isGroupingValidated) {
        return getCondition();
      }

      return response;
    }).then(function () {
      return initProducts();
    }).then(function () {
      return getStocks();
    });
  }
  /**
   * Load current environment
   *
   * @returns {Promise}
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      return response;
    });
  }
  /**
   * Get laboratory.
   *
   * @returns {Promise}
   */


  function getLaboratory() {
    var query = {
      id: $stateParams.laboratoryId
    };
    return laboratoriesService.read(query).then(function (laboratory) {
      $scope.laboratory = laboratory;
      $scope.apodisContentTitle.title.text = $scope.apodisContentTitle.title.text + laboratory.name;
      return laboratory;
    });
  }
  /**
   * Get laboratory's branding with conditions
   *
   * @returns {Promise}
   */


  function getBranding() {
    var query = {
      laboratoryId: $stateParams.laboratoryId
    };
    return laboratoriesService.readBranding(query).then(function (response) {
      if (response) {
        $scope.currentBranding = response;
        $scope.laboratoryBranding = response;
        return response;
      }

      return null;
    });
  }
  /**
   * Get condition of target laboratory
   *
   * @returns {Promise}
   */


  function getCondition() {
    var query = {
      laboratoryId: $stateParams.laboratoryId
    };
    return laboratoriesService.readConditions(query).then(function (response) {
      if (response) {
        $scope.laboratory.conditions = response[0];
        return response;
      }

      return null;
    });
  }
  /**
   * Set all products or target universes products
   *
   * @param {object} myRange - The range.
   * @returns {Array};
   */


  function initProducts(myRange) {
    $scope.products = [];

    if (myRange) {
      if (myRange.products.length > 0) {
        $scope.products = myRange.products;
      }

      return $scope.products;
    }

    if ($scope.laboratoryBranding.parentRanges) {
      $scope.laboratoryBranding.parentRanges.map(function (univers) {
        univers.childrenRanges.map(function (range) {
          if (range.products.length) {
            range.products.map(function (product) {
              $scope.products.push(product);
              return product;
            });
          }

          return range;
        });
        return univers;
      });
      return $scope.products;
    }

    return $scope.products;
  }
  /**
   * Set $scope.currentBranding -> range.branding
   * Cast event $broadcast -> 'slideshow:refresh' with 'response.sliders'
   *
   * @param {object} range - The range.
   */


  function setBranding(range) {
    if (range.branding.sliders.length === 0) {
      range.branding.sliders = $scope.laboratoryBranding.sliders;
    }

    $scope.currentBranding = range.branding;

    if (Array.isArray($scope.currentBranding.sliders)) {
      $rootScope.$broadcast("slideshow:refresh", $scope.currentBranding.sliders);
    }
  }
  /**
   * Reset $scope.currentBranding -> $scope.laboratoryBranding
   * Cast event $broadcast -> 'slideshow:refresh' with 'response.sliders'
   */


  function resetBranding() {
    $scope.currentBranding = $scope.laboratoryBranding;

    if (Array.isArray($scope.currentBranding.sliders)) {
      $rootScope.$broadcast("slideshow:refresh", $scope.currentBranding.sliders);
    }

    initProducts();
  }
  /**
   * Set range products
   *
   * @param {object} parentRange - The parent range.
   * @param {object} childrenRange - The children ranges.
   */


  function setRangeProducts(parentRange, childrenRange) {
    parentRange.active = false;

    if (childrenRange) {
      initProducts(childrenRange);
      setBranding(childrenRange);
    }

    initProducts();
    setBranding(parentRange);
  }
  /**
   * getStocks
   */


  function getStocks() {
    $scope.usrquery.must.laboratory.selected = [{
      id: $stateParams.laboratoryId
    }];
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      return analysisService.getLaboratoryStockByHealthcenter($scope.currentAccess.entity.cip, query);
    }).then(function (response) {
      $scope.stocks = response.hits.hits;
    });
  }
  /**
   * Go to product edition 'app.laboratory-products.edit'
   *
   * @param {object} product - The product.
   */


  function goToProduct(product) {
    $state.go("app.laboratory-products.edit", {
      id: product.id
    });
  }
}

angular.module("app.users-laboratory").controller("usersLaboratoryDetailsController", UsersLaboratoryDetailsController);