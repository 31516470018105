"use strict";
/**
 * The app-card-title directive.
 *
 * @returns {object}
 */

function AppCardTitle() {
  return {
    restrict: "E",
    template: "<p class='app-card-title' ng-transclude></p>",
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appCardTitle", AppCardTitle);