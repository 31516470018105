"use strict";
/**
 * Proxy client factory.
 *
 * @param {object} $http - $http helper
 * @param {object} hdsApiConfig - Sante secure API config.
 * @param {object} sessionStorageService - Session storage service.
 *
 * @returns {Function}
 */

HdsApiClient.$inject = ["$http", "hdsApiConfig", "sessionStorageService"];

function HdsApiClient($http, hdsApiConfig, sessionStorageService) {
  return function (method, url) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var params = {
      method: method,
      params: options ? options.params : null,
      responseType: options ? options.responseType : null,
      url: "".concat(hdsApiConfig.url).concat(url),
      headers: {
        Authorization: sessionStorageService.getBearerToken()
      }
    };

    if (options && options.data) {
      params = Object.assign(params, {
        data: {
          data: options.data
        }
      });
    }

    return $http(params).then(function (response) {
      return response.data;
    });
  };
}

angular.module("app.services").factory("hdsApiClient", HdsApiClient);