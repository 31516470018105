"use strict";
/**
 * Dashboard laboratories sellin challenges push controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 */

DashboardLaboratoriesSellinChallengesPushController.$inject = ["$scope", "$state"];

function DashboardLaboratoriesSellinChallengesPushController($scope, $state) {
  $scope.goToChallenges = function () {
    $state.go("app.sellinChallenge");
  };
}

angular.module("app.dashboard").controller("dashboardLaboratoriesSellinChallengesPushController", DashboardLaboratoriesSellinChallengesPushController);