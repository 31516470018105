"use strict";
/**
 * Dashboard laboratories map statistics controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} gmap - Google map.
 * @param {object} dashboardAnalysisService - Dashboard analysis service.
 * @param {object} $filter - $filter.
 */

DashboardLaboratoriesMapStatisticsController.$inject = ["$scope", "gmap", "dashboardAnalysisService", "$filter"];

function DashboardLaboratoriesMapStatisticsController($scope, gmap, dashboardAnalysisService, $filter) {
  var myLatLng = {
    lat: 47.6468754,
    lng: 2.3242131
  };
  var mapOptions = {
    center: new google.maps.LatLng(myLatLng),
    zoom: 6,
    minZoom: 0,
    gestureHandling: "cooperative",
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapTypeControl: true,
    disableDefaultUI: true,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      position: google.maps.ControlPosition.LEFT_TOP
    },
    zoomControl: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM
    },
    scaleControl: true,
    streetViewControl: true,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM
    },
    fullscreenControl: false,
    styles: gmap
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    initMapStats();
  }
  /**
   * Initialize map statistics.
   */


  function initMapStats() {
    dashboardAnalysisService.getMapByUGAStats().then(function (response) {
      $scope.uga = response.aggregations.uga.buckets;
      $scope.map = new google.maps.Map(document.getElementById("map-container-uga"), mapOptions);
      $scope.bounds = new google.maps.LatLngBounds();
      angular.forEach($scope.uga, function (uga) {
        if (uga.thisYear.centroid.location) {
          var geo = {
            lat: uga.thisYear.centroid.location.lat,
            lng: uga.thisYear.centroid.location.lon
          };
          var marker = new google.maps.Marker({
            position: geo,
            map: $scope.map,
            icon: {
              url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(getSvg(uga)),
              scaledSize: new google.maps.Size(50, 50),
              anchor: new google.maps.Point(25, 50)
            },
            optimized: true
          });
          marker.setMap($scope.map);
          $scope.bounds.extend(geo);
        }
      });
      $scope.map.fitBounds($scope.bounds);
    });
  }
  /**
   * Get SVG for the map marker
   *
   * @param {object} uga - The value
   *
   * @returns {string} The generated SVG
   */


  function getSvg(uga) {
    var evol = uga.thisYear.ca.sum / uga.lastYear.ca.sum * 100 - 100;
    var color = "";

    if (evol > 0) {
      color = "blue";
    } else if (evol < 0) {
      color = "red";
    } else if (evol >= 0) {
      color = "orange";
    }

    var arrColor = {
      green: "#33cc00",
      orange: "#FF8800 ",
      red: "#D0011B ",
      blue: "#1E90FF"
    };
    var strokeColor = arrColor.orange;

    if (arrColor[color]) {
      strokeColor = arrColor[color];
    }

    var svg = "<?xml version='1.0'?>" + "<svg width='50px' height='47px' viewBox='0 0 50 47' version='1.1' xmlns='http://www.w3.org/2000/svg'>" + "<g id='Welcome' >" + "<g id='Desktop-2' transform='translate(-888.000000, -294.000000)'>" + "<g id='Group-2' transform='translate(893.000000, 297.000000)'>" + "<g id='Notes-/-Callout-S'>" + "<g id='Callout-Background'>" + "<path stroke='" + strokeColor + "' stroke-width='1' fill='#FFFFFF ' fill-rule='evenodd'  " + "d='M4.53984489,4.51543712 C-1.51328163,10.53602 -1.51328163,20.2973134 4.53984489,26.3178962 L14.6216116,36.3454599 " + "C15.1067321,36.8279722 15.8900777,36.8311452 16.3783884,36.3454599 L26.4601551,26.3178962 C32.5132816,20.2973134 " + "32.5132816,10.53602 26.4601551,4.51543712 C20.4070286,-1.50514571 10.5929714,-1.50514571 4.53984489,4.51543712 Z' id='path-1'></path>" + "</g>" + "</g>" + "<text id='number' fill='" + strokeColor + "' text-anchor='middle' font-family='Arial, Arial ' font-size='8' font-weight='400'>" + "<tspan x='16' y='16'>" + $filter("currency")(uga.thisYear.ca.sum, "€") + "</tspan>" + "</text>" + "<text id='number' fill='" + strokeColor + "' text-anchor='middle' font-family='Arial, Arial ' font-size='8' font-weight='400'>" + "<tspan x='16' y='25'>" + $filter("number")(evol, 0) + " %</tspan>" + "</text>" + "</g>" + "</g>" + "</g>" + "</svg>";
    return svg;
  }
}

angular.module("app.dashboard").controller("dashboardLaboratoriesMapStatisticsController", DashboardLaboratoriesMapStatisticsController);