"use strict";
/**
 * import directive.
 *
 * @param {object} $mdDialog - Material design dialog.
 * @param {object} notifyUserService - User notification service.
 * @param {object} importService - Import service.
 * @param {object} sessionStorageService - Session storage service.
 *
 * @returns {object} Directive.
 */

importDirective.$inject = ["$mdDialog", "notifyUserService", "importService", "sessionStorageService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function importDirective($mdDialog, notifyUserService, importService, sessionStorageService) {
  return {
    restrict: "A",
    scope: {
      config: "@config",
      callback: "&importCallback"
    },
    link: function link(scope, element) {
      element.on("click", function (event) {
        var importConfig = null;

        if (scope.config) {
          importConfig = scope.config;
        } else if (scope.$parent.vm.importConfig) {
          importConfig = scope.$parent.vm.importConfig;
        }

        openDialog(event, importConfig);
      });
      /**
       * Open import dialog.
       *
       * @param {object} event - Event triggered.
       * @param {object} importConfig - Import configuration.
       */

      function openDialog(event, importConfig) {
        $mdDialog.show({
          controller: ["$rootScope", "$mdDialog", "apiurl", function controller($rootScope, $mdDialog, apiurl) {
            var token = sessionStorageService.getToken();
            var vm = this; // No configuration set in the parent controller

            if (importConfig === null) {
              // eslint-disable-next-line no-console
              console.error("error : no importConfig defined");
            } else {
              vm.importConfig = _typeof(importConfig) !== "object" ? JSON.parse(importConfig) : importConfig;
            }

            vm.apiurl = apiurl;
            vm.apiImportUrl = vm.apiurl + "/import/upload?token=" + token;
            vm.token = token;
            vm.loading = false;
            vm.cancel = cancel;
            vm.uploadFile = uploadFile;
            vm.fileSuccess = fileSuccess;
            vm.fileError = fileError;
            vm.importFileContent = importFileContent;
            vm.fileLoaded = false;
            vm.canImport = false;
            vm.titleConnection = {};
            vm.dataPreview = {};
            vm.availableColumns = {};
            vm.fieldsToBeImported = {};
            vm.entityName = vm.importConfig.entityName !== "undefined" ? vm.importConfig.entityName : "Product";
            vm.allowChunks = typeof vm.importConfig.allowChunks !== "undefined" ? vm.importConfig.allowChunks : true;
            vm.ngFlowOptions = {
              target: function target() {
                return vm.apiurl + "/import/upload";
              },
              simultaneousUploads: 1,
              testChunks: false,
              progressCallbacksInterval: 1000
            };
            vm.ngFlow = {
              flow: {}
            };
            vm.fieldsSelected = fieldsSelected;
            /**
             * Fields selected.
             */

            function fieldsSelected() {
              angular.forEach(vm.fieldsToBeImported, function (field, key) {
                if (field === "") {
                  delete vm.fieldsToBeImported[key];
                }
              });
              vm.canImport = Object.keys(vm.fieldsToBeImported).length > 0;
            }
            /**
             * When a file is choosen, upload file with these options
             */


            function uploadFile() {
              vm.loading = true;
              vm.ngFlow.flow.opts.query = {
                entityName: vm.entityName
              };
              vm.ngFlow.flow.opts.headers = {
                "X-Requested-With": "XMLHttpRequest",
                Authorization: "Bearer " + token
              };
              vm.ngFlow.flow.upload();
            }
            /**
             * Set vm vars to response values
             *
             * @param {string} message - The message
             */


            function fileSuccess(message) {
              var response = JSON.parse(message);
              vm.fullPath = response.fullPath;
              vm.titlePreview = response.titlePreview;
              vm.dataPreview = response.dataPreview;
              vm.availableColumns = response.availableColumns;
              vm.availableColumns[""] = "";
              vm.fileLoaded = true;
              vm.loading = false;
            }
            /**
             * Set vm vars to response values
             *
             * @param {string} message - The message
             */


            function fileError(message) {
              var response = JSON.parse(message);
              notifyUserService.showDangerToast(response.message);
              $mdDialog.hide();
              vm.fileLoaded = false;
              vm.loading = false;
            }
            /**
             * Import the content of the requested file
             */


            function importFileContent() {
              vm.loading = true;
              vm.canImport = false; // If callbackEvent, get Data from the file and send it to the event

              if (vm.importConfig.callbackEvent && vm.importConfig.callbackEvent !== "") {
                notifyUserService.showSimpleToast("Analyse et import des données en cours.");
                var params = {
                  entityName: vm.entityName,
                  fullPath: vm.fullPath,
                  fieldsToBeImported: vm.fieldsToBeImported,
                  offset: 0,
                  chunkSize: 3000,
                  allowChunks: vm.allowChunks
                };
                processImport(params).then(function (data) {
                  vm.loading = false;
                  vm.canImport = true;
                  $rootScope.$broadcast(vm.importConfig.callbackEvent, data);
                  $mdDialog.hide();
                  notifyUserService.showSuccessToast("Les données ont bien été importées.");
                });
              } else {
                // Else persist to the db
                importService.save({
                  entityName: vm.entityName,
                  fullPath: vm.fullPath,
                  fieldsToBeImported: vm.fieldsToBeImported
                }).then(function () {
                  vm.loading = false;
                  notifyUserService.showSuccessToast("Les données ont bien été importées.");
                  $mdDialog.hide();
                }, function (response) {
                  vm.loading = false;
                  notifyUserService.showDangerToast("Une erreur a été rencontrée lors de l'import des données."); // eslint-disable-next-line no-console

                  console.error(response);
                });
              }
            }
            /**
             * Process entities import.
             *
             * @param {object} params - Import parameters.
             *
             * @returns {Promise} Import promise.
             */


            function processImport(params) {
              if (params.allowChunks) {
                return getChunks(params);
              }

              return importService.getData(params);
            }
            /**
             * Get all datas by chunks.
             *
             * @param {object} params - Import parameters.
             *
             * @returns {Promise}
             */


            function getChunks(params) {
              return importService.getChunksCount(params).then(function (response) {
                var copyParams = params;
                var promises = [];

                for (var index = 0; index < response.count; index++) {
                  promises.push(importService.getDataByChunk(_objectSpread({}, copyParams)));
                  copyParams.offset += copyParams.chunkSize;
                }

                return Promise.all(promises);
              }).then(function (data) {
                return data.reduce(function (previousValue, currentValue) {
                  return previousValue.concat(currentValue.data);
                }, []);
              })["catch"](function () {
                vm.loading = false;
                notifyUserService.showDangerToast("Une erreur a été rencontrée lors de l'import des données.");
              });
            }
            /**
             * Hide dialog box
             */


            function cancel() {
              $mdDialog.hide();
            }
          }],
          controllerAs: "vm",
          templateUrl: "app/main/directives/import/import.html",
          parent: angular.element(document.body),
          targetEvent: event,
          clickOutsideToClose: true
        });
      }
    }
  };
}

angular.module("apodis").directive("import", importDirective);