"use strict";
/**
 * Fabric constants factory.
 *
 * @returns {object}
 */

function FabricConstants() {
  var commonDefaults = {
    padding: 0,
    rotatingPointOffset: 50,
    borderColor: "#3498db",
    cornerColor: "#3498db",
    cornerSize: 12,
    transparentCorners: false
  };
  var shapeDefaults = angular.extend({
    fill: "#ffffff",
    stroke: null,
    strokeWidth: 1,
    strokeDashArray: null,
    strokeLineCap: "butt",
    strokeLineJoin: "miter",
    strokeMiterLimit: 10,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    flipX: false,
    flipY: false,
    opacity: 1,
    shadow: null,
    visible: true,
    clipTo: null,
    backgroundColor: "",
    fillRule: "nonzero",
    paintFirst: "fill",
    globalCompositeOperation: "source-over",
    transformMatrix: null,
    skewX: 0,
    skewY: 0
  }, commonDefaults);
  var textDefaults = angular.extend({
    originX: "left",
    scaleX: 1,
    scaleY: 1,
    fontFamily: "Open Sans",
    fontSize: 50,
    fill: "#000000",
    textAlign: "left"
  }, commonDefaults);
  var imageDefaults = angular.extend({}, commonDefaults);
  var svgDefaults = angular.extend({}, commonDefaults);
  var windowDefaults = angular.extend({}, commonDefaults);
  var presetTypes = [{
    name: "title",
    description: "Titre"
  }, {
    name: "descriptions",
    description: "Description"
  }, {
    name: "texts",
    description: "Description"
  }, {
    name: "contents",
    description: "Contenu"
  }, {
    name: "prices",
    description: "Prix"
  }, {
    name: "shapes",
    description: "Formes"
  }, {
    name: "start_date",
    description: "Date de début"
  }, {
    name: "end_date",
    description: "Date de fin"
  }];
  var shapeCategories = [{
    name: "Popular Shapes",
    shapes: ["arrow6", "bubble4", "circle1", "rectangle1", "star1", "triangle1"]
  }, {
    name: "Simple Shapes",
    shapes: ["circle1", "heart1", "rectangle1", "triangle1", "star1", "star2", "star3", "square1"]
  }, {
    name: "Arrows & Pointers",
    shapes: ["arrow1", "arrow9", "arrow3", "arrow6"]
  }, {
    name: "Bubbles & Balloons",
    shapes: ["bubble5", "bubble4"]
  }, {
    name: "Check Marks",
    shapes: []
  }, {
    name: "Badges",
    shapes: ["badge1", "badge2", "badge4", "badge5", "badge6"]
  }];
  var JSONExportProperties = ["id", "cip", "id_7", "id_13", "product_id", "product_name", "slug", "name", "description", "href", "model", "background", "height", "width", "lineHeight", "objects", "originalHeight", "originalWidth", "originalScaleX", "originalScaleY", "originalLeft", "originalTop", "parentName", "lockMovementX", "lockMovementY", "lockScalingX", "lockScalingY", "lockUniScaling", "lockRotation", "lockObject", "filters", "meta", "zindex", "sourcePath", "import", "src", "padding", "rotatingPointOffset", "borderColor", "cornerColor", "cornerSize", "transparentCorners", "selectable", "start_date", "crossOrigin", "type", "end_date"];
  return {
    presetTypes: presetTypes,
    shapeCategories: shapeCategories,
    JSONExportProperties: JSONExportProperties,
    shapeDefaults: shapeDefaults,
    textDefaults: textDefaults,
    imageDefaults: imageDefaults,
    svgDefaults: svgDefaults,
    windowDefaults: windowDefaults
  };
}

angular.module("app.generator").factory("fabricConstants", FabricConstants);