"use strict";
/**
 * Controller to edit plv.
 *
 * @param {object} $scope - Controller scope.
 * @param {object} $state - Module state.
 * @param {object} $stateParams - State parameters.
 * @param {object} $rootScope - Root scope.
 * @param {object} $q - Service to run function asynchronously.
 * @param {object} $mdDialog - Material design modal.
 * @param {object} $injector - Injector service.
 * @param {object} fabricLab - FabricLab service.
 * @param {object} fabricConstants - Fabric constants.
 * @param {object} fabricShapes - Fabric shapes.
 * @param {object} plvsFontsService - Plv fonts service.
 * @param {object} productService - Loader product.
 * @param {string} apiurl - Api base url.
 * @param {object} environmentService - Environment service.
 * @param {object} notifyUserService - User notification service.
 * @param {object} plvsService - Plv service.
 * @param {object} sessionStorageService - Session storage service.
 */

ExportDialogController.$inject = ["$scope", "$mdDialog"];
PlvDialogProductsController.$inject = ["$rootScope", "$scope", "$mdDialog", "apiurl", "environmentService", "laboratoryProductsService"];
PlvShareDocumentModalController.$inject = ["$scope", "$mdDialog", "plvsService", "myDocument"];
EditorController.$inject = ["$scope", "$state", "$stateParams", "$rootScope", "$q", "$mdDialog", "$injector", "fabricLab", "fabricConstants", "fabricShapes", "plvsFontsService", "productService", "apiurl", "environmentService", "notifyUserService", "plvsService", "sessionStorageService"];

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function EditorController($scope, $state, $stateParams, $rootScope, $q, $mdDialog, $injector, fabricLab, fabricConstants, fabricShapes, plvsFontsService, productService, apiurl, environmentService, notifyUserService, plvsService, sessionStorageService) {
  loader();
  /**
   * Loader to load each method inside.
   */

  function loader() {
    var hasServiceAndOrigin = $state.params.injectedService.length > 0 && $state.params.stateOrigin;

    if (!hasServiceAndOrigin) {
      $state.go("app.home");
      return;
    }

    $injector.invoke([$state.params.injectedService, function (injectedService) {
      /**
       * Declaration of public methods
       */
      $scope.save = save;
      $scope.print = print;
      $scope.announceClick = announceClick;
      $scope.saveExit = saveExit;
      $scope.exit = exit;
      $scope.exitWithModal = exitWithModal;
      $scope.addSvg = addSvg;
      $scope.addFmImage = addFmImage;
      $scope.addFmImageBackground = addFmImageBackground;
      $scope.addProducts = addProducts;
      $scope.addSimpleText = addSimpleText;
      $scope.menuClick = menuClick;
      $scope.subMenuClick = subMenuClick;
      $scope.toggleLockDocument = toggleLockDocument;
      $scope.shareDocument = shareDocument;
      /**
       * Declaration of public objects
       */

      $scope.injectedService = injectedService;
      $scope.originState = "app." + $state.params.stateOrigin.replace("/", ".");
      $scope.originatorEv;
      $scope.shapes = fabricShapes.shapes;
      $scope.token = sessionStorageService.getToken();
      $scope.apiurl = apiurl;
      $scope.optionsPickerBg = {
        icon: "format_paint",
        type: 2,
        history: false,
        genericPalette: false,
        palette: false
      };
      $scope.optionsPickerTxt = {
        icon: "format_color_text",
        genericPalette: false,
        history: false
      };
      $scope.menus = [{
        name: "Fichier",
        child: [{
          name: "Exporter",
          icon: "icon-content-save-all",
          description: "Exporter le document",
          actionClick: exportPlv
        }, {
          name: "Quitter",
          icon: "icon-exit-to-app",
          actionClick: $scope.exit,
          description: "saveExit"
        }]
      }];
      $scope.documentIsGenerated = false;
      $scope.isMaster = false;
      $scope.loader = {
        message: "Bienvenue dans l'interface de création de support de communication avec Apodis"
      };
      $scope.isEmpty = true;
      /**
       * Initialization of the loader
       */

      loader();
      /**
       * loader to load each method inside
       */

      function loader() {
        loaderEnvironment().then(read).then(loaderOpen).then(function (response) {
          if (!response.container) {
            return initor($scope.myDocument).then(save(true));
          }

          var sandBox = [];

          switch ($scope.accessPointType) {
            case 1:
              return initor($scope.myDocument, response);

            case 2:
              if (response.container.length > 0) {
                response.container.forEach(function (element) {
                  if (element.model === "prices") {
                    loaderPrices(element, sandBox, $scope.myDocument, response);
                  } else {
                    sandBox.push(element);

                    if (sandBox.length === response.container.length) {
                      initor($scope.myDocument, response, sandBox);
                      return;
                    }
                  }
                });
                break;
              }

              return initor($scope.myDocument, response, sandBox);

            case 3:
              if (response.container.length > 0) {
                response.container.forEach(function (containerElement) {
                  sandBox.push(containerElement);

                  if (sandBox.length === response.container.length) {
                    initor($scope.myDocument, response, sandBox);
                    return;
                  }
                });
                break;
              }

              return initor($scope.myDocument, response, sandBox);
          }

          return undefined;
        }).then(loadFonts);
      }
      /**
       * Load environment.
       *
       * @returns {Promise} Environment promise.
       */


      function loaderEnvironment() {
        return environmentService.getEnvironment().then(function (response) {
          $scope.environment = response;
          $scope.myProfile = response.user;
          $scope.accessPointType = response.accessPointType;
          return response;
        });
      }
      /**
       * read
       * to read document with document.id
       * init loaderToOpen()
       *
       * @returns {Promise} The document promise.
       */


      function read() {
        $scope.loader = {
          message: "Génération du support..."
        };
        var query = {
          id: $stateParams.id
        };
        return $scope.injectedService.read(query).then(function (response) {
          if (!response) {
            notifyUserService.showSimpleToast("Un problème est survenu lors de l'ouverture de votre support.", {
              color: "danger",
              hideDelay: 1000
            });
            return exit();
          }

          $scope.isMaster = response.access.entityId === $scope.environment.entityId;
          $scope.myDocument = response;
          $scope.workSpaceSize = {
            height: response.format.height_pixel,
            width: response.format.width_pixel
          };
          return response;
        });
      }
      /**
       * loaderOpen
       *
       * @param {object} myDocument - The current document.
       *
       * @returns {Promise} Open document promise.
       */


      function loaderOpen(myDocument) {
        return $scope.injectedService.open(myDocument).then(function (response) {
          if (response.container && response.container.length > 0) {
            $scope.isEmpty = response.container.length > 0;
            $scope.myDocument.myFile = response;
          }

          return response;
        });
      }
      /**
       * loadFonts
       *
       * @returns {Promise} Fonts promise.
       */


      function loadFonts() {
        $scope.loader = {
          message: "Chargement des typographies..."
        };
        return plvsFontsService.load().then(function (response) {
          $scope.fonts = response;
          return response;
        });
      }
      /**
       * loaderPrices
       *
       * @param {object} element - Element to load.
       * @param {object} sandBox - Sand box flag.
       * @param {object} myDocument - Document to update.
       * @param {object} myFile - File to update.
       *
       * @returns {Promise}
       */


      function loaderPrices(element, sandBox, myDocument, myFile) {
        var query = {
          id: element.product_id
        };
        return productService.getPresentation(query).then(function (response) {
          if (response && element.model === "prices") {
            var price = 0;

            if (response.stock) {
              price = response.stock.price_ttc;
            }

            element.text = price.toString().replace(".", ",") + " €";

            if (sandBox) {
              sandBox.push(element);
            }
          }

          if (sandBox && sandBox.length === myFile.container.length) {
            initor(myDocument, myFile, sandBox);
          }

          return response;
        });
      }
      /**
       * initor
       *
       * @param {object} myDocument - Document to initialize.
       * @param {object} myFile - File to initialize.
       * @param {object} sandBox - Sand box flag.
       *
       * @returns {Promise}
       */


      function initor(myDocument, myFile, sandBox) {
        if (sandBox) {
          myFile.container = sandBox;
          $scope.allLayers = sandBox;
        }

        myDocument.myFile = myFile;
        $scope.myDocument = myDocument;
        return initCanvas(myDocument).then($scope.fabric.setDefaultSize()).then($scope.fabric.centerCanvas()).then($scope.fabric.constructDocument(myDocument, $scope.myProfile));
      }
      /**
       * initCanvas
       *
       * @param {object} myDocument - Document to initialize.
       *
       * @returns {Promise} Initialized canvas promise.
       */


      function initCanvas(myDocument) {
        return $q(function (resolve) {
          if (_.isUndefined(angular.element(document).find(".upper-canvas")[0]) && _.isUndefined(angular.element(document).find(".lower-canvas")[0])) {
            $rootScope.$broadcast("initCanvasWatchers");
          }

          $scope.fabric = new fabricLab({
            canvasWidth: myDocument ? myDocument.format.width_pixel : 1000,
            canvasHeight: myDocument ? myDocument.format.height_pixel : 1000,
            canvasOriginalWidth: myDocument ? myDocument.format.width_pixel : 1000,
            canvasOriginalHeight: myDocument ? myDocument.format.height_pixel : 1000,
            JSONExportProperties: fabricConstants.JSONExportProperties,
            textDefaults: fabricConstants.textDefaults,
            shapeDefaults: fabricConstants.shapeDefaults,
            windowDefaults: fabricConstants.windowDefaults,
            imageDefaults: fabricConstants.imageDefaults,
            svgDefaults: fabricConstants.svgDefaults,
            isMaster: $scope.isMaster,
            isLocked: $scope.myDocument.myFile ? $scope.myDocument.myFile.isLocked : false,
            json: {}
          });
          initMenus();
          $scope.loader = {
            message: "Génération des calques..."
          };
          resolve(true);
        });
      }
      /**
       * initMenus
       *
       * @returns {object} Editor menus.
       */


      function initMenus() {
        if (!$scope.isMaster) {
          return false;
        }

        $scope.menus = [{
          name: "Fichier",
          child: [{
            name: "Enregistrer",
            icon: "icon-content-save",
            actionClick: $scope.save,
            description: "save"
          }, {
            name: "Exporter",
            icon: "icon-content-save-all",
            description: "Exporter le document",
            actionClick: exportPlv
          }, {
            name: "Enregistrer et quitter",
            icon: "icon-exit-to-app",
            actionClick: $scope.saveExit,
            description: "save and exit"
          }, {
            name: "Quitter sans enregistrer",
            icon: "icon-exit-to-app",
            actionClick: $scope.exit,
            description: "exit"
          }]
        }, {
          name: "Ajouter",
          child: [{
            name: "Ajouter un texte",
            icon: "icon-format-text",
            actionClick: $scope.addSimpleText,
            description: "text"
          }, {
            name: "Ajouter un produit",
            icon: "icon-package-variant",
            actionClick: $scope.addProducts,
            description: "product"
          }, {
            name: "Ajouter une image",
            icon: "icon-file-image-box",
            description: "image",
            isFileManager: true,
            fileManager: {
              type: "image",
              file: "myImages.file",
              callback: $scope.addFmImage
            }
          }, {
            name: "Ajouter un svg",
            icon: "icon-vector-square",
            description: "vector",
            isFileManager: true,
            fileManager: {
              type: "vector",
              file: "myImages.file",
              callback: $scope.addSvg
            }
          }, {
            name: "Ajouter un logo groupement",
            icon: "icon-satellite",
            description: "logo groupement",
            id: "grouping_logo_placeholder",
            actionClick: function actionClick() {
              angular.element(document.getElementById("grouping_logo_placeholder")).hide();
              $scope.fabric.addImage({
                name: "grouping_logo_placeholder",
                "import": "/assets/images/generator/logoPlaceholder.png"
              });
            }
          }, {
            name: "Ajouter une forme",
            icon: "icon-shape-plus",
            description: "shapes",
            child: [{
              name: "Rectangle",
              icon: "icon-crop-square",
              actionClick: $scope.fabric.addRectangle,
              slug: "shapes"
            }, {
              name: "Cercle",
              icon: "icon-checkbox-blank-circle-outline",
              actionClick: $scope.fabric.addCircle,
              slug: "shapes"
            }, {
              name: "Triangle",
              icon: "icon-details",
              actionClick: $scope.fabric.addTriangle,
              slug: "shapes"
            }]
          }]
        }];
        return $scope.menus;
      }
      /**
       * menuClick
       *
       * @param {object} menu - Clicked menu.
       * @param {object} event - Triggered event.
       */


      function menuClick(menu, event) {
        event.stopPropagation();

        if (menu) {
          if (menu.name === "Aide") {
            menu.actionClick();
          }

          $scope.menus.forEach(function (obj) {
            if (obj !== menu) {
              obj.active = false;
            }
          });

          if (menu.name !== "Aide") {
            menu.active = !menu.active;
          }
        }
      }
      /**
       * subMenuClick
       *
       * @param {object} menu - Sub menu clicked.
       * @param {object} event - Triggered event.
       */


      function subMenuClick(menu, event) {
        event.stopPropagation();

        if (menu.child) {
          menu.active = !menu.active;
        } else {
          menu.actionClick();
          menuClick(menu, event);
          menu.active = !menu.active;
          $scope.menus.forEach(function (mainMenu) {
            if (mainMenu.child) {
              mainMenu.child.forEach(function (subMenu) {
                if (subMenu.description === menu.slug) {
                  subMenu.active = !subMenu.active;
                }
              });
            }
          });
        }
      }
      /**
       * announceClick
       *
       * @param {object} index - Announce index clicked.
       */


      function announceClick(index) {
        $mdDialog.show($mdDialog.alert().title("You clicked!").textContent("You clicked the item at index".concat(index)).ok("Nice").targetEvent($scope.originatorEv));
        $scope.originatorEv = null;
      }
      /**
       * addFmImage
       *
       * @param {object} file - Image file to add.
       */


      function addFmImage(file) {
        if (!_.isUndefined(file) && !_.isNull(file)) {
          file["import"] = "".concat(apiurl, "/filemanager/file/show?id=").concat(file.id, "&token=").concat(file.token);
          $scope.fabric.addImage(file);
        }
      }
      /**
       * addFmImageBackground
       *
       * @param {object} file - Image file to add.
       */


      function addFmImageBackground(file) {
        if (!_.isUndefined(file) && !_.isNull(file)) {
          file["import"] = "".concat(apiurl, "/filemanager/file/show?id=").concat(file.id, "&token=").concat(file.token);
          $scope.fabric.addImageBackground(file);
        }
      }
      /**
       * addSvg
       *
       * @param {object} file - SVG file to add.
       */


      function addSvg(file) {
        if (!_.isUndefined(file) && !_.isNull(file)) {
          file["import"] = "".concat(apiurl, "/filemanager/file/show?id=").concat(file.id, "&token=").concat(file.token);
          $scope.fabric.addSvg(file);
        }
      }
      /**
       * addSimpleText
       *
       * @param {object} text - Text to add.
       */


      function addSimpleText(text) {
        $scope.fabric.addSimpleText(text);
      }
      /**
       * save the document
       *
       * @returns {Promise} Document save promise.
       */


      function save() {
        $scope.documentIsGenerated = false;
        $scope.loader = {
          message: "Le support \xAB ".concat($scope.myDocument.name, " \xBB est en cours d'enregistrement...")
        };
        var myDocument = generateDocument();
        myDocument.productsAdded = listProductsAdded(myDocument);
        return $scope.injectedService.save(myDocument).then(function (response) {
          $scope.myDocument.file_id = response.id;
          $scope.fabric.centerCanvas();
          notifyUserService.showSimpleToast("Le support \xAB ".concat($scope.myDocument.name, " \xBB a \xE9t\xE9 enregistr\xE9 avec succ\xE8s"), {
            color: "success",
            hideDelay: 1000
          });
          return response;
        })["catch"](function (error) {
          notifyUserService.showSimpleToast("Une erreur est survenue, \xAB ".concat($scope.myDocument.name, " \xBB non enregistr\xE9"), {
            color: "danger",
            hideDelay: 1000
          });
          return error;
        });
      }
      /**
       * Generate PLV document.
       *
       * @returns {object} PLV document.
       */


      function generateDocument() {
        return angular.extend($scope.fabric.generateDocument($scope.myDocument), {
          id: $scope.myDocument.id
        });
      }
      /**
       * Add products id list in PLV document.
       *
       * @param {object} myDocument - PLV document.
       *
       * @returns {Array} - PLV document.
       */


      function listProductsAdded(myDocument) {
        var canvasProductModels = ["products", "prices", "productImages"];
        var productsAdded = [];
        angular.forEach(myDocument.container, function (canvas) {
          if (canvasProductModels.indexOf(canvas.model) !== -1) {
            var productAdded = productsAdded.find(function (product) {
              return product.id === canvas.product_id;
            });

            if (!productAdded) {
              productsAdded.push({
                id: canvas.product_id
              });
            }
          }
        });
        return productsAdded;
      }
      /**
       * saveExit
       */


      function saveExit() {
        $scope.documentIsGenerated = false;
        $scope.loader = {
          message: "Le support \xAB ".concat($scope.myDocument.name, " \xBB est en cours d'enregistrement...")
        };
        save().then(exit);
      }
      /**
       * exit
       */


      function exit() {
        $scope.fabric.clearCanvas();
        $state.go($scope.originState);
      }
      /**
       * exitWithModal
       */


      function exitWithModal() {
        if (!$scope.isMaster) {
          $state.go($scope.originState);
          return;
        }

        var confirm = $mdDialog.confirm().title("Quitter").textContent("Vous allez quitter l'édition du document « " + $scope.myDocument.name + " », souhaitez-vous 'Enregistrer et quitter'" + " ou 'Quitter sans enregistrer'?").ariaLabel("Quitter le document").ok("Enregistrer et quitter").cancel("Quitter sans enregistrer");
        $mdDialog.show(confirm).then(saveExit, exit);
      }
      /**
       * addProducts
       *
       * @param {object} event - Triggered event.
       */


      function addProducts(event) {
        var dialog = {
          templateUrl: "app/main/generator/view/modals/dialogProducts.html",
          controller: PlvDialogProductsController,
          parent: angular.element(document.body),
          targetEvent: event,
          clickOutsideToClose: true
        };
        $mdDialog.show(dialog).then(function (product) {
          product.product_id = product.id;

          if ($scope.accessPointType === 2) {
            loaderPrices(product).then(function (response) {
              product.text = "0,00 €";

              if (response.stock) {
                product.text = response.stock.price_ttc.toString().replace(".", ",") + " €";
              }

              addToFabric(product);
            });
          } else {
            addToFabric(product);
          }

          return product;
        });
        $scope.objsCanvas = $scope.fabric.getObjsWithoutTemplate();
      }
      /**
       * Add product to fabric
       *
       * @param {object} product - The product
       */


      function addToFabric(product) {
        if (product.hasText) {
          $scope.fabric.addProductText(product);
        }

        if (product.hasPrice) {
          $scope.fabric.addProductPrice(product);
        }

        if (product.hasImage) {
          var prodImage = {
            id: product.id,
            name: product.name,
            "import": "".concat(apiurl, "/images/").concat(product.photoPath)
          };
          $scope.fabric.addImage(prodImage, product);
        }
      }
      /**
       * toggleLockDocument
       * change boolean value to lock or unlock document
       */


      function toggleLockDocument() {
        $scope.myDocument.myFile.isLocked = !$scope.myDocument.myFile.isLocked;
        $scope.fabric.lockObjects($scope.myDocument.myFile.isLocked);
        save();
      }
      /**
       * Share a document.
       *
       * @param {object} myDocument - Document to share.
       */


      function shareDocument(myDocument) {
        var dialog = {
          templateUrl: "app/main/generator/view/modals/share.html",
          locals: {
            myDocument: myDocument
          },
          controller: PlvShareDocumentModalController
        };
        $mdDialog.show(dialog);
      }
      /**
       * exportPlv
       */


      function exportPlv() {
        if (!$scope.myDocument.myFile) {
          plvsService.open($scope.myDocument).then(function (response) {
            $scope.myDocument.myFile = response;
          });
        }

        var dialog = {
          templateUrl: "app/main/generator/view/modals/export.html",
          controller: ExportDialogController
        };
        $mdDialog.show(dialog).then(function (response) {
          if (response) {
            exportDoc($scope.myDocument, response.type, response.variant);
          }
        });
      }
      /**
       * exportDoc
       *
       * @param {object} plv - Plv to export.
       * @param {string} type - Export type.
       * @param {string} variant - Export variant.
       *
       * @returns {Promise} Document export promise.
       */


      function exportDoc(plv, type, variant) {
        var document = generateDocument();

        if (!variant) {
          return plvsService["export"](plv.id, type, document).then(function (file) {
            return window.open("".concat(apiurl, "/images/").concat(file.full_path));
          });
        }

        return plvsService.exportVariant(plv.id, type, variant, document).then(function (file) {
          if (type !== "png") {
            return window.open("".concat(apiurl, "/files/").concat(file.full_path));
          }

          return window.open("".concat(apiurl, "/images/").concat(file.full_path));
        });
      }
      /**
       * openTab
       * $on "fabric:watchCanvas"
       */


      $rootScope.$on("fabric:watchCanvas", function (event, array) {
        event.preventDefault();

        if (array.length > 0) {
          $scope.isEmpty = false;
        } else {
          $scope.isEmpty = true;
        }

        $scope.allLayers = array;
        $scope.$apply();
        $scope.documentIsGenerated = true;
      });
      /**
       * openTab
       * $on "selectTargetObject"
       */

      $rootScope.$on("selectTargetObject", function (event, object) {
        event.preventDefault();
        $scope.fabric.selectTargetObject(object);
      });
      /**
       * openTab
       * $on "toggleVisible"
       */

      $rootScope.$on("toggleVisible", function (event, object) {
        event.preventDefault();
        $scope.fabric.toggleVisible(object);
      });
      /**
       * openTab
       * $on "toggleLockActiveObject"
       */

      $rootScope.$on("toggleLockActiveObject", function (event, object) {
        event.preventDefault();
        $scope.fabric.toggleLockActiveObject(object);
      });
      /**
       * openTab
       * $on "saveFabricEvent"
       */

      $rootScope.$on("saveFabricEvent", function () {
        return save;
      });
      /**
       * openTab
       * $on "fabric:zoom"
       */

      $rootScope.$on("fabric:zoom", function (event, size) {
        event.preventDefault();
        $scope.workSpaceSize = size;
      });
    }]);
  }
}
/**
 * Controller to manage plv products dialog.
 *
 * @param {object} $rootScope - Root scope.
 * @param {object} $scope - Controller scope.
 * @param {object} $mdDialog - Material design dialog.
 * @param {string} apiurl - Api base url.
 * @param {object} environmentService - Environment service.
 * @param {object} laboratoryProductsService - Products service.
 */


function PlvDialogProductsController($rootScope, $scope, $mdDialog, apiurl, environmentService, laboratoryProductsService) {
  /**
   * Declaration of public methods
   */
  $scope.hide = hide;
  $scope.cancel = cancel;
  $scope.add = add;
  $scope.hasText = true;
  $scope.hasPrice = true;
  $scope.hasImage = true;
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * loader
   */

  function loader() {
    loaderEnvironment();
  }
  /**
   * loader environment.
   *
   * @returns {Promise} Environment promise.
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      switch (response.accessPointType) {
        case 1:
          $scope.config = {
            must: {
              "laboratory.id": [response.entity.id]
            },
            search: ["idProducts"],
            event: "addProductPlv",
            tag: "configProductPlv",
            autoLaunchEvent: true
          };
          break;

        case 2:
        case 3:
          $scope.config = {
            search: ["idProducts"],
            event: "addProductPlv",
            tag: "configProductPlv",
            autoLaunchEvent: true
          };
          break;
      }

      return response;
    });
  }
  /**
   * getProduct
   *
   * @param {object} product - Product to fetch.
   *
   * @returns {Promise} Product promise.
   */


  function getProduct(product) {
    return laboratoryProductsService.read(product.id).then(function (response) {
      response.hasText = $scope.hasText;
      response.hasPrice = $scope.hasPrice;
      response.hasImage = $scope.hasImage;
      response.photoPath = "".concat(apiurl, "/images/").concat(response.photoPath);
      $mdDialog.hide(response);
      return response;
    });
  }
  /**
   * hide
   */


  function hide() {
    $mdDialog.hide();
  }
  /**
   * cancel
   */


  function cancel() {
    $mdDialog.cancel();
  }
  /**
   * add
   *
   * @param {Array} prods - Added products.
   */


  function add(prods) {
    $mdDialog.hide(prods);
  }
  /**
   * addProductPlv
   * $on "addProductPlv"
   */


  $rootScope.$on("addProductPlv", function (event, args) {
    getProduct(args.product[0]);
  });
}
/**
 * Controller to manage export dialog.
 *
 * @param {object} $scope - The controller scope.
 * @param {object} $mdDialog - Material design dialog.
 */


function ExportDialogController($scope, $mdDialog) {
  $scope["export"] = {
    types: [{
      id: 1,
      name: "PDF",
      value: "pdf"
    }, {
      id: 2,
      name: "PNG",
      value: "png"
    }, {
      id: 3,
      name: "SVG",
      value: "svg"
    }],
    variants: [{
      id: 1,
      name: "72 Dpi",
      value: "small"
    }, {
      id: 2,
      name: "100 Dpi",
      value: "medium"
    }, {
      id: 3,
      name: "200 Dpi",
      value: "large"
    }, {
      id: 4,
      name: "300 Dpi",
      value: "xlarge"
    }],
    type: false,
    variant: false
  };
  $scope.validExport = validExport;
  $scope.cancelExport = cancelExport;
  /**
   * validExport
   */

  function validExport() {
    $mdDialog.hide($scope["export"]);
  }
  /**
   * cancel
   */


  function cancelExport() {
    $mdDialog.cancel();
  }
}
/**
 * Controller to manage plv document sharing dialog.
 *
 * @param {object} $scope - Controller scope.
 * @param {object} $mdDialog - Material design dialog.
 * @param {object} plvsService - Plv service.
 * @param {object} myDocument - Document to share.
 */


function PlvShareDocumentModalController($scope, $mdDialog, plvsService, myDocument) {
  $scope.close = close;
  $scope.remove = remove;
  $scope.validate = validate;
  $scope.toggle = toggle;
  $scope.isChecked = isChecked;
  $scope.entityTypes = [{
    id: 1,
    label: "Tous les laboratoires"
  }, {
    id: 2,
    label: "Toutes les pharmacies"
  }, {
    id: 3,
    label: "Tous les groupements"
  }];
  $scope.selectedEntityTypes = [];
  $scope.searchConfig = {
    event: "addShareEditInEditor",
    search: ["laboratory.id", "idOrgas", "group.id"],
    tag: "share",
    searchPlaceHolder: "Rechercher des laboratoires, des groupements et des pharmacies",
    autoLaunchEvent: true
  };
  $scope.selectedOrganizations = [];
  /**
   * addShareEdit event.
   */

  $scope.$on("addShareEditInEditor", function (event, results) {
    var _iterator = _createForOfIteratorHelper(_.keys(results)),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var type = _step.value;
        results[type].forEach(function (value) {
          var shareExist = $scope.selectedOrganizations.find(function (organization) {
            return organization.id === value.organization_id;
          });

          if (!shareExist) {
            $scope.selectedOrganizations.push({
              id: value.organization_id,
              name: value.name
            });
          }
        });
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  });

  if (myDocument.share) {
    var share = myDocument.share;
    $scope.selectedEntityTypes = share.entityTypes.map(function (entityType) {
      return {
        id: entityType.id
      };
    });
    $scope.selectedOrganizations = share.organizations.map(function (organization) {
      return {
        id: organization.id,
        name: organization.morphable.name
      };
    });
  }
  /**
   * Toggle.
   *
   * @param {object} entityType - Entity type to toggle.
   *
   * @returns {Array} The selected entity types
   */


  function toggle(entityType) {
    var exists = $scope.selectedEntityTypes.some(function (item) {
      return item.id === entityType.id;
    });

    if (!exists) {
      return $scope.selectedEntityTypes.push(entityType);
    }

    $scope.selectedEntityTypes = $scope.selectedEntityTypes.filter(function (item) {
      return item.id !== entityType.id;
    });
    return $scope.selectedEntityTypes;
  }
  /**
   * Is checked.
   *
   * @param {object} entityType - Entity type to test.
   *
   * @returns {boolean}
   */


  function isChecked(entityType) {
    return $scope.selectedEntityTypes.some(function (item) {
      return item.id === entityType.id;
    });
  }
  /**
   * Close.
   */


  function close() {
    $mdDialog.hide();
  }
  /**
   * Remove
   *
   * @param {number} index - Index of organization to remove.
   */


  function remove(index) {
    $scope.selectedOrganizations.splice(index, 1);
  }
  /**
   * validate
   */


  function validate() {
    var entityTypeIds = $scope.selectedEntityTypes.map(function (entityType) {
      return entityType.id;
    });
    var organizationIds = $scope.selectedOrganizations.map(function (organization) {
      return organization.id;
    });
    var shareWith = {
      entityTypes: entityTypeIds,
      organizations: organizationIds
    };
    plvsService.share(myDocument.id, shareWith).then(function (share) {
      myDocument.share = share;
      $mdDialog.hide();
    });
  }
}

angular.module("app.generator").controller("editorController", EditorController).controller("plvShareDocumentModalController", PlvShareDocumentModalController).controller("plvDialogProductsController", PlvDialogProductsController).controller("exportDialogController", ExportDialogController);