"use strict";
/**
 * Average shopping cart service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

AverageShoppingCartService.$inject = ["apiClient"];

function AverageShoppingCartService(apiClient) {
  return {
    /**
     * Get.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.post("/analysis/average-shopping-cart", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get by operator.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getByOperator: function getByOperator(params) {
      return apiClient.post("/analysis/average-shopping-cart/operator", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get by operator with prescription.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getByOperatorWithPrescription: function getByOperatorWithPrescription(params) {
      return apiClient.post("/analysis/average-shopping-cart/operatorWithPrescription", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics.averageShoppingCartAnalysis").service("averageShoppingCartService", AverageShoppingCartService);