"use strict";
/**
 * Disruption list controller.
 *
 * @param {object} disruptionService - Disruption service.
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} packListService - Pack list service.
 */

DisruptionListController.$inject = ["disruptionService", "$scope", "$state", "packListService"];

function DisruptionListController(disruptionService, $scope, $state, packListService) {
  $scope.pagination = {
    limit: 10,
    page: 1,
    order: "-_source.datetime"
  };
  $scope.loaderPartnerOptions = {
    loading: true,
    loader: "Chargement des laboratoires partenaires",
    empty: "Aucun laboratoire partenaire"
  };
  $scope.loaderDisruptionsOptions = {
    loading: true,
    loader: "Chargement des ruptures",
    empty: "Aucune rupture"
  };
  $scope.rangeSelected = rangeSelected;
  $scope.seeDisruptions = seeDisruptions;
  loader();
  /**
   * The loading function
   */

  function loader() {
    var rangeDate = {
      startDate: moment().date(1).format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    };
    $scope.dateLabel = moment(rangeDate.startDate).format("DD-MMM-YYYY") + " Au " + moment(rangeDate.endDate).format("DD-MMM-YYYY");
    $scope.loaderDisruptionsOptions.loading = true;
    $scope.loaderPartnerOptions.loading = true;
    getDisruptions();
    getNegativePharmaML(rangeDate);
  }
  /**
   * Get negative pharmaML.
   *
   * @param {object} rangeDate - Range date.
   */


  function getNegativePharmaML(rangeDate) {
    disruptionService.getNegativePharmaML(rangeDate).then(function (negativePharmaML) {
      $scope.negativePharmaMLs = negativePharmaML.hits.hits;
      $scope.loaderDisruptionsOptions.loading = false;
    });
  }
  /**
   * get disruptions
   */


  function getDisruptions() {
    packListService.getAccesses("disruption-apodis").then(function (accesses) {
      $scope.loaderPartnerOptions.loading = false;
      $scope.partnerLaboratories = accesses;
    });
  }
  /**
   * Range selected.
   *
   * @param {object} date - Date.
   */


  function rangeSelected(date) {
    var rangeDate = {
      startDate: moment(date.startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      endDate: moment(date.endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
    };
    getNegativePharmaML(rangeDate);
  }
  /**
   * See Disruption detail.
   *
   * @param {number} id - ID.
   */


  function seeDisruptions(id) {
    $state.go("app.disruption.list.detail.analysis", {
      laboId: id
    });
  }
}

angular.module("app.disruption").controller("disruptionListController", DisruptionListController);