"use strict";
/**
 * The app-evolution-badge directive.
 *
 * @returns {object}
 */

function AppEvolutionBadge() {
  return {
    restrict: "E",
    scope: {
      tooltip: "@tooltip",
      variant: "@variant",
      value: "@value"
    },
    templateUrl: "app/main/directives/app-evolutionBadge/appEvolutionBadge.html",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appEvolutionBadge", AppEvolutionBadge);