"use strict";
/** @ngInject */

/**
 * The substitution service.
 *
 * @param {object} apiClient - The apiClient object.
 * @returns {object}
 */

SubstitutionService.$inject = ["apiClient"];

function SubstitutionService(apiClient) {
  return {
    /**
     * Get substitution with operator details.
     *
     * @returns {Promise}
     */
    getSubstitutionWithOperatorDetail: function getSubstitutionWithOperatorDetail() {
      return apiClient.get("/analysis/substitution/detail-by-operators", {
        wrappers: {
          response: "data"
        }
      });
    }
  };
}

angular.module("app.dashboard").service("substitutionService", SubstitutionService);