"use strict";
/**
 * Laboratory products service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

LaboratoryProductsService.$inject = ["apiClient"];

function LaboratoryProductsService(apiClient) {
  return {
    /**
     * Get top supplying.
     *
     * @param {object} parameters - Parameters.
     * @param {number} parameters.id - Laboratory ID.
     * @param {string} parameters.date - Date.
     *
     * @returns {Promise}
     */
    getTopSupplying: function getTopSupplying(_ref) {
      var id = _ref.id,
          date = _ref.date;
      return apiClient.get("/laboratories/".concat(id, "/products/getTopSupplying/").concat(date), {
        wrappers: {
          response: "aggregations"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get products.
     *
     * @param {object} pagination - Pagination.
     * @param {number} pagination.current_page - Current page.
     * @param {number} pagination.per_page - Elements per page.
     * @param {string} searchTerms - Search terms.
     *
     * @returns {Promise}
     */
    get: function get(_ref2, searchTerms) {
      var page = _ref2.current_page,
          perPage = _ref2.per_page;
      return apiClient.get("/laboratories/products", {
        includes: ["notice", "override"],
        parameters: [{
          name: "page",
          value: page
        }, {
          name: "perPage",
          value: perPage
        }, {
          name: "searchTerms",
          value: searchTerms
        }],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a product.
     *
     * @param {number} productId - Product ID.
     *
     * @returns {Promise}
     */
    read: function read(productId) {
      return apiClient.get("/laboratories/products/".concat(productId), {
        includes: ["delivrance", "genericType", "notice", "override", "refund"],
        wrappers: {
          response: "product"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a product.
     *
     * @param {object} product - Product.
     *
     * @returns {Promise}
     */
    update: function update(product) {
      return apiClient.post("/laboratories/products/".concat(product.productId), product, {
        wrappers: {
          response: "product"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Remove a product.
     *
     * @param {number} productId - Product ID.
     *
     * @returns {Promise}
     */
    "delete": function _delete(productId) {
      return apiClient["delete"]("/laboratories/products/".concat(productId), {
        wrappers: {
          response: "product"
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.laboratory").service("laboratoryProductsService", LaboratoryProductsService);