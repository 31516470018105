"use strict";
/**
 * Controller for wholesaler category list.
 *
 * @param {object} $scope - Scope
 * @param {object} $state - State
 * @param {object} $mdDialog - MD Dialog
 * @param {object} notifyUserService - The notify user service
 * @param {object} environmentService - Environment service
 * @param {object} wholesalerCategoryService - Wholesaler category service
 */

WholesalerCategoryListController.$inject = ["$scope", "$state", "$mdDialog", "notifyUserService", "environmentService", "wholesalerCategoryService"];

function WholesalerCategoryListController($scope, $state, $mdDialog, notifyUserService, environmentService, wholesalerCategoryService) {
  $scope.loaderWholesalerCategoriesOptions = {
    loading: true,
    loader: "Récupération de vos catégories de produits...",
    empty: "Aucune catégorie de produit n'a été créée jusqu'à maintenant"
  };
  $scope.query = {
    order: "name",
    limit: 10,
    page: 1
  };
  $scope.getWholesalerCategories = getWholesalerCategories;
  $scope.createWholesalerCategory = createWholesalerCategory;
  $scope.editWholesalerCategory = editWholesalerCategory;
  $scope.deleteWholesalerCategory = deleteWholesalerCategory;
  loader();
  /**
   * The loader
   *
   * @returns {Promise} The promise
   */

  function loader() {
    return environmentService.getEnvironment().then(function (env) {
      $scope.currentEnv = env;
      getWholesalerCategories();
    });
  }
  /**
   * Get wholesaler categories.
   *
   * @returns {Promise} The promise
   */


  function getWholesalerCategories() {
    $scope.loading = true;
    $scope.loaderWholesalerCategoriesOptions.loading = true;
    return wholesalerCategoryService.get({
      wholesalerId: $scope.currentEnv.entityId
    }).then(function (response) {
      $scope.wholesalerCategories = response;
      $scope.loading = false;
      $scope.loaderWholesalerCategoriesOptions.loading = false;
    });
  }
  /**
   * Open the wholesaler category creation form.
   *
   * @param {event} event - The event
   */


  function createWholesalerCategory(event) {
    event.stopPropagation();
    $state.go("app.wholesaler.categories.new");
  }
  /**
   * Open the wholesaler category modification form.
   *
   * @param {number} wholesalerCategoryId - The wholesaler category ID
   * @param {event} event - The event
   */


  function editWholesalerCategory(wholesalerCategoryId, event) {
    event.stopPropagation();
    $state.go("app.wholesaler.categories.edit", {
      wholesalerId: $scope.currentEnv.entityId,
      id: wholesalerCategoryId
    });
  }
  /**
   * Confirm box for deletion of a wholesaler category.
   *
   * @param {event} event - The event
   * @param {object} wholesalerCategory - The wholesaler category
   */


  $scope.confirmDeleteWholesalerCategory = function (event, wholesalerCategory) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain(e) de vouloir supprimer la catégorie « " + wholesalerCategory.name + " » ?").ariaLabel("suppression de la catégorie").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      deleteWholesalerCategory(wholesalerCategory);
    });
  };
  /**
   * Delete a wholesaler category
   *
   * @param {object} wholesalerCategory - The wholesaler category
   *
   * @returns {Promise} The promise
   */


  function deleteWholesalerCategory(wholesalerCategory) {
    return wholesalerCategoryService["delete"](wholesalerCategory).then(function () {
      notifyUserService.showSuccessToast("La catégorie a été correctement supprimée.");
      getWholesalerCategories();
    });
  }
}

angular.module("wholesaler.category").controller("wholesalerCategoryListController", WholesalerCategoryListController);