"use strict";
/**
 * Dashboard pharmacies sellout prestation events month controller.
 *
 * @param {object} $scope - $scope.
 */

DashboardPharmaciesSelloutPrestationsEventsMonthController.$inject = ["$scope"];

function DashboardPharmaciesSelloutPrestationsEventsMonthController($scope) {
  $scope.options = {
    type: "sellout-participation,prestation-participation",
    start: moment().format("YYYY-MM-DD"),
    end: moment().add("months", 1).format("YYYY-MM-DD")
  };
}

angular.module("app.dashboard").controller("dashboardPharmaciesSelloutPrestationsEventsMonthController", DashboardPharmaciesSelloutPrestationsEventsMonthController);