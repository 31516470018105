"use strict";
/**
 * The cart service.
 *
 * @param {object} hdsApiClient - The HDS API Client service
 *
 * @returns {object}
 */

PatientCartService.$inject = ["hdsApiClient"];

function PatientCartService(hdsApiClient) {
  return {
    /**
     * Gets a customer's cart content.
     *
     * @param {number} customerId - The costumer id.
     * @param {number} cartId - The cart id.
     *
     * @returns {Promise}
     */
    readCart: function readCart(customerId, cartId) {
      return hdsApiClient("GET", "/customers/".concat(customerId, "/carts/").concat(cartId, "/sales")).then(function (response) {
        return response.data.data;
      });
    }
  };
}

angular.module("app.patients.services").service("patientCartService", PatientCartService);