"use strict";
/**
 * Patient pathology details controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} patientPathologyService - The patient pathology service.
 */

PatientPathologyDetailsController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "patientPathologyService"];

function PatientPathologyDetailsController($scope, $state, $stateParams, $mdDialog, patientPathologyService) {
  $scope.pathologyDetailsTitle = {
    title: {
      text: "Mes conseils",
      back: {
        state: null
      }
    }
  };
  $scope.redirectToEntry = redirectToEntry;
  $scope.loading = true;
  $scope.pathology = null;
  $scope.customersPathology = [];
  $scope.openRemoveCustomerModal = openRemoveCustomerModal;
  $scope.openAddCustomerModal = openAddCustomerModal;
  loader();
  /**
   * Loader function.
   */

  function loader() {
    Promise.all([readPathology(), indexCustomersPathology()])["finally"](function () {
      $scope.loading = false;
      $scope.$apply();
    });
  }
  /**
   * Read the pathology.
   *
   * @returns {Promise}
   */


  function readPathology() {
    return patientPathologyService.readPathology($stateParams.pathologyId).then(function (response) {
      $scope.pathology = response;
    });
  }
  /**
   * Get customers related to the pathology.
   *
   * @returns {Promise}
   */


  function indexCustomersPathology() {
    return patientPathologyService.getCustomersForPathology($stateParams.pathologyId).then(function (response) {
      $scope.customersPathology = response;
    });
  }
  /**
   * Redirect to the entry details.
   *
   * @param {object} entry - The entry
   */


  function redirectToEntry(entry) {
    $state.go("app.patients.pathologies.details.entries", {
      entryId: entry.id
    });
  }
  /**
   * Open the customer modal.
   */


  function openRemoveCustomerModal() {
    $mdDialog.show({
      controller: "patientPathologyRemoveCustomerModalController",
      locals: {
        customers: $scope.customersPathology,
        callback: indexCustomersPathology
      },
      templateUrl: "app/main/patients/views/pathologies/pathology-details/modals/pathology-customers/remove/patients.pathologyCustomerModal.remove.html",
      targetEvent: event,
      clickOutsideToClose: true,
      multiple: true,
      skipHide: true
    });
  }
  /**
   * Open the add customer modal.
   */


  function openAddCustomerModal() {
    $mdDialog.show({
      controller: "patientPathologyAddCustomerModalController",
      locals: {
        customers: $scope.customersPathology,
        callback: indexCustomersPathology
      },
      templateUrl: "app/main/patients/views/pathologies/pathology-details/modals/pathology-customers/add/patients.pathologyCustomerModal.add.html",
      targetEvent: event,
      clickOutsideToClose: true,
      multiple: true,
      skipHide: true
    });
  }
}

angular.module("app.patients").controller("patientPathologyDetailsController", PatientPathologyDetailsController);