"use strict";
/**
 * Dashboard configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

DashboardConfig.$inject = ["$stateProvider"];

function DashboardConfig($stateProvider) {
  $stateProvider.state("app.dashboard", {
    url: "/:brickSlug/dashboard",
    views: {
      "content@app": {
        templateUrl: "app/main/dashboard/view/dashboard.html",
        controller: "dashboardController"
      }
    }
  });
}

angular.module("app.dashboard", []).config(DashboardConfig);