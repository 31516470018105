"use strict";
/**
 * The app-prism directive.
 *
 * @returns {object}
 */

function AppPrism() {
  return {
    restrict: "E",
    template: "<div class='app-prism'><pre class='no-margin'><code ng-transclude></code></pre></div>",
    replace: true,
    transclude: true
  };
}

angular.module("app.directives").directive("appPrism", AppPrism);