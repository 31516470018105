"use strict";
/**
 * Dashboard laboratories sales figures controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} dashboardAnalysisService - Dashboard analysis service.
 */

DashboardLaboratoriesSalesFiguresController.$inject = ["$scope", "dashboardAnalysisService"];

function DashboardLaboratoriesSalesFiguresController($scope, dashboardAnalysisService) {
  $scope.sales = {};
  $scope.healthcenterCount = 0;
  getSales();
  /**
   * Get sales.
   */

  function getSales() {
    $scope.sales.today = {};
    $scope.sales.month = {};
    $scope.sales.year = {};
    $scope.sales.todayLastYear = {};
    $scope.sales.monthLastYear = {};
    $scope.sales.yearLastYear = {};
    dashboardAnalysisService.getSalesFigures().then(function (response) {
      var salesData = response.sales.responses;
      $scope.healthcenterCount = salesData[2].aggregations.healthcenterQuantity.value;
      $scope.sales.today.caHt = salesData[0].aggregations.amount_excl_tax.value;
      $scope.sales.today.unit = salesData[0].aggregations.quantity.value;
      $scope.sales.month.caHt = salesData[1].aggregations.amount_excl_tax.value;
      $scope.sales.month.unit = salesData[1].aggregations.quantity.value;
      $scope.sales.year.caHt = salesData[2].aggregations.amount_excl_tax.value;
      $scope.sales.year.unit = salesData[2].aggregations.quantity.value;
      $scope.sales.todayLastYear.caHt = salesData[3].aggregations.amount_excl_tax.value;
      $scope.sales.todayLastYear.unit = salesData[3].aggregations.quantity.value;
      $scope.sales.monthLastYear.caHt = salesData[4].aggregations.amount_excl_tax.value;
      $scope.sales.monthLastYear.unit = salesData[4].aggregations.quantity.value;
      $scope.sales.yearLastYear.caHt = salesData[5].aggregations.amount_excl_tax.value;
      $scope.sales.yearLastYear.unit = salesData[5].aggregations.quantity.value;
    });
  }
}

angular.module("app.dashboard").controller("dashboardLaboratoriesSalesFiguresController", DashboardLaboratoriesSalesFiguresController);