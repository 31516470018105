"use strict";
/**
 * Prestation detail controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} prestationService - The prestation service.
 */

PrestationDetailController.$inject = ["$scope", "$state", "$stateParams", "prestationService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function PrestationDetailController($scope, $state, $stateParams, prestationService) {
  $scope.goToPhoto = goToPhoto;
  $scope.goToParticipation = goToParticipation;
  prestationService.read($stateParams.id).then(function (prestation) {
    createPhotoMap(prestation.user_prestation_photos);
    $scope.prestation = prestation;
  });
  /**
   * Go to participation.
   *
   * @param {object} filters - The filters object.
   */

  function goToParticipation(filters) {
    $state.go("app.prestation.participation", _objectSpread({
      type: "healthcenter",
      prestation_id: $scope.prestation.id
    }, filters));
  }
  /**
   * Go to photo.
   *
   * @param {object} filters - The filters object.
   */


  function goToPhoto(filters) {
    $state.go("app.prestation.photo", _objectSpread({
      id: $scope.prestation.id
    }, filters));
  }
  /**
   * Create the photos map.
   *
   * @param {Array} photos - The photo container.
   */


  function createPhotoMap(photos) {
    var map = new google.maps.Map(document.getElementById("map"), {
      center: {
        lat: 46.52863469527167,
        lng: 2.43896484375
      },
      zoom: 6
    });
    angular.forEach(photos, function (photo) {
      var markerSize = 50;
      var marker = new google.maps.Marker({
        position: {
          lat: photo.latitude,
          lng: photo.longitude
        },
        icon: {
          url: photo.full_path + "&w=".concat(markerSize, "&h=").concat(markerSize, "&fit=crop"),
          size: new google.maps.Size(markerSize, markerSize)
        }
      });
      marker.setMap(map);
    });
  }
}

angular.module("app.prestation").controller("PrestationDetailController", PrestationDetailController);