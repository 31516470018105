"use strict";
/**
 * The app-modal-title directive.
 *
 * @returns {object}
 */

AppModalTitleController.$inject = ["$scope"];

function AppModalTitle() {
  return {
    restrict: "EA",
    scope: {
      icon: "@icon",
      variant: "@variant",
      close: "=close"
    },
    templateUrl: "app/main/directives/app-modal/app-modal-title/appModalTitle.html",
    replace: true,
    transclude: true,
    controllerAs: "vm",
    controller: AppModalTitleController
  };
}
/**
 * App modal title controller.
 *
 * @param {object} $scope - $scope.
 */


function AppModalTitleController($scope) {
  $scope.closeIt = closeIt;
  /**
   * Close it.
   */

  function closeIt() {
    $scope.close();
  }
}

angular.module("app.directives").directive("appModalTitle", AppModalTitle);