"use strict";
/**
 * Wholesaler catalog service.
 *
 * @param {object} apiClient - The api client service
 *
 * @returns {object} List of methods
 */

WholesalerCatalogService.$inject = ["apiClient"];

function WholesalerCatalogService(apiClient) {
  return {
    /**
     * Get wholesaler catalogs.
     *
     * @param {object} data - The wholesaler
     *
     * @returns {Promise} The promise
     */
    getWholesalerCatalogs: function getWholesalerCatalogs(data) {
      return apiClient.get("/wholesalers/".concat(data.wholesalerId, "/catalogs")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create catalog.
     *
     * @param {object} data - The wholesaler
     *
     * @returns {Promise} The promise
     */
    createCatalog: function createCatalog(data) {
      return apiClient.post("/wholesalers/".concat(data.wholesalerId, "/catalogs"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read catalog.
     *
     * @param {object} data - The catalog
     *
     * @returns {Promise} The promise
     */
    readCatalog: function readCatalog(data) {
      return apiClient.get("/wholesalers/".concat(data.wholesalerId, "/catalogs/").concat(data.id)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update catalog.
     *
     * @param {object} data - The catalog
     *
     * @returns {Promise} The promise
     */
    updateCatalog: function updateCatalog(data) {
      return apiClient.put("/wholesalers/".concat(data.wholesalerId, "/catalogs/").concat(data.id), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get wholesaler catalog laboratories.
     *
     * @param {object} data - The catalog
     *
     * @returns {Promise} The promise
     */
    getCatalogLaboratories: function getCatalogLaboratories(data) {
      return apiClient.get("/wholesalers/".concat(data.wholesalerId, "/catalogs/").concat(data.id, "/laboratories")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Wholesaler catalog filtered and paginated.
     *
     * @param {object} data - Post data for filter
     * @param {object} queryFilter - Query filter for pagination
     *
     * @returns {Promise} The promise
     */
    getFilteredCatalog: function getFilteredCatalog(data, queryFilter) {
      var query = {
        parameters: [{
          name: "page",
          value: queryFilter.current_page
        }, {
          name: "perPage",
          value: queryFilter.per_page
        }, {
          name: "order",
          value: queryFilter.order
        }, {
          name: "orderDirection",
          value: queryFilter.orderDirection
        }],
        wrappers: {
          response: null
        }
      };
      var uri = "/wholesalers/".concat(data.wholesalerId, "/catalogs/").concat(data.id, "/products/filtered");
      return apiClient.post(uri, {
        data: data
      }, query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the price lists.
     *
     * @param {object} data - The wholesaler catalog
     *
     * @returns {Promise} The promise
     */
    getPriceLists: function getPriceLists(data) {
      return apiClient.get("/wholesalers/".concat(data.wholesalerId, "/catalogs/").concat(data.id, "/priceLists")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create price list.
     *
     * @param {object} data - The wholesaler catalog
     *
     * @returns {Promise} The promise
     */
    createPriceList: function createPriceList(data) {
      var uri = "/wholesalers/".concat(data.wholesalerId, "/catalogs/").concat(data.wholesalerCatalogId, "/priceLists");
      return apiClient.post(uri, {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a price list.
     *
     * @param {object} data - The wholesaler price list
     *
     * @returns {Promise} The promise
     */
    readPriceList: function readPriceList(data) {
      var uri = "/wholesalers/".concat(data.wholesalerId, "/catalogs/") + "".concat(data.wholesalerCatalogId, "/priceLists/").concat(data.id);
      return apiClient.get(uri).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update price list.
     *
     * @param {object} data - The wholesaler price list
     *
     * @returns {Promise} The promise
     */
    updatePriceList: function updatePriceList(data) {
      var uri = "/wholesalers/".concat(data.wholesalerId, "/catalogs/") + "".concat(data.wholesalerCatalogId, "/priceLists/").concat(data.id);
      return apiClient.put(uri, {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a price list.
     *
     * @param {object} data - The wholesaler price list
     *
     * @returns {Promise} The promise
     */
    deletePriceList: function deletePriceList(data) {
      var uri = "/wholesalers/".concat(data.wholesalerId, "/catalogs/") + "".concat(data.wholesalerCatalogId, "/priceLists/").concat(data.id);
      return apiClient["delete"](uri).then(function (response) {
        return response.data;
      });
    },

    /**
     * Wholesaler products needs.
     *
     * @param {object} data - The wholesaler
     *
     * @returns {Promise} The promise
     */
    getProductsNeeds: function getProductsNeeds(data) {
      return apiClient.post("/wholesalers/".concat(data.id, "/productsNeeds"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Add products to the wholesaler catalog.
     *
     * @param {object} data - The wholesaler catalog
     *
     * @returns {Promise} The promise
     */
    addProducts: function addProducts(data) {
      var uri = "/wholesalers/".concat(data.wholesalerId, "/catalogs/").concat(data.id, "/products/multiple");
      return apiClient.post(uri, {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Save many product prices.
     *
     * @param {object} data - Product prices data
     *
     * @returns {Promise} The promise
     */
    saveMany: function saveMany(data) {
      var uri = "/wholesalers/".concat(data.wholesalerId, "/catalogs/").concat(data.id, "/productPrices/many");
      return apiClient.post(uri, {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search for wholesaler products.
     *
     * @param {object} data - The filter data
     *
     * @returns {Promise} The promise
     */
    searchForWholesalerProducts: function searchForWholesalerProducts(data) {
      return apiClient.post("/wholesaler/products/filter", {
        data: data
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("wholesaler.catalog").service("wholesalerCatalogService", WholesalerCatalogService);