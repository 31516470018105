"use strict";
/**
 * Dashboard laboratories commercial offer push controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 */

DashboardLaboratoriesCommercialOfferPushController.$inject = ["$scope", "$state"];

function DashboardLaboratoriesCommercialOfferPushController($scope, $state) {
  $scope.goToCommercialOffer = function () {
    $state.go("app.commercialOffer");
  };
}

angular.module("app.dashboard").controller("dashboardLaboratoriesCommercialOfferPushController", DashboardLaboratoriesCommercialOfferPushController);