"use strict";
/**
 * Dashboard laboratories prestations controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {string} apiurl - API URL.
 * @param {object} prestationService - Prestation service.
 */

DashboardLaboratoriesPrestationsController.$inject = ["$scope", "$state", "apiurl", "prestationService"];

function DashboardLaboratoriesPrestationsController($scope, $state, apiurl, prestationService) {
  $scope.loading = true;
  $scope.goToDetail = goToDetail;
  $scope.imageFallback = "assets/images/sellouts/default-challenge-1080x600.jpg";
  $scope.apiurl = apiurl;
  $scope.token = window.sessionStorage.token;
  loader();
  /**
   *
   * loader
   * to load each method inside
   */

  function loader() {
    getPrestations();
  }
  /**
   * Get prestations
   */


  function getPrestations() {
    prestationService.get().then(function (response) {
      $scope.prestations = updatePrestationForDisplay(response.data);
    });
  }
  /**
   * update prestations for display
   *
   * @param {Array} list - List.
   *
   * @returns {Array}
   */


  function updatePrestationForDisplay(list) {
    var today = new Date();

    if (!list) {
      return list;
    }

    list.map(function (currentValue) {
      var start = new Date(currentValue.startDatetime);
      var end = new Date(currentValue.endDatetime);

      if (today < start) {
        currentValue.status = "coming";
      } else if (today >= start && today <= end) {
        currentValue.status = "ongoing";
      } else if (today > end) {
        currentValue.status = "finished";
      }

      currentValue.image = currentValue.imagePath ? $scope.apiurl + "/images/" + currentValue.imagePath + "?w=160&h=90&fit=crop&sharp=10&token=" + $scope.token : $scope.imageFallback;
      return currentValue;
    });
    $scope.loading = false;
    return list;
  }
  /**
   * Go to detail.
   *
   * @param {number} id - ID.
   */


  function goToDetail(id) {
    $state.go("app.prestation.detail", {
      id: id
    });
  }
}

angular.module("app.dashboard").controller("dashboardLaboratoriesPrestationsController", DashboardLaboratoriesPrestationsController);