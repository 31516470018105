"use strict";
/**
 * Healthcenter service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

var _excluded = ["includes"];
HealthcenterService.$inject = ["apiClient"];

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function HealthcenterService(apiClient) {
  return {
    /**
     * Get healthcenters by geo.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getHealthcentersByGeo: function getHealthcentersByGeo(params) {
      return apiClient.post("/healthcenters/geo/search", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get healthcenters by id.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getHealthcentersById: function getHealthcentersById(params) {
      return apiClient.post("/healthcenters/getHealthcentersById", params, {
        includes: ["holder.user", "grouping"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.healthcenters;
      });
    },

    /**
     * Get healthcenters.
     *
     * @param {object} params -
     * @param {object} params.includes -
     * @param {object} params.params -
     * @returns {Promise}
     */
    getHealthcenters: function getHealthcenters(_ref) {
      var includes = _ref.includes,
          params = _objectWithoutProperties(_ref, _excluded);

      var parameters = Object.keys(params).filter(function (param) {
        return params[param];
      }).map(function (param) {
        if (Array.isArray(params[param])) {
          var name = "".concat(param, "[]");
          return params[param].map(function (value) {
            return {
              name: name,
              value: value
            };
          });
        }

        return {
          name: param,
          value: params[param]
        };
      }).flat();
      return apiClient.get("/healthcenters", {
        includes: includes,
        parameters: parameters
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get healthcenters services.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getHealthcentersServices: function getHealthcentersServices(params) {
      return apiClient.get("/healthcenters/service", {
        parameters: params,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get details.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getDetails: function getDetails(params) {
      return apiClient.get("/healthcenters/".concat(params.id), {
        includes: ["address", "grouping", "opening"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update details.
     *
     * @param {number} healthcenterId -
     * @param {object} data -
     * @returns {Promise}
     */
    updateDetails: function updateDetails(healthcenterId, data) {
      return apiClient.put("/healthcenters/".concat(healthcenterId), data).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get infos.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getInfos: function getInfos(params) {
      return apiClient.get("/healthcenters/infos/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/healthcenters/infos/".concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get smoothed degressive margin for a period.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getSmoothedDegressiveMarginForAPeriod: function getSmoothedDegressiveMarginForAPeriod(params) {
      return apiClient.post("/mdl/getSmoothedDegressiveMargin", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get smoothed degressive margin estimated.
     *
     * @returns {Promise}
     */
    getSmoothedDegressiveMarginEstimated: function getSmoothedDegressiveMarginEstimated() {
      return apiClient.get("/mdl/getEstimatedSmoothedDegressiveMargin", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get healthcenter opening hours.
     *
     * @param {number} healthcenterId - The healthcenter id.
     *
     * @returns {Promise}
     */
    getOpening: function getOpening(healthcenterId) {
      return apiClient.get("/healthcenters/".concat(healthcenterId, "/opening"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Set opening.
     *
     * @param {number} healthcenterId -
     * @param {object} data -
     * @returns {Promise}
     */
    setOpening: function setOpening(healthcenterId, data) {
      return apiClient.post("/healthcenters/".concat(healthcenterId, "/opening"), data, {
        wrappers: {
          response: null
        }
      });
    },

    /**
     * Update opening.
     *
     * @param {number} openingHourId - The opening hour id.
     * @param {object} data - The data.
     * @returns {Promise}
     */
    updateOpening: function updateOpening(openingHourId, data) {
      return apiClient.put("/healthcenters/opening/".concat(openingHourId), data).then(function (response) {
        return response.data;
      });
    },

    /**
     * Affect LGO.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    affectLgo: function affectLgo(params) {
      return apiClient.post("/healthcenter/".concat(params.id, "/lgo/").concat(params.lgo), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Post a media.
     *
     * @param {number} healthcenterId - The healthcenter id.
     * @param {object} data - The data to post.
     *
     * @returns {Promise}
     */
    postMedia: function postMedia(healthcenterId, data) {
      return apiClient.post("/healthcenters/".concat(healthcenterId, "/medias"), data).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a healthcenter media.
     *
     * @param {number} mediaId - The healthcenter media id.
     *
     * @returns {Promise}
     */
    deleteMedia: function deleteMedia(mediaId) {
      return apiClient["delete"]("/healthcenters/medias/".concat(mediaId)).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.healthcenters").service("healthcenterService", HealthcenterService);