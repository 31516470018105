"use strict";
/**
 * Grouping wholesaler purchases analysis controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} wholesalerPurchasesAnalysisService -
 *      Wholesaler purchases analysis service.
 */

GroupingWholesalerPurchasesAnalysisController.$inject = ["$scope", "wholesalerPurchasesAnalysisService"];

function GroupingWholesalerPurchasesAnalysisController($scope, wholesalerPurchasesAnalysisService) {
  $scope.loaderMembers = {
    loading: true,
    loader: "Récupération des adhérents du groupements...",
    empty: "Vous n'avez aucun adhérent."
  };
  var AUDIT_WHOLESALER_PACK_SLUG = "wholesaler-audit";
  $scope.query = {
    order: "-datetime",
    limit: 10,
    page: 1
  };
  $scope.limitOptions = [5, 10, 15];
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.wholesalerPurchasesAnalysis = {
    title: {
      icon: "icon-chart-bar",
      text: "Analyses achats grossistes"
    }
  };
  /**
   * Loader.
   */

  function loader() {
    wholesalerPurchasesAnalysisService.getMemberStatus().then(function (members) {
      $scope.members = members.map(function (member) {
        var pack = _.find(member.organization.packSubscriptions, function (packSubscription) {
          return packSubscription.pack.slug === AUDIT_WHOLESALER_PACK_SLUG;
        });

        if (pack) {
          member.moduleActive = true;
          member.activationDate = new Date(pack.created_at.date);
        }

        return member;
      });
      $scope.loaderMembers.loading = false;
    });
  }

  loader();
}

angular.module("app.statistics.salesAnalysis").controller("groupingWholesalerPurchasesAnalysisController", GroupingWholesalerPurchasesAnalysisController);