"use strict";
/**
 * Users transactions controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} usersTransactionsService - Users transactions service.
 */

UserTransactionsController.$inject = ["$scope", "usersTransactionsService"];

function UserTransactionsController($scope, usersTransactionsService) {
  $scope.loading = true;
  $scope.query = {
    order: "name",
    limit: 10,
    page: 1
  };
  usersTransactionsService.getHistoric().then(function (response) {
    $scope.transactions = response.transactions;
    $scope.loading = false;
  });
}

angular.module("app.users-transactions").controller("UserTransactionsController", UserTransactionsController);