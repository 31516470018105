"use strict";
/**
 * Challenge sellout list controller.
 *
 * @param {object} $state - $state.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $scope - $scope.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} selloutService - Sellout service
 */

ChallengeSelloutListController.$inject = ["$state", "$mdDialog", "$scope", "notifyUserService", "selloutService"];

function ChallengeSelloutListController($state, $mdDialog, $scope, notifyUserService, selloutService) {
  var vm = this;
  vm.challenges = [];
  vm.getChallenges = getChallenges();
  vm.addChallenge = addChallenge;
  vm.editChallenge = editChallenge;
  vm.deleteChallenge = deleteChallenge;
  vm.publishChallenge = publishChallenge;
  vm.goToStatisticsPage = goToStatisticsPage;
  vm.duplicateChallenge = duplicateChallenge;
  /**
   * Get challenges
   *
   * @returns {Promise}
   */

  function getChallenges() {
    vm.loading = true;
    return selloutService.get().then(function (response) {
      vm.challenges = updateChallengeForDisplay(response);
      vm.loading = false;
    });
  }
  /**
   * Update challenges for display
   *
   * @param {Array} list -
   * @returns {Array}
   */


  function updateChallengeForDisplay(list) {
    var today = new Date();
    list.forEach(function (currentValue) {
      var start = new Date(currentValue.startDate);
      var end = new Date(currentValue.endDate);

      if (today < start) {
        currentValue.status = "coming";
      } else if (today >= start && today <= end) {
        currentValue.status = "ongoing";
      } else if (today > end) {
        currentValue.status = "finished";
      }

      currentValue.startDate = new Date(currentValue.startDate);
      currentValue.endDate = new Date(currentValue.endDate);
    });
    return list;
  }
  /**
   * Add challenge
   */


  function addChallenge() {
    $state.go("app.sellout.new");
  }
  /**
   * Edit challenge
   *
   * @param {object} event -
   * @param {number} challengeId - Sellout ID
   */


  function editChallenge(event, challengeId) {
    event.stopPropagation();
    $state.go("app.sellout.edit", {
      id: challengeId
    });
  }
  /**
   * Duplicate challenge.
   *
   * @param {object} event -
   * @param {number} index -
   * @param {object} challenge -
   */


  function duplicateChallenge(event, index, challenge) {
    var confirm = $mdDialog.confirm().title("Duplication").textContent("Êtes-vous certain de vouloir dupliquer le challenge « " + challenge.name + " » ?").ariaLabel("Dupliquer le challenge").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      return selloutService.duplicate(challenge.id);
    }).then(function (sellout) {
      return vm.challenges.splice(index, 0, sellout);
    });
  }
  /**
   * Delete challenge
   *
   * @param {object} event -
   * @param {object} challenge - Sellout
   */


  function deleteChallenge(event, challenge) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain de vouloir supprimer le challenge « " + challenge.name + " » ?").ariaLabel("suppression challenge").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      return selloutService["delete"](challenge.id);
    }).then(function () {
      return notifyUserService.showSuccessToast("Le challenge est supprimé");
    }).then(getChallenges);
  }
  /**
   * Publish challenge
   *
   * @param {object} event -
   * @param {object} challenge - Sellout
   */


  function publishChallenge(event, challenge) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Publication").textContent("Êtes-vous certain de vouloir publier le challenge « " + challenge.name + " » ?").ariaLabel("publication challenge").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      return selloutService.publish(challenge.id);
    }).then(function () {
      return notifyUserService.showSuccessToast("Le challenge est publié");
    }).then(getChallenges);
  }
  /**
   * Goto report page
   *
   * @param {object} event -
   * @param {object} challenge -
   */


  function goToStatisticsPage(event, challenge) {
    $state.go("app.sellout.report", {
      id: challenge.id
    });
  }
  /**
   * Order && filter the challenge
   */


  $scope.selected = [];
  $scope.limitOptions = [5, 10, 15];
  $scope.query = {
    order: "-createdAt",
    limit: 15,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.filters = [];
  $scope.$watch("filter.search", function (newValue) {
    if (typeof newValue !== "undefined") {
      $scope.filters = newValue.split(" ");
    }
  });
}

angular.module("app.sellout").controller("ChallengeSelloutListController", ChallengeSelloutListController);