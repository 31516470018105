"use strict";
/**
 * Healthcenter audit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $compile - $compile.
 * @param {object} $state - $state.
 * @param {object} $q - $q.
 * @param {object} healthCenterAuditGeolocService - Healthcenter audit geoloc service.
 * @param {object} gmap - gmap.
 * @param {object} environmentService - Environment service.
 * @param {object} groupingsMarkersLogos - Groupings markers logos.
 */

HealthcenterAuditController.$inject = ["$scope", "$compile", "$state", "$q", "healthCenterAuditGeolocService", "gmap", "environmentService", "groupingsMarkersLogos"];

function HealthcenterAuditController($scope, $compile, $state, $q, healthCenterAuditGeolocService, gmap, environmentService, groupingsMarkersLogos) {
  /**
   * Declaration of public methods
   */
  $scope.showAuditHealthcenter = showAuditHealthcenter;
  $scope.deleteMarkers = deleteMarkers;
  $scope.showMarkers = showMarkers;
  /**
   * Declaration of public objects
   */

  var markers = [];
  $scope.configHealthcenterAudit = {
    event: "healthcenterAuditChange",
    search: ["geo.region.id", "geo.departement.number", "geo.uga", "group.id", "idOrgas"],
    tag: "healthcenterAuditChange",
    geoForce: true,
    searchPlaceHolder: "Rechercher une pharmacie..."
  };
  $scope.mainTilte = {
    title: {
      icon: "icon-map-marker-multiple",
      text: "Audit pharmacie"
    }
  };
  $scope.mapTitle = {
    icon: "icon-crosshairs-gps",
    text: "Position géographique des Pharmacies",
    type: "2"
  };
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * Loader.
   */

  function loader() {
    initMap().then(getEnvironment).then(function (response) {
      var queryPharma = {};

      if (response.accessPointType === 3) {
        queryPharma.type = {};
        queryPharma.type.groups = [response.entityId];
      }

      queryPharma.full = true;
      return queryPharma;
    }).then(loadPharmas).then(processMarkers);
  }
  /**
   * getEnvironment
   *
   * @returns {Promise}
   */


  function getEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentEnv = response;
      return response;
    });
  }
  /**
   * Initialize map
   *
   * @returns {Promise}
   */


  function initMap() {
    return $q(function (resolve) {
      var myLatLng = {
        lat: 47.0833300,
        lng: 2.4000000
      };
      var mapOptions = {
        center: new google.maps.LatLng(myLatLng),
        zoom: 6,
        minZoom: 5,
        draggable: true,
        gestureHandling: "cooperative",
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: true,
        disableDefaultUI: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.LEFT_TOP
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        fullscreenControl: false,
        styles: gmap
      };
      $scope.map = new google.maps.Map(document.getElementById("map-healthcenter-audit"), mapOptions);
      resolve($scope.map);
    });
  }
  /**
   * deleteMarkers
   * for each marker set map 'null'
   */


  function deleteMarkers() {
    markers.forEach(function (marker) {
      marker.setMap(null);
    });
  }
  /**
   * showMarkers
   * for each marker set map $scope.map
   */


  function showMarkers() {
    $scope.markers.forEach(function (marker) {
      marker.setMap($scope.map);
    });
  }
  /**
   * loadPharmas
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function loadPharmas(query) {
    return healthCenterAuditGeolocService.pharmasByGeo(query).then(function (response) {
      $scope.pharmacies = response;
      return response;
    });
  }
  /**
   * processMarkers
   * generate content marker for each pharmacy
   *
   * @param {object} pharmacies - Pharmacies.
   * @param {string} type - Type.
   *
   * @returns {Array}
   */


  function processMarkers(pharmacies, type) {
    markers = [];
    $scope.markers = [];
    $scope.bounds = new google.maps.LatLngBounds();
    var pharmacyOwner = "";
    var pharmacyGrouping = "";
    angular.forEach(pharmacies, function (pharma) {
      var latLng = new google.maps.LatLng(pharma._source.geo.location.lat, pharma._source.geo.location.lon);
      var grouping = groupingsMarkersLogos["default"].filter(function (grouping) {
        return pharma._source.group.length ? grouping.id === pharma._source.group[0].id : false;
      });
      var marker = generateMarker(latLng, grouping, type);
      var infowindow = new google.maps.InfoWindow({});
      marker.addListener("click", function () {
        // Get the pharmacy owner
        pharmacyOwner = "";
        angular.forEach(pharma._source.user, function (pharmacyUser) {
          // If rank id of the ower
          if (pharmacyUser.rank_id === 1) {
            pharmacyOwner = pharmacyUser.full_name;
          }
        }); // Get the pharmacy grouping

        pharmacyGrouping = "inconnu / aucun";

        if (pharma._source.group.length > 0 && pharma._source.group[0]) {
          pharmacyGrouping = pharma._source.group[0].name;
        }

        var content = "<div><h2>" + pharma._source.name + " (" + pharma._source.cip + ")</h2>" + "<div class=\"pharmacyAddress\">" + pharma._source.geo.city.address1 + " " + pharma._source.geo.city.address2 + "<br>" + pharma._source.geo.city.postal_code + " " + pharma._source.geo.city.name + "</div>" + "<div class=\"pharmacyOwner\">Titulaire : " + pharmacyOwner + "</div>" + "<div class=\"pharmacyGrouping\">Groupement : " + pharmacyGrouping + "</div>" + "<md-button class=\"md-accent-bg centered\" ng-click=\"showAuditHealthcenter('" + pharma._source.id + "')\">Auditer cette pharmacie</md-button></div>";
        var compiled = $compile(content)($scope);
        $scope.$apply();
        infowindow.setContent(compiled[0]);
        infowindow.open($scope.map, marker);
      });
      $scope.bounds.extend(latLng); // eslint-disable-next-line no-invalid-this

      this.push(marker);
    }, markers);
    $scope.markers = markers;
    return markers;
  }
  /**
   * generateMarker
   * generate a marker with latLng, grouping, type
   *
   * @param {object} latLng - Lat lng.
   * @param {object} grouping - Grouping.
   * @param {string} type - Type.
   *
   * @returns {object} google.maps.Marker
   */


  function generateMarker(latLng, grouping, type) {
    var anchor;
    var size;
    var scaledSize;

    if (!type) {
      anchor = new google.maps.Point(32, 64);
      size = new google.maps.Size(64, 64);
      scaledSize = new google.maps.Size(64, 64);
    }

    if (type === "mini") {
      anchor = new google.maps.Point(16, 32);
      size = new google.maps.Size(32, 32);
      scaledSize = new google.maps.Size(32, 32);
    }

    return new google.maps.Marker({
      position: latLng,
      map: $scope.map,
      icon: {
        url: grouping[0] ? grouping[0].logo : groupingsMarkersLogos.apodis["default"],
        anchor: anchor,
        size: size,
        scaledSize: scaledSize
      }
    });
  }
  /**
   * showAuditHealthcenter
   * go to state detail of pharmacy.id
   *
   * @param {number} pharmacyId - Pharmacy ID.
   */


  function showAuditHealthcenter(pharmacyId) {
    $state.go("app.healthcenterAudit.detail.general", {
      id: pharmacyId
    });
  }
  /**
   * healthcenterAuditChange event listner
   * to reload all markers
   */


  $scope.$on("healthcenterAuditChange", function (event, query) {
    $scope.directAudit = false;
    var queryPharma = {};
    queryPharma.type = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(query, type)) {
        if (type !== "pharmas") {
          $scope.directAudit = false;
        }

        if (query.pharmas && query.pharmas.length === 1) {
          $scope.directAudit = true;
        }

        if (typeof queryPharma.type[type] === "undefined") {
          queryPharma.type[type] = [];
        }

        angular.forEach(query[type], function (obj) {
          if (type === "uga") {
            queryPharma.type[type].push(obj.name);
          } else {
            queryPharma.type[type].push(obj.id);
          }
        });
      }
    };

    for (var type in query) {
      _loop(type);
    }

    if ($scope.directAudit) {
      $scope.showAuditHealthcenter(query.pharmas[0].id);
    } else {
      queryPharma.full = true;
      loadPharmas(queryPharma).then(function (response) {
        deleteMarkers();
        processMarkers(response);
      });
    }
  });
}

angular.module("app.healthcenterAudit").controller("HealthcenterAuditController", HealthcenterAuditController);