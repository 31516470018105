"use strict";
/**
 * Statistics real marge configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

StatisticsRealMargeConfig.$inject = ["$stateProvider"];

function StatisticsRealMargeConfig($stateProvider) {
  $stateProvider.state("app.statistics.wholesalerAudit.real-marge", {
    url: "/analyse-marge",
    views: {
      "tabContent@app.statistics.wholesalerAudit": {
        templateUrl: "app/main/statistics/marge/real-marge/views/realMarge.html",
        controller: "RealMargeController as vm"
      }
    },
    bodyClass: "dashboard"
  });
}

angular.module("app.statistics.realMarge", []).config(StatisticsRealMargeConfig);