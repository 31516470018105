"use strict";
/**
 * The controller for the patient count card, in pharmacy dashboard.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} customerService - The customer service.
 */

dashboardPharmaciesPatientCertifiedCountController.$inject = ["$scope", "$state", "customerService"];

function dashboardPharmaciesPatientCertifiedCountController($scope, $state, customerService) {
  $scope.loading = true;
  $scope.searchText;
  $scope.activatedCount = null;
  $scope.redirectToList = redirectToList;
  loader();
  /**
   * The loader function
   */

  function loader() {
    customerService.certifiedCustomersCount().then(function (certifiedCustomersCount) {
      $scope.activatedCount = certifiedCustomersCount;
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Redirect to list with a renewal status filter.
   *
   * @param {string} filter - The filter.
   *
   * @returns {void}
   */


  function redirectToList(filter) {
    $state.go("app.patients", {
      booleanFilter: filter
    });
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesPatientCertifiedCountController", dashboardPharmaciesPatientCertifiedCountController);