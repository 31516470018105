"use strict";
/**
 * Controller for listing PLVs.
 *
 * @param {object} $rootScope - Root scope.
 * @param {object} $scope - Controller scope.
 * @param {object} $state - Module state.
 * @param {object} $mdDialog - Material design dialog.
 * @param {string} apiurl - API base url.
 * @param {object} plvsService - PLVs service.
 * @param {object} notifyUserService - User notification service.
 * @param {object} environmentService - Service environment.
 */

ListController.$inject = ["$rootScope", "$scope", "$state", "$mdDialog", "apiurl", "plvsService", "notifyUserService", "environmentService"];
editDialogController.$inject = ["$scope", "$mdDialog", "myDocument", "plvsService", "notifyUserService"];

function ListController($rootScope, $scope, $state, $mdDialog, apiurl, plvsService, notifyUserService, environmentService) {
  var previousState = $state.current.name.replace("app.", "");
  var currentState = previousState.replace(".", "/");
  $scope.editDoc = editDoc;
  $scope.openDoc = openDoc;
  $scope.deleteDoc = deleteDoc;
  $scope.shareDocument = shareDocument;
  $scope.exportPlv = exportPlv;
  $scope.selected = [];
  $scope.filters = [];
  $scope.files = [];
  $scope.limitOptions = [5, 10, 15];
  $scope.query = {
    order: "-startDate",
    limit: 10,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.documentsOptions = {
    loading: true,
    loader: "Récupération de vos supports de communication...",
    empty: "Vous n'avez aucun support de communication."
  };
  $scope.documentsSharedOptions = {
    loading: true,
    loader: "Récupération de supports de communication partagé...",
    empty: "Vous n'avez aucun support de communication partagé."
  };
  $scope.plvsOptions = {
    loading: true,
    loader: "Récupération de vos supports de communication...",
    empty: "Vous n'avez aucun support de communication."
  };
  loader();
  /**
   * Load PLVs listing.
   */

  function loader() {
    getEnvironement().then(loaderDocuments);
  }
  /**
   * getEnvironement
   *
   * @returns {Promise} Environment promise.
   */


  function getEnvironement() {
    return environmentService.getEnvironment().then(function (environment) {
      $scope.environement = environment.entity;
      return environment;
    });
  }
  /**
   * loaderDocuments
   */


  function loaderDocuments() {
    getDocuments();
    getSharedDocuments();
  }
  /**
   * getDocuments
   *
   * @returns {Promise} Documents promise.
   */


  function getDocuments() {
    return plvsService.load().then(function (response) {
      $scope.documentsOptions.loading = false;
      $scope.documents = response;
      return response;
    })["catch"](function (error) {
      notifyUserService.showSimpleToast("Une erreur est survenue lors du chargement de vos supports", {
        color: "danger",
        hideDelay: 1000
      });
      return error;
    });
  }
  /**
   * getDocuments
   *
   * @returns {Promise} Shared documents promise.
   */


  function getSharedDocuments() {
    return plvsService.loadShared().then(function (response) {
      $scope.documentsSharedOptions.loading = false;
      $scope.documentsShared = response;
      return response;
    })["catch"](function (error) {
      notifyUserService.showSimpleToast("Une erreur est survenue lors du chargement de vos supports partagés", {
        color: "danger",
        hideDelay: 1000
      });
      return error;
    });
  }
  /**
   * editDoc
   *
   * @param {object} event - Event triggered.
   * @param {object} myDocument - Document to edit.
   */


  function editDoc(event, myDocument) {
    $mdDialog.show({
      templateUrl: "app/main/generator/view/modals/editPlv.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      locals: {
        myDocument: myDocument
      },
      controller: editDialogController
    });
  }
  /**
   * Open a document.
   *
   * @param {object} event - Triggered event.
   * @param {object} myDocument - Document to open.
   */


  function openDoc(event, myDocument) {
    event.stopPropagation();
    $state.go("app.generator.edit", {
      id: myDocument.id,
      stateOrigin: currentState,
      injectedService: "plvsService"
    });
  }
  /**
   * deleteDoc
   *
   * @param {object} event - Triggered event.
   * @param {object} document - Document to delete.
   */


  function deleteDoc(event, document) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Suppression").textContent("\xCAtes-vous certain de vouloir supprimer le support \xAB ".concat(document.name, " \xBB ?")).ariaLabel("suppression support").targetEvent(event).ok("Supprimer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      return deleteDocument(document);
    }, null);
  }
  /**
   * deleteDocument
   *
   * @param {object} myDocument - Document to delete.
   *
   * @returns {Promise} Document deletion promise.
   */


  function deleteDocument(myDocument) {
    return plvsService["delete"](myDocument).then(function (response) {
      $scope.documents.splice($scope.documents.findIndex(function (index) {
        return index.id === myDocument.id;
      }), 1);
      notifyUserService.showSimpleToast("Le support \xAB ".concat(myDocument.name, " \xBB est supprim\xE9"), {
        color: "success",
        hideDelay: 1000
      });
      return response;
    }).then(loader)["catch"](function (error) {
      notifyUserService.showSimpleToast("Une erreur est survenue lors de la suppression de votre support", {
        color: "danger",
        hideDelay: 1000
      });
      return error;
    });
  }
  /**
   * Share a document.
   *
   * @param {object} myDocument - Document to share.
   */


  function shareDocument(myDocument) {
    var dialog = {
      templateUrl: "app/main/generator/view/modals/share.html",
      locals: {
        myDocument: myDocument
      },
      controller: shareDocumentModalController
    };
    $mdDialog.show(dialog).then(loaderDocuments);
  }
  /**
   * exportPlv
   *
   * @param {object} event - Triggered event.
   * @param {object} plv - PLV to export.
   */


  function exportPlv(event, plv) {
    event.preventDefault();

    if (!plv.file) {
      plvsService.open(plv).then(function (response) {
        plv.file = response;
        return plv.file;
      });
    }

    var dialog = {
      templateUrl: "app/main/generator/view/modals/export.html",
      controller: exportDialogController
    };
    $mdDialog.show(dialog).then(function (response) {
      if (response) {
        exportDoc(plv, response.type, response.variant);
      }
    });
  }
  /**
   * exportDoc
   *
   * @param {object} plv - PLV to export.
   * @param {string} type - Export file type.
   * @param {string} variant - Export variant.
   *
   * @returns {Promise} Export promise.
   */


  function exportDoc(plv, type, variant) {
    if (!variant) {
      return plvsService["export"](plv.id, type, plv.file).then(function (file) {
        return window.open("".concat(apiurl, "/images/").concat(file.full_path));
      });
    }

    return plvsService.exportVariant(plv.id, type, variant, plv.file).then(function (file) {
      if (variant !== "png") {
        return window.open("".concat(apiurl, "/files/").concat(file.full_path));
      }

      return window.open("".concat(apiurl, "/images/").concat(file.full_path));
    });
  }
  /**
   * Watcher filter.search
   */


  $scope.$watch("filter.search", function (newValue) {
    $scope.filters = newValue ? newValue.split(" ") : [];
    return $scope.filters;
  });
  /**
   * On edit
   */

  $rootScope.$on("edit", loader);
}
/**
 * Controller to show sharing modal.
 *
 * @param {object} $scope - Controller scope.
 * @param {object} $mdDialog - Material design.
 * @param {object} myDocument - PLV document.
 * @param {object} plvsService - PLV service.
 */


function shareDocumentModalController($scope, $mdDialog, myDocument, plvsService) {
  $scope.close = close;
  $scope.remove = remove;
  $scope.validate = validate;
  $scope.toggle = toggle;
  $scope.isChecked = isChecked;
  $scope.entityTypes = [{
    id: 1,
    label: "Tous les laboratoires"
  }, {
    id: 2,
    label: "Toutes les pharmacies"
  }, {
    id: 3,
    label: "Tous les groupements"
  }];
  $scope.selectedEntityTypes = [];
  $scope.searchConfig = {
    event: "addShareEdit",
    search: ["laboratory.id", "idOrgas", "group.id"],
    tag: "share",
    searchPlaceHolder: "Rechercher des laboratoires, des groupements et des pharmacies",
    autoLaunchEvent: true
  };
  $scope.selectedOrganizations = [];
  /**
   * addShareEdit event.
   */

  $scope.$on("addShareEdit", function (event, results) {
    for (var type in results) {
      if (Object.prototype.hasOwnProperty.call(results, type)) {
        results[type].forEach(function (value) {
          var shareExist = $scope.selectedOrganizations.find(function (organization) {
            return organization.id === value.organization_id;
          });

          if (!shareExist) {
            $scope.selectedOrganizations.push({
              id: value.organization_id,
              name: value.name
            });
          }
        });
      }
    }
  });

  if (myDocument.share) {
    var share = myDocument.share;
    $scope.selectedEntityTypes = share.entityTypes.map(function (entityType) {
      return {
        id: entityType.id
      };
    });
    $scope.selectedOrganizations = share.organizations.map(function (organization) {
      return {
        id: organization.id,
        name: organization.morphable.name
      };
    });
  }
  /**
   * Toggle.
   *
   * @param {object} entityType - Entity type to toggle.
   *
   * @returns {Array} The selected entity types
   */


  function toggle(entityType) {
    var exists = $scope.selectedEntityTypes.some(function (item) {
      return item.id === entityType.id;
    });

    if (!exists) {
      return $scope.selectedEntityTypes.push(entityType);
    }

    $scope.selectedEntityTypes = $scope.selectedEntityTypes.filter(function (item) {
      return item.id !== entityType.id;
    });
    return $scope.selectedEntityTypes;
  }
  /**
   * Is checked.
   *
   * @param {object} entityType - Entity type to test.
   *
   * @returns {boolean} True if entity type is checked, else false.
   */


  function isChecked(entityType) {
    return $scope.selectedEntityTypes.some(function (item) {
      return item.id === entityType.id;
    });
  }
  /**
   * Close
   */


  function close() {
    $mdDialog.hide();
  }
  /**
   * Remove
   *
   * @param {number} index - The index of shared document.
   */


  function remove(index) {
    $scope.selectedOrganizations.splice(index, 1);
  }
  /**
   * Validate
   */


  function validate() {
    var entityTypeIds = $scope.selectedEntityTypes.map(function (entityType) {
      return entityType.id;
    });
    var organizationIds = $scope.selectedOrganizations.map(function (organization) {
      return organization.id;
    });
    var shareWith = {
      entityTypes: entityTypeIds,
      organizations: organizationIds
    };
    plvsService.share(myDocument.id, shareWith).then(function (share) {
      myDocument.share = share;
      $mdDialog.hide();
    });
  }
}
/**
 * Controller to show edit dialog.
 *
 * @param {object} $scope - Controller scope.
 * @param {object} $mdDialog - Material design dialog.
 * @param {object} myDocument - My document.
 * @param {object} plvsService - PLV Service.
 * @param {object} notifyUserService - User notification service.
 */


function editDialogController($scope, $mdDialog, myDocument, plvsService, notifyUserService) {
  $scope.myDocument = myDocument;
  $scope.save = save;
  $scope.cancel = cancel;
  /**
   * Save the document.
   *
   * @returns {Promise} Document save promise.
   */

  function save() {
    return plvsService.update($scope.myDocument).then(function (response) {
      notifyUserService.showSimpleToast("Le support \xAB ".concat(response.name, " \xBB a bien \xE9t\xE9 modifi\xE9"), {
        color: "success",
        hideDelay: 1000
      });
      cancel();
      return response;
    });
  }
  /**
   * cancel
   * To cancel the edit
   */


  function cancel() {
    $mdDialog.cancel();
  }
}
/**
 * Controller to show export form.
 *
 * @param {object} $scope - Controller scope.
 * @param {object} $mdDialog - Material design.
 */


function exportDialogController($scope, $mdDialog) {
  $scope["export"] = {
    types: [{
      id: 1,
      name: "PDF",
      value: "pdf"
    }, {
      id: 2,
      name: "PNG",
      value: "png"
    }, {
      id: 3,
      name: "SVG",
      value: "svg"
    }],
    variants: [{
      id: 1,
      name: "72 Dpi",
      value: "small"
    }, {
      id: 2,
      name: "100 Dpi",
      value: "medium"
    }, {
      id: 3,
      name: "200 Dpi",
      value: "large"
    }, {
      id: 4,
      name: "300 Dpi",
      value: "xlarge"
    }],
    type: false,
    variant: false
  };
  $scope.validExport = validExport;
  $scope.cancelExport = cancelExport;
  /**
   * validExport
   */

  function validExport() {
    $mdDialog.hide($scope["export"]);
  }
  /**
   * cancel
   */


  function cancelExport() {
    $mdDialog.cancel();
  }
}

angular.module("app.generator").controller("listController", ListController);