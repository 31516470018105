"use strict";
/**
 * Controller for top sales product.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} StatisticsQueryService - The statistics query service.
 * @param {object} salesAnalysisTopSalesProductService - The top sales product service.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} environmentService - The environment service.
 */

TopSalesProductController.$inject = ["$scope", "StatisticsQueryService", "salesAnalysisTopSalesProductService", "$mdDialog", "environmentService"];

function TopSalesProductController($scope, StatisticsQueryService, salesAnalysisTopSalesProductService, $mdDialog, environmentService) {
  $scope.loading = true;
  $scope.products = [];
  $scope.dateLabel = moment().subtract(1, "year").format("DD-MM-YYYY") + " au " + moment().format("DD-MM-YYYY");
  $scope.showDetails = showDetails;
  $scope.rangeSelected = rangeSelected;
  $scope.toggleCb = toggleCb;
  $scope.getLimits = getLimits;
  $scope.indexByLabel = indexByLabel;
  $scope.existsFilter = existsFilter;
  $scope.query = StatisticsQueryService.query;
  $scope.rawValues = StatisticsQueryService.values;
  loader();
  $scope.config = {
    event: "topSalesProductAnalysis",
    search: []
  };
  /**
   * The loader function
   */

  function loader() {
    environmentService.getEnvironment().then(function (env) {
      $scope.currentEnv = env;
      getTopProductAnalysis($scope.query);
    });
  }
  /**
   * Get the top sold products
   *
   * @param {object} query - The query.
   */


  function getTopProductAnalysis(query) {
    $scope.loading = true;
    $scope.products = [];
    var startDate = moment(query.date.from).format("DD-MMM-YYYY");
    var endDate = moment(query.date.to).format("DD-MM-YYYY");
    $scope.dateLabel = startDate + " Au " + endDate;
    StatisticsQueryService.buildquery(query, query.date).then(function (builtQuery) {
      return salesAnalysisTopSalesProductService.post(builtQuery);
    }).then(function (response) {
      $scope.products = response;
      $scope.loading = false;
    });
  }
  /**
   * On date range changed
   *
   * @param {object} range - The date range.
   */


  function rangeSelected(range) {
    $scope.dateLabel = range.startDate + " au " + range.endDate;
    $scope.rangeDates = range;
    $scope.query.date.from = moment(range.startDate, "DD-MM-YYYY");
    $scope.query.date.to = moment(range.endDate, "DD-MM-YYYY");
    getTopProductAnalysis($scope.query);
  }
  /**
   * Open the detail by pahrma dialog
   *
   * @param {object} product - The product.
   */


  function showDetails(product) {
    $mdDialog.show({
      controller: function controller() {
        var vm = this;
        vm.product = product;

        vm.close = function () {
          $mdDialog.cancel();
        };
      },
      controllerAs: "vm",
      templateUrl: "app/main/statistics/productAnalysis/views/topSalesProduct/details.tmpl.html",
      parent: angular.element(document.body)
    });
  }
  /**
   * When a checkbox is checked
   *
   * @param {object} item - The item.
   * @param {object} list - The list.
   */


  function toggleCb(item, list) {
    var idx = indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    getTopProductAnalysis($scope.query);
  }
  /**
   * Split the array
   *
   * @param {Array} array - The array.
   *
   * @returns {Array}
   */


  function getLimits(array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  }
  /**
   * If the filter exists
   *
   * @param {string} name - The name.
   * @param {Array} list - The list.
   *
   * @returns {boolean} If the filter is in the list.
   */


  function existsFilter(name, list) {
    return indexByLabel(name, list) > -1;
  }
  /**
   * Index by label.
   *
   * @param {string} name - The name.
   * @param {Array} list - The list.
   *
   * @returns {number}
   */


  function indexByLabel(name, list) {
    if (!name || !list) {
      return -1;
    }

    for (var index = 0, length = list.length; index < length; index++) {
      if (list[index].label === name) {
        return index;
      }
    }

    return -1;
  }
  /**
   * Event of the search bar
   */


  $scope.$on("topSalesProductAnalysis", function () {
    getTopProductAnalysis($scope.query);
  });
}

angular.module("app.statistics.products").controller("topSalesProductController", TopSalesProductController);