"use strict";
/**
 * Dashboard laboratories sellouts push controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 */

DashboardLaboratoriesSelloutsPushController.$inject = ["$scope", "$state"];

function DashboardLaboratoriesSelloutsPushController($scope, $state) {
  $scope.goToSellouts = function () {
    $state.go("app.sellout");
  };
}

angular.module("app.dashboard").controller("dashboardLaboratoriesSelloutsPushController", DashboardLaboratoriesSelloutsPushController);