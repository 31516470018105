"use strict";
/**
 * Commercial offer home controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} sessionStorageService - Session storage service.
 * @param {object} wholesalerService - Wholesaler service.
 */

CommercialOfferHomeController.$inject = ["$scope", "$state", "sessionStorageService", "wholesalerService"];

function CommercialOfferHomeController($scope, $state, sessionStorageService, wholesalerService) {
  $scope.wholesalers = null;
  $scope.loading = true;
  $scope.tokenV2 = "&token=" + sessionStorageService.getToken();
  $scope.getWholesalers = getWholesalers;
  $scope.getCommercialOfferForWholesaler = getCommercialOfferForWholesaler;
  loader();
  /**
   * Loader
   */

  function loader() {
    getWholesalers();
  }
  /**
   * Get the wholesalers
   *
   * @returns {Promise} The wholesalers
   */


  function getWholesalers() {
    return wholesalerService.get().then(function (response) {
      $scope.wholesalers = response;
      getCommercialOfferForWholesaler($scope.wholesalers[0]);
    });
  }
  /**
   * Get the commercial offer for a wholesaler
   *
   * @param {object} wholesaler - The wholesaler
   */


  function getCommercialOfferForWholesaler(wholesaler) {
    $state.go("app.commercialOffer.wholesaler", {
      id: wholesaler.id
    });
  }
}

angular.module("app.commercialOffer").controller("commercialOfferHomeController", CommercialOfferHomeController);