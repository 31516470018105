"use strict";
/**
 * User laboratory branding statistics controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $state - $state.
 * @param {string} apiurl - API URL.
 * @param {object} bricksService - Bricks service.
 * @param {object} brandingStatisticsService - The branding statistics service.
 */

UserLaboratoryBrandingStatisticsController.$inject = ["$scope", "$rootScope", "$state", "apiurl", "bricksService", "brandingStatisticsService"];

function UserLaboratoryBrandingStatisticsController($scope, $rootScope, $state, apiurl, bricksService, brandingStatisticsService) {
  $scope.goTo = goTo;
  $scope.goToEvent = goToEvent;
  $scope.goToState = goToState;
  $scope.changePeriodForWidget = changePeriodForWidget;
  $scope.setOperatorsContributionOptions = setOperatorsContributionOptions;
  $scope.topProductsPeriodSelected = "month";
  $scope.topOperatorsPeriodSelected = "month";
  $scope.topProductsPricePeriodSelected = "month";
  $scope.operatorsContributionPeriodSelected = "month";
  $scope.operatorsContributionOptions = {};
  $scope.loaderSales = {
    loading: true,
    loader: "Chargement des ventes"
  };
  $scope.loaderBranding = {
    loading: true,
    loader: ""
  };
  $scope.loaderSalesFromJanuary = {
    loading: true,
    loader: "Chargement des données"
  };
  $scope.loaderNeeds = {
    loading: true,
    loader: "Chargement des données de stock"
  };
  $scope.loaderTopProducts = {
    loading: true,
    loader: "Chargement du top produit"
  };
  $scope.loaderTopOperators = {
    loading: true,
    loader: "Chargement du top vendeur"
  };
  $scope.loaderOperatorsContribution = {
    loading: true,
    loader: "Chargement des contributions"
  };
  $scope.loaderTopProductPrices = {
    loading: true,
    loader: "Chargement des prix"
  };
  /**
   * Declaration of public objects
   */

  $scope.apiurl = apiurl;
  /**
   * Go to given state.
   *
   * @param {string} state - State.
   */

  function goToState(state) {
    $state.go(state);
  }
  /**
   * Go to brick state and switch brick.
   *
   * @param {object} brick - Brick.
   */


  function goTo(brick) {
    $rootScope.$broadcast("sideNavConstruct", brick);
    bricksService.setCurrentBrick(brick);
  }
  /**
   * Go to event details.
   *
   * @param {object} event - Event.
   */


  function goToEvent(event) {
    $state.go("app.calendar.detail", {
      id: event.id
    });
  }

  $rootScope.$watch("laboratoryBranding", function (branding) {
    if (branding) {
      $scope.branding = branding;
      var params = {
        laboratoryId: branding.laboratoryId
      };
      getSalesEvolution(params);
      getTopProdutPrices(params);
      getTopOperators(params);
      getTopProduts(params);
      getNeeds(params);
      getOperatorsContribution(params);
    }
  });
  /**
   * Get sales evolution options.
   *
   * @param {object} sales - The sales to format.
   *
   * @returns {object}
   */

  function getSalesEvolutionOptions(sales) {
    return {
      type: "serial",
      theme: "light",
      data: sales.aggregations.sales.sales.buckets.map(function (sale, index) {
        return {
          date: sale.key,
          sales: sale.amount_excl_tax.value,
          salesLastYear: sales.aggregations.salesLastYear.salesLastYear.buckets[index].amount_excl_tax.value
        };
      }),
      thousandsSeparator: " ",
      precision: 0,
      valueAxes: [{
        axisAlpha: 0,
        position: "left",
        title: "CA total",
        color: $scope.branding.primaryColor,
        gridColor: $scope.branding.primaryColor,
        titleColor: $scope.branding.primaryColor
      }],
      legend: {
        useGraphSettings: true,
        marginTop: 20,
        color: $scope.branding.primaryColor
      },
      graphs: [{
        lineAlpha: 1,
        valueField: "sales",
        lineThickness: 1,
        title: "Ventes totales",
        type: "smoothedLine",
        lineColor: $scope.branding.secondaryColor,
        balloonText: "<div style='font-size:11px;text-align:center'>[[category]] : <br><b>[[value]] EUR</b></div>"
      }, {
        lineAlpha: 1,
        valueField: "salesLastYear",
        title: "Ventes totales N-1",
        lineThickness: 1,
        type: "smoothedLine",
        lineColor: lightenDarkenColor($scope.branding.secondaryColor, 160),
        balloonText: "<div style='font-size:11px;text-align:center'>[[category]] : <br><b>[[value]] EUR</b></div>"
      }],
      chartCursor: {
        enabled: true,
        avoidBalloonOverlapping: false,
        balloonPointerOrientation: "vertical",
        oneBalloonOnly: true,
        cursorAlpha: 0,
        cursorColor: "#003D56"
      },
      categoryField: "date",
      categoryAxis: {
        parseDates: true,
        color: $scope.branding.primaryColor,
        axisColor: $scope.branding.primaryColor,
        gridColor: $scope.branding.primaryColor,
        titleColor: $scope.branding.primaryColor
      }
    };
  }
  /**
   * Get sales since beginning of the year.
   *
   * @param {object} sales - The sales to handle.
   *
   * @returns {object}
   */


  function getSalesFromJanuary(sales) {
    var averageCart = sales.aggregations.salesFromBeginningOfYear.amount_excl_tax.value / sales.aggregations.salesFromBeginningOfYear.quantityOfCart.value;
    var averageCartLastYear = sales.aggregations.salesFromBeginningOfYearLastYear.amount_excl_tax.value / sales.aggregations.salesFromBeginningOfYearLastYear.quantityOfCart.value;
    var averageCartEvolution = 100 - averageCartLastYear / averageCart * 100;
    return {
      turnover: sales.aggregations.salesFromBeginningOfYear.amount_excl_tax.value,
      turnoverEvolution: 100 - sales.aggregations.salesFromBeginningOfYearLastYear.amount_excl_tax.value / sales.aggregations.salesFromBeginningOfYear.amount_excl_tax.value * 100,
      quantity: sales.aggregations.salesFromBeginningOfYear.quantity.value,
      quantityEvolution: 100 - sales.aggregations.salesFromBeginningOfYearLastYear.quantity.value / sales.aggregations.salesFromBeginningOfYear.quantity.value * 100,
      averageCart: averageCart,
      averageCartEvolution: averageCartEvolution
    };
  }
  /**
   * Get sales evolution for branding.
   *
   * @param {object} params - The params.
   */


  function getSalesEvolution(params) {
    brandingStatisticsService.getSales(params).then(function (sales) {
      $scope.salesEvolutionOptions = getSalesEvolutionOptions(sales);
      $scope.salesFromJanuary = getSalesFromJanuary(sales);
    });
  }
  /**
   * Get needs for branding.
   *
   * @param {object} params - The params.
   */


  function getNeeds(params) {
    brandingStatisticsService.getNeeds(params).then(function (needs) {
      $scope.needs = needs;
    });
  }
  /**
   * Set operators contribution options.
   *
   * @param {string} period - The selected period to show.
   */


  function setOperatorsContributionOptions(period) {
    $scope.operatorsContributionPeriodSelected = period;

    if ($scope.operatorsContribution[$scope.operatorsContributionPeriodSelected]) {
      $scope.operatorsContributionOptions = {
        type: "serial",
        theme: "light",
        rotate: true,
        marginBottom: 50,
        marginLeft: 50,
        data: Object.keys($scope.operatorsContribution[$scope.operatorsContributionPeriodSelected]).map(function (index) {
          return {
            operator: index,
            evolution: ($scope.operatorsContribution[$scope.operatorsContributionPeriodSelected][index].lastYear - $scope.operatorsContribution[$scope.operatorsContributionPeriodSelected][index].current).toFixed(2)
          };
        }),
        graphs: [{
          fillAlphas: 1,
          lineAlpha: 0.2,
          type: "column",
          valueField: "evolution",
          title: "Opérateur",
          clustered: false,
          lineColor: $scope.branding.secondaryColor,
          balloonFunction: function balloonFunction(item) {
            return item.category + " : " + item.values.value + " €";
          }
        }],
        categoryField: "operator",
        categoryAxis: {
          gridPosition: "start",
          gridAlpha: 0.2,
          axisAlpha: 0,
          parseDates: false,
          color: $scope.branding.primaryColor,
          axisColor: $scope.branding.primaryColor,
          gridColor: $scope.branding.primaryColor,
          titleColor: $scope.branding.primaryColor
        },
        valueAxes: [{
          gridAlpha: 0,
          ignoreAxisWidth: true,
          labelFunction: function labelFunction(value) {
            return value + " €";
          },
          color: $scope.branding.primaryColor,
          gridColor: $scope.branding.primaryColor,
          titleColor: $scope.branding.primaryColor,
          guides: [{
            value: 0
          }]
        }],
        chartCursor: {
          enabled: true,
          avoidBalloonOverlapping: false,
          balloonPointerOrientation: "vertical",
          oneBalloonOnly: true,
          cursorAlpha: 0,
          cursorColor: "#003D56"
        },
        "export": {
          enabled: false
        }
      };
    } else {
      $scope.operatorsContributionOptions.data = [];
    }
  }
  /**
   * Get operators contribution.
   *
   * @param {object} params - The params.
   */


  function getOperatorsContribution(params) {
    brandingStatisticsService.getOperatorsContribution(params).then(function (operatorsContribution) {
      $scope.operatorsContribution = operatorsContribution;
      setOperatorsContributionOptions("month");
    });
  }
  /**
   * Get top product prices.
   *
   * @param {object} params - The params.
   */


  function getTopProdutPrices(params) {
    brandingStatisticsService.getTopProductPrices(params).then(function (topProductPrices) {
      $scope.topProductPrices = topProductPrices;
      $scope.topProductPrices.periodSelected = topProductPrices[$scope.topProductsPricePeriodSelected].topProductPrices.buckets;
    });
  }
  /**
   * Get top products.
   *
   * @param {object} params - The params.
   */


  function getTopProduts(params) {
    brandingStatisticsService.getTopProducts(params).then(function (topProducts) {
      $scope.topProducts = topProducts;
      $scope.topProducts.periodSelected = topProducts[$scope.topProductsPeriodSelected].topProducts.buckets;
    });
  }
  /**
   * Get top operators.
   *
   * @param {object} params - The params.
   */


  function getTopOperators(params) {
    brandingStatisticsService.getTopOperators(params).then(function (topOperators) {
      $scope.topOperators = topOperators.aggregations;
      $scope.topOperators.periodSelected = topOperators.aggregations[$scope.topOperatorsPeriodSelected].topOperators.buckets;
    });
  }
  /**
   * Change selected period.
   *
   * @param {string} periodSelected - The period selected.
   * @param {string} widgetData - The data to work on.
   */


  function changePeriodForWidget(periodSelected, widgetData) {
    $scope[widgetData].periodSelected = $scope[widgetData][periodSelected][widgetData].buckets;
  }
  /**
   * Lighten or darken a color.
   *
   * @param {string} col - The color to modify.
   * @param {number} amt - The coefficient of modification.
   */


  function lightenDarkenColor(col, amt) {
    var usePound = false;
    var color = "";

    if (col[0] === "#") {
      color = col.slice(1);
      usePound = true;
    }

    var num = parseInt(color, 16);
    var red = (num >> 16) + amt;

    if (red > 255) {
      red = 255;
    } else if (red < 0) {
      red = 0;
    }

    var blue = (num >> 8 & 0x00FF) + amt;

    if (blue > 255) {
      blue = 255;
    } else if (blue < 0) {
      blue = 0;
    }

    var green = (num & 0x0000FF) + amt;

    if (green > 255) {
      green = 255;
    } else if (green < 0) {
      green = 0;
    }

    return (usePound ? "#" : "") + (green | blue << 8 | red << 16).toString(16);
  }
}

angular.module("app.users-laboratory").controller("userLaboratoryBrandingStatisticsController", UserLaboratoryBrandingStatisticsController);