"use strict";
/**
 * Sales area management configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

SalesAreaManagementConfig.$inject = ["$stateProvider"];

function SalesAreaManagementConfig($stateProvider) {
  $stateProvider.state("app.salesAreaManagement", {
    url: "/espace-de-vente",
    views: {
      "content@app": {
        templateUrl: "app/main/sales-area-management/views/home/home.html",
        controller: "salesAreaManagementController"
      },
      "salesAreaContent@app.salesAreaManagement": {
        templateUrl: "app/main/sales-area-management/views/list/list.html",
        controller: "salesAreaManagementListController"
      }
    },
    bodyClass: "dashboard"
  }).state("app.salesAreaManagement.list", {
    url: "/liste",
    views: {
      "salesAreaContent@app.salesAreaManagement": {
        templateUrl: "app/main/sales-area-management/views/list/list.html",
        controller: "salesAreaManagementListController"
      }
    },
    bodyClass: "dashboard"
  }).state("app.salesAreaManagement.edit", {
    url: "/nouveau",
    views: {
      "content@app": {
        templateUrl: "app/main/sales-area-management/views/edit/edit.html",
        controller: "salesAreaManagementEditController"
      }
    },
    params: {
      salesArea: null
    },
    bodyClass: "dashboard"
  }).state("app.salesAreaManagement.productsAffectation", {
    url: "/produits",
    views: {
      "salesAreaContent@app.salesAreaManagement": {
        templateUrl: "app/main/sales-area-management/views/list/list.html",
        controller: "salesAreaManagementListController"
      }
    },
    bodyClass: "dashboard"
  }).state("app.salesAreaManagement.productsAffectation.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/sales-area-management/views/productsAffectation/productsAffectation.html",
        controller: "salesAreaManagementProductsAffectationController"
      }
    },
    params: {
      salesArea: null
    },
    bodyClass: "dashboard"
  }).state("app.salesAreaManagement.profitability", {
    url: "/rentabilite",
    views: {
      "salesAreaContent@app.salesAreaManagement": {
        templateUrl: "app/main/sales-area-management/views/profitability/profitability.html",
        controller: "salesAreaProfitabilityController"
      }
    },
    params: {
      salesArea: null
    },
    bodyClass: "dashboard"
  }).state("app.salesAreaManagement.profitability.detail", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/sales-area-management/views/profitabilityDetail/profitabilityDetail.html",
        controller: "salesAreaProfitabilityDetailController"
      }
    },
    params: {
      profitability: null
    },
    bodyClass: "dashboard"
  });
}

angular.module("app.salesAreaManagement", []).config(SalesAreaManagementConfig);