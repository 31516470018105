"use strict";
/**
 * Disruption module config.
 *
 * @param {object} $stateProvider - The angular $stateProvider object.
 */

DisruptionConfig.$inject = ["$stateProvider"];

function DisruptionConfig($stateProvider) {
  $stateProvider.state("app.disruption", {
    url: "/rupture",
    views: {
      "content@app": {
        templateUrl: "app/main/disruption/disruption.html",
        controller: "DisruptionController as vm"
      },
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/statistics/stocks/stat/stat.html",
        controller: "StatisticsStatStockController as vm"
      }
    },
    bodyClass: "dashboard"
  }).state("app.disruption.goumy", {
    url: "/stock",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/statistics/stocks/stat/stat.html",
        controller: "StatisticsStatStockController as vm"
      }
    }
  }).state("app.disruption.map", {
    url: "/carte-stock",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/statistics/stocks/map/map.html",
        controller: "StatisticsMapStockController as vm"
      }
    }
  }).state("app.disruption.supplyingHealthcenter", {
    url: "/analyse-des-pharmaml",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/pharmaMLAnalysis/pharmaMLAnalysis.html",
        controller: "pharmaMLAnalysisController"
      }
    }
  }).state("app.disruption.distributor", {
    url: "/distributor",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/statistics/productAnalysis/views/distributor/distributor.html",
        controller: "distributorController as vm"
      }
    }
  }).state("app.disruption.supplying", {
    url: "/analyse-des-commandes",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/statistics/stocks/supplying/supplying.html",
        controller: "SupplyingController as vm"
      }
    }
  }).state("app.disruption.declaration", {
    url: "/declaration",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/declaration/declaration.html",
        controller: "disruptionDeclarationController"
      }
    }
  }).state("app.disruption.declaration.edit", {
    url: "/:id",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/edit/edit.html",
        controller: "editDisruptionDeclarationController"
      }
    }
  }).state("app.disruption.declaration.creation", {
    url: "/creation/nouveau",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/edit/edit.html",
        controller: "editDisruptionDeclarationController"
      }
    }
  }).state("app.disruption.list", {
    url: "/laboratoires",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/list/list.html",
        controller: "disruptionListController"
      }
    }
  }).state("app.disruption.list.detail", {
    url: "/:laboId",
    views: {
      "disruptionContent@app.disruption.details": {
        templateUrl: "app/main/disruption/views/detail/detail.html",
        controller: "disruptionDetailController"
      }
    }
  }).state("app.disruption.list.detail.analysis", {
    url: "/:id",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/analysis/analysis.html",
        controller: "disruptionAnalysisController"
      }
    }
  }).state("app.disruption.negativePharmaML", {
    url: "/pharmaML-negatif",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/negativePharmaML/negativePharmaML.html",
        controller: "negativePharmaMLController"
      }
    }
  }).state("app.disruption.laboratory", {
    url: "/rupture-laboratoires",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/laboratoryDisruption/laboratoryDisruption.html",
        controller: "laboratoryDisruptionController"
      }
    }
  }).state("app.disruption.laboratory.detail", {
    url: "/:laboId",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/laboratoryDisruptionDetail/laboratoryDisruptionDetail.html",
        controller: "laboratoryDisruptionDetailController"
      }
    }
  }).state("app.disruption.offer", {
    url: "/offre",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/offer/offer.html",
        controller: "disruptionOfferController"
      }
    }
  }).state("app.disruption.alertReport", {
    url: "/rapport-d-alerte",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/alertReport/alertReport.html",
        controller: "disruptionAlertReportController"
      }
    }
  }).state("app.disruption.partners", {
    url: "/partenaires",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/partners/partners.html",
        controller: "disruptionPartnersController"
      }
    }
  }).state("app.disruption.offersMonitoring", {
    url: "/suivi-des-offres",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/offersMonitoring/offersMonitoring.html",
        controller: "offersMonitoringController"
      }
    },
    params: {
      selectedTab: null
    }
  }).state("app.disruption.offersMonitoring.healthcenterDetail", {
    url: "/detail-pharmacie/:id",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/offersMonitoring/detail/healthcenterDetail.html",
        controller: "detailHealthcenterOfferMonitoringController"
      }
    },
    params: {
      pharma: null,
      selectedTab: null
    }
  }).state("app.disruption.offersMonitoring.orderDetail", {
    url: "/detail-offre/:id",
    views: {
      "disruptionContent@app.disruption": {
        templateUrl: "app/main/disruption/views/offersMonitoring/detail/orderDetail.html",
        controller: "detailOrderOfferMonitoringController"
      }
    },
    params: {
      order: null,
      selectedTab: null,
      healthcenterId: null,
      pharma: null
    }
  });
}

angular.module("app.disruption", []).config(DisruptionConfig);