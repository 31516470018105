"use strict";
/** @ngInject */

/**
 * The substitution service.
 *
 * @param {object} apiClient - The apiClient object.
 * @returns {object}
 */

WholesalerAuditService.$inject = ["apiClient"];

function WholesalerAuditService(apiClient) {
  return {
    /**
     * Get wholesalers orders.
     *
     * @returns {Promise}
     */
    getOrders: function getOrders() {
      return apiClient.get("/analysis/wholesalers/orders", {
        wrappers: {
          response: "data"
        }
      });
    }
  };
}

angular.module("app.dashboard").service("wholesalerAuditService", WholesalerAuditService);