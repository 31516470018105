"use strict";

AdministrationPatientUserService.$inject = ["hdsApiClient"];

/**
 * Administration patient user service.
 *
 * @param {object} hdsApiClient - The hds api client.
 *
 * @returns {object}
 */
function AdministrationPatientUserService(hdsApiClient) {
  return {
    /**
     * Index user who has pending certification.
     *
     * @param {object} params - The query params.
     *
     * @returns {Promise}
     */
    index: function index(params) {
      return hdsApiClient("GET", "/users", {
        params: params
      }).then(function (response) {
        return response;
      });
    }
  };
}

angular.module("app.patient-administration.services").service("administrationPatientUserService", AdministrationPatientUserService);