"use strict";
/**
 * PLV formats service.
 *
 * @param {object} apiClient -
 * @returns {object}
 */

PlvsFormatsService.$inject = ["apiClient"];

function PlvsFormatsService(apiClient) {
  return {
    /**
     * load
     *
     * @returns {Promise}
     */
    load: function load() {
      return apiClient.get("/plvs/formats").then(function (result) {
        return result.data;
      });
    },

    /**
     * read
     *
     * @param {number} format_id - The format id
     * @returns {Promise}
     */
    read: function read(_ref) {
      var format_id = _ref.format_id;
      return apiClient.get("/plvs/formats/".concat(format_id)).then(function (result) {
        return result.data;
      });
    },

    /**
     * getByTypeId
     *
     * @param {number} id - The type id
     * @returns {Promise}
     */
    getByTypeId: function getByTypeId(_ref2) {
      var id = _ref2.id,
          format_name = _ref2.format_name;
      var params = {
        parameters: [{
          name: "type_id",
          value: id
        }, {
          name: "format_name",
          value: format_name
        }]
      };
      return apiClient.get("/plvs/formats", params).then(function (result) {
        return result.data;
      });
    }
  };
}

angular.module("app.generator").service("plvsFormatsService", PlvsFormatsService);