"use strict";
/**
 * The order detail controller.
 *
 * @param {object} $scope - $scope.
 * @param {string} apiurl - The api url.
 * @param {object} $stateParams - The $stateParams object.
 * @param {object} $state - The $state object.
 * @param {object} $window - The $window object.
 */

DetailOrderOfferMonitoringController.$inject = ["$scope", "apiurl", "$stateParams", "$state", "$window"];

function DetailOrderOfferMonitoringController($scope, apiurl, $stateParams, $state, $window) {
  $scope.showPharmamlInfos = false;
  $scope.goBack = goBack;

  if ($stateParams.order) {
    $scope.order = $stateParams.order;
    $scope.totalQuantity = $scope.order.products.reduce(function (acc, product) {
      return acc + product.quantity;
    }, 0);
    $scope.totalDiscountedTotalHT = $scope.order.products.reduce(function (acc, product) {
      return acc + product.totalPriceAfterDiscountWithoutVat;
    }, 0);
  } else {
    $window.history.back();
  }

  $scope.startDate = {
    order: "-date",
    page: 1,
    limit: 50
  };
  $scope.apiurl = apiurl;
  /**
   * Return to the offers monitoring page
   */

  function goBack() {
    if ($stateParams.pharma) {
      $state.go("app.disruption.offersMonitoring.healthcenterDetail", {
        id: $stateParams.healthcenterId,
        pharma: $stateParams.pharma,
        selectedTab: $stateParams.selectedTab
      });
    } else {
      $state.go("app.disruption.offersMonitoring", {
        selectedTab: $stateParams.selectedTab
      });
    }
  }
}

angular.module("app.disruption").controller("detailOrderOfferMonitoringController", DetailOrderOfferMonitoringController);