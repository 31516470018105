"use strict";
/**
 * Guest main controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} guestAccessesService - Guest accesses service.
 */

GuestMainController.$inject = ["$scope", "$mdDialog", "notifyUserService", "guestAccessesService"];

function GuestMainController($scope, $mdDialog, notifyUserService, guestAccessesService) {
  createGuestController.$inject = ["$scope", "$mdDialog", "guestService"];

  /**
   * Declaration of public methods
   */
  $scope.createGuest = createGuest;
  $scope.deleteAccess = deleteAccess;
  $scope.showStatus = showStatus;
  /**
   * Declaration of public variables
   */

  $scope.paginationPage = 1;
  $scope.paginationLimit = 10;
  $scope.loaderAccess = {
    loading: true,
    loader: "Récupération des employés",
    empty: "Vous n'avez aucun employé."
  };
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * Loader.
   */

  function loader() {
    getAccesses();
  }
  /**
   * Get the pending requests
   */


  function getAccesses() {
    guestAccessesService.get().then(function (response) {
      $scope.accesses = response;
      $scope.loaderAccess.loading = false;
    });
  }
  /**
   * Show status.
   *
   * @param {object} access - Access.
   *
   * @returns {string}
   */


  function showStatus(access) {
    if (access.validated) {
      return "Inscrit";
    }

    if (angular.isObject(access.guest) && access.guest.status === "waiting") {
      return "Invitation en attente";
    }

    if (access.user.isRegistered) {
      return "Inscrit";
    }

    return "Non inscrit";
  }
  /**
   * Delete an access.
   *
   * @param {object} event - Event dispatched
   * @param {object} access - Access to delete
   */


  function deleteAccess(event, access) {
    event.stopPropagation();
    var username = (access.user.given + " " + access.user.familyBirth).trim();

    if (!username) {
      username = access.user.email;
    }

    var confirm = $mdDialog.confirm();
    confirm.title("Suppression de l'accès à l'utilisateur " + username).textContent("Êtes-vous certain de vouloir supprimer l'accès à votre organisation à " + username + " ?").ariaLabel("suppression acces").targetEvent(event).ok("Confirmer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      guestAccessesService["delete"](access).then(function () {
        notifyUserService.showSimpleToast("L'accès de " + username + " a été supprimé.");
        getAccesses();
      });
    });
  }
  /**
   * Create guest.
   */


  function createGuest() {
    $mdDialog.show({
      controller: createGuestController,
      templateUrl: "app/main/guest/views/includes/modals/createGuest/createGuest.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true
    }).then(function () {// do nothing
    }, getAccesses);
  }
  /**
   * Create guest controller.
   *
   * @param {object} $scope - $scope.
   * @param {object} $mdDialog - $mdDialog.
   * @param {object} guestService - Guest service.
   */


  function createGuestController($scope, $mdDialog, guestService) {
    $scope.save = save;
    $scope.cancel = cancel;
    $scope.checkMail = checkMail;
    $scope.guest = {
      message: "Le champ email doit contenir une adresse email."
    };
    $scope.guest.isAvailable = false;
    /**
     * Check email.
     *
     * @param {string} email - Email.
     */

    function checkMail(email) {
      var re = /\S+@\S+\.\S+/;
      var isMail = re.test(String(email).toLowerCase());

      if (isMail) {
        guestService.available({
          email: email
        }).then(function (response) {
          $scope.checker = response.success;
          $scope.guest.message = response.message;
        });
        return;
      }

      $scope.checker = false;
    }
    /**
     * Save.
     *
     * @param {object} form - Form.
     */


    function save(form) {
      var guest = {
        email: form.email.$modelValue
      };
      guestService.invite(guest).then(cancel);
    }
    /**
     * Cancel.
     */


    function cancel() {
      $mdDialog.cancel();
    }
  }
}

angular.module("app.guest").controller("guestMainController", GuestMainController);