"use strict";
/**
 * @ngInject
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} directOfferService - The directOffer service.
 * @param {object} environmentService - The environment service.
 * @param {object} notifyUserService - The notify user service.
 * @param {object} productService - The loader product.
 * @param {object} healthcenterService - The healthcenter service.
 */

DirectOfferPriceRangeEditController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "directOfferService", "environmentService", "notifyUserService", "productService", "healthcenterService"];

function DirectOfferPriceRangeEditController($scope, $state, $stateParams, $mdDialog, directOfferService, environmentService, notifyUserService, productService, healthcenterService) {
  $scope.select = true;
  $scope.createLoading = false;
  $scope.query = {
    order: "name",
    limit: 10,
    limitOptions: [5, 10, 15, 30, 50, 100, 200],
    page: 1
  };
  $scope.priceRangeEditLoading = true; // Product search config

  $scope.productSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    event: "addProductsInLaboratoryPriceRange",
    tag: "configProductLaboratoryPriceRange",
    autoLaunchEvent: true,
    must: {
      "laboratory.id": []
    }
  }; // Healthcenter search config

  $scope.healthcenterSearchConfig = {
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
    event: "addHealthcentersInLaboratoryPriceRange",
    tag: "healthcenter",
    autoLaunchEvent: true
  };
  $scope.titleConfig = {
    title: {
      icon: "icon-basket",
      text: "...",
      back: {
        state: "app.directOffer",
        tooltip: "Retour à la gestion des tarifs"
      }
    }
  };
  $scope.importConfig = {
    entityName: "LaboratoryPriceRangeProduct",
    callbackEvent: "addProductPriceFromImport"
  };
  $scope.goToList = goToList;
  $scope.laboratoryPriceRangeId = $stateParams.id;
  $scope.submit = submit;
  $scope.removeProduct = removeProduct;
  $scope.removeHealthcenter = removeHealthcenter;
  loader();
  /**
   * Loader which get at first the environment
   */

  function loader() {
    loaderEnvironment().then(getLaboratoryPriceRange);
  }
  /**
   * Get the current environment
   *
   * @returns {Promise} The user access point
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.access = access;

      if ($scope.access.accessPointType === 1) {
        $scope.laboratoryId = access.entityId;
        $scope.productSearchConfig.must = {
          "laboratory.id": [access.entityId]
        };
      }

      return access;
    });
  }
  /** Get LaboratoryPriceRange. */


  function getLaboratoryPriceRange() {
    if ($stateParams.id) {
      var laboratoryPriceRange = {
        laboratoryId: $scope.laboratoryId,
        id: $stateParams.id
      };
      directOfferService.readPriceRange(laboratoryPriceRange).then(function (response) {
        $scope.laboratoryPriceRange = response;
        $scope.laboratoryPriceRange.healthcenters = response.healthcentersLight.map(function (healthcenter) {
          return {
            id: healthcenter.id,
            name: healthcenter.name,
            cip: healthcenter.cip
          };
        });
        $scope.laboratoryPriceRange.products = response.productsLight;
        $scope.titleConfig.title.text = response.name;
        $scope.priceRangeEditLoading = false;
      });
    } else {
      $scope.laboratoryPriceRange = {
        laboratoryId: $scope.access.entityId,
        isEnabled: true,
        isDefaultRange: true,
        isForAllHealthcenters: false,
        products: [],
        healthcenters: []
      };
      $scope.titleConfig.title.text = "Création de votre tarif";
      $scope.priceRangeEditLoading = false;
    }
  }
  /**
   * Add product price from import
   */


  $scope.$on("addProductPriceFromImport", function (event, items) {
    if (items) {
      $scope.items = items;
      $mdDialog.show($mdDialog.confirm().title("Finalisation de l'import").textContent("Confirmez-vous l'import de " + items.length + " produit(s) ?").ariaLabel("Import de produits").ok("Oui").cancel("Non")).then(function () {
        $mdDialog.cancel();
        return getProductsAfterSearch();
      }).then(function () {
        formatAndAddImportedProducts();
      });
    }
  });
  /**
   * Get product IDs from Elastic Search
   *
   * @returns {Promise} The products
   */

  function getProductsAfterSearch() {
    var queryProduct = {};
    queryProduct.product = [];
    angular.forEach($scope.items, function (item) {
      if (item.product_id) {
        queryProduct.product.push(item.product_id);
      }
    });
    return productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        angular.forEach($scope.items, function (item) {
          if (product.id === item.product_id) {
            item.name = product.name;
            item.code = product.cip;
          }
        });
      });
      return products;
    });
  }
  /**
   * Format the imported products
   */


  function formatAndAddImportedProducts() {
    var quantityOfProductsImported = 0;
    $scope.laboratoryPriceRange.products = $scope.items.map(function (item) {
      if (item.product_id) {
        quantityOfProductsImported++;
        return {
          id: item.product_id,
          code: item.code,
          name: item.name,
          laboratoryPrice: {
            manufacturerPriceWithoutVat: parseFloat(item.manufacturer_price_without_vat),
            discountPercentage: parseFloat(item.discount_percentage),
            startQuantity: item.start_quantity,
            endQuantity: item.end_quantity,
            minimumQuantity: item.minimum_quantity,
            packingQuantity: item.packing_quantity ? parseInt(item.packing_quantity) : 0
          }
        };
      }
    });
    notifyUserService.showSuccessToast(quantityOfProductsImported + " produit(s) ont été importés.");
  }
  /**
   * Submit laboratoryPriceRange.
   */


  function submit() {
    if ($scope.laboratoryPriceRangeId) {
      updateLaboratoryPriceRange();
    } else {
      createLaboratoryPriceRange();
    }
  }
  /**
   * Create laboratoryPriceRange.
   *
   * @returns {Promise} response
   */


  function createLaboratoryPriceRange() {
    $scope.createLoading = true;
    return directOfferService.createPriceRange($scope.laboratoryPriceRange).then(function (response) {
      notifyUserService.showSuccessToast("Le tarif est ajouté");
      $scope.createLoading = false;
      goToList();
      return response;
    });
  }
  /**
   * Update laboratoryPriceRange.
   *
   * @returns {Promise} response
   */


  function updateLaboratoryPriceRange() {
    $scope.createLoading = true;
    $scope.laboratoryPriceRange.id = $stateParams.id;
    return directOfferService.updatePriceRange($scope.laboratoryPriceRange).then(function (response) {
      notifyUserService.showSuccessToast("Le tarif est modifié");
      $scope.createLoading = false;
      goToList();
      return response;
    });
  }
  /**
   * Remove product from selection.
   *
   * @param {number} productId - The id of the product to remove.
   */


  function removeProduct(productId) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression du produit ?").ariaLabel("Suppression du produit").ok("Oui").cancel("Non")).then(function () {
      $scope.laboratoryPriceRange.products = $scope.laboratoryPriceRange.products.filter(function (product) {
        return product.id !== productId;
      });
      notifyUserService.showSuccessToast("Le produit a été supprimé.");
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Remove healthcenter from selection.
   *
   * @param {number} healthcenterId - The id of the healthcenter to remove.
   */


  function removeHealthcenter(healthcenterId) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression de la cible ?").ariaLabel("Suppression de la cible").ok("Oui").cancel("Non")).then(function () {
      $scope.laboratoryPriceRange.healthcenters = $scope.laboratoryPriceRange.healthcenters.filter(function (healthcenter) {
        return healthcenter.id !== healthcenterId;
      });
      notifyUserService.showSuccessToast("La cible a été supprimée.");
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Go to the laboratory direct offer page, price range tab
   */


  function goToList() {
    $state.go("app.directOffer", {
      activeTab: 2
    });
  }
  /**
   * Adding healthcenters to the laboratoryPriceRange
   *
   * @param {object} event - The event
   * @param {object} query - The query
   */


  $scope.$on("addHealthcentersInLaboratoryPriceRange", function (event, query) {
    var queryPharma = {};
    queryPharma.type = {};
    var groups = [];

    var _loop = function _loop(type) {
      if (type !== "groups") {
        if (typeof query[type] !== "undefined") {
          if (typeof queryPharma.type[type] === "undefined") {
            queryPharma.type[type] = [];
          }

          angular.forEach(query[type], function (obj) {
            if (type === "uga") {
              queryPharma.type[type].push(obj.name);
            } else {
              queryPharma.type[type].push(obj.id);
            }
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(query, "type")) {
        angular.forEach(query[type], function (group) {
          if (!_.find($scope.laboratoryPriceRange.healthcenters, function (element) {
            return group.id === element.id && element.entityTypeId === 3;
          })) {
            group.entityTypeId = 3;
            $scope.laboratoryPriceRange.healthcenters.push(group);
          }

          groups.push(group);
        });
      }
    };

    for (var type in query) {
      _loop(type);
    } // getPharmas with query results


    healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      angular.forEach(pharmas, function (pharma) {
        if (!_.find($scope.laboratoryPriceRange.healthcenters, function (participant) {
          return pharma.id === participant.id;
        })) {
          pharma.entityTypeId = 2;
          $scope.laboratoryPriceRange.healthcenters.push(pharma);
        }
      });
    });
  });
}

angular.module("app.directOffer").controller("directOfferPriceRangeEditController", DirectOfferPriceRangeEditController);