"use strict";

/* global Raven */
angular.module("exceptionOverwrite", []).config(["$provide", function ($provide) {
  $provide.decorator("$exceptionHandler", ["$delegate", "$injector", function ($delegate, $injector) {
    return function (exception) {
      var environmentService = $injector.get("environmentService");
      var $state = $injector.get("$state");
      var env = "Prod";

      if (window.location.href.indexOf("dev.") > -1) {
        env = "Dev";
      }

      if (window.location.href.indexOf("preprod.") > -1) {
        env = "Preprod";
      }

      if (window.location.href.indexOf("localhost") > -1) {
        env = "local";
      }

      if (env !== "local") {
        environmentService.getEnvironment().then(function (environment) {
          var user = environment.user;
          Raven.setTagsContext({
            environment: env,
            state: $state.current.name
          });

          if (user) {
            Raven.setUserContext({
              id: user.id,
              email: user.email,
              username: user.given + " " + user.familyBirth
            });
            Raven.setExtraContext({
              "Access ID": environment.id,
              "Entity ID": environment.entity.id,
              "Entity name": environment.entity.name,
              "Entity type": environment.accessPointType,
              "Rank ID": environment.rank.id,
              "Rank title": environment.rank.title
            });
          }

          Raven.captureException(exception);
        });
      } // eslint-disable-next-line no-console


      console.error(exception);
    };
  }]);
}]);