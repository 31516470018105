"use strict";
/**
 * Entity admin configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 * @param {object} entityAdminConfig -
 */

EntityAdminConfig.$inject = ["$stateProvider", "entityAdminConfig"];

function EntityAdminConfig($stateProvider, entityAdminConfig) {
  $stateProvider.state("app.entityAdmin", {
    url: "/administration",
    views: {
      "content@app": {
        templateUrl: entityAdminConfig.baseDir + "/views/main/entityAdmin.main.html",
        controller: "entityAdminMainController"
      }
    }
  }).state("app.entityAdmin.team", {
    url: "/equipe/:id/:slug",
    views: {
      "content@app": {
        templateUrl: entityAdminConfig.baseDir + "/views/team/entityAdmin.team.html",
        controller: "entityAdminTeamController"
      }
    }
  });
}

angular.module("app.entityAdmin").config(EntityAdminConfig).constant("entityAdminConfig", {
  baseDir: "app/main/entityAdmin",
  operatorsTeamName: "Opérateurs"
});