"use strict";
/**
 * Pack detail service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

PackDetailService.$inject = ["apiClient"];

function PackDetailService(apiClient) {
  return {
    read: function read(params) {
      return apiClient.get("/packs/".concat(params.packSlug), {
        wrappers: {
          response: "pack"
        }
      }).then(function (response) {
        return response.data;
      });
    },
    subscribe: function subscribe(params) {
      return apiClient.post("/packs/".concat(params.packSlug, "/subscribe"), {
        data: params
      }, {
        wrappers: {
          response: "pack"
        }
      }).then(function (response) {
        return response.data;
      });
    },
    hasSubscribed: function hasSubscribed(params) {
      return apiClient.get("/packs/".concat(params.packSlug, "/subscribed"), {
        wrappers: {
          response: "subscribed"
        }
      }).then(function (response) {
        return response.data;
      });
    },
    applyCoupon: function applyCoupon(_ref) {
      var packSlug = _ref.packSlug,
          couponCode = _ref.couponCode;
      return apiClient.get("/packs/".concat(packSlug, "/coupons/").concat(couponCode)).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.pack").service("packDetailService", PackDetailService);