"use strict";
/**
 * The patient vaccination service.
 *
 * @param {object} hdsApiClient - The proxy client.
 *
 * @returns {object}
 */

PatientVaccinationService.$inject = ["hdsApiClient"];

function PatientVaccinationService(hdsApiClient) {
  return {
    /**
     * Get the vaccination history for a patient.
     *
     * @param {number} customerId - The customer id.
     * @param {object} params - The query params.
     *
     * @returns {Promise}
     */
    getPatientVaccinationHistory: function getPatientVaccinationHistory(customerId) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return hdsApiClient("GET", "/customers/".concat(customerId, "/vaccinations/history"), {
        params: params
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the vaccinations information.
     *
     * @returns {Promise}
     */
    getVaccinations: function getVaccinations() {
      return hdsApiClient("GET", "/vaccinations").then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.patients.services").service("patientVaccinationService", PatientVaccinationService);