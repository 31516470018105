"use strict";
/**
 * Auth healthcenter access service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

AuthHcAccessService.$inject = ["apiClient"];

function AuthHcAccessService(apiClient) {
  return {
    /**
     * Get owned healthcenter by Id.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getOwnedHcById: function getOwnedHcById(params) {
      return apiClient.get("/access/healthcenter/".concat(params.id, "/owned"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search healthcenter.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    searchHc: function searchHc(params) {
      return apiClient.post("/healthcenters/search", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search grouping's healthcenter.
     *
     * @returns {Promise}
     */
    searchGroupsHc: function searchGroupsHc() {
      return apiClient.get("/healthcenters/grouping/search", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get healthcenter by CIP.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getHealthcenterByCip: function getHealthcenterByCip(params) {
      return apiClient.get("/healthcenters/cip/".concat(params.cip, "/register"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.auth").service("authHcAccessService", AuthHcAccessService);