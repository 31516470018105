"use strict";
/**
 * PharmaMLAnalysisController controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} StatisticsQueryService - The statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} $state - The angular $state object.
 * @param {object} notifyUserService - The notify user service.
 * @param {object} $mdMenu - The angular $mdMenu object.
 * @param {object} analysisService - Analysis service.
 * @param {object} disruptionService - The disruption service.
 */

PharmaMLAnalysisController.$inject = ["$scope", "StatisticsQueryService", "environmentService", "$mdDialog", "$state", "notifyUserService", "$mdMenu", "analysisService", "disruptionService"];
DetailSupplyingController.$inject = ["$mdDialog", "aggBy", "usrQuery", "data", "$state", "StatisticsQueryService", "analysisService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function PharmaMLAnalysisController($scope, StatisticsQueryService, environmentService, $mdDialog, $state, notifyUserService, $mdMenu, analysisService, disruptionService) {
  $scope.aggBySelect = StatisticsQueryService.aggBy;
  $scope.currentState = $state.current.name;
  $scope.hide = hide;
  $scope.open = open;
  $scope.getNegativePharmaML = getNegativePharmaML;
  $scope.hideColumn = {};
  $scope.aggByName = $scope.aggBySelect[1].title;
  $scope.aggregateBy = $scope.aggBySelect[1].val;
  $scope.aggBy = {};
  $scope.aggBy.model = JSON.stringify($scope.aggBySelect[1]);
  $scope.searchByEnv = {
    1: [],
    2: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id"],
    3: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id"]
  };
  $scope.loaderPharmaMlAnalysis = {
    loading: true,
    loader: "Récupération des pharmaML à analyser",
    empty: "Aucun pharmaML à analyser"
  };
  $scope.dataOk = false;
  $scope.data = null;
  /**
   * Launch both queries.
   */

  function launchQueries() {
    $scope.processQuery();
    getNegativePharmaML();
  }
  /**
   * The loading function.
   */


  function loader() {
    environmentService.getEnvironment().then(function (env) {
      $scope.currentEnv = env;
      $scope.config = {
        event: "supplyingAnalysisFilterChange",
        search: $scope.searchByEnv[$scope.currentEnv.accessPointType],
        geoForce: true,
        showBadge: true,
        searchPlaceHolder: "Effectuer une recherche pour choisir les données à analyser"
      };
      StatisticsQueryService.queryRebase();
      $scope.usrQuery = StatisticsQueryService.query;
      $scope.usrQuery.date = {
        from: moment().subtract(1, "month"),
        to: moment()
      }; // We preselect the laboratory if the current env is a laboratory one

      if ($scope.currentEnv.accessPointType === 1) {
        var laboratory = {
          id: $scope.currentEnv.entityId,
          name: $scope.currentEnv.entity.name,
          type: "laboratory"
        };

        var laboratoryMust = _.find($scope.usrQuery.must.laboratory.selected, function (labo) {
          return labo.id === laboratory.id;
        });

        if (!laboratoryMust) {
          laboratoryMust = _.find($scope.usrQuery.should.laboratory.selected, function (labo) {
            return labo.id === laboratory.id;
          });
        }

        if (!laboratoryMust) {
          $scope.usrQuery.must.laboratory.selected.push(laboratory);
        }
      }

      launchQueries();
    });
  }

  loader();
  $scope.interval = {
    model: "auto",
    values: [{
      label: "Automatique",
      value: "auto"
    }, {
      label: "Jour",
      value: "1d",
      displayPattern: "DD MMM YYYY"
    }, {
      label: "Semaine",
      value: "1w",
      displayPattern: "semaine W YYYY"
    }, {
      label: "Mois",
      value: "1M",
      displayPattern: "MMM YYYY"
    }]
  };
  $scope.toggleCb = toggleCb;
  $scope.indexByLabel = indexByLabel;
  $scope.existsFilter = existsFilter;
  /**
   * Date Filter.
   *
   * @param {object} date - The date.
   */

  $scope.rangeSelected = function (date) {
    $scope.usrQuery.date.from = moment(date.startDate, "DD-MM-YYYY");
    $scope.usrQuery.date.to = moment(date.endDate, "DD-MM-YYYY");
    launchQueries();
  };

  $scope.paginationSupplying = {
    order: "-delivered.sum",
    limit: 10,
    page: 1
  };
  $scope.rawValues = StatisticsQueryService.values;
  $scope.getLimits = getLimits;
  /**
   * Open.
   *
   * @param {object} event - The event.
   */

  function open(event) {
    $mdMenu.open(event);
  }
  /**
   * toggleCheckboxes
   *
   * @param {object} item - The item
   * @param {object} list - The list
   */


  function toggleCb(item, list) {
    $scope.pagination.page = 1;
    var idx = $scope.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    launchQueries();
  }
  /**
   * Index by label.
   *
   * @param {string} name - The name
   * @param {Array} list - The array of values
   *
   * @returns {number}
   */


  function indexByLabel(name, list) {
    if (!name || !list) {
      return -1;
    }

    for (var index = 0, length = list.length; index < length; index++) {
      if (list[index].label === name) {
        return index;
      }
    }

    return -1;
  }
  /**
   * Exists filter.
   *
   * @param {string} name - The name
   * @param {Array} list - The array of values
   *
   * @returns {boolean}
   */


  function existsFilter(name, list) {
    return $scope.indexByLabel(name, list) > -1;
  }
  /**
   * Get limits.
   *
   * @param {object} array - The limits
   * @returns {Array}
   */


  function getLimits(array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  }
  /**
   * processQuery
   */


  $scope.processQuery = function () {
    $scope.data = [];
    $scope.dataOk = false;
    $scope.loaderPharmaMlAnalysis.loading = true;
    StatisticsQueryService.buildquery($scope.usrQuery, $scope.usrQuery.date).then(function (query) {
      $scope.query = query;
      $scope.dateLabel = moment($scope.usrQuery.date.from).format("DD-MMM-YYYY") + " Au " + moment($scope.usrQuery.date.to).format("DD-MMM-YYYY");
      query.aggBy = $scope.aggregateBy;
      query.sort = $scope.paginationSupplying;
      query.interval = $scope.interval.model;
      analysisService.searchProductSupplying(query).then(function (response) {
        $scope.data = response.data;
        $scope.data.totalDistinct = response.data.length;
        $scope.dataOk = true;
        $scope.loaderPharmaMlAnalysis.loading = false;
      });
    });
  };
  /**
   * Hide.
   *
   * @param {*} event -
   * @param {*} column -
   */


  function hide(event, column) {
    event.stopPropagation();
    $scope.hideColumn[column] = !$scope.hideColumn[column];
  }
  /**
   * Add data in filters.
   *
   * @param {object} line - The line of the table clicked.
   */


  $scope.addInFilter = function (line) {
    var type = JSON.parse($scope.aggBy.model).type;

    if (type) {
      var data = {
        name: line.key,
        id: line.key_id
      };
      $scope.usrQuery.must[type].selected.push(data);
      launchQueries();
    } else {
      notifyUserService.showSimpleToast("Impossible d'ajouter " + line.key + " aux filtres", {
        color: "error",
        hideDelay: 2000
      });
    }
  };
  /**
   * Go detail
   *
   * @param {object} data - The data
   */


  $scope.goDetail = function (data) {
    $mdDialog.show({
      locals: {
        data: data,
        aggBy: $scope.aggregateBy,
        usrQuery: $scope.usrQuery,
        scope: $scope
      },
      controller: DetailSupplyingController,
      controllerAs: "dsc",
      templateUrl: "app/main/statistics/stocks/supplying/supplyingDialog.tmpl.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  };
  /**
   * Open eclatment dialog.
   *
   * @param {object} event - The click event.
   */


  $scope.openDialogEclatement = function (event) {
    DialogEclatementController.$inject = ["$scope", "$mdDialog", "items", "aggBy", "currentState"];
    var parentEl = angular.element(document.body);
    $mdDialog.show({
      parent: parentEl,
      targetEvent: event,
      templateUrl: "app/main/statistics/template/eclatement.tmpl.html",
      locals: {
        items: $scope.aggBySelect,
        aggBy: $scope.aggBy,
        currentState: $scope.currentState
      },
      controller: DialogEclatementController
    });
    /**
     * Dialog eclatement controller.
     *
     * @param {object} $scope - The angular $scope object.
     * @param {object} $mdDialog - The angular $mdDialog object.
     * @param {object} items - The items.
     * @param {string} aggBy - The aggby.
     * @param {object} currentState - The current state.
     */

    function DialogEclatementController($scope, $mdDialog, items, aggBy, currentState) {
      /**
       * Chunk.
       *
       * @param {Array} arr - The array.
       * @param {number} size - The size to chunk on.
       *
       * @returns {Array}
       */
      function chunk(arr, size) {
        var newArr = [];

        for (var index = 0; index < arr.length; index += size) {
          newArr.push(arr.slice(index, index + size));
        }

        return newArr;
      }

      $scope.currentState = currentState;
      $scope.items = chunk(items, 8);
      $scope.aggBy = aggBy; // eslint-disable-next-line consistent-return

      $scope.canEclate = function (item) {
        if (typeof $scope.currentEnv === "undefined") {
          environmentService.getEnvironment().then(function (env) {
            $scope.currentEnv = env;

            if (typeof item.rankNeeded !== "undefined") {
              return item.rights.indexOf($scope.currentEnv.accessPointType) > -1 && item.rankNeeded.indexOf($scope.currentEnv.rankId) > -1;
            }

            if (item.rights.indexOf("Administrator") > -1) {
              return $scope.currentEnv.user.role === "Administrator";
            }

            return item.rights.indexOf($scope.currentEnv.accessPointType) > -1;
          });
        } else if (typeof item.rankNeeded !== "undefined") {
          return item.rights.indexOf($scope.currentEnv.accessPointType) > -1 && item.rankNeeded.indexOf($scope.currentEnv.rankId) > -1;
        } else {
          if (item.rights.indexOf("Administrator") > -1) {
            return $scope.currentEnv.user.role === "Administrator";
          }

          return item.rights.indexOf($scope.currentEnv.accessPointType) > -1;
        }
      };

      $scope.flexResult = function (array) {
        if (array.length >= 10) {
          return 50;
        }

        return 100;
      };

      $scope.eclateBy = function (agg) {
        var parsedAgg = JSON.parse(agg);
        $scope.aggregateBy = parsedAgg.val;
        $scope.aggBy = {};
        $scope.aggBy.model = agg;
        $scope.aggByName = parsedAgg.title + (parsedAgg.label ? " " + parsedAgg.label : "");

        if ($scope.aggregateBy === "none") {
          $scope.display100Percent = 0;
        }

        $scope.loading = true;
        launchQueries();
        $mdDialog.hide();
      };
    }
  };

  $scope.pagination = {
    limit: 10,
    page: 1,
    order: "-_source.datetime"
  };
  $scope.loaderDisruptionsOptions = {
    loading: true,
    loader: "Chargement de vos ruptures",
    empty: "Aucune rupture"
  };
  $scope.usrQuery = StatisticsQueryService.query;
  $scope.usrQuery.date = {
    from: moment().subtract(1, "month"),
    to: moment()
  };
  /**
   * getNegativePharmaML
   */

  function getNegativePharmaML() {
    $scope.negativePharmaMLs = [];
    $scope.loaderDisruptionsOptions.loading = true;
    StatisticsQueryService.buildquery($scope.usrQuery, $scope.usrQuery.date).then(function (queryBuilt) {
      var params = _objectSpread(_objectSpread({}, queryBuilt), $scope.pagination);

      disruptionService.getNegativePharmaML(params).then(function (negativePharmaML) {
        $scope.negativePharmaMLs = negativePharmaML.hits.hits;
        $scope.totalHits = negativePharmaML.hits.total;
        $scope.loaderDisruptionsOptions.loading = false;
      });
    });
  }
  /**
   * Event of the search bar.
   *
   * @param {object} event - The event.
   * @param {object} searchUsrProductquery - The query.
   */


  $scope.$on("supplyingAnalysisFilterChange", function (event, searchUsrProductquery) {
    $scope.loadingProduct = true;
    $scope.paginationSupplying.page = 1;
    angular.forEach(_.keys(searchUsrProductquery), function (obj) {
      angular.forEach(searchUsrProductquery[obj], function (data) {
        if (obj === "product") {
          $scope.usrQuery.must[obj].selected = [data];
        } else if ($scope.usrQuery.must[obj] && $scope.usrQuery.must[obj].selected.indexOf(data) === -1) {
          $scope.usrQuery.must[obj].selected.push(data);
        }
      });
    });
    launchQueries();
    $scope.loadingProduct = false;
  });
}
/**
 * Detail supplying controller.
 *
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {string} aggBy - The aggregations of the orders.
 * @param {object} usrQuery - The query.
 * @param {object} data - The data passed to the modal.
 * @param {object} $state - The angular $state object.
 * @param {object} StatisticsQueryService - The query service.
 * @param {object} analysisService - Analysis service.
 */


function DetailSupplyingController($mdDialog, aggBy, usrQuery, data, $state, StatisticsQueryService, analysisService) {
  var dsc = this;
  dsc.data = data;
  dsc.pharmaml = [];
  dsc.paginationDetail = {
    order: "-datetime",
    limit: 10,
    page: 1
  };
  dsc.getDetailData = getDetailData;
  dsc.goToAudit = goToAudit;
  getDetailData();
  /**
   * Go to healthcenter audit.
   *
   * @param {object} line - The data pass to the function.
   */

  function goToAudit(line) {
    $state.go("app.healthcenterAudit.detail.general", {
      id: line.key_id
    });
    $mdDialog.hide();
  }
  /**
   * Get detail data.
   */


  function getDetailData() {
    dsc.loading = true;
    StatisticsQueryService.buildquery(usrQuery, usrQuery.date).then(function (query) {
      dsc.query = query;
      dsc.dateLabel = moment(usrQuery.date.from).format("DD-MMM-YYYY") + " Au " + moment(usrQuery.date.to).format("DD-MMM-YYYY");
      query.aggBy = aggBy;
      query.sort = dsc.paginationDetail;
      analysisService.productSupplyingProvider(data.key_id, query).then(function (response) {
        var data = [];
        angular.forEach(response.data, function (line) {
          if (line.item.state.message === null) {
            line.item.state.message = "";
          }

          data.push(line);
        });
        dsc.pharmaml = data;
        dsc.pharmaml.totalDistinct = response.totalDistinct;
        dsc.loading = false;
      });
    });
  }

  dsc.hide = function () {
    $mdDialog.hide();
  };

  dsc.cancel = function () {
    $mdDialog.cancel();
  };

  dsc.iconState = function (line) {
    if (line.item.quantity.order === line.item.quantity.delivery) {
      return "<i class='material-icons green-500-fg' title='Livraison totale'>star</i>";
    }

    if (line.item.quantity.order === line.item.quantity.missing) {
      return "<i class='material-icons red-500-fg' title='Aucune livraison'>star</i>";
    }

    if (line.item.quantity.missing > 0) {
      return "<i class='material-icons orange-500-fg' title='Livraison partielle'>star_half</i>";
    }

    if (line.item.quantity.missing < 0) {
      return "<i class='material-icons blue-500-fg'' " + "title='Livraison supérieure à la commande (Colisage minimum)'>star</i>";
    }

    return "";
  };
}

angular.module("app.disruption").controller("pharmaMLAnalysisController", PharmaMLAnalysisController);