"use strict";
/**
 * Notification service to user.
 *
 * @param {object} $mdToast - $mdToast.
 * @returns {object}
 */

notifyUserService.$inject = ["$mdToast"];

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function notifyUserService($mdToast) {
  /**
   * Backward compatibility to display a default simple toast.
   *
   * @param {string} text - Text to display
   * @param {number} hideDelay - Delay before the notification disappears
   * @param {string} position - Position in the web view
   */
  function backwardCompatibleSimpleToast(text, hideDelay, position) {
    var time = hideDelay ? hideDelay : 3000;
    var display = position ? position : "top right";
    $mdToast.show($mdToast.simple().textContent(text).position(display).hideDelay(time));
  }
  /**
   * Display a simple toast with a template in the web view.
   *
   * @param {string} text - Text to display
   * @param {object} options - Options
   */


  function simpleToast(text, options) {
    var defaultOptions = {
      hideDelay: 3000,
      position: "top right",
      color: "default"
    };
    var finalOptions = Object.assign(defaultOptions, options);
    $mdToast.show($mdToast.simple({
      template: "<md-toast md-theme=\"{{ toast.theme }}\" ng-class=\"{'md-capsule': toast.capsule}\">" + "  <div class=\"md-toast-content md-toast-color-" + finalOptions.color + "\">" + "    <span flex class=\"md-toast-text\" role=\"alert\" aria-relevant=\"all\" aria-atomic=\"true\">" + "      {{ toast.content }}" + "    </span>" + "    <md-button class=\"md-action\" ng-if=\"toast.action\" ng-click=\"toast.resolve()\" " + "        ng-class=\"highlightClasses\">" + "      {{ toast.action }}" + "    </md-button>" + "  </div>" + "</md-toast>"
    }).textContent(text).position(finalOptions.position).hideDelay(finalOptions.hideDelay));
  }
  /**
   * Display a toast.
   *
   * @param {string} text - Text to display
   * @param {number} hideDelay - Delay before the notification disappears
   * @param {string} position - Position in the web view
   * @returns {void}
   */


  function showSimpleToast(text, hideDelay, position) {
    if (_typeof(hideDelay) !== "object") {
      return backwardCompatibleSimpleToast(text, hideDelay, position);
    }

    return simpleToast(text, hideDelay);
  }
  /**
   * Display a colored toast.
   *
   * @param {string} color - The color to display.
   * @returns {void}
   */


  function showColorToast(color) {
    return function (text, options) {
      var defaultOptions = {
        color: color
      };
      var finalOptions = Object.assign(defaultOptions, options);
      return simpleToast(text, finalOptions);
    };
  }

  return {
    showSimpleToast: showSimpleToast,
    showDangerToast: showColorToast("danger"),
    showSuccessToast: showColorToast("success"),
    showWarningToast: showColorToast("warning")
  };
}

angular.module("app.services").factory("notifyUserService", notifyUserService);