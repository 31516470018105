"use strict";
/**
 * PatientAdministrationProductsPathologyController
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} productService - The product service.
 * @param {object} pathologyService - The pathology service.
 * @param {object} notifyUserService - The notify user service.
 */

PatientAdministrationProductsPathologyController.$inject = ["$scope", "$rootScope", "$mdDialog", "productService", "pathologyService", "notifyUserService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function PatientAdministrationProductsPathologyController($scope, $rootScope, $mdDialog, productService, pathologyService, notifyUserService) {
  $scope.configAddProductSearchBar = {
    event: "productsSelected",
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct", "category.levelX.id"],
    tag: "pathologiesProductsChange",
    geoForce: true,
    searchPlaceHolder: "Ajouter un produit...",
    autoLaunchEvent: false
  };
  $scope.configSearchProductSearchBar = {
    event: "productSearch",
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct", "category.levelX.id"],
    tag: "pathologiesProductSearch",
    geoForce: true,
    searchPlaceHolder: "Filtrer les produits de votre sélection..."
  };
  $scope.query = {
    order: "name",
    limit: 10,
    page: 1,
    total: null,
    search: ""
  };
  $scope.loading = false;
  $scope.attachProduct = attachProducts;
  $scope.deleteProduct = deleteProduct;
  $scope.getPathologyProducts = getPathologyProducts;
  $scope.resetProductSearchFilter = resetProductSearchFilter;
  $scope.deleteSearchProducts = deleteSearchProducts;
  $scope.detachAll = detachAll;
  $scope.tableProducts = [];
  $scope.productSearch = [];
  loader();
  /**
   * Loader function.
   */

  function loader() {
    if ($scope.selectedPathology) {
      $scope.loading = true;
      getPathologyProducts().then(function (products) {
        $scope.products = products;
        $scope.loading = false;
      });
    }
  }
  /**
   * Get pathology products.
   *
   * @returns {Promise}
   */


  function getPathologyProducts() {
    var params = [{
      name: "limit",
      value: 10
    }, {
      name: "page",
      value: $scope.query.page
    }];
    $scope.productSearch.forEach(function (product) {
      params.push({
        name: "ids[]",
        value: product.id
      });
    });
    $scope.loading = true;
    return pathologyService.indexProductsForPathology($scope.selectedPathology.id, params).then(function (response) {
      $scope.query.total = response.data.length;
      $scope.tableProducts = formatProducts(response.data);
      $scope.loading = false;
      return response.data;
    });
  }
  /**
   * Delete a product from the list.
   *
   * @param {number} productId - The product id.
   */


  function deleteProduct(productId) {
    $scope.products = $scope.products.filter(function (product) {
      return product.id !== productId;
    });
    attachProducts();
  }
  /**
   * Detach all products.
   */


  function detachAll() {
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain(e) de vouloir supprimer tous les produits associés ?").ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      $scope.products = [];
      attachProducts();
    });
  }
  /**
   * Format products for display.
   *
   * @param {Array} products - The products
   *
   * @returns {Array}
   */


  function formatProducts(products) {
    return products.map(function (product) {
      return _objectSpread(_objectSpread({}, product), {}, {
        cip: product.id_7
      });
    });
  }
  /**
   * Attach selected products to the pathology.
   *
   * @returns {Promise}
   */


  function attachProducts() {
    return pathologyService.attachProductsToPathology($scope.selectedPathology.id, $scope.products.map(function (product) {
      return product.id;
    })).then(function (response) {
      notifyUserService.showSuccessToast("".concat(Object.keys(response.data.detached).length, " produit(s) supprim\xE9(s) et ").concat(response.data.attached.length, " produit(s) li\xE9(s)"));
      $scope.productSearch = [];
      getPathologyProducts().then(function (products) {
        $scope.products = products;
      });
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur s'est produite");
    });
  }
  /**
   * Reset filter from product search search bar.
   */


  function resetProductSearchFilter() {
    $scope.productSearch = [];
    getPathologyProducts();
  }
  /**
   * Delete products from search.
   */


  function deleteSearchProducts() {
    $scope.products = $scope.products.filter(function (product) {
      return $scope.productSearch.findIndex(function (searchProduct) {
        return searchProduct.id === product.id;
      }) === -1;
    });
    attachProducts();
  }

  $rootScope.$on("productSearch", function (event, data) {
    if (_.isUndefined($scope.productSearch)) {
      $scope.productSearch = [];
    }

    getProductsFromSearchBar(event, data).then(function (products) {
      $scope.productSearch = products;
      getPathologyProducts();
    });
  });
  $rootScope.$on("productsSelected", function (event, data) {
    if (_.isUndefined($scope.products)) {
      $scope.products = [];
    }

    getProductsFromSearchBar(event, data).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.products.push(product);
        }
      });
      attachProducts();
    });
  });
  /**
   * Get products from search bar.
   *
   * @param {object} event - The searchbar event.
   * @param {object} data - The searchbar data.
   *
   * @returns {Promise<[]>}
   */

  function getProductsFromSearchBar(event, data) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    }

    return productService.postPresentationByType(queryProduct).then(function (products) {
      var result = [];
      angular.forEach(products, function (product) {
        if (!_.find(result, function (prod) {
          return product.id === prod.id;
        })) {
          result.push(product);
        }
      });
      return result;
    });
  }
}

angular.module("app.patient-administration").controller("patientAdministrationProductsPathologyController", PatientAdministrationProductsPathologyController);