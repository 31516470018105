"use strict";
/**
 * Sellout factory.
 *
 * @returns {object}
 */

function SelloutFactory() {
  /**
   * Generate a unit reward teaser.
   *
   * @param {number} gain - The gain amount.
   * @param {object} remuneration - The remuneration object.
   *
   * @returns {string} The generated teaser.
   */
  function generateUnitTeaser(gain, remuneration) {
    return "Gagnez " + gain + " " + remuneration.label;
  }
  /**
   * Generate an objective reward teaser.
   *
   * @param {number} gain - The gain amount.
   * @param {object} remuneration - The remuneration object.
   * @param {number} goal - The goal amount.
   * @param {object} objective - The objective object.
   * @param {Date} startDate - The objective start date.
   * @param {Date} endDate - The objective end date.
   * @param {object} mode - The objective mode.
   *
   * @returns {string} teaser - The generated teaser.
   */


  function generateObjectiveTeaser(gain, remuneration, goal, objective, startDate, endDate, mode) {
    var teaser = "";
    teaser += "Gagnez " + gain + " " + remuneration.label;
    teaser += " dès";
    teaser += " " + goal;
    teaser += " ";
    teaser += objective.label;

    if (mode) {
      switch (mode.id) {
        case 1:
          teaser += " par l’ensemble des pharmacies de votre groupement";
          break;

        case 2:
          teaser += " par votre pharmacie";
          break;
      }
    }

    if (objective.isGrowth) {
      teaser += " sur une période de référence";
      teaser += " du ";
      teaser += moment(startDate).format("DD/MM/YYYY");
      teaser += " au ";
      teaser += moment(endDate).format("DD/MM/YYYY");
    }

    return teaser;
  }
  /**
   * Generate an detailed objective reward teaser.
   *
   * @param {number} gain - The gain amount.
   * @param {object} remuneration - The remuneration object.
   * @param {number} goal - The goal amount.
   * @param {object} objective - The objective object.
   * @param {Date} startDate - The objective start date.
   * @param {Date} endDate - The objective end date.
   * @param {object} mode - The objective mode.
   * @param {Array} referenceSales - The reference sales
   * @param {Array} healthcenters - The healthcenters
   *
   * @returns {Array} teasers - The generated array of teaser.
   */


  function generateDetailedObjectiveTeaser(gain, remuneration, goal, objective, startDate, endDate, mode, referenceSales, healthcenters) {
    var teasers = [];
    angular.forEach(healthcenters, function (healthcenter) {
      if (typeof referenceSales[healthcenter.id] !== "undefined") {
        var teaser = "";
        teaser += "Gain de " + gain + " " + remuneration.label;
        teaser += " dès";

        if (!objective.isPercent) {
          teaser += " " + goal;
          teaser += " ";
          teaser += objective.label;
        } else {
          var amount = Math.round(parseInt(referenceSales[healthcenter.id].quantity) * (1 + parseFloat(goal) / 100));
          teaser += " " + amount;
          teaser += amount > 1 ? " unités vendues" : " unité vendue";
        }

        teaser += " par la " + healthcenter.name;

        if (objective.isGrowth) {
          teaser += " sur une période de référence";
          teaser += " du ";
          teaser += moment(startDate).format("DD/MM/YYYY");
          teaser += " au ";
          teaser += moment(endDate).format("DD/MM/YYYY");
        }

        teasers.push(teaser);
      }
    });
    return teasers;
  }

  return {
    generateUnitTeaser: generateUnitTeaser,
    generateObjectiveTeaser: generateObjectiveTeaser,
    generateDetailedObjectiveTeaser: generateDetailedObjectiveTeaser
  };
}

angular.module("app.sellout").factory("selloutFactory", SelloutFactory);