"use strict";
/**
 * Statistics stat stock controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $q - The angular $q object.
 * @param {object} healthcenterAuditHcDetailsService - The healthcenter audit detail service.
 * @param {object} groupmentAuditDetailService - The groupment audit detail service.
 * @param {object} StatisticsQueryService - The statistics query service.
 * @param {object} $mdToast - The angular $mdToast object.
 * @param {object} environmentService - Environment service.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $state - The angular $state object.
 * @param {object} analysisService - Analysis service.
 */

StatisticsStatStockController.$inject = ["$scope", "$q", "healthcenterAuditHcDetailsService", "groupmentAuditDetailService", "StatisticsQueryService", "$mdToast", "environmentService", "$stateParams", "$state", "analysisService"];

function StatisticsStatStockController($scope, $q, healthcenterAuditHcDetailsService, groupmentAuditDetailService, StatisticsQueryService, $mdToast, environmentService, $stateParams, $state, analysisService) {
  var vm = this;
  vm.LabID = 0;
  vm.loading = true;
  vm.loadingChart = true;
  vm.deferredChartPieAmount0w60 = [];
  vm.deferredChartPieAmount60w90 = [];
  vm.deferredChartPieAmount0w90 = [];
  vm.legendStock = [];
  vm.legendSurStock = [];
  vm.deferredChartSerial = $q.defer();
  vm.dataChartSerial = [];
  vm.hasGraphsFiltered = [];
  vm.deferredChart = $q.defer();
  vm.config = {
    event: "statStockFilterChange",
    search: [],
    searchPlaceHolder: "Effectuer une recherche pour choisir les données à analyser"
  };
  vm.colors = ["#9ccc66", "#0b98a8", "#D27703", "#C88B05", "#0AD247", "#00BB87", "#0A65C8"];
  $scope.hasHealthcenterId = false;
  vm.rawValues = StatisticsQueryService.values;
  vm.toggleCb = toggleCb;
  vm.indexByLabel = indexByLabel;
  vm.existsFilter = existsFilter;
  /**
   * Toggle checkboxes
   *
   * @param {object} item - The item.
   * @param {Array} list - The list.
   */

  function toggleCb(item, list) {
    var idx = vm.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    vm.query = StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      launchSearch(query, true);
      vm.loading = true;
    });
  }

  vm.getLimits = function (array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  }; // Methods


  vm.removeFilter = removeFilter;
  /**
   * Index array by label.
   *
   * @param {string} name - The name.
   * @param {Array} list - The list.
   *
   * @returns {number}
   */

  function indexByLabel(name, list) {
    if (!name || !list) {
      return -1;
    }

    for (var index = 0, length = list.length; index < length; index++) {
      if (list[index].label === name) {
        return index;
      }
    }

    return list.findIndex(function (item) {
      return item.label === name;
    });
  }
  /**
   * Check if filter exists.
   *
   * @param {string} name - The name.
   * @param {Array} list - The list.
   *
   * @returns {boolean}
   */


  function existsFilter(name, list) {
    if (vm.genQuery) {
      return false;
    }

    return vm.indexByLabel(name, list) > -1;
  }
  /**
   * Remove filter.
   *
   * @param {object} obj - The item.
   * @param {Array} list - The list.
   */


  function removeFilter(obj, list) {
    var index = list.indexOf(obj);

    if (index !== -1) {
      list.splice(index, 1);
      vm.loading = true;
      vm.query = StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function () {
        launchQuery(true);
      });
    }
  }
  /**
   * Launch the query.
   */


  function launchQuery() {
    vm.usrquery = StatisticsQueryService.query;
    vm.query = StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function () {
      launchSearch(vm.query, true);
    });
  }

  var groupListLabo = [{
    value: 0,
    title: "Pharmacies",
    type: "pharmas",
    filterId: "cip"
  }, {
    value: 1,
    title: "Laboratoires",
    type: "laboratory",
    filterId: "id"
  }, {
    value: 2,
    title: "Départements",
    type: "departement",
    filterId: "id"
  }, {
    value: 3,
    title: "Régions"
  }, {
    value: 4,
    title: "UGA",
    type: "uga",
    filterId: "uga"
  }, {
    value: 5,
    title: "Groupements",
    type: "group",
    filterId: "id"
  }, {
    value: 6,
    title: "Gammes",
    type: "range",
    filterId: "id"
  }, {
    value: 7,
    title: "Catégories"
  }, {
    value: 8,
    title: "Segments"
  }, {
    value: 9,
    title: "Produits",
    type: "product",
    filterId: "id"
  }];
  var groupListGroup = [{
    value: 0,
    title: "Pharmacies",
    type: "pharmas",
    filterId: "cip"
  }, {
    value: 1,
    title: "Laboratoires",
    type: "laboratory",
    filterId: "id"
  }, {
    value: 2,
    title: "Départements",
    type: "departement",
    filterId: "id"
  }, {
    value: 3,
    title: "Régions"
  }, {
    value: 4,
    title: "UGA",
    type: "uga",
    filterId: "uga"
  }, {
    value: 6,
    title: "Gammes",
    type: "range",
    filterId: "id"
  }, {
    value: 7,
    title: "Catégories"
  }, {
    value: 8,
    title: "Segments"
  }, {
    value: 9,
    title: "Produits",
    type: "product",
    filterId: "id"
  }];
  var groupListPharma = [{
    value: 1,
    title: "Laboratoires",
    type: "laboratory",
    filterId: "id"
  }, {
    value: 6,
    title: "Gammes",
    type: "range",
    filterId: "id"
  }, {
    value: 7,
    title: "Catégories"
  }, {
    value: 8,
    title: "Segments"
  }, {
    value: 9,
    title: "Produits",
    type: "product",
    filterId: "id"
  }];

  vm.changeGroupByChart = function () {
    vm.loadingChart = true;
    launchSearch(vm.query);
  };

  vm.stepPosition = 0;
  vm.stepMax = 0;
  vm.stepNumberLine = 10;
  vm.stepNumberList = [10, 20, 50, 100];

  vm.stepChangeNumberLine = function () {
    vm.deferredChartSerial = $q.defer();
    vm.getAmchartOptionSerial();
    vm.deferredChartSerial.resolve(vm.dataChartSerial.slice(vm.stepPosition, vm.stepPosition + vm.stepNumberLine));
  };

  vm.stepBack = function () {
    vm.stepPosition = vm.stepPosition - vm.stepNumberLine;

    if (vm.stepPosition < 0) {
      vm.stepPosition = 0;
    }

    vm.deferredChartSerial = $q.defer();
    vm.getAmchartOptionSerial();
    vm.deferredChartSerial.resolve(vm.dataChartSerial.slice(vm.stepPosition, vm.stepPosition + vm.stepNumberLine));
  };

  vm.stepNext = function () {
    vm.stepPosition += vm.stepNumberLine;

    if (vm.stepPosition > vm.stepMax) {
      vm.stepPosition = vm.stepMax;
    }

    vm.deferredChartSerial = $q.defer();
    vm.getAmchartOptionSerial();
    vm.deferredChartSerial.resolve(vm.dataChartSerial.slice(vm.stepPosition, vm.stepPosition + vm.stepNumberLine));
  };
  /**
   * Initialization of options.
   *
   * @param {boolean} reinitPie - If we have to reinit pie data.
   */


  function initOptions(reinitPie) {
    // Set only a few export options
    var menuOptions = [{
      "class": "export-main",
      menu: [{
        label: "Télécharger",
        menu: ["PNG", "JPG", "CSV", "PDF"]
      }, {
        label: "Annoter",
        action: "draw",
        menu: [{
          "class": "export-drawing",
          menu: ["PNG", "JPG"]
        }]
      }]
    }];

    if (reinitPie) {
      vm.amChartOptionsPie0w90 = {
        data: [],
        type: "pie",
        theme: "light",
        startDuration: 0,
        marginTop: 5,
        marginBottom: 0,
        marginLeft: 5,
        marginRight: 5,
        mouseWheelScrollEnabled: true,
        thousandsSeparator: " ",
        unit: " EUR HT",
        categoryField: "title",
        valueField: "amount",
        colorField: "color",
        titleField: "title",
        precision: 0,
        balloonText: "[[title]] : [[percents]] %",
        labelsEnabled: true,
        labelText: "[[value]] €",
        pullOutRadius: 0,
        labelRadius: -80,
        legend: {
          enabled: true,
          labelText: "[[title]]",
          valueText: ""
        },
        "export": {
          enabled: true,
          menu: menuOptions
        }
      };
    }

    vm.getAmchartOptionSerial();
  }

  vm.stackTypeValue = "regular";

  vm.getAmchartOptionSerial = function () {
    vm.deferredChartSerial = $q.defer();
    vm.amChartOptionsSerial = {
      type: "serial",
      theme: "light",
      data: vm.deferredChartSerial.promise,
      thousandsSeparator: " ",
      percentPrecision: 0,
      precision: 0,
      startDuration: 0,
      rotate: true,
      angle: 30,
      depth3D: 10,
      mouseWheelScrollEnabled: true,
      listeners: [{
        event: "clickGraphItem",
        method: function method(obj) {
          if (vm.groupByChart.type !== undefined) {
            if (vm.groupByChart.value === 0) {
              healthcenterAuditHcDetailsService.hCDetailByCip(obj.item.dataContext.id).then(function (healthcenter) {
                $state.go("app.healthcenterAudit.detail.general", {
                  id: healthcenter.id
                });
              });
            } else {
              var addFilter = {};
              addFilter.name = obj.item.dataContext.healthcenter;
              addFilter[vm.groupByChart.filterId] = obj.item.dataContext.id;

              if (vm.groupByChart.filterId === "cip") {
                addFilter.id = obj.item.dataContext.id;
              }

              vm.usrquery.must[vm.groupByChart.type].selected.push(addFilter);
              StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
                vm.query = query;
                vm.loading = true;
                launchSearch(vm.query);
              });
            }
          } else {
            $mdToast.show($mdToast.simple().textContent("Pas de filtre sur cet élément").position("right").hideDelay(3000));
          }
        }
      }],
      legend: {
        autoMargins: true,
        borderAlpha: 0.8,
        useGraphSettings: true,
        horizontalGap: 10,
        markerSize: 10,
        valueAlign: "left",
        valueWidth: 0,
        clickMarker: handleLegendClick,
        clickLabel: handleLegendClick
      },
      chartCursor: {
        enabled: true,
        avoidBalloonOverlapping: false,
        balloonPointerOrientation: "vertical",
        oneBalloonOnly: true,
        cursorAlpha: 0,
        cursorColor: "#003D56"
      },
      chartScrollbar: {
        enabled: false
      },
      valueAxes: [{
        stackType: vm.stackTypeValue,
        gridColor: "#FFFFFF",
        axisAlpha: 0,
        gridAlpha: 0,
        labelsEnabled: true,
        position: "left",
        margin: 50
      }],
      graphs: [{
        hidden: vm.hasGraphsFiltered.range1 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 1,
        title: "Besoins",
        type: "column",
        lineColor: vm.colors[0],
        columnWidth: 0.70,
        valueField: "needs",
        id: "needs"
      }, {
        hidden: vm.hasGraphsFiltered.range2 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: "Surstocks",
        type: "column",
        lineColor: vm.colors[1],
        columnWidth: 0.70,
        valueField: "surstock",
        id: "surstock"
      }],
      autoMargins: true,
      autoDisplay: true,
      categoryField: "healthcenter",
      categoryAxis: {
        gridPosition: "start",
        position: "left",
        parseDates: false,
        labelFunction: function labelFunction(valueText) {
          if (valueText.length > 30) {
            return valueText.substring(0, 30) + "...";
          }

          return valueText;
        },
        listeners: [{
          event: "clickItem",
          method: function method(event) {
            if (vm.groupByChart.type !== undefined) {
              if (vm.groupByChart.value === 0) {
                healthcenterAuditHcDetailsService.hCDetailByCip(event.serialDataItem.dataContext.id).then(function (healthcenter) {
                  $state.go("app.healthcenterAudit.detail.general", {
                    id: healthcenter.id
                  });
                });
              }
            }
          }
        }, {
          event: "rollOverItem",
          method: function method(event) {
            event.target.setAttr("cursor", "pointer");
          }
        }, {
          event: "rollOutItem",
          method: function method(event) {
            event.target.setAttr("cursor", "default");
          }
        }]
      },
      "export": {
        enabled: true
      }
    };
  };

  vm.removeFilter = function (obj, list) {
    var index = list.indexOf(obj);

    if (index !== -1) {
      list.splice(index, 1);
      vm.loading = true;
      vm.query = StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date);
      launchSearch(vm.query, true);
    }
  };

  vm.stackType = function (stackType) {
    vm.deferredChartSerial = $q.defer();
    angular.forEach(vm.amChartOptionsSerial.graphs, function (dataRange) {
      if (stackType === "regular") {
        dataRange.labelText = "[[value]]€";
      } else {
        dataRange.labelText = "[[percents]]%";
      }
    });
    vm.stackTypeValue = stackType;

    if (stackType === "regular") {
      vm.dataChartSerial.sort(function (left, right) {
        return left.range1 - right.range1;
      });
    } else {
      vm.dataChartSerial.sort(function (left, right) {
        return left.range1Pc - right.range1Pc;
      });
    }

    vm.dataChartSerial = vm.dataChartSerial.reverse();
    vm.stepPosition = 0;
    vm.getAmchartOptionSerial();
    angular.forEach(vm.amChartOptionsSerial.graphs, function (dataRange) {
      if (stackType === "regular") {
        dataRange.labelText = "[[value]]€";
      } else {
        dataRange.labelText = "[[percents]]%";
      }
    });
    vm.deferredChartSerial.resolve(vm.dataChartSerial.slice(vm.stepPosition, vm.stepPosition + vm.stepNumberLine));
  };
  /**
   * Define function to handle legend clicks
   *
   * @param {object} graph - The clicked graph.
   *
   * @returns {boolean}
   */


  function handleLegendClick(graph) {
    var chart = graph.chart;
    var reShowAll = false;

    if (vm.hasGraphsFiltered[graph.id] === false) {
      for (var index = 0; index < chart.graphs.length; index++) {
        chart.showGraph(chart.graphs[index]);
        vm.hasGraphsFiltered[chart.graphs[index].id] = false;
      }

      reShowAll = true;
      vm.hasGraphsFiltered = [];
    }

    if (reShowAll === false) {
      for (var _index = 0; _index < chart.graphs.length; _index++) {
        if (graph.id === chart.graphs[_index].id) {
          chart.showGraph(chart.graphs[_index]);
          vm.hasGraphsFiltered[chart.graphs[_index].id] = false;
        } else {
          chart.hideGraph(chart.graphs[_index]);
          vm.hasGraphsFiltered[chart.graphs[_index].id] = true;
        }
      }
    }

    vm.dataChartSerial.sort(function (left, right) {
      return left[graph.valueField] - right[graph.valueField];
    });
    vm.dataChartSerial.reverse();
    vm.stepPosition = 0;
    vm.stepMax = vm.dataChartSerial.length; // return false so that default action is canceled

    return false;
  }

  var currentEnvironment = environmentService.getEnvironment();
  currentEnvironment.then(function (env) {
    vm.currentEnv = env;
    initTab();
  });
  /**
   * Initialize tab.
   */

  function initTab() {
    if (vm.currentEnv.accessPointType === 2) {
      vm.groupByList = groupListPharma;
      vm.groupByChart = vm.groupByList[0];
    } else if (vm.currentEnv.accessPointType === 1) {
      vm.groupByList = groupListLabo;
      vm.groupByChart = vm.groupByList[0];
    } else {
      vm.groupByList = groupListGroup;
      vm.groupByChart = vm.groupByList[0];
    }

    vm.usrquery = StatisticsQueryService.query;

    if (vm.currentEnv.accessPointType === 1 && vm.usrquery.must.laboratory.selected.length === 0) {
      var lab = {
        id: vm.currentEnv.entityId,
        name: vm.currentEnv.entity.name,
        type: "laboratory"
      };
      vm.usrquery.must.laboratory.selected.push(lab);
    } // Apply filter if direct access from segments list (healthcenters and products)


    if ($stateParams.segment) {
      if ($stateParams.segment.segmentTypeId === 1) {
        vm.usrquery.must.segmentHealthcenter.selected.push($stateParams.segment);
      } else if ($stateParams.segment.segmentTypeId === 2) {
        vm.usrquery.must.segmentProduct.selected.push($stateParams.segment);
      }
    }

    if ($stateParams.id) {
      $scope.hasHealthcenterId = true;
      healthcenterAuditHcDetailsService.hCDetail($stateParams.id).then(function (pharma) {
        var pharmaInfos = {
          id: pharma.healthcenter.id,
          name: pharma.healthcenter.name,
          type: "pharmas",
          cip: pharma.healthcenter.cip
        };
        vm.usrquery.must.pharmas.selected = [pharmaInfos];
        StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
          vm.query = query;
          launchSearch(query, true);
        });
      });
    }

    if ($stateParams.groupId) {
      groupmentAuditDetailService.one($stateParams).then(function (groupment) {
        $scope.group = groupment[0].grouping;
        var groupInfos = {
          id: $scope.group.id,
          name: $scope.group.name,
          type: "groups"
        };
        vm.usrquery.must.groups.selected = [groupInfos];
      });
    }

    if (!$scope.hasHealthcenterId) {
      StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
        vm.query = query;
        launchSearch(query);
      });
    }
  }
  /**
   * Launche the search/
   *
   * @param {object} obj - the query.
   */


  function launchSearch(obj) {
    initOptions(true);
    analysisService.getLaboratoryStockByGroup(vm.LabID, vm.groupByChart.value, obj).then(function (response) {
      vm.loading = false;
      vm.detailStocksProducts = response.hits.hits;
      vm.detailStocksGeneral = response.aggregations;
      processDataForAmCharts();
    });
  }
  /**
   * Process data for the carts.
   */


  function processDataForAmCharts() {
    vm.legendStock = {
      amount: 0
    };
    vm.legendSurStock = {
      amount: 0
    };
    var valueNeeds = {
      amount: 0
    };
    var valueSurstock = {
      amount: 0
    };
    angular.forEach(vm.detailStocksGeneral.range_days.buckets, function (data) {
      var nbRange = data.key;
      var colors = [{
        title: "Besoins",
        color: vm.colors[0]
      }, {
        title: "Surstock",
        color: vm.colors[1]
      }];

      if (nbRange < 4) {
        vm.legendStock = {
          color: colors[0].color,
          label: colors[0].title,
          amount: valueNeeds.amount + data.needs_amount.value
        };
        valueNeeds = {
          amount: valueNeeds.amount + data.needs_amount.value,
          color: colors[0].color,
          title: colors[0].title
        };
      } else {
        valueSurstock = {
          amount: valueSurstock.amount + data.order_amount.value,
          color: colors[1].color,
          title: colors[1].title
        };
        vm.legendSurStock = {
          color: colors[1].color,
          label: colors[1].title,
          amount: valueSurstock.amount + data.order_amount.value
        };
      }
    });
    vm.dataRangeGrossAmount0w90 = [valueNeeds, valueSurstock];
    vm.amChartOptionsPie0w90.data = vm.dataRangeGrossAmount0w90;
    var dataChartSerial = [];
    angular.forEach(vm.detailStocksGeneral.datas.buckets, function (dataHc) {
      var name = dataHc.key;
      var id = dataHc.id.buckets[0].key;
      var sumOrderAmount = 0;
      var serialLine = {
        healthcenter: name,
        id: id,
        needs: 0,
        range1Pc: 0,
        surstock: 0,
        range3: 0,
        range4: 0,
        range5: 0,
        range6: 0,
        range7: 0
      };
      angular.forEach(dataHc.range_days.buckets, function (dataRange) {
        var nbRange = dataRange.key + 1;

        if (nbRange < 4) {
          sumOrderAmount += dataRange.needs_amount.value;
          serialLine.needs = serialLine.needs + dataRange.needs_amount.value;
        } else {
          sumOrderAmount += dataRange.order_amount.value;
          serialLine.surstock = serialLine.surstock + dataRange.order_amount.value;
        }
      });

      if (sumOrderAmount !== 0) {
        serialLine.range1Pc = serialLine.needs / sumOrderAmount * 100;
      }

      dataChartSerial.push(serialLine);
    });
    dataChartSerial.sort(function (left, right) {
      return left.range1 - right.range1;
    });
    dataChartSerial.reverse();
    vm.stepPosition = 0;
    vm.stepMax = dataChartSerial.length;
    vm.dataChartSerial = dataChartSerial;
    vm.deferredChartSerial.resolve(dataChartSerial.slice(vm.stepPosition, vm.stepPosition + vm.stepNumberLine));
    vm.loading = false;
    vm.loadingChart = false;
  }

  $scope.$on("statStockFilterChange", function () {
    vm.loading = true;
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      vm.query = query;
      launchSearch(query, true);
    });
  });
}

angular.module("app.statistics.stocks").controller("StatisticsStatStockController", StatisticsStatStockController);