"use strict";
/**
 * Patient factory.
 *
 * @returns {object}
 */

function PatientFactory() {
  return {
    patient: {
      id: 1,
      name: "Brunet",
      firstName: "Thomas",
      socialSecurityNumber: "123465789456",
      address: {
        streetAddress: "3, rue du port",
        postalCode: "37000",
        city: "Tours"
      },
      phoneNumber: "012456987",
      email: "t.brunet@mail.com",
      birthDate: "12/10/1975"
    }
  };
}

angular.module("app.patients.factories.patients").factory("patientFactory", PatientFactory);