"use strict";
/**
 * Search patient service.
 *
 * @param {object} hdsApiClient - HDS API client.
 *
 * @returns {object}
 */

SearchPatientService.$inject = ["hdsApiClient"];

function SearchPatientService(hdsApiClient) {
  return {
    /**
     * Get search patients.
     *
     * @param {string} search -The search keywords.
     *
     * @returns {Promise}
     */
    getSearchPatients: function getSearchPatients(search) {
      return hdsApiClient("GET", "/patients?search=".concat(search, "&limit=10")).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.patients.services.searchService").service("searchPatientService", SearchPatientService);