"use strict";

(function () {
  "use strict";
  /**
   * Calendar.
   *
   * @returns {object}
   */

  function Calender() {
    return {
      restrict: "E",
      replace: false,
      require: ["^ngModel", "smCalender"],
      scope: {
        minDate: "=",
        maxDate: "=",
        initialDate: "=",
        format: "@",
        mode: "@",
        startView: "@",
        weekStartDay: "@",
        disableYearSelection: "@",
        dateSelectCall: "&"
      },
      controller: ["$scope", "$timeout", "picker", "$mdMedia", CalenderCtrl],
      controllerAs: "vm",
      templateUrl: "app/main/directives/date-picker/calender-date.html",
      link: function link(scope, element, attr, ctrls) {
        var ngModelCtrl = ctrls[0];
        var calCtrl = ctrls[1];
        calCtrl.configureNgModel(ngModelCtrl);
      }
    };
  }

  var CalenderCtrl = function CalenderCtrl($scope, $timeout, picker, $mdMedia) {
    var self = this;
    moment.locale("fr");
    self.$scope = $scope;
    self.$timeout = $timeout;
    self.picker = picker;
    self.dayHeader = self.picker.dayHeader;
    self.initialDate = $scope.initialDate;
    self.viewModeSmall = $mdMedia("xs");
    self.startDay = angular.isUndefined($scope.weekStartDay) || $scope.weekStartDay === "" ? "Monday" : $scope.weekStartDay;
    self.minDate = $scope.minDate ? $scope.minDate : moment(new Date("01/01/2016")); // Minimum date

    self.mode = angular.isUndefined($scope.mode) ? "DATE" : $scope.mode;
    self.format = $scope.format;
    self.restrictToMinDate = angular.isUndefined($scope.minDate) ? false : true;
    self.restrictToMaxDate = angular.isUndefined($scope.maxDate) ? false : true;
    self.stopScrollPrevious = false;
    self.stopScrollNext = false;
    self.disableYearSelection = $scope.disableYearSelection;
    self.monthCells = ["Jan", "Fev", "Mars", "Avr", "Mai", "Juin", "Juil", "Aout", "Sept", "Oct", "Nov", "Dec"];
    self.dateCellHeader = [];
    self.dateCells = [];
    self.monthList = ["Jan", "Fev", "Mars", "Avr", "Mai", "Juin", "Juil", "Aout", "Sept", "Oct", "Nov", "Dec"];
    self.moveCalenderAnimation = "";
    self.format = angular.isUndefined(self.format) ? "DD-MM-YYYY" : self.format;
    self.initialDate = angular.isUndefined(self.initialDate) ? moment() : moment(self.initialDate, self.format);
    self.currentDate = self.initialDate.clone();

    if (self.restrictToMinDate) {
      self.minDate = moment(self.minDate, self.format).subtract(1, "d");
    }

    if (self.restrictToMaxDate) {
      self.maxDate = moment(self.maxDate, self.format);
    }

    self.yearItems = {
      currentIndex_: 0,
      PAGE_SIZE: 7,
      START: 2016,
      END: moment().year(),
      getItemAtIndex: function getItemAtIndex(index) {
        if (this.currentIndex_ < index) {
          this.currentIndex_ = index;
        }

        if (this.START + index <= this.END) {
          return this.START + index;
        }

        return false;
      },
      getLength: function getLength() {
        if (this.END - this.START === 0) {
          return 1;
        }

        return this.END - this.START + 1;
      }
    };
    self.init();
  };

  CalenderCtrl.prototype.setInitDate = function (dt) {
    var self = this;
    self.initialDate = angular.isUndefined(dt) ? moment() : moment(dt, self.format);
  };

  CalenderCtrl.prototype.configureNgModel = function (ngModelCtrl) {
    var self = this;
    self.ngModelCtrl = ngModelCtrl;

    ngModelCtrl.$render = function () {
      self.ngModelCtrl.$viewValue = self.currentDate;
    };
  };

  CalenderCtrl.prototype.setNgModelValue = function (date) {
    var self = this;
    self.ngModelCtrl.$setViewValue(date);
    self.ngModelCtrl.$render();
  };

  CalenderCtrl.prototype.init = function () {
    var self = this;
    self.buildDateCells();
    self.buildDateCellHeader();
    self.buildMonthCells();
    self.setView();
    self.showYear();
  };

  CalenderCtrl.prototype.setView = function () {
    var self = this;
    self.headerDispalyFormat = "ddd, DD MMM ";

    switch (self.mode) {
      case "date-time":
        self.view = "DATE";
        self.headerDispalyFormat = "ddd, DD MMM  HH:mm";
        break;

      case "time":
        self.view = "HOUR";
        self.headerDispalyFormat = "HH:mm";
        break;

      default:
        self.view = "DATE";
    }
  };

  CalenderCtrl.prototype.showYear = function () {
    var self = this;
    self.yearTopIndex = self.initialDate.year() - self.yearItems.START + Math.floor(self.yearItems.PAGE_SIZE / 2);
    self.yearItems.currentIndex_ = self.initialDate.year() - self.yearItems.START + 1;
  };

  CalenderCtrl.prototype.buildMonthCells = function () {
    var self = this;
    self.monthCells = moment.months();
  };

  CalenderCtrl.prototype.buildDateCells = function () {
    var self = this;
    var currentMonth = self.initialDate.month();
    var calStartDate = self.initialDate.clone().date(0).day(self.startDay);
    var weekend = false;
    var isDisabledDate = false;
    /*
     Check if min date is greater than first date of month
     if true than set stopScrollPrevious=true
     */

    if (!angular.isUndefined(self.minDate)) {
      self.stopScrollPrevious = self.minDate.unix() > calStartDate.unix();
    }

    self.dateCells = [];

    for (var index1 = 0; index1 < 6; index1++) {
      var week = [];

      for (var index2 = 0; index2 < 7; index2++) {
        var isCurrentMonth = calStartDate.month() === currentMonth;
        isDisabledDate = !isCurrentMonth;

        if (self.restrictToMinDate && !angular.isUndefined(self.minDate) && !isDisabledDate) {
          isDisabledDate = self.minDate.isAfter(calStartDate);
        }

        if (self.restrictToMaxDate && !angular.isUndefined(self.maxDate) && !isDisabledDate) {
          isDisabledDate = self.maxDate.isBefore(calStartDate);
        }

        var day = {
          date: calStartDate.clone(),
          dayNum: isCurrentMonth ? calStartDate.date() : "",
          month: calStartDate.month(),
          today: calStartDate.isSame(moment(), "day") && calStartDate.isSame(moment(), "month") && isCurrentMonth,
          year: calStartDate.year(),
          dayName: calStartDate.format("dddd"),
          isWeekEnd: weekend,
          isDisabledDate: isDisabledDate,
          isCurrentMonth: isCurrentMonth
        };
        week.push(day);
        calStartDate.add(1, "d");
      }

      self.dateCells.push(week);
    }
    /*
     Check if max date is greater than first date of month
     if true than set stopScrollPrevious=true
     */


    if (self.restrictToMaxDate && !angular.isUndefined(self.maxDate)) {
      self.stopScrollNext = self.maxDate.unix() < calStartDate.unix() && self.initialDate.month() === self.maxDate.month();
    }

    if (self.dateCells[0][6].isDisabledDate && !self.dateCells[0][6].isCurrentMonth) {
      self.dateCells[0].splice(0);
    }
  };

  CalenderCtrl.prototype.changePeriod = function (param) {
    var self = this;

    if (param === "p") {
      if (self.stopScrollPrevious) {
        return;
      }

      self.moveCalenderAnimation = "slideLeft";
      self.initialDate.subtract(1, "M");
    } else {
      if (self.stopScrollNext) {
        return;
      }

      self.moveCalenderAnimation = "slideRight";
      self.initialDate.add(1, "M");
    }

    self.buildDateCells();
    self.$timeout(function () {
      self.moveCalenderAnimation = "";
    }, 500);
  };

  CalenderCtrl.prototype.selectDate = function (date, isDisabled) {
    var self = this;

    if (isDisabled) {
      return;
    }

    self.currentDate = date;
    self.$scope.dateSelectCall({
      date: date
    });
    self.setNgModelValue(date);
    self.$scope.$emit("calender:date-selected");
  };

  CalenderCtrl.prototype.buildDateCellHeader = function () {
    var self = this;
    var daysByName = self.picker.daysNames;
    var keys = [];

    for (var key in daysByName) {
      if (Object.prototype.hasOwnProperty.call(daysByName, key)) {
        keys.push(key);
      }
    }

    var startIndex = moment().day(self.startDay).day();
    var count = 0;
    daysByName.forEach(function () {
      self.dateCellHeader.push(daysByName[keys[(count + startIndex) % keys.length]]);
      count++; // Don't forget to increase count.
    });
  };
  /*
   Month Picker
   */


  CalenderCtrl.prototype.changeView = function (view) {
    var self = this;

    if (self.disableYearSelection) {
      return;
    }

    if (view === "YEAR_MONTH") {
      self.showYear();
    }

    self.view = view;
  };
  /*
   Year Picker
   */


  CalenderCtrl.prototype.changeYear = function (yr, mn) {
    var self = this;
    self.initialDate.year(yr).month(mn);
    self.buildDateCells();
    self.view = "DATE";
  };
  /*
   Hour and Time
   */


  CalenderCtrl.prototype.setHour = function (hour) {
    var self = this;
    self.currentDate.hour(hour);
  };

  CalenderCtrl.prototype.setMinute = function (minute) {
    var self = this;
    self.currentDate.minute(minute);
  };

  CalenderCtrl.prototype.selectedDateTime = function () {
    var self = this;
    self.setNgModelValue(self.currentDate);

    if (self.mode === "time") {
      self.view = "HOUR";
    } else {
      self.view = "DATE";
    }

    self.$scope.$emit("calender:close");
  };

  CalenderCtrl.prototype.closeDateTime = function () {
    var self = this;

    if (self.mode === "time") {
      self.view = "HOUR";
    } else {
      self.view = "DATE";
    }

    self.$scope.$emit("calender:close");
  };

  var app = angular.module("app.rangePicker", []);
  app.directive("smCalender", ["$timeout", "picker", Calender]);
})();

(function () {
  "use strict";
  /**
   * Time picker.
   *
   * @returns {object}
   */

  function TimePicker() {
    return {
      restrict: "E",
      replace: true,
      require: ["^ngModel", "smTime"],
      scope: {
        initialTime: "@",
        format: "@",
        timeSelectCall: "&"
      },
      controller: ["$scope", "$timeout", TimePickerCtrl],
      controllerAs: "vm",
      templateUrl: "app/main/directives/date-picker/calender-hour.html",
      link: function link(scope, element, att, ctrls) {
        var ngModelCtrl = ctrls[0];
        var calCtrl = ctrls[1];
        calCtrl.configureNgModel(ngModelCtrl);
      }
    };
  }

  var TimePickerCtrl = function TimePickerCtrl($scope, $timeout) {
    var self = this;
    self.uid = Math.random().toString(36).substr(2, 5);
    self.$scope = $scope;
    self.$timeout = $timeout;
    self.initialDate = $scope.initialTime; // if calender to be  initiated with specific date

    self.format = $scope.format;
    self.hourItems = [];
    self.minuteCells = [];
    self.format = angular.isUndefined(self.format) ? "HH:mm" : self.format;
    self.initialDate = angular.isUndefined(self.initialDate) ? moment() : moment(self.initialDate, self.format);
    self.currentDate = self.initialDate.clone();
    self.hourSet = false;
    self.minuteSet = false;
    self.show = true;
    self.init();
  };

  TimePickerCtrl.prototype.init = function () {
    var self = this;
    self.buidHourCells();
    self.buidMinuteCells();
    self.headerDispalyFormat = "HH:mm";
    self.showHour();
  };

  TimePickerCtrl.prototype.showHour = function () {
    var self = this;
    self.hourTopIndex = 22;
    self.minuteTopIndex = self.initialDate.minute() - 0 + Math.floor(7 / 2);
  };

  TimePickerCtrl.prototype.configureNgModel = function (ngModelCtrl) {
    this.ngModelCtrl = ngModelCtrl;
    var self = this;

    ngModelCtrl.$render = function () {
      self.ngModelCtrl.$viewValue = self.currentDate;
    };
  };

  TimePickerCtrl.prototype.setNgModelValue = function (date) {
    var self = this;
    self.ngModelCtrl.$setViewValue(date);
    self.ngModelCtrl.$render();
  };

  TimePickerCtrl.prototype.buidHourCells = function () {
    var self = this;

    for (var index = 0; index <= 23; index++) {
      var hour = {
        hour: index,
        isCurrent: self.initialDate.hour() === index
      };
      self.hourItems.push(hour);
    }
  };

  TimePickerCtrl.prototype.buidMinuteCells = function () {
    var self = this;
    self.minuteTopIndex = self.initialDate.minute();

    for (var index = 0; index <= 59; index++) {
      var minute = {
        minute: index,
        isCurrent: self.initialDate.minute() === index
      };
      self.minuteCells.push(minute);
    }
  };

  TimePickerCtrl.prototype.selectDate = function (date, isDisabled) {
    var self = this;

    if (isDisabled) {
      return;
    }

    self.currentDate = date;
    self.$scope.$emit("calender:date-selected");
  };

  TimePickerCtrl.prototype.setHour = function (hour) {
    var self = this;
    self.currentDate.hour(hour);
    self.setNgModelValue(self.currentDate);
    self.hourSet = true;

    if (self.hourSet && self.minuteSet) {
      self.$scope.timeSelectCall({
        time: self.currentDate
      });
      self.hourSet = false;
      self.minuteSet = false;
    }
  };

  TimePickerCtrl.prototype.setMinute = function (minute) {
    var self = this;
    self.currentDate.minute(minute);
    self.setNgModelValue(self.currentDate);
    self.minuteSet = true;

    if (self.hourSet && self.minuteSet) {
      self.$scope.timeSelectCall({
        time: self.currentDate
      });
      self.hourSet = false;
      self.minuteSet = false;
    }
  };

  TimePickerCtrl.prototype.selectedDateTime = function () {
    var self = this;
    self.setNgModelValue(self.currentDate);

    if (self.mode === "time") {
      self.view = "HOUR";
    } else {
      self.view = "DATE";
    }

    self.$scope.$emit("calender:close");
  };

  var app = angular.module("app.rangePicker");
  app.directive("smTime", ["$timeout", TimePicker]);
})();

(function () {
  "use strict";
  /**
   * Date picker dir.
   *
   * @returns {object}
   */

  function DatePickerDir() {
    return {
      restrict: "E",
      require: ["^ngModel", "smDatePicker"],
      replace: false,
      scope: {
        initialDate: "=",
        minDate: "=",
        maxDate: "=",
        format: "@",
        mode: "@",
        startDay: "@",
        closeOnSelect: "@",
        weekStartDay: "@",
        disableYearSelection: "@",
        onSelectCall: "&"
      },
      controller: ["$scope", "picker", "$mdMedia", PickerCtrl],
      controllerAs: "vm",
      bindToController: true,
      templateUrl: "picker/date-picker.html",
      link: function link(scope, element, att, ctrls) {
        var ngModelCtrl = ctrls[0];
        var calCtrl = ctrls[1];
        calCtrl.configureNgModel(ngModelCtrl);
      }
    };
  }

  var PickerCtrl = function PickerCtrl($scope, picker, $mdMedia) {
    var self = this;
    self.scope = $scope;
    self.okLabel = picker.okLabel;
    self.cancelLabel = picker.cancelLabel;
    self.picker = picker;
    self.$mdMedia = $mdMedia;
    self.init();
  };

  PickerCtrl.prototype.init = function () {
    var self = this;

    if (angular.isUndefined(self.mode) || self.mode === "") {
      self.mode = "date";
    }

    self.currentDate = isNaN(self.ngModelCtrl) ? moment() : self.ngModelCtrl.$viewValue;
    self.setViewMode(self.mode);
  };

  PickerCtrl.prototype.configureNgModel = function (ngModelCtrl) {
    var self = this;
    self.ngModelCtrl = ngModelCtrl;

    self.ngModelCtrl.$render = function () {
      self.ngModelCtrl.$viewValue = ngModelCtrl.$viewValue;
      self.ngModelCtrl.$modelvalue = ngModelCtrl.$modelvalue;
    };
  };

  PickerCtrl.prototype.setViewMode = function (mode) {
    var self = this;

    switch (mode) {
      case "date":
        self.view = "DATE";
        self.headerDispalyFormat = self.picker.customHeader.date;
        break;

      case "date-time":
        self.view = "DATE";
        self.headerDispalyFormat = self.picker.customHeader.dateTime;
        break;

      case "time":
        self.view = "TIME";
        self.headerDispalyFormat = "HH:mm";
        break;

      default:
        self.headerDispalyFormat = "ddd, MMM DD ";
        self.view = "DATE";
    }
  };

  PickerCtrl.prototype.setNextView = function () {
    var self = this;

    switch (self.mode) {
      case "date":
        self.view = "DATE";
        break;

      case "date-time":
        self.view = self.view === "DATE" ? "TIME" : "DATE";
        break;

      default:
        self.view = "DATE";
    }
  };

  PickerCtrl.prototype.selectedDateTime = function () {
    var self = this;
    var date = moment(self.selectedDate, this.format);

    if (!date.isValid()) {
      date = moment();
      moment.locale("fr");
      self.selectedDate = date;
    }

    if (!angular.isUndefined(self.selectedTime)) {
      date.hour(self.selectedTime.hour()).minute(self.selectedTime.minute());
    }

    self.setNgModelValue(date);
  };

  PickerCtrl.prototype.dateSelected = function (date) {
    var self = this;
    self.currentDate.date(date.date()).month(date.month()).year(date.year());
    self.selectedDate = self.currentDate;

    if (self.closeOnSelect && self.mode === "date") {
      self.selectedDateTime();
    } else {
      self.setNextView();
    }
  };

  PickerCtrl.prototype.timeSelected = function (time) {
    var self = this;
    self.currentDate.hours(time.hour()).minutes(time.minute());
    self.selectedTime = self.currentDate;

    if (self.closeOnSelect && self.mode === "date-time") {
      self.selectedDateTime();
    } else {
      self.setNextView();
    }
  };

  PickerCtrl.prototype.setNgModelValue = function (date) {
    var self = this;
    self.onSelectCall({
      date: date
    });
    self.ngModelCtrl.$setViewValue(date.format(self.format));
    self.ngModelCtrl.$render();
    self.closeDateTime();
  };

  PickerCtrl.prototype.closeDateTime = function () {
    this.view = "DATE";
    this.scope.$emit("calender:close");
  };
  /**
   * Time picker dir.
   *
   * @param {object} $timeout - $timeout.
   * @param {object} picker - Picker.
   * @param {object} $mdMedia - $mdMedia.
   *
   * @returns {object}
   */


  function TimePickerDir($timeout, picker, $mdMedia) {
    return {
      restrict: "E",
      require: "^ngModel",
      replace: true,
      scope: {
        initialDate: "@",
        format: "@",
        mode: "@",
        closeOnSelect: "@"
      },
      templateUrl: "app/main/directives/date-picker/time-picker.html",
      link: function link(scope, element, att, ngModelCtrl) {
        setViewMode(scope.mode);
        scope.okLabel = picker.okLabel;
        scope.cancelLabel = picker.cancelLabel;
        scope.currentDate = isNaN(ngModelCtrl.$viewValue) ? moment() : ngModelCtrl.$viewValue;
        scope.$mdMedia = $mdMedia;
        /**
         * Set view mode.
         *
         * @param {string} mode - Mode.
         */

        function setViewMode(mode) {
          switch (mode) {
            case "date-time":
              scope.view = "DATE";
              scope.headerDispalyFormat = "ddd, MMM DD HH:mm";
              break;

            case "time":
              scope.view = "HOUR";
              scope.headerDispalyFormat = "HH:mm";
              break;

            default:
              scope.view = "DATE";
          }
        }

        scope.$on("calender:date-selected", function () {
          if (scope.closeOnSelect && (scope.mode !== "date-time" || scope.mode !== "time")) {
            var date = moment(scope.selectedDate, scope.format);

            if (!date.isValid()) {
              date = moment();
              scope.selectedDate = date;
            }

            if (!angular.isUndefined(scope.selectedTime)) {
              date.hour(scope.selectedTime.hour()).minute(scope.selectedTime.minute());
            }

            scope.currentDate = scope.selectedDate;
            ngModelCtrl.$setViewValue(date.format(scope.format));
            ngModelCtrl.$render();
            setViewMode(scope.mode);
            scope.$emit("calender:close");
          }
        });

        scope.selectedDateTime = function () {
          var date = moment(scope.selectedDate, scope.format);

          if (!date.isValid()) {
            date = moment();
            scope.selectedDate = date;
          }

          if (!angular.isUndefined(scope.selectedTime)) {
            date.hour(scope.selectedTime.hour()).minute(scope.selectedTime.minute());
          }

          scope.currentDate = scope.selectedDate;
          ngModelCtrl.$setViewValue(date.format(scope.format));
          ngModelCtrl.$render();
          setViewMode(scope.mode);
          scope.$emit("calender:close");
        };

        scope.closeDateTime = function () {
          scope.$emit("calender:close");
        };
      }
    };
  }

  var app = angular.module("app.rangePicker");
  app.directive("smDatePicker", ["$timeout", "picker", "$mdMedia", "$window", DatePickerDir]);
  app.directive("smTimePicker", ["$timeout", "picker", "$mdMedia", "$window", TimePickerDir]);
})();
/**
 * Date time picker.
 *
 * @returns {object}
 */


function DateTimePicker() {
  return {
    restrict: "E",
    require: ["^ngModel", "smDateTimePicker"],
    scope: {
      weekStartDay: "@",
      startView: "@",
      mode: "@",
      format: "@",
      minDate: "@",
      maxDate: "@",
      fname: "@",
      label: "@",
      isRequired: "@",
      disable: "=",
      noFloatingLabel: "=",
      disableYearSelection: "@",
      closeOnSelect: "@",
      onDateSelectedCall: "&"
    },
    controller: ["$scope", "$element", "$mdUtil", "$mdMedia", "$document", SMDateTimePickerCtrl],
    controllerAs: "vm",
    bindToController: true,
    template: function template(element, attributes) {
      var inputType = "";

      if (Object.prototype.hasOwnProperty.call(attributes, "onFocus")) {
        inputType = "<input name=\"{{vm.fname}}\" ng-model=\"vm.value\" " + "  type=\"text\" placeholder=\"{{vm.label}}\"" + "  aria-label=\"{{vm.fname}}\" ng-click=\"vm.show()\" data-ng-required=\"vm.isRequired\"  ng-disabled=\"vm.disable\"" + "  server-error class=\"sm-input-container\" />";
      } else {
        inputType = "      <input class=\"\" name=\"{{vm.fname}}\" ng-model=\"vm.value\" " + "             type=\"text\" placeholder=\"{{vm.label}}\" " + "             aria-label=\"{{vm.fname}}\" aria-hidden=\"true\" data-ng-required=\"vm.isRequired\"  ng-disabled=\"vm.disable\"/>" + "     <md-button tabindex=\"-1\" class=\"sm-picker-icon md-icon-button\" aria-label=\"showCalender\" ng-disabled=\"vm.disable\" aria-hidden=\"true\" type=\"button\" ng-click=\"vm.show()\">" + "         <svg  fill=\"#000000\" height=\"24\" viewBox=\"0 0 24 24\" width=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M7 10l5 5 5-5z\"/><path d=\"M0 0h24v24H0z\" fill=\"none\"/></svg>" + "     </md-button>";
      }

      return "  <md-input-container class=\"sm-input-container md-icon-float md-block\" md-no-float=\"vm.noFloatingLabel\">" + inputType + "     <div id=\"picker\" class=\"sm-calender-pane md-whiteframe-z2\">" + "          <sm-date-picker " + "              id=\"{{vm.fname}}Picker\" " + "              initial-date=\"vm.value\"" + "              ng-model=\"vm.value\"" + "              mode=\"{{vm.mode}}\" " + "              disable-year-selection={{vm.disableYearSelection}}" + "              close-on-select=\"{{vm.closeOnSelect}}\"" + "              start-view=\"{{vm.startView}}\" " + "              data-min-date=\"vm.minDate\" " + "              data-max-date=\"vm.maxDate\"  " + "              data-format=\"{{vm.format}}\"  " + "              data-on-select-call=\"vm.onDateSelected(date)\"" + "              data-week-start-day=\"{{vm.weekStartDay}}\" > " + "         </sm-date-picker>" + "     </div>" + " </md-input-container>";
    },
    link: function link(scope, $element, attr, ctrl) {
      var ngModelCtrl = ctrl[0];
      var pickerCtrl = ctrl[1];
      pickerCtrl.configureNgModel(ngModelCtrl);
    }
  };
}

var SMDateTimePickerCtrl = function SMDateTimePickerCtrl($scope, $element, $mdUtil, $mdMedia, $document) {
  var self = this;
  self.$scope = $scope;
  self.$element = $element;
  self.$mdUtil = $mdUtil;
  self.$mdMedia = $mdMedia;
  self.$document = $document;
  self.isCalenderOpen = false;
  self.calenderHeight = 320;
  self.calenderWidth = 450; // find input button and assign to variable

  self.inputPane = $element[0].querySelector(".sm-input-container"); // find Calender Picker  and assign to variable

  self.calenderPane = $element[0].querySelector(".sm-calender-pane"); // button to start calender

  self.button = $element[0].querySelector(".sm-picker-icon");
  self.calenderPan = angular.element(self.calenderPane); // check if mode is undefied set to date mode

  self.mode = angular.isUndefined($scope.mode) ? "date" : $scope.mode; // check if Pre defined format is supplied

  self.format = angular.isUndefined($scope.format) ? "MM-DD-YYYY" : $scope.format;
  self.calenderPan.addClass("hide hide-animate");
  self.bodyClickHandler = angular.bind(self, self.clickOutSideHandler);
  self.$scope.$on("calender:close", function () {
    self.$document.off("keydown");
    self.hideElement();
  });
  self.$scope.$on("$destroy", function () {
    self.calenderPane.parentNode.removeChild(self.calenderPane);
  }); // if tab out hide key board

  angular.element(self.inputPane).on("keydown", function (event) {
    switch (event.which) {
      case 27:
      case 9:
        self.hideElement();
        break;
    }
  });
};

SMDateTimePickerCtrl.prototype.configureNgModel = function (ngModelCtrl) {
  var self = this;
  self.ngModelCtrl = ngModelCtrl;
  self.ngModelCtrl.$formatters.push(function (dateValue) {
    if (angular.isUndefined(dateValue)) {
      return;
    }

    if (!dateValue) {
      return;
    }

    self.setNgModelValue(dateValue);
  });
};

SMDateTimePickerCtrl.prototype.setNgModelValue = function (date) {
  var self = this;
  self.onDateSelectedCall({
    date: date
  });
  var data = {};

  if (moment.isMoment(date)) {
    data = date.format(self.format);
  } else {
    data = moment(date, self.format).format(self.format);
  }

  self.ngModelCtrl.$setViewValue(data);
  self.ngModelCtrl.$render();
  self.value = data;
};

SMDateTimePickerCtrl.prototype.onDateSelected = function (date) {
  var self = this;
  self.setNgModelValue(date);
};
/* get visiable port

 @param : elementnRect

 @param : bodyRect

 */


SMDateTimePickerCtrl.prototype.getVisibleViewPort = function (elementRect, bodyRect) {
  var self = this;
  var top = elementRect.top;

  if (elementRect.top + self.calenderHeight > bodyRect.bottom) {
    top = elementRect.top - (elementRect.top + self.calenderHeight - (bodyRect.bottom - 20));
  }

  var left = elementRect.left;

  if (elementRect.left + self.calenderWidth > bodyRect.right) {
    left = elementRect.left - (elementRect.left + self.calenderWidth - (bodyRect.right - 10));
  }

  return {
    top: top,
    left: left
  };
};

SMDateTimePickerCtrl.prototype.show = function () {
  var self = this;
  var elementRect = self.inputPane.getBoundingClientRect();
  var bodyRect = document.body.getBoundingClientRect();
  self.calenderPan.removeClass("hide hide-animate");

  if (self.$mdMedia("sm") || self.$mdMedia("xs")) {
    self.calenderPane.style.left = (bodyRect.width - 320) / 2 + "px";
    self.calenderPane.style.top = (bodyRect.height - 450) / 2 + "px";
  } else {
    var rect = self.getVisibleViewPort(elementRect, bodyRect);
    self.calenderPane.style.left = rect.left + "px";
    self.calenderPane.style.top = rect.top + "px";
  }

  document.body.appendChild(self.calenderPane);
  angular.element(self.calenderPane).focus();
  self.calenderPan.addClass("show");
  self.$mdUtil.disableScrollAround(self.calenderPane);
  self.isCalenderOpen = true;
  self.$document.on("click", self.bodyClickHandler);
};

SMDateTimePickerCtrl.prototype.tabOutEvent = function (element) {
  var self = this;

  if (element.which === 9) {
    self.hideElement();
  }
};

SMDateTimePickerCtrl.prototype.hideElement = function () {
  var self = this;
  self.calenderPan.addClass("hide-animate");
  self.calenderPan.addClass("hide");
  self.calenderPan.removeClass("show");
  self.$mdUtil.enableScrolling();

  if (self.button) {
    angular.element(self.button).focus();
  }

  self.$document.off("click");
  self.isCalenderOpen = false;
};

SMDateTimePickerCtrl.prototype.clickOutSideHandler = function (event) {
  var self = this;

  if (!self.button) {
    if (self.calenderPane !== event.target && self.inputPane !== event.target && !self.calenderPane.contains(event.target) && !self.inputPane.contains(event.target)) {
      self.hideElement();
    }
  } else if (self.calenderPane !== event.target && self.button !== event.target && !self.calenderPane.contains(event.target) && !self.button.contains(event.target)) {
    self.hideElement();
  }
};

var app1 = angular.module("app.rangePicker");
app1.directive("smDateTimePicker", ["$mdUtil", "$mdMedia", "$document", "picker", DateTimePicker]);
/**
 * Picker.
 *
 * @returns {object}
 */

function picker() {
  var massagePath = "X";
  var cancelLabel = "Cancel";
  var okLabel = "Ok";
  var clearLabel = "Clear";
  var customHeader = {
    date: "ddd, MMM DD",
    dateTime: "ddd, MMM DD HH:mm",
    time: "HH:mm"
  }; // date picker configuration

  var daysNames = [{
    single: "D",
    shortName: "Di",
    fullName: "Dimanche"
  }, {
    single: "L",
    shortName: "Lu",
    fullName: "Lundi"
  }, {
    single: "M",
    shortName: "Ma",
    fullName: "Mardi"
  }, {
    single: "M",
    shortName: "Me",
    fullName: "Mercredi"
  }, {
    single: "J",
    shortName: "Je",
    fullName: "Jeudi"
  }, {
    single: "V",
    shortName: "Ve",
    fullName: "Vendredi"
  }, {
    single: "S",
    shortName: "Sa",
    fullName: "Samedi"
  }];
  var dayHeader = "single";
  var monthNames = moment.months(); // range picker configuration

  var rangeDivider = "au";
  var rangeDefaultList = [{
    label: "Aujourd'hui",
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day")
  }, {
    label: "7 Derniers jours",
    startDate: moment().subtract(7, "d"),
    endDate: moment()
  }, {
    label: "Mois en cours",
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month")
  }, {
    label: "Mois dernier",
    startDate: moment().subtract(1, "month").startOf("month"),
    endDate: moment()
  }, {
    label: "Année de date a date",
    startDate: moment().subtract(12, "month"),
    endDate: moment()
  }, {
    label: "Année en cours",
    startDate: moment().startOf("year"),
    endDate: moment().endOf("year")
  }];
  var rangeCustomStartEnd = ["Date de départ", "Date de fin"];
  return {
    setMassagePath: function setMassagePath(param) {
      massagePath = param;
    },
    setDaysNames: function setDaysNames(array) {
      daysNames = array;
    },
    setMonthNames: function setMonthNames(array) {
      monthNames = array;
    },
    setDayHeader: function setDayHeader(param) {
      dayHeader = param;
    },
    setOkLabel: function setOkLabel(param) {
      okLabel = param;
    },
    setCancelLabel: function setCancelLabel(param) {
      cancelLabel = param;
    },
    setClearLabel: function setClearLabel(param) {
      clearLabel = param;
    },
    setRangeDefaultList: function setRangeDefaultList(array) {
      rangeDefaultList = array;
    },
    setRangeCustomStartEnd: function setRangeCustomStartEnd(array) {
      rangeCustomStartEnd = array;
    },
    setCustomHeader: function setCustomHeader(obj) {
      if (!angular.isUndefined(obj.date)) {
        customHeader.date = obj.date;
      }

      if (!angular.isUndefined(obj.dateTime)) {
        customHeader.dateTime = obj.dateTime;
      }

      if (!angular.isUndefined(obj.time)) {
        customHeader.time = obj.time;
      }
    },
    $get: function $get() {
      return {
        massagePath: massagePath,
        cancelLabel: cancelLabel,
        okLabel: okLabel,
        clearLabel: clearLabel,
        daysNames: daysNames,
        monthNames: monthNames,
        dayHeader: dayHeader,
        customHeader: customHeader,
        rangeDivider: rangeDivider,
        rangeCustomStartEnd: rangeCustomStartEnd,
        rangeDefaultList: rangeDefaultList
      };
    }
  };
}

var app2 = angular.module("app.rangePicker");
app2.provider("picker", [picker]);

(function () {
  "use strict";
  /**
   * Range picker input.
   *
   * @returns {object}
   */

  function RangePickerInput() {
    return {
      restrict: "EA",
      replace: true,
      require: ["^ngModel"],
      scope: {
        label: "@",
        fname: "@",
        isRequired: "@",
        closeOnSelect: "@",
        disable: "=",
        format: "@",
        mode: "@",
        divider: "@",
        showCustom: "@",
        weekStartDay: "@",
        customToHome: "@",
        customList: "=",
        noFloatingLabel: "=",
        minDate: "@",
        maxDate: "@",
        allowClear: "@",
        allowEmpty: "@",
        onRangeSelect: "&"
      },
      controller: ["$scope", "$element", "$mdUtil", "$mdMedia", "$document", SMRangePickerCtrl],
      controllerAs: "vm",
      bindToController: true,
      template: function template() {
        return "<md-input-container md-no-float=\"vm.noFloatingLabel\" class=\"pickerContainer\">" + "     <label ng-hide='true'>{{vm.label}}</label>" + "     <md-button class=\"md-accent-bg md-raised white-fg\" ng-click=\"vm.show()\" style=\"width: 270px;\">Modifier les dates</md-button>" + "     <input name=\"{{vm.fname}}\" ng-model=\"vm.value\" ng-readonly=\"true\"" + "            type=\"text\" ng-click=\"vm.show()\" " + "            aria-label=\"{{vm.fname}}\" ng-required=\"{{vm.isRequired}}\" class=\"sm-input-container\"" + "            placeholder=\"{{vm.label}}\" style=\"margin-top: 10px; z-index:2\">" + "     <i class=\"icon icon-calendar-text\" style=\"z-index:1\"></i>   " + "     <div id=\"picker\" class=\"sm-calender-pane md-whiteframe-4dp\" ng-model=\"value\">" + "         <sm-range-picker ng-model=\"vm.value\" date-label=\"{{vm.label}}\" custom-to-home=\"{{vm.customToHome}}\" custom-list=\"vm.customList\" mode=\"{{vm.mode}}\" min-date=\"{{vm.minDate}}\"  max-date=\"{{vm.maxDate}}\" range-select-call=\"vm.rangeSelected(range)\" close-on-select=\"{{vm.closeOnSelect}}\" show-custom=\"{{vm.showCustom}}\" week-start-day=\"{{vm.weekStartDay}}\"  divider=\"{{vm.divider}}\" format=\"{{vm.format}}\" allow-clear=\"{{vm.allowClear}}\" allow-empty=\"{{vm.allowEmpty}}\"></sm-range-picker>" + "     </div> " + " </md-input-container>";
      },
      link: function link(scope, $element, attr, ctrl) {
        ctrl[0].$render = function () {
          scope.vm.value = this.$viewValue;
        };
      }
    };
  }

  var SMRangePickerCtrl = function SMRangePickerCtrl($scope, $element, $mdUtil, $mdMedia, $document) {
    var self = this;
    self.$scope = $scope;
    self.$element = $element;
    self.$mdUtil = $mdUtil;
    self.$mdMedia = $mdMedia;
    self.$document = $document;
    self.isCalenderOpen = false;
    self.calenderHeight = 460;
    self.calenderWidth = 296; // find input button and assign to variable

    self.inputPane = $element[0].querySelector(".sm-input-container"); // find Calender Picker  and assign to variable

    self.calenderPane = $element[0].querySelector(".sm-calender-pane"); // button to start calender

    self.button = $element[0].querySelector(".sm-picker-icon");
    self.buttonDates = $element[0].querySelector(".md-raised");
    self.calenderPan = angular.element(self.calenderPane); // check if mode is undefied set to date mode

    self.mode = angular.isUndefined($scope.mode) ? "date" : $scope.mode; // check if Pre defined format is supplied

    self.format = angular.isUndefined($scope.format) ? "MM-DD-YYYY" : $scope.format;
    self.calenderPan.addClass("hide hide-animate");
    self.bodyClickHandler = angular.bind(self, self.clickOutSideHandler);
    self.$scope.$on("range-picker:close", function () {
      self.$document.off("keydown");
      self.hideElement();
    });
    self.$scope.$on("$destroy", function () {
      self.calenderPane.parentNode.removeChild(self.calenderPane);
    }); // if tab out hide key board

    angular.element(self.inputPane).on("keydown", function (event) {
      switch (event.which) {
        case 27:
        case 9:
          self.hideElement();
          break;
      }
    });
  };
  /* get visiable port
    @param : elementnRect
    @param : bodyRect
    */


  SMRangePickerCtrl.prototype.getVisibleViewPort = function (elementRect, bodyRect) {
    var self = this;
    var top = elementRect.top;

    if (elementRect.top + self.calenderHeight > bodyRect.bottom) {
      top = elementRect.top - (elementRect.top + self.calenderHeight - (bodyRect.bottom - 20));
    }

    var left = elementRect.left;

    if (elementRect.left + self.calenderWidth > bodyRect.right) {
      left = elementRect.left - (elementRect.left + self.calenderWidth - (bodyRect.right - 10));
    }

    return {
      top: top,
      left: left
    };
  };

  SMRangePickerCtrl.prototype.rangeSelected = function (range) {
    var self = this;
    self.onRangeSelect({
      range: range
    });
    self.value = range;
  };

  SMRangePickerCtrl.prototype.show = function () {
    var self = this;
    var elementRect = self.inputPane.getBoundingClientRect();
    var bodyRect = document.body.getBoundingClientRect();
    self.calenderPan.removeClass("hide hide-animate");

    if (self.$mdMedia("sm") || self.$mdMedia("xs")) {
      self.calenderPane.style.left = (bodyRect.width - 320) / 2 + "px";
      self.calenderPane.style.top = (bodyRect.height - 450) / 2 + "px";
    } else {
      var rect = self.getVisibleViewPort(elementRect, bodyRect);
      var left = rect.left;

      if (rect.left + 520 > bodyRect.width) {
        left = rect.left - (rect.left + 520 - bodyRect.width + 20);
      }

      self.calenderPane.style.left = left + "px";
      self.calenderPane.style.top = rect.top + "px";
    }

    document.body.appendChild(self.calenderPane); // angular.element(self.calenderPane).focus();

    angular.element(self.calenderPane).addClass("show");
    self.$mdUtil.disableScrollAround(self.calenderPane);
    self.isCalenderOpen = true;
    self.$document.on("click", self.bodyClickHandler);
  };

  SMRangePickerCtrl.prototype.tabOutEvent = function (element) {
    var self = this;

    if (element.which === 9) {
      self.hideElement();
    }
  };

  SMRangePickerCtrl.prototype.hideElement = function () {
    var self = this;
    self.calenderPan.addClass("hide hide-animate");
    self.calenderPan.removeClass("show");
    self.$mdUtil.enableScrolling();

    if (self.button) {
      angular.element(self.button).focus();
    }

    self.$document.off("click");
    self.isCalenderOpen = false;
  };

  SMRangePickerCtrl.prototype.clickOutSideHandler = function (event) {
    if (event.target.classList.contains("md-scroll-mask")) {
      this.hideElement();
    }
  };

  var app = angular.module("app.rangePicker");
  app.directive("smRangePickerInput", ["$document", "$mdMedia", "$mdUtil", "picker", RangePickerInput]);
})();
/**
 * SM range picker.
 *
 * @returns {object}
 */


function smRangePicker() {
  return {
    restrict: "E",
    require: ["^?ngModel", "smRangePicker"],
    scope: {
      format: "@",
      divider: "@",
      weekStartDay: "@",
      customToHome: "@",
      closeOnSelect: "@",
      mode: "@",
      showCustom: "@",
      customList: "=",
      minDate: "@",
      maxDate: "@",
      allowClear: "@",
      allowEmpty: "@",
      rangeSelectCall: "&",
      dateLabel: "@",
      noHeader: "@"
    },
    terminal: true,
    controller: ["$scope", "picker", RangePickerCtrl],
    controllerAs: "vm",
    bindToController: true,
    templateUrl: "app/main/directives/date-picker/custom-range.html",
    link: function link(scope, element, att, ctrls) {
      var ngModelCtrl = ctrls[0];
      var calCtrl = ctrls[1];
      calCtrl.configureNgModel(ngModelCtrl);
    }
  };
}

var RangePickerCtrl = function RangePickerCtrl($scope, picker) {
  moment.locale("fr");
  var self = this;
  self.scope = $scope;
  self.clickedButton = 0;
  self.startShowCustomSettting = self.showCustom;
  self.dateLabel = self.scope.dateLabel;
  self.divider = angular.isUndefined(self.scope.divider) || self.scope.divider === "" ? picker.rangeDivider : $scope.divider; // display the clear button?

  self.showClearButton = self.allowClear === "true" || false; // allow set start/end date as empty value

  self.allowEmptyDates = self.allowEmpty === "true" || false;
  self.okLabel = picker.okLabel;
  self.cancelLabel = picker.cancelLabel;
  self.clearLabel = picker.clearLabel;
  self.view = "DATE";
  self.rangeCustomStartEnd = picker.rangeCustomStartEnd;
  var defaultList = [];
  angular.copy(picker.rangeDefaultList, defaultList);
  self.rangeDefaultList = defaultList;

  if (self.customList) {
    for (var index = 0; index < self.customList.length; index++) {
      self.rangeDefaultList[self.customList[index].position] = self.customList[index];
    }
  }

  if (self.showCustom) {
    self.selectedTabIndex = 0;
  } else {
    self.selectedTabIndex = $scope.selectedTabIndex;
  }
};

RangePickerCtrl.prototype.configureNgModel = function (ngModelCtrl) {
  this.ngModelCtrl = ngModelCtrl;
  var self = this;

  ngModelCtrl.$render = function () {
    self.ngModelCtrl.$viewValue = self.dateLabel;
  };
};

RangePickerCtrl.prototype.setNextView = function () {
  switch (this.mode) {
    case "date":
      this.view = "DATE";

      if (this.selectedTabIndex === 0) {
        this.selectedTabIndex = 1;
      }

      break;

    case "date-time":
      if (this.view === "DATE") {
        this.view = "TIME";
      } else {
        this.view = "DATE";

        if (this.selectedTabIndex === 0) {
          this.selectedTabIndex = 1;
        }
      }

      break;

    default:
      this.view = "DATE";

      if (this.selectedTabIndex === 0) {
        this.selectedTabIndex = 1;
      }

  }
};

RangePickerCtrl.prototype.showCustomView = function () {
  this.showCustom = true;
  this.selectedTabIndex = 0;
};

RangePickerCtrl.prototype.dateRangeSelected = function () {
  var self = this;
  self.selectedTabIndex = 0;
  self.view = "DATE";

  if (self.startShowCustomSettting) {
    self.showCustom = true;
  } else {
    self.showCustom = false;
  }

  self.setNgModelValue(self.startDate, self.divider, self.endDate);
};
/* sets an empty value on dates. */


RangePickerCtrl.prototype.clearDateRange = function () {
  var self = this;
  self.selectedTabIndex = 0;
  self.view = "DATE";

  if (self.startShowCustomSettting) {
    self.showCustom = true;
  } else {
    self.showCustom = false;
  }

  self.setNgModelValue("", self.divider, "");
};

RangePickerCtrl.prototype.startDateSelected = function (date) {
  this.startDate = date;
  this.minDate = new Date(date);
  this.scope.$emit("range-picker:startDateSelected");
  this.setNextView();
};

RangePickerCtrl.prototype.startTimeSelected = function (time) {
  this.startDate.hour(time.hour()).minute(time.minute());
  this.scope.$emit("range-picker:startTimeSelected");
  this.setNextView();
};

RangePickerCtrl.prototype.endDateSelected = function (date) {
  this.endDate = date;
  this.scope.$emit("range-picker:endDateSelected");

  if (this.closeOnSelect && this.mode === "date") {
    this.setNgModelValue(this.startDate, this.divider, this.endDate);
  } else {
    this.setNextView();
  }
};

RangePickerCtrl.prototype.endTimeSelected = function (time) {
  this.endDate.hour(time.hour()).minute(time.minute());
  this.scope.$emit("range-picker:endTimeSelected");

  if (this.closeOnSelect && this.mode === "date-time") {
    this.setNgModelValue(this.startDate, this.divider, this.endDate);
  }
};

RangePickerCtrl.prototype.setNgModelValue = function (startDate, divider, endDate) {
  var self = this;

  if (startDate) {
    // eslint-disable-next-line no-param-reassign
    startDate = startDate.format(self.format) || "";
  }

  if (endDate) {
    // eslint-disable-next-line no-param-reassign
    endDate = endDate.format(self.format) || "";
  }

  var range = {
    startDate: startDate,
    endDate: endDate
  };
  self.rangeSelectCall({
    range: range
  });

  var _ng_model_value; // if no startDate && endDate, then empty the model.


  if (!startDate && !endDate) {
    _ng_model_value = "";
  } else {
    // eslint-disable-next-line no-param-reassign
    startDate = startDate || "Any"; // eslint-disable-next-line no-param-reassign

    endDate = endDate || "Any";
    _ng_model_value = startDate + " " + divider + " " + endDate;
  }

  setTimeout(function () {
    self.ngModelCtrl.$setViewValue(_ng_model_value);
    self.ngModelCtrl.$render();
  }, 50);
  self.selectedTabIndex = 0;
  self.view = "DATE";
  self.scope.$emit("range-picker:close");
};

RangePickerCtrl.prototype.cancel = function () {
  var self = this;

  if (self.customToHome && self.showCustom) {
    self.showCustom = false;
  } else {
    self.selectedTabIndex = 0;
    self.showCustom = false;
    self.scope.$emit("range-picker:close");
  }
};

var app3 = angular.module("app.rangePicker");
app3.provider("picker", function () {
  moment.locale("fr");
  var massagePath = "X";
  var cancelLabel = "Annuler";
  var okLabel = "Ok";
  var clearLabel = "RAZ";
  var customHeader = {
    date: "ddd, MMM DD",
    dateTime: "ddd, MMM DD HH:mm",
    time: "HH:mm"
  }; // date picker configuration

  var daysNames = [{
    single: "D",
    shortName: "Di",
    fullName: "Dimanche"
  }, {
    single: "L",
    shortName: "Lu",
    fullName: "Lundi"
  }, {
    single: "M",
    shortName: "Ma",
    fullName: "Mardi"
  }, {
    single: "M",
    shortName: "Me",
    fullName: "Mercredi"
  }, {
    single: "J",
    shortName: "Je",
    fullName: "Jeudi"
  }, {
    single: "V",
    shortName: "Ve",
    fullName: "Vendredi"
  }, {
    single: "S",
    shortName: "Sa",
    fullName: "Samedi"
  }];
  var dayHeader = "single";
  var monthNames = moment.months(); // range picker configuration

  var rangeDivider = "Au";
  var rangeDefaultList = [{
    label: "Aujourd'hui",
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day")
  }, {
    label: "Les 7 derniers jours",
    startDate: moment().subtract(7, "d"),
    endDate: moment()
  }, {
    label: "Mois en cours",
    startDate: moment().startOf("month"),
    endDate: moment().endOf("day")
  }, {
    label: "Mois dernier",
    startDate: moment().subtract(1, "month").startOf("month"),
    endDate: moment().subtract(1, "month").endOf("month")
  }, {
    label: "Année de date à date",
    startDate: moment().subtract(12, "month"),
    endDate: moment()
  }, {
    label: "année en cours",
    startDate: moment().startOf("year"),
    endDate: moment()
  }];
  var rangeCustomStartEnd = ["Date de départ", "Date de fin"];
  return {
    setMassagePath: function setMassagePath(param) {
      massagePath = param;
    },
    setDaysNames: function setDaysNames(array) {
      daysNames = array;
    },
    setMonthNames: function setMonthNames(array) {
      monthNames = array;
    },
    setDayHeader: function setDayHeader(param) {
      dayHeader = param;
    },
    setOkLabel: function setOkLabel(param) {
      okLabel = param;
    },
    setCancelLabel: function setCancelLabel(param) {
      cancelLabel = param;
    },
    setClearLabel: function setClearLabel(param) {
      clearLabel = param;
    },
    setRangeDefaultList: function setRangeDefaultList(array) {
      rangeDefaultList = array;
    },
    setRangeCustomStartEnd: function setRangeCustomStartEnd(array) {
      rangeCustomStartEnd = array;
    },
    setCustomHeader: function setCustomHeader(obj) {
      if (!angular.isUndefined(obj.date)) {
        customHeader.date = obj.date;
      }

      if (!angular.isUndefined(obj.dateTime)) {
        customHeader.dateTime = obj.dateTime;
      }

      if (!angular.isUndefined(obj.time)) {
        customHeader.time = obj.time;
      }
    },
    $get: function $get() {
      return {
        massagePath: massagePath,
        cancelLabel: cancelLabel,
        okLabel: okLabel,
        clearLabel: clearLabel,
        daysNames: daysNames,
        monthNames: monthNames,
        dayHeader: dayHeader,
        customHeader: customHeader,
        rangeDivider: rangeDivider,
        rangeCustomStartEnd: rangeCustomStartEnd,
        rangeDefaultList: rangeDefaultList
      };
    }
  };
});
app3.directive("smRangePicker", ["picker", smRangePicker]);
/**
 * SM time picker new.
 *
 * @param {object} $mdUtil - $mdUtil.
 * @param {object} $mdMedia - $mdMedia.
 * @param {object} $document - $document.
 * @param {object} $timeout - $timeout.
 * @param {object} picker - Picker.
 *
 * @returns {object}
 */

function smTimePickerNew($mdUtil, $mdMedia, $document, $timeout, picker) {
  return {
    restrict: "E",
    replace: true,
    scope: {
      value: "=",
      startDate: "@",
      weekStartDay: "@",
      startView: "@",
      mode: "@",
      format: "@",
      minDate: "@",
      maxDate: "@",
      fname: "@",
      lable: "@",
      isRequired: "@",
      disable: "=",
      form: "=",
      closeOnSelect: "@"
    },
    template: "  <md-input-container >" + "    <label for=\"{{fname}}\">{{lable }}</label>" + "    <input name=\"{{fname}}\" ng-model=\"value\" ng-readonly=\"true\"" + "             type=\"text\" placeholde=\"{{lable}}\"" + "             aria-label=\"{{fname}}\" data-ng-required=\"isRequired\"" + "             ng-click=\"show()\" server-error class=\"sm-input-container\">" + "    <div ng-messages=\"form.fname.$error\" ng-if=\"form[fname].$touched\">" + "    		<div ng-messages-include=\"{{ngMassagedTempaltePath}}\"></div>" + "    </div>" + "    <div id=\"picker\" class=\"sm-calender-pane md-whiteframe-z2\" style=\"min-width: 275px\" >" + "     		<sm-time-picker " + "              id=\"{{fname}}Picker\" " + "              ng-model=\"value\" " + "				initial-date=\"{{value}}\"" + "              mode=\"{{mode}}\" " + "				close-on-select=\"{{closeOnSelect}}\"" + "              start-view=\"{{startView}}\" " + "              data-min-date=\"minDate\" " + "              data-max-date=\"maxDate\"  " + "              format=\"{{format}}\"  " + "          	start-day=\"{{weekStartDay}}\" > " + "			</sm-time-picker>" + "    </div>" + "  </md-input-container>",
    link: function link(scope, $element) {
      var inputPane = $element[0].querySelector(".sm-input-container");
      var calenderPane = $element[0].querySelector(".sm-calender-pane");
      var cElement = angular.element(calenderPane);
      scope.ngMassagedTempaltePath = picker.massagePath; // check if Pre defined format is supplied

      scope.format = angular.isUndefined(scope.format) ? "MM-DD-YYYY" : scope.format; // Hide calender pane on initialization

      cElement.addClass("hide hide-animate"); // set start date

      scope.startDate = angular.isUndefined(scope.value) ? scope.startDate : scope.value; // Hide Calender on click out side

      $document.on("click", function (event) {
        if (calenderPane !== event.target && inputPane !== event.target && !calenderPane.contains(event.target) && !inputPane.contains(event.target)) {
          hideElement();
        }
      }); // if tab out hide key board

      angular.element(inputPane).on("keydown", function (event) {
        if (event.which === 9) {
          hideElement();
        }
      }); // show calender

      scope.show = function () {
        var elementRect = inputPane.getBoundingClientRect();
        var bodyRect = document.body.getBoundingClientRect();
        cElement.removeClass("hide");

        if ($mdMedia("sm") || $mdMedia("xs")) {
          calenderPane.style.left = (bodyRect.width - 300) / 2 + "px";
          calenderPane.style.top = (bodyRect.height - 450) / 2 + "px";
        } else {
          var rect = getVisibleViewPort(elementRect, bodyRect);
          calenderPane.style.left = rect.left + "px";
          calenderPane.style.top = rect.top + "px";
        }

        document.body.appendChild(calenderPane);
        $mdUtil.disableScrollAround(calenderPane);
        cElement.addClass("show");
      };
      /**
       * Calculate visible port to display calender.
       *
       * @param {object} elementRect - Element rect.
       * @param {object} bodyRect - Body rect.
       *
       * @returns {object}
       */


      function getVisibleViewPort(elementRect, bodyRect) {
        var calenderHeight = 460;
        var calenderWidth = 296;
        var top = elementRect.top;

        if (elementRect.top + calenderHeight > bodyRect.bottom) {
          top = elementRect.top - (elementRect.top + calenderHeight - (bodyRect.bottom - 20));
        }

        var left = elementRect.left;

        if (elementRect.left + calenderWidth > bodyRect.right) {
          left = elementRect.left - (elementRect.left + calenderWidth - (bodyRect.right - 10));
        }

        return {
          top: top,
          left: left
        };
      }
      /**
       * Hide element.
       */


      function hideElement() {
        cElement.addClass("hide-animate");
        cElement.removeClass("show"); // this is only for animation

        $mdUtil.enableScrolling();
      }

      scope.$on("$destroy", function () {
        calenderPane.parentNode.removeChild(calenderPane);
      }); // listen to emit for closing calender

      scope.$on("calender:close", function () {
        hideElement();
      });
    }
  };
}

var app4 = angular.module("app.rangePicker");
app4.directive("smTimePickerNew", ["$mdUtil", "$mdMedia", "$document", "$timeout", "picker", smTimePickerNew]);
angular.module("app.rangePicker").run(["$templateCache", function ($templateCache) {
  $templateCache.put("picker/date-picker.html", "<div class=\"picker-container\">\n	<md-content  layout-xs=\"column\" layout=\"row\"  class=\"container\" >\n		<md-toolbar class=\"md-height md-menu-toolbar\" ng-class=\"{'portrait': !vm.$mdMedia('gt-xs'),'landscape': vm.$mdMedia('gt-xs')}\" >			\n				<span class=\"year-header\" layout=\"row\" layout-xs=\"row\">{{vm.currentDate.format('YYYY')}}</span>\n				<span class=\"date-time-header\" layout=\"row\" layout-xs=\"row\">{{vm.currentDate.format(vm.headerDispalyFormat)}}</span>\n		</md-toolbar>\n		<div layout=\"column\" class=\"picker-container\" >\n			<div ng-show=\"vm.view==='DATE'\" >\n				<sm-calender \n					data-ng-model=\"vm.selectedDate\"\n					data-initial-date=\"vm.initialDate\"					\n					data-id=\"{{vm.fname}}Picker\" \n					data-mode=\"{{vm.mode}}\" \n					data-min-date=\"vm.minDate\" \n					data-max-date=\"vm.maxDate\" \n					data-close-on-select=\"{{vm.closeOnSelect}}\"				 \n					data-data-format=\"{{vm.format}}\" \n					data-disable-year-selection=\"{{vm.disableYearSelection}}\" \n					data-week-start-day=\"{{vm.weekStartDay}}\"\n					data-date-select-call=\"vm.dateSelected(date)\">\n				</sm-calender>\n			</div>\n			<div ng-show=\"vm.view==='TIME'\">\n				<sm-time\n					data-ng-model=\"vm.selectedTime\"\n					data-format=\"HH:mm\"\n					data-time-select-call=\"vm.timeSelected(time)\">\n				</sm-time>\n			</div>		\n			<div layout=\"row\" ng-hide=\"vm.closeOnSelect\">\n					<span flex></span>\n					<md-button class=\"md-button md-primary\" ng-click=\"vm.closeDateTime()\">{{vm.cancelLabel}}</md-button>\n					<md-button class=\"md-button md-primary\" ng-click=\"vm.selectedDateTime()\">{{vm.okLabel}}</md-button>\n			</div>\n		</div>\n	</md-content>	\n</div>");
}]);