"use strict";
/**
 * Operator sales controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $q - $q.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */

OperatorSalesController.$inject = ["$scope", "$q", "StatisticsQueryService", "environmentService", "analysisService"];

function OperatorSalesController($scope, $q, StatisticsQueryService, environmentService, analysisService) {
  var vm = this;
  vm.loading = true;
  vm.chartTvaData = $q.defer();
  vm.chartParaData = $q.defer();
  vm.chartOtcData = $q.defer();
  vm.currentEnv = environmentService.getEnvironment();
  vm.noOperatorCode = false;
  loader();
  /**
   * Loader.
   */

  function loader() {
    StatisticsQueryService.queryRebase();
    vm.usrquery = JSON.parse(JSON.stringify(StatisticsQueryService.pristineQuery));
    vm.currentEnv.then(function (env) {
      if (env.operatorCode === null) {
        vm.noOperatorCode = true;
      } else {
        vm.noOperatorCode = false;
        vm.usrquery.must.operator.selected.push({
          id: env.operatorCode
        });
        vm.dateLabel = moment(vm.usrquery.date.from).format("DD-MMM-YYYY") + " Au " + moment(vm.usrquery.date.to).format("DD-MMM-YYYY");
        getTopLab(vm.usrquery);
      }
    });
  }

  vm.rangeSelected = function (date) {
    vm.usrquery.date.from = moment(date.startDate, "DD-MM-YYYY");
    vm.usrquery.date.to = moment(date.endDate, "DD-MM-YYYY");
    getTopLab(vm.usrquery);
  };

  $scope.$on("analysis:regquery", function (event, data) {
    vm.loading = true;
    vm.usrquery = data;
    vm.query = StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date);
  });
  vm.chartTva = operatorTvaChart();
  /**
   * Operator TVA chart.
   *
   * @returns {object}
   */

  function operatorTvaChart() {
    return {
      data: vm.chartTvaData.promise,
      type: "serial",
      theme: "light",
      titles: [{
        size: 15,
        text: "TOP 5 Laboratoires"
      }],
      categoryField: "labo",
      categoryAxis: {
        parseDates: false,
        autoWrap: true
      },
      legend: {
        enabled: true,
        useGraphSettings: true
      },
      chartScrollbar: {
        enabled: false
      },
      chartCursor: {
        zoomable: false
      },
      startDuration: 0,
      valueAxes: [{
        id: "v1",
        axisThickness: 2,
        title: "unités",
        axisAlpha: 1,
        position: "left"
      }, {
        id: "v2",
        axisThickness: 2,
        axisAlpha: 1,
        title: "CA",
        position: "right"
      }],
      graphs: [{
        valueAxis: "v1",
        title: "Unités",
        valueField: "unit",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " unités";
        },
        type: "column"
      }, {
        valueAxis: "v2",
        title: "CA",
        valueField: "sales",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " €";
        },
        type: "column"
      }],
      plotAreaFillAlphas: 0.1,
      "export": {
        enabled: true
      }
    };
  }

  vm.chartPara = operatorParaChart();
  /**
   * Operator para chart.
   *
   * @returns {object}
   */

  function operatorParaChart() {
    return {
      data: vm.chartParaData.promise,
      type: "serial",
      theme: "light",
      categoryField: "labo",
      titles: [{
        size: 15,
        text: "TOP 5 Parapharmacie"
      }],
      categoryAxis: {
        parseDates: false,
        autoWrap: true
      },
      legend: {
        enabled: true,
        useGraphSettings: true
      },
      chartScrollbar: {
        enabled: false
      },
      chartCursor: {
        zoomable: false
      },
      startDuration: 0,
      valueAxes: [{
        id: "v1",
        axisThickness: 2,
        title: "unités",
        axisAlpha: 1,
        position: "left"
      }, {
        id: "v2",
        axisThickness: 2,
        axisAlpha: 1,
        title: "CA",
        position: "right"
      }],
      graphs: [{
        valueAxis: "v1",
        title: "Unités",
        valueField: "unit",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " unités";
        },
        type: "column"
      }, {
        valueAxis: "v2",
        title: "CA",
        valueField: "sales",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " €";
        },
        type: "column"
      }],
      plotAreaFillAlphas: 0.1,
      "export": {
        enabled: true
      }
    };
  }

  vm.chartConseil = operatorConseilChart();
  /**
   * Operator conseil chart.
   *
   * @returns {object}
   */

  function operatorConseilChart() {
    return {
      marginLeft: 60,
      data: vm.chartOtcData.promise,
      type: "serial",
      theme: "light",
      titles: [{
        size: 15,
        text: "TOP 5 OTC"
      }],
      categoryField: "labo",
      categoryAxis: {
        parseDates: false,
        autoWrap: true
      },
      legend: {
        useGraphSettings: true
      },
      chartScrollbar: {
        enabled: false
      },
      chartCursor: {
        zoomable: false
      },
      synchronizeGrid: true,
      startDuration: 0,
      valueAxes: [{
        id: "v1",
        axisThickness: 2,
        title: "unités",
        axisAlpha: 1,
        position: "left"
      }, {
        id: "v2",
        axisThickness: 2,
        axisAlpha: 1,
        title: "CA",
        position: "right"
      }],
      graphs: [{
        valueAxis: "v1",
        title: "Unités",
        valueField: "unit",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " unités";
        },
        type: "column"
      }, {
        valueAxis: "v2",
        title: "CA",
        valueField: "sales",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " €";
        },
        type: "column"
      }],
      plotAreaFillAlphas: 0.1,
      "export": {
        enabled: true
      }
    };
  }
  /**
   * Get top laboratory.
   *
   * @param {object} query -
   */


  function getTopLab(query) {
    // Reset the datas to promise
    // For X. Otc = Over the counter (The products sold without any
    // prescription, the ones you can see over the counter in a pharmacy
    // or even in the pharmacy itself)
    vm.chartTvaData = $q.defer();
    vm.chartParaData = $q.defer();
    vm.chartOtcData = $q.defer();
    vm.loading = true;
    query.must.tva.selected = [];
    query.must.product.selected = [];
    vm.chartTva = operatorTvaChart();
    StatisticsQueryService.buildquery(query, query.date).then(analysisService.getTop5Laboratory).then(function (data) {
      vm.topLaboTva21 = data.aggregations.salesLaboratory.buckets;
      var tvaData = [];
      angular.forEach(vm.topLaboTva21, function (dataTva) {
        tvaData.push({
          labo: dataTva.key,
          sales: dataTva.sales.value,
          unit: dataTva.unit.value
        });
      });
      vm.chartTvaData.resolve(tvaData);
      query.must.tva.selected = [];
      vm.chartPara = operatorParaChart();
      query.must.category.selected.push({
        id: 1403
      });
      return StatisticsQueryService.buildquery(query, query.date);
    }).then(analysisService.getTop5Laboratory).then(function (data) {
      vm.topLaboPara = data.aggregations.salesLaboratory.buckets;
      var paraData = [];
      angular.forEach(vm.topLaboPara, function (dataPara) {
        paraData.push({
          labo: dataPara.key,
          sales: dataPara.sales.value,
          unit: dataPara.unit.value
        });
      });
      vm.chartParaData.resolve(paraData);
      query.must.category.selected = [];
      query.must.tva.selected.push({
        value: 8
      });
      vm.chartConseil = operatorConseilChart();
      return StatisticsQueryService.buildquery(query, query.date);
    }).then(analysisService.getTop5Laboratory).then(function (data) {
      vm.topLaboOtc = data.aggregations.salesLaboratory.buckets;
      var otcData = [];
      angular.forEach(vm.topLaboOtc, function (dataOtc) {
        otcData.push({
          labo: dataOtc.key,
          sales: dataOtc.sales.value,
          unit: dataOtc.unit.value
        });
      });
      vm.chartOtcData.resolve(otcData);
      vm.loading = false;
    });
  }
}

angular.module("app.statistics.operator-sales").controller("OperatorSalesController", OperatorSalesController);