"use strict";
/**
 * Prestation home controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 */

PrestationHomeController.$inject = ["$scope", "$state"];

function PrestationHomeController($scope, $state) {
  switch ($state.current.name) {
    case "app.prestation.dashboard":
      $scope.selectedTab = 0;
      break;

    case "app.prestation.list":
      $scope.selectedTab = 1;
      break;

    default:
      $scope.selectedTab = 0;
  }
}

angular.module("app.prestation").controller("PrestationHomeController", PrestationHomeController);