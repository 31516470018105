"use strict";
/**
 * Dashboard grouping top laboratories controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} groupingService - Grouping service.
 */

dashboardGroupTopLaboratoriesController.$inject = ["$scope", "groupingService"];

function dashboardGroupTopLaboratoriesController($scope, groupingService) {
  loader();
  /**
   * Loader.
   */

  function loader() {
    getDashboard();
  }
  /**
   * Get grouping dashboard.
   */


  function getDashboard() {
    $scope.dashboardLoading = true;
    groupingService.dashboard().then(function (response) {
      $scope.dashboard = response.dashboard;
      $scope.dashboardLoading = false;
    });
  }
}

angular.module("app.dashboard").controller("dashboardGroupTopLaboratoriesController", dashboardGroupTopLaboratoriesController);