"use strict";
/**
 * Generator configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

GeneratorConfig.$inject = ["$stateProvider"];

function GeneratorConfig($stateProvider) {
  var APP_GENERATOR = {
    url: "/support-communication",
    views: {
      "content@app": {
        templateUrl: "app/main/generator/view/list/list.html",
        controller: "listController"
      }
    }
  };
  var APP_GENERATOR_EDIT = {
    url: "/:stateOrigin/edition/:id/:injectedService",
    views: {
      "content@app": {
        templateUrl: "app/main/generator/view/edit/editor.html",
        controller: "editorController"
      }
    }
  };
  $stateProvider.state("app.generator", APP_GENERATOR).state("app.generator.edit", APP_GENERATOR_EDIT);
}

angular.module("app.generator", []).config(GeneratorConfig);