"use strict";
/**
 * Statistics generic substitution controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} environmentService - Environment service.
 * @param {object} substitutionPageService - Substitution page service.
 */

StatisticsGenericSubstitutionController.$inject = ["$scope", "environmentService", "substitutionPageService"];

function StatisticsGenericSubstitutionController($scope, environmentService, substitutionPageService) {
  $scope.noresult = false;
  $scope.loading = true;
  $scope.optionsPagination = {
    limit: 50,
    page: 1,
    order: "name"
  };
  init();
  /**
   * Initialize.
   */

  function init() {
    environmentService.getEnvironment().then(function (response) {
      $scope.access = response;
      substitutionPageService.get().then(function (data) {
        $scope.subRates = data;
        $scope.loading = false;
      });
    });
  }
}

angular.module("app.statistics.genericaudit").controller("StatisticsGenericSubstitutionController", StatisticsGenericSubstitutionController);