"use strict";

/**
 * Load Google map scripts and then resolve it and launch the site
 */
if (Promise && !("deferred" in Promise)) {
  Promise.deferred = function () {
    var fResolve,
        fReject,
        P = new Promise(function (resolve, reject) {
      fResolve = resolve;
      fReject = reject;
    });
    return {
      promise: P,
      resolve: fResolve,
      reject: fReject
    };
  };
}

window['GmapDeferred'] = Promise.deferred();
window['GmapPromise'] = GmapDeferred.promise;

window['initializeGmap'] = function () {
  GmapDeferred.resolve();
};