"use strict";
/**
 * Dashboard laboratories sales controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} environmentService - Environment service.
 * @param {object} apodisLiveService - Apodis live service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} homeLaboratoryAnalysisService - Laboratory analysis service.
 */

DashboardLaboratoriesSalesController.$inject = ["$scope", "$rootScope", "environmentService", "apodisLiveService", "StatisticsQueryService", "homeLaboratoryAnalysisService"];

function DashboardLaboratoriesSalesController($scope, $rootScope, environmentService, apodisLiveService, StatisticsQueryService, homeLaboratoryAnalysisService) {
  var today = moment().format("YYYY-MM-DD");
  var salesServiceName;
  $scope.unitToday = 0;
  $scope.salesToday = 0;
  /**
   * Loader.
   */

  function loader() {
    processSalesToday();
    environmentService.getEnvironment().then(function (env) {
      launchSocket(env);
    });
  }
  /**
   * On sales data.
   *
   * @param {object} salesData - Sales data.
   */


  function onSalesData(salesData) {
    $scope.unitToday += salesData.item.quantity;
    $scope.salesToday += salesData.item.price.sell.total_ht;
    $scope.$apply();
  }
  /**
   * Launch socket.
   *
   * @param {object} environment - Environment.
   */


  function launchSocket(environment) {
    salesServiceName = "sales_laboratory_" + environment.entityId;

    if (typeof apodisLiveService.socket._callbacks["$" + salesServiceName] === "undefined") {
      // Handler not present, install now
      apodisLiveService.register({
        service: salesServiceName
      });
    }

    apodisLiveService.socket.on(salesServiceName, onSalesData);
  }
  /**
   * Sales today.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function salesToday(query) {
    return homeLaboratoryAnalysisService.sales(query).then(function (response) {
      $scope.salesToday = response.aggregations.amount_excl_tax.value;
      $scope.unitToday = response.aggregations.quantity.value;
    });
  }
  /**
   * Process sales today.
   */


  function processSalesToday() {
    StatisticsQueryService.queryRebase();
    $scope.liveQuery = JSON.parse(JSON.stringify(StatisticsQueryService.query));
    $scope.liveQuery.date = {
      from: today,
      to: today
    };
    StatisticsQueryService.buildquery($scope.liveQuery, $scope.liveQuery.date).then(function (query) {
      query.must.laboratory = [$scope.env.entityId];
      salesToday(query);
    });
  }

  $rootScope.$on("$stateChangeStart", function () {
    apodisLiveService.unregister(salesServiceName, onSalesData);
  });
  loader();
}

angular.module("app.dashboard").controller("dashboardLaboratoriesSalesController", DashboardLaboratoriesSalesController);