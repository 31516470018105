"use strict";
/**
 * Event factory.
 *
 * @returns {object}
 */

function EventFactory() {
  var factory = {};
  factory.tooltip = {};
  factory.eventTypeName = {
    sellout: {
      name: "Challenges équipe"
    },
    birthday: {
      name: "Anniversaires"
    },
    prestation: {
      name: "Prestations"
    },
    "sellin-offer": {
      name: "Offres Sellin"
    },
    "sellin-challenge": {
      name: "Challenges Sellin"
    },
    "prestation-participation": {
      name: "Prestations"
    },
    "sellout-participation": {
      name: "Challenges équipe"
    },
    "sellin-challenge-participation": {
      name: "Challenges sellin"
    },
    "sellin-offer-participation": {
      name: "Offres sellin"
    },
    "basic-event": {
      name: "Événements"
    },
    formation: {
      name: "Formations"
    },
    "tv-ad": {
      name: "Campagnes télé"
    },
    "radio-ad": {
      name: "Campagnes radio"
    },
    "press-ad": {
      name: "Campagnes presse"
    },
    "poster-ad": {
      name: "Campagnes affichage"
    },
    "medical-visit": {
      name: "Visites médicales"
    },
    animation: {
      name: "Animations"
    },
    commercial_appointment: {
      name: "Rendez-vous commerciaux"
    },
    meeting: {
      name: "Réunions"
    },
    emailing: {
      name: "Emailing"
    },
    "product-launch": {
      name: "Lancement produits"
    },
    "digital-campaign": {
      name: "Campagne digitale"
    },
    "stock-return": {
      name: "Retour de stock"
    }
  };
  factory.eventListingOptions = {
    loading: true,
    loader: "Récupération des événements...",
    empty: "Vous n'avez pas d'événements"
  };
  factory.query = {
    order: "-startDate",
    limit: 15,
    page: 1
  };
  factory.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  factory.ctrl = {};
  factory.ctrl.typesSelected = [];
  factory.ctrl.typesSelectedNameGroupLabo = [{
    value: "sellout",
    name: "Challenges équipe",
    checked: true
  }, {
    value: "prestation",
    name: "Prestations",
    checked: true
  }, {
    value: "sellin-offer",
    name: "Offres Sellin",
    checked: false
  }, {
    value: "sellin-challenge",
    name: "Challenges sellin",
    checked: false
  }, {
    value: "birthday",
    name: "Anniversaires",
    checked: true
  }];
  factory.ctrl.typesSelectedNameHealthcenter = [{
    value: "prestation-participation",
    name: "Prestations",
    checked: true
  }, {
    value: "sellout-participation",
    name: "Challenges Equipe",
    checked: true
  }, {
    value: "sellin-offer-participation",
    name: "Offres Sellin",
    checked: false
  }, {
    value: "sellin-challenge-participation",
    name: "Challenges sellin",
    checked: false
  }, {
    value: "birthday",
    name: "Anniversaires",
    checked: true
  }];
  factory.ctrl.typesSelectedName = [{
    value: "basic-event",
    name: "Événements",
    checked: false
  }, {
    value: "formation",
    name: "Formations",
    checked: false
  }, {
    value: "tv-ad",
    name: "Campagnes télé",
    checked: false
  }, {
    value: "radio-ad",
    name: "Campagnes radio",
    checked: false
  }, {
    value: "press-ad",
    name: "Campagnes presse",
    checked: false
  }, {
    value: "poster-ad",
    name: "Campagnes d'affichage",
    checked: false
  }, {
    value: "medical-visit",
    name: "Visites médicale",
    checked: false
  }, {
    value: "animation",
    name: "Animations",
    checked: false
  }, {
    value: "commercial_appointment",
    name: "Rendez-vous commerciaux",
    checked: false
  }, {
    value: "meeting",
    name: "Réunions",
    checked: false
  }, {
    value: "emailing",
    name: "Emailing",
    checked: false
  }, {
    value: "product-launch",
    name: "Lancement produits",
    checked: false
  }, {
    value: "digital-campaign",
    name: "Campagne digitale",
    checked: false
  }, {
    value: "stock-return",
    name: "Retour de stock",
    checked: false
  }];
  factory.eventsColors = {};
  factory.today = new Date();
  factory.calendarOptions = {
    loading: true,
    loader: "Récupération des événements..."
  };
  return factory;
}

angular.module("app.events").factory("eventFactory", EventFactory);