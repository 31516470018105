"use strict";
/**
 * History controller.
 *
 * @param {object} healthcenterGroupingService - Healthcenter grouping service.
 */

HistoryController.$inject = ["healthcenterGroupingService"];

function HistoryController(healthcenterGroupingService) {
  var vm = this;
  vm.movements = {
    totalRecords: 0
  };
  vm.limit = 10;
  vm.page = 1;
  vm.limitOptions = [vm.limit, 25, 50, {
    label: "Tous",
    value: function value() {
      return vm.movements.totalRecords;
    }
  }];
  (vm.loadHistory = function () {
    healthcenterGroupingService.history(vm.page, vm.limit).then(function (response) {
      vm.movements = response;
      vm.movements.records.forEach(function (item) {
        if (item.actionType.isHealthcenterSource === 1) {
          item.description = "La pharmacie " + item.healthcenter.name + " ";

          switch (item.actionType.action) {
            case "join":
              item.description += "rejoint votre groupement.";
              item.icon = {
                "class": "icon-map-marker-radius",
                style: {
                  color: "#1FA055"
                }
              };
              break;

            case "leave":
              item.description += "quitte votre groupement.";
              item.icon = {
                "class": "icon-import",
                style: {
                  color: "#A91101"
                }
              };
              break;
          }
        } else {
          item.description = "Votre groupement (" + item.grouping.name + ") ";

          switch (item.actionType.action) {
            case "accept_request":
              item.description += "accepte la pharmacie " + item.healthcenter.name + ".";
              item.icon = {
                "class": "icon-bookmark-check",
                style: {
                  color: "#1FA055"
                }
              };
              break;

            case "reject_request":
              item.description += "refuse la pharmacie " + item.healthcenter.name + ".";
              item.icon = {
                "class": "icon-close-circle",
                style: {
                  color: "#A91101"
                }
              };
              break;
          }
        }

        item.action_date = Date.parse(item.actionDate);
        item.action_date = new Date(item.action_date).toISOString();
      });
    }, function (response) {
      // eslint-disable-next-line no-console
      console.error(response);
    });
  })();
}

angular.module("app.teammanager").controller("HistoryController", HistoryController);