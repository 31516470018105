"use strict";
/**
 * Statistics map stock controller.
 *
 * @param {object} $compile - $compile.
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} environmentService - Environment service.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} analysisService - Analysis service.
 */

StatisticsMapStockController.$inject = ["$compile", "$scope", "$state", "environmentService", "$mdDialog", "StatisticsQueryService", "analysisService"];
SellinChallengeModalController.$inject = ["$scope", "$mdDialog", "$state", "laboratory", "healthcenters", "products"];
MapSellinOfferModalController.$inject = ["$scope", "$mdDialog", "$state", "laboratory", "healthcenters", "products"];

function StatisticsMapStockController($compile, $scope, $state, environmentService, $mdDialog, StatisticsQueryService, analysisService) {
  LotModalController.$inject = ["$scope", "$mdDialog", "notifyUserService"];
  $scope.searchBarConfig = {
    event: "mapStockFilterChange",
    search: []
  };
  $scope.quantityOrAmount = null;
  $scope.quantityOrAmountComparisons = [{
    sign: ">",
    label: "Supérieur à "
  }, {
    sign: ">=",
    label: "Supérieur ou égal à "
  }, {
    sign: "<",
    label: "Inférieur à "
  }, {
    sign: "<=",
    label: "Inférieur ou égal à "
  }, {
    sign: "=",
    label: "Egal à "
  }];
  $scope.quantityOrAmountComparison = $scope.quantityOrAmountComparisons[0];
  $scope.arrColor = [{
    color: "#D0011B ",
    name: "Stock négatif",
    category: 0
  }, {
    color: "#FF8800 ",
    name: "De 0 à 90 Jours",
    category: 1
  }, {
    color: "#33CC00 ",
    name: "De 90 à 120 Jours",
    category: 2
  }, {
    color: "#1E90FF",
    name: "Sur Stock",
    category: 3
  }];
  $scope.realInfos = [{
    color: "#FF8800 ",
    name: "Stock",
    category: 6
  }, {
    color: "#FF8800 ",
    name: "Jour de Stock",
    category: 7
  }];
  $scope.needsToShow = [{
    color: "#D0011B ",
    name: "Besoin en jour",
    category: 0
  }, {
    color: "#FF8800 ",
    name: "Besoin en valeur",
    category: 1
  }, {
    color: "#33CC00 ",
    name: "Besoin en unité",
    category: 2
  }, {
    color: "#D0011B ",
    name: "Sur Stock en jour",
    category: 3
  }, {
    color: "#FF8800 ",
    name: "Sur Stock en valeur",
    category: 4
  }, {
    color: "#33CC00 ",
    name: "Sur Stock en unité",
    category: 5
  }];
  $scope.aggsByZoneModels = [{
    slug: "users",
    name: "Pharmacie"
  }, {
    slug: "department",
    name: "Départements"
  }, {
    slug: "uga",
    name: "UGA"
  }, {
    slug: "region",
    name: "Région"
  }, {
    slug: "national",
    name: "National"
  }];
  $scope.filters = {};
  $scope.filters.filterNeedsStockModel = 6;
  $scope.filters.zone = "users";
  $scope.aggsByZone = processMarkers;
  $scope.token = window.sessionStorage.token;
  $scope.showLotModal = showLotModal;
  /**
   * Init the data and the map of the tab
   */

  function initTab() {
    var myLatLng = {
      lat: 47.6468754,
      lng: 2.3242131
    };
    $scope.markers = [];
    $scope.healthcenters = [];
    $scope.products = [];
    var mapOptions = {
      center: new google.maps.LatLng(myLatLng),
      zoom: 6,
      minZoom: 0,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_CENTER
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      addressControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      fullscreenControl: true
    };
    $scope.map = new google.maps.Map(document.getElementById("map_stock"), mapOptions);
    $scope.noresult = false;
    $scope.loading = false;
    $scope.usrquery = StatisticsQueryService.query;

    if ($scope.currentEnv.accessPointType === 1 && $scope.usrquery.must.laboratory.selected.length === 0) {
      var lab = {
        id: $scope.currentEnv.entityId,
        name: $scope.currentEnv.entity.name,
        type: "laboratory"
      };
      $scope.laboratory = lab;
      $scope.usrquery.must.laboratory.selected.push(lab);
    }

    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      launchSearch($scope.query);
    });
  }

  var currentEnvironment = environmentService.getEnvironment();
  currentEnvironment.then(function (env) {
    $scope.currentEnv = env;
    initTab();
  });
  /**
   * Show lot modal.
   */

  function showLotModal() {
    $mdDialog.show({
      controller: LotModalController,
      templateUrl: "app/main/statistics/stocks/map/fakeLotModal.tmpl.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  }
  /**
   * Lot modal controller.
   *
   * @param {object} $scope - $scope.
   * @param {object} $mdDialog - $mdDialog.
   * @param {object} notifyUserService - Notify user service.
   */


  function LotModalController($scope, $mdDialog, notifyUserService) {
    $scope.closeLotDialog = closeLotDialog;
    $scope.diffuseLotPeremption = diffuseLotPeremption;
    /**
     * Close lot dialog.
     */

    function closeLotDialog() {
      $mdDialog.hide();
    }
    /**
     * Diffuse lot peremption.
     */


    function diffuseLotPeremption() {
      notifyUserService.showSimpleToast("Retrait du lot numéro " + $scope.lot.number + " diffusé", {
        color: "success",
        hideDelay: 2000
      });
      $mdDialog.hide();
    }
  }
  /**
   * Handle the display of the markers on the map, regarding search and filters
   */


  function processMarkers() {
    var markers = [];

    if ($scope.markers.length > 0) {
      angular.forEach($scope.markers, function (marker) {
        if (marker) {
          marker.setMap(null);
        }
      });
    }

    $scope.markers = [];
    $scope.healthcenters = [];
    $scope.bounds = new google.maps.LatLngBounds();
    $scope.resultConfig = {
      title: {
        icon: "icon-google-maps",
        text: "Carte des stocks (" + $scope.mapStocks.aggregations.users.buckets.length + " résultats) "
      }
    };

    if (!$scope.mapStocks.aggregations.users.buckets.length) {
      $scope.resultConfig = {
        title: {
          icon: "icon-google-maps",
          text: "Carte des stocks (Aucun résultat) "
        }
      };
      return;
    }

    var stockData = $scope.mapStocks.aggregations.users.buckets;
    angular.forEach(stockData, function (hc) {
      manageHealthcenter(hc);
    });
    var markerData = [];

    if ($scope.filters.zone !== "national") {
      markerData = $scope.mapStocks.aggregations[$scope.filters.zone].buckets;
      markerData.forEach(function (hc) {
        markers.push(manageMarkers(hc));
      });
    } else {
      markerData = $scope.mapStocks.aggregations;
      markers.push(manageMarkers(markerData));
    }

    $scope.markers = markers;
  }
  /**
   * Manage healthcenter.
   *
   * @param {object} hc - Healthcenter.
   */


  function manageHealthcenter(hc) {
    var healthcenter = {};
    var healthcenterCipSearch = "";
    var regExpCipHealthcenter = new RegExp("[0-9]{7}", "g");

    if (getFigureAndUnit(hc)) {
      healthcenterCipSearch = hc.key.match(regExpCipHealthcenter);
      healthcenter = {
        id: hc.key_id,
        entityTypeId: 2,
        cip: healthcenterCipSearch ? healthcenterCipSearch[0] : "",
        name: hc.key
      };
      $scope.healthcenters.push(healthcenter);
    }
  }
  /**
   * Get figure and unit.
   *
   * @param {object} hc - Healthcenter.
   *
   * @returns {object}
   */


  function getFigureAndUnit(hc) {
    var surstockValue = 0;
    var surstockQuantity = 0;
    var needs60Days = 0;
    var quantityNeeds60Days = 0;

    if (typeof hc.range_category !== "undefined" && typeof hc.range_category.buckets !== "undefined") {
      angular.forEach(hc.range_category.buckets, function (user, key) {
        if (key <= 3) {
          needs60Days += user.needs_amount.value;
          quantityNeeds60Days += user.needs_quantity.value;
        } else {
          surstockValue += user.needs_amount.value;
          surstockQuantity += user.needs_quantity.value;
        }
      });
    }

    var figure = 0;
    var unit = "";
    $scope.filters.filterNeedsStockModel = parseInt($scope.filters.filterNeedsStockModel);

    if ($scope.filters.filterNeedsStockModel === 0) {
      figure = Math.round(hc.stock_available_days.avg);
      unit = "j";
    }

    if ($scope.filters.filterNeedsStockModel === 1) {
      figure = needs60Days;
      unit = "€";
    }

    if ($scope.filters.filterNeedsStockModel === 2) {
      figure = quantityNeeds60Days;
    }

    if ($scope.filters.filterNeedsStockModel === 3) {
      unit = "j";
      figure = hc.stock_available_days.avg;
    }

    if ($scope.filters.filterNeedsStockModel === 4) {
      figure = surstockValue;
      unit = "€";
    }

    if ($scope.filters.filterNeedsStockModel === 5) {
      figure = surstockQuantity;
    }

    if ($scope.filters.filterNeedsStockModel === 6) {
      figure = hc.stock.sum;
    }

    if ($scope.filters.filterNeedsStockModel === 7) {
      figure = Math.round(hc.stock_available_days.avg);
      unit = "j";
    } // Filter regarding the filter applied by the user


    if (!($scope.quantityOrAmountComparison.sign === ">" && $scope.quantityOrAmount >= figure || $scope.quantityOrAmountComparison.sign === ">=" && $scope.quantityOrAmount > figure || $scope.quantityOrAmountComparison.sign === "<" && $scope.quantityOrAmount <= figure || $scope.quantityOrAmountComparison.sign === "<=" && $scope.quantityOrAmount < figure || $scope.quantityOrAmountComparison.sign === "=" && $scope.quantityOrAmount !== figure) || $scope.quantityOrAmount === null) {
      return {
        figure: figure,
        unit: unit,
        needs60Days: needs60Days
      };
    }

    return {};
  }
  /**
   * Get figure and unit for zone.
   *
   * @param {object} zone -
   *
   * @returns {object}
   */


  function getFigureAndUnitForZone(zone) {
    var stockData = $scope.mapStocks.aggregations.users.buckets;
    var figure = 0;
    var needs60Days = 0;
    var unit = "";
    angular.forEach(zone.organization_id.buckets, function (hc) {
      var organization = _.find(stockData, function (organization) {
        return hc.key_id === organization.key_id;
      });

      if (organization) {
        var figureandUnit = getFigureAndUnit(organization);

        if (figureandUnit) {
          figure += getFigureAndUnit(organization).figure;
          unit = getFigureAndUnit(organization).unit;
          needs60Days += getFigureAndUnit(organization).needs60Days;
        }
      }
    });

    if (figure !== 0) {
      return {
        figure: figure,
        unit: unit,
        needs60Days: needs60Days
      };
    }

    return {};
  }
  /**
   * Manage markers.
   *
   * @param {object} hc - Healthcenter.
   *
   * @returns {object}
   */


  function manageMarkers(hc) {
    if (hc.location && hc.location.bounds && hc.location.bounds.top_left && hc.location.bounds.top_left.lon) {
      var figureandUnit = getFigureAndUnit(hc);

      if ($scope.filters.zone !== "users") {
        figureandUnit = getFigureAndUnitForZone(hc);
      }

      if (figureandUnit) {
        var figure = figureandUnit.figure;
        var unit = figureandUnit.unit;
        var needs60Days = figureandUnit.needs60Days;
        var latLng;

        if ($scope.filters.zone === "users") {
          latLng = new google.maps.LatLng(hc.location.bounds.top_left.lat, hc.location.bounds.top_left.lon);
        } else {
          latLng = new google.maps.LatLng(hc.centroid.location.lat, hc.centroid.location.lon);
        }

        var marker = new google.maps.Marker({
          position: latLng,
          map: $scope.map,
          icon: {
            url: "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(getSvg(formatNumber(figure), unit)),
            scaledSize: new google.maps.Size(50, 50),
            anchor: new google.maps.Point(25, 50)
          },
          optimized: true
        });
        var infowindow = new google.maps.InfoWindow({});
        marker.addListener("click", function () {
          var name = hc.key;

          if ($scope.filters.zone === "national") {
            name = "National";
          }

          var content = "<div><h2>" + name + "</h2>" + "<p>Stock : " + hc.stock.sum + "</p>" + "<p>Stock moyen : " + Math.round(hc.stock_available_days.avg) + " jours</p>" + "<p>Montant des besoins sur 60 jours : " + Math.round(needs60Days) + " €</p>";

          if (hc.stock_available_days.avg > 60) {
            content += "<p >Surstock : " + Math.round(hc.stock_available_days.avg - 60) + " jours</p>";
          }

          if ($scope.filters.zone === "users") {
            content += "<md-button class='md-raised md-accent' ng-click='showStocksDetailDialog(" + hc.key_id + ")'>Auditer les stocks</md-button>";
            content += "<md-button class='md-raised md-accent' ng-click='auditHealthcenter(" + hc.key_id + ")'>Auditer la pharmacie</md-button></div>";
          }

          var compiled = $compile(content)($scope);
          $scope.$apply();
          infowindow.setContent(compiled[0]);
          infowindow.open($scope.map, marker);
        });
        marker.setMap($scope.map);
        $scope.bounds.extend(latLng);
        return marker;
      }
    }

    return null;
  }

  $scope.filterStock = function (stockType) {
    $scope.isEnd = false;

    for (var index = 0; index < $scope.markers.length; index++) {
      if (index === $scope.markers.length - 1) {
        $scope.isEnd = true;
      }

      var marker = $scope.markers[index];

      if (stockType.category === $scope.categoryFiltered) {
        marker.setVisible(true);

        if ($scope.isEnd) {
          $scope.categoryFiltered = "";
        }
      } else {
        if (marker.category === stockType.category && stockType.category !== $scope.categoryFiltered) {
          marker.setVisible(true);
        } else {
          // Categories don't match
          marker.setVisible(false);
        }

        if ($scope.isEnd) {
          $scope.categoryFiltered = stockType.category;
        }
      }
    }
  };
  /**
   * Launch the search regarding filters, with reload or not of the markers
   *
   * @param {object} obj - The query object
   */


  function launchSearch() {
    var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    $scope.loading = true;
    analysisService.getLaboratoryStockMap(obj).then(function (response) {
      $scope.loading = false;
      $scope.mapStocks = response;
      processMarkers();
    }, function () {
      $scope.loading = false;
    });
  }

  $scope.showStocksDetailDialog = function (healthcenter) {
    $state.go("app.healthcenterAudit.detail.goumy", {
      id: healthcenter
    });
  };

  $scope.auditHealthcenter = function (id) {
    $state.go("app.healthcenterAudit.detail.general", {
      id: id
    });
  };

  $scope.$on("mapStockFilterChange", function (event, searchUsrQuery) {
    $scope.loading = true;
    StatisticsQueryService.buildquery(searchUsrQuery, searchUsrQuery.date).then(function (query) {
      $scope.query = query;
      launchSearch($scope.query);
    });
  });

  $scope.filterNeedsStock = function () {
    $scope.pharmaNumber = 0;
    processMarkers();
  };

  $scope.getLimits = function (array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  };
  /**
   * Get SVG for the map marker
   *
   * @param {number} value - The value
   * @param {string} unit - The unit of value
   *
   * @returns {string} The generated SVG
   */


  function getSvg(value, unit) {
    var color = "red";

    if (value > 120) {
      color = "blue";
    } else if (value > 90) {
      color = "green";
    } else if (value >= 0) {
      color = "orange";
    }

    var arrColor = {
      green: "#33cc00",
      orange: "#FF8800 ",
      red: "#D0011B ",
      blue: "#1E90FF"
    };
    var strokeColor = arrColor.orange;

    if (arrColor[color]) {
      strokeColor = arrColor[color];
    }

    var svg = "<?xml version='1.0'?>" + "<svg width='50px' height='47px' viewBox='0 0 50 47' version='1.1' xmlns='http://www.w3.org/2000/svg'>" + "<g id='Welcome' >" + "<g id='Desktop-2' transform='translate(-888.000000, -294.000000)'>" + "<g id='Group-2' transform='translate(893.000000, 297.000000)'>" + "<g id='Notes-/-Callout-S'>" + "<g id='Callout-Background'>" + "<path stroke='" + strokeColor + "' stroke-width='1' fill='#FFFFFF ' fill-rule='evenodd'  " + "d='M4.53984489,4.51543712 C-1.51328163,10.53602 -1.51328163,20.2973134 4.53984489,26.3178962 L14.6216116,36.3454599 " + "C15.1067321,36.8279722 15.8900777,36.8311452 16.3783884,36.3454599 L26.4601551,26.3178962 C32.5132816,20.2973134 " + "32.5132816,10.53602 26.4601551,4.51543712 C20.4070286,-1.50514571 10.5929714,-1.50514571 4.53984489,4.51543712 Z' id='path-1'></path>" + "</g>" + "</g>" + "<text id='number' fill='" + strokeColor + "' text-anchor='middle' font-family='Arial, Arial ' font-size='10' font-weight='400'>" + "<tspan x='16' y='19'>" + value + " " + unit + "</tspan>" + "</text>" + "</g>" + "</g>" + "</g>" + "</svg>";
    return svg;
  }

  var ranges = [{
    divider: 1e18,
    suffix: "E"
  }, {
    divider: 1e15,
    suffix: "P"
  }, {
    divider: 1e12,
    suffix: "T"
  }, {
    divider: 1e9,
    suffix: "G"
  }, {
    divider: 1e6,
    suffix: "M"
  }, {
    divider: 1e3,
    suffix: "k"
  }];
  /**
   * Format number.
   *
   * @param {number} num - Number.
   *
   * @returns {number}
   */

  function formatNumber(num) {
    for (var index = 0; index < ranges.length; index++) {
      if (num >= ranges[index].divider) {
        return (num / ranges[index].divider).toFixed(0).toString() + ranges[index].suffix;
      }
    }

    return num ? num.toFixed(0).toString() : 0;
  }
  /* Sellin offers */


  $scope.setSellinOffer = function () {
    // get must product
    if ($scope.usrquery.must.product && $scope.usrquery.must.product.selected && $scope.usrquery.must.product.selected.length > 0) {
      angular.forEach($scope.usrquery.must.product.selected, function (product) {
        $scope.products.push({
          id: product.id,
          cip: product.code,
          name: product.name
        });
      });
    } // get should product


    if ($scope.usrquery.should.product && $scope.usrquery.should.product.selected && $scope.usrquery.should.product.selected.length > 0) {
      angular.forEach($scope.usrquery.should.product.selected, function (product) {
        $scope.products.push({
          id: product.id,
          cip: product.code,
          name: product.name
        });
      });
    }

    $mdDialog.show({
      locals: {
        laboratory: $scope.laboratory,
        healthcenters: $scope.healthcenters,
        products: $scope.products
      },
      controller: MapSellinOfferModalController,
      templateUrl: "app/main/statistics/stocks/views/includes/modals/sellinOffer.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  };
  /* Sellin challenges */


  $scope.setSellinChallenge = function () {
    if ($scope.usrquery.must.product && $scope.usrquery.must.product.selected && $scope.usrquery.must.product.selected.length > 0) {
      angular.forEach($scope.usrquery.must.product.selected, function (product) {
        $scope.products.push({
          id: product.id,
          cip: product.code,
          name: product.name
        });
      });
    }

    $mdDialog.show({
      locals: {
        laboratory: $scope.laboratory,
        healthcenters: $scope.healthcenters,
        products: $scope.products
      },
      controller: SellinChallengeModalController,
      templateUrl: "app/main/statistics/stocks/views/includes/modals/sellinChallenge.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  };
}
/**
 * Map sellin offer modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $state - $state.
 * @param {object} laboratory - Laboratory.
 * @param {Array} healthcenters - Healthcenters.
 * @param {Array} products - Products.
 */


function MapSellinOfferModalController($scope, $mdDialog, $state, laboratory, healthcenters, products) {
  /**
   * Close prompt (mdDialog)
   */
  $scope.closePrompt = function () {
    $mdDialog.hide();
  };
  /**
   * Go to sellin offers with pre-loaded informations
   */


  $scope.goToSellinOffer = function () {
    $mdDialog.hide();
    $state.go("app.sellinOffer.new", {
      name: $scope.sellinOffer.name,
      description: $scope.sellinOffer.description,
      laboratory: laboratory,
      targets: healthcenters,
      products: products
    });
  };
}
/**
 * Sellin challenge modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $state - $state.
 * @param {object} laboratory - Laboratory.
 * @param {Array} healthcenters - Healthcenters.
 * @param {Array} products - Products.
 */


function SellinChallengeModalController($scope, $mdDialog, $state, laboratory, healthcenters, products) {
  /**
   * Close prompt (mdDialog)
   */
  $scope.closePrompt = function () {
    $mdDialog.hide();
  };
  /**
   * Go to sellin challenge with pre-loaded informations
   */


  $scope.goToSellinChallenge = function () {
    $mdDialog.hide();
    $state.go("app.sellinChallenge.new", {
      name: $scope.sellinChallenge.name,
      description: $scope.sellinChallenge.description,
      laboratory: laboratory,
      targets: healthcenters,
      products: products
    });
  };
}

angular.module("app.statistics.stocks").controller("StatisticsMapStockController", StatisticsMapStockController);