"use strict";
/**
 * Segment healthcenters controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} segmentService - Segment service.
 * @param {object} environmentService - Environment service.
 * @param {object} healthcenterService - Healthcenter service.
 * @param {object} $state - $state.
 * @param {object} notifyUserService - Notify user service.
 */

SegmentHealthcentersController.$inject = ["$scope", "$stateParams", "segmentService", "environmentService", "healthcenterService", "$state", "notifyUserService"];

function SegmentHealthcentersController($scope, $stateParams, segmentService, environmentService, healthcenterService, $state, notifyUserService) {
  $scope.onPaginationChange = onPaginationChange;
  $scope.searchHealthcenters = searchHealthcenters;
  $scope.deleteHealthcenterFromSegment = deleteHealthcenterFromSegment;
  $scope.createSegment = createSegment;
  $scope.updateSegment = updateSegment;
  $scope.submit = submit;
  $scope.setTag = setTag;
  $scope.segmentId = $stateParams.id;
  $scope.segment = {};
  var healthcenterIds = [];
  $scope.query = {
    healthcenterOrder: "healthcenter.name",
    limit: 10,
    page: 1,
    total: null,
    search: ""
  };
  $scope.loaderSegmentsDetailOptions = {
    loading: true,
    loader: "Récupération du segment...",
    empty: "Aucune donnée n'est disponible"
  };
  $scope.segmentHealthcenterSearchConfig = {
    search: ["idOrgas", "geo.region.id", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
    event: "addHealthcenterToSegmentFromSearch",
    submitLabel: "Choisir ces pharmacies",
    searchPlaceHolder: "Rechercher les pharmacies à ajouter au segment",
    isFilteredOnSanteSecure: false
  };
  $scope.importHealthcenterConfig = {
    entityName: "SegmentHealthcenter",
    callbackEvent: "addHealthcenterToSegmentFromImport"
  };

  $scope.goToList = function () {
    $state.go("app.segments", {
      type: 1
    });
  };

  $scope.limitOptions = [5, 10, 15];
  /**
   * On pagination change
   */

  function onPaginationChange() {
    if ($scope.segmentId) {
      getSegmentHealthcenters();
    } else {
      getHealthcenters(healthcenterIds);
    }
  }
  /**
   *
   * Initialization of the loader
   */


  loader();
  /**
   * Get segment tags.
   *
   * @returns {Promise}
   */

  function getSegmentTags() {
    return segmentService.getSegmentTags().then(function (tags) {
      $scope.segmentTags = tags;
    });
  }
  /**
   *
   * loader to load each method inside
   */


  function loader() {
    getCurrentAccess();
    getSegmentTags();

    if ($scope.segmentId) {
      getSegment().then(getSegmentHealthcenters);
    } else {
      $scope.segment.segmentTypeId = 1;
    }
  }
  /**
   * Prepare healthcenters
   *
   * @param {Array} healthcenters -
   * @param {string} keyId -
   * @returns {Promise}
   */


  function prepareHealthcenters(healthcenters, keyId) {
    var nextHealthcentersId = healthcenters.map(function (healthcenter) {
      return parseInt(healthcenter[keyId]);
    });

    if (!$scope.segmentId) {
      healthcenterIds = healthcenterIds.concat(nextHealthcentersId);
      return getHealthcenters(healthcenterIds);
    }

    var params = {
      id: $scope.segment.id,
      healthcenterIds: nextHealthcentersId
    };
    return segmentService.addHealthcentersToSegment(params).then(getSegmentHealthcenters);
  }
  /**
   * Set tag to segment.
   *
   * @param {object} tag - The tag.
   */


  function setTag(tag) {
    if ($scope.segment.segment_tag_id === tag.id) {
      $scope.segment.segmentTagId = null;
      $scope.segment.tag = null;
    } else {
      $scope.segment.tag = {
        id: tag.id
      };
      $scope.segment.segmentTagId = tag.id;
    }
  }
  /**
   * On healthcenter search validation, get healthcenters information.
   */


  $scope.$on("addHealthcenterToSegmentFromSearch", function (event, query) {
    var queryHealthcenter = {
      type: {}
    };

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(query, type)) {
        if (typeof queryHealthcenter.type[type] === "undefined") {
          queryHealthcenter.type[type] = [];
        }

        angular.forEach(query[type], function (obj) {
          queryHealthcenter.type[type].push(obj.id);
        });
      }
    };

    for (var type in query) {
      _loop(type);
    } // get healthcenter information with query results


    getHealthcenterInformation(queryHealthcenter).then(function (healthcenters) {
      return prepareHealthcenters(healthcenters, "id");
    });
  });
  /**
   * On healthcenter import validation, get healthcenters information.
   */

  $scope.$on("addHealthcenterToSegmentFromImport", function (event, healthcenters) {
    prepareHealthcenters(healthcenters, "healthcenter_id");
  });
  /**
   * Get the selected segment
   *
   * @returns {Promise}
   */

  function getSegment() {
    if ($scope.segmentId) {
      var param = {
        id: $scope.segmentId
      };
      $scope.loaderSegmentsDetailOptions.loading = true;
      return segmentService.read(param).then(function (response) {
        $scope.segment = response;
        $scope.loaderSegmentsDetailOptions.loading = false;
      });
    }

    return Promise.reject();
  }
  /**
   * Get the current access.
   */


  function getCurrentAccess() {
    environmentService.getEnvironment().then(function (access) {
      $scope.entityId = access.entity.id;
      $scope.accessPointType = access.accessPointType;

      if ($scope.accessPointType === 2) {
        $scope.segment.segmentTypeId = 2;
      }
    });
  }
  /**
   * Get healthcenters from the segment.
   */


  function getSegmentHealthcenters() {
    $scope.loaderSegmentsDetailOptions.loading = true;
    var params = {
      id: $scope.segmentId,
      page: $scope.query.page,
      limit: $scope.query.limit,
      search: $scope.query.search,
      includes: ["healthcenter.holder.user", "healthcenter.grouping"]
    };
    segmentService.getHealthcenters(params).then(function (response) {
      $scope.segment.healthcenters = response.data;
      healthcenterIds = response.data.map(function (healthcenter) {
        return healthcenter.healthcenter.id;
      });
      $scope.loaderSegmentsDetailOptions.loading = false;
      $scope.query.total = response.meta.pagination.total;
    });
  }
  /**
   * Get healthcenters information.
   *
   * @param {Array} ids - Healthcenter Ids
   * @returns {Promise} Healthcenter information
   */


  function getHealthcenters(ids) {
    $scope.loaderSegmentsDetailOptions.loading = true;

    if (!$scope.segment.healthcenters) {
      $scope.segment.healthcenters = [];
    }

    var finalIds = ids.slice(($scope.query.page - 1) * $scope.query.limit, $scope.query.page * $scope.query.limit);
    var params = {
      ids: finalIds,
      search: $scope.query.search,
      includes: ["holder.user", "grouping"]
    };
    return healthcenterService.getHealthcenters(params).then(function (healthcenters) {
      $scope.segment.healthcenters = [];
      $scope.query.total = healthcenterIds.length;
      angular.forEach(healthcenters, function (pharma) {
        if (healthcenterIds.indexOf(pharma.id) !== -1) {
          // If the healthcenter is not attached to the segment yet
          var healthcenter = {
            id: pharma.id,
            name: pharma.name,
            cip: pharma.cip,
            uga: pharma.uga,
            titu: "Pas de titulaire renseigné",
            group: ""
          };

          if (pharma.holder && pharma.holder.user) {
            healthcenter.titu = pharma.holder.user.name;
          }

          if (pharma.grouping) {
            healthcenter.group = pharma.grouping.name;
          }

          $scope.segment.healthcenters.push({
            healthcenter: healthcenter
          });
        }
      });
      $scope.loaderSegmentsDetailOptions.loading = false;
    });
  }
  /**
   * get healthcenter information from Elastic Search
   *
   * @param {object} queryPharma - Query for Elastic Search
   * @returns {Promise}
   */


  function getHealthcenterInformation(queryPharma) {
    queryPharma.full = true;
    queryPharma.isFilteredOnSanteSecure = false;
    return healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      if (!$scope.segment.healthcenters) {
        $scope.segment.healthcenters = [];
      }

      var healthcenterArray = $scope.segment.healthcenters.map(function (_ref) {
        var healthcenter = _ref.healthcenter;
        return healthcenter.id;
      });
      angular.forEach(pharmas, function (pharma) {
        // If the healthcenter is not attached to the segment yet
        if (healthcenterArray.indexOf(pharma._source.id) === -1) {
          var healthcenter = {
            id: pharma._source.id,
            name: pharma._source.name,
            cip: pharma._source.cip,
            uga: pharma._source.geo.uga,
            titu: pharma._source.user[0] ? pharma._source.user[0].full_name : "Pas de titulaire renseigné"
          };

          if (pharma._source.group[0]) {
            healthcenter.group = pharma._source.group[0].name;
          }
        }
      });
      return pharmas.map(function (pharma) {
        return pharma._source;
      });
    });
  }
  /**
   * Search healthcenters in the segment.
   *
   * @returns {Promise}
   */


  function searchHealthcenters() {
    return $scope.segmentId ? getSegmentHealthcenters() : $scope.query.search.length ? getHealthcenters([]) : getHealthcenters(healthcenterIds);
  }
  /**
   * Delete the healthcenter from segment.
   *
   * @param {event} event - The event.
   * @param {object} healthcenter - The healthcenter.
   */


  function deleteHealthcenterFromSegment(event, healthcenter) {
    // Delete
    if (!$scope.segmentId) {
      healthcenterIds.splice(healthcenterIds.indexOf(healthcenter.id), 1);
      $scope.query.search = "";
      getHealthcenters(healthcenterIds);
    } // If the segment is defined, need to delete it in the database


    if ($scope.segmentId) {
      var params = {
        segmentId: $scope.segmentId,
        healthcenterId: healthcenter.id
      };
      segmentService.healthcenterDelete(params).then(getSegmentHealthcenters());
    }
  }
  /**
   * Create the segment
   *
   * @param {object} segment - The segment.
   * @returns {Promise}
   */


  function createSegment(segment) {
    return segmentService.create(segment).then(function () {
      notifyUserService.showSuccessToast("Le segment a bien été créé.");
      $scope.goToList();
    }, function () {
      notifyUserService.showDangerToast("Erreur lors de la création du segment, veuillez réessayer.");
    });
  }
  /**
   * Update the segment
   *
   * @param {object} segment - The segment.
   * @returns {Promise}
   */


  function updateSegment(segment) {
    return segmentService.update(segment).then(function () {
      notifyUserService.showSuccessToast("Le segment a bien été modifié.");
      $scope.goToList();
    }, function () {
      notifyUserService.showDangerToast("Erreur lors de la modification du segment, veuillez réessayer.");
    });
  }
  /**
   * Submit segment form
   */


  function submit() {
    var data = formatData();
    $scope.isSaving = true;

    if (data.id) {
      $scope.updateSegment(data).then(function () {
        $scope.isSaving = false;
        notifyUserService.showSuccessToast("Le segment a bien été enregistré.");
      });
    } else {
      $scope.createSegment(data).then(function () {
        $scope.isSaving = false;
        notifyUserService.showSuccessToast("Le segment a bien été enregistré.");
      });
    }
  }
  /**
   * Format data before saving
   *
   * @returns {object}
   */


  function formatData() {
    return {
      id: $scope.segment.id,
      name: $scope.segment.name,
      segment_type_id: $scope.segment.segmentTypeId,
      segment_tag_id: $scope.segment.segmentTagId,
      entity_id: $scope.entityId,
      access_point_type: $scope.accessPointType,
      healthcenters: healthcenterIds
    };
  }
}

angular.module("app.segment").controller("segmentHealthcentersController", SegmentHealthcentersController);