"use strict";
/**
 * Dashboard laboratories wholesaler analysis controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} wholesalersService - Wholesalers service.
 */

DashboardLaboratoriesWholesalerAnalysisController.$inject = ["$scope", "wholesalersService"];

function DashboardLaboratoriesWholesalerAnalysisController($scope, wholesalersService) {
  loader();
  /**
   * Loader.
   */

  function loader() {
    wholesalersService.getDisponibilityDashboard().then(function (results) {
      $scope.disponibility = [];
      $scope.nationalDisponibility = {
        yesterday: {
          numberOfProducts: results.national.yesterday.numberOfProducts.value,
          totalProductOnDisruptionDay: 0,
          totalProductDispoRate: 0
        },
        comparison: {
          numberOfProducts: results.national.comparison.numberOfProducts.value,
          totalProductOnDisruptionDay: 0,
          totalProductDispoRate: 0
        }
      };

      if (results.national.yesterday) {
        _.values(results.national.yesterday).forEach(function (product) {
          if (product.disruptionDayCount) {
            $scope.nationalDisponibility.yesterday.totalProductOnDisruptionDay += product.disruptionDayCount.value.totalDisruptedDay;
            $scope.nationalDisponibility.yesterday.totalProductDispoRate += product.disruptionDayCount.value.rectifiedDispoRate;
          }
        });

        $scope.nationalDisponibility.yesterday.disponibilityRate = $scope.nationalDisponibility.yesterday.totalProductDispoRate / results.national.yesterday.numberOfProducts.value;
        $scope.nationalDisponibility.yesterday.disruptionDays = $scope.nationalDisponibility.yesterday.totalProductOnDisruptionDay / results.national.yesterday.numberOfProducts.value;
      }

      if (results.national.comparison) {
        _.values(results.national.comparison).forEach(function (product) {
          if (product.disruptionDayCount) {
            $scope.nationalDisponibility.comparison.totalProductOnDisruptionDay += product.disruptionDayCount.value.totalDisruptedDay;
            $scope.nationalDisponibility.comparison.totalProductDispoRate += product.disruptionDayCount.value.rectifiedDispoRate;
          }
        });

        $scope.nationalDisponibility.comparison.disponibilityRate = $scope.nationalDisponibility.comparison.totalProductDispoRate / results.national.yesterday.numberOfProducts.value;
        $scope.nationalDisponibility.comparison.disruptionDays = $scope.nationalDisponibility.comparison.totalProductOnDisruptionDay / results.national.comparison.numberOfProducts.value;
      }

      angular.forEach(results, function (wholesaler, index) {
        if (index !== "national") {
          wholesaler.name = wholesaler.yesterday.key;

          if (!wholesaler.yesterday.totalProductOnDisruptionDay) {
            wholesaler.yesterday.totalProductOnDisruptionDay = 0;
            wholesaler.comparison.totalProductOnDisruptionDay = 0;
            wholesaler.yesterday.totalProductDispoRate = 0;
            wholesaler.comparison.totalProductDispoRate = 0;
          }

          if (wholesaler.yesterday.product) {
            wholesaler.yesterday.product.buckets.forEach(function (product) {
              wholesaler.yesterday.totalProductOnDisruptionDay += product.disruptionDayCount.value.totalDisruptedDay;
              wholesaler.yesterday.totalProductDispoRate += product.disruptionDayCount.value.rectifiedDispoRate;
            });
            wholesaler.yesterday.disponibilityRate = wholesaler.yesterday.totalProductDispoRate / wholesaler.yesterday.numberOfProducts.value;
            wholesaler.yesterday.disruptionDays = wholesaler.yesterday.totalProductOnDisruptionDay / wholesaler.yesterday.numberOfProducts.value;
          }

          if (wholesaler.comparison.product) {
            wholesaler.comparison.product.buckets.forEach(function (product) {
              wholesaler.comparison.totalProductOnDisruptionDay += product.disruptionDayCount.value.totalDisruptedDay;
              wholesaler.comparison.totalProductDispoRate += product.disruptionDayCount.value.rectifiedDispoRate;
            });
            wholesaler.comparison.disponibilityRate = wholesaler.comparison.totalProductDispoRate / wholesaler.comparison.numberOfProducts.value;
            wholesaler.comparison.disruptionDays = wholesaler.comparison.totalProductOnDisruptionDay / wholesaler.comparison.numberOfProducts.value;
          }

          $scope.disponibility.push(wholesaler);
        }
      });
    });
  }
}

angular.module("app.dashboard").controller("dashboardLaboratoriesWholesalerAnalysisController", DashboardLaboratoriesWholesalerAnalysisController);