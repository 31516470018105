/* eslint-disable no-invalid-this */
"use strict";
/**
 * Data model branding.
 *
 * @param {object} $q - $q.
 * @returns {object}
 */

DataModelBranding.$inject = ["$q"];

function DataModelBranding($q) {
  var model = {
    isNew: true,
    email: "email@domain.com",
    president: "",
    phone: "01 23 45 67 89",
    logoFileId: "",
    logo: {},
    faviconFileId: "",
    favicon: {},
    displayHomePage: false,
    templateSlug: "",
    templateId: null,
    leftBarBackGround: "#2ecc71",
    leftBarColor: "#3498db",
    topBarBackGround: "#2980b9",
    topBarColor: "#2980b9",
    ourValues1: "Indiquez ici les valeurs qui fondent votre groupement",
    ourValues2: "Indiquez ici les valeurs qui fondent votre groupement",
    ourValues3: "Indiquez ici les valeurs qui fondent votre groupement",
    presidentWord: "Ecrivez ici ce que vous souhaitez dire à vos adhérents",
    whoAreUs: "Résumez ici votre groupement en quelques mots",
    sliders: []
  };
  /**
   * Constructor, with class name
   */

  function branding() {
    this.isNew = model.isNew;
    this.email = model.email;
    this.president = model.president;
    this.phone = model.phone;
    this.logoFileId = model.logoFileId;
    this.logo = model.logo;
    this.faviconFileId = model.faviconFileId;
    this.favicon = model.favicon;
    this.displayHomePage = model.displayHomePage;
    this.templateSlug = model.templateSlug;
    this.templateId = model.templateId;
    this.groupingBrandingTemplateId = model.groupingBrandingTemplateId;
    this.leftBarBackGround = model.leftBarBackGround;
    this.leftBarColor = model.leftBarColor;
    this.topBarBackGround = model.topBarBackGround;
    this.topBarColor = model.topBarColor;
    this.ourValues1 = model.ourValues1;
    this.ourValues2 = model.ourValues2;
    this.ourValues3 = model.ourValues3;
    this.presidentWord = model.presidentWord;
    this.whoAreUs = model.whoAreUs;
    this.sliders = model.sliders;
  }
  /**
   * branding.prototype.build
   *
   * @returns {Promise}
   */


  branding.prototype.build = function () {
    return $q(function (resolve) {
      resolve(new branding());
    });
  };
  /**
   * Return the constructor function
   * branding
   */


  return branding;
}

angular.module("app.grouping").factory("dataModelBranding", DataModelBranding);