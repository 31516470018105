"use strict";
/**
 * Sellin challenge card directive.
 *
 * @returns {object}
 */

showRefuseDialogController.$inject = ["$scope", "$mdDialog", "sellinChallenge"];
SellinChallengeCardController.$inject = ["$scope", "apiurl", "$state", "sellinChallengeConfig", "$mdDialog", "sellinChallengeService", "$mdToast"];

function SellinChallengeCard() {
  return {
    restrict: "E",
    scope: {
      sellin: "=sellin",
      editable: "=editable",
      awaitingValidation: "=?awaitingValidation"
    },
    templateUrl: "app/main/sellin-challenge/directives/sellinChallengeCard/sellinChallengeCard.html",
    controller: SellinChallengeCardController
  };
}
/**
 * Sellin challenge card controller.
 *
 * @param {object} $scope - Scope.
 * @param {string} apiurl - API url.
 * @param {object} $state - State.
 * @param {object} sellinChallengeConfig - Sellin challenge config.
 * @param {object} $mdDialog - MD dialog.
 * @param {object} sellinChallengeService - Sellin challenge service.
 * @param {object} $mdToast - MD toast.
 */


function SellinChallengeCardController($scope, apiurl, $state, sellinChallengeConfig, $mdDialog, sellinChallengeService, $mdToast) {
  var SELLIN_STEP_TYPES = {
    BY_QUANTITY: 1,
    BY_AMOUNT: 2
  };
  $scope.goToDetail = goToDetail;
  $scope.goToEdit = goToEdit;
  $scope.apiurl = apiurl;
  $scope.getStepProgressRate = getStepProgressRate;
  $scope.isOver = isOver;
  $scope.accept = accept;
  $scope.refuse = refuse;
  $scope.negotiate = negotiate;
  $scope.duplicate = duplicate;
  $scope.loading = {
    validation: false,
    duplication: false
  };
  /**
   * Get step progress rate.
   *
   * @param {object} step - Sellin step.
   *
   * @returns {number}
   */

  function getStepProgressRate(step) {
    if (!$scope.sellin.progress) {
      return 0;
    }

    var progress = step.type.id === SELLIN_STEP_TYPES.BY_QUANTITY ? $scope.sellin.progress.progress_quantity : $scope.sellin.progress.progress_amount;

    if (progress >= step.stepValue) {
      return 1;
    }

    return progress / step.stepValue;
  }
  /**
   * Go to sellin detail.
   */


  function goToDetail() {
    $state.go("app.sellinChallenge.detail", {
      id: $scope.sellin.id
    });
  }
  /**
   * Go to sellin edit.
   */


  function goToEdit() {
    $state.go("app.sellinChallenge.edit", {
      id: $scope.sellin.id
    });
  }
  /**
   * Check if sellin is over.
   *
   * @param {object} sellin - Sellin to test.
   *
   * @returns {boolean}
   */


  function isOver(sellin) {
    return new Date(sellin.endDate) < new Date();
  }
  /**
   * Accept sellin.
   */


  function accept() {
    var confirm = $mdDialog.confirm().title("Accepter le challenge").textContent("Êtes-vous certain(e) de vouloir accepter le challenge sell-in « " + $scope.sellin.name + " » ?").ariaLabel("acceptation challenge").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      $scope.loading.validation = true;
      sellinChallengeService.accept($scope.sellin).then(function (response) {
        $mdToast.show($mdToast.simple().textContent("Le challenge sell-in est accepté").position("top right"));
        $scope.awaitingValidation = false;
        $scope.loading.validation = false;
        $scope.$emit("accept");
        return response;
      });
    });
  }
  /**
   * Negotiate sellin.
   */


  function negotiate() {
    var confirm = $mdDialog.confirm(); // If negotiation has already started

    if ($scope.sellin.isNegotiating === 1) {
      $state.go("app.sellinChallenge.negotiate", {
        id: $scope.sellin.id
      });
    } else {
      confirm.title("Négocier le challenge").textContent("Êtes-vous certain(e) de vouloir négocier le challenge sell-in « " + $scope.sellin.name + " » ?").ariaLabel("negociation challenge").targetEvent(event).ok("Continuer").cancel("Annuler");
      $mdDialog.show(confirm).then(function () {
        $state.go("app.sellinChallenge.negotiate", {
          id: $scope.sellin.id
        });
      });
    }
  }
  /**
   * Refuse sellin.
   */


  function refuse() {
    $mdDialog.show({
      controller: showRefuseDialogController,
      templateUrl: sellinChallengeConfig.baseDir + "includes/modal/refuse/refuse.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        sellinChallenge: $scope.sellin
      }
    }).then(function (response) {
      $scope.sellin.reasonForRefusal = response;
      $scope.loading.validation = true;
      sellinChallengeService.refuse($scope.sellin).then(function (response) {
        $mdToast.show($mdToast.simple().textContent("Le challenge sell-in est refusé").position("top right"));
        $scope.awaitingValidation = false;
        $scope.loading.validation = false;
        $scope.$emit("refuse");
        return response;
      });
    });
  }
  /**
   * Duplicate sellin challenge.
   */


  function duplicate() {
    var confirm = $mdDialog.confirm().title("Duplication").textContent("\xCAtes-vous certain de vouloir dupliquer le challenge \xAB ".concat($scope.sellin.name, " \xBB ?")).ariaLabel("Dupliquer le challenge").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      $scope.loading.duplication = true;
      return sellinChallengeService.duplicate($scope.sellin.id);
    }).then(function (duplicatedSellin) {
      $mdToast.show($mdToast.simple().textContent("Le challenge sell-in a bien été dupliqué.").position("top right"));
      $scope.loading.duplication = false;
      $scope.$emit("duplicate", duplicatedSellin);
    });
  }
}
/**
 * Show refuse dialog controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} sellinChallenge - Sellin challenge.
 */


function showRefuseDialogController($scope, $mdDialog, sellinChallenge) {
  $scope.sellinChallenge = sellinChallenge;
  $scope.cancel = cancel;
  $scope.validate = validate;
  $scope.reasonForRefusal = "";
  /**
   * Cancel.
   */

  function cancel() {
    $mdDialog.cancel();
  }
  /**
   * Validate.
   */


  function validate() {
    $mdDialog.hide($scope.reasonForRefusal);
  }
}

angular.module("app.sellinChallenge").directive("sellinChallengeCard", SellinChallengeCard);