"use strict";

InfiniteScrollSpinnerController.$inject = ["$scope"];

/**
 * The infinite-scroll-spinner component.
 */
var InfiniteScrollSpinnerComponent = {
  controller: InfiniteScrollSpinnerController,
  templateUrl: "app/main/directives/app-infinite-scroll/app-infinite-scroll-spinner/appInfiniteScrollSpinner.html"
};
/**
 * Infinite scroll spinner controller.
 *
 * @param {object} $scope - $scope.
 */

function InfiniteScrollSpinnerController($scope) {
  /**
   * Toggle spinner visibility
   *
   * @param {event} event - Custom event emitted by infinite-scroll directive
   * @param {boolean} value - True to hide spinner, false to show it.
   */
  function toggle(event, value) {
    event.preventDefault();
    $scope.hide = value;
  }

  $scope.$on("infinite-scroll-completed", toggle);
}

angular.module("app.directives").component("infiniteScrollSpinner", InfiniteScrollSpinnerComponent);