"use strict";
/**
 * Fabric key press factory.
 *
 * @returns {object}
 */

function FabricKeyPress() {
  return {
    onSave: function onSave(cb) {
      $(document).keydown(function (event) {
        if ((event.ctrlKey || event.metaKey) && event.which === 83) {
          event.preventDefault();
          cb();
          return false;
        }

        return true;
      });
    }
  };
}

angular.module("app.generator").factory("fabricKeyPress", FabricKeyPress);