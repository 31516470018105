"use strict";
/**
 * Partners controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $q - $q.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $window - $window.
 * @param {object} partnersService - Partners service.
 * @param {object} partnersHealthcenterService - Partners healthcenter service.
 * @param {object} environmentService - Environment service.
 * @param {object} partnersConfig - Partners configuration.
 * @param {string} MCEPUrl - MCEP URL.
 * @param {object} $sce - $sce.
 */

PartnersController.$inject = ["$scope", "$q", "$mdDialog", "$window", "partnersService", "partnersHealthcenterService", "environmentService", "partnersConfig", "MCEPUrl", "$sce"];

function PartnersController($scope, $q, $mdDialog, $window, partnersService, partnersHealthcenterService, environmentService, partnersConfig, MCEPUrl, $sce) {
  PartnerDetailsController.$inject = ["$scope", "$mdDialog", "locals", "partnersHealthcenterService"];
  $scope.loading = true;
  $scope.partners = [];
  $scope.details = details;
  var healthcenterId;
  loader();
  /**
   * Loader.
   */

  function loader() {
    environmentService.getEnvironment().then(function (environment) {
      healthcenterId = environment.entity.id;
      return $q.all([partnersService.get(), partnersHealthcenterService.get(healthcenterId)]);
    }).then(function (responses) {
      var partners = responses[0];
      var connectedPartners = responses[1].map(function (partner) {
        return partner.id;
      });
      $scope.partners = partners.map(function (partner) {
        var isActive = connectedPartners.indexOf(partner.id) !== -1;

        if (partner.file) {
          partner.file = $sce.trustAsResourceUrl(window.location.origin + partner.file);
        }

        return Object.assign({}, partner, {
          isActive: isActive,
          isTransmissible: true
        });
      });
      $scope.loading = false;
    });
  }
  /**
   * Show partner details.
   *
   * @param {object} $event - The click event
   * @param {object} partner - The partner object
   */


  function details($event, partner) {
    if (!partner.isTransmissible) {
      $window.open(partner.url, "_blank");
      return;
    }

    $mdDialog.show({
      controller: PartnerDetailsController,
      templateUrl: partnersConfig.baseDir + "/detailsPartner.tmpl.html",
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      locals: {
        partner: partner
      }
    });
  }
  /**
   * Partner details controller.
   *
   * @param {object} $scope - $scope.
   * @param {object} $mdDialog - $mdDialog.
   * @param {object} locals - Locals.
   * @param {object} partnersHealthcenterService - Partners healthcenter service.
   */


  function PartnerDetailsController($scope, $mdDialog, locals, partnersHealthcenterService) {
    $scope.partner = locals.partner;
    $scope.partner.nextIsActive = $scope.partner.isActive;

    $scope.activate = function () {
      $scope.partner.nextIsActive = true;
      confirm();
    };

    $scope.desactivate = function () {
      $scope.partner.nextIsActive = false;
      confirm();
    };

    $scope.cancel = function () {
      $mdDialog.hide();
    };
    /**
     * Confirm.
     */


    function confirm() {
      var query = {
        healthcenter: healthcenterId,
        partner: $scope.partner.id,
        active: $scope.partner.nextIsActive
      };
      partnersHealthcenterService.toggle(query).then(function () {
        $scope.partner.isActive = $scope.partner.nextIsActive;
        $mdDialog.hide();
      });
    }
  }
}

angular.module("app.partners").controller("partnersController", PartnersController);