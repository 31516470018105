"use strict";
/**
 * Direct offer parameter pharmaML edit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} directOfferService - Direct offer service.
 * @param {object} environmentService - Environment service.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} healthcenterAuditHcDetailsService - Healthcenter audit service.
 */

DirectOfferParameterPharmaMlEditController.$inject = ["$scope", "$state", "$stateParams", "directOfferService", "environmentService", "notifyUserService", "healthcenterAuditHcDetailsService"];

function DirectOfferParameterPharmaMlEditController($scope, $state, $stateParams, directOfferService, environmentService, notifyUserService, healthcenterAuditHcDetailsService) {
  $scope.createLoading = false;
  $scope.limitOptions = [10, 25, 50];
  $scope.pagination = {
    current_page: 1,
    per_page: 10
  };
  $scope.titleConfig = {
    title: {
      icon: "icon-basket",
      text: "...",
      back: {
        state: "app.directOffer",
        tooltip: "Retour à l'offre directe"
      }
    }
  };
  $scope.searchForHealthcenter = searchForHealthcenter;
  $scope.goToList = goToList;
  $scope.pharmaMlParameterId = $stateParams.id;
  $scope.submit = submit;
  loader();
  /**
   * Loader which get at first the environment
   */

  function loader() {
    loaderEnvironment().then(getPharmaMlParameter);
  }
  /**
   * Get the current environment
   *
   * @returns {object} The user access point
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.access = access;
      return access;
    });
  }
  /**
   * Get the pharmaMl parameter if exist
   */


  function getPharmaMlParameter() {
    if ($stateParams.id) {
      var pharmaMlParameter = {
        laboratoryId: $scope.laboratoryId,
        id: $stateParams.id
      };
      directOfferService.readPharmaMlParameter(pharmaMlParameter).then(function (response) {
        $scope.pharmaMlParameter = response;
      });
      $scope.titleConfig.title.text = "Modification d'un identifiant Pharma ML";
    } else {
      $scope.pharmaMlParameter = {
        laboratoryId: $scope.access.entityId
      };
      $scope.titleConfig.title.text = "Ajout d'un identifiant Pharma ML";
    }
  }
  /**
   * Search for healthcenter to be sure it exists
   */


  function searchForHealthcenter() {
    if ($scope.pharmaMlParameter.healthcenter.cip !== "") {
      healthcenterAuditHcDetailsService.hCDetailByCip($scope.pharmaMlParameter.healthcenter.cip).then(function (healthcenter) {
        // If the healthcenter can't be found
        if (!healthcenter || !healthcenter.id) {
          $scope.healthcenterFoundOrNot = "(non trouvée)";
          $scope.pharmaMlParameter.healthcenter.cip = "";
        } else {
          $scope.healthcenterFoundOrNot = "(trouvée : " + healthcenter.name + ")";
          $scope.pharmaMlParameter.healthcenterId = healthcenter.id;
        }
      });
    }
  }
  /**
   * Submit pharmaMlParameter.
   */


  function submit() {
    if ($scope.pharmaMlParameterId) {
      updatePharmaMlParameter();
    } else {
      createPharmaMlParameter();
    }
  }
  /**
   * Create pharmaMlParameter.
   *
   * @returns {Promise} response
   */


  function createPharmaMlParameter() {
    $scope.createLoading = true;
    return directOfferService.createPharmaMlParameter($scope.pharmaMlParameter).then(function (response) {
      $scope.createLoading = false;
      notifyUserService.showSuccessToast("Le paramètre Pharma ML est ajouté.");
      goToList();
      return response;
    });
  }
  /**
   * Update pharmaMlParameter.
   *
   * @returns {Promise} response
   */


  function updatePharmaMlParameter() {
    $scope.createLoading = true;
    $scope.pharmaMlParameter.id = $stateParams.id;
    return directOfferService.updatePharmaMlParameter($scope.pharmaMlParameter).then(function (response) {
      notifyUserService.showSuccessToast("Le paramètre Pharma ML est modifié.");
      goToList();
      return response;
    });
  }
  /**
   * Go to the laboratory direct offer page, pharmaml parameter tab
   */


  function goToList() {
    $state.go("app.directOffer", {
      activeTab: 4
    });
  }
}

angular.module("app.directOffer").controller("directOfferParameterPharmaMlEditController", DirectOfferParameterPharmaMlEditController);