"use strict";
/**
 * Wholesaler markers logo.
 *
 * @returns {object}
 */

function WholesalerMarkersLogos() {
  return {
    apodis: {
      "default": "/assets/images/logos/apodis_marker_default.png",
      available: "/assets/images/logos/apodis_marker.png",
      unavailable: "/assets/images/logos/apodis_marker.png"
    },
    "default": [{
      id: 18,
      logo: "/assets/images/wholesalers/markers/default/18-cerp-rouen.png"
    }, {
      id: 23,
      logo: "/assets/images/wholesalers/markers/default/23-phoenix.png"
    }, {
      id: 20,
      logo: "/assets/images/wholesalers/markers/default/20-ocp.png"
    }, {
      id: 11,
      logo: "/assets/images/wholesalers/markers/default/11-alliance.png"
    }, {
      id: 12,
      logo: "/assets/images/wholesalers/markers/default/12-cerp-bretagne.png"
    }, {
      id: 17,
      logo: "/assets/images/wholesalers/markers/default/17-cerp-mediterranee.png"
    }],
    available: [{
      id: 18,
      logo: "/assets/images/wholesalers/markers/available/18-cerp-rouen.png"
    }, {
      id: 23,
      logo: "/assets/images/wholesalers/markers/available/23-phoenix.png"
    }, {
      id: 20,
      logo: "/assets/images/wholesalers/markers/available/20-ocp.png"
    }, {
      id: 11,
      logo: "/assets/images/wholesalers/markers/available/11-alliance.png"
    }, {
      id: 12,
      logo: "/assets/images/wholesalers/markers/available/12-cerp-bretagne.png"
    }, {
      id: 17,
      logo: "/assets/images/wholesalers/markers/available/17-cerp-mediterranee.png"
    }],
    unavailable: [{
      id: 18,
      logo: "/assets/images/wholesalers/markers/unavailable/18-cerp-rouen.png"
    }, {
      id: 23,
      logo: "/assets/images/wholesalers/markers/unavailable/23-phoenix.png"
    }, {
      id: 20,
      logo: "/assets/images/wholesalers/markers/unavailable/20-ocp.png"
    }, {
      id: 11,
      logo: "/assets/images/wholesalers/markers/unavailable/11-alliance.png"
    }, {
      id: 12,
      logo: "/assets/images/wholesalers/markers/unavailable/12-cerp-bretagne.png"
    }, {
      id: 17,
      logo: "/assets/images/wholesalers/markers/unavailable/17-cerp-mediterranee.png"
    }]
  };
}

angular.module("wholesaler.factories").factory("wholesalerMarkersLogos", WholesalerMarkersLogos);