"use strict";
/**
 * Service to manage disruption presentation.
 *
 * @param {object} apiClient - The API client.
 *
 * @returns {object} The disruption presentation service.
 */

DisruptionPresentationService.$inject = ["apiClient"];

function DisruptionPresentationService(apiClient) {
  return {
    /**
     * Read a disruption presentation.
     *
     * @param {number} laboratoryId - The ID of the laboratory.
     *
     * @returns {Promise}
     */
    read: function read(laboratoryId) {
      return apiClient.get("/laboratories/".concat(laboratoryId, "/disruption-presentation")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a disruption presentation.
     *
     * @param {number} laboratoryId - The ID of the laboratory.
     * @param {object} data - The data container.
     *
     * @returns {Promise}
     */
    update: function update(laboratoryId, data) {
      var params = {
        presentation: data.presentation
      };
      return apiClient.patch("/laboratories/".concat(laboratoryId, "/disruption-presentation"), params).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.disruption").service("disruptionPresentationService", DisruptionPresentationService);