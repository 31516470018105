"use strict";
/**
 * Pharma openings directive.
 *
 * @returns {object}
 */

PharmaOpeningsController.$inject = ["$scope"];

function PharmaOpeningsDirective() {
  return {
    restrict: "E",
    scope: {
      pharma: "="
    },
    templateUrl: "app/main/directives/pharmaOpenings/pharmaOpenings.html",
    controller: PharmaOpeningsController,
    controllerAs: "vm"
  };
}
/**
 * Pharma openings controller.
 *
 * @param {object} $scope - $scope.
 */


function PharmaOpeningsController($scope) {
  var vm = this;
  vm.pharma = $scope.pharma;
}

angular.module("apodis").directive("pharmaOpenings", PharmaOpeningsDirective);