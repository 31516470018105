"use strict";
/**
 * Dashboard pharmacies substitution operator controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} colorService - Color service.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */

dashboardPharmaciesSubstitutionOperatorController.$inject = ["$scope", "StatisticsQueryService", "colorService", "environmentService", "analysisService"];

function dashboardPharmaciesSubstitutionOperatorController($scope, StatisticsQueryService, colorService, environmentService, analysisService) {
  $scope.loading = true;
  $scope.usrquery = JSON.parse(JSON.stringify(StatisticsQueryService.pristineQuery));
  var dateStart = moment().month(0).date(0);
  var dateEnd = moment();
  $scope.initiated = false;
  $scope.noOperatorCode = false;
  $scope.colors = colorService.colorsArray;
  $scope.detailRate = [];
  $scope.detailOperatorRate = [];
  $scope.detailCopy = [];
  $scope.table = {
    filterRate: "rate",
    filterName: "name",
    reverse: false,
    searchopn: false,
    search: ""
  };
  $scope.aimedMoleculesId = ["185", "286", "184", "183", "182", "45", "126", "261", "368", "207", "81", "202", "382", "313", "379", "380", "219", "273", "37", "325", "229", "230", "208", "324", "320"];
  loader();
  /**
   * Get current environment.
   */

  function getCurrentEnv() {
    environmentService.getEnvironment().then(function (env) {
      $scope.usrquery.date = {
        from: new Date(dateStart),
        to: new Date(dateEnd)
      };
      $scope.usrquery.must.operator.selected.push({
        id: env.operatorCode
      });
      StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
        $scope.query = query;
        processQuery($scope.query);
      });
    });
  }
  /**
   * Loader.
   */


  function loader() {
    getCurrentEnv();
  }
  /**
   * Parse data operator.
   */


  function parseDataOperator() {
    var retPri = 0;
    var retGen = 0;
    angular.forEach($scope.elasticdataOperator.aggregations.groupName.buckets, function (data) {
      var parentIndex = data.parentGroupId.buckets[0].key;

      if (!$scope.detailOperatorRate[parentIndex]) {
        $scope.detailOperatorRate[parentIndex] = {
          id: parentIndex,
          name: data.parentGroupId.buckets[0].name ? data.parentGroupId.buckets[0].name : "",
          detailOperator: [],
          generiques: 0,
          princeps: 0
        };
      }

      if (!$scope.detailOperatorRate[parentIndex].detailOperator[data.key]) {
        $scope.detailOperatorRate[parentIndex].detailOperator[data.key] = {};
        $scope.detailOperatorRate[parentIndex].detailOperator[data.key].totalPrin = 0;
        $scope.detailOperatorRate[parentIndex].detailOperator[data.key].totalGen = 0;
        $scope.detailOperatorRate[parentIndex].detailOperator[data.key].name = data.name;
      }

      angular.forEach(data.prodType.buckets, function (buck) {
        switch (buck.key) {
          case "1":
            // princeps
            retPri += buck.quantity.value;
            $scope.detailOperatorRate[parentIndex].princeps += buck.quantity.value;
            $scope.detailOperatorRate[parentIndex].detailOperator[data.key].totalPrin += buck.quantity.value;
            break;

          case "2":
            // generic
            retGen += buck.quantity.value;
            $scope.detailOperatorRate[parentIndex].detailOperator[data.key].totalGen += buck.quantity.value;
            $scope.detailOperatorRate[parentIndex].generiques += buck.quantity.value;
            break;
        }
      });
      var pri = $scope.detailOperatorRate[parentIndex].princeps ? $scope.detailOperatorRate[parentIndex].princeps : 0;
      var gen = $scope.detailOperatorRate[parentIndex].generiques ? $scope.detailOperatorRate[parentIndex].generiques : 0;
      $scope.detailOperatorRate[parentIndex].rate = getDetailRate(gen, pri);
      $scope.detailOperatorCopy = _.clone($scope.detailOperatorRate);
    });
    $scope.chartOperator.data[0] = {
      label: "Génériques",
      value: retGen,
      color: $scope.colors[1]
    };
    $scope.chartOperator.data[1] = {
      label: "Princeps",
      value: retPri,
      color: $scope.colors[0]
    }; // Calculate the substitution rate for operator

    $scope.genRateOperator = retGen;
    $scope.priRateOperator = retPri;
    $scope.substitionRateOperator = 100 - $scope.priRateOperator / ($scope.genRateOperator + $scope.priRateOperator) * 100;
  }
  /**
   * Parse data.
   */


  function parseData() {
    var retPri = 0;
    var retGen = 0;
    angular.forEach($scope.elasticSubstitionData.aggregations.groupName.buckets, function (data) {
      var parentIndex = data.parentGroupId.buckets[0].key;

      if (!$scope.detailRate[parentIndex]) {
        $scope.detailRate[parentIndex] = {
          id: parentIndex,
          name: data.parentGroupId.buckets[0].name ? data.parentGroupId.buckets[0].name : "",
          detail: [],
          generiques: 0,
          princeps: 0
        };
      }

      if (!$scope.detailRate[parentIndex].detail[data.key]) {
        $scope.detailRate[parentIndex].detail[data.key] = {};
        $scope.detailRate[parentIndex].detail[data.key].totalPrin = 0;
        $scope.detailRate[parentIndex].detail[data.key].totalGen = 0;
        $scope.detailRate[parentIndex].detail[data.key].name = data.name;
      }

      angular.forEach(data.prodType.buckets, function (buck) {
        switch (buck.key) {
          case "1":
            // princeps
            retPri += buck.quantity.value;
            $scope.detailRate[parentIndex].princeps += buck.quantity.value;
            $scope.detailRate[parentIndex].detail[data.key].totalPrin += buck.quantity.value;
            break;

          case "2":
            // generic
            retGen += buck.quantity.value;
            $scope.detailRate[parentIndex].detail[data.key].totalGen += buck.quantity.value;
            $scope.detailRate[parentIndex].generiques += buck.quantity.value;
            break;
        }
      });
      var pri = $scope.detailRate[parentIndex].princeps ? $scope.detailRate[parentIndex].princeps : 0;
      var gen = $scope.detailRate[parentIndex].generiques ? $scope.detailRate[parentIndex].generiques : 0;
      $scope.detailRate[parentIndex].rate = getDetailRate(gen, pri);
      $scope.detailCopy = _.clone($scope.detailRate);
    });
    $scope.chart.data[0] = {
      label: "Génériques",
      value: retGen,
      color: $scope.colors[1]
    };
    $scope.chart.data[1] = {
      label: "Princeps",
      value: retPri,
      color: $scope.colors[0]
    }; // Calculate the global substitution rate

    $scope.genRateGloabl = retGen;
    $scope.prinRateGloabl = retPri;
    $scope.substitionRateGlobal = 100 - $scope.prinRateGloabl / ($scope.genRateGloabl + $scope.prinRateGloabl) * 100;
  }
  /**
   * Process query.
   *
   * @param {object} query - Query.
   */


  function processQuery(query) {
    analysisService.searchSubstitute(query).then(function (responseOperator) {
      $scope.loading = false;
      $scope.elasticdataOperator = responseOperator;
      initChart(true);
      delete query.must.operator;
      return analysisService.searchSubstitute(query);
    }).then(function (response) {
      $scope.loading = false;
      $scope.elasticSubstitionData = response;
      initChart(false);
    });
  }
  /**
   * Get detail rate.
   *
   * @param {number} gen -
   * @param {number} pri -
   *
   * @returns {number}
   */


  function getDetailRate(gen, pri) {
    if (!pri) {
      return 100;
    }

    if (!gen) {
      return 0;
    }

    return Math.round(gen / (gen + pri) * 100);
  }
  /**
   * Initialize chart.
   *
   * @param {boolean} operator - Operator.
   */


  function initChart(operator) {
    if (operator === false) {
      $scope.chart = {
        config: {
          refreshDataOnly: true,
          deepWatchData: true
        },
        options: {
          chart: {
            type: "pieChart",
            height: 450,
            margin: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            },
            donut: true,
            clipEdge: true,
            cornerRadius: 0,
            labelType: "percent",
            padAngle: 0.02,
            showLegend: true,
            noData: "Aucun Résultat",
            x: function x(item) {
              return item.label;
            },
            y: function y(item) {
              return item.value;
            },
            tooltip: {
              gravity: "s",
              classes: "gravity-s",
              contentGenerator: function contentGenerator(item) {
                var rows = "<tr>" + "<td class='key'>" + item.data.label + " : </td>" + "<td class='x-value'>" + item.data.value + " unités</td>" + "</tr>";
                var header = "<thead>" + "<tr>" + "<td class='legend-color-guide'><div style='background-color: " + item.data.color + ";'></div></td>" + "</tr>" + "</thead>";
                return "<table>" + header + "<tbody>" + rows + "</tbody>" + "</table>";
              }
            }
          }
        },
        data: []
      };
    } else {
      $scope.chartOperator = {
        config: {
          refreshDataOnly: true,
          deepWatchData: true
        },
        options: {
          chart: {
            type: "pieChart",
            height: 450,
            margin: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            },
            donut: true,
            clipEdge: true,
            cornerRadius: 0,
            labelType: "percent",
            padAngle: 0.02,
            showLegend: true,
            noData: "Aucun Résultat",
            x: function x(item) {
              return item.label;
            },
            y: function y(item) {
              return item.value;
            },
            tooltip: {
              gravity: "s",
              classes: "gravity-s",
              contentGenerator: function contentGenerator(item) {
                var rows = "<tr>" + "<td class='key'>" + item.data.label + " : </td>" + "<td class='x-value'>" + item.data.value + " unités</td>" + "</tr>";
                var header = "<thead>" + "<tr>" + "<td class='legend-color-guide'><div style='background-color: " + item.data.color + ";'></div></td>" + "</tr>" + "</thead>";
                return "<table>" + header + "<tbody>" + rows + "</tbody>" + "</table>";
              }
            }
          }
        },
        data: []
      };
    }

    if (operator) {
      parseDataOperator();
    } else {
      parseData();
    }
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesSubstitutionOperatorController", dashboardPharmaciesSubstitutionOperatorController);