"use strict";
/**
 * Direct offer franco edit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} directOfferService - Direct offer service.
 * @param {object} environmentService - Environment service.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} productService - Product service.
 * @param {object} healthcenterService - Healthcenter service.
 */

DirectOfferFrancoEditController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "directOfferService", "environmentService", "notifyUserService", "productService", "healthcenterService"];

function DirectOfferFrancoEditController($scope, $state, $stateParams, $mdDialog, directOfferService, environmentService, notifyUserService, productService, healthcenterService) {
  $scope.select = true;
  $scope.createLoading = false;
  $scope.limitOptions = [10, 25, 50];
  $scope.pagination = {
    current_page: 1,
    per_page: 10
  };
  $scope.francoTypes = [{
    id: 1,
    name: "Nombre d'unités"
  }, {
    id: 2,
    name: "Valeur en € H.T."
  }, {
    id: 3,
    name: "Jours de stock mini"
  }]; // Product search config

  $scope.productSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    event: "addProductsInLaboratoryFranco",
    tag: "configProductLaboratoryFranco",
    autoLaunchEvent: true,
    must: {
      "laboratory.id": []
    }
  }; // Healthcenter search config

  $scope.healthcenterSearchConfig = {
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
    event: "addHealthcentersInLaboratoryFranco",
    tag: "healthcenter",
    autoLaunchEvent: true
  };
  $scope.titleConfig = {
    title: {
      icon: "icon-basket",
      text: "...",
      back: {
        state: "app.directOffer",
        tooltip: "Retour à la gestion des offres directes"
      }
    }
  };
  $scope.goToList = goToList;
  $scope.laboratoryFrancoId = $stateParams.id;
  $scope.submit = submit;
  $scope.removeProduct = removeProduct;
  $scope.removeHealthcenter = removeHealthcenter;
  loader();
  /**
   * Loader which get at first the environment
   */

  function loader() {
    loaderEnvironment().then(getLaboratoryFranco);
  }
  /**
   * Get the current environment
   *
   * @returns {object} The user access point
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.access = access;

      if ($scope.access.accessPointType === 1) {
        $scope.laboratoryId = access.entityId;
        $scope.productSearchConfig.must = {
          "laboratory.id": [access.entityId]
        };
      }

      return access;
    });
  }
  /**
   * Get the laboratory franco
   */


  function getLaboratoryFranco() {
    if ($stateParams.id) {
      var laboratoryFranco = {
        laboratoryId: $scope.laboratoryId,
        id: $stateParams.id
      };
      directOfferService.readFranco(laboratoryFranco).then(function (response) {
        $scope.laboratoryFranco = response;
        $scope.laboratoryFranco.products = response.productsLight.map(function (product) {
          return {
            id: product.id,
            name: product.name,
            cip: product.code
          };
        });
        $scope.laboratoryFranco.healthcenters = response.healthcentersLight.map(function (healthcenter) {
          return {
            id: healthcenter.id,
            name: healthcenter.name,
            cip: healthcenter.cip
          };
        });
        $scope.laboratoryFranco.type = $scope.francoTypes.find(function (element) {
          return element.id === response.type.id;
        });
        $scope.titleConfig.title.text = response.name;
      });
    } else {
      $scope.laboratoryFranco = {
        laboratoryId: $scope.access.entityId,
        type: $scope.francoTypes[0],
        isVariegated: 1,
        isForAllProducts: false,
        isForAllHealthcenters: false,
        products: [],
        healthcenters: []
      };
      $scope.titleConfig.title.text = "Création de votre franco";
    }
  }
  /**
   * Submit laboratoryFranco.
   */


  function submit() {
    if ($scope.laboratoryFrancoId) {
      updateLaboratoryFranco();
    } else {
      createLaboratoryFranco();
    }
  }
  /**
   * Create laboratoryFranco.
   *
   * @returns {Promise}
   */


  function createLaboratoryFranco() {
    $scope.createLoading = true;
    return directOfferService.createFranco($scope.laboratoryFranco).then(function (response) {
      $scope.createLoading = false;
      notifyUserService.showSuccessToast("Le franco est ajouté");
      goToList();
      return response;
    });
  }
  /**
   * Update laboratoryFranco.
   *
   * @returns {Promise}
   */


  function updateLaboratoryFranco() {
    $scope.createLoading = true;
    $scope.laboratoryFranco.id = $stateParams.id;
    return directOfferService.updateFranco($scope.laboratoryFranco).then(function (response) {
      notifyUserService.showSuccessToast("Le franco est modifié");
      goToList();
      return response;
    });
  }
  /**
   * Remove product from selection.
   *
   * @param {number} productId - The id of the product to remove.
   */


  function removeProduct(productId) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression du produit ?").ariaLabel("Suppression du produit").ok("Oui").cancel("Non")).then(function () {
      $scope.laboratoryFranco.products = $scope.laboratoryFranco.products.filter(function (product) {
        return product.id !== productId;
      });
      notifyUserService.showSuccessToast("Le produit a été supprimé.");
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Remove healthcenter from selection.
   *
   * @param {number} healthcenterId - The id of the healthcenter to remove.
   */


  function removeHealthcenter(healthcenterId) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression de la cible ?").ariaLabel("Suppression de la cible").ok("Oui").cancel("Non")).then(function () {
      $scope.laboratoryFranco.healthcenters = $scope.laboratoryFranco.healthcenters.filter(function (healthcenter) {
        return healthcenter.id !== healthcenterId;
      });
      notifyUserService.showSuccessToast("La cible a été supprimée.");
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Go to the laboratory direct offer page, franco tab
   */


  function goToList() {
    $state.go("app.directOffer", {
      activeTab: 1
    });
  }
  /**
   * Add the products in laboratory franco
   *
   * @param {object} event - The event
   * @param {Array} data - Data
   *
   * @returns {Promise}
   */


  $scope.$on("addProductsInLaboratoryFranco", function (event, data) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    } // getProducts with query results


    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.laboratoryFranco.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.laboratoryFranco.products.push(product);
        }
      });
    });
  });
  /**
   * Add the healthcenters in laboratory franco
   *
   * @param {object} event - The event
   * @param {object} query - The query
   *
   * @returns {Promise}
   */

  $scope.$on("addHealthcentersInLaboratoryFranco", function (event, query) {
    var queryPharma = {};
    queryPharma.type = {};
    var groups = [];

    var _loop2 = function _loop2(type) {
      if (type !== "groups") {
        if (Object.prototype.hasOwnProperty.call(query, type)) {
          if (typeof queryPharma.type[type] === "undefined") {
            queryPharma.type[type] = [];
          }

          angular.forEach(query[type], function (obj) {
            if (type === "uga") {
              queryPharma.type[type].push(obj.name);
            } else {
              queryPharma.type[type].push(obj.id);
            }
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(query, type)) {
        angular.forEach(query[type], function (group) {
          if (!_.find($scope.laboratoryFranco.healthcenters, function (element) {
            return group.id === element.id && element.entityTypeId === 3;
          })) {
            group.entityTypeId = 3;
            $scope.laboratoryFranco.healthcenters.push(group);
          }

          groups.push(group);
        });
      }
    };

    for (var type in query) {
      _loop2(type);
    } // getPharmas with query results


    healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      angular.forEach(pharmas, function (pharma) {
        if (!_.find($scope.laboratoryFranco.healthcenters, function (participant) {
          return pharma.id === participant.id;
        })) {
          pharma.entityTypeId = 2;
          $scope.laboratoryFranco.healthcenters.push(pharma);
        }
      });
    });
  });
}

angular.module("app.directOffer").controller("directOfferFrancoEditController", DirectOfferFrancoEditController);