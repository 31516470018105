"use strict";

PrescriptionRenewalPictureController.$inject = ["$scope", "$window", "$sce", "customerService", "hdsApiConfig", "notifyUserService"];
var PrescriptionRenewalPictureComponent = {
  templateUrl: "app/main/patients/components/prescription-renewal-picture/prescription-renewal-picture.html",
  controller: PrescriptionRenewalPictureController,
  bindings: {
    prescription: "<",
    hideActionsButton: "@"
  },
  controllerAs: "vm"
};
/**
 * PrescriptionRenewalPictureController.
 *
 * @param {object} $scope - $scope.
 * @param {object} $window - $window.
 * @param {object} $sce - $sce.
 * @param {object} customerService - The customer service.
 * @param {object} hdsApiConfig - The HDS API config.
 * @param {object} notifyUserService - The notification service.
 */

function PrescriptionRenewalPictureController($scope, $window, $sce, customerService, hdsApiConfig, notifyUserService) {
  var prescription;
  $scope.hideActionsButton = this.hideActionsButton;
  $scope.loading = true;
  $scope.imageUrl = null;
  $scope.print = print;
  $scope.open = open;
  $scope.nextPage = nextPage;
  $scope.previousPage = previousPage;
  $scope.fileIndex = 0;
  $scope.content = null;
  var vm = this;
  /**
   * $onInit function.
   */

  this.$onInit = function () {
    prescription = vm.prescription;
    $scope.filesLength = prescription.files.length;
    $scope.patientOnBoarded = prescription.customer.onboarded;
    loader();
  };
  /**
   * Loads prescription renewal thumbnail.
   *
   * @returns {void}
   */


  function loader() {
    getPrescriptionRenewalThumbnail(prescription);
  }
  /**
   * Get the prescription renewal thumbnail.
   *
   * @param {object} prescription - The prescription object.
   *
   * @returns {Promise<*>}
   */


  function getPrescriptionRenewalThumbnail(prescription) {
    $scope.loading = true;
    var imageUri = getPrescriptionRenewalUri(prescription);

    if (!imageUri) {
      return Promise.resolve(null);
    }

    var formattedImageUri = imageUri.replace(hdsApiConfig.url, "");
    return customerService.getPrescriptionRenewalFile(formattedImageUri).then(function (file) {
      if (file) {
        $scope.imageUrl = URL.createObjectURL(file);

        if (file.type === "application/pdf") {
          $scope.content = $sce.trustAsResourceUrl($scope.imageUrl);
        }
      }
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Retrieve the uri of the prescription image.
   *
   * @param {object} prescription - The prescription object.
   *
   * @returns {string|null}
   */


  function getPrescriptionRenewalUri(prescription) {
    if (!prescription.files.length) {
      return null;
    }

    if (prescription.files[$scope.fileIndex].thumbnail) {
      return prescription.files[$scope.fileIndex].thumbnail.large;
    }

    return null;
  }
  /**
   * Create an invisible iframe then print the content.
   *
   * @param {string} fileUri - The uri of the file to put in the iframe.
   *
   * @returns {void}
   */


  function createIframeAndPrint(fileUri) {
    var iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = fileUri;
    document.body.appendChild(iframe);
    iframe.focus();
    iframe.contentWindow.print();
  }
  /**
   * Open the prescription renewal pdf file into a new blank page.
   *
   * @returns {void}
   */


  function open() {
    output(getPdfUri(), "window");
  }
  /**
   * Print the prescription renewal pdf file.
   *
   * @returns {void}
   */


  function print() {
    output(getPdfUri(), "print");
  }
  /**
   * Output the document depending the mode.
   *
   * @param {string} uri - The file uri
   * @param {string} mode - output type.
   *
   * @returns {void}
   */


  function output(uri, mode) {
    if (!uri) {
      if (mode === "print") {
        createIframeAndPrint($scope.imageUrl);
      } else if (mode === "window") {
        $window.open($scope.imageUrl);
      }

      return;
    }

    customerService.getPrescriptionRenewalFile(uri).then(function (blobFile) {
      if (!blobFile) {
        notifyUserService.showDangerToast("Impossible de récupérer le document");
        return null;
      }

      var file = URL.createObjectURL(blobFile);

      if (mode === "print") {
        createIframeAndPrint(file);
      } else if (mode === "window") {
        $window.open(file);
      }

      return file;
    });
  }
  /**
   * Retrieve and format the pdf file uri.
   *
   * @returns {null|string}
   */


  function getPdfUri() {
    if (!prescription.files[$scope.fileIndex].pdf) {
      return null;
    }

    return prescription.files[$scope.fileIndex].pdf.replace(hdsApiConfig.url, "");
  }
  /**
   * Fetch the next page if exists.
   *
   * @returns {null|Promise<*>}
   */


  function nextPage() {
    if (++$scope.fileIndex >= prescription.files.length) {
      $scope.fileIndex = prescription.files.length;
      return null;
    }

    URL.revokeObjectURL($scope.imageUrl);
    return getPrescriptionRenewalThumbnail(prescription);
  }
  /**
   * Fetch the previous page.
   *
   * @returns {null|Promise<*>}
   */


  function previousPage() {
    if ($scope.fileIndex <= 0) {
      return null;
    }

    URL.revokeObjectURL($scope.imageUrl);
    $scope.fileIndex -= 1;
    return getPrescriptionRenewalThumbnail(prescription);
  }
}

angular.module("app.patients.components").component("prescriptionRenewalPicture", PrescriptionRenewalPictureComponent);