"use strict";
/**
 * Patient pathology advices controller.
 *
 * @param {object} $scope - The angular $scope object.$
 * @param {object} $rootScope - $rootScope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} productService - The product service.
 * @param {object} patientPathologyService - The patient pathology service.
 * @param {object} notifyUserService - The notify user service.
 */

PatientPathologyAdvicesController.$inject = ["$scope", "$rootScope", "$mdDialog", "$state", "$stateParams", "productService", "patientPathologyService", "notifyUserService"];

function PatientPathologyAdvicesController($scope, $rootScope, $mdDialog, $state, $stateParams, productService, patientPathologyService, notifyUserService) {
  $scope.pathologiesAdvicesTitle = {
    title: {
      text: "Mes conseils",
      back: {
        state: null
      }
    }
  };
  $scope.forms = {};
  $scope.advice = {
    content: ""
  };
  $scope.loading = false;
  $scope.configAddProductSearchBar = {
    event: "productsSelected",
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    tag: "pathologiesProductsChange",
    geoForce: true,
    searchPlaceHolder: "Ajouter un produit...",
    autoLaunchEvent: false
  };
  $scope.query = {
    order: "name",
    limit: 10,
    page: 1,
    total: null,
    search: ""
  };
  $scope.products = [];
  $scope.deleteProduct = deleteProduct;
  $scope.postAdviceForm = postAdviceForm;
  $scope.detachAll = detachAll;
  loader();
  /**
   * Loader function.
   */

  function loader() {
    Promise.all([readPathology(), loadAdvice(), loadProducts()]).then(function () {
      $scope.loading = false;
      $scope.$apply();
    });
  }
  /**
   * Read the pathology passed in $stateParams.
   *
   * @returns {Promise}
   */


  function readPathology() {
    return patientPathologyService.readPathology($stateParams.pathologyId).then(function (response) {
      $scope.pathology = response;
    });
  }
  /**
   * Load the pathology advice of exists.
   *
   * @returns {Promise}
   */


  function loadAdvice() {
    return patientPathologyService.readPathologyAdvice($stateParams.pathologyId).then(function (advice) {
      $scope.advice = advice;
    });
  }
  /**
   * Load the pathology products.
   *
   * @returns {Promise}
   */


  function loadProducts() {
    return patientPathologyService.indexHealthcenterPathologyProducts($stateParams.pathologyId).then(function (products) {
      $scope.products = products;
      $scope.query.total = products.length;
    });
  }
  /**
   * Post the advice form.
   */


  function postAdviceForm() {
    if (!$scope.forms.adviceForm.$valid) {
      return;
    }

    $scope.advice.id ? updateAdvice() : createAdvice();
  }
  /**
   * Detach all products.
   */


  function detachAll() {
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain(e) de vouloir supprimer tous les produits associés ?").ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      $scope.products = [];
      attachProducts();
    });
  }
  /**
   * Update the current advice.
   */


  function updateAdvice() {
    patientPathologyService.updatePathologyAdvice($stateParams.pathologyId, $scope.advice.content).then(function (response) {
      $scope.advice = response;
      notifyUserService.showSuccessToast("Votre conseil pharmacien a bien été mis à jour");
    });
  }
  /**
   * Create the advice.
   */


  function createAdvice() {
    patientPathologyService.createPathologyAdvice($stateParams.pathologyId, $scope.advice.content).then(function (response) {
      $scope.advice = response;
      notifyUserService.showSuccessToast("Votre conseil pharmacien a bien été enregistré");
    });
  }
  /**
   * Delete a product from the list.
   *
   * @param {number} productId - The product id.
   */


  function deleteProduct(productId) {
    $scope.products = $scope.products.filter(function (product) {
      return product.id !== productId;
    });
    attachProducts();
  }
  /**
   * Attach the products.
   *
   * @returns {Promise}
   */


  function attachProducts() {
    return patientPathologyService.attachHealthcenterPathologyProducts($stateParams.pathologyId, $scope.products.map(function (product) {
      return product.id;
    })).then(function (response) {
      notifyUserService.showSuccessToast("".concat(Object.keys(response.detached).length, " produit(s) supprim\xE9(s) et ").concat(response.attached.length, " produit(s) li\xE9(s)"));
      loadProducts();
    });
  }

  $rootScope.$on("productsSelected", function (event, data) {
    if (_.isUndefined($scope.products)) {
      $scope.products = [];
    }

    getProductsFromSearchBar(event, data).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.products.push(product);
        }
      });
      attachProducts();
    });
  });
  /**
   * Get products from search bar.
   *
   * @param {object} event - The searchbar event.
   * @param {object} data - The searchbar data.
   *
   * @returns {Promise<[]>}
   */

  function getProductsFromSearchBar(event, data) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    }

    return productService.postPresentationByType(queryProduct).then(function (products) {
      var result = [];
      angular.forEach(products, function (product) {
        if (!_.find(result, function (prod) {
          return product.id === prod.id;
        })) {
          result.push(product);
        }
      });
      return result;
    });
  }
}

angular.module("app.patients").controller("patientPathologyAdvicesController", PatientPathologyAdvicesController);