"use strict";
/**
 * Merchandising plan main controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $mdToast - $mdToast.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} merchandisingPlanMainService - Merchandising plan main service.
 */

MerchandisingPlanMainController.$inject = ["$scope", "$state", "$mdToast", "$mdDialog", "merchandisingPlanMainService"];

function MerchandisingPlanMainController($scope, $state, $mdToast, $mdDialog, merchandisingPlanMainService) {
  $scope.create = create;
  $scope.update = update;
  $scope.destroy = destroy;
  $scope.loaderMerchandisingPlans = {
    loading: true,
    loader: "Chargement des plans merchandising…",
    empty: "Vous n'avez pas encore ajouté des plans merchandising"
  };
  /** Loader. */

  (function loader() {
    get();
  })();
  /** Get merchandising plans. */


  function get() {
    $scope.loading = true;
    merchandisingPlanMainService.get().then(function (merchandisingPlans) {
      $scope.merchandisingPlans = merchandisingPlans;
      $scope.loaderMerchandisingPlans.loading = false;
    });
  }
  /**
   * Create a merchandising plan.
   *
   * @param {object} event - Event.
   */


  function create(event) {
    $mdDialog.show({
      controller: ["$scope", function controller($scope) {
        $scope.cancel = cancel;
        $scope.validate = validate;
        /**
         * Cancel.
         */

        function cancel() {
          $mdDialog.cancel();
        }
        /**
         * Validate.
         */


        function validate() {
          merchandisingPlanMainService.create($scope.data).then(function (merchandisingPlan) {
            $mdDialog.cancel();
            $state.go("app.merchandisingPlanFile", {
              merchandisingPlanId: merchandisingPlan.id
            });
          });
        }
      }],
      templateUrl: "app/main/merchandising-plan/views/dialog.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      fullscreen: $scope.customFullscreen
    });
  }
  /**
   * Update a merchandising plan.
   *
   * @param {object} event - Event.
   * @param {object} merchandisingPlan - Merchandising plan.
   */


  function update(event, merchandisingPlan) {
    $mdDialog.show({
      controller: ["$scope", function controller($scope) {
        $scope.cancel = cancel;
        $scope.validate = validate;
        $scope.data = merchandisingPlan;
        /**
         * Cancel.
         */

        function cancel() {
          $mdDialog.cancel();
        }
        /**
         * Validate.
         */


        function validate() {
          $scope.data.merchandisingPlanId = merchandisingPlan.id;
          merchandisingPlanMainService.update($scope.data).then(function () {
            $mdDialog.cancel();
          });
        }
      }],
      templateUrl: "app/main/merchandising-plan/views/dialog.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      fullscreen: $scope.customFullscreen
    });
  }
  /**
   * Destroy a merchandising plan.
   *
   * @param {object} event - Event.
   * @param {object} merchandisingPlan - Mmerchandising plan.
   * @param {number} index - Index.
   */


  function destroy(event, merchandisingPlan, index) {
    $mdDialog.show($mdDialog.confirm().title("Confirmez-vous la suppression du plan merchandising « " + merchandisingPlan.title + " » ?").textContent("Le plan merchandising sera définitevement supprimé.").ariaLabel("Suppression d’un plan merchandising").targetEvent(event).ok("Oui").cancel("Non")).then(function () {
      return merchandisingPlanMainService["delete"]({
        merchandisingPlanId: merchandisingPlan.id
      });
    }).then(function (merchandisingPlan) {
      $scope.merchandisingPlans.splice(index, 1);
      $mdToast.show($mdToast.simple().textContent("Le plan merchandising « " + merchandisingPlan.title + " » est supprimé"));
    });
  }
}

angular.module("app.merchandising-plan").controller("merchandisingPlanMainController", MerchandisingPlanMainController);