"use strict";
/**
 * The wholesaler category service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

WholesalerCategoryService.$inject = ["apiClient"];

function WholesalerCategoryService(apiClient) {
  return {
    /**
     * Get wholesaler categories.
     *
     * @param {object} data - The wholesaler
     *
     * @returns {Promise} The promise
     */
    get: function get(data) {
      return apiClient.get("/wholesalers/".concat(data.wholesalerId, "/categories")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a wholesaler category.
     *
     * @param {object} data - The wholesaler category
     *
     * @returns {Promise} The promise
     */
    create: function create(data) {
      return apiClient.post("/wholesalers/".concat(data.wholesalerId, "/categories"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a wholesaler category.
     *
     * @param {object} data - The wholesaler category
     *
     * @returns {Promise} The promise
     */
    read: function read(data) {
      var query = {
        includes: ["products", "products.laboratory", "file"],
        data: data
      };
      return apiClient.get("/wholesalers/".concat(data.wholesalerId, "/categories/").concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a wholesaler category.
     *
     * @param {object} data - The wholesaler category
     *
     * @returns {Promise} The promise
     */
    update: function update(data) {
      return apiClient.put("/wholesalers/".concat(data.wholesalerId, "/categories/").concat(data.id), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a wholesaler category.
     *
     * @param {object} data - The wholesaler category
     *
     * @returns {Promise} The promise
     */
    "delete": function _delete(data) {
      return apiClient["delete"]("/wholesalers/".concat(data.wholesalerId, "/categories/").concat(data.id)).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("wholesaler.category").service("wholesalerCategoryService", WholesalerCategoryService);