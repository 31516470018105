"use strict";
/**
 * The app-plural directive.
 *
 * @returns {object}
 */

function AppPlural() {
  return {
    restrict: "E",
    scope: {
      unit: "@",
      count: "="
    },
    templateUrl: "app/main/directives/app-plural/appPlural.html"
  };
}

angular.module("app.directives").directive("appPlural", AppPlural);