"use strict";
/**
 * Contact list controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} contactsService - Contacts service.
 * @param {object} $mdDialog - Material design dialog.
 * @param {object} $mdToast - Material design toast.
 */

ContactListController.$inject = ["$scope", "contactsService", "$mdDialog", "$mdToast"];

function ContactListController($scope, contactsService, $mdDialog, $mdToast) {
  $scope.limitOptions = [5, 10, 15];
  $scope.query = {
    order: "-healthcenters.length",
    limit: 10,
    page: 1
  };
  $scope.queryDetail = {
    order: "-name",
    limit: 10,
    page: 1
  };
  $scope.pharmacyDetailTitleConfig = {
    title: {
      icon: "icon-format-list-bulleted",
      text: "Liste des contacts de " + $scope.commercial
    }
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.loaderContacts = {
    loading: true,
    loader: "Récupération des contacts en cours",
    empty: "Aucun contact trouvé"
  };
  $scope.importConfig = {
    entityName: "Contact"
  };
  $scope.exportConfig = {
    entityName: "Contact",
    fileType: "xlsx",
    fileName: "Export_Contact",
    availableColumns: {
      name: "Nom",
      first_name: "Prénom",
      job_title: "Fonction",
      mail: "Email",
      phone_number: "Téléphone",
      uga: "UGA"
    },
    defaultFilter: {}
  };
  /**
   * Confirm box for deletion of a contact
   *
   * @param {event} event - The event
   * @param {number} contactId - The contact identifier
   */

  $scope.confirmDeleteContact = function (event, contactId) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain(e) de vouloir supprimer ce contact ?").ariaLabel("suppression du contact").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      deleteContact(contactId);
    });
  };

  $scope.pharmaciesDetail = [];
  $scope.seeContactHealthcenters = seeContactHealthcenters;
  $scope.showGlobalContacts = showGlobalContacts;
  $scope.contactHealthcenters = [];
  loader();
  /**
   * The loading function
   */

  function loader() {
    getContacts();
  }
  /**
   * Get Contacts
   */


  function getContacts() {
    contactsService.get().then(function (contacts) {
      $scope.contacts = contacts;
      $scope.loaderContacts.loading = false;
    });
  }
  /**
   * see the contacts of the commercial
   *
   * @param {object} contact - Contact.
   */


  function seeContactHealthcenters(contact) {
    $scope.loaderContacts.loading = true;
    var params = {
      id: contact.id
    };
    contactsService.read(params).then(function (laboratoryContact) {
      $scope.contactName = laboratoryContact.name;
      $scope.contactHealthcenters = laboratoryContact.healthcentersLight;
      $scope.loaderContacts.loading = false;
    });
  }
  /**
   * Delete one contact
   *
   * @param {number} contactId - The contact identifier
   *
   * @returns {Promise} Contact deletion promise.
   */


  function deleteContact(contactId) {
    var contact = {
      id: contactId
    };
    return contactsService["delete"](contact).then(function () {
      $mdToast.show($mdToast.simple().textContent("Le contact a été correctement supprimé.").position("top right"));
      getContacts();
    });
  }
  /**
   * Show all the laboratory commercials/contacts
   */


  function showGlobalContacts() {
    $scope.contactName = "";
    $scope.contactHealthcenters = [];
  }
}

angular.module("app.contacts").controller("contactListController", ContactListController);