"use strict";
/**
 * Dashboard groups sellin challenge controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {string} apiurl - API URL.
 * @param {object} sellinChallengeService - Sellin challenge service.
 */

DashboardGroupsSellinChallengesController.$inject = ["$scope", "$state", "apiurl", "sellinChallengeService"];

function DashboardGroupsSellinChallengesController($scope, $state, apiurl, sellinChallengeService) {
  $scope.goToChallengeDetail = goToChallengeDetail;
  $scope.imageFallback = "assets/images/sellouts/default-challenge-1080x600.jpg";
  $scope.apiurl = apiurl;
  $scope.token = window.sessionStorage.token;
  loader();
  /**
   * loader
   * to load each method inside
   */

  function loader() {
    $scope.loading = true;
    loadCurrents();
  }
  /**
   * Load currents.
   *
   * @returns {Promise}
   */


  function loadCurrents() {
    return sellinChallengeService.currents().then(function (response) {
      $scope.loading = false;
      $scope.sellinChallenges = response;
      return response;
    });
  }
  /**
   * go to detail state of the challenge
   *
   * @param {number} id - ID.
   */


  function goToChallengeDetail(id) {
    $state.go("app.sellinChallenge.detail", {
      id: id
    });
  }
}

angular.module("app.dashboard").controller("dashboardGroupsSellinChallengesController", DashboardGroupsSellinChallengesController);