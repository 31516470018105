"use strict";
/**
 * Disruption analysis controller.
 *
 * @param {object} disruptionService - Disruption service.
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} StatisticsQueryService - Statistics query service.
 */

DisruptionAnalysisController.$inject = ["disruptionService", "$scope", "$stateParams", "StatisticsQueryService"];

function DisruptionAnalysisController(disruptionService, $scope, $stateParams, StatisticsQueryService) {
  $scope.pagination = {
    order: "-delivery.sum",
    limit: 10,
    page: 1
  };
  $scope.query = StatisticsQueryService.query;
  $scope.loaderOptions = {
    loading: true,
    loader: "Chargement de l’analyse",
    empty: "Aucune donnée."
  };
  $scope.loaderDisruptionsOptions = {
    loading: true,
    loader: "Chargement des tensions d'approvisionnement déclarées",
    empty: "Aucune donnée."
  };
  $scope.rangeSelected = rangeSelected;
  loader();
  /**
   * The loading function
   */

  function loader() {
    var rangeDate = {
      from: moment().date(1).format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD")
    };
    $scope.dateLabel = moment(rangeDate.from).format("DD-MMM-YYYY") + " Au " + moment(rangeDate.to).format("DD-MMM-YYYY");
    $scope.query.date = rangeDate;
    $scope.loaderDisruptionsOptions.loading = true;
    $scope.loaderOptions.loading = true;
    getDisruptionAnalysis();
  }
  /**
   * get disruptions
   */


  function getDisruptionAnalysis() {
    StatisticsQueryService.buildquery($scope.query, $scope.query.date).then(function (query) {
      query.aggBy = "item.wholesaler.id";
      query.sort = $scope.pagination;
      query.interval = "auto";
      var params = {
        id: $stateParams.laboId,
        query: query
      };
      disruptionService.getLaboratoryDisruptionAnalysis(params).then(function (analysis) {
        $scope.laboratory = analysis.laboratory;
        $scope.disruptions = analysis.disruption;
        $scope.wholesalerAudit = analysis.wholesalerAudit.datas.aggregations.aggBy.buckets;
        $scope.negativePharmaML = [];
        analysis.negativePharmaML.aggregations.presentation.buckets.forEach(function (presentation) {
          presentation.disruption.value.forEach(function (disruption) {
            disruption.presentation = presentation.key;
            analysis.stock.hits.hits.forEach(function (stock) {
              if (presentation.key_id === stock._source.product.presentation) {
                disruption.stock = stock;
              }
            });
            $scope.negativePharmaML.push(disruption);
          });
        });
        $scope.loaderDisruptionsOptions.loading = false;
        $scope.loaderOptions.loading = false;
      });
    });
  }
  /**
   * Range selected.
   *
   * @param {object} date - Date.
   */


  function rangeSelected(date) {
    $scope.loaderOptions.loading = true;
    $scope.loaderDisruptionsOptions.loading = true;
    $scope.query.date = {
      from: moment(date.startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      to: moment(date.endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
    };
    getDisruptionAnalysis();
  }
}

angular.module("app.disruption").controller("disruptionAnalysisController", DisruptionAnalysisController);