"use strict";
/**
 * Dashboard laboratories sellin offers controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {string} apiurl - API URL.
 * @param {object} sellinOfferService - Sellin offer service.
 */

DashboardLaboratoriesSellinOffersController.$inject = ["$scope", "$state", "apiurl", "sellinOfferService"];

function DashboardLaboratoriesSellinOffersController($scope, $state, apiurl, sellinOfferService) {
  $scope.loading = true;
  $scope.goToOfferDetail = goToOfferDetail;
  $scope.imageFallback = "assets/images/sellouts/default-challenge-1080x600.jpg";
  $scope.apiurl = apiurl;
  $scope.token = window.sessionStorage.token;
  loader();
  /**
   * Loader.
   */

  function loader() {
    loadAwaiting();
  }
  /**
   * Load awaiting sellin offers.
   *
   * @returns {Promise}
   */


  function loadAwaiting() {
    return sellinOfferService.getAwaiting().then(function (response) {
      $scope.loading = false;
      $scope.sellinOffers = response;
      return response;
    });
  }
  /**
   * go to detail state of the offer
   *
   * @param {number} id - ID.
   */


  function goToOfferDetail(id) {
    $state.go("app.sellinOffer.detail", {
      id: id
    });
  }
}

angular.module("app.dashboard").controller("dashboardLaboratoriesSellinOffersController", DashboardLaboratoriesSellinOffersController);