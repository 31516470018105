"use strict";
/**
 * Dashboard groupings transmission sante secure controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} groupingTransmissionService - Grouping transmission service.
 */

DashboardGroupsTransmissionSanteSecureController.$inject = ["$scope", "groupingTransmissionService"];

function DashboardGroupsTransmissionSanteSecureController($scope, groupingTransmissionService) {
  var maxDayWithoutTransmission = moment().subtract(2, "days");
  loader();
  /**
   * Loader.
   */

  function loader() {
    getTransmission();
  }
  /**
   * Get healthcenters' last transmission.
   */


  function getTransmission() {
    groupingTransmissionService.getLastTransmission().then(function (response) {
      $scope.transmissions = response.filter(filterTransmission).map(mapTransmission);
    });
  }
  /**
   * Filter on healthcenter last import.
   *
   * @param {object} healthcenter - Healthcenter instance
   *
   * @returns {boolean} Last transmission expired
   */


  function filterTransmission(healthcenter) {
    return moment(healthcenter.lastLgoImport) <= maxDayWithoutTransmission;
  }
  /**
   * Adapt and filter attributes healthcenter.
   *
   * @param {object} healthcenter - Healthcenter instance
   *
   * @returns {object} Healthcenter's attributes filtered
   */


  function mapTransmission(healthcenter) {
    return {
      name: healthcenter.name,
      lastLgoImport: new Date(healthcenter.lastLgoImport)
    };
  }
}

angular.module("app.dashboard").controller("dashboardGroupsTransmissionSanteSecureController", DashboardGroupsTransmissionSanteSecureController);