"use strict";
/**
 * The patient vaccination history service.
 *
 * @param {object} patientUtilitiesService - The patient utilities service.
 */

PatientVaccinationHistoryService.$inject = ["patientUtilitiesService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function PatientVaccinationHistoryService(patientUtilitiesService) {
  return {
    /**
     * Create the vaccination schedule from the vaccinations list.
     *
     * @param {Array} vaccinations - The vaccinations list.
     *
     * @returns {Array} The vaccination schedule configuration.
     */
    createVaccinationSchedule: function createVaccinationSchedule(vaccinations) {
      var splitVaccinations = this.splitVaccinations(vaccinations);
      var vaccinationGroups = this.groupVaccinationsByRange(splitVaccinations);
      vaccinationGroups = this.groupRangeByInjection(vaccinationGroups);
      return vaccinationGroups.sort(function (previous, current) {
        return previous.from - current.from;
      });
    },

    /**
     * Split vaccinations to have a vaccination object for each vaccination schedule.
     *
     * @param {Array} vaccinations - The vaccinations list.
     *
     * @returns {Array} The split vaccinations array.
     */
    splitVaccinations: function splitVaccinations(vaccinations) {
      var vacc = [];
      vaccinations.forEach(function (vaccinationsInformation) {
        vaccinationsInformation.schedules.forEach(function (schedule) {
          vacc.push(_objectSpread(_objectSpread({}, vaccinationsInformation), {}, {
            from: schedule.numeric_scheduled_from,
            to: schedule.numeric_scheduled_to
          }));
        });
      });
      return vacc;
    },

    /**
     * Group vaccination by date range.
     *
     * @param {Array} vaccinations - The vaccinations list.
     *
     * @returns {Array} The vaccinations range.
     */
    groupVaccinationsByRange: function groupVaccinationsByRange(vaccinations) {
      var groupedVaccinations = [];
      vaccinations.forEach(function (vaccinationInformation) {
        var currentRangeIndex = groupedVaccinations.findIndex(function (vaccinationRange) {
          return vaccinationRange.from === vaccinationInformation.from && vaccinationRange.to === vaccinationInformation.to;
        });

        if (currentRangeIndex === -1) {
          var group = {
            from: vaccinationInformation.from,
            to: vaccinationInformation.to,
            vaccinations: [vaccinationInformation]
          };
          groupedVaccinations.push(group);
        } else {
          groupedVaccinations[currentRangeIndex].vaccinations.push(vaccinationInformation);
        }
      });
      return groupedVaccinations;
    },

    /**
     * Group the vaccination ranges by injection.
     *
     * @param {Array} vaccinationRanges - The vaccinations ranges.
     *
     * @returns {object}
     */
    groupRangeByInjection: function groupRangeByInjection(vaccinationRanges) {
      var splitLine = [];
      var self = this;
      vaccinationRanges.forEach(function (range) {
        var vaccinationInjections = [];
        range.vaccinations.forEach(function (vaccination) {
          var productArray = vaccinationInjections.filter(function (value) {
            return self.sameMembers(value.products, vaccination.products);
          });

          if (productArray.length) {
            productArray[0].vaccinations.push(vaccination);
          } else {
            vaccinationInjections.push({
              products: vaccination.products,
              vaccinations: [vaccination]
            });
          }
        });

        var newSection = _objectSpread(_objectSpread({}, range), {}, {
          injections: vaccinationInjections
        });

        delete newSection.vaccinations;
        splitLine.push(newSection);
      });
      return splitLine;
    },

    /**
     * Check whether an array of VaccinationProducts has the same products as the second.
     *
     * @param {Array} array1 - The array to compare.
     * @param {Array} array2 - The base array.
     *
     * @returns {boolean}
     */
    arrayContainsAll: function arrayContainsAll(array1, array2) {
      return array2.every(function (array2Item) {
        return array1.findIndex(function (array1Item) {
          return array1Item.product.id === array2Item.product.id;
        }) !== -1;
      });
    },

    /**
     * Check whether two array of VaccinationProducts has the same products.
     *
     * @param {Array} array1 - The first vaccination array products.
     * @param {Array} array2 - The second vaccination array products.
     *
     * @returns {boolean}
     */
    sameMembers: function sameMembers(array1, array2) {
      return this.arrayContainsAll(array1, array2) && this.arrayContainsAll(array2, array1);
    },

    /**
     * Retrieve the repeatable vaccinations from the vaccination list.
     *
     * @param {Array} vaccinations - The vaccinations array.
     *
     * @returns {Array} The list of repeatable vaccinations.
     **/
    getRepeatableVaccinations: function getRepeatableVaccinations(vaccinations) {
      return vaccinations.filter(function (vaccination) {
        return vaccination.schedules.find(function (schedule) {
          return schedule.repetition;
        });
      });
    },

    /**
     * Retrieve the last injection for a given vaccine.
     *
     * @param {object} vaccination - The involved vaccination.
     * @param {Array} patientVaccinations - The patient vaccinations list.
     *
     * @returns {null|object} The last patient injection corresponding to the given vaccination.
     */
    lastInjectionFromPatientVaccinations: function lastInjectionFromPatientVaccinations(vaccination, patientVaccinations) {
      var vaccinations = patientVaccinations.filter(function (patientVaccination) {
        return patientVaccination.slug === vaccination.slug;
      });

      if (!vaccinations.length) {
        return null;
      }

      return vaccinations[vaccinations.length - 1];
    },

    /**
     * Check whether a vaccination is done.
     *
     * @param {object} vaccination - The involved vaccination.
     * @param {Array} patientVaccinations - The patient vaccinations list.
     *
     * @returns {boolean|null}
     */
    isVaccinationDone: function isVaccinationDone(vaccination, patientVaccinations) {
      var currentInjection = patientVaccinations.find(function (patientVaccination) {
        return patientVaccination.slug === vaccination.slug && vaccination.from === patientVaccination.from;
      });

      if (!currentInjection) {
        currentInjection = this.lastInjectionFromPatientVaccinations(vaccination, patientVaccinations);
      }

      if (currentInjection.user_vaccination_schedule && currentInjection.user_vaccination_schedule.done) {
        return currentInjection.user_vaccination_schedule.done;
      }

      return currentInjection.done;
    },

    /**
     * Map the vaccination range repetition with the patient vaccination schedule.
     *
     * @param {object} repeatableVaccinationRange - The repeatable vaccination range.
     * @param {Array} patientVaccinations - The patient vaccinations.
     *
     * @returns {object}
     */
    mapVaccinationRepetitionWithPatient: function mapVaccinationRepetitionWithPatient(repeatableVaccinationRange, patientVaccinations) {
      var concernedVaccination = repeatableVaccinationRange.vaccinations.map(function (vaccination) {
        return vaccination.slug;
      });
      var patientConcernedVaccination = patientVaccinations.filter(function (schedule) {
        return concernedVaccination.includes(schedule.slug);
      });
      return _objectSpread(_objectSpread({}, repeatableVaccinationRange), {}, {
        done: patientConcernedVaccination[patientConcernedVaccination.length - 1].done
      });
    },

    /**
     * Convert a vaccination range window to human readable string.
     *
     * @param {object} vaccinationRange - The involved vaccination range.
     *
     * @returns {string} The human readable age range.
     */
    formatVaccinationAgeRange: function formatVaccinationAgeRange(vaccinationRange) {
      if (vaccinationRange.from <= 19 && vaccinationRange.to <= 19) {
        if (vaccinationRange.from === vaccinationRange.to) {
          return "".concat(vaccinationRange.from, " mois");
        }

        return "".concat(vaccinationRange.from, "-").concat(vaccinationRange.to, " mois");
      }

      if (vaccinationRange.to > vaccinationRange.from - 12 && vaccinationRange.to < vaccinationRange.from + 12) {
        return Math.floor(vaccinationRange.from / 12) + " ans";
      }

      return "".concat(Math.floor(vaccinationRange.from / 12), "-").concat(Math.floor(vaccinationRange.to / 12), " ans");
    },

    /**
     * Get a human readable string for a vaccination repeatable value.
     *
     * @param {number} repeatValue - The value representing how often a vaccination must do.
     *
     * @returns {string}
     */
    formatVaccinationRepetitionLabel: function formatVaccinationRepetitionLabel(repeatValue) {
      return "Tout les ".concat(repeatValue !== 1 ? repeatValue : "", " ans");
    },

    /**
     * Tighten the vaccination range values for display.
     *
     * @param {Array} vaccinations - List of vaccinations.
     *
     * @returns {Array}
     */
    tightVaccinationRange: function tightVaccinationRange(vaccinations) {
      return vaccinations.map(function (vaccination) {
        if (vaccination.to <= 19) {
          vaccination.to -= 1;
        } else {
          vaccination.to -= 12;
        }

        return vaccination;
      });
    },

    /**
     * Compute how often in year the vaccinationSchedule has to be done.
     *
     * @param {object} vaccinationSchedule - The vaccinationSchedule.
     *
     * @returns {number|null} The repetition value in year.
     */
    vaccinationScheduleRepetitionValue: function vaccinationScheduleRepetitionValue(vaccinationSchedule) {
      if (!vaccinationSchedule.numeric_repetition) {
        return null;
      }

      if (vaccinationSchedule.numeric_repetition === vaccinationSchedule.numeric_scheduled_to) {
        return 1;
      }

      var repetitionValueInMonth = vaccinationSchedule.numeric_repetition - vaccinationSchedule.numeric_scheduled_to;
      return repetitionValueInMonth / 12 + 1;
    },

    /**
     * Check whether the consumer is involved by the repetition range.
     *
     * @param {object} vaccination - The repeatable vaccination.
     * @param {string} birthDate - The birth date.
     *
     * @returns {boolean}
     */
    isConsumerInvolvedByRepetitionRange: function isConsumerInvolvedByRepetitionRange(vaccination, birthDate) {
      var numericAge = patientUtilitiesService.numericAge(birthDate) / 12;
      var vaccinationSchedule = vaccination.schedules[vaccination.schedules.length - 1];
      var repetitionValue = this.vaccinationScheduleRepetitionValue(vaccinationSchedule);
      var vaccinationEnding = vaccinationSchedule.numeric_scheduled_to / 12;
      return numericAge >= vaccinationEnding + repetitionValue;
    }
  };
}

angular.module("app.patients.services").service("patientVaccinationHistoryService", PatientVaccinationHistoryService);