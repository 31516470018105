"use strict";
/**
 * User sellout vqlidqtion directive.
 *
 * @returns {object}
 */

UserSelloutValidationController.$inject = ["$rootScope", "$scope"];

function UserSelloutValidation() {
  return {
    restrict: "E",
    scope: {
      userSellout: "=",
      onValidated: "&"
    },
    templateUrl: "app/main/users-sellout/directives/validation/userSelloutValidation.html",
    transclude: true,
    controller: UserSelloutValidationController
  };
}
/**
 * User sellout validation controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 */


function UserSelloutValidationController($rootScope, $scope) {
  /**
   * Public methods.
   */
  $scope.acceptSellout = acceptSellout;
  $scope.refuseSellout = refuseSellout;
  /**
   * accept a tile
   *
   * @param {object} event - Event.
   * @param {object} sellout - Sellout.
   */

  function acceptSellout(event, sellout) {
    $rootScope.$broadcast("userSelloutValidation:eventTrigger:accept", event, sellout);
  }
  /**
   * refuse a tile
   *
   * @param {object} event - Event.
   * @param {object} sellout - Sellout.
   */


  function refuseSellout(event, sellout) {
    $rootScope.$broadcast("userSelloutValidation:eventTrigger:refuse", event, sellout);
  }
}

angular.module("app.users-sellout").directive("userSelloutValidation", UserSelloutValidation);