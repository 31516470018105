"use strict";
/**
 * Service to manage alert report.
 *
 * @param {object} apiClient - The API client.
 *
 * @returns {object} The alert report service.
 */

AlertReportService.$inject = ["apiClient"];

function AlertReportService(apiClient) {
  return {
    /**
     * Read an alert report.
     *
     * @param {number} laboratoryId - The ID of the laboratory.
     *
     * @returns {Promise}
     */
    read: function read(laboratoryId) {
      return apiClient.get("/laboratories/".concat(laboratoryId, "/disruption-alert-report?include=products,geographicalZones,wholesalers,warehouses")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update an alert report.
     *
     * @param {number} laboratoryId - The ID of the laboratory.
     * @param {object} data - The data container.
     *
     * @returns {Promise}
     */
    update: function update(laboratoryId, data) {
      return apiClient.patch("/laboratories/".concat(laboratoryId, "/disruption-alert-report?include=products,geographicalZones,wholesalers,warehouses"), data).then(function (response) {
        return response.data;
      });
    },

    /**
     * Send the alert report.
     *
     * @param {number} laboratoryId - The ID of the laboratory.
     *
     * @returns {Promise}
     */
    send: function send(laboratoryId) {
      return apiClient.get("/laboratories/".concat(laboratoryId, "/disruption-alert-report/send-report")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Download the alert report.
     *
     * @param {number} laboratoryId - The ID of the laboratory.
     *
     * @returns {Promise}
     */
    download: function download(laboratoryId) {
      return apiClient.get("/laboratories/".concat(laboratoryId, "/disruption-alert-report/download-report")).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.disruption").service("alertReportService", AlertReportService);