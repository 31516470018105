"use strict";
/**
 * Laboratory products configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

LaboratoryProductsConfig.$inject = ["$stateProvider"];

function LaboratoryProductsConfig($stateProvider) {
  $stateProvider.state("app.laboratory-products", {
    url: "/laboratoire/produits",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory/products-laboratory/products-laboratory.html",
        controller: "laboratoryProductsController"
      }
    }
  }).state("app.laboratory-products.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory/products-laboratory/edit/product-laboratory-edit.html",
        controller: "LaboratoryProductEditController"
      }
    }
  });
}

angular.module("app.laboratory-products", []).config(LaboratoryProductsConfig);