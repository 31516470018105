"use strict";
/**
 * Access service.
 *
 * @param {object} $q - $q.
 * @param {object} apiClient - API client.
 * @param {object} environmentService -
 * @param {object} teamService - Team service.
 *
 * @returns {object}
 */

accessService.$inject = ["$q", "apiClient", "environmentService", "teamService"];

function accessService($q, apiClient, environmentService, teamService) {
  var access = {
    user: $q.defer(),
    accessPoints: null,
    currentEnv: null,
    themes: {
      1: {
        icon: "icon-hospital",
        name: "default"
      },
      2: {
        icon: "icon-hospital",
        name: "teal"
      },
      3: {
        icon: "icon-people",
        name: "pink"
      },
      4: {
        icon: "icon-key",
        name: "default"
      }
    },
    adminRankId: [1, 2, 4, 5],
    others: [{
      accessPointType: 4,
      active: 0,
      entity: {
        name: "Admin"
      }
    }]
  };
  /**
   * Get user.
   */

  function getUser() {
    var payload = environmentService.getPayload();
    access.user = payload.user;
  }

  var pendingRequests;
  /**
   * Get pending requests.
   *
   * @returns {Promise}
   */

  function getPendingRequest() {
    if (!pendingRequests) {
      pendingRequests = environmentService.getEnvironment().then(function (env) {
        if (!isEntityAdmin(env.rankId)) {
          return [];
        }

        if ((_.isUndefined(access.pendingRequest) || _.isNull(access.pendingRequest)) && isEntityAdmin(env.rankId)) {
          return teamService.getPendingRequests().then(function (response) {
            return response.pendingRequests;
          });
        }

        return access.pendingRequest;
      });
    }

    return pendingRequests;
  }
  /**
   * Is healthcenter unplugged.
   *
   * @param {number} accessPointType - Access point type.
   * @param {number} rankId - Rank ID.
   * @returns {Promise}
   */


  function isHcUnplugged(accessPointType, rankId) {
    var isUnplugged = $q.defer();
    environmentService.getEnvironment().then(function (env) {
      if (env && accessPointType === 2 && (rankId === 1 || rankId === 2)) {
        isUnplugged.resolve(_.isNull(env.entity.lastLgoImport));
      } else {
        isUnplugged.resolve(null);
      }
    });
    return isUnplugged.promise;
  }
  /**
   * Is entity admin.
   *
   * @param {number} rankId - Rank ID.
   * @returns {boolean}
   */


  function isEntityAdmin(rankId) {
    var isEntityAdmin = false;

    _.each(access.adminRankId, function (rank) {
      if (rank === rankId) {
        isEntityAdmin = true;
      }
    });

    return isEntityAdmin;
  }
  /**
   * Get me.
   *
   * @returns {object}
   */


  function getMe() {
    if (_.isUndefined(access.user) || _.isNull(access.user)) {
      getUser();
    }

    return access.user;
  }

  return {
    getMe: getMe,
    isHcUnplugged: isHcUnplugged,
    isEntityAdmin: isEntityAdmin,
    getPendingRequest: getPendingRequest,

    /**
     * Get current.
     *
     * @returns {Promise}
     */
    getCurrent: function getCurrent() {
      return apiClient.get("/profile/accesses/current", {
        includes: ["entity", "packs", "rank", "user"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.access;
      });
    },

    /**
     * Get accesses.
     *
     * @returns {Promise}
     */
    getAccesses: function getAccesses() {
      return apiClient.get("/access/accesses", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.accesses;
      });
    },

    /**
     * Put me.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    putMe: function putMe(params) {
      return apiClient.put("/users/me", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.user;
      });
    },

    /**
     * Put address by user id.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    putAddressByUserId: function putAddressByUserId(params) {
      return apiClient.put("/useraddress/uid/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Post address by user id.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    postAddressByUserId: function postAddressByUserId(params) {
      return apiClient.post("/useraddress/uid/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.address;
      });
    },

    /**
     * Put password.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    putPassword: function putPassword(params) {
      return apiClient.put("/users/me/password", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Accept CGU.
     *
     * @returns {Promise}
     */
    acceptCgu: function acceptCgu() {
      return apiClient.post("/users/cgu", {}, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get Edenred account.
     *
     * @returns {Promise}
     */
    getEdenredAccounts: function getEdenredAccounts() {
      return apiClient.get("/user/me/point", {
        includes: ["entity"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.services").service("accessService", accessService);