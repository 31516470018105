"use strict";
/**
 * Dashboard pharmacies controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} bricksService - Bricks service.
 * @param {object} environmentService - Environment service.
 */

DashboardPharmaciesController.$inject = ["$scope", "$state", "bricksService", "environmentService"];

function DashboardPharmaciesController($scope, $state, bricksService, environmentService) {
  $scope.isHealthCenter = false;
  $scope.goToGuests = goToGuests;
  $scope.goToTeams = goToTeams;
  loader();
  /**
   * Loader.
   */

  function loader() {
    loaderCurrentEnv();
  }
  /**
   * Load current environment.
   */


  function loaderCurrentEnv() {
    environmentService.getEnvironment().then(function (environment) {
      $scope.currentEnv = environment;
      $scope.user = environment.user.name;
      $scope.relaunchSS = lastLgoImport(environment.entity.lastLgoImport);
      loaderCurrentBrick();
    });
  }
  /**
   * Load current brick.
   */


  function loaderCurrentBrick() {
    bricksService.getCurrentBrick().then(function (brick) {
      $scope.brick = brick;
    });
  }
  /**
   * Go to guests.
   */


  function goToGuests() {
    $state.go("app.guest");
  }
  /**
   * Go to teams.
   */


  function goToTeams() {
    $state.go("app.entityAdmin");
  }
  /**
   *  Last LGO import format.
   *
   * @param {Date} lastLgoImport - Last LGO date import.
   *
   * @returns {Date}
   */


  function lastLgoImport(lastLgoImport) {
    $scope.lastSynchro = moment().diff(moment(lastLgoImport), "days");
    return moment(lastLgoImport) <= moment().subtract(2, "days");
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesController", DashboardPharmaciesController);