"use strict";

salesAnalysisController.$inject = ["$state", "environmentService"];
angular.module("app.statistics.salesAnalysis").controller("salesAnalysisController", salesAnalysisController);
/** @ngInject */

/**
 * Sales analysis controller.
 *
 * @param {object} $state - The angular $state object.
 * @param {object} environmentService - The environment service.
 */

function salesAnalysisController($state, environmentService) {
  // eslint-disable-next-line no-invalid-this
  var vm = this;
  var currentState = $state.current.name;
  vm.isEmployee = false;
  environmentService.getEnvironment().then(function (env) {
    vm.currentEnv = env;

    if (vm.currentEnv.accessPointType === 2 && vm.currentEnv.rankId !== 1 && vm.currentEnv.rankId !== 2) {
      vm.isEmployee = true;
    } // TABS STATES


    switch (currentState) {
      case "app.statistics.salesAnalysis.sandBoxLineAtcl":
        vm.selectedIndex = 0;
        break;

      case "app.statistics.salesAnalysis.topLabo":
        vm.selectedIndex = 1;
        break;

      case "app.statistics.salesAnalysis.marketShare":
        vm.selectedIndex = 2;
        break;

      case "app.statistics.salesAnalysis.memberAnalysis":
        vm.selectedIndex = 3;
        break;

      case "app.statistics.salesAnalysis.groupingMdl":
        vm.selectedIndex = 4;
        break;

      case "app.statistics.salesAnalysis.marketSharesEvolution":
        vm.selectedIndex = 5;
        break;

      default:
        vm.selectedIndex = 0;
        break;
    }
  });
}