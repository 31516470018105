"use strict";
/**
 * Pack list controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} packListService - Pack list service.
 */

PackListController.$inject = ["$scope", "$state", "packListService"];

function PackListController($scope, $state, packListService) {
  $scope.goToDetail = goToDetail;
  /**
   * Loader
   */

  (function loader() {
    getPacks();
  })();
  /**
   * Get packs
   *
   * @returns {Promise} Promise object represents the packs.
   */


  function getPacks() {
    $scope.loading = true;
    return packListService.getSubscribable().then(function (response) {
      $scope.packs = response.packs;
      $scope.loading = false;
    });
  }
  /**
   * Go to detail.
   *
   * @param {string} slug - The slug of the pack.
   */


  function goToDetail(slug) {
    $state.go("app.packDetail", {
      packSlug: slug
    });
  }
}

angular.module("app.pack").controller("packListController", PackListController);