"use strict";
/**
 * Legal configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

LegalConfig.$inject = ["$stateProvider"];

function LegalConfig($stateProvider) {
  $stateProvider.state("app.legalNotice", {
    url: "/mentions-legales",
    views: {
      "content@app": {
        templateUrl: "app/main/legal/legalNotice/legalNotice.html",
        controller: "LegalNoticeOutsideConnectionController as vm"
      }
    }
  }).state("app.legalNoticeOutsideConnection", {
    url: "/mention-legale",
    views: {
      "main@": {
        templateUrl: "app/main/legal/legalNotice/legalNotice.html",
        controller: "LegalNoticeOutsideConnectionController as vm"
      }
    }
  }).state("app.conditionOfUse", {
    url: "/conditions-generales",
    views: {
      "content@app": {
        templateUrl: "app/main/legal/conditionOfUse/conditionOfUse.html",
        controller: "ConditionOfUseController as vm"
      }
    }
  });
}

angular.module("app.legal", []).config(LegalConfig);