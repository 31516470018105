"use strict";
/**
 * Detail wholesaler purchases analysis controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} wholesalerPurchasesAnalysisService -
 *      Wholesaler purchases analysis service.
 * @param {object} $stateParams - $stateParams.
 */

DetailWholesalerPurchasesAnalysisController.$inject = ["$scope", "wholesalerPurchasesAnalysisService", "$stateParams"];

function DetailWholesalerPurchasesAnalysisController($scope, wholesalerPurchasesAnalysisService, $stateParams) {
  $scope.getTotalWholesalerMargin = getTotalWholesalerMargin;
  $scope.getDiscount = getDiscount;
  $scope.getWholesalerMargin = getWholesalerMargin;
  $scope.getTotalWholesalerDiscount = getTotalWholesalerDiscount;
  $scope.saveSimulation = saveSimulation;
  $scope.calculMarginAndDiscount = calculMarginAndDiscount;
  $scope.id = $stateParams.id;
  $scope.loaderBuckets = {
    loading: true,
    loader: "Récupération de la simulation",
    empty: "Vous n'avez passé aucune commande chez ce grossiste"
  };
  $scope.wholesalerPurchasesAnalysisDetail = {
    title: {
      icon: "icon-chart-bar",
      text: "",
      back: {
        state: "app.statistics.wholesalerPurchasesAnalysis",
        tooltip: "Retour à l'analyse des achats grossiste"
      }
    }
  };
  $scope.currencyTitle = {
    icon: "icon-currency-eur",
    text: "Quelques chiffres",
    type: "2"
  };
  $scope.analysisTitle = {
    icon: "icon-reload",
    text: "Simulation",
    type: "2"
  };
  $scope.query = {
    limit: 6,
    page: 1,
    perPage: [6]
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.showSave = true;
  $scope.discountTypes = ["PFHT", "PGHT", "A la boîte"];
  $scope.loaderBucket = {
    loading: true,
    loader: "Récupération du panier grossiste...",
    empty: "Vous n'avez aucune donnée dans votre panier grossiste."
  };
  $scope.discountTypesWithoutWholeSaler = ["PFHT", "A la boîte"];
  $scope.reimbursedColdDiscountRate = {};
  $scope.reimbursedDiscountRate = {};
  $scope.not_reimbursedDiscountRate = {};
  $scope.discountType = {};
  $scope.discountType.reimbursment = {};
  $scope.discountType.reimbursment_cold = {};
  $scope.discountType.not_reimburshed = {};
  loader();
  /**
   * Loader.
   */

  function loader() {
    getWholesalerPurchase();
  }
  /**
   * Get wholesaler purchase.
   *
   * @returns {Promise}
   */


  function getWholesalerPurchase() {
    $scope.loaderBucket.loading = true;
    var startDate = moment().month(0).date(1).format("DD-MMM-YYYY");
    var endDate = moment().format("DD-MMM-YYYY");
    var query = {
      startDatetime: $stateParams.startDate || startDate,
      endDatetime: $stateParams.endDate || endDate
    };
    return wholesalerPurchasesAnalysisService.getStats(query).then(function (response) {
      $scope.loaderBucket.loading = false;
      $scope.wholesalerPurchaseAnalysis = response.aggregations.wholesaler.buckets.find(function (wholesaler) {
        return wholesaler.key_id === $stateParams.id;
      });
      $scope.wholesalerPurchasesAnalysisDetail.title.text = $scope.wholesalerPurchaseAnalysis.key;
      return $scope.wholesalerPurchaseAnalysis;
    }).then(function (wholesalerPurchaseAnalysis) {
      $scope.totalWholesalerMargin = wholesalerPurchaseAnalysis.reimbursment.marge.value + wholesalerPurchaseAnalysis.reimbursment_cold.marge.value;
      return wholesalerPurchaseAnalysis;
    }).then(function () {
      readSimulation();
    });
  }
  /**
   * Read simulation.
   *
   * @returns {Promise}
   */


  function readSimulation() {
    var query = {
      id: parseInt($scope.wholesalerPurchaseAnalysis.key_id)
    };
    return wholesalerPurchasesAnalysisService.readSimulation(query) // eslint-disable-next-line consistent-return
    .then(function (simulation) {
      if (simulation) {
        addSimulationToAnalysis(simulation);
        return simulation;
      }
    }).then(function (simulation) {
      $scope.wholesalerPurchaseAnalysis.reimbursment.margin_type.buckets.forEach(calculMarginAndDiscount);
      $scope.wholesalerPurchaseAnalysis.reimbursment_cold.margin_type.buckets.forEach(calculMarginAndDiscount);
      $scope.wholesalerPurchaseAnalysis.not_reimburshed.vat.buckets.forEach(calculMarginAndDiscount);
      calculMarginAndDiscount($scope.wholesalerPurchaseAnalysis.drugs);
      calculMarginAndDiscount($scope.wholesalerPurchaseAnalysis.lppr);
      return simulation;
    });
  }
  /**
   * Get total wholesaler margin.
   */


  function getTotalWholesalerMargin() {
    $scope.totalWholesalerMargin = 0;
    $scope.wholesalerPurchaseAnalysis.reimbursment.margin_type.buckets.forEach(function (slice) {
      $scope.totalWholesalerMargin += slice.marge.value;
    });
    $scope.wholesalerPurchaseAnalysis.reimbursment_cold.margin_type.buckets.forEach(function (slice) {
      $scope.totalWholesalerMargin += slice.marge.value;
    });
    $scope.wholesalerPurchaseAnalysis.drugs.margin_type.buckets.forEach(function (slice) {
      $scope.totalWholesalerMargin += slice.marge.value;
    });
  }
  /**
   * Get wholesaler margin.
   *
   * @param {object} slice - Slice.
   * @param {number} discountType - Discount type.
   * @param {string} discount - Discount.
   *
   * @returns {number}
   */


  function getWholesalerMargin(slice, discountType, discount) {
    if (!discountType) {
      slice.wholesalerMargin = slice.marge.value;
      return slice.marge.value;
    }

    var discountFloated = 0;

    if (discount) {
      discountFloated = parseFloat(discount);
    }

    switch (discountType) {
      case "PFHT":
        slice.wholesalerMargin = slice.marge.value - slice.caTotal.value * discountFloated / 100;
        return slice.wholesalerMargin;

      case "PGHT":
        slice.wholesalerMargin = slice.marge.value - (slice.caTotal.value + slice.marge.value) * discountFloated / 100;
        return slice.wholesalerMargin;

      default:
        slice.wholesalerMargin = slice.marge.value - slice.quantity.value * discountFloated;
        return slice.wholesalerMargin;
    }
  }
  /**
   * Calculate margin and discount.
   *
   * @param {object} slice - Slice.
   */


  function calculMarginAndDiscount(slice) {
    if ($scope.wholesalerPurchaseAnalysis) {
      getDiscount(slice, slice.discountType, slice.discount);
      getTotalWholesalerDiscount();

      if (slice.marge) {
        getWholesalerMargin(slice, slice.discountType, slice.discount);
        getTotalWholesalerMargin();
      }
    }
  }
  /**
   * Get discount.
   *
   * @param {object} slice - Slice.
   * @param {number} discountType - Discount type.
   * @param {string} discount - Discount.
   *
   * @returns {number}
   */


  function getDiscount(slice, discountType, discount) {
    if (!discountType) {
      slice.discountAmount = 0;
      slice.discount = 0;
      return 0;
    }

    var discountFloated = 0;

    if (discount) {
      discountFloated = parseFloat(discount);
    } else {
      slice.discountAmount = discountFloated;
      slice.discount = 0;
      return discountFloated;
    }

    slice.discountType = discountType;
    slice.discount = discountFloated;

    switch (discountType) {
      case "PFHT":
        slice.discountAmount = slice.caTotal.value * discountFloated / 100;
        return slice.discountAmount;

      case "PGHT":
        slice.discountAmount = (slice.caTotal.value + slice.marge.value) * discountFloated / 100;
        return slice.discountAmount;

      default:
        slice.discountAmount = slice.quantity.value * discountFloated;
        return slice.discountAmount;
    }
  }
  /**
   * Get total wholesaler discount.
   *
   * @returns {number}
   */


  function getTotalWholesalerDiscount() {
    var totalDiscount = 0;
    $scope.wholesalerPurchaseAnalysis.reimbursment.margin_type.buckets.forEach(function (slice) {
      totalDiscount += slice.discountAmount ? slice.discountAmount : 0;
    });
    $scope.wholesalerPurchaseAnalysis.reimbursment_cold.margin_type.buckets.forEach(function (slice) {
      totalDiscount += slice.discountAmount ? slice.discountAmount : 0;
    });
    $scope.wholesalerPurchaseAnalysis.not_reimburshed.vat.buckets.forEach(function (slice) {
      totalDiscount += slice.discountAmount ? slice.discountAmount : 0;
    });
    $scope.wholesalerPurchaseAnalysis.drugs.margin_type.buckets.forEach(function (slice) {
      totalDiscount += slice.discountAmount ? slice.discountAmount : 0;
    });
    $scope.wholesalerPurchaseAnalysis.lppr.laboratory.buckets.forEach(function (slice) {
      totalDiscount += slice.discountAmount ? slice.discountAmount : 0;
    });
    $scope.totalWholesalerDiscount = totalDiscount;
    return totalDiscount;
  }
  /**
   * Save simulation.
   *
   * @returns {Promise}
   */


  function saveSimulation() {
    $scope.showSave = false;
    var query = {
      id: parseInt($scope.wholesalerPurchaseAnalysis.key_id),
      simulation: $scope.wholesalerPurchaseAnalysis
    };
    return wholesalerPurchasesAnalysisService.saveSimulation(query).then(function (response) {
      $scope.showSave = true;
      $scope.wholesalerPurchaseAnalysis = response;
      return response;
    });
  }
  /**
   * Add Simulation to new data
   *
   * @param {object} simulation - Simulation.
   */


  function addSimulationToAnalysis(simulation) {
    $scope.wholesalerPurchaseAnalysis.reimbursment.margin_type.buckets.forEach(function (slice) {
      var discount = _.find(simulation.reimbursment.margin_type.buckets, function (margin_type) {
        return margin_type.key_id === slice.key_id;
      });

      if (discount) {
        slice.discount = discount.discount;
        slice.discountAmount = discount.discountAmount;
        slice.discountType = discount.discountType;
      }
    });
    $scope.wholesalerPurchaseAnalysis.reimbursment_cold.margin_type.buckets.forEach(function (slice) {
      var discount = _.find(simulation.reimbursment_cold.margin_type.buckets, function (margin_type) {
        return margin_type.key_id === slice.key_id;
      });

      if (discount) {
        slice.discount = discount.discount;
        slice.discountAmount = discount.discountAmount;
        slice.discountType = discount.discountType;
      }
    });
    $scope.wholesalerPurchaseAnalysis.not_reimburshed.vat.buckets.forEach(function (slice) {
      var discount = _.find(simulation.not_reimburshed.vat.buckets, function (margin_type) {
        return margin_type.key_id === slice.key_id;
      });

      if (discount) {
        slice.discount = discount.discount;
        slice.discountAmount = discount.discountAmount;
        slice.discountType = discount.discountType;
      }
    });
    $scope.wholesalerPurchaseAnalysis.drugs.discount = simulation.drugs.discount;
    $scope.wholesalerPurchaseAnalysis.drugs.discountAmount = simulation.drugs.discountAmount;
    $scope.wholesalerPurchaseAnalysis.drugs.discountType = simulation.drugs.discountType;
    $scope.wholesalerPurchaseAnalysis.lppr.discount = simulation.lppr.discount;
    $scope.wholesalerPurchaseAnalysis.lppr.discountAmount = simulation.lppr.discountAmount;
    $scope.wholesalerPurchaseAnalysis.lppr.discountType = simulation.lppr.discountType;
  }
}

angular.module("app.statistics.salesAnalysis").controller("detailWholesalerPurchasesAnalysisController", DetailWholesalerPurchasesAnalysisController);