"use strict";
/**
 * Controller for loyalty card list.
 *
 * @param {object} $scope - Scope
 * @param {object} $state - State
 * @param {object} $mdToast - MD toast
 * @param {object} $mdDialog - MD dialog
 * @param {object} environmentService - Environment service
 * @param {object} loyaltyCardService - Loyalty card service
 */

LoyaltyCardListController.$inject = ["$scope", "$state", "$mdToast", "$mdDialog", "environmentService", "loyaltyCardService"];

function LoyaltyCardListController($scope, $state, $mdToast, $mdDialog, environmentService, loyaltyCardService) {
  $scope.addLoyaltyCard = addLoyaltyCard;
  $scope.readLoyaltyCard = readLoyaltyCard;
  $scope.editLoyaltyCard = editLoyaltyCard;
  $scope.deleteLoyaltyCard = deleteLoyaltyCard;
  $scope.addLoyaltyCardMember = addLoyaltyCardMember;
  $scope.showLoyaltyCardMembers = showLoyaltyCardMembers;
  $scope.loading = true;
  $scope.query = {
    order: "-startDate",
    limit: 15,
    page: 1
  };
  loader();
  /**
   * Loader of the controller.
   *
   * @returns {Promise} The promise
   */

  function loader() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      getLoyaltyCards();
      return response;
    });
  }
  /**
   * Get the loyalty cards.
   *
   * @returns {Promise} The promise
   */


  function getLoyaltyCards() {
    return loyaltyCardService.get().then(function (response) {
      $scope.loading = false;
      $scope.loyaltyCards = response;
      return response;
    });
  }
  /**
   * Add a loyalty card.
   */


  function addLoyaltyCard() {
    $state.go("app.loyaltyCard.new");
  }
  /**
   * Add a loyalty card member.
   *
   * @param {number} loyaltyCardId - The loyalty card ID
   */


  function addLoyaltyCardMember(loyaltyCardId) {
    $state.go("app.loyaltyCard.members.newMember", {
      loyaltyCardId: loyaltyCardId
    });
  }
  /**
   * Read a Loyalty Card.
   *
   * @param {object} event - The event
   * @param {object} loyaltyCard - The loyalty card
   */


  function readLoyaltyCard(event, loyaltyCard) {
    event.stopPropagation();
    $state.go("app.loyaltyCard.detail", {
      id: loyaltyCard.id
    });
  }
  /**
   * Show the loyalty card members.
   *
   * @param {object} event - The event
   * @param {number} loyaltyCardId - The loyalty card ID
   */


  function showLoyaltyCardMembers(event, loyaltyCardId) {
    $state.go("app.loyaltyCard.members", {
      loyaltyCardId: loyaltyCardId
    });
  }
  /**
   * Edit a Loyalty Card.
   *
   * @param {object} event - The event
   * @param {object} loyaltyCard - The loyalty card
   */


  function editLoyaltyCard(event, loyaltyCard) {
    event.stopPropagation();
    $state.go("app.loyaltyCard.edit", {
      id: loyaltyCard.id
    });
  }
  /**
   * Delete Loyalty Card.
   *
   * @param {object} event - The event
   * @param {object} loyaltyCard - The loyalty card
   */


  function deleteLoyaltyCard(event, loyaltyCard) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain de vouloir supprimer la carte de fidélité « " + loyaltyCard.name + " » ?").ariaLabel("suppression de carte de fidélité").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      return loyaltyCardService["delete"](loyaltyCard);
    }).then(function () {
      $mdToast.show($mdToast.simple().textContent("La carte de fidélité est supprimée.").position("top right"));
    }).then(getLoyaltyCards);
  }
}

angular.module("app.loyaltyCard").controller("loyaltyCardListController", LoyaltyCardListController);