"use strict";
"use-strict";
/**
 * Instant discount voucher configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

InstantDiscountVoucherConfig.$inject = ["$stateProvider"];

function InstantDiscountVoucherConfig($stateProvider) {
  $stateProvider.state("app.instantDiscountVoucher", {
    url: "/bon-de-reduction-immediat",
    views: {
      "content@app": {
        templateUrl: "app/main/instant-discount-voucher/views/list/list.html",
        controller: "InstantDiscountVoucherListController"
      }
    }
  }).state("app.instantDiscountVoucher.new", {
    url: "/creation",
    views: {
      "content@app": {
        templateUrl: "app/main/instant-discount-voucher/views/edit/edit.html",
        controller: "InstantDiscountVoucherEditController"
      }
    }
  }).state("app.instantDiscountVoucher.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/instant-discount-voucher/views/edit/edit.html",
        controller: "InstantDiscountVoucherEditController"
      }
    }
  }).state("app.instantDiscountVoucher.detail", {
    url: "/detail/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/instant-discount-voucher/views/detail/detail.html",
        controller: "InstantDiscountVoucherDetailController"
      }
    }
  });
}

angular.module("app.instantDiscountVoucher", []).config(InstantDiscountVoucherConfig);