"use strict";
/**
 * Purchase audit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} wholesalerPurchasesAnalysisService - Wholesaler purchases analysis service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} healthcenterAuditHcDetailsService - Healthcenter audit details service.
 * @param {object} environmentService - Environment service.
 */

PurchaseAuditController.$inject = ["$scope", "$stateParams", "wholesalerPurchasesAnalysisService", "StatisticsQueryService", "healthcenterAuditHcDetailsService", "environmentService"];

function PurchaseAuditController($scope, $stateParams, wholesalerPurchasesAnalysisService, StatisticsQueryService, healthcenterAuditHcDetailsService, environmentService) {
  var endDate = moment().format("DD-MMM-YYYY");
  var startDate = moment().month(0).date(1).format("DD-MMM-YYYY");
  $scope.startDatetime = moment().month(0).date(1).format("YYYY-MM-DD");
  $scope.endDatetime = moment().format("YYYY-MM-DD");
  $scope.searchConfig = {
    event: "pharmaMLSearchChange",
    search: []
  };
  $scope.dateLabel = startDate + " Au " + endDate;
  $scope.rangeSelected = rangeSelected;
  $scope.getPharmaML = getPharmaML;
  $scope.usrquery = StatisticsQueryService.query;
  $scope.loaderOptions = {
    loading: true,
    loader: "Récupération de vos données...",
    empty: "Aucune donnée n'est disponible"
  };
  $scope.query = {
    order: "-datetime",
    limit: 10,
    page: 1
  };
  loader();
  $scope.limitOptions = [5, 10, 15];
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  /**
   * Get pharma ml.
   *
   * @param {object} queryBuilt - Query.
   */

  function getPharmaML(queryBuilt) {
    $scope.loaderOptions.loading = true;
    $scope.pharmaMLs = [];
    var request = $scope.query;

    if (queryBuilt) {
      request.must = queryBuilt.must;
      request.should = queryBuilt.should;
    }

    request.startDatetime = $scope.startDatetime;
    request.endDatetime = $scope.endDatetime;
    wholesalerPurchasesAnalysisService.getPharmaML(request).then(function (pharmaML) {
      $scope.loaderOptions.loading = false;
      $scope.pharmaMLs = pharmaML.pharmaMLs;
      $scope.total = pharmaML.total;
    });
  }
  /**
   * Loader.
   */


  function loader() {
    if ($stateParams.id) {
      environmentService.getEnvironment().then(function (env) {
        $scope.currentEnv = env;

        if ($scope.currentEnv.accessPointType === 1) {
          var laboratory = {
            id: $scope.currentEnv.entityId,
            name: $scope.currentEnv.entity.name,
            type: "laboratory"
          };

          var laboratoryMust = _.find($scope.usrquery.must.laboratory.selected, function (labo) {
            return labo.id === laboratory.id;
          });

          if (!laboratoryMust) {
            laboratoryMust = _.find($scope.usrquery.should.laboratory.selected, function (labo) {
              return labo.id === laboratory.id;
            });
          }

          if (!laboratoryMust) {
            $scope.usrquery.must.laboratory.selected.push(laboratory);
          }

          healthcenterAuditHcDetailsService.hCDetail($stateParams.id).then(function (pharma) {
            var pharmaInfos = {
              id: pharma.healthcenter.id,
              name: pharma.healthcenter.name,
              type: "pharmas",
              cip: pharma.healthcenter.cip
            };
            $scope.usrquery.must.pharmas.selected = [pharmaInfos];
            StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(getPharmaML);
          });
        }
      });
    }
  }
  /**
   * On date range changed
   *
   * @param {object} range - Range.
   */


  function rangeSelected(range) {
    $scope.dateLabel = range.startDate + " au " + range.endDate;
    $scope.startDatetime = moment(range.startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    $scope.endDatetime = moment(range.endDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    getPharmaML();
  }

  $scope.$on("pharmaMLSearchChange", function (event, query) {
    StatisticsQueryService.buildquery(query, query.date).then(function (queryBuilt) {
      getPharmaML(queryBuilt);
    });
  });
}

angular.module("app.healthcenterStockAudit").controller("purchaseAuditController", PurchaseAuditController);