"use strict";
/**
 * Controller for presentation of the module.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {string} playstoreApodisPharma - The url of the app on the store.
 * @param {string} appstoreApodisPharma - The url of the app on the store.
 */

DisruptionPresentationController.$inject = ["$scope", "playstoreApodisPharma", "appstoreApodisPharma"];

function DisruptionPresentationController($scope, playstoreApodisPharma, appstoreApodisPharma) {
  $scope.playstoreApodisPharma = playstoreApodisPharma;
  $scope.appstoreApodisPharma = appstoreApodisPharma;
}

angular.module("app.dashboard").controller("disruptionPresentationController", DisruptionPresentationController);