"use strict";
/**
 * Dashboard grou psales today controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} apodisLiveService - Apodis live service.
 * @param {object} $rootScope - $rootScope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} analysisService - Analysis service.
 */

dashboardGroupSalesTodayController.$inject = ["$scope", "environmentService", "apodisLiveService", "$rootScope", "StatisticsQueryService", "analysisService"];

function dashboardGroupSalesTodayController($scope, environmentService, apodisLiveService, $rootScope, StatisticsQueryService, analysisService) {
  $scope.salesToday = 0;
  $scope.unitToday = 0;
  $scope.loading = true;
  /**
   * Loader.
   */

  function loader() {
    launchStatisticsQueries();
  }
  /**
   * Launch statistics queries.
   */


  function launchStatisticsQueries() {
    $scope.usrquery = angular.copy(StatisticsQueryService.pristineQuery);
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      environmentService.getEnvironment().then(function (environment) {
        $scope.env = environment;
        $scope.groupinId = environment.entity.id;

        if (environment && environment.entity) {
          processSalesToday().then(function () {
            launchSocket(environment);
            $scope.initiated = true;
          });
        }
      });
    });
  }
  /**
   * Process sales today.
   *
   * @returns {Promise}
   */


  function processSalesToday() {
    var query = JSON.parse(JSON.stringify($scope.query));
    query.startDatetime = moment().format("YYYY-MM-DD");
    query.endDatetime = moment().format("YYYY-MM-DD");
    query.must.groups = [$scope.env.entity.id];
    return analysisService.getSales(query).then(function (response) {
      if (response.aggregations) {
        $scope.salesToday = response.aggregations.amount_excl_tax.value;
        $scope.unitToday = response.aggregations.quantity.value;
      }

      $scope.loading = false;
      return response;
    });
  }

  var salesServiceName;
  /**
   * On sales data.
   *
   * @param {object} dataSales - Data sales.
   */

  function onSalesData(dataSales) {
    $scope.productSalesLiveId = dataSales.product.id;
    $scope.salesToday += dataSales.item.price.sell.total_ht;
    $scope.unitToday += dataSales.item.quantity;
    $scope.$apply();
  }
  /**
   * Launch socket.
   *
   * @param {object} env - Environment.
   */


  function launchSocket(env) {
    salesServiceName = "sales_grouping_" + env.entity.id;

    if (typeof apodisLiveService.socket._callbacks["$" + salesServiceName] === "undefined") {
      // Handler not present, install now
      apodisLiveService.register({
        service: salesServiceName
      });
    }

    apodisLiveService.socket.on(salesServiceName, onSalesData);
  }

  $rootScope.$on("$stateChangeStart", function () {
    apodisLiveService.unregister(salesServiceName, onSalesData);
  });
  loader();
}

angular.module("app.dashboard").controller("dashboardGroupSalesTodayController", dashboardGroupSalesTodayController);