"use strict";
/**
 * Export directive.
 *
 * @param {object} $mdDialog - $mdDialog.
 *
 * @returns {object}
 */

exportDirective.$inject = ["$mdDialog"];

function exportDirective($mdDialog) {
  return {
    restrict: "A",
    scope: {
      callback: "&exportCallback"
    },
    link: function link(scope, element) {
      var exportConfig = scope.$parent.exportConfig ? scope.$parent.exportConfig : null;
      element.on("click", function (event) {
        openDialog(event);
      });
      /**
       * Open dialog.
       *
       * @param {object} event - Event.
       */

      function openDialog(event) {
        $mdDialog.show({
          controller: ["$mdDialog", "apiurl", "sessionStorageService", "exportService", function controller($mdDialog, apiurl, sessionStorageService, exportService) {
            var token = sessionStorageService.getToken();
            var vm = this;
            vm.apiExportUrl = apiurl + "/export/getFile?token=" + token;
            vm.token = token;
            vm.loading = false;
            vm.cancel = cancel; // Default export configuration if not set

            vm.entityName = exportConfig.entityName !== "undefined" ? exportConfig.entityName : "Product";
            vm.fileType = exportConfig.fileType !== "xlsx" ? exportConfig.fileType : "xslx";
            vm.fileName = exportConfig.fileName !== "undefined" ? exportConfig.fileName : "export";
            vm.isAllowedColumnChoice = exportConfig.isAllowedColumnChoice === true;
            vm.defaultFilter = exportConfig.defaultFilter ? exportConfig.defaultFilter : {}; // Get available columns regarding entityName

            if (exportConfig.availableColumns) {
              vm.availableColumns = exportConfig.availableColumns;
            } else {
              vm.getColumns = getColumns(vm.entityName);
            }

            vm.exportToFile = exportToFile;
            /**
             * Interrogate API to get all available columns and
             * If isAllowedColumnChoice is true, display all
             * available columns, else hide them all.
             *
             * @param {string} entityName - The entity name
             */

            function getColumns(entityName) {
              vm.loading = true;
              exportService.getAvailableColumns({
                entityName: entityName
              }).then(function (response) {
                vm.availableColumns = response.availableColumns;
                vm.loading = false;
              });
            }
            /**
             * Export to file and close dialog box
             */


            function exportToFile() {
              document.getElementById("exportDialogForm").submit();
              vm.cancel();
            }
            /**
             * Hide dialog box
             */


            function cancel() {
              $mdDialog.hide();
            }
          }],
          controllerAs: "vm",
          templateUrl: "app/main/directives/export/export.html",
          parent: angular.element(document.body),
          targetEvent: event,
          clickOutsideToClose: true
        });
      }
    }
  };
}

angular.module("app.directives").config(["$sceDelegateProvider", function ($sceDelegateProvider) {
  $sceDelegateProvider.resourceUrlWhitelist(["self", new RegExp("^http[s]?://[^/]+/export/getFile(.+)")]);
}]).directive("export", exportDirective);