"use strict";
/**
 * Grouping audit detail controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $window - $window.
 * @param {object} groupmentAuditDetailService - Grouping audit detail service.
 */

GroupmentAuditDetailController.$inject = ["$scope", "$state", "$stateParams", "$window", "groupmentAuditDetailService"];

function GroupmentAuditDetailController($scope, $state, $stateParams, $window, groupmentAuditDetailService) {
  $scope.titleConfig = {
    title: {
      icon: "icon-map-marker-multiple",
      text: "...",
      back: {
        state: "app.groupmentAudit",
        tooltip: "Retour à l'audit groupement CRM"
      }
    }
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    groupmentAuditDetailService.one($stateParams).then(function (groupment) {
      $scope.group = groupment[0].grouping;
      $scope.titleConfig.title.text = $scope.group.name;
      setActiveState();
    });
  }
  /**
   * Set the current active state
   */


  function setActiveState() {
    var currentState = $state.current.name;

    switch (currentState) {
      case "app.groupmentAudit.detail.general":
        $scope.selected = 0;
        break;

      case "app.groupmentAudit.detail.salesEvolution":
        $scope.selected = 1;
        break;

      case "app.groupmentAudit.detail.marketShare":
        $scope.selected = 2;
        break;

      case "app.groupmentAudit.detail.mdl":
        $scope.selected = 3;
        break;

      case "app.groupmentAudit.detail.goumy":
        $scope.selected = 4;
        break;

      case "app.groupmentAudit.detail.realStock":
        $scope.selected = 5;
        break;

      case "app.groupmentAudit.detail.reassort":
        $scope.selected = 6;
        break;

      case "app.groupmentAudit.detail.sellout":
        $scope.selected = 7;
        break;

      case "app.groupmentAudit.detail.prestation":
        $scope.selected = 8;
        break;

      case "app.groupmentAudit.detail.sellin":
        $scope.selected = 9;
        break;

      case "app.groupmentAudit.detail.formation":
        $scope.selected = 10;
        break;

      case "app.groupmentAudit.detail.visit":
        $scope.selected = 11;
        break;

      case "app.groupmentAudit.detail.clientService":
        $scope.selected = 12;
        break;

      case "app.groupmentAudit.detail.chat":
        $scope.selected = 13;
        break;

      case "app.groupmentAudit.detail.visitePrep":
        $scope.selected = 14;
        break;

      case "app.groupmentAudit.detail.doc":
        $scope.selected = 15;
        break;

      default:
        $scope.selected = 0;
    }
  }
  /**
   * Go back in history
   */


  $scope.goBack = function () {
    $window.history.back();
  };
}

angular.module("app.groupmentAudit").controller("GroupmentAuditDetailController", GroupmentAuditDetailController);