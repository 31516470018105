"use strict";
/**
 * Grouping members service.
 *
 * @param {object} apiClient - API client
 *
 * @returns {object} The service
 */

GroupingMembersGroupingService.$inject = ["apiClient"];

function GroupingMembersGroupingService(apiClient) {
  return {
    /**
     * Get members.
     *
     * @param {object} queryFilter - Query filter
     *
     * @returns {Promise} The promise
     */
    getMembers: function getMembers(queryFilter) {
      var query = {
        parameters: queryFilter,
        wrappers: {
          response: null
        }
      };
      return apiClient.get("/grouping/members", query).then(function (response) {
        return response.data;
      });
    },

    /**
     * All members.
     *
     * @returns {Promise}
     */
    getAllMembers: function getAllMembers() {
      return apiClient.get("/grouping/allMembers").then(function (response) {
        return response.data;
      });
    },

    /**
     * Accept member.
     *
     * @param {object} data - Healthcenter data
     *
     * @returns {Promise}
     */
    acceptMember: function acceptMember(data) {
      return apiClient.post("/grouping/members/".concat(data.healthcenterId, "/accept")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Refuse member.
     *
     * @param {object} data - Healthcenter data
     *
     * @returns {Promise}
     */
    refuseMember: function refuseMember(data) {
      return apiClient.post("/grouping/members/".concat(data.healthcenterId, "/refuse")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete member.
     *
     * @param {object} data - Healthcenter data
     *
     * @returns {Promise} The promise
     */
    deleteMember: function deleteMember(data) {
      return apiClient["delete"]("/grouping/members/".concat(data.healthcenterId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get purchases by healthcenter.
     *
     * @param {object} member - Member.
     *
     * @returns {Promise}
     */
    getMemberWholesalerPurchases: function getMemberWholesalerPurchases(member) {
      return apiClient.get("/grouping/members/".concat(member.id, "/wholesalerPurchases")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get member top laboratories purchases.
     *
     * @param {object} member - Member.
     * @param {number} limit - Top limit.
     *
     * @returns {Promise}
     */
    getMemberTopLaboratoriesPurchases: function getMemberTopLaboratoriesPurchases(member, limit) {
      return apiClient.post("/grouping/members/".concat(member.id, "/topLaboratoriesPurchases"), {
        limit: limit
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get grouping history of actions.
     *
     * @param {object} queryFilter - Query filter
     *
     * @returns {Promise} The promise
     */
    getGroupingHistoryActions: function getGroupingHistoryActions(queryFilter) {
      var query = {
        parameters: [{
          name: "page",
          value: queryFilter.current_page
        }, {
          name: "perPage",
          value: queryFilter.per_page
        }],
        wrappers: {
          response: null
        }
      };
      return apiClient.get("/grouping/history", query).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.grouping").service("groupingMembersGroupingService", GroupingMembersGroupingService);