"use strict";
/**
 * Dashboard grouping top laboratories controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} groupingService - Grouping service.
 */

DashboardGroupTopLaboratoriesListController.$inject = ["$scope", "groupingService"];

function DashboardGroupTopLaboratoriesListController($scope, groupingService) {
  loader();
  /**
   * Loader.
   */

  function loader() {
    getDashboard();
  }
  /**
   * Get grouping dashboard.
   */


  function getDashboard() {
    var LIMIT = 5;
    $scope.loading = true;
    groupingService.getTopLaboratories(LIMIT).then(function (response) {
      $scope.laboratories = response.data;
      $scope.loading = false;
    });
  }
}

angular.module("app.dashboard").controller("dashboardGroupTopLaboratoriesListController", DashboardGroupTopLaboratoriesListController);