"use strict";
/**
 * Dashboard pharmacies market shares controller.
 *
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} colorService - Color service.
 * @param {object} analysisService - Analysis service.
 */

dashboardPharmaciesMarketSharesController.$inject = ["$state", "$scope", "StatisticsQueryService", "environmentService", "colorService", "analysisService"];

function dashboardPharmaciesMarketSharesController($state, $scope, StatisticsQueryService, environmentService, colorService, analysisService) {
  $scope.LabID = 0;
  $scope.loading = true;
  $scope.loadingChart = true;
  $scope.dataChartSerial = [];
  $scope.lgoCompatibility = false;
  $scope.labDashbd = false;
  $scope.hcDashbd = false;
  $scope.hcUnplgdDashbd = false;
  $scope.empDashbd = false;
  $scope.grpDashbd = false;
  $scope.detailRate = [];
  $scope.colors = colorService.colorsArray;
  $scope.currentEnv = environmentService.getEnvironment(); // Methods

  $scope.goToState = function (state) {
    $state.go(state);
  };

  loader();
  /**
   * Loader.
   */

  function loader() {
    launchStatisticsQueries();
  }
  /**
   * Initialize market share pie.
   *
   * @param {object} query -
   */


  function initMarketSharePie(query) {
    $scope.mShareloading = true;
    $scope.marketSharePieOptions = {
      theme: "light",
      hideLabelsPercent: 3,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      labelsEnabled: true,
      type: "pie",
      valueField: "rawvalue",
      titleField: "label",
      data: [],
      startDuration: 0,
      balloon: {
        fixedPosition: true
      },
      "export": {
        enabled: true
      }
    };
    processLabsMarketShareQuery(query);
  }
  /**
   * Process laboratories market share query.
   *
   * @param {object} query -
   */


  function processLabsMarketShareQuery(query) {
    analysisService.searchLaboratories(query).then(function (response) {
      $scope.mShareloading = false;
      $scope.LabsData = response;
      processMarketShareDataForAmCharts();
    });
  }
  /**
   * Process market share data for AmCharts.
   */


  function processMarketShareDataForAmCharts() {
    var ret = [];
    var objectList = [];
    $scope.caCount = $scope.LabsData.aggregations.amount_excl_tax.value.formatMoney(0, 3, " ", ", ");
    $scope.qtyCount = $scope.LabsData.aggregations.quantity.value.formatMoney(0, 3, " ");
    angular.forEach($scope.LabsData.aggregations.laboratories.buckets, function (data) {
      var obj = {};
      obj.label = data.key;

      if ($scope.currentRange === "CA") {
        obj.value = data.amount_excl_tax.value.formatMoney(0, 3, " ", ", ") + " €";
        obj.rawvalue = Math.trunc(data.amount_excl_tax.value);
      } else {
        obj.value = data.quantity.value.formatMoney(0, 3, " ") + " unités";
        obj.rawvalue = data.quantity.value;
      }

      var tmp = angular.copy(obj);
      tmp.products = data.products;
      objectList.push(tmp); // eslint-disable-next-line no-invalid-this

      this.push(obj);
    }, ret);
    $scope.marketSharePieOptions.data = ret;
  }
  /**
   * Launch statistics queries.
   */


  function launchStatisticsQueries() {
    $scope.usrquery = JSON.parse(JSON.stringify(StatisticsQueryService.pristineQuery));
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(initMarketSharePie);
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesMarketSharesController", dashboardPharmaciesMarketSharesController);