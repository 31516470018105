"use strict";
/**
 * Segment configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

SegmentConfiguration.$inject = ["$stateProvider"];

function SegmentConfiguration($stateProvider) {
  $stateProvider.state("app.segments", {
    url: "/segments",
    views: {
      "content@app": {
        templateUrl: "app/main/segment/views/list/segment.html",
        controller: "segmentController"
      }
    },
    params: {
      type: 1
    }
  }).state("app.statistics.products.segments", {
    url: "/segments",
    views: {
      "tabContent@app.statistics.products": {
        templateUrl: "app/main/segment/views/list/segment.html",
        controller: "segmentController"
      }
    },
    params: {
      type: 2
    }
  }).state("app.segments.create", {}).state("app.segments.edit", {}).state("app.segments.create.healthcenter", {
    url: "/healthcenters",
    views: {
      "content@app": {
        templateUrl: "app/main/segment/views/edit/healthcenters/segment-healthcenter-edit.html",
        controller: "segmentHealthcentersController"
      }
    }
  }).state("app.segments.edit.healthcenter", {
    url: "/healthcenters/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/segment/views/edit/healthcenters/segment-healthcenter-edit.html",
        controller: "segmentHealthcentersController"
      }
    }
  }).state("app.segments.create.product", {
    url: "/products",
    views: {
      "content@app": {
        templateUrl: "app/main/segment/views/edit/products/segment-product-edit.html",
        controller: "segmentProductsController"
      }
    }
  }).state("app.segments.edit.product", {
    url: "/products/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/segment/views/edit/products/segment-product-edit.html",
        controller: "segmentProductsController"
      }
    }
  });
}

angular.module("app.segment", []).config(SegmentConfiguration);