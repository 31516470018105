"use strict";
/**
 * The app-card-image directive.
 *
 * @returns {object}
 */

function AppCardImage() {
  return {
    restrict: "E",
    scope: {
      source: "@source"
    },
    templateUrl: "app/main/directives/app-card/app-card-image/appCardImage.html",
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appCardImage", AppCardImage);