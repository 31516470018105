"use strict";
/**
 * Average shopping cart audit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} averageShoppingCartService - Average shopping cart service.
 * @param {object} environmentService - Environment service.
 * @param {object} healthcenterAuditHcDetailsService - Healthcenter audit details service.
 */

AverageShoppingCartAuditController.$inject = ["$scope", "$stateParams", "StatisticsQueryService", "averageShoppingCartService", "environmentService", "healthcenterAuditHcDetailsService"];

function AverageShoppingCartAuditController($scope, $stateParams, StatisticsQueryService, averageShoppingCartService, environmentService, healthcenterAuditHcDetailsService) {
  $scope.config = {
    event: "averageShoppingCartAuditFilterChange",
    search: []
  };
  $scope.query = StatisticsQueryService.query;
  $scope.query.date = {
    from: moment().month(0).date(1),
    to: moment()
  };
  var startDate = moment($scope.query.date.from).format("DD-MM-YYYY");
  var endDate = moment($scope.query.date.to).format("DD-MM-YYYY");
  $scope.dateLabel = startDate + " Au " + endDate;
  $scope.rangeSelected = rangeSelected;
  loader();
  /**
   * Loader.
   */

  function loader() {
    var inCall = false;
    environmentService.getEnvironment().then(function (env) {
      var labo = {
        name: env.entity.name,
        id: env.entityId,
        type: "laboratory"
      };

      if ($stateParams.id) {
        inCall = true;
        healthcenterAuditHcDetailsService.hCDetail($stateParams.id).then(function (pharma) {
          var pharmaInfos = {
            id: pharma.healthcenter.id,
            name: pharma.healthcenter.name,
            type: "pharmas",
            cip: pharma.healthcenter.cip
          };
          $scope.query.must.pharmas.selected = [pharmaInfos];
          getAverageShoppingCartAnalysis($scope.query);
        });
      } else {
        $scope.query.must.laboratory.selected.push(labo);
      }

      if (!inCall) {
        getAverageShoppingCartAnalysis($scope.query);
      }
    });
  }
  /**
   * Make data by operator.
   *
   * @param {object} averageShoppingCart - Average shopping cart.
   */


  function makeDataByOperator(averageShoppingCart) {
    $scope.operatorData = [];
    averageShoppingCart.current.aggregations.byOperator.buckets.forEach(function (operator) {
      var comparisonData = _.find(averageShoppingCart.comparison.aggregations.byOperator.buckets, function (comparisonOperatorData) {
        return comparisonOperatorData.key_id === operator.key_id;
      });

      $scope.operatorData.push({
        current: operator,
        comparison: comparisonData
      });
    });
  }
  /**
   * Get average shopping cart analysis.
   *
   * @param {object} query - Query.
   */


  function getAverageShoppingCartAnalysis(query) {
    StatisticsQueryService.buildquery(query, query.date).then(averageShoppingCartService.getByOperator).then(function (averageShoppingCart) {
      $scope.averageShoppingCart = averageShoppingCart.current.aggregations;
      $scope.averageShoppingCartComparison = averageShoppingCart.comparison.aggregations;
      makeDataByOperator(averageShoppingCart);
    });
  }
  /**
   * on change date
   *
   * @param {object} range - Range.
   */


  function rangeSelected(range) {
    var startDate = moment(range.startDate).format("DD-MM-YYYY");
    var endDate = moment(range.endDate).format("DD-MM-YYYY");
    $scope.dateLabel = startDate + " Au " + endDate;
    $scope.query.date.from = moment(range.startDate, "DD-MM-YYYY");
    $scope.query.date.to = moment(range.endDate, "DD-MM-YYYY");
    getAverageShoppingCartAnalysis($scope.query);
  }

  $scope.$on("averageShoppingCartAuditFilterChange", function () {
    getAverageShoppingCartAnalysis($scope.query);
  });
}

angular.module("app.healthcenterAudit").controller("averageShoppingCartAuditController", AverageShoppingCartAuditController);