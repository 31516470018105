"use strict";
/**
 * Healthcenter stock audit distributor controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $compile - $compile.
 * @param {object} $timeout - $timeout.
 * @param {object} $stateParams - $stateParams.
 * @param {object} gmap - Google map.
 * @param {object} healthcenterAuditStockDistributorService -
 *      Healthcenter audit stock distributor service.
 * @param {object} StatisticsQueryService - Statistics query service.
 */

HealthcenterStockAuditDistributorController.$inject = ["$scope", "$compile", "$timeout", "$stateParams", "gmap", "healthcenterAuditStockDistributorService", "StatisticsQueryService"];

function HealthcenterStockAuditDistributorController($scope, $compile, $timeout, $stateParams, gmap, healthcenterAuditStockDistributorService, StatisticsQueryService) {
  /**
   * Declaration of public methods
   */
  $scope.getDistributor = getDistributor;
  $scope.showMap = showMap;
  $scope.hideMap = hideMap;
  $scope.usrquery = StatisticsQueryService.query;
  $scope.query = {};
  /**
   * Declaration of public objects
   */

  $scope.mapShown = false;
  $scope.loading = false;
  $scope.mapHasBeenShown = false;
  $scope.callError = false;
  $scope.datas = [];
  $scope.configSearch = {
    event: "stockDistributorFilterChange",
    search: ["idProducts", "segment.value", "segment.product", "laboratory.id", "category.levelX.id", "category", "range.id", "generic.id"]
  };
  $scope.loaderMarkers = [{
    id: 1,
    name: "sagitta-pharma",
    geo: {
      lat: 47.4277983,
      lng: 0.6234003
    },
    path: ""
  }, {
    id: 2,
    name: "brest",
    geo: {
      lat: 48.4084597,
      lng: -4.5346199
    },
    path: ""
  }, {
    id: 3,
    name: "lille",
    geo: {
      lat: 50.6311167,
      lng: 3.0121411
    },
    path: ""
  }, {
    id: 4,
    name: "strasbourg",
    geo: {
      lat: 48.5692059,
      lng: 7.6920546
    },
    path: ""
  }, {
    id: 5,
    name: "bayonne",
    geo: {
      lat: 43.4844564,
      lng: -1.496084
    },
    path: ""
  }, {
    id: 6,
    name: "marseille",
    geo: {
      lat: 43.2804942,
      lng: 5.3104569
    },
    path: ""
  }];
  /**
   * Process markers.
   */

  function processMarkers() {
    $scope.markers = [];

    if ($scope.markers && $scope.markers.length > 0) {
      $scope.markers.forEach(function (marker) {
        if (marker) {
          marker.setMap(null);
        }
      });
    }

    $scope.datas.result.forEach(function (res) {
      var latLng = new google.maps.LatLng(res.warehouse.geo_lat, res.warehouse.geo_lon);
      var infowindow = new google.maps.InfoWindow({});
      var error = 0;
      var content = "<div><p>" + res.warehouse.name + "</p>";
      angular.forEach(res.products, function (prod) {
        content += "<p>";

        if (typeof prod.dispo === "undefined") {
          error++;
          content += "<i class='material-icons red-500-fg' title='Erreur'>bug_report</i>" + prod.designation;
        }

        if (prod.dispo === true) {
          content += "<i class='material-icons green-500-fg' title='Disponible'>star</i>" + prod.designation;
        }

        if (prod.dispo === false) {
          error++;
          var msg = "<i class='material-icons red-500-fg' title='Non disponible'>star</i>" + prod.designation;

          if (typeof prod.message !== "undefined" && typeof prod.message.additif !== "undefined") {
            msg += "<h3>Code: " + prod.message.code + "</h3>";
            msg += "<h3>" + prod.message.additif + "</h3>";
          }

          content += msg;
        }

        content += "</p>";
      });
      content += "</div>";
      var marker;

      if (error !== 0 || !res.products) {
        marker = new google.maps.Marker({
          position: latLng,
          map: $scope.map,
          icon: {
            path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
            fillColor: "#e74c3c",
            fillOpacity: 1,
            strokeColor: "#e74c3c",
            strokeWeight: 1,
            scale: 1
          }
        });
      } else {
        marker = new google.maps.Marker({
          position: latLng,
          map: $scope.map,
          icon: {
            path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
            fillColor: "#27ae60",
            fillOpacity: 1,
            strokeColor: "#27ae60",
            strokeWeight: 1,
            scale: 1
          }
        });
      }

      marker.addListener("click", function () {
        var compiled = $compile(content)($scope);
        $scope.$apply();
        infowindow.setContent(compiled[0]);
        infowindow.open($scope.map, marker);
      });
      this.push(marker);
      marker.setMap($scope.map);
    }, $scope.markers);
  }
  /**
   * Show map.
   */


  function showMap() {
    $scope.mapShown = true;

    if (!$scope.mapHasBeenShown) {
      $scope.map = null;
      $scope.mapHasBeenShown = true;
      var myLatLng = {
        lat: 47.0833300,
        lng: 2.4000000
      };
      var mapOptions = {
        center: new google.maps.LatLng(myLatLng),
        zoom: 6,
        minZoom: 5,
        draggable: true,
        gestureHandling: "cooperative",
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: true,
        disableDefaultUI: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.LEFT_TOP
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        fullscreenControl: false,
        styles: gmap
      };
      $scope.map = new google.maps.Map(document.getElementById("distributorMap"), mapOptions);
      processMarkers();
    }

    $timeout(function () {
      google.maps.event.trigger($scope.map, "resize");
    }, 0);
  }
  /**
   * Hide map.
   */


  function hideMap() {
    $scope.mapShown = !$scope.mapShown;
    $scope.map = null;
    $scope.mapHasBeenShown = false;
  }
  /**
   * Get distributor.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function getDistributor(query) {
    $scope.loading = true;
    $scope.mapHasBeenShown = false;
    $scope.datas = [];
    $scope.presentations = {};
    query.healthcenter = $stateParams.id;
    return healthcenterAuditStockDistributorService.post(query).then(function (response) {
      $scope.datas = response.response;
      $scope.presentations = response.presentations;
      $scope.callError = response.error;
      $scope.loading = false;
      $scope.mapShown = false;
      return response;
    });
  }
  /*
       ** Events
       */


  $scope.$on("stockDistributorFilterChange", function () {
    $scope.loading = true;
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      return getDistributor(query);
    });
  });
}

angular.module("app.healthcenterStockAudit").controller("healthcenterStockAuditDistributorController", HealthcenterStockAuditDistributorController);