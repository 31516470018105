"use strict";
/**
 * Monitoring graph directive.
 *
 * @returns {object}
 */

monitoringGraphController.$inject = ["$scope", "$q"];

function MonitoringGraph() {
  return {
    restrict: "E",
    scope: {
      datas: "@",
      options: "@",
      unit: "@"
    },
    templateUrl: "app/main/directives/stats/monitoringGraph/monitoringGraphs.tmpl.html",
    controller: monitoringGraphController
  };
}
/**
 * Monitoring graph controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $q - $q.
 */


function monitoringGraphController($scope, $q) {
  $scope.datasObj = JSON.parse($scope.datas);
  $scope.optionsObj = JSON.parse($scope.options);
  /**
   * Manage data.
   */

  function manageDatas() {
    var lines = [];
    $scope.monitoringGraphs = {};
    $scope.deferredChart = $q.defer();
    $scope.monitoringGraphs = {
      type: "serial",
      data: $scope.deferredChart.promise,
      startDuration: 0,
      marginTop: 20,
      autoMarginOffset: 20,
      marginRight: 20,
      decimalSeparator: ",",
      thousandSeparator: " ",
      legend: false,
      valueAxes: [{
        id: "g1",
        axisAlpha: 1,
        position: "left",
        gridAlpha: 0.05,
        axisColor: "#000000",
        labelFunction: function labelFunction(value) {
          return value + " " + $scope.unit;
        }
      }],
      chartScrollbar: {
        graph: "g1",
        scrollbarHeight: 80,
        backgroundAlpha: 0,
        selectedBackgroundAlpha: 0.1,
        selectedBackgroundColor: "#888888",
        graphFillAlpha: 0,
        graphLineAlpha: 0.5,
        selectedGraphFillAlpha: 0,
        selectedGraphLineAlpha: 1,
        autoGridCount: true,
        color: "#AAAAAA",
        resizeEnabled: true
      },
      graphs: [{
        id: "min",
        fillAlphas: 0,
        lineAlpha: 0,
        showBalloon: false,
        valueField: "min",
        title: "Minimun",
        fillColors: "#008000",
        type: "step"
      }, {
        fillToGraph: "min",
        fillAlphas: 0.2,
        lineAlpha: 0,
        showBalloon: false,
        valueField: "max",
        title: "Maximum",
        fillColors: "#008000",
        type: "step"
      }, {
        fillAlphas: 0,
        balloonText: "<div style='margin:5px; text-align:left'>Max: [[max]]</br>Moyenne: [[avg]]</br>Min: [[min]]</div>",
        valueField: "avg",
        title: "Moyenne",
        fillColors: "#008000",
        lineColor: "#008000",
        type: "step"
      }],
      chartCursor: {
        cursorColor: "#888888",
        cursorAlpha: 0.5,
        categoryBalloonDateFormat: "MMM YYYY",
        cursorPosition: "mouse",
        fullWidth: false,
        valueLineBalloonEnabled: true
      },
      categoryField: "date",
      categoryAxis: {
        gridColor: "#F2F2F2",
        gridAlpha: 0.3,
        parseDates: true,
        minorGridAlpha: 0.1,
        minorGridEnabled: true,
        axisAlpha: 0,
        tickLength: 0,
        minPeriod: "mm",
        twoLineMode: true
      },
      "export": {
        enabled: false
      },
      listeners: [{
        event: "rendered",
        method: function method(item) {
          item.chart.zoomToIndexes(lines.length - 30, lines.length);
        }
      }, {
        event: "zoomed",
        method: function method() {// do nothing
        }
      }]
    };
    angular.forEach($scope.datasObj, function (data) {
      var obj = {
        date: moment(data.key)
      };
      obj.avg = data[$scope.optionsObj.avg].avg;
      obj.min = data[$scope.optionsObj.min].min;
      obj.max = data[$scope.optionsObj.max].max; // eslint-disable-next-line no-invalid-this

      this.push(obj);
    }, lines);
    $scope.deferredChart.resolve(lines);
  }

  $scope.$watch("datas", function (newValue) {
    $scope.datasObj = JSON.parse(newValue);
    manageDatas();
  });
}

angular.module("app.directives").directive("monitoringGraph", MonitoringGraph);