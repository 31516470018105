"use strict";
/**
 * Age pyramide controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */

AgePyramideController.$inject = ["$scope", "StatisticsQueryService", "environmentService", "analysisService"];

function AgePyramideController($scope, StatisticsQueryService, environmentService, analysisService) {
  var vm = this;
  vm.searchByEnv = {
    1: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id", "idOrgas"],
    2: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id"],
    3: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id"]
  };
  vm.datasOk = false;
  vm.noDatas = false;
  vm.pyramideDatas = [];
  environmentService.getEnvironment().then(function (env) {
    vm.currentEnv = env;
    vm.config = {
      event: "agePyramideAnalysisFilterChange",
      search: vm.searchByEnv[vm.currentEnv.accessPointType],
      limits: {
        product: 1
      },
      geoForce: true,
      showBadge: true
    };
    vm.usrQuery = StatisticsQueryService.query;

    if (vm.usrQuery.must.product.selected.length === 1) {
      vm.processQuery();
    }
  });
  vm.genderCorrespondTo = {
    1: "male",
    2: "female"
  };
  /*
       ** Date Filter
       */

  vm.rangeSelected = function (date) {
    vm.usrQuery.date.from = moment(date.startDate, "DD-MM-YYYY");
    vm.usrQuery.date.to = moment(date.endDate, "DD-MM-YYYY");
    vm.processQuery();
  };

  vm.processQuery = function () {
    vm.datasOk = false;
    var salesDatas = [];
    StatisticsQueryService.buildquery(vm.usrQuery, vm.usrQuery.date).then(function (query) {
      vm.query = query;
      vm.dateLabel = moment(vm.usrQuery.date.from).format("DD-MMM-YYYY") + " Au " + moment(vm.usrQuery.date.to).format("DD-MMM-YYYY");
      analysisService.getAgePyramide(query).then(function (response) {
        salesDatas = [];

        if (response.aggregations.age.buckets.length > 0) {
          angular.forEach(response.aggregations.age.buckets, function (age) {
            var split = age.key.split("-");
            var year = 60 * 60 * 24 * 365 * 1000;
            var ageKey1 = moment().diff(moment("01/" + split[1])) / year;
            var ageKey2 = moment().diff(moment("01/" + split[0])) / year;
            var ageKey = Math.floor(ageKey1) + " - " + Math.floor(ageKey2) + " ans";
            var datas = {
              age: ageKey
            };
            angular.forEach(age.gender.buckets, function (gen) {
              if (gen.key === "1") {
                datas[vm.genderCorrespondTo[gen.key]] = -gen.sales.value;
              } else if (gen.key === "2") {
                datas[vm.genderCorrespondTo[gen.key]] = gen.sales.value;
              }
            });
            salesDatas.push(datas);
          });
          vm.chartOptionsSalesPyramide = getchartOptionsSalesPyramide();
          vm.chartOptionsSalesPyramide.data = salesDatas;
          vm.datasOk = true;
        } else {
          vm.noDatas = true;
        }
      });
    });
  };
  /**
   * Get chart options sales pyramide.
   *
   * @returns {object}
   */


  function getchartOptionsSalesPyramide() {
    return {
      type: "serial",
      theme: "light",
      rotate: true,
      marginBottom: 50,
      marginLeft: 50,
      data: [],
      graphs: [{
        fillAlphas: 1,
        lineAlpha: 0.2,
        type: "column",
        valueField: "male",
        title: "Homme",
        clustered: false,
        balloonFunction: function balloonFunction(item) {
          return item.category + " : " + Math.abs(item.values.value) + " €";
        }
      }, {
        fillAlphas: 1,
        lineAlpha: 0.2,
        type: "column",
        valueField: "female",
        title: "Femme",
        fillColors: "#FF69B4",
        balloonFunction: function balloonFunction(item) {
          return item.category + " : " + Math.abs(item.values.value) + " €";
        }
      }],
      categoryField: "age",
      categoryAxis: {
        gridPosition: "start",
        gridAlpha: 0.2,
        axisAlpha: 0,
        parseDates: false
      },
      valueAxes: [{
        gridAlpha: 0,
        ignoreAxisWidth: true,
        labelFunction: function labelFunction(value) {
          return Math.abs(value) + " €";
        },
        guides: [{
          value: 0
        }]
      }],
      chartCursor: {
        valueBalloonsEnabled: false,
        cursorAlpha: 0.05,
        fullWidth: true
      },
      allLabels: [{
        text: "Homme",
        x: "28%",
        y: "97%",
        bold: true,
        align: "middle"
      }, {
        text: "Femme",
        x: "75%",
        y: "97%",
        bold: true,
        align: "middle"
      }],
      "export": {
        enabled: true
      }
    };
  }

  $scope.$on("agePyramideAnalysisFilterChange", function (event, searchusrProductquery) {
    vm.loadingProduct = true;
    angular.forEach(_.keys(searchusrProductquery), function (obj) {
      angular.forEach(searchusrProductquery[obj], function (data) {
        if (obj === "product") {
          vm.usrQuery.must[obj].selected = [data];
        } else if (vm.usrQuery.must[obj] && vm.usrQuery.must[obj].selected.indexOf(data) === -1) {
          vm.usrQuery.must[obj].selected.push(data);
        }
      });
    });
    vm.noProduct = vm.usrQuery.must.product.selected.length === 0;

    if (vm.noProduct === false) {
      vm.processQuery();
    }

    vm.loadingProduct = false;
  });
}

angular.module("app.statistics.products").controller("AgePyramideController", AgePyramideController);