"use strict";
/**
 * Controller for loyalty card member list.
 *
 * @param {object} $scope - Scope.
 * @param {object} $state - State.
 * @param {object} $stateParams - State params.
 * @param {object} $mdToast - MD toast.
 * @param {object} $mdDialog - MD dialog.
 * @param {object} environmentService - Environment service.
 * @param {object} loyaltyCardService - Loyalty card service.
 * @param {string} apiurl - Api URL.
 * @param {object} loyaltyCardConfig - Loyalty card config.
 */

LoyaltyCardMemberController.$inject = ["$scope", "$state", "$stateParams", "$mdToast", "$mdDialog", "environmentService", "loyaltyCardService", "apiurl", "loyaltyCardConfig"];

function LoyaltyCardMemberController($scope, $state, $stateParams, $mdToast, $mdDialog, environmentService, loyaltyCardService, apiurl, loyaltyCardConfig) {
  $scope.addLoyaltyCardMember = addLoyaltyCardMember;
  $scope.showLoyaltyCardMember = showLoyaltyCardMember;
  $scope.editLoyaltyCardMember = editLoyaltyCardMember;
  $scope.deleteLoyaltyCardMember = deleteLoyaltyCardMember;
  $scope.goToList = goToList;
  $scope.apiurl = apiurl;
  $scope.displayAllProducts = false;
  $scope.toggleDisplayAllProducts = toggleDisplayAllProducts;
  $scope.openSalesDialog = openSalesDialog;
  $scope.membersNotCompletedTableOptions = {
    order: "progression"
  };
  $scope.loading = true;
  $scope.query = {
    order: "-startDate",
    limit: 15,
    page: 1
  };
  loader();
  /**
   * Loader of the controller.
   *
   * @returns {Promise} The promise
   */

  function loader() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      getLoyaltyCard();
      getLoyaltyCardMembers();
      getLoyaltyCardProgress();
      return response;
    });
  }
  /**
   * Get the loyalty cards.
   *
   * @returns {Promise} The promise
   */


  function getLoyaltyCard() {
    var loyaltyCard = {
      id: $stateParams.loyaltyCardId
    };
    return loyaltyCardService.read(loyaltyCard).then(function (response) {
      $scope.loyaltyCard = response;
      return response;
    });
  }
  /**
   * Get the loyalty card members.
   *
   * @returns {Promise} The promise
   */


  function getLoyaltyCardMembers() {
    var loyaltyCard = {
      loyaltyCardId: $stateParams.loyaltyCardId
    };
    return loyaltyCardService.getMembers(loyaltyCard).then(function (response) {
      $scope.loading = false;
      $scope.loyaltyCardMembers = response;
      $scope.loyaltyCardMembersCompleted = $scope.loyaltyCardMembers.filter(function (member) {
        return member.isCompleted;
      });
      $scope.loyaltyCardMembersNotCompleted = $scope.loyaltyCardMembers.filter(function (member) {
        return !member.isCompleted;
      });
      $scope.loyaltyCardMembersCompleted;
      return response;
    });
  }
  /**
   * Get loyalty card progress.
   *
   * @returns {Promise}
   */


  function getLoyaltyCardProgress() {
    var loyaltyCard = {
      id: $stateParams.loyaltyCardId
    };
    return loyaltyCardService.getProgress(loyaltyCard).then(function (progress) {
      $scope.loyaltyCardProgress = progress;
    });
  }
  /**
   * Add a loyalty card member.
   *
   */


  function addLoyaltyCardMember() {
    $state.go("app.loyaltyCard.members.newMember", {
      loyaltyCardId: $scope.loyaltyCard.id
    });
  }
  /**
   * Show a loyalty card member.
   *
   * @param {number} loyaltyCardId - The loyalty card ID
   * @param {number} loyaltyCardMemberId - The loyalty card member ID
   */


  function showLoyaltyCardMember(loyaltyCardId, loyaltyCardMemberId) {
    $state.go("app.loyaltyCard.members.detail", {
      loyaltyCardId: loyaltyCardId,
      loyaltyCardMemberId: loyaltyCardMemberId
    });
  }
  /**
   * Edit a loyalty card member.
   *
   * @param {object} event - The event
   * @param {object} loyaltyCardMember - The loyalty card member
   */


  function editLoyaltyCardMember(event, loyaltyCardMember) {
    event.stopPropagation();
    $state.go("app.loyaltyCard.editMember", {
      id: loyaltyCardMember.id
    });
  }
  /**
   * Delete a loyalty card member.
   *
   * @param {object} event - The event
   * @param {object} loyaltyCardMember - The loyalty card member
   */


  function deleteLoyaltyCardMember(event, loyaltyCardMember) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm();
    confirm.title("Suppression").textContent("Êtes-vous certain de vouloir supprimer la carte de fidélité de cette personne ?").ariaLabel("suppression de carte de fidélité d'une personne").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      loyaltyCardService.deleteMember(loyaltyCardMember).then(function (response) {
        $mdToast.show($mdToast.simple().textContent("La carte de fidélité de cette personne est supprimée.").position("top right"));
        getLoyaltyCardMembers();
        return response;
      });
    });
  }
  /**
   * Toggle display all products.
   */


  function toggleDisplayAllProducts() {
    $scope.displayAllProducts = !$scope.displayAllProducts;
  }
  /**
   * Go to loyaltyCardMember list.
   */


  function goToList() {
    $state.go("app.loyaltyCard");
  }
  /**
   * Show a loyalty card member sales.
   *
   * @param {object} loyaltyCardMember - Loyalty card member.
   */


  function openSalesDialog(loyaltyCardMember) {
    var loyaltyCard = $scope.loyaltyCard;
    $mdDialog.show({
      controller: ["$scope", function controller($scope) {
        $scope.loading = true;
        loyaltyCardService.listLoyaltyCardMemberSales(loyaltyCard, loyaltyCardMember).then(function (sales) {
          $scope.loading = false;
          $scope.sales = sales;
        });

        $scope.cancel = function () {
          return $mdDialog.cancel();
        };
      }],
      templateUrl: loyaltyCardConfig.baseDir + "includes/modal/sales/sales.html"
    });
  }
}

angular.module("app.loyaltyCard").controller("loyaltyCardMemberController", LoyaltyCardMemberController);