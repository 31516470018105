"use strict";
/**
 * The app-mobile-menu directive.
 *
 * @returns {object}
 */

AppMobileMenuController.$inject = ["$scope", "$rootScope", "$state", "apiurl", "bricksService", "environmentService", "noRedirectStates"];

function AppMobileMenu() {
  return {
    restrict: "E",
    scope: {
      branding: "=branding"
    },
    templateUrl: "app/main/directives/app-mobile-menu/appMobileMenu.html",
    replace: true,
    transclude: true,
    controllerAs: "vm",
    controller: AppMobileMenuController
  };
}
/**
 * App mobile menu controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $state - $state.
 * @param {string} apiurl - API URL.
 * @param {object} bricksService - Bricks service.
 * @param {object} environmentService - Environment service.
 * @param {Array} noRedirectStates - No redirect states.
 */


function AppMobileMenuController($scope, $rootScope, $state, apiurl, bricksService, environmentService, noRedirectStates) {
  $scope.apiurl = apiurl;
  $scope.getBricks = getBricks;
  $scope.goTo = goTo;
  $scope.goToState = goToState;
  $scope.goToMenu = goToMenu;
  $scope.goHome = goHome;
  $scope.goToBrandingState = goToBrandingState;
  loader();
  /**
   * Loader.
   */

  function loader() {
    loaderCurrentEnv().then(getBricks).then(getCurrentBrick);
  }
  /**
   * Load current environment.
   *
   * @returns {Promise}
   */


  function loaderCurrentEnv() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentEnv = response;

      if (response.entity.wholesaler) {
        $scope.hasCapContract = typeof response.entity.wholesaler.id !== "undefined";
      }

      $scope.activeMenuEntity = "activeMenu" + response.accessPointType;
      $scope.isEmployee = response.accessPointType === 2 && response.rankId !== 1 && response.rankId !== 2;
      $scope.toState = $state.current.name;
      $scope.currentState = $state.current.name;
      $scope.itemActive = $state.current.name;
      return response;
    });
  }
  /**
   * Get bricks.
   *
   * @returns {Promise}
   */


  function getBricks() {
    var menuCreated = false;
    return bricksService.getBricks().then(function (response) {
      $scope.bricks = response;

      if (response) {
        response.forEach(function (brick) {
          if (!_.isUndefined($state.params.brickSlug) && brick.slug === $state.params.brickSlug.replace("_", ".")) {
            menuCreated = true;
            createMenu(brick);
            bricksService.setCurrentBrick(brick);
          }

          if ($scope.currentState !== "app.dashboard") {
            brick.menus.forEach(function (menu) {
              if (menu && menu.state === $scope.currentState) {
                menuCreated = true;
                createMenu(brick);
                bricksService.setCurrentBrick(brick);
              }
            });
          }
        });

        if (!menuCreated && $scope.currentState !== "app.home" && noRedirectStates.indexOf($scope.currentState) === -1) {
          getMenu();
        }
      } else {
        $state.go("app.home");
      }

      return response;
    });
  }
  /**
   * Get current brick.
   *
   * @returns {Promise}
   */


  function getCurrentBrick() {
    return bricksService.getCurrentBrick().then(function (response) {
      $scope.currentBrick = response;
      return response;
    });
  }
  /**
   * Create menu.
   *
   * @param {object} menus -
   */


  function createMenu(menus) {
    $scope.menuItems = menus.menus;
    $scope.itemActive = menus.state;
    setActiveItem($scope.menuItems);
  }
  /**
   * Get menu.
   *
   * @returns {Promise}
   */


  function getMenu() {
    return environmentService.getEnvironment().then(bricksService.getCurrentBrick).then(function (brick) {
      if (brick === null) {
        return bricksService.tryToFindCurrentBrick(true);
      }

      return brick;
    }).then(function (brick) {
      if (brick) {
        $scope.currentBrick = brick;
        $scope.menuItems = brick.menus;
        $scope.itemActive = brick.state;
        setActiveItem($scope.menuItems);
      }
    });
  }
  /**
   * Set active item.
   *
   * @param {object} menus -
   */


  function setActiveItem(menus) {
    menus.forEach(function (menu) {
      var isActive = $scope.toState === menu.state || $scope.itemActive === menu.state;

      if (menu.state.split(".").length >= 2) {
        var length = menu.state.split(".").length;
        isActive = menu.state.split(".")[length - 1] === $state.current.name.split(".")[length - 1];
      }

      menu.active = isActive;
    });
  }
  /**
   * Go to.
   *
   * @param {object} brick -
   */


  function goTo(brick) {
    $scope.branding = [];
    $scope.active = false;
    $rootScope.$broadcast("sideNavConstruct", brick);
    bricksService.setCurrentBrick(brick);
  }
  /**
   * Go to state.
   *
   * @param {object} state -
   */


  function goToState(state) {
    $scope.branding = [];
    $scope.active = false;
    $state.go(state);
  }
  /**
   * Go to branding state.
   *
   * @param {object} menu -
   */


  function goToBrandingState(menu) {
    $scope.active = false;
    $state.go(menu.state, menu);
  }
  /**
   * Go to menu.
   *
   * @param {object} item -
   */


  function goToMenu(item) {
    $scope.branding = [];
    $scope.active = false;
    item.active = true;
    $scope.menuItems.forEach(function (obj) {
      if (obj && obj.state !== item.state) {
        obj.active = false;
      }
    });

    if (item.state === "app.dashboard") {
      bricksService.getCurrentBrick().then(function (brick) {
        $state.go(item.state, {
          brickSlug: brick.slug.replace(".", "_")
        });
      });
    } else {
      $state.go(item.state);
    }
  }
  /**
   * Go home.
   */


  function goHome() {
    $rootScope.$broadcast("goHome");
  }

  $rootScope.$on("$stateChangeSuccess", function () {
    getMenu();
  });
  $rootScope.$on("setBrandingMenus", function (event, branding) {
    $scope.branding = branding;
  });
  $rootScope.$on("unsetBrandingMenus", function () {
    $scope.branding = [];
  });
}

angular.module("app.directives").directive("appMobileMenu", AppMobileMenu);