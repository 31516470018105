"use strict";
/**
 * Dashboard laboratories branding quarter controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} environmentService - Environment service.
 * @param {object} laboratoryBrandingService - Laboratory branding service.
 */

DashboardLaboratoriesBrandingQuarterController.$inject = ["$scope", "$state", "environmentService", "laboratoryBrandingService"];

function DashboardLaboratoriesBrandingQuarterController($scope, $state, environmentService, laboratoryBrandingService) {
  $scope.goToBranding = goToBranding;
  loader();
  /**
   * Load branding.
   *
   * @param {object} envirnment - Environment.
   *
   * @returns {Promise}
   */

  function loadBranding(envirnment) {
    var query = {
      laboratoryId: envirnment.entity.id
    };
    return laboratoryBrandingService.read(query);
  }
  /**
   * Loader.
   */


  function loader() {
    environmentService.getEnvironment().then(loadBranding).then(function (branding) {
      $scope.branding = branding;
    });
  }
  /**
   * Go to branding.
   */


  function goToBranding() {
    $state.go("app.laboratory-branding");
  }
}

angular.module("app.dashboard").controller("dashboardLaboratoriesBrandingQuarterController", DashboardLaboratoriesBrandingQuarterController);