"use strict";
/**
 * Browser alert directive.
 *
 * @returns {object}
 */

browserAlertController.$inject = ["$window", "$scope"];

function browserAlertDirective() {
  return {
    restrict: "E",
    templateUrl: "app/main/directives/browserAlert/browserAlert.html",
    controller: browserAlertController
  };
}
/**
 * Browser alert controller.
 *
 * @param {object} $window - $window.
 * @param {object} $scope - $scope.
 */


function browserAlertController($window, $scope) {
  if ($window.navigator.userAgent.toLowerCase().search("chrome") !== -1) {
    $scope.browserInfo = {
      browser: "chrome"
    };
  }
}

angular.module("app.directives").directive("browseralert", browserAlertDirective);