"use strict";
/**
 * Laboratory branding configuration.
 *
 * @param {object} $stateProvider - $stateProvider
 */

LaboratoryBrandingConfiguration.$inject = ["$stateProvider"];

function LaboratoryBrandingConfiguration($stateProvider) {
  $stateProvider.state("app.laboratory-branding", {
    url: "/laboratoire/ma-vitrine",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory/branding/views/main/main.html",
        controller: "laboratoryBrandingMainController"
      }
    }
  }).state("app.laboratory-branding.univers", {
    url: "/univers/:universId",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory/branding/views/edit/univers/laboratory.branding.edit.univers.html",
        controller: "laboratoryBrandingEditUniversController as vm"
      }
    },
    params: {
      univers: null
    }
  }).state("app.laboratory-branding.univers.range", {
    url: "/gamme/:rangeId",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory/branding/views/edit/range/laboratory.branding.edit.range.html",
        controller: "laboratoryBrandingEditRangeController as vm"
      }
    },
    params: {
      univers: null,
      range: null
    }
  });
}

angular.module("app.laboratory", ["app.laboratory-products", "app.laboratory-universe-range"]).config(LaboratoryBrandingConfiguration);