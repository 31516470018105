"use strict";
/**
 * Auth profile service.
 *
 * @param {object} apiClient - API client.
 * @param {object} notifyUserService - Notify user service.
 *
 * @returns {object}
 */

AuthProfileService.$inject = ["apiClient", "notifyUserService"];

function AuthProfileService(apiClient, notifyUserService) {
  return {
    /**
     * Auth.
     *
     * @param {object} params - Params.
     *
     * @returns {Promise}
     */
    auth: function auth(params) {
      return apiClient.post("/users/authenticate", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Register.
     *
     * @param {object} params - Params.
     *
     * @returns {Promise}
     */
    register: function register(params) {
      return apiClient.post("/users", params, {
        headers: {
          "Content-Type": "application/json;utf-8"
        },
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Available mail.
     *
     * @param {string} email - Email.
     *
     * @returns {Promise}
     */
    availableMail: function availableMail(email) {
      return apiClient.get("/users/".concat(email, "/available"), {
        headers: {
          "Content-Type": "application/json;utf-8"
        },
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * User.
     *
     * @returns {Promise}
     */
    user: function user() {
      return apiClient.get("/users", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Edit user.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    editUser: function editUser(params) {
      return apiClient.put("/users", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete profile.
     *
     * @returns {Promise}
     */
    deleteProfile: function deleteProfile() {
      return apiClient["delete"]("/users", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Change password.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    changePassword: function changePassword(params) {
      return apiClient.put("/users/changeForgotPassword", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Confirm forgot password.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    confirmForgotPassword: function confirmForgotPassword(params) {
      return apiClient.get("/users/confirmForgotPassword/".concat(params.code), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Forgot password.
     *
     * @param {object} params - The params containing the email.
     *
     * @returns {Promise}
     */
    forgotPassword: function forgotPassword(params) {
      return apiClient.post("/users/forgotPassword", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Confirm mail modification.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    confirmMailModification: function confirmMailModification(params) {
      return apiClient.put("/users/confirmMailModification", params, {
        wrappers: {
          response: null
        }
      })["catch"](function () {
        return notifyUserService.showDangerToast("Une erreur est survenue lors de la modification de votre email");
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.auth").service("authProfileService", AuthProfileService);