"use strict";

PathologyService.$inject = ["apiClient"];

/**
 * The pathology service.
 *
 * @param {object} apiClient - The api client.
 *
 * @returns {object}
 */
function PathologyService(apiClient) {
  return {
    /**
     * Index pathologies.
     *
     * @param {Array} parameters - The query params.
     *
     * @returns {Promise}
     */
    indexPathologies: function indexPathologies(parameters) {
      return apiClient.get("/pathologies", {
        wrappers: {
          response: null
        },
        parameters: parameters
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Index products for a pathology.
     *
     * @param {number} pathologyId - The pathology id.
     * @param {Array} params - The query params.
     *
     * @returns {Promise}
     */
    indexProductsForPathology: function indexProductsForPathology(pathologyId, params) {
      return apiClient.get("/pathologies/".concat(pathologyId, "/products"), {
        wrappers: {
          response: null
        },
        parameters: params
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Attach products to a pathology.
     *
     * @param {number} pathologyId - The pathology id.
     * @param {Array} presentationIds - The products speciality presentation ids.
     *
     * @returns {Promise}
     */
    attachProductsToPathology: function attachProductsToPathology(pathologyId, presentationIds) {
      var params = {
        product_ids: presentationIds
      };
      return apiClient.post("/pathologies/".concat(pathologyId, "/products"), params, {
        wrappers: {
          response: null
        }
      });
    },

    /**
     * Sync the customer association to the pathology.
     *
     * @param {number} pathologyId - The id of the pathology.
     * @param {Array} associations - The customer association array.
     *
     * @returns {Promise}
     */
    syncCustomerAssociationsToPathology: function syncCustomerAssociationsToPathology(pathologyId, associations) {
      return apiClient.post("/pathologies/".concat(pathologyId, "/customer-associations"), {
        associations: associations
      }, {
        wrappers: {
          response: null
        }
      });
    },

    /**
     * Get customer associations associated with a pathology.
     *
     * @param {number} pathologyId - The pathology id.
     *
     * @returns {Promise}
     */
    getCustomerAssociationsForPathology: function getCustomerAssociationsForPathology(pathologyId) {
      return apiClient.get("/pathologies/".concat(pathologyId, "/customer-associations")).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.patient-administration.services").service("pathologyService", PathologyService);