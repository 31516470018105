"use strict";
/**
 * Reverse lab filter.
 *
 * @returns {Function}
 */

function reverseLab() {
  // eslint-disable-next-line consistent-return
  return function (items) {
    if (items) {
      return items.slice().reverse();
    }
  };
}

angular.module("app.generator").filter("reverseLab", reverseLab);