"use strict";
/**
 * Apodis brick search directive.
 *
 * @returns {object}
 */

ApodisDelegateHealthcenterSearchController.$inject = ["$rootScope", "$scope", "$state"];

function ApodisDelegateHealthcenterSearch() {
  return {
    restrict: "E",
    templateUrl: "app/main/directives/apodis-delegate-healthcenter-search/apodisDelegateHealthcenterSearch.html",
    transclude: true,
    controller: ApodisDelegateHealthcenterSearchController
  };
}
/**
 * Apodis brick search controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $state - $state service.
 */


function ApodisDelegateHealthcenterSearchController($rootScope, $scope, $state) {
  /**
   * Declaration of public objects
   */
  $scope.healthcenterSearchConfig = {
    event: "healthcenterClicked",
    search: ["idOrgas"],
    tag: "delegateHealthcenterSearch",
    autoLaunchEvent: true
  };
  /**
   * Event
   */

  $scope.$on("healthcenterClicked", function (event, query) {
    var params = {
      id: query.pharmas[0].id
    };
    var menu = {
      state: "app.healthcenterAudit.detail.general",
      params: params
    };
    $state.go("app.healthcenterAudit.detail.general", params);
    $rootScope.$broadcast("sideNavConstruct:with-menu", menu);
  });
}

angular.module("app.directives").directive("apodisDelegateHealthcenterSearch", ApodisDelegateHealthcenterSearch);