"use strict";

/**
 * The patient program card component.
 */
var PatientProgramCard = {
  templateUrl: "app/main/patients/components/patient-program-card/patient-program-card.html",
  bindings: {
    isActive: "<",
    onActionPress: "&",
    tooltipLabel: "<",
    cardIcon: "<"
  },
  controllerAs: "vm",
  transclude: true
};
angular.module("app.patients.components").component("patientProgramCard", PatientProgramCard);