"use strict";
/**
 * Supplying controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $state - $state.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} $mdMenu - $mdMenu.
 * @param {object} analysisService - Analysis service.
 * @param {object} $stateParams - $stateParams
 * @param {object} healthcenterService - The healthcenter service.
 */

SupplyingController.$inject = ["$scope", "StatisticsQueryService", "environmentService", "$mdDialog", "$state", "notifyUserService", "$mdMenu", "analysisService", "$stateParams", "healthcenterService"];
DetailSupplyingController.$inject = ["$mdDialog", "aggBy", "usrQuery", "data", "$state", "StatisticsQueryService", "analysisService"];

function SupplyingController($scope, StatisticsQueryService, environmentService, $mdDialog, $state, notifyUserService, $mdMenu, analysisService, $stateParams, healthcenterService) {
  var vm = this;
  vm.aggBySelect = StatisticsQueryService.aggBy;
  $scope.currentState = $state.current.name;
  $scope.hide = hide;
  $scope.open = open;
  $scope.hideColumn = {};
  vm.aggByName = vm.aggBySelect[1].title;
  vm.aggregateBy = vm.aggBySelect[1].val;
  vm.aggBy = {};
  vm.aggBy.model = JSON.stringify(vm.aggBySelect[1]);
  vm.searchByEnv = {
    1: [],
    2: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id"],
    3: ["idProducts", "geo.region.id", "geo.departement.number", "geo.uga", "group.id"]
  };
  vm.loaderPharmaMlAnalysis = {
    loading: true,
    loader: "Récupération des pharmaML à analyser",
    empty: "Aucun pharmaML à analyser"
  };
  vm.dataOk = false;
  vm.data = null;
  $scope.totalCount = 0;
  environmentService.getEnvironment().then(function (env) {
    vm.currentEnv = env;
    vm.config = {
      event: "supplyingAnalysisFilterChange",
      search: vm.searchByEnv[vm.currentEnv.accessPointType],
      geoForce: true,
      showBadge: true,
      searchPlaceHolder: "Effectuer une recherche pour choisir les données à analyser"
    };
    StatisticsQueryService.queryRebase();
    vm.usrQuery = StatisticsQueryService.query;
    vm.usrQuery.date = {
      from: moment().subtract(1, "month"),
      to: moment()
    }; // We preselect the laboratory if the current env is a laboratory one

    if (vm.currentEnv.accessPointType === 1) {
      var laboratory = {
        id: vm.currentEnv.entityId,
        name: vm.currentEnv.entity.name,
        type: "laboratory"
      };

      var laboratoryMust = _.find(vm.usrQuery.must.laboratory.selected, function (labo) {
        return labo.id === laboratory.id;
      });

      if (!laboratoryMust) {
        laboratoryMust = _.find(vm.usrQuery.should.laboratory.selected, function (labo) {
          return labo.id === laboratory.id;
        });
      }

      if (!laboratoryMust) {
        vm.usrQuery.must.laboratory.selected.push(laboratory);
      }
    }

    if ($stateParams.id) {
      healthcenterService.getDetails({
        id: $stateParams.id
      }).then(function (pharma) {
        vm.usrQuery.must.pharmas.selected = [];
        var pharmaInfo = {
          id: $stateParams.id,
          name: pharma.healthcenter.name,
          type: "pharmas",
          cip: pharma.healthcenter.cip
        };

        if (vm.usrQuery.must.pharmas.selected.indexOf(pharmaInfo) === -1) {
          vm.usrQuery.must.pharmas.selected.push(pharmaInfo);
        }

        vm.processQuery();
      });
    } else {
      vm.processQuery();
    }
  });
  vm.interval = {
    model: "auto",
    values: [{
      label: "Automatique",
      value: "auto"
    }, {
      label: "Jour",
      value: "1d",
      displayPattern: "DD MMM YYYY"
    }, {
      label: "Semaine",
      value: "1w",
      displayPattern: "semaine W YYYY"
    }, {
      label: "Mois",
      value: "1M",
      displayPattern: "MMM YYYY"
    }]
  };
  vm.toggleCb = toggleCb;
  vm.indexByLabel = indexByLabel;
  vm.existsFilter = existsFilter;
  /**
   * Date Filter.
   *
   * @param {object} date - Date.
   */

  vm.rangeSelected = function (date) {
    vm.usrQuery.date.from = moment(date.startDate, "DD-MM-YYYY");
    vm.usrQuery.date.to = moment(date.endDate, "DD-MM-YYYY");
    vm.processQuery();
  };

  vm.pagination = {
    order: "-delivered.sum",
    limit: 10,
    page: 1
  };
  vm.rawValues = StatisticsQueryService.values;
  vm.getLimits = getLimits;
  /**
   * Open.
   *
   * @param {object} event - Event.
   */

  function open(event) {
    $mdMenu.open(event);
  }
  /**
   * toggleCheckboxes
   *
   * @param {object} item - The item
   * @param {object} list - The list
   */


  function toggleCb(item, list) {
    vm.pagination.page = 1;
    var idx = vm.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    } // Add or delete a filter using checkbox : auto search


    vm.processQuery();
  }
  /**
   * Index by label.
   *
   * @param {string} name - The name
   * @param {Array} list - The array of values
   *
   * @returns {number}
   */


  function indexByLabel(name, list) {
    if (!name || !list) {
      return -1;
    }

    for (var index = 0, length = list.length; index < length; index++) {
      if (list[index].label === name) {
        return index;
      }
    }

    return -1;
  }
  /**
   * Exists filter.
   *
   * @param {string} name - The name
   * @param {Array} list - The array of values
   *
   * @returns {boolean}
   */


  function existsFilter(name, list) {
    return vm.indexByLabel(name, list) > -1;
  }
  /**
   * Get limits.
   *
   * @param {object} array - The limits
   * @returns {Array}
   */


  function getLimits(array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  }
  /**
   * processQuery
   */


  vm.processQuery = function () {
    vm.data = [];
    vm.dataOk = false;
    vm.loaderPharmaMlAnalysis.loading = true;
    StatisticsQueryService.buildquery(vm.usrQuery, vm.usrQuery.date).then(function (query) {
      vm.query = query;
      vm.dateLabel = moment(vm.usrQuery.date.from).format("DD-MMM-YYYY") + " Au " + moment(vm.usrQuery.date.to).format("DD-MMM-YYYY");
      query.aggBy = vm.aggregateBy;
      query.sort = vm.pagination;
      query.interval = vm.interval.model;
      analysisService.searchProductSupplying(query).then(function (response) {
        if (response.data.length > 0) {
          vm.data = response.data;
          $scope.totalCount = response.pagination.total;
        }

        vm.dataOk = true;
        vm.loaderPharmaMlAnalysis.loading = false;
      });
    });
  };
  /**
   * Hide.
   *
   * @param {object} event - Event.
   * @param {number} column - Column.
   */


  function hide(event, column) {
    event.stopPropagation();
    $scope.hideColumn[column] = !$scope.hideColumn[column];
  }

  $scope.addInFilter = function (line) {
    var type = JSON.parse(vm.aggBy.model).type;

    if (type) {
      var data = {
        name: line.key,
        id: line.key_id
      };
      vm.usrQuery.must[type].selected.push(data);
      vm.processQuery();
    } else {
      notifyUserService.showSimpleToast("Impossible d'ajouter " + line.key + " aux filtres", {
        color: "error",
        hideDelay: 2000
      });
    }
  };

  $scope.$on("supplyingAnalysisFilterChange", function (event, searchUsrProductquery) {
    vm.loadingProduct = true;
    vm.pagination.page = 1;
    angular.forEach(_.keys(searchUsrProductquery), function (obj) {
      angular.forEach(searchUsrProductquery[obj], function (data) {
        if (obj === "product") {
          vm.usrQuery.must[obj].selected = [data];
        } else if (vm.usrQuery.must[obj] && vm.usrQuery.must[obj].selected.indexOf(data) === -1) {
          vm.usrQuery.must[obj].selected.push(data);
        }
      });
    });
    vm.processQuery();
    vm.loadingProduct = false;
  });
  /**
   * Go detail
   *
   * @param {object} data - The data
   */

  vm.goDetail = function (data) {
    $mdDialog.show({
      locals: {
        data: data,
        aggBy: vm.aggregateBy,
        usrQuery: vm.usrQuery,
        scope: $scope
      },
      controller: DetailSupplyingController,
      controllerAs: "dsc",
      templateUrl: "app/main/statistics/stocks/supplying/supplyingDialog.tmpl.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true
    });
  };

  vm.openDialogEclatement = function (event) {
    DialogEclatementController.$inject = ["$scope", "$mdDialog", "items", "aggBy", "currentState"];
    var parentEl = angular.element(document.body);
    $mdDialog.show({
      parent: parentEl,
      targetEvent: event,
      templateUrl: "app/main/statistics/template/eclatement.tmpl.html",
      locals: {
        items: vm.aggBySelect,
        aggBy: vm.aggBy,
        currentState: $scope.currentState
      },
      controller: DialogEclatementController
    });
    /**
     * Dialog eclatement controller.
     *
     * @param {object} $scope - $scope.
     * @param {object} $mdDialog - $mdDialog.
     * @param {Array} items - Items.
     * @param {string} aggBy - Aggregate by.
     * @param {string} currentState - Current state.
     */

    function DialogEclatementController($scope, $mdDialog, items, aggBy, currentState) {
      /**
       * Chunk.
       *
       * @param {Array} arr -
       * @param {number} size -
       *
       * @returns {Array}
       */
      function chunk(arr, size) {
        var newArr = [];

        for (var index = 0; index < arr.length; index += size) {
          newArr.push(arr.slice(index, index + size));
        }

        return newArr;
      }

      $scope.currentState = currentState;
      $scope.items = chunk(items, 8);
      $scope.aggBy = aggBy; // eslint-disable-next-line consistent-return

      $scope.canEclate = function (item) {
        if (typeof vm.currentEnv === "undefined") {
          environmentService.getEnvironment().then(function (env) {
            vm.currentEnv = env;

            if (typeof item.rankNeeded !== "undefined") {
              return item.rights.indexOf(vm.currentEnv.accessPointType) > -1 && item.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
            }

            if (item.rights.indexOf("Administrator") > -1) {
              return vm.currentEnv.user.role === "Administrator";
            }

            return item.rights.indexOf(vm.currentEnv.accessPointType) > -1;
          });
        } else if (typeof item.rankNeeded !== "undefined") {
          return item.rights.indexOf(vm.currentEnv.accessPointType) > -1 && item.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
        } else {
          if (item.rights.indexOf("Administrator") > -1) {
            return vm.currentEnv.user.role === "Administrator";
          }

          return item.rights.indexOf(vm.currentEnv.accessPointType) > -1;
        }
      };

      $scope.flexResult = function (array) {
        if (array.length >= 10) {
          return 50;
        }

        return 100;
      };

      $scope.eclateBy = function (agg) {
        var parsedAgg = JSON.parse(agg);
        vm.aggregateBy = parsedAgg.val;
        vm.aggBy = {};
        vm.aggBy.model = agg;
        vm.aggByName = parsedAgg.title + (parsedAgg.label ? " " + parsedAgg.label : "");

        if (vm.aggregateBy === "none") {
          vm.display100Percent = 0;
        }

        vm.loading = true;
        vm.processQuery();
        $mdDialog.hide();
      };
    }
  };
}
/**
 * Detail supplying controller.
 *
 * @param {object} $mdDialog - $mdDialog.
 * @param {string} aggBy - Aggregate by.
 * @param {object} usrQuery - User query.
 * @param {object} data - Data.
 * @param {object} $state - $state.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} analysisService - Analysis service.
 */


function DetailSupplyingController($mdDialog, aggBy, usrQuery, data, $state, StatisticsQueryService, analysisService) {
  var dsc = this;
  dsc.data = data;
  dsc.pharmaml = [];
  dsc.paginationDetail = {
    order: "-datetime",
    limit: 10,
    page: 1
  };
  dsc.getDetailData = getDetailData;
  dsc.goToAudit = goToAudit;
  getDetailData();
  /**
   * Go to healthcenter audit.
   *
   * @param {object} line - Line.
   */

  function goToAudit(line) {
    $state.go("app.healthcenterAudit.detail.general", {
      id: line.key_id
    });
    $mdDialog.hide();
  }
  /**
   * Get detail data.
   */


  function getDetailData() {
    dsc.loading = true;
    StatisticsQueryService.buildquery(usrQuery, usrQuery.date).then(function (query) {
      dsc.query = query;
      dsc.dateLabel = moment(usrQuery.date.from).format("DD-MMM-YYYY") + " Au " + moment(usrQuery.date.to).format("DD-MMM-YYYY");
      query.aggBy = aggBy;
      query.sort = dsc.paginationDetail;
      analysisService.productSupplyingProvider(data.key_id, query).then(function (response) {
        var data = [];
        angular.forEach(response.data, function (line) {
          if (line.item.state.message === null) {
            line.item.state.message = "";
          }

          data.push(line);
        });
        dsc.pharmaml = data;
        dsc.pharmaml.totalDistinct = response.totalDistinct;
        dsc.loading = false;
      });
    });
  }

  dsc.hide = function () {
    $mdDialog.hide();
  };

  dsc.cancel = function () {
    $mdDialog.cancel();
  };

  dsc.iconState = function (line) {
    if (line.item.quantity.order === line.item.quantity.delivery) {
      return "<i class='material-icons green-500-fg' title='Livraison totale'>star</i>";
    }

    if (line.item.quantity.order === line.item.quantity.missing) {
      return "<i class='material-icons red-500-fg' title='Aucune livraison'>star</i>";
    }

    if (line.item.quantity.missing > 0) {
      return "<i class='material-icons orange-500-fg' title='Livraison partielle'>star_half</i>";
    }

    if (line.item.quantity.missing < 0) {
      return "<i class='material-icons blue-500-fg'' " + "title='Livraison supérieure à la commande (Colisage minimum)'>star</i>";
    }

    return "";
  };
}

angular.module("app.statistics.products").controller("SupplyingController", SupplyingController);