"use strict";
/** @ngInject */

/**
 * Login Controller
 *
 * @param {object} $state - The angular $state object.
 * @param {object} environmentService - The environment service.
 * @param {object} $scope - The angular $scope object.
 * @param {object} $rootScope - The angular $rootScope object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} notifyUserService - The notify user service.
 * @param {object} authService - The auth service.
 * @param {object} amplitudeService - The amplitude service.
 * @param {object} bricksService - Bricks service.
 * @param {object} NotificationService -  The notification service.
 * @param {object} apodisThemingFactory - The theming factory.
 * @param {object} deepLinkService - The deep link service.
 */

LoginController.$inject = ["$state", "environmentService", "$scope", "$rootScope", "$stateParams", "notifyUserService", "authService", "amplitudeService", "bricksService", "NotificationService", "apodisThemingFactory", "deepLinkService"];

function LoginController($state, environmentService, $scope, $rootScope, $stateParams, notifyUserService, authService, amplitudeService, bricksService, NotificationService, apodisThemingFactory, deepLinkService) {
  var vm = this;
  vm.loading = false;
  vm.domainName = window.sessionStorage.getItem("domainName");
  $rootScope.unvalidatedContracts = null;
  $scope.loadingContracts = {
    loading: true,
    loader: "Récupération des contrats en cours",
    empty: "Vous n'avez aucun contrat"
  };
  vm.user = {
    name: "",
    password: ""
  };

  if ($stateParams.key) {
    login($stateParams.key);
  } else {
    vm.login = login;
  }

  loader();
  /**
   * loader
   */

  function loader() {
    apodisThemingFactory.generateDefault();
    logout();
  }
  /**
   * logout
   */


  function logout() {
    authService.logout();
  }
  /**
   * login
   *
   * @param {string} key - The key.
   * @returns {Promise}
   */


  function login(key) {
    vm.loading = true;
    return authService.destroyCredentials().then(function () {
      return authService.login(vm.user, key);
    }).then(function (result) {
      amplitudeService.identifyUser(result.data.user.id, {
        email: vm.user.name
      });
      amplitudeService.logEvent("login", {
        email: vm.user.name
      });
      var payload = JSON.parse(window.sessionStorage.getItem("payload"));
      payload.options = result.data.options;
      window.sessionStorage.setItem("payload", JSON.stringify(payload));
      vm.error401 = false;
      environmentService.getEnvironment().then(bricksService.getBricks).then(function (bricks) {
        vm.loading = false;
        NotificationService.load();
        var options = angular.fromJson(window.sessionStorage.getItem("payload")).options;
        $scope.hasBricksMenus = options.indexOf("bricks-menus") > -1;

        if (!$scope.hasBricksMenus && bricks.length) {
          bricksService.setCurrentBrick(bricks[0]);
          $state.go("app.dashboard", {
            brickSlug: bricks[0].slug.replace(".", "_")
          });
        } else if (deepLinkService.isDeepLinkingKey($stateParams["deep-link"])) {
          deepLinkService.redirectToDeepLink($stateParams["deep-link"]);
        } else {
          $state.go("app.home");
        }

        return result;
      });
    })["catch"](function (error) {
      apodisThemingFactory.generateDefault();
      vm.loading = false;

      if (error.status === 401) {
        notifyUserService.showSimpleToast("Email ou mot de passe incorrect, veuillez réessayer.");
        vm.error401 = true;
      } else {
        notifyUserService.showSimpleToast("Une erreur est survenue, veuillez réessayer.");
      }

      return error;
    });
  }
}

angular.module("app.auth").controller("LoginController", LoginController);