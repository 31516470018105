"use strict";
/**
 * Generic service.
 *
 * @param {object} apiClient -
 * @returns {object}
 */

GenericService.$inject = ["apiClient"];

function GenericService(apiClient) {
  return {
    /**
     * Get.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/generic-laboratories", {
        includes: ["laboratory"]
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Save generic ideal order.
     *
     * @param {object} data -
     * @returns {Promise}
     */
    saveGenericIdealOrder: function saveGenericIdealOrder(data) {
      return apiClient.post("/generic-laboratories", data).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics").service("genericService", GenericService);