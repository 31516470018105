/* eslint-disable consistent-return */
"use strict";
/**
 * Fabric lab factory.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $timeout - $timeout.
 * @param {object} fabricWindow - Fabric window.
 * @param {object} fabricCanvas - Fabric canvas.
 * @param {object} fabricDirtyStatus - Fabric dirty status.
 * @param {object} fabricMask - Fabric mask.
 *
 * @returns {object}
 */

FabricLab.$inject = ["$rootScope", "$timeout", "fabricWindow", "fabricCanvas", "fabricDirtyStatus", "fabricMask"];

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function FabricLab($rootScope, $timeout, fabricWindow, fabricCanvas, fabricDirtyStatus, fabricMask) {
  return function (options) {
    var canvas;
    var JSONObject;
    var service;
    var SCALE_FACTOR = 1.1;
    var isMaster = options.isMaster;
    var isLocked = options.isLocked;

    var getFabricCanvases = function getFabricCanvases() {
      var fabricCanvasCollection = [];
      var fabricCanvas = $(".fabric-container");
      fabricCanvas.map(function (item) {
        return fabricCanvasCollection.push($(item));
      });
      return fabricCanvasCollection;
    };

    var myFabric = {
      name: "",
      description: "",
      canvasBackgroundColor: "rgba(255, 255, 255, 0)",
      maxContinuousRenderLoops: 25,
      continuousRenderTimeDelay: 500,
      start_date: "",
      end_date: "",
      editable: true,
      JSONExportProperties: [],
      loading: false,
      dirty: false,
      initialized: false,
      downloadMultipler: 2,
      imageDefaults: {},
      textDefaults: {},
      shapeDefaults: {},
      windowDefaults: {},
      canvasDefaults: {
        selection: true
      },
      enderCount: 0,
      continuousRenderCounter: 0,
      allowTouchScrolling: true
    };
    service = Object.assign(myFabric, options);
    service.continuousRenderHandle;
    /**
     * Override fabric3.Image.prototype to add getSvgSrc method
     *
     * @returns {string}
     */

    fabric3.Image.prototype.getSvgSrc = function () {
      return canvas.toDataURLforSVG();
    };
    /**
     * Override fabric3.Image.prototype to add toDataURLforSVG method
     *
     * @param {any} options -
     * @returns {string} base64
     */


    fabric3.Image.prototype.toDataURLforSVG = function (options) {
      var el = fabric3.util.createCanvasElement();

      if (canvas._element) {
        el.width = canvas._element.naturalWidth;
        el.height = canvas._element.naturalHeight;
        el.getContext("2d").drawImage(canvas._element, 0, 0);
        return el.toDataURL(options);
      }
    };
    /**
     * fabric3.Canvas.prototype.getAbsoluteCoords to get Absolute Coords
     * of a canvas object
     *
     * @param {object} object -
     * @returns {object}
     */


    fabric3.Canvas.prototype.getAbsoluteCoords = function (object) {
      return {
        left: "".concat(object.left).concat(canvas._offset.left),
        top: "".concat(object.top).concat(canvas._offset.top)
      };
    };
    /**
     * fabric3.Canvas.prototype.centerObject to set center a canvas object
     *
     * @param {object} object -
     * @returns {object} this canvas
     */


    fabric3.Canvas.prototype.centerObject = function (object) {
      var center = canvas.getCenter();

      canvas._centerObject(object, new fabric3.Point(center.left / Math.pow(SCALE_FACTOR, canvas.zoomInCounter), center.top / Math.pow(SCALE_FACTOR, canvas.zoomInCounter)));

      watchCanvas();
      canvas.renderAll();
      return canvas;
    };
    /**
     * fabric3.Canvas.prototype.centerObjectH to set center a canvas object
     *
     * @param {object} object -
     * @returns {object} this canvas
     */


    fabric3.Canvas.prototype.centerObjectH = function (object) {
      var center = canvas.getCenter();

      canvas._centerObject(object, new fabric3.Point(center.left / Math.pow(SCALE_FACTOR, canvas.zoomInCounter), object.getCenterPoint().y));

      watchCanvas();
      canvas.renderAll();
      return canvas;
    };
    /**
     * fabric3.Canvas.prototype.centerObjectV to set center a canvas object
     *
     * @param {object} object -
     * @returns {object} this canvas
     */


    fabric3.Canvas.prototype.centerObjectV = function (object) {
      var center = canvas.getCenter();

      canvas._centerObject(object, new fabric3.Point(object.getCenterPoint().x, center.top / Math.pow(SCALE_FACTOR, canvas.zoomInCounter)));

      watchCanvas();
      canvas.renderAll();
      return canvas;
    };

    fabric3.Object.prototype.objectCaching = false;
    /**
     * watchCanvas to sorted objects in canvas
     * and launch $event with sorted objects
     */

    function watchCanvas() {
      var objects = [];
      setTimeout(function () {
        if (canvas.getObjects().length > 0) {
          objects = canvas.getObjects();
          objects.forEach(function (object) {
            var oldMask = angular.element(document).find("#" + object.id);
            oldMask.remove();

            if (isMaster && !isLocked) {
              fabricMask.createMask(canvas, SCALE_FACTOR, service.JSONExportProperties, object, service.render).then(function (mask) {
                return fabricMask.setMaskEventListener(canvas, SCALE_FACTOR, object, mask);
              });
            }
          });
        }

        $rootScope.$broadcast("fabric:watchCanvas", objects);
      }, 600);
    }
    /**
     * Capitalize to render param capitalize
     *
     * @param {any} string -
     * @returns {string} modified || null
     */


    function capitalize(string) {
      if (typeof string !== "string") {
        return null;
      }

      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    /**
     * getActiveStyle get active object to get his style
     *
     * @param {string} styleName -
     * @param {object} object - target object into canvas
     * @returns {string}
     */


    function getActiveStyle(styleName, object) {
      var finalObject = object || canvas.getActiveObject();

      if (_typeof(finalObject) !== "object" || finalObject === null) {
        return "";
      }

      return finalObject.getSelectionStyles && finalObject.isEditing ? finalObject.getSelectionStyles()[styleName] || "" : finalObject[styleName] || "";
    }
    /**
     * setActiveStyle get active object to set this style
     *
     * @param {string} styleName -
     * @param {string} value -
     * @param {object} object -
     */


    function setActiveStyle(styleName, value, object) {
      var finalObject = object || canvas.getActiveObject();

      if (finalObject.setSelectionStyles && finalObject.isEditing) {
        var style = {};
        style[styleName] = value;
        finalObject.setSelectionStyles(style);
      } else {
        finalObject[styleName] = value;
      }

      canvas.renderAll();
      service.render();
    }
    /**
     * getActiveProp
     *
     * @param {string} name -
     * @returns {string}
     */


    function getActiveProp(name) {
      var object = canvas.getActiveObject();
      return _typeof(object) === "object" && object !== null ? object[name] : "";
    }
    /**
     * b64toBlob convert bs64 to blob
     *
     * @param {object} b64Data -
     * @param {string} templateType -
     * @param {number} sliceSize -
     * @returns {Blob}
     */


    function b64toBlob(b64Data, templateType, sliceSize) {
      var finalTemplateType = templateType || "";
      var finalSliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += finalSliceSize) {
        var slice = byteCharacters.slice(offset, offset + finalSliceSize);
        var byteNumbers = new Array(slice.length);

        for (var index = 0; index < slice.length; index++) {
          byteNumbers[index] = slice.charCodeAt(index);
        }

        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, {
        type: finalTemplateType
      });
    }
    /**
     * firstWord
     *
     * @param {string} string -
     * @returns {string}
     */


    function firstWord(string) {
      if (!string) {
        return string;
      }

      return string.split(" ").shift();
    }
    /**
     * render objects to canvas
     */


    service.render = function () {
      var objects = canvas.getObjects();

      for (var index in objects) {
        if (Object.prototype.hasOwnProperty.call(objects, index)) {
          objects[index].setCoords();
        }
      }

      canvas.calcOffset();
      canvas.renderAll();
      service.renderCount++;
      watchCanvas();
    };
    /**
     * setCanvasBackgroundColor to set a canvas background color
     *
     * @param {string} color -
     */


    service.setCanvasBackgroundColor = function (color) {
      canvas.backgroundImage = null;
      service.canvasBackgroundColor = color;
      canvas.setBackgroundColor(color);
      service.render();
    };
    /**
     * setCanvasBackgroundImage to set a canvas background image
     *
     * @param {object} file -
     */


    service.setCanvasBackgroundImage = function (file) {
      var data = file.target.result;
      fabric3.Image.fromURL(data, function (img) {
        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          scaleX: canvas.width / img.width,
          scaleY: canvas.height / img.height
        });
      });
      watchCanvas();
    };
    /**
     * setResponsiveCover to set active object responsive full cover
     *
     * @param {object} object -
     */


    service.setResponsiveCover = function (object) {
      var image = object ? object : canvas.getActiveObject();
      var canvasIsWider = canvas.getWidth() > canvas.getHeight();
      var imageIsWider = image.width > image.height;
      var canvasIsCubic = canvas.getWidth() === canvas.getHeight();
      var imageIsCubic = image.width === image.height;

      if (canvasIsWider && imageIsWider || !canvasIsWider && imageIsWider) {
        image.scaleToWidth(canvas.getWidth());
      }

      if (!canvasIsWider && !imageIsWider || canvasIsWider && !imageIsWider) {
        image.scaleToHeight(canvas.getHeight());
      }

      if (imageIsCubic && !canvasIsWider) {
        image.scaleToWidth(canvas.getWidth());
      }

      if (imageIsCubic && canvasIsWider) {
        image.scaleToWidth(canvas.getHeight());
      }

      if (canvasIsCubic && imageIsCubic) {
        image.scaleToHeight(canvas.getHeight());
      }

      image.center();
      service.render();
    };
    /**
     * setBackgroundColor to set a backgroundColor
     * to the canvas object "model === "defaultBackground"
     *
     * @returns {Array}
     */


    service.setBackgroundColor = function () {
      var currentCanvas = angular.fromJson(service.getJSON()).objects;
      return currentCanvas.filter(function (obj) {
        return obj.model === "defaultBackground";
      });
    };
    /**
     * setCanvasSize to set a cavans width | height
     *
     * @param {string} width - the canvas width
     * @param {string} height - the canvas height
     * @param {Function} callback - The callback init at the end of programm
     */


    service.setCanvasSize = function (width, height, callback) {
      service.stopContinuousRendering();
      var initialCanvasScale = service.canvasScale;
      service.resetZoom();
      service.canvasWidth = width;
      service.canvasOriginalWidth = width;
      canvas.originalWidth = width;
      canvas.setWidth(width);
      service.canvasHeight = height;
      service.canvasOriginalHeight = height;
      canvas.originalHeight = height;
      canvas.setHeight(height);
      service.canvasScale = initialCanvasScale;
      service.render();
      service.setZoom();
      service.render();
      service.setZoom();

      if (callback) {
        callback();
      }
    };
    /**
     * deactivateAll to deactivate all object in canvas
     */


    service.deactivateAll = function () {
      canvas.discardActiveObject();
      service.unselectActiveObject();
      service.render();
    };
    /**
     * clearCanvas clear canvas  and setBackgroundColor
     *
     * @param {string} color -
     */


    service.clearCanvas = function (color) {
      var masks = angular.element(document).find(".mask");
      masks.remove();
      canvas.clear();
      canvas.setBackgroundColor(color);
      service.render();
    };
    /**
     * getText to get text value of target object
     *
     * @returns {object}
     */


    service.getText = function () {
      return getActiveProp("text");
    };
    /**
     * setText to set text value of target object
     *
     * @param {string} value -
     */


    service.setText = function (value) {
      setActiveStyle("text", value);
      service.render();
    };
    /**
     * getFontSize to get font size of target object
     *
     * @returns {string}
     */


    service.getFontSize = function () {
      return getActiveStyle("fontSize");
    };
    /**
     * setFontSize to set font size of target object
     *
     * @param {string} value -
     */


    service.setFontSize = function (value) {
      setActiveStyle("fontSize", parseInt(value, 10));
      service.render();
    };
    /**
     * getTextAlign to get text property "textAlign"
     *
     * @returns {string}
     */


    service.getTextAlign = function () {
      return capitalize(getActiveProp("textAlign"));
    };
    /**
     * setTextAlign to set text to lower case of taget object
     *
     * @param {string} value -
     */


    service.setTextAlign = function (value) {
      setActiveStyle("textAlign", value);
      service.render();
    };
    /**
     * getFontFamily to get font family of target object
     *
     * @returns {object}
     */


    service.getFontFamily = function () {
      return getActiveProp("fontFamily");
    };
    /**
     * setFontFamily to set font family to target object
     *
     * @param {string} value -
     */


    service.setFontFamily = function (value) {
      new FontFaceObserver(value).load().then(function () {
        canvas.getActiveObject().set("fontFamily", value);
        canvas.requestRenderAll();
        service.render();
      });
    };
    /**
     * getLineHeight to get line height of target object
     *
     * @returns {string}
     */


    service.getLineHeight = function () {
      return getActiveStyle("lineHeight");
    };
    /**
     * setLineHeight to set line height of target object
     *
     * @param {string} value -
     */


    service.setLineHeight = function (value) {
      setActiveStyle("lineHeight", parseFloat(value, 10));
      service.render();
    };
    /**
     * isBold to defined target object font weight to bold
     *
     * @returns {boolean}
     */


    service.isBold = function () {
      return getActiveStyle("fontWeight") === "bold";
    };
    /**
     * toggleBold to toggle target object font weight "italic"
     */


    service.toggleBold = function () {
      setActiveStyle("fontWeight", getActiveStyle("fontWeight") === "bold" ? "" : "bold");
      service.render();
    };
    /**
     * isItalic to defined target object active font style "italic"
     *
     * @returns {boolean}
     */


    service.isItalic = function () {
      return getActiveStyle("fontStyle") === "italic";
    };
    /**
     * toggleItalic toggle target object font style "italic"
     */


    service.toggleItalic = function () {
      setActiveStyle("fontStyle", getActiveStyle("fontStyle") === "italic" ? "" : "italic");
      service.render();
    };
    /**
     * isUnderline to defined target object active style "underline"
     *
     * @returns {boolean}
     */


    service.isUnderline = function () {
      return getActiveStyle("textDecoration").indexOf("underline") > -1;
    };
    /**
     * toggleUnderline toggle target object active style "underline"
     */


    service.toggleUnderline = function () {
      var value = service.isUnderline() ? getActiveStyle("textDecoration").replace("underline", "") : getActiveStyle("textDecoration") + "underline";
      setActiveStyle("textDecoration", value);
      service.render();
    };
    /**
     * isLinethrough to set target object active style "line-through"
     *
     * @returns {boolean}
     */


    service.isLinethrough = function () {
      return getActiveStyle("textDecoration").indexOf("line-through") > -1;
    };
    /**
     * toggleLinethrough toglle target object active style "line-through"
     */


    service.toggleLinethrough = function () {
      var value = service.isLinethrough() ? getActiveStyle("textDecoration").replace("line-through", "") : getActiveStyle("textDecoration") + " line-through";
      setActiveStyle("textDecoration", value);
      service.render();
    };
    /**
     * getOpacity to get opacity of target object
     *
     * @returns {string}
     */


    service.getOpacity = function () {
      return getActiveStyle("opacity");
    };
    /**
     * setOpacity to set opacity of target object
     *
     * @param {string} value -
     */


    service.setOpacity = function (value) {
      setActiveStyle("opacity", value);
    };
    /**
     * getFlipX to get flipx of target object
     *
     * @returns {object}
     */


    service.getFlipX = function () {
      return getActiveProp("flipX");
    };
    /**
     * setFlipX to set flipx to target object
     *
     * @param {string} value -
     */


    service.setFlipX = function (value) {
      canvas.getActiveObject().set("flipX", value);
      service.render();
    };
    /**
     * toggleFlipX toggle flipx
     */


    service.toggleFlipX = function () {
      var value = !service.getFlipX();
      service.setFlipX(value);
      service.render();
    };
    /**
     * center to make the target object into the center
     */


    service.center = function () {
      var activeObject = canvas.getActiveObject();

      if (activeObject) {
        activeObject.center();
        service.render();
      }
    };
    /**
     * centerH to make the target object into horizontal center
     */


    service.centerH = function () {
      var activeObject = canvas.getActiveObject();

      if (activeObject) {
        activeObject.centerH();
        service.updateActiveObjectOriginals();
        service.render();
      }
    };
    /**
     * centerV to make the target object into vertical center
     */


    service.centerV = function () {
      var activeObject = canvas.getActiveObject();

      if (activeObject) {
        activeObject.centerV();
        service.updateActiveObjectOriginals();
        service.render();
      }
    };
    /**
     * sendBackwards to make target object into backwards
     */


    service.sendBackwards = function () {
      var activeObject = canvas.getActiveObject();

      if (activeObject) {
        canvas.sendBackwards(activeObject);
        service.render();
      }
    };
    /**
     * sendToBack to make target object into back
     */


    service.sendToBack = function () {
      var activeObject = canvas.getActiveObject();

      if (activeObject) {
        canvas.sendToBack(activeObject);
        service.render();
      }
    };
    /**
     * bringForward to make target object into forward
     */


    service.bringForward = function () {
      var activeObject = canvas.getActiveObject();

      if (activeObject) {
        canvas.bringForward(activeObject);
        service.render();
      }
    };
    /**
     * bringToFront to make target object into front
     */


    service.bringToFront = function () {
      var activeObject = canvas.getActiveObject();

      if (activeObject) {
        canvas.bringToFront(activeObject);
        service.render();
      }
    };
    /**
     * getFill to get fill of target object
     *
     * @returns {object}
     */


    service.getFill = function () {
      return getActiveStyle("fill");
    };
    /**
     * setFill to set fill of target object
     *
     * @param {string} value - of fill
     */


    service.setFill = function (value) {
      canvas.getActiveObject().set({
        fill: value
      });
      service.render();
    };
    /**
     * setFillPath to set fill path of target
     *
     * @param {object} object - object
     * @param {string} value - value
     */


    service.setFillPath = function (object, value) {
      if (object.isSameColor && object.isSameColor() || !object.paths) {
        object.setFill(value);
      } else if (object.paths) {
        for (var index = 0; index < object.paths.length; index++) {
          object.paths[index].setFill(value);
        }
      }

      service.render();
    };
    /**
     * setObjectZoom to set zoom of the target object
     *
     * @param {object} object - to set zoom
     */


    service.setObjectZoom = function (object) {
      var scaleX = object.originalScaleX;
      var scaleY = object.originalScaleY;
      var left = object.originalLeft;
      var top = object.originalTop;
      var tempScaleX = scaleX * service.canvasScale;
      var tempScaleY = scaleY * service.canvasScale;
      var tempLeft = left * service.canvasScale;
      var tempTop = top * service.canvasScale;
      object.scaleX = tempScaleX;
      object.scaleY = tempScaleY;
      object.left = tempLeft;
      object.top = tempTop;
      object.setCoords();
    };
    /**
     * setCanvasZoom to set zoom of the canvas
     */


    service.setCanvasZoom = function () {
      var width = service.canvasOriginalWidth;
      var height = service.canvasOriginalHeight;
      var tempWidth = width * service.canvasScale;
      var tempHeight = height * service.canvasScale;
      canvas.setWidth(tempWidth);
      canvas.setHeight(tempHeight);
    };
    /**
     * Set zoom in of masks
     */


    service.setMasksZoomIn = function () {
      var objects = canvas.getObjects();

      for (var index in objects) {
        if (Object.prototype.hasOwnProperty.call(objects, index)) {
          var mask = document.getElementById(objects[index].id);
          mask.style.transform = "rotate(" + objects[index].angle + "deg)";
          mask.style.left = objects[index].left * SCALE_FACTOR + "px";
          mask.style.top = objects[index].top * SCALE_FACTOR + "px";
          mask.style.width = objects[index].width * objects[index].scaleX * SCALE_FACTOR + "px";
          mask.style.height = objects[index].height * objects[index].scaleY * SCALE_FACTOR + "px";
        }
      }
    };
    /**
     * Set zoom out of masks
     */


    service.setMasksZoomOut = function () {
      var objects = canvas.getObjects();

      for (var index in objects) {
        if (Object.prototype.hasOwnProperty.call(objects, index)) {
          var mask = document.getElementById(objects[index].id);
          mask.style.transform = "rotate(" + objects[index].angle + "deg)";
          mask.style.left = objects[index].left / SCALE_FACTOR + "px";
          mask.style.top = objects[index].top / SCALE_FACTOR + "px";
          mask.style.width = objects[index].width * objects[index].scaleX / SCALE_FACTOR + "px";
          mask.style.height = objects[index].height * objects[index].scaleY / SCALE_FACTOR + "px";
        }
      }
    };
    /**
     * setZoom to set zoom of all objects in canvas
     *
     * @param {number} value -
     */


    service.setZoom = function (value) {
      var objects = canvas.getObjects();

      for (var index in objects) {
        if (Object.prototype.hasOwnProperty.call(objects, index)) {
          objects[index].originalScaleX = objects[index].originalScaleX ? objects[index].originalScaleX : objects[index].scaleX;
          objects[index].originalScaleY = objects[index].originalScaleY ? objects[index].originalScaleY : objects[index].scaleY;
          objects[index].originalLeft = objects[index].originalLeft ? objects[index].originalLeft : objects[index].left;
          objects[index].originalTop = objects[index].originalTop ? objects[index].originalTop : objects[index].top;
          service.setObjectZoom(objects[index]);
          canvas.zoomToPoint(value);
        }
      }

      watchCanvas();
      service.setCanvasZoom();
      service.render();
    };
    /**
     * canvasGetHeight
     * to return canvas height
     *
     * @returns {number} canvas height
     */


    service.canvasGetHeight = function () {
      return canvas.getHeight();
    };
    /**
     * canvasGetWidth
     * to return canvas width
     *
     * @returns {number} canvas width
     */


    service.canvasGetWidth = function () {
      return canvas.getWidth();
    };
    /**
     * broadcastZoom
     * to broadcast fabric:zoom event
     *
     * @returns {object} canvas height && width
     */


    service.broadcastZoom = function () {
      var size = {
        height: service.canvasGetHeight(),
        width: service.canvasGetWidth()
      };
      $rootScope.$broadcast("fabric:zoom", size);
      return size;
    };
    /**
     * setDefaultSize
     * to set default height && width of image-builder to service
     *
     * @returns {object} canvas
     */


    service.setDefaultSize = function () {
      var imageBuilder = $("#image-builder");
      var imageBuilderHeight = imageBuilder.height();
      var imageBuilderWidth = imageBuilder.width();
      service.defaultHeight = imageBuilderHeight;
      service.defaultWidth = imageBuilderWidth;
      return canvas;
    };
    /**
     * centerCanvas
     * to center canvas into image-builder
     */


    service.centerCanvas = function () {
      var imageBuilder = $("#image-builder");
      var workSpace = $("#fabric-container");
      var height = workSpace.height();
      var width = workSpace.width();

      if (!service.workSpaceHeight && !service.workSpaceWidth) {
        service.workSpaceHeight = height;
        service.workSpaceWidth = width;
      }

      if (!service.scrollToLeft && !service.scrollToTop || service.workSpaceHeight !== height || service.workSpaceWidth !== width) {
        if (!service.scrollToTop || service.workSpaceHeight !== height) {
          service.workSpaceHeight = height;
          service.scrollToTop = 0 - (service.defaultHeight - service.workSpaceHeight) / 2 + 8.5;
        }

        if (!service.scrollToLeft || service.workSpaceWidth !== width) {
          service.workSpaceWidth = width;
          service.scrollToLeft = 0 - (service.defaultWidth - service.workSpaceWidth) / 2 + 8.5;
        }
      }

      if (service.defaultWidth > service.workSpaceWidth) {
        workSpace.css("left", (service.defaultWidth - service.workSpaceWidth) / 2 + 8.5 + "px");
        imageBuilder.scrollTop(service.scrollToTop);
      }

      if (service.defaultHeight > service.workSpaceHeight) {
        workSpace.css("top", (service.defaultHeight - service.workSpaceHeight) / 2 + 8.5 + "px");
        imageBuilder.scrollLeft(service.scrollToLeft);
      }

      if (service.defaultWidth < service.workSpaceWidth && service.defaultHeight < service.workSpaceHeight) {
        imageBuilder.scrollTop(service.scrollToTop);
        imageBuilder.scrollLeft(service.scrollToLeft);
      }
    };
    /**
     * zoomIn to zoom in of the canvas
     */


    service.zoomIn = function () {
      canvas.zoomInCounter++;
      canvas.zoomOutCounter--;
      var objects = canvas.getObjects();
      objects.forEach(function (object) {
        var oldMask = angular.element(document).find("#" + object.id);
        oldMask.remove();

        if (isMaster && !isLocked) {
          fabricMask.createMask(canvas, SCALE_FACTOR, service.JSONExportProperties, object, service.render).then(function (mask) {
            return fabricMask.setMaskEventListener(canvas, SCALE_FACTOR, object, mask);
          });
        }
      });
      canvas.setHeight(canvas.getHeight() * SCALE_FACTOR);
      canvas.setWidth(canvas.getWidth() * SCALE_FACTOR);
      service.broadcastZoom();
      canvas.setZoom(canvas.getZoom() * SCALE_FACTOR);
      service.render();
      setTimeout(service.centerCanvas(), 0);
    };
    /**
     * zoomOut to zoom out of the canvas
     */


    service.zoomOut = function () {
      canvas.zoomInCounter--;
      canvas.zoomOutCounter++;
      var objects = canvas.getObjects();
      objects.forEach(function (object) {
        var oldMask = angular.element(document).find("#" + object.id);
        oldMask.remove();

        if (isMaster && !isLocked) {
          fabricMask.createMask(canvas, SCALE_FACTOR, service.JSONExportProperties, object, service.render).then(function (mask) {
            return fabricMask.setMaskEventListener(canvas, SCALE_FACTOR, object, mask);
          });
        }
      });
      canvas.setHeight(canvas.getHeight() / SCALE_FACTOR);
      canvas.setWidth(canvas.getWidth() / SCALE_FACTOR);
      service.broadcastZoom();
      canvas.setZoom(canvas.getZoom() / SCALE_FACTOR);
      service.render();
      setTimeout(service.centerCanvas(), 0);
    };
    /**
     * resetZoom to reset zoom of the canvas
     */


    service.resetZoom = function () {
      canvas.zoomInCounter = 0;
      canvas.zoomOutCounter = 0;
      var objects = canvas.getObjects();
      objects.forEach(function (object) {
        var oldMask = angular.element(document).find("#" + object.id);
        oldMask.remove();

        if (isMaster && !isLocked) {
          fabricMask.createMask(canvas, SCALE_FACTOR, service.JSONExportProperties, object, service.render).then(function (mask) {
            return fabricMask.setMaskEventListener(canvas, SCALE_FACTOR, object, mask);
          });
        }
      });
      canvas.setHeight(canvas.getHeight() / canvas.getZoom());
      canvas.setWidth(canvas.getWidth() / canvas.getZoom());
      canvas.setZoom(1);
      service.render();
      getFabricCanvases().forEach(function (item) {
        item.css("left", 0);
        item.css("top", 0);
      });
    };
    /**
     * updateActiveObjectOriginals to update value of originals
     * positions and value of scale for the target object
     */


    service.updateActiveObjectOriginals = function () {
      var object = canvas.getActiveObject();

      if (object) {
        object.scaleX = object.scaleX / service.canvasScale;
        object.scaleY = object.scaleY / service.canvasScale;
        object.left = object.left / service.canvasScale;
        object.top = object.top / service.canvasScale;
      }
    };
    /**
     * toggleLockActiveObject toggle lock the target object
     *
     * @param {object} object -
     */


    service.toggleLockActiveObject = function (object) {
      if (object) {
        canvas.forEachObject(function (obj) {
          if (object.id === obj.id) {
            obj.lockMovementX = !obj.lockMovementX;
            obj.lockMovementY = !obj.lockMovementY;
            obj.lockScalingX = !obj.lockScalingX;
            obj.lockScalingY = !obj.lockScalingY;
            obj.lockUniScaling = !obj.lockUniScaling;
            obj.lockRotation = !obj.lockRotation;
            obj.lockObject = !obj.lockObject;
            obj.selectable = !obj.selectable;
            service.render();
            watchCanvas();
          }
        });
      }
    };
    /**
     * lockActiveObject toggle lock the target object
     *
     * @param {object} object -
     */


    service.lockActiveObject = function (object) {
      if (object) {
        canvas.forEachObject(function (obj) {
          if (object.id === obj.id) {
            obj.lockMovementX = true;
            obj.lockMovementY = true;
            obj.lockScalingX = true;
            obj.lockScalingY = true;
            obj.lockUniScaling = true;
            obj.lockRotation = true;
            obj.lockObject = true;
            obj.selectable = false;
            service.render();
            watchCanvas();
          }
        });
      }
    };
    /**
     * toggleLockActiveObject toggle lock the target object
     *
     * @param {boolean} isLocked -
     */


    service.lockObjects = function (isLocked) {
      canvas.forEachObject(function (object) {
        object.lockMovementX = isLocked;
        object.lockMovementY = isLocked;
        object.lockScalingX = isLocked;
        object.lockScalingY = isLocked;
        object.lockUniScaling = isLocked;
        object.lockRotation = isLocked;
        object.lockObject = isLocked;
        object.selectable = !isLocked;
        service.render();
        watchCanvas();
      });
    };
    /**
     * toggleVisible toggle visible the target object
     *
     * @param {object} object -
     */


    service.toggleVisible = function (object) {
      if (object) {
        canvas.forEachObject(function (obj) {
          if (object.id === obj.id) {
            obj.visible = !obj.visible;
            service.render();
            watchCanvas();
          }
        });
      }
    };
    /**
     * selectActiveObject to select target object
     * and have all value of this object
     *
     * @returns {object}
     */


    service.selectActiveObject = function () {
      var object = canvas.getActiveObject();
      $rootScope.$broadcast("activeObject", object);
      return object;
    };
    /**
     * unselectActiveObject to unselect active object
     */


    service.unselectActiveObject = function () {
      service.selectedObject = false;
      $rootScope.$broadcast("deactivateObject");
      service.render();
    };
    /**
     * deleteActiveObject to delete active object
     */


    service.deleteActiveObject = function () {
      var activeObject = canvas.getActiveObject();
      var activeObjects = canvas.getActiveObjects();
      var mask = angular.element(document).find("#" + activeObject.id);
      mask.remove();

      if (activeObject) {
        canvas.remove(activeObject);
        service.render();

        if (activeObject.name === "grouping_logo_placeholder") {
          angular.element(document.getElementById(activeObject.name)).show();
        }

        watchCanvas();
      } else if (activeObjects) {
        canvas.discardActiveGroup();
        activeObjects.forEach(function (object) {
          canvas.remove(object);
          service.render();
        });
        watchCanvas();
      }
    };
    /**
     * setDirty to set dirty status
     *
     * @param {boolean} isDirty -
     */


    service.setDirty = function (isDirty) {
      fabricDirtyStatus.setDirty(isDirty);
    };
    /**
     * isDirty to know if fabric was dorty
     *
     * @returns {boolean}
     */


    service.isDirty = function () {
      return fabricDirtyStatus.isDirty();
    };
    /**
     * setInitialized to set init of service
     *
     * @param {boolean} isInitialized -
     */


    service.setInitialized = function (isInitialized) {
      service.initialized = isInitialized;
    };
    /**
     * isInitialized to know if service was initialized
     *
     * @returns {boolean}
     */


    service.isInitialized = function () {
      return service.initialized;
    };
    /**
     * stopContinuousRendering to stop the continous rendering of the canvas
     */


    service.stopContinuousRendering = function () {
      $timeout.cancel(service.continuousRenderHandle);
      service.continuousRenderCounter = service.maxContinuousRenderLoops;
    };
    /**
     * startContinuousRendering to start the continous rendering of the canvas
     */


    service.startContinuousRendering = function () {
      service.continuousRenderCounter = 0;
      service.continuousRender();
    };
    /**
     * continuousRenderPrevents the "not fully rendered
     * up upon init for a few seconds" bug.
     */


    service.continuousRender = function () {
      if (service.userHasClickedCanvas || service.continuousRenderCounter > service.maxContinuousRenderLoops) {
        return;
      }

      service.continuousRenderHandle = $timeout(function () {
        service.setZoom();
        service.render();
        service.continuousRenderCounter++;
        service.continuousRender();
      }, service.continuousRenderTimeDelay);
    };
    /**
     * createId to create an if with
     * Math.floor(Math.random() * 10000)
     *
     * @returns {number} id generated
     */


    service.createId = function () {
      return Math.floor(Math.random() * 10000);
    };
    /**
     * disableEditing to disable editing
     * for each objects in canvas
     */


    service.disableEditing = function () {
      canvas.selection = false;
      canvas.forEachObject(function (object) {
        object.selectable = false;
      });
    };
    /**
     * enableEditing to enable editing
     * for each objects in canvas
     */


    service.enableEditing = function () {
      canvas.selection = true;
      canvas.forEachObject(function (object) {
        object.selectable = true;
      });
    };
    /**
     * setCanvasDefaults to set default
     * selection of canvas
     */


    service.setCanvasDefaults = function () {
      canvas.selection = service.canvasDefaults.selection;
    };
    /**
     * setWindowDefaults to set default properties of window
     */


    service.setWindowDefaults = function () {
      fabricWindow.Object.prototype.transparentCorners = service.windowDefaults.transparentCorners;
      fabricWindow.Object.prototype.rotatingPointOffset = service.windowDefaults.rotatingPointOffset;
      fabricWindow.Object.prototype.padding = service.windowDefaults.padding;
    };
    /**
     * selectTargetObject to set activeObject
     * to target object in params
     *
     * @param {object} object - object
     * @returns {object}
     */


    service.selectTargetObject = function (object) {
      canvas.getObjects().forEach(function (canvasObject) {
        if (canvasObject.id === object.id) {
          canvas.setActiveObject(canvasObject);
          service.render();
        }
      });
      return object;
    };
    /**
     * toogleLockTargetObj to toggle lock target
     * object property selectable
     *
     * @param {object} obj - object
     */


    service.toogleLockTargetObj = function (obj) {
      obj.selectable = !obj.selectable;
    };
    /**
     * deleteTargetObj to delete target object
     *
     * @param {object} object - object
     */


    service.deleteTargetObj = function (object) {
      var mask = angular.element(document).find("#" + object.id);
      mask.remove();
      canvas.getObjects().forEach(function (obj) {
        if (obj.id === object.id) {
          canvas.setActiveObject(obj);
          service.deleteActiveObject();
          service.render();
        }
      });
    };
    /**
     * startCanvasListeners all canvas listeners
     * selected | created | cleared | modified
     */


    service.startCanvasListeners = function () {
      canvas.on("object:selected", function () {
        service.stopContinuousRendering();
        $timeout(function () {
          service.selectActiveObject();
          service.setDirty(true);
        });
      });
      canvas.on("selection:created", function () {
        return service.stopContinuousRendering();
      });
      canvas.on("selection:cleared", function () {
        return $timeout(function () {
          return service.unselectActiveObject();
        });
      });
      canvas.on("after:render", function () {
        return canvas.calcOffset();
      });
      canvas.on("object:modified", function () {
        service.stopContinuousRendering();
        $timeout(function () {
          service.updateActiveObjectOriginals();
          service.setDirty(true);
        });
      });
    };
    /**
     * addObjectToCanvas to add object into canvas and init his
     * props : scale, position, bringToFront ...
     *
     * @param {object} object -
     */


    service.addObjectToCanvas = function (object) {
      object.originalScaleX = object.scaleX;
      object.originalScaleY = object.scaleY;
      object.originalLeft = object.left;
      object.originalTop = object.top;
      canvas.add(object);
      service.setObjectZoom(object);
      object.center();
      object.bringToFront();
      service.render();
    };
    /**
     * addObjectIntoCanvas to add object into canvas and init his
     * props : scale, position, bringToFront ...
     *
     * @param {object} object -
     */


    service.addObjectIntoCanvas = function (object) {
      object.originalScaleX = object.scaleX;
      object.originalScaleY = object.scaleY;
      object.originalLeft = object.left;
      object.originalTop = object.top;
      canvas.add(object);
      service.setObjectZoom(object);
      canvas.setActiveObject(object);
      service.render();
    };
    /**
     * addSimpleText add object with model = texts
     */


    service.addSimpleText = function () {
      var object = new fabricWindow.Text("Mon texte", service.textDefaults);
      object.id = service.createId();
      object.model = "texts";
      service.addObjectToCanvas(object);
      watchCanvas();
    };
    /**
     * addProductText add object with model = contents
     *
     * @param {object} product - The product to add
     *
     * @returns {number}
     */


    service.addProductText = function (product) {
      var myProduct = product.name ? new fabricWindow.Text(product.name, service.textDefaults) : new fabricWindow.Text("Produit", service.textDefaults);
      myProduct.id = service.createId();
      myProduct.product_id = product.id;
      myProduct.product_name = product.name ? firstWord(product.name) : "";
      myProduct.parentName = product.name ? firstWord(product.name) : "";
      myProduct.cip = product.meta ? product.meta.replace("CIP : ", "") : null;
      myProduct.id_7 = product.id_7 ? product.id_7 : null;
      myProduct.id_13 = product.id_13 ? product.id_13 : null;
      myProduct.model = "products";
      service.addObjectToCanvas(myProduct);
      watchCanvas();
    };
    /**
     * addProductPrice add object with model = prices
     *
     * @param {object} product - The product to add
     *
     * @returns {number}
     */


    service.addProductPrice = function (product) {
      var myProduct = product.text ? new fabricWindow.Text(product.text, service.textDefaults) : new fabricWindow.Text("0,00 €", service.textDefaults);
      myProduct.id = service.createId();
      myProduct.product_id = product.id;
      myProduct.product_name = product.name ? firstWord(product.name) : "";
      myProduct.parentName = product.name ? firstWord(product.name) : "";
      myProduct.cip = product.meta ? product.meta.replace("CIP : ", "") : null;
      myProduct.id_7 = product.id_7 ? product.id_7 : null;
      myProduct.id_13 = product.id_13 ? product.id_13 : null;
      myProduct.model = "prices";
      service.addObjectToCanvas(myProduct);
      watchCanvas();
    };
    /**
     * addImage to add image with url data into canvas
     *
     * @param {object} file - The file to add
     * @param {object} product - If file image is product
     *
     * @returns {number}
     */


    service.addImage = function (file, product) {
      if (file) {
        fabric3.Image.fromURL(file["import"], function (image) {
          for (var property in service.imageOptions) {
            if (Object.prototype.hasOwnProperty.call(service.imageOptions, property)) {
              image[property] = service.imageOptions[property];
            }
          }

          angular.extend(image, service.imageDefaults);
          image.set("id", service.createId());

          if (!product) {
            image.set("model", "images");
          } else {
            image.set("model", "productImages");
            image.set("product_id", product.id);
            image.set("product_name", product.name ? firstWord(product.name) : "");
            image.set("cip", product.meta ? product.meta.replace("CIP : ", "") : null);
            image.set("id_7", product.id_7 ? product.id_7 : null);
            image.set("id_13", product.id_13 ? product.id_13 : null);
          }

          image.set("type", "image");
          image.set("name", file.name);
          image.set("src", file["import"]);
          image.crossOrigin = "Anonymous";
          image.set("crossOrigin", "Anonymous");
          service.addObjectToCanvas(image);
          service.setResponsiveCover(image);
          service.selectTargetObject(image);
          image.bringToFront();
          watchCanvas();
        }, {
          crossOrigin: "Anonymous"
        });
      }
    };
    /**
     * addImage to add image with url data into canvas
     *
     * @param {object} image -
     */


    service.addImageBackground = function (image) {
      if (image) {
        fabric3.Image.fromURL(image["import"], function (img) {
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            scaleX: canvas.width / img.width,
            scaleY: canvas.height / img.height
          });
          watchCanvas();
        });
      }
    };
    /**
     * addTriangle to add triangle shape into the canvas
     */


    service.addTriangle = function () {
      var triangle = new fabricWindow.Triangle(angular.extend({
        type: "triangle",
        width: 50,
        height: 50,
        model: "shapes"
      }, service.shapeDefaults));
      triangle.id = service.createId();
      service.addObjectToCanvas(triangle);
      watchCanvas();
    };
    /**
     * addCircle to add circle shape into the canvas
     */


    service.addCircle = function () {
      var circle = new fabricWindow.Circle(angular.extend({
        type: "circle",
        width: 100,
        height: 100,
        radius: 50,
        startAngle: 0,
        endAngle: 6.283185307179586,
        model: "shapes"
      }, service.shapeDefaults));
      circle.id = service.createId();
      service.addObjectToCanvas(circle);
      watchCanvas();
    };
    /**
     * addRectangle to add rectangle shape into the canvas
     */


    service.addRectangle = function () {
      var rectangle = new fabricWindow.Rect(angular.extend({
        type: "rect",
        width: 50,
        height: 50,
        model: "shapes"
      }, service.shapeDefaults));
      rectangle.id = service.createId();
      service.addObjectToCanvas(rectangle);
      watchCanvas();
    };
    /**
     * addSvg to add svg url data into canvas
     *
     * @param {object} file -
     */


    service.addSvg = function (file) {
      fabric3.loadSVGFromURL(file["import"], function (objects, options) {
        var svg = new fabric3.util.groupSVGElements(objects, options);
        angular.extend(svg, service.svgDefaults);
        svg.set({
          id: service.createId()
        });
        svg.set("model", "svgs");
        svg.set("type", "group");
        svg.set("sourcePath", file.name);
        svg.set("src", file["import"]);
        canvas.add(svg);
        svg.center();
        svg.setCoords();
        service.render();
        canvas.discardActiveObject();
      });
      watchCanvas();
    };
    /**
     * getJSON to get all JSON Exported Properties
     *
     * @returns {object} format json of canvas exported properties
     */


    service.getJSON = function () {
      return JSON.stringify(canvas.toJSON(service.JSONExportProperties));
    };
    /**
     * loadJSON to load a json into canvas
     *
     * @param {object} json -
     */


    service.loadJSON = function (json) {
      canvas.loadFromJSON(json, function () {
        if (!service.editable) {
          service.disableEditing();
        }

        service.render();
      });
    };
    /**
     * getCanvasData to get data of the canvas
     *
     * @returns {string} dataUrl
     */


    service.getCanvasData = function () {
      return canvas.toDataURL();
    };
    /**
     * getCanvasBlob to get the canvas blob
     *
     * @returns {string} blobUrl
     */


    service.getCanvasPngBlob = function () {
      return URL.createObjectURL(b64toBlob(service.getCanvasData().replace("data:image/png;base64,", ""), "image/png"));
    };
    /**
     * getCanvasSvgBlob to get the blobUrl
     *
     * @returns {string} blobUrl
     */


    service.getCanvasSvgBlob = function () {
      return URL.createObjectURL(new Blob([canvas.toSVG()], {
        type: "image/svg+xml"
      }));
    };
    /**
     * exportPngBlob to get the blob
     *
     * @returns {string} blob
     */


    service.exportPngBlob = function () {
      service.render();
      return b64toBlob(service.getCanvasData().replace("data:image/png;base64,", ""), "image/png");
    };
    /**
     * exportBlob to get the blob
     *
     * @returns {string} blob
     */


    service.exportSvgBlob = function () {
      service.render();
      return new Blob([canvas.toSVG()], {
        type: "image/svg+xml"
      });
    };
    /**
     * exportBlob to get the blob
     *
     * @returns {string} blob
     */


    service.exportXmlBlob = function () {
      service.render();
      return new Blob([canvas.toSVG()], {
        type: "image/svg+xml"
      });
    };
    /**
     * startCanvasListeners to get all objects
     * without template init
     *
     * @returns {Array} array container all objects withou template
     */


    service.getObjsWithoutTemplate = function () {
      return canvas.getObjects() ? canvas.getObjects().filter(function (object) {
        return object.model !== "template";
      }) : {};
    };
    /**
     * generateDocument
     * with canvas specific object
     *
     * @param {object} myDocument -
     * @returns {object}
     */


    service.generateDocument = function (myDocument) {
      service.render();
      return {
        container: service.getMyFile(),
        isLocked: myDocument.myFile ? myDocument.myFile.isLocked : false,
        template: {
          background: canvas.backgroundColor,
          backgroundImage: canvas.backgroundImage
        }
      };
    };
    /**
     * getMyFile
     * get canvas objects to init object with
     * cip property and id_7 and id_13 and model
     *
     * @returns {Array}
     */


    service.getMyFile = function () {
      return angular.fromJson(service.getJSON()).objects;
    };
    /**
     * constructDocument to
     * be injected into the canvas
     *
     * @param {object} myDocument -
     */


    service.constructDocument = function (myDocument) {
      /**
       * Initialize contructor.
       *
       * @returns {object}
       */
      function initConstruct() {
        service.name = myDocument.format.name;
        service.description = myDocument.format.description;

        if (myDocument.myFile) {
          var documentJson = angular.toJson({
            objects: myDocument.myFile.container,
            background: myDocument.myFile.template.background ? myDocument.myFile.template.background : "",
            backgroundImage: myDocument.myFile.template.backgroundImage ? myDocument.myFile.template.backgroundImage : ""
          });
          var unselectableObjects = [];
          canvas.loadFromJSON(documentJson, canvas.renderAll.bind(canvas), function (item, object) {
            if (!object.id) {
              object.set({
                id: service.createId()
              });
            }

            if (!object.selectable) {
              unselectableObjects.push(object);
            }

            if (object.name === "grouping_logo_placeholder") {
              angular.element(document.getElementById(object.name)).hide();
            }
          });

          if (myDocument.myFile.template.background !== "") {
            service.setCanvasBackgroundColor(myDocument.myFile.template.background);
          }

          if (myDocument.myFile.template.backgroundImage !== "") {
            service.addImageBackground(myDocument.myFile.template.backgroundImage);
          }

          canvas.discardActiveObject();
          service.lockObjects(myDocument.myFile.isLocked);
          unselectableObjects.forEach(function (obj) {
            canvas.getObjects().forEach(function (object) {
              if (obj.id === object.id) {
                service.lockActiveObject(object);
              }
            });
          });
          watchCanvas();
          return myDocument;
        }

        watchCanvas();
      } // basic orientation


      if (myDocument.orientation.name === "portrait") {
        service.setCanvasSize(myDocument.format.width_pixel, myDocument.format.height_pixel, initConstruct);
      } // change orientation


      if (myDocument.orientation.name === "paysage") {
        service.setCanvasSize(myDocument.format.height_pixel, myDocument.format.width_pixel, initConstruct);
      }
    };
    /**
     * init to init canvas
     */


    service.init = function () {
      canvas = fabricCanvas.getCanvas();
      canvas.zoomInCounter = 0;
      canvas.zoomOutCounter = 0;
      canvas.on("selection:created", function (select) {
        if (select.selected) {
          select.selected.forEach(function (object) {
            var oldMask = angular.element(document).find("#" + object.id);
            oldMask.remove();
          });
        }

        service.unselectActiveObject();
        watchCanvas();
      });
      canvas.on("selection:updated", function () {
        $rootScope.$broadcast("activeObject", canvas.getActiveObject());
        watchCanvas();
      });
      service.canvasId = fabricCanvas.getCanvasId();
      canvas.clear();
      JSONObject = angular.fromJson(service.json);
      service.loadJSON(service.json);
      JSONObject = JSONObject || {};
      service.canvasScale = JSONObject.canvasScale || 1;
      service.setCanvasBackgroundColor(JSONObject.background);
      service.canvasOriginalWidth = JSONObject.width;
      service.canvasOriginalHeight = JSONObject.height;
      canvas.controlsAboveOverlay = true;
      service.render();
      service.setDirty(false);
      service.setInitialized(true);
      service.setCanvasDefaults();
      service.setWindowDefaults();
      service.startCanvasListeners();
      service.startContinuousRendering();
      fabricDirtyStatus.startListening();
      service.centerCanvas();
      service.render();
    };

    service.init();
    return service;
  };
}

angular.module("app.generator").factory("fabricLab", FabricLab);