"use strict";
/**
 * Patient pathology remove customer modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} customers - Customers.
 * @param {Function} callback - Callback when form submitted.
 * @param {object} patientPathologyService - The patient pathology service.
 * @param {object} notifyUserService - notifyUserService.
 */

PatientPathologyRemoveCustomerModalController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "customers", "callback", "patientPathologyService", "notifyUserService"];

function PatientPathologyRemoveCustomerModalController($scope, $state, $stateParams, $mdDialog, customers, callback, patientPathologyService, notifyUserService) {
  $scope.close = close;
  $scope.customers = customers;
  $scope.selected = [];
  $scope.detachSelectedCustomers = detachSelectedCustomers;
  $scope.query = {
    limit: 10,
    page: 1,
    total: customers.length
  };
  /**
   * Close the modal
   */

  function close() {
    $mdDialog.hide();
  }
  /**
   * Detach selected customers.
   */


  function detachSelectedCustomers() {
    var innerCustomers = customers.filter(function (customer) {
      return !$scope.selected.includes(customer);
    });
    patientPathologyService.syncCustomersForPathology($stateParams.pathologyId, innerCustomers).then(function () {
      notifyUserService.showSuccessToast("La fiche a été détachée.");
      callback();
      close();
    });
  }
}

angular.module("app.patients").controller("patientPathologyRemoveCustomerModalController", PatientPathologyRemoveCustomerModalController);