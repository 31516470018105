"use strict";
/**
 * Commercial offer wholesaler controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $mdToast - $mdToast.
 * @param {object} environmentService - Environment service.
 * @param {object} wholesalerService - Wholesaler service.
 * @param {object} sellinChallengeService - Sellin challenge service.
 * @param {object} sellinOfferService - Sellin offer service.
 */

CommercialOfferWholesalerController.$inject = ["$scope", "$stateParams", "$state", "$mdDialog", "$mdToast", "environmentService", "wholesalerService", "sellinChallengeService", "sellinOfferService"];

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr && (typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]); if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function CommercialOfferWholesalerController($scope, $stateParams, $state, $mdDialog, $mdToast, environmentService, wholesalerService, sellinChallengeService, sellinOfferService) {
  $scope.loading = true;
  $scope.wholesaler = null;
  $scope.wholesalerId = null;
  $scope.laboratoryId = null;
  $scope.hasGraphsFiltered = [];
  $scope.wholesalerProducts = [];
  $scope.stocks = [];
  $scope.sales = [];
  $scope.colors = ["#D21E03", "#BB4507", "#D27703", "#C88B05", "#0AD247", "#00BB87", "#0A65C8"];
  $scope.titleConfig = {
    title: {
      icon: "icon-basket",
      text: "Mon partenaire logisticien",
      back: {
        state: "app.commercialOffer",
        tooltip: "Retour à mes partenaires logisticiens"
      }
    }
  };
  var date = new Date();
  $scope.salesFilter = {
    fromDate: new Date(date.getFullYear(), date.getMonth(), 1),
    toDate: new Date()
  };
  $scope.exportConfig = {
    entityName: "WholesalerSale",
    fileType: "xlsx",
    fileName: "Ventes_logisticien_",
    isAllowedColumnChoice: true,
    defaultFilter: {
      startDate: $scope.salesFilter.fromDate,
      endDate: $scope.salesFilter.toDate
    }
  };
  $scope.groupsHealthcenterSearchConfig = {
    event: "groupsHealthcenterFilter",
    search: ["idOrgas", "group.id"],
    tag: "groupsHealthcenterFilter",
    autoLaunchEvent: true,
    showBadge: true,
    limits: {
      combined: true,
      groups: 1,
      pharmas: 1
    }
  };
  $scope.openTab = openTab;
  $scope.getCommercialOfferForWholesaler = getCommercialOfferForWholesaler;
  $scope.getProductsList = getProductsList;
  $scope.editProduct = editProduct;
  $scope.getSales = getSales;
  $scope.getSellinChallenges = getSellinChallenges;
  $scope.addSellinChallenge = addSellinChallenge;
  $scope.viewSellinChallenge = viewSellinChallenge;
  $scope.editSellinChallenge = editSellinChallenge;
  $scope.deleteSellinChallenge = deleteSellinChallenge;
  $scope.addSellinOffer = addSellinOffer;
  $scope.viewSellinOffer = viewSellinOffer;
  $scope.editSellinOffer = editSellinOffer;
  $scope.deleteSellinOffer = deleteSellinOffer;
  $scope.stepChangeNumberLine = stepChangeNumberLine;
  $scope.stepNext = stepNext;
  $scope.stepBack = stepBack;
  $scope.query = {
    order: "name",
    limit: 10,
    page: 1
  };
  $scope.tabs = [{
    id: 1,
    name: "Dashboard",
    method: getDashboard,
    slug: "dashboard",
    active: true,
    isActive: true
  }, {
    id: 2,
    name: "Liste des produits",
    method: getProductsList,
    slug: "product-list",
    isActive: true
  }, {
    id: 3,
    name: "Liste des ventes",
    method: getSales,
    slug: "product-sales",
    isActive: true
  }, {
    id: 4,
    name: "Challenges sell-in",
    method: getSellinChallenges,
    slug: "sellin-challenge",
    isActive: true
  }];
  loader();
  /**
   * Get Dashboard data
   *
   * @returns {Promise}
   */

  function getDashboard() {
    $scope.dashboardLoading = true;
    var params = {
      id: $scope.wholesalerId
    };
    return wholesalerService.getDashboard(params).then(function (dashboard) {
      $scope.dashboardData = dashboard;
      $scope.detailStocksGeneral = dashboard.needs;
      $scope.top10Healthcenter = _.values(dashboard.top10Healthcenter).slice(0, 10);
      $scope.top10Grouping = _.values(dashboard.top10Grouping).slice(0, 10);
      $scope.globalBoughtsThisYear = dashboard.globalBoughtsThisYear[0];
      $scope.globalBoughtsLastYear = dashboard.globalBoughtsLastYear[0];
      $scope.globalSalesThisYear = dashboard.globalSalesThisYear;
      $scope.globalSalesLastYear = dashboard.globalSalesLastYear;
      $scope.directPurchasesThisYear = dashboard.directPurchasesThisYear;
      $scope.directPurchasesLastYear = dashboard.directPurchasesLastYear;
      $scope.top10Sellin = dashboard.top10Growth.sellin.top;
      $scope.top10Sellout = dashboard.top10Growth.sellout.top;
      $scope.flop10Sellin = dashboard.top10Growth.sellin.flop;
      $scope.flop10Sellout = dashboard.top10Growth.sellout.flop;
      $scope.dashboardLoading = false;
      return dashboard;
    });
  }

  $scope.stepPosition = 0;
  $scope.stepMax = 0;
  $scope.stepNumber = {
    line: 10,
    list: [10, 20, 50, 100]
  };
  /**
   * Step change number line.
   */

  function stepChangeNumberLine() {
    $scope.needsByProducts.data = $scope.dataSaved.slice($scope.stepPosition, $scope.stepPosition + $scope.stepNumber.line);
  }
  /**
   * Step back.
   */


  function stepBack() {
    $scope.stepPosition = $scope.stepPosition - $scope.stepNumber.line;

    if ($scope.stepPosition < 0) {
      $scope.stepPosition = 0;
    }

    $scope.needsByProducts.data = $scope.dataSaved.slice($scope.stepPosition, $scope.stepPosition + $scope.stepNumber.line);
  }
  /**
   * Step next.
   */


  function stepNext() {
    $scope.stepPosition += $scope.stepNumber.line;

    if ($scope.stepPosition > $scope.stepMax) {
      $scope.stepPosition = $scope.stepMax;
    }

    $scope.needsByProducts.data = $scope.dataSaved.slice($scope.stepPosition, $scope.stepPosition + $scope.stepNumber.line);
  }
  /**
   * Loader.
   */


  function loader() {
    /**
     * To display block the first tab
     */
    $scope.tabs.forEach(function () {
      angular.element(document).find("#" + $scope.tabs[0].slug)[0].style.display = "block";
    });
    $scope.wholesalerId = $stateParams.id;
    $scope.exportConfig.defaultFilter.wholesalerId = $stateParams.id;
    environmentService.getEnvironment().then(function (access) {
      $scope.access = access;
      $scope.exportConfig.defaultFilter.laboratoryId = access.entityId;
    });
    getDashboard().then(function () {
      processDataForAmCharts();
    });
    getProductsList();
    getCommercialOfferForWholesaler();
  }
  /**
   * Process data for AmCharts.
   */


  function processDataForAmCharts() {
    var dataChartSerial = [];
    angular.forEach($scope.detailStocksGeneral, function (dataHc) {
      var name = dataHc.key;
      var id = dataHc.id.buckets[0].key;
      var sumOrderAmount = 0;
      var serialLine = {
        healthcenter: name,
        id: id,
        range1: 0,
        range1Pc: 0,
        range2: 0,
        range3: 0,
        range4: 0,
        range5: 0,
        range6: 0,
        range7: 0,
        products: []
      };
      angular.forEach(dataHc.range_days.buckets, function (dataRange) {
        var nbRange = dataRange.key + 1;
        var strRange = "range" + nbRange;

        if (nbRange < 4) {
          sumOrderAmount += dataRange.needs_amount.value;
          serialLine[strRange] = dataRange.needs_amount.value;
        } else {
          sumOrderAmount += dataRange.order_amount.value;
          serialLine[strRange] = dataRange.order_amount.value;
        }
      });

      if (sumOrderAmount !== 0) {
        serialLine.range1Pc = serialLine.range1 / sumOrderAmount * 100;
      }

      dataChartSerial.push(serialLine);
    });
    $scope.dataSaved = dataChartSerial;
    $scope.stepMax = dataChartSerial.length;
    $scope.needsByProducts = {
      type: "serial",
      theme: "light",
      data: dataChartSerial.slice($scope.stepPosition, $scope.stepPosition + $scope.stepNumber.line),
      thousandsSeparator: " ",
      percentPrecision: 0,
      precision: 0,
      startDuration: 0,
      rotate: true,
      angle: 30,
      depth3D: 10,
      mouseWheelScrollEnabled: true,
      listeners: [{
        event: "clickGraphItem",
        method: function method(obj) {
          if ($scope.laboClicked === null) {
            $scope.needsByProducts.data = obj.item.dataContext.products;
            $scope.laboClicked = obj.item;
            $scope.$apply();
          }
        }
      }],
      legend: {
        autoMargins: true,
        borderAlpha: 0.8,
        useGraphSettings: true,
        horizontalGap: 10,
        markerSize: 10,
        valueAlign: "left",
        valueWidth: 0,
        clickMarker: handleLegendClick,
        clickLabel: handleLegendClick
      },
      chartCursor: {
        enabled: true,
        avoidBalloonOverlapping: false,
        balloonPointerOrientation: "vertical",
        oneBalloonOnly: true
      },
      chartScrollbar: {
        enabled: false
      },
      valueAxes: [{
        stackType: "regular",
        gridColor: "#FFFFFF",
        axisAlpha: 0,
        gridAlpha: 0,
        labelsEnabled: true,
        position: "left",
        margin: 50
      }],
      graphs: [{
        hidden: $scope.hasGraphsFiltered.range1 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 1,
        title: "< 10 jours",
        type: "column",
        lineColor: $scope.colors[0],
        columnWidth: 0.70,
        valueField: "range1",
        id: "range1"
      }, {
        hidden: $scope.hasGraphsFiltered.range2 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: "Entre 10 et 20 jours",
        type: "column",
        lineColor: $scope.colors[1],
        columnWidth: 0.70,
        valueField: "range2",
        id: "range2"
      }, {
        hidden: $scope.hasGraphsFiltered.range3 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: "Entre 21 et 30 jours",
        type: "column",
        lineColor: $scope.colors[2],
        columnWidth: 0.70,
        valueField: "range3",
        id: "range3"
      }, {
        hidden: $scope.hasGraphsFiltered.range4 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: "Entre 31 et 60 jours",
        type: "column",
        lineColor: $scope.colors[3],
        columnWidth: 0.70,
        valueField: "range4",
        id: "range4"
      }, {
        hidden: $scope.hasGraphsFiltered.range5 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: "Entre 61 et 90 jours",
        type: "column",
        lineColor: $scope.colors[4],
        columnWidth: 0.70,
        valueField: "range5",
        id: "range5"
      }, {
        hidden: $scope.hasGraphsFiltered.range6 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: ">90 jours",
        type: "column",
        lineColor: $scope.colors[5],
        columnWidth: 0.70,
        valueField: "range6",
        id: "range6"
      }, {
        hidden: $scope.hasGraphsFiltered.range7 ? true : false,
        showHandOnHover: true,
        balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
        fillAlphas: 1,
        fontSize: 11,
        labelText: "[[value]] €",
        lineAlpha: 0.5,
        title: "Stocks dormant",
        type: "column",
        lineColor: $scope.colors[6],
        color: "white",
        columnWidth: 0.70,
        valueField: "range7",
        id: "range7"
      }],
      autoMargins: true,
      autoDisplay: true,
      categoryField: "healthcenter",
      categoryAxis: {
        gridPosition: "start",
        position: "left",
        parseDates: false,
        labelFunction: function labelFunction(valueText) {
          if (valueText.length > 30) {
            return valueText.substring(0, 30) + "...";
          }

          return valueText;
        },
        listeners: [{
          event: "rollOverItem",
          method: function method(event) {
            if ($scope.laboClicked === null) {
              event.target.setAttr("cursor", "pointer");
            }
          }
        }, {
          event: "rollOutItem",
          method: function method(event) {
            event.target.setAttr("cursor", "default");
          }
        }]
      },
      "export": {
        enabled: true
      }
    };
    $scope.loading = false;
  }
  /**
   * Open tab.
   *
   * @param {object} myTab - My tab.
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";
        tab.method();
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Get the commercial offer for a wholesaler
   *
   * @returns {Promise} The wholesaler
   */


  function getCommercialOfferForWholesaler() {
    var wholesaler = {
      id: $scope.wholesalerId
    };
    return wholesalerService.read(wholesaler).then(function (response) {
      $scope.wholesaler = response;
      $scope.titleConfig.title.text += " - " + response.name;
    });
  }
  /**
   * Get a product list with prices of the wholesaler for the laboratory
   *
   * @returns {Promise} The products
   */


  function getProductsList() {
    $scope.loading = true;
    var wholesaler = {
      id: $scope.wholesalerId
    };
    return wholesalerService.getProducts(wholesaler).then(function (response) {
      $scope.loading = false;
      $scope.wholesalerProducts = response;
    });
  }
  /**
   * Open the product modification form
   *
   * @param {number} productId - The product identifier
   */


  function editProduct(productId) {
    $state.go("app.laboratory-products.edit", {
      id: productId
    });
  }
  /**
   * Get a product sales of the wholesaler for the laboratory
   *
   * @returns {Promise} The sales
   */


  function getSales() {
    $scope.loading = true;
    var query = {
      id: $scope.wholesalerId,
      laboratoryId: $scope.access.entityId,
      startDate: $scope.salesFilter.fromDate,
      endDate: $scope.salesFilter.toDate
    };
    $scope.exportConfig.defaultFilter.startDate = $scope.salesFilter.fromDate;
    $scope.exportConfig.defaultFilter.endDate = $scope.salesFilter.toDate;
    return wholesalerService.getFilteredSales(query).then(function (response) {
      $scope.loading = false;
      $scope.wholesalerSales = response;
    });
  }
  /**
   * Get sellin challenges for this laboratory and wholesaler
   *
   * @returns {Promise} The sellin challenges
   */


  function getSellinChallenges() {
    $scope.loading = true;
    return sellinChallengeService.currents().then(function (response) {
      $scope.loading = false;
      $scope.currentsSellinChallenges = response;
      return response;
    });
  }
  /**
   * addSellinChallenge
   * to change state
   */


  function addSellinChallenge() {
    $state.go("app.sellinChallenge.new", {
      wholesalerId: $scope.wholesalerId
    });
  }
  /**
   * viewSellinChallenge
   * to change state with param id
   *
   * @param {object} sellinChallenge -
   */


  function viewSellinChallenge(sellinChallenge) {
    $state.go("app.sellinChallenge.detail", {
      id: sellinChallenge.id
    });
  }
  /**
   * editSellinChallenge
   *
   * @param {object} event -
   * @param {object} sellinChallenge -
   */


  function editSellinChallenge(event, sellinChallenge) {
    event.stopPropagation();
    $state.go("app.sellinChallenge.edit", {
      id: sellinChallenge.id
    });
  }
  /**
   * deleteSellinChallenge
   *
   * @param {object} event -
   * @param {object} sellinChallenge -
   */


  function deleteSellinChallenge(event, sellinChallenge) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain(e) de vouloir supprimer le challenge sell-in « " + sellinChallenge.name + " » ?").ariaLabel("suppression challenge").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      return sellinChallengeService["delete"](sellinChallenge);
    }).then(function () {
      $mdToast.show($mdToast.simple().textContent("Le challenge sell-in est supprimé").position("top right"));
    });
  }
  /**
   * addSellinOffer
   * to change state
   */


  function addSellinOffer() {
    $state.go("app.sellinOffer.new", {
      wholesalerId: $scope.wholesalerId
    });
  }
  /**
   * viewSellinOffer
   * to change state with param id
   *
   * @param {object} sellinOffer -
   */


  function viewSellinOffer(sellinOffer) {
    $state.go("app.sellinOffer.detail", {
      id: sellinOffer.id
    });
  }
  /**
   * publishSellinOffer
   *
   * @param {object} event -
   * @param {object} sellinOffer -
   */


  function editSellinOffer(event, sellinOffer) {
    event.stopPropagation();
    $state.go("app.sellinOffer.edit", {
      id: sellinOffer.id
    });
  }
  /**
   * Delete sellin offer.
   *
   * @param {object} event -
   * @param {object} sellinOffer -
   */


  function deleteSellinOffer(event, sellinOffer) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Êtes-vous certain de vouloir supprimer l'offre sell - in « " + sellinOffer.name + " » ? ").ariaLabel("suppression offre sell-in").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      return sellinOfferService["delete"](sellinOffer);
    }).then(function () {
      $mdToast.show($mdToast.simple().textContent("L'offre sell - in est supprimée").position("top right"));
    });
  }
  /**
   * Define function to handle legend clicks
   *
   * @param {object} graph - Graph.
   *
   * @returns {boolean}
   */


  function handleLegendClick(graph) {
    var chart = graph.chart;
    var reShowAll = false;

    if ($scope.hasGraphsFiltered[graph.id] === false) {
      for (var index = 0; index < chart.graphs.length; index++) {
        chart.showGraph(chart.graphs[index]);
        $scope.hasGraphsFiltered[chart.graphs[index].id] = false;
      }

      reShowAll = true;
      $scope.hasGraphsFiltered = [];
    }

    if (reShowAll === false) {
      for (var _index = 0; _index < chart.graphs.length; _index++) {
        if (graph.id === chart.graphs[_index].id) {
          chart.showGraph(chart.graphs[_index]);
          $scope.hasGraphsFiltered[chart.graphs[_index].id] = false;
        } else {
          chart.hideGraph(chart.graphs[_index]);
          $scope.hasGraphsFiltered[chart.graphs[_index].id] = true;
        }
      }
    }

    $scope.dataChartSerial.sort(function (left, right) {
      return left[graph.valueField] - right[graph.valueField];
    });
    $scope.dataChartSerial.reverse();
    $scope.stepPosition = 0;
    $scope.stepMax = $scope.dataChartSerial.length; // return false so that default action is canceled

    return false;
  }
  /**
   * Get global boughts.
   *
   * @param {object} data - Data.
   *
   * @returns {Promise}
   */


  function getGlobalBoughts(data) {
    $scope.globalBoughtsLoading = true;
    var params = {
      id: $scope.wholesalerId
    };
    var getParams = [];

    for (var _i = 0, _Object$entries = Object.entries(data); _i < _Object$entries.length; _i++) {
      var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          key = _Object$entries$_i[0],
          value = _Object$entries$_i[1];

      if (value) {
        getParams[key] = value[0].id;
      }
    }

    return wholesalerService.getGlobalBoughts(params, getParams);
  }

  $scope.$on("groupsHealthcenterFilter", function (event, data) {
    getGlobalBoughts(data).then(function (dashboard) {
      $scope.globalBoughtsThisYear = dashboard.globalBoughtsThisYear[0];
      $scope.globalBoughtsLastYear = dashboard.globalBoughtsLastYear[0];
      $scope.globalSalesThisYear = dashboard.globalSalesThisYear;
      $scope.globalSalesLastYear = dashboard.globalSalesLastYear;
      $scope.globalBoughtsLoading = false;
    });
  });
}

angular.module("app.commercialOffer").controller("commercialOfferWholesalerController", CommercialOfferWholesalerController);