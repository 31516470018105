"use strict";
/**
 * Quote infos directive.
 *
 * @returns {object}
 */

function QuoteInfos() {
  return {
    restrict: "EA",
    transclude: true,
    controller: function controller() {// do nothing
    },
    templateUrl: "app/main/directives/quoteInfos/quoteInfos.html"
  };
}

angular.module("app.directives").directive("quoteInfos", QuoteInfos);