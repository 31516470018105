"use strict";
/**
 * Patients search configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

PatientsSearchConfig.$inject = ["$stateProvider"];

function PatientsSearchConfig($stateProvider) {
  $stateProvider.state("app.patients-search", {
    url: "/patients-search",
    views: {
      "content@app": {
        templateUrl: "app/main/patients-search/patients-search.html",
        controller: "patientSearchController"
      }
    }
  });
}

angular.module("app.patients-search", []).config(PatientsSearchConfig);