"use strict";
/**
 * Indexes graph directive.
 *
 * @returns {object}
 */

indexesGraphController.$inject = ["$scope", "$q"];

function IndexesGraph() {
  return {
    restrict: "E",
    scope: {
      datas: "@"
    },
    templateUrl: "app/main/directives/stats/indexesGraph/indexesGraphs.tmpl.html",
    controller: indexesGraphController
  };
}
/**
 * Indexes graph controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $q - $q.
 */


function indexesGraphController($scope, $q) {
  var lines = [];
  $scope.datas = JSON.parse($scope.datas);
  $scope.deferredChart = $q.defer();
  $scope.indexesGraphs = {
    type: "serial",
    theme: "light",
    data: $scope.deferredChart.promise,
    startDuration: 1,
    marginTop: 20,
    autoMarginOffset: 20,
    marginRight: 20,
    decimalSeparator: ",",
    thousandSeparator: " ",
    valueAxes: [{
      id: "g1",
      axisAlpha: 1,
      position: "left",
      gridAlpha: 0.05,
      labelFunction: function labelFunction(value) {
        return value;
      }
    }],
    graphs: [{
      id: "g1",
      fillAlphas: 0.9,
      lineAlpha: 0.2,
      valueField: "value",
      valueAxis: "value",
      title: "Index",
      type: "column",
      fillColorsField: "color",
      topRadius: 1,
      balloon: {
        drop: false,
        adjustBorderColor: true,
        color: "#212121"
      },
      balloonFunction: function balloonFunction(graphDataItem) {
        return graphDataItem.values.value + " docs";
      }
    }],
    depth3D: 30,
    angle: 30,
    chartCursor: {
      cursorColor: "#888888",
      cursorAlpha: 0.5,
      cursorPosition: "mouse",
      fullWidth: false
    },
    categoryField: "name",
    categoryAxis: {
      gridColor: "#F2F2F2",
      gridAlpha: 0.3,
      parseDates: false,
      minorGridAlpha: 0.1,
      minorGridEnabled: true,
      axisAlpha: 0,
      tickLength: 0,
      minPeriod: "mm",
      labelRotation: 45
    },
    "export": {
      enabled: false
    }
  };
  angular.forEach($scope.datas, function (data) {
    var color = "#0D8ECF";

    if (data.key.search(/sales_/i) === 0) {
      color = "#B0DE09";
    }

    if (data.key.search(/stocks_/i) === 0) {
      color = "#FCD202";
    } // eslint-disable-next-line no-invalid-this


    this.push({
      color: color,
      name: data.key,
      value: data.doc_count
    });
  }, lines);
  $scope.deferredChart.resolve(lines);
}

angular.module("app.directives").directive("indexesGraph", IndexesGraph);