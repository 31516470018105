"use strict";
/**
 * Apodis container title directive.
 *
 * @returns {object}
 */

ApodisContainerTitleController.$inject = ["$rootScope", "$scope"];

function ApodisContainerTitle() {
  return {
    restrict: "E",
    scope: {
      config: "=config"
    },
    templateUrl: "app/main/directives/apodis-container-title/apodisContainerTitle.html",
    transclude: true,
    controller: ApodisContainerTitleController
  };
}
/**
 * Apodis container title controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 */


function ApodisContainerTitleController($rootScope, $scope) {
  /**
   * Declaration of public methods
   */
  $scope.sendEvent = sendEvent;
  $scope.isList = isList;
  $scope.isTile = isTile;
  /**
   * To determinate if $scope.config.button.value is a list
   * To set active DOM element with ng-class directive
   *
   * @returns {boolean}
   */

  function isList() {
    return $scope.config.button.value === "list";
  }
  /**
   * To determinate if $scope.config.button.value is a tile
   * To set active DOM element with ng-class directive
   *
   * @returns {boolean}
   */


  function isTile() {
    return $scope.config.button.value === "tile";
  }
  /**
   * Return $scope.config with $broadcast.
   *
   * @param {object} event - jQlite $event
   */


  function sendEvent(event) {
    event.preventDefault();
    var elementValue = angular.element(event.target).attr("data-value");
    $scope.config.button.value = elementValue;
    $rootScope.$broadcast("apodis-container-title:event", $scope.config);
  }
}

angular.module("app.directives").directive("apodisContainerTitle", ApodisContainerTitle);