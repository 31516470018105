"use strict";
/**
 * Prestation listing controller.
 *
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $scope - The angular $scope object.
 * @param {string} apiurl - API URL.
 * @param {object} prestationService - Prestation service.
 * @param {object} $compile - The angular $compile object.
 * @param {object} $mdDialog - The angular $mdDialog object.
 */

PrestationListingController.$inject = ["$state", "$stateParams", "$scope", "apiurl", "prestationService", "$compile", "$mdDialog"];

function PrestationListingController($state, $stateParams, $scope, apiurl, prestationService, $compile, $mdDialog) {
  $scope.loading = false;
  $scope.photos = [];
  $scope.photo = [];
  $scope.participant = [];
  $scope.refusedMembers = [];
  $scope.acceptedMembers = [];
  $scope.pendingMembers = [];
  $scope.apiurl = apiurl;
  $scope.token = window.sessionStorage.token;
  $scope.imageFallback = "assets/images/sellouts/default-challenge-1080x600.jpg";
  $scope.goToHealthcenterAudit = goToHealthcenterAudit;
  $scope.goToImagesDetail = goToImagesDetail;
  $scope.showModal = showModal;
  $scope.showRefusalReason = showRefusalReason;
  var pagination = {
    page: 1,
    limit: 5,
    label: {
      page: "Page :",
      rowsPerPage: "Lignes par page :",
      of: "sur"
    }
  };
  $scope.acceptedMembersPagination = Object.create(pagination);
  $scope.refusedMembersPagination = Object.create(pagination);
  $scope.pendingMembersPagination = Object.create(pagination);
  $scope.getAcceptedMembers = getAcceptedMembers;
  $scope.getRefusedMembers = getRefusedMembers;
  $scope.getPendingMembers = getPendingMembers;
  /**
   * Get all pictures of the prestation.
   */

  function getAllBenefitPhoto() {
    prestationService.read($stateParams.id).then(function (response) {
      var prestation = response.prestation;
      prestation.startDatetime = new Date(prestation.startDatetime);
      prestation.endDatetime = new Date(prestation.endDatetime);
      var today = new Date();
      var start = prestation.startDatetime;
      var end = prestation.endDatetime;

      if (today < start) {
        prestation.status = "À venir";
      } else if (today >= start && today <= end) {
        prestation.status = "En cours";
      } else if (today > end) {
        prestation.status = "Terminé";
      }

      $scope.prestation = prestation;
      return prestation;
    }).then(function (prestation) {
      prestationService.getAllBenefitPhotos(prestation.id).then(function (response) {
        var photosForMap = [];
        $scope.photos = [];
        $scope.photo = [];
        $scope.loading = true;
        $scope.allBenefitPhoto = response.photos;
        angular.forEach(response.photos, function (photo) {
          if (typeof $scope.photo[photo.organization.morphable_id] === "undefined") {
            $scope.photo[photo.organization.morphable_id] = 0;
          }

          if (typeof $scope.photos[photo.organization.morphable_id] === "undefined") {
            $scope.photos[photo.organization.morphable_id] = [];
          }

          if (typeof photosForMap[photo.organization.morphable_id] === "undefined") {
            photosForMap[photo.organization.morphable_id] = {
              entity: photo.organization.morphable
            };
            photosForMap[photo.organization.morphable_id].photos = [];
          }

          $scope.photos[photo.organization.morphable_id].push(photo);
          photosForMap[photo.organization.morphable_id].photos.push(photo);
        });
        $scope.markers = processMarkers(photosForMap);
      });
    }).then(getAcceptedMembers).then(getRefusedMembers).then(getPendingMembers);
  }
  /**
   * Get accepted members.
   *
   * @returns {Promise} Accepted members promise.
   */


  function getAcceptedMembers() {
    return prestationService.readAcceptedMembers($stateParams.id, $scope.acceptedMembersPagination).then(function (acceptedMembers) {
      $scope.prestation.members = acceptedMembers;
      $scope.prestation.acceptedMembers = acceptedMembers;
    });
  }
  /**
   * Get refused members.
   *
   * @returns {Promise} Refused members promise.
   */


  function getRefusedMembers() {
    return prestationService.readRefusedMembers($stateParams.id, $scope.refusedMembersPagination).then(function (refusedMembers) {
      $scope.prestation.members = _.union($scope.prestation.members, refusedMembers);
      $scope.prestation.refusedMembers = refusedMembers;
    });
  }
  /**
   * Get pending members.
   *
   * @returns {Promise} Pending members promise.
   */


  function getPendingMembers() {
    return prestationService.readPendingMembers($stateParams.id, $scope.pendingMembersPagination).then(function (pendingMembers) {
      $scope.prestation.members = _.union($scope.prestation.members, pendingMembers);
      $scope.prestation.pendingMembers = pendingMembers;
      $scope.statusTotal = {
        totalPicture: 0,
        totalValidate: 0,
        totalRefused: 0,
        totalPercent: 0,
        participantAccept: 0,
        participantPending: 0,
        participantRefused: 0,
        objectif: 0,
        objectifOk: 0,
        prestationAccept: 0,
        prestationRefused: 0
      }; // percentage widget prestation participant

      $scope.statusTotal.participantAcceptPercent = 0;
      $scope.statusTotal.participantPendingPercent = 0;
      $scope.statusTotal.participantRefusedPercent = 0;
      $scope.statusTotal.participantObjectif = 0;
      $scope.counterImage = {};
      $scope.totalMembers = $scope.prestation.members.length;
      angular.forEach($scope.prestation.members, function (member) {
        // member pending
        if (member.status === 0) {
          $scope.pendingMembers.push(member);
          $scope.statusTotal.participantPending++;
          $scope.statusTotal.participantPendingPercent = parseFloat($scope.statusTotal.participantPending / $scope.totalMembers * 100);
        } // member accept


        if (member.status === 1) {
          $scope.acceptedMembers.push(member);
          $scope.statusTotal.participantAccept += 1;
          $scope.statusTotal.participantAcceptPercent = parseFloat($scope.statusTotal.participantAccept / $scope.totalMembers * 100);
        } // member refused


        if (member.status === 2) {
          $scope.refusedMembers.push(member);
          $scope.statusTotal.participantRefused += 1;
          $scope.statusTotal.participantRefusedPercent = parseFloat($scope.statusTotal.participantRefused / $scope.totalMembers * 100);
        } // member objective


        if (member.entity) {
          $scope.counterImage[member.entity.id] = {
            validate: 0,
            refused: 0,
            total: 0,
            pending: 0,
            objPercent: 0
          };

          if (typeof $scope.counterImage[member.entity.id].total === "undefined") {
            $scope.photos[member.entity.id] = [];
          }
        }
      });

      switch (true) {
        case $scope.statusTotal.participantAcceptPercent > 100:
          $scope.statusTotal.participantAcceptPercent = 100;
          break;

        case $scope.statusTotal.participantRefusedPercent > 100:
          $scope.statusTotal.participantRefusedPercent = 100;
          break;

        case $scope.statusTotal.participantPendingPercent > 100:
          $scope.statusTotal.participantPendingPercent = 100;
          break;
      }

      $scope.statusTotal.objPercent = [];
      $scope.loading = false;
    });
  }

  $scope.selectedA = [];
  $scope.selectedR = [];
  $scope.selectedP = [];
  $scope.limitOptions = [5, 10, 15];
  $scope.queryA = {
    order: "name",
    limit: 5,
    page: 1
  };
  $scope.queryR = {
    order: "name",
    limit: 5,
    page: 1
  };
  $scope.queryP = {
    order: "name",
    limit: 5,
    page: 1
  };
  $scope.optionsA = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.optionsR = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.optionsP = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };

  $scope.goToList = function () {
    $state.go("app.prestation");
  };
  /**
   * Open a recommendation.
   *
   * @param {object} recommendation - The recommendation to open.
   */


  $scope.openRecommendation = function (recommendation) {
    window.open($scope.apiurl + "/filemanager/file/show?path=" + recommendation.file.path + "&token=" + recommendation.file.token);
  };
  /**
   * Go to prestation detail.
   *
   * @param {number} accessId - The id of the access.
   */


  $scope.benefitInfo = function (accessId) {
    if (accessId) {
      $state.go("app.prestation.benefitInfo", {
        id: $stateParams.id,
        picture: $scope.photos[accessId]
      });
    }
  };
  /**
   * Go to healthcenter audit.
   *
   * @param {object} entity - The entity object.
   */


  function goToHealthcenterAudit(entity) {
    $state.go("app.healthcenterAudit.detail.general", {
      id: entity.id
    });
  }
  /**
   * Go to image detail.
   *
   * @param {object} entity - The entity object.
   */


  function goToImagesDetail(entity) {
    $state.go("app.prestation.benefitInfo", {
      id: $stateParams.id,
      picture: $scope.photos[entity.id]
    });
  }
  /**
   * Initialization of the page.
   */


  function init() {
    initMap();
    getAllBenefitPhoto();
  }
  /**
   * Initialization of the map.
   */


  function initMap() {
    $scope.markers = [];
    var mapOptions = {
      zoom: 7,
      minZoom: 0,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_CENTER
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      addressControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP
      },
      fullscreenControl: true
    };
    $scope.map = new google.maps.Map(document.getElementById("prestaPhotoLocalization"), mapOptions);
  }
  /**
   * Handle response to make markers on map.
   *
   * @param {object} healthcenters - The healthcenters.
   *
   * @returns {object} The markers.
   */


  function processMarkers(healthcenters) {
    if ($scope.markers.length > 0) {
      angular.forEach($scope.markers, function (marker) {
        if (marker) {
          marker.setMap(null);
        }
      });
    }

    $scope.markers = [];
    $scope.bounds = new google.maps.LatLngBounds();
    var markers = [];
    healthcenters.forEach(function (healthcenter) {
      var latLng = new google.maps.LatLng(healthcenter.entity.address.latitude, healthcenter.entity.address.longitude);
      var marker = new google.maps.Marker({
        position: latLng
      });
      var infowindow = new google.maps.InfoWindow({});
      $scope.photosInSlider = [];
      healthcenter.photos.forEach(function (photo) {
        $scope.photosInSlider.push(apiurl + "/images/" + photo.path);
      });
      marker.addListener("click", function () {
        closeLastOpenedInfoWindow();
        var content = "<div style='height:150px;width: 150px;'>" + "<ui-carousel" + "        slides='photosInSlider'" + "        slides-to-show='1'" + "        slides-to-scroll='1'" + "        autoplay='true'" + "        autoplay-speed='3000'" + "        arrows='false'" + "        dots='true'>" + "        <carousel-item>" + "            <div style='height:100px;width: 150;'>" + "                <img style='height:100px;width: 150px;' src='{{ item }}' />" + "            </div>" + "        </carousel-item>" + "    </ui-carousel>" + "</div>";
        var compiled = $compile(content)($scope);
        $scope.$apply();
        infowindow.setContent(compiled[0]);
        infowindow.open($scope.map, marker);
        $scope.lastOpenedInfoWindow = infowindow;
      });
      marker.setMap($scope.map);
      markers.push(marker);
      $scope.bounds.extend(latLng);
    });
    $scope.map.fitBounds($scope.bounds);
    return markers;
  }
  /**
   * Close last opened information window.
   */


  function closeLastOpenedInfoWindow() {
    if ($scope.lastOpenedInfoWindow) {
      $scope.lastOpenedInfoWindow.close();
    }
  }
  /**
   * show list modal.
   *
   * @param {Array} list - The list to display.
   * @param {string} type - The type of the list.
   */


  function showModal(list, type) {
    var listHandled = [];
    var title = "";

    if (type === "product") {
      listHandled = list.map(function (product) {
        return {
          name: product.presentation.name,
          code: product.presentation.code
        };
      });
      title = "Liste des produits";
    }

    if (type === "healthcenter") {
      listHandled = list.map(function (healthcenter) {
        return {
          name: healthcenter.entity.name,
          code: healthcenter.entity.cip
        };
      });
      title = "Liste des cibles";
    }

    $mdDialog.show({
      controller: ["$mdDialog", "$scope", "listHandled", function controller($mdDialog, $scope, listHandled) {
        $scope.close = close;
        $scope.listHandled = listHandled;
        $scope.title = title;
        /**
         * Close the modal.
         */

        function close() {
          $mdDialog.hide();
        }
      }],
      templateUrl: "app/main/prestation/listing/dialog/listDetail.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        listHandled: listHandled,
        title: title
      }
    });
  }
  /**
   * show reason of refusal.
   *
   * @param {object} member - The member of the prestation.
   */


  function showRefusalReason(member) {
    $mdDialog.show({
      controller: ["$mdDialog", "$scope", "member", function controller($mdDialog, $scope, member) {
        $scope.close = close;
        $scope.entity = member.entity;
        $scope.memberRefusal = member.comments;
        /**
         * Close the modal.
         */

        function close() {
          $mdDialog.hide();
        }
      }],
      templateUrl: "app/main/prestation/listing/dialog/refusalReason.html",
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        member: member
      }
    });
  }

  init();
}

angular.module("app.prestation").controller("prestationListingController", PrestationListingController);