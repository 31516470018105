"use strict";
/**
 * Geoloc pharmas directive.
 *
 * @returns {object}
 */

GeolocPharmasController.$inject = ["$state", "$scope", "$timeout", "geolocPharmasService", "gmap", "$compile"];

function GeolocPharmasDirective() {
  return {
    restrict: "E",
    scope: {
      ids: "=",
      callback: "@"
    },
    templateUrl: "app/main/directives/geolocPharmas/geolocPharmas.tmpl.html",
    controller: GeolocPharmasController
  };
}
/**
 * Geoloc pharmas controller.
 *
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 * @param {object} $timeout - $timeout.
 * @param {object} geolocPharmasService - Geoloc pharmas service.
 * @param {object} gmap - Google map.
 * @param {object} $compile - $compile.
 */


function GeolocPharmasController($state, $scope, $timeout, geolocPharmasService, gmap, $compile) {
  $scope.id = "";
  $scope.coords = [];
  $scope.pharmas = [];
  $scope.showAuditHealthcenter = showAuditHealthcenter;

  if ($scope.ids.length > 1) {
    angular.forEach($scope.ids, function (id) {
      $scope.id += id;
      loadHcDetail(id);
    });
  } else {
    loadHcDetail($scope.ids);
  }
  /**
   * Load healthcenter detail.
   *
   * @param {number} id - ID.
   */


  function loadHcDetail(id) {
    geolocPharmasService.hCDetail(id).then(function (pharma) {
      $scope.pharmas.push(pharma);
      $scope.coords.push(new google.maps.LatLng({
        lat: pharma.healthcenter.address.latitude,
        lng: pharma.healthcenter.address.longitude
      }));

      if ($scope.coords.length === $scope.ids.length) {
        initMap();
      }
    });
  }
  /**
   * Initialize map.
   */


  function initMap() {
    $scope.markers = [];
    var myLatLng = {
      lat: 47.6468754,
      lng: 2.3242131
    };
    var mapOptions = {
      center: new google.maps.LatLng(myLatLng),
      zoom: 2,
      gestureHandling: "cooperative",
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      disableDefaultUI: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: google.maps.ControlPosition.LEFT_TOP
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM
      },
      fullscreenControl: false,
      styles: gmap
    };
    $timeout(function () {
      $scope.map = new google.maps.Map(document.getElementById("a_" + $scope.id), mapOptions);

      if ($scope.markers.length > 0) {
        angular.forEach($scope.markers, function (marker) {
          if (marker) {
            marker.setMap(null);
          }
        });
      }

      $scope.markers = [];
      $scope.bounds = new google.maps.LatLngBounds();
      angular.forEach($scope.coords, function (coords, idx) {
        var marker = new google.maps.Marker({
          position: coords,
          map: $scope.map
        });
        marker.setMap($scope.map);
        $scope.bounds.extend(coords);

        if ($scope.callback) {
          marker.addListener("click", function () {
            var infowindow = new google.maps.InfoWindow({}); // Get the pharmacy

            var pharma = $scope.pharmas[idx].healthcenter;
            var content = "<div><h2>" + pharma.name + " (" + pharma.cip + ")</h2>" + "<div class='pharmacyAddress'>" + pharma.address.streetLine1 + " " + pharma.address.postalCode + " " + pharma.address.cityName + "</div>" + "<md-button class='md-accent-bg centered' " + "ng-click='showAuditHealthcenter(\"" + pharma.id + "\")'>" + "Auditer cette pharmacie</md-button></div>";
            var compiled = $compile(content)($scope);
            $scope.$apply();
            infowindow.setContent(compiled[0]);
            infowindow.open($scope.map, marker);
          });
        }
      });
      $scope.map.fitBounds($scope.bounds); // eslint-disable-next-line no-new

      new google.maps.event.addListenerOnce($scope.map, "bounds_changed", function () {
        // eslint-disable-next-line no-invalid-this
        if (this.getZoom()) {
          // eslint-disable-next-line no-invalid-this
          this.setZoom(6);
        }
      });
    }, 0);
  }
  /**
   * Go to healthcenter audit.
   *
   * @param {number} id -
   */


  function showAuditHealthcenter(id) {
    $state.go("app.healthcenterAudit.detail.general", {
      id: id
    });
  }
}

angular.module("app.directives").directive("geolocPharmas", GeolocPharmasDirective);