"use strict";
/**
 * User laboratory main controller.
 *
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {string} apiurl - API URL.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} environmentService - Environment service.
 * @param {object} $stateParams - $stateParams.
 */

UserLaboratoryMainController.$inject = ["$state", "$scope", "$rootScope", "apiurl", "laboratoriesService", "environmentService", "$stateParams"];

function UserLaboratoryMainController($state, $scope, $rootScope, apiurl, laboratoriesService, environmentService, $stateParams) {
  /**
   * Declaration of public methods
   */
  $scope.goToEdit = goToEdit;
  $scope.saveFav = saveFav;
  $scope.goToBranding = goToBranding;
  /**
   * Declaration of public objects
   */

  $scope.apiurl = apiurl;
  $scope.laboBranding = [];
  $scope.wholesalerLaboratories = [];
  $scope.loading = true;
  $scope.showLaboratories = false;
  $scope.loadingLaboratories = {
    loading: true,
    loader: "Récupération des laboratoires administrables",
    empty: "Aucun laboratoire administrable"
  };
  $scope.queryLaboratories = {
    order: "-startDate",
    limit: 10,
    page: 1
  };
  $scope.limitOptions = [5, 10, 15];
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * Load each method inside
   */

  function loader() {
    getEnvironment();
  }
  /**
   * Get environment
   *
   * @returns {Promise} promise
   */


  function getEnvironment() {
    return environmentService.getEnvironment().then(function () {
      $scope.isGrouping = environmentService.isGrouping();
      return $scope.isGrouping ? getWholesalerLaboratories() : mapPartners();
    });
  }
  /**
   * Get laboratories
   */


  function getWholesalerLaboratories() {
    var query = {
      wholesalerId: $stateParams.wholesalerId
    };
    laboratoriesService.getWholesalerLaboratories(query).then(function (wholesalerLaboratories) {
      $scope.wholesalerLaboratories = wholesalerLaboratories;
      $scope.loadingLaboratories.loading = false;
    });
  }
  /**
   * Map partners
   */


  function mapPartners() {
    var partnersId = [{
      laboratoryId: 2904,
      name: "3M FRANCE"
    }, {
      laboratoryId: 37,
      name: "BOIRON"
    }, {
      laboratoryId: 3275,
      name: "B.S.A"
    }];
    $scope.partners = [];
    partnersId.forEach(setBranding);
  }
  /**
   * Set branding
   *
   * @param {object} partner - instance of partner
   */


  function setBranding(partner) {
    var query = {
      laboratoryId: partner.laboratoryId
    };
    laboratoriesService.readBranding(query).then(function (branding) {
      return $scope.partners.push(Object.assign(partner, branding));
    });
  }
  /**
   * Go to edit
   *
   * @param {object} laboratory - instance of laboratory
   */


  function goToEdit(laboratory) {
    var query = {
      wholesalerId: laboratory.wholesaler.id,
      wholesalerLaboratoryId: laboratory.id
    };
    $state.go("app.usersLaboratory.edit", query);
  }
  /**
   * Save wholesaler laboratory fav
   *
   * @param {object} laboratory - instance of laboratory
   */


  function saveFav(laboratory) {
    var query = {
      wholesalerId: laboratory.wholesaler.id,
      wholesalerLaboratoryId: laboratory.id,
      isFavorite: laboratory.isFavorite
    };
    laboratoriesService.updateWholesalerLaboratoryFav(query).then(function (wholesalerLaboratory) {
      var index = $scope.wholesalerLaboratories.findIndex(function (laboratory) {
        return laboratory.id === wholesalerLaboratory.id;
      });

      if (index !== -1) {
        $scope.wholesalerLaboratories[index].isFavorite = wholesalerLaboratory.isFavorite;
      } else {
        getWholesalerLaboratories();
      }
    });
  }
  /**
   * Go to branding
   *
   * @param {object} partner - instance of partner
   */


  function goToBranding(partner) {
    var query = {
      laboratoryId: parseFloat(partner)
    };
    laboratoriesService.readBrandingWithoutProducts(query).then(function (branding) {
      $rootScope.laboratoryBranding = branding;
      $state.go("app.usersLaboratory.branding", {
        laboratoryId: partner.laboratoryId
      });
    });
  }
}

angular.module("app.users-laboratory").controller("userLaboratoryMainController", UserLaboratoryMainController);