"use strict";
/**
 * Team manager configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

TeammanagerConfig.$inject = ["$stateProvider"];

function TeammanagerConfig($stateProvider) {
  $stateProvider.state("app.teammanager_members_pharma", {
    url: "/pharmacie/mon-equipe",
    views: {
      "content@app": {
        templateUrl: "app/main/teammanager/teammanager.html",
        controller: "TeamManagerController as vm"
      },
      "tabContent@app.teammanager_members_pharma": {
        templateUrl: "app/main/teammanager/members/members.html",
        controller: "MembersController as vm"
      }
    }
  });
}

angular.module("app.teammanager", []).config(TeammanagerConfig);