"use strict";
/**
 * Side navigation menu for healthcenters directive.
 *
 * @returns {object}
 */

SideNavMenuPharmaciesCtrl.$inject = ["$rootScope", "$scope", "$state", "$location", "bricksService", "environmentService", "authService"];

function SideNavMenuPharmacies() {
  return {
    restrict: "E",
    transclude: true,
    scope: {
      itemActive: "@itemActive"
    },
    templateUrl: "app/main/navigation/sideNavMenuPharmacies/view/sideNavMenuPharmacies.html",
    controller: SideNavMenuPharmaciesCtrl
  };
}
/**
 * Side navigation menu for healthcenters controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $location - Location.
 * @param {object} bricksService - Bricks service.
 * @param {object} environmentService - Environment service.
 * @param {object} authService - Auth service.
 */


function SideNavMenuPharmaciesCtrl($rootScope, $scope, $state, $location, bricksService, environmentService, authService) {
  $scope.toggleSideNav = toggleSideNav;
  $scope.goToMenu = goToMenu;
  $scope.goToSubMenu = goToSubMenu;
  var noLeftMenuStates = ["app.santesecure", "app.filemanager"];
  loader();
  /**
   * Loader.
   */

  function loader() {
    if (authService.isAuthenticated()) {
      loaderCurrentEnv();
    }
  }
  /**
   * Domain loader.
   */


  function loaderDomain() {
    var hostSplit = $location.$$host.split(".");
    var subdomain = hostSplit[0];
    $scope.imgUrl = "/assets/images/logos/logo_apodis_violet.png";

    if ($scope.currentEnv.accessPointType !== 1) {
      switch (subdomain) {
        case "dlsante":
          $scope.domainName = "dlsante";
          break;

        case "unipharm":
          $scope.domainName = "unipharm";
          break;

        case "reseau-pnp":
          $scope.domainName = "reseauPnp";
          break;

        case "pdapharma":
          $scope.domainName = "pdaPharma";
          break;

        case "simplypharma":
          $scope.domainName = "simplyPharma";
          break;

        default:
          $scope.domainName = "apodis";
      }

      $scope.imgUrl = "/assets/images/logos/" + $scope.domainName + ".png";
    } else {
      $scope.domainName = "apodis";
    }
  }
  /**
   * Current environment loader.
   *
   * @returns {Promise}
   */


  function loaderCurrentEnv() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentEnv = response;
      $scope.activeMenuEntity = "activeMenu" + response.accessPointType;
      $scope.isAdmin = response.role === "Administrator";
      $scope.isEmployee = response.accessPointType === 2 && response.rankId !== 1 && response.rankId !== 2;
      $scope.toState = $state.current.name;
      $scope.currentState = $state.current.name;
      $scope.itemActive = $state.current.name;
      loaderBricks();
      loaderDomain();
      return response;
    });
  }
  /**
   * Bricks loader.
   *
   * @returns {Promise}
   */


  function loaderBricks() {
    return bricksService.getBricks().then(function (response) {
      $scope.bricks = response;

      if ($scope.bricks.length > 0 && noLeftMenuStates.indexOf($scope.currentState) === -1 && !$rootScope.hasBricksMenus) {
        $rootScope.showBricksMenu = false;
        $rootScope.showLeftMenu = true;
      }

      setActive();
      return response;
    });
  }
  /**
   * Profile loader.
   *
   * @returns {Promise}
   */


  function loaderProfile() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentEnv = response;
      $scope.activeMenuEntity = "activeMenu" + response.accessPointType;
      return response;
    });
  }
  /**
   * Go to menu.
   *
   * @param {object} myMenu -
   */


  function goToMenu(myMenu) {
    myMenu.active = true;
    bricksService.setCurrentBrick(myMenu);
    $scope.bricks.forEach(function (brick) {
      brick.menus.forEach(function (menu) {
        menu.active = false;
      });

      if (brick.slug !== myMenu.slug) {
        brick.active = false;
      } else {
        myMenu.active = true;
      }
    });
    $state.go("app.dashboard", {
      brickSlug: myMenu.slug.replace(".", "_")
    });
  }
  /**
   * Go to submenu.
   *
   * @param {object} subMenu -
   */


  function goToSubMenu(subMenu) {
    $scope.bricks.forEach(function (brick) {
      brick.active = false;
      brick.menus.forEach(function (menu) {
        if (menu.state !== subMenu.state) {
          menu.active = false;
        } else {
          bricksService.setCurrentBrick(brick);
          subMenu.active = true;
        }
      });
    });
    $state.go(subMenu.state);
  }
  /**
   * Set active menu.
   */


  function setActive() {
    $scope.bricks.forEach(function (menus) {
      if (!_.isUndefined($state.params.brickSlug) && menus.slug === $state.params.brickSlug.replace("_", ".")) {
        menus.active = true;
      } else {
        menus.active = false;
        menus.menus.forEach(function (menu) {
          var _boolean = $scope.toState === menu.state || $scope.itemActive === menu.state;

          if (menu.state.split(".").length >= 2) {
            var length = menu.state.split(".").length;
            _boolean = menu.state.split(".")[length - 1] === $state.current.name.split(".")[length - 1];
          }

          menu.active = Boolean(_boolean);
        });
      }
    });
  }
  /**
   * Toggle side navigation.
   */


  function toggleSideNav() {
    $rootScope.showLeftMenu = !$rootScope.showLeftMenu;
  }

  $rootScope.$on("$stateChangeStart", function (event, toState) {
    if (toState.name !== "app.pages_auth_login") {
      loaderProfile();
    }

    $scope.toState = toState.name;
    $scope.itemActive = toState.name;
  });
  $rootScope.$on("$stateChangeSuccess", function (event, toState) {
    if (toState.name !== "app.pages_auth_login") {
      loaderProfile();
    }

    $scope.toState = toState.name;
    $scope.itemActive = toState.name;
  });
  $rootScope.$on("sideNavId", function (event, _boolean2) {
    $scope.showLeftMenu = _boolean2;
  });
}

angular.module("app.navigation").directive("sideNavMenuPharmacies", SideNavMenuPharmacies);