"use strict";
/**
 * Side nav menu directive.
 *
 * @returns {object}
 */

SideNavMenuCtrl.$inject = ["$rootScope", "$scope", "$state", "noRedirectStates", "sidenavMenuFactory", "$location", "bricksService", "environmentService", "laboratoriesService", "amplitudeService", "authService"];

function SideNavMenu() {
  return {
    restrict: "E",
    transclude: true,
    scope: {
      itemActive: "@itemActive"
    },
    templateUrl: "app/main/navigation/sideNavMenu/view/sideNavMenu.html",
    controller: SideNavMenuCtrl
  };
}
/**
 * Side nav menu controller.
 *
 * @param {object} $rootScope - The RootScope
 * @param {object} $scope - The Scope
 * @param {object} $state - The State
 * @param {object} noRedirectStates - The noRedirectStates
 * @param {object} sidenavMenuFactory - The sidenavMenuFactory
 * @param {object} $location - The Location
 * @param {object} bricksService - Bricks service.
 * @param {object} environmentService - The environment service.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} amplitudeService - The amplitude service.
 * @param {object} authService - Auth service.
 */


function SideNavMenuCtrl($rootScope, $scope, $state, noRedirectStates, sidenavMenuFactory, $location, bricksService, environmentService, laboratoriesService, amplitudeService, authService) {
  $scope.goTo = goTo;
  $scope.toggleSideNav = toggleSideNav;
  $scope.goHome = goHome;
  loader();
  /**
   * loader
   * to load each method inside
   */

  function loader() {
    if (authService.isAuthenticated()) {
      loaderCurrentEnv();
    }
  }
  /**
   * loaderCurrentEnv
   *
   * @returns {Promise} The promise
   */


  function loaderCurrentEnv() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentEnv = response;

      if (response.entity.wholesaler) {
        $scope.hasCapContract = typeof response.entity.wholesaler.id !== "undefined";
      }

      $scope.activeMenuEntity = "activeMenu" + response.accessPointType;
      $scope.isAdmin = response.role === "Administrator";
      $scope.isEmployee = response.accessPointType === 2 && response.rankId !== 1 && response.rankId !== 2;
      $scope.toState = $state.current.name;
      $scope.currentState = $state.current.name;
      $scope.itemActive = $state.current.name;
      loaderBricks();
      loaderDomain();
      return response;
    });
  }
  /**
   * loaderDomain
   */


  function loaderDomain() {
    var hostSplit = $location.$$host.split(".");
    var subdomain = hostSplit[0];
    $scope.imgUrl = "/assets/images/logos/logo_apodis_violet.png";

    switch (subdomain) {
      case "dlsante":
        $scope.domainName = "dlsante";
        break;

      case "unipharm":
        $scope.domainName = "unipharm";
        break;

      case "reseau-pnp":
        $scope.domainName = "reseauPnp";
        break;

      case "pdapharma":
        $scope.domainName = "pdaPharma";
        break;

      case "simplypharma":
        $scope.domainName = "simplyPharma";
        break;

      default:
        $scope.domainName = "apodisPharma";
    }

    $scope.imgUrl = "/assets/images/logos/" + $scope.domainName + ".png";
  }
  /**
   * loaderBricks
   *
   * @returns {Promise} The promise
   */


  function loaderBricks() {
    var menuCreated = false;
    return bricksService.getBricks().then(function (response) {
      $scope.bricks = response;

      if (response) {
        response.forEach(function (brick) {
          if (!_.isUndefined($state.params.brickSlug) && brick.slug === $state.params.brickSlug.replace("_", ".")) {
            menuCreated = true;
            createMenu(brick);
            bricksService.setCurrentBrick(brick);
          }

          brick.menus.forEach(function (menu) {
            if (menu && menu.state === $scope.currentState && $scope.currentState !== "app.dashboard") {
              menuCreated = true;
              createMenu(brick);
              bricksService.setCurrentBrick(brick);
            }
          });
        });

        if (!menuCreated && $scope.currentState !== "app.home" && noRedirectStates.indexOf($scope.currentState) === -1) {
          createMenu();
        }
      } else {
        $state.go("app.home");
      }

      return response;
    });
  }
  /**
   * loaderProfile
   *
   * @returns {Promise} The promise
   */


  function loaderProfile() {
    return environmentService.getEnvironment().then(function (response) {
      if (response) {
        $scope.currentEnv = response;
        $scope.activeMenuEntity = "activeMenu" + response.accessPointType;
      }

      return response;
    });
  }
  /**
   * showChilds
   *
   * @param {object} item - The item
   */


  function goTo(item) {
    item.active = true;
    $scope.menuItems.forEach(function (obj) {
      if (obj && obj.state !== item.state) {
        obj.active = false;
      }
    });
    bricksService.getCurrentBrick().then(function (brick) {
      if (item.state === "app.dashboard") {
        $state.go(item.state, {
          brickSlug: brick.slug.replace(".", "_")
        });
        amplitudeService.logEvent("dashboard clicked", {
          name: brick.name
        });
      } else if (item.state === "app.usersLaboratory") {
        var query = {
          wholesalerId: laboratoriesService.getWholesalerId()
        };
        $state.go(item.state, query);
      } else {
        $state.go(item.state);
        amplitudeService.logEvent("vertical menu clicked", {
          name: item.name,
          brick: brick.name
        });
      }
    });
  }
  /**
   * setActiveItem
   *
   * @param {object} menus - Menus
   */


  function setActiveItem(menus) {
    if (menus && menus.length > 0) {
      menus.forEach(function (menu) {
        var _boolean = $scope.toState === menu.state || $scope.itemActive === menu.state;

        if (menu.state.split(".").length >= 2) {
          var length = menu.state.split(".").length;
          _boolean = menu.state.split(".")[length - 1] === $state.current.name.split(".")[length - 1];
        }

        menu.active = Boolean(_boolean);
      });
    }
  }
  /**
   * createMenu
   *
   * @param {object} menus - Menus
   */


  function createMenu(menus) {
    if (menus) {
      $scope.menuItems = menus.menus;
      $scope.itemActive = menus.state;
      setActiveItem($scope.menuItems);
    } else {
      environmentService.getEnvironment().then(function () {
        return bricksService.getCurrentBrick();
      }).then(function (brick) {
        if (brick === null) {
          return bricksService.tryToFindCurrentBrick(true);
        }

        return brick;
      }).then(function (brick) {
        if (brick === null) {
          $state.go("app.home", {}, {
            reload: true
          });
        } else {
          $scope.currentBrick = brick;
          $scope.menuItems = brick.menus;
          $scope.itemActive = brick.state;
          setActiveItem($scope.menuItems);
        }
      });
    }

    $rootScope.$emit("openMenu");
  }
  /**
   *
   * toggleSideNav
   * $scope.showLeftMenu = !$showLeftMenu
   */


  function toggleSideNav() {
    $rootScope.showLeftMenu = !$rootScope.showLeftMenu;
  }
  /**
   *
   * goHome
   */


  function goHome() {
    $rootScope.$broadcast("goHome");
  }
  /**
   * $rootScope.$on
   * accessService:switchEnv
   * init loaderProfile
   */


  $rootScope.$on("EnvironmentService.switchEnvironment:done", function () {
    loaderProfile();
  });
  /**
   * $rootScope.$on
   * $stateChangeStart
   * init loaderProfile
   * set toState
   * set itemActive
   */

  $rootScope.$on("$stateChangeStart", function (event, toState) {
    if (toState.name !== "app.pages_auth_login") {
      loaderProfile();
    }

    $scope.toState = toState.name;
    $scope.itemActive = toState.name;
  });
  /**
   * $rootScope.$on
   * $stateChangeSuccess
   * init loaderProfile
   * set toState
   * set itemActive
   * setActiveItem
   */

  $rootScope.$on("$stateChangeSuccess", function (event, toState) {
    if (toState.name !== "app.pages_auth_login") {
      loaderProfile();
    }

    $scope.toState = toState.name;
    $scope.itemActive = toState.name;
    setActiveItem($scope.menuItems);
  });
  /**
   * $rootScope.$on
   * sideNavConstruct
   * createMenu
   */

  $rootScope.$on("sideNavConstruct", function (event, brick) {
    if (brick) {
      sidenavMenuFactory.getDashboard().then(function (response) {
        $scope.dashboardMenu = response;
        createMenu(brick);
        amplitudeService.logEvent("brick clicked", {
          name: brick.name
        });

        if (brick.hasDashboard) {
          $state.go("app.dashboard", {
            brickSlug: brick.slug.replace(".", "_")
          });
        } else {
          $state.go(brick.menus[0].state);
        }
      });
    }
  });
  /**
   * $rootScope.$on
   * sideNavConstruct
   * createMenu
   */

  $rootScope.$on("sideNavConstruct:with-menu", function (event, menu) {
    if (menu) {
      sidenavMenuFactory.getDashboard().then(function (response) {
        $rootScope.showBricksMenu = false;
        $rootScope.showLeftMenu = true;
        $scope.dashboardMenu = response;
        createMenu(menu.brick);

        if (menu.name === "Dashboard") {
          $state.go(menu.state, {
            brickSlug: menu.brick.slug.replace(".", "_")
          });
          return response;
        }

        if (menu.params) {
          $state.go(menu.state, menu.params);
        } else {
          $state.go(menu.state);
        }

        return response;
      });
    }
  });
  $rootScope.$on("sideNavId", function (event, _boolean2) {
    $scope.showLeftMenu = _boolean2;
  });
}

angular.module("app.navigation").directive("sideNavMenu", SideNavMenu);