"use strict";
/**
 * Dashboard pharmacies carousel controller.
 *
 * @param {object} authSponsorshipsService - Auth sponsorships service.
 * @param {object} $scope - $scope.
 * @param {object} homeSlideService - Home slide service.
 */

dashboardPharmaciesCarouselController.$inject = ["authSponsorshipsService", "$scope", "homeSlideService"];

function dashboardPharmaciesCarouselController(authSponsorshipsService, $scope, homeSlideService) {
  $scope.loading = true;
  authSponsorshipsService.getCode().then(function (response) {
    $scope.sliderTop = homeSlideService.sliderTop;
    $scope.sliderTop[0].sponsorShipCode = response.sponsorCode;
    $scope.loading = false;
  });
}

angular.module("app.dashboard").controller("dashboardPharmaciesCarouselController", dashboardPharmaciesCarouselController);