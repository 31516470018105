"use strict";
/**
 * The dosage factory.
 *
 * @returns {object}
 */

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function DosageFactory() {
  var factory = {};
  factory.dosageMapping = {
    0.75: "3/4",
    0.5: "1/2",
    0.25: "1/4",
    0.125: "1/8"
  };
  factory.dosageTimeMapping = {
    morning: {
      slug: "Matin",
      value: "09:00"
    },
    noon: {
      slug: "Midi",
      value: "12:00"
    },
    evening: {
      slug: "Soir",
      value: "19:00"
    },
    lying: {
      slug: "Coucher",
      value: "22:00"
    }
  };
  /**
   * Get a default dosage plan for a given product.
   *
   * @param {string} name - The product name.
   * @param {number} productId - The product id.
   * @param {number} timesLength - The time length.
   *
   * @returns {object}
   */

  factory.getDefaultDosage = function (name, productId) {
    var timesLength = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
    var dosageMomentKey = Object.keys(factory.dosageTimeMapping);
    return {
      name: name,
      product_id: productId,
      dosage_form_id: null,
      dosage_frequency_id: null,
      start_at: new Date(),
      end_at: null,
      times: new Array(timesLength).fill(0).map(function (value, index) {
        return {
          time: factory.dosageTimeMapping[dosageMomentKey[index]].value,
          dose: null
        };
      }),
      dosage_instruction_id: null,
      comment: ""
    };
  };
  /**
   * Load a given dosage.
   *
   * @param {object} dosage - The dosage.
   *
   * @returns {object}
   */


  factory.loadDosage = function (dosage) {
    var defaultModel = this.getDefaultDosage(dosage.product.name, dosage.product.id);
    defaultModel = _objectSpread(_objectSpread({}, defaultModel), {}, {
      id: dosage.id,
      dosage_form_id: dosage.form.id,
      dosage_frequency_id: dosage.frequency.id,
      start_at: dosage.start_at,
      end_at: dosage.end_at,
      times: dosage.times,
      dosage_instruction_id: dosage.instruction.id,
      comment: dosage.comment
    });
    return defaultModel;
  };

  return factory;
}

angular.module("app.patients.factories").factory("dosageFactory", DosageFactory);