"use strict";
/**
 * User laboratory branding branded space controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {string} apiurl - API URL.
 * @param {object} bricksService - Bricks service.
 * @param {object} environmentService - Environment service.
 * @param {object} userLaboratoryBrandingService - User laboratory branding service.
 * @param {object} realMargeService - Real marge service.
 * @param {object} statisticsService - Statistics service.
 * @param {object} brandingStatisticsService - Branding statistics service.
 * @param {object} shopLaboratoryService - Shop laboratory service.
 * @param {object} eventsService - Events service.
 * @param {object} contactsService - Contact service.
 */

UserLaboratoryBrandingBrandedSpaceController.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "apiurl", "bricksService", "environmentService", "userLaboratoryBrandingService", "realMargeService", "statisticsService", "brandingStatisticsService", "shopLaboratoryService", "eventsService", "contactsService"];

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function UserLaboratoryBrandingBrandedSpaceController($scope, $rootScope, $state, $stateParams, apiurl, bricksService, environmentService, userLaboratoryBrandingService, realMargeService, statisticsService, brandingStatisticsService, shopLaboratoryService, eventsService, contactsService) {
  $scope.goTo = goTo;
  $scope.goToEvent = goToEvent;
  $scope.goToState = goToState;
  /**
   * Declaration of public objects
   */

  $scope.apiurl = apiurl;
  $scope.loaderFranco = {
    loading: true,
    loader: "Récupération du franco en cours",
    empty: "Aucun franco pour ce laboratoire"
  };
  $scope.loaderSoldOut = {
    loading: true,
    loader: "Récupération de vos références en rupture",
    empty: "Aucune référence en rupture actuellement"
  };
  $scope.loaderReassortParameters = {
    loading: true,
    loader: "Récupération de vos paramètres de réassort",
    empty: "Aucun paramètre de réassort défini."
  };
  $scope.loaderNotificationParameters = {
    loading: true,
    loader: "Récupération de vos paramètres de notification",
    empty: "Aucun paramètre de notification défini."
  };
  $scope.loaderEvents = {
    loading: true,
    loader: "Récupération des événements en cours",
    empty: "Aucun événement en cours"
  };
  $scope.loaderBestSeller = {
    loading: true,
    loader: "Récupération de votre top produit",
    empty: "Top produit non disponible sur ce laboratoire"
  };
  $scope.loaderBestSellerNational = {
    loading: true,
    loader: "Récupération du top produit national",
    empty: "Top produit national non disponible sur ce laboratoire"
  };
  $scope.loaderTopOperator = {
    loading: true,
    loader: "Calcul du top opérateur en cours",
    empty: "Pas de top opérateur sur ce laboratoire"
  };
  $scope.loaderOrders = {
    loading: true,
    loader: "Récupération des commandes effectuées",
    empty: "Aucune commande à ce jour."
  };
  $scope.loaderChallenges = {
    loading: true,
    loader: "Récupération des challenges",
    empty: "Aucun challenge"
  };
  $scope.loaderCustomerSupport = {
    loading: true,
    loader: "Récupération des informations de contact",
    empty: "Aucune information de contact disponible"
  };
  $scope.loaderCalendar = {
    loading: true,
    loader: "Récupération du calendrier",
    empty: "Aucun calendrier disponible"
  };
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * Loader.
   */

  function loader() {
    getEnvironment().then(function () {
      getLaboratoryOrders();
      getReassortConfiguration();
      getBestSellerNational();
      getCustomerSupportContacts();
      getMostProfitableProduct();
      getMargin();
      getTopOperator();
      getEvents();
      getBestSeller();
    });
  }
  /**
   * Load current environment
   *
   * @returns {Promise}
   */


  function getEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.environment = response;
      return response;
    });
  }
  /**
   * Get orders.
   *
   * @returns {Promise}
   */


  function getLaboratoryOrders() {
    $scope.loaderOrders.loading = true;
    var query = {
      laboratoryId: $stateParams.laboratoryId
    };
    return shopLaboratoryService.getLaboratoryOrders(query).then(function (response) {
      var orders = [];
      response.forEach(function (order) {
        order.amountWithoutTaxes = 0;

        if (order.lines) {
          order.lines.forEach(function (line) {
            order.amountWithoutTaxes += line.totalDiscountedWithoutVat;
          });
        }

        orders.push(order);
      });
      $scope.orders = orders;
      $scope.loaderOrders.loading = false;
      return response;
    });
  }
  /**
   * Get reassort configuration.
   *
   * @returns {Promise}
   */


  function getReassortConfiguration() {
    $scope.loaderReassortParameters.loading = true;
    var query = {
      healthcenterId: $scope.environment.entityId,
      laboratoryId: $stateParams.laboratoryId
    };
    return userLaboratoryBrandingService.readReassortConfiguration(query).then(function (response) {
      $scope.reassortParameters = response;
      $scope.loaderReassortParameters.loading = false;
      return response;
    });
  }
  /**
   * Get real marge and calculate avg margin.
   *
   * @returns {Promise}
   */


  function getMargin() {
    $scope.loaderAvgMargin.loading = true;
    var startDate = new Date();
    var endDate = new Date();
    startDate.setDate(1);
    startDate.setMonth(0);
    var query = {
      endDatetime: endDate.toISOString().split("T")[0],
      must: {
        laboratory: [$stateParams.laboratoryId]
      },
      startDatetime: startDate.toISOString().split("T")[0]
    };
    return realMargeService.get(query).then(function (response) {
      var buyingPriceTotal = 0;
      var sellinPriceHT = 0;

      var _iterator = _createForOfIteratorHelper(response),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var product = _step.value;
          buyingPriceTotal += product.product.buyingPrice;
          sellinPriceHT += product.price_sell_HT;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      $scope.avgMargin = sellinPriceHT ? parseFloat(((buyingPriceTotal / sellinPriceHT - 1) * 100 * -1).toFixed(0)) : 0;
      $scope.loaderAvgMargin.loading = false;
      return response;
    });
  }
  /**
   * Get operators and loop to calculate top operator.
   *
   * @returns {Promise}
   */


  function getTopOperator() {
    $scope.loaderTopOperator.loading = true;
    $scope.topOperator = {};
    var query = {
      aggby: "item.operator.id",
      endDatetime: new Date().toISOString().split("T")[0],
      interval: "1M",
      must: {
        laboratory: [$stateParams.laboratoryId]
      },
      startDatetime: new Date().toISOString().split("T")[0]
    };
    return statisticsService.sales(query).then(function (response) {
      if (response.aggregations && response.aggregations.aggby.buckets) {
        $scope.operators = response.aggregations.aggby.buckets.map(function (operator) {
          return {
            name: operator.key || operator.key_id,
            value: operator.amount_incl_tax.value
          };
        });
        var sortedOperators = $scope.operators.sort(function (operatorA, operatorB) {
          return parseInt(operatorB.value) - parseInt(operatorA.value);
        });
        $scope.topOperator = sortedOperators[0];
      }

      $scope.loaderTopOperator.loading = false;
      return response;
    });
  }
  /**
   * Get events.
   *
   * @returns {Promise}
   */


  function getEvents() {
    $scope.loaderEvents.loading = true;
    var query = {
      type: "tv-ad,animation,emailing,radio-ad,press-ad,poster-ad",
      from: new Date().toISOString().split("T")[0],
      to: new Date("2050-12-31").toISOString().split("T")[0]
    };
    return eventsService.getEvents(query).then(function (response) {
      $scope.events = response;
      $scope.loaderEvents.loading = false;
      return response;
    });
  }
  /**
   * Get best seller product.
   *
   * @returns {Promise}
   */


  function getBestSeller() {
    $scope.loaderBestSeller.loading = true;
    var query = {
      laboratoryId: $stateParams.laboratoryId
    };
    return brandingStatisticsService.getTopProduct(query).then(function (response) {
      if (response.aggregations && response.aggregations.topProduct.buckets) {
        $scope.bestSeller = response.aggregations.topProduct.buckets[0];
      }

      $scope.loaderBestSeller.loading = false;
      return response;
    });
  }
  /**
   * Get best seller national product.
   *
   * @returns {Promise}
   */


  function getBestSellerNational() {
    $scope.loaderBestSellerNational.loading = true;
    var query = {
      national: true
    };
    return brandingStatisticsService.getTopNationalProduct(query).then(function (response) {
      if (response.aggregations && response.aggregations.topProductNational.buckets) {
        $scope.bestSellerNational = response.aggregations.topProductNational.buckets[0];
      }

      $scope.loaderBestSellerNational.loading = false;
      return response;
    });
  }
  /**
   * Get customer support infos.
   *
   * @returns {Promise}
   */


  function getCustomerSupportContacts() {
    $scope.loaderCustomerSupport.loading = true;
    var query = {
      organizationId: $stateParams.laboratoryId
    };
    return contactsService.getByHealthcenter(query).then(function (response) {
      $scope.contacts = response;
      $scope.loaderCustomerSupport.loading = false;
      return response;
    });
  }
  /**
   * Return most profitable product.
   *
   * @param {object} sales - Sales.
   *
   * @returns {{key: string, amount_excl_tax: {value: number}}}
   */


  function getMostProfitableProduct(sales) {
    var mostProfitableItem = {
      key: "n/c",
      amount_excl_tax: {
        value: 0
      }
    };
    var tmpNumber = 0;

    var _iterator2 = _createForOfIteratorHelper(sales.aggregations.aggby.buckets),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var elem = _step2.value;

        if (elem.amount_excl_tax && elem.amount_excl_tax.value >= tmpNumber) {
          tmpNumber = elem.amount_excl_tax.value;
          mostProfitableItem = elem;
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }

    return mostProfitableItem;
  }
  /**
   * Go to given state.
   *
   * @param {string} state - State.
   */


  function goToState(state) {
    $state.go(state);
  }
  /**
   * Go to brick state and switch brick.
   *
   * @param {object} brick - Brick.
   */


  function goTo(brick) {
    $rootScope.$broadcast("sideNavConstruct", brick);
    bricksService.setCurrentBrick(brick);
  }
  /**
   * Go to event details.
   *
   * @param {object} event - Event.
   */


  function goToEvent(event) {
    $state.go("app.calendar.detail", {
      id: event.id
    });
  }
}

angular.module("app.users-laboratory").controller("userLaboratoryBrandingBrandedSpaceController", UserLaboratoryBrandingBrandedSpaceController);