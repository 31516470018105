"use strict";
/**
 * Dashboard pharmacy wholesaler audit controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} wholesalerAuditService - The wholesaler audit service.
 */

DashboardPharmaciesWholesalerAuditController.$inject = ["$scope", "wholesalerAuditService"];

function DashboardPharmaciesWholesalerAuditController($scope, wholesalerAuditService) {
  loading();
  /**
   * The loading function.
   */

  function loading() {
    $scope.wholesalersOrdersLoading = true;
    wholesalerAuditService.getOrders().then(function (response) {
      $scope.wholesalersOrders = response.data;
      $scope.wholesalersOrdersLoading = false;
    });
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesWholesalerAuditController", DashboardPharmaciesWholesalerAuditController);