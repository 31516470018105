"use strict";
/**
 * Users prestation service.
 *
 * @param {object} notifyUserService - Notify user service.
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

UsersPrestationService.$inject = ["notifyUserService", "apiClient"];

function UsersPrestationService(notifyUserService, apiClient) {
  return {
    /**
     * Read a user prestation.
     *
     * @param {number} prestationId - Prestation ID.
     *
     * @returns {Promise}
     */
    read: function read(prestationId) {
      return apiClient.get("/user/me/prestation/".concat(prestationId), {
        includes: ["prestation.products", "prestation.organization.morphable"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.userPrestation;
      });
    },

    /**
     * Read a user prestation photo.
     *
     * @param {number} prestationId - Prestation ID.
     *
     * @returns {Promise}
     */
    readPhoto: function readPhoto(prestationId) {
      return apiClient.get("/user/me/prestation/".concat(prestationId, "/photo"), {
        includes: ["organization.morphable"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Filter user prestations.
     *
     * @param {object} parameters - The parameters for the filter.
     *
     * @returns {object} The promise object.
     */
    filter: function filter(parameters) {
      return apiClient.post("/user/me/prestation", {}, {
        includes: ["prestation.organization.morphable"],
        parameters: parameters,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.prestations;
      })["catch"](function (error) {
        notifyUserService.showSimpleToast("Les prestations n'ont pas été trouvées.", {
          color: "danger",
          hideDelay: 2000
        });
        return error;
      });
    },

    /**
     * Accept a user prestation.
     *
     * @param {object} event - The event object.
     * @param {object} prestation - The prestation to accept.
     *
     * @returns {object}
     */
    accept: function accept(event, prestation) {
      return apiClient.put("/user/me/prestation/".concat(prestation.id, "/accept"), prestation, {
        parameters: {
          comments: null,
          status: 1
        },
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      })["catch"](function (error) {
        notifyUserService.showSimpleToast("La prestation : « " + prestation.name + " » n'a pas pu être acceptée.", {
          color: "danger",
          hideDelay: 2000
        });
        return error;
      });
    },

    /**
     * Refuse a user prestation.
     *
     * @param {object} event - The event object.
     * @param {object} prestation - The prestation to refuse.
     *
     * @returns {object}
     */
    refuse: function refuse(event, prestation) {
      return apiClient.put("/user/me/prestation/".concat(prestation.id, "/refuse"), prestation, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      })["catch"](function (error) {
        notifyUserService.showSimpleToast("La prestation : « " + prestation.name + " » n'a pas pu être refusée.", {
          color: "danger",
          hideDelay: 2000
        });
        return error;
      });
    },

    /**
     * Get user prestations by organization.
     *
     * @param {number} organizationId - The organization id.
     *
     * @returns {object}
     */
    getUserPrestationsByOrganization: function getUserPrestationsByOrganization(organizationId) {
      return apiClient.get("/user-prestations/".concat(organizationId)).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.users-prestation").service("usersPrestationService", UsersPrestationService);