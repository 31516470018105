"use strict";
/**
 * Toolbar menu directive.
 *
 * @returns {object}
 */

toolBarMenuCtrl.$inject = ["$scope", "$rootScope", "$q", "$state", "$timeout", "$mdDialog", "msNavigationService", "authService", "accessService", "notificationCallbackService", "environmentService", "sessionStorageService", "apiurl", "laboratoriesService", "popupConfig"];

function toolBarMenu() {
  return {
    restrict: "E",
    transclude: true,
    templateUrl: "app/main/navigation/toolBarMenu/view/toolBarMenu.html",
    controller: toolBarMenuCtrl
  };
}
/**
 * Toolbar menu controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $q - $q.
 * @param {object} $state - $state.
 * @param {object} $timeout - $timeout.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} msNavigationService - MS navigation service.
 * @param {object} authService - Authentication service.
 * @param {object} accessService - Access service.
 * @param {object} notificationCallbackService - Notification callback service.
 * @param {object} environmentService - Environment service.
 * @param {object} sessionStorageService - Session storage service.
 * @param {string} apiurl - API URL.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} popupConfig - The pop up config.
 */


function toolBarMenuCtrl($scope, $rootScope, $q, $state, $timeout, $mdDialog, msNavigationService, authService, accessService, notificationCallbackService, environmentService, sessionStorageService, apiurl, laboratoriesService, popupConfig) {
  EnvironmentDialogController.$inject = ["$rootScope", "$scope", "$mdDialog", "$sce"];
  $scope.token = sessionStorageService.getToken();
  $scope.apiurl = apiurl;
  $scope.popupUrl = popupConfig.url;
  $rootScope.global = {
    search: ""
  };
  $scope.bodyEl = angular.element("body");
  $scope.userStatusOptions = [{
    title: "En Ligne",
    icon: "icon-checkbox-marked-circle",
    color: "#4CAF50"
  }, {
    title: "Absent",
    icon: "icon-clock",
    color: "#FFC107"
  }, {
    title: "Ne Pas Déranger",
    icon: "icon-minus-circle",
    color: "#F44336"
  }, {
    title: "Invisible",
    icon: "icon-checkbox-blank-circle-outline",
    color: "#BDBDBD"
  }, {
    title: "Hors-Ligne",
    icon: "icon-checkbox-blank-circle-outline",
    color: "#616161"
  }];
  $scope.NotificationCallbackService = notificationCallbackService;
  $scope.toggleSideNav = toggleSideNav;
  $scope.goHome = goHome;
  $scope.logout = logout;
  $scope.setUserStatus = setUserStatus;
  $scope.toggleHorizontalMobileMenu = toggleHorizontalMobileMenu;
  $scope.toggleMsNavigationFolded = toggleMsNavigationFolded;
  $scope.search = search;
  $scope.searchResultClick = searchResultClick;
  $scope.goToLab = goToLab;
  $scope.showEnvDialog = showEnvDialog;
  $scope.goToFilemanager = goToFilemanager;
  loader();
  /**
   * Loader.
   */

  function loader() {
    if (authService.isAuthenticated()) {
      loaderCurrentEnv();
    }
  }
  /**
   * Load current environment.
   *
   * @returns {Promise}
   */


  function loaderCurrentEnv() {
    return environmentService.getEnvironment().then(function (response) {
      var options = angular.fromJson(window.sessionStorage.getItem("payload")).options;
      $scope.currentEnv = response;
      $scope.environment = response;
      $scope.hasBricksMenus = options.indexOf("bricks-menus") > -1;
      $scope.user = response.user; // calculate birthDate

      $scope.isBirthday = typeof $scope.user.birthTime !== "undefined" ? moment().month() === moment($scope.user.birthTime).month() && moment().date() === moment($scope.user.birthTime).date() : false;
      $scope.years = moment().diff($scope.user.birthTime, "years", false);

      if ($scope.environment.rank) {
        $scope.welcomePhrase = getWelcomePhrase();
      }

      $scope.hcUnplugged = accessService.isHcUnplugged($scope.currentEnv.accessPointType, $scope.currentEnv.rankId);
      $scope.isHealthcenterOwner = response.accessPointType === 2 && (response.rankId === 1 || response.rankId === 2);
      return response;
    });
  }
  /**
   * Get welcome phrase.
   *
   * @returns {string}
   */


  function getWelcomePhrase() {
    var type = $scope.currentEnv.accessPointType;
    var word = "";

    switch (type) {
      case 1:
      case 3:
        word = "chez";
        break;

      case 2:
        word = "à la";
        break;
    }

    return $scope.environment.rank.title + " " + word + " " + $scope.environment.entity.name;
  }
  /**
   * Toggle sidenav
   */


  function toggleSideNav() {
    $rootScope.showLeftMenu = !$rootScope.showLeftMenu;
  }
  /**
   * Go home.
   */


  function goHome() {
    $rootScope.$broadcast("goHome");
  }
  /**
   * Set user status.
   *
   * @param {object} status -
   */


  function setUserStatus(status) {
    $scope.userStatus = status;
  }
  /**
   * Logout Function
   */


  function logout() {
    authService.logout();
  }
  /**
   * Toggle horizontal mobile menu
   */


  function toggleHorizontalMobileMenu() {
    $scope.bodyEl.toggleClass("ms-navigation-horizontal-mobile-menu-active");
  }
  /**
   * Toggle msNavigation folded
   */


  function toggleMsNavigationFolded() {
    msNavigationService.toggleFolded();
  }
  /**
   * Search action
   *
   * @param {object} query -
   * @returns {Promise}
   */


  function search(query) {
    var navigation = [];
    var flatNavigation = msNavigationService.getFlatNavigation();
    var deferred = $q.defer(); // Iterate through the navigation array and
    // make sure it doesn't have any groups or
    // none ui-sref items

    for (var x = 0; x < flatNavigation.length; x++) {
      if (flatNavigation[x].uisref) {
        navigation.push(flatNavigation[x]);
      }
    } // If there is a query, filter the navigation;
    // otherwise we will return the entire navigation
    // list. Not exactly a good thing to do but it's
    // for demo purposes.


    if (query) {
      navigation = navigation.filter(function (item) {
        return angular.lowercase(item.title).search(angular.lowercase(query)) > -1;
      });
    } // Fake service delay


    $timeout(function () {
      deferred.resolve(navigation);
    }, 1000);
    return deferred.promise;
  }
  /**
   * Search result click action
   *
   * @param {object} item -
   */


  function searchResultClick(item) {
    // If item has a link
    if (item.uisref) {
      // If there are state params,
      // use them...
      if (item.stateParams) {
        $state.go(item.state, item.stateParams);
      } else {
        $state.go(item.state);
      }
    }
  }
  /**
   * Go to lab.
   */


  function goToLab() {
    var query = {
      wholesalerId: laboratoriesService.getWholesalerId()
    };
    $state.go("app.usersLaboratory", query);
  }
  /**
   * Go to file manager.
   */


  function goToFilemanager() {
    $state.go("app.file-manager");
  }
  /**
   * Show environment dialog.
   *
   * @param {object} event -
   */


  function showEnvDialog(event) {
    $mdDialog.show({
      controller: EnvironmentDialogController,
      templateUrl: "app/main/navigation/environment/views/env-dialog.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true
    });
  }
  /**
   * Environment dialog controller.
   *
   * @param {object} $rootScope - $rootScope.
   * @param {object} $scope - $scope.
   * @param {object} $mdDialog - $mdDialog.
   * @param {object} $sce - $sce.
   */


  function EnvironmentDialogController($rootScope, $scope, $mdDialog, $sce) {
    var myAccess = {
      accessPoints: null,
      currentEnv: null,
      themes: {
        2: {
          icon: "icon-hospital",
          name: "teal"
        },
        3: {
          icon: "icon-people",
          name: "pink"
        },
        1: {
          icon: "icon-hospital",
          name: "default"
        },
        4: {
          icon: "icon-key",
          name: "default"
        },
        5: {
          icon: "icon-cart-outline",
          name: "default"
        },
        6: {
          icon: "icon-account-multiple-outline",
          name: "default"
        }
      },
      adminRankId: [1, 2, 4, 5]
    };
    $scope.searchPharma = {};
    $scope.switchActiveEnv = switchActiveEnv;
    $scope.closeDialog = closeDialog;
    $scope.me = $rootScope.environment.user;
    $scope.loading = true;
    $scope.active = 0;
    $scope.hcenters = [];
    $scope.healthcentersTemplate = "";
    $scope.groups = [];
    $scope.groupsTemplate = "";
    $scope.labs = [];
    $scope.labsTemplate = "";
    $scope.wholesalers = [];
    $scope.wholesalersTemplate = "";
    $scope.companies = [];
    $scope.companiesTemplate = "";
    $scope.clickListener = angular.element(document).one("click", ".environment-items md-list-item", function (event) {
      var entityType = event.currentTarget.closest("md-list").getAttribute("data-entity-type");
      var entityId = event.currentTarget.getAttribute("data-entity-id");
      switchActiveEnv($scope[entityType][entityId]);
    });
    loader();
    /**
     * Loader.
     */

    function loader() {
      loadEnvironments();
    }
    /**
     * Load environments.
     *
     * @returns {Array}
     */


    function loadEnvironments() {
      $scope.loading = true;
      return $q.all([environmentService.getEnvironments().then(function (response) {
        $scope.labs = response.laboratories;
        $scope.hcenters = response.healthcenters;
        $scope.groups = response.groupings;
        $scope.wholesalers = response.wholesalers;
        $scope.companies = response.companies;
        return response;
      }), environmentService.getEnvironment().then(function (response) {
        myAccess.currentEnv = response;
        $scope.active = response.id;
        $scope.welcomePhrase = getWelcomePhrase();
        return response;
      })]).then(function (response) {
        generateHealthcentersTemplate();
        generateGroupsTemplate();
        generateLabsTemplate();
        generateWholesalersTemplate();
        generateCompaniesTemplate();
        $scope.loading = false;
        return response;
      });
    }
    /**
     * Generate healthcenters template.
     */


    function generateHealthcentersTemplate() {
      var tpl = "";

      if (!_.isUndefined($scope.hcenters) && $scope.hcenters.length) {
        var forItems = function forItems(items) {
          var ret = "";
          items.forEach(function (item, index) {
            ret += "<md-list-item" + "    class=\"md-2-line md-clickable" + (item.id === $scope.active ? " md-accent-bg active-item" : "") + "\"" + "    data-entity-id=\"" + index + "\" role=\"listitem\">" + "    <button class=\"md-button md-ink-ripple md-default-theme\">" + "    <div class=\"md-list-item-inner\">" + "        <md-icon md-font-icon=\"icon-hospital\" class=\"icon md-font icon-hospital material-icons md-teal-theme\"></md-icon>" + "        <div class=\"md-list-item-text\" layout=\"column\">" + "            <h3>" + item.organization.morphable.name + "</h3>" + "            <small>" + "                " + (typeof item.organization.morphable.address !== "undefined" ? item.organization.morphable.address.streetLine1 + " " + item.organization.morphable.address.postalCode + " " + item.organization.morphable.address.cityName : "") + "<br/>" + "                " + (typeof item.organization.morphable.lgoInfos !== "undefined" ? item.organization.morphable.lgoInfos.name : "") + "<br/>" + "                " + (typeof item.organization.morphable.santeSecureVersion !== "undefined" && item.organization.morphable.santeSecureVersion !== null ? item.organization.morphable.santeSecureVersion : "") + "            </small>" + "        </div>" + "    </div>" + "    </button>" + "</md-list-item>";
          });
          return ret;
        };

        tpl = "<md-list flex data-entity-type=\"hcenters\" class=\"environment-items\" role=\"list\">" + forItems($scope.hcenters) + "</md-list>";
      }

      $scope.healthcentersTemplate = $sce.trustAsHtml(tpl);
    }
    /**
     * Generate groups template.
     */


    function generateGroupsTemplate() {
      var tpl = "";

      if (!_.isUndefined($scope.groups) && $scope.groups.length) {
        var forItems = function forItems(items) {
          var ret = "";
          items.forEach(function (item, index) {
            ret += "<md-list-item" + "    class=\"md-2-line md-clickable" + (item.id === $scope.active ? " md-accent-bg active-item" : "") + "\"" + "    data-entity-id=\"" + index + "\" role=\"listitem\">" + "    <button class=\"md-button md-ink-ripple md-default-theme\">" + "    <div class=\"md-list-item-inner\">" + "        <md-icon md-font-icon=\"icon-people\" class=\"icon md-font icon-people material-icons md-teal-theme\"></md-icon>" + "            <h3>" + item.organization.morphable.name + "</h3>" + "    </div>" + "    </button>" + "</md-list-item>";
          });
          return ret;
        };

        tpl = "<md-list flex data-entity-type=\"groups\" class=\"environment-items\" role=\"list\">" + forItems($scope.groups) + "</md-list>";
      }

      $scope.groupsTemplate = $sce.trustAsHtml(tpl);
    }
    /**
     * Generate laboratories template.
     */


    function generateLabsTemplate() {
      var tpl = "";

      if (!_.isUndefined($scope.labs) && $scope.labs.length) {
        var forItems = function forItems(items) {
          var ret = "";
          items.forEach(function (item, index) {
            ret += "<md-list-item" + "    class=\"md-2-line md-clickable" + (item.id === $scope.active ? " md-accent-bg active-item" : "") + "\"" + "    data-entity-id=\"" + index + "\" role=\"listitem\">" + "    <button class=\"md-button md-default-theme md-ink-ripple\">" + "    <div class=\"md-list-item-inner\">" + "        <md-icon md-font-icon=\"icon-beaker-empty-outline\" class=\"icon md-font icon-beaker-empty-outline material-icons md-teal-theme\"></md-icon>" + "         <h3>" + item.organization.morphable.name + "</h3>" + "    </div>" + "    </button>" + "</md-list-item>";
          });
          return ret;
        };

        tpl = "<md-list flex data-entity-type=\"labs\" class=\"environment-items\" role=\"list\">" + forItems($scope.labs) + "</md-list>";
      }

      $scope.labsTemplate = $sce.trustAsHtml(tpl);
    }
    /**
     * Generate wholesalers template.
     */


    function generateWholesalersTemplate() {
      var tpl = "";

      if (!_.isUndefined($scope.wholesalers) && $scope.wholesalers.length) {
        var forItems = function forItems(items) {
          var ret = "";
          items.forEach(function (item, index) {
            ret += "<md-list-item" + "    class=\"md-2-line md-clickable" + (item.id === $scope.active ? " md-accent-bg active-item" : "") + "\"" + "    data-entity-id=\"" + index + "\" role=\"listitem\">" + "    <button class=\"md-button md-ink-ripple md-default-theme\">" + "    <div class=\"md-list-item-inner\">" + "        <md-icon md-font-icon=\"icon-beaker-empty-outline\" class=\"icon md-font icon-beaker-empty-outline material-icons md-teal-theme\"></md-icon>" + "            <h3>" + item.organization.morphable.name + "</h3>" + "    </div>" + "    </button>" + "</md-list-item>";
          });
          return ret;
        };

        tpl = "<md-list flex data-entity-type=\"wholesalers\" class=\"environment-items\" role=\"list\">" + forItems($scope.wholesalers) + "</md-list>";
      }

      $scope.wholesalersTemplate = $sce.trustAsHtml(tpl);
    }
    /**
     * Generate companies template.
     */


    function generateCompaniesTemplate() {
      var tpl = "";

      if (!_.isUndefined($scope.companies) && $scope.companies.length) {
        var forItems = function forItems(items) {
          var ret = "";
          items.forEach(function (item, index) {
            ret += "<md-list-item" + "    class=\"md-2-line md-clickable" + (item.id === $scope.active ? " md-accent-bg active-item" : "") + "\"" + "    data-entity-id=\"" + index + "\" role=\"listitem\">" + "    <button class=\"md-button md-ink-ripple md-default-theme\">" + "    <div class=\"md-list-item-inner\">" + "        <md-icon md-font-icon=\"icon-beaker-empty-outline\" class=\"icon md-font icon-beaker-empty-outline material-icons md-teal-theme\"></md-icon>" + "            <h3>" + item.organization.morphable.name + "</h3>" + "    </div>" + "    </button>" + "</md-list-item>";
          });
          return ret;
        };

        tpl = "<md-list flex data-entity-type=\"companies\" class=\"environment-items\" role=\"list\">" + forItems($scope.companies) + "</md-list>";
      }

      $scope.companiesTemplate = $sce.trustAsHtml(tpl);
    }
    /**
     * Switch active environment.
     *
     * @param {object} env - Target.
     * @returns {Promise}
     */


    function switchActiveEnv(env) {
      closeDialog();
      return environmentService.switchEnvironment(env);
    }
    /**
     * Close dialog.
     */


    function closeDialog() {
      $scope.clickListener.off();
      $mdDialog.cancel();
    }
  }
  /**
   * Event getter.
   */


  $rootScope.$on("EnvironmentService.switchEnvironment:done", function () {
    $rootScope.$broadcast("goHome");
    loaderCurrentEnv();
  });
}

angular.module("app.navigation").directive("toolBarMenu", toolBarMenu);