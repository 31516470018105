"use strict";

PatientOrdersSummaryController.$inject = ["$scope", "$state", "$mdDialog", "orderFactory"];

/**
 * Patient orders summary component.
 */
var PatientOrdersSummaryComponent = {
  templateUrl: "app/main/patients/components/patient-orders-summary/patient-orders-summary.html",
  controllerAs: "vm",
  bindings: {
    customer: "<",
    prescriptions: "<",
    orders: "<",
    history: "<",
    isBeneficiary: "<"
  },
  controller: PatientOrdersSummaryController
};
/**
 * Patient orders summary controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} orderFactory - The order factory.
 */

function PatientOrdersSummaryController($scope, $state, $mdDialog, orderFactory) {
  $scope.statusMapping = orderFactory.statusMapping;
  $scope.openRenewalSchedule = openRenewalSchedule;
  $scope.redirectToOrderList = redirectToOrderList;
  $scope.redirectToPrescriptionList = redirectToPrescriptionList;
  $scope.redirectToSaleList = redirectToSaleList;
  $scope.redirectToDosagePlan = redirectToDosagePlan;
  $scope.redirectToOrderDetails = redirectToOrderDetails;
  $scope.redirectToPrescriptionDetails = redirectToPrescriptionDetails;
  $scope.redirectToCartDetails = redirectToCartDetails;
  var vm = this;
  /**
   * Open renewal schedule modal
   *
   * @param {object} $event - The angular $event?
   * @param {object} prescription - The prescription.
   */

  function openRenewalSchedule($event, prescription) {
    $event.stopPropagation();
    $mdDialog.show({
      controller: "prescriptionScheduleController",
      locals: {
        prescription: prescription
      },
      templateUrl: "app/main/patients/components/prescription-schedule/prescription-schedule.html",
      targetEvent: event,
      clickOutsideToClose: true,
      multiple: true,
      skipHide: true
    });
  }
  /**
   * Redirect to the prescription dosage plan.
   *
   * @param {object} $event - The angular $event.
   * @param {object} prescription - The prescription.
   *
   * @returns {void}
   */


  function redirectToDosagePlan($event, prescription) {
    $event.stopPropagation();
    $state.go("app.patients.details.prescription.dosage", {
      customerId: vm.customer.id,
      prescriptionId: prescription.id,
      dosageId: prescription.dosage_plan.id
    });
  }
  /**
   * Redirect to the order details.
   *
   * @param {object} order - The order.
   */


  function redirectToOrderDetails(order) {
    $state.go("app.patients.details.orders.details", {
      orderId: order.id
    });
  }
  /**
   * Redirect to the prescription details.
   *
   * @param {object} prescription - The prescription.
   */


  function redirectToPrescriptionDetails(prescription) {
    $state.go("app.patients.details.prescription", {
      prescriptionId: prescription.key
    });
  }
  /**
   * Redirect to cart details.
   *
   * @param {object} cart - The cart.
   */


  function redirectToCartDetails(cart) {
    $state.go("app.patients.details.carts.details", {
      cartId: cart.key
    });
  }
  /**
   * Redirect to the prescription list of the patient.
   */


  function redirectToPrescriptionList() {
    if (!vm.isBeneficiary) {
      $state.go("app.patients.details.prescriptions");
    } else {
      $state.go("app.patients.details.beneficiaries.details.prescriptions");
    }
  }
  /**
   * Redirect to the sale history list.
   */


  function redirectToSaleList() {
    if (!vm.isBeneficiary) {
      $state.go("app.patients.details.carts");
    } else {
      $state.go("app.patients.details.beneficiaries.details.carts");
    }
  }
  /**
   * Redirect to the order history list.
   */


  function redirectToOrderList() {
    if (!vm.isBeneficiary) {
      $state.go("app.patients.details.orders");
    } else {
      $state.go("app.patients.details.beneficiaries.details.orders");
    }
  }
}

angular.module("app.patients.components").component("patientOrdersSummary", PatientOrdersSummaryComponent);