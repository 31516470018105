"use strict";
/**
 * Patient order details controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $mdDialog - The angular $mdDialog object.
 * @param {object} orderFactory - The order factory.
 * @param {object} customerService - The customer service.
 * @param {object} notifyUserService - The notify user service.
 */

PatientOrderDetailsController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "orderFactory", "customerService", "notifyUserService"];

function PatientOrderDetailsController($scope, $state, $stateParams, $mdDialog, orderFactory, customerService, notifyUserService) {
  $scope.orderTitle = {
    title: {
      text: "Commande de",
      back: {
        state: null
      }
    }
  };
  $scope.order = null;
  $scope.loading = true;
  $scope.typeMapping = orderFactory.typeMapping;
  $scope.openRejectModal = openRejectModal;
  $scope.openScheduleModal = openScheduleModal;
  $scope.redirectToDosagePlan = redirectToDosagePlan;
  $scope.openRenewalSchedule = openRenewalSchedule;
  loader();
  /**
   * Loader function.
   */

  function loader() {
    customerService.readOrder($stateParams.orderId).then(function (response) {
      $scope.order = response.data;
      var consumer = $scope.order.content.prescription.beneficiary || $scope.order.content.prescription.customer;
      $scope.orderTitle.title.text += " ".concat(consumer.information.name);
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue, veuillez réessayer");
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Open the order reject modal.
   */


  function openRejectModal() {
    $mdDialog.show({
      controller: "orderRejectModalController",
      locals: {
        order: $scope.order
      },
      templateUrl: "app/main/patients/views/order-details/modals/order-reject/patients.orderReject.modal.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true
    });
  }
  /**
   * Open the order schedule modal.
   */


  function openScheduleModal() {
    $mdDialog.show({
      controller: "orderScheduleModalController",
      locals: {
        order: $scope.order
      },
      templateUrl: "app/main/patients/views/order-details/modals/order-schedule/patients.orderSchedule.modal.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true
    });
  }
  /**
   * Redirect to the dosage plan.
   *
   * @param {object} prescription - The prescription.
   */


  function redirectToDosagePlan(prescription) {
    $state.go("app.patients.details.prescription.dosage", {
      prescriptionId: prescription.key,
      dosageId: prescription.dosage_plan.id
    });
  }
  /**
   * Open the renewal plan schedule modal.
   *
   * @param {object} prescription - The prescription.
   */


  function openRenewalSchedule(prescription) {
    $mdDialog.show({
      controller: "prescriptionScheduleController",
      locals: {
        prescription: prescription
      },
      templateUrl: "app/main/patients/components/prescription-schedule/prescription-schedule.html",
      targetEvent: event,
      clickOutsideToClose: true,
      multiple: true,
      skipHide: true
    });
  }
}

angular.module("app.patients").controller("patientsOrderDetailsController", PatientOrderDetailsController);