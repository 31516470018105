"use strict";
/**
 * Grouping dashboard cap service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

GroupingDashboardCapService.$inject = ["apiClient"];

function GroupingDashboardCapService(apiClient) {
  return {
    /**
     * Get needs by labo.
     *
     * @returns {Promise}
     */
    getNeedsByLabo: function getNeedsByLabo() {
      return apiClient.get("/grouping/dashboard/needsByLabo", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get needs by pharmas.
     *
     * @returns {Promise}
     */
    getNeedsByPharmas: function getNeedsByPharmas() {
      return apiClient.get("/grouping/dashboard/needsByPharmas", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top 10 growth.
     *
     * @returns {Promise}
     */
    getTop10Growth: function getTop10Growth() {
      return apiClient.get("/grouping/dashboard/top10Growth", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top customer healthcenters.
     *
     * @param {number} limit - Top limit.
     *
     * @returns {Promise}
     */
    getTopCustomerHealthcenters: function getTopCustomerHealthcenters(limit) {
      return apiClient.post("/grouping/dashboard/purchases/healthcenters/top", {
        limit: limit
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get cumulated purchases amount by month for all members.
     *
     * @returns {Promise}
     */
    getCumulatedPurchasesByMonth: function getCumulatedPurchasesByMonth() {
      return apiClient.post("/grouping/dashboard/cumulatedPurchasesByMonth").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get purchases by type.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getPurchasesByType: function getPurchasesByType(params) {
      return apiClient.post("/grouping/dashboard/purchasesByType", params).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top laboratories purchases by healthcenter.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getTopLaboratoriesPurchasesByHealtcenter: function getTopLaboratoriesPurchasesByHealtcenter(params) {
      return apiClient.post("/grouping/dashboard/topLaboratoriesPurchasesByHealtcenter", params).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top laboratories purchases by healthcenters.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getTopLaboratoriesPurchasesByHealtcenters: function getTopLaboratoriesPurchasesByHealtcenters(params) {
      return apiClient.post("/grouping/dashboard/purchasesByType", params).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get purchases by generic or princeps.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getPurchasesByGenericOrPrinceps: function getPurchasesByGenericOrPrinceps(params) {
      return apiClient.post("/grouping/dashboard/purchasesByGenericOrPrinceps", params).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get purchases from wholesaler.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getPurchasesFromWholesaler: function getPurchasesFromWholesaler(params) {
      return apiClient.post("/grouping/dashboard/purchasesFromWholesaler", params).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.dashboard").service("groupingDashboardCapService", GroupingDashboardCapService);