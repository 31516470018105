"use strict";
/**
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $filter - $filter.
 * @param {object} $scope - $scope.
 * @param {object} $mdSidenav - $mdSidenav.
 * @param {object} $location - $location.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $q - $q.
 * @param {object} healthcenterAuditHcDetailsService - Healthcenter audit details service.
 * @param {object} salesAnalysisMarketShareService - Sales analysis market share service.
 * @param {object} colorService - Color service.
 * @param {object} groupmentAuditDetailService - Groupment audit detail service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} notifyUserService - Notify user service.
 */

MarketShareController.$inject = ["$rootScope", "$filter", "$scope", "$mdSidenav", "$location", "$stateParams", "$q", "healthcenterAuditHcDetailsService", "salesAnalysisMarketShareService", "colorService", "groupmentAuditDetailService", "StatisticsQueryService", "environmentService", "notifyUserService"];

function MarketShareController($rootScope, $filter, $scope, $mdSidenav, $location, $stateParams, $q, healthcenterAuditHcDetailsService, salesAnalysisMarketShareService, colorService, groupmentAuditDetailService, StatisticsQueryService, environmentService, notifyUserService) {
  var vm = this;
  var eur = d3.locale({
    decimal: ".",
    thousands: " ",
    grouping: [3],
    currency: ["", "€"],
    dateTime: "%a %b %e %X %Y",
    date: "%m/%d/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  });
  var psd3 = {};

  var _this = this;

  var brdBp = [{
    dataset: null,
    label: "Votre Recherche"
  }];
  var dirtyReturn = false;
  vm.selectedElement = null;
  vm.othersCompares = [];

  var processSelectedOthers = function processSelectedOthers(data) {
    var breadcrumb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    var ret = [];
    data.forEach(function (datum) {
      if (datum.type === vm.selectedElement.type && datum.id === vm.selectedElement.id) {
        datum.breadcrumb = breadcrumb.join(" → ");
        ret.push(datum);
      }

      if (datum.drilldown.length > 0) {
        ret = ret.concat(processSelectedOthers(datum.drilldown, breadcrumb.concat([datum.label])));
      }
    });
    return ret;
  };

  var processSelectedElement = function processSelectedElement() {
    vm.othersCompares = processSelectedOthers(vm.config.data);
    vm.selectedElement.totalBrothersCompare = vm.selectedElement.brothers.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue.value;
    }, 0);
    vm.selectedElement.totalOthersCompare = vm.othersCompares.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue.value;
    }, 0);

    if (vm.selectedElement !== null && (vm.selectedElement.brothers.length > 1 || vm.othersCompares.length > 1)) {
      notifyUserService.showSimpleToast("Analyse des parts de marché calculée", {
        color: "success",
        hideDelay: 2000
      });
    }
  };
  /**
   * Config Search BAR
   */


  vm.configSearchBar = {
    event: "sandboxFilterChange",
    search: []
  };
  vm.tooltip = {
    config: {
      color: "#CCC",
      backgroundColor: "rgba(68, 68, 68, 0.85)"
    },
    display: function display(data) {
      var innerCircle = 0;
      angular.forEach(vm.elasticdata.data, function (data) {
        innerCircle += data.value;
      });
      angular.forEach(vm.elasticdata.data, function (data) {
        data.total = innerCircle;
        angular.forEach(data.drilldown, function (_data) {
          _data.total = data.value;
        });
      });
      var tooltip = document.getElementById(_this.tooltipId);
      tooltip.style.position = "fixed";
      tooltip.style.color = this.config.color;
      tooltip.style.backgroundColor = this.config.backgroundColor;
      tooltip.innerHTML = this.format(data.data); // hide

      tooltip.classList.remove("psd3Hidden");
      d3.event.target.addEventListener("mousemove", function (event) {
        var offsetLeft = 250;
        var offsetTop = 150;
        var mouseOffset = 20;
        var left = offsetLeft + mouseOffset + event.clientX;
        var top = offsetTop + mouseOffset + event.clientY;
        tooltip.style.left = left + "px";
        tooltip.style.top = top + "px";
      });
    },
    format: function format(data) {
      var unit;
      var filteredValue; // display unit type

      var ret = "";
      ret += "<table>";
      ret += "<thead><tr md-row><th>Nom</th><th>Valeur</th><th>%</th></tr></thead>";
      ret += "<tbody>";

      if (vm.form.by === "item.price.sell.total_ht") {
        unit = eur.numberFormat("$,.2f")(data.value);
      } else {
        unit = d3.format(",.3")(data.value) + " unités";
      }

      filteredValue = $filter("number")(data.value / data.total * 100, 2);
      ret += "<tr md-row><td md-cell>" + data.label + "</td><td md-cell>" + unit + "&nbsp</td><td md-cell>" + filteredValue + "%</td></tr>";
      ret += "</tbody>";
      ret += "</table>";
      return ret;
    }
  }; // à mettre dans une directive

  vm.interval = {
    model: "1d",
    values: [{
      label: "Jour",
      value: "1d",
      displayPattern: "DD MMM YYYY"
    }, {
      label: "Semaine",
      value: "1w",
      displayPattern: "semaine W YYYY"
    }, {
      label: "Mois",
      value: "1M",
      displayPattern: "MMM YYYY"
    }]
  };
  vm.rawValues = StatisticsQueryService.values;
  vm.getLimits = getLimits;
  vm.showMoreFilters = false;
  vm.showMoreFilter = showMoreFilter;
  vm.sortableOptions = {
    placeholder: "app-ph2",
    "ui-floating": true,
    connectWith: ".valuesOptions",
    stop: function stop() {
      if (vm.movedApp) {
        StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
          processQuery(query, true);
        });
      }
    },
    beforeStop: function beforeStop(event, ui) {
      // on drop change the category if needed
      vm.movedApp = findAppById(vm.form.values, ui.helper[0].id);

      if (vm.movedApp) {
        var newColumn = ui.helper.parent()[0].getAttribute("app-column");

        if (vm.movedApp.checked !== newColumn) {
          vm.movedApp.checked = newColumn === "true";
        }
      }
    }
  };
  vm.changeTarget = changeTarget;
  vm.rangeSelected = rangeSelected;
  vm.canEclate = canEclate;
  vm.removeFilter = removeFilter;
  vm.openSettingsTo = openSettingsTo;
  vm.toggleSidenav = toggleSidenav;
  vm.toggleCb = toggleCb;
  vm.indexByLabel = indexByLabel;
  vm.existsFilter = existsFilter;
  vm.sandBoxOpts = [];
  vm.addFilters = addFilters;
  vm.checkOpt = checkOpt;
  loader();
  /**
   * Loader.
   */

  function loader() {
    vm.loading = true;
    vm.colors = colorService.purpleFunc;
    vm.usrquery = StatisticsQueryService.query;
    vm.dateLabel = moment(vm.usrquery.date.from).format("DD-MMM-YYYY") + " Au " + moment(vm.usrquery.date.to).format("DD-MMM-YYYY");
    vm.dataset = [];
    vm.form = {
      isBlurred: false,
      nbEl: {
        min: 2,
        max: 30,
        model: 15
      },
      by: "quantity",
      values: StatisticsQueryService.aggBy
    };
    vm.valuesChecked = $filter("filter")(vm.form.values, {
      checked: true
    });
    vm.valuesNotChecked = $filter("filter")(vm.form.values, {
      checked: false
    });
    vm.valuesChecked.forEach(function (data) {
      vm.valuesNotChecked.push(data);
    });
    environmentService.getEnvironment().then(function (env) {
      angular.forEach(vm.form.values, function (item) {
        vm.currentEnv = env;

        if (typeof item.rankNeeded !== "undefined") {
          item.canEclate = item.rights.indexOf(vm.currentEnv.accessPointType) > -1 && item.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
        } else {
          item.canEclate = item.rights.indexOf(vm.currentEnv.accessPointType) > -1;
        }
      });

      if (vm.currentEnv.accessPointType === 1) {
        var lab = {
          id: vm.currentEnv.entityId,
          name: vm.currentEnv.entity.name,
          type: "laboratory"
        };

        if (!_.find(vm.usrquery.must.laboratory.selected, function (labo) {
          return labo.id === vm.currentEnv.entityId;
        }) && !_.find(vm.usrquery.should.laboratory.selected, function (labo) {
          return labo.id === vm.currentEnv.entityId;
        })) {
          vm.usrquery.must.laboratory.selected.push(lab);
        }
      } // We preselect the grouping if the current env is a grouping one


      if (vm.currentEnv.accessPointType === 3) {
        var grouping = {
          id: vm.currentEnv.entityId,
          name: vm.currentEnv.entity.name,
          type: "groups"
        };

        var groupingMust = _.find(vm.usrquery.must.groups.selected, function (group) {
          return group.id === grouping.id;
        });

        if (!groupingMust) {
          groupingMust = _.find(vm.usrquery.should.groups.selected, function (group) {
            return group.id === grouping.id;
          });
        }

        if (!groupingMust) {
          vm.usrquery.must.groups.selected.push(grouping);
        }
      }

      manageStateParams().then(function (query) {
        StatisticsQueryService.buildquery(query, query.date).then(function (query) {
          processQuery(query, true);
        });
      });
    });
  }
  /**
   * Manage state params.
   *
   * @returns {Promise}
   */


  function manageStateParams() {
    var inCall = false;
    return $q(function (resolve) {
      if ($stateParams.products) {
        vm.usrquery.must.product.selected = [];
        angular.forEach($stateParams.products, function (productName, productId) {
          if (vm.usrquery.must.product.selected.indexOf({
            id: productId,
            name: productName,
            type: "product"
          }) === -1) {
            vm.usrquery.must.product.selected.push({
              id: productId,
              name: productName,
              type: "product"
            });
          }
        });
      }

      if ($stateParams.pharmas) {
        var pharmaInfo = {};
        vm.usrquery.must.pharmas.selected = [];
        angular.forEach($stateParams.pharmas, function (pharma) {
          pharmaInfo = {
            id: pharma.id,
            name: pharma.name,
            type: "pharmas",
            cip: pharma.cip
          };

          if (!_.find(vm.usrquery.must.pharmas.selected(function (pharmaInMust) {
            return pharmaInMust.id === pharma.id;
          }))) {
            vm.usrquery.must.pharmas.selected.push(pharmaInfo);
          }
        });
      } // Apply filter if direct access from segments list (healthcenters and products)


      if ($stateParams.segment) {
        vm.usrquery.must.segmentHealthcenter.selected = [];
        vm.usrquery.must.segmentProduct.selected = [];
        var segmentHealthcenter = {};
        var segmentProduct = {};
        angular.forEach($stateParams.segment, function (segment) {
          if (segment.segmentTypeId === 1) {
            segmentHealthcenter = {
              id: segment.id,
              name: segment.name,
              type: "segmentHealthcenter"
            };

            if (!_.find(vm.usrquery.must.segmentHealthcenter.selected, function (segmentHealthcenterInMust) {
              return segmentHealthcenterInMust.id === segment.id;
            })) {
              vm.usrquery.must.segmentHealthcenter.selected.push(segmentHealthcenter);
            }
          }

          if (segment.segmentTypeId === 2) {
            segmentProduct = {
              id: segment.id,
              name: segment.name,
              type: "segmentProduct"
            };

            if (!_.find(vm.usrquery.must.segmentProduct.selected, function (segmentProductInMust) {
              return segmentProductInMust.id === segment.id;
            })) {
              vm.usrquery.must.segmentProduct.selected.push(segmentProduct);
            }
          }
        });
      }

      if ($stateParams.id) {
        inCall = true;
        healthcenterAuditHcDetailsService.hCDetail($stateParams.id).then(function (pharma) {
          var pharmaInfos = {
            id: pharma.healthcenter.id,
            name: pharma.healthcenter.name,
            type: "pharmas",
            cip: pharma.healthcenter.cip
          };
          vm.usrquery.must.pharmas.selected = [pharmaInfos];
          resolve(vm.usrquery);
        });
      }

      if ($stateParams.groupId) {
        inCall = true;
        groupmentAuditDetailService.one($stateParams).then(function (groupment) {
          $scope.group = groupment[0].grouping;
          var groupInfos = {
            id: $scope.group.id,
            name: $scope.group.name,
            type: "groups"
          };
          vm.usrquery.must.groups.selected = [groupInfos];
          resolve(vm.usrquery);
        });
      }

      if (!inCall) {
        resolve(vm.usrquery);
      }
    });
  }
  /**
   * Get limits.
   *
   * @param {Array} array - Array.
   *
   * @returns {Array}
   */


  function getLimits(array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  }
  /**
   * Show more filter.
   */


  function showMoreFilter() {
    vm.showMoreFilters = !vm.showMoreFilters;
  }
  /**
   * Find app by ID.
   *
   * @param {Array} appList - App list.
   * @param {number} id - ID.
   *
   * @returns {object}
   */


  function findAppById(appList, id) {
    // IDs are unique so we know we"ll only return one app or null
    var app = appList.find(function (app) {
      return app.id === id;
    });

    if (app && app !== undefined) {
      return app;
    }

    return null;
  }
  /**
   * Range selected.
   *
   * @param {Date} date - Date.
   */


  function rangeSelected(date) {
    vm.usrquery.date.from = moment(date.startDate, "DD-MM-YYYY");
    vm.usrquery.date.to = moment(date.endDate, "DD-MM-YYYY");
    StatisticsQueryService.saveRegQuery(vm.usrquery);
  }
  /**
   * DnD-related functions
   *
   * @param {object} item - Item.
   *
   * @returns {boolean}
   */


  function canEclate(item) {
    if (typeof vm.currentEnv === "undefined") {
      environmentService.getEnvironment().then(function (env) {
        vm.currentEnv = env;
      });
    } else if (typeof item.rankNeeded !== "undefined") {
      return item.rights.indexOf(vm.currentEnv.accessPointType) > -1 && item.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
    } else {
      return item.rights.indexOf(vm.currentEnv.accessPointType) > -1;
    }

    return false;
  }
  /**
   * Change target.
   */


  function changeTarget() {
    if (vm.form.nbEl.model > 30) {
      vm.form.nbEl.model = 30;
    }

    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      return processQuery(query, true);
    });
  }
  /**
   * Get buckets.
   *
   * @returns {Array}
   */


  function getBuckets() {
    var buckets = [];
    angular.forEach(vm.valuesChecked, function (data) {
      if (data.model) {
        buckets.push({
          term: data.val,
          size: vm.form.nbEl.model
        });
      }
    });
    return buckets;
  }
  /**
   * Data-related methods
   *
   * @param {object} query - Query.
   * @param {boolean} update - Update.
   */


  function processQuery(query, update) {
    vm.selectedElement = null;
    vm.form.isBlurred = true;
    query.by = vm.form.by;
    query.buckets = getBuckets();

    if (update) {
      angular.element(document.getElementById("chartContainer")).html("");
    }

    vm.loading = true;
    salesAnalysisMarketShareService.load(query).then(function (response) {
      vm.loading = false;
      vm.form.isBlurred = false;
      vm.elasticdata = {
        data: response.data
      };
      parseData();
    }, function () {
      vm.loading = false;
      vm.form.isBlurred = false;
    });
  }
  /**
   * Get total.
   *
   * @param {object} data - Data.
   * @param {number} total - Total.
   */


  function getTotal(data, total) {
    angular.forEach(data, function (datum) {
      datum.total = total;

      if (datum.drilldown.length > 0) {
        getTotal(datum.drilldown, datum.value);
      }
    });
  }
  /**
   * Populate brothers.
   *
   * @param {object} data - Data.
   */


  function populateBrothers(data) {
    angular.forEach(data, function (datum) {
      datum.brothers = data.map(function (brother) {
        return {
          id: brother.id,
          label: brother.label,
          value: brother.value
        };
      });

      if (datum.drilldown.length > 0) {
        populateBrothers(datum.drilldown);
      }
    });
  }
  /**
   * Parse data.
   */


  function parseData() {
    angular.forEach(vm.elasticdata.data, function (data) {
      if (data.drilldown && data.drilldown.length > 0) {
        getTotal(data.drilldown, data.value);
      }
    });
    populateBrothers(vm.elasticdata.data);
    vm.config = {
      containerId: "chartContainer",
      width: angular.element(document.getElementById("chartContainer")).width(),
      height: angular.element(document.getElementById("chartContainer")).height(),
      data: vm.elasticdata.data,
      label: function label() {
        return "";
      },
      value: "value",
      inner: "drilldown",
      tooltip: function tooltip(data) {
        var unit;

        if (vm.form.by === "item.price.sell.total_ht") {
          unit = eur.numberFormat("$,.2f")(data.value);
        } else {
          unit = d3.format(",.3")(data.value) + " unités";
        }

        var html = "<table>" + "<thead><tr md-row><th>Champ</th><th>Valeur</th><th>Pourcentage</th></tr></thead><tbody><tr md-row>" + "<td md-cell>" + data.label + "</td><td md-cell>" + unit + "</td><td md-cell>";

        if (data.total) {
          html += $filter("number")(data.value / data.total * 100, 2) + " %";
        }

        html += "</td></tr></tbody></table>";
        return html;
      },
      transition: "none",
      transitionDuration: 0,
      donutRadius: 50,
      gradient: false,
      labelColor: "#333",
      colors: d3.scale.category20c(),
      stroke: "#eee",
      strokeWidth: 1,
      drilldownTransition: "none",
      drilldownTransitionDuration: 0
    };
    var pie = document.getElementById(vm.config.containerId);

    if (pie) {
      vm.pie = new psd3.Pie(vm.config);
    }

    vm.form.isBlurred = false;
  }
  /**
   * Remove filter.
   *
   * @param {object} opt - Opt.
   */


  function removeFilter(opt) {
    var index = vm.valuesChecked.indexOf(opt);

    if (index !== -1) {
      vm.valuesChecked.forEach(function (optChecked) {
        if (opt.id === optChecked.id) {
          optChecked.checked = false;
        }
      });
      vm.valuesChecked.splice(index, 1);
      vm.loading = true;

      if (vm.valuesChecked.length > 0) {
        StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
          processQuery(query, true);
        });
      }
    }
  }
  /**
   * Open settings to.
   *
   * @param {object} panel - Panel.
   */


  function openSettingsTo(panel) {
    $rootScope.$broadcast("statistics:opento", panel);
  }
  /**
   * Toggle side nav.
   */


  function toggleSidenav() {
    $mdSidenav("settings-sidenav").toggle();
  }
  /**
   * Toggle checkbox.
   *
   * @param {object} item - Item.
   * @param {Array} list - List.
   */


  function toggleCb(item, list) {
    if (vm.genQuery) {
      return;
    }

    var idx = vm.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      processQuery(query, true);
    });
  }
  /**
   * Index by label.
   *
   * @param {string} name - Name.
   * @param {Array} list - List.
   *
   * @returns {number}
   */


  function indexByLabel(name, list) {
    if (!name || !list) {
      return -1;
    }

    return list.findIndex(function (item) {
      return item.label === name;
    });
  }
  /**
   * Exists filter.
   *
   * @param {string} name - Name.
   * @param {Array} list - List.
   *
   * @returns {boolean}
   */


  function existsFilter(name, list) {
    if (vm.genQuery) {
      return false;
    }

    return vm.indexByLabel(name, list) > -1;
  }
  /**
   * Check opt.
   *
   * @param {object} opt - Opt.
   */


  function checkOpt(opt) {
    if (opt.checked) {
      var index = vm.sandBoxOpts.indexOf(opt);
      vm.sandBoxOpts.splice(index, 1);
    } else {
      opt.checked = true;
      vm.sandBoxOpts.push(opt);
    }
  }
  /**
   * Add filters.
   *
   * @param {object} opt - Opt.
   */


  function addFilters(opt) {
    if (!opt.checked) {
      vm.valuesChecked.push(opt);
      opt.checked = true;
      StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
        processQuery(query, true);
      });
    }
  }

  $scope.$on("analysis:regquery", function (event, data) {
    vm.loading = true;
    vm.usrquery = data;
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      vm.query = query;
      processQuery(query, true);
    });
  });
  $scope.$on("sandboxpie:configchange", function (event, data) {
    vm.loading = true;
    vm.form = data;
    processQuery(vm.query, true);
  });
  $scope.$on("sandboxFilterChange", function () {
    vm.loading = true;
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      processQuery(query, true);
    });
  });

  psd3.Graph = function (config) {
    this.config = config;
    this.defaults = {
      width: angular.element(document.getElementById("chartContainer")).width(),
      height: angular.element(document.getElementById("chartContainer")).height(),
      value: "value",
      inner: "inner",
      label: function label(item) {
        return item.data.value;
      },
      tooltip: function tooltip(item) {
        if (_this.config.value !== undefined) {
          return item[_this.config.value];
        }

        return item.value;
      },
      transition: "linear",
      transitionDuration: 1000,
      donutRadius: 0,
      gradient: false,
      colors: d3.scale.category20(),
      labelColor: "black",
      drilldownTransition: "linear",
      drilldownTransitionDuration: 0,
      stroke: "white",
      strokeWidth: 2,
      highlightColor: "orange"
    };

    for (var property in this.defaults) {
      if (Object.prototype.hasOwnProperty.call(this.defaults, property)) {
        if (!Object.prototype.hasOwnProperty.call(config, property)) {
          config[property] = this.defaults[property];
        }
      }
    }
  };

  psd3.Pie = function (config) {
    psd3.Graph.call(this, config);
    this.zoomStack = [];
    var pos = "top";

    if (this.config.heading !== undefined && this.config.heading.pos !== undefined) {
      pos = this.config.heading.pos;
    }

    if (pos === "top") {
      this.setHeading();
    }

    this.drawPie(config.data);

    if (pos === "bottom") {
      this.setHeading();
    }
  };

  psd3.Pie.prototype = Object.create(psd3.Graph.prototype);
  psd3.Pie.prototype.constructor = psd3.Pie;

  psd3.Pie.prototype.findMaxDepth = function (dataset) {
    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return 0;
    }

    var currentLevel;
    var maxOfInner = 0;

    for (var index = 0; index < dataset.length; index++) {
      var maxInnerLevel = this.findMaxDepth(dataset[index][this.config.inner]);

      if (maxOfInner < maxInnerLevel) {
        maxOfInner = maxInnerLevel;
      }
    }

    currentLevel = 1 + maxOfInner;
    return currentLevel;
  };

  psd3.Pie.prototype.setHeading = function () {
    if (this.config.heading !== undefined) {
      d3.select("#" + this.config.containerId).append("div").style("text-align", "center").style("width", String(this.config.width) + "px").style("padding-top", "20px").style("padding-bottom", "20px").append("strong").text(this.config.heading.text);
    }
  };

  psd3.Pie.prototype.mouseover = function (item) {
    vm.tooltip.display(item); // over effect

    d3.select(item.path).style("fill", _this.config.highlightColor);
  };

  psd3.Pie.prototype.mouseout = function (item) {
    d3.select("#" + _this.tooltipId).classed("psd3Hidden", true);
    d3.select(item.path).style("fill", item.fill);
  };

  psd3.Pie.prototype.drawPie = function (dataset) {
    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return;
    }

    _this = this;
    _this.arcIndex = 0;
    _this.width = 100;
    _this.height = 100;
    var svg = d3.select("#" + _this.config.containerId).append("svg").attr("id", _this.config.containerId + "_svg").attr("viewBox", "0 0 640 640").attr("preserveAspectRatio", "xMinYMin meet").append("g");
    _this.tooltipId = _this.config.containerId + "_tooltip";
    var tooltipDiv = d3.select("#" + _this.config.containerId).append("div").attr("id", _this.tooltipId).attr("class", "psd3Hidden psd3Tooltip");
    tooltipDiv.append("p").append("span").attr("id", "value").text("100%");
    var labelBp = d3.select("#chartLabelContainer");
    labelBp.html("");

    for (var index = 0; index < brdBp.length; index++) {
      labelBp.append("span").text(brdBp[index].label);

      if (brdBp[index + 1]) {
        labelBp.append("md-icon").attr("class", "mh-8 icon s16 icon-chevron-right").attr("md-font-icon", "icon-chevron-right");
      }
    }

    var returnBp = d3.select("#chartReturnBtnContainer");

    if (brdBp.length > 1) {
      if (!dirtyReturn) {
        returnBp.append("span").text("Retour").attr("class", "returnPsdBrd md-accent-fg mt-8");
        dirtyReturn = true;
      }
    } else {
      returnBp.html("");
      dirtyReturn = false;
    } // to contain pie cirlce


    var radius;

    if (_this.config.width > _this.config.height) {
      radius = _this.config.width / 2;
    } else {
      radius = _this.config.height / 2;
    }

    var innerRadius = _this.config.donutRadius;

    var maxDepth = _this.findMaxDepth(dataset);

    var outerRadius = innerRadius + (radius - innerRadius) / maxDepth;
    var radiusDelta = outerRadius - innerRadius;

    _this.draw(svg, radius, dataset, dataset, dataset.length, innerRadius, outerRadius, radiusDelta, 0, 360 * 22 / 7 / 180, [0, 0]);
  };

  psd3.Pie.prototype.customArcTween = function (item) {
    var start = {
      startAngle: item.startAngle,
      endAngle: item.startAngle
    };
    var interpolate = d3.interpolate(start, item);
    return function (data) {
      return item.arc(interpolate(data));
    };
  };

  psd3.Pie.prototype.textTransform = function (item) {
    return "translate(" + item.arc.centroid(item) + ")";
  };

  psd3.Pie.prototype.textTitle = function (item) {
    return item.data[_this.config.value];
  };

  psd3.Pie.prototype.draw = function (svg, totalRadius, dataset, originalDataset, originalDatasetLength, innerRadius, outerRadius, radiusDelta, startAngle, endAngle) {
    var _this = this;

    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return;
    }

    brdBp[0].dataset = originalDataset;

    psd3.Pie.prototype.textText = function (item) {
      return _this.config.label(item);
    };

    var pie = d3.layout.pie();
    pie.sort(null);
    pie.value(function (item) {
      return item[_this.config.value];
    });
    pie.startAngle(startAngle).endAngle(endAngle);
    var values = [];

    for (var index = 0; index < dataset.length; index++) {
      values.push(dataset[index][_this.config.value]);
    }

    var arc = d3.svg.arc().innerRadius(innerRadius).outerRadius(outerRadius); // Set up groups

    _this.arcIndex = _this.arcIndex + 1;
    var indexClass = "arc" + _this.arcIndex;
    var elementClass = "arc" + _this.arcIndex;

    var storeMetadataWithArc = function storeMetadataWithArc(item) {
      // eslint-disable-next-line no-invalid-this
      item.path = this; // eslint-disable-next-line no-invalid-this

      item.fill = this.fill;
      item.arc = arc;
      item.length = dataset.length;
    };

    var arcs = svg.selectAll("g." + indexClass).data(pie(dataset)).enter().append("g").attr("class", "arc " + indexClass).attr("transform", "translate(" + totalRadius + "," + totalRadius + ")").on("click", function (item) {
      vm.selectedElement = item.data;
      processSelectedElement();
      $scope.$apply();
    }).on("mouseover", function (item) {
      var arcsElements = angular.element("g.arc");
      angular.forEach(arcsElements, function (arcsElement) {
        angular.element(arcsElement).removeAttr("selected");
      });
      var elementClass = [".arc", item.data.type, item.data.id].join("_");
      var elements = angular.element(elementClass);
      elements.attr("selected", "selected");
    }).on("mouseout", function () {
      var arcsElements = angular.element("g.arc");
      angular.forEach(arcsElements, function (arcsElement) {
        angular.element(arcsElement).removeAttr("selected");
      });
    });
    var gradient = svg.append("svg:defs").append("svg:linearGradient").attr("id", "gradient_" + _this.arcIndex).attr("x1", "0%").attr("y1", "0%").attr("x2", "100%").attr("y2", "100%").attr("spreadMethod", "pad");
    var startColor;
    var endColor;

    if (_this.config.gradient) {
      var _index = 2 * _this.arcIndex;

      var endIndex = _index + 1;
      startColor = _this.config.colors(_index);
      endColor = _this.config.colors(endIndex);
    } else {
      startColor = endColor = _this.config.colors(this.arcIndex);
    }

    gradient.append("svg:stop").attr("offset", "0%").attr("stop-color", startColor).attr("stop-opacity", 1);
    gradient.append("svg:stop").attr("offset", "100%").attr("stop-color", endColor).attr("stop-opacity", 1); // Draw arc paths

    var paths = arcs.append("path").attr("fill", "url(" + $location.$$url + "#gradient_" + _this.arcIndex + ")").style("stroke", _this.config.stroke).style("stroke-width", _this.config.strokeWidth);
    paths.on("mouseover", _this.mouseover);
    paths.on("mouseout", _this.mouseout);
    paths.each(storeMetadataWithArc);
    paths.transition().duration(_this.config.transitionDuration).delay(_this.config.transitionDuration * (_this.arcIndex - 1)).ease(_this.config.transition).attrTween("d", _this.customArcTween);
    dataset.sort(function (left, right) {
      return right.value - left.value;
    });

    for (var _index2 = 0; _index2 < dataset.length; _index2++) {
      elementClass = ["arc", dataset[_index2].type, dataset[_index2].id].join("_");

      if (dataset[_index2][_this.config.inner] !== "undefined" && dataset[_index2][_this.config.inner].length > 0) {
        _this.draw(svg, totalRadius, dataset[_index2][_this.config.inner], originalDataset, originalDatasetLength, innerRadius + radiusDelta, outerRadius + radiusDelta, radiusDelta, paths.data()[_index2].startAngle, paths.data()[_index2].endAngle, arc.centroid(paths.data()[_index2]), elementClass);
      }

      var el = svg.selectAll("g." + indexClass)[0][_index2];

      angular.element(el).attr("class", ["arc", indexClass, elementClass].join(" "));
    }
  };

  psd3.Pie.prototype.reDrawPie = function (item, ds) {
    var tmp = [];
    d3.select("#" + _this.tooltipId).remove();
    d3.select("#" + _this.config.containerId + "_svg").transition().ease(_this.config.drilldownTransition).duration(_this.config.drilldownTransitionDuration).style("height", 0).remove().each("end", function () {
      if (item.length === 1) {
        tmp = _this.zoomStack.pop();
      } else {
        tmp.push(item.data);

        _this.zoomStack.push(ds);
      }

      _this.drawPie(tmp);
    });
  };
}

angular.module("app.statistics.salesAnalysis").controller("MarketShareController", MarketShareController);