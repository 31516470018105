"use strict";
/**
 * Supplying graph directive.
 *
 * @returns {object}
 */

SupplyingGraphController.$inject = ["$scope", "$q"];

function SupplyingGraphDirective() {
  return {
    restrict: "E",
    scope: {
      datas: "@",
      field: "@",
      unit: "@",
      percent: "@",
      color: "@"
    },
    templateUrl: "app/main/directives/stats/supplyingGraph/supplyingGraphs.tmpl.html",
    controller: SupplyingGraphController
  };
}
/**
 * Supplying graph controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $q - $q.
 */


function SupplyingGraphController($scope, $q) {
  $scope.datasObj = JSON.parse($scope.datas);
  /**
   * Manage data.
   */

  function manageDatas() {
    var lines = [];
    $scope.supplyingGraphs = {};
    $scope.deferredChart = $q.defer();
    $scope.supplyingGraphs = {
      type: "serial",
      data: $scope.deferredChart.promise,
      startDuration: 0,
      decimalSeparator: ",",
      thousandSeparator: " ",
      legend: false,
      autoMargins: false,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      chartCursor: {
        cursorAlpha: 0
      },
      valueAxes: [{
        id: "g1",
        axisAlpha: 0,
        position: "left",
        gridAlpha: 0,
        axisColor: "#000000",
        labelsEnabled: false
      }],
      graphs: [{
        fillAlphas: 0.3,
        fillToGraph: "firstLine",
        showBalloon: true,
        valueField: "firstLine",
        lineColor: $scope.color,
        lineThickness: 1,
        fillColors: $scope.color,
        type: "smoothedLine",
        balloonFunction: function balloonFunction(graphDataItem) {
          return "<div style='margin:0px;padding:0px;font-size:10px'>" + graphDataItem.dataContext.firstLine + " " + $scope.unit + "<br>" + graphDataItem.dataContext.date.format("DD/MM/YYYY HH:mm") + "</div>";
        }
      }]
    };

    if ($scope.unit === "%" && $scope.percent) {
      $scope.supplyingGraphs.valueAxes[0].maximum = 100;
      $scope.supplyingGraphs.valueAxes[0].minimum = 0;
    }

    angular.forEach($scope.datasObj, function (data) {
      var obj = {
        date: moment(data.key)
      };
      obj.firstLine = data[$scope.field].value;

      if ($scope.unit === "%" && $scope.percent) {
        obj.firstLine = Math.round(100 * data[$scope.field].value / data[$scope.percent].value);
      } // eslint-disable-next-line no-invalid-this


      this.push(obj);
    }, lines);
    $scope.deferredChart.resolve(lines);
  }

  $scope.$watch("datas", function (newValue) {
    $scope.datasObj = JSON.parse(newValue);
    manageDatas();
  });
}

angular.module("app.directives").directive("supplyingGraph", SupplyingGraphDirective);