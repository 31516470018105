"use strict";
/**
 * Generic bookmark service.
 *
 * @param {object} apiClient - API client.
 * @returns {object}
 */

GenericBookmarkService.$inject = ["apiClient"];

function GenericBookmarkService(apiClient) {
  return {
    /**
     * Get all generic bookmarks..
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/users/me/bookmark/generic", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a generic bookmark.
     *
     * @param {object} data - Generic bookmark data
     * @returns {Promise}
     */
    create: function create(data) {
      return apiClient.post("/users/me/bookmark/generic", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics").service("genericBookmarkService", GenericBookmarkService);