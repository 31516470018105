"use strict";
/**
 * Dashboard wholesaler disruption rates controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} wholesalersService - Wholesalers service.
 */

DashboardWholesalerDisruptionRatesController.$inject = ["$scope", "wholesalersService"];

function DashboardWholesalerDisruptionRatesController($scope, wholesalersService) {
  $scope.loaderWholesalersAvailability = {
    loading: true,
    loader: "Récupération des ruptures de stock grossiste",
    empty: "Aucune rupture de stock grossiste"
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    $scope.disponibility = [];
    wholesalersService.getDisponibilityDashboard().then(function (results) {
      if (typeof results === "undefined" || results.length === 0) {
        $scope.loaderWholesalersAvailability.loading = false;
        return;
      }

      $scope.nationalDisponibility = {
        yesterday: {
          numberOfProducts: results.national.yesterday.numberOfProducts.value,
          totalProductOnDisruptionDay: 0,
          totalProductDispoRate: 0
        }
      };
      angular.forEach(results, function (wholesaler, index) {
        if (index !== "national") {
          wholesaler.name = wholesaler.yesterday.key;

          if (!wholesaler.yesterday.totalProductOnDisruptionDay) {
            wholesaler.yesterday.totalProductOnDisruptionDay = 0;
            wholesaler.yesterday.totalProductDispoRate = 0;
            wholesaler.yesterday.totalProductRectifiedDispoRate = 0;
          }

          if (wholesaler.yesterday.product) {
            wholesaler.yesterday.product.buckets.forEach(function (product) {
              wholesaler.yesterday.totalProductOnDisruptionDay += product.disruptionDayCount.value.totalDisruptedDay;
              wholesaler.yesterday.totalProductRectifiedDispoRate += product.disruptionDayCount.value.rectifiedDispoRate;
              wholesaler.yesterday.totalProductDispoRate += product.disruptionDayCount.value.dispoRate;
            });
            wholesaler.yesterday.rectifiedDisruptionRate = 100 - wholesaler.yesterday.totalProductRectifiedDispoRate / wholesaler.yesterday.numberOfProducts.value;
            wholesaler.yesterday.disruptionRate = 100 - wholesaler.yesterday.totalProductDispoRate / wholesaler.yesterday.numberOfProducts.value;
            wholesaler.yesterday.disruptionDays = wholesaler.yesterday.totalProductOnDisruptionDay / wholesaler.yesterday.numberOfProducts.value;
          }

          $scope.disponibility.push(wholesaler);
        }
      });
      $scope.loaderWholesalersAvailability.loading = false;
    });
  }
}

angular.module("app.dashboard").controller("dashboardWholesalerDisruptionRatesController", DashboardWholesalerDisruptionRatesController);