"use strict";
/**
 * Fabric parent click directive.
 *
 * @returns {object}
 */

function FabricParentClick() {
  return {
    scope: {
      parentClick: "&"
    },
    link: FabricParentClickLink
  };
}
/**
 * Fabric parent click link.
 *
 * @param {object} scope - Scope.
 * @param {object} element - Element.
 */


function FabricParentClickLink(scope, element) {
  element.mousedown(function () {
    scope.$parent.fabric.deactivateAll();
    setTimeout(function () {
      return scope.parentClick();
    });
  }).children().mousedown(function (event) {
    return event.stopPropagation();
  });
}

angular.module("app.generator").directive("fabricParentClick", FabricParentClick);