"use strict";
/**
 * Wholesaler purchases analysis service.
 *
 * @param {object} notifyUserService - Notify user service.
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

WholesalerPurchasesAnalysisService.$inject = ["notifyUserService", "apiClient"];

function WholesalerPurchasesAnalysisService(notifyUserService, apiClient) {
  return {
    /**
     * Get statistics.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getStats: function getStats(params) {
      return apiClient.post("/analysis/wholesalers/purchases", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get PharmaML.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getPharmaML: function getPharmaML(params) {
      return apiClient.post("/analysis/wholesalers/purchases/pharmaML", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Save simulation.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    saveSimulation: function saveSimulation(params) {
      return apiClient.post("/analysis.wholesalers/purchases/".concat(params.id, "/save"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      }).then(function (response) {
        notifyUserService.showSimpleToast("La simulation a été enregistrée avec succès", {
          color: "success",
          hideDelay: 1000
        });
        return response.simulation;
      });
    },

    /**
     * Read simulation.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readSimulation: function readSimulation(params) {
      return apiClient.get("/analysis/wholesalers/purchases/".concat(params.id, "/read"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.simulation;
      });
    },

    /**
     * Check if the user has accepted the contract.
     *
     * @returns {Promise}
     */
    getMemberStatus: function getMemberStatus() {
      return apiClient.get("/grouping/members/packs?include=organization.packSubscriptions.pack").then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics.salesAnalysis").service("wholesalerPurchasesAnalysisService", WholesalerPurchasesAnalysisService);