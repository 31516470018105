"use strict";
/**
 * Dashboard grouping pending requests controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} teamService - Team service.
 */

DashboardGroupPendingRequestsController.$inject = ["$scope", "$state", "teamService"];

function DashboardGroupPendingRequestsController($scope, $state, teamService) {
  loader();
  $scope.pendingRequests = [];
  $scope.goToTeamManager = goToTeamManager;
  /**
   * Loader.
   */

  function loader() {
    loadPendingRequests();
  }
  /**
   * Load the heatlhcenter members of the grouping
   */


  function loadPendingRequests() {
    teamService.getPendingRequests().then(function (response) {
      $scope.pendingRequests = response.pendingRequests;
    });
  }
  /**
   * change state to go to the team manager
   */


  function goToTeamManager() {
    $state.go("app.groupingMembers");
  }
}

angular.module("app.dashboard").controller("dashboardGroupPendingRequestsController", DashboardGroupPendingRequestsController);