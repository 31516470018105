"use strict";
/**
 * Sales area profitability controller.
 *
 * @param {object} salesAreaManagementService - Sales area management service.
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 */

SalesAreaProfitabilityController.$inject = ["salesAreaManagementService", "$scope", "$state"];

function SalesAreaProfitabilityController(salesAreaManagementService, $scope, $state) {
  $scope.goToDetail = goToDetail;
  $scope.getLength = getLength;
  /**
   * Order && filter the profitabilities
   */

  $scope.selected = [];
  $scope.limitOptions = [5, 10, 15];
  $scope.query = {
    order: "-startDate",
    limit: 15,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    getProfitability();
  }
  /**
   * Get profitability.
   */


  function getProfitability() {
    $scope.loading = true;
    salesAreaManagementService.getProfitabilities().then(function (profitabilities) {
      angular.forEach(profitabilities, function (profitability) {
        var averageProfitability = 0;
        var len = 0;
        angular.forEach(profitability, function (prof) {
          if (prof.profitability) {
            len++;
            averageProfitability += prof.profitability;
          }

          if (new Date(prof.start_date) <= new Date() && new Date(prof.end_date) >= new Date()) {
            profitability.actualProfitability = prof.profitability;
          }
        });

        if (len) {
          profitability.averageProfitability = averageProfitability / len;
        }
      });
      $scope.profitabilities = profitabilities;
      $scope.loading = false;
    });
  }
  /**
   * Get object length.
   *
   * @param {object} profitabilities - Profitabilities.
   *
   * @returns {number}
   */


  function getLength(profitabilities) {
    var len = 0;
    angular.forEach(profitabilities, function (prof) {
      if (prof.type) {
        len++;
      }
    });
    return len;
  }
  /**
   * Go to profitability detail
   *
   * @param {number} id - ID.
   * @param {object} profitability - Profitability.
   */


  function goToDetail(id, profitability) {
    $state.go("app.salesAreaManagement.profitability.detail", {
      profitability: JSON.stringify(profitability),
      id: id
    });
  }
}

angular.module("app.salesAreaManagement").controller("salesAreaProfitabilityController", SalesAreaProfitabilityController);