"use strict";

AppointmentService.$inject = ["hdsApiClient"];

/**
 * The appointment service.
 *
 * @param {object} hdsApiClient - The HDS APU Client service.
 *
 * @returns {object}
 */
function AppointmentService(hdsApiClient) {
  return {
    /**
     * Index appointments for the current healthcenter.
     *
     * @param {object} params - Query params.
     *
     * @returns {Promise}
     */
    index: function index(params) {
      return hdsApiClient("GET", "/healthcenter/appointments", {
        params: params
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update an appointment.
     *
     * @param {number} appointmentId - The appointment id.
     * @param {object} data - The data.
     *
     * @returns {Promise}
     */
    update: function update(appointmentId, data) {
      return hdsApiClient("PATCH", "/appointments/".concat(appointmentId), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Index customer's appointments
     *
     * @param {number} customerId - The customer id.
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    indexCustomerAppointments: function indexCustomerAppointments(customerId, params) {
      return hdsApiClient("GET", "/customers/".concat(customerId, "/appointments"), {
        params: params
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Transfrom appointments for calendar.
     *
     * @param {Array} appointments - The appointments array.
     *
     * @returns {Array} The transformed array.
     */
    transformAppointmentsForCalendar: function transformAppointmentsForCalendar(appointments) {
      return appointments.map(function (appointment) {
        var patient = appointment.beneficiary || appointment.customer;
        appointment.originalTitle = appointment.title;
        appointment.title = patient.information.name;
        appointment.start = moment(appointment.start_at);
        appointment.end = moment(appointment.end_at);
        appointment.accepted_at = appointment.accepted_at ? moment(appointment.accepted_at) : null;
        appointment.refused_at = appointment.refused_at ? moment(appointment.refused_at) : null;
        appointment.className = "appointment pointer";
        appointment.type = "appointment";
        appointment.allDay = false;
        return appointment;
      });
    }
  };
}

angular.module("app.patients.services").service("patientAppointmentsService", AppointmentService);