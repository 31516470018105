"use strict";
/**
 * Branding slider esrvice.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

BrandingSliderService.$inject = ["apiClient"];

function BrandingSliderService(apiClient) {
  return {
    /**
     * Read.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/groupings/".concat(params.groupingBrandingId, "/branding/sliders/").concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.groupingBrandingSlider;
      });
    },

    /**
     * Read by type id.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readByTypeId: function readByTypeId(params) {
      return apiClient.get("/groupings/".concat(params.groupingBrandingId, "/branding/sliders/type/").concat(params.groupingBrandingSliderTypeId), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.groupingBrandingSlider;
      });
    },

    /**
     * Post.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    post: function post(params) {
      return apiClient.post("/groupings/".concat(params.groupingBrandingId, "/branding/sliders/"), params, {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.groupingBrandingSlider;
      });
    },

    /**
     * Update.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/groupings/".concat(params.groupingBrandingId, "/branding/sliders/").concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.groupingBrandingSlider;
      });
    },

    /**
     * Delete.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/groupings/".concat(params.groupingBrandingId, "/branding/sliders/").concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.groupingBrandingSlider;
      });
    },

    /**
     * Post slide.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    postSlide: function postSlide(params) {
      return apiClient.post("/groupings/".concat(params.groupingId, "/branding/sliders/").concat(params.groupingBrandingSliderId, "/slides"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.groupingBrandingSliderSlide;
      });
    },

    /**
     * Put slide.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    putSlide: function putSlide(params) {
      return apiClient.put("/groupings/".concat(params.groupingId, "/branding/sliders/").concat(params.groupingBrandingSliderId, "/slides/").concat(params.id), params).then(function (response) {
        return response.data.groupingBrandingSliderSlide;
      });
    },

    /**
     * Delete slide.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    deleteSlide: function deleteSlide(params) {
      return apiClient["delete"]("/groupings/".concat(params.groupingId, "/branding/sliders/").concat(params.groupingBrandingSliderId, "/slides/").concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.groupingBrandingSliderSlide;
      });
    }
  };
}

angular.module("app.grouping").service("brandingSliderService", BrandingSliderService);