"use strict";
/**
 * The app-laboratory-cart directive.
 *
 * @returns {object}
 */

AppLaboratoryCartController.$inject = ["$scope", "$rootScope", "$stateParams", "$mdToast", "apiurl", "sessionStorageService", "cartService"];

function AppLaboratoryCart() {
  return {
    restrict: "E",
    scope: {
      icon: "@icon",
      iconColor: "=iconColor",
      branding: "=branding"
    },
    templateUrl: "app/main/directives/app-laboratory-cart/appLaboratoryCart.html",
    controllerAs: "vm",
    controller: AppLaboratoryCartController
  };
}
/**
 * The app-laboratory-cart controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdToast - $mdToast.
 * @param {string} apiurl - API URL.
 * @param {object} sessionStorageService - Session storage service.
 * @param {object} cartService - Cart service.
 */


function AppLaboratoryCartController($scope, $rootScope, $stateParams, $mdToast, apiurl, sessionStorageService, cartService) {
  /**
   * Declaration of public objects
   */
  $scope.apiurl = apiurl;
  $scope.token = sessionStorageService.getToken();
  $scope.query = {
    order: "-name",
    limit: 10,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.myBasket = {
    open: false,
    counter: 0
  };
  /**
   * Declaration of public methods
   */

  $scope.toggleMyBasket = toggleMyBasket;
  $scope.updateCart = updateCart;
  $scope.clearBasket = clearBasket;
  $scope.getLaboratoryCart = getLaboratoryCart;
  /**
   * Get laboratory cart.
   *
   * @returns {Promise}
   */

  function getLaboratoryCart() {
    var query = {
      laboratoryId: $stateParams.laboratoryId,
      healthcenterId: $rootScope.environment.entity.id
    };
    return cartService.getCart(query).then(function (cart) {
      $scope.myBasket.counter = 0;
      $scope.myBasket.cart = cart.lines.map(function (line) {
        $scope.myBasket.counter = $scope.myBasket.counter + line.quantity;
        angular.merge(line.product, line.product.laboratoryProductPrices[0]);
        line.product.discountPrice = line.product.discountPercentage ? line.product.manufacturerPriceWithoutVat * line.product.discountPercentage / 100 : line.product.manufacturerPriceWithoutVat;
        return line;
      }).reverse();
      return cart;
    });
  }
  /**
   * Toggle my basket.
   */


  function toggleMyBasket() {
    $scope.myBasket.open = !$scope.myBasket.open;
  }
  /**
   * Update cart.
   *
   * @param {object} product -
   * @returns {Promise}
   */


  function updateCart(product) {
    var order = {};
    product.id = product.productId ? product.productId : product.id;
    order[product.id] = product.quantity;
    var query = {
      laboratoryId: $stateParams.laboratoryId,
      healthcenterId: $rootScope.environment.entity.id,
      products: order
    };
    return cartService.manageCart(query).then(function (response) {
      product.quantity = product.quantity ? product.quantity : 0;
      $rootScope.$broadcast("cart:update");
      return response;
    });
  }
  /**
   * Clear basket.
   *
   * @returns {Promise}
   */


  function clearBasket() {
    var query = {
      laboratoryId: $rootScope.laboratoryBranding.laboratory.id,
      healthcenterId: $rootScope.environment.entity.id
    };
    return cartService.emptyCart(query).then(function (cart) {
      $scope.myBasket.counter = 0;
      $scope.myBasket.cart = [];
      $rootScope.$broadcast("cart:clear");
      return cart;
    });
  }

  $rootScope.$watch("laboratoryCart", function (laboratoryCart) {
    if (laboratoryCart) {
      $scope.laboratoryCart = laboratoryCart;
    }
  });
  $rootScope.$watch("environment", function (environment) {
    if (environment && $stateParams.laboratoryId) {
      getLaboratoryCart();
    }
  });
  $rootScope.$on("cart:update", function () {
    if ($stateParams.laboratoryId) {
      getLaboratoryCart().then(function () {
        $mdToast.show($mdToast.simple().textContent("Produit ajouté au panier.").position("top right"));
      });
    }
  });
  $rootScope.$on("cart:delete", function () {
    if ($stateParams.laboratoryId) {
      getLaboratoryCart().then(function () {
        $mdToast.show($mdToast.simple().textContent("Produit supprimé du panier.").position("top right"));
      });
    }
  });
  $rootScope.$on("cart:close", function () {
    $scope.myBasket.open = false;
  });
}

angular.module("app.directives").directive("appLaboratoryCart", AppLaboratoryCart);