"use strict";
/**
 * The direct offer service.
 *
 * @param {object} apiClient - API client..
 *
 * @returns {object} Direct offer service.
 */

DirectOfferService.$inject = ["apiClient"];

function DirectOfferService(apiClient) {
  return {
    /**
     * Get direct offer parameters.
     *
     * @param {object} data - The laboratory
     *
     * @returns {Promise} Direct offer parameters promise.
     */
    getDirectOfferParameters: function getDirectOfferParameters(data) {
      var query = {
        includes: ["laboratory"]
      };
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/directOfferParameters"), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get direct offer shops.
     *
     * @returns {Promise} - Direct offer parameters promise.
     */
    getDirectOfferShops: function getDirectOfferShops() {
      return apiClient.get("/laboratories/direct-offers/shop").then(function (response) {
        return response.data;
      });
    },

    /**
     * Create the direct offer parameter.
     *
     * @param {object} data - The direct offer parameter.
     *
     * @returns {Promise} Direct offer parameters creation promise.
     */
    createDirectOfferParameters: function createDirectOfferParameters(data) {
      return apiClient.post("/laboratories/".concat(data.laboratoryId, "/directOfferParameters"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update the direct offer parameter.
     *
     * @param {object} data - The direct offer parameter.
     *
     * @returns {Promise} Direct offer parameters update promise.
     */
    updateDirectOfferParameters: function updateDirectOfferParameters(data) {
      var params = {
        includes: ["laboratory"],
        data: data.shops,
        laboratory: data.laboratory
      };
      return apiClient.put("/laboratories/".concat(data.laboratoryId, "/directOfferParameters"), params).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get francos.
     *
     * @param {object} data - The laboratory
     *
     * @returns {Promise} Francos promise.
     */
    getFrancos: function getFrancos(data) {
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/francos")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a franco.
     *
     * @param {object} data - The franco
     *
     * @returns {Promise} Franco promise.
     */
    readFranco: function readFranco(data) {
      var query = {
        includes: ["healthcentersLight", "productsLight"]
      };
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/francos/").concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a franco.
     *
     * @param {object} data - The franco.
     *
     * @returns {Promise} Franco creation promise.
     */
    createFranco: function createFranco(data) {
      var query = {
        includes: ["healthcenters", "products"],
        data: data
      };
      return apiClient.post("/laboratories/".concat(data.laboratoryId, "/francos"), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a franco.
     *
     * @param {object} data - The franco
     *
     * @returns {Promise} Franco update promise.
     */
    updateFranco: function updateFranco(data) {
      var query = {
        includes: ["healthcenters", "products"],
        data: data
      };
      return apiClient.put("/laboratories/".concat(data.laboratoryId, "/francos/").concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a franco.
     *
     * @param {object} data - The franco
     *
     * @returns {Promise} Franco deletion promise.
     */
    deleteFranco: function deleteFranco(data) {
      var query = {
        includes: ["healthcenters", "products"]
      };
      return apiClient["delete"]("/laboratories/".concat(data.laboratoryId, "/francos/").concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get price ranges.
     *
     * @param {object} data - The laboratory.
     *
     * @returns {Promise} Price ranges promise.
     */
    getPriceRanges: function getPriceRanges(data) {
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/priceRanges")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a price range.
     *
     * @param {object} data - The price range.
     *
     * @returns {Promise} Price range promise.
     */
    readPriceRange: function readPriceRange(data) {
      var query = {
        includes: ["healthcentersLight", "productsLight", "productsLight.laboratoryPrice"]
      };
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/priceRanges/").concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a price range.
     *
     * @param {object} data - The price range
     *
     * @returns {Promise} Price range creation promise.
     */
    createPriceRange: function createPriceRange(data) {
      var query = {
        includes: ["healthcenters", "products"],
        data: data
      };
      return apiClient.post("/laboratories/".concat(data.laboratoryId, "/priceRanges"), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a price range.
     *
     * @param {object} data - The price range.
     *
     * @returns {Promise} Price range update promise.
     */
    updatePriceRange: function updatePriceRange(data) {
      var query = {
        includes: ["healthcenters", "products"],
        data: data
      };
      return apiClient.put("/laboratories/".concat(data.laboratoryId, "/priceRanges/").concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a price range.
     *
     * @param {object} data - The price range.
     *
     * @returns {Promise} Price range deletion promise.
     */
    deletePriceRange: function deletePriceRange(data) {
      var query = {
        includes: ["healthcenters", "products"]
      };
      return apiClient["delete"]("/laboratories/".concat(data.laboratoryId, "/priceRanges/").concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get pharmaML parameters.
     *
     * @param {object} data - The laboratory.
     * @param {object} page - Page number.
     * @param {object} perPage - Element per page.
     *
     * @returns {Promise} PharmaML parameters promise.
     */
    getPharmaMlParameters: function getPharmaMlParameters(data, page, perPage) {
      var query = {
        includes: ["healthcenter"],
        parameters: [{
          name: "page",
          value: page
        }, {
          name: "perPage",
          value: perPage
        }],
        wrappers: {
          response: null
        }
      };
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/pharmaMlParameters"), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a pharmaML parameter.
     *
     * @param {object} data - The pharmaML parameter.
     *
     * @returns {Promise} PharmaML parameter promise.
     */
    readPharmaMlParameter: function readPharmaMlParameter(data) {
      var query = {
        includes: ["healthcenter"]
      };
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/pharmaMlParameters/").concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a pharmaML parameter.
     *
     * @param {object} data - The pharmaML parameter
     *
     * @returns {Promise} PharmaML creation promise.
     */
    createPharmaMlParameter: function createPharmaMlParameter(data) {
      var query = {
        includes: ["healthcenter"],
        data: data
      };
      return apiClient.post("/laboratories/".concat(data.laboratoryId, "/pharmaMlParameters"), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a pharmaML parameter.
     *
     * @param {object} data - The pharmaML parameter.
     *
     * @returns {Promise} PharmaML parameter update promise.
     */
    updatePharmaMlParameter: function updatePharmaMlParameter(data) {
      var query = {
        includes: ["healthcenter"],
        data: data
      };
      return apiClient.put("/laboratories/".concat(data.laboratoryId, "/pharmaMlParameters/").concat(data.id), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete pharmaML parameters.
     *
     * @param {object} data - The data.
     *
     * @returns {Promise} PharmaML parameter update promise.
     */
    deletePharmaMlParameters: function deletePharmaMlParameters(data) {
      return apiClient["delete"]("/laboratories/".concat(data.laboratoryId, "/pharmaMlParameters/")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the shop laboratory orders.
     *
     * @param {object} data - The laboratory.
     *
     * @returns {Promise} LShop laboratory orders promise.
     */
    getShopLaboratoryOrders: function getShopLaboratoryOrders(data) {
      return apiClient.get("/shop/laboratories/".concat(data.laboratoryId, "/orders")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get sold out alert parameters.
     *
     * @param {object} data - The laboratory.
     *
     * @returns {Promise}
     */
    getSoldOutAlertParameters: function getSoldOutAlertParameters(data) {
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/soldOutAlertParameters")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Set sold out alert parameters.
     *
     * @param {object} data - The laboratory.
     *
     * @returns {Promise} Sold out parameters update promise.
     */
    setSoldOutAlertParameters: function setSoldOutAlertParameters(data) {
      return apiClient.post("/laboratories/".concat(data.laboratoryId, "/soldOutAlertParameters"), {
        data: data
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get direct offer notifications sent by the given laboratory.
     *
     * @param {object} laboratory - Laboratory sender.
     * @param {object} filters - Filters to apply.
     *
     * @returns {Promise} Notifications promise.
     */
    getDirectOfferNotificationsSummary: function getDirectOfferNotificationsSummary(laboratory, filters) {
      return apiClient.post("/laboratories/".concat(laboratory.laboratoryId, "/direct-offers/notifications"), filters).then(function (response) {
        return response.data;
      });
    },

    /**
     * Send a summary of notifications sent by a laboratory.
     *
     * @param {object} laboratory - Laboratory sender.
     * @param {object} filters - Filters to apply.
     *
     * @returns {Promise} Summary sending status promise.
     */
    sendDirectOfferNotificationsSummary: function sendDirectOfferNotificationsSummary(laboratory, filters) {
      return apiClient.post("/laboratories/".concat(laboratory.laboratoryId, "/direct-offers/notifications/summaries/send"), filters).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.directOffer").service("directOfferService", DirectOfferService);