"use strict";
/**
 * Contacts configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

ContactsConfig.$inject = ["$stateProvider"];

function ContactsConfig($stateProvider) {
  $stateProvider.state("app.contacts", {
    url: "/contacts",
    views: {
      "content@app": {
        templateUrl: "app/main/contacts/views/list/list.html",
        controller: "contactListController"
      }
    }
  });
}

angular.module("app.contacts", []).config(ContactsConfig);