"use strict";

(function () {
  'use strict';

  angular.module('apodis').constant('apiurl', 'https://api.apodispharma.com').constant('liveUrl', 'https://live.apodispharma.com').constant('gtagKeyId', 'UA-128200648-1').constant('stripeKey', 'pk_test_J6d6hgLwfYKtnQTNYPEulQFL').constant('hdsApiConfig', {
    url: 'https://proxy.santesecure.com/api'
  }).constant('popupConfig', {
    url: 'https://connect-updater.apodispharma.com/download/win'
  });
})();