"use strict";
/**
 * Laboratory range service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

LaboratoryRangeService.$inject = ["apiClient"];

function LaboratoryRangeService(apiClient) {
  return {
    /**
     * get
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/ranges"), {
        includes: ["branding.logo", "branding.background", "branding.sliders.file", "products.laboratoryPrice", "childrenRanges.products.laboratoryPrice", "childrenRanges.branding.logo", "childrenRanges.branding.background"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * read
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id), {
        includes: ["branding.logo", "branding.background", "branding.backgroundMobile", "branding.sliders.file", "products.laboratoryPrice", "childrenRanges.products.laboratoryPrice", "childrenRanges.branding.logo", "childrenRanges.branding.background", "childrenRanges.branding.backgroundMobile"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * create
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    create: function create(params) {
      return apiClient.post("/laboratories/".concat(params.laboratoryId, "/ranges"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.range;
      });
    },

    /**
     * update
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id), params, {
        includes: ["branding.logo", "branding.background", "branding.backgroundMobile", "branding.sliders.file", "products.laboratoryPrice", "childrenRanges.products.laboratoryPrice", "childrenRanges.branding.logo", "childrenRanges.branding.background", "childrenRanges.branding.backgroundMobile"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * delete
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * getBranding
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    getBranding: function getBranding(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.rangeId, "/branding"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.rangeBranding;
      });
    },

    /**
     * readBranding
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    readBranding: function readBranding(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.rangeId, "/branding/").concat(params.brandingId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.rangeBranding;
      });
    },

    /**
     * createBranding
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    createBranding: function createBranding(params) {
      return apiClient.post("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.rangeId, "/branding"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.rangeBranding;
      });
    },

    /**
     * updateBranding
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    updateBranding: function updateBranding(params) {
      return apiClient.put("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.rangeId, "/branding/").concat(params.brandingId), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.rangeBranding;
      });
    },

    /**
     * deleteBranding
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    deleteBranding: function deleteBranding(params) {
      return apiClient["delete"]("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.rangeId, "/branding/").concat(params.brandingId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.rangeBranding;
      });
    },

    /**
     * getProducts
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    getProducts: function getProducts(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id, "/products"), {
        includes: ["laboratoryPrice", "delivrance", "genericType", "notice", "override", "refund"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.products;
      });
    },

    /**
     * assignProduct
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    assignProduct: function assignProduct(params) {
      return apiClient.post("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id, "/products/assign"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * unassignProduct
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    unassignProduct: function unassignProduct(params) {
      return apiClient["delete"]("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.id, "/products/unassign"), {
        wrappers: {
          response: null
        }
      }, params).then(function (response) {
        return response.data;
      });
    },

    /**
     * getSlider
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    getSlider: function getSlider(params) {
      return apiClient.get("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.rangeId, "/sliders"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.sliders;
      });
    },

    /**
     * createSlider
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    createSlider: function createSlider(params) {
      return apiClient.post("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.rangeId, "/sliders"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.sliders;
      });
    },

    /**
     * updateSlider
     *
     * @param {object} params - Params
     *
     * @returns {Promise}
     */
    updateSlider: function updateSlider(params) {
      return apiClient.put("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.rangeId, "/sliders/").concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.sliders;
      });
    },

    /**
     * deleteSlider
     *
     * @param {object} params - Param
     * @returns {Promise}
     */
    deleteSlider: function deleteSlider(params) {
      return apiClient["delete"]("/laboratories/".concat(params.laboratoryId, "/ranges/").concat(params.rangeId, "/sliders/").concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.sliders;
      });
    }
  };
}

angular.module("app.laboratory").service("laboratoryRangeService", LaboratoryRangeService);