"use strict";
/**
 * Sales analysis top sales product service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

salesAnalysisTopSalesProductService.$inject = ["apiClient"];

function salesAnalysisTopSalesProductService(apiClient) {
  return {
    /**
     * Post.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    post: function post(params) {
      return apiClient.post("/analysis/topSalesProduct", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.aggregations.top10Product.buckets;
      });
    }
  };
}

angular.module("app.statistics.salesAnalysis").service("salesAnalysisTopSalesProductService", salesAnalysisTopSalesProductService);