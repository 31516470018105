"use strict";
/**
 * List layers directive.
 *
 * @returns {object}
 */

ListLayersCtrl.$inject = ["$scope", "$rootScope"];

function listLayers() {
  return {
    restrict: "E",
    scope: {
      layers: "=layers",
      fonts: "=fonts",
      fabric: "=env",
      isMaster: "=master",
      access: "=access",
      document: "=document"
    },
    templateUrl: "app/main/generator/directives/listLayers/listLayers.html",
    controller: ListLayersCtrl
  };
}
/**
 * List layers controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 */


function ListLayersCtrl($scope, $rootScope) {
  $scope.selectTargetObject = selectTargetObject;
  $scope.opacity = opacity;
  $scope.setFontFamily = setFontFamily;
  $scope.setText = setText;
  $scope.setFill = setFill;
  /**
   * selectTargetObject
   *
   * @param {object} layer - layer
   */

  function selectTargetObject(layer) {
    $scope.layers.forEach(function (myLayer) {
      if (myLayer !== layer) {
        myLayer.active = false;
        myLayer.edit = false;
      }
    });

    if (layer.active) {
      layer.active = false;
      layer.edit = false;
      $scope.fabric.deactivateAll();
      return;
    }

    layer.active = true;
    layer.edit = true;
    $scope.fabric.selectTargetObject(layer);
  }
  /**
   * Set opacity.
   *
   * @param {number} opacity - Opacity.
   */


  function opacity(opacity) {
    $scope.fabric.setOpacity(opacity);
  }
  /**
   * Set font family.
   *
   * @param {string} font - Font.
   */


  function setFontFamily(font) {
    $scope.fabric.setFontFamily(font);
  }
  /**
   * Set text.
   *
   * @param {string} text - Text.
   */


  function setText(text) {
    $scope.fabric.setText(text);
  }
  /**
   * Set fill.
   *
   * @param {string} fill - Fill.
   */


  function setFill(fill) {
    $scope.fabric.setFill(fill);
  }

  $rootScope.$on("activeObject", function (event, object) {
    if (object && $scope.layers) {
      if ($scope.layers.length === 1) {
        if ($scope.layers[0].id === object.id) {
          $scope.layers[0].active = true;
        }
      } else {
        $scope.layers.forEach(function (layer) {
          if (layer.id === object.id) {
            layer.active = true;
          } else {
            layer.active = false;
          }
        });
      }
    }
  });
  $rootScope.$on("deactivateObject", function () {
    $scope.layers.forEach(function (layer) {
      layer.active = false;
    });
  });
  $rootScope.$on("fabric.directive:mousedown", function (event, objects) {
    $scope.layers = objects;
  });
}

angular.module("app.generator").directive("listLayers", listLayers);