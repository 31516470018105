"use strict";
/**
 * Impact analysis create controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 * @param {object} impactAnalysisService - Impact analysis service.
 * @param {object} eventService - Events service.
 * @param {object} productService - Product service.
 * @param {object} healthcenterService - Healthcenter service.
 */

ImpactAnalysisCreateController.$inject = ["$scope", "$stateParams", "$state", "impactAnalysisService", "eventService", "productService", "healthcenterService"];

function ImpactAnalysisCreateController($scope, $stateParams, $state, impactAnalysisService, eventService, productService, healthcenterService) {
  $scope.limitOptions = [5, 10, 15];
  $scope.query = {
    order: "name",
    limit: 10,
    page: 1
  };
  $scope.impactAnalysis = {};
  $scope.configSearchBar = {
    events: {
      event: "impactAnalysisEvents",
      search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
      tag: "impactAnalysisEventsChange",
      geoForce: true,
      searchPlaceHolder: "Rechercher un événement...",
      autoLaunchEvent: false
    },
    products: {
      event: "impactAnalysisProducts",
      search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
      tag: "impactAnalysisProductsChange",
      geoForce: true,
      searchPlaceHolder: "Rechercher un produit...",
      autoLaunchEvent: false
    },
    market: {
      event: "impactAnalysisMarket",
      search: ["idProducts", "range.id", "laboratory.id", "category.levelX.id", "generic.id", "segmentProduct"],
      tag: "impactAnalysisMarketChange",
      geoForce: true,
      searchPlaceHolder: "Rechercher un marché...",
      autoLaunchEvent: false
    },
    targets: {
      event: "impactAnalysisTargets",
      search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
      tag: "impactAnalysisTargetsChange",
      geoForce: true,
      searchPlaceHolder: "Rechercher une cible...",
      autoLaunchEvent: false
    },
    controlSegment: {
      event: "impactAnalysisControlSegment",
      search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
      tag: "impactAnalysisControlSegmentChange",
      geoForce: true,
      searchPlaceHolder: "Rechercher un segment témoin...",
      autoLaunchEvent: false
    },
    event: {
      event: "impactAnalysisEvents",
      tag: "events",
      autoLaunchEvent: false
    }
  };
  $scope.isEditing = false;
  $scope.save = save;
  $scope.deleteEvent = deleteEvent;
  $scope.deleteProduct = deleteProduct;
  $scope.deleteProductFromMarket = deleteProductFromMarket;
  $scope.deleteTarget = deleteTarget;
  $scope.deleteControlSegment = deleteControlSegment;
  loader();
  /**
   * loader
   * Load each methods inside
   */

  function loader() {
    if (!$stateParams.id) {
      $scope.impactAnalysis.events = [];
      $scope.impactAnalysis.products = [];
      $scope.impactAnalysis.market = [];
      $scope.impactAnalysis.targets = [];
      $scope.impactAnalysis.controlSegment = [];
      $scope.loader = {
        products: {
          loading: false,
          empty: "Ajouter un ou des produits pour l'analyse"
        },
        market: {
          loading: false,
          empty: "Ajouter un ou des marchés pour l'analyse"
        },
        targets: {
          loading: false,
          empty: "Ajouter une ou des cibles pour l'analyse"
        },
        controlSegment: {
          loading: false,
          empty: "Ajouter un ou des segments témoins pour l'analyse"
        },
        events: {
          loading: false,
          empty: "Ajouter un ou des événements pour l'analyse"
        }
      };
    } else {
      $scope.isEditing = true;
      $scope.loader = {
        products: {
          loading: true,
          loader: "Récupération des produits pour l'analyse",
          empty: "Ajouter un ou des produits pour l'analyse"
        },
        market: {
          loading: true,
          loader: "Récupération des marchés pour l'analyse",
          empty: "Ajouter un ou des marchés pour l'analyse"
        },
        targets: {
          loading: true,
          loader: "Récupération des cibles pour l'analyse",
          empty: "Ajouter une ou des cibles pour l'analyse"
        },
        controlSegment: {
          loading: true,
          loader: "Récupération des segments témoins pour l'analyse",
          empty: "Ajouter un ou des segments témoins pour l'analyse"
        },
        events: {
          loading: true,
          loader: "Récupération des événements pour l'analyse",
          empty: "Ajouter un ou des événements pour l'analyse"
        }
      };
      readAnalysis();
    }
  }
  /**
   * readAnalysis
   * Read $stateParams.id analysis
   *
   * @returns {Promise}
   */


  function readAnalysis() {
    return impactAnalysisService.read($stateParams).then(function (response) {
      response.start_date = new Date(response.start_date);
      response.end_date = new Date(response.end_date);
      $scope.impactAnalysis = response;
      $scope.loader.products.loading = false;
      $scope.loader.market.loading = false;
      $scope.loader.targets.loading = false;
      $scope.loader.controlSegment.loading = false;
      $scope.loader.events.loading = false;
      return response;
    });
  }
  /**
   * save
   * Check if $stateParams.id to do save or update
   *
   * @returns {Promise}
   */


  function save() {
    if (!$stateParams.id) {
      return impactAnalysisService.create($scope.impactAnalysis).then(function (response) {
        $scope.impactAnalysis = response;
        $state.go("app.impactAnalysis");
        return response;
      });
    }

    return impactAnalysisService.update($scope.impactAnalysis).then(function (response) {
      response.start_date = new Date(response.start_date);
      response.end_date = new Date(response.end_date);
      $scope.impactAnalysis = response;
      return response;
    });
  }
  /**
   * getProductsFromSearchBar
   * Get product(s)
   *
   * @param {Array} args - products
   *
   * @returns {Promise}
   */


  function getProductsFromSearchBar(args) {
    var queryProduct = {};
    var productsToReturn = [];

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(args, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(args[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in args) {
      _loop(type);
    } // getProducts with query results


    return productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find(productsToReturn, function (prod) {
          return product.id === prod.id;
        })) {
          productsToReturn.push(product);
        }
      });
      return productsToReturn;
    });
  }
  /**
   * getEventsFromSearchBar
   * Get event(s)
   *
   * @param {Array} args - events
   *
   * @returns {Promise}
   */


  function getEventsFromSearchBar(args) {
    var eventIds = [];
    angular.forEach(_.values(args), function (arg) {
      angular.forEach(_.values(arg), function (ar) {
        eventIds.push(ar.id);
      });
    });
    var params = {
      ids: eventIds
    };
    return eventService.getEventsByIds(params).then(function (events) {
      events.forEach(function (event) {
        if (event.custom) {
          if (event.custom.organizations.length) {
            event.custom.organizations.forEach(function (healthcenter) {
              if (!_.find($scope.impactAnalysis.targets, function (pharma) {
                return pharma.id === healthcenter.morphable.id;
              })) {
                $scope.impactAnalysis.targets.push(healthcenter.morphable);
              }
            });
          }

          event.custom.products.forEach(function (product) {
            if (!_.find($scope.impactAnalysis.targets, function (impactProduct) {
              return impactProduct.id === product.id;
            })) {
              $scope.impactAnalysis.products.push(product);
            }
          });
        }
      });
      return events;
    });
  }
  /**
   * getHealthcenterFromSearchBar
   * Get healthcenter(s)
   *
   * @param {Array} args - pharmas
   *
   * @returns {Promise}
   */


  function getHealthcenterFromSearchBar(args) {
    var healthcenters = [];
    var queryPharma = {};
    queryPharma.type = {};

    var _loop2 = function _loop2(type) {
      if (Object.prototype.hasOwnProperty.call(args, type)) {
        if (typeof queryPharma.type[type] === "undefined") {
          queryPharma.type[type] = [];
        }

        angular.forEach(args[type], function (obj) {
          if (type === "uga") {
            queryPharma.type[type].push(obj.name);
          } else {
            queryPharma.type[type].push(obj.id);
          }
        });
      }
    };

    for (var type in args) {
      _loop2(type);
    } // getPharmas with query results


    return healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      angular.forEach(pharmas, function (pharma) {
        if (!_.find(healthcenters, function (healthcenter) {
          return pharma.id === healthcenter.id;
        })) {
          healthcenters.push(pharma);
        }
      });
      return healthcenters;
    });
  }
  /**
   * deleteProduct
   *
   * @param {object} event - Event.
   * @param {object} product - Product.
   */


  function deleteProduct(event, product) {
    event.preventDefault();
    $scope.impactAnalysis.products.splice($scope.impactAnalysis.products.findIndex(function (item) {
      return item.id === product.id;
    }), 1);
    save();
  }
  /**
   * deleteProductFromMarket
   *
   * @param {object} event - Event.
   * @param {object} productMarket - Product market.
   */


  function deleteProductFromMarket(event, productMarket) {
    event.preventDefault();
    $scope.impactAnalysis.targets.splice($scope.impactAnalysis.targets.findIndex(function (item) {
      return item.id === productMarket.id;
    }), 1);
    save();
  }
  /**
   * deleteTarget
   *
   * @param {object} event - Event.
   * @param {object} target - Target.
   */


  function deleteTarget(event, target) {
    event.preventDefault();
    $scope.impactAnalysis.targets.splice($scope.impactAnalysis.targets.findIndex(function (item) {
      return item.id === target.id;
    }), 1);
    save();
  }
  /**
   * deleteControlSegment
   *
   * @param {object} event - Event.
   * @param {object} controlSegment - Control segment.
   */


  function deleteControlSegment(event, controlSegment) {
    event.preventDefault();
    $scope.impactAnalysis.controlSegment.splice($scope.impactAnalysis.controlSegment.findIndex(function (item) {
      return item.id === controlSegment.id;
    }), 1);
    save();
  }
  /**
   * deleteEvents
   *
   * @param {object} domEvent - DOM event.
   * @param {object} event - Event.
   */


  function deleteEvent(domEvent, event) {
    domEvent.preventDefault();
    $scope.impactAnalysis.events.splice($scope.impactAnalysis.events.findIndex(function (item) {
      return item.id === event.id;
    }), 1);
    save();
  }
  /**
   * On product search validation, get all product Ids and name
   */


  $scope.$on("impactAnalysisProducts", function (event, args) {
    getProductsFromSearchBar(args).then(function (products) {
      products.forEach(function (product) {
        if (!_.find($scope.impactAnalysis.products, function (impactProd) {
          return impactProd.id === product.id;
        })) {
          $scope.impactAnalysis.products.push(product);
        }
      });
    });
  });
  /**
   * On market search validation, get all market Ids and name
   */

  $scope.$on("impactAnalysisMarket", function (event, args) {
    getProductsFromSearchBar(args).then(function (products) {
      products.forEach(function (product) {
        if (!_.find($scope.impactAnalysis.market, function (impactProd) {
          return impactProd.id === product.id;
        })) {
          $scope.impactAnalysis.market.push(product);
        }
      });
    });
  });
  /**
   * On targets search validation, get all targets Ids and name
   */

  $scope.$on("impactAnalysisTargets", function (event, args) {
    getHealthcenterFromSearchBar(args).then(function (healthcenters) {
      healthcenters.forEach(function (healthcenter) {
        if (!_.find($scope.impactAnalysis.targets, function (pharma) {
          return pharma.id === healthcenter.id;
        })) {
          $scope.impactAnalysis.targets.push(healthcenter);
        }
      });
    });
  });
  /**
   * On segments search validation, get all segments Ids and name
   */

  $scope.$on("impactAnalysisControlSegment", function (event, args) {
    getHealthcenterFromSearchBar(args).then(function (healthcenters) {
      healthcenters.forEach(function (healthcenter) {
        if (!_.find($scope.impactAnalysis.controlSegment, function (pharma) {
          return pharma.id === healthcenter.id;
        })) {
          $scope.impactAnalysis.controlSegment.push(healthcenter);
        }
      });
    });
  });
  /**
   * On events search validation, get all segments Ids and name
   */

  $scope.$on("impactAnalysisEvents", function (event, args) {
    getEventsFromSearchBar(args).then(function (events) {
      events.forEach(function (event) {
        if (!_.find($scope.impactAnalysis.events, function (impactEvent) {
          return impactEvent.id === event.id;
        })) {
          $scope.impactAnalysis.events.push(event);
        }
      });
    });
  });
}

angular.module("app.impactAnalysis").controller("impactAnalysisCreateController", ImpactAnalysisCreateController);