"use strict";
/**
 * Home slide service.
 *
 * @returns {object}
 */

function HomeSlideService() {
  return {
    sliderTop: [{
      src: "/assets/images/membership/bandeau-parrainage.jpg",
      title: "code parrain",
      sponsor: "code parrain"
    }, {
      src: "/assets/images/sellouts/2018-12/adiaril.jpg"
    }, {
      src: "/assets/images/sellouts/2018-12/lacteol.jpg"
    }, {
      src: "/assets/images/sellouts/2018-12/lipikar.jpg"
    }, {
      src: "/assets/images/sellouts/2018-12/prorhinel.jpg"
    }, {
      src: "/assets/images/sellouts/2018-12/rennie.jpg"
    }],
    sliders: [{
      src: "/assets/images/slider/nespresso-edenred.jpg",
      title: "Nespresso Initia",
      productPoint: "10 051"
    }, {
      src: "/assets/images/slider/longchamp-edenred.jpg",
      title: "Sac porté main pliage",
      productPoint: "6 271"
    }, {
      src: "/assets/images/slider/galaxy-edenred.jpg",
      title: "Galaxy Tab A",
      productPoint: "32 911"
    }],
    sliderDealing: [{
      src: "/assets/images/sliderapodis/mediflux.jpg"
    }, {
      src: "/assets/images/sliderapodis/nutricia.jpg"
    }, {
      src: "/assets/images/sliderapodis/fabre.jpg"
    }, {
      src: "/assets/images/sliderapodis/merck.jpg"
    }, {
      src: "/assets/images/sliderapodis/reckitt.jpg"
    }, {
      src: "/assets/images/sliderapodis/skills.jpg"
    }, {
      src: "/assets/images/sliderapodis/eztWrap.jpg"
    }, {
      src: "/assets/images/sliderapodis/nuck.jpg"
    }, {
      src: "/assets/images/sliderapodis/thuasne.jpg"
    }, {
      src: "/assets/images/sliderapodis/humex.jpg"
    }, {
      src: "/assets/images/sliderapodis/visiomed.jpg"
    }, {
      src: "/assets/images/sliderapodis/zambon.jpg"
    }]
  };
}

angular.module("app.home").factory("homeSlideService", HomeSlideService);