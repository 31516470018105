"use strict";
/**
 * The controller for the sales widget in pharmacy dashboard.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} StatisticsQueryService - The statistics query service object.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */

dashboardPharmaciesSalesController.$inject = ["$scope", "StatisticsQueryService", "environmentService", "analysisService"];

function dashboardPharmaciesSalesController($scope, StatisticsQueryService, environmentService, analysisService) {
  $scope.loading = true;
  loader();
  /**
   * The loader function
   */

  function loader() {
    loaderEnv().then(function () {
      initOptions();
    });
  }
  /**
   * init the widget options
   */


  function initOptions() {
    $scope.usrquery = StatisticsQueryService.pristineQuery;

    if ($scope.env.accessPointType === 2 && $scope.env.rankId !== 1 && $scope.env.rankId !== 2 && !_.find($scope.usrquery.must.operator.selected, function (operator) {
      return operator.id === $scope.env.operatorCode;
    })) {
      $scope.usrquery.must.operator.selected.push({
        id: $scope.env.operatorCode
      });
    }

    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      processSalesData().then(function (data) {
        $scope.data = data;
        handleData();
      });
    });
  }
  /**
   * load for environment
   *
   * @returns {Promise}
   */


  function loaderEnv() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.env = response;
      return response;
    });
  }
  /**
   * Retrieve the datum
   *
   * @returns {Promise}
   */


  function processSalesData() {
    return analysisService.getSales($scope.query);
  }
  /**
   * Handle the data.
   */


  function handleData() {
    var lines = $scope.data.aggregations.sales.buckets.map(function (datum) {
      return {
        date: datum.key,
        sales: datum.amount_excl_tax.value,
        quantity: datum.quantity.value,
        cart: datum.cartTotal.value
      };
    });
    var date = moment();
    var startDate = moment().date(0).valueOf();
    var currentMonth = moment().date(0).valueOf();
    var lastMonth = moment().date(0).subtract(1, "month").valueOf();
    $scope.totalCurrentMonth = 0;
    $scope.totalLastMonth = 0;
    $scope.totalPeriod = 0;
    $scope.cartTotal = 0;
    var count = 0;
    var countWithSales = 0;
    angular.forEach(lines, function (value) {
      $scope.totalPeriod += value.sales;
      $scope.cartTotal += value.cart;

      if (value.sales > 0) {
        countWithSales += 1;
      }

      if (value.date > currentMonth && value.date < date) {
        $scope.totalCurrentMonth += value.sales;
      }

      if (value.date > lastMonth && value.date < startDate) {
        $scope.totalLastMonth += value.sales;
        count += 1;
      }
    });
    $scope.averageSalesCurrentMonth = $scope.totalCurrentMonth / moment().date();
    $scope.averageSalesLastMonth = count ? $scope.totalLastMonth / count : 0;
    $scope.averagePerDay = countWithSales ? $scope.totalPeriod / countWithSales : 0;
    $scope.averageCartPerDay = countWithSales ? $scope.cartTotal / countWithSales : 0;
    $scope.loading = false;
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesSalesController", dashboardPharmaciesSalesController);