"use strict";
/**
 * Users prestation configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

UsersPrestationConfig.$inject = ["$stateProvider"];

function UsersPrestationConfig($stateProvider) {
  $stateProvider.state("app.UserPrestationHome", {
    url: "/users/prestation",
    views: {
      "content@app": {
        templateUrl: "app/main/users-prestation/views/list/users-prestation.html",
        controller: "UserPrestationController"
      }
    }
  }).state("app.UserPrestationHome.detail", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/users-prestation/views/detail/users-prestation-detail.html",
        controller: "UserPrestationDetailController as vm"
      }
    }
  });
}

angular.module("app.users-prestation", ["app.users-prestation-detail"]).config(UsersPrestationConfig);