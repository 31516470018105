"use strict";
/**
 * Sales area management products affectation controller.
 *
 * @param {object} salesAreaManagementService - Sales area management service.
 * @param {object} $scope - $scope.
 * @param {object} $window - $window.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $q - $q.
 * @param {object} productService - Product service.
 */

SalesAreaManagementProductsAffectationController.$inject = ["salesAreaManagementService", "$scope", "$window", "$stateParams", "$rootScope", "$mdDialog", "$q", "productService"];

function SalesAreaManagementProductsAffectationController(salesAreaManagementService, $scope, $window, $stateParams, $rootScope, $mdDialog, $q, productService) {
  $scope.goBack = goBack;
  $scope.deleteFromList = deleteFromList;
  $scope.affect = affect;
  $scope.addProductSalesArea = addProductSalesArea;
  $scope.deleteSalesAreaAffectation = deleteSalesAreaAffectation;
  $scope.editSalesAreaAffectation = editSalesAreaAffectation;
  $scope.salesAreaAffectation = {};
  $scope.salesAreaProductSearch = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    event: "salesAreaProductSearch",
    tag: "salesAreaProductSearch",
    autoLaunchEvent: true
  };
  $scope.titleConfig = {
    title: {
      icon: "icon-cart",
      text: "Affecter des produits",
      back: {
        state: "app.salesAreaManagement.list"
      }
    }
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    readSalesArea().then(affectSalesArea).then(getSalesAreaAffectation);
  }
  /**
   * Get sales area.
   *
   * @returns {Promise}
   */


  function readSalesArea() {
    var salesArea = JSON.parse($stateParams.salesArea) || {};

    if (salesArea.id) {
      return $q.when(salesArea);
    }

    return salesAreaManagementService.readSalesArea({
      id: $stateParams.id
    });
  }
  /**
   * Affect sales area.
   *
   * @param {object} salesArea - Sales area.
   */


  function affectSalesArea(salesArea) {
    $scope.salesArea = salesArea;
    $scope.titleConfig.title.text = "Affecter des produits à " + salesArea.name;
  }
  /**
   * Get sales area affectation
   */


  function getSalesAreaAffectation() {
    $scope.loading = true;
    salesAreaManagementService.getSalesAreaAffectations({
      id: $stateParams.id
    }).then(function (salesAreaAffectations) {
      $scope.salesAreaAffectations = salesAreaAffectations.salesAreaAffectations.map(function (salesAreaAffectation) {
        salesAreaAffectation.start_date = new Date(salesAreaAffectation.start_date);
        salesAreaAffectation.end_date = new Date(salesAreaAffectation.end_date);
        return salesAreaAffectation;
      });
      $scope.loading = false;
    });
  }
  /**
   * Add products affectation.
   *
   * @param {object} event - Event.
   */


  function addProductSalesArea(event) {
    $scope.salesAreaAffectation = {
      start_date: new Date(),
      end_date: new Date()
    };
    $mdDialog.show({
      templateUrl: "app/main/sales-area-management/views/productsAffectation/productsAffectationDialog.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      scope: $scope.$new()
    }).then(function (data) {
      postSalesAreaAffectation(data);
    });
  }
  /**
   * Edit an affectation.
   *
   * @param {object} event - Event.
   * @param {object} salesAreaAffectation - Sales area affectation.
   */


  function editSalesAreaAffectation(event, salesAreaAffectation) {
    $scope.start_date = new Date(salesAreaAffectation.start_date);
    $scope.end_date = new Date(salesAreaAffectation.end_date);
    $scope.salesAreaAffectation = salesAreaAffectation;
    $mdDialog.show({
      templateUrl: "app/main/sales-area-management/views/productsAffectation/productsAffectationDialog.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      scope: $scope.$new()
    }).then(function (data) {
      updateSalesAreaAffectation(data);
    });
  }
  /**
   * Delete a sale area affectation.
   *
   * @param {object} event - Event.
   */


  function deleteSalesAreaAffectation(event) {
    var confirm = $mdDialog.confirm().title("Souhaitez-vous supprimer cette affectation de produit ?").ariaLabel("Supprimer l'affectation").ok("Oui").cancel("Non");
    $mdDialog.show(confirm).then(function () {
      return salesAreaManagementService.deleteAffectation(event);
    }).then(function () {
      getSalesAreaAffectation();
    });
  }
  /**
   * Go back function
   */


  function goBack() {
    $window.history.back();
  }
  /**
   * Delete a product from the list.
   *
   * @param {Array} list - List.
   * @param {number} index - Index.
   */


  function deleteFromList(list, index) {
    list.splice(index, 1);
  }
  /**
   * Affect products.
   *
   * @param {object} data - Data.
   */


  function affect(data) {
    $mdDialog.hide(data);
  }
  /**
   * Create an affectation
   *
   * @param {object} data - Data.
   */


  function postSalesAreaAffectation(data) {
    data.start_date = moment(data.start_date).format("YYYY-MM-DD");
    data.end_date = moment(data.end_date).format("YYYY-MM-DD");
    data.sales_area_id = $stateParams.id;
    salesAreaManagementService.affectProducts(data).then(function (salesAreaAffectations) {
      $scope.salesAreaAffectation = salesAreaAffectations.salesAreaAffectation;
      getSalesAreaAffectation();
    });
  }
  /**
   * Edit an affectation.
   *
   * @param {object} data - Data.
   */


  function updateSalesAreaAffectation(data) {
    data.start_date = moment(data.start_date).format("YYYY-MM-DD");
    data.end_date = moment(data.end_date).format("YYYY-MM-DD");
    data.sales_area_id = $stateParams.id;
    salesAreaManagementService.updateAffectation(data).then(function (salesAreaAffectations) {
      $scope.salesAreaAffectation = salesAreaAffectations.salesAreaAffectation;
      getSalesAreaAffectation();
    });
  }
  /**
   * Search bar event
   */


  $rootScope.$on("salesAreaProductSearch", function (event, data) {
    var queryProduct = {};

    if (_.isUndefined($scope.salesAreaAffectation.products)) {
      $scope.salesAreaAffectation.products = [];
    }

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    } // getProducts with query results


    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.salesAreaAffectation.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.salesAreaAffectation.products.push(product);
        }
      });
    });
  });
  /**
   * Order && filter the sales area
   */

  $scope.selected = [];
  $scope.limitOptions = [5, 10, 15];
  $scope.query = {
    order: "-startDate",
    limit: 15,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
}

angular.module("app.salesAreaManagement").controller("salesAreaManagementProductsAffectationController", SalesAreaManagementProductsAffectationController);