"use strict";
/**
 * Pack detail controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdToast - $mdToast.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $window - $window.
 * @param {object} stripeKey - Stripe key.
 * @param {object} packDetailService - Pack detail service.
 * @param {object} environmentService - Environment service.
 * @param {object} organizationService - Organization service.
 * @param {object} packSubscriptionService - Pack subscription service.
 */

PackDetailController.$inject = ["$scope", "$mdToast", "$stateParams", "$mdDialog", "$window", "stripeKey", "packDetailService", "environmentService", "organizationService", "packSubscriptionService"];

function PackDetailController($scope, $mdToast, $stateParams, $mdDialog, $window, stripeKey, packDetailService, environmentService, organizationService, packSubscriptionService) {
  $scope.applyCoupon = applyCoupon;
  $scope.subscribe = subscribe;
  $scope.setStripe = setStripe;
  $scope.unsubscribe = unsubscribe;
  /**
   * Loader
   */

  (function loader() {
    readEnvironment().then(readPack).then(hasSubscribed);
  })();
  /**
   * Read environment.
   *
   * @returns {Promise} Promise object represents the current environment.
   */


  function readEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.environment = response;
      $scope.hasBankDetail = false;

      if ($scope.environment.entity.organization && $scope.environment.entity.organization.bankDetail) {
        if ($scope.environment.entity.organization.bankDetail.bic && $scope.environment.entity.organization.bankDetail.iban) {
          $scope.hasBankDetail = true;
        }
      } else if ($scope.environment.organization.bankDetail) {
        if ($scope.environment.organization.bankDetail.bic && $scope.environment.organization.bankDetail.iban) {
          $scope.hasBankDetail = true;
        }
      }
    });
  }
  /**
   * Read pack.
   *
   * @returns {Promise} Promise object represents the pack.
   */


  function readPack() {
    $scope.loading = true;
    return packDetailService.read({
      packSlug: $stateParams.packSlug
    }).then(function (pack) {
      $scope.pack = pack;
      $scope.loading = false;
    });
  }
  /**
   * Has subscribed to the pack.
   *
   * @returns {Promise} Promise
   */


  function hasSubscribed() {
    return packSubscriptionService.hasSubscribed({
      packSlug: $stateParams.packSlug
    }).then(function (response) {
      $scope.hasSubscribed = response.hasSubscribed;
    });
  }
  /** Set Stripe. */


  function setStripe() {
    var handler = StripeCheckout.configure({
      key: stripeKey,
      locale: "auto",
      token: function token(_token) {
        subscribe(_token.id);
      }
    });
    angular.element("#subscriptionButton").addEventListener("click", function (event) {
      handler.open({
        name: "Apodis Pharma",
        description: "Pack « " + $scope.pack.name + " »",
        currency: "eur",
        amount: $scope.priceCentDiscount || $scope.pack.price_cent,
        email: $scope.environment.user.email,
        allowRememberMe: false
      });
      event.preventDefault();
    });
    $window.addEventListener("popstate", function () {
      handler.close();
    });
  }
  /**
   * Subscribe.
   *
   * @param {number} tokenId - The ID of the token.
   * @param {object} ev - event.
   */


  function subscribe(tokenId, ev) {
    var coupon = $scope.voucher ? $scope.code : null;

    if (!$scope.hasBankDetail) {
      $mdDialog.show({
        templateUrl: "app/main/pack/views/detail/includes/bankDetail.tmpl.html",
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        controllerAs: "vm",
        locals: {
          currentEnv: $scope.environment
        },
        controller: ["currentEnv", function controller(currentEnv) {
          var vm = this;
          vm.cancel = cancel;
          vm.updateBank = updateBank;
          /**
           * Update bank.
           *
           * @param {object} bank - Bank.
           */

          function updateBank(bank) {
            var data = {
              organizationId: currentEnv.organization.id,
              info: bank
            };
            $scope.loading = true;
            organizationService.updateBankDetail(data).then(function (response) {
              vm.bank = response;
              packSubscriptionService.subscribe({
                packSlug: $stateParams.packSlug,
                coupon: coupon
              }).then(function (response) {
                showToast(response.pack);
              }).then(function () {
                $scope.loading = false;
                hasSubscribed();
              });
              $mdDialog.cancel();
            });
          }
          /**
           * cancel
           */


          function cancel() {
            $mdDialog.cancel();
          }
        }]
      });
    } else {
      $scope.loading = true;
      packSubscriptionService.subscribe({
        packSlug: $stateParams.packSlug,
        coupon: coupon
      }).then(function (response) {
        showToast(response.pack);
      }).then(function () {
        $scope.loading = false;
        hasSubscribed();
      });
    }

    $window.history.back();
  }
  /**
   * Unsubscribe from a pack
   */


  function unsubscribe() {
    var params = {
      packSlug: $stateParams.packSlug
    };
    packSubscriptionService.unsubscribe(params).then(function (response) {
      $mdToast.show($mdToast.simple().textContent("Vous êtes bien désabonné du pack « " + response.pack.name + " »"));
    }).then(function () {
      $scope.loading = false;
      hasSubscribed();
    });
  }
  /**
   * Show toast.
   *
   * @param {object} pack - The pack object.
   *
   * @returns {Promise} Promise object represents the toast.
   */


  function showToast(pack) {
    return $mdToast.show($mdToast.simple().textContent("Vous avez souscrit au pack « " + pack.name + " »"));
  }
  /** Apply coupon. */


  function applyCoupon() {
    $scope.voucher = null;

    if ($scope.code) {
      setTimeout(function () {
        packDetailService.applyCoupon({
          packSlug: $scope.pack.slug,
          couponCode: $scope.code,
          amount: $scope.pack.price
        }).then(function (data) {
          $scope.voucher = data;
        });
      }, 1000);
    }
  }
}

angular.module("app.pack").controller("packDetailController", PackDetailController);