"use strict";
/**
 * Branding main controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $mdToast - $mdToast.
 * @param {string} apiurl - API URL.
 * @param {object} sessionStorageService - Session storage service.
 * @param {object} brandingService - Branding service.
 * @param {object} environmentService - Environment service.
 * @param {object} brandingTemplateService - Branding template service.
 */

BrandingMainController.$inject = ["$scope", "$state", "$mdToast", "apiurl", "sessionStorageService", "brandingService", "environmentService", "brandingTemplateService"];

function BrandingMainController($scope, $state, $mdToast, apiurl, sessionStorageService, brandingService, environmentService, brandingTemplateService) {
  var SLIDER_TYPES = {
    SHOWCASE: 2,
    HOMEPAGE: 1
  };
  /**
   * Declaration of public methods
   */

  $scope.getBranding = getBranding;
  $scope.getShowcaseSlider = getShowcaseSlider;
  $scope.getHomepageSlider = getHomepageSlider;
  $scope.addFavicon = addFavicon;
  $scope.addHomepageImage = addHomepageImage;
  $scope.addLogo = addLogo;
  $scope.openTab = openTab;
  $scope.editSlider = editSlider;
  $scope.save = save;
  $scope.apiurl = apiurl;
  $scope.token = sessionStorageService.getToken();
  $scope.loading = false;
  /**
   * Declaration of public objects
   */

  $scope.slidersOptions = {
    loading: true,
    loader: "Récupération des sliders du site vitrine.",
    empty: "Vous n'avez aucun slider attaché à votre site vitrine."
  };
  $scope.query = {
    order: "-startDate",
    limit: 10,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.tabs = [{
    id: 1,
    name: "Informations",
    method: "",
    slug: "informations",
    active: true,
    isActive: true
  }, {
    id: 2,
    name: "Charte graphique",
    method: "",
    slug: "template",
    active: false,
    isActive: true
  }, {
    id: 3,
    name: "Page vitrine",
    method: "",
    slug: "showcase",
    active: false,
    isActive: true
  }, {
    id: 5,
    name: "Page d'accueil",
    method: "",
    slug: "homepage",
    active: false,
    isActive: true
  }];
  $scope.optionsPickerBg = {
    type: 2,
    history: false,
    genericPalette: false,
    palette: false
  };
  /**
   * Get slider by type id.
   *
   * @param {number} typeId - The type id.
   *
   * @returns {object} The slider or null if no slider was found.
   */

  function getSliderByTypeId(typeId) {
    var filteredSliders = $scope.groupingBranding.sliders.filter(function (slider) {
      return slider.groupingBrandingSliderTypeId === typeId;
    });

    if (filteredSliders.length) {
      return filteredSliders[0];
    }

    return null;
  }
  /**
   * Get showcase slider.
   *
   * @returns {object} The showcase slider or null if no showcase slider was found.
   */


  function getShowcaseSlider() {
    return getSliderByTypeId(SLIDER_TYPES.SHOWCASE);
  }
  /**
   * Get homepage slider.
   *
   * @returns {object} The homepage slider or null if no homepage slider was found.
   */


  function getHomepageSlider() {
    return getSliderByTypeId(SLIDER_TYPES.HOMEPAGE);
  }
  /**
   * Initialization of the loader
   */


  loader();
  /**
   * loader to load each method inside
   */

  function loader() {
    getEnvironment().then(function () {
      openTab($scope.tabs[0]);
    });
  }
  /**
   * Get environment.
   *
   * @returns {Promise}
   */


  function getEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.environment = response.entity;
      return response;
    }).then(getBranding);
  }
  /**
   * Get branding.
   *
   * @returns {Promise}
   */


  function getBranding() {
    $scope.loading = true;
    var grouping = {
      groupingId: $scope.environment.id
    };
    return brandingService.read(grouping).then(function (response) {
      $scope.groupingBranding = response;
      $scope.slidersOptions.loading = false;
      $scope.sliders = {
        homepage: getHomepageSlider(),
        showcase: getShowcaseSlider()
      };
      return response;
    }).then(getTemplates);
  }
  /**
   * Get templates.
   *
   * @param {object} group - Group from which templates must be loaded.
   *
   * @returns {Promise}
   */


  function getTemplates(group) {
    return brandingTemplateService.get(group).then(function (response) {
      $scope.templates = response;

      if ($scope.groupingBranding.isNew && response) {
        $scope.groupingBranding.templateHomePage = response[0].slug;
        $scope.groupingBranding.templateId = response[0].id;
        $scope.selectedTemplate = response[0];
      }

      $scope.loading = false;
      return response;
    });
  }
  /**
   * Save.
   *
   * @returns {Promise}
   */


  function save() {
    if ($scope.groupingBranding.isNew) {
      $scope.groupingBranding.groupingId = $scope.environment.id;
      return brandingService.post($scope.groupingBranding).then(function (response) {
        $scope.groupingBranding = response;
        getTemplates(response);
        $mdToast.show($mdToast.simple().textContent("Site vitrine enregistré").position("top right"));
        return response;
      });
    }

    return brandingService.update($scope.groupingBranding).then(function (response) {
      $scope.groupingBranding = response;
      getTemplates(response);
      $mdToast.show($mdToast.simple().textContent("Site vitrine mis à jour").position("top right"));
      return response;
    });
  }
  /**
   * Add favicon.
   *
   * @param {object} file - return by filemanager
   */


  function addFavicon(file) {
    if (!_.isUndefined(file) || !_.isNull(file)) {
      $scope.groupingBranding.favicon = file;
      $scope.groupingBranding.faviconFileId = file.id;
    }
  }
  /**
   * Add homepage image.
   *
   * @param {object} file - Image file to add.
   */


  function addHomepageImage(file) {
    if (!_.isUndefined(file) || !_.isNull(file)) {
      $scope.groupingBranding.homepageImage = file;
      $scope.groupingBranding.homepageImageFileId = file.id;
    }
  }
  /**
   * Add logo.
   *
   * @param {object} file - return by filemanager
   */


  function addLogo(file) {
    if (!_.isUndefined(file) || !_.isNull(file)) {
      $scope.groupingBranding.logo = file;
      $scope.groupingBranding.logoFileId = file.id;
    }
  }
  /**
   * Open tab.
   *
   * @param {object} myTab -
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";

        if (tab.method) {
          tab.method();
        }
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Edit slider.
   *
   * @param {object} $event -
   * @param {object} slider -
   */


  function editSlider($event, slider) {
    $state.go("app.branding.slider", {
      groupingId: $scope.environment.id,
      groupingBrandingId: $scope.groupingBranding.id,
      id: slider.id
    });
  }
}

angular.module("app.grouping").controller("brandingMainController", BrandingMainController);