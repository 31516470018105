"use strict";
/**
 * User laboratory branding communication controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $rootScope - The angular $rootScope object.
 * @param {object} $state - The angular $state object.
 * @param {object} bricksService - Bricks service.
 * @param {object} laboratoriesService - Laboratories service.
 */

UserLaboratoryBrandingCommunicationController.$inject = ["$scope", "$rootScope", "$state", "bricksService", "laboratoriesService"];

function UserLaboratoryBrandingCommunicationController($scope, $rootScope, $state, bricksService, laboratoriesService) {
  $scope.selected = 0;
  $scope.goBack = goBack;
  $scope.goTo = goTo;
  $scope.goToEvent = goToEvent;
  $scope.goToState = goToState;
  $scope.goToBrandingState = goToBrandingState;
  /**
   * goBack
   */

  function goBack() {
    var query = {
      wholesalerId: laboratoriesService.getWholesalerId()
    };
    $state.go("app.usersLaboratory", query);
  }
  /**
   * Go to given state
   *
   * @param {string} state - The state to go.
   */


  function goToState(state) {
    $state.go(state);
  }
  /**
   * goToBrandingState
   *
   * @param {object}  menu - The menu.
   */


  function goToBrandingState(menu) {
    $state.go(menu.state, menu);
  }
  /**
   * goTo
   *
   * @param {object}  brick - The brick.
   */


  function goTo(brick) {
    $rootScope.$broadcast("sideNavConstruct", brick);
    bricksService.setCurrentBrick(brick);
  }
  /**
   * goToEvent
   *
   * @param {object} event - The event.
   */


  function goToEvent(event) {
    $state.go("app.calendar.detail", {
      id: event.id
    });
  }

  $rootScope.$watch("laboratoryBranding", function (branding) {
    if (branding) {
      $scope.branding = branding;
    }
  });
}

angular.module("app.users-laboratory").controller("userLaboratoryBrandingCommunicationController", UserLaboratoryBrandingCommunicationController);