"use strict";
/**
 * PLV list directive.
 *
 * @returns {object}
 */

exportDialogController.$inject = ["$scope", "$mdDialog"];
shareDocumentModalController.$inject = ["$scope", "$mdDialog", "myDocument", "plvsService"];
editDialogController.$inject = ["$scope", "$mdDialog", "myDocument", "plvsService", "notifyUserService"];
PlvListModalController.$inject = ["$scope", "$state", "$mdDialog", "plvsService", "options", "apiurl", "notifyUserService"];
PlvListController.$inject = ["$rootScope", "$scope", "$mdDialog", "$state"];

function PlvList() {
  return {
    restrict: "E",
    scope: {
      select: "=select",
      // Button options
      buttonTitle: "@buttonTitle",
      buttonIcon: "@buttonIcon",
      buttonTooltip: "@buttonTooltip",
      buttonVariant: "@buttonVariant",
      // Modal options
      modalIcon: "@modalIcon",
      modalTitle: "@modalTitle",
      modalLoader: "=modalLoader",
      modalVariant: "=modalVariant"
    },
    templateUrl: "app/main/generator/directives/plv-list/button.html",
    controller: PlvListController
  };
}
/**
 * PLV list controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $state - $state.
 */


function PlvListController($rootScope, $scope, $mdDialog, $state) {
  var options = $scope;
  var previousState = $state.current.name.replace("app.", "");
  var currentState = previousState.replace(".", "/");
  $scope.open = open;
  /**
   * open the modal
   */

  function open() {
    $mdDialog.show({
      controller: PlvListModalController,
      templateUrl: "app/main/generator/directives/plv-list/modal.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        options: options,
        formatTypes: $scope.myRequest,
        currentState: currentState
      },
      clickOutsideToClose: true
    }).then(function (response) {
      return $rootScope.$broadcast("app.modal:confirm", response);
    });
  }
}
/**
 * PLV list modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} plvsService - Plvs service.
 * @param {object} options - Options.
 * @param {string} apiurl - API URL.
 * @param {object} notifyUserService - Notify user service.
 */


function PlvListModalController($scope, $state, $mdDialog, plvsService, options, apiurl, notifyUserService) {
  var previousState = $state.current.name.replace("app.", "");
  var currentState = previousState.replace(".", "/");
  $scope.options = options;
  $scope.currentType = false;
  $scope.selected = [];
  $scope.editDoc = editDoc;
  $scope.openDoc = openDoc;
  $scope.deleteDoc = deleteDoc;
  $scope.exportPlv = exportPlv;
  $scope.shareDocument = shareDocument;
  $scope.cancel = cancel;
  $scope.confirm = confirm;
  $scope.toggleActive = toggleActive;
  $scope.query = {
    order: "-startDate",
    limit: 8,
    limitOptions: [5, 10, 15],
    page: 1
  };
  $scope.plvsOptions = {
    loading: true,
    loader: "Récupération de vos supports de communication...",
    empty: "Vous n'avez aucun support de communication."
  };
  loader();
  /**
   * loader
   * To call each method inside
   */

  function loader() {
    getPlvs().then(getThumbs);
  }
  /**
   * Get plvs
   *
   * @returns {Promise}
   */


  function getPlvs() {
    return plvsService.load().then(function (plvs) {
      plvs.forEach(function (plv) {
        plv.active = false;
      });
      $scope.plvs = plvs;
      $scope.options.modalLoader.loading = false;
      return plvs;
    });
  }
  /**
   * Get plvs thumbs
   */


  function getThumbs() {
    var type = "thumbs";
    var variant = "small";
    $scope.plvs.forEach(function (plv) {
      plvsService.open(plv).then(function (file) {
        plvsService.exportVariant(plv.id, type, variant, file).then(function (response) {
          plv.thumb = "".concat(apiurl, "/images/").concat(response.full_path);
        });
      });
    });
  }
  /**
   * editDoc
   * Show the dialog to edit the document
   *
   * @param {object} event - The angular event
   * @param {object} myDocument - The target document
   */


  function editDoc(event, myDocument) {
    $mdDialog.show({
      templateUrl: "app/main/generator/view/modals/editPlv.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      locals: {
        myDocument: myDocument
      },
      controller: editDialogController
    });
  }
  /**
   * editDoc
   *
   * @param {object} event - The angular event
   * @param {object} myDocument - The target document
   */


  function openDoc(event, myDocument) {
    event.stopPropagation();
    $state.go("app.generator.edit", {
      id: myDocument.id,
      stateOrigin: currentState,
      injectedService: "plvsService"
    });
  }
  /**
   * deleteDoc
   * To delete the target document
   *
   * @param {object} event - The angular event
   * @param {object} document - The target document
   */


  function deleteDoc(event, document) {
    event.stopPropagation();
    var confirm = $mdDialog.confirm().title("Suppression").textContent("\xCAtes-vous certain de vouloir supprimer le support \xAB ".concat(document.name, " \xBB?")).ariaLabel("suppression support").targetEvent(event).ok("Supprimer").cancel("Annuler");
    $mdDialog.show(confirm).then(deleteDocument(document));
  }
  /**
   * deleteDocument
   *
   * @param {object} document - The target document
   */


  function deleteDocument(document) {
    plvsService["delete"](document).then(function (response) {
      $scope.documents.splice($scope.documents.findIndex(function (index) {
        return index.id === document.id;
      }), 1);
      notifyUserService.showSuccessToast("Le support \xAB ".concat(document.name, " \xBB est supprim\xE9"));
      return response;
    }).then(loader)["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue lors de la suppression de votre support");
    });
  }
  /**
   * Share a document.
   *
   * @param {object} myDocument - The document to share.
   */


  function shareDocument(myDocument) {
    $mdDialog.show({
      templateUrl: "app/main/generator/view/modals/share.html",
      locals: {
        myDocument: myDocument
      },
      controller: shareDocumentModalController
    });
  }
  /**
   * exportPlv
   *
   * @param {object} event -
   * @param {object} plv -
   */


  function exportPlv(event, plv) {
    event.preventDefault();

    if (!plv.file) {
      plvsService.open(plv).then(function (response) {
        plv.file = response;
      });
    }

    $mdDialog.show({
      templateUrl: "app/main/generator/view/modals/export.html",
      controller: exportDialogController
    }).then(function (response) {
      if (response) {
        exportDoc(plv, response.type, response.variant);
      }
    });
  }
  /**
   * exportDoc
   *
   * @param {object} plv -
   * @param {string} type -
   * @param {string} variant -
   */


  function exportDoc(plv, type, variant) {
    if (!variant) {
      plvsService["export"](plv.id, type, plv.file).then(function (file) {
        return window.open("".concat(apiurl, "/images/").concat(file.full_path));
      });
    }

    var path = variant === "png" ? "".concat(apiurl, "/images/") : "".concat(apiurl, "/files/");
    plvsService.exportVariant(plv.id, type, variant, plv.file).then(function (file) {
      return window.open("".concat(path).concat(file.full_path));
    });
  }
  /**
   * Toggle active plv
   *
   * @param {object} plv - The target plv
   * @returns {Array} The array of selected plv
   */


  function toggleActive(plv) {
    plv.active = !plv.active;

    if (!plv.active) {
      return $scope.selected.splice($scope.selected.findIndex(function (select) {
        return select.id === plv.id;
      }), 1);
    }

    return $scope.selected.push(plv);
  }
  /**
   * cancel
   */


  function cancel() {
    $mdDialog.cancel($scope.selected);
  }
  /**
   * confirm
   */


  function confirm() {
    $mdDialog.hide($scope.selected);
    $scope.selected = [];
  }
}
/**
 * Edit dialog controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} myDocument - Document.
 * @param {object} plvsService - Plvs service.
 * @param {object} notifyUserService - Notify user service.
 */


function editDialogController($scope, $mdDialog, myDocument, plvsService, notifyUserService) {
  $scope.myDocument = myDocument;
  $scope.save = save;
  $scope.cancel = cancel;
  /**
   * save
   * To save the document
   *
   * @returns {Promise}
   */

  function save() {
    return plvsService.update($scope.myDocument).then(function (response) {
      notifyUserService.showSuccessToast("Le support \xAB ".concat(response.name, " \xBB a bien \xE9t\xE9 modifi\xE9"));
      cancel();
      return response;
    });
  }
  /**
   * cancel
   * To cancel the edit
   */


  function cancel() {
    $mdDialog.cancel();
  }
}
/**
 * Share document modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} myDocument - Document.
 * @param {object} plvsService - Plvs service.
 */


function shareDocumentModalController($scope, $mdDialog, myDocument, plvsService) {
  $scope.close = close;
  $scope.remove = remove;
  $scope.validate = validate;
  $scope.toggle = toggle;
  $scope.isChecked = isChecked;
  $scope.entityTypes = [{
    id: 1,
    label: "Tous les laboratoires"
  }, {
    id: 2,
    label: "Toutes les pharmacies"
  }, {
    id: 3,
    label: "Tous les groupements"
  }];
  $scope.selectedEntityTypes = [];
  $scope.searchConfig = {
    event: "addShareEdit",
    search: ["laboratory.id", "idOrgas", "group.id"],
    tag: "share",
    searchPlaceHolder: "Rechercher des laboratoires, des groupements et des pharmacies",
    autoLaunchEvent: true
  };
  $scope.selectedOrganizations = [];
  /**
   * addShareEdit event.
   */

  $scope.$on("addShareEdit", function (event, results) {
    for (var type in results) {
      if (Object.prototype.hasOwnProperty.call(results, type)) {
        results[type].forEach(function (value) {
          var shareExist = $scope.selectedOrganizations.find(function (organization) {
            return organization.id === value.organization_id;
          });

          if (!shareExist) {
            $scope.selectedOrganizations.push({
              id: value.organization_id,
              name: value.name
            });
          }
        });
      }
    }
  });

  if (myDocument.share) {
    var share = myDocument.share;
    $scope.selectedEntityTypes = share.entityTypes.map(function (entityType) {
      return {
        id: entityType.id
      };
    });
    $scope.selectedOrganizations = share.organizations.map(function (organization) {
      return {
        id: organization.id,
        name: organization.morphable.name
      };
    });
  }
  /**
   * Toggle.
   *
   * @param {object} entityType - Entity type to toggle.
   *
   * @returns {Array} The selected entity types
   */


  function toggle(entityType) {
    var exists = $scope.selectedEntityTypes.some(function (item) {
      return item.id === entityType.id;
    });

    if (!exists) {
      return $scope.selectedEntityTypes.push(entityType);
    }

    $scope.selectedEntityTypes = $scope.selectedEntityTypes.filter(function (item) {
      return item.id !== entityType.id;
    });
    return $scope.selectedEntityTypes;
  }
  /**
   * Is checked.
   *
   * @param {object} entityType - Entity type to test.
   *
   * @returns {boolean}
   */


  function isChecked(entityType) {
    return $scope.selectedEntityTypes.some(function (item) {
      return item.id === entityType.id;
    });
  }
  /**
   * Close
   */


  function close() {
    $mdDialog.hide();
  }
  /**
   * Remove
   *
   * @param {number} index - The index of shared document
   */


  function remove(index) {
    $scope.selectedOrganizations.splice(index, 1);
  }
  /**
   * Validate
   */


  function validate() {
    var entityTypeIds = $scope.selectedEntityTypes.map(function (entityType) {
      return entityType.id;
    });
    var organizationIds = $scope.selectedOrganizations.map(function (organization) {
      return organization.id;
    });
    var shareWith = {
      entityTypes: entityTypeIds,
      organizations: organizationIds
    };
    plvsService.share(myDocument.id, shareWith).then(function (share) {
      myDocument.share = share;
      $mdDialog.hide();
    });
  }
}
/**
 * Export dialog controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 */


function exportDialogController($scope, $mdDialog) {
  $scope["export"] = {
    types: [{
      id: 1,
      name: "PDF",
      value: "pdf"
    }, {
      id: 2,
      name: "PNG",
      value: "png"
    }, {
      id: 3,
      name: "SVG",
      value: "svg"
    }],
    variants: [{
      id: 1,
      name: "72 Dpi",
      value: "small"
    }, {
      id: 2,
      name: "100 Dpi",
      value: "medium"
    }, {
      id: 3,
      name: "200 Dpi",
      value: "large"
    }, {
      id: 4,
      name: "300 Dpi",
      value: "xlarge"
    }],
    type: false,
    variant: false
  };
  $scope.validExport = validExport;
  $scope.cancelExport = cancelExport;
  /**
   * validExport
   * To validate the export
   */

  function validExport() {
    $mdDialog.hide($scope["export"]);
  }
  /**
   * cancel
   * To cancel the export
   */


  function cancelExport() {
    $mdDialog.cancel();
  }
}

angular.module("app.generator").directive("plvList", PlvList);