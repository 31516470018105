"use strict";
/**
 * The app-card-date directive.
 *
 * @returns {object}
 */

function AppCardDate() {
  return {
    restrict: "E",
    template: "<div class='app-card-date' ng-transclude></figcaption>",
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appCardDate", AppCardDate);