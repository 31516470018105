"use strict";
/**
 * Micro disruption controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} StatisticsQueryService - The statistics query service.
 * @param {object} stocksService - The stocks service.
 * @param {object} environmentService - The environment service.
 * @param {object} $state - The angular $state object.
 */

MicroDisruptionController.$inject = ["$scope", "StatisticsQueryService", "stocksService", "environmentService", "$state"];

function MicroDisruptionController($scope, StatisticsQueryService, stocksService, environmentService, $state) {
  $scope.configSearch = {
    event: "microDisruptionFilterChange",
    search: []
  };
  $scope.rawValues = StatisticsQueryService.values;
  $scope.toggleCb = toggleCb;
  $scope.indexByLabel = indexByLabel;
  $scope.existsFilter = existsFilter;
  $scope.getLimits = getLimits;
  $scope.getMicroDisruption = getMicroDisruption;
  $scope.filterDisruption = filterDisruption;
  $scope.removeFilter = removeFilter;
  $scope.goToHealthcenterAudit = goToHealthcenterAudit;
  $scope["export"] = exportCsv;
  $scope.optionsPagination = {
    page: 1,
    limit: 10,
    order: "-_source.quantity"
  };
  $scope.loaderMicroDisruptions = {
    loading: true,
    loader: "Chargement de vos ruptures",
    empty: "Aucune donnée"
  };
  loader();
  /**
   * The loading function
   */

  function loader() {
    $scope.usrquery = StatisticsQueryService.query;
    environmentService.getEnvironment().then(function (env) {
      $scope.environment = env;
      StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
        if (env.accessPointType === 1 && $scope.usrquery.must.laboratory.selected.length === 0) {
          var lab = {
            id: env.entityId,
            name: env.entity.name,
            type: "laboratory"
          };
          $scope.usrquery.must.laboratory.selected.push(lab);
        }

        $scope.query = query;
        $scope.query.stockDaysType = ">=";
        $scope.query.stockDays = 60;
        $scope.query.reassortDays = 30;
        $scope.query.surstockValue = null;
        getMicroDisruption();
      });
    });
  }
  /**
   * Check or uncheck the checkbox.
   *
   * @param {object} item - The object from the checkbox.
   * @param {Array} list - The list of checkboxes.
   */


  function toggleCb(item, list) {
    $scope.loaderMicroDisruptions.loading = true;
    var idx = $scope.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query.must = query.must;
      $scope.query.should = query.should;
      getMicroDisruption();
      $scope.loaderMicroDisruptions.loading = true;
    });
  }
  /**
   * Get limits of the filters
   *
   * @param {Array} array - The array to delimite.
   * @returns {Array}
   */


  function getLimits(array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  }
  /**
   * Index the filters by label
   *
   * @param {string} name - The name of the object to index.
   * @param {Array} list - The list of objects to index.
   * @returns {number}
   */


  function indexByLabel(name, list) {
    if (!name || !list) {
      return -1;
    }

    for (var index = 0, length = list.length; index < length; index++) {
      if (list[index].label === name) {
        return index;
      }
    }

    return -1;
  }
  /**
   * Check if filter exists
   *
   * @param {string} name - The name of the object to check.
   * @param {Array} list - The list of objects to check.
   * @returns {boolean}
   */


  function existsFilter(name, list) {
    if ($scope.genQuery) {
      return false;
    }

    return $scope.indexByLabel(name, list) > -1;
  }
  /**
   * Remove the filter.
   *
   * @param {object} obj - The object from the checkbox.
   * @param {Array} list - The list of objects in which to remove.
   */


  function removeFilter(obj, list) {
    $scope.loaderInventory.loading = true;
    var index = list.indexOf(obj);

    if (index !== -1) {
      list.splice(index, 1);
      $scope.loading = true;
      StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
        $scope.query = query;
        getMicroDisruption();
      });
    }
  }
  /**
   * get micro disruption
   */


  function getMicroDisruption() {
    $scope.loaderMicroDisruptions.loading = true;
    stocksService.getMicroDisruption({
      data: $scope.query
    }).then(function (disruptions) {
      $scope.disruptions = disruptions.disruptions;
      $scope.disruptionsSaved = disruptions.disruptions;
      $scope.totalSurstockAmount = disruptions.totalSurstockAmount;
      $scope.totalReassortAmount = disruptions.totalReassortAmount;
      $scope.loaderMicroDisruptions.loading = false;
    });
  }
  /**
   * filter the disruptions
   */


  function filterDisruption() {
    $scope.disruptions = [];
    $scope.disruptionsSaved.forEach(function (disruption) {
      if ($scope.query.stockDaysType === ">=" && disruption.needsDay.min >= $scope.query.stockDays || $scope.query.stockDaysType === "<=" && disruption.needsDay.min <= $scope.query.stockDays) {
        $scope.disruptions.push(disruption);
      }
    });
  }
  /**
   * Go to healthcenter audit.
   *
   * @param {object} disruption - The disruption object.
   */


  function goToHealthcenterAudit(disruption) {
    if ($scope.environment.accessPointType === 1) {
      $state.go("app.healthcenterAudit.detail.reassort", {
        id: disruption.key
      });
    }
  }
  /**
   * Export.
   */


  function exportCsv() {
    stocksService.exportCsv($scope.query).then(function (data) {
      // eslint-disable-next-line no-undef
      saveAs(new Blob([data.csv], {
        type: "text/csv;charset=UTF-8"
      }), "export.csv");
    });
  }
  /**
   * Listen to the search bar event
   */


  $scope.$on("microDisruptionFilterChange", function () {
    $scope.loaderMicroDisruptions.loading = true;
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      getMicroDisruption();
    });
  });
}

angular.module("app.statistics.stocks").controller("microDisruptionController", MicroDisruptionController);