"use strict";
/**
 * Map service.
 *
 * @returns {object}
 */

function MapService() {
  var vm = this;
  var distanceMarker;
  vm.map;
  return {
    initialize: function initialize(photo) {
      var myLatLng = {
        lat: 47.6468754,
        lng: 2.3242131
      };
      var mapWarningOptions = {
        center: new google.maps.LatLng(myLatLng),
        zoom: 6,
        minZoom: 6,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        panControl: true,
        mapTypeControl: false,
        panControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.LARGE,
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
        scaleControl: false,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
        addressControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_CENTER
        }
      };
      vm.map = new google.maps.Map(document.getElementById("mapImageWarning"), mapWarningOptions); // marker healthcenter

      var markerPharmaLatLng = new google.maps.LatLng({
        lat: photo.organization.morphable.address.latitude,
        lng: photo.organization.morphable.address.longitude
      }); // eslint-disable-next-line no-new

      new google.maps.Marker({
        id: photo.organization.morphable.id,
        position: markerPharmaLatLng,
        map: vm.map,
        accessId: photo.accessId,
        icon: "/assets/images/iconMap/markerHealthcenter.png"
      }); // marker photo

      var markerPhotoLatLng = new google.maps.LatLng({
        lat: photo.latitude,
        lng: photo.longitude
      }); // eslint-disable-next-line no-new

      new google.maps.Marker({
        id: photo.id,
        position: markerPhotoLatLng,
        map: vm.map,
        animation: google.maps.Animation.DROP,
        accessId: photo.accessId,
        icon: "/assets/images/iconMap/markerPicture.png"
      }); // distance between healthcenters and photo

      distanceMarker = google.maps.geometry.spherical.computeDistanceBetween(markerPhotoLatLng, markerPharmaLatLng); // zoom marker map

      var bounds = new google.maps.LatLngBounds();
      bounds.extend(markerPhotoLatLng);
      bounds.extend(markerPharmaLatLng);
      var originalMinZoom = vm.map.minZoom;

      switch (true) {
        case distanceMarker < 500:
          vm.map.setOptions({
            minZoom: 18
          });
          break;

        case distanceMarker < 1200:
          vm.map.setOptions({
            minZoom: 16
          });
          break;

        case distanceMarker < 350000:
          vm.map.setOptions({
            minZoom: 8
          });
          break;

        case distanceMarker > 500000:
          vm.map.setOptions({
            minZoom: 6
          });
          break;
      }

      vm.map.fitBounds(bounds);
      vm.map.setOptions({
        minZoom: originalMinZoom
      });
    }
  };
}

angular.module("app.prestation").factory("mapService", MapService);