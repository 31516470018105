"use strict";
/**
 * Instant discount voucher edit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} environmentService - Environment service.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} instantDiscountVoucherService - Instant discount voucher service.
 * @param {object} organizationService - Organization service.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 * @param {object} productService - Product service.
 * @param {object} healthcenterService - Healthcenter service.
 */

InstantDiscountVoucherEditController.$inject = ["$scope", "$mdDialog", "environmentService", "notifyUserService", "instantDiscountVoucherService", "organizationService", "$stateParams", "$state", "productService", "healthcenterService"];

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function InstantDiscountVoucherEditController($scope, $mdDialog, environmentService, notifyUserService, instantDiscountVoucherService, organizationService, $stateParams, $state, productService, healthcenterService) {
  getCurrentAccess();
  loadInstantDiscountVoucher();
  $scope.isLaboratory = false;
  $scope.access = {};
  $scope.validType = validType;
  $scope.removeProduct = removeProduct;
  $scope.removeTarget = removeTarget;
  $scope.addRewardStep = addRewardStep;
  $scope.removeRewardStep = removeRewardStep;
  $scope.isInstantDiscountVoucherValid = isInstantDiscountVoucherValid;
  $scope.submit = submit;
  $scope.submitAndPublish = submitAndPublish;
  $scope.loading = false;
  $scope.loadingSubmit = false;
  $scope.instantDiscountVoucherTypes = [{
    id: 1,
    name: "Basique",
    description: "Remises par paliers"
  }, {
    id: 2,
    name: "Avancé",
    description: "Remises par paliers, avec produits déclencheurs et bénéficiaires"
  }];
  var baseProductSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    autoLaunchEvent: true,
    must: {}
  };
  $scope.productTriggerSearchConfig = _.defaults({
    event: "addProductsTrigger",
    tag: "productTrigger"
  }, baseProductSearchConfig);
  $scope.productRewardSearchConfig = _.defaults({
    event: "addRewardProducts",
    tag: "productReward"
  }, baseProductSearchConfig);
  $scope.laboratorySearchConfig = {
    event: "changeInstantDiscountVoucherLaboratory",
    tag: "laboratory",
    search: ["laboratory.id"],
    limits: {
      laboratory: 1
    }
  };
  $scope.targetSearchConfig = {
    event: "addInstantDiscountVoucherTargets",
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
    tag: "target",
    autoLaunchEvent: true
  };
  $scope.instantDiscountVoucher = {
    type: $scope.instantDiscountVoucherTypes[0],
    name: null,
    description: null,
    file: null,
    startDate: null,
    endDate: null,
    productsTrigger: [],
    productsReward: [],
    targets: [],
    rewards: [],
    isPublished: false,
    laboratory: [],
    isAllTargetsAllowed: false
  };
  $scope.rewardTypes = [{
    id: 1,
    name: "euros"
  }, {
    id: 2,
    name: "%"
  }];
  $scope.currentEditingRewardStep = {
    triggerValue: null,
    rewardValue: null,
    rewardType: $scope.rewardTypes[0]
  };
  /**
   * Load current editing instant discount voucher data an id was given.
   */

  function loadInstantDiscountVoucher() {
    if (!$stateParams.id) {
      return;
    }

    $scope.loading = true;
    var queryParameters = {
      id: $stateParams.id
    };
    instantDiscountVoucherService.read(queryParameters).then(function (instantDiscountVoucher) {
      $scope.instantDiscountVoucher = instantDiscountVoucher;
      $scope.instantDiscountVoucher.startDate = new Date(instantDiscountVoucher.startDate);
      $scope.instantDiscountVoucher.endDate = new Date(instantDiscountVoucher.endDate);
      $scope.instantDiscountVoucher.laboratory = [instantDiscountVoucher.laboratory];
      $scope.instantDiscountVoucher.productsTrigger = $scope.instantDiscountVoucher.productsTrigger.map(function (product) {
        product.cip = product.cip7 || product.cip13;
        return product;
      });
      $scope.instantDiscountVoucher.productsReward = $scope.instantDiscountVoucher.productsReward.map(function (product) {
        product.cip = product.cip7 || product.cip13;
        return product;
      });
      $scope.instantDiscountVoucher.targets = instantDiscountVoucher.targets.map(function (target) {
        return {
          organizationId: target.id,
          name: target.morphable.name
        };
      });
      $scope.loading = false;
    });
  }
  /**
   * Assign a type to the editing instant discount voucher.
   *
   * @param {object} type - The instant discount voucher type to assign.
   */


  function validType(type) {
    $scope.instantDiscountVoucher.type = type;
  }
  /**
   * Fetch current user access from server.
   */


  function getCurrentAccess() {
    environmentService.getEnvironment().then(function (access) {
      $scope.access = access;
      $scope.isLaboratory = access.accessPointType === 1;

      if ($scope.isLaboratory) {
        $scope.productTriggerSearchConfig.must = {
          "laboratory.id": [access.entity.id]
        };
        $scope.productRewardSearchConfig.must = {
          "laboratory.id": [access.entity.id]
        };
        $scope.instantDiscountVoucher.laboratory = [access.entity];
      }
    });
  }
  /**
   * Build payload to request products data.
   *
   * @param {Array} requestedProducts - The requested products.
   *
   * @returns {object}
   */


  function buildSearchProductQueryPayload(requestedProducts) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(requestedProducts, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(requestedProducts[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in requestedProducts) {
      _loop(type);
    }

    return queryProduct;
  }
  /**
   * Fetch products data from server.
   *
   * @param {object} queryProduct - Query product.
   *
   * @returns {Promise}
   */


  function fetchProducts(queryProduct) {
    return productService.postPresentationByType(queryProduct);
  }

  $scope.$on("addProductsTrigger", function (event, data) {
    var queryProduct = buildSearchProductQueryPayload(data);
    fetchProducts(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        product.requestedQuantity = null;
        addProduct(product, "productsTrigger");
      });
    });
  });
  $scope.$on("addRewardProducts", function (event, data) {
    var queryProduct = buildSearchProductQueryPayload(data);
    fetchProducts(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        addProduct(product, "productsReward");
      });
    });
  });
  /**
   * Add a product to the current editing instant discount voucher.
   *
   * @param {object} productToAdd - Product to add.
   * @param {string} to - Field from which the product will be added
   *      ("productsTrigger" or "productsReward").
   *
   * @returns {void}
   */

  function addProduct(productToAdd, to) {
    if (!_.find($scope.instantDiscountVoucher[to], function (product) {
      return product.id === productToAdd.id;
    })) {
      $scope.instantDiscountVoucher[to].push(productToAdd);
    }
  }
  /**
   * Remove a product from current editing instant discount voucher.
   *
   * @param {number} productId - Id of product to remove.
   * @param {string} from - Field from which the product will be removed
   *      ("productsTrigger" or "productsReward").
   */


  function removeProduct(productId, from) {
    $scope.instantDiscountVoucher[from] = $scope.instantDiscountVoucher[from].filter(function (product) {
      return product.id !== productId;
    });
  }

  $scope.$on("addInstantDiscountVoucherTargets", function (event, query) {
    var queryHealthcenter = {
      type: {}
    };

    var _loop2 = function _loop2(typeOfTarget) {
      if (Object.prototype.hasOwnProperty.call(query, typeOfTarget)) {
        if (typeof queryHealthcenter.type[typeOfTarget] === "undefined") {
          queryHealthcenter.type[typeOfTarget] = [];
        }

        angular.forEach(query[typeOfTarget], function (obj) {
          if (typeOfTarget === "uga") {
            queryHealthcenter.type[typeOfTarget].push(obj.name);
          } else {
            queryHealthcenter.type[typeOfTarget].push(obj.id);
          }
        });
      }
    };

    for (var typeOfTarget in query) {
      _loop2(typeOfTarget);
    }

    healthcenterService.getHealthcentersByGeo(queryHealthcenter).then(function (pharmas) {
      var healthcenters = _toConsumableArray($scope.instantDiscountVoucher.targets);

      var healthcenterToAdd = pharmas.filter(function (healthcenter) {
        return !healthcenters.find(function (hc) {
          return hc.organizationId === healthcenter.organizationId;
        });
      });
      $scope.instantDiscountVoucher.targets = [].concat(_toConsumableArray($scope.instantDiscountVoucher.targets), _toConsumableArray(healthcenterToAdd));
    });
  });
  $scope.$on("changeInstantDiscountVoucherLaboratory", function (event, result) {
    if (result.laboratory && result.laboratory[0]) {
      $scope.instantDiscountVoucher.laboratory = [result.laboratory[0]];
      $scope.productTriggerSearchConfig.must = {
        "laboratory.id": [result.laboratory[0].id]
      };
      $scope.productRewardSearchConfig.must = {
        "laboratory.id": [result.laboratory[0].id]
      };
    } else {
      $scope.instantDiscountVoucher.laboratory = null;
      $scope.productTriggerSearchConfig.must = {
        "laboratory.id": []
      };
      $scope.productRewardSearchConfig.must = {
        "laboratory.id": []
      };
    }

    $scope.instantDiscountVoucher.productsTrigger = [];
    $scope.instantDiscountVoucher.productsReward = [];
  });
  /**
   * Remove target from current editing instant discount voucher.
   *
   * @param {number} targetId - Id of target to remove.
   *
   * @returns {void}
   */

  function removeTarget(targetId) {
    $scope.instantDiscountVoucher.targets = $scope.instantDiscountVoucher.targets.filter(function (target) {
      return target.id !== targetId;
    });
  }
  /**
   * Add a reward step to the current editing instant discount voucher.
   *
   * @returns {void}
   */


  function addRewardStep() {
    if (!isRewardStepValid()) {
      return;
    }

    $scope.instantDiscountVoucher.rewards.push($scope.currentEditingRewardStep);
    $scope.instantDiscountVoucher.rewards = _.sortBy($scope.instantDiscountVoucher.rewards, "triggerValue", "asc");
    $scope.currentEditingRewardStep = {
      triggerValue: null,
      rewardValue: null,
      rewardType: $scope.currentEditingRewardStep.rewardType
    };
  }
  /**
   * Remove a reward step from the current editing voucher.
   *
   * @param {number} index - Index of the reward step to remove.
   */


  function removeRewardStep(index) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression du palier ?").ariaLabel("Suppression de l'objectif ").ok("Oui").cancel("Non")).then(function () {
      $scope.instantDiscountVoucher.rewards.splice(index, 1);
      notifyUserService.showSimpleToast("Le palier a été supprimé.", {
        color: "success",
        hideDelay: 1000
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Check if current instant discount voucher is valid.
   *
   * @returns {boolean}
   */


  function isInstantDiscountVoucherValid() {
    return $scope.instantDiscountVoucher.name && $scope.instantDiscountVoucher.description && $scope.instantDiscountVoucher.startDate && $scope.instantDiscountVoucher.endDate && $scope.instantDiscountVoucher.startDate < $scope.instantDiscountVoucher.endDate && $scope.instantDiscountVoucher.targets && $scope.instantDiscountVoucher.productsTrigger.length && $scope.instantDiscountVoucher.laboratory.length === 1 && ($scope.instantDiscountVoucher.type.id !== 2 || $scope.instantDiscountVoucher.productsReward.length) && ($scope.instantDiscountVoucher.targets.length || $scope.instantDiscountVoucher.isAllTargetsAllowed);
  }
  /**
   * Check current editing reward step is valid.
   *
   * @returns {boolean}
   */


  function isRewardStepValid() {
    return _.isNumber($scope.currentEditingRewardStep.triggerValue) && _.isNumber($scope.currentEditingRewardStep.rewardValue) && $scope.currentEditingRewardStep.triggerValue >= 0 && $scope.currentEditingRewardStep.rewardValue >= 0;
  }
  /**
   * Submit the form wizard.
   */


  function submit() {
    $scope.loadingSubmit = false;
    instantDiscountVoucherService.createOrUpdate(formatInstantDiscountVoucherData($scope.instantDiscountVoucher)).then(function () {
      $state.go("app.instantDiscountVoucher");
    });
  }
  /**
   * Format instant discount voucher object.
   *
   * @param {object} instantDiscountVoucher - Instant discount voucher.
   *
   * @returns {object}
   */


  function formatInstantDiscountVoucherData(instantDiscountVoucher) {
    var formattedInstantDiscountVoucher = _.clone(instantDiscountVoucher);

    formattedInstantDiscountVoucher.instantDiscountVoucherTypeId = $scope.instantDiscountVoucher.type.id;

    if ($scope.instantDiscountVoucher.file) {
      formattedInstantDiscountVoucher.fileId = $scope.instantDiscountVoucher.file.id;
    }

    formattedInstantDiscountVoucher.rewards = formattedInstantDiscountVoucher.rewards.map(function (rewardStep) {
      rewardStep.instantDiscountVoucherRewardTypeId = rewardStep.rewardType.id;
      return rewardStep;
    });
    return formattedInstantDiscountVoucher;
  }
  /**
   * Submit the form and publish the instant discount voucher.
   */


  function submitAndPublish() {
    var confirm = $mdDialog.confirm();
    confirm.title("Envoyer et publier").textContent("Êtes-vous certain de vouloir publier  le bon de réduction  ?").ariaLabel("Publier le bon de réduction immédiat").ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      $scope.instantDiscountVoucher.isPublished = true;
      submit();
    });
  }
}

angular.module("app.instantDiscountVoucher").controller("InstantDiscountVoucherEditController", InstantDiscountVoucherEditController);