"use strict";
/**
 * Auth service.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $base64 - $base64.
 * @param {object} $state - $state.
 * @param {object} $window - $window.
 * @param {object} environmentService - Environment service.
 * @param {object} bricksService - Bricks service.
 * @param {object} apodisThemingFactory - Apodis theming factory.
 * @param {object} apiClient - API client.
 * @param {object} sessionStorageService - Session storage service.
 * @param {object} deepLinkingService - Deep linking service.
 *
 * @returns {object}
 */

AuthService.$inject = ["$rootScope", "$base64", "$state", "$window", "environmentService", "bricksService", "apodisThemingFactory", "apiClient", "sessionStorageService", "deepLinkService"];

function AuthService($rootScope, $base64, $state, $window, environmentService, bricksService, apodisThemingFactory, apiClient, sessionStorageService, deepLinkService) {
  /**
   * Login.
   *
   * @param {object} user - User.
   * @param {string} key - Key.
   *
   * @returns {Promise}
   */
  function login(user, key) {
    var config = {
      headers: {
        Authorization: "Basic " + $base64.encode("".concat(user.name, ":").concat(user.password))
      },
      includes: ["rank", "user", "packs", "currentAccess.teams", "access.entity.manager", "access.entity.branding", "access.entity.branding.sliders", "currentAccess.organization.bankDetail", "access.entity.grouping.branding.sliders"],
      wrappers: {
        response: null
      }
    };

    if (key) {
      config.parameters = [{
        name: deepLinkService.isDeepLinkingKey(key) ? "deepKey" : "key",
        value: key
      }];
    }

    return apiClient.post("/authenticate", {}, config).then(function (result) {
      storeProfile(result.data);
      sessionStorageService.setToken(result.data.token);
      storePayload(result.data.token);
      environmentService.setEnvironment(result.data.user.currentAccess);
      $rootScope.$broadcast("auth:login");
      return result;
    });
  }
  /**
   * Logout.
   */


  function logout() {
    destroyCredentials();
    apodisThemingFactory.destroyStyles();
    $rootScope.$broadcast("auth:logout");
    $state.go("app.pages_auth_login");
  }
  /**
   * Destroy credentials.
   *
   * @returns {Promise}
   */


  function destroyCredentials() {
    window.sessionStorage.clear();
    return Promise.all([environmentService.clean(), bricksService.clean()]);
  }
  /**
   * Store payload.
   *
   * @param {string} token - Token.
   */


  function storePayload(token) {
    var groupPayload = token.split(".")[1];
    var payload = groupPayload.replace("-", "+").replace("_", "/");
    var decryptPayload = JSON.stringify(JSON.parse($window.atob(payload)));
    window.sessionStorage.setItem("payload", decryptPayload);
  }
  /**
   * Store profile.
   *
   * @param {object} profile - Profile.
   */


  function storeProfile(profile) {
    var myProfile = JSON.stringify({
      id: profile.user.id,
      role: profile.user.role,
      email: profile.user.email,
      firstname: profile.user.given,
      lastname: profile.user.familyBirth,
      cgu_accepted: profile.user.cgu_accepted,
      is_registered: profile.user.is_registered,
      createdAt: profile.user.createdAt,
      updatedAt: profile.user.updatedAt,
      access: profile.user.currentAccess
    });
    window.sessionStorage.setItem("profile", myProfile);
  }
  /**
   * User is authenticated.
   *
   * @returns {boolean}
   */


  function isAuthenticated() {
    return Boolean(sessionStorageService.getToken());
  }

  return {
    login: login,
    logout: logout,
    destroyCredentials: destroyCredentials,
    isAuthenticated: isAuthenticated
  };
}

angular.module("app.auth").service("authService", AuthService);