"use strict";
/**
 * Legal notice outside connection controller.
 *
 * @param {object} $window - $window.
 */

LegalNoticeOutsideConnectionController.$inject = ["$window"];

function LegalNoticeOutsideConnectionController($window) {
  var vm = this;

  vm.close = function () {
    $window.history.back();
  };
}

angular.module("app.legal").controller("LegalNoticeOutsideConnectionController", LegalNoticeOutsideConnectionController);