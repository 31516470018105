"use strict";
/**
 * Dashboard laboratories prestations statistics controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} dashboardAnalysisService - Dashboard analysis service.
 */

DashboardLaboratoriesPrestationsStatisticsController.$inject = ["$scope", "dashboardAnalysisService"];

function DashboardLaboratoriesPrestationsStatisticsController($scope, dashboardAnalysisService) {
  getPrestationsStatistics();
  /**
   * Get prestations statistics.
   */

  function getPrestationsStatistics() {
    dashboardAnalysisService.getPrestationsStatistics().then(function (prestationsStatistics) {
      $scope.prestationsStatisticsAccepted = prestationsStatistics[0].aggregations;
      $scope.prestationsStatisticsPending = prestationsStatistics[1].aggregations;
      $scope.prestationsStatisticsRefused = prestationsStatistics[2].aggregations;
      $scope.prestationsStatisticsAcceptedLastYear = prestationsStatistics[3].aggregations;
      $scope.prestationsStatisticsPendingLastYear = prestationsStatistics[4].aggregations;
      $scope.prestationsStatisticsRefusedLastYear = prestationsStatistics[5].aggregations;
    });
  }
}

angular.module("app.dashboard").controller("dashboardLaboratoriesPrestationsStatisticsController", DashboardLaboratoriesPrestationsStatisticsController);