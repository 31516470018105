"use strict";
/**
 * Documentation widgets controller.
 *
 * @param {object} $scope - $scope.
 */

DocumentationWidgetsController.$inject = ["$scope"];

function DocumentationWidgetsController($scope) {
  $scope.clickTest = function () {
    // eslint-disable-next-line no-console
    console.log("It's running ON !!!");
  };
}

angular.module("app.documentation").controller("documentationWidgetsController", DocumentationWidgetsController);