"use strict";
/**
 * App tile content directive.
 *
 * @returns {object}
 */

function AppTileContent() {
  return {
    restrict: "E",
    template: "<div class='app-tile-content' ng-transclude></div>",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appTileContent", AppTileContent);