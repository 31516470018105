"use strict";
/**
 * Dashboard groups average shopping cart controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} laboratoriesAverageShoppingCartService -
 *      Laboratories average shopping cart service.
 * @param {object} StatisticsQueryService - Statistics query service.
 */

DashboardGroupsAverageShoppingCartController.$inject = ["$scope", "environmentService", "laboratoriesAverageShoppingCartService", "StatisticsQueryService"];

function DashboardGroupsAverageShoppingCartController($scope, environmentService, laboratoriesAverageShoppingCartService, StatisticsQueryService) {
  $scope.loading = true;
  $scope.startDatetime = moment("2018-01-01").format("YYYY-MM-DD");
  $scope.endDatetime = moment().format("YYYY-MM-DD");
  $scope.thisYear = moment().format("YYYY");
  $scope.date = {
    startDatetime: $scope.startDatetime,
    endDatetime: $scope.endDatetime
  };
  $scope.query = StatisticsQueryService.query;
  loader();
  /**
   * Get average shopping cart analysis.
   *
   * @param {object} query - Query.
   */

  function getAverageShoppingCartAnalysis(query) {
    StatisticsQueryService.buildquery(query, query.date).then(laboratoriesAverageShoppingCartService.get).then(function (averageShoppingCart) {
      $scope.averageShoppingCart = averageShoppingCart.current.aggregations;
      $scope.averageShoppingCartComparison = averageShoppingCart.comparison.aggregations;
      $scope.loading = false;
    });
  }
  /**
   * Loader.
   */


  function loader() {
    environmentService.getEnvironment().then(function (environment) {
      var group = {
        name: environment.entity.name,
        id: environment.entityId,
        type: "groups"
      };
      $scope.query.must.groups.selected.push(group);
      getAverageShoppingCartAnalysis($scope.query);
    });
  }
}

angular.module("app.dashboard").controller("dashboardGroupsAverageShoppingCartController", DashboardGroupsAverageShoppingCartController);