"use strict";

dashboardPharmaciesPatientNextVisitModalController.$inject = ["$scope", "$mdDialog", "renewalScheduleService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * Controller for patient next visit modal, in pharmacy dashboard.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $mdDialog - The $mdDialog object.
 * @param {object} renewalScheduleService - The renewal schedule service.
 */
function dashboardPharmaciesPatientNextVisitModalController($scope, $mdDialog, renewalScheduleService) {
  $scope.close = close;
  $scope.getUnavailableProducts = getUnavailableProducts;
  $scope.isProductsUnavailable = isProductsUnavailable;
  $scope.loading = true;
  $scope.incomingVisits = null;
  loader();
  /**
   * Load the incoming visits.
   */

  function loader() {
    var params = {
      upcoming: true,
      availability: true
    };
    renewalScheduleService.getRenewalSchedulePlan(params).then(function (response) {
      var incomingVisits = response.data.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          next_renewal_date: moment(item.next_renewal_date)
        });
      });
      $scope.groupedIncomingVisits = groupVisitsByMonth(incomingVisits);
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Get unavailable products for a next visit.
   *
   * @param {object} nextVisit - The next visit object.
   *
   * @returns {Array}
   */


  function getUnavailableProducts(nextVisit) {
    return nextVisit.availabilities.details.filter(function (availability) {
      return availability.is_available === false;
    });
  }
  /**
   * Group given visits by month.
   *
   * @param {object} visits - The visits.
   *
   * @returns {Array}
   */


  function groupVisitsByMonth(visits) {
    if (!visits) {
      return null;
    }

    var groups = visits.reduce(function (previous, current) {
      var splitDate = current.next_renewal_date.format("dddd-MM-YYYY").split("-");
      var month = splitDate[1];
      var year = splitDate[2];
      previous[month] ? previous[month].data.push(current) : previous[month] = {
        group: moment(month).format("MMMM") + " " + moment({
          year: year
        }).format("YYYY"),
        data: [current]
      };
      return previous;
    }, {});
    return Object.keys(groups).map(function (value) {
      return groups[value];
    });
  }
  /**
   * Determine whether products of next visit are available.
   *
   * @param {object} nextVisit - The visit object.
   *
   * @returns {boolean}
   */


  function isProductsUnavailable(nextVisit) {
    return !nextVisit.availabilities.healthcenter_available;
  }
  /**
   * Close the modal
   */


  function close() {
    $mdDialog.hide();
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesPatientNextVisitModalController", dashboardPharmaciesPatientNextVisitModalController);