"use strict";
/**
 * Category configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

CategoryConfig.$inject = ["$stateProvider"];

function CategoryConfig($stateProvider) {
  $stateProvider.state("app.categories", {
    url: "/categories",
    views: {
      "content@app": {
        templateUrl: "app/main/category/views/category.html",
        controller: "categoryController"
      }
    },
    params: {
      type: "product"
    }
  }).state("app.categories.product", {
    url: "/product",
    views: {
      "content@app": {
        templateUrl: "app/main/category/views/category.html",
        controller: "categoryController"
      }
    },
    params: {
      type: "product"
    }
  }).state("app.categories.healthcenter", {
    url: "/healthcenter",
    views: {
      "content@app": {
        templateUrl: "app/main/category/views/category.html",
        controller: "categoryController"
      }
    },
    params: {
      type: "healthcenter"
    }
  });
}

angular.module("app.category", []).config(CategoryConfig);