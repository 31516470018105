"use strict";
/**
 * Sales analysis member service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

SalesAnalysisMemberService.$inject = ["apiClient"];

function SalesAnalysisMemberService(apiClient) {
  return {
    /**
     * Get statistics.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getStats: function getStats(params) {
      return apiClient.post("/analysis/grouping", params, {
        wrappers: {
          respones: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics.salesAnalysis").service("salesAnalysisMemberService", SalesAnalysisMemberService);