"use strict";
/**
 * Grouping service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

GroupingService.$inject = ["apiClient"];

function GroupingService(apiClient) {
  return {
    /**
     * Get dashboard.
     *
     * @returns {Promise}
     */
    dashboard: function dashboard() {
      return apiClient.get("/grouping/dashboard", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Calculate sales total.
     *
     * @param {object} data -
     *
     * @returns {Promise}
     */
    calculateSalesTotal: function calculateSalesTotal(data) {
      return apiClient.post("/grouping/sale", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top grouping laboratories.
     *
     * @param {number} limit - Number
     *
     * @returns {Promise}
     */
    getTopLaboratories: function getTopLaboratories(limit) {
      return apiClient.get("/grouping/topLaboratories/".concat(limit), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.grouping").service("groupingService", GroupingService);