"use strict";
/**
 * Future controller.
 *
 * @param {object} mdlService - MDL service
 */

FutureController.$inject = ["mdlService"];

function FutureController(mdlService) {
  var vm = this;
  vm.availableYears = [];
  /**
   * Gets sales from last 12 months
   */

  function getSalesSinceTwelveMonths() {
    vm.loading = true;
    mdlService.estimated().then(function (response) {
      var totalValue = 0;
      angular.forEach(response.marginByYear, function (monthes, year) {
        totalValue = 0;
        angular.forEach(monthes, function (value) {
          totalValue += value;
        });
        vm.availableYears.push({
          year: year,
          value: totalValue
        });
      });
      vm.loading = false;
    });
  }

  getSalesSinceTwelveMonths();
}

angular.module("app.statistics.smoothed-degressive-margin").controller("FutureController", FutureController);