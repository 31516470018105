"use strict";
/**
 * Oauth service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

OauthService.$inject = ["apiClient"];

function OauthService(apiClient) {
  return {
    /**
     * Read.
     *
     * @returns {Promise}
     */
    read: function read() {
      return apiClient.get("/oauth", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.oauthClients;
      });
    },

    /**
     * Create.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    create: function create(params) {
      return apiClient.post("/oauth", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.oauthClient;
      });
    },

    /**
     * Delete.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/oauth/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.oauth").service("oauthService", OauthService);