"use strict";
/**
 * Confirm mail modification controller.
 *
 * @param {object} $state - $state.
 * @param {object} authProfileService - Auth profile service.
 * @param {object} $location - $location.
 * @param {object} $scope - $scope.
 * @param {object} notifyUserService - Notify user service.
 */

ConfirmMailModificationController.$inject = ["$state", "authProfileService", "$location", "$scope", "notifyUserService"];

function ConfirmMailModificationController($state, authProfileService, $location, $scope, notifyUserService) {
  $scope.loading = true;
  var params = {
    email: $location.search().newEmail,
    emailKey: $location.search().key
  };
  authProfileService.confirmMailModification(params).then(function () {
    $scope.loading = false;
    notifyUserService.showSuccessToast("Votre email a été modifié.");
    $state.go("app.pages_auth_login");
  });
}

angular.module("app.auth").controller("confirmMailModificationController", ConfirmMailModificationController);