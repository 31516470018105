"use strict";
/**
 * Dashboard pharmacies operator push controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} environmentService - Environment service.
 */

DashboardPharmaciesOperatorPushController.$inject = ["$scope", "$state", "environmentService"];

function DashboardPharmaciesOperatorPushController($scope, $state, environmentService) {
  loader();
  $scope.goToGuestManager = goToGuestManager;
  /**
   * The loader function
   */

  function loader() {
    isEntityAdmin();
  }
  /**
   * Is the current environment owner of the healthcenter?
   */


  function isEntityAdmin() {
    environmentService.getEnvironment().then(function (env) {
      $scope.isEntityAdmin = [1, 2].indexOf(env.rankId) > -1;
    });
  }
  /**
   * Go to the guest page
   */


  function goToGuestManager() {
    $state.go("app.guest");
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesOperatorPushController", DashboardPharmaciesOperatorPushController);