"use strict";
/**
 * MDL controller.
 *
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} $filter - $filter.
 * @param {object} analysisService - Analysis service.
 */

MdlController.$inject = ["StatisticsQueryService", "$filter", "analysisService"];

function MdlController(StatisticsQueryService, $filter, analysisService) {
  var vm = this;
  vm.usrquery = JSON.parse(JSON.stringify(StatisticsQueryService.pristineQuery));
  vm.loadingMarge = true;
  vm.loadingMargeGrossiste = true;
  (vm.getMarges = function () {
    vm.dateLabel = moment(vm.usrquery.date.from).format("DD-MMM-YYYY") + " Au " + moment(vm.usrquery.date.to).format("DD-MMM-YYYY");
    vm.marge = [];
    vm.margeGrossiste = [];
    vm.totalUnit = 0;
    vm.totalSales = 0;
    vm.totalMarge = 0;
    vm.marge = [];
    vm.margeGrossiste = [];
    vm.totalUnit = 0;
    vm.totalSales = 0;
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      analysisService.getMarge(query).then(function (data) {
        angular.forEach(data.datasNational.aggregations.margin_type.buckets, function (bucketNational, idx) {
          var bucketLocal;

          if (!data.datas.aggregations.margin_type.buckets[idx]) {
            bucketLocal = {
              key: bucketNational.key,
              ca_stats: {
                sum: 0
              },
              quantity: {
                value: 0
              },
              margin: {
                sum: 0
              }
            };
          } else {
            bucketLocal = data.datas.aggregations.margin_type.buckets[idx];
          }

          vm.marge.push({
            key: bucketNational.key,
            sales: bucketLocal.ca_stats.sum,
            salesCompare: bucketLocal.ca_stats.sum / (bucketLocal.ca_stats.sum + bucketNational.ca_stats.sum) * 100,
            salesNational: bucketNational.ca_stats.sum / bucketNational.pharmacyQuantity.value / (bucketLocal.ca_stats.sum + bucketNational.ca_stats.sum) * 100,
            marge: bucketLocal.margin.sum,
            margeCompare: bucketLocal.margin.sum / (bucketLocal.margin.sum + bucketNational.margin.sum / bucketNational.pharmacyQuantity.value) * 100,
            margeNational: bucketNational.margin.sum / bucketNational.pharmacyQuantity.value / (bucketLocal.margin.sum + bucketNational.margin.sum / bucketNational.pharmacyQuantity.value) * 100,
            quantity: bucketLocal.quantity.value,
            quantityCompare: bucketLocal.quantity.value / (bucketLocal.quantity.value + bucketNational.quantity.value) * 100,
            quantityNational: bucketNational.quantity.value / bucketNational.pharmacyQuantity.value / (bucketLocal.quantity.value + bucketNational.quantity.value) * 100,
            palier: bucketLocal.key
          });
          vm.totalMarge += bucketLocal.margin.sum;
          vm.totalUnit += bucketLocal.quantity.value;
          vm.totalSales += bucketLocal.ca_stats.sum;
        });
        vm.loadingMarge = false;
      });
    });
  })();
  vm.amChartOptionsMarge = {
    sales: {
      data: vm.marge,
      type: "serial",
      thousandsSeparator: " ",
      categoryField: "palier",
      rotate: true,
      legend: {
        enabled: true
      },
      categoryAxis: {
        gridPosition: "start",
        parseDates: false
      },
      precision: 0,
      marginLeft: 100,
      marginRight: 100,
      graphs: [{
        type: "column",
        title: "Ventes",
        valueField: "sales",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return $filter("currency")(graphDataItem.values.value, "€") + ", (" + $filter("number")(graphDataItem.values.value / vm.totalSales * 100, 2) + "%)";
        }
      }, {
        type: "column",
        title: "Quantité",
        valueField: "quantity",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return $filter("number")(graphDataItem.values.value, 0) + " unités, (" + $filter("number")(graphDataItem.values.value / vm.totalUnit * 100, 2) + "%)";
        }
      }, {
        type: "column",
        title: "Marge",
        valueField: "marge",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return $filter("number")(graphDataItem.values.value, 2) + " €";
        }
      }]
    },
    marge: {
      data: vm.marge,
      type: "radar",
      categoryField: "palier",
      marginLeft: 100,
      marginRight: 100,
      legend: {
        enabled: true
      },
      thousandsSeparator: " ",
      categoryAxis: {
        gridPosition: "start",
        parseDates: false
      },
      precision: 0,
      graphs: [{
        type: "column",
        title: "Marge",
        valueField: "margeCompare",
        fillAlphas: 0.2,
        bullet: "round",
        lineThickness: 2,
        balloonFunction: function balloonFunction(graphDataItem) {
          return $filter("currency")(graphDataItem.values.value, "€") + ", (" + $filter("number")(graphDataItem.values.value / vm.totalSales * 100, 2) + "%)";
        }
      }]
    },
    margeNational: {
      data: vm.marge,
      type: "radar",
      categoryField: "palier",
      marginLeft: 100,
      marginRight: 100,
      legend: {
        enabled: true
      },
      thousandsSeparator: " ",
      categoryAxis: {
        gridPosition: "start",
        parseDates: false
      },
      precision: 0,
      graphs: [{
        type: "column",
        title: "Marge Moyenne Nationale",
        valueField: "margeNational",
        fillAlphas: 0.2,
        bullet: "round",
        lineThickness: 2,
        balloonFunction: function balloonFunction(graphDataItem) {
          return $filter("currency")(graphDataItem.values.value, "€") + ", (" + $filter("number")(graphDataItem.values.value / vm.totalSales * 100, 2) + "%)";
        }
      }]
    }
  };
  /*
       ** Date Filter
       */

  vm.rangeSelected = function (date) {
    vm.usrquery.date.from = moment(date.startDate, "DD-MM-YYYY");
    vm.usrquery.date.to = moment(date.endDate, "DD-MM-YYYY");
    vm.getMarges();
  };
}

angular.module("app.statistics.smoothed-degressive-margin").controller("MdlController", MdlController);