"use strict";
/**
 * Statistics generic ideal controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $timeout - $timeout.
 * @param {object} environmentService - Environment service.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $mdSidenav - $mdSidenav.
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} genericService - Generic service.
 * @param {object} genericBookmarkService - Generic bookmark service.
 * @param {object} analysisService - Analysis service.
 * @param {object} healthcenterService - Healthcenter service.
 */

StatisticsGenericIdealController.$inject = ["$rootScope", "$timeout", "environmentService", "$mdDialog", "$mdSidenav", "$scope", "StatisticsQueryService", "genericService", "genericBookmarkService", "analysisService", "healthcenterService"];

function StatisticsGenericIdealController($rootScope, $timeout, environmentService, $mdDialog, $mdSidenav, $scope, StatisticsQueryService, genericService, genericBookmarkService, analysisService, healthcenterService) {
  $scope.selected = [];
  $scope.zentivaId = 6443;
  $scope.filteredPharmas = [];
  $scope.idealLoaderOptions = {
    loading: true,
    loader: "Récupération de votre catalogue générique idéal...",
    empty: "Vous n'avez aucune données de ventes."
  };
  $scope.genericBookmark = [];
  $scope.notInPrestationValues = [];
  $scope.brutPercentPrestationError = false;
  $scope.canExport = false;
  environmentService.getEnvironment().then(function (env) {
    $scope.currentEnv = env;
    $scope.canExport = canExport();
  });
  $scope.tabConfig = {
    order: "name",
    limit: 10,
    page: 1,
    filter: ""
  };
  $scope.dtInstance = {};
  $scope.asc = 0;
  $scope.manualTotalPriceSorted = false;
  $scope.ascClass = "sorting";
  $scope.saveGenericIdealOrder = saveGenericIdealOrder;
  $scope.configIdealGeneric = {
    event: "pharmacyIdealSearched",
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
    tag: "participant",
    searchPlaceHolder: "Rechercher des pharmacies"
  };

  $scope.changeParacetamol = function () {
    $scope.moleculesToShow = [];
    $scope.idealLoaderOptions.loading = true;
    var order = processLaboratoriesOrder($scope.theoricdata.laboratory);
    processTheoricQuery($scope.query, order);
  };

  getGenericBookmark();
  $scope.noresult = false;
  $scope.table = {};
  $scope.selectedLab = null;
  $scope.labExpanded = false;
  $scope.hasChanged = false;
  $scope.usrquery = StatisticsQueryService.pristineSubstituteQuery;
  $scope.usrquery.must.pharmas.selected = [];
  var dateStart = moment().subtract(1, "year");
  var dateEnd = moment();
  $scope.dateLabel = dateStart.format("DD-MMM-YYYY") + " Au " + dateEnd.format("DD-MMM-YYYY");
  $scope.usrquery.date = {
    from: new Date(dateStart),
    to: new Date(dateEnd)
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    genericService.get().then(function (orederLabo) {
      var orderLabo = orederLabo;
      $scope.usrquery.lab.selected = _.sortBy($scope.usrquery.lab.selected, function (labo) {
        return labo.name;
      });
      var order = processLaboratoriesOrder($scope.usrquery.lab.selected); // origin

      StatisticsQueryService.buildSubstituteQuery($scope.usrquery).then(function (query) {
        $scope.query = query;

        if (orderLabo.length) {
          $scope.hasGenericLaboratoryOrder = true;
          order = orderLabo.map(function (laboratory) {
            return laboratory.laboratory_id;
          });
        }

        processTheoricQuery($scope.query, order);
      });
    });
  }

  $scope.prestationEuroChange = function (lab) {
    var notInPrestation = $scope.notInPrestationValues[lab.laboId];

    if (notInPrestation === undefined) {
      notInPrestation = 0;
    }

    lab.prestationPercent = parseFloat((lab.prestationEuro / (lab.totalDiscountPrice + notInPrestation) * 100).toFixed(2));
    lab.remisePercent = parseFloat(((1 - (lab.totalDiscountPrice + notInPrestation - lab.prestationEuro) / lab.totalPrice) * 100).toFixed(2));
    lab.remiseEuro = lab.remisePercent / 100 * lab.totalPrice;
    getTotalLabs($scope.datas);
  };

  $scope.prestationPercentChange = function (lab) {
    var notInPrestation = $scope.notInPrestationValues[lab.laboId];

    if (notInPrestation === undefined) {
      notInPrestation = 0;
    }

    if (lab.prestationPercent > 100) {
      lab.prestationPercent = 100;
      $scope.prestationPercentChange(lab);
      return;
    }

    if (lab.prestationPercent < 0) {
      lab.prestationPercent = 0;
      $scope.prestationPercentChange(lab);
      return;
    }

    lab.prestationEuro = parseFloat((lab.prestationPercent / 100 * (lab.totalDiscountPrice + notInPrestation)).toFixed(2));
    lab.remisePercent = ((1 - (lab.totalDiscountPrice + notInPrestation - lab.prestationEuro) / lab.totalPrice) * 100).toFixed(2);
    lab.remiseEuro = lab.remisePercent / 100 * lab.totalPrice;
    getTotalLabs($scope.datas);
  };

  $scope.prestationBrutPercentChange = function (lab) {
    var notInPrestation = $scope.notInPrestationValues[lab.laboId];

    if (notInPrestation === undefined) {
      notInPrestation = 0;
    }

    $scope.errorRemisePercent = lab.remisePercent > 40;
    lab.errorBrut = lab.remisePercent < lab.discount;
    lab.prestationEuro = parseFloat(lab.remisePercent / 100 * lab.totalPrice - (lab.totalPrice - (lab.totalDiscountPrice + notInPrestation))).toFixed(2);
    lab.remiseEuro = parseFloat(lab.totalPrice - (lab.totalDiscountPrice + notInPrestation) + parseFloat(lab.prestationEuro)).toFixed(2);
    lab.prestationPercent = (lab.prestationEuro / (lab.totalDiscountPrice + notInPrestation) * 100).toFixed(2);
    getTotalLabs($scope.datas);
  };
  /**
   * Get total laboratories.
   *
   * @param {Array} labs - Laboratories.
   */


  function getTotalLabs(labs) {
    $scope.labTotal = {
      totalPrice: 0,
      totalDiscountPrice: 0,
      remiseEuro: 0
    };
    angular.forEach(labs, function (value) {
      $scope.labTotal.totalPrice += value.totalPrice;
      $scope.labTotal.totalDiscountPrice += value.totalDiscountPrice;
      $scope.labTotal.remiseEuro += parseFloat(value.remiseEuro);
    });
    $scope.labTotal.discount = parseFloat(parseFloat(parseFloat($scope.labTotal.totalPrice - $scope.labTotal.totalDiscountPrice) / $scope.labTotal.totalPrice) * 100).toFixed(2);
    $scope.labTotal.remisePercent = parseFloat($scope.labTotal.remiseEuro / $scope.labTotal.totalPrice) * 100;
  }
  /**
   * Add input models to laboratories.
   *
   * @param {Array} list -
   * @returns {Array}
   */


  function addInputModelsToLabs(list) {
    return list.map(function (currentValue) {
      currentValue.prestationPercent = 0;
      currentValue.prestationEuro = 0;
      currentValue.discount = parseFloat(currentValue.discount) || 0;

      if (!currentValue.totalPrice) {
        currentValue.remisePercent = 0;
        currentValue.remiseEuro = 0;
      } else {
        currentValue.remisePercent = ((1 - (currentValue.totalDiscountPrice - currentValue.prestationEuro) / currentValue.totalPrice) * 100).toFixed(2);
        currentValue.remiseEuro = currentValue.remisePercent / 100 * currentValue.totalPrice;
      }

      return currentValue;
    });
  }
  /**
   * Process laboratories order.
   *
   * @param {Array} list -
   * @returns {Array}
   */


  function processLaboratoriesOrder(list) {
    var order = [];

    if (list[0].laboId) {
      order = list.map(function (currentValue) {
        return currentValue.laboId;
      });
    } else {
      order = list.map(function (currentValue) {
        return currentValue.id;
      });
    }

    return order;
  }
  /**
   * Process theoric query.
   *
   * @param {object} query - Query.
   * @param {string} order - Order.
   */


  function processTheoricQuery(query, order) {
    query.laboratoryOrder = order;
    delete query.laboratory; //

    $scope.datas = {};
    $scope.theoricMolecules = [];
    analysisService.searchTheoricGeneric(query).then(function (response) {
      $scope.idealLoaderOptions.loading = false;
      response.laboratory = addInputModelsToLabs(response.laboratory);
      $scope.theoricdata = response;
      $scope.parseDatas(response);
    }, function () {
      $scope.idealLoaderOptions.loading = false;
    });
  }
  /**
   * Has duplicates.
   *
   * @param {Array} array -
   * @returns {boolean}
   */


  function hasDuplicates(array) {
    // eslint-disable-next-line no-undef
    return new Set(array).size !== array.length;
  }

  $scope.doesItShows = function (index) {
    if ($scope.labExpanded) {
      return true;
    } else if (index < 3) {
      return true;
    }

    return false;
  };

  $scope.dragMoved = function (index) {
    $scope.idealLoaderOptions.loading = true;

    if (index !== $scope.datas.length) {
      $scope.datas.splice(index, 1);
    }

    $scope.hasChanged = true;

    if ($scope.theoricdata.laboratory) {
      var order = processLaboratoriesOrder($scope.datas);

      if (!hasDuplicates(order)) {
        processTheoricQuery($scope.query, order);
      }
    }
  };

  $scope.minimizeLabs = function () {
    $scope.labExpanded = false;
  };

  $scope.toggleSettingsSidenav = function () {
    $mdSidenav("settings-sidenav").toggle();
  };

  $scope.openSettingsTo = function (panel) {
    $rootScope.$broadcast("statistics:opento", panel);
  };

  $scope.rangeSelected = function (date) {
    $scope.usrquery.date.from = moment(date.startDate, "DD-MM-YYYY");
    $scope.usrquery.date.to = moment(date.endDate, "DD-MM-YYYY");
    $scope.idealLoaderOptions.loading = true;
    StatisticsQueryService.buildSubstituteQuery($scope.usrquery).then(function (query) {
      $scope.query = query;
      var order = processLaboratoriesOrder($scope.theoricdata.laboratory);
      processTheoricQuery($scope.query, order);
    });
  };

  $scope.updateLaboForMolecule = function (labo, laboSelected) {
    var laboTmp = labo.product;

    var laboBefore = _.find($scope.datas, function (data) {
      return data.laboId === laboTmp.laboId;
    });

    var laboAfter = _.find($scope.datas, function (data) {
      return data.laboId === labo.laboSelected.laboId;
    });

    labo.hasBetterDiscount = _.find(labo.products, function (prod) {
      return parseFloat(labo.laboSelected.discount) < parseFloat(prod.discount);
    });
    labo.product = laboSelected;
    laboAfter.totalPrice = laboAfter.totalPrice + labo.quantity * parseFloat(labo.laboSelected.price);
    laboAfter.totalDiscountPrice = laboAfter.totalDiscountPrice + parseFloat(labo.quantity) * (parseFloat(labo.laboSelected.price) - parseFloat(labo.laboSelected.discount) / 100 * parseFloat(labo.laboSelected.price));
    laboAfter.discount = (1 - laboAfter.totalDiscountPrice / laboAfter.totalPrice) * 100;
    laboAfter.remisePercent = ((1 - (laboAfter.totalDiscountPrice - laboAfter.prestationEuro) / laboAfter.totalPrice) * 100).toFixed(2);
    laboAfter.remiseEuro = laboAfter.remisePercent / 100 * laboAfter.totalPrice;
    laboBefore.totalPrice -= labo.quantity * parseFloat(labo.laboSelected.price);
    laboBefore.totalDiscountPrice -= labo.quantity * (parseFloat(labo.laboSelected.price) - parseFloat(labo.laboSelected.discount) / 100 * parseFloat(labo.laboSelected.price));
    laboBefore.discount = (1 - laboBefore.totalDiscountPrice / laboBefore.totalPrice) * 100;
    laboBefore.remisePercent = ((1 - (laboBefore.totalDiscountPrice - laboBefore.prestationEuro) / laboBefore.totalPrice) * 100).toFixed(2);
    laboBefore.remiseEuro = laboBefore.remisePercent / 100 * laboBefore.totalPrice;
    getTotalLabs($scope.datas);
  };

  $scope.updateLabosList = function () {
    $scope.hasChanged = true;
    $scope.datas = [];
    $scope.theoricdata.laboratory = $scope.laboSelectedToShow;

    if ($scope.theoricdata.laboratory) {
      var order = processLaboratoriesOrder($scope.theoricdata.laboratory);

      if (!hasDuplicates(order)) {
        $scope.idealLoaderOptions.loading = true;
        processTheoricQuery($scope.query, order);
      }
    }
  };

  $scope.notInPrestationChange = function (mol) {
    if ($scope.notInPrestationValues[mol.laboSelected.laboId] === undefined) {
      $scope.notInPrestationValues[mol.laboSelected.laboId] = 0;
    }

    if (mol.notInPrestation === true) {
      $scope.notInPrestationValues[mol.laboSelected.laboId] += parseFloat(mol.totalPrice);
    } else {
      $scope.notInPrestationValues[mol.laboSelected.laboId] -= parseFloat(mol.totalPrice);
    }
  };

  $scope.hasBetterDiscount = function (mol) {
    angular.forEach(mol.products, function (prod) {
      if (parseFloat(prod.discount) > parseFloat(mol.laboSelected.discount)) {
        mol.bestDiscount = prod;
        mol.hasBetterDiscount = true;
      } else if (mol.hasBetterDiscount !== true) {
        mol.hasBetterDiscount = false;
      }
    });
  };

  $scope.parseDatas = function (data) {
    var datas = [];
    var tmp = [];
    $scope.theoricMolecules = [];

    _.each(data.group, function (molecule) {
      _.each($scope.theoricdata.laboratory, function (labo, index) {
        if (!datas[index]) {
          datas[index] = {};
          datas[index].totalPrice = 0;
          datas[index].totalDiscountPrice = 0;
          datas[index].laboName = labo.laboName;
          datas[index].laboId = labo.laboId;
          datas[index].discount = 0;
          datas[index].prestationEuro = 0;
          datas[index].prestationPercent = 0;
          datas[index].quadri = labo.quadri;
          datas[index].color = labo.color;

          if (!datas[index].totalPrice) {
            datas[index].remisePercent = 0;
            datas[index].remiseEuro = 0;
          }
        }

        var product = _.find(molecule.products, function (prod) {
          return prod.laboId === labo.laboId;
        });

        if (product && typeof _.find(tmp, function (id) {
          return id === molecule.key;
        }) === "undefined") {
          if (typeof product.discount === "undefined") {
            product.discount = 0;
          }

          product.quantity = molecule.quantity;
          tmp.push(molecule.key);
          datas[index].laboName = labo.laboName;
          datas[index].laboId = labo.laboId;
          datas[index].discount = parseFloat(labo.discount).toFixed(2);
          datas[index].totalPrice = parseFloat(labo.totalPrice);
          datas[index].totalDiscountPrice = parseFloat(labo.totalDiscountPrice);
          datas[index].prestationEuro = 0;
          datas[index].prestationPercent = 0;
          datas[index].quadri = labo.quadri;
          datas[index].color = labo.color;

          if (!datas[index].totalPrice) {
            datas[index].remisePercent = 0;
            datas[index].remiseEuro = 0;
          } else {
            datas[index].remisePercent = ((1 - (datas[index].totalDiscountPrice - datas[index].prestationEuro) / datas[index].totalPrice) * 100).toFixed(2);
            datas[index].remiseEuro = datas[index].remisePercent / 100 * datas[index].totalPrice;
          }
        }
      });
    });

    $scope.datas = datas;
    getTotalLabs($scope.datas);
    $scope.theoricMolecules = $scope.theoricdata.group;
    $scope.laboSelected = [];

    if ($scope.hasChanged === false || $scope.hasGenericLaboratoryOrder === false) {
      $scope.theoricdata.laboratory = _.sortBy($scope.theoricdata.laboratory, function (labo) {
        return labo.laboName;
      });
    }

    _.each($scope.theoricMolecules, function (mol) {
      _.each($scope.theoricdata.laboratory, function (labo) {
        _.each(mol.products, function (prod) {
          if (prod.laboId === labo.laboId && typeof mol.laboSelected === "undefined") {
            mol.laboSelected = prod;
          }

          if (!prod.discount) {
            prod.discount = 0;
          }

          prod.nameWithDiscount = prod.quadri + " / " + prod.discount + "%";
        });
      });
    });
  };

  $scope.bestDiscountAuto = function () {
    _.each($scope.theoricMolecules, function (mol) {
      _.each(mol.products, function (prod) {
        if (parseFloat(prod.discount) > parseFloat(mol.laboSelected.discount)) {
          mol.laboSelected = prod;
          mol.hasBetterDiscount = false;
        }
      });
    });
  };

  $scope.saveGenericIdeal = function (ev) {
    var confirm = $mdDialog.prompt().title("Enregistrer votre configuration").textContent("Veuillez spécifier un nom :").placeholder("Nom").ariaLabel("Nom de l'enregistrement").targetEvent(ev).ok("Enregistrer").cancel("Annuler");
    $mdDialog.show(confirm).then(function (fileName) {
      var obj = {
        name: fileName,
        content: angular.toJson({
          theoricdata: $scope.theoricdata
        })
      };
      genericBookmarkService.create(obj).then(getGenericBookmark);
    });
  };

  $scope.genericGetBookmark = function (bookmark) {
    $scope.idealLoaderOptions.loading = true;
    $scope.theoricdata = bookmark.theoricdata;
    $scope.parseDatas(bookmark.theoricdata);
    $scope.idealLoaderOptions.loading = false;
  };
  /**
   * Get generic bookmarks.
   *
   * @returns {Promise}
   */


  function getGenericBookmark() {
    return genericBookmarkService.get().then(function (bookmarks) {
      if (bookmarks.bookmark.length) {
        $scope.genericBookmark = deserializeGenericBookmarks(bookmarks.bookmark);
      }
    });
  }
  /**
   * Deserialize generick bookmarks.
   *
   * @param {string} bookmarks - JSON bookmarks list.
   * @returns {Array}
   */


  function deserializeGenericBookmarks(bookmarks) {
    return bookmarks.map(function (item) {
      var bookmark = angular.fromJson(item.content) || {};
      bookmark.id = item.id;
      bookmark.user_id = item.user_id;
      bookmark.title = item.name;
      return bookmark;
    });
  }

  $scope.exportExcel = function () {
    $scope["export"] = [];
    $scope.exportHeaders = ["Nom", "Cip7", "Cip13", "Prix", "Quantité Totale", "Labo", "Quantité", "Remise", "Prix remisé", "Total acheté"];

    _.each($scope.theoricMolecules, function (molecule) {
      if (molecule.quantity > 0 && molecule.product.price) {
        $scope["export"].push({
          name: molecule.name,
          cip7: molecule.product.cip7,
          cip13: molecule.product.cip13,
          price: molecule.product.price.toString().replace(",", " ").replace(".", ","),
          totalQuantity: molecule.quantity,
          labo: molecule.laboSelected.quadri,
          quantity: molecule.quantity,
          discount: molecule.laboSelected.discount,
          discountedPrice: (molecule.product.price - molecule.product.price * molecule.laboSelected.discount / 100).toString().replace(",", " ").replace(".", ","),
          totalPrice: ((molecule.product.price - molecule.product.price * molecule.laboSelected.discount / 100) * molecule.quantity).toString().replace(",", " ").replace(".", ",")
        });
      }
    });

    return $scope["export"];
  };

  $scope.removeFilter = function (obj, list) {
    var index = list.indexOf(obj);

    if (index !== -1) {
      list.splice(index, 1);
      $scope.idealLoaderOptions.loading = true;
      StatisticsQueryService.buildSubstituteQuery($scope.usrquery).then(function (query) {
        $scope.query = query;
        var order = processLaboratoriesOrder($scope.theoricdata.laboratory); // origin

        processTheoricQuery($scope.query, order);
      });
    }
  };

  $scope.sortByTotalPrice = function () {
    $scope.asc = changeOrder($scope.asc);
    $scope.manualTotalPriceSorted = true;
    $scope.theoricMolecules = _.sortBy($scope.theoricMolecules, function (mol) {
      if ($scope.asc === -1) {
        return -parseFloat((mol.product.price - mol.product.price * mol.laboSelected.discount / 100) * mol.quantity);
      }

      return parseFloat((mol.product.price - mol.product.price * mol.laboSelected.discount / 100) * mol.quantity);
    });
    $timeout(function () {
      if ($scope.asc === 1) {
        $scope.ascClass = "sorting_asc";
      } else if ($scope.asc === -1) {
        $scope.ascClass = "sorting_desc";
      }
    }, 0);
  };
  /**
   * Change order.
   *
   * @param {number} asc -
   * @returns {number}
   */


  function changeOrder(asc) {
    switch (asc) {
      case 0:
        return 1;

      case 1:
        return -1;

      case -1:
        return 1;
    }

    return asc;
  }

  $scope.$on("pharmacyIdealSearched", function (event, query) {
    var queryPharma = {
      type: {}
    };

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(query, type)) {
        if (typeof queryPharma.type[type] === "undefined") {
          queryPharma.type[type] = [];
        }

        angular.forEach(query[type], function (obj) {
          if (type === "uga") {
            queryPharma.type[type].push(obj.name);
          } else {
            queryPharma.type[type].push(obj.id);
          }
        });
      }
    };

    for (var type in query) {
      _loop(type);
    }

    queryPharma.full = true; // getPharmas with query results

    healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      angular.forEach(pharmas, function (pharma) {
        $scope.usrquery.must.pharmas.selected.push(pharma._source);
        $scope.filteredPharmas.push(pharma._source);
      });
      StatisticsQueryService.buildSubstituteQuery($scope.usrquery).then(function (query) {
        $scope.query = query;
        var order = processLaboratoriesOrder($scope.theoricdata.laboratory); // origin

        processTheoricQuery($scope.query, order);
      });
    });
  });

  $scope.deletePharmaFromFilter = function (index) {
    $scope.usrquery.must.pharmas.selected.splice(index, 1);
    $scope.filteredPharmas.splice(index, 1);
    StatisticsQueryService.buildSubstituteQuery($scope.usrquery).then(function (query) {
      $scope.query = query;
      var order = processLaboratoriesOrder($scope.theoricdata.laboratory);
      processTheoricQuery(query, order);
    });
  };
  /**
   * Can export.
   *
   * @returns {boolean}
   */


  function canExport() {
    var authorizedIds = [63, 57, 43, 90];
    return authorizedIds.indexOf($scope.currentEnv.userId) > -1;
  }
  /**
   * save generic ideal order
   */


  function saveGenericIdealOrder() {
    var data = processLaboratoriesOrder($scope.theoricdata.laboratory);
    genericService.saveGenericIdealOrder(data);
  }
}

angular.module("app.statistics.genericaudit").controller("StatisticsGenericIdealController", StatisticsGenericIdealController);