/* eslint-disable no-invalid-this */
"use strict";
/**
 * Data model branding slide.
 *
 * @param {object} $q - $q.
 * @returns {object}
 */

DataModelBrandingSlide.$inject = ["$q"];

function DataModelBrandingSlide($q) {
  var model = {
    groupingBrandingSliderId: "",
    fileId: "",
    file: {},
    linkUrl: "",
    title: "",
    content: ""
  };
  /**
   * Constructor, with class name
   *
   * @param {object} groupingBrandingSliderId -
   */

  function slide(groupingBrandingSliderId) {
    this.groupingBrandingSliderId = groupingBrandingSliderId;
    this.fileId = model.fileId;
    this.file = model.file;
    this.linkUrl = model.linkUrl;
    this.title = model.title;
    this.content = model.content;
  }
  /**
   * slide.prototype.build
   *
   * @param {object} data -
   * @returns {Promise}
   */


  slide.prototype.build = function (data) {
    return $q(function (resolve) {
      resolve(new slide(data.groupingBrandingId));
    });
  };
  /**
   * Return the constructor function
   * branding
   */


  return slide;
}

angular.module("app.grouping").factory("dataModelBrandingSlide", DataModelBrandingSlide);