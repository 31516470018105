"use strict";
/**
 * Event service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

eventService.$inject = ["apiClient"];

function eventService(apiClient) {
  return {
    /**
     * Get events.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getEvents: function getEvents(params) {
      return apiClient.get("/calendars", {
        parameters: params,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get events for healthcenter by laboratory.
     *
     * @param {object} params - The params.
     * @param {number} laboratoryId - The laboratory id.
     *
     * @returns {Promise}
     */
    getEventsForHealthcenterByLaboratory: function getEventsForHealthcenterByLaboratory(params, laboratoryId) {
      return apiClient.get("/calendars/healthcenters/".concat(laboratoryId), {
        parameters: params,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get events by Ids.
     *
     * @param {object} params - The params
     *
     * @returns {Promise}
     */
    getEventsByIds: function getEventsByIds(params) {
      return apiClient.get("/events/byIds", {
        includes: ["custom.organizations.morphable", "custom.products"],
        parameters: params
      });
    },

    /**
     * Read an event.
     *
     * @param {object} params - The params
     *
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/events/".concat(params.id), {
        includes: ["custom.organizations.pivot", "custom.organizations.morphable", "organization"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.event;
      });
    },

    /**
     * Get gantt events.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    getGanttEvents: function getGanttEvents(params) {
      return apiClient.get("/calendars/gantt", {
        parameters: params,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get patient appointment events.
     *
     * @returns {Promise}
     */
    getPatientAppointmentEvents: function getPatientAppointmentEvents() {
      return apiClient.get("/calendars/patients", {
        includes: ["custom_event.manager"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get event types.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    getEventTypes: function getEventTypes(params) {
      return apiClient.get("/events/types/all", {
        parameters: params,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create an event.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    create: function create(params) {
      return apiClient.post("/events", params, {
        includes: ["custom.organizations.pivot"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.event;
      });
    },

    /**
     * Update an event.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/events/".concat(params.id), params, {
        includes: ["custom.organizations.pivot", "custom.organizations.morphable"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.event;
      });
    },

    /**
     * Delete an event.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/events/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Download a file.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    downloadFile: function downloadFile(params) {
      return apiClient.get("/events/".concat(params.id, "/downloadFile"), {
        responseType: "arraybuffer",
        transformResponse: function transformResponse(response) {
          return {
            file: response
          };
        },
        wrappers: {
          response: null
        }
      });
    },

    /**
     * Get next event of type.
     *
     * @param {number} type - The event type.
     * @param {number} healthcenterId - The healthcenter id.
     *
     * @returns {Promise}
     */
    getNextEventOfType: function getNextEventOfType(type, healthcenterId) {
      return apiClient.get("/events/type/".concat(type, "/next"), {
        parameters: {
          healthcenterId: healthcenterId
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.events").service("eventService", eventService);