"use strict";
/**
 * The appointment factory.
 *
 * @returns {object}
 */

function AppointmentsFactory() {
  var factory = {};
  factory.calendarConfig = {
    calendar: {
      height: "100%",
      aspectRatio: 2,
      selectable: true,
      lang: "fr",
      timeFormat: "HH:mm",
      header: {
        left: "prev,next today",
        center: "title",
        right: "month basicWeek basicDay agendaDay"
      },
      buttonText: {
        today: "Aujourd'hui",
        month: "Mois",
        basicWeek: "Semaine",
        basicDay: "Jour",
        agendaDay: "Agenda"
      },
      allDayText: "Toute la journée",
      eventLimit: 4,
      eventLimitText: "de plus",
      dayPopoverFormat: "DD MMMM YYYY",
      views: {
        month: {
          titleFormat: "DD MMMM YYYY"
        },
        basicWeek: {
          titleFormat: "DD MMMM YYYY"
        },
        basicDay: {
          titleFormat: "DD MMMM YYYY"
        },
        agendaWeek: {
          titleFormat: "DD MMMM YYYY"
        },
        agendaDay: {
          titleFormat: "DD MMMM YYYY"
        },
        axisFormat: "H:mm",
        timeFormat: {
          agenda: "H:mm{ - H:mm}"
        },
        slotLabelFormat: "HH:mm",
        eventTextColor: "black",
        allDayText: "Toute la journée"
      }
    }
  };
  factory.eventTypes = [{
    id: 16,
    name: "Vaccin"
  }, {
    id: 17,
    name: "Entretien pharma."
  }, {
    id: 18,
    name: "Test antigénique"
  }];
  factory.bookingDuration = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
  return factory;
}

angular.module("app.patients.factories").factory("appointmentsFactory", AppointmentsFactory);