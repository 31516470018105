"use strict";
/**
 * Controller for patient orders summary, in pharmacy dashboard.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} customerService - The customer service.
 */

dashboardPharmaciesPatientOrdersSummaryController.$inject = ["$scope", "$state", "customerService"];

function dashboardPharmaciesPatientOrdersSummaryController($scope, $state, customerService) {
  $scope.loading = true;
  $scope.redirectToList = redirectToList;
  loader();
  /**
   * The loader function
   */

  function loader() {
    customerService.getCustomersPrescriptionRenewalSummary().then(function (summary) {
      $scope.summary = summary;
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Redirect to list with a renewal status filter.
   *
   * @param {string} filter - The filter.
   *
   * @returns {void}
   */


  function redirectToList(filter) {
    $state.go("app.patients.orders", {
      orderStatus: filter
    });
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesPatientOrdersSummaryController", dashboardPharmaciesPatientOrdersSummaryController);