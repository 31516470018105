"use strict";
/**
 * Real marge service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

RealMargeService.$inject = ["apiClient"];

function RealMargeService(apiClient) {
  return {
    /**
     * Get.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.post("/analysis/real-marge", params, {
        includes: ["laboratory"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.statistics.realMarge").service("realMargeService", RealMargeService);