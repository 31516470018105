"use strict";
/**
 * App widget tile button directive.
 *
 * @returns {object}
 */

function AppWidgetTitleButton() {
  return {
    restrict: "E",
    scope: {
      icon: "@icon"
    },
    templateUrl: "app/main/directives/app-widget/app-widget-title-button/appWidgetTitleButton.html",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appWidgetTitleButton", AppWidgetTitleButton);