"use strict";
/**
 * The controller for the patient count card, in pharmacy dashboard.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} customerService - The customer service.
 * @param {object} environmentService - The environment service.
 */

dashboardPharmaciesPatientToCertifiedCountController.$inject = ["$scope", "$state", "customerService", "environmentService"];

function dashboardPharmaciesPatientToCertifiedCountController($scope, $state, customerService, environmentService) {
  $scope.loading = true;
  $scope.searchText;
  $scope.toActivateCount = 0;
  $scope.redirectToList = redirectToList;
  loader();
  /**
   * The loader function
   */

  function loader() {
    environmentService.getEnvironment().then(function (environment) {
      return customerService.getFutureCustomers(environment.entityId);
    }).then(function (futureCertifiedCustomers) {
      $scope.toActivateCount = futureCertifiedCustomers.length || 0;
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Redirect to list with a to certify status filter.
   *
   * @param {string} filter - The filter.
   *
   * @returns {void}
   */


  function redirectToList(filter) {
    $state.go("app.patients", {
      toCertifyFilter: filter
    });
  }
}

angular.module("app.dashboard").controller("dashboardToCertifiedCountController", dashboardPharmaciesPatientToCertifiedCountController);