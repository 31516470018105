"use strict";
/**
 * Thermometer graph directive.
 *
 * @returns {object}
 */

ThermometerGraphController.$inject = ["$scope"];

function ThermometerGraphDirective() {
  return {
    restrict: "E",
    scope: {
      data: "@"
    },
    templateUrl: "app/main/directives/stats/thermometerGraph/thermometerGraph.tmpl.html",
    controller: ThermometerGraphController
  };
}
/**
 * Thermometer graph controller.
 *
 * @param {object} $scope - $scope.
 */


function ThermometerGraphController($scope) {
  $scope.thermometerOptions = {
    type: "serial",
    data: [{
      x: 0,
      y1: $scope.data > 100 ? 100 : $scope.data,
      y2: 100 - ($scope.data > 100 ? 100 : $scope.data)
    }],
    categoryField: "x",
    rotate: true,
    autoMargins: false,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    chartCursor: {
      enabled: false
    },
    graphs: [{
      valueField: "y1",
      type: "column",
      lineAlphas: $scope.data <= 0 ? 0 : 1,
      fillAlphas: 0.5,
      fillColors: "#88ff00",
      lineColor: "#88ff00",
      lineThickness: 1,
      gradientOrientation: "horizontal",
      showBalloon: false
    }, {
      valueField: "y2",
      type: "column",
      lineAlpha: 1,
      fillAlphas: 1,
      fillColors: "#AAA",
      lineThickness: $scope.data >= 100 ? 0 : 1,
      lineColor: "#AAA",
      showBalloon: false
    }],
    valueAxes: [{
      gridAlpha: 0,
      axisAlpha: 0,
      stackType: "100%"
    }],
    categoryAxis: {
      gridAlpha: 0,
      axisAlpha: 0
    }
  };
}

angular.module("app.directives").directive("thermometerGraph", ThermometerGraphDirective);