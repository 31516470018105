"use strict";

StatsStocksController.$inject = ["$state", "$scope", "environmentService"];
angular.module("app.statistics.stocks").controller("StatsStocksController", StatsStocksController);
/** @ngInject */

/**
 * Stat stock controller.
 *
 * @param {object} $state - The angular $state object.
 * @param {object} $scope - The angular $scope object.
 * @param {object} environmentService - The environment service.
 */

function StatsStocksController($state, $scope, environmentService) {
  var vm = this;
  var currentState = $state.current.name;
  environmentService.getEnvironment().then(function (env) {
    vm.currentEnv = env; // TABS STATES

    switch (currentState) {
      case "app.statistics.stocks.stock":
        vm.selectedIndex = 0;
        break;

      case "app.statistics.stocks.map":
        vm.selectedIndex = 0;
        break;

      case "app.statistics.stocks.stat":
        vm.selectedIndex = 3;
        break;

      case "app.statistics.stocks.needs":
        vm.selectedIndex = 0;
        break;

      case "app.statistics.stocks.supplying":
        vm.selectedIndex = 2;
        break;

      case "app.statistics.stocks.microDisruption":
        vm.selectedIndex = 3;
        break;

      default:
        vm.selectedIndex = 0;
    }
  });
}