"use strict";
/**
 * Laboratory universe range configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

LaboratoryUniverseRangeConfig.$inject = ["$stateProvider"];

function LaboratoryUniverseRangeConfig($stateProvider) {
  $stateProvider.state("app.laboratory-universe-range", {
    url: "/laboratoire/univers-et-gammes",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory/universe-range/universe-range.html",
        controller: "LaboratoryUniverseRangeController as vm"
      }
    }
  }).state("app.laboratory-universe-range.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/laboratory/universe-range/modals/universe-range-create-edit.html",
        controller: "LaboratoryUniverseRangeEditController as vm"
      }
    }
  });
}

angular.module("app.laboratory-universe-range", []).config(LaboratoryUniverseRangeConfig);