"use strict";
/**
 * Create PLV directive.
 *
 * @returns {object}
 */

CreatePlvModalController.$inject = ["$scope", "$mdDialog", "$state", "notifyUserService", "plvsFormatsService", "plvsService", "plvsOrientationsService", "plvsTypesService", "formatTypes", "modalTitle", "currentState"];
CreatePlvController.$inject = ["$scope", "$mdDialog", "$state"];

function CreatePlv() {
  return {
    restrict: "E",
    scope: {
      label: "@label",
      title: "@title",
      modalTitle: "@modalTitle",
      icon: "@icon",
      tooltip: "@tooltip",
      request: "=request",
      variant: "@variant"
    },
    templateUrl: "app/main/generator/directives/createPlv/createPlv.html",
    controller: CreatePlvController
  };
}
/**
 * Create PLV controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $state - $state.
 *
 * @ngInject
 */


function CreatePlvController($scope, $mdDialog, $state) {
  var previousState = $state.current.name.replace("app.", "");
  var currentState = previousState.replace(".", "/");
  $scope.open = open;
  /**
   * open the modal
   *
   * @param {object} event - the click event.
   */

  function open(event) {
    var dialog = {
      controller: CreatePlvModalController,
      templateUrl: "app/main/generator/directives/createPlv/modal.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        formatTypes: $scope.request,
        modalTitle: $scope.modalTitle,
        currentState: currentState
      },
      clickOutsideToClose: true
    };
    $mdDialog.show(dialog);
  }
}
/**
 * Create PLV modal controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $state - $state.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} plvsFormatsService - Plvs formats service.
 * @param {object} plvsService - Plvs service.
 * @param {object} plvsOrientationsService - Plvs orientations service.
 * @param {object} plvsTypesService - Plvs types service.
 * @param {object} formatTypes - Format types.
 * @param {string} modalTitle - Modal title.
 * @param {string} currentState - Current state.
 *
 * @ngInject
 */


function CreatePlvModalController($scope, $mdDialog, $state, notifyUserService, plvsFormatsService, plvsService, plvsOrientationsService, plvsTypesService, formatTypes, modalTitle, currentState) {
  $scope.selectedFormat = {};
  $scope.selectedOrientation;
  $scope.currentType = false;
  $scope.selected = [];
  $scope.formatsOptions = {
    loading: true,
    loader: "Récupération des formats.",
    empty: "Aucun format disponible."
  };
  $scope.updateSelection = updateSelection;
  $scope.loadFormatsByType = loadFormatsByType;
  $scope.selectFormat = selectFormat;
  $scope.modalTitle = modalTitle;
  $scope.save = save;
  $scope.cancel = cancel;
  loader();
  /**
   * loader
   *
   * @description To call each method inside
   */

  function loader() {
    loaderOrientations().then(function () {
      if (formatTypes) {
        var query = {
          id: formatTypes.type_id,
          format_name: formatTypes.format_name
        };
        loadFormatsByType(query);
      } else {
        loaderTypes();
      }
    });
  }
  /**
   * loaderTypes
   *
   * @returns {Promise}
   */


  function loaderTypes() {
    return plvsTypesService.getTypes().then(function (response) {
      $scope.types = response;
      $scope.types[0].active = true;
      loadFormatsByType(response[0]);
      return response;
    })["catch"](function (error) {
      notifyUserService.showSimpleToast("Une erreur est survenue lors du chargement des types de format", {
        color: "danger",
        hideDelay: 1000
      });
      return error;
    });
  }
  /**
   * loadFormatsByType
   *
   * @param {object} formatTypes - The format Type
   *
   * @returns {Promise}
   */


  function loadFormatsByType(formatTypes) {
    $scope.autoSelectFormat = true;
    $scope.formatsOptions.loading = true;
    $scope.formats = [];

    if (!formatTypes.format_name) {
      $scope.autoSelectFormat = false;
      $scope.currentType = formatTypes;
      $scope.types.forEach(function (nav) {
        nav.active = formatTypes.id === nav.id;
      });
    }

    return plvsFormatsService.getByTypeId(formatTypes).then(function (response) {
      $scope.formats = response;
      $scope.formatsOptions.loading = false;

      if (!$scope.autoSelectFormat) {
        $scope.selectedFormat = response[0];
        $scope.selectedFormat.active = true;
        return response;
      }

      var autSelectedFormat = $scope.formats.filter(function (format) {
        return format.name === formatTypes.format_name;
      });
      $scope.selectedFormat = autSelectedFormat[0];
      $scope.selectedFormat.active = true;
      return response;
    })["catch"](function (error) {
      notifyUserService.showSimpleToast("Une erreur est survenue lors du chargement des formats ".concat(formatTypes.format_name), {
        color: "danger",
        hideDelay: 1000
      });
      return error;
    });
  }
  /**
   * loaderOrientations
   *
   * @returns {Promise}
   */


  function loaderOrientations() {
    return plvsOrientationsService.load().then(function (response) {
      $scope.orientations = response;
      response[0].checked = true;
      $scope.selectedOrientation = response[0];
      return response;
    })["catch"](function (error) {
      notifyUserService.showSimpleToast("Une erreur est survenue lors du chargement des orientations paysage / portrait", {
        color: "danger",
        hideDelay: 1000
      });
      return error;
    });
  }
  /**
   * updateSelection
   *
   * @param {object} position - current position of document
   * @param {Array} orientations - orientations
   */


  function updateSelection(position, orientations) {
    orientations.forEach(function (orientation, index) {
      orientation.checked = false;

      if (position === index) {
        $scope.selectedOrientation = orientation;
      }
    });
  }
  /**
   * selectFormat
   *
   * @param {object} format -
   */


  function selectFormat(format) {
    $scope.selectedFormat = format;
    $scope.formats.forEach(function (myFormat) {
      myFormat.active = false;
    });
    $scope.selectedFormat.active = true;
  }
  /**
   * save
   *
   * @param {object} form - angularJs form
   */


  function save(form) {
    var myDocument = {
      name: form.name.$modelValue,
      description: form.description.$modelValue,
      orientation_id: $scope.selectedOrientation.id,
      format_id: $scope.selectedFormat.id
    };
    plvsService.create(myDocument).then(function (response) {
      notifyUserService.showSimpleToast("Le support \xAB ".concat(response.name, " \xBB a bien \xE9t\xE9 cr\xE9\xE9"), {
        color: "success",
        hideDelay: 1000
      });
      return response;
    }).then(goToEditor)["catch"](function () {
      notifyUserService.showSimpleToast("Une erreur est survenue lors de la création de votre support", {
        color: "danger",
        hideDelay: 1000
      });
      cancel();
    });
  }
  /**
   * goToEditor
   *
   * @param {object} myDocument - the plv document
   */


  function goToEditor(myDocument) {
    var query = {
      id: myDocument.id,
      stateOrigin: currentState,
      injectedService: "plvsService"
    };
    $state.go("app.generator.edit", query);
    cancel();
  }
  /**
   * cancel
   */


  function cancel() {
    $mdDialog.cancel();
  }
}

angular.module("app.generator").directive("createPlv", CreatePlv);