"use strict";
/**
 * Wholesaler catalog products controller.
 *
 * @param {object} $scope - Scope
 * @param {object} $rootScope - Root scope
 * @param {object} $state - State
 * @param {object} $stateParams - State params
 * @param {object} $mdDialog - MD dialog
 * @param {object} notifyUserService - Notify user service
 * @param {object} wholesalerCatalogConfig - Wholesaler catalog config
 * @param {object} wholesalerCatalogService - Wholesaler catalog service
 * @param {object} wholesalerService - Wholesaler service
 * @param {object} environmentService - Environment service
 * @param {object} StatisticsQueryService - Statistics query service
 */

WholesalerCatalogProducts.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "$mdDialog", "notifyUserService", "wholesalerCatalogConfig", "wholesalerCatalogService", "wholesalerService", "environmentService", "StatisticsQueryService"];

function WholesalerCatalogProducts($scope, $rootScope, $state, $stateParams, $mdDialog, notifyUserService, wholesalerCatalogConfig, wholesalerCatalogService, wholesalerService, environmentService, StatisticsQueryService) {
  showSalesController.$inject = ["$scope", "sales", "$mdDialog"];
  $scope.loading = true;
  $scope.openTab = openTab;
  $scope.getWholesalerCatalog = getWholesalerCatalog;
  $scope.getWholesalerCatalogProducts = getWholesalerCatalogProducts;
  $scope.changeAllDiscountPercentage = changeAllDiscountPercentage;
  $scope.cancelProductPriceChanges = cancelProductPriceChanges;
  $scope.saveProductPrices = saveProductPrices;
  $scope.showWholesalerSales = showWholesalerSales;
  $scope.getFilters = getFilters;
  $scope.filterProductsByLaboratory = filterProductsByLaboratory;
  $scope.getProductStocks = getProductStocks;
  $scope.getWholesalerPriceLists = getWholesalerPriceLists;
  $scope.createPriceList = createPriceList;
  $scope.editPriceList = editPriceList;
  var productIds = [];
  var wholesalerProductIds = [];
  var selectedTab = "products-list";
  $scope.tabs = [{
    id: 1,
    name: "Mes laboratoires",
    method: getLaboratories,
    slug: "laboratories-list",
    isActive: true
  }, {
    id: 2,
    name: "Mon catalogue",
    method: getProducts,
    slug: "products-list",
    isActive: true
  }, {
    id: 3,
    name: "Mes stocks",
    method: getProductStocks,
    slug: "products-stock",
    isActive: true
  }, {
    id: 4,
    name: "Offres de prix",
    method: getWholesalerPriceLists,
    slug: "price-lists",
    isActive: true
  }]; // Product import config

  $scope.importProductConfig = {
    entityName: "WholesalerProduct",
    callbackEvent: "addProductToWholesalerProductFromImport"
  }; // Product search config

  $scope.productSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    event: "searchForWholesalerProducts",
    tag: "searchProductWholesalerCatalog",
    autoLaunchEvent: false,
    must: {
      "wholesaler.id": []
    }
  }; // Order and  pagination of products result

  $scope.wholesalerCatalog = {};
  $scope.productNeedDays = 15;
  $scope.limitOptions = [25, 50, 100, 200, 500, 1000];
  $scope.rawValues = StatisticsQueryService.values;
  $scope.filter = {
    tva: [],
    refund: [],
    delivrance: [],
    generique: []
  };
  $scope.query = {
    order: "product.name"
  };
  $scope.pagination = {
    current_page: 1,
    per_page: 200
  };
  var selectedFilters = {};
  loader();
  /**
   * The loader function.
   */

  function loader() {
    // If ask for the price list
    if ($state.current.name === "app.wholesaler.catalogs.priceList") {
      selectedTab = "price-lists";
    } else if ($state.current.name === "app.wholesaler.catalogs.stocks") {
      selectedTab = "products-stock";
    }

    environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      getWholesalerCatalog();

      if (selectedTab === "price-lists") {
        openTab($scope.tabs[3]);
      } else {
        $scope.productSearchConfig.must["wholesaler.id"] = [response.entityId];
        openTab($scope.tabs[0]);
      }
    });
  }
  /**
   * Open a tab.
   *
   * @param {object} myTab - myTab
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";
        tab.method();
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Get laboratories of the wholesaler.
   */


  function getLaboratories() {
    $scope.loading = true;
    getWholesalerCatalogLaboratories();
  }
  /**
   * Get products of the wholesaler.
   */


  function getProducts() {
    $scope.loading = true;
    getWholesalerPriceLists().then(function () {
      getWholesalerCatalogProducts();
    });
  }
  /**
   * Return wholesaler's catalog informations.
   *
   * @returns {Promise} The promise
   */


  function getWholesalerCatalog() {
    // Get the products filtered and paginated
    return wholesalerCatalogService.readCatalog({
      wholesalerId: $scope.currentAccess.entityId,
      id: $stateParams.id ? $stateParams.id : $stateParams.wholesalerCatalogId
    }).then(function (wholesalerCatalog) {
      $scope.wholesalerCatalog = wholesalerCatalog;
    });
  }
  /**
   * Return the laboratories from wholesaler's catalog.
   *
   * @returns {Promise} The promise
   */


  function getWholesalerCatalogLaboratories() {
    $scope.loading = true;
    var postParams = {
      wholesalerId: $scope.currentAccess.entityId,
      id: $stateParams.id
    }; // Get the laboratories

    return wholesalerCatalogService.getCatalogLaboratories(postParams).then(function (laboratories) {
      var wholesalerLaboratories = [];
      var found = false;
      var firstLetter = ""; // For each laboratory, get the first letter

      laboratories.forEach(function (wholesalerLaboratory) {
        firstLetter = wholesalerLaboratory.name.charAt(0);
        found = wholesalerLaboratories.findIndex(function (element) {
          return element.letter === firstLetter;
        }); // If not found yet, create the letter

        if (found === -1) {
          wholesalerLaboratories.push({
            letter: firstLetter,
            laboratories: []
          });
          found = wholesalerLaboratories.length - 1;
        }

        wholesalerLaboratories[found].laboratories.push(wholesalerLaboratory);
      });
      $scope.wholesalerLaboratories = wholesalerLaboratories;
      $scope.loading = false;
    });
  }
  /**
   * Open the product list filtered by laboratory ID.
   *
   * @param {number} laboratoryId - The laboratory ID
   */


  function filterProductsByLaboratory(laboratoryId) {
    $scope.productSearchConfig.must["laboratory.id"] = [laboratoryId];
    openTab($scope.tabs[1]);
  }
  /**
   * Return the products from wholesaler's catalog.
   *
   * @returns {Promise} The promise
   */


  function getWholesalerCatalogProducts() {
    $scope.loading = true;
    var prices = {};
    var wholesalerCatalogId = $stateParams.id ? $stateParams.id : $stateParams.wholesalerCatalogId;
    var postParams = {
      wholesalerId: $scope.currentAccess.entityId,
      id: wholesalerCatalogId,
      productIds: productIds
    };

    if ($scope.productSearchConfig.must["laboratory.id"]) {
      postParams.laboratoryIds = $scope.productSearchConfig.must["laboratory.id"];
    } // Get the order by


    var orderByField = $scope.query.order;
    var orderDirection = "ASC";

    if ($scope.query.order.charAt(0) === "-") {
      orderByField = $scope.query.order.slice(1);
      orderDirection = "DESC";
    }

    var getParams = {
      current_page: $scope.pagination.current_page,
      per_page: $scope.pagination.per_page,
      order: orderByField,
      orderDirection: orderDirection
    }; // Get the products filtered and paginated

    return wholesalerCatalogService.getFilteredCatalog(postParams, getParams).then(function (response) {
      wholesalerProductIds = []; // Get the pagination informations

      $scope.pagination = response.meta.pagination; // Foreach product, initialize the prices

      response.data.forEach(function (wholesalerProduct) {
        wholesalerProductIds.push(wholesalerProduct.product.id);
        prices = {}; // Set the wholesaler price list id as a key

        if (wholesalerProduct.prices.length > 0) {
          wholesalerProduct.prices.forEach(function (price) {
            prices[price.wholesalerPriceListId] = price;
          });
          wholesalerProduct.prices = prices;
        }
      });
      $scope.wholesalerProducts = response.data;
      $scope.loading = false;
    });
  }
  /**
   * Change discount percentage of all products displayed.
   *
   * @param {number} wholesalerPriceListId - The wholesaler price list ID
   * @param {string} value - "-", "+" or a number
   */


  function changeAllDiscountPercentage(wholesalerPriceListId, value) {
    var currentDiscountPercentage = 0;
    $scope.wholesalerProducts.forEach(function (wholesalerProduct) {
      // Get the current value and initialize it if not set
      if (!wholesalerProduct.prices[wholesalerPriceListId]) {
        wholesalerProduct.prices[wholesalerPriceListId] = {
          discountPercentage: 0,
          wholesalerPriceListId: wholesalerPriceListId
        };
      }

      currentDiscountPercentage = wholesalerProduct.prices[wholesalerPriceListId].discountPercentage; // Increase, decrease or replace the discount percentage

      if (value === "+") {
        currentDiscountPercentage = currentDiscountPercentage >= 100 ? 100 : currentDiscountPercentage + 1;
      } else if (value === "-") {
        currentDiscountPercentage = currentDiscountPercentage <= 0 ? 0 : currentDiscountPercentage - 1;
      } else if (value !== "") {
        currentDiscountPercentage = value;
      }

      wholesalerProduct.prices[wholesalerPriceListId].discountPercentage = currentDiscountPercentage;
      wholesalerProduct.prices[wholesalerPriceListId].modified = true;
    });
  }
  /**
   * Cancel the product price changes.
   */


  function cancelProductPriceChanges() {
    // eslint-disable-next-line no-alert
    if (confirm("Êtes-vous sûr(e) de vouloir annuler les modifications de prix ?")) {
      getWholesalerCatalogProducts();
    }
  }
  /**
   * Save the product prices.
   */


  function saveProductPrices() {
    var productPrices = []; // Check each product

    $scope.wholesalerProducts.forEach(function (wholesalerProduct) {
      // If at least one price has been defined
      if (wholesalerProduct.prices) {
        angular.forEach(wholesalerProduct.prices, function (price, priceListId) {
          // If the price has been modified
          if (price.modified) {
            if (!price.wholesalerPriceListId) {
              price.wholesalerPriceListId = priceListId;
            }

            productPrices.push({
              id: wholesalerProduct.id,
              wholesalerPriceListId: price.wholesalerPriceListId,
              discountPercentage: price.discountPercentage,
              edenredPoint: price.edenredPoint ? price.edenredPoint : 0
            });
          }
        });
      }
    }); // If no discount changed, no reason to save

    if (productPrices.length === 0) {
      notifyUserService.showSuccessToast("Aucune remise modifiée à enregistrer."); // eslint-disable-next-line no-alert
    } else if (confirm("Êtes-vous sûr(e) de vouloir modifier " + productPrices.length + " remise(s) ?")) {
      wholesalerCatalogService.saveMany({
        wholesalerId: $scope.currentAccess.entityId,
        id: $stateParams.id ? $stateParams.id : $stateParams.wholesalerCatalogId,
        productPrices: productPrices
      }).then(function (response) {
        var message = response.length > 1 ? response.length + " remises ont été mises à jour." : response.length + " remise a été mise à jour."; // Reset all prices to not modified

        $scope.wholesalerProducts.forEach(function (wholesalerProduct) {
          angular.forEach(wholesalerProduct.prices, function (price) {
            price.modified = false;
          });
        });
        notifyUserService.showSuccessToast(message);
      });
    }
  } // Adding products to the wholesaler product


  $rootScope.$on("searchForWholesalerProducts", function (event, data) {
    var queryProduct = {};
    productIds = [];

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    } // Additional filters


    if (selectedFilters.vat) {
      queryProduct.tva = selectedFilters.vat;
    }

    if (selectedFilters.refund) {
      queryProduct.refund = selectedFilters.refund;
    }

    if (selectedFilters.delivrance) {
      queryProduct.delivrance = selectedFilters.delivrance;
    }

    if (selectedFilters.generique) {
      queryProduct.generique = selectedFilters.generique;
    }

    queryProduct.wholesaler = $scope.currentAccess.entityId; // getProducts with query results

    return wholesalerCatalogService.searchForWholesalerProducts(queryProduct).then(function (foundProducts) {
      productIds = foundProducts.productIds;
      return getWholesalerCatalogProducts();
    });
  });
  /**
   * On product import validation, get all products Ids and name.
   */

  $scope.$on("addProductToWholesalerProductFromImport", function (event, products) {
    var queryProduct = {
      product: []
    };
    angular.forEach(products, function (product) {
      queryProduct.product.push(product.product_id);
    }); // get Product by type with query results

    getProductInformation(queryProduct, products);
  });
  /**
   * Get product information from Elastic Search.
   *
   * @param {object} queryProduct - Query for Elastic Search
   * @param {Array} products - Array from imported file
   *
   * @returns {Promise} The promise
   */

  function getProductInformation(queryProduct, products) {
    var foundProducts = [];
    var product;
    return wholesalerCatalogService.searchForWholesalerProducts(queryProduct).then(function (elasticProducts) {
      // Check if the product is found, and add it
      angular.forEach(elasticProducts, function (productItem) {
        product = products.find(function (product) {
          return product.product_id === productItem.id;
        });

        if (product) {
          foundProducts.push(product);
        }
      });
      addProductsToCatalog(foundProducts);
    });
  }
  /**
   * Add products to wholesaler catalog.
   *
   * @param {Array} products - The products
   *
   * @returns {Promise} The promise
   */


  function addProductsToCatalog(products) {
    var postParams = {
      id: $scope.currentAccess.entityId,
      products: products
    }; // Get the products filtered and paginated

    return wholesalerCatalogService.addProducts(postParams).then(function (response) {
      if (response.status && response.status === 500) {
        notifyUserService.showDangerToast("Erreur lors de l'import, merci de vérifier le format des données.");
      } else {
        getWholesalerCatalogProducts();
      }
    });
  }
  /**
   * Show the sales.
   *
   * @param {number|null} laboratoryId - The laboratory ID
   * @param {number|null} productId - The product ID
   */


  function showWholesalerSales(laboratoryId, productId) {
    // The start date must be one year ago
    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 365);
    var year = startDate.getFullYear();
    var month = (startDate.getMonth() + 1).toString();
    var formatedMonth = month.length === 1 ? "0" + month : month;
    var day = startDate.getDate().toString();
    var formatedDay = day.length === 1 ? "0" + day : day;
    var parameters = {
      id: $scope.currentAccess.entityId,
      startDate: year + "-" + formatedMonth + "-" + formatedDay
    }; // If the laboratory is defined

    if (laboratoryId) {
      parameters.laboratoryId = laboratoryId;
    } // If the product is defined


    if (productId) {
      parameters.productId = productId;
    }

    wholesalerService.getFilteredSales(parameters).then(function (response) {
      $mdDialog.show({
        controller: showSalesController,
        templateUrl: wholesalerCatalogConfig.baseDir + "includes/modal/sales/sales.html",
        parent: angular.element(document.body),
        targetEvent: event,
        clickOutsideToClose: true,
        locals: {
          sales: response
        }
      });
    });
  }
  /**
   * Show sales controller.
   *
   * @param {object} $scope - The scope
   * @param {object} sales - The sales
   * @param {object} $mdDialog - The md dialog directive
   */


  function showSalesController($scope, sales, $mdDialog) {
    $scope.sales = sales;
    $scope.cancel = cancel;
    /**
     * Cancel the dialog box.
     */

    function cancel() {
      $mdDialog.cancel();
    }
  }
  /**
   * Get the filters to apply on search
   *
   * @returns {object} The selected filters
   */


  function getFilters() {
    selectedFilters = {}; // VAT filter

    var vatFilter = [];
    angular.forEach($scope.rawValues.tva, function (tva, index) {
      if ($scope.filter.tva[index]) {
        vatFilter.push(tva.value);
      }
    });

    if (vatFilter.length > 0) {
      selectedFilters.vat = vatFilter;
    } // Refund filter


    var refundFilter = [];
    angular.forEach($scope.rawValues.refund, function (refund, index) {
      if ($scope.filter.refund[index]) {
        refundFilter.push(refund.value);
      }
    });

    if (refundFilter.length > 0) {
      selectedFilters.refund = refundFilter;
    } // Delivrance filter


    var delivranceFilter = [];
    angular.forEach($scope.rawValues.delivrance, function (delivrance, index) {
      if ($scope.filter.delivrance[index]) {
        delivranceFilter.push(delivrance.value);
      }
    });

    if (delivranceFilter.length > 0) {
      selectedFilters.delivrance = delivranceFilter;
    } // Generique filter


    var generiqueFilter = [];
    angular.forEach($scope.rawValues.generiques, function (generique, index) {
      if ($scope.filter.generique[index]) {
        generiqueFilter.push(generique.value);
      }
    });

    if (generiqueFilter.length > 0) {
      selectedFilters.generique = generiqueFilter;
    }

    return selectedFilters;
  }
  /**
   * Get product stocks of the wholesaler.
   */


  function getProductStocks() {
    $scope.loading = true; // If the list of product has been already loaded

    if ($scope.wholesalerProducts) {
      getProductsNeeds();
      $scope.loading = false;
    } else {
      getWholesalerCatalogProducts().then(function () {
        getProductsNeeds();
        $scope.loading = false;
      });
    }
  }
  /**
   * Get product needs.
   *
   * @returns {Promise} The promise
   */


  function getProductsNeeds() {
    var postParams = {
      id: $scope.currentAccess.entityId,
      productIds: wholesalerProductIds
    }; // Get the products filtered and paginated

    var foundWholesalerProduct;
    return wholesalerCatalogService.getProductsNeeds(postParams).then(function (response) {
      response.forEach(function (product) {
        // Search for the product inside the wholesaler products list
        foundWholesalerProduct = $scope.wholesalerProducts.find(function (wholesalerProduct) {
          return wholesalerProduct.productId === product.product_id;
        }); // If the product has been found

        if (foundWholesalerProduct) {
          foundWholesalerProduct.neededQuantity = product.need;
        }
      });
    });
  }
  /**
   * Return the price lists of the wholesaler.
   *
   * @returns {Promise} The promise
   */


  function getWholesalerPriceLists() {
    $scope.loading = true;
    $scope.productListColumnCount = 6;
    return wholesalerCatalogService.getPriceLists({
      wholesalerId: $scope.currentAccess.entityId,
      id: $stateParams.id ? $stateParams.id : $stateParams.wholesalerCatalogId
    }).then(function (priceLists) {
      $scope.priceLists = priceLists;
      priceLists.forEach(function (priceList) {
        if (priceList.isAvailableEdenred === 1) {
          $scope.productListColumnCount += 3;
        } else {
          $scope.productListColumnCount += 2;
        }

        if (priceList.color) {
          priceList.cssStyle = {
            borderLeftColor: priceList.color,
            borderRightColor: priceList.color
          };
          priceList.cssStyleInput = {
            backgroundColor: priceList.color,
            opacity: 0.5
          };
        } else {
          priceList.cssStyle = null;
          priceList.cssStyleInput = null;
        }
      });
      $scope.loading = false;
    });
  }
  /**
   * Create a new price list - go to form.
   */


  function createPriceList() {
    var wholesalerCatalogId = $stateParams.id ? $stateParams.id : $stateParams.wholesalerCatalogId;
    $state.go("app.wholesaler.catalogs.priceList.new", {
      wholesalerCatalogId: wholesalerCatalogId
    });
  }
  /**
   * Update a price list - go to form.
   *
   * @param {number} priceListId - The price list ID
   */


  function editPriceList(priceListId) {
    var wholesalerCatalogId = $stateParams.id ? $stateParams.id : $stateParams.wholesalerCatalogId;
    $state.go("app.wholesaler.catalogs.priceList.edit", {
      wholesalerCatalogId: wholesalerCatalogId,
      id: priceListId
    });
  }
}

angular.module("wholesaler.catalog").controller("wholesalerCatalogProducts", WholesalerCatalogProducts);