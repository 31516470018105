"use strict";
/**
 * The User Laboratory Branding service.
 *
 * @param {object} apiClient - API client.
 * @class
 */

UserLaboratoryBrandingService.$inject = ["apiClient"];

function UserLaboratoryBrandingService(apiClient) {
  return {
    /**
     * authorize.
     *
     * @param {object} data -
     * @returns {Promise}
     */
    authorize: function authorize(data) {
      var options = {
        includes: ["user"]
      };
      return apiClient.post("/laboratories/".concat(data.laboratoryId, "/branding/authorize"), data, options).then(function (response) {
        return response.data;
      });
    },

    /**
     * changeReassortConfiguration.
     *
     * @param {object} data -
     * @returns {Promise}
     */
    changeReassortConfiguration: function changeReassortConfiguration(data) {
      return apiClient.post("/healthcenters/".concat(data.healthcenterId, "/reassortConfig/").concat(data.laboratoryId), data).then(function (response) {
        return response.data;
      });
    },

    /**
     * readReassortConfiguration.
     *
     * @param {object} data -
     * @returns {Promise}
     */
    readReassortConfiguration: function readReassortConfiguration(data) {
      return apiClient.get("/healthcenters/".concat(data.healthcenterId, "/reassortConfig/").concat(data.laboratoryId), data).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.laboratory").service("userLaboratoryBrandingService", UserLaboratoryBrandingService);