"use strict";
/**
 * Apodis blockquotes directive.
 *
 * @returns {object}
 */

function ApodisBlockquotes() {
  return {
    restrict: "E",
    scope: {
      config: "=config"
    },
    templateUrl: "app/main/directives/apodis-blockquotes/apodisBlockquotes.html",
    transclude: true,
    controller: function controller() {// do nothing
    }
  };
}

angular.module("app.directives").directive("apodisBlockquotes", ApodisBlockquotes);