"use strict";
/**
 * Import service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

ImportService.$inject = ["apiClient"];

function ImportService(apiClient) {
  return {
    /**
     * Get data.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getData: function getData(data) {
      return apiClient.post("/import/getData", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Save.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    save: function save(data) {
      return apiClient.post("/import/save", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * getDataByChunk
     *
     * @param {object} params -
     *
     * @returns {Promise}
     */
    getDataByChunk: function getDataByChunk(params) {
      return apiClient.post("/import/getChunkedData", params, {
        wrappers: {
          response: null
        }
      });
    },

    /**
     * getChunksCount
     *
     * @param {object} params -
     *
     * @returns {Promise}
     */
    getChunksCount: function getChunksCount(params) {
      return apiClient.get("/import/getChunksCount", {
        parameters: [{
          name: "fullPath",
          value: params.fullPath
        }, {
          name: "chunkSize",
          value: params.chunkSize
        }]
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.directives").service("importService", ImportService);