"use strict";
/**
 * Register controller.
 *
 * @param {object} $filter - $filter.
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 * @param {object} NotificationService - Notification service.
 * @param {object} $window - $window.
 * @param {object} $compile - $compile.
 * @param {object} $timeout - $timeout.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} $mdToast - $mdToast.
 * @param {object} bricksService - Bricks service.
 * @param {object} gmap - Google map.
 * @param {object} authProfileService - Auth profile service.
 * @param {object} authHcAccessService - Auth healthcenter access service.
 * @param {object} authSponsorshipsService - Auth sponsorships service.
 * @param {object} authLgoService - Auth LGO service.
 * @param {object} authService - Authentication service.
 */

RegisterController.$inject = ["$filter", "$state", "$scope", "NotificationService", "$window", "$compile", "$timeout", "notifyUserService", "$mdToast", "bricksService", "gmap", "authProfileService", "authHcAccessService", "authSponsorshipsService", "authLgoService", "authService"];

function RegisterController($filter, $state, $scope, NotificationService, $window, $compile, $timeout, notifyUserService, $mdToast, bricksService, gmap, authProfileService, authHcAccessService, authSponsorshipsService, authLgoService, authService) {
  var vm = this;
  var groupDefault = [{
    id: "none",
    name: "Indépendant"
  }, {
    id: 0,
    name: "Autre groupement"
  }];
  vm.domainName = window.sessionStorage.getItem("domainName"); // Destroy all informations about a useless user

  authService.destroyCredentials(); // Methods

  vm.validateType = validateType;
  vm.validatePharmacist = validatePharmacist;
  vm.validateFinal = validateFinal;
  vm.selectHcenter = selectHcenter;
  vm.selectHcenterEmployee = selectHcenterEmployee;
  vm.removeHcenter = removeHcenter;
  vm.removeHcenterEmployee = removeHcenterEmployee;
  vm.closeList = closeList;
  vm.returnType = returnType;
  vm.returnHome = returnHome;
  vm.switchSponsorCode = switchSponsorCode;
  vm.sponsorCodeValidity = sponsorCodeValidity;
  vm.emailAvailability = userEmailIsAvailable;
  vm.pwdConfirm = pwdConfirm;
  vm.searchPharmas = searchPharmas;
  vm.openPdf = openPdf;
  vm.goToAuth = goToAuth;
  $scope.validateLgo = validateLgo;
  loader();
  /**
   * Loader.
   */

  function loader() {
    initTab();
    loadGroups();
    initGmap();
  }
  /**
   * Go to auth.
   */


  function goToAuth() {
    $state.go("app.pages_auth_login");
  }
  /**
   * Show custom toast.
   */


  function showCustomToast() {
    vm.isDlgOpen = true;
    $mdToast.show({
      hideDelay: 0,
      position: "top left",
      templateUrl: "app/main/auth/views/register/help.html",
      locals: {
        url: vm.helpUrl
      },
      controller: ["$scope", "url", function controller($scope, url) {
        $scope.openGuide = function () {
          $window.open(url);
        };

        $scope.closeToast = function () {
          $mdToast.hide();
        };
      }]
    });
  }
  /**
   * Close toast.
   */


  function closeToast() {
    if (!vm.isDlgOpen) {
      return;
    }

    $mdToast.hide().then(function () {
      vm.isDlgOpen = false;
      vm.helpUrl = null;
    });
  }
  /**
   * Return type.
   */


  function returnType() {
    vm.hcenter.list = false;
    vm["return"] = true;
    vm.step.type.complete = false;
    vm.step.type.active = true;
    vm.step.current = "type";
    vm.sponsor.codeNotOk = false;
    vm.userEmail.isUnavailable = true;
    vm.pharmas = false;
    vm.step.pharmacist.active = false;
    vm.step.employee.active = false;
    closeToast();
    initTab();
  }
  /**
   * Return home.
   */


  function returnHome() {
    vm.hcenter.list = false;
    vm["return"] = true;
    vm.step.pharmacist.complete = false;
    vm.step.pharmacist.active = true;
    vm.step.current = "pharmacist";
    vm.sponsor.codeNotOk = false;
    vm.userEmail.isUnavailable = true;
    vm.pharmas = false;
    vm.step.map.active = false;
    $timeout(function () {
      deleteMarkers();
      initTab(true);
    }, 500);
  }
  /**
   * Close list.
   */


  function closeList() {
    vm.hcenter.list = false;
    vm.user.postal = null;
    vm.step.map.active = false;
    $timeout(function () {
      deleteMarkers();
      initGmap();
    }, 500);
  }
  /**
   * Validate type.
   *
   * @param {string} type - Type.
   */


  function validateType(type) {
    vm.step.type.complete = true;
    vm.step.type.active = false;
    vm.step.current = type;

    switch (type) {
      case "pharmacist":
        vm.step.pharmacist.active = true;
        vm.helpUrl = "../assets/files/install-apodis-pharmacist.pdf";
        break;

      case "employee":
        vm.step.employee.active = true;
        vm.helpUrl = "../assets/files/Guide-employe.pdf";
        showCustomToast();
        break;
    }
  }
  /**
   * Validate LGO.
   *
   * @param {number} lgo -
   */


  function validateLgo(lgo) {
    if (lgo !== 2) {
      showCustomToast();
    }
  }
  /**
   * Validate pharmacist.
   */


  function validatePharmacist() {
    vm.step.pharmacist.complete = true;
    vm.step.pharmacist.active = false;
    vm.step.map.active = true;
    vm.step.current = "map";
    var query = {
      postalCode: [vm.user.postal]
    };
    launchSearch(query);
  }
  /**
   * Format postal code.
   *
   * @param {number} postalCode - Postal code
   * @returns {string}
   */


  function FormatPostalCode(postalCode) {
    return postalCode.toString().padStart(5, "0");
  }
  /**
   * Open PDF.
   */


  function openPdf() {
    if (vm.step.employee.active) {
      $window.open("../assets/files/cgu_employee.pdf");
    } else {
      $window.open("../assets/files/cgu_pharmacist.pdf");
    }
  }
  /**
   * Handle registration with smartRX Alliadis LGO.
   *
   * @returns {boolean}
   */


  function handleSmartRxRegistration() {
    $scope.smartRxRegistration = true;
    return true;
  }
  /**
   * Validate final.
   */


  function validateFinal() {
    vm.disabledSubmit = true;
    vm.user.email.replace(" ", "");
    authProfileService.register(vm.user).then(function (response) {
      vm.freshUser = response;
      return response;
    }, function () {
      notifyUserService.showSimpleToast("Nous avons rencontré un problème à la création de votre compte", 2000, "top left");
      returnHome();
      vm.disabledSubmit = false;
    }).then(function () {
      vm.step.map.complete = true;
      vm.step.map.active = false;
      vm.showLogo = false;
      vm.finalEasing = true;
      vm.finalPhase = true;

      if (vm.user.type === "pharmacist") {
        // Authenticate
        var newUser = {
          name: vm.user.email,
          password: vm.user.pwd
        };
        authService.login(newUser).then(function (msg) {
          if (msg.data.user.currentAccess.entity.lgoInfos.id === 2) {
            return handleSmartRxRegistration();
          }

          var payload = JSON.parse(window.sessionStorage.getItem("payload"));
          payload.options = msg.data.options;
          window.sessionStorage.setItem("payload", JSON.stringify(payload));
          return bricksService.getBricks().then(function (bricks) {
            NotificationService.load();
            var options = angular.fromJson(window.sessionStorage.getItem("payload")).options;
            $scope.hasBricksMenus = options.indexOf("bricks-menus") > -1;

            if (!$scope.hasBricksMenus && bricks.length) {
              bricksService.setCurrentBrick(bricks[0]);
              $state.go("app.dashboard", {
                brickSlug: bricks[0].slug.replace(".", "_")
              });
            } else {
              $state.go("app.home");
            }

            return msg;
          });
        });
      }

      vm.disabledSubmit = false;
    });
  }
  /**
   * Select healthcenter.
   *
   * @param {number} key - Key.
   */


  function selectHcenter(key) {
    removeHcenter();
    vm.hcenter.availabilityInProgress = true;
    closeInfoWindow();
    var hc = vm.pharmas.hits.hits[key]._source;
    authHcAccessService.getOwnedHcById(hc).then(function (response) {
      if (response.owned) {
        vm.hcenter.isOwned = true;
        vm.hcenter.showMessage = true;
        showMessage();
      } else {
        vm.user.access = {
          accessPointType: 2,
          entityId: hc.id,
          rankId: 1,
          operatorCode: null,
          active: 1,
          name: hc.name,
          address: hc.geo.city.address1 + " " + hc.geo.city.postal_code + " " + hc.geo.city.name
        };
        vm.hcenter.list = false;
        closeInfoWindow();
        vm.hcenter.isOwned = false;
      }

      vm.hcenter.availabilityInProgress = false;
    });
  }
  /**
   * Show message.
   */


  function showMessage() {
    notifyUserService.showSimpleToast(vm.hcenter.message, 3000, "top left");
  }
  /**
   * Remove healthcenter.
   */


  function removeHcenter() {
    vm.user.access = null;
    vm.hcenter.showMessage = false;
    vm.hcenter.list = true;
  }
  /**
   * Select healthcenter employee.
   *
   * @param {number} key - Key.
   */


  function selectHcenterEmployee(key) {
    if (_.isNull(vm.user.access)) {
      vm.user.access = {};
    }

    var hc = vm.pharmas.hits.hits[key]._source;
    var index = "access_" + FormatPostalCode(vm.user.postal) + "_" + key;
    vm.user.access[index] = {
      accessPointType: 2,
      entityId: hc.id,
      rankId: null,
      operatorCode: null,
      active: null,
      name: hc.name,
      address: hc.geo.city.address1 + " " + hc.geo.city.postal_code + " " + hc.geo.city.name
    };
    vm.step.employee.complete = true;
    hc.added = true;
    notifyUserService.showSimpleToast(vm.user.access[index].name + " (" + vm.user.access[index].address + ") a été ajoutée à vos lieux de travail", 2000, "top left");
  }
  /**
   * Remove healthcenter employee.
   *
   * @param {string} access - Access.
   */


  function removeHcenterEmployee(access) {
    closeInfoWindow();
    var info = angular.copy(vm.user.access[access].name) + " (" + angular.copy(vm.user.access[access].address) + ")";
    delete vm.user.access[access];
    var key = parseInt(access.split("_")[2]);
    vm.pharmas.hits.hits[key]._source.added = false;

    if (vm.user.access.length < 1) {
      vm.user.access = null;
      vm.step.employee.complete = false;
    }

    notifyUserService.showSimpleToast(info + " a été retirée de vos lieux de travail", 2000, "top left");
  }
  /**
   * Search healthcenters.
   */


  function searchPharmas() {
    if (vm.user.postal && (vm.user.postal.toString().length === 4 || vm.user.postal.toString().length === 5)) {
      vm.user.postalProgress = true;
      vm.step.map.active = true;
      var query = {
        postalCode: [vm.user.postal]
      };
      launchSearch(query);
    }
  }
  /**
   * Launch search.
   *
   * @param {object} obj - Object.
   */


  function launchSearch(obj) {
    var healthcenter = obj ? obj : {};
    authHcAccessService.searchHc(healthcenter).then(function (response) {
      vm.user.postalProgress = false;
      vm.pharmas = response;
      vm.hcenter.list = true;
      updateMarkers();
    }, function () {
      vm.block = false;
      notifyUserService.showSimpleToast("Nous n'avons trouvé aucune pharmacie associée au nom de " + vm.user.name + " " + vm.user.firstName, 5000, "top left");
    });
  }
  /**
   * Process markers.
   *
   * @returns {boolean}
   */


  function processMarkers() {
    var markers = [];
    vm.bounds = new google.maps.LatLngBounds();

    if (!vm.pharmas) {
      vm.markers = markers;
      return false;
    }

    angular.forEach(vm.pharmas.hits.hits, function (hc, key) {
      if (hc._source.geo.location) {
        hc._source.added = hcenterAlreadyAdded(key);
        var latLng = new google.maps.LatLng(hc._source.geo.location.lat, hc._source.geo.location.lon);
        var marker = new google.maps.Marker({
          position: latLng
        });
        vm.infowindowOpened = false;
        var infowindow = null;
        marker.addListener("click", function () {
          // openWindow(key);
          var hc = vm.pharmas.hits.hits[key]._source;
          closeInfoWindow();
          infowindow = new google.maps.InfoWindow();
          var content = "<h2>" + hc.name + "</h2>" + "<p>" + hc.geo.city.address1 + "</p>" + "<p>" + hc.geo.city.postal_code + " " + hc.geo.city.name + "</p>" + "<div layout=\"row\" layout-align=\"center center\" flex>" + getTemplate(key) + "<div class=\"requestState\" ng-if=\"vm.hcenter.availabilityInProgress\">" + "<div layout=\"row\" layout-sm=\"column\" layout-align=\"center center\">" + "<md-progress-circular class=\"md-hue-3\" md-mode=\"indeterminate\" md-diameter=\"70\" aria-valuemax=\"100\"></md-progress-circular>" + "</div>" + "</div>" + "</div>";
          var compiled = $compile(content)($scope);
          $scope.$apply();
          var div = document.createElement("div");
          div.setAttribute("class", "infowindow");
          angular.forEach(compiled, function (value) {
            div.appendChild(value);
          });
          infowindow.setContent(div);
          infowindow.open(vm.map, marker);
          vm.infowindowOpened = infowindow;
        });
        marker.setMap(vm.map); // eslint-disable-next-line no-invalid-this

        this.push(marker);
        vm.bounds.extend(latLng);
      }
    }, markers);
    vm.markers = markers;
    vm.map.fitBounds(vm.bounds);
    return true;
  }
  /**
   * Healthcenter already added.
   *
   * @param {number} key - Key.
   * @returns {boolean}
   */


  function hcenterAlreadyAdded(key) {
    if (_.isNull(vm.user.access)) {
      return false;
    }

    return _.has(vm.user.access, "access_" + FormatPostalCode(vm.user.postal) + "_" + key);
  }
  /**
   * Close info window.
   */


  function closeInfoWindow() {
    if (vm.infowindowOpened) {
      vm.infowindowOpened.close();
    }
  }
  /**
   * Get template.
   *
   * @param {number} key - Key.
   * @returns {string}
   */


  function getTemplate(key) {
    switch (vm.user.type) {
      case "pharmacist":
        return "<md-button ng-if=\"!vm.pharmas.hits.hits[" + key + "]._source.added\" class=\"md-raised md-accent md-teal-theme submit-button\" ng-click=\"vm.selectHcenter(" + key + ")\">" + "<md-icon md-font-icon=\"icon-check\" class=\"icon\"></md-icon>Je suis le titulaire" + "</md-button>";

      case "employee":
        return "<md-button ng-if=\"!vm.pharmas.hits.hits[" + key + "]._source.added\" class=\"md-raised md-accent md-teal-theme submit-button\" ng-click=\"vm.selectHcenterEmployee(" + key + ")\">" + "<md-icon md-font-icon=\"icon-check\" class=\"icon\"></md-icon>J'y travaille" + "</md-button>" + "<md-button ng-if=\"vm.pharmas.hits.hits[" + key + "]._source.added\" ng-init=\"access = 'access_" + FormatPostalCode(vm.user.postal) + "_" + key + "'\" class=\"md-raised md-accent-bg md-teal-theme submit-button\" ng-click=\"vm.removeHcenterEmployee(access)\">" + "<md-icon md-font-icon=\"icon-close\" class=\"icon\"></md-icon>Annuler" + "</md-button>";
    }

    return "";
  }
  /**
   * Set map on all.
   *
   * @param {object} map - Map.
   */


  function setMapOnAll(map) {
    if (vm.markers.length > 0) {
      for (var index = 0, length = vm.markers.length; index < length; index++) {
        if (map === null) {
          deleteMarkers();
        } else {
          vm.map.clusterer.addMarker(vm.markers[index]);
        }
      }
    } else {
      processMarkers();
    }
  }
  /**
   * Delete markers.
   */


  function deleteMarkers() {
    if (vm.markers.length > 0) {
      vm.markers = [];

      if (vm.map.clusterer) {
        vm.map.clusterer.clearMarkers();
      }
    }
  }
  /**
   * Update markers.
   */


  function updateMarkers() {
    setMapOnAll(null);

    if (processMarkers()) {
      if (typeof vm.map.clusterer !== "undefined") {
        // eslint-disable-next-line no-undef
        vm.map.clusterer = new MarkerClusterer(vm.map.instance, vm.markers, vm.hcMap);
      }
    }
  }
  /**
   * Check sponsor code validity.
   *
   * @param {string} sponsorCode - The sponsor code to check.
   */


  function sponsorCodeValidity(sponsorCode) {
    vm.sponsor.codeValidityInProgress = true;
    authSponsorshipsService.getByCode({
      code: sponsorCode
    }).then(function (response) {
      if (response.success) {
        vm.sponsor.codeNotOk = false;
        vm.sponsor.codeValidityInProgress = false;
        vm.sponsor.info = response.sponsor;
        vm.sponsor.message = response.message;
      } else {
        vm.sponsor.codeNotOk = true;
      }
    })["catch"](function (error) {
      vm.sponsor.message = "Ce code parrainage n'existe pas.";
      vm.sponsor.codeValidityInProgress = false;
      vm.sponsor.codeNotOk = true;
      return error;
    });
  }
  /**
   * User email is available.
   *
   * @param {string} email - Email.
   */


  function userEmailIsAvailable(email) {
    vm.userEmail.availabilityInProgress = true;

    if (email && email.length >= 5) {
      vm.userEmail.hasSpace = vm.user.email.indexOf(" ") > -1;
      authProfileService.availableMail(email).then(function (response) {
        if (response.success) {
          vm.userEmail.isUnavailable = false;
        } else {
          vm.userEmail.isUnavailable = true;
        }

        vm.userEmail.message = response.message;
        vm.userEmail.availabilityInProgress = false;
      });
    } else {
      vm.userEmail.isUnavailable = true;
    }
  }
  /**
   * Get LGO list.
   */


  function getLgoList() {
    authLgoService.get().then(function (response) {
      vm.step.lgo.values = response.lgo;
    });
  }
  /**
   * Switch sponsor code.
   *
   * @param {boolean} state - State.
   */


  function switchSponsorCode(state) {
    if (state) {
      vm.sponsor = {
        info: null,
        message: null,
        codeValidityInProgress: false
      };
      vm.user.parrainage = null;
    }

    vm.sponsor.codeNotOk = !state;
  }
  /**
   * Password confirm.
   *
   * @param {string} pwd - Password.
   */


  function pwdConfirm(pwd) {
    vm.user.pwdProgress = true;
    vm.user.pwdNotOk = true;

    if (pwd && pwd.length >= vm.user.pwd.length) {
      vm.user.pwdProgress = false;
      vm.user.pwdNotOk = true;

      if (pwd === vm.user.pwd) {
        vm.user.pwdNotOk = false;
        vm.user.pwdProgress = false;
      }
    }
  }
  /**
   * Initialize Google Maps.
   */


  function initGmap() {
    GmapPromise.then(function () {
      vm.markers = [];
      vm.clusterStyles = [{
        textColor: "black",
        url: "https://raw.githubusercontent.com/googlemaps/js-marker-clusterer/gh-pages/images/m1.png",
        height: 55,
        width: 55
      }, {
        textColor: "black",
        url: "https://raw.githubusercontent.com/googlemaps/js-marker-clusterer/gh-pages/images/m2.png",
        height: 55,
        width: 55
      }, {
        textColor: "black",
        url: "https://raw.githubusercontent.com/googlemaps/js-marker-clusterer/gh-pages/images/m3.png",
        height: 65,
        width: 65
      }, {
        textColor: "black",
        url: "https://raw.githubusercontent.com/googlemaps/js-marker-clusterer/gh-pages/images/m4.png",
        height: 55,
        width: 55
      }, {
        textColor: "black",
        url: "https://raw.githubusercontent.com/googlemaps/js-marker-clusterer/gh-pages/images/m5.png",
        height: 55,
        width: 55
      }];
      var myLatLng = {
        lat: 47.6468754,
        lng: 2.3245131
      };
      var mapOptions = {
        center: new google.maps.LatLng(myLatLng),
        zoom: 6,
        minZoom: 6,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        disableDefaultUI: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_CENTER
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        scaleControl: true,
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        addressControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        fullscreenControl: false,
        styles: gmap
      };
      vm.map = new google.maps.Map(document.getElementById("map_register"), mapOptions);

      if (vm.step.map.active) {
        launchSearch(vm.query);
      }
    });
  }
  /**
   * Initialiaze tab.
   *
   * @param {boolean} reload - Reload.
   */


  function initTab(reload) {
    getLgoList();
    vm.isDlgOpen = false;
    vm.helpUrl = null;
    vm.robotLoading = false;
    vm.block = false;
    vm.returnedHome = false;
    vm.step = {
      current: "type",
      type: {
        active: true,
        complete: false
      },
      employee: {
        active: false,
        complete: false
      },
      pharmacist: {
        active: false,
        complete: false
      },
      map: {
        active: false,
        complete: false,
        hcenter: null
      },
      lgo: {
        active: false,
        complete: false,
        selected: null
      }
    };
    vm.sponsorship = false;
    vm.sponsor = {
      info: null,
      message: null,
      codeNotOk: false,
      codeValidityInProgress: false
    };
    vm.userEmail = {
      message: "Le champ E-mail doit être une adresse valide.",
      isUnavailable: true,
      hasSpace: false,
      availabilityInProgress: false
    };
    vm.getFinalScreenAnim = {
      one: false,
      two: false,
      three: false
    };
    vm.hcenter = {
      message: "Un pharmacien titulaire est déjà enregistré sur cette pharmacie.",
      isOwned: true,
      availabilityInProgress: false,
      showMessage: false,
      list: false
    };
    vm.finalEasing = false;
    vm.showLogo = true;

    if (reload) {
      vm.step.type = {
        active: false,
        complete: true
      };
      vm.step.pharmacist = {
        active: true,
        complete: false
      };
      vm.user.postal = null;
      vm.user.coords = null;
      vm.user.pwd = null;
      vm.user.pwdConfirm = null;
      vm.user.email = null;
      vm.user.access = null;
    } else {
      vm.user = {
        type: null,
        gender: null,
        firstName: null,
        name: null,
        email: null,
        pwd: null,
        pwdConfirm: null,
        pwdNotOk: true,
        pwdProgress: false,
        postalProgress: false,
        postal: null,
        coords: null,
        access: null,
        lgo: null,
        parrainage: null
      };
    } // Pre-select groupment if white mark


    switch (vm.domainName) {
      case "dlsante":
        vm.user.groupmentId = 235;
        break;

      case "unipharm":
        vm.user.groupmentId = 182;
        break;

      case "reseauPnp":
        vm.user.groupmentId = 226;
        break;

      case "pdaPharma":
        vm.user.groupmentId = 231;
        break;

      case "simplyPharma":
        vm.user.groupmentId = 230;
        break;

      default:
        vm.user.groupmentId = null;
    }

    vm["return"] = false;
    vm.finalPhase = false;
    initGmap();
  }
  /**
   * Load groupings.
   */


  function loadGroups() {
    authHcAccessService.searchGroupsHc().then(function (response) {
      vm.groupmentList = groupDefault.concat($filter("orderBy")(response.groups, "name"));
    });
  }
}

angular.module("app.auth").controller("RegisterController", RegisterController);