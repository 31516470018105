"use strict";
/**
 * Media plan controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $rootScope - The angular $rootScope object.
 * @param {object} $state - The angular $state object.
 * @param {object} bricksService - Bricks service.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} eventsService - The event service.
 * @param {object} eventFactory - The event factory.
 * @param {object} environmentService - The environment service.
 * @param {string} apiurl - The api url.
 * @param {object} $mdDialog - The $mdDialog object.
 */

EventModalController.$inject = ["$scope", "event", "branding", "$mdDialog"];
MediaPlanController.$inject = ["$scope", "$rootScope", "$state", "bricksService", "laboratoriesService", "eventsService", "eventFactory", "environmentService", "apiurl", "$mdDialog"];

function MediaPlanController($scope, $rootScope, $state, bricksService, laboratoriesService, eventsService, eventFactory, environmentService, apiurl, $mdDialog) {
  $scope.ctrl = {};
  $scope.ctrl.typesSelected = [];
  $scope.ctrl.allChecked = false;
  $scope.eventTypeName = eventFactory.eventTypeName;
  $scope.eventListingOptions = eventFactory.eventListingOptions;
  $scope.query = eventFactory.query;
  $scope.goBack = goBack;
  $scope.goTo = goTo;
  $scope.goToEvent = goToEvent;
  $scope.goToState = goToState;
  $scope.goToBrandingState = goToBrandingState;
  $scope.filterTheEvents = filterTheEvents;
  $scope.isFiltered = isFiltered;
  $scope.noEvent = noEvent;
  $scope.showEvent = showEvent;
  loader();
  /**
   * The loader function
   */

  function loader() {
    $scope.loading = true;
    $scope.today = new Date();
    environmentService.getEnvironment().then(function (env) {
      $scope.env = env;
      var typesSelectedName = eventFactory.ctrl.typesSelectedName;
      $scope.ctrl.typesSelectedName = eventFactory.ctrl.typesSelectedNameHealthcenter.concat(typesSelectedName);
      $scope.eventsFilters = $scope.ctrl.typesSelectedName;
      getEvents();
    });
  }
  /**
   * Retrieve the events
   *
   * @returns {Promise}
   */


  function getEvents() {
    $scope.loading = true;
    $scope.eventListingOptions.loading = true;
    $scope.events = [];
    $scope.ctrl.typesSelectedName.forEach(function (type) {
      type.checked = true;
      return type;
    });
    var types = $scope.ctrl.typesSelectedName.map(function (type) {
      return type.value;
    });
    var params = {
      from: moment().format("YYYY-MM-DD"),
      to: moment().add("year", 1).format("YYYY-MM-DD"),
      type: types.join(",")
    };
    return eventsService.getEventsForHealthcenterByLaboratory(params, $scope.branding.laboratory.id).then(function (results) {
      $scope.eventsSaved = results;
      manageEventsForList();
      $scope.loading = false;
    });
  }
  /**
   * Filter the events.
   *
   * @param {object} filter - The filter.
   */


  function filterTheEvents(filter) {
    filter.checked = !filter.checked;
  }
  /**
   * Check if the event is filtered.
   *
   * @param {object} event - The event.
   *
   * @returns {boolean}
   */


  function isFiltered(event) {
    return $scope.eventsFilters.find(function (filter) {
      return filter.value === event.type;
    }).checked;
  }
  /**
   * Check if there is an event in the month.
   *
   * @param {object} events - The event.
   *
   * @returns {boolean}
   */


  function noEvent(events) {
    var hasEvent = false;
    $scope.eventsFilters.filter(function (filter) {
      return filter.checked === true;
    }).forEach(function (filter) {
      var eventsOfType = events.filter(function (event) {
        return filter.value === event.type;
      });

      if (eventsOfType && eventsOfType.length && !hasEvent) {
        hasEvent = true;
      }
    });
    return hasEvent;
  }
  /**
   * Show event.
   *
   * @param {object} event - The target event.
   * @param {object} $event - The click event.
   */


  function showEvent(event, $event) {
    var dialog = {
      templateUrl: "app/main/laboratory/users-laboratory/views/branding/includes/communication/includes/eventModal.html",
      locals: {
        event: event,
        branding: $scope.branding
      },
      parent: angular.element(document.body),
      controller: EventModalController,
      $event: $event,
      clickOutsideToClose: true
    };
    $mdDialog.show(dialog);
  }
  /**
   * Manage events for list.
   *
   * @returns {object}
   */


  function manageEventsForList() {
    $scope.eventListingOptions.loading = true;
    $scope.eventsTmp = {};
    $scope.tmp = {};
    var today = moment();
    $scope.eventsSaved.events.forEach(function (event) {
      var month = moment(event.start).month() + 1;

      if (month <= today.month()) {
        month = today.month() + 1;
      }

      var monthWithYear = moment(event.start).year() + "/" + month;

      if (!$scope.eventsTmp[monthWithYear]) {
        $scope.eventsTmp[monthWithYear] = [];
      }

      event.title = event.name;
      event.source = event.imagePath ? "".concat(apiurl, "/images/").concat(event.imagePath) : "assets/images/sellouts/default-challenge-1080x600.jpg";
      event.start = new Date(event.start);
      event.end = new Date(event.end);
      event.onGoing = today.isBetween(moment(event.start), moment(event.end));
      event.daysLeft = moment(event.end).diff(moment(), "days");
      event.custom = event.custom_event;
      event.color = event.lightColor;
      event.event_type_id = event.event_type ? event.event_type.id : 1;
      event.event_type = event.type;
      _.find($scope.ctrl.typesSelectedName, function (type) {
        return type.value === event.type;
      }).hasEvents = true;
      $scope.eventsTmp[monthWithYear].push(event);
    });
    Object.keys($scope.eventsTmp).sort(function (dateA, dateB) {
      return moment(dateA, "YYYY/M").toDate() - moment(dateB, "YYYY/M").toDate();
    }).forEach(function (key) {
      $scope.tmp[key] = $scope.eventsTmp[key];
    });
    $scope.events = $scope.tmp;
    $scope.eventListingOptions.loading = false;
    return $scope.events;
  }
  /**
   * goBack
   */


  function goBack() {
    var query = {
      wholesalerId: laboratoriesService.getWholesalerId()
    };
    $state.go("app.usersLaboratory", query);
  }
  /**
   * Go to given state
   *
   * @param {string} state - The state to go.
   */


  function goToState(state) {
    $state.go(state);
  }
  /**
   * goToBrandingState
   *
   * @param {object}  menu - The menu.
   */


  function goToBrandingState(menu) {
    $state.go(menu.state, menu);
  }
  /**
   * goTo
   *
   * @param {object}  brick - The brick.
   */


  function goTo(brick) {
    $rootScope.$broadcast("sideNavConstruct", brick);
    bricksService.setCurrentBrick(brick);
  }
  /**
   * goToEvent
   *
   * @param {object} event - The event.
   */


  function goToEvent(event) {
    $state.go("app.calendar.detail", {
      id: event.id
    });
  }

  $scope.$watch("laboratoryBranding", function (branding) {
    if (branding) {
      $scope.branding = branding;
      loader();
    }
  });
}
/**
 * Controller for event modal.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} event - The event.
 * @param {object} branding - The branding.
 * @param {object} $mdDialog - The angular $mdDialog object.
 */


function EventModalController($scope, event, branding, $mdDialog) {
  $scope.event = event;
  $scope.branding = branding;
  $scope.close = close;
  /**
   * Close the dialog.
   */

  function close() {
    $mdDialog.hide();
  }
}

angular.module("app.users-laboratory").controller("mediaPlanController", MediaPlanController).controller("eventModalController", EventModalController);