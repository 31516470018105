"use strict";
/**
 * Healthcenter Sellin offer controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} sessionStorageService - Session storage service.
 * @param {string} apiurl - API URL.
 * @param {object} environmentService - Environment service.
 * @param {object} sellinOfferService - Sellin offer service.
 */

SellinOffersListController.$inject = ["$scope", "$state", "sessionStorageService", "apiurl", "environmentService", "sellinOfferService"];

function SellinOffersListController($scope, $state, sessionStorageService, apiurl, environmentService, sellinOfferService) {
  $scope.accept = accept;
  $scope.refuse = refuse;
  $scope.viewSellinOffer = viewSellinOffer;
  $scope.limitOptions = [5, 10, 15];
  $scope.apiurl = apiurl;
  $scope.tokenV2 = "&token=" + sessionStorageService.getToken();
  $scope.query = {
    order: "-startDate",
    limit: 15,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.sellinOffers = [];
  $scope.loading = true;
  $scope.loadingAccepted = true;
  $scope.loadingRefused = true;
  $scope.loadAcceptedSelected = false;
  $scope.loadRefusedSelected = false;
  $scope.loadToDoSelected = false;
  $scope.loadAccepted = loadAccepted;
  $scope.loadRefused = loadRefused;
  $scope.loadToDo = loadToDo;
  loader();
  /**
   *
   * loader
   * this method init forEach tabs
   * and load loadAccess() method
   */

  function loader() {
    loadCurrentEnvironment().then(function () {
      loadToDo();
    });
  }
  /**
   * Load current environment.
   *
   * @returns {Promise}
   */


  function loadCurrentEnvironment() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.currentAccess = access;
    });
  }
  /**
   * loadToDo
   *
   * @returns {Promise}
   */


  function loadToDo() {
    $scope.loading = true;
    return sellinOfferService.getToDo().then(function (response) {
      $scope.loading = false;
      $scope.sellinOffers = response;
      $scope.loadToDoSelected = true;
      $scope.loadAcceptedSelected = false;
      $scope.loadRefusedSelected = false;
      return response;
    });
  }
  /**
   * loadToDo
   *
   * @returns {Promise}
   */


  function loadAccepted() {
    $scope.loading = true;
    return sellinOfferService.getAccepted().then(function (response) {
      $scope.loading = false;
      $scope.loadToDoSelected = false;
      $scope.loadAcceptedSelected = true;
      $scope.loadRefusedSelected = false;
      $scope.sellinOffers = response;
      return response;
    });
  }
  /**
   * loadToDo
   *
   * @returns {Promise}
   */


  function loadRefused() {
    $scope.loading = true;
    return sellinOfferService.getRefused().then(function (response) {
      $scope.loading = false;
      $scope.loadToDoSelected = false;
      $scope.loadAcceptedSelected = false;
      $scope.loadRefusedSelected = true;
      $scope.sellinOffers = response;
      return response;
    });
  }
  /**
   * accept
   *
   * @param {object} sellinOffer - Sellin offer.
   *
   * @returns {Promise}
   */


  function accept(sellinOffer) {
    return sellinOfferService.accept(sellinOffer).then(function (response) {
      $scope.currentsSellinOffers = response;
      return response;
    });
  }
  /**
   * refuse
   *
   * @param {object} sellinOffer - Sellin offer.
   *
   * @returns {Promise}
   */


  function refuse(sellinOffer) {
    return sellinOfferService.refuse(sellinOffer).then(function (response) {
      $scope.currentsSellinOffers = response;
      return response;
    });
  }
  /**
   * viewSellinOffer
   * to change state with param id
   *
   * @param {object} sellinOffer - Sellin offer.
   */


  function viewSellinOffer(sellinOffer) {
    $state.go("app.commercialOffer.sellin_offers_detail", {
      id: sellinOffer.id
    });
  }
}

angular.module("app.commercialOffer").controller("sellinOffersListController", SellinOffersListController);