"use strict";
/**
 * Partners healthcenter service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

PartnersHealthcenterService.$inject = ["apiClient"];

function PartnersHealthcenterService(apiClient) {
  return {
    /**
     * Get all partners.
     *
     * @param {number} healthcenterId - Healthcenter unique Id
     * @returns {Promise}
     */
    get: function get(healthcenterId) {
      return apiClient.get("/healthcenters/".concat(healthcenterId, "/partners"), {
        wrappers: {
          response: "partners"
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Enable or disable a partner.
     *
     * @param {object} query -
     * @returns {Promise}
     */
    toggle: function toggle(_ref) {
      var healthcenter = _ref.healthcenter,
          partner = _ref.partner,
          active = _ref.active;
      return apiClient.post("/healthcenters/".concat(healthcenter, "/partners/").concat(partner), {
        active: active
      }, {
        wrappers: {
          response: null
        }
      });
    }
  };
}

angular.module("app.partners").service("partnersHealthcenterService", PartnersHealthcenterService);