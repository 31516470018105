"use strict";
/**
 * Change forgot password controller.
 *
 * @param {object} authService - Auth service.
 * @param {object} authProfileService - Auth profile service.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 */

ChangeForgotPasswordController.$inject = ["authService", "authProfileService", "$stateParams", "$state"];

function ChangeForgotPasswordController(authService, authProfileService, $stateParams, $state) {
  authService.destroyCredentials();
  var vm = this;
  vm.domainName = window.sessionStorage.getItem("domainName");
  vm.changeForgotPassword = changeForgotPassword;
  vm.userChangeForgotPwd = {
    password: "",
    confirmPassword: "",
    code: $stateParams.code
  };
  /**
   * Loader.
   */

  function loader() {
    authProfileService.confirmForgotPassword(vm.userChangeForgotPwd).then(function (response) {
      vm.response = response;
    });
  }
  /**
   * Change forgot password.
   */


  function changeForgotPassword() {
    authProfileService.changePassword(vm.userChangeForgotPwd).then(function (response) {
      vm.response = response;
      return response;
    }).then(function () {
      $state.go("app.pages_auth_login");
    });
  }

  loader();
}

angular.module("app.auth").controller("ChangeForgotPasswordController", ChangeForgotPasswordController);