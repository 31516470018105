"use strict";
/**
 * Sante service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

SanteService.$inject = ["apiClient"];

function SanteService(apiClient) {
  return {
    /**
     * Get.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.post("/admin/santesecure", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * PostState.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    postState: function postState(params) {
      return apiClient.post("/admin/state", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * PostReindex.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    postReIndex: function postReIndex(params) {
      return apiClient.post("/admin/reindex", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.admin").service("santeService", SanteService);