"use strict";
/**
 * Auth interceptor service.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $q - $q.
 * @param {Array} AUTH_EVENTS - Auth events.
 *
 * @returns {object}
 */

authInterceptorService.$inject = ["$rootScope", "$q", "AUTH_EVENTS"];

function authInterceptorService($rootScope, $q, AUTH_EVENTS) {
  return {
    responseError: function responseError(response) {
      $rootScope.$broadcast({
        401: AUTH_EVENTS.notAuthenticated,
        403: AUTH_EVENTS.forbidden
      }[response.status], response);
      return $q.reject(response);
    }
  };
}

angular.module("apodis").factory("authInterceptor", authInterceptorService);