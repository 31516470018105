"use strict";

/**
 * Session storage service.
 *
 * @returns {object}
 */
function SessionStorageService() {
  /**
   * Get token.
   *
   * @returns {string}
   */
  function getToken() {
    return window.sessionStorage.getItem("token");
  }
  /**
   * Get bearer token.
   *
   * @returns {string}
   */


  function getBearerToken() {
    return "Bearer " + getToken();
  }
  /**
   * Set token.
   *
   * @param {string} token - Token.
   */


  function setToken(token) {
    window.sessionStorage.setItem("token", token);
  }

  return {
    getToken: getToken,
    getBearerToken: getBearerToken,
    setToken: setToken
  };
}

angular.module("app.services").service("sessionStorageService", SessionStorageService);