"use strict";
/**
 * Guest team service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

GuestTeamService.$inject = ["apiClient"];

function GuestTeamService(apiClient) {
  return {
    /**
     * Get.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/teams", {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.teams;
      });
    },

    /**
     * Read.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/teams/".concat(params.id), {
        includes: ["options"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.team;
      });
    },

    /**
     * Get accesses.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getAccesses: function getAccesses(params) {
      return apiClient.get("/teams/".concat(params.id, "/accesses"), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.accesses;
      });
    },

    /**
     * Get options.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getOptions: function getOptions(params) {
      return apiClient.get("/teams/".concat(params.id, "/options"), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.options;
      });
    },

    /**
     * Create.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    create: function create(params) {
      return apiClient.post("/teams", params, {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.team;
      });
    },

    /**
     * Update.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/teams/".concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.team;
      });
    },

    /**
     * Delete.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/teams/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (result) {
        return result.data.team;
      });
    },

    /**
     * Get team operator.
     *
     * @returns {Promise}
     */
    getTeamOperator: function getTeamOperator() {
      return apiClient.get("/teams/team-operator", {
        includes: ["options"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.team;
      });
    },

    /**
     * Get operator accesses.
     *
     * @returns {Promise}
     */
    getOperatorAccesses: function getOperatorAccesses() {
      return apiClient.get("/teams/team-operator/accesses", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.accesses;
      });
    }
  };
}

angular.module("app.guest").service("guestTeamService", GuestTeamService);