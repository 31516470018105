"use strict";
/**
 * The app-thumbnail-title directive.
 *
 * @returns {object}
 */

function AppThumbnailTitle() {
  return {
    restrict: "E",
    scope: {
      variant: "@variant"
    },
    templateUrl: "app/main/directives/app-thumbnail/app-thumbnail-title/appThumbnailTitle.html",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appThumbnailTitle", AppThumbnailTitle);