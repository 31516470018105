"use strict";
/**
 * User sellout list controller.
 *
 * @param {object} $scope - Scope
 * @param {object} $state - State
 * @param {object} $mdDialog - MD dialog
 * @param {string} apiurl - API URL constant
 * @param {object} sessionStorageService - Session storage service.
 * @param {object} $stateParams - State params
 * @param {object} environmentService - Environment service
 * @param {object} userSelloutService - User sellout service
 * @param {object} notifyUserService - Notify user service
 */

UserSelloutListController.$inject = ["$scope", "$state", "$mdDialog", "apiurl", "sessionStorageService", "$stateParams", "environmentService", "userSelloutService", "notifyUserService"];

function UserSelloutListController($scope, $state, $mdDialog, apiurl, sessionStorageService, $stateParams, environmentService, userSelloutService, notifyUserService) {
  /**
   * Declaration of public methods
   */
  $scope.showSellout = showSellout;
  $scope.getAllSellouts = getAllSellouts;
  $scope.getOngoingSellouts = getOngoingSellouts;
  $scope.getFinishedSellouts = getFinishedSellouts;
  $scope.getComeupSellouts = getComeupSellouts;
  $scope.openTab = openTab;
  $scope.acceptSellOut = acceptSellOut;
  $scope.refuseSellOut = refuseSellOut;
  $scope.isList = isList;
  $scope.isTile = isTile;
  $scope.sendEvent = sendEvent;
  /**
   * Declaration of public objects
   */

  $scope.loading = true;
  $scope.viewBy = "tile";
  $scope.statusFiltered = "all";
  $scope.token = sessionStorageService.getToken();
  $scope.mainTitle = {
    title: {
      icon: "icon-cart-outline",
      text: "Mes challenges équipe"
    }
  };
  /**
   * Get tabs.
   *
   * @returns {Array}
   */

  function getTabs() {
    return [{
      id: 1,
      name: "En cours",
      method: getOngoingSellouts,
      slug: "ongoing",
      isActive: true,
      active: true
    }, {
      id: 2,
      name: "À venir",
      method: getComeupSellouts,
      slug: "future",
      data: $scope.futureSellinChallenges,
      isActive: true
    }, {
      id: 3,
      name: "Terminés",
      method: getFinishedSellouts,
      slug: "finished",
      isActive: true
    }];
  }

  $scope.loadingComeup = {
    loading: true,
    loader: "Récupération des challenges à venir",
    empty: "Vous n'avez aucun challenge à venir."
  };
  $scope.loadingOngoing = {
    loading: true,
    loader: "Récupération des challenges sell-out en cours",
    empty: "Vous n'avez aucun challenge sell-out en cours."
  };
  $scope.loadingFinished = {
    loading: true,
    loader: "Récupération des challenges terminés",
    empty: "Vous n'avez aucun challenge terminé."
  };
  $scope.queryComeup = {
    order: "-startDate",
    limit: 10,
    page: 1
  };
  $scope.queryOngoing = {
    order: "-startDate",
    limit: 10,
    page: 1
  };
  $scope.queryFinished = {
    order: "-startDate",
    limit: 10,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.fromDateComeup = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  $scope.toDateComeup = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  $scope.fromDateOngoing = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  $scope.toDateOngoing = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  $scope.fromDatePending = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  $scope.toDatePending = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  $scope.fromDateFinished = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  $scope.toDateFinished = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  $scope.fromDateRefused = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  $scope.toDateRefused = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  loader();
  /**
   * Loader.
   */

  function loader() {
    getEnvironment().then(function () {
      getOngoingSellouts();
      getComeupSellouts();
    });
  }

  var actionsTileButtons = {
    detail: {
      label: "Détails",
      onClick: function onClick(tile) {
        $state.go(generateState(), {
          id: tile.id
        });
      },
      htmlClass: "full md-accent-bg md-white",
      icon: "icon-eye",
      tooltip: "Détails du challenge sell-out"
    },
    "delete": {
      label: "Supprimer",
      htmlClass: "left md-red-bg",
      onClick: deleteTile,
      icon: "icon-close",
      tooltip: "Supprimer le challenge sell-out"
    },
    accept: {
      label: "Accepter",
      htmlClass: "left md-green-bg",
      onClick: acceptTile,
      icon: "icon-check",
      tooltip: "Accepter le challenge sell-out"
    },
    refuse: {
      label: "Refuser",
      htmlClass: "left md-red-bg",
      onClick: refuseTile,
      icon: "icon-close",
      tooltip: "Refuser le challenge sell-out"
    }
  };
  /**
   * Get current environment.
   *
   * @returns {Promise}
   */

  function getEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentEnv = response;
      $scope.isEmployee = $scope.currentEnv.accessPointType === 2 && $scope.currentEnv.rankId !== 1 && $scope.currentEnv.rankId !== 2;
      $scope.isHolder = $scope.currentEnv.accessPointType === 2 && ($scope.currentEnv.rankId === 1 || $scope.currentEnv.rankId === 2);
      $scope.tabs = getTabs();

      if (!$stateParams.activeTab) {
        /**
         * To display block the first tab
         */
        $scope.tabs.forEach(function () {
          var elem = angular.element(document).find("#ongoing")[0];
          elem.style.display = "block";
        });
      }

      $scope.loading = false;
      return response;
    });
  }
  /**
   * Get all user-sellout.
   *
   * @returns {Promise}
   */


  function getAllSellouts() {
    $scope.allSellouts = [];
    $scope.elementsList = [];
    return userSelloutService.filterUserSellouts().then(function (response) {
      $scope.allSellouts = parseSellout(response);
      $scope.elementsList = angular.copy($scope.allSellouts);
      return response;
    });
  }
  /**
   * Get come-up user-sellout.
   *
   * @returns {Promise}
   */


  function getComeupSellouts() {
    $scope.loadingComeup.loading = true;
    $scope.comeupSellouts = [];
    $scope.comeupSelloutsLast = null;
    var query = {
      moment: "comeup"
    };
    return userSelloutService.filterUserSellouts(query).then(function (response) {
      $scope.comeupSellouts = parseSellout(response);
      $scope.configComeupTiles = {
        tiles: parseToTiles($scope.comeupSellouts),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.accept, actionsTileButtons.refuse],
        filter: "Que cherchez-vous ?"
      };
      $scope.comeupSelloutsLast = extractLasts($scope.comeupSellouts);
      $scope.fromDateComeup = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
      $scope.toDateComeup = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      return response;
    }).then(function (response) {
      $scope.loadingComeup.loading = false;
      return response;
    });
  }
  /**
   * Get on-coming user-sellout
   *
   * @returns {Promise}
   */


  function getOngoingSellouts() {
    $scope.loadingOngoing.loading = true;
    $scope.ongoingSellouts = [];
    $scope.ongoingSelloutsLast = null;
    var query = {
      moment: "ongoing"
    };
    return userSelloutService.filterUserSellouts(query).then(function (response) {
      $scope.ongoingSellouts = parseSellout(response);
      $scope.configOngoingTiles = {
        tiles: parseToTiles($scope.ongoingSellouts),
        states: {
          show: generateState()
        },
        buttons: [actionsTileButtons.accept, actionsTileButtons.refuse],
        filter: "Que cherchez-vous ?"
      };
      $scope.ongoingSelloutsLast = extractLasts($scope.ongoingSellouts);
      $scope.fromDateOngoing = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
      $scope.toDateOngoing = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      return response;
    }).then(function (response) {
      $scope.loadingOngoing.loading = false;
      return response;
    });
  }
  /**
   * Get finished user-sellout
   *
   * @returns {Promise}
   */


  function getFinishedSellouts() {
    $scope.loadingFinished.loading = true;
    $scope.finishedSellouts = [];
    $scope.finishedSelloutsLast = null;
    var query = {
      moment: "finished"
    };
    return userSelloutService.filterUserSellouts(query).then(function (response) {
      $scope.finishedSellouts = parseSellout(response);
      $scope.configFinishedTiles = {
        tiles: parseToTiles($scope.finishedSellouts),
        states: {
          show: generateState()
        },
        filter: "Que cherchez-vous ?",
        buttons: [actionsTileButtons.detail]
      };
      $scope.finishedSelloutsLast = extractLasts($scope.finishedSellouts);
      $scope.fromDateOngoing = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
      $scope.toDateOngoing = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      return response;
    }).then(function (response) {
      $scope.loadingFinished.loading = false;
      return response;
    });
  }
  /**
   * Parse sellout
   *
   * @param {Array} sellouts -
   * @returns {Array}
   */


  function parseSellout(sellouts) {
    if (_.isUndefined(sellouts)) {
      return [];
    }

    return sellouts.map(function (challenge) {
      var today = new Date();
      var start = new Date(challenge.sellout.startDate);
      var end = new Date(challenge.sellout.endDate);

      if (today < start) {
        challenge.sellout.status = "coming";
      } else if (today >= start && today <= end) {
        challenge.sellout.status = "ongoing";
      } else if (today > end) {
        challenge.sellout.status = "finished";
      }

      challenge.sellout.duration = moment(end).diff(moment(start), "days") + " Jours";
      challenge.sellout.image = challenge.sellout.imagePath ? apiurl + "/images/" + challenge.sellout.imagePath : "assets/images/sellouts/default-challenge-1080x600.jpg";
      return challenge;
    });
  }
  /**
   * Parse to tiles sellouts
   *
   * @param {Array} challenges -
   * @returns {Array}
   */


  function parseToTiles(challenges) {
    return challenges.map(function (challenge) {
      return {
        id: challenge.sellout.id,
        entityId: challenge.entityId,
        entityTypeId: challenge.entityTypeId,
        organizationId: challenge.sellout.organization_id,
        organizationTypeId: challenge.sellout.organization.entity_type_id,
        organization: challenge.sellout.organization,
        name: challenge.sellout.name,
        subName: challenge.sellout.organization.morphable.name,
        description: challenge.sellout.description,
        image: challenge.sellout.image,
        imagePath: challenge.sellout.imagePath,
        isEnabled: challenge.sellout.isEnabled,
        isPublished: challenge.sellout.isPublished,
        isSynchronized: challenge.sellout.isSynchronized,
        isSucceeded: challenge.isSucceeded,
        distribution: challenge.distribution,
        rewardDistributionId: challenge.rewardDistributionId,
        objectiveRewards: challenge.sellout.objectiveRewards,
        status: challenge.sellout.status,
        challengeStatus: challenge.status,
        succeededAt: challenge.succeededAt,
        validatedAt: challenge.validatedAt,
        startDate: challenge.sellout.startDate,
        endDate: challenge.sellout.endDate,
        createdAt: challenge.sellout.createdAt,
        updatedAt: challenge.sellout.updatedAt,
        duration: challenge.sellout.duration
      };
    });
  }
  /**
   * Generate state by currentEnv.accessPointType
   *
   * @returns {string}
   */


  function generateState() {
    switch ($scope.currentEnv.accessPointType) {
      case 2:
        return "app.UserSelloutHome.detail";

      case 3:
        return "app.UserSelloutHome.detail.grouping";
    }

    return "";
  }
  /**
   * Extract last sellouts
   *
   * @param {Array} sellouts -
   * @returns {Array}
   */


  function extractLasts(sellouts) {
    return sellouts.slice(0, 6);
  }
  /**
   * Show sellout
   *
   * @param {object} sellout -
   */


  function showSellout(sellout) {
    switch ($scope.currentEnv.accessPointType) {
      case 2:
        $state.go("app.UserSelloutHome.detail", {
          id: sellout.id
        });
        break;

      case 3:
        $state.go("app.UserSelloutHome.detail.grouping", {
          id: sellout.id
        });
        break;
    }
  }
  /**
   * Accept a sell-out.
   *
   * @param {object} event - Event.
   * @param {object} tile - Tile.
   */


  function acceptSellOut(event, tile) {
    $mdDialog.show({
      templateUrl: "app/main/users-sellout/views/dialogs/accept.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        tile: tile
      },
      clickOutsideToClose: true,
      controller: ["$scope", "$mdDialog", "environmentService", "rewardsService", "tile", function controller($scope, $mdDialog, environmentService, rewardsService, tile) {
        /**
         * Declaration of public methods
         */
        $scope.close = close;
        $scope.validate = validate;
        /**
         * Declaration of public objects
         */

        $scope.tile = tile;
        $scope.loadingDistributions = {
          loading: true,
          loader: "Récupération des types de récompenses",
          empty: "Vous n'avez pas de type de récompenses."
        };
        loader();
        /**
         * Loader.
         */

        function loader() {
          getEnvironment().then(getRewardDistribution);
        }
        /**
         * Get current environment
         *
         * @returns {Promise}
         */


        function getEnvironment() {
          return environmentService.getEnvironment().then(function (response) {
            $scope.environment = response;
            return response;
          });
        }
        /**
         * Get reward distribution
         *
         * @returns {Promise}
         */


        function getRewardDistribution() {
          return rewardsService.getRewardDistributions().then(function (response) {
            $scope.loadingDistributions.loading = false;
            $scope.distributions = response;
            return response;
          });
        }
        /**
         * Validate.
         */


        function validate() {
          tile.rewardDistributionId = $scope.rewardDistributionId;
          $mdDialog.hide(tile);
        }
        /**
         * Close.
         */


        function close() {
          $mdDialog.cancel();
        }
      }]
    }).then(function (response) {
      if (response) {
        userSelloutService.accept(event, response).then(function () {
          notifyUserService.showSuccessToast("Le challenge \xAB ".concat(response.name, " \xBB a \xE9t\xE9 accept\xE9."));
          return response;
        });
      }
    }).then(getOngoingSellouts).then(getComeupSellouts);
  }
  /**
   * Refuse a sell-out.
   *
   * @param {object} event -
   * @param {object} tile -
   */


  function refuseSellOut(event, tile) {
    $mdDialog.show({
      templateUrl: "app/main/users-sellout/views/dialogs/refuse.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        tile: tile
      },
      clickOutsideToClose: true,
      controller: ["$scope", "$mdDialog", "tile", function controller($scope, $mdDialog, tile) {
        $scope.validate = validate;
        $scope.close = close;
        /**
         * Validate.
         */

        function validate() {
          tile.comments = $scope.comments;
          $mdDialog.hide(tile);
        }
        /**
         * Close.
         */


        function close() {
          $mdDialog.cancel();
        }
      }]
    }).then(function (response) {
      if (response) {
        userSelloutService.refuse(event, tile).then(function () {
          notifyUserService.showSucessToast("Le challenge « " + tile.name + " » a été refusé");
          return response;
        });
      }
    }).then(getOngoingSellouts).then(getComeupSellouts);
  }
  /**
   * Open tab.
   *
   * @param {object} myTab - The tab
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";
        tab.method ? tab.method() : null;

        if (tab.methods) {
          tab.methods.forEach(function (method) {
            method();
          });
        }
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Accept a tile.
   *
   * @param {object} tile - The tile.
   * @param {object} event - The event.
   */


  function acceptTile(tile, event) {
    $mdDialog.show({
      templateUrl: "app/main/users-sellout/views/dialogs/accept.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        tile: tile
      },
      clickOutsideToClose: true,
      controller: ["$scope", "$mdDialog", "environmentService", "rewardsService", "tile", function controller($scope, $mdDialog, environmentService, rewardsService, tile) {
        /**
         * Declaration of public methods
         */
        $scope.close = close;
        $scope.validate = validate;
        /**
         * Declaration of public objects
         */

        $scope.tile = tile;
        $scope.loadingDistributions = {
          loading: true,
          loader: "Récupération des types de récompenses",
          empty: "Vous n'avez pas de type de récompenses."
        };
        loader();
        /**
         * Loader.
         */

        function loader() {
          getEnvironment().then(getRewardDistribution);
        }
        /**
         * Get current environment.
         *
         * @returns {Promise}
         */


        function getEnvironment() {
          return environmentService.getEnvironment().then(function (response) {
            $scope.environment = response;
            return response;
          });
        }
        /**
         * Get reward distribution
         *
         * @returns {Promise}
         */


        function getRewardDistribution() {
          return rewardsService.getRewardDistributions().then(function (response) {
            $scope.loadingDistributions.loading = false;
            $scope.distributions = response;
            return response;
          });
        }
        /**
         * Validate.
         */


        function validate() {
          tile.rewardDistributionId = $scope.tile.rewardDistributionId;
          $mdDialog.hide(tile);
        }
        /**
         * Close.
         */


        function close() {
          $mdDialog.cancel();
        }
      }]
    }).then(function (response) {
      if (response) {
        userSelloutService.accept(event, response).then(function () {
          notifyUserService.showSuccessToast("Le challenge « " + response.name + " » a été accepté");
          return response;
        });
      }
    }).then(getOngoingSellouts).then(getComeupSellouts);
  }
  /**
   * Refuse a tile.
   *
   * @param {object} tile -
   * @param {object} event -
   */


  function refuseTile(tile, event) {
    $mdDialog.show({
      templateUrl: "app/main/users-sellout/views/dialogs/refuse.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        tile: tile
      },
      clickOutsideToClose: true,
      controller: ["$scope", "$mdDialog", "tile", function controller($scope, $mdDialog, tile) {
        $scope.validate = validate;
        $scope.close = close;
        /**
         * Validate.
         */

        function validate() {
          tile.comments = $scope.comments;
          $mdDialog.hide(tile);
        }
        /**
         * Close.
         */


        function close() {
          $mdDialog.cancel();
        }
      }]
    }).then(function (response) {
      if (response) {
        userSelloutService.refuse(event, tile).then(function () {
          notifyUserService.showSuccessToast("Le challenge « " + tile.name + " » a été refusé");
          return response;
        });
      }
    }).then(getOngoingSellouts).then(getComeupSellouts);
  }
  /**
   * Delete a tile.
   *
   * @param {object} tile -
   * @param {object} event -
   */


  function deleteTile(tile, event) {
    $mdDialog.show({
      templateUrl: "app/main/users-sellout/views/dialogs/accept.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        tile: tile
      },
      clickOutsideToClose: true,
      controller: ["$scope", "$mdDialog", "tile", function controller($scope, $mdDialog, tile) {
        /**
         * Declaration of public methods
         */
        $scope.close = close;
        $scope.validate = validate;
        /**
         * Validate.
         */

        function validate() {
          $mdDialog.cancel(tile);
        }
        /**
         * Close.
         */


        function close() {
          $mdDialog.cancel();
        }
      }]
    }).then(function (response) {
      if (response) {
        userSelloutService.accept(event, response).then(function () {
          notifyUserService.showSuccessToast("Le challenge « " + response.name + " » a été accepté");
          return response;
        });
      }
    }).then(getOngoingSellouts).then(getComeupSellouts);
  }
  /**
   * To determinate if current view is list.
   *
   * @returns {boolean}
   */


  function isList() {
    return $scope.viewBy === "list";
  }
  /**
   * To determinate if current view tile
   *
   * @returns {boolean}
   */


  function isTile() {
    return $scope.viewBy === "tile";
  }
  /**
   * Change display of sellouts.
   *
   * @param {string} viewBy - The way to show sellout
   */


  function sendEvent(viewBy) {
    $scope.viewBy = viewBy;
  }
}

angular.module("app.users-sellout").controller("userSelloutListController", UserSelloutListController);