"use strict";
/**
 * PLV fonts service.
 *
 * @param {object} apiClient -
 * @returns {object}
 */

PlvsFontsService.$inject = ["apiClient"];

function PlvsFontsService(apiClient) {
  return {
    /**
     * load
     *
     * @returns {Promise}
     */
    load: function load() {
      return apiClient.get("/plvs/fonts").then(function (result) {
        return result.data;
      });
    }
  };
}

angular.module("app.generator").service("plvsFontsService", PlvsFontsService);