"use strict";
/**
 * Events calendar home controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} environmentService - Environment service.
 */

EventsCalendarHomeController.$inject = ["$scope", "$state", "environmentService"];

function EventsCalendarHomeController($scope, $state, environmentService) {
  $scope.tabSelected = 0;
  $scope.calendarTitle = {
    title: {
      icon: "icon-calendar",
      text: "Calendrier"
    }
  };
  environmentService.getEnvironment().then(function (env) {
    $scope.env = env;

    switch ($state.current.name) {
      case "app.calendar.agenda":
        $scope.tabSelected = 1;
        break;

      case "app.calendar.timeline":
        $scope.tabSelected = 0;
        break;

      case "app.calendar.events":
        $scope.tabSelected = 2;
        break;
    }
  });
}

angular.module("app.events").controller("eventsCalendarHomeController", EventsCalendarHomeController);