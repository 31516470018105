"use strict";
/**
 * Dashboard groups sellout prestations events month controller.
 *
 * @param {object} $scope - $scope.
 */

DashboardGroupsSelloutPrestationsEventsMonthController.$inject = ["$scope"];

function DashboardGroupsSelloutPrestationsEventsMonthController($scope) {
  $scope.options = {
    type: "sellout,prestation",
    start: new Date(),
    end: new Date().setMonth(new Date().getMonth() + 1)
  };
}

angular.module("app.dashboard").controller("dashboardGroupsSelloutPrestationsEventsMonthController", DashboardGroupsSelloutPrestationsEventsMonthController);