"use strict";
/**
 * Statistics query service.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $q - $q.
 * @param {object} environmentService - Environment service.
 *
 * @returns {object}
 */

StatisticsQueryService.$inject = ["$rootScope", "$q", "environmentService"];

function StatisticsQueryService($rootScope, $q, environmentService) {
  var dateFrom = moment().subtract(1, "year");
  var querySkeleton = {
    region: {
      pluralize: "e",
      name: "Région",
      slug: "geo.region.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "Region",
      accessPointRight: [1, 3]
    },
    departement: {
      pluralize: "",
      name: "Département",
      slug: "geo.departement.number",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "Departement",
      accessPointRight: [1, 3]
    },
    uga: {
      pluralize: "e",
      name: "UGA",
      slug: "geo.uga",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "UGA",
      accessPointRight: [1, 3]
    },
    city: {
      pluralize: "e",
      name: "Ville",
      slug: "geo.city.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "City",
      accessPointRight: [1, 3]
    },
    pharmas: {
      pluralize: "e",
      name: "Pharmacies",
      slug: "idOrgas",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "Healthcenter",
      accessPointRight: [1, 3]
    },
    operator: {
      pluralize: "s",
      name: "Vendeur",
      slug: "vendeur",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "Operator"
    },
    groups: {
      pluralize: "e",
      name: "Groupements",
      slug: "group.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "Groups",
      accessPointRight: [1]
    },
    stocks: {
      pluralize: "",
      name: "Stocks",
      slug: "stocks",
      min: null,
      max: null,
      filter: "",
      belongs: "quanti"
    },
    ca: {
      pluralize: "",
      name: "Chiffre d'affaires",
      slug: "ca",
      min: null,
      max: null,
      filter: "",
      belongs: "quanti"
    },
    population: {
      pluralize: "e",
      name: "Population",
      slug: "population",
      min: null,
      max: null,
      filter: "",
      belongs: "quanti"
    },
    product: {
      pluralize: "",
      name: "Produit",
      slug: "idProducts",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "Products",
      accessPointRight: [1, 2, 3]
    },
    segment: {
      pluralize: "",
      name: "Segment",
      slug: "segment.value",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "Segments",
      accessPointRight: [1, 2, 3]
    },
    segmentHealthcenter: {
      pluralize: "",
      name: "Segment pharmacie",
      slug: "segment.healthcenter",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "Segments pharmacie",
      accesPointRight: [1, 3]
    },
    segmentProduct: {
      pluralize: "",
      name: "Segment produit",
      slug: "segment.product",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "Segments produit",
      accesPointRight: [1, 3]
    },
    paracetamol: {
      pluralize: "",
      name: "Paracetamol",
      slug: "paracetamol",
      belongs: "quali",
      selected: 0
    },
    laboratory: {
      pluralize: "",
      name: "Laboratoire",
      slug: "laboratory.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "Laboratory",
      accessPointRight: [1, 2, 3]
    },
    category: {
      pluralize: "s",
      name: "Classification",
      slug: "category.levelX.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "Category",
      accessPointRight: [1, 2, 3]
    },
    categoriesLaboratoryProduct: {
      pluralize: "s",
      name: "Catégories produits",
      slug: "categoriesLaboratoryProduct.levelX.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "CategoriesLaboratoryProduct",
      accessPointRight: [1]
    },
    categoriesLaboratoryHealthcenter: {
      pluralize: "s",
      name: "Catégories pharmacies",
      slug: "categoriesLaboratoryHealthcenter.levelX.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      atcl: "CategoriesLaboratoryHealthcenter",
      accessPointRight: [1]
    },
    categoryMulti: {
      pluralize: "s",
      name: "Classification",
      slug: "category",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "",
      atcl: "Category",
      accessPointRight: [1, 2, 3]
    },
    ranges: {
      pluralize: "s",
      name: "Gamme",
      slug: "range.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "quali",
      accessPointRight: [1, 2, 3]
    },
    refund: {
      pluralize: "s",
      name: "Remboursement",
      slug: "refund",
      selected: [],
      belongs: "quali"
    },
    tva: {
      pluralize: "",
      name: "T.V.A.",
      slug: "tva",
      selected: [],
      belongs: "quali"
    },
    deliv: {
      pluralize: "s",
      name: "Délivrance",
      slug: "deliv",
      selected: [],
      belongs: "quali"
    },
    salesPriceSlices: {
      pluralize: "s",
      name: "Tranche de prix",
      slug: "salesPriceSlices",
      selected: [],
      belongs: "quali",
      accessPointRight: [1, 2, 3]
    },
    gen: {
      pluralize: "s",
      name: "Générique",
      slug: "gen",
      selected: [],
      belongs: "quali"
    },
    generic: {
      pluralize: "s",
      name: "Générique",
      slug: "generic.id",
      selected: [],
      atclSelected: null,
      belongs: "quali",
      accessPointRight: [1, 2, 3]
    },
    wholesaler: {
      pluralize: "s",
      name: "Grossiste",
      slug: "wholesaler.id",
      atclSelected: null,
      filter: "",
      selected: [],
      belongs: "quali",
      accessPointRight: [1, 2, 3],
      stateRestriction: "app.statistics.stocks.supplying"
    },
    warehouse: {
      pluralize: "s",
      name: "Agence",
      atclSelected: null,
      filter: "",
      slug: "warehouse.warehouse_code",
      selected: [],
      belongs: "quali",
      accessPointRight: [1, 2, 3],
      stateRestriction: "app.statistics.stocks.supplying"
    },
    user: {
      pluralize: "s",
      name: "Utilisateur",
      slug: "user.id",
      selected: [],
      atclSelected: null,
      filter: "",
      belongs: "geo",
      atcl: "Utilisateurs",
      accessPointRight: [2]
    }
  };
  /**
   * Get category for aggregations.
   *
   * @param {number} currentLevel - Current category level.
   *
   * @returns {{
   *      id: string,
   *      val: string,
   *      rights: number[],
   *      label: null,
   *      title: string,
   *      lbl: string,
   *      checked: boolean,
   *      model: boolean,
   *      type: string
   * }}
   */

  function getCategoryForAgg(currentLevel) {
    var humanLevel = parseInt(currentLevel) + 1;
    return {
      id: "cat" + humanLevel,
      val: "product.category.level" + currentLevel,
      rights: [1, 2, 3],
      label: null,
      title: "Catégorie de Niveau " + humanLevel,
      lbl: "Catégorie de Niveau " + humanLevel,
      checked: false,
      model: true,
      type: "category"
    };
  }
  /**
   * Get categories laboratory product for aggregations.
   *
   * @param {number} currentLevel - Current category level.
   * @returns {{
   *      id: string,
   *      val: string,
   *      rights: number[],
   *      label: null,
   *      title: string,
   *      lbl: string,
   *      checked: boolean,
   *      model: boolean,
   *      type: string
   *   }}
   */


  function getCategoriesLaboratoryProductForAgg(currentLevel) {
    var humanLevel = parseInt(currentLevel) + 1;
    return {
      id: "catLaboratoriesProduct" + humanLevel,
      val: "product.categoriesLaboratoryProduct.level" + currentLevel,
      rights: [1],
      label: null,
      title: "Catégorie produits Niv " + humanLevel,
      lbl: "Catégorie produits Niv " + humanLevel,
      checked: false,
      model: true,
      type: "categoriesLaboratoryProduct"
    };
  }
  /**
   * Get categories laboratory healthcenter for aggregations.
   *
   * @param {number} currentLevel - Current category level.
   * @returns {{
   *      id: string,
   *      val: string,
   *      rights: number[],
   *      label: null,
   *      title: string,
   *      lbl: string,
   *      checked: boolean,
   *      model: boolean,
   *      type: string
   *  }}
   */


  function getCategoriesLaboratoryHealthcenterForAgg(currentLevel) {
    var humanLevel = parseInt(currentLevel) + 1;
    return {
      id: "catLaboratoriesHealthcenter" + humanLevel,
      val: "organization.categoriesLaboratoryHealthcenter.level" + currentLevel,
      rights: [1],
      label: null,
      title: "Catégorie pharmacies Niv " + humanLevel,
      lbl: "Catégorie pharmacies Niv " + humanLevel,
      checked: false,
      model: true,
      type: "categoriesLaboratoryHealthcenter"
    };
  }

  var service = {
    hasFilter: false,
    saveSubQuery: function saveSubQuery(obj) {
      this.substituteQuery = obj;
      $rootScope.$broadcast("analysis:subquery", obj);
    },
    saveRegQuery: function saveRegQuery(obj) {
      this.query = obj;
      $rootScope.$broadcast("analysis:regquery", obj);
    },
    substituteQuery: {
      date: {
        pluralize: "e",
        name: "PériodedeRecherche",
        slug: "date",
        from: dateFrom,
        to: new Date()
      },
      lab: {
        pluralize: "",
        name: "Laboratoire",
        slug: "lab",
        selected: [{
          id: 1419,
          name: "Arrow génériques"
        }, {
          id: 6443,
          name: "Zentiva"
        }, {
          id: 4489,
          name: "Cristers"
        }, {
          id: 1084,
          name: "Biogaran"
        }, {
          id: 1085,
          name: "EGLabo"
        }, {
          id: 879,
          name: "Mylan"
        }, {
          id: 4691,
          name: "RPG"
        }, {
          id: 286,
          name: "Sandoz"
        }, {
          id: 4965,
          name: "TEVA"
        }],
        belongs: "quali"
      },
      paracetamol: {
        pluralize: "",
        name: "Paracetamol",
        slug: "paracetamol",
        belongs: "quali",
        selected: 0
      },
      must: {
        pharmas: {
          pluralize: "e",
          name: "Pharmacies",
          slug: "pharmacies",
          selected: [],
          atclSelected: null,
          filter: "",
          belongs: "quali"
        }
      }
    },
    query: {
      must: JSON.parse(JSON.stringify(querySkeleton)),
      should: JSON.parse(JSON.stringify(querySkeleton)),
      date: {
        pluralize: "e",
        name: "PériodedeRecherche",
        slug: "date",
        from: dateFrom,
        to: moment()
      }
    },
    pristineQuery: {
      must: JSON.parse(JSON.stringify(querySkeleton)),
      should: JSON.parse(JSON.stringify(querySkeleton)),
      date: {
        pluralize: "e",
        name: "PériodedeRecherche",
        slug: "date",
        from: dateFrom,
        to: moment()
      }
    },
    pristineSubstituteQuery: {
      date: {
        pluralize: "e",
        name: "PériodedeRecherche",
        slug: "date",
        from: dateFrom,
        to: new Date()
      },
      lab: {
        pluralize: "",
        name: "Laboratoire",
        slug: "lab",
        selected: [{
          id: 1419,
          name: "Arrow génériques"
        }, {
          id: 6443,
          name: "Zentiva"
        }, {
          id: 4489,
          name: "Cristers"
        }, {
          id: 1084,
          name: "Biogaran"
        }, {
          id: 1085,
          name: "EGLabo"
        }, {
          id: 879,
          name: "Mylan"
        }, {
          id: 4691,
          name: "RPG"
        }, {
          id: 286,
          name: "Sandoz"
        }, {
          id: 5801,
          name: "KRKA"
        }, {
          id: 4965,
          name: "TEVA"
        }],
        belongs: "quali"
      },
      paracetamol: {
        pluralize: "",
        name: "Paracetamol",
        slug: "paracetamol",
        belongs: "quali",
        selected: 0
      },
      must: {
        pharmas: {
          pluralize: "e",
          name: "Pharmacies",
          slug: "pharmacies",
          selected: [],
          atclSelected: null,
          filter: "",
          belongs: "quali"
        }
      }
    },
    values: {
      refund: [{
        label: "Non",
        value: 0
      }, {
        label: "15%",
        value: 8
      }, {
        label: "30%",
        value: 1
      }, {
        label: "65%",
        value: 2
      }, {
        label: "100%",
        value: 3
      }, {
        label: "LPPR",
        value: 4
      }],
      tva: [{
        label: "2,1%",
        value: 5
      }, {
        label: "5,5%",
        value: 3
      }, {
        label: "10%",
        value: 8
      }, {
        label: "20%",
        value: 9
      }],
      delivrance: [{
        label: "Stupéfiant",
        value: "S"
      }, {
        label: "Liste I",
        value: 1
      }, {
        label: "Liste II",
        value: 2
      }],
      generiques: [{
        label: "Non Génériqué",
        value: 3
      }, {
        label: "Génériques",
        value: 2
      }, {
        label: "Princeps",
        value: 1
      }],
      salesPriceSlices: [{
        label: "De 0€ à 0,82€",
        value: 1
      }, {
        label: "De 0,83€ à 1,91€",
        value: 2
      }, {
        label: "De 1,92€ à 22,90€",
        value: 3
      }, {
        label: "De 22,91€ à 150€",
        value: 4
      }, {
        label: "De 150,01€ à 1500€",
        value: 5
      }, {
        label: "Plus de 1500€",
        value: 6
      }] // cold: [
      //     {label: "Froid", value: 1},
      //     {label: "Ambiant", value: 0}
      // ],
      // ald: [
      //     {label: "Oui", value: 1},
      //     {label: "Non", value: 0}
      // ]

    },
    aggBy: [{
      id: "1",
      val: "none",
      rights: [1, 2, 3],
      label: "(Produit une courbe moyenne)",
      title: "Désactivé",
      lbl: null,
      stateRestriction: "app.statistics.salesAnalysis"
    }, {
      id: "26",
      val: "item.wholesaler.id",
      rights: [1],
      label: null,
      title: "Grossistes",
      lbl: "Grossistes",
      checked: false,
      model: true,
      type: "wholesaler",
      stateRestriction: "app.disruption.supplying"
    }, {
      id: "2",
      val: "product.laboratory",
      rights: [1, 2, 3],
      label: null,
      title: "Laboratoires",
      lbl: "Laboratoires",
      checked: true,
      model: true,
      type: "laboratory"
    }, {
      id: "4",
      val: "product.presentation",
      rights: [1, 2, 3],
      label: null,
      title: "Produits",
      lbl: "Produits",
      checked: true,
      model: true,
      type: "product"
    }, {
      id: "6",
      val: "product.margin.healthcenter.type",
      rights: [1, 2, 3],
      label: null,
      title: "MDL Pharmacien",
      lbl: "MDL Pharmacien",
      checked: false,
      model: true
    }, {
      id: "7",
      val: "product.margin.wholesaler.type",
      rights: [1, 2, 3],
      label: null,
      title: "MDL Grossiste",
      lbl: "MDL Grossiste",
      checked: false,
      model: true
    }, {
      id: "8",
      val: "segmentProduct",
      rights: [1, 3],
      label: null,
      title: "Segments Produits",
      lbl: "Segments Produits",
      checked: false,
      model: true,
      type: "segmentProduct"
    }, {
      id: "9",
      val: "segmentHealthcenter",
      rights: [1, 3],
      label: null,
      title: "Segments Pharmacies",
      lbl: "Segments Pharmacies",
      checked: false,
      model: true,
      type: "segmentHealthcenter"
    }, {
      id: "5",
      val: "product.segment.value",
      rights: [1, 2, 3],
      label: null,
      title: "Segments",
      lbl: "Segments",
      checked: false,
      model: true,
      type: "segment"
    }, {
      id: "10",
      val: "product.range",
      rights: [1, 2, 3],
      label: null,
      title: "Gammes",
      lbl: "Gammes",
      checked: false,
      model: true,
      type: "ranges"
    }, {
      id: "11",
      val: "product.type",
      rights: [1, 2, 3],
      label: null,
      title: "Génériques / Princeps",
      lbl: "Génériques / Princeps",
      checked: false,
      model: true,
      type: "generic"
    }, getCategoryForAgg(0), getCategoryForAgg(1), getCategoryForAgg(2), getCategoryForAgg(3), getCategoryForAgg(4), getCategoriesLaboratoryProductForAgg(0), getCategoriesLaboratoryProductForAgg(1), getCategoriesLaboratoryProductForAgg(2), getCategoriesLaboratoryProductForAgg(3), getCategoriesLaboratoryProductForAgg(4), getCategoriesLaboratoryHealthcenterForAgg(0), getCategoriesLaboratoryHealthcenterForAgg(1), getCategoriesLaboratoryHealthcenterForAgg(2), getCategoriesLaboratoryHealthcenterForAgg(3), getCategoriesLaboratoryHealthcenterForAgg(4), {
      id: "12",
      val: "organization.id",
      rights: [1, 3],
      label: null,
      title: "Pharmacies",
      lbl: "Pharmacies",
      checked: false,
      model: true,
      type: "pharmas"
    }, {
      id: "13",
      val: "item.operator.id",
      rights: [2],
      rankNeeded: [1, 2],
      label: null,
      title: "Opérateurs",
      lbl: "Opérateurs",
      checked: false,
      model: true
    }, {
      id: "14",
      val: "item.operator.rank_id",
      rights: [1, 2, 3],
      rankNeeded: [1, 2],
      label: null,
      title: "Métiers",
      lbl: "Métiers",
      checked: false,
      model: true
    }, {
      id: "3",
      val: "product.vat",
      rights: [1, 2, 3],
      label: null,
      title: "TVA",
      lbl: "TVA",
      checked: false,
      model: true,
      type: "vat"
    }, {
      id: "15",
      val: "product.list",
      rights: [1, 2, 3],
      label: null,
      title: "Listes de Délivrance",
      lbl: "Délivrance",
      checked: false,
      model: true,
      type: "list"
    }, {
      id: "16",
      val: "organization.geo.region",
      rights: [1, 3],
      label: null,
      title: "Régions",
      lbl: "Régions",
      checked: false,
      model: true,
      type: "region"
    }, {
      id: "17",
      val: "organization.geo.departement",
      rights: [1, 3],
      label: null,
      title: "Départements",
      lbl: "Départements",
      checked: false,
      model: true,
      type: "departement"
    }, {
      id: "18",
      val: "organization.geo.city",
      rights: [1, 3],
      label: null,
      title: "Villes",
      lbl: "Villes",
      checked: false,
      model: true,
      type: "city"
    }, {
      id: "19",
      val: "organization.geo.uga",
      rights: [1, 3],
      label: null,
      title: "UGA",
      lbl: "UGA",
      checked: false,
      model: true,
      type: "uga"
    }, {
      id: "20",
      val: "organization.group.id",
      rights: [1],
      label: null,
      title: "Groupements",
      lbl: "Groupements",
      checked: false,
      model: true,
      type: "groups"
    }, {
      id: "21",
      val: "organization.lgo",
      rights: ["Administrator"],
      label: null,
      title: "LGO",
      lbl: "LGO",
      checked: false,
      model: true,
      type: "lgo"
    }, {
      id: "22",
      val: "item.customer.consumer.information.gender",
      rights: [1, 2, 3],
      rankNeeded: [1, 2],
      label: null,
      title: "Sexes",
      lbl: "Sexes",
      checked: false,
      model: true,
      stateRestriction: "app.statistics.salesAnalysis"
    }, {
      id: "23",
      val: "item.customer.information.fund_complementary",
      rights: [1, 2, 3],
      rankNeeded: [1, 2],
      label: null,
      title: "Mutuelles",
      lbl: "Mutuelles",
      checked: false,
      model: true,
      stateRestriction: "app.statistics.salesAnalysis"
    }, {
      id: "24",
      val: "item.customer.prescriptor.code",
      rights: [2],
      rankNeeded: [1, 2],
      label: null,
      title: "Médecins",
      lbl: "Médecins",
      checked: false,
      model: true,
      stateRestriction: "app.statistics.salesAnalysis"
    }, {
      id: "25",
      val: "item.customer.consumer.information.birth_date",
      rights: [1, 2, 3],
      rankNeeded: [1, 2],
      label: null,
      title: "Ages",
      lbl: "Ages",
      checked: false,
      model: true,
      stateRestriction: "app.statistics.salesAnalysis"
    }, {
      id: "27",
      val: "warehouse.id",
      rights: [1],
      label: null,
      title: "Agences",
      lbl: "Agences",
      checked: false,
      model: true,
      type: "warehouse",
      stateRestriction: "app.disruption.supplying"
    }, {
      id: "28",
      val: "item.state.code",
      rights: [1],
      label: null,
      title: "Code erreur",
      lbl: "Code erreur",
      checked: false,
      model: true,
      stateRestriction: "app.disruption.supplying"
    }, {
      id: "29",
      val: "item.state.message",
      rights: [1],
      label: null,
      title: "Message d'erreur",
      lbl: "Message d'erreur",
      checked: false,
      model: true,
      stateRestriction: "app.disruption.supplying"
    }, {
      id: "30",
      val: "product.lppr",
      rights: [1],
      label: null,
      title: "LPPR",
      lbl: "LPPR",
      checked: false,
      model: true
    }, {
      id: "31",
      val: "product.is_cold",
      rights: ["Administrator"],
      label: null,
      title: "Froid",
      lbl: "Froid",
      checked: false,
      model: true
    }],
    datePresetFunc: function datePresetFunc(substract, type) {
      var from = moment().subtract(substract, type);
      return {
        from: from.toDate(),
        to: new Date()
      };
    },
    queryRebase: function queryRebase() {
      this.query = {
        must: JSON.parse(JSON.stringify(querySkeleton)),
        should: JSON.parse(JSON.stringify(querySkeleton)),
        date: {
          pluralize: "e",
          name: "Période de Recherche",
          slug: "date",
          from: dateFrom,
          to: moment()
        }
      };
    },
    buildquery: function buildquery(usrquery, date, fileExport) {
      var query = $q.defer();
      var obj = {};
      environmentService.getEnvironment().then(function (env) {
        var currentEnv = env;
        obj.startDatetime = moment(date.from).format("YYYY-MM-DD");
        obj.endDatetime = moment(date.to).format("YYYY-MM-DD");

        if (fileExport) {
          obj["export"] = true;
        }

        var types = ["should", "must"];

        if (currentEnv && currentEnv.accessPointType === 2) {
          obj.must = {};
          obj.must.healthcenter = [currentEnv.entity.cip];
        }

        angular.forEach(types, function (type) {
          // geographic filters
          obj[type] = typeof obj[type] === "undefined" ? {} : obj[type];

          if (usrquery[type].region.selected.length) {
            obj[type].region = usrquery[type].region.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].departement.selected.length) {
            obj[type].departement = usrquery[type].departement.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].operator.selected.length) {
            obj[type].operator = usrquery[type].operator.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].uga.selected.length) {
            obj[type].uga = usrquery[type].uga.selected.map(function (currentValue) {
              return currentValue.name;
            });
          }

          if (usrquery[type].city.selected.length) {
            obj[type].city = usrquery[type].city.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].pharmas !== undefined && usrquery[type].pharmas.selected !== undefined && usrquery[type].pharmas.selected.length) {
            obj[type].healthcenter = usrquery[type].pharmas.selected.map(function (currentValue) {
              return parseInt(currentValue.cip);
            });
          }

          if (usrquery[type].groups !== undefined && usrquery[type].groups.selected !== undefined && usrquery[type].groups.selected.length) {
            obj[type].groups = usrquery[type].groups.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].operator !== undefined && usrquery[type].operator.selected !== undefined && usrquery[type].operator.selected.length) {
            obj[type].operator = usrquery[type].operator.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].user !== undefined && usrquery[type].user.selected !== undefined && usrquery[type].user.selected.length) {
            obj[type].user = usrquery[type].user.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].laboratory.selected.length) {
            obj[type].laboratory = usrquery[type].laboratory.selected.map(function (currentValue) {
              return currentValue.id && currentValue.id !== "null" ? currentValue.id : "000000";
            });
          }

          if (usrquery[type].category.selected.length) {
            obj[type].category = usrquery[type].category.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].categoriesLaboratoryProduct.selected.length) {
            obj[type].categoriesLaboratoryProduct = usrquery[type].categoriesLaboratoryProduct.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].categoriesLaboratoryHealthcenter.selected.length) {
            obj[type].categoriesLaboratoryHealthcenter = usrquery[type].categoriesLaboratoryHealthcenter.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].categoryMulti.selected.length) {
            obj[type].categoryMulti = usrquery[type].categoryMulti.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].refund.selected.length) {
            obj[type].refund = usrquery[type].refund.selected.map(function (currentValue) {
              return currentValue.value;
            });
          }

          if (usrquery[type].tva.selected.length) {
            obj[type].tax = usrquery[type].tva.selected.map(function (currentValue) {
              return currentValue.value;
            });
          }

          if (usrquery[type].deliv.selected.length) {
            obj[type].list = usrquery[type].deliv.selected.map(function (currentValue) {
              return currentValue.value;
            });
          }

          if (usrquery[type].salesPriceSlices.selected.length) {
            obj[type].salesPriceSlices = usrquery[type].salesPriceSlices.selected.map(function (currentValue) {
              return currentValue.value;
            });
          }

          if (usrquery[type].gen.selected.length) {
            var prodType = []; // Système différent des autres car nous pouvons
            // avoir des int et des array

            angular.forEach(usrquery[type].gen.selected, function (currentValue) {
              // Si la valeur est un array alors l'extraire
              if (Array.isArray(currentValue.value)) {
                angular.forEach(currentValue.value, function (currentArrayValue) {
                  prodType.push(currentArrayValue);
                });
              } else {
                prodType.push(currentValue.value);
              }
            });
            obj[type].prodType = prodType;
          } // Filtres quantitatifs


          if (usrquery[type].generic.selected.length) {
            service.hasFilter = true;
            obj[type].generic = usrquery[type].generic.selected.map(function (currentValue) {
              return currentValue.id;
            });
          } // Filtres quantitatifs


          if (usrquery[type].segment.selected.length) {
            obj[type].segment = usrquery[type].segment.selected.map(function (currentValue) {
              return currentValue.id;
            });
          } // healthcenters filter


          if (usrquery[type].segmentHealthcenter.selected.length) {
            obj[type].segmentHealthcenter = usrquery[type].segmentHealthcenter.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].segmentProduct.selected.length) {
            obj[type].segmentProduct = usrquery[type].segmentProduct.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].population.min || usrquery[type].population.max) {
            obj[type].population = {};
          }

          if (usrquery[type].population.min) {
            obj[type].population.gte = usrquery[type].population.min;
          }

          if (usrquery[type].population.max) {
            obj[type].population.lte = usrquery[type].population.max;
          }

          if (usrquery[type].stocks.min || usrquery[type].stocks.max) {
            obj[type].stockrange = {};
          }

          if (usrquery[type].stocks.min) {
            obj[type].stockrange.gte = usrquery[type].stocks.min;
          }

          if (usrquery[type].stocks.max) {
            obj[type].stockrange.lte = usrquery[type].stocks.max;
          }

          if (usrquery[type].product.selected.length) {
            obj[type].product = usrquery[type].product.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].wholesaler.selected.length) {
            obj[type].wholesaler = usrquery[type].wholesaler.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].warehouse.selected.length) {
            obj[type].warehouse = usrquery[type].warehouse.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }

          if (usrquery[type].ranges && usrquery[type].ranges.selected.length) {
            service.hasFilter = true;
            obj[type].ranges = usrquery[type].ranges.selected.map(function (currentValue) {
              return currentValue.id;
            });
          }
        });
        query.resolve(obj);
        return query.promise;
      });
      return query.promise;
    },
    buildSubstituteQuery: function buildSubstituteQuery(usrquery) {
      var query = $q.defer();
      var obj = {};
      obj.must = {};
      obj.startDatetime = moment(usrquery.date.from).format("YYYY-MM-DD");
      obj.endDatetime = moment(usrquery.date.to).format("YYYY-MM-DD");

      if (typeof usrquery.paracetamol !== "undefined") {
        obj.paracetamol = usrquery.paracetamol.selected;
      }

      if (typeof usrquery.lab !== "undefined" && usrquery.lab.selected.length) {
        obj.laboratory = usrquery.lab.selected.map(function (currentValue) {
          return currentValue.id;
        });
      }

      if (usrquery.must.pharmas !== undefined && usrquery.must.pharmas.selected !== undefined && usrquery.must.pharmas.selected.length) {
        obj.must.healthcenter = usrquery.must.pharmas.selected.map(function (currentValue) {
          return parseInt(currentValue.cip);
        });
      }

      query.resolve(obj);
      return query.promise;
    },
    hasFilters: function hasFilters() {
      return service.hasFilter;
    }
  };
  return service;
}

angular.module("app.statistics").factory("StatisticsQueryService", StatisticsQueryService);