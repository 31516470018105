"use strict";
/**
 * Patient detail controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} patientVaccinationService - Vaccination service.
 * @param {object} patientVaccinationHistoryService - Vaccination service.
 * @param {object} patientUtilitiesService - The patient utilities service.
 * @param {object} customerService - The customer service.
 * @param {object} notifyUserService - The notifyUserService.
 */

PatientVaccinationController.$inject = ["$scope", "$stateParams", "patientVaccinationService", "patientVaccinationHistoryService", "patientUtilitiesService", "customerService", "notifyUserService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function PatientVaccinationController($scope, $stateParams, patientVaccinationService, patientVaccinationHistoryService, patientUtilitiesService, customerService, notifyUserService) {
  $scope.timelineData;
  $scope.loading = true;
  $scope.patient;
  $scope.lastRangeInvolved;
  $scope.vaccinationScheduleTitle = {
    title: {
      text: "Calendrier vaccinal",
      back: {
        state: "app.patients.details",
        tooltip: "Retour aux details du patient."
      }
    }
  };
  var vaccinationUtilities = patientVaccinationHistoryService;
  var vaccinationSchedule;
  var patientVaccinationsHistory;
  var repeatableVaccinations;
  loader();
  /**
   * Loader function.
   *
   * @returns {void}
   */

  function loader() {
    Promise.all([readPatient(), getVaccinations(), getPatientVaccinationsHistory()]).then(function () {
      $scope.timelineData = buildVaccinationForTimeLine(vaccinationSchedule, patientVaccinationsHistory);
      $scope.lastRangeInvolved = lastInvolvedVaccinationRange($scope.timelineData);
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue, veuillez réessayer");
    })["finally"](function () {
      $scope.loading = false;
      $scope.$apply();
      setTimeout(function () {
        scrollToPatientRange();
      }, 1000);
    });
  }
  /**
   * Read the customer.
   *
   * @returns {Promise<void>}
   */


  function readCustomer() {
    return customerService.readCustomer($stateParams.patientId).then(function (customer) {
      customer.information.age = patientUtilitiesService.ageFromBirthDate(customer.information.birth_date);
      $scope.patient = customer;
    });
  }
  /**
   * Read the customer beneficiary.
   *
   * @returns {Promise<void>}
   */


  function readCustomerBeneficiary() {
    return customerService.readCustomerBeneficiary($stateParams.patientId, $stateParams.beneficiaryId).then(function (beneficiary) {
      beneficiary.information.age = patientUtilitiesService.ageFromBirthDate(beneficiary.information.birth_date);
      $scope.patient = beneficiary;
    });
  }
  /**
   * Read the patient passed in stateParams.
   *
   * @returns {Promise<void>}
   */


  function readPatient() {
    if ($stateParams.beneficiaryId) {
      return readCustomerBeneficiary();
    }

    return readCustomer();
  }
  /**
   * Read the vaccinations.
   *
   * @returns {Promise<void>}
   */


  function getVaccinations() {
    return patientVaccinationService.getVaccinations().then(function (vaccinations) {
      vaccinationSchedule = vaccinationUtilities.createVaccinationSchedule(vaccinations);
      repeatableVaccinations = vaccinationUtilities.getRepeatableVaccinations(vaccinations);
    });
  }
  /**
   * Fetch the patient vaccinations history.
   *
   * @returns {Promise<void>}
   */


  function getPatientVaccinationsHistory() {
    return patientVaccinationService.getPatientVaccinationHistory($stateParams.patientId, $stateParams.beneficiaryId ? {
      beneficiary_id: $stateParams.beneficiaryId
    } : null).then(function (patientVaccinations) {
      patientVaccinationsHistory = patientVaccinations;
    });
  }
  /**
   * Build the vaccination schedule for the timeline.
   *
   * @param {Array} vaccinationRanges - The vaccination ranges.
   * @param {Array} patientVaccination - The patient vaccination list.
   *
   * @returns {Array}
   */


  function buildVaccinationForTimeLine(vaccinationRanges, patientVaccination) {
    var tightenedVaccinationRange = vaccinationUtilities.tightVaccinationRange(vaccinationRanges);

    if (repeatableVaccinations.length) {
      repeatableVaccinations.forEach(function (repeatableVaccination) {
        if (vaccinationUtilities.isConsumerInvolvedByRepetitionRange(repeatableVaccination, $scope.patient.information.birth_date)) {
          var repeatedSchedule = repeatableVaccination.schedules[repeatableVaccination.schedules.length - 1];
          tightenedVaccinationRange.push({
            repeatEvery: vaccinationUtilities.vaccinationScheduleRepetitionValue(repeatedSchedule),
            injections: [{
              vaccinations: [repeatableVaccination]
            }]
          });
        }
      });
    }

    var mappedVaccinations = tightenedVaccinationRange.map(function (vaccinationRange) {
      return mapVaccinationRangeWithPatient(vaccinationRange, patientVaccination);
    });
    return mappedVaccinations.map(function (vaccinationRange, index) {
      var isRangeDone = isRangeCompleted(vaccinationRange);
      return {
        from: vaccinationRange.from,
        to: index !== mappedVaccinations.length - 1 ? vaccinationRange.to : 9999,
        badgeClass: isRangeDone === null ? "default-badge" : isRangeDone ? "done-badge" : "not-done-badge",
        badgeIconClass: isRangeDone === null ? "" : isRangeDone ? "check" : "close",
        title: vaccinationRange.repeatEvery ? vaccinationUtilities.formatVaccinationRepetitionLabel(vaccinationRange.repeatEvery) : vaccinationUtilities.formatVaccinationAgeRange(vaccinationRange),
        injections: vaccinationRange.injections.map(function (injection) {
          return {
            icon: injection.done === null ? "" : injection.done ? "check" : "close",
            labels: injection.vaccinations.map(function (vaccination) {
              return formatVaccinationName(vaccination);
            })
          };
        })
      };
    });
  }
  /**
   * Check whether all vaccine in a vaccination range is completed.
   *
   * @param {object} vaccinationRange - The vaccination range.
   *
   * @returns {boolean|null}
   */


  function isRangeCompleted(vaccinationRange) {
    var vaccinationNotInvolved = vaccinationRange.injections.filter(function (vaccination) {
      return vaccination.done === null;
    });

    if (vaccinationNotInvolved.length === vaccinationRange.injections.length) {
      return null;
    }

    return vaccinationRange.injections.every(function (vaccination) {
      return vaccination.done;
    });
  }
  /**
   * Merge the vaccinations object with the patient vaccination.
   *
   * @param {object} vaccinationRange - The involved vaccination range.
   * @param {Array} patientVaccinations - The patient vaccinations.
   *
   * @returns {object}
   */


  function mapVaccinationRangeWithPatient(vaccinationRange, patientVaccinations) {
    if (vaccinationRange.isRepeatable) {
      return vaccinationUtilities.mapVaccinationRepetitionWithPatient(vaccinationRange, patientVaccinations);
    }

    vaccinationRange.injections = vaccinationRange.injections.map(function (injection) {
      return _objectSpread(_objectSpread({}, injection), {}, {
        done: injection.vaccinations.every(function (vaccination) {
          return vaccinationUtilities.isVaccinationDone(vaccination, patientVaccinations);
        })
      });
    });
    return vaccinationRange;
  }
  /**
   * Compute the injection name with the injection short name if exists.
   *
   * @param {object} vaccination - The injection.
   *
   * @returns {string}
   */


  function formatVaccinationName(vaccination) {
    var vaccinationName = vaccination.name;

    if (vaccination.name !== vaccination.short_name) {
      vaccinationName += " (".concat(vaccination.short_name, ")");
    }

    return vaccinationName;
  }
  /**
   * Scroll to the last involved vaccination range.
   */


  function scrollToPatientRange() {
    var content = document.getElementById("scroll");
    var anchorElement = document.getElementById("anchor");

    if (!anchorElement || !content) {
      setTimeout(scrollToPatientRange, 500);
      return;
    }

    content.scrollTo({
      top: anchorElement.offsetTop,
      behavior: "smooth"
    });
  }
  /**
   * Get the last involed vaccination range.
   *
   * @param {Array} timeLineData - The timeline data.
   *
   * @returns {null|object}
   */


  function lastInvolvedVaccinationRange(timeLineData) {
    var involvedRanges = timeLineData.filter(function (vaccinationRange) {
      return vaccinationRange.badgeClass !== "default-badge";
    });

    if (!involvedRanges.length) {
      return null;
    }

    return involvedRanges[involvedRanges.length - 1];
  }
}

angular.module("app.patients").controller("patientVaccinationController", PatientVaccinationController);