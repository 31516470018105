"use strict";
/**
 * The patient information component.
 */

PatientInformationController.$inject = ["$scope", "$state", "customerService", "notifyUserService", "patientPathologyService"];
var PatientInformationComponent = {
  templateUrl: "app/main/patients/components/patient-information/patientInformation.html",
  bindings: {
    patient: "=",
    isBeneficiary: "<"
  },
  controller: PatientInformationController,
  controllerAs: "vm"
};
/**
 * Patient information controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} customerService - The customer service.
 * @param {object} notifyUserService - The notify user service.
 * @param {object} patientPathologyService - The patient pathology service.
 */

function PatientInformationController($scope, $state, customerService, notifyUserService, patientPathologyService) {
  var vm = this;
  $scope.updateIsDead = updateIsDead;
  $scope.redirectToBeneficiaryDetails = redirectToBeneficiaryDetails;
  $scope.redirectToInsuredDetails = redirectToInsuredDetails;
  $scope.getPathologies = getPathologies;
  $scope.redirectToPathologyCard = redirectToPathologyCard;
  $scope.handlePathologyDeleted = handlePathologyDeleted;
  $scope.handlePathologyAdd = handlePathologyAdd;
  $scope.selectedPathologies = [];

  if (vm.patient && !vm.patient.declared_information) {
    vm.patient.declared_information = {};
  }

  $scope.$watch("vm.patient", function (newValue) {
    if (newValue) {
      $scope.selectedPathologies = newValue.pathologies;

      if (newValue.primary_prescriptor) {
        notifyUserService.showSuccessToast("Votre patient ".concat(newValue.information.name, " a d\xE9clar\xE9 son m\xE9decin traitant via l'application Apodis sant\xE9"));
      }
    }
  });
  /**
   * Update customer is_dead attribute.
   *
   * @param {boolean} isDead - Whether the customer is dead.
   *
   * @returns {Promise}
   */

  function updateIsDead(isDead) {
    var params = {
      is_dead: isDead
    };

    if (vm.isBeneficiary) {
      return customerService.updateCustomerBeneficiary(vm.patient.customer.id, vm.patient.id, params)["catch"](function () {
        vm.patient.declared_information.is_dead = !isDead;
      });
    }

    return customerService.updateCustomer(vm.patient.id, params)["catch"](function () {
      vm.patient.declared_information.is_dead = !isDead;
    });
  }
  /**
   * Redirect to pathology details card.
   *
   * @param {object} pathology - The pathology.
   */


  function redirectToPathologyCard(pathology) {
    $state.go("app.patients.pathologies.details", {
      pathologyId: pathology.id
    });
  }
  /**
   * Delete the pathology from the customer.
   *
   * @param {object} $event - $event object.
   * @param {object} pathology - The pathology.
   */


  function handlePathologyDeleted($event, pathology) {
    $event.stopPropagation();
    patientPathologyService.detachCustomerPathology(vm.patient.id, pathology.id).then(function (customerPathologies) {
      vm.patient.pathologies = customerPathologies;
    });
  }
  /**
   * Create the pathology for the customer.
   *
   * @param {object} pathology - The pathology.
   */


  function handlePathologyAdd(pathology) {
    patientPathologyService.attachCustomerPathology(vm.patient.id, pathology.id).then(function (customerPathologies) {
      $scope.selectedPathology = null;
      vm.patient.pathologies = customerPathologies;
    });
  }
  /**
   * Get pathologies.
   *
   * @param {string} search - Search text.
   *
   * @returns {Promise}
   */


  function getPathologies(search) {
    var params = [];

    if (search) {
      params = [{
        name: "search",
        value: search
      }];
    }

    return patientPathologyService.indexPathologies(params);
  }
  /**
   * Redirect to the beneficiary insured details.
   *
   * @param {object} customer - The involved customer.
   */


  function redirectToInsuredDetails(customer) {
    $state.go("app.patients.details", {
      customerId: customer.id
    });
  }
  /**
   * Redirect to a given beneficiary details.
   *
   * @param {object} beneficiary - The involved beneficiary.
   */


  function redirectToBeneficiaryDetails(beneficiary) {
    $state.go("app.patients.details.beneficiaries.details", {
      beneficiaryId: beneficiary.id
    });
  }
}

angular.module("app.patients.components").component("patientInformation", PatientInformationComponent);