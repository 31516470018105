"use strict";
/**
 * User sellout detail grouping controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $window - $window.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} selloutFactory - Sellout factory.
 * @param {object} userSelloutService - User sellout service.
 * @param {object} environmentService - Environment service.
 * @param {object} notifyUserService - Notify user service.
 */

UserSelloutDetailGroupingController.$inject = ["$scope", "$stateParams", "$window", "$mdDialog", "selloutFactory", "userSelloutService", "environmentService", "notifyUserService"];

function UserSelloutDetailGroupingController($scope, $stateParams, $window, $mdDialog, selloutFactory, userSelloutService, environmentService, notifyUserService) {
  RefuseSelloutController.$inject = ["$scope", "$mdDialog", "sellout"];
  AcceptSelloutController.$inject = ["$scope", "$mdDialog", "environmentService", "sellout"];
  $scope.goBack = goBack;
  $scope.read = read;
  $scope.showDetail = showDetail;
  $scope.acceptSellout = acceptSellout;
  $scope.refuseSellout = refuseSellout;
  $scope.dataTableConfig = {
    dom: "<\"top\"f>rt<\"bottom\"<\"left\"<\"length\"l>><\"right\"<\"info\"i><\"pagination\"p>>>",
    pagingType: "full_numbers",
    autoWidth: false,
    responsive: true,
    searching: false,
    lengthChange: false,
    info: false,
    language: {
      paginate: {
        first: "Début",
        last: "Fin",
        previous: "Précédent",
        next: "Suivant"
      }
    }
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    $scope.loading = true;
    getEnvironment().then(read).then(readScore).then(function () {
      $scope.loading = false;
    });
  }
  /**
   * Get environment.
   *
   * @returns {Promise}
   */


  function getEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.environment = response;
      return response;
    });
  }
  /**
   * Read user sellout.
   *
   * @returns {Promise}
   */


  function read() {
    return userSelloutService.read($stateParams).then(function (response) {
      $scope.userSellout = response;
      $scope.image = userSelloutService.getSelloutImage($scope.userSellout.sellout);
      $scope.label = userSelloutService.getStatusLabel($scope.userSellout.sellout);
      $scope.userSellout.sellout.unitRewards.map(function (unitReward) {
        unitReward.teaser = selloutFactory.generateUnitTeaser(unitReward.gain, unitReward.remuneration);
        return unitReward.teaser;
      });
      $scope.userSellout.sellout.objectiveRewards.map(function (objectiveReward) {
        objectiveReward.teaser = selloutFactory.generateObjectiveTeaser(objectiveReward.gain, objectiveReward.remuneration, objectiveReward.goal, objectiveReward.objective, objectiveReward.startDate, objectiveReward.endDate, objectiveReward.objectiveMode);
        return objectiveReward.teaser;
      });
    });
  }
  /**
   * Read user sellout score.
   *
   * @returns {Promise}
   */


  function readScore() {
    return userSelloutService.readGroupingScore($stateParams).then(function (response) {
      $scope.score = response.score;
      return response;
    });
  }
  /**
   * Go back.
   */


  function goBack() {
    $window.history.back();
  }
  /**
   * Show detail.
   *
   * @param {object} event - The event object.
   * @param {object} healthcenter - The healthcenter object.
   */


  function showDetail(event, healthcenter) {
    var dataTableConfig = $scope.dataTableConfig;
    $mdDialog.show({
      controller: ["$scope", "$mdDialog", function controller($scope, $mdDialog) {
        $scope.healthcenter = healthcenter;
        $scope.dataTableConfig = dataTableConfig;

        $scope.close = function () {
          $mdDialog.cancel();
        };
      }],
      templateUrl: "app/main/users-sellout/views/detail/grouping/operator.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      fullscreen: $scope.customFullscreen,
      locals: {
        healthcenter: healthcenter,
        dataTableConfig: dataTableConfig
      }
    });
  }
  /**
   * Accept user sellout.
   *
   * @param {object} event - Event.
   * @param {object} sellout - Sellout.
   */


  function acceptSellout(event, sellout) {
    $mdDialog.show({
      controller: AcceptSelloutController,
      templateUrl: "app/main/users-sellout/views/dialogs/accept.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        sellout: sellout
      },
      clickOutsideToClose: true
    }).then(read);
  }
  /**
   * Refuse user sellout.
   *
   * @param {object} event - Event.
   * @param {object} sellout - Sellout.
   */


  function refuseSellout(event, sellout) {
    $mdDialog.show({
      controller: RefuseSelloutController,
      templateUrl: "app/main/users-sellout/views/dialogs/refuse.html",
      parent: angular.element(document.body),
      targetEvent: event,
      locals: {
        sellout: sellout
      },
      clickOutsideToClose: true
    }).then(read);
  }
  /**
   * Accept sellout controller.
   *
   * @param {object} $scope - $scope.
   * @param {object} $mdDialog - $mdDialog.
   * @param {object} environmentService - Environment service.
   * @param {object} sellout - Sellout.
   */


  function AcceptSelloutController($scope, $mdDialog, environmentService, sellout) {
    /**
     * Declaration of public methods
     */
    $scope.close = close;
    $scope.validate = validate;
    /**
     * Declaration of public objects
     */

    $scope.tile = sellout;
    $scope.loadingDistributions = {
      loading: true,
      loader: "Récupération des types de récompenses",
      empty: "Vous n'avez pas de type de récompenses."
    };
    loader();
    /**
     * Loader.
     */

    function loader() {
      getEnvironment();
    }
    /**
     * Get current environment
     *
     * @returns {Promise}
     */


    function getEnvironment() {
      return environmentService.getEnvironment().then(function (response) {
        $scope.environment = response;
        return response;
      });
    }
    /**
     * Validate.
     *
     * @returns {Promise}
     */


    function validate() {
      sellout.rewardDistributionId = $scope.rewardDistributionId;
      return userSelloutService.accept(event, sellout).then(function (response) {
        notifyUserService.showSimpleToast("Le challenge « " + sellout.name + " » a été accepté", {
          color: "success",
          hideDelay: 2000
        });
        return response;
      }).then(function (response) {
        $mdDialog.hide(sellout);
        return response;
      });
    }
    /**
     * Close.
     */


    function close() {
      $mdDialog.cancel();
    }
  }
  /**
   * Refuse sellout controller.
   *
   * @param {object} $scope - $scope.
   * @param {object} $mdDialog - $mdDialog.
   * @param {object} sellout - Sellout.
   */


  function RefuseSelloutController($scope, $mdDialog, sellout) {
    $scope.validate = validate;
    $scope.close = close;
    /**
     * Validate.
     */

    function validate() {
      sellout.comments = $scope.comments;
      userSelloutService.refuse(event, sellout).then(function (response) {
        notifyUserService.showSimpleToast("Le challenge « " + sellout.name + " » a été refusé", {
          color: "success",
          hideDelay: 2000
        });
        return response;
      }).then(function (response) {
        $mdDialog.hide(sellout);
        return response;
      });
    }
    /**
     * Close.
     */


    function close() {
      $mdDialog.cancel();
    }
  }
}

angular.module("app.users-sellout").controller("userSelloutDetailGroupingController", UserSelloutDetailGroupingController);