"use strict";
/**
 * App list item directive.
 *
 * @returns {object}
 */

function AppListItem() {
  return {
    restrict: "EA",
    template: "<div class='app-list-item md-white-bg' ng-transclude></div>",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appListItem", AppListItem);