"use strict";
/**
 * Disruption detail controller.
 *
 * @param {object} disruptionService - Disruption service.
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 */

DisruptionDetailController.$inject = ["disruptionService", "$scope", "$stateParams", "$state"];

function DisruptionDetailController(disruptionService, $scope, $stateParams, $state) {
  $scope.pagination = {
    order: "-start_date",
    limit: 10,
    page: 1
  };
  $scope.loaderOptions = {
    loading: true,
    loader: "Chargement des laboratoires partenaires",
    empty: "Aucun laboratoire partenaire"
  };
  $scope.analyze = analyze;
  loader();
  /**
   * The loading function
   */

  function loader() {
    $scope.loaderOptions.loading = true;
    getDisruptions();
  }
  /**
   * get disruptions
   */


  function getDisruptions() {
    var params = {
      id: $stateParams.laboId
    };
    disruptionService.getLaboratoryDisruptions(params).then(function (laboratory) {
      $scope.laboratory = laboratory;
      $scope.disruptions = laboratory.disruption.map(function (disruption) {
        if (disruption.start_date) {
          disruption.start_date = new Date(disruption.start_date);
        }

        if (disruption.end_date) {
          disruption.end_date = new Date(disruption.end_date);
        }

        return disruption;
      });
      $scope.loaderOptions.loading = false;
    });
  }
  /**
   * Analyze.
   *
   * @param {number} disruptionId - Disruption ID.
   */


  function analyze(disruptionId) {
    $state.go("app.disruption.list.detail.analysis", {
      id: disruptionId
    });
  }
}

angular.module("app.disruption").controller("disruptionDetailController", DisruptionDetailController);