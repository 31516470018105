"use strict";
/**
 * Inventory controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} StatisticsQueryService - The statistics query service.
 * @param {object} stocksService - The stock service.
 */

InventoryController.$inject = ["$scope", "StatisticsQueryService", "stocksService"];

function InventoryController($scope, StatisticsQueryService, stocksService) {
  $scope.configSearch = {
    event: "inventoryFilterChange",
    search: []
  };
  $scope.rawValues = StatisticsQueryService.values;
  $scope.toggleCb = toggleCb;
  $scope.indexByLabel = indexByLabel;
  $scope.existsFilter = existsFilter;
  $scope.getLimits = getLimits;
  $scope.getInventory = getInventory;
  $scope.addLaboratoryFilter = addLaboratoryFilter;
  $scope.tableParams = {
    page: 1,
    limit: 10,
    order: "-_source.quantity"
  };
  $scope.loaderInventory = {
    loading: true,
    loader: "Chargement de votre inventaire",
    empty: "Aucune donnée"
  };
  loader();
  /**
   * The loading function
   */

  function loader() {
    $scope.usrquery = StatisticsQueryService.query;
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      getInventory();
    });
  }
  /**
   * check or uncheck the checkbox
   *
   * @param {object} item - The item
   * @param {Array} list - The list
   */


  function toggleCb(item, list) {
    $scope.loaderInventory.loading = true;
    var idx = $scope.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      getInventory();
      $scope.loaderInventory.loading = false;
    });
  }
  /**
   * Get limits of the filters
   *
   * @param {Array} array - The array.
   * @returns {Array}
   */


  function getLimits(array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  }
  /**
   * index the filters by label
   *
   * @param {string} name - The name.
   * @param {Array} list - The list.
   * @returns {number}
   */


  function indexByLabel(name, list) {
    if (!name || !list) {
      return -1;
    }

    for (var index = 0, length = list.length; index < length; index++) {
      if (list[index].label === name) {
        return index;
      }
    }

    return -1;
  }
  /**
   * Check if filter exists
   *
   * @param {string} name - The name.
   * @param {Array} list - The list.
   * @returns {boolean}
   */


  function existsFilter(name, list) {
    if ($scope.genQuery) {
      return false;
    }

    return $scope.indexByLabel(name, list) > -1;
  }

  $scope.removeFilter = removeFilter;
  /**
   * Remove the filter
   *
   * @param {object} obj - The item
   * @param {Array} list - The list
   */

  function removeFilter(obj, list) {
    $scope.loaderInventory.loading = true;
    var index = list.indexOf(obj);

    if (index !== -1) {
      list.splice(index, 1);
      $scope.loading = true;
      StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
        $scope.query = query;
        getInventory();
        $scope.loaderInventory.loading = false;
      });
    }
  }
  /**
   * get inventory
   */


  function getInventory() {
    $scope.loaderInventory.loading = true;
    $scope.inventory = [];
    angular.merge($scope.query, $scope.tableParams);
    stocksService.getInventory($scope.query).then(function (inventory) {
      $scope.total = inventory.total;
      $scope.inventory = inventory.values;
      $scope.quantityTotal = inventory.quantityTotal;
      $scope.valueTotal = inventory.valueTotal;
      $scope.loaderInventory.loading = false;
    });
  }
  /**
   * Add laboratory to product filter on inventory page.
   *
   * @param {object} product - The product concerned.
   */


  function addLaboratoryFilter(product) {
    $scope.usrquery.must.laboratory.selected.push({
      name: product.laboratory_name,
      id: product.laboratory,
      type: "laboratory"
    });
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      getInventory();
    });
  }

  $scope.$on("inventoryFilterChange", function () {
    $scope.loaderInventory.loading = true;
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      getInventory();
    });
  });
}

angular.module("app.statistics.stocks").controller("inventoryController", InventoryController);