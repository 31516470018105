"use strict";
/**
 * Wholesaler service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

WholesalersService.$inject = ["apiClient"];

function WholesalersService(apiClient) {
  return {
    /**
     * Get top supplying.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getTopSupplying: function getTopSupplying(params) {
      return apiClient.get("/laboratories/".concat(params.id, "/wholesalers/getTopSupplying/").concat(params.date), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.aggregations;
      });
    },

    /**
     * Get count last month.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getCountLastMonth: function getCountLastMonth(params) {
      return apiClient.get("/laboratories/".concat(params.id, "/wholesalers/getCountLastMonth"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.aggregations;
      });
    },

    /**
     * Get disponibility dashboard.
     *
     * @returns {Promise}
     */
    getDisponibilityDashboard: function getDisponibilityDashboard() {
      return apiClient.get("/analysis/wholesalers/disponibility/dashboard", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get weak disponibility product.
     *
     * @returns {Promise}
     */
    getWeakDisponibilityProduct: function getWeakDisponibilityProduct() {
      return apiClient.get("/analysis/wholesalers/disponibility/weakDisponibilityProduct", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get weak disponibility laboratory.
     *
     * @returns {Promise}
     */
    getWeakDisponibilityLaboratory: function getWeakDisponibilityLaboratory() {
      return apiClient.get("/analysis/wholesalers/disponibility/weakDisponibilityLaboratory", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.dashboard").service("wholesalersService", WholesalersService);