"use strict";
/**
 * Grouping audit general infos service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

GroupmentAuditGeneralInfosService.$inject = ["apiClient"];

function GroupmentAuditGeneralInfosService(apiClient) {
  return {
    /**
     * List.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    allHealthcenters: function allHealthcenters(params) {
      return apiClient.get("/healthcenters/grouping/one/".concat(params.groupId, "/healthcenters/all"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.groupmentAudit").service("groupmentAuditGeneralInfosService", GroupmentAuditGeneralInfosService);