"use strict";
/**
 * User laboratory branding controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $rootScope - The angular $rootScope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $q - The angular $q object.
 * @param {string} apiurl - The api url constant.
 * @param {object} usersLaboratoryMenu - The menu for user laboratory.
 * @param {object} environmentService -  The environment service.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} bricksService - Bricks service.
 */

UserLaboratoryBrandingController.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "$q", "apiurl", "usersLaboratoryMenu", "environmentService", "laboratoriesService", "bricksService"];

function UserLaboratoryBrandingController($scope, $rootScope, $state, $stateParams, $q, apiurl, usersLaboratoryMenu, environmentService, laboratoriesService, bricksService) {
  $scope.goBack = goBack;
  $scope.goToState = goToState;
  $rootScope.currentState = $state.current.name;
  $scope.menuHoverIn = menuHoverIn;
  $scope.menuHoverOut = menuHoverOut;
  /**
   *
   * Declaration of public objects
   */

  $scope.apiurl = apiurl;
  /**
   *
   * Initialization of the loader
   */

  loader();
  /**
   * Load each method inside
   *
   * @returns {Promise}
   */

  function loader() {
    return getLaboratory().then(function () {
      return $q.all([getBranding(), getEnvironment(), getBricks()]);
    });
  }
  /**
   * Get laboratory.
   *
   * @returns {Promise}
   */


  function getLaboratory() {
    var query = {
      id: typeof $stateParams.laboratoryId === "string" ? parseFloat($stateParams.laboratoryId) : $stateParams.laboratoryId
    };
    return laboratoriesService.read(query).then(function (response) {
      $scope.laboratory = response;
      return response;
    });
  }
  /**
   * Get laboratory"s branding with conditions
   *
   * @returns {Promise}
   */


  function getBranding() {
    var query = {
      laboratoryId: typeof $stateParams.laboratoryId === "string" ? parseFloat($stateParams.laboratoryId) : $stateParams.laboratoryId
    };
    return laboratoriesService.readBrandingWithoutProducts(query).then(function (response) {
      if (response) {
        $scope.branding = response;
        $rootScope.laboratoryBranding = response;
        $scope.branding.menus = usersLaboratoryMenu.get();
        $rootScope.$broadcast("setBrandingMenus", $scope.branding);
        $scope.backgroundUrl = $scope.branding.background ? "url(" + $scope.apiurl + "/filemanager/image/render?id=" + $scope.branding.background.id + "&token=" + $scope.branding.background.token + ") no-repeat;" : "";
        $scope.branding.menus.forEach(function (myMenu) {
          myMenu.active = myMenu.state === $state.current.name;
        });
        $scope.laboratoryBranding = response;
        return response;
      }

      return null;
    });
  }
  /**
   * Load current environment
   *
   * @returns {Promise}
   */


  function getEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.environment = response;
      return response;
    }).then(function () {
      if ($scope.environment.entity.isGroupingValidated) {
        return getCondition();
      }

      return null;
    });
  }
  /**
   * Get condition of target laboratory
   *
   * @returns {Promise}
   */


  function getCondition() {
    var query = {
      laboratoryId: $stateParams.laboratoryId
    };
    return laboratoriesService.readConditions(query).then(function (response) {
      if (response) {
        $scope.laboratory.conditions = response[0];
        return response;
      }

      return null;
    });
  }
  /**
   * getBricks
   *
   * @returns {Promise}
   */


  function getBricks() {
    return bricksService.getBricks().then(function (response) {
      $scope.bricks = response;
      return response;
    });
  }
  /**
   * goBack
   *
   * Return to "app.usersLaboratory" $state
   */


  function goBack() {
    var query = {
      wholesalerId: laboratoriesService.getWholesalerId()
    };
    $state.go("app.usersLaboratory", query);
  }
  /**
   * Go to branding state
   *
   * @param {object}  menu - The menu.
   */


  function goToState(menu) {
    $rootScope.$broadcast("cart:close");
    $state.go(menu.state, menu);
    $scope.branding.menus.forEach(function (myMenu) {
      myMenu.active = false;
    });
    menu.active = true;
  }
  /**
   * Set menu active true
   *
   * @param {object}  menu - The menu
   */


  function menuHoverIn(menu) {
    menu.active = true;
  }
  /**
   * Set menu active false
   *
   * @param {object}  menu - The menu
   */


  function menuHoverOut(menu) {
    if (menu.state !== $state.current.name) {
      menu.active = false;
    }
  }

  $rootScope.$on("$stateChangeSuccess", function (event, state) {
    $rootScope.currentState = state.name;
  });
}

angular.module("app.users-laboratory").controller("userLaboratoryBrandingController", UserLaboratoryBrandingController);