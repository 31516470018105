"use strict";
/**
 * The app-button directive.
 *
 * @returns {object}
 */

function AppButton() {
  return {
    restrict: "E",
    scope: {
      icon: "@icon",
      label: "@label",
      tooltip: "@tooltip",
      variant: "@variant"
    },
    templateUrl: "app/main/directives/app-button/appButton.html",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appButton", AppButton);