"use strict";
/**
 * progress bar directive.
 *
 * @returns {object}
 */

function AppProgressBar() {
  return {
    restrict: "E",
    templateUrl: "app/main/directives/app-progress-bar/appProgressBar.html",
    scope: {
      progress: "=",
      max: "=?",
      displayValue: "@?"
    },
    controller: ["$scope", function controller($scope) {
      $scope.max = angular.isDefined($scope.max) ? $scope.max : 1;
      $scope.displayValue = angular.isDefined($scope.displayValue) ? $scope.displayValue : "none";
      $scope.progressRate = ($scope.progress || 0) / $scope.max;
      $scope.progressWidth = $scope.progressRate * 100 + "%";
    }]
  };
}

angular.module("app.directives").directive("appProgressBar", AppProgressBar);