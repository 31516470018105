"use strict";
/**
 * Direct offer configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

DirectOfferConfig.$inject = ["$stateProvider"];

function DirectOfferConfig($stateProvider) {
  $stateProvider.state("app.directOffer", {
    url: "/mon-offre-directe",
    views: {
      "content@app": {
        templateUrl: "app/main/direct-offer/views/home/home.html",
        controller: "directOfferHomeController"
      },
      params: {
        activeTab: null
      }
    }
  }).state("app.directOffer.francoEdit", {
    url: "/franco/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/direct-offer/views/francoEdit/francoEdit.html",
        controller: "directOfferFrancoEditController"
      }
    }
  }).state("app.directOffer.priceRangeEdit", {
    url: "/gamme-tarifaire/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/direct-offer/views/priceRangeEdit/priceRangeEdit.html",
        controller: "directOfferPriceRangeEditController"
      }
    }
  }).state("app.directOffer.pharmaMlParameterEdit", {
    url: "/parametres-pharmaml/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/direct-offer/views/pharmaMlParameterEdit/pharmaMlParameterEdit.html",
        controller: "directOfferParameterPharmaMlEditController"
      }
    }
  });
}

angular.module("app.directOffer", []).config(DirectOfferConfig);