"use strict";
/**
 * API client.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $http - $http.
 * @param {string} apiurl - API URL.
 * @param {object} sessionStorageService - Session storage service.
 *
 * @returns {object}
 */

apiClient.$inject = ["$rootScope", "$http", "apiurl", "sessionStorageService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function apiClient($rootScope, $http, apiurl, sessionStorageService) {
  /**
   * Host.
   *
   * @type {string}
   */
  var host = apiurl;
  /**
   * Apply prefix.
   *
   * @param {string} url - The URL to prefix.
   * @returns {string} The prefixed URL.
   */

  function applyPrefix(url) {
    var entityTypeId = $rootScope.environment.accessPointType;
    var prefixes = {
      1: "/laboratory",
      2: "/healthcenter",
      3: "/grouping",
      5: "/wholesaler"
    };
    return prefixes[entityTypeId] + url;
  }
  /**
   * Stringify parameters.
   *
   * @param {Array} parameters - The parameters to stringify.
   * @returns {string} The stringified parameters.
   */


  function stringifyParameters(parameters) {
    var finalParameters = parameters;

    if (!Array.isArray(parameters)) {
      finalParameters = Object.keys(parameters).map(function (name) {
        return {
          name: name,
          value: parameters[name]
        };
      });
    }

    return finalParameters.reduce(function (accumulator, current) {
      if (typeof current.value === "undefined") {
        return accumulator;
      }

      var separator = accumulator ? "&" : "?";
      return "".concat(accumulator).concat(separator).concat(current.name, "=").concat(current.value);
    }, "");
  }
  /**
   * Stringify includes.
   *
   * @param {Array} includes - The includes to stringify.
   * @returns {string} The stringified includes.
   */


  function stringifyIncludes(includes) {
    return "include=".concat(includes.join(","));
  }
  /**
   * Get defaults configuration for request.
   *
   * @returns {object} The defaults configuration.
   */


  function getDefaults() {
    var bearerToken = sessionStorageService.getBearerToken();
    return {
      headers: {
        Authorization: bearerToken,
        "Content-Type": "application/json;utf-8"
      },
      wrappers: {
        request: "data",
        response: "data"
      },
      isPrefixed: false,
      transformResponse: $http.defaults.transformResponse
    };
  }

  return {
    /**
     * Request.
     *
     * @param {object} customConfig - The config container.
     * @throws {Error} Response data must be provided.
     * @returns {Promise}
     */
    request: function request(customConfig) {
      var defaultConfig = getDefaults();

      var config = _objectSpread(_objectSpread({}, defaultConfig), customConfig);

      var fullUrl = config.url;

      if (config.isPrefixed) {
        fullUrl = applyPrefix(config.url);
      }

      if (config.parameters) {
        fullUrl += stringifyParameters(config.parameters);
      }

      if (config.includes) {
        fullUrl += config.parameters ? "&" : "?";
        fullUrl += stringifyIncludes(config.includes);
      }

      var request = {
        method: config.method,
        url: host + fullUrl,
        headers: config.headers,
        transformResponse: config.transformResponse
      };

      if (config.timeout) {
        request.timeout = config.timeout;
      }

      if (config.data) {
        request.data = config.data;
      }

      return $http(request).then(function (response) {
        var data = config.wrappers.response ? response.data[config.wrappers.response] : response.data;
        var meta = response.data ? response.data.meta : {};

        if (typeof data === "undefined") {
          throw new Error("".concat(request.method, " ").concat(request.url, ": Response data not found."));
        }

        return {
          data: data,
          meta: meta,
          status: {
            code: response.status,
            text: response.statusText
          },
          options: response.options
        };
      });
    },

    /**
     * Send.
     *
     * @param {string} method - The HTTP method.
     * @param {string} url - The URL to call.
     * @param {object} data - The data to send.
     * @param {object} customConfig - The config.
     * @returns {Promise}
     */
    send: function send(method, url, data, customConfig) {
      var config = customConfig || {};
      config.method = method;
      config.url = url;
      config.data = data;
      return this.request(config);
    },

    /**
     * Get.
     *
     * @param {string} url - The URL to call.
     * @param {object} customConfig - The config.
     * @returns {Promise}
     */
    get: function get(url, customConfig) {
      var config = customConfig || {};
      config.method = "GET";
      config.url = url;
      return this.request(config);
    },

    /**
     * Post.
     *
     * @param {string} url - The URL to call.
     * @param {object} data - The data to send.
     * @param {object} customConfig - The config.
     * @returns {Promise}
     */
    post: function post(url, data, customConfig) {
      var config = customConfig || {};
      config.method = "POST";
      config.url = url;
      config.data = data;
      return this.request(config);
    },

    /**
     * Put.
     *
     * @param {string} url - The URL to call.
     * @param {object} data - The data to send.
     * @param {object} customConfig - The config.
     * @returns {Promise}
     */
    put: function put(url, data, customConfig) {
      var config = customConfig || {};
      config.method = "PUT";
      config.url = url;
      config.data = data;
      return this.request(config);
    },

    /**
     * Patch.
     *
     * @param {string} url - The URL to call.
     * @param {object} data - The data to send.
     * @param {object} customConfig - The config.
     * @returns {Promise}
     */
    patch: function patch(url, data, customConfig) {
      var config = customConfig || {};
      config.method = "PATCH";
      config.url = url;
      config.data = data;
      return this.request(config);
    },

    /**
     * Delete.
     *
     * @param {string} url - The URL to call.
     * @param {object} customConfig - The config.
     * @param {object} data - The data.
     * @returns {Promise}
     */
    "delete": function _delete(url, customConfig, data) {
      var config = customConfig || {};
      config.method = "DELETE";
      config.url = url;
      config.data = data;
      return this.request(config);
    }
  };
}

angular.module("app.services").factory("apiClient", apiClient);