"use strict";
/**
 * Users transactions service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

UsersTransactionsService.$inject = ["apiClient"];

function UsersTransactionsService(apiClient) {
  return {
    /**
     * Get historic.
     *
     * @returns {Promise}
     */
    getHistoric: function getHistoric() {
      return apiClient.get("/entity/transaction/historic", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.users-transactions").service("usersTransactionsService", UsersTransactionsService);