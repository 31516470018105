"use strict";
/**
 * Dashboard laboratories sellin offers push controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 */

DashboardLaboratoriesSellinOffersPushController.$inject = ["$scope", "$state"];

function DashboardLaboratoriesSellinOffersPushController($scope, $state) {
  $scope.goToOffers = function () {
    $state.go("app.sellinOffer");
  };
}

angular.module("app.dashboard").controller("dashboardLaboratoriesSellinOffersPushController", DashboardLaboratoriesSellinOffersPushController);