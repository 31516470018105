"use strict";
/**
 * Statistics operator sales configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

StatisticsOperatorSalesConfig.$inject = ["$stateProvider"];

function StatisticsOperatorSalesConfig($stateProvider) {
  $stateProvider.state("app.statistics.operator-sales", {
    url: "/mes-ventes",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/statistics.html",
        controller: "StatisticsController as vm"
      },
      "statisticsContent@app.statistics.operator-sales": {
        templateUrl: "app/main/statistics/operator-sales/operator-sales.html",
        controller: "OperatorSalesController as vm"
      }
    },
    bodyClass: "dashboard"
  });
}

angular.module("app.statistics.operator-sales", []).config(StatisticsOperatorSalesConfig);