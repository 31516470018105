"use strict";
/**
 * Controller for wholesaler category edit.
 *
 * @param {object} $scope - Scope
 * @param {object} $state - State
 * @param {object} $stateParams - State params
 * @param {object} notifyUserService - The notify user service
 * @param {object} environmentService - Environment service
 * @param {object} wholesalerCategoryService - Wholesaler category service
 * @param {string} apiurl - API URL.
 * @param {object} productService - Product service.
 */

WholesalerCategoryEditController.$inject = ["$scope", "$state", "$stateParams", "notifyUserService", "environmentService", "wholesalerCategoryService", "apiurl", "productService"];

function WholesalerCategoryEditController($scope, $state, $stateParams, notifyUserService, environmentService, wholesalerCategoryService, apiurl, productService) {
  $scope.apiurl = apiurl;
  $scope.wholesalerCategoryId = $stateParams.id;
  $scope.wholesalerCategory = {};
  $scope.deleteProductFromWholesalerCategory = deleteProductFromWholesalerCategory;
  $scope.submit = submit;
  $scope.goToList = goToList;
  $scope.query = {
    productOrder: "product.name",
    limit: 10,
    page: 1,
    total: null,
    search: ""
  };
  $scope.loaderWholesalerCategoryOptions = {
    loading: true,
    loader: "Récupération de la catégorie...",
    empty: "Aucune donnée n'est disponible"
  };
  $scope.wholesalerCategoryProductSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "category.level4.id"],
    event: "addProductToWholesalerCategoryFromSearch",
    tag: "addProductToWholesalerCategoryFromSearch",
    submitLabel: "Choisir ces produits",
    searchPlaceHolder: "Rechercher les produits à ajouter à la catégorie"
  };
  $scope.importProductConfig = {
    entityName: "WholesalerCategory",
    callbackEvent: "addProductToWholesalerCategoryFromImport"
  };
  $scope.limitOptions = [5, 10, 15, 25, 50, 100, 250];
  loader();
  /**
   * Loader to load each method inside.
   */

  function loader() {
    environmentService.getEnvironment().then(function (env) {
      $scope.currentEnv = env;
      getWholesalerCategory();
    });
  }
  /**
   * Get the selected wholesaler category.
   *
   * @returns {Promise} The promise
   */
  // eslint-disable-next-line consistent-return


  function getWholesalerCategory() {
    if ($scope.wholesalerCategoryId) {
      $scope.loaderWholesalerCategoryOptions.loading = true;
      var wholesalerCategory = {
        wholesalerId: $scope.currentEnv.entityId,
        id: $scope.wholesalerCategoryId
      };
      return wholesalerCategoryService.read(wholesalerCategory).then(function (response) {
        $scope.wholesalerCategory = response;
        $scope.loaderWholesalerCategoryOptions.loading = false;
      });
    }

    $scope.wholesalerCategory = {
      wholesalerId: $scope.currentEnv.entityId,
      name: "",
      description: "",
      file: {}
    };
  }
  /**
   * On product search validation, get their informations.
   */


  $scope.$on("addProductToWholesalerCategoryFromSearch", function (event, query) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(query, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(query[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in query) {
      _loop(type);
    }

    getProductInformationByType(queryProduct);
  });
  /**
   * On healthcenter import validation, get healthcenters information.
   */

  $scope.$on("addProductToWholesalerCategoryFromImport", function (event, products) {
    if (products.length > 0) {
      var queryProduct = {
        product: []
      }; // Push each product ID found

      products.forEach(function (product) {
        queryProduct.product.push(Object.values(product)[0]);
      });
      getProductInformationByType(queryProduct);
    }
  });
  /**
   * Submit wholesaler category form
   */

  function submit() {
    $scope.wholesalerCategory.fileId = $scope.wholesalerCategory.file ? $scope.wholesalerCategory.file.id : null;
    var productIds = [];
    angular.forEach($scope.wholesalerCategory.products, function (product) {
      productIds.push(product.id);
    });
    $scope.wholesalerCategory.productIds = productIds;
    $scope.isSaving = true;

    if ($scope.wholesalerCategoryId) {
      updateWholesalerCategory($scope.wholesalerCategory).then(function () {
        $scope.isSaving = false;
        goToList();
      });
    } else {
      createWholesalerCategory($scope.wholesalerCategory).then(function () {
        $scope.isSaving = false;
        goToList();
      });
    }
  }
  /**
   * Create the wholesaler category.
   *
   * @param {object} wholesalerCategory - The wholesaler category
   *
   * @returns {Promise} The promise
   */


  function createWholesalerCategory(wholesalerCategory) {
    return wholesalerCategoryService.create(wholesalerCategory).then(function () {
      notifyUserService.showSuccessToast("La catégorie de produit a bien été créée.");
      $scope.goToList();
    }, function () {
      notifyUserService.showDangerToast("Erreur lors de la création de la catégorie de produits, veuillez réessayer.");
    });
  }
  /**
   * Update the wholesaler category.
   *
   * @param {object} wholesalerCategory - The wholesaler category
   *
   * @returns {Promise} The promise
   */


  function updateWholesalerCategory(wholesalerCategory) {
    return wholesalerCategoryService.update(wholesalerCategory).then(function () {
      notifyUserService.showSuccessToast("La catégorie de produit a bien été modifiée.");
      $scope.goToList();
    }, function () {
      notifyUserService.showDangerToast("Erreur lors de la mise à jour de la catégorie de produits, veuillez réessayer.");
    });
  }
  /**
   * Get product information from ElasticSearch.
   *
   * @param {object} queryProduct - Query for ElasticSearch.
   *
   * @returns {Promise} List of products.
   */


  function getProductInformationByType(queryProduct) {
    return productService.postPresentationByType(queryProduct).then(function (products) {
      if (!$scope.wholesalerCategory.products) {
        $scope.wholesalerCategory.products = [];
      } // Store in array the product list to make it easier to test


      var productArray = [];
      $scope.wholesalerCategory.products.map(function (item) {
        return productArray.push(item.id);
      }); // generating the same object than the database

      products.forEach(function (productItem) {
        // If the product is not attached to the wholesaler category yet
        if (productItem && productArray.indexOf(productItem.id) === -1) {
          $scope.wholesalerCategory.products.push({
            id: productItem.id,
            name: productItem.name,
            code: productItem.cip,
            laboratory: {
              name: productItem.laboratory
            }
          });
        }
      });
      return products;
    });
  }
  /**
   * Delete the product from wholesaler category.
   *
   * @param {event} event - The event.
   * @param {number} productId - The product ID.
   */


  function deleteProductFromWholesalerCategory(event, productId) {
    angular.forEach($scope.wholesalerCategory.products, function (product, index) {
      if (product.id === productId) {
        $scope.wholesalerCategory.products.splice(index, 1);
      }
    });
  }
  /**
   * Go to list
   */


  function goToList() {
    $state.go("app.wholesaler.categories");
  }
}

angular.module("wholesaler.category").controller("wholesalerCategoryEditController", WholesalerCategoryEditController);