"use strict";
/**
 * Grouping audit controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} groupmentAuditListService - Groupment audit list service.
 * @param {object} $state - $state.
 */

GroupmentAuditController.$inject = ["$scope", "groupmentAuditListService", "$state"];

function GroupmentAuditController($scope, groupmentAuditListService, $state) {
  /**
   * Get all the active groupments
   */
  $scope.getGroupments = getGroupments;
  $scope.auditGroup = auditGroup;
  /**
   * Retrieve the groupments
   */

  function getGroupments() {
    groupmentAuditListService.list().then(function (groups) {
      $scope.groups = groups;
      return groups;
    });
  }
  /**
   * Go to the detailed audit.
   *
   * @param {object} grouping - Grouping.
   */


  function auditGroup(grouping) {
    $state.go("app.groupmentAudit.detail.generalInfos", {
      groupId: grouping.id,
      group: grouping
    });
  }
  /**
   * Loader.
   */


  function loader() {
    getGroupments();
  }

  loader();
}

angular.module("app.groupmentAudit").controller("GroupmentAuditController", GroupmentAuditController);