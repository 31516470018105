"use strict";
/**
 * The app-thumbnail-description directive.
 *
 * @returns {object}
 */

function AppThumbnailDescription() {
  return {
    restrict: "E",
    scope: {
      variant: "@variant"
    },
    templateUrl: "app/main/directives/app-thumbnail/app-thumbnail-description/appThumbnailDescription.html",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appThumbnailDescription", AppThumbnailDescription);