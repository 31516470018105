"use strict";
/**
 * Marge audit grouping controller.
 *
 * @param {object} mdlService - MDL service
 * @param {object} environmentService - Environment service.
 */

MargeAuditGroupingController.$inject = ["mdlService", "environmentService"];

function MargeAuditGroupingController(mdlService, environmentService) {
  var vm = this;
  vm.startDate = moment().subtract(364, "days");
  vm.endDate = moment();
  vm.groupingId = null;
  environmentService.getEnvironment().then(function (env) {
    vm.currentEnv = env;
    vm.groupingId = vm.currentEnv.entityId;
    vm.getSmoothedDegressiveMargin();
  });
  vm.groupingSale = [];
  vm.groupingMargin = [];
  vm.groupingTotalMarginPharmacy = 0;
  vm.groupingTotalMarginNational = 0;
  vm.groupingTotalSalesPharmacy = 0;
  /**
   * Reload smoothed degressive margin after period change
   *
   * @param {object} date -
   */

  vm.reloadSmoothedDegressiveMargin = function reloadSmoothedDegressiveMargin(date) {
    vm.startDate = moment(date.startDate, "DD-MM-YYYY");
    vm.endDate = moment(date.endDate, "DD-MM-YYYY");
    vm.getSmoothedDegressiveMargin();
  };
  /**
   * Get all smoothed degressive margin
   */


  vm.getSmoothedDegressiveMargin = function getSmoothedDegressiveMargin() {
    vm.dateLabel = moment(vm.startDate).format("DD-MMM-YYYY") + " Au " + moment(vm.endDate).format("DD-MMM-YYYY");
    vm.getSmoothedDegressiveMarginPharmacy();
  };
  /**
   * Get smoothed degressive margin for the pharmacy
   */


  vm.getSmoothedDegressiveMarginPharmacy = function getSmoothedDegressiveMarginPharmacy() {
    vm.loadingMarge = true;
    var params = {
      startDate: vm.startDate,
      endDate: vm.endDate,
      groupingId: vm.groupingId,
      pharmacyId: null
    };
    mdlService.forAPeriod(params).then(function (response) {
      vm.marginGroupingByPriceRange = response.marginGroupingByPriceRange; // Calculation of the grouping total margin for pharmacy and national

      angular.forEach(vm.marginGroupingByPriceRange, function (values) {
        vm.groupingTotalMarginPharmacy += values.pharmacy.margin ? values.pharmacy.margin : 0;
        vm.groupingTotalMarginNational += values.national.margin ? values.national.margin : 0;
        vm.groupingTotalSalesPharmacy += values.pharmacy.salesHt ? values.pharmacy.salesHt : 0;
      }); // Calculation of sales and ranges for grouping

      angular.forEach(vm.marginGroupingByPriceRange, function (values, key) {
        vm.groupingSale[key] = {
          range: values.range,
          salesHt: values.pharmacy.salesHt ? Math.round(values.pharmacy.salesHt) : 0,
          buyHt: values.pharmacy.buyHt ? Math.round(values.pharmacy.buyHt) : 0,
          margin: values.pharmacy.margin ? Math.round(values.pharmacy.margin) : 0,
          quantity: values.pharmacy.quantity ? values.pharmacy.quantity : 0
        };
        vm.groupingMargin[key] = {
          range: values.range,
          percentPharmacy: values.pharmacy.margin ? Math.round(values.pharmacy.margin / vm.groupingTotalMarginPharmacy) * 100 : 0,
          percentNational: values.national.margin ? Math.round(values.national.margin / vm.groupingTotalMarginNational) * 100 : 0
        };
      });
      vm.amChartGrouping = vm.generateGroupingMargin();
      vm.loadingMarge = false;
    });
  };
  /**
   * Generate Grouping Margin graph - using amcharts
   *
   * @returns {object}
   */


  vm.generateGroupingMargin = function generateGroupingMargin() {
    return {
      groupingSale: {
        type: "serial",
        data: vm.groupingSale,
        marginLeft: 100,
        marginRight: 100,
        legend: {
          enabled: true
        },
        thousandsSeparator: " ",
        rotate: true,
        startDuration: 1,
        graphs: [{
          balloonText: "[[value]]€ - PFHT",
          fillAlphas: 1,
          valueField: "buyHt",
          type: "column",
          title: "PFHT"
        }, {
          balloonText: "[[value]] unités",
          fillAlphas: 1,
          valueField: "quantity",
          type: "column",
          title: "Quantité"
        }, {
          balloonText: "[[value]]€ - marge",
          fillAlphas: 1,
          valueField: "margin",
          type: "column",
          title: "Marge"
        }],
        categoryField: "range",
        categoryAxis: {
          gridPosition: "start",
          parseDates: false
        }
      },
      groupingMargin: {
        type: "radar",
        data: vm.groupingMargin,
        marginLeft: 100,
        marginRight: 100,
        legend: {
          enabled: true
        },
        valueAxes: [{
          axisAlpha: 0.15,
          unit: "%"
        }],
        startDuration: 1,
        graphs: [{
          balloonText: "[[value]]% - pharmacie [[category]]",
          bullet: "round",
          lineThickness: 2,
          fillAlphas: 0.2,
          valueField: "percentPharmacy",
          title: "Pharmacie"
        }, {
          balloonText: "[[value]]% - national [[category]]",
          bullet: "square",
          lineThickness: 2,
          fillAlphas: 0.2,
          valueField: "percentNational",
          title: "National"
        }],
        categoryField: "range"
      }
    };
  };
}

angular.module("app.statistics.salesAnalysis").controller("MargeAuditGroupingController", MargeAuditGroupingController);