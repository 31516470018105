"use strict";
/**
 * Dashboard group stock status controller.
 *
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 * @param {object} $timeout - $timeout.
 * @param {object} environmentService - Environment service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} StocksQueryService - Stocks query service.
 * @param {object} $stateParams - $stateParams.
 * @param {object} analysisService - Analysis service.
 */

DashboardGroupStockStatusController.$inject = ["$state", "$scope", "$timeout", "environmentService", "StatisticsQueryService", "StocksQueryService", "$stateParams", "analysisService"];

function DashboardGroupStockStatusController($state, $scope, $timeout, environmentService, StatisticsQueryService, StocksQueryService, $stateParams, analysisService) {
  $scope.loading = true;
  $scope.goToState = goToState;
  $scope.viewDetails = viewDetails;
  $scope.goBackFromStockDetails = goBackFromStockDetails;
  var groupListLaboGroup = [{
    value: 0,
    title: "Segments"
  }];
  var eur = d3.locale({
    decimal: ".",
    thousands: " ",
    grouping: [3],
    currency: ["", "€"],
    dateTime: "%a %b %e %X %Y",
    date: "%m/%d/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  });
  loader();
  /**
   * Loader.
   */

  function loader() {
    $scope.usrStockReal = JSON.parse(JSON.stringify(StatisticsQueryService.query));
    environmentService.getEnvironment().then(function (environment) {
      $scope.currentEnv = environment;
      $scope.LabID = environment.entity.id;

      if (!_.find($scope.usrStockReal.must.groups.selected, function (group) {
        return group.id === $scope.currentEnv.entity.id;
      })) {
        $scope.usrStockReal.must.groups.selected.push({
          id: $scope.currentEnv.entity.id,
          name: $scope.currentEnv.entity.name,
          type: "groups"
        });
      }

      StatisticsQueryService.buildquery($scope.usrStockReal, $scope.usrStockReal.date).then(function (query) {
        processStocksQuery(query, true);
        initTab();
      });
    });
  }

  $scope.amChartOptionsPie0w90 = {
    data: [],
    type: "pie",
    theme: "light",
    startDuration: 0,
    marginTop: 5,
    marginBottom: 0,
    marginLeft: 5,
    marginRight: 5,
    pullOutRadius: 10,
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    decimalSeparator: ",",
    unit: " EUR HT",
    categoryField: "title",
    valueField: "amount",
    colorField: "color",
    titleField: "title",
    precision: 0,
    labelsEnabled: false,
    balloonText: "<div>[[percents]]% ( [[value]] unités )</div>",
    balloon: {
      fixedPosition: true
    },
    legend: {
      enabled: false
    },
    "export": {
      enabled: false
    },
    responsive: {
      enabled: true
    }
  };
  /**
   * Go to state.
   *
   * @param {string} state -
   */

  function goToState(state) {
    $state.go(state);
  }
  /**
   * Init tab.
   */


  function initTab() {
    $scope.groupByList = groupListLaboGroup;
    $scope.groupByChart = $scope.groupByList[0];
    $scope.usrquery = StocksQueryService.query;
    $scope.query = StocksQueryService.buildQuery($scope.usrquery);
    launchSearch($scope.query);
  }
  /**
   * Launch search.
   *
   * @param {object} obj -
   */


  function launchSearch(obj) {
    analysisService.getLaboratoryStockByGroup($scope.LabID, $scope.groupByChart.value, obj).then(function (response) {
      $scope.loading = false;
      $scope.detailStocksProducts = response.hits.hits;
      $scope.detailStocksGeneral = response.aggregations;
      processDataForAmCharts();
    }, function () {
      $scope.loading = false;
      $scope.loadingChart = false;
    });
  }
  /**
   * Process data for AmCharts.
   */


  function processDataForAmCharts() {
    var colors = [{
      title: "< 10 jours",
      color: "#ff2c00"
    }, {
      title: "Entre 10 et 20 jours",
      color: "#ffca28"
    }, {
      title: "Entre 21 et 30 jours",
      color: "#ffe082"
    }, {
      title: "Entre 31 et 60 jours",
      color: "#ffecb3"
    }, {
      title: "Entre 61 et 90 jours",
      color: "#b3e5fc"
    }, {
      title: "> 90 jours",
      color: "#29b6f6"
    }, {
      title: "Stock dormant",
      color: "#4a148c"
    }];
    var dataRangeGrossAmount0w90 = [];
    $scope.legend = [];
    $scope.total = 0;
    angular.forEach($scope.detailStocksGeneral.range_days.buckets, function (datum) {
      var nbRange = datum.key;
      var value = 0;
      var data = {};

      if (nbRange < 4) {
        data = {
          range30days: "-" + nbRange + " mois",
          amount: datum.needs_amount.value,
          color: colors[nbRange].color,
          title: colors[nbRange].title
        };
        value = datum.needs_amount.value;
        dataRangeGrossAmount0w90.push(data);
      } else {
        value = datum.order_amount.value;
        data = {
          range30days: "-" + nbRange + " mois",
          amount: datum.order_amount.value,
          color: colors[nbRange].color,
          title: colors[nbRange].title
        };
        dataRangeGrossAmount0w90.push(data);
      }

      $scope.total += value;
      $scope.legend.push({
        color: colors[nbRange].color,
        title: colors[nbRange].title,
        amount: value
      });
    });
    $scope.amChartOptionsPie0w90.data = dataRangeGrossAmount0w90;
    $scope.loading = false;
    $scope.loadingChart = false;
  }

  $scope.form = {
    isBlurred: false,
    nbEl: {
      min: 2,
      max: 25,
      model: 5
    },
    by: "quantity",
    values: [{
      lbl: "Laboratoires",
      val: "product.laboratory",
      checked: true,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Produits",
      val: "product.presentation",
      checked: true,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Segments",
      val: "product.segment.value",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 1",
      val: "product.category.level0",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 2",
      val: "product.category.level1",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 3",
      val: "product.category.level2",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 4",
      val: "product.category.level3",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }, {
      lbl: "Catégories niv. 5",
      val: "product.category.level4",
      checked: false,
      model: true,
      rights: [1, 2, 3]
    }]
  };
  /**
   * Process stock query.
   */

  function processStocksQuery() {
    $timeout(function () {
      $scope.loading = true;
      angular.element(document.getElementById("realStockHome")).html("");
      StatisticsQueryService.buildquery($scope.usrStockReal, $scope.usrStockReal.date).then(function (query) {
        $scope.query = query;
        $scope.query.buckets = getBuckets();
        analysisService.getStockList($scope.query).then(function (response) {
          $scope.loading = false;
          $scope.elasticdata = response;
          $scope.stockDatas = response.data;
          $scope.totalStock = 0;
          angular.forEach($scope.stockDatas, function (data) {
            $scope.totalStock += data.value;

            if (data.drilldown && data.drilldown.length > 0) {
              getTotal(data.drilldown, data.value);
            }
          });
          loaderRealStockGroupDashboardBis();
        }, function () {
          $scope.loading = false;
        });
      });
    }, 1500);
  }
  /**
   * Get buckets.
   *
   * @returns {Array}
   */


  function getBuckets() {
    var buckets = [];
    angular.forEach($scope.form.values, function (data) {
      if (data.model && data.checked) {
        buckets.push({
          term: data.val,
          size: $scope.form.nbEl.model
        });
      }
    });
    return buckets;
  }
  /**
   * Loader real stock group dashboard bis.
   */


  function loaderRealStockGroupDashboardBis() {
    angular.forEach($scope.elasticdata.data, function (data) {
      if (data.drilldown && data.drilldown.length > 0) {
        getTotal(data.drilldown, data.value);
      }
    });
    $scope.tooltip = {
      config: {
        adjustX: "-65px",
        adjustY: "-45px",
        color: "#CCC",
        backgroundColor: "rgba(68, 68, 68, 0.85)"
      },
      display: function display(data) {
        var innerCircle = 0;
        angular.forEach($scope.elasticdata.data, function (data) {
          innerCircle += data.value;
        });
        angular.forEach($scope.elasticdata.data, function (data) {
          data.total = innerCircle;
          angular.forEach(data.drilldown, function (_data) {
            _data.total = data.value;
          });
        });
        var tooltip = document.getElementById(_this.tooltipId);
        tooltip.style.marginTop = this.config.adjustY;
        tooltip.style.color = this.config.color;
        tooltip.style.backgroundColor = this.config.backgroundColor;
        tooltip.innerHTML = this.format(data.data);
        d3.event.target.addEventListener("mousemove", function (event) {
          tooltip.style.left = event.layerX + event.layerX / 2 + "px";
          tooltip.style.top = event.layerY + event.layerY / 2 + "px";
          tooltip.style.zIndex = 9999;
        });
      },
      format: function format(data) {
        var value; // display unit type

        if ($scope.form.by === "price_ht") {
          value = eur.numberFormat("$,.2f")(data.value);
        } else {
          value = d3.format(" .3")(data.value) + " unités";
        }

        return "<table class='toolTip'>" + "<thead>" + "<tr md-row>" + "<th>Nom</th>" + "<th>Quantité</th>" + "</tr>" + "</thead>" + "<tbody>" + "<tr md-row>" + "<td md-cell>" + data.label + "</td>" + "<td md-cell>" + value + "</td>" + "</tr>" + "</tbody>" + "</table>";
      }
    };
    $scope.config = {
      containerId: "realStockGroupDashboardBis",
      width: angular.element(document.getElementById("realStockGroupDashboardBis")).width() - 64,
      height: angular.element(document.getElementById("realStockGroupDashboardBis")).height() - 64,
      data: $scope.elasticdata.data,
      label: function label() {
        return "";
      },
      value: "value",
      inner: "drilldown",
      transition: "none",
      transitionDuration: 0,
      donutRadius: 50,
      gradient: false,
      labelColor: "white",
      colors: d3.scale.category20c(),
      stroke: "#eee",
      strokeWidth: 1,
      drilldownTransition: "none",
      drilldownTransitionDuration: 0
    };
    var pie = document.getElementById($scope.config.containerId);

    if (pie) {
      $scope.pie = new psd3.Pie($scope.config);
    }

    $scope.form.isBlurred = false;
  }

  $scope.canGoBackFromStockDetails = false;
  /**
   * View details.
   *
   * @param {object} laboratory - Laboratory.
   */

  function viewDetails(laboratory) {
    $scope.laboActive = laboratory;
    $scope.canGoBackFromStockDetails = true;
    $scope.stockDatas = laboratory.drilldown;
  }
  /**
   * Go back from stock details.
   */


  function goBackFromStockDetails() {
    $scope.canGoBackFromStockDetails = false;
    $scope.stockDatas = $scope.elasticdata.data;
  }
  /**
   * Get total.
   *
   * @param {Array} data - Data.
   * @param {number} total - Total.
   */


  function getTotal(data, total) {
    angular.forEach(data, function (datum) {
      datum.total = total;

      if (datum.drilldown.length > 0) {
        getTotal(datum.drilldown, datum.value);
      }
    });
  }

  var psd3 = {};

  var _this = this;

  psd3.Graph = function (config) {
    this.config = config;
    this.defaults = {
      value: "value",
      inner: "inner",
      label: function label(item) {
        return item.data.value;
      },
      transition: "linear",
      transitionDuration: 1000,
      donutRadius: 0,
      gradient: false,
      colors: d3.scale.category20(),
      labelColor: "black",
      drilldownTransition: "linear",
      drilldownTransitionDuration: 0,
      stroke: "white",
      strokeWidth: 2,
      highlightColor: "orange"
    };

    for (var property in this.defaults) {
      if (Object.prototype.hasOwnProperty.call(this.defaults, property)) {
        if (!Object.prototype.hasOwnProperty.call(config, property)) {
          config[property] = this.defaults[property];
        }
      }
    }
  };

  psd3.Pie = function (config) {
    psd3.Graph.call(this, config);
    this.zoomStack = [];
    var pos = "top";

    if (this.config.heading !== undefined && this.config.heading.pos !== undefined) {
      pos = this.config.heading.pos;
    }

    if (pos === "top") {
      this.setHeading();
    }

    this.drawPie(config.data);

    if (pos === "bottom") {
      this.setHeading();
    }
  };

  psd3.Pie.prototype = Object.create(psd3.Graph.prototype);
  psd3.Pie.prototype.constructor = psd3.Pie;

  psd3.Pie.prototype.findMaxDepth = function (dataset) {
    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return 0;
    }

    var currentLevel;
    var maxOfInner = 0;

    for (var index = 0; index < dataset.length; index++) {
      var maxInnerLevel = this.findMaxDepth(dataset[index][this.config.inner]);

      if (maxOfInner < maxInnerLevel) {
        maxOfInner = maxInnerLevel;
      }
    }

    currentLevel = 1 + maxOfInner;
    return currentLevel;
  };

  psd3.Pie.prototype.setHeading = function () {
    if (this.config.heading !== undefined) {
      d3.select("#" + this.config.containerId).append("div").style("text-align", "center").style("width", String(this.config.width) + "px").style("padding-top", "20px").style("padding-bottom", "20px").append("strong").text(this.config.heading.text);
    }
  };

  psd3.Pie.prototype.mouseover = function (data) {
    $scope.tooltip.display(data);
    d3.select("#" + _this.tooltipId).classed("psd3Hidden", false); // over effect

    d3.select(data.path).style("fill", _this.config.highlightColor);
  };

  psd3.Pie.prototype.mouseout = function (data) {
    d3.select("#" + _this.tooltipId).classed("psd3Hidden", true);
    d3.select(data.path).style("fill", data.fill);
  };

  psd3.Pie.prototype.drawPie = function (dataset) {
    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return;
    }

    var _this = this;

    _this.arcIndex = 0;
    _this.width = 100;
    _this.height = 100;
    var svg = d3.select("#" + _this.config.containerId).append("svg").attr("id", _this.config.containerId + "_svg").attr("viewBox", "0 0 640 640").attr("preserveAspectRatio", "xMinYMin meet").append("g");
    _this.tooltipId = _this.config.containerId + "_tooltip";
    var tooltipDiv = d3.select("#" + _this.config.containerId).append("div").attr("id", _this.tooltipId).attr("class", "psd3Hidden  psd3Tooltip");
    tooltipDiv.append("p").append("span").attr("id", "value").text("100%"); // to contain pie circle

    var radius;

    if (_this.config.width > _this.config.height) {
      radius = _this.config.width / 2;
    } else {
      radius = _this.config.height / 2;
    }

    var innerRadius = _this.config.donutRadius;

    var maxDepth = _this.findMaxDepth(dataset);

    var outerRadius = innerRadius + (radius - innerRadius) / maxDepth;
    var radiusDelta = outerRadius - innerRadius;

    _this.draw(svg, radius, dataset, dataset, dataset.length, innerRadius, outerRadius, radiusDelta, 0, 360 * 22 / 7 / 180, [0, 0]);
  };

  psd3.Pie.prototype.customArcTween = function (data) {
    var start = {
      startAngle: data.startAngle,
      endAngle: data.startAngle
    };
    var interpolate = d3.interpolate(start, data);
    return function (item) {
      return data.arc(interpolate(item));
    };
  };

  psd3.Pie.prototype.textTransform = function (data) {
    return "translate(" + data.arc.centroid(data) + ")";
  };

  psd3.Pie.prototype.textTitle = function (data) {
    return data.data[_this.config.value];
  };

  psd3.Pie.prototype.draw = function (svg, totalRadius, dataset, originalDataset, originalDatasetLength, innerRadius, outerRadius, radiusDelta, startAngle, endAngle) {
    var _this = this;

    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return;
    }

    psd3.Pie.prototype.textText = function (data) {
      return _this.config.label(data);
    };

    var pie = d3.layout.pie();
    pie.sort(null);
    pie.value(function (data) {
      return data[_this.config.value];
    });
    pie.startAngle(startAngle).endAngle(endAngle);
    var arc = d3.svg.arc().innerRadius(innerRadius).outerRadius(outerRadius); // Set up groups

    _this.arcIndex = _this.arcIndex + 1;
    var clazz = "arc" + _this.arcIndex;

    var storeMetadataWithArc = function storeMetadataWithArc(data) {
      // eslint-disable-next-line no-invalid-this
      data.path = this; // eslint-disable-next-line no-invalid-this

      data.fill = this.fill;
      data.arc = arc;
      data.length = dataset.length;
    };

    var arcs = svg.selectAll("g." + clazz).data(pie(dataset)).enter().append("g").attr("class", "arc " + clazz).attr("transform", "translate(" + totalRadius + "," + totalRadius + ")");
    var gradient = svg.append("svg:defs").append("svg:linearGradient").attr("id", "gradient_" + _this.arcIndex).attr("x1", "0%").attr("y1", "0%").attr("x2", "100%").attr("y2", "100%").attr("spreadMethod", "pad");
    var startColor;
    var endColor;

    if (_this.config.gradient) {
      var index = 2 * _this.arcIndex;
      var endIndex = index + 1;
      startColor = _this.config.colors(index);
      endColor = _this.config.colors(endIndex);
    } else {
      startColor = endColor = _this.config.colors(this.arcIndex);
    }

    gradient.append("svg:stop").attr("offset", "0%").attr("stop-color", startColor).attr("stop-opacity", 1);
    gradient.append("svg:stop").attr("offset", "100%").attr("stop-color", endColor).attr("stop-opacity", 1); // Draw arc paths

    var paths = arcs.append("path").attr("fill", "url(" + $stateParams.brickSlug + "/dashboard#gradient_" + _this.arcIndex + ")").style("stroke", _this.config.stroke).style("stroke-width", _this.config.strokeWidth);
    paths.on("mouseover", _this.mouseover);
    paths.on("mouseout", _this.mouseout);
    paths.each(storeMetadataWithArc);
    paths.transition().duration(_this.config.transitionDuration).delay(_this.config.transitionDuration * (_this.arcIndex - 1)).ease(_this.config.transition).attrTween("d", _this.customArcTween);

    for (var _index = 0; _index < dataset.length; _index++) {
      if (dataset[_index][_this.config.inner] !== undefined) {
        _this.draw(svg, totalRadius, dataset[_index][_this.config.inner], originalDataset, originalDatasetLength, innerRadius + radiusDelta, outerRadius + radiusDelta, radiusDelta, paths.data()[_index].startAngle, paths.data()[_index].endAngle, arc.centroid(paths.data()[_index]));
      }
    }
  };
}

angular.module("app.home").controller("dashboardGroupStockStatusController", DashboardGroupStockStatusController);