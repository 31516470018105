"use strict";
/**
 * Plannogram controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $rootScope - The angular $rootScope object.
 * @param {object} $state - The angular $state object.
 * @param {object} bricksService - Bricks service.
 * @param {object} laboratoriesService - Laboratories service.
 * @param {object} communicationService - Communication service.
 * @param {string} apiurl - The api url.
 * @param {object} $window - The angular $window object.
 */

PlannogramController.$inject = ["$scope", "$rootScope", "$state", "bricksService", "laboratoriesService", "communicationService", "apiurl", "$window"];

function PlannogramController($scope, $rootScope, $state, bricksService, laboratoriesService, communicationService, apiurl, $window) {
  $scope.goBack = goBack;
  $scope.goTo = goTo;
  $scope.goToEvent = goToEvent;
  $scope.goToState = goToState;
  $scope.goToBrandingState = goToBrandingState;
  $scope.print = print;
  $scope.fullScreen = fullScreen;
  /**
   * goBack
   */

  function goBack() {
    var query = {
      wholesalerId: laboratoriesService.getWholesalerId()
    };
    $state.go("app.usersLaboratory", query);
  }
  /**
   * Get merchandising plans.
   */


  function getMerchandisingPlans() {
    var params = {
      laboratoryId: $scope.branding.laboratory.id,
      includes: ["files"]
    };
    communicationService.getMerchandisingPlans(params).then(function (plans) {
      $scope.merchandisingPlans = plans.map(function (plan) {
        plan.source = plan.files.length && plan.files[0].path ? "".concat(apiurl, "/images/").concat(plan.files[0].path) : "assets/images/sellouts/default-challenge-1080x600.jpg";
        return plan;
      });
    });
  }
  /**
   * Print the plan.
   *
   * @param {object} plan - The merchandising plan.
   */


  function print(plan) {
    $scope.popup = window.open(plan.source);
    $scope.popup.onbeforeunload = closePrint;
    $scope.popup.onafterprint = closePrint;
    $scope.popup.focus(); // Required for IE

    $scope.popup.print();
  }
  /**
   * Close print window.
   */


  function closePrint() {
    if ($scope.popup) {
      $scope.popup.close();
    }
  }
  /**
   * See the plan full screen.
   *
   * @param {object} plan - The merchandising plan.
   */


  function fullScreen(plan) {
    $window.open(plan.source, "_blank");
  }
  /**
   * Go to given state
   *
   * @param {string} state - The state to go.
   */


  function goToState(state) {
    $state.go(state);
  }
  /**
   * goToBrandingState
   *
   * @param {object}  menu - The menu.
   */


  function goToBrandingState(menu) {
    $state.go(menu.state, menu);
  }
  /**
   * goTo
   *
   * @param {object}  brick - The brick.
   */


  function goTo(brick) {
    $rootScope.$broadcast("sideNavConstruct", brick);
    bricksService.setCurrentBrick(brick);
  }
  /**
   * goToEvent
   *
   * @param {object} event - The event.
   */


  function goToEvent(event) {
    $state.go("app.calendar.detail", {
      id: event.id
    });
  }

  $scope.$watch("laboratoryBranding", function (branding) {
    if (branding) {
      $scope.branding = branding;
      getMerchandisingPlans();
    }
  });
}

angular.module("app.users-laboratory").controller("plannogramController", PlannogramController);