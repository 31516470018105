"use strict";
/**
 * Dashboard laboratories live sales controller.
 *
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} $timeout - $timeout.
 * @param {object} environmentService - Environment service.
 * @param {object} apodisLiveService - Apodis live service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} homeLaboratoryAnalysisService - Laboratory analysis service.
 * @param {object} gmap - gmap.
 */

DashboardLaboratoriesLiveSalesController.$inject = ["$state", "$scope", "$rootScope", "$timeout", "environmentService", "apodisLiveService", "StatisticsQueryService", "homeLaboratoryAnalysisService", "gmap"];

function DashboardLaboratoriesLiveSalesController($state, $scope, $rootScope, $timeout, environmentService, apodisLiveService, StatisticsQueryService, homeLaboratoryAnalysisService, gmap) {
  var scrolled = false;
  var salesServiceName;
  var stocksServiceName;
  $scope.showList = false;
  $scope.LabID = 0;
  $scope.loading = true;
  $scope.dataChartSerial = [];
  $scope.initiated = false;
  $scope.liveSales = [];
  $scope.liveBreaks = [];
  $scope.markers = [];
  /**
   * Loader.
   */

  function loader() {
    initTab();
  }
  /**
   * Init tab.
   */


  function initTab() {
    if ($scope.initiated === false) {
      environmentService.getEnvironment().then(function (env) {
        $scope.env = env;
        $scope.LabID = env.entity.id;

        if (env && env.entity) {
          launchStatisticsQueries();
        }

        $scope.initiated = true;
      });
    }
  }
  /**
   * Pin symbol.
   *
   * @param {string} color - Color.
   *
   * @returns {object}
   */


  function pinSymbol(color) {
    return {
      path: "M12.000001,0.182621c6.526171," + "0 11.81738,5.291209 11.81738," + "11.81738c0,6.525411 -5.291209" + ",11.81738 -11.81738,11.81738s" + "-11.81738,-5.291293 -11.81738" + ",-11.81738s5.290449,-11.81738" + " 11.81738,-11.81738z M6.82914" + "8,9.786405l2.965444,0l0,-2.95" + "7257c0,-0.407659 0.330178,-0." + "738597 0.737837,-0.738597l2.9" + "51349,0c0.406984,0 0.737078,0" + ".330854 0.737078,0.738597l0,2" + ".956582l2.949914,0c0.408418,0" + " 0.738597,0.330178 0.738597,0" + ".738597l0,2.955062c0,0.409178" + " -0.330178,0.740032 -0.738597" + ",0.740032l-2.949914,0l0,2.948" + "395c0,0.408418 -0.330178,0.73" + "8597 -0.737078,0.738597l-2.95" + "1349,0c-0.407659,0 -0.737837," + "-0.330178 -0.737837,-0.738597" + "l0,-2.948395l-2.965444,0c-0.4" + "08418,0 -0.739356,-0.330854 -" + "0.739356,-0.740032l0,-2.95506" + "2c0,-0.407743 0.330938,-0.737" + "922 0.739356,-0.737922z",
      fillColor: color,
      fillOpacity: 1,
      strokeColor: "white",
      strokeWeight: 1.5,
      id: "liveSalesMarker"
    };
  }
  /**
   * Pin symbol mini.
   *
   * @param {string} color - Color.
   *
   * @returns {object}
   */


  function pinSymbolMini(color) {
    return {
      path: "M12.000001,0.182621c6.526171," + "0 11.81738,5.291209 11.81738," + "11.81738c0,6.525411 -5.291209" + ",11.81738 -11.81738,11.81738s" + "-11.81738,-5.291293 -11.81738" + ",-11.81738s5.290449,-11.81738" + " 11.81738,-11.81738z M6.82914" + "8,9.786405l2.965444,0l0,-2.95" + "7257c0,-0.407659 0.330178,-0." + "738597 0.737837,-0.738597l2.9" + "51349,0c0.406984,0 0.737078,0" + ".330854 0.737078,0.738597l0,2" + ".956582l2.949914,0c0.408418,0" + " 0.738597,0.330178 0.738597,0" + ".738597l0,2.955062c0,0.409178" + " -0.330178,0.740032 -0.738597" + ",0.740032l-2.949914,0l0,2.948" + "395c0,0.408418 -0.330178,0.73" + "8597 -0.737078,0.738597l-2.95" + "1349,0c-0.407659,0 -0.737837," + "-0.330178 -0.737837,-0.738597" + "l0,-2.948395l-2.965444,0c-0.4" + "08418,0 -0.739356,-0.330854 -" + "0.739356,-0.740032l0,-2.95506" + "2c0,-0.407743 0.330938,-0.737" + "922 0.739356,-0.737922z",
      fillColor: color,
      fillOpacity: 1,
      strokeColor: "white",
      strokeWeight: 1.5,
      id: "liveSalesMarker"
    };
  }
  /**
   * Add marker on map.
   *
   * @param {object} data - Data.
   * @param {boolean} isStock - Is stock marker.
   */


  function addMarkerOnMap(data, isStock) {
    var latLng = new google.maps.LatLng(data.geo.location.lat, data.geo.location.lon);
    var marker = new google.maps.Marker({
      position: latLng,
      icon: pinSymbol("#27ae60"),
      animation: google.maps.Animation.Wo
    });
    data.dateTime = new Date();

    if (isStock) {
      marker.icon = pinSymbol("#e74c3c");
    }

    $timeout(function () {
      if (isStock) {
        marker.setIcon(pinSymbolMini("#e74c3c"));
      } else {
        marker.setIcon(pinSymbolMini("#27ae60"));
      }
    }, 5000);
    marker.setMap($scope.map);
    $scope.markers.push(marker);

    if ($scope.markers.length > 500) {
      $scope.markers[0].setMap(null);
      $scope.markers.splice(0, 1);
    }

    if ($scope.liveBreaks.length > 100) {
      $scope.liveBreaks = [];
    }

    if ($scope.liveSales.length > 100) {
      $scope.liveSales = [];
    }

    if (data.item.quantity <= 0) {
      $scope.liveBreaks.push(data);
    } else {
      $scope.liveSales.push(data);
    }

    updateScroll();
    $timeout(function () {
      if (!scrolled) {
        var sales = document.getElementsByClassName("sales")[0];
        var breaks = document.getElementsByClassName("breaks")[0];

        if (sales !== null) {
          sales.scrollTop = sales.scrollHeight;
        }

        if (breaks !== null) {
          breaks.scrollTop = breaks.scrollHeight;
        }
      }
    }, 0, false);
  }

  $scope.goToAudit = function (data) {
    $state.go("app.healthcenterAudit.detail.general", {
      id: data.organization.id
    });
  };
  /**
   * Update scroll.
   */


  function updateScroll() {
    if (!scrolled) {
      var sales = document.getElementsByClassName("sales")[0];
      var breaks = document.getElementsByClassName("breaks")[0];

      if (sales !== null) {
        sales.scrollTop = sales.scrollHeight;
      }

      if (breaks !== null) {
        breaks.scrollTop = breaks.scrollHeight;
      }
    }
  }
  /**
   * Initialize map.
   */


  function initMap() {
    if (document.getElementById("map-container-sales")) {
      $scope.map = new google.maps.Map(document.getElementById("map-container-sales"), {
        center: new google.maps.LatLng(47.3881, 11.7738),
        zoom: 6,
        minZoom: 0,
        gestureHandling: "cooperative",
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: true,
        disableDefaultUI: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.LEFT_TOP
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        fullscreenControl: false,
        styles: gmap
      });
    }
  }
  /**
   * On stock data.
   *
   * @param {object} stockData - Stock data.
   */


  function onStockData(stockData) {
    if (stockData.item.quantity <= 0) {
      addMarkerOnMap(stockData, true);
      $scope.$apply();
    }
  }
  /**
   * On sales data.
   *
   * @param {object} salesData - Sales data.
   */


  function onSalesData(salesData) {
    $scope.productSalesLiveId = salesData.product.id;
    addMarkerOnMap(salesData);
    $scope.$apply();
  }
  /**
   * Launch socket.
   *
   * @param {object} environment - Environment.
   */


  function launchSocket(environment) {
    salesServiceName = "sales_laboratory_" + environment.entity.id;
    stocksServiceName = "stock_laboratory_" + environment.entity.id;

    if (typeof apodisLiveService.socket._callbacks["$" + salesServiceName] === "undefined") {
      // Handler not present, install now
      apodisLiveService.register({
        service: salesServiceName
      });
    }

    if (typeof apodisLiveService.socket._callbacks["$" + stocksServiceName] === "undefined") {
      // Handler not present, install now
      apodisLiveService.register({
        service: stocksServiceName
      });
    }

    apodisLiveService.socket.on(stocksServiceName, onStockData);
    apodisLiveService.socket.on(salesServiceName, onSalesData);
  }
  /**
   * Loader sales analysis.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function loaderSalesAnalysis(query) {
    if (!query.must.laboratory || query.must.laboratory.length === 0) {
      query.must.laboratory = [];
      query.must.laboratory.push($scope.env.entity.id);
    }

    return Promise.all([salesHistory(query), salesByGeo(query)]);
  }
  /**
   * Sales history.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function salesHistory(query) {
    return homeLaboratoryAnalysisService.sales(query).then(function (response) {
      $scope.salesLoading = false;
      $scope.elasticdata = response;
      initMap();
    });
  }
  /**
   * Sales by geo.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function salesByGeo(query) {
    query.startDatetime = moment().month(0).date(0).format("YYYY-MM-DD");
    query.endDatetime = moment().format("YYYY-MM-DD");
    return homeLaboratoryAnalysisService.topByGeo(query).then(function (response) {
      $scope.topGeo = response.aggregations;
      launchSocket($scope.env);
      $scope.showList = true;
    }, function () {
      $scope.salesLoading = false;
    });
  }
  /**
   * Launch statistics queries.
   */


  function launchStatisticsQueries() {
    StatisticsQueryService.queryRebase();
    $scope.usrquery = StatisticsQueryService.query;
    $scope.usrquery.date = {
      from: moment().subtract(12, "months"),
      to: moment()
    };
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      $scope.query.interval = "1M";
      loaderSalesAnalysis($scope.query);
    });
  }

  $rootScope.$on("$stateChangeStart", function () {
    apodisLiveService.unregister(stocksServiceName, onStockData);
    apodisLiveService.unregister(salesServiceName, onSalesData);
  });
  loader();
}

angular.module("app.home").controller("dashboardLaboratoriesLiveSalesController", DashboardLaboratoriesLiveSalesController);