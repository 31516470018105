"use strict";
/**
 * Directive for Apodis bricks Menu.
 *
 * @returns {object} Directive elements
 */

ApodisDelegateBricksMenuController.$inject = ["$rootScope", "$scope", "$state", "apodisLiveService", "sidenavMenuFactory", "bricksService", "environmentService", "sessionStorageService", "apiurl"];

function ApodisDelegateBricksMenu() {
  return {
    restrict: "E",
    templateUrl: "app/main/directives/apodis-delegate-bricks-menu/apodisDelegateBricksMenu.html",
    controller: ApodisDelegateBricksMenuController
  };
}
/**
 * Controller for Apodis bricks menu.
 *
 * @param {object} $rootScope - Root scope
 * @param {object} $scope - Scope
 * @param {object} $state - State
 * @param {object} apodisLiveService - Apodis live service
 * @param {object} sidenavMenuFactory - Side nav menu factory
 * @param {object} bricksService - Bricks service
 * @param {object} environmentService - Environment service
 * @param {object} sessionStorageService - Session storage service.
 * @param {string} apiurl - Api URL
 */


function ApodisDelegateBricksMenuController($rootScope, $scope, $state, apodisLiveService, sidenavMenuFactory, bricksService, environmentService, sessionStorageService, apiurl) {
  $scope.setBackgroundColor = setBackgroundColor;
  $scope.setIcon = setIcon;
  $scope.goToState = goToState;
  $scope.currentState = $state.current.name;
  $scope.token = sessionStorageService.getToken();
  $scope.apiurl = apiurl;
  loader();
  /**
   * The loader function
   */

  function loader() {
    getDashboardMenu();
    loadEnvironment();
  }
  /**
   * Get the dashboard menu.
   *
   * @returns {Promise} The promise
   */


  function getDashboardMenu() {
    return sidenavMenuFactory.getDashboard().then(function (response) {
      $scope.dashboardMenu = response;
    });
  }
  /**
   * Load the current environment.
   *
   * @returns {Promise} The promise
   */


  function loadEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentEnv = response;
      loaderApodisLive(response);
      getBricks();
      return response;
    });
  }
  /**
   * Get the bricks.
   *
   * @returns {Promise} The promise
   */


  function getBricks() {
    return bricksService.getBricks().then(function (bricks) {
      var options = angular.fromJson(window.sessionStorage.getItem("payload")).options;
      $scope.hasBricksMenus = options.indexOf("bricks-menus") > -1;

      if (bricks.length && !$scope.hasBricksMenus) {
        $rootScope.showBricksMenu = false;
        $rootScope.showLeftMenu = true;
        bricksService.setCurrentBrick(bricks[0]);
        $rootScope.$broadcast("sideNavConstruct", bricks[0]); // Go the dashboard

        $state.go("app.dashboard", {
          brickSlug: bricks[0].slug.replace(".", "_")
        });
      } else {
        $scope.bricks = bricks;
      }

      return bricks;
    });
  }
  /**
   * Set the background color of the brick.
   *
   * @param {object} brick - The brick
   *
   * @returns {object} Css property background color
   */


  function setBackgroundColor(brick) {
    return {
      "background-color": brick.color
    };
  }
  /**
   * Set the icon of the brick.
   *
   * @param {object} brick - The brick
   *
   * @returns {string}
   */


  function setIcon(brick) {
    return brick.icon;
  }
  /**
   * Go to state.
   *
   * @param {object} brick - The brick
   */


  function goToState(brick) {
    $rootScope.showBricksMenu = false;
    $rootScope.showLeftMenu = true;
    switcherMenu(brick);
  }
  /**
   * The menu switcher, set the current brick.
   *
   * @param {object} brick - The brick
   */


  function switcherMenu(brick) {
    $rootScope.$broadcast("sideNavConstruct", brick);
    bricksService.setCurrentBrick(brick);
    $scope.menuItems = brick;
  }
  /**
   * Loader of apodis live.
   */


  function loaderApodisLive() {
    var options = {
      service: "reload.authorizations"
    };
    apodisLiveService.register(options);
    apodisLiveService.socket.on(options.service, function () {
      getBricks();
    });
  }
  /**
   * Get bricks when environment is loaded after a switch.
   */


  $rootScope.$on("EnvironmentService.switchEnvironment:done", function () {
    getBricks();
  });
}

angular.module("app.directives").directive("apodisDelegateBricksMenu", ApodisDelegateBricksMenu);