"use strict";
/**
 * Sales area management service.
 *
 * @param {object} apiClient - HTTP builder.
 *
 * @returns {object}
 */

SalesAreaManagementService.$inject = ["apiClient"];

function SalesAreaManagementService(apiClient) {
  return {
    get: function get() {
      return apiClient.get("/sales-areas", {
        includes: ["salesAreaType"],
        wrappers: {
          repsonse: null
        }
      }).then(function (response) {
        return response.data;
      });
    },
    readSalesArea: function readSalesArea(params) {
      return apiClient.get("/sales-areas/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.salesArea;
      });
    },
    post: function post(params) {
      return apiClient.post("/sales-areas", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },
    update: function update(params) {
      return apiClient.put("/sales-areas/".concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },
    "delete": function _delete(params) {
      return apiClient["delete"]("/sales-areas/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },
    getSalesAreaTypes: function getSalesAreaTypes() {
      return apiClient.get("/sales-areas-types", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },
    getSalesAreaAffectations: function getSalesAreaAffectations(params) {
      return apiClient.get("/sales-areas-affectations/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },
    affectProducts: function affectProducts(data) {
      return apiClient.post("/sales-areas-affectations", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },
    deleteAffectation: function deleteAffectation(params) {
      return apiClient["delete"]("/sales-areas-affectations/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },
    updateAffectation: function updateAffectation(params) {
      return apiClient.put("/sales-areas-affectations/".concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },
    getProfitabilities: function getProfitabilities() {
      return apiClient.get("/sales-areas-profitabilities", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },
    getProfitability: function getProfitability(params) {
      return apiClient.get("/sales-areas-profitabilities/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.salesAreaManagement").service("salesAreaManagementService", SalesAreaManagementService);