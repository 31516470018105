"use strict";
/**
 * Prestation participation controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} prestationService - The prestation service.
 */

PrestationParticipationController.$inject = ["$scope", "$state", "$stateParams", "prestationService"];

function PrestationParticipationController($scope, $state, $stateParams, prestationService) {
  $scope.query = {
    limit: 15,
    page: 1
  };
  $scope.get = get;
  $scope.goToPhoto = goToPhoto;

  switch ($stateParams.status) {
    case "0":
      $scope.title = "Pharmacies n’ayant pas répondu";
      break;

    case "1":
      $scope.title = "Pharmacies ayant validé";
      break;

    case "2":
      $scope.title = "Pharmacies ayant refusé";
      break;

    default:
      $scope.title = "Pharmacies ciblées";
  }

  get();
  /**
   * Get all prestation participations.
   *
   * @returns {Promise} Promise object represents the client request.
   */

  function get() {
    return prestationService.getParticipations($scope.query.page, $stateParams).then(function (participation) {
      $scope.participation = participation;
    });
  }
  /**
   * Go to photo.
   *
   * @param {number} organizationId - The ID of the organization.
   */


  function goToPhoto(organizationId) {
    $state.go("app.prestation.photo", {
      organization_id: organizationId
    });
  }
}

angular.module("app.prestation").controller("PrestationParticipationController", PrestationParticipationController);