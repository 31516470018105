"use strict";
/**
 * Dashboard groups prestations push controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 */

DashboardGroupsPrestationsPushController.$inject = ["$scope", "$state"];

function DashboardGroupsPrestationsPushController($scope, $state) {
  $scope.goToPrestations = function () {
    $state.go("app.prestation");
  };
}

angular.module("app.dashboard").controller("dashboardGroupsPrestationsPushController", DashboardGroupsPrestationsPushController);