"use strict";
/**
 * Controller to manage disruption offer.
 *
 * @param {object} $scope - The controller scope.
 * @param {object} $stateParams - The state parameters.
 * @param {object} notifyUserService - The notification service.
 * @param {object} environmentService - The environment service.
 * @param {object} disruptionAlertService - The disruption alert service.
 * @param {object} productService - The product service.
 * @param {object} healthcenterService - The healthcenter service.
 * @param {object} disruptionPresentationService - The disruption presentation service.
 */

DisruptionOfferController.$inject = ["$scope", "$stateParams", "notifyUserService", "environmentService", "disruptionAlertService", "productService", "healthcenterService", "disruptionPresentationService"];

function DisruptionOfferController($scope, $stateParams, notifyUserService, environmentService, disruptionAlertService, productService, healthcenterService, disruptionPresentationService) {
  $scope.tabs = [{
    id: 1,
    name: "Gestion de l’alerte",
    slug: "disruption-offer-alert",
    active: true,
    isActive: true
  }, {
    id: 2,
    name: "Paramètres",
    slug: "disruption-offer-presentation",
    method: readPresentation,
    active: false,
    isActive: true
  }];
  $scope.select = false;
  $scope.model = {
    presentation: ""
  };
  $scope.productSearchConfig = {
    search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
    event: "addProducts",
    autoLaunchEvent: true,
    tag: "configDisruptionOfferAlertProduct",
    searchPlaceHolder: "Rerchercher des produits"
  };
  $scope.productQuery = {
    order: "name",
    limit: 5,
    page: 1
  };
  $scope.healthcenterSearchConfig = {
    search: ["idOrgas", "geo.region.id", "geo.city.id", "geo.departement.number", "geo.uga", "segmentHealthcenter"],
    event: "addHealthcenters",
    autoLaunchEvent: true,
    tag: "configDisruptionOfferAlertHealthcenter",
    searchPlaceHolder: "Rerchercher des pharmacies"
  };
  $scope.healthcenterQuery = {
    order: "name",
    limit: 5,
    page: 1
  };
  $scope.paginationLabel = {
    page: "Page :",
    rowsPerPage: "Lignes par page :",
    of: "sur"
  };
  $scope.tinymceOptions = {
    selector: "textarea#groupment",
    height: 100,
    menubar: false,
    plugins: "link image code advlist autolink lists link image charmap hr anchor visualblocks visualchars code fullscreen media save table contextmenu directionality emoticons template paste textcolor colorpicker textpattern imagetools codesample ",
    toolbar: " styleselect | fontselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | media | forecolor backcolor | textcolor colorpicker",
    image_advtab: true,
    font_formats: "Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace;AkrutiKndPadmini=Akpdmi-n",
    fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
    skin: "lightgray",
    theme: "modern"
  };
  $scope.readAlert = readAlert;
  $scope.saveAlert = saveAlert;
  $scope.removeProduct = removeProduct;
  $scope.removeHealthcenter = removeHealthcenter;
  $scope.deleteImage = deleteImage;
  $scope.openTab = openTab;
  $scope.readPresentation = readPresentation;
  $scope.savePresentation = savePresentation;
  loader();
  /** Loader */

  function loader() {
    loadAccess().then(function () {
      openTab($scope.tabs[0]);
      readAlert();
    });
  }
  /**
   * loadAccess
   *
   * @returns {Promise}
   */


  function loadAccess() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.access = response;
      return response;
    });
  }
  /**
   * Open the tab
   *
   * @param {object} myTab - The tab to open
   */


  function openTab(myTab) {
    var tabs = document.getElementsByClassName("tab");

    for (var index = 0; index < tabs.length; index++) {
      tabs[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";

        if (myTab.method) {
          myTab.method();
        }
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Remove a product.
   *
   * @param {number} index - The index of the element to remove.
   */


  function removeProduct(index) {
    $scope.alert.products.splice(index, 1);
  }
  /**
   * Remove a healthcenter.
   *
   * @param {number} index - The index of the element to remove.
   */


  function removeHealthcenter(index) {
    $scope.alert.healthcenters.splice(index, 1);
  }
  /** Read disruption alert. */


  function readAlert() {
    disruptionAlertService.read($scope.access.entityId).then(function (alert) {
      $scope.alert = alert;
    });
  }
  /** Save disruption alert. */


  function saveAlert() {
    disruptionAlertService.update($scope.access.entityId, $scope.alert).then(function (alert) {
      notifyUserService.showSuccessToast("Les paramètres ont bien été enregistrés.");
      $scope.alert = alert;
    });
  }
  /**
   * Delete the image
   */


  function deleteImage() {
    $scope.alert.image_path = null;
  }
  /** Read disruption alert. */


  function readPresentation() {
    disruptionPresentationService.read($scope.access.entityId).then(function (presentation) {
      $scope.model = presentation;
    });
  }
  /** Save disruption alert. */


  function savePresentation() {
    disruptionPresentationService.update($scope.access.entityId, $scope.model).then(function () {
      notifyUserService.showSuccessToast("Les paramètres ont bien été enregistrés.");
    });
  }

  $scope.$on("addProducts", function (event, data) {
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(data, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        angular.forEach(data[type], function (obj) {
          queryProduct[type].push(obj.id);
        });
      }
    };

    for (var type in data) {
      _loop(type);
    }

    productService.postPresentationByType(queryProduct).then(function (products) {
      angular.forEach(products, function (product) {
        if (!_.find($scope.alert.products, function (prod) {
          return product.id === prod.id;
        })) {
          $scope.alert.products.push(product);
        }
      });
    });
  });
  $scope.$on("addHealthcenters", function (event, query) {
    var queryPharma = {};
    queryPharma.type = {};
    var groups = [];

    var _loop2 = function _loop2(type) {
      if (type !== "groups") {
        if (Object.prototype.hasOwnProperty.call(query, type)) {
          if (typeof queryPharma.type[type] === "undefined") {
            queryPharma.type[type] = [];
          }

          angular.forEach(query[type], function (obj) {
            if (type === "uga") {
              queryPharma.type[type].push(obj.name);
            } else {
              queryPharma.type[type].push(obj.id);
            }
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(query, type)) {
        angular.forEach(query[type], function (group) {
          if (!_.find($scope.alert.healthcenters, function (element) {
            return group.id === element.id && element.entityTypeId === 3;
          })) {
            group.entityTypeId = 3;
            $scope.alert.healthcenters.push(group);
          }

          groups.push(group);
        });
      }
    };

    for (var type in query) {
      _loop2(type);
    }

    healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      angular.forEach(pharmas, function (pharma) {
        if (!_.find($scope.alert.healthcenters, function (participant) {
          return pharma.id === participant.id;
        })) {
          pharma.entityTypeId = 2;
          $scope.alert.healthcenters.push(pharma);
        }
      });
    });
  });
}

angular.module("app.disruption").controller("disruptionOfferController", DisruptionOfferController);