"use strict";
/**
 * Marge controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 */

MargeController.$inject = ["$scope", "environmentService"];

function MargeController($scope, environmentService) {
  environmentService.getEnvironment().then(function (env) {
    $scope.currentEnv = env;

    if ($scope.currentEnv.entity.wholesaler) {
      $scope.hasCapContract = typeof $scope.currentEnv.entity.wholesaler.id !== "undefined";
    }
  });
}

angular.module("app.statistics.wholesalerAudit").controller("MargeController", MargeController);