"use strict";
/**
 * Lab serial factory.
 *
 * @returns {object}
 */

function labSerial() {
  return {
    type: "serial",
    theme: "light",
    thousandsSeparator: " ",
    percentPrecision: 0,
    precision: 0,
    startDuration: 0,
    rotate: true,
    angle: 30,
    depth3D: 10,
    mouseWheelScrollEnabled: true,
    legend: {
      autoMargins: true,
      useGraphSettings: true,
      horizontalGap: 10,
      markerSize: 10,
      valueAlign: "left",
      valueWidth: 0
    },
    chartCursor: {
      enabled: true,
      avoidBalloonOverlapping: false,
      balloonPointerOrientation: "vertical",
      oneBalloonOnly: true
    },
    chartScrollbar: {
      enabled: false
    },
    data: [],
    valueAxes: [{
      stackType: "regular",
      gridColor: "#FFFFFF",
      axisAlpha: 0,
      gridAlpha: 0,
      labelsEnabled: true,
      position: "left",
      margin: 50
    }],
    graphs: [{
      balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
      fillAlphas: 1,
      fontSize: 11,
      labelText: "[[value]] €",
      lineAlpha: 1,
      title: "< 10 jours",
      type: "column",
      lineColor: "#ff2c00",
      columnWidth: 0.70,
      valueField: "range1"
    }, {
      balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
      fillAlphas: 1,
      fontSize: 11,
      labelText: "[[value]] €",
      lineAlpha: 0.5,
      title: "Entre 10 et 20 jours",
      type: "column",
      lineColor: "#ffca28",
      columnWidth: 0.70,
      valueField: "range2"
    }, {
      balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
      fillAlphas: 1,
      fontSize: 11,
      labelText: "[[value]] €",
      lineAlpha: 0.5,
      title: "Entre 21 et 30 jours",
      type: "column",
      lineColor: "#ffe082",
      columnWidth: 0.70,
      valueField: "range3"
    }, {
      balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
      fillAlphas: 1,
      fontSize: 11,
      labelText: "[[value]] €",
      lineAlpha: 0.5,
      title: "Entre 31 et 60 jours",
      type: "column",
      lineColor: "#ffecb3",
      columnWidth: 0.70,
      valueField: "range4"
    }, {
      balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
      fillAlphas: 1,
      fontSize: 11,
      labelText: "[[value]] €",
      lineAlpha: 0.5,
      title: "Entre 61 et 90 jours",
      type: "column",
      lineColor: "#b3e5fc",
      columnWidth: 0.70,
      valueField: "range5"
    }, {
      balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
      fillAlphas: 1,
      fontSize: 11,
      labelText: "[[value]] €",
      lineAlpha: 0.5,
      title: ">90 jours",
      type: "column",
      lineColor: "#29b6f6",
      columnWidth: 0.70,
      valueField: "range6"
    }, {
      balloonText: "<div style='font-size:11px;text-align:left'>[[title]] : <br>[[percents]]% <br><b>[[value]] EUR</b></div>",
      fillAlphas: 1,
      fontSize: 11,
      labelText: "[[value]] €",
      lineAlpha: 0.5,
      title: "Stocks dormant",
      type: "column",
      lineColor: "#4a148c",
      color: "white",
      columnWidth: 0.70,
      valueField: "range7"
    }],
    autoMargins: true,
    autoDisplay: true,
    categoryField: "healthcenter",
    categoryAxis: {
      gridPosition: "start",
      position: "left",
      parseDates: false,
      labelFunction: function labelFunction(valueText) {
        if (valueText.length > 30) {
          return valueText.substring(0, 30) + "...";
        }

        return valueText;
      }
    },
    "export": {
      enabled: true
    }
  };
}

angular.module("app.home").factory("labSerial", labSerial);