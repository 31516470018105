"use strict";
/**
 * Patient age filter directive.
 *
 * @returns {object}
 */

function PatientAgeFilterDirective() {
  return {
    restrict: "E",
    templateUrl: "app/main/patients-search/filters/age/patient.age.filter.html"
  };
}

angular.module("apodis").directive("patientAgeFilter", PatientAgeFilterDirective);