"use strict";
/**
 * User sellout service.
 *
 * @param {string} apiurl - API URL.
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

UserSelloutService.$inject = ["apiurl", "apiClient"];

function UserSelloutService(apiurl, apiClient) {
  return {
    getSelloutImage: function getSelloutImage(sellout) {
      var fallback = "assets/images/sellouts/default-challenge-1080x600.jpg";
      return sellout.fileId ? "".concat(apiurl, "/images/").concat(sellout.imagePath) : fallback;
    },
    getStatusLabel: function getStatusLabel(sellout) {
      var now = moment();
      var startDate = new Date(sellout.startDate);
      var endDate = new Date(sellout.endDate);

      if (now < startDate) {
        return "À venir";
      }

      if (now >= startDate && now <= endDate) {
        return "En cours";
      }

      return "Terminé";
    },

    /**
     * Read a user sellout.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/user/me/sellout/".concat(params.id), {
        includes: ["sellout.organization.morphable", "distribution"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.userSellout;
      });
    },

    /**
     * Read score.
     *
     * @param {number} selloutId - Sellout ID.
     *
     * @returns {Promise}
     */
    readScore: function readScore(selloutId) {
      return apiClient.get("/user/me/sellout/".concat(selloutId, "/score"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read user sellout score as grouping.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readGroupingScore: function readGroupingScore(params) {
      return apiClient.get("/user/me/sellout/".concat(params.id, "/score/grouping"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get filter user sell-out.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    filterUserSellouts: function filterUserSellouts(params) {
      return apiClient.post("/user/me/sellout", params, {
        includes: ["sellout", "sellout.objectiveRewards", "sellout.organization.morphable"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.sellouts;
      });
    },

    /**
     * Accept user sell-out.
     *
     * @param {object} event -
     * @param {object} params -
     * @returns {Promise}
     */
    accept: function accept(event, params) {
      return apiClient.put("/user/me/sellout/".concat(params.id, "/accept"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.sellouts;
      });
    },

    /**
     * Refuse user sell-out.
     *
     * @param {object} event -
     * @param {object} params -
     * @returns {Promise}
     */
    refuse: function refuse(event, params) {
      return apiClient.put("/user/me/sellout/".concat(params.id, "/refuse"), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.sellouts;
      });
    }
  };
}

angular.module("app.users-sellout").service("userSelloutService", UserSelloutService);