"use strict";
/**
 * Fabric dirty status.
 *
 * @param {object} $window - $window.
 * @returns {object}
 */

FabricDirtyStatus.$inject = ["$window"];

function FabricDirtyStatus($window) {
  var factory = {
    dirty: false,
    endListening: function endListening() {
      $window.onbeforeunload = null;
      $window.onhashchange = null;
    },
    startListening: function startListening() {
      $window.onbeforeunload = checkSaveStatus;
      $window.onhashchange = checkSaveStatus;
    },
    isDirty: function isDirty() {
      return factory.dirty;
    },
    setDirty: function setDirty(value) {
      factory.dirty = value;
    }
  };
  /**
   * checkSaveStatus
   *
   * @returns {string}
   */

  function checkSaveStatus() {
    if (factory.isDirty()) {
      return "Attention travail non enregistré.\n\nSauvegardez vos données.";
    }

    return "";
  }

  return factory;
}

angular.module("app.generator").factory("fabricDirtyStatus", FabricDirtyStatus);