"use strict";
/**
 * Slideshow directive.
 *
 * @returns {object}
 */

SlideshowController.$inject = ["$rootScope", "$scope", "$timeout", "sessionStorageService", "apiurl"];

function SlideShow() {
  return {
    restrict: "E",
    scope: {
      slides: "=slides",
      delayMs: "=delayMs"
    },
    templateUrl: "app/main/directives/slideshow/slideshow.html",
    transclude: true,
    controller: SlideshowController
  };
}
/**
 * Slideshow controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $timeout - $timeout.
 * @param {object} sessionStorageService - Session storage service.
 * @param {string} apiurl - API URL.
 */


function SlideshowController($rootScope, $scope, $timeout, sessionStorageService, apiurl) {
  /**
   * Declaration of public methods
   */
  $scope.plusSlides = plusSlides;
  $scope.currentSlide = currentSlide;
  /**
   * Declaration of public objects
   */

  $scope.slideIndex = !_.isUndefined($scope.delayMs) ? 0 : 1;
  $scope.duration = !_.isUndefined($scope.delayMs) ? $scope.delayMs : 3000;
  $scope.token = sessionStorageService.getToken();
  $scope.apiurl = apiurl;
  loader();
  /**
   * Loader.
   */

  function loader() {
    if (!_.isUndefined($scope.slides) && $scope.slides.length > 0) {
      if (!_.isUndefined($scope.delayMs)) {
        start();
      } else {
        showSlides($scope.slideIndex);
      }
    }
  }
  /**
   * Plus slides.
   *
   * @param {number} index - Slide index coefficient
   */


  function plusSlides(index) {
    showSlides($scope.slideIndex += index);
  }
  /**
   * Current slide.
   *
   * @param {number} slideIndex - slide index coefficient
   */


  function currentSlide(slideIndex) {
    showSlide(slideIndex);
  }
  /**
   * Show slide.
   *
   * @param {number} slideIndex - slide index
   */


  function showSlide(slideIndex) {
    var currentSlide = $scope.slides[slideIndex];
    $scope.slides.forEach(function (slide) {
      slide.active = false;
    });
    currentSlide.active = true;
  }
  /**
   * Show slides.
   *
   * @param {number} index - slide index coefficient
   */


  function showSlides(index) {
    if (index > $scope.slides.length) {
      $scope.slideIndex = 1;
    }

    if (index < 1) {
      $scope.slideIndex = $scope.slides.length;
    }

    $scope.slides.forEach(function (slide) {
      slide.active = false;
    });
    $scope.slides[$scope.slideIndex - 1].active = true;
  }
  /**
   * Start.
   */


  function start() {
    $scope.slides.forEach(function (slide) {
      slide.active = false;
    });
    $scope.slideIndex++;

    if ($scope.slideIndex >= $scope.slides.length) {
      $scope.slideIndex = 1;
    }

    $scope.slides[$scope.slideIndex - 1].active = true;
    $timeout(start, $scope.duration);
  }
  /**
   * Refresh slides.
   *
   * @param {object} event - $rootScope.$on event
   * @param {Array} slides - array of broadcast's slide
   */


  $rootScope.$on("slideshow:refresh", function (event, slides) {
    if (slides) {
      $scope.slides = slides;

      if ($scope.slides.length > 0) {
        loader();
      }
    }
  });
}

angular.module("app.directives").directive("slideShow", SlideShow);