"use strict";

LoyaltyCardConfig.$inject = ["$stateProvider", "loyaltyCardConfig"];
angular.module("app.loyaltyCard", []).config(LoyaltyCardConfig).constant("loyaltyCardConfig", {
  baseDir: "app/main/loyalty-card/views/"
});
/** @ngInject */

/**
 * Config for loyalty card.
 *
 * @param {object} $stateProvider - The state provider
 * @param {object} loyaltyCardConfig - The loyalty card config
 */

function LoyaltyCardConfig($stateProvider, loyaltyCardConfig) {
  $stateProvider.state("app.loyaltyCard", {
    url: "/carte-de-fidelite",
    views: {
      "content@app": {
        templateUrl: loyaltyCardConfig.baseDir + "list/loyaltyCards.html",
        controller: "loyaltyCardListController"
      }
    }
  }).state("app.loyaltyCard.new", {
    url: "/creation",
    views: {
      "content@app": {
        templateUrl: loyaltyCardConfig.baseDir + "edit/edit.html",
        controller: "loyaltyCardEditController"
      }
    }
  }).state("app.loyaltyCard.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: loyaltyCardConfig.baseDir + "edit/edit.html",
        controller: "loyaltyCardEditController"
      }
    }
  }).state("app.loyaltyCard.detail", {
    url: "/:id/details",
    views: {
      "content@app": {
        templateUrl: loyaltyCardConfig.baseDir + "detail/detail.html",
        controller: "loyaltyCardDetailController"
      }
    }
  }).state("app.loyaltyCard.members", {
    url: "/:loyaltyCardId/membres",
    views: {
      "content@app": {
        templateUrl: loyaltyCardConfig.baseDir + "member-list/loyaltyCardMembers.html",
        controller: "loyaltyCardMemberController"
      }
    }
  }).state("app.loyaltyCard.members.detail", {
    url: "/:loyaltyCardMemberId/details",
    views: {
      "content@app": {
        templateUrl: loyaltyCardConfig.baseDir + "member-detail/member-detail.html",
        controller: "loyaltyCardMemberDetailController"
      }
    }
  }).state("app.loyaltyCard.members.newMember", {
    url: "/creation",
    views: {
      "content@app": {
        templateUrl: loyaltyCardConfig.baseDir + "member-edit/member-edit.html",
        controller: "loyaltyCardMemberEditController"
      }
    }
  }).state("app.loyaltyCard.members.editMember", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: loyaltyCardConfig.baseDir + "member-edit/member-edit.html",
        controller: "loyaltyCardMemberEditController"
      }
    }
  });
}