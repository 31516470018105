"use strict";
/**
 * Controller for patient next visit, in pharmacy dashboard.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $state - The angular $state object.
 * @param {object} $mdDialog - The $mdDialog object.
 * @param {object} renewalScheduleService - The renewal schedule service.
 * @param {object} notifyUserService - The notify user service.
 */

dashboardPharmaciesPatientNextVisitController.$inject = ["$scope", "$state", "$mdDialog", "renewalScheduleService", "notifyUserService"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function dashboardPharmaciesPatientNextVisitController($scope, $state, $mdDialog, renewalScheduleService, notifyUserService) {
  $scope.loading = true;
  $scope.incomingVisits = null;
  $scope.shouldCallPharmaML = {
    value: false
  };
  $scope.isProductsUnavailable = isProductsUnavailable;
  $scope.getUnavailableProducts = getUnavailableProducts;
  $scope.loadSchedules = loadRenewalSchedule;
  $scope.openNextVisitsModal = openNextVisitsModal;
  $scope.redirectToPatientDetails = redirectToPatientDetails;
  loader();
  /**
   * The loader function.
   */

  function loader() {
    loadRenewalSchedule();
  }
  /**
   * Load renewal schedules and put it in the $scope.
   *
   * @returns {void}
   */


  function loadRenewalSchedule() {
    $scope.loading = true;
    var params = {
      limit: 4,
      upcoming: true
    };

    if ($scope.shouldCallPharmaML.value) {
      params.availability = true;
    }

    renewalScheduleService.getRenewalSchedulePlan(params).then(function (response) {
      $scope.incomingVisits = response.data.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          next_renewal_date: new Date(item.next_renewal_date)
        });
      });
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue lors du chargement des prochaines visites.");
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Determine whether products of next visit are available.
   *
   * @param {object} nextVisit - The visit object.
   *
   * @returns {boolean}
   */


  function isProductsUnavailable(nextVisit) {
    return !nextVisit.availabilities.healthcenter_available;
  }
  /**
   * Get unavailable products for a next visit.
   *
   * @param {object} nextVisit - The next visit object.
   *
   * @returns {Array}
   */


  function getUnavailableProducts(nextVisit) {
    return nextVisit.availabilities.details.filter(function (availability) {
      return availability.is_available === false;
    });
  }
  /**
   * Redirect to the patient details.
   *
   * @param {object} patient - The patient.
   */


  function redirectToPatientDetails(patient) {
    $state.go("app.patients.details", {
      patientId: patient.id
    });
  }
  /**
   * Open a modal displaying incoming visits.
   */


  function openNextVisitsModal() {
    $mdDialog.show({
      controller: "dashboardPharmaciesPatientNextVisitModalController",
      templateUrl: "app/main/dashboard/view/pharmacies/includes/widgets/patientNextVisit/modals/patientNextVisitModal.html",
      targetEvent: event,
      clickOutsideToClose: true,
      multiple: true,
      skipHide: true
    });
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesPatientNextVisitController", dashboardPharmaciesPatientNextVisitController);