"use strict";
/**
 * Pack configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

PackConfig.$inject = ["$stateProvider"];

function PackConfig($stateProvider) {
  $stateProvider.state("app.packList", {
    url: "/packs",
    views: {
      "content@app": {
        templateUrl: "app/main/pack/views/list/pack.list.html",
        controller: "packListController"
      }
    }
  }).state("app.packDetail", {
    url: "/packs/:packSlug",
    views: {
      "content@app": {
        templateUrl: "app/main/pack/views/detail/pack.detail.html",
        controller: "packDetailController"
      }
    }
  });
}

angular.module("app.pack", []).config(PackConfig);