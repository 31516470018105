"use strict";
/**
 * User access points admin service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

UserAccessPointsAdminService.$inject = ["apiClient"];

function UserAccessPointsAdminService(apiClient) {
  return {
    /**
     * Get.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    get: function get(params) {
      return apiClient.get("/admin/user-access-point/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      }).then(function (result) {
        return result.usersAccessPoints.map(function (access) {
          access.name = access.entity.name;
          return access;
        });
      });
    },

    /**
     * Post.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    post: function post(params) {
      return apiClient.post("/admin/user-access-point", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    "delete": function _delete(params) {
      return apiClient["delete"]("/admin/user-access-point/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/admin/user-access-point/".concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get rank list.
     *
     * @returns {Promise}
     */
    getRankList: function getRankList() {
      return apiClient.get("/access/admin/rank-list", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.ranks;
      });
    }
  };
}

angular.module("app.admin").service("userAccessPointsAdminService", UserAccessPointsAdminService);