"use strict";

FilemanagerDialogService.$inject = ["$mdDialog", "apiurl", "$parse", "sessionStorageService", "$state"];

/**
 * File manager dialog service.
 *
 * @param {object} $mdDialog - mdDialog object.
 * @param {string} apiurl - The api url.
 * @param {object} $parse - The $parse object.
 * @param {object} sessionStorageService - The session storage object.
 * @param {object} $state - The $state object.
 *
 * @return {object}
 * @constructor
 */
function FilemanagerDialogService($mdDialog, apiurl, $parse, sessionStorageService, $state) {
  /**
   * Open the file manager dialog.
   *
   * @param {object} event - The event.
   * @param {object} type - The type.
   * @param {Function} callback - The callback function.
   * @param {object} item - The item.
   * @param {object} file - The file.
   *
   * @returns {void}
   */
  var openDialog = function openDialog(event, type, callback, item, file) {
    return $mdDialog.show({
      controller: ["$mdDialog", "$mdToast", "uploadFilesizeLimit", "notifyUserService", "filemanagerService", function controller($mdDialog, $mdToast, uploadFilesizeLimit, notifyUserService, filemanagerService) {
        var vm = this;
        vm.apiurl = apiurl;
        vm.loading = false;
        vm.cancel = cancel;
        vm.currentLocation = "/";
        vm.history = [{
          location: "/"
        }];
        vm.getFoldersTree = getFoldersTree("/");
        vm.getfiles = getFiles("/");
        vm.selectFile = selectFile;
        vm.goToFilemanager = goToFilemanager;
        vm.uploadFile = uploadFile;
        vm.uploadComplete = uploadComplete;
        vm.openDirectory = openDirectory;
        vm.openFolder = openFolder;
        vm.openParentDirectory = openParentDirectory;
        vm.goToHome = goToHome;
        vm.fileError = fileError;
        vm.fileAdded = fileAdded;
        var bearerToken = sessionStorageService.getBearerToken();
        vm.ngFlowOptions = {
          target: function target() {
            return vm.apiurl + "/filemanager/upload" + "?location=" + vm.currentLocation;
          },
          chunkSize: uploadFilesizeLimit,
          maxChunkRetries: 1,
          simultaneousUploads: 4,
          testChunks: false,
          progressCallbacksInterval: 1000
        };
        vm.ngFlow = {
          flow: {}
        };
        vm.collapse = collapse;
        vm.expand = expand;
        /** Upload file. */

        function uploadFile() {
          vm.ngFlow.flow.opts.headers = {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: bearerToken
          };
          vm.ngFlow.flow.upload();
        }
        /**
         * Validate file when added.
         *
         * @param {object} file -
         * @returns {boolean}
         */


        function fileAdded(file) {
          var oneMegaBytes = 1048576; // 1 * 1024 * 1024

          var isUnderFilesize = file.size < uploadFilesizeLimit;

          if (!isUnderFilesize) {
            notifyUserService.showDangerToast("Votre fichier ne doit pas dépasser " + uploadFilesizeLimit / oneMegaBytes + "Mo.");
          }

          return isUnderFilesize;
        }
        /**
         * File upload error callback.
         *
         * @param {object} file -
         * @param {string} message -
         */


        function fileError(file, message) {
          $mdToast.show($mdToast.simple().textContent(JSON.parse(message).message).position("top right"));
        }
        /**
         * Upload complete callback
         * Triggers when single upload completed
         */


        function uploadComplete() {
          getFiles(vm.currentLocation);
        }
        /**
         * Get folders tree.
         *
         * @param {string} location -
         */


        function getFoldersTree(location) {
          vm.loading = true;
          filemanagerService.getFoldersTree(location).then(function (response) {
            vm.tree = response.tree;
            vm.loading = false;
          });
        }
        /**
         * Get files.
         *
         * @param {string} location -
         */


        function getFiles(location) {
          vm.loading = true;
          filemanagerService.getFiles(location, type).then(function (response) {
            var files = response.files;
            files.map(function (file) {
              var type;
              var icon;
              var source = null;

              if (file.isFolder) {
                type = "Dossier";
                icon = "icon-folder";
              } else if (file.mime) {
                type = file.mime.type.name;
                icon = file.mime.type.icon;
              } else {
                type = "Inconnu";
                icon = "icon-help";
              }

              if (file.mime && file.mime.isRendable) {
                source = apiurl + "/filemanager/image/render" + "?id=" + file.id + "&w=400&h=400&fit=crop&sharp=10&q=40" + "&token=" + file.token;
              }

              file.type = type;
              file.icon = icon;
              file.source = source;
              return file;
            });
            vm.files = response.files;
            vm.loading = false;
          });
        }
        /**
         * Select file.
         *
         * @param {object} file - File.
         */


        function selectFile(file) {
          if (typeof callback !== "undefined") {
            if (!_.isUndefined(item)) {
              callback(undefined, file, item);
            } else {
              callback(undefined, file);
            }
          }

          $mdDialog.hide(file);
        }
        /**
         * Cancel.
         */


        function cancel() {
          $mdDialog.hide();
        }
        /**
         * Go to filemanager.
         */


        function goToFilemanager() {
          $mdDialog.hide();
          $state.go("app.file-manager");
        }
        /**
         * Open a directory.
         *
         * @param {object} file - The file instance.
         */


        function openDirectory(file) {
          var location = vm.currentLocation === "/" ? vm.currentLocation + file.name : vm.currentLocation + "/" + file.name;
          vm.history.push({
            location: location
          });
          vm.currentLocation = location;
          getFiles(location);
        }
        /**
         * Open a folder.
         *
         * @param {string} location - The location to open.
         */


        function openFolder(location) {
          if (vm.currentLocation !== location) {
            vm.history.push({
              location: location
            });
            vm.currentLocation = location;
            getFiles(location);
          }
        }
        /** Open the parent directory. */


        function openParentDirectory() {
          var history = vm.history;
          history.pop();
          var location = history[history.length - 1].location;
          vm.currentLocation = location;
          getFiles(location);
        }
        /** Go to home. */


        function goToHome() {
          getFiles("/");
        }
        /**
         * Collapse.
         *
         * @param {object} $event - The captured event.
         */


        function collapse($event) {
          var target = $($event.target).parent();
          target.addClass("collapsed");
          target.removeClass("expanded");
        }
        /**
         * Expand.
         *
         * @param {object} $event - The captured event.
         */


        function expand($event) {
          var target = $($event.target).parent();
          target.addClass("expanded");
          target.removeClass("collapsed");
        }
      }],
      controllerAs: "vm",
      templateUrl: "app/main/file-manager/dialog/filemanagerDialog.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      hasBackdrop: false
    });
  };

  return {
    openDialog: openDialog
  };
}

angular.module("apodis").service("filemanagerDialogService", FilemanagerDialogService);