"use strict";
/**
 * Entity admin team controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} entityAdminTeamsService - Entity admin teams service.
 * @param {object} entityAdminPacksService - Entity admin packs service.
 * @param {object} entityAdminBricksService - Entity admin bricks service.
 * @param {object} accessService - Access service.
 * @param {object} notifyUserService - Notify user service.
 */

EntityAdminTeamController.$inject = ["$scope", "$state", "entityAdminTeamsService", "entityAdminPacksService", "entityAdminBricksService", "accessService", "notifyUserService"];

function EntityAdminTeamController($scope, $state, entityAdminTeamsService, entityAdminPacksService, entityAdminBricksService, accessService, notifyUserService) {
  /**
   * Declaration of public methods
   */
  $scope.openTab = openTab;
  $scope.backToAdmin = backToAdmin;
  $scope.save = save;
  $scope.setBackgroundColor = setBackgroundColor;
  $scope.setIcon = setIcon;
  /**
   * Declaration of public objects
   */

  $scope.query = {
    order: "-startDate",
    limit: 10,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.searchAccess = {};
  $scope.entityAccessesOptions = {
    loading: true,
    loader: "Récupération des utilisateurs pouvant intégrer une équipe",
    empty: "Vous n'avez aucun employé pouvant être attaché à l'équipe."
  };
  $scope.accessesOptions = {
    loading: true,
    loader: "Récupération des utilisateurs de l'équipe.",
    empty: "Vous n'avez aucun employé attaché à l'équipe."
  };
  $scope.saveAccesses = saveAccesses;
  $scope.loadAuthorizations = loadAuthorizations;
  $scope.saveAuthorizations = saveAuthorizations;
  $scope.saveMenus = saveMenus;
  $scope.tabs = [{
    id: 1,
    name: "Gestion des employés",
    method: loadAccesses,
    slug: "accesses",
    active: true
  }, {
    id: 2,
    name: "Gestion des menus",
    method: loadMenus,
    slug: "menus"
  }
  /* , {
          id: 3,
          name: "Gérer les autorisations",
          method: loadAuthorizations,
          slug: "authorizations",
      } */
  ];
  $scope.mainTitle = {
    title: {
      text: "Gestion de l'équipe : ",
      back: {
        state: "app.entityAdmin",
        tooltip: "Revenir à la gestion des équipes"
      }
    }
  };
  $scope.loaderBricks = {
    loading: true,
    loader: "Récupération des menus",
    empty: "Vous n'avez aucun employé pouvant être attaché à l'équipe."
  };
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * Loader.
   */

  function loader() {
    loadTeam();
  }
  /**
   * Load team.
   */


  function loadTeam() {
    entityAdminTeamsService.getTeamById($state.params).then(function (response) {
      $scope.team = response;
      $scope.mainTitle.title.text += "« " + response.name + " »";
    }).then(loaderTabs);
  }
  /**
   * Tabs loader.
   */


  function loaderTabs() {
    var hash = "#" + $state.params.slug;
    var mainTab = angular.element(document).find(hash)[0];
    /**
     * To display block the first tab
     */

    $scope.tabs.forEach(function (tab) {
      mainTab.style.display = "block";
      tab.active = tab.slug === $state.params.slug;

      if (tab.slug === mainTab.id && tab.method) {
        tab.method();
      }
    });
  }
  /**
   * Open tab.
   *
   * @param {object} myTab - Current tab.
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";

        if (tab.method) {
          tab.method();
        }
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Load accesses.
   */


  function loadAccesses() {
    entityAdminTeamsService.getAccesses($state.params).then(function (teamAccesses) {
      $scope.accessesOptions.loading = false;
      accessService.getAccesses().then(function (entityAccesses) {
        $scope.entityAccesses = entityAccesses.map(function (entityAccess) {
          var isInTeamAccess = teamAccesses.filter(function (teamAccess) {
            return teamAccess.id === entityAccess.id;
          });
          entityAccess.active = isInTeamAccess.length > 0;
          return entityAccess;
        });
        $scope.entityAccessesOptions.loading = false;
      });
    });
  }
  /**
   * Save accesses.
   */


  function saveAccesses() {
    var accesses = $scope.entityAccesses.filter(function (access) {
      return access.active;
    }).map(function (access) {
      return access.id;
    });
    var myTeam = {
      id: $state.params.id,
      accesses: accesses
    };
    entityAdminTeamsService.update(myTeam).then(function (response) {
      $scope.team = response;
      notifyUserService.showSimpleToast("Configuration enregistrée", {
        color: "success",
        hideDelay: 1000
      });
    });
  }
  /**
   * Load menus.
   */


  function loadMenus() {
    entityAdminTeamsService.getMenus($state.params).then(function (teamMenus) {
      entityAdminBricksService.getBricks().then(function (bricks) {
        $scope.loaderBricks.loading = false;
        $scope.bricks = bricks.map(function (brick) {
          brick.menus.forEach(function (brickMenu) {
            var isInTeamMenus = teamMenus.filter(function (teamMenu) {
              return brickMenu.pivotId === teamMenu.id;
            });
            brickMenu.active = isInTeamMenus.length > 0;
          });
          return brick;
        });
      });
    });
  }
  /**
   * Save menus.
   */


  function saveMenus() {
    var menus = [];
    $scope.bricks.forEach(function (brick) {
      var brickMenus = brick.menus.filter(function (menu) {
        return menu.active;
      }).map(function (menu) {
        return menu.pivotId;
      });
      menus = menus.concat(brickMenus);
    });
    var team = {
      id: $state.params.id,
      menus: menus
    };
    entityAdminTeamsService.update(team).then(function (response) {
      $scope.team = response;
      notifyUserService.showSimpleToast("Configuration enregistrée", {
        color: "success",
        hideDelay: 1000
      });
    });
  }
  /**
   * Load authorizations.
   */


  function loadAuthorizations() {
    entityAdminTeamsService.getAuthorizations($state.params).then(function (teamAuthorizations) {
      entityAdminPacksService.getPacks().then(function (packs) {
        $scope.packs = packs.map(function (pack) {
          pack.authorizations.forEach(function (packAuthorization) {
            var isInTeamAuthorizations = teamAuthorizations.filter(function (authorization) {
              return authorization.id === packAuthorization.id;
            });
            packAuthorization.active = isInTeamAuthorizations.length > 0;
          });
          return pack;
        });
      });
    });
  }
  /**
   * Save authorizations.
   */


  function saveAuthorizations() {
    var authorizations = [];
    $scope.packs.forEach(function (pack) {
      var packAuthorizations = pack.authorizations.filter(function (authorization) {
        return authorization.active;
      }).map(function (authorization) {
        return authorization.id;
      });
      authorizations = authorizations.concat(packAuthorizations);
    });
    var team = {
      id: $state.params.id,
      authorizations: authorizations
    };
    entityAdminTeamsService.update(team).then(function (response) {
      $scope.team = response;
    });
  }
  /**
   * Back to admin.
   */


  function backToAdmin() {
    $state.go("app.entityAdmin");
  }
  /**
   * Save team.
   *
   * @returns {Promise}
   */


  function save() {
    var myTeam = {
      id: $state.params.id,
      options: $scope.selectedOptions,
      accesses: $scope.selectedAccesses
    };
    return entityAdminTeamsService.update(myTeam).then(function (response) {
      $scope.team = response;
      return response;
    });
  }
  /**
   * Set background color.
   *
   * @param {object} brick - Brick.
   * @returns {object}
   */


  function setBackgroundColor(brick) {
    return {
      "background-color": brick.color
    };
  }
  /**
   * Set icon.
   *
   * @param {object} brick - Brick.
   * @returns {string}
   */


  function setIcon(brick) {
    return brick.icon;
  }
}

angular.module("app.entityAdmin").controller("entityAdminTeamController", EntityAdminTeamController);