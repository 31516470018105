"use strict";

routeConfig.$inject = ["$stateProvider", "$urlRouterProvider", "$locationProvider"];
angular.module("apodis").config(routeConfig);
/** @ngInject */

function routeConfig($stateProvider, $urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise("/identification");
  $stateProvider.state("app", {
    "abstract": true,
    views: {
      "main@": {
        templateUrl: "app/core/layouts/vertical-navigation.html",
        controller: "MainController as vm"
      }
    }
  });
}