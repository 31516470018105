"use strict";
/**
 * Healthcenter audit details service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

healthcenterAuditHcDetailsService.$inject = ["apiClient"];

function healthcenterAuditHcDetailsService(apiClient) {
  return {
    /**
     * Healthcenter detail.
     *
     * @param {number} healthcenterId - Unique healthcenter id.
     * @returns {Promise}
     */
    hCDetail: function hCDetail(healthcenterId) {
      return apiClient.get("/healthcenters/".concat(healthcenterId), {
        includes: ["address", "grouping", "opening"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Healthcenter detail by CIP.
     *
     * @param {string} healthcenterCip - Healthcenter's CIP.
     * @returns {Promise}
     */
    hCDetailByCip: function hCDetailByCip(healthcenterCip) {
      return apiClient.get("/healthcenters/cip/".concat(healthcenterCip), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.healthcenter;
      });
    },

    /**
     * Get labo performance for a healthcenter.
     *
     * @param {string} healthcenterId - Healthcenter id.
     * @returns {Promise}
     */
    getPerformance: function getPerformance(healthcenterId) {
      return apiClient.get("/analysis/pharmaceutical-delegate-performance/".concat(healthcenterId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top 10 laboratories and others.
     *
     * @param {string} healthcenterId - Healthcenter id.
     * @returns {Promise}
     */
    getTop10Laboratories: function getTop10Laboratories(healthcenterId) {
      return apiClient.get("/analysis/market-potential/".concat(healthcenterId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top 10 presentation in exhaust.
     *
     * @param {string} healthcenterId - Healthcenter id.
     * @returns {Promise}
     */
    getTop10ExhaustPresentation: function getTop10ExhaustPresentation(healthcenterId) {
      return apiClient.get("/analysis/exhaust-presentation/".concat(healthcenterId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get product disruptions.
     *
     * @param {string} healthcenterId - Healthcenter id.
     * @returns {Promise}
     */
    getProductDisruptions: function getProductDisruptions(healthcenterId) {
      return apiClient.get("/analysis/product-disruptions/".concat(healthcenterId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get laboratory product disruption.
     *
     * @param {string} healthcenterId - Healthcenter id.
     * @returns {Promise}
     */
    getLaboratoryProductDisruptions: function getLaboratoryProductDisruptions(healthcenterId) {
      return apiClient.get("/analysis/laboratory-product-disruptions/".concat(healthcenterId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get product launches.
     *
     * @param {string} healthcenterId - Healthcenter id.
     * @returns {Promise}
     */
    getLaunches: function getLaunches(healthcenterId) {
      return apiClient.get("/analysis/product-launches/".concat(healthcenterId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get product future launches.
     *
     * @param {string} healthcenterId - Healthcenter id.
     * @returns {Promise}
     */
    getFutureLaunches: function getFutureLaunches(healthcenterId) {
      return apiClient.get("/analysis/product-future-launches/".concat(healthcenterId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get substitution.
     *
     * @param {string} healthcenterId - Healthcenter id.
     * @returns {Promise}
     */
    getSubstitution: function getSubstitution(healthcenterId) {
      return apiClient.get("/analysis/pharmaceutical-delegate-report/substitution/".concat(healthcenterId)).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.healthcenterAudit").service("healthcenterAuditHcDetailsService", healthcenterAuditHcDetailsService);