"use strict";
/**
 * Patient appointment details controller.
 *
 * @param {object} $scope - Angular $scope object.
 * @param {object} $mdDialog - The $mdDialog object.
 * @param {object} eventService - The event service.
 * @param {object} patientAppointmentsService - The patient appointments service.
 * @param {object} dateTimeService - The dateTime service.
 * @param {object} notifyUserService - The notify user service.
 * @param {object} event - The event.
 * @param {Function} callback - The callback function.
 * @param {Function} handleEdit - The edit callback.
 * @param {object} target - The DOM element.
 */

PatientAppointmentDetailsController.$inject = ["$scope", "$mdDialog", "eventService", "patientAppointmentsService", "dateTimeService", "notifyUserService", "event", "callback", "handleEdit", "target"];

function PatientAppointmentDetailsController($scope, $mdDialog, eventService, patientAppointmentsService, dateTimeService, notifyUserService, event, callback, handleEdit, target) {
  $scope.rangeForHuman = rangeForHuman;
  $scope.handleAppointment = handleAppointment;
  $scope.handlingAppointment = false;
  $scope.deleteEvent = deleteEvent;
  $scope.edit = edit;
  /**
   * Get a date range representation for human.
   *
   * @returns {string}
   */

  function rangeForHuman() {
    if (event.type !== "appointment") {
      return dateTimeService.rangeForHuman(event.start, event.end.clone().subtract(1, "days"));
    }

    return dateTimeService.rangeForHuman(event.start, event.end);
  }
  /**
   * Handle an appointment response
   *
   * @param {boolean} accepted - If the appointment is accepted.
   */


  function handleAppointment(accepted) {
    $scope.handlingAppointment = true;
    var data = {};

    if (accepted) {
      data.accepted_at = dateTimeService.toDateTimeString();
    } else {
      data.refused_at = dateTimeService.toDateTimeString();
    }

    patientAppointmentsService.update(event.id, data).then(function () {
      $scope.handlingAppointment = false;
      notifyUserService.showSuccessToast("Le rendez-vous a bien \xE9t\xE9 ".concat(accepted ? "accepté" : "refusé"));
      callback();
    })["catch"](function () {
      notifyUserService.showDangerToast("Une erreur est survenue.");
    });
  }
  /**
   * Edit the event.
   */


  function edit() {
    handleEdit(event, target);
  }
  /**
   * Delete the event.
   */


  function deleteEvent() {
    var confirm = $mdDialog.confirm().title("Souhaitez-vous supprimer cet événement ? Cette action aura un impact sur l'application patient, souhaitez-vous continuer ?").ariaLabel("Supprimer un événement").ok("Oui").cancel("Non");
    $mdDialog.show(confirm).then(function () {
      eventService["delete"](event).then(function () {
        notifyUserService.showSuccessToast("L'événement a bien été supprimé.");
        callback();
      });
    });
  }
}

angular.module("app.patients").controller("patientAppointmentDetailsController", PatientAppointmentDetailsController);