"use strict";
/**
 * Disruption service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

DisruptionService.$inject = ["apiClient"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function DisruptionService(apiClient) {
  return {
    /**
     * Get.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/disruptions", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.disruptions;
      });
    },

    /**
     * Read.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    read: function read(params) {
      return apiClient.get("/disruptions/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.disruption;
      });
    },

    /**
     * Create.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    create: function create(params) {
      return apiClient.post("/disruptions", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.disruption;
      });
    },

    /**
     * Update.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    update: function update(params) {
      return apiClient.put("/disruptions/".concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.disruption;
      });
    },

    /**
     * Get laboratory disruptions.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getLaboratoryDisruptions: function getLaboratoryDisruptions(params) {
      return apiClient.get("/disruptions/laboratories/".concat(params.id), {
        includes: ["disruption"],
        wrappers: {
          respones: null
        }
      }).then(function (response) {
        return response.data.disruptions;
      });
    },

    /**
     * Get laboratory disruption analysis.
     *
     * @param {object} params -
     *
     * @returns {Promise}
     */
    getLaboratoryDisruptionAnalysis: function getLaboratoryDisruptionAnalysis(params) {
      return apiClient.post("/disruptions/".concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get negative PharmaML.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getNegativePharmaML: function getNegativePharmaML(params) {
      return apiClient.post("/disruptions/negativePharmaML", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get disruption by laboratory.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getDisruptionByLaboratory: function getDisruptionByLaboratory(params) {
      return apiClient.get("/disruptions/byLaboratory", {
        parameters: params,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get disruption detail by laboratory.
     *
     * @param {object} params -
     * @param {object} query -
     * @returns {Promise}
     */
    getDisruptionDetailByLaboratory: function getDisruptionDetailByLaboratory(params, query) {
      return apiClient.get("/disruptions/byLaboratory/".concat(params.id), {
        parameters: query,
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get disruption analysis dashboard.
     *
     * @returns {Promise}
     */
    getDisruptionAnalysisDashboard: function getDisruptionAnalysisDashboard() {
      return apiClient.get("/disruptions/analysis", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get disruption rate for wholesalers.
     *
     * @returns {Promise}
     */
    getDisruptionRateForWholesaler: function getDisruptionRateForWholesaler() {
      return apiClient.get("/disruptions/rate-for-wholesalers").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get wholesalers top.
     *
     * @returns {Promise}
     */
    getWholesalersTop: function getWholesalersTop() {
      return apiClient.get("/disruptions/wholesalers-top").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get undelivered turnover.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    getUndeliveredTurnover: function getUndeliveredTurnover(params) {
      var config = {
        parameters: params
      };
      return apiClient.get("/disruptions/undelivered-turnover", config).then(function (response) {
        return response.data;
      });
    },

    /**
     * Method to retrieve the laboratory presentation rupture.
     *
     * @param {string} laboratoryId - The laboratory id.
     *
     * @returns {Promise}
     */
    getDisruptionPresentation: function getDisruptionPresentation(laboratoryId) {
      return apiClient.get("/laboratories/" + laboratoryId + "/disruption-presentation").then(function (response) {
        return response;
      });
    },

    /**
     * Get emergency offers by laboratory.
     *
     * @param {string} laboratoryId - The laboratory id.
     * @param {string} from - The from param.
     *
     * @returns {Promise}
     */
    getEmergencyByLaboratory: function getEmergencyByLaboratory(laboratoryId, from) {
      return apiClient.get("/disruptions/" + laboratoryId + "/offers?size=&from=" + from).then(function (response) {
        return response;
      });
    },

    /**
     * Get the shop laboratory orders.
     *
     * @param {string} laboratoryId - The laboratory.
     * @param {string} activeOrganiztionId - The active organization id.
     *
     * @returns {Promise} LShop laboratory orders promise.
     */
    getShopLaboratoryOrdersByHealthcenter: function getShopLaboratoryOrdersByHealthcenter(laboratoryId, activeOrganiztionId) {
      return apiClient.get("/shop/laboratories/" + laboratoryId + "/healthcenters/" + activeOrganiztionId + "/orders").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get the pharmas ml.
     *
     * @param {object} params - The request params.
     *
     * @returns {Promise} LShop laboratory orders promise.
     */
    getPharmamlsListing: function getPharmamlsListing(params) {
      return apiClient.post("/disruptions/pharmamls", params).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get dashboard infos.
     *
     * @param {object} params - The params.
     * @returns {Promise}
     */
    getDashboard: function getDashboard(params) {
      var config = {
        parameters: params
      };
      return apiClient.get("/disruptions/offers-monitoring", config).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get healthcenters with orders.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    getHealthcenters: function getHealthcenters(params) {
      var config = {
        parameters: _objectSpread(_objectSpread({}, params), {}, {
          include: "ordersForOffers.lines,emergencySellinOffers.notificationDisruptionAlert.notificationDisruptionAlertProducts.wholesaler"
        })
      };
      return apiClient.get("/disruptions/healthcenters-with-orders", config).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get emergency offers.
     *
     * @param {object} params - The params.
     *
     * @returns {Promise}
     */
    getEmergencyOffersForLaboratory: function getEmergencyOffersForLaboratory(params) {
      var config = {
        parameters: _objectSpread(_objectSpread({}, params), {}, {
          include: "notificationDisruptionAlert.notificationDisruptionAlertProducts.product"
        })
      };
      return apiClient.get("/disruptions/emergency-offers-laboratory", config).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get pharmamls by labo.
     *
     * @param {number} healthcenterId - The request params.
     *
     * @returns {Promise}
     */
    getPharmamlsByLabo: function getPharmamlsByLabo(healthcenterId) {
      return apiClient.get("/disruptions/pharmamls/" + healthcenterId).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.disruption").service("disruptionService", DisruptionService);