"use strict";
/**
 * Dashbord pharmacies edenred slider controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} homeSlideService - Home slide service.
 */

DashboardPharmaciesEdenredSliderController.$inject = ["$rootScope", "$scope", "homeSlideService"];

function DashboardPharmaciesEdenredSliderController($rootScope, $scope, homeSlideService) {
  /**
   * Declaration of public objects
   */
  $scope.sliders = homeSlideService.sliders;
  $scope.showDefault = false;
  /**
   * Initialization of the loader
   */

  loader();
  /**
   * Loader.
   */

  function loader() {
    if ($rootScope.branding) {
      if ($rootScope.groupingSlider.length > 0) {
        $rootScope.$broadcast("slideshow:refresh", $scope.commercialSlides);
      } else {
        $scope.showDefault = true;
      }
    }

    $scope.showDefault = !$rootScope.branding;
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesEdenredSliderController", DashboardPharmaciesEdenredSliderController);