"use strict";
/**
 * Cart service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

CartService.$inject = ["apiClient"];

function CartService(apiClient) {
  return {
    /**
     * Manage cart.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    manageCart: function manageCart(data) {
      return apiClient.post("/shop/laboratories/".concat(data.laboratoryId, "/healthcenters/").concat(data.healthcenterId, "/manageCart"), data).then(function (result) {
        return result.data;
      });
    },

    /**
     * Get cart.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getCart: function getCart(data) {
      var query = {
        includes: ["lines.product", "lines.product.laboratoryPrice", "lines.product.delivrance", "lines.product.genericType", "lines.product.notice", "lines.product.override", "lines.product.refund"]
      };
      return apiClient.get("/shop/laboratories/".concat(data.laboratoryId, "/healthcenters/").concat(data.healthcenterId, "/cart"), query).then(function (result) {
        return result.data;
      });
    },

    /**
     * Empty cart.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    emptyCart: function emptyCart(data) {
      return apiClient.get("/shop/laboratories/".concat(data.laboratoryId, "/healthcenters/").concat(data.healthcenterId, "/emptyCart"), {
        wrappers: {
          response: null
        }
      });
    },

    /**
     * Validate cart.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    validateCart: function validateCart(data) {
      return apiClient.get("/shop/laboratories/".concat(data.laboratoryId, "/healthcenters/").concat(data.healthcenterId, "/validateCart")).then(function (result) {
        return result.data;
      });
    },

    /**
     * Get orders.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getOrders: function getOrders(data) {
      return apiClient.get("/shop/laboratories/".concat(data.laboratoryId, "/healthcenters/").concat(data.healthcenterId, "/orders")).then(function (result) {
        return result.data;
      });
    },

    /**
     * Validate order.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    validateOrder: function validateOrder(data) {
      return apiClient.post("/shop/laboratories/".concat(data.laboratoryId, "/healthcenters/").concat(data.healthcenterId, "/validateOrder"), data).then(function (result) {
        return result.data;
      });
    }
  };
}

angular.module("app.services").service("cartService", CartService);