"use strict";
/**
 * The app-card-content directive.
 *
 * @returns {object}
 */

function AppCardContent() {
  return {
    restrict: "E",
    template: "<figcaption class='app-card-content' ng-transclude></figcaption>",
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appCardContent", AppCardContent);