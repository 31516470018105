"use strict";
/**
 * Goumy graph directive.
 *
 * @returns {object}
 */

goumyGraphController.$inject = ["$scope", "$q", "StatisticsQueryService", "environmentService", "analysisService"];

function GoumyGraphDirective() {
  return {
    restrict: "E",
    scope: {
      labId: "@",
      healthcenterId: "@"
    },
    templateUrl: "app/main/directives/stats/goumyGraph/goumyGraph.tmpl.html",
    controller: goumyGraphController
  };
}
/**
 * Goumy graph controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $q - $q.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */


function goumyGraphController($scope, $q, StatisticsQueryService, environmentService, analysisService) {
  $scope.legendStock = [];
  $scope.legendSurStock = [];
  $scope.deferredChartPieAmount0w90 = $q.defer();
  $scope.amChartOptionsPie0w90 = {
    data: $scope.deferredChartPieAmount0w90.promise,
    type: "pie",
    theme: "light",
    startDuration: 0,
    marginTop: 5,
    marginBottom: 0,
    marginLeft: 5,
    marginRight: 5,
    pullOutRadius: 10,
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    unit: " EUR HT",
    categoryField: "title",
    valueField: "amount",
    colorField: "color",
    titleField: "title",
    precision: 0,
    balloon: {
      fixedPosition: true
    },
    labelsEnabled: false,
    legend: {
      enabled: false
    },
    "export": {
      enabled: true
    }
  };
  $scope.colors = ["#D21E03", "#BB4507", "#D27703", "#C88B05", "#0AD247", "#00BB87", "#0A65C8"];
  $scope.groupByChart = {
    value: 1,
    title: "Laboratoires",
    type: "laboratory",
    filterId: "id"
  };
  var dataRangeGrossAmount0w90 = [];
  $scope.usrquery = StatisticsQueryService.query;
  environmentService.getEnvironment().then(function (env) {
    $scope.currentEnv = env;
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;

      if ($scope.currentEnv.accessPointType === 1) {
        $scope.query.must.laboratory = [$scope.labId];
      }

      if ($scope.healthcenterId) {
        $scope.query.must.healthcenter = [$scope.healthcenterId];
      }

      var today = moment().format("YYYY-MM-DD");
      $scope.query.startDatetime = moment(today).subtract(7, "months").format("YYYY-MM-DD");
      $scope.query.endDatetime = today;
      var colors = [{
        title: "< 10 jours",
        color: $scope.colors[0]
      }, {
        title: "Entre 10 et 20 jours",
        color: $scope.colors[1]
      }, {
        title: "Entre 21 et 30 jours",
        color: $scope.colors[2]
      }, {
        title: "Entre 31 et 60 jours",
        color: $scope.colors[3]
      }, {
        title: "Entre 61 et 90 jours",
        color: $scope.colors[4]
      }, {
        title: "> 90 jours",
        color: $scope.colors[5]
      }, {
        title: "Stock dormant",
        color: $scope.colors[6]
      }];
      $scope.totalSurStock = 0;
      $scope.totalStock = 0;
      var laboId = $scope.labId;

      if ($scope.currentEnv.accessPointType !== 1) {
        laboId = 0;
      }

      analysisService.getLaboratoryStockByGroup(laboId, $scope.groupByChart.value, $scope.query).then(function (response) {
        angular.forEach(response.aggregations.range_days.buckets, function (datas) {
          var nbRange = datas.key;
          var data = {};

          if (nbRange < 4) {
            $scope.legendStock.push({
              color: colors[nbRange].color,
              label: colors[nbRange].title,
              amount: datas.needs_amount.value
            });
            $scope.totalStock += datas.needs_amount.value;
            data = {
              range30days: "-" + nbRange + " mois",
              amount: datas.needs_amount.value,
              color: colors[nbRange].color,
              title: colors[nbRange].title
            };
            dataRangeGrossAmount0w90.push(data);
          } else {
            $scope.legendSurStock.push({
              color: colors[nbRange].color,
              label: colors[nbRange].title,
              amount: datas.order_amount.value
            });
            $scope.totalSurStock += datas.order_amount.value;
            data = {
              range30days: "-" + nbRange + " mois",
              amount: datas.order_amount.value,
              color: colors[nbRange].color,
              title: colors[nbRange].title
            };
            dataRangeGrossAmount0w90.push(data);
          }
        });
        $scope.deferredChartPieAmount0w90.resolve(dataRangeGrossAmount0w90);
      });
    });
  });
}

angular.module("app.directives").directive("goumyGraph", GoumyGraphDirective);