"use strict";
/**
 * Controller for loyalty card edit.
 *
 * @param {object} $scope - Scope
 * @param {object} $state - State
 * @param {object} $stateParams - State params
 * @param {object} $mdDialog - MD dialog
 * @param {string} apiurl - API URL.
 * @param {object} environmentService - Environment service
 * @param {object} loyaltyCardService - Loyalty card service
 * @param {object} loyaltyCardConfig - Loyalty card configuration
 */

LoyaltyCardDetailController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "apiurl", "environmentService", "loyaltyCardService", "loyaltyCardConfig"];

function LoyaltyCardDetailController($scope, $state, $stateParams, $mdDialog, apiurl, environmentService, loyaltyCardService, loyaltyCardConfig) {
  showProductsController.$inject = ["$scope", "products", "$mdDialog"];
  showTargetsController.$inject = ["$scope", "targets", "isAllTargetAllowed", "$mdDialog"];
  // Used to load the picture
  $scope.apiurl = apiurl;
  $scope.loyaltyCardId = $stateParams.id;
  $scope.loading = true;
  $scope.loadingMessage = "";
  $scope.goToList = goToList;
  $scope.showTargets = showTargets;
  $scope.showProducts = showProducts;
  $scope.openTab = openTab;
  $scope.tabs = [{
    id: 1,
    name: "Performances",
    slug: "performances",
    method: getPerformances,
    active: true
  }, {
    id: 2,
    name: "Profils consommateurs",
    method: getConsumersProfiles,
    slug: "consumers_profiles"
  }, {
    id: 3,
    name: "Gestion",
    slug: "card_management"
  }];
  loader();
  /**
   * Loader of the controller.
   *
   * @returns {Promise} The promise
   */

  function loader() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.currentAccess = access;
      getLoyaltyCard();
    });
  }
  /**
   * Get the loyalty card.
   */


  function getLoyaltyCard() {
    $scope.loading = true;
    $scope.loadingMessage = "Chargement de la carte de fidélité en cours.";

    if ($scope.loyaltyCardId) {
      var loyaltyCard = {
        id: $stateParams.id
      };
      loyaltyCardService.read(loyaltyCard).then(function (response) {
        var loyaltyCard = response;
        var products = [];
        var targets = []; // Initialize the sales by product, store productIds

        loyaltyCard.products.forEach(function (loyaltyCardProduct) {
          products.push({
            id: loyaltyCardProduct.productId,
            code: loyaltyCardProduct.product.code,
            name: loyaltyCardProduct.product.name
          });
        }); // Keep only the accessible / authorized healthcenters

        var target = null;
        loyaltyCard.targets.forEach(function (element) {
          target = null;

          if ($scope.currentAccess.accessPointType === 1 || element.entityId === $scope.currentAccess.entityId) {
            target = {
              id: element.id,
              name: element.organization.morphable.name,
              cip: element.organization.morphable.cip ? element.organization.morphable.cip : null,
              healthcenters: element.organization.morphable.healthcenters ? element.organization.morphable.healthcenters : null,
              type: element.organization.morphable_type
            };
            targets.push(target);
          }
        });
        $scope.loyaltyCard = {
          id: loyaltyCard.id,
          name: loyaltyCard.name,
          description: loyaltyCard.description,
          organization: loyaltyCard.organization,
          file: loyaltyCard.file,
          startDate: loyaltyCard.startDate,
          endDate: loyaltyCard.endDate,
          products: products,
          objectives: loyaltyCard.objectives,
          targets: targets
        };
        $scope.loading = false; // Open the first tab

        openTab($scope.tabs[0]);
        return $scope.loyaltyCard.id;
      });
    } else {
      $state.go("app.loyaltyCard");
    }
  }
  /**
   * Go to list.
   */


  function goToList() {
    $state.go("app.loyaltyCard");
  }
  /**
   * Show the targets array
   *
   * @param {Array} targets - Targets array
   * @param {boolean} isAllTargetAllowed - Is all target allowed
   * @param {object} event - The event
   */


  function showTargets(targets, isAllTargetAllowed, event) {
    $mdDialog.show({
      controller: showTargetsController,
      templateUrl: loyaltyCardConfig.baseDir + "includes/modal/detail/targets.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      locals: {
        targets: targets,
        isAllTargetAllowed: isAllTargetAllowed
      }
    });
  }
  /**
   * Show targets controller.
   *
   * @param {object} $scope - The scope
   * @param {Array} targets - The targets
   * @param {boolean} isAllTargetAllowed - Is all target allowed or not
   * @param {object} $mdDialog - MD Dialog
   */


  function showTargetsController($scope, targets, isAllTargetAllowed, $mdDialog) {
    $scope.targets = targets;
    $scope.isAllTargetAllowed = isAllTargetAllowed;
    $scope.cancel = cancel;
    /**
     * Cancel the dialog.
     */

    function cancel() {
      $mdDialog.cancel();
    }
  }
  /**
   * Show the products array
   *
   * @param {Array} products - Products array
   * @param {object} event - Event
   */


  function showProducts(products, event) {
    $mdDialog.show({
      controller: showProductsController,
      templateUrl: loyaltyCardConfig.baseDir + "includes/modal/detail/products.html",
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      locals: {
        products: products
      }
    });
  }
  /**
   * Show products controller.
   *
   * @param {object} $scope - Scope
   * @param {Array} products - Products
   * @param {object} $mdDialog - MD Dialog
   */


  function showProductsController($scope, products, $mdDialog) {
    $scope.products = products;
    $scope.cancel = cancel;
    /**
     * Cancel the dialog.
     */

    function cancel() {
      $mdDialog.cancel();
    }
  }
  /**
   * Open the tab
   *
   * @param {object} myTab - The tab to open
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var counter = 0; counter < tab.length; counter++) {
      tab[counter].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";

        if (tab.method) {
          tab.method();
        }
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Get the loyalty card performances.
   *
   * @returns {Promise} Promise
   */


  function getPerformances() {
    var loyaltyCard = {
      id: $stateParams.id
    };
    $scope.performances = [];
    return loyaltyCardService.getPerformances(loyaltyCard).then(function (response) {
      $scope.performances = response;
    });
  }
  /**
   * Get the loyalty card consumers profiles.
   *
   * @returns {Promise} Promise
   */


  function getConsumersProfiles() {
    var loyaltyCard = {
      id: $stateParams.id
    };
    $scope.consumersProfiles = [];
    return loyaltyCardService.getConsumersProfiles(loyaltyCard).then(function (response) {
      $scope.chartOptionsConsumerAgePyramid = getChartOptionsConsumerAgePyramid();
      $scope.chartOptionsConsumerAgePyramid.data = response.consumerAgePyramid.map(function (ageRange) {
        ageRange.male *= -1;
        return ageRange;
      });
    });
  }
  /**
   * Get options for consumer age pyramid chart.
   *
   * @returns {object} Chart options
   */


  function getChartOptionsConsumerAgePyramid() {
    return {
      type: "serial",
      theme: "light",
      rotate: true,
      marginBottom: 50,
      marginLeft: 50,
      data: [],
      graphs: [{
        fillAlphas: 1,
        lineAlpha: 0.2,
        type: "column",
        valueField: "male",
        title: "Homme",
        clustered: false,
        balloonFunction: function balloonFunction(item) {
          return item.category + " : " + Math.abs(item.values.value).toFixed(2) + " €";
        }
      }, {
        fillAlphas: 1,
        lineAlpha: 0.2,
        type: "column",
        valueField: "female",
        title: "Femme",
        fillColors: "#FF69B4",
        balloonFunction: function balloonFunction(item) {
          return item.category + " : " + Math.abs(item.values.value) + " €";
        }
      }],
      categoryField: "age",
      categoryAxis: {
        gridPosition: "start",
        gridAlpha: 0.2,
        axisAlpha: 0,
        parseDates: false
      },
      valueAxes: [{
        gridAlpha: 0,
        ignoreAxisWidth: true,
        labelFunction: function labelFunction(value) {
          return Math.abs(value) + " €";
        },
        guides: [{
          value: 0
        }]
      }],
      chartCursor: {
        valueBalloonsEnabled: false,
        cursorAlpha: 0.05,
        fullWidth: true
      },
      allLabels: [{
        text: "Homme",
        x: "28%",
        y: "97%",
        bold: true,
        align: "middle"
      }, {
        text: "Femme",
        x: "75%",
        y: "97%",
        bold: true,
        align: "middle"
      }],
      "export": {
        enabled: true
      }
    };
  }
}

angular.module("app.loyaltyCard").controller("loyaltyCardDetailController", LoyaltyCardDetailController);