"use strict";
/**
 * Dashboard laboratories stocks numbers controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} wholesalersService - Wholesalers service.
 * @param {object} environmentService - Environment service.
 */

DashboardLaboratoriesStocksNumbersController.$inject = ["$scope", "wholesalersService", "environmentService"];

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function DashboardLaboratoriesStocksNumbersController($scope, wholesalersService, environmentService) {
  $scope.loading = true;
  $scope.quantityTotal = 0;
  $scope.quantityMissing = 0;
  $scope.caTotal = 0;
  $scope.caMissing = 0;
  environmentService.getEnvironment().then(function (environment) {
    return wholesalersService.getCountLastMonth({
      id: environment.entityId
    });
  }).then(function (response) {
    if (_typeof(response) === "object") {
      $scope.quantityTotal = response.quantityTotal ? response.quantityTotal.value : 0;
      $scope.quantityMissing = response.quantityMissing ? response.quantityMissing.value : 0;
      $scope.caTotal = response.caTotal ? response.caTotal.value : 0;
      $scope.caMissing = response.caMissing ? response.caMissing.value : 0;
    }

    $scope.loading = false;
  });
}

angular.module("app.dashboard").controller("dashboardLaboratoriesStocksNumbersController", DashboardLaboratoriesStocksNumbersController);