"use strict";
/**
 * Sellin offer configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

SellinOfferConfig.$inject = ["$stateProvider"];

function SellinOfferConfig($stateProvider) {
  $stateProvider.state("app.sellinOffer", {
    url: "/offres-sellin",
    views: {
      "content@app": {
        templateUrl: "app/main/sellin-offer/views/list/sellinOffers.html",
        controller: "sellinOfferController"
      }
    }
  }).state("app.sellinOffer.new", {
    url: "/creation",
    views: {
      "content@app": {
        templateUrl: "app/main/sellin-offer/views/edit/edit.html",
        controller: "sellinOfferEditController"
      }
    },
    params: {
      name: null,
      description: null,
      laboratory: null,
      targets: null,
      products: null,
      wholesalerId: null
    }
  }).state("app.sellinOffer.edit", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/sellin-offer/views/edit/edit.html",
        controller: "sellinOfferEditController"
      }
    }
  }).state("app.sellinOffer.detail", {
    url: "/detail/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/sellin-offer/views/detail/detail.html",
        controller: "sellinOfferDetailController"
      }
    }
  });
}

angular.module("app.sellinOffer", []).config(SellinOfferConfig);