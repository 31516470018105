"use strict";
/**
 * Grouping members controller.
 *
 * @param {object} $state - $state.
 * @param {object} $scope - $scope.
 */

GroupingMembersController.$inject = ["$state", "$scope"];

function GroupingMembersController($state, $scope) {
  switch ($state.current.name) {
    case "app.groupingMembers.map":
      $scope.selectedIndex = 1;
      break;

    case "app.groupingMembers.history":
      $scope.selectedIndex = 2;
      break;

    case "app.groupingMembers.list":
    default:
      $scope.selectedIndex = 0;
  }
}

angular.module("app.grouping").controller("groupingMembersController", GroupingMembersController);