"use strict";
/**
 * Medication summary patient membership controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $window - $window.
 * @param {object} medicationSummaryService - Medication summary service.
 * @param {object} environmentService - Environment service.
 * @param {object} notifyUserService - Notify user service.
 */

MedicationSummaryPatientMembershipController.$inject = ["$scope", "$window", "medicationSummaryService", "environmentService", "notifyUserService"];

function MedicationSummaryPatientMembershipController($scope, $window, medicationSummaryService, environmentService, notifyUserService) {
  $scope.steps = [];
  $scope.stepIndex = 0;
  $scope.medicationSummaryFormData = {};
  $scope.mainTitle = {
    title: {
      text: "Bulletin d'adhésion de ",
      back: {
        state: "app.patients.medicationSummary.patient",
        tooltip: "Retour au bilan de médication du patient"
      }
    }
  };
  $scope.sendMembership = sendMembership;
  loader();
  /**
   * Loader
   */

  function loader() {
    getMedicationSummaryData();
    getMedicationSummaryForm();
  }
  /**
   * Get a medication summary for a given patient
   *
   * @returns {object}
   */


  function getMedicationSummaryData() {
    return medicationSummaryService.getMedicationSummaryMembership().then(function (medicationSummary) {
      environmentService.getEnvironment().then(function (env) {
        $scope.env = env;
        $scope.medicationSummary = medicationSummary;
        $scope.patientMembership = medicationSummary.patient;
        $scope.patientMembership.pharmacyName = env.entity.name;
        $scope.patientMembership.pharmacyTituFirstName = env.entity.manager.given;
        $scope.patientMembership.pharmacyTituName = env.entity.manager.familyBirth;
        $scope.mainTitle.title.text = $scope.mainTitle.title.text + $scope.patientMembership.firstName + " " + $scope.patientMembership.name;
        $scope.mainTitle.title.back.paramId = $scope.patientMembership.id;
        return medicationSummary;
      });
    });
  }
  /**
   * Get a medication summary form
   *
   * @returns {object}
   */


  function getMedicationSummaryForm() {
    return medicationSummaryService.readFormBySlug({
      slug: "bulletin-adhesion"
    }).then(function (medicationSummaryForm) {
      $scope.medicationSummaryForm = medicationSummaryForm; // Build tabs

      $scope.steps = medicationSummaryForm.tabs.map(function (tab) {
        return {
          slug: tab.slug,
          name: tab.name
        };
      });
      return medicationSummaryForm;
    });
  }
  /**
   * Send membership.
   */


  function sendMembership() {
    notifyUserService.showSimpleToast("Le bulletin a été transmis sur AMELIPRO");
    $window.history.back();
  }
}

angular.module("app.patients").controller("medicationSummaryPatientMembershipController", MedicationSummaryPatientMembershipController);