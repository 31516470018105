"use strict";
/**
 * Healthcenter grouping service.
 *
 * @param {object} apiClient - API client service.
 * @returns {object}
 */

HealthcenterGroupingService.$inject = ["apiClient"];

function HealthcenterGroupingService(apiClient) {
  return {
    /**
     * Assign.
     *
     * @param {object} data - The data.
     * @returns {Promise}
     */
    assign: function assign(data) {
      return apiClient.post("/healthcenters/grouping/assign", data, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Leave.
     *
     * @returns {Promise}
     */
    leave: function leave() {
      var params = {
        wrappers: {
          response: null
        }
      };
      return apiClient.post("/healthcenters/grouping/leaveGroup", {}, params).then(function (response) {
        return response.data;
      });
    },

    /**
     * History.
     *
     * @param {number} page - Page.
     * @param {number} limit - Limit.
     * @returns {Promise}
     */
    history: function history(page, limit) {
      return apiClient.get("/healthcenters/grouping/history/".concat(page, "/").concat(limit)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Search.
     *
     * @param {string} name - Grouping's name
     * @returns {Promise}
     */
    search: function search(name) {
      return apiClient.get("/grouping/search", {
        parameters: [{
          name: "name",
          value: name
        }],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.healthcenters").service("healthcenterGroupingService", HealthcenterGroupingService);