"use strict";
/**
 * Product analysis query service.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $q - $q.
 * @param {object} environmentService - Environment service.
 *
 * @returns {object}
 */

ProductAnalysisQueryService.$inject = ["$rootScope", "$q", "environmentService"];

function ProductAnalysisQueryService($rootScope, $q, environmentService) {
  var dateFrom = new Date(moment().month(-3));
  return {
    saveSubQuery: function saveSubQuery(obj) {
      this.substituteQuery = obj;
      $rootScope.$broadcast("analysis:subquery", obj);
    },
    saveRegQuery: function saveRegQuery(obj) {
      this.query = obj;
      $rootScope.$broadcast("analysis:regquery", obj);
    },
    query: {
      date: {
        pluralize: "e",
        name: "Période de Recherche",
        slug: "date",
        from: dateFrom,
        to: new Date()
      },
      must: {
        product: {
          pluralize: "",
          name: "Produit",
          slug: "product",
          selected: [],
          atclSelected: null,
          filter: "",
          belongs: "quali",
          atcl: "Products"
        }
      }
    },
    pristineQuery: {
      date: {
        pluralize: "e",
        name: "Période de Recherche",
        slug: "date",
        from: dateFrom,
        to: new Date()
      },
      product: {
        pluralize: "",
        name: "Produit",
        slug: "product",
        selected: [],
        atclSelected: null,
        filter: "",
        belongs: "quali",
        atcl: "Products"
      }
    },
    values: {
      refund: [{
        label: "Non",
        value: 0
      }, {
        label: "15%",
        value: 8
      }, {
        label: "30%",
        value: 1
      }, {
        label: "65%",
        value: 2
      }, {
        label: "100%",
        value: 3
      }, {
        label: "LPPR",
        value: 4
      }],
      tva: [{
        label: "2,1%",
        value: 5
      }, {
        label: "5,5%",
        value: 3
      }, {
        label: "10%",
        value: 8
      }, {
        label: "20%",
        value: 9
      }],
      delivrance: [{
        label: "Stupéfiant",
        value: 3
      }, {
        label: "Liste I",
        value: 1
      }, {
        label: "Liste II",
        value: 2
      }],
      generiques: [{
        label: "Non Génériqué",
        value: [0, 3]
      }, {
        label: "Génériques",
        value: 2
      }, {
        label: "Princeps",
        value: 1
      }]
    },
    datePresetFunc: function datePresetFunc(substract, type) {
      var from = moment().subtract(substract, type);
      return {
        from: from.toDate(),
        to: new Date()
      };
    },
    getFilterFromSlug: function getFilterFromSlug(needle) {
      // THE RETURN OF THIS SHOULD BE USED AS READ ONLY -
      // it will modify the current query object
      var ret = null;
      angular.forEach(this.query, function (value) {
        if (value.slug === needle) {
          ret = value;
        }
      });
      return ret;
    },
    buildquery: function buildquery(usrquery, date) {
      var query = $q.defer();
      var obj = {};
      obj.must = {};
      environmentService.getEnvironment().then(function (env) {
        var currentEnv = env;
        obj.startDatetime = date.from.toISOString().substr(0, 10);
        obj.endDatetime = date.to.toISOString().substr(0, 10);

        if (currentEnv && currentEnv.accessPointType === 2) {
          obj.healthcenter = [currentEnv.entity.cip];
        }

        if (usrquery.must.product.selected.length) {
          obj.must.product = usrquery.must.product.selected.map(function (currentValue) {
            return parseInt(currentValue.id);
          });
        }

        query.resolve(obj);
        return query.promise;
      });
      return query.promise;
    }
  };
}

angular.module("app.statistics").factory("ProductAnalysisQueryService", ProductAnalysisQueryService);