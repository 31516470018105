"use strict";
/**
 * Home configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

HomeConfig.$inject = ["$stateProvider"];

function HomeConfig($stateProvider) {
  $stateProvider.state("app.home", {
    url: "/accueil",
    views: {
      "content@app": {}
    }
  });
}

angular.module("app.home", []).config(HomeConfig);