"use strict";
/**
 * Sellout service.
 *
 * @param {object} apiClient - API client
 * @returns {object}
 */

SelloutService.$inject = ["apiClient"];

function SelloutService(apiClient) {
  return {
    /**
     * Get all sellouts.
     *
     * @param {object} parameters - Query parameters
     * @returns {Promise}
     */
    get: function get() {
      var parameters = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var params = {
        wrappers: {
          response: "sellouts"
        },
        parameters: parameters
      };
      return apiClient.get("/sellout", params).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read an offer.
     *
     * @param {number} offerId - The offer id.
     * @param {object} parameters - The parameters.
     * @returns {Promise}
     */
    readOffer: function readOffer(offerId) {
      var parameters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var params = {
        wrappers: {
          response: "offer"
        },
        parameters: parameters
      };
      return apiClient.get("/offer/".concat(offerId), params).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read a sellout.
     *
     * @param {number} selloutId - Sellout ID
     * @returns {Promise}
     */
    read: function read(selloutId) {
      return apiClient.get("/sellout/".concat(selloutId)).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a sellout.
     *
     * @param {object} sellout - Sellout data
     * @returns {Promise}
     */
    create: function create(sellout) {
      var params = {
        wrappers: {
          response: "sellout"
        }
      };
      return apiClient.post("/sellout", sellout, params).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update a sellout.
     *
     * @param {number} selloutId - Sellout ID
     * @param {object} sellout - Sellout data
     * @returns {Promise}
     */
    update: function update(selloutId, sellout) {
      return apiClient.put("/sellout/".concat(selloutId), sellout).then(function (response) {
        return response.data;
      });
    },

    /**
     * Publish a sellout.
     *
     * @param {number} selloutId - Sellout ID
     * @returns {Promise}
     */
    publish: function publish(selloutId) {
      return apiClient.put("/sellout/".concat(selloutId, "/publish")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Duplicate a sellout.
     *
     * @param {number} selloutId - selloutId
     * @returns {Promise}
     */
    duplicate: function duplicate(selloutId) {
      return apiClient.post("/sellout/".concat(selloutId, "/duplicate")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a sellout.
     *
     * @param {number} selloutId - Sellout ID to delete.
     * @returns {Promise} Deletion promise.
     */
    "delete": function _delete(selloutId) {
      return apiClient["delete"]("/sellout/".concat(selloutId)).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.sellout").service("selloutService", SelloutService);