"use strict";
/**
 * App widget content directive.
 *
 * @returns {object}
 */

function AppWidgetContent() {
  return {
    restrict: "EA",
    scope: {
      variant: "@variant"
    },
    templateUrl: "app/main/directives/app-widget/app-widget-content/appWidgetContent.html",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appWidgetContent", AppWidgetContent);