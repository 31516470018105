"use strict";
/**
 * Sales area management edit controller.
 *
 * @param {object} salesAreaManagementService - Sales area management service.
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 */

SalesAreaManagementEditController.$inject = ["salesAreaManagementService", "$scope", "$stateParams", "$state"];

function SalesAreaManagementEditController(salesAreaManagementService, $scope, $stateParams, $state) {
  $scope.salesArea = JSON.parse($stateParams.salesArea) || {};
  $scope.titleConfig = {
    title: {
      icon: "icon-cart",
      text: $scope.salesArea.id ? "Modifier un espace de vente" : "Créer un espace de vente",
      back: {
        state: "app.salesAreaManagement.list"
      }
    }
  };
  $scope.buttonLabel = $scope.salesArea.id ? "Modifier l'espace de vente" : "Créer un espace de vente";
  /**
   * Go to list
   */

  function goToList() {
    $state.go("app.salesAreaManagement.list");
  }

  loader();
  $scope.createSalesArea = createSalesArea;
  $scope.createOrUpdateSalesArea = createOrUpdateSalesArea;
  $scope.updateSalesArea = updateSalesArea;
  /**
   * Get types of sales area
   */

  function getSalesAreaTypes() {
    salesAreaManagementService.getSalesAreaTypes().then(function (salesAreaTypes) {
      $scope.salesAreaTypes = salesAreaTypes.salesAreaTypes;
    });
  }
  /**
   * The loading function
   */


  function loader() {
    getSalesAreaTypes();
  }
  /**
   * Create a sales area
   */


  function createSalesArea() {
    salesAreaManagementService.post($scope.salesArea).then(goToList);
  }
  /**
   * Update a sales area
   */


  function updateSalesArea() {
    salesAreaManagementService.update($scope.salesArea).then(goToList);
  }
  /**
   * The switch function for creation or update
   */


  function createOrUpdateSalesArea() {
    if ($scope.salesArea.id) {
      updateSalesArea();
    } else {
      createSalesArea();
    }
  }
}

angular.module("app.salesAreaManagement").controller("salesAreaManagementEditController", SalesAreaManagementEditController);