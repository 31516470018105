"use strict";
/**
 * Pack list service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

PackListService.$inject = ["apiClient"];

function PackListService(apiClient) {
  return {
    getSubscribable: function getSubscribable() {
      return apiClient.get("/packs/subscribable", {
        includes: ["entityType"],
        wrappers: {
          response: "packs"
        }
      }).then(function (response) {
        return response.data;
      });
    },
    getSubscribed: function getSubscribed() {
      return apiClient.get("/packs/subscribed", {
        includes: ["entityType"],
        wrappers: {
          response: "packs"
        }
      }).then(function (response) {
        return response.data;
      });
    },
    getAccesses: function getAccesses(slug) {
      return apiClient.get("/packs/".concat(slug, "/accesses"), {
        includes: ["organization.morphable.disruption"],
        parameters: [{
          name: "accessPointType",
          value: 1
        }],
        wrappers: {
          response: "accesses"
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.pack").service("packListService", PackListService);