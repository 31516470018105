"use strict";
/**
 * Helper directive.
 *
 * @returns {object}
 */

HelperController.$inject = ["$scope"];

function HelperDirective() {
  return {
    restrict: "E",
    scope: {
      tooltip: "@tooltip",
      url: "@url"
    },
    templateUrl: "app/main/directives/helper/helper.html",
    transclude: true,
    controller: HelperController,
    controllerAs: "vm"
  };
}
/**
 * Helper controller.
 *
 * @param {object} $scope - $scope.
 */


function HelperController($scope) {
  var vm = this;
  vm.tooltip = $scope.tooltip;
  vm.url = $scope.url;
}

angular.module("apodis").directive("helper", HelperDirective);