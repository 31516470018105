"use strict";
/**
 * Top analysis controller.
 *
 * @param {object} $q - The angular $q object.
 * @param {object} environmentService - Environment service.
 * @param {object} StatisticsQueryService - The query service.
 * @param {object} analysisService - Analysis service.
 * @param {object} $scope - The angular $scope object.
 */

TopAnalysisController.$inject = ["$q", "environmentService", "StatisticsQueryService", "analysisService", "$scope"];

function TopAnalysisController($q, environmentService, StatisticsQueryService, analysisService, $scope) {
  $scope.loading = true;
  $scope.currentEnv = environmentService.getEnvironment();
  $scope.sortBy = sortBy;
  $scope.fieldtoSortOn = "sales";
  loader();
  /**
   * Loader.
   */

  function loader() {
    StatisticsQueryService.queryRebase();
    $scope.top5Query = JSON.parse(JSON.stringify(StatisticsQueryService.pristineQuery));
    $scope.currentEnv.then(function () {
      $scope.dateLabel = moment($scope.top5Query.date.from).format("DD-MMM-YYYY") + " Au " + moment($scope.top5Query.date.to).format("DD-MMM-YYYY");
      getTopLab($scope.top5Query);
    });
  }

  $scope.rangeSelected = function (date) {
    $scope.top5Query.date.from = moment(date.startDate, "DD-MM-YYYY");
    $scope.top5Query.date.to = moment(date.endDate, "DD-MM-YYYY");
    StatisticsQueryService.saveRegQuery($scope.top5Query);
    getTopLab($scope.top5Query);
  };
  /**
   * Sort by.
   *
   * @param {string} sort - The value to sort on.
   */


  function sortBy(sort) {
    $scope.chartTva = operatorTvaChart();
    $scope.chartTva.data = $scope.topLaboTva21[sort].sort(function (value1, value2) {
      return value2[sort].value - value1[sort].value;
    });
    $scope.topLaboTva21[sort] = $scope.topLaboTva21[sort].sort(function (value1, value2) {
      return value2[sort] - value1[sort];
    });
    $scope.chartPara = operatorParaChart();
    $scope.chartPara.data = $scope.topLaboPara[sort].sort(function (value1, value2) {
      return value2[sort].value - value1[sort].value;
    });
    $scope.topLaboPara[sort] = $scope.topLaboPara[sort].sort(function (value1, value2) {
      return value2[sort] - value1[sort];
    });
    $scope.chartConseil = operatorConseilChart();
    $scope.chartConseil.data = $scope.topLaboOtc[sort].sort(function (value1, value2) {
      return value2[sort].value - value1[sort].value;
    });
    $scope.topLaboOtc[sort] = $scope.topLaboOtc[sort].sort(function (value1, value2) {
      return value2[sort] - value1[sort];
    });
  }
  /**
   * Operator TVA chart configuraiton.
   *
   * @returns {object}
   */


  function operatorTvaChart() {
    return {
      data: $scope.chartTvaData.promise,
      type: "serial",
      theme: "light",
      titles: [{
        size: 15,
        text: "TOP 5 Laboratoires"
      }],
      categoryField: "labo",
      categoryAxis: {
        parseDates: false,
        autoWrap: true
      },
      legend: {
        enabled: true,
        useGraphSettings: true
      },
      chartScrollbar: {
        enabled: false
      },
      chartCursor: {
        zoomable: false
      },
      startDuration: 0,
      valueAxes: [{
        id: "v1",
        axisThickness: 2,
        title: "unités",
        axisAlpha: 1,
        position: "left"
      }, {
        id: "v2",
        axisThickness: 2,
        axisAlpha: 1,
        title: "CA",
        position: "right"
      }],
      graphs: [{
        valueAxis: "v1",
        title: "Unités",
        valueField: "unit",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " unités";
        },
        type: "column"
      }, {
        valueAxis: "v2",
        title: "CA",
        valueField: "sales",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " €";
        },
        type: "column"
      }],
      plotAreaFillAlphas: 0.1,
      "export": {
        enabled: true
      }
    };
  }
  /**
   * Operator parapharmacy chart configuration.
   *
   * @returns {object}
   */


  function operatorParaChart() {
    return {
      data: $scope.chartParaData.promise,
      type: "serial",
      theme: "light",
      categoryField: "labo",
      titles: [{
        size: 15,
        text: "TOP 5 Parapharmacie"
      }],
      categoryAxis: {
        parseDates: false,
        autoWrap: true
      },
      legend: {
        enabled: true,
        useGraphSettings: true
      },
      chartScrollbar: {
        enabled: false
      },
      chartCursor: {
        zoomable: false
      },
      startDuration: 0,
      valueAxes: [{
        id: "v1",
        axisThickness: 2,
        title: "unités",
        axisAlpha: 1,
        position: "left"
      }, {
        id: "v2",
        axisThickness: 2,
        axisAlpha: 1,
        title: "CA",
        position: "right"
      }],
      graphs: [{
        valueAxis: "v1",
        title: "Unités",
        valueField: "unit",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " unités";
        },
        type: "column"
      }, {
        valueAxis: "v2",
        title: "CA",
        valueField: "sales",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " €";
        },
        type: "column"
      }],
      plotAreaFillAlphas: 0.1,
      "export": {
        enabled: true
      }
    };
  }
  /**
   * Operator conseil chart configuration.
   *
   * @returns {object}
   */


  function operatorConseilChart() {
    return {
      marginLeft: 60,
      data: $scope.chartOtcData.promise,
      type: "serial",
      theme: "light",
      titles: [{
        size: 15,
        text: "TOP 5 OTC"
      }],
      categoryField: "labo",
      categoryAxis: {
        parseDates: false,
        autoWrap: true
      },
      legend: {
        useGraphSettings: true
      },
      chartScrollbar: {
        enabled: false
      },
      chartCursor: {
        zoomable: false
      },
      synchronizeGrid: true,
      startDuration: 0,
      valueAxes: [{
        id: "v1",
        axisThickness: 2,
        title: "unités",
        axisAlpha: 1,
        position: "left"
      }, {
        id: "v2",
        axisThickness: 2,
        axisAlpha: 1,
        title: "CA",
        position: "right"
      }],
      graphs: [{
        valueAxis: "v1",
        title: "Unités",
        valueField: "unit",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " unités";
        },
        type: "column"
      }, {
        valueAxis: "v2",
        title: "CA",
        valueField: "sales",
        fillAlphas: 1,
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " €";
        },
        type: "column"
      }],
      plotAreaFillAlphas: 0.1,
      "export": {
        enabled: true
      }
    };
  }
  /**
   * Get top laboratory.
   *
   * @param {object} query -
   */


  function getTopLab(query) {
    $scope.chartTvaData = $q.defer();
    $scope.chartParaData = $q.defer();
    $scope.chartOtcData = $q.defer();
    $scope.loading = true;
    query.must.tva.selected = [];
    query.must.product.selected = [];
    $scope.chartTva = operatorTvaChart();
    StatisticsQueryService.buildquery(query, query.date).then(analysisService.getTop5Laboratory).then(function (data) {
      var tvaData = {
        sales: [],
        unit: []
      };
      angular.forEach(data.aggregations.salesLaboratory.buckets, function (dataTva, index) {
        tvaData.sales.push({
          labo: dataTva.key,
          sales: dataTva.sales.value,
          unit: dataTva.unit.value
        });
        tvaData.unit.push({
          labo: data.aggregations.unitLaboratory.buckets[index].key,
          sales: data.aggregations.unitLaboratory.buckets[index].sales.value,
          unit: data.aggregations.unitLaboratory.buckets[index].unit.value
        });
      });
      $scope.topLaboTva21 = tvaData;
      $scope.chartTvaData.resolve(tvaData.sales);
      query.must.tva.selected = [];
      $scope.chartPara = operatorParaChart();
      query.must.category.selected.push({
        id: 1403
      });
      return StatisticsQueryService.buildquery(query, query.date);
    }).then(analysisService.getTop5Laboratory).then(function (data) {
      var paraData = {
        sales: [],
        unit: []
      };
      angular.forEach(data.aggregations.salesLaboratory.buckets, function (dataPara, index) {
        paraData.sales.push({
          labo: dataPara.key,
          sales: dataPara.sales.value,
          unit: dataPara.unit.value
        });
        paraData.unit.push({
          labo: data.aggregations.unitLaboratory.buckets[index].key,
          sales: data.aggregations.unitLaboratory.buckets[index].sales.value,
          unit: data.aggregations.unitLaboratory.buckets[index].unit.value
        });
      });
      $scope.topLaboPara = paraData;
      $scope.chartParaData.resolve(paraData.sales);
      query.must.category.selected = [];
      query.must.tva.selected.push({
        value: 8
      });
      $scope.chartConseil = operatorConseilChart();
      return StatisticsQueryService.buildquery(query, query.date);
    }).then(analysisService.getTop5Laboratory).then(function (data) {
      var otcData = {
        sales: [],
        unit: []
      };
      angular.forEach(data.aggregations.salesLaboratory.buckets, function (dataOtc, index) {
        otcData.sales.push({
          labo: dataOtc.key,
          sales: dataOtc.sales.value,
          unit: dataOtc.unit.value
        });
        otcData.unit.push({
          labo: data.aggregations.unitLaboratory.buckets[index].key,
          sales: data.aggregations.unitLaboratory.buckets[index].sales.value,
          unit: data.aggregations.unitLaboratory.buckets[index].unit.value
        });
      });
      $scope.topLaboOtc = otcData;
      $scope.chartOtcData.resolve(otcData.sales);
      $scope.loading = false;
    });
  }
}

angular.module("app.statistics.salesAnalysis").controller("topAnalysisController", TopAnalysisController);