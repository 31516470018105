"use strict";

DashboardLaboratoriesAverageShoppingCartController.$inject = ["$scope", "dashboardAnalysisService"];
angular.module("app.dashboard").controller("dashboardLaboratoriesAverageShoppingCartController", DashboardLaboratoriesAverageShoppingCartController);
/**
 * @ngInject
 *
 * @param {object} $scope - Angular scope.
 * @param {object} dashboardAnalysisService - Dashboard analysis service.
 */

function DashboardLaboratoriesAverageShoppingCartController($scope, dashboardAnalysisService) {
  $scope.averageShoppingCart = {};
  $scope.healthcenterCount = 0;
  loader();
  /**
   * Load the sales data.
   */

  function loader() {
    getAverageShoppingCartDashboard();
  }
  /**
   * Load average shopping cart dashboard.
   *
   * @returns {object} The average shopping cart dashboard promise.
   */


  function getAverageShoppingCartDashboard() {
    $scope.averageShoppingCart.today = {};
    $scope.averageShoppingCart.month = {};
    $scope.averageShoppingCart.year = {};
    $scope.averageShoppingCart.todayLastYear = {};
    $scope.averageShoppingCart.monthLastYear = {};
    $scope.averageShoppingCart.yearLastYear = {};
    return dashboardAnalysisService.getAverageShoppingCartDashboard().then(function (response) {
      var cartsData = response.carts.responses;
      $scope.healthcenterCount = cartsData[2].aggregations.healthcenterQuantity.value;
      $scope.averageShoppingCart.today.caHt = cartsData[0].aggregations.salesForCart.value / cartsData[0].aggregations.quantityOfCart.value;
      $scope.averageShoppingCart.today.unit = cartsData[0].aggregations.quantityForCart.value / cartsData[0].aggregations.quantityOfCart.value;
      $scope.averageShoppingCart.month.caHt = cartsData[1].aggregations.salesForCart.value / cartsData[1].aggregations.quantityOfCart.value;
      $scope.averageShoppingCart.month.unit = cartsData[1].aggregations.quantityForCart.value / cartsData[1].aggregations.quantityOfCart.value;
      $scope.averageShoppingCart.year.caHt = cartsData[2].aggregations.salesForCart.value / cartsData[2].aggregations.quantityOfCart.value;
      $scope.averageShoppingCart.year.unit = cartsData[2].aggregations.quantityForCart.value / cartsData[2].aggregations.quantityOfCart.value;
      $scope.averageShoppingCart.todayLastYear.caHt = cartsData[3].aggregations.salesForCart.value / cartsData[3].aggregations.quantityOfCart.value;
      $scope.averageShoppingCart.todayLastYear.unit = cartsData[3].aggregations.quantityForCart.value / cartsData[3].aggregations.quantityOfCart.value;
      $scope.averageShoppingCart.monthLastYear.caHt = cartsData[4].aggregations.salesForCart.value / cartsData[4].aggregations.quantityOfCart.value;
      $scope.averageShoppingCart.monthLastYear.unit = cartsData[4].aggregations.quantityForCart.value / cartsData[4].aggregations.quantityOfCart.value;
      $scope.averageShoppingCart.yearLastYear.caHt = cartsData[5].aggregations.salesForCart.value / cartsData[5].aggregations.quantityOfCart.value;
      $scope.averageShoppingCart.yearLastYear.unit = cartsData[5].aggregations.quantityForCart.value / cartsData[5].aggregations.quantityOfCart.value;
      return response;
    });
  }
}