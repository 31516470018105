"use strict";
/**
 * Dashboard pharmacy sales analysis controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} ChartSettingsService - Chart settings service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */

dashboardPharmaciesSalesAnalysisController.$inject = ["$scope", "ChartSettingsService", "StatisticsQueryService", "environmentService", "analysisService"];

function dashboardPharmaciesSalesAnalysisController($scope, ChartSettingsService, StatisticsQueryService, environmentService, analysisService) {
  $scope.LabID = 0;
  $scope.loading = true;
  $scope.loadingChart = true;
  $scope.dataChartSerial = [];
  $scope.lgoCompatibility = false;
  $scope.labDashbd = false;
  $scope.hcDashbd = false;
  $scope.hcUnplgdDashbd = false;
  $scope.empDashbd = false;
  $scope.grpDashbd = false;
  $scope.detailRate = [];
  loader();
  /**
   * Loader.
   */

  function loader() {
    loaderEnv().then(function () {
      initSalesAnalysisChart();
    });
  }
  /**
   * Load the environment.
   *
   * @returns {Promise}
   */


  function loaderEnv() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.env = response;
      return response;
    });
  }
  /**
   * Init sales graph options.
   */


  function initSalesAnalysisChart() {
    $scope.salesLoading = true;
    $scope.serialSalesOptions = getSalesAnalysisChartOptions();
    $scope.serialSalesOptions.legend.valueWidth = 100;
    $scope.serialSalesOptions.legend.valueAlign = "left";
    launchStatisticsQueries(false);
  }
  /**
   * Launch statistic query.
   */


  function launchStatisticsQueries() {
    $scope.usrquery = JSON.parse(JSON.stringify(StatisticsQueryService.pristineQuery));

    if ($scope.env.accessPointType === 2 && $scope.env.rankId !== 1 && $scope.env.rankId !== 2 && !_.find($scope.usrquery.must.operator.selected, function (operator) {
      return operator.id === $scope.env.operatorCode;
    })) {
      $scope.usrquery.must.operator.selected.push({
        id: $scope.env.operatorCode
      });
    }

    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      processSalesAnalysisQuery(query).then(function (data) {
        query.startDatetime = moment($scope.query.startDatetime).subtract("year", 1).format("YYYY-MM-DD");
        query.endDatetime = moment($scope.query.endDatetime).subtract("year", 1).format("YYYY-MM-DD");
        $scope.sales = data;
        processSalesAnalysisQuery(query).then(function (dataLastYear) {
          $scope.salesLastYear = dataLastYear;
          processSalesData();
        });
      });
    });
  }
  /**
   * Retrieve sales analysis options.
   *
   * @returns {object}
   */


  function getSalesAnalysisChartOptions() {
    var salesGraphs = {
      type: "serial",
      data: [],
      startDuration: 0,
      marginTop: 40,
      marginRight: 60,
      legend: {
        useGraphSettings: true,
        marginTop: 20
      },
      valueAxes: [{
        id: "ca",
        axisAlpha: 1,
        position: "left",
        gridAlpha: 0.1,
        axisColor: "#9AD8E0",
        labelFunction: function labelFunction(value) {
          return d3.format(".2s")(value) + "€";
        }
      }, {
        id: "salesLastYear",
        axisAlpha: 1,
        position: "left",
        gridAlpha: 0,
        axisColor: "#0B98A8",
        offset: 60,
        labelFunction: function labelFunction(value) {
          return d3.format(".2s")(value);
        }
      }],
      graphs: [{
        id: "g1",
        valueField: "sales",
        valueAxis: "ca",
        title: "Ventes totales",
        bullet: "round",
        bulletBorderColor: "#FFFFFF",
        lineColor: "#0B98A8",
        bulletBorderAlpha: 1,
        bulletBorderThickness: 2,
        animationPlayed: false,
        balloon: {
          drop: false,
          adjustBorderColor: true,
          color: "#212121"
        },
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + "€";
        }
      }, {
        id: "g3",
        valueField: "salesLastYear",
        valueAxis: "salesLastYear",
        title: "Ventes totales N-1",
        bullet: "round",
        bulletBorderColor: "#FFFFFF",
        bulletBorderAlpha: 1,
        bulletBorderThickness: 2,
        animationPlayed: false,
        lineColor: "#9AD8E0",
        balloon: {
          drop: false,
          adjustBorderColor: true,
          color: "#212121"
        },
        balloonFunction: function balloonFunction(graphDataItem) {
          return d3.format(".2s")(graphDataItem.values.value) + " €";
        }
      }],
      chartCursor: {
        cursorColor: "#888888",
        cursorAlpha: 0.5,
        categoryBalloonDateFormat: "DD MMM YYYY",
        fullWidth: false
      },
      categoryField: "date",
      categoryAxis: {
        gridColor: "#F2F2F2",
        gridAlpha: 0.3,
        dateFormats: [{
          period: "fff",
          format: "JJ:NN:SS"
        }, {
          period: "ss",
          format: "JJ:NN:SS"
        }, {
          period: "mm",
          format: "JJ:NN"
        }, {
          period: "hh",
          format: "JJ:NN"
        }, {
          period: "DD",
          format: "DD MMM"
        }, {
          period: "WW",
          format: "DD MMM"
        }, {
          period: "MM",
          format: "MMM YYYY"
        }, {
          period: "YYYY",
          format: "MMM YYYY"
        }],
        parseDates: true,
        minorGridAlpha: 0.1,
        minorGridEnabled: true
      },
      "export": {
        enabled: true,
        menu: [{
          "class": "export-main",
          menu: [{
            format: "PNG",
            title: "Exporter au format PNG"
          }, {
            format: "JPG",
            title: "Exporter au format JPG"
          }]
        }]
      }
    };
    salesGraphs = angular.extend(salesGraphs, ChartSettingsService.getLineObj());
    salesGraphs.graphs = mergeGraphObjects(salesGraphs.graphs);

    salesGraphs.legend.valueFunction = function (graphDataItem) {
      if (graphDataItem && graphDataItem.graph) {
        var value = Math.round(graphDataItem.values.value).formatMoney(0, 3, " ", ", ") + " €";
        return value;
      }

      return "";
    };

    return salesGraphs;
  }
  /**
   * Merge graphs with default graph options.
   *
   * @param {object} graphs - The graph object to merge.
   * @returns {Array}
   */


  function mergeGraphObjects(graphs) {
    var ngraphs = [];
    angular.forEach(graphs, function (data) {
      var obj = angular.extend(data, ChartSettingsService.getLineGraphObj());
      ngraphs.push(obj);
    });
    return ngraphs;
  }
  /**
   * Process the query.
   *
   * @param {object} query - The query to pass to the API.
   *
   * @returns {Promise}
   */


  function processSalesAnalysisQuery(query) {
    query.interval = "1M";
    return analysisService.getSales(query);
  }
  /**
   * Handle the data.
   */


  function processSalesData() {
    var lines = $scope.sales.aggregations.sales.buckets.map(function (line, index) {
      var salesLastYearForMonth = $scope.salesLastYear.aggregations.sales.buckets[index];
      return {
        date: line.key,
        sales: line.amount_excl_tax.value,
        salesLastYear: salesLastYearForMonth.amount_excl_tax.value
      };
    });
    $scope.serialSalesOptions.data = lines;
    $scope.salesLoading = false;
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesSalesAnalysisController", dashboardPharmaciesSalesAnalysisController);