"use strict";
/**
 * Statistic stock controller.
 *
 * @param {object} environmentService - The environment service.
 * @param {object} $scope - The angular $scope object.
 * @param {object} $stateParams - The angular $stateParams object.
 * @param {object} $filter - The angular $filter object.
 * @param {object} $location - The angular $location object.
 * @param {object} StatisticsQueryService - The statistics query service.
 * @param {object} healthcenterAuditHcDetailsService - The healthcenter audit service.
 * @param {object} analysisService - Analysis service.
 */

StatisticsStockController.$inject = ["environmentService", "$scope", "$stateParams", "$filter", "$location", "StatisticsQueryService", "healthcenterAuditHcDetailsService", "analysisService"];

function StatisticsStockController(environmentService, $scope, $stateParams, $filter, $location, StatisticsQueryService, healthcenterAuditHcDetailsService, analysisService) {
  var vm = this;
  var eur = d3.locale({
    decimal: ".",
    thousands: " ",
    grouping: [3],
    currency: ["", "€"],
    dateTime: "%a %b %e %X %Y",
    date: "%m/%d/%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  });
  var psd3 = {};

  var _this = this;

  var brdBp = [{
    dataset: null,
    label: "Votre Recherche"
  }];
  var goBackTo = null;
  var dirtyReturn = false;
  vm.filters = {};
  vm.addFilter = addFilter;
  vm.configSearch = {
    event: "stockFilterChange",
    search: []
  };
  vm.tooltip = {
    config: {
      color: "#CCC",
      backgroundColor: "rgba(68, 68, 68, 0.85)"
    },
    display: function display(data) {
      var innerCircle = 0;
      angular.forEach(vm.elasticdata.data, function (data) {
        innerCircle += data.value;
      });
      angular.forEach(vm.elasticdata.data, function (data) {
        data.total = innerCircle;
        angular.forEach(data.drilldown, function (_data) {
          _data.total = data.value;
        });
      });
      var tooltip = document.getElementById(_this.tooltipId);
      tooltip.style.position = "fixed";
      tooltip.style.color = this.config.color;
      tooltip.style.backgroundColor = this.config.backgroundColor;
      tooltip.innerHTML = this.format(data.data); // hide

      tooltip.classList.remove("psd3Hidden");
      d3.event.target.addEventListener("mousemove", function (event) {
        var screenHeight = angular.element(document).height();
        var el = angular.element("#chartStockContainer_tooltip");
        var height = el.height();
        var offsetLeft = 250;
        var offsetTop = 150;
        var mouseOffset = 50;
        var left = offsetLeft + mouseOffset + event.clientX;
        var top = offsetTop - Math.round(height / 2) + event.clientY;
        top = Math.max(0, top);
        top = Math.min(top, screenHeight - height);
        tooltip.style.left = left + "px";
        tooltip.style.top = top + "px";
      });
    },
    format: function format(data) {
      var unit; // display unit type

      var ret = "";
      ret += "<table>";
      ret += "<thead><tr md-row><th>Nom</th><th>Quantité</th></tr></thead>";
      ret += "<tbody>";
      data.brothers.forEach(function (brother) {
        if (vm.form.by === "price_ht") {
          unit = eur.numberFormat("$,.2f")(brother.value);
        } else {
          unit = d3.format(",.3")(brother.value) + " unités";
        }

        ret += "<tr md-row" + (brother.id === data.id ? " class='selected'" : "") + "><td md-cell>" + brother.label + "</td><td md-cell>" + unit + "&nbsp</td></tr>";
      });
      ret += "</tbody>";
      ret += "</table>";
      return ret;
    }
  };
  vm.rawValues = StatisticsQueryService.values;
  vm.showMoreFilters = false;
  vm.sortableOptions = {
    placeholder: "app-ph2",
    "ui-floating": true,
    connectWith: ".valuesOptions",
    stop: function stop() {
      if (vm.movedApp) {
        StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function () {
          processStocksQuery();
        });
      }
    },
    beforeStop: function beforeStop(event, ui) {
      // on drop change the category if needed
      vm.movedApp = findAppById(vm.form.values, ui.helper[0].id);

      if (vm.movedApp) {
        var newColumn = ui.helper.parent()[0].getAttribute("app-column");

        if (vm.movedApp.checked !== newColumn) {
          vm.movedApp.checked = newColumn === "true";
        }
      }
    }
  };
  vm.getLimits = getLimits;
  vm.cbChanged = cbChanged;
  vm.canEclate = canEclate;
  vm.showMoreFilter = showMoreFilter;
  vm.removeFilter = removeFilter;
  vm.toggleCb = toggleCb;
  vm.indexByLabel = indexByLabel;
  vm.existsFilter = existsFilter;
  vm.addFilters = addFilters;
  vm.checkOpt = checkOpt;
  vm.changeTarget = changeTarget;
  loader();
  /**
   * The loading function.
   */

  function loader() {
    vm.loading = true;
    vm.colors = ["#fab231", "#faeb4c", "#b7d787", "#89b74e", "#85caa0", "#9bd8df", "#218b9a"];
    vm.usrquery = StatisticsQueryService.query;
    vm.dateLabel = moment(vm.usrquery.date.from).format("DD-MMM-YYYY") + " Au " + moment(vm.usrquery.date.to).format("DD-MMM-YYYY");
    vm.dataset = [];
    vm.form = {
      isBlurred: false,
      nbEl: {
        min: 2,
        max: 25,
        model: 15
      },
      by: "quantity",
      values: StatisticsQueryService.aggBy
    };
    vm.valuesChecked = $filter("filter")(vm.form.values, {
      checked: true
    });
    vm.valuesNotChecked = $filter("filter")(vm.form.values, {
      checked: false
    });
    vm.valuesChecked.forEach(function (data) {
      vm.valuesNotChecked.push(data);
    });
    environmentService.getEnvironment().then(function (env) {
      angular.forEach(vm.form.values, function (type) {
        vm.currentEnv = env;

        if (typeof type.rankNeeded !== "undefined") {
          type.canEclate = type.rights.indexOf(vm.currentEnv.accessPointType) > -1 && type.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
        } else {
          type.canEclate = type.rights.indexOf(vm.currentEnv.accessPointType) > -1;
        }
      });

      if ($stateParams.id) {
        $scope.hasHealthcenterId = true;
        healthcenterAuditHcDetailsService.hCDetail($stateParams.id).then(function (pharma) {
          var pharmaInfos = {
            id: pharma.healthcenter.id,
            name: pharma.healthcenter.name,
            type: "pharmas",
            cip: pharma.healthcenter.cip
          };
          vm.usrquery.must.pharmas.selected = [pharmaInfos];
          StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
            vm.query = query;
            processStocksQuery();
          });
        });
      }

      if (vm.currentEnv.accessPointType === 1 && vm.usrquery.must.laboratory.selected.length === 0) {
        var lab = {
          id: vm.currentEnv.entityId,
          name: vm.currentEnv.entity.name,
          type: "laboratory"
        };
        vm.usrquery.must.laboratory.selected.push(lab);
      }

      if (!$scope.hasHealthcenterId) {
        StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function () {
          processStocksQuery();
        });
      }
    });
  }
  /**
   * get limits.
   *
   * @param {Array} array - The array
   *
   * @returns {Array}
   */


  function getLimits(array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  }
  /**
   * Change target
   */


  function changeTarget() {
    if (vm.form.nbEl.model > 30) {
      vm.form.nbEl.model = 30;
    }

    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function () {
      processStocksQuery();
    });
  }
  /**
   * Filter the results by quantity or stockDays
   */


  function addFilter() {
    if (vm.filters.comparator && vm.filters.value && vm.filters.type) {
      processStocksQuery();
    }
  }
  /**
   * Process query.
   */


  function processStocksQuery() {
    vm.loading = true;
    angular.element(document.getElementById("chartStockContainer")).html("");
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      vm.query = query;
      vm.query.buckets = getBuckets();

      if (vm.filters.comparator && vm.filters.value && vm.filters.type) {
        vm.query.filters = vm.filters;
      }

      analysisService.getStockList(vm.query).then(function (response) {
        vm.loading = false;
        vm.elasticdata = response;
        parseData();
      });
    });
  }
  /**
   * Show more filter
   */


  function showMoreFilter() {
    vm.showMoreFilters = !vm.showMoreFilters;
  }
  /**
   * Find app.
   *
   * @param {object} appList - The app list.
   * @param {string} id - The id.
   *
   * @returns {object|null}
   */


  function findAppById(appList, id) {
    // IDs are unique so we know we"ll only return one app or null
    var app = $.grep(appList, function (event) {
      return event.id === id;
    })[0];

    if (app) {
      return app;
    }

    return null;
  }
  /**
   * Can eclate.
   *
   * @param {object} type - The type of eclatment.
   *
   * @returns {boolean}
   */


  function canEclate(type) {
    if (typeof vm.currentEnv === "undefined") {
      return environmentService.getEnvironment().then(function (env) {
        vm.currentEnv = env;

        if (typeof type.rankNeeded !== "undefined") {
          return type.rights.indexOf(vm.currentEnv.accessPointType) > -1 && type.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
        }

        return type.rights.indexOf(vm.currentEnv.accessPointType) > -1;
      });
    } else if (typeof type.rankNeeded !== "undefined") {
      return type.rights.indexOf(vm.currentEnv.accessPointType) > -1 && type.rankNeeded.indexOf(vm.currentEnv.rankId) > -1;
    }

    return type.rights.indexOf(vm.currentEnv.accessPointType) > -1;
  }
  /**
   * cbChanged.
   *
   * @param {object} item - The item.
   * @param {number} index - The index.
   */


  function cbChanged(item, index) {
    vm.form.values.splice(index, 1);

    if (item.model === false) {
      vm.form.values.push(item);
    } else {
      var idx = getFirstchecked() + 1;
      vm.form.values.splice(idx, 0, item);
    }

    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function () {
      processStocksQuery();
    });
  }
  /**
   * Get first checked.
   *
   *
   * @returns {number}
   */


  function getFirstchecked() {
    var idx = 0;
    angular.forEach(vm.form.values, function (data, index) {
      if (data.model === true) {
        idx = index;
      }
    });
    return idx;
  }
  /**
   * Get Buckets
   *
   *
   * @returns {Array}
   */


  function getBuckets() {
    var buckets = [];
    angular.forEach(vm.valuesChecked, function (data) {
      if (data.model && data.checked === true) {
        buckets.push({
          term: data.val,
          size: vm.form.nbEl.model
        });
      }
    });
    return buckets;
  }
  /**
   * Get total.
   *
   * @param {Array} data - The data.
   * @param {number} totality - The total.
   */


  function getTotal(data, totality) {
    angular.forEach(data, function (datum) {
      datum.total = totality;

      if (datum.drilldown.length > 0) {
        getTotal(datum.drilldown, datum.value);
      }
    });
  }
  /**
   * Populate brothers.
   *
   * @param {Array} data - The data.
   */


  function populateBrothers(data) {
    angular.forEach(data, function (datum) {
      datum.brothers = data.map(function (value) {
        return {
          id: value.id,
          label: value.label,
          value: value.value
        };
      });

      if (datum.drilldown.length > 0) {
        populateBrothers(datum.drilldown);
      }
    });
  }
  /**
   * Parse data
   */


  function parseData() {
    angular.forEach(vm.elasticdata.data, function (datum) {
      if (datum.drilldown && datum.drilldown.length > 0) {
        getTotal(datum.drilldown, datum.value);
      }
    });
    populateBrothers(vm.elasticdata.data);
    vm.config = {
      containerId: "chartStockContainer",
      width: angular.element(document.getElementById("chartStockContainer")).width(),
      height: angular.element(document.getElementById("chartStockContainer")).height(),
      data: vm.elasticdata.data,
      label: function label() {
        return "";
      },
      value: "value",
      inner: "drilldown",
      tooltip: function tooltip(data) {
        var unit;

        if ($scope.form.by === "price_ht") {
          unit = eur.numberFormat("$,.2f")(data.value);
        } else {
          unit = d3.format(",.3")(data.value) + " unités";
        }

        return "<table>" + "<thead><tr md-row><th>Champ</th><th>Valeur</th></tr></thead><tbody><tr md-row>" + "<td md-cell>" + data.label + "</td><td md-cell>" + unit + "</td></tr></tbody></table>";
      },
      transition: "none",
      transitionDuration: 0,
      donutRadius: 50,
      gradient: false,
      labelColor: "white",
      colors: d3.scale.category20c(),
      // getColors(),
      stroke: "#eee",
      strokeWidth: 1,
      drilldownTransition: "none",
      drilldownTransitionDuration: 0
    };
    var pie = document.getElementById(vm.config.containerId);

    if (pie) {
      vm.pie = new psd3.Pie(vm.config);
    }

    vm.form.isBlurred = false;
  }
  /**
   * Remove filter.
   *
   * @param {object} opt - The option.
   */


  function removeFilter(opt) {
    var index = vm.valuesChecked.indexOf(opt);

    if (index !== -1) {
      vm.valuesChecked.forEach(function (optChecked) {
        if (opt.id === optChecked.id) {
          optChecked.checked = false;
        }
      });
      vm.valuesChecked.splice(index, 1);
      vm.loading = true;

      if (vm.valuesChecked.length > 0) {
        StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function () {
          processStocksQuery();
        });
      }
    }
  }
  /**
   * Toggle the checkbox.
   *
   * @param {object} item - The item.
   * @param {Array} list - The list.
   */


  function toggleCb(item, list) {
    var idx = vm.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function () {
      processStocksQuery();
    });
  }
  /**
   * Index By label
   *
   * @param {string} name - The name.
   * @param {Array} list - The list.
   *
   * @returns {number}
   */


  function indexByLabel(name, list) {
    if (!name || !list) {
      return -1;
    }

    for (var index = 0, length = list.length; index < length; index++) {
      if (list[index].label === name) {
        return index;
      }
    }

    return list.findIndex(function (item) {
      return item.label === name;
    });
  }
  /**
   * Exist filter
   *
   * @param {string} name - The name.
   * @param {Array} list - The list.
   *
   * @returns {boolean}
   */


  function existsFilter(name, list) {
    if (vm.genQuery) {
      return false;
    }

    return vm.indexByLabel(name, list) > -1;
  }
  /**
   * Check opt.
   *
   * @param {object} opt - The option.
   */


  function checkOpt(opt) {
    if (opt.checked) {
      var index = vm.sandBoxOpts.indexOf(opt);
      vm.sandBoxOpts.splice(index, 1);
    } else {
      opt.checked = true;
      vm.sandBoxOpts.push(opt);
    }
  }
  /**
   * Add filter
   *
   * @param {object} opt - The option.
   */


  function addFilters(opt) {
    if (!opt.checked) {
      vm.valuesChecked.push(opt);
      opt.checked = true;
      StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function () {
        processStocksQuery();
      });
    }
  }

  $scope.$on("stockFilterChange", function () {
    vm.loading = true;
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function () {
      processStocksQuery();
    });
  });

  psd3.Graph = function (config) {
    this.config = config;
    this.defaults = {
      width: angular.element(document.getElementById("chartStockContainer")).width(),
      height: angular.element(document.getElementById("chartStockContainer")).height(),
      value: "value",
      inner: "inner",
      label: function label(item) {
        return item.data.value;
      },
      tooltip: function tooltip(item) {
        if (_this.config.value !== undefined) {
          return item[_this.config.value];
        }

        return item.value;
      },
      transition: "linear",
      transitionDuration: 1000,
      donutRadius: 0,
      gradient: false,
      colors: d3.scale.category20(),
      labelColor: "black",
      drilldownTransition: "linear",
      drilldownTransitionDuration: 0,
      stroke: "white",
      strokeWidth: 2,
      highlightColor: "orange"
    };

    for (var property in this.defaults) {
      if (Object.prototype.hasOwnProperty.call(this.defaults, property)) {
        if (!Object.prototype.hasOwnProperty.call(config, property)) {
          config[property] = this.defaults[property];
        }
      }
    }
  };

  psd3.Pie = function (config) {
    psd3.Graph.call(this, config);
    this.zoomStack = [];
    var pos = "top";

    if (this.config.heading !== undefined && this.config.heading.pos !== undefined) {
      pos = this.config.heading.pos;
    }

    if (pos === "top") {
      this.setHeading();
    }

    this.drawPie(config.data);

    if (pos === "bottom") {
      this.setHeading();
    }
  };

  psd3.Pie.prototype = Object.create(psd3.Graph.prototype);
  psd3.Pie.prototype.constructor = psd3.Pie;

  psd3.Pie.prototype.findMaxDepth = function (dataset) {
    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return 0;
    }

    var currentLevel;
    var maxOfInner = 0;

    for (var index = 0; index < dataset.length; index++) {
      var maxInnerLevel = this.findMaxDepth(dataset[index][this.config.inner]);

      if (maxOfInner < maxInnerLevel) {
        maxOfInner = maxInnerLevel;
      }
    }

    currentLevel = 1 + maxOfInner;
    return currentLevel;
  };

  psd3.Pie.prototype.setHeading = function () {
    if (this.config.heading !== undefined) {
      d3.select("#" + this.config.containerId).append("div").style("text-align", "center").style("width", String(this.config.width) + "px").style("padding-top", "20px").style("padding-bottom", "20px").append("strong").text(this.config.heading.text);
    }
  };

  psd3.Pie.prototype.mouseover = function (item) {
    vm.tooltip.display(item); // over effect

    d3.select(item.path).style("fill", _this.config.highlightColor);
  };

  psd3.Pie.prototype.mouseout = function (item) {
    d3.select("#" + _this.tooltipId).classed("psd3Hidden", true);
    d3.select(item.path).style("fill", item.fill);
  };

  psd3.Pie.prototype.drawPie = function (dataset) {
    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return;
    }

    _this = this;
    _this.arcIndex = 0;
    _this.width = 100;
    _this.height = 100;
    var svg = d3.select("#" + _this.config.containerId).append("svg").attr("id", _this.config.containerId + "_svg").attr("viewBox", "0 0 640 640").attr("preserveAspectRatio", "xMinYMin meet").append("g");
    _this.tooltipId = _this.config.containerId + "_tooltip";
    var tooltipDiv = d3.select("#" + _this.config.containerId).append("div").attr("id", _this.tooltipId).attr("class", "psd3Hidden psd3Tooltip");
    tooltipDiv.append("p").append("span").attr("id", "value").text("100%");

    var brdPsd3GoBackClick = function brdPsd3GoBackClick() {
      var centerPie = d3.select("g.arc1");
      centerPie.on("dblclick")(goBackTo);
    };

    var labelBp = d3.select("#chartLabelContainer");
    labelBp.html("");

    for (var index = 0; index < brdBp.length; index++) {
      labelBp.append("span").text(brdBp[index].label);

      if (brdBp[index + 1]) {
        labelBp.append("md-icon").attr("class", "mh-8 icon s16 icon-chevron-right").attr("md-font-icon", "icon-chevron-right");
      }
    }

    var returnBp = d3.select("#chartReturnBtnContainer");

    if (brdBp.length > 1) {
      if (!dirtyReturn) {
        returnBp.append("span").text("Retour").attr("class", "returnPsdBrd md-accent-fg mt-8").on("click", brdPsd3GoBackClick);
        dirtyReturn = true;
      }
    } else {
      returnBp.html("");
      dirtyReturn = false;
    } // to contain pie cirlce


    var radius;

    if (_this.config.width > _this.config.height) {
      radius = _this.config.width / 2;
    } else {
      radius = _this.config.height / 2;
    }

    var innerRadius = _this.config.donutRadius;

    var maxDepth = _this.findMaxDepth(dataset);

    var outerRadius = innerRadius + (radius - innerRadius) / maxDepth;
    var radiusDelta = outerRadius - innerRadius;

    _this.draw(svg, radius, dataset, dataset, dataset.length, innerRadius, outerRadius, radiusDelta, 0, 360 * 22 / 7 / 180, [0, 0]);
  };

  psd3.Pie.prototype.customArcTween = function (item) {
    var start = {
      startAngle: item.startAngle,
      endAngle: item.startAngle
    };
    var interpolate = d3.interpolate(start, item);
    return function (data) {
      return item.arc(interpolate(data));
    };
  };

  psd3.Pie.prototype.textTransform = function (data) {
    return "translate(" + data.arc.centroid(data) + ")";
  };

  psd3.Pie.prototype.textTitle = function (data) {
    return data.data[_this.config.value];
  };

  psd3.Pie.prototype.draw = function (svg, totalRadius, dataset, originalDataset, originalDatasetLength, innerRadius, outerRadius, radiusDelta, startAngle, endAngle, parentCentroid) {
    var _this = this;

    if (dataset === null || dataset === undefined || dataset.length < 1) {
      return;
    }

    brdBp[0].dataset = originalDataset;

    psd3.Pie.prototype.textText = function (data) {
      return _this.config.label(data);
    };

    var pie = d3.layout.pie();
    pie.sort(null);
    pie.value(function (data) {
      return data[_this.config.value];
    });
    pie.startAngle(startAngle).endAngle(endAngle);
    var values = [];

    for (var index = 0; index < dataset.length; index++) {
      values.push(dataset[index][_this.config.value]);
    }

    vm.searchCorrespondTo = {
      "product.laboratory": "laboratory",
      "product.segment.value": "segment",
      "product.presentation": "product"
    };

    var dblclick = function dblclick(item) {
      var type = vm.searchCorrespondTo[item.data.type];

      if (item.data.type.indexOf("category") > -1) {
        type = "category";
      }

      if (typeof vm.usrquery.must[type] === "undefined") {
        vm.usrquery.must[type] = {};
        vm.usrquery.must[type].selected = [];
      }

      var obj = {
        id: item.data.id,
        name: item.data.label
      };
      var hasObj = false;
      angular.forEach(vm.usrquery.must[type].selected, function (must) {
        if (item.data.id === must.id) {
          hasObj = true;
        }
      });

      if (hasObj) {
        vm.usrquery.must[type].selected.splice(vm.usrquery.must[type].selected.indexOf(obj), 1);
      } else {
        vm.usrquery.must[type].selected.push(obj);
      }

      StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
        vm.query = query;
        processStocksQuery();
      });
    };

    var arc = d3.svg.arc().innerRadius(innerRadius).outerRadius(outerRadius); // Set up groups

    _this.arcIndex = _this.arcIndex + 1;
    var clazz = "arc" + _this.arcIndex;

    var storeMetadataWithArc = function storeMetadataWithArc(item) {
      // eslint-disable-next-line no-invalid-this
      item.path = this; // eslint-disable-next-line no-invalid-this

      item.fill = this.fill;
      item.arc = arc;
      item.length = dataset.length;

      if (_this.arcIndex === 1) {
        goBackTo = item;
      }
    };

    var arcs = svg.selectAll("g." + clazz).data(pie(dataset)).enter().append("g").attr("class", "arc " + clazz).attr("transform", "translate(" + totalRadius + "," + totalRadius + ")").on("dblclick", dblclick);
    var gradient = svg.append("svg:defs").append("svg:linearGradient").attr("id", "gradient_" + _this.arcIndex).attr("x1", "0%").attr("y1", "0%").attr("x2", "100%").attr("y2", "100%").attr("spreadMethod", "pad");
    var startColor;
    var endColor;

    if (_this.config.gradient) {
      var _index = 2 * _this.arcIndex;

      var endIndex = _index + 1;
      startColor = _this.config.colors(_index);
      endColor = _this.config.colors(endIndex);
    } else {
      startColor = endColor = _this.config.colors(this.arcIndex);
    }

    gradient.append("svg:stop").attr("offset", "0%").attr("stop-color", startColor).attr("stop-opacity", 1);
    gradient.append("svg:stop").attr("offset", "100%").attr("stop-color", endColor).attr("stop-opacity", 1); // Draw arc paths

    var paths = arcs.append("path").attr("fill", "url(" + $location.$$url + "#gradient_" + _this.arcIndex + ")").style("stroke", _this.config.stroke).style("stroke-width", _this.config.strokeWidth);
    paths.on("mouseover", _this.mouseover);
    paths.on("mouseout", _this.mouseout);
    paths.each(storeMetadataWithArc);
    paths.transition().duration(_this.config.transitionDuration).delay(_this.config.transitionDuration * (_this.arcIndex - 1)).ease(_this.config.transition).attrTween("d", _this.customArcTween); // Labels

    arcs.append("text").attr("x", function () {
      return parentCentroid[0];
    }).attr("y", function () {
      return parentCentroid[1];
    }).transition().ease(_this.config.transition).duration(_this.config.transitionDuration).delay(_this.config.transitionDuration * (_this.arcIndex - 1)).attr("transform", function (item) {
      var array = [];
      array[0] = arc.centroid(item)[0] - parentCentroid[0];
      array[1] = arc.centroid(item)[1] - parentCentroid[1];
      return "translate(" + array + ")";
    }).attr("text-anchor", "middle").text(_this.textText).style("fill", _this.config.labelColor).attr("title", _this.textTitle);

    for (var _index2 = 0; _index2 < dataset.length; _index2++) {
      if (dataset[_index2][_this.config.inner] !== undefined) {
        _this.draw(svg, totalRadius, dataset[_index2][_this.config.inner], originalDataset, originalDatasetLength, innerRadius + radiusDelta, outerRadius + radiusDelta, radiusDelta, paths.data()[_index2].startAngle, paths.data()[_index2].endAngle, arc.centroid(paths.data()[_index2]));
      }
    }
  };

  psd3.Pie.prototype.reDrawPie = function (data, ds) {
    var tmp = [];
    d3.select("#" + _this.tooltipId).remove();
    d3.select("#" + _this.config.containerId + "_svg") // .remove();
    .transition().ease(_this.config.drilldownTransition).duration(_this.config.drilldownTransitionDuration).style("height", 0).remove().each("end", function () {
      if (data.length === 1) {
        tmp = _this.zoomStack.pop();
      } else {
        tmp.push(data.data);

        _this.zoomStack.push(ds);
      }

      _this.drawPie(tmp);
    });
  };
}

angular.module("app.statistics.stocks").controller("StatisticsStockController", StatisticsStockController);