"use strict";
/**
 * Segment controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $mdToast - $mdToast.
 * @param {object} environmentService - Environment service.
 * @param {object} segmentService - Segment service.
 */

SegmentController.$inject = ["$scope", "$state", "$stateParams", "$mdDialog", "$mdToast", "environmentService", "segmentService"];

function SegmentController($scope, $state, $stateParams, $mdDialog, $mdToast, environmentService, segmentService) {
  $scope.loaderSegmentsOptions = {
    loading: true,
    loader: "Récupération de vos segments...",
    empty: "Aucun segment n’est disponible"
  };
  $scope.segmentType = {};
  $scope.query = {
    order: "name",
    limit: 10,
    page: 1
  };
  $scope.editHealthcenterSegment = editHealthcenterSegment;
  $scope.editProductSegment = editProductSegment;
  $scope.deleteSegment = deleteSegment;
  $scope.getSegments = getSegments;
  $scope.goToStock = goToStock;
  $scope.goToMarketShare = goToMarketShare;
  $scope.goToSalesAnalysis = goToSalesAnalysis;
  $scope.createHealthcenterSegment = createHealthcentersSegment;
  $scope.createProductSegment = createProductSegment;
  environmentService.getEnvironment().then(function (env) {
    $scope.currentEnv = env;

    if ([1, 3, 5].indexOf($scope.currentEnv.accessPointType) !== -1) {
      $scope.segmentType.selectedId = $stateParams.type;
      $scope.selectedTab = $scope.segmentType.selectedId - 1;
    } else {
      $scope.segmentType.selectedId = 2;
    }

    getSegments();
  });
  /**
   * Gets all segments.
   *
   * @returns {Promise}
   */

  function getSegments() {
    $scope.loading = true;
    $scope.loaderSegmentsOptions.loading = true;
    return segmentService.get().then(function (response) {
      $scope.segments = response;
      $scope.loading = false;
      $scope.loaderSegmentsOptions.loading = false;
      filterSegments();
    });
  }
  /**
   * Open the modification healthcenter segment form.
   *
   * @param {number} segmentId - The segment identifier
   * @param {object} event - The event
   */


  function editHealthcenterSegment(segmentId, event) {
    event.stopPropagation();
    $state.go("app.segments.edit.healthcenter", {
      id: segmentId
    });
  }
  /**
   * Open the creation healthcenter segment form.
   *
   * @param {object} event - The event
   */


  function createHealthcentersSegment(event) {
    event.stopPropagation();
    $state.go("app.segments.create.healthcenter");
  }
  /**
   * Open the modification product segment form.
   *
   * @param {number} segmentId - The segment identifier
   * @param {object} event - The event
   */


  function editProductSegment(segmentId, event) {
    event.stopPropagation();
    $state.go("app.segments.edit.product", {
      id: segmentId
    });
  }
  /**
   * Open the creation product segment form
   *
   * @param {object} event - The event
   */


  function createProductSegment(event) {
    event.stopPropagation();
    $state.go("app.segments.create.product");
  }
  /**
   * Confirm box for deletion of a segment
   *
   * @param {object} event - The event
   * @param {object} segment - The segment
   */


  $scope.confirmDeleteSegment = function (event, segment) {
    event.stopPropagation(); // Check if a custom name has been defined

    var segmentName = segment.name !== "" && segment.name !== null ? segment.name : segment.originalName;
    var confirm = $mdDialog.confirm().title("Suppression").textContent("Etes-vous certain(e) de vouloir supprimer le segment <<" + segmentName + ">> ?").ariaLabel("suppression du segment").targetEvent(event).ok("Continuer").cancel("Annuler");
    $mdDialog.show(confirm).then(function () {
      deleteSegment(segment.id);
    });
  };
  /**
   * Delete one segment
   *
   * @param {number} segmentId - The segment identifier
   *
   * @returns {Promise}
   */


  function deleteSegment(segmentId) {
    return segmentService["delete"]({
      id: segmentId
    }).then(function () {
      $mdToast.show($mdToast.simple().textContent("Le segment est correctement supprimé.").position("top right"));
      getSegments();
    });
  }
  /**
   * Open the stocks with segmentFilter
   *
   * @param {object} segment - The segment
   * @param {object} event - Event.
   */


  function goToStock(segment, event) {
    event.stopPropagation();
    $state.go("app.statistics.stocks.stat", {
      segment: segment
    });
  }
  /**
   * Open the market share with segmentFilter
   *
   * @param {object} segment - The segment
   * @param {object} event - Event.
   */


  function goToMarketShare(segment, event) {
    event.stopPropagation();
    $state.go("app.statistics.salesAnalysis.marketShare", {
      segment: [segment]
    });
  }
  /**
   * Open the sales analysis with segmentFilter
   *
   * @param {object} segment - The segment
   * @param {object} event - Event.
   */


  function goToSalesAnalysis(segment, event) {
    var segmentForSalesAnalysis = {
      id: segment.id,
      name: segment.name,
      segmentTypeId: segment.segmentTypeId
    };
    event.stopPropagation();
    $state.go("app.statistics.salesAnalysis.sandBoxLineAtcl", {
      segment: [segmentForSalesAnalysis]
    });
  }
  /**
   * Filter segments.
   */


  function filterSegments() {
    $scope.healthcenterSegments = $scope.segments.filter(function (segment) {
      return segment.segmentTypeId === 1;
    });
    $scope.productSegments = $scope.segments.filter(function (segment) {
      return segment.segmentTypeId === 2;
    });
  }

  $scope.filterBySegmentType = function (segment) {
    if (segment) {
      return segment.segmentTypeId === $scope.segmentType.selectedId;
    }

    return false;
  };
}

angular.module("app.segment").controller("segmentController", SegmentController);