"use strict";
/**
 * The app-input-cart directive.
 *
 * @returns {object}
 */

AppInputCartController.$inject = ["$scope", "$rootScope", "cartService"];

function AppInputCart() {
  return {
    restrict: "E",
    scope: {
      variant: "@variant",
      deletable: "=deletable",
      product: "=product"
    },
    templateUrl: "app/main/directives/app-input-cart/appInputCart.html",
    controllerAs: "vm",
    controller: AppInputCartController
  };
}
/**
 * App input cart controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} cartService - Cart service.
 */


function AppInputCartController($scope, $rootScope, cartService) {
  /**
   * Declaration of public objects
   */
  $scope.isUpdating = false;
  /**
   * Declaration of public methods
   */

  $scope.decrement = decrement;
  $scope.updateCart = updateCart;
  $scope.deleteProduct = deleteProduct;
  $scope.increment = increment;
  $scope.decrement = decrement;
  $scope.debounceUpdate = _.debounce(updateCart, 800);
  /**
   * Increment.
   *
   * @param {object} product -
   */

  function increment(product) {
    var finalProduct = product.product ? product.product : product;
    finalProduct.quantity = finalProduct.minimumQuantity && finalProduct.quantity === 0 ? finalProduct.minimumQuantity : finalProduct.quantity + finalProduct.packingQuantity;
    $scope.debounceUpdate(finalProduct);
  }
  /**
   * Decrement.
   *
   * @param {object} product -
   */


  function decrement(product) {
    var finalProduct = product.product ? product.product : product;

    if (finalProduct.quantity > 0) {
      finalProduct.quantity -= finalProduct.packingQuantity;
    }

    if (finalProduct.quantity < finalProduct.minimumQuantity) {
      finalProduct.quantity = 0;
    }

    $scope.debounceUpdate(finalProduct);
  }
  /**
   * Delete product.
   *
   * @param {object} product -
   * @returns {Promise}
   */


  function deleteProduct(product) {
    var order = {};
    product.id = product.productId ? product.productId : product.id;
    order[product.id] = 0;
    var query = {
      laboratoryId: $rootScope.laboratoryBranding.laboratory.id,
      healthcenterId: $rootScope.environment.entity.id,
      products: order
    };
    return cartService.manageCart(query).then(function (response) {
      product.quantity = product.quantity ? product.quantity : 0;
      $rootScope.$broadcast("cart:delete");
      return response;
    });
  }
  /**
   * Update cart.
   *
   * @param {object} product -
   * @returns {Promise}
   */


  function updateCart(product) {
    $scope.isUpdating = true;
    var order = {};
    product.id = product.productId ? product.productId : product.id;
    order[product.id] = product.quantity;
    var query = {
      laboratoryId: $rootScope.laboratoryBranding.laboratory.id,
      healthcenterId: $rootScope.environment.entity.id,
      products: order
    };
    return cartService.manageCart(query).then(function (response) {
      $scope.isUpdating = false;
      product.quantity = product.quantity ? product.quantity : 0;
      $rootScope.$broadcast("cart:update");
      return response;
    });
  }
}

angular.module("app.directives").directive("appInputCart", AppInputCart);