"use strict";
/**
 * Bug report service.
 *
 * @param {object} apiClient - API client service.
 * @returns {object}
 */

BugReportService.$inject = ["apiClient"];

function BugReportService(apiClient) {
  return {
    /**
     * Create a bug report.
     *
     * @param {object} content - Content of bug report
     * @returns {Promise}
     */
    create: function create(content) {
      return apiClient.post("/bugreport", content, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.services").service("bugReportService", BugReportService);