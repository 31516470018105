"use strict";
/**
 * Merchandising plan file controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $mdToast - $mdToast.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $http - $http.
 * @param {object} merchandisingPlanFileService - Merchandising plan file service.
 * @param {string} apiurl - API URL.
 * @param {object} sessionStorageService - Session storage service.
 */

MerchandisingPlanFileController.$inject = ["$scope", "$mdToast", "$mdDialog", "$stateParams", "$http", "merchandisingPlanFileService", "apiurl", "sessionStorageService"];

function MerchandisingPlanFileController($scope, $mdToast, $mdDialog, $stateParams, $http, merchandisingPlanFileService, apiurl, sessionStorageService) {
  $scope.create = create;
  $scope.createFromUpload = createFromUpload;
  $scope.destroy = destroy;
  $scope.loaderMerchandisingPlanFiles = {
    loading: true,
    loader: "Chargement des fichiers du plan merchandising…",
    empty: "Vous n'avez pas encore ajouté des fichiers au plan merchandising"
  };
  $scope.configTitle = {
    title: {
      text: "Plan merchandising",
      back: {
        state: "app.merchandisingPlanMain",
        tooltip: "Revenir aux plans merchandising"
      }
    }
  };
  /** Loader. */

  (function loader() {
    if ($stateParams.merchandisingPlan) {
      $scope.configTitle.title.text += " " + $stateParams.merchandisingPlan.title;
    }

    get();
  })();
  /** Get merchandising plan files. */


  function get() {
    $scope.loading = true;
    merchandisingPlanFileService.get({
      merchandisingPlanId: $stateParams.merchandisingPlanId
    }).then(function (merchandisingPlanFiles) {
      $scope.merchandisingPlanFiles = merchandisingPlanFiles;
      $scope.loaderMerchandisingPlanFiles.loading = false;
    });
  }
  /** Create a merchandising plan file. */


  function create() {
    merchandisingPlanFileService.create({
      merchandisingPlanId: $stateParams.merchandisingPlanId,
      file_id: $scope.file.id
    }).then(function (merchandisingPlanFile) {
      $scope.merchandisingPlanFiles.push(merchandisingPlanFile);
      $scope.file = null;
      $mdToast.show($mdToast.simple().textContent("Le fichier « " + merchandisingPlanFile.name + " » est ajouté"));
    });
  }
  /**
   * Create a merchandising plan file from upload.
   *
   * @todo The httpBuilder service should handle FormData objects.
   */


  function createFromUpload() {
    var formData = new FormData();
    var file = document.getElementById("uploadFromComputer").files[0];
    formData.append("file", file);
    $http.post(apiurl + "/merchandising-plans/" + $stateParams.merchandisingPlanId + "/files/upload", formData, {
      transformRequest: angular.identity,
      headers: {
        "Content-Type": undefined,
        Authorization: sessionStorageService.getBearerToken()
      }
    }).success(function (response) {
      $scope.merchandisingPlanFiles.push(response.data);
      $mdToast.show($mdToast.simple().textContent("Le fichier « " + response.data.name + " » est ajouté"));
    }).error(function (response) {
      $mdToast.show($mdToast.simple().textContent(response.errors.file[0]));
    });
  }
  /**
   * Destroy a merchandising plan file.
   *
   * @param {object} event - Event.
   * @param {object} merchandisingPlanFile - Merchandising plan file.
   * @param {number} index - Index.
   */


  function destroy(event, merchandisingPlanFile, index) {
    $mdDialog.show($mdDialog.confirm().title("Confirmez-vous la suppression du fichier « " + merchandisingPlanFile.name + " » ?").textContent("Le fichier sera conservé dans votre gestionnaire de fichiers.").ariaLabel("Suppression de fichier").targetEvent(event).ok("Oui").cancel("Non")).then(function () {
      return merchandisingPlanFileService["delete"]({
        merchandisingPlanId: $stateParams.merchandisingPlanId,
        merchandisingPlanFileId: merchandisingPlanFile.id
      });
    }).then(function (merchandisingPlanFile) {
      $scope.merchandisingPlanFiles.splice(index, 1);
      $mdToast.show($mdToast.simple().textContent("Le fichier « " + merchandisingPlanFile.name + " » est supprimé"));
    });
  }
}

angular.module("app.merchandising-plan").controller("merchandisingPlanFileController", MerchandisingPlanFileController);