"use strict";
/**
 * The patient order status graphic component.
 */

PatientOrderStatusGraphicController.$inject = ["$scope", "orderFactory"];
var PatientOrderStatusGraphicComponent = {
  templateUrl: "app/main/patients/components/patient-order-status-graphic/patientOrderStatusGraphic.html",
  bindings: {
    order: "<",
    withDate: "<"
  },
  controller: PatientOrderStatusGraphicController,
  controllerAs: "vm"
};
/**
 * The patient order status graphic controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} orderFactory - The order factory.
 */

function PatientOrderStatusGraphicController($scope, orderFactory) {
  $scope.hasStatus = hasStatus;
  $scope.orderMapping = orderFactory.statusMapping;
  var vm = this;
  var statusWeight = {
    pending: 0,
    accepted: 1,
    available: 2,
    delivered: 3
  };
  /**
   * Determine whether an order has a given status.
   *
   * @param {string} status - The status to check.
   *
   * @returns {boolean}
   */

  function hasStatus(status) {
    return statusWeight[vm.order.status] >= statusWeight[status];
  }
}

angular.module("app.patients.components").component("patientOrderStatusGraphic", PatientOrderStatusGraphicComponent);