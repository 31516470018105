"use strict";
/**
 * Commercial offer configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

CommercialOfferConfig.$inject = ["$stateProvider"];

function CommercialOfferConfig($stateProvider) {
  $stateProvider.state("app.commercialOffer", {
    url: "/mes-partenaires-logisticiens",
    views: {
      "content@app": {
        templateUrl: "app/main/commercial-offer/views/home/home.html",
        controller: "commercialOfferHomeController"
      }
    }
  }).state("app.commercialOffer.wholesaler", {
    url: "/grossiste/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/commercial-offer/views/wholesaler/wholesaler.html",
        controller: "commercialOfferWholesalerController"
      }
    }
  }).state("app.priceDown", {
    url: "/decrochage-prix",
    views: {
      "content@app": {
        templateUrl: "app/main/toBeMade/toBeMade.html",
        controller: "ToBeMadeController as vm"
      }
    }
  }).state("app.fidelity", {
    url: "/fidelite",
    views: {
      "content@app": {
        templateUrl: "app/main/toBeMade/toBeMade.html",
        controller: "ToBeMadeController as vm"
      }
    }
  }).state("app.commercialOffer.sellin_offers", {
    url: "/healthcenters/offres-sellin",
    views: {
      "content@app": {
        templateUrl: "app/main/commercial-offer/views/healthcenter/list/sellinOffersList.html",
        controller: "sellinOffersListController"
      }
    }
  }).state("app.commercialOffer.sellin_offers_detail", {
    url: "/healthcenters/offres-sellin/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/commercial-offer/views/healthcenter/detail/sellinOfferDetails.html",
        controller: "sellinOfferDetailsController"
      }
    }
  });
}

angular.module("app.commercialOffer", []).config(CommercialOfferConfig);