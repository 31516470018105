"use strict";
/**
 * The User Laboratory Branding Statistics service.
 *
 * @param {object} apiClient - API client.
 * @class
 */

BrandingStatisticsService.$inject = ["apiClient"];

function BrandingStatisticsService(apiClient) {
  return {
    /**
     * sales.
     *
     * @param {object} data - The data passed to the api.
     * @returns {Promise}
     */
    getSales: function getSales(data) {
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/branding/sales")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top product prices.
     *
     * @param {object} data - The data passed to the api.
     * @returns {Promise}
     */
    getTopProductPrices: function getTopProductPrices(data) {
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/branding/top-product-prices")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top product.
     *
     * @param {object} data - The data passed to the api.
     * @returns {Promise}
     */
    getTopProduct: function getTopProduct(data) {
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/branding/top-product")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top products.
     *
     * @param {object} data - The data passed to the api.
     * @returns {Promise}
     */
    getTopProducts: function getTopProducts(data) {
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/branding/top-products")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top national product.
     *
     * @param {object} data - The data passed to the api.
     *
     * @returns {Promise}
     */
    getTopNationalProduct: function getTopNationalProduct(data) {
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/branding/top-national-product")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get top operators.
     *
     * @param {object} data - The data passed to the api.
     * @returns {Promise}
     */
    getTopOperators: function getTopOperators(data) {
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/branding/top-operators")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get needs.
     *
     * @param {object} data - The data passed to the api.
     * @returns {Promise}
     */
    getNeeds: function getNeeds(data) {
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/branding/needs")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get operators contribution to the evolution of turnover.
     *
     * @param {object} data - The data passed to the api.
     * @returns {Promise}
     */
    getOperatorsContribution: function getOperatorsContribution(data) {
      return apiClient.get("/laboratories/".concat(data.laboratoryId, "/branding/operators-contribution")).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.users-laboratory").service("brandingStatisticsService", BrandingStatisticsService);