"use strict";
/**
 * Loader wholesalers.
 *
 * @returns {object}
 */

function LoaderWholeSalers() {
  return {
    restrict: "E",
    scope: {
      data: "=data"
    },
    templateUrl: "app/main/statistics/productAnalysis/directives/loaderWholeSalers/loaderWholeSalers.html",
    transclude: true,
    controller: LoaderWholeSalersController
  };
}
/**
 * Loader wholesalers controller.
 */


function LoaderWholeSalersController() {// do nothing
}

angular.module("app.statistics.products.directives.loaderWholeSalers", []).directive("loaderWholeSalers", LoaderWholeSalers);