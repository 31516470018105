"use strict";
/**
 * Controller for loyalty card member detail.
 *
 * @param {object} $scope - Scope
 * @param {object} $state - State
 * @param {object} $stateParams - State params
 * @param {object} environmentService - Environment service
 * @param {object} loyaltyCardService - Loyalty card service
 */

LoyaltyCardMemberDetailController.$inject = ["$scope", "$state", "$stateParams", "environmentService", "loyaltyCardService"];

function LoyaltyCardMemberDetailController($scope, $state, $stateParams, environmentService, loyaltyCardService) {
  $scope.select = false; // LoyaltyCardMember

  $scope.loyaltyCardMember = {
    loyaltyCardId: $stateParams.loyaltyCardId,
    patientHash: "",
    startDate: ""
  };
  $scope.isLoyaltyCardWithPoints = false;
  $scope.goToList = goToList;
  $scope.loyaltyCardMemberId = $stateParams.id; // Store the point reward type ID to check if there are points as reward

  var pointRewardTypeId = 5;
  loader();
  /**
   * Loader of the controller.
   *
   * @returns {Promise} The promise
   */

  function loader() {
    return environmentService.getEnvironment().then(function (access) {
      $scope.access = access;
      getLoyaltyCardMember();
      return access;
    });
  }
  /**
   * Get loyalty card member.
   */


  function getLoyaltyCardMember() {
    if ($stateParams.loyaltyCardMemberId) {
      var loyaltyCardMember = {
        loyaltyCardId: $stateParams.loyaltyCardId,
        id: $stateParams.loyaltyCardMemberId
      };
      loyaltyCardService.readMember(loyaltyCardMember).then(function (response) {
        $scope.loyaltyCardMember = response;
        $scope.loyaltyCard = $scope.loyaltyCardMember.loyaltyCard; // If the card has objectives, get if there are points rewards or not

        if (response.objectives) {
          response.objectives.forEach(function (objective) {
            if (objective.rewardType.id === pointRewardTypeId) {
              $scope.isLoyaltyCardWithPoints = true;
            }
          });
        }
      });
    }
  }
  /**
   * Go to loyalty card list.
   */


  function goToList() {
    $state.go("app.loyaltyCard");
  }
}

angular.module("app.loyaltyCard").controller("loyaltyCardMemberDetailController", LoyaltyCardMemberDetailController);