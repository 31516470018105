"use strict";
/**
 * Team manager controller.
 *
 * @param {object} $state - $state.
 * @param {object} environmentService - Environment service.
 */

TeamManagerController.$inject = ["$state", "environmentService"];

function TeamManagerController($state, environmentService) {
  var vm = this;
  var currentState = $state.current.name;
  environmentService.getEnvironment().then(function (env) {
    vm.currentEnv = env; // TABS STATES

    switch (currentState) {
      case "app.teammanager_members":
        vm.selectedIndex = 0;
        break;

      case "app.teammanager_map":
        vm.selectedIndex = 1;
        break;

      case "app.teammanager_history":
        vm.selectedIndex = 2;
        break;

      default:
        vm.selectedIndex = 0;
    }
  });
}

angular.module("app.teammanager").controller("TeamManagerController", TeamManagerController);