"use strict";
/**
 * Dashboard laboratories top healthcenters controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} dashboardAnalysisService - Dashboard analysis service.
 */

DashboardLaboratoriesTopHealthcentersController.$inject = ["$scope", "dashboardAnalysisService"];

function DashboardLaboratoriesTopHealthcentersController($scope, dashboardAnalysisService) {
  $scope.query = {
    page: 1,
    limit: 10,
    order: "-amount_excl_tax.value"
  };
  $scope.loaderTopHealthcenters = {
    loading: true,
    loader: "Chargement du top pharmacie",
    empty: "Aucune donnée"
  };
  getTopHealthcenters();
  /**
   * Get top healthcenters.
   */

  function getTopHealthcenters() {
    dashboardAnalysisService.getTopHealthcenters().then(function (healthcenters) {
      $scope.healthcenters = healthcenters.aggregations.healthcenters.buckets;
      $scope.loaderTopHealthcenters.loading = false;
    });
  }
}

angular.module("app.dashboard").controller("dashboardLaboratoriesTopHealthcentersController", DashboardLaboratoriesTopHealthcentersController);