"use strict";
/**
 * Patient search factory.
 *
 * @returns {object}
 */

function PatientSearchFactory() {
  return {
    patients: [{
      id: 1,
      name: "Brunet",
      firstName: "Thomas"
    }]
  };
}

angular.module("app.patients.factories.search").factory("patientSearchFactory", PatientSearchFactory);