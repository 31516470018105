"use strict";
/**
 * Sellin offer detail controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 * @param {object} sellinOfferService - Sellin offer service.
 */

SellinOfferDetailController.$inject = ["$scope", "$stateParams", "$state", "sellinOfferService"];

function SellinOfferDetailController($scope, $stateParams, $state, sellinOfferService) {
  $scope.sellinOfferId = $stateParams.id;
  $scope.loading = true;
  $scope.titleConfig = {
    title: {
      icon: "icon-cart-fill",
      text: "...",
      back: {
        state: "app.sellinOffer",
        tooltip: "Retour à mes offres sell-in"
      }
    }
  };
  $scope.getSellinOffer = getSellinOffer;
  $scope.goToList = goToList;
  loader();
  /**
   * Loader.
   */

  function loader() {
    getSellinOffer();
  }
  /**
   * Get the sellin offer
   *
   * @returns {Promise} The sellin offer
   */


  function getSellinOffer() {
    var id = $scope.sellinOfferId;

    if (id) {
      var sellinOffer = {
        id: $stateParams.id
      };
      return sellinOfferService.read(sellinOffer).then(function (sellinOffer) {
        var products = [];
        var targets = [];
        var pending = 0;
        var validated = 0;
        var refused = 0;

        if (sellinOffer.entityTypeId !== 1) {
          $scope.laboratory = {
            id: sellinOffer.laboratory.id,
            name: sellinOffer.laboratory.name
          };
        }

        sellinOffer.products.forEach(function (element) {
          if (element.product) {
            products.push({
              id: element.product.id,
              code: element.product.code,
              name: element.product.name
            });
          }
        });
        sellinOffer.targets.forEach(function (element) {
          targets.push({
            id: element.entityId,
            name: element.target.name,
            entityTypeId: element.entityTypeId
          }); // Accepted by default for pharmacies, or manually by groupings

          if (element.isAccepted === 1 && element.entityTypeId === 3 || element.entityTypeId === 2) {
            validated++;
          } else if (element.isRefused === 1) {
            refused++;
          } else {
            pending++;
          }
        });
        $scope.sellinOffer = {
          id: sellinOffer.id,
          name: sellinOffer.name,
          description: sellinOffer.description,
          file: sellinOffer.file,
          startDate: new Date(sellinOffer.startDate),
          endDate: new Date(sellinOffer.endDate),
          products: products,
          targets: targets,
          pending: pending,
          validated: validated,
          refused: refused
        };
        $scope.titleConfig.title.text = sellinOffer.name;
        $scope.loading = false;
        return $scope.sellinOffer.id;
      });
    }

    return Promise.resolve();
  }
  /**
   * Go to the sellin offers list
   */


  function goToList() {
    $state.go("app.sellinOffer");
  }
}

angular.module("app.sellinOffer").controller("sellinOfferDetailController", SellinOfferDetailController);