"use strict";
/**
 * Healthcenter stock audit configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

HealthcenterStockAuditConfig.$inject = ["$stateProvider"];

function HealthcenterStockAuditConfig($stateProvider) {
  $stateProvider.state("app.healthcenterStockAudit", {
    url: "/audit-stock-pharmacie",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/healthcenter-stock-audit/views/home/healthcenter-audit.html",
        controller: "healthcenterStockAuditController as vm"
      }
    }
  }).state("app.healthcenterStockAudit.detail", {
    url: "/:id",
    views: {
      "content@app": {
        templateUrl: "app/main/statistics/healthcenter-stock-audit/views/audit/healthcenterStockAudit.html",
        controller: "healthcenterStockAuditDetailController as vm"
      }
    }
  }).state("app.healthcenterStockAudit.detail.general", {
    url: "/general",
    views: {
      "tabContent@app.healthcenterStockAudit.detail": {
        templateUrl: "app/main/statistics/healthcenter-stock-audit/views/general/healthcenter-audit-generalInfos.html",
        controller: "healthcenterStockAuditGeneralInfosController as vm"
      }
    }
  }).state("app.healthcenterStockAudit.detail.realStock", {
    url: "/stock-reel",
    views: {
      "tabContent@app.healthcenterStockAudit.detail": {
        templateUrl: "app/main/statistics/stocks/views/stock/stock.html",
        controller: "StatisticsStockController as vm"
      }
    }
  }).state("app.healthcenterStockAudit.detail.goumy", {
    url: "/valorisation-des-stocks",
    views: {
      "tabContent@app.healthcenterStockAudit.detail": {
        templateUrl: "app/main/statistics/stocks/stat/stat.html",
        controller: "StatisticsStatStockController as vm"
      }
    }
  }).state("app.healthcenterStockAudit.detail.reassort", {
    url: "/reassort",
    views: {
      "tabContent@app.healthcenterStockAudit.detail": {
        templateUrl: "app/main/statistics/healthcenter-stock-audit/views/reassortAudit/reassortAudit.html",
        controller: "ReassortAuditStockController as vm"
      }
    }
  }).state("app.healthcenterStockAudit.detail.distributor", {
    url: "/distributor",
    views: {
      "tabContent@app.healthcenterStockAudit.detail": {
        templateUrl: "app/main/statistics/healthcenter-stock-audit/views/distributor/healthcenterStockAuditDistributor.html",
        controller: "healthcenterStockAuditDistributorController as vm"
      }
    }
  }).state("app.healthcenterStockAudit.detail.purchases", {
    url: "/commandes",
    views: {
      "tabContent@app.healthcenterStockAudit.detail": {
        templateUrl: "app/main/statistics/healthcenter-stock-audit/views/purchases/purchases.html",
        controller: "purchaseAuditController"
      }
    }
  });
}

angular.module("app.healthcenterStockAudit", []).config(HealthcenterStockAuditConfig);