"use strict";
/**
 * Bricks service.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $state - $state.
 * @param {object} $q - $q.
 * @param {object} sidenavMenuFactory - Sidenav menu factory.
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

BricksService.$inject = ["$rootScope", "$state", "$q", "sidenavMenuFactory", "apiClient"];

function BricksService($rootScope, $state, $q, sidenavMenuFactory, apiClient) {
  var bricks = null;
  /**
   * Sort elements by the priority attribute.
   *
   * @param  {object} left - Left element to compare.
   * @param  {object} right - Right element to compare.
   * @returns {number} Ordering direction.
   */

  function sortByPriority(left, right) {
    return left.priority - right.priority;
  }
  /**
   * Returns bricks from API or local storage
   *
   * @returns {Promise}
   */


  function getBricks() {
    if (bricks === null) {
      var states = [];
      bricks = apiClient.get("/profile/bricks", {
        includes: ["menus", "authorizations"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      }).then(function (result) {
        result.bricks.sort(sortByPriority);
        result.bricks.forEach(function (brick) {
          brick.menus.sort(sortByPriority);
        });
        result.bricks.forEach(function (brick) {
          brick.menus.forEach(function (menu) {
            states.push(menu.state);
          });
        });
        window.sessionStorage.setItem("states", states);
        return sidenavMenuFactory.getDashboard().then(function (dashboardMenu) {
          var options = angular.fromJson(window.sessionStorage.getItem("payload")).options;
          var hasBricksMenus = options.indexOf("bricks-menus") > -1;

          if (hasBricksMenus) {
            result.bricks.forEach(function (brick) {
              if (brick.hasDashboard) {
                brick.menus.unshift(dashboardMenu);
              }
            });
          }

          bricks = result.bricks;
          return result.bricks;
        });
      })["catch"](function (error) {
        return error;
      });
      return bricks;
    }

    return $q.when(bricks);
  }
  /**
   * Reload bricks
   *
   * @returns {Promise}
   */


  function reloadBricks() {
    bricks = null;
    return getBricks();
  }
  /**
   * Return the current brick if brickSlug is in the state params
   *
   * @returns {Promise}
   */


  function getCurrentBrickFromUri() {
    if (Object.keys($state.params).indexOf("brickSlug") >= 0) {
      return getBricks().then(function (bricks) {
        var slug = $state.params.brickSlug.replace("_", ".");

        for (var index = 0, length = bricks.length; index < length; index++) {
          if (bricks[index].slug === slug) {
            return bricks[index];
          }
        }

        return null;
      });
    }

    return $q.when(null);
  }
  /**
   * Returns the current brick displayed
   *
   * @returns {Promise}
   */


  function getCurrentBrick() {
    var currentBrick = window.sessionStorage.getItem("currentBrick");

    if (currentBrick) {
      return $q.when(angular.fromJson(currentBrick));
    }

    return getCurrentBrickFromUri();
  }
  /**
   * Set the current brick to display
   *
   * @param {object} brick -
   * @returns {Promise}
   */


  function setCurrentBrick(brick) {
    return window.sessionStorage.setItem("currentBrick", angular.toJson(brick));
  }
  /**
   * Find brick by root scope.
   *
   * @returns {Promise}
   */


  function findBrickByRootScope() {
    return getBricks().then(function (bricks) {
      if ($rootScope.currentBrickSlug) {
        for (var index = 0, length = bricks.length; index < length; index++) {
          if (bricks[index].slug === $rootScope.currentBrickSlug) {
            return bricks[index];
          }
        }
      }

      return null;
    });
  }
  /**
   * Find brick by menus.
   *
   * @returns {Promise}
   */


  function findBrickByMenus() {
    return getBricks().then(function (bricks) {
      for (var index = 0, length = bricks.length; index < length; index++) {
        for (var index2 = 0, ll = bricks[index].menus.length; index2 < ll; index2++) {
          if ($state.current.name.startsWith(bricks[index].menus[index2].state)) {
            return bricks[index];
          }
        }
      }

      return null;
    });
  }
  /**
   * Try to find current brick.
   *
   * @param {boolean} persist - Persist.
   *
   * @returns {Promise}
   */


  function tryToFindCurrentBrick(persist) {
    return findBrickByRootScope().then(function (brick) {
      if (brick === null) {
        return findBrickByMenus();
      }

      return brick;
    }).then(function (brick) {
      if (persist) {
        setCurrentBrick(brick);
      }

      return brick;
    });
  }
  /**
   * Clean bricks items.
   *
   * @returns {Promise}
   */


  function clean() {
    return $q(function (resolve) {
      window.sessionStorage.removeItem("bricks");
      window.sessionStorage.removeItem("currentBrick");
      bricks = null;
      resolve(bricks);
    });
  }

  return {
    getBricks: getBricks,
    reloadBricks: reloadBricks,
    getCurrentBrick: getCurrentBrick,
    setCurrentBrick: setCurrentBrick,
    tryToFindCurrentBrick: tryToFindCurrentBrick,
    clean: clean
  };
}

angular.module("app.services").service("bricksService", BricksService);