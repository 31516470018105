"use strict";
/**
 * Offers monitoring controller.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} disruptionService - The disruption service.
 * @param {object} $state - The $state object.
 * @param {object} $stateParams - The $stateParams object.
 */

OffersMonitoringController.$inject = ["$scope", "disruptionService", "$state", "$stateParams"];

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function OffersMonitoringController($scope, disruptionService, $state, $stateParams) {
  $scope.seeDetailHealthcenter = seeDetailHealthcenter;
  $scope.seeDetailOrder = seeDetailOrder;
  $scope.changeYear = changeYear;
  $scope.deleteHealthcenterFromFilter = deleteHealthcenterFromFilter;
  $scope.getEmergencyOffers = getEmergencyOffers;
  $scope.topProduct = {};
  $scope.topProduct.selected = "turnover";
  $scope.topDisruption = {};
  $scope.topDisruption.selected = "topDisruptionByAmount";
  $scope.pagination = {
    order: "name",
    page: 1,
    limit: 50
  };
  $scope.healthcenterSearchConfig = {
    event: "healthcentersFilter",
    search: ["idOrgas"],
    tag: "healthcentersFilter",
    autoLaunchEvent: true,
    limits: {
      pharmas: 1
    }
  };
  $scope.paginationOrder = {
    order: "-startDate",
    page: 1,
    limit: 50
  };
  $scope.paginationPharmaml = {
    order: "-date",
    page: 1,
    limit: 50
  };
  $scope.years = [];
  $scope.year = new Date().getFullYear();

  if ($stateParams.selectedTab) {
    $scope.tab = {};
    $scope.tab.selected = $stateParams.selectedTab;
  } else {
    $scope.tab = {};
    $scope.tab.selected = 0;
  }

  getDashboard();
  getHealthcenters();
  getEmergencyOffers();

  for (var year = 2016; year <= $scope.year; year++) {
    $scope.years.push(year);
  }
  /**
   * Get dashboard infos.
   *
   * @param {object} healthcenter - The healthcenter.
   */


  function getDashboard(healthcenter) {
    var params = {
      year: $scope.year
    };

    if (healthcenter) {
      $scope.healthcenter = healthcenter;
    }

    if ($scope.healthcenter) {
      params.healthcenter = $scope.healthcenter.id;
    }

    $scope.loading = true;
    disruptionService.getDashboard(params).then(function (dashboard) {
      $scope.dashboardInfos = dashboard;
      $scope.loading = false;
    });
  }
  /**
   * Change year.
   *
   * @param {number} year - The year.
   */


  function changeYear(year) {
    $scope.year = year;
    getDashboard();
    getEmergencyOffers();
    getHealthcenters();
  }
  /**
   * Get emergency offers.
   */


  function getEmergencyOffers() {
    $scope.ordersLoading = true;
    var params = {
      year: $scope.year,
      size: $scope.paginationOrder.limit,
      offset: $scope.paginationOrder.limit * ($scope.paginationOrder.page - 1)
    };

    if ($scope.healthcenterId) {
      params.healthcenter = $scope.healthcenterId;
    }

    disruptionService.getEmergencyOffersForLaboratory(params).then(function (orders) {
      $scope.count = orders.count;
      orders.offers.data.map(function (order) {
        return order.products.map(function (product) {
          return _objectSpread(_objectSpread({}, product), {}, {
            shortName: product.product.name.substr(0, product.product.name.indexOf(" "))
          });
        });
      });
      $scope.orders = orders.offers.data;
      $scope.ordersLoading = false;
    });
  }
  /**
   * Get dashboard infos.
   *
   * @param {number} id - The healthcenter id.
   */


  function getHealthcenters(id) {
    $scope.healthcenterLoading = true;
    $scope.healthcenters = [];
    var params = {
      year: $scope.year
    };

    if (id) {
      params.healthcenter = id;
    }

    disruptionService.getHealthcenters(params).then(function (healthcenters) {
      healthcenters.forEach(function (healthcenter) {
        healthcenter.ordersAmount = 0;
        healthcenter.disruptionCount = 0;

        if (healthcenter.ordersForOffers) {
          healthcenter.ordersForOffers.forEach(function (order) {
            order.lines.forEach(function (line) {
              healthcenter.ordersAmount += line.totalDiscountedWithoutVat;
            });
          });
        }

        if (healthcenter.emergencySellinOffers) {
          healthcenter.lastDisruptionDate = new Date(Math.max.apply(Math, _toConsumableArray(healthcenter.emergencySellinOffers.map(function (offer) {
            return new Date(offer.startDate);
          }))));
        }

        $scope.healthcenters.push(healthcenter);
      });
      $scope.healthcenterLoading = false;
    });
  }
  /**
   * see detail of healthcenter.
   *
   * @param {object} healthcenter - The healthcenter.
   */


  function seeDetailHealthcenter(healthcenter) {
    $state.go("app.disruption.offersMonitoring.healthcenterDetail", {
      id: healthcenter.id,
      pharma: healthcenter,
      orders: $scope.orders,
      selectedTab: $scope.tab.selected
    });
  }
  /**
   * see detail of order.
   *
   * @param {object} order - The order.
   */


  function seeDetailOrder(order) {
    $state.go("app.disruption.offersMonitoring.orderDetail", {
      id: order.id,
      order: order,
      selectedTab: $scope.tab.selected
    });
  }
  /**
   * Delete healthcenter from filter.
   */


  function deleteHealthcenterFromFilter() {
    $scope.healthcenter = null;
    getDashboard();
    getEmergencyOffers();
    getHealthcenters();
  }

  $scope.$on("healthcentersFilter", function (event, data) {
    $scope.healthcenterId = data.pharmas[0].id;
    getDashboard(data.pharmas[0]);
    getEmergencyOffers();
    getHealthcenters(data.pharmas[0].id);
  });
}

angular.module("app.disruption").controller("offersMonitoringController", OffersMonitoringController);