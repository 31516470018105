"use strict";
/**
 * Filemanager configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

FilemanagerConfig.$inject = ["$stateProvider"];

function FilemanagerConfig($stateProvider) {
  // State
  $stateProvider.state("app.file-manager", {
    url: "/file-manager",
    views: {
      "content@app": {
        templateUrl: "app/main/file-manager/file-manager.html",
        controller: "FileManagerController as vm"
      }
    },
    bodyClass: "file-manager"
  });
}

angular.module("app.file-manager", []).config(FilemanagerConfig);