"use strict";
/**
 * Oauth configuration.
 *
 * @param {object} $stateProvider - $stateProvider.
 */

OauthConfig.$inject = ["$stateProvider"];

function OauthConfig($stateProvider) {
  $stateProvider.state("app.oauth", {
    url: "/oauth",
    views: {
      "content@app": {
        templateUrl: "app/main/oauth/views/main/oauth.main.html",
        controller: "oauthMainController"
      }
    }
  });
}

angular.module("app.oauth", []).config(OauthConfig).constant("oauthConfig", {
  baseDir: "app/main/oauth"
});