"use strict";
/**
 * Grouping audit list service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

GroupmentAuditListService.$inject = ["apiClient"];

function GroupmentAuditListService(apiClient) {
  return {
    /**
     * List.
     *
     * @returns {Promise}
     */
    list: function list() {
      return apiClient.get("/healthcenters/grouping/all", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.groupmentAudit").service("groupmentAuditListService", GroupmentAuditListService);