"use strict";
/**
 * App tile directive.
 *
 * @returns {object}
 */

function AppTile() {
  return {
    restrict: "E",
    scope: {
      tooltip: "@tooltip",
      variant: "@variant"
    },
    templateUrl: "app/main/directives/app-tile/appTile.html",
    replace: true,
    transclude: true,
    controllerAs: "vm"
  };
}

angular.module("app.directives").directive("appTile", AppTile);