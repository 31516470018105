"use strict";
/**
 * Instant discount voucher service.
 *
 * @param {object} apiClient -
 * @returns {object}
 */

InstantDiscountVoucherService.$inject = ["apiClient"];

function InstantDiscountVoucherService(apiClient) {
  return {
    /**
     * Create an instant discount voucher or update it if it already exists.
     *
     * @param {object} instantDiscountVoucher - Instant discount voucher to create or update.
     *
     * @returns {Promise}
     */
    createOrUpdate: function createOrUpdate(instantDiscountVoucher) {
      return instantDiscountVoucher.id ? this.update(instantDiscountVoucher) : this.create(instantDiscountVoucher);
    },

    /**
     * Create an instant discount voucher.
     *
     * @param {object} instantDiscountVoucher - Instant discount voucher to create.
     *
     * @returns {Promise}
     */
    create: function create(instantDiscountVoucher) {
      return apiClient.post("/instant-discount-vouchers", instantDiscountVoucher).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update an instant discount voucher.
     *
     * @param {object} instantDiscountVoucher - Instant discount voucher to update.
     *
     * @returns {Promise}
     */
    update: function update(instantDiscountVoucher) {
      return apiClient.put("/instant-discount-vouchers/".concat(instantDiscountVoucher.id), instantDiscountVoucher).then(function (response) {
        return response.data;
      });
    },

    /**
     * Publish an instant discount voucher.
     *
     * @param {object} instantDiscountVoucher - Instant discount voucher to publish.
     *
     * @returns {Promise}
     */
    publish: function publish(instantDiscountVoucher) {
      return apiClient.put("/instant-discount-vouchers/".concat(instantDiscountVoucher.id, "/publish"), instantDiscountVoucher).then(function (response) {
        return response.data;
      });
    },

    /**
     * Accept an instant discount voucher.
     *
     * @param {object} instantDiscountVoucher - Instant discount voucher to accept.
     *
     * @returns {Promise}
     */
    accept: function accept(instantDiscountVoucher) {
      return apiClient.put("/instant-discount-vouchers/".concat(instantDiscountVoucher.id, "/accept")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Accept an instant discount voucher.
     *
     * @param {object} instantDiscountVoucher - Instant discount voucher to accept.
     *
     * @returns {Promise}
     */
    refuse: function refuse(instantDiscountVoucher) {
      return apiClient.put("/instant-discount-vouchers/".concat(instantDiscountVoucher.id, "/accept")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Read an instant discount voucher.
     *
     * @param {object} instantDiscountVoucher - Instant discount voucher to read.
     *
     * @returns {Promise}
     */
    read: function read(instantDiscountVoucher) {
      return apiClient.get("/instant-discount-vouchers/".concat(instantDiscountVoucher.id) + "?include=include=laboratory,image,productsTrigger,productsReward,targets.morphable,targets.morphable.healthcenters,rewards,productsInformations").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get awaiting instant discount voucher.
     *
     * @returns {Promise}
     */
    getAwaiting: function getAwaiting() {
      return apiClient.get("/instant-discount-vouchers/awaiting?include=sponsor,target").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get current instant discount voucher.
     *
     * @returns {Promise}
     */
    getCurrent: function getCurrent() {
      return apiClient.get("/instant-discount-vouchers/current?include=sponsor").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get future instant discount voucher.
     *
     * @returns {Promise}
     */
    getFuture: function getFuture() {
      return apiClient.get("/instant-discount-vouchers/future?include=sponsor").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get finished instant discount voucher.
     *
     * @returns {Promise}
     */
    getFinished: function getFinished() {
      return apiClient.get("/instant-discount-vouchers/finished?include=sponsor").then(function (response) {
        return response.data;
      });
    },

    /**
     * Get performance data for an instant discount voucher.
     *
     * @param {object} instantDiscountVoucher - Instant discount voucher
     *      from which performance must be fetched.
     *
     * @returns {Promise}
     */
    getPerformance: function getPerformance(instantDiscountVoucher) {
      return apiClient.get("/instant-discount-vouchers/".concat(instantDiscountVoucher.id, "/performance")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get instant discount voucher details by healthcenters.
     *
     * @param {object} instantDiscountVoucher - Instant discount voucher
     *     from which details must be returned.
     *
     * @returns {Promise}
     */
    getDetailsByHealthcenters: function getDetailsByHealthcenters(instantDiscountVoucher) {
      return apiClient.post("/instant-discount-vouchers/".concat(instantDiscountVoucher.id, "/details/healthcenters")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get instant discount voucher details by products.
     *
     * @param {object} instantDiscountVoucher - Instant discount voucher
     *      from which details must be returned.
     *
     * @returns {Promise}
     */
    getDetailsByProducts: function getDetailsByProducts(instantDiscountVoucher) {
      return apiClient.post("/instant-discount-vouchers/".concat(instantDiscountVoucher.id, "/details/products")).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get an healthcenter sales that triggered a given instant discount voucher.
     *
     * @param {object} instantDiscountVoucher -
     * @param {number} healthcenterId -
     *
     * @returns {Promise}
     */
    getTriggeringSales: function getTriggeringSales(instantDiscountVoucher, healthcenterId) {
      return apiClient.get("/instant-discount-vouchers/".concat(instantDiscountVoucher.id, "/sales/healthcenter/").concat(healthcenterId)).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.instantDiscountVoucher").service("instantDiscountVoucherService", InstantDiscountVoucherService);