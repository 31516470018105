"use strict";
/**
 * The app-loading directive.
 *
 * @returns {object}
 */

function AppLoading() {
  return {
    restrict: "E",
    templateUrl: "app/main/directives/app-loading/appLoading.html"
  };
}

angular.module("app.directives").directive("appLoading", AppLoading);