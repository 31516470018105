"use strict";
/**
 * Dashboard laboratories sales analysis controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $rootScope - $rootScope.
 * @param {object} environmentService - Environment service.
 * @param {object} apodisLiveService - Apodis live service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} homeLaboratoryAnalysisService - Laboratory analysis service.
 */

dashboardLaboratoriesSalesAnalysisController.$inject = ["$scope", "$rootScope", "environmentService", "apodisLiveService", "StatisticsQueryService", "homeLaboratoryAnalysisService"];

function dashboardLaboratoriesSalesAnalysisController($scope, $rootScope, environmentService, apodisLiveService, StatisticsQueryService, homeLaboratoryAnalysisService) {
  var salesServiceName;
  var today = moment().format("YYYY-MM-DD");
  var firstDayOfTheYear = moment().month(0).date(0).format("YYYY-MM-DD");
  var todayLastYear = moment().subtract(1, "year").format("YYYY-MM-DD");
  var firstDayLastYear = moment(firstDayOfTheYear).subtract(1, "year").format("YYYY-MM-DD");
  $scope.thisYear = moment().format("YYYY");
  $scope.loading = true;
  $scope.salesThisYear = {
    aggregations: {
      quantity: {
        value: 0
      },
      amount_excl_tax: {
        value: 0
      }
    }
  };
  $scope.salesThisMonth = {
    aggregations: {
      quantity: {
        value: 0
      },
      amount_excl_tax: {
        value: 0
      }
    }
  };
  loader();
  /**
   * Loader.
   */

  function loader() {
    launchStatisticsQueries();
    environmentService.getEnvironment().then(launchSocket);
  }
  /**
   * On sales data.
   *
   * @param {object} salesData - Sales data.
   */


  function onSalesData(salesData) {
    $scope.salesThisYear.aggregations.quantity.value += salesData.item.quantity;
    $scope.salesThisMonth.aggregations.quantity.value += salesData.item.quantity;
    $scope.salesThisYear.aggregations.amount_excl_tax.value += salesData.item.price.sell.total_ht;
    $scope.salesThisMonth.aggregations.amount_excl_tax.value += salesData.item.price.sell.total_ht;
    $scope.$apply();
  }
  /**
   * Launch socket.
   *
   * @param {object} environment - Environment.
   */


  function launchSocket(environment) {
    salesServiceName = "sales_laboratory_" + environment.entityId;

    if (typeof apodisLiveService.socket._callbacks["$" + salesServiceName] === "undefined") {
      // Handler not present, install now
      apodisLiveService.register({
        service: salesServiceName
      });
    }

    apodisLiveService.socket.on(salesServiceName, onSalesData);
  }
  /**
   * Loader sales analysis.
   *
   * @param {object} query - Query.
   * @param {boolean} brdcast - Broadcast.
   *
   * @returns {Promise}
   */


  function loaderSalesAnalysis(query, brdcast) {
    if (!query.must.laboratory || query.must.laboratory.length === 0) {
      query.must.laboratory = [];
      query.must.laboratory.push($scope.env.entityId);
    }

    return salesCurrentYear(query, brdcast);
  }
  /**
   * Sales current year.
   *
   * @param {object} query - Query.
   * @param {boolean} brdcast - Broadcast.
   *
   * @returns {Promise}
   */


  function salesCurrentYear(query, brdcast) {
    query.startDatetime = firstDayOfTheYear;
    query.endDatetime = today;
    return homeLaboratoryAnalysisService.sales(query).then(function (response) {
      $scope.salesThisYear = response;
      salesLastYear(query, brdcast);
    });
  }
  /**
   * Sales last year.
   *
   * @param {object} query - Query.
   * @param {boolean} brdcast - Broadcast.
   *
   * @returns {Promise}
   */


  function salesLastYear(query, brdcast) {
    query.startDatetime = firstDayLastYear;
    query.endDatetime = todayLastYear;
    return homeLaboratoryAnalysisService.sales(query).then(function (response) {
      $scope.salesLastYear = response;
      salesCurrentMonth(query, brdcast);
    });
  }
  /**
   * Sales current month.
   *
   * @param {object} query - Query.
   * @param {boolean} brdcast - Broadcast.
   *
   * @returns {Promise}
   */


  function salesCurrentMonth(query, brdcast) {
    query.startDatetime = moment().date(0).format("YYYY-MM-DD");
    query.endDatetime = today;
    return homeLaboratoryAnalysisService.sales(query).then(function (response) {
      $scope.salesThisMonth = response;
      salesLastMonth(query, brdcast);
    });
  }
  /**
   * Sales last month.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function salesLastMonth(query) {
    query.startDatetime = moment().date(0).subtract(1, "year").format("YYYY-MM-DD");
    query.endDatetime = moment().subtract(1, "year").format("YYYY-MM-DD");
    return homeLaboratoryAnalysisService.sales(query).then(function (response) {
      $scope.salesLastMonth = response;
    });
  }
  /**
   * Launch statistics queries.
   */


  function launchStatisticsQueries() {
    // On ne peut lancer les requetes de statistiques
    // seulement lorsque l'objet access est disponible et valide.
    StatisticsQueryService.queryRebase(); // on recupere une query vierge pour ne pas interferer sur les queries de statistics

    $scope.usrquery = StatisticsQueryService.query;
    $scope.usrquery.date = {
      from: moment().subtract(12, "months"),
      to: moment()
    };
    StatisticsQueryService.buildquery($scope.usrquery, $scope.usrquery.date).then(function (query) {
      $scope.query = query;
      $scope.query.interval = "1M";
      loaderSalesAnalysis($scope.query);
    });
  }

  $rootScope.$on("$stateChangeStart", function () {
    apodisLiveService.unregister(salesServiceName, onSalesData);
  });
}

angular.module("app.dashboard").controller("dashboardLaboratoriesSalesAnalysisController", dashboardLaboratoriesSalesAnalysisController);