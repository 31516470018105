"use strict";
/**
 * Dashboard laboratories pie charts controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} environmentService - Environment service.
 * @param {object} StocksQueryService - Stocks query service.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} labSerial - Laboratory serial graph configuration.
 * @param {object} homeLaboratoryAnalysisService - Laboratory analysis service.
 * @param {object} analysisService - Analysis service.
 */

dashboardLaboratoriesPieChartsController.$inject = ["$scope", "environmentService", "StocksQueryService", "StatisticsQueryService", "labSerial", "homeLaboratoryAnalysisService", "analysisService"];

function dashboardLaboratoriesPieChartsController($scope, environmentService, StocksQueryService, StatisticsQueryService, labSerial, homeLaboratoryAnalysisService, analysisService) {
  var groupListLaboGroup = [{
    value: 0,
    title: "Pharmacies"
  }];
  $scope.loadingChart = true;
  $scope.amChartOptionsSerial = labSerial;
  $scope.amChartHistoStockOptions = {
    data: [],
    type: "serial",
    theme: "light",
    startDuration: 1,
    autoMarginOffset: 5,
    categoryField: "date",
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    legend: {
      enabled: true
    },
    chartScrollbar: {
      enabled: true
    },
    categoryAxis: {
      gridPosition: "start",
      parseDates: false
    },
    graphs: [{
      type: "column",
      title: "Unités",
      valueField: "qty",
      balloonText: "Unités :[[value]]",
      fillAlphas: 0.8,
      lineAlpha: 0.2
    }],
    "export": {
      enabled: true
    }
  };
  /**
   * Loader.
   */

  function loader() {
    environmentService.getEnvironment().then(function (environment) {
      launchSearch(environment.entityId);
      launchStatisticsQueries(environment.entityId);
    });
  }
  /**
   * Launch search.
   *
   * @param {number} laboratoryId - Laboratory ID.
   */


  function launchSearch(laboratoryId) {
    var stockQuery = StocksQueryService.buildQuery(StocksQueryService.query);
    analysisService.getLaboratoryStockByGroup(laboratoryId, groupListLaboGroup[0].value, stockQuery).then(processDataForAmCharts);
  }
  /**
   * Process data for AmCharts.
   *
   * @param {object} response -
   */


  function processDataForAmCharts(response) {
    var dataChartSerial = [];
    angular.forEach(response.aggregations.datas.buckets, function (dataHc) {
      var name = dataHc.key;
      var id = dataHc.id.buckets[0].key;
      var sumOrderAmount = 0;
      var serialLine = {
        healthcenter: name,
        id: id,
        range1: 0,
        range1Pc: 0,
        range2: 0,
        range3: 0,
        range4: 0,
        range5: 0,
        range6: 0,
        range7: 0
      };
      angular.forEach(dataHc.range_days.buckets, function (dataRange) {
        var nbRange = dataRange.key + 1;
        var strRange = "range" + nbRange;

        if (nbRange < 4) {
          sumOrderAmount += dataRange.needs_amount.value;
          serialLine[strRange] = dataRange.needs_amount.value;
        } else {
          sumOrderAmount += dataRange.order_amount.value;
          serialLine[strRange] = dataRange.order_amount.value;
        }
      });

      if (sumOrderAmount !== 0) {
        serialLine.range1Pc = serialLine.range1 / sumOrderAmount * 100;
      }

      dataChartSerial.push(serialLine);
    });
    dataChartSerial.sort(function (left, right) {
      return left.range1 - right.range1;
    });
    dataChartSerial.reverse();
    $scope.amChartOptionsSerial.data = dataChartSerial.slice(0, 10);
    $scope.loadingChart = false;
  }
  /**
   * Launch statistics queries.
   *
   * @param {number} laboratoryId - Laboratory ID.
   */


  function launchStatisticsQueries(laboratoryId) {
    StatisticsQueryService.queryRebase(); // on recupere une query vierge pour ne pas interferer sur les queries
    // de statistics

    var usrquery = StatisticsQueryService.query;
    usrquery.date = {
      from: moment().subtract(12, "months"),
      to: moment()
    };
    StatisticsQueryService.buildquery(usrquery, usrquery.date).then(function (query) {
      query.interval = "1M";

      if (!query.must.laboratory || query.must.laboratory.length === 0) {
        query.must.laboratory = [laboratoryId];
      }

      return homeLaboratoryAnalysisService.sales(query);
    }).then(processSalesData);
  }
  /**
   * Process sales data.
   *
   * @param {object} response - Response.
   */


  function processSalesData(response) {
    var lines = [];
    angular.forEach(response.aggregations.sales.buckets, function (data) {
      var obj = {
        date: moment(data.key).format("MM-YY")
      };
      obj.sales = data.amount_excl_tax.value;
      obj.qty = data.quantity.value; // eslint-disable-next-line no-invalid-this

      this.push(obj);
    }, lines);
    $scope.amChartHistoStockOptions.data = lines;
  }

  loader();
}

angular.module("app.dashboard").controller("dashboardLaboratoriesPieChartsController", dashboardLaboratoriesPieChartsController);