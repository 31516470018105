"use strict";
/**
 * Controller for patient last prescriptions, in pharmacy dashboard.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} $mdDialog - The $mdDialog object.
 * @param {object} $state - The angular $state object.
 * @param {object} patientPrescriptionService - The patient prescription service.
 */

dashboardPharmaciesPatientLastPrescriptionsController.$inject = ["$scope", "$mdDialog", "$state", "patientPrescriptionService"];

function dashboardPharmaciesPatientLastPrescriptionsController($scope, $mdDialog, $state, patientPrescriptionService) {
  $scope.loading = true;
  $scope.openRenewalSchedule = openRenewalSchedule;
  $scope.redirectToDosagePlan = redirectToDosagePlan;
  $scope.redirectToPrescriptionDetails = redirectToPrescriptionDetails;
  $scope.prescriptions = null;
  loader();
  /**
   * Loader function.
   */

  function loader() {
    var params = {
      limit: 5,
      certified: 1,
      "no-dosage-plan": 1
    };
    patientPrescriptionService.getPrescriptions(params).then(function (response) {
      $scope.prescriptions = response.data;
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Open renewal schedule modal
   *
   * @param {object} $event - The angular $event object.
   * @param {object} prescription - The prescription.
   */


  function openRenewalSchedule($event, prescription) {
    $event.stopPropagation();
    $mdDialog.show({
      controller: "prescriptionScheduleController",
      locals: {
        prescription: prescription
      },
      templateUrl: "app/main/patients/components/prescription-schedule/prescription-schedule.html",
      targetEvent: event,
      clickOutsideToClose: true,
      multiple: true,
      skipHide: true
    });
  }
  /**
   * Redirect to dosage plan.
   *
   * @param {object} $event - The angular $event object.
   * @param {object} prescription - The concerned prescription.
   */


  function redirectToDosagePlan($event, prescription) {
    $event.stopPropagation();
    $state.go("app.patients.details.prescription.dosage", {
      patientId: prescription.customer.id,
      prescriptionId: prescription.id
    });
  }
  /**
   * Redirect to the prescription details.
   *
   * @param {object} $event - The angular $event object.
   * @param {object} prescription - The prescription.
   */


  function redirectToPrescriptionDetails($event, prescription) {
    $event.stopPropagation();
    $state.go("app.patients.details.prescription", {
      patientId: prescription.customer.id,
      prescriptionId: prescription.key
    });
  }
}

angular.module("app.dashboard").controller("dashboardPharmaciesPatientLastPrescriptionsController", dashboardPharmaciesPatientLastPrescriptionsController);