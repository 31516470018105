"use strict";
/**
 * The patient order services.
 *
 * @param {object} hdsApiClient - The HDS Api client service.
 *
 * @returns {object}
 */

PatientOrderService.$inject = ["hdsApiClient"];

function PatientOrderService(hdsApiClient) {
  return {
    /**
     * Get order index.
     *
     * @param {object} params - Query params.
     *
     * @returns {Promise}
     */
    indexOrders: function indexOrders(params) {
      return hdsApiClient("GET", "/orders", {
        params: params
      });
    }
  };
}

angular.module("app.patients.services").service("patientOrderService", PatientOrderService);