"use strict";

(function () {
  'use strict';

  MsFormWizardController.$inject = ["$rootScope"];
  angular.module('app.core').controller('MsFormWizardController', MsFormWizardController).directive('msFormWizard', msFormWizardDirective).directive('msFormWizardForm', msFormWizardFormDirective);
  /** @ngInject */

  function MsFormWizardController($rootScope) {
    var vm = this; // Data

    vm.forms = [];
    vm.selectedIndex = 0; // Methods

    vm.registerForm = registerForm;
    vm.previousStep = previousStep;
    vm.nextStep = nextStep;
    vm.firstStep = firstStep;
    vm.lastStep = lastStep;
    vm.totalSteps = totalSteps;
    vm.isFirstStep = isFirstStep;
    vm.isLastStep = isLastStep;
    vm.currentStepInvalid = currentStepInvalid;
    vm.previousStepInvalid = previousStepInvalid;
    vm.formsIncomplete = formsIncomplete;
    vm.resetForm = resetForm; //////////

    /**
     * Register form
     *
     * @param form
     */

    function registerForm(form) {
      vm.forms.push(form);
    }
    /**
     * Go to previous step
     */


    function previousStep() {
      if (isFirstStep()) {
        return;
      }

      vm.selectedIndex--;
    }
    /**
     * Go to next step
     */


    function nextStep() {
      if (isLastStep()) {
        return;
      }

      $rootScope.$broadcast('formsPrestation', vm.forms[vm.selectedIndex]);
      vm.selectedIndex++;
    }
    /**
     * Go to first step
     */


    function firstStep() {
      vm.selectedIndex = 0;
    }
    /**
     * Go to last step
     */


    function lastStep() {
      vm.selectedIndex = totalSteps() - 1;
    }
    /**
     * Return total steps
     *
     * @returns {int}
     */


    function totalSteps() {
      return vm.forms.length;
    }
    /**
     * Is first step?
     *
     * @returns {boolean}
     */


    function isFirstStep() {
      return vm.selectedIndex === 0;
    }
    /**
     * Is last step?
     *
     * @returns {boolean}
     */


    function isLastStep() {
      return vm.selectedIndex === totalSteps() - 1;
    }
    /**
     * Is current step invalid?
     *
     * @returns {boolean}
     */


    function currentStepInvalid() {
      return angular.isDefined(vm.forms[vm.selectedIndex]) && vm.forms[vm.selectedIndex].$invalid;
    }
    /**
     * Is previous step invalid?
     *
     * @returns {boolean}
     */


    function previousStepInvalid() {
      return vm.selectedIndex > 0 && angular.isDefined(vm.forms[vm.selectedIndex - 1]) && vm.forms[vm.selectedIndex - 1].$invalid;
    }
    /**
     * Check if there is any incomplete forms
     *
     * @returns {boolean}
     */


    function formsIncomplete() {
      for (var x = 0; x < vm.forms.length; x++) {
        if (vm.forms[x].$invalid) {
          return true;
        }
      }

      return false;
    }
    /**
     * Reset form
     */


    function resetForm() {
      // Go back to the first step
      vm.selectedIndex = 0; // Make sure all the forms are back in the $pristine & $untouched status

      for (var x = 0; x < vm.forms.length; x++) {
        vm.forms[x].$setPristine();
        vm.forms[x].$setUntouched();
      }
    }

    $rootScope.$on("errorFormWizard", function (event, data) {
      previousStep();
    });
  }
  /** @ngInject */


  function msFormWizardDirective() {
    return {
      restrict: 'E',
      scope: true,
      controller: 'MsFormWizardController as msWizard',
      compile: function compile(tElement) {
        tElement.addClass('ms-form-wizard');
        return function postLink() {};
      }
    };
  }
  /** @ngInject */


  function msFormWizardFormDirective() {
    return {
      restrict: 'A',
      require: ['form', '^msFormWizard'],
      compile: function compile(tElement) {
        tElement.addClass('ms-form-wizard-form');
        return function postLink(scope, iElement, iAttrs, ctrls) {
          var formCtrl = ctrls[0],
              MsFormWizardCtrl = ctrls[1];
          MsFormWizardCtrl.registerForm(formCtrl);
        };
      }
    };
  }
})();