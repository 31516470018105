"use strict";
/**
 * Offers service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

offersService.$inject = ["apiClient"];

function offersService(apiClient) {
  return {
    /**
     * Get.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    get: function get(data) {
      var query = {
        includes: data.includes ? data.includes : null
      };
      return apiClient.get("/offer/commercial-offers/".concat(data.category, "/").concat(data.type, "/laboratories/").concat(data.laboratoryId), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get cart.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getCart: function getCart(data) {
      var query = {
        includes: data.includes ? data.includes : null
      };
      return apiClient.get("/shop/laboratories/".concat(data.laboratoryId, "/healthcenters/").concat(data.healthcenterId, "/cart"), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Manage cart.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    manageCart: function manageCart(data) {
      var query = {
        includes: data.includes ? data.includes : null,
        products: data.products
      };
      return apiClient.post("/shop/laboratories/".concat(data.laboratoryId, "/healthcenters/").concat(data.healthcenterId, "/manageCart"), query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Manage cart.
     *
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    getProductsPrice: function getProductsPrice(data) {
      var query = {
        includes: data.includes ? data.includes : null,
        products: data.products
      };
      return apiClient.post("/shop/laboratories/".concat(data.laboratoryId, "/healthcenters/").concat(data.healthcenterId, "/productsPrice"), query).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.users-laboratory").service("offersService", offersService);