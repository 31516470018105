"use strict";
/**
 * Forgot password controller.
 *
 * @param {object} authProfileService - Auth profile service.
 */

ForgotPasswordController.$inject = ["authProfileService"];

function ForgotPasswordController(authProfileService) {
  var vm = this;
  vm.domainName = window.sessionStorage.getItem("domainName");
  vm.userForgotPwd = {
    email: ""
  };
  vm.forgotPassword = forgotPassword;
  /**
   * Forgot password.
   */

  function forgotPassword() {
    authProfileService.forgotPassword(vm.userForgotPwd).then(function (response) {
      vm.response = response;
    });
  }
}

angular.module("app.auth").controller("ForgotPasswordController", ForgotPasswordController);