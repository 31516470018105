"use strict";
/**
 * Impact analysis details controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} impactAnalysisService - Impact analysis service.
 * @param {object} $q - $q.
 */

ImpactAnalysisDetailsController.$inject = ["$scope", "$stateParams", "impactAnalysisService", "$q"];

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ImpactAnalysisDetailsController($scope, $stateParams, impactAnalysisService, $q) {
  loader();
  $scope.studiedNumberChange = studiedNumberChange;
  $scope.studiedNumber = "CA";
  /**
   * loader
   * Load each methods inside
   */

  function loader() {
    readAnalysis();
  }
  /**
   * Make line chart.
   *
   * @param {Array} data - Data.
   * @param {Array} guides - Guides.
   */


  function makeLineChart(data, guides) {
    $scope.lineChart = AmCharts.makeChart("impactAnalysisLineChart", {
      type: "serial",
      theme: "light",
      autoMargins: true,
      thousandsSeparator: " ",
      precision: 2,
      unit: " EUR HT",
      balloon: {
        adjustBorderColor: false,
        horizontalPadding: 10,
        verticalPadding: 8,
        color: "#ffffff"
      },
      titles: [{
        text: "Impact " + $scope.impactAnalysis.name + " et période d’analyse du " + $scope.impactAnalysis.start_date + " au " + $scope.impactAnalysis.end_date,
        size: 15
      }],
      dataProvider: data,
      valueAxes: [{
        id: "marketShares",
        axisAlpha: 0,
        gridAlpha: 0,
        position: "left",
        title: "Parts de marché",
        minimum: 0,
        maximum: Math.max.apply(Math, _toConsumableArray(data.map(function (obj) {
          return obj.marketShares;
        }))),
        unit: " %"
      }, {
        id: "sales",
        axisAlpha: 0,
        gridAlpha: 0,
        position: "right",
        title: "Ventes",
        unit: " €"
      }],
      legend: {
        enabled: true
      },
      startDuration: 0,
      graphs: [{
        id: "marketShares",
        alphaField: "alpha",
        balloonText: "<span style='font-size:12px;'>[[title]] la semaine [[category]]:<br>" + "<span style='font-size:20px;'>[[value]] %</span>" + "</span>",
        fillAlphas: 1,
        title: "Parts de marché",
        type: "column",
        valueField: "marketShares",
        valueAxis: "marketShares",
        dashLengthField: "dashLengthColumn"
      }, {
        id: "sales",
        balloonText: "<span style='font-size:12px;'>[[title]]  la semaine [[category]]:<br>" + "<span style='font-size:20px;'>[[value]] €</span>" + "</span>",
        bullet: "round",
        lineThickness: 3,
        bulletSize: 7,
        bulletBorderAlpha: 1,
        bulletColor: "#FFFFFF",
        useLineColorForBulletBorder: true,
        bulletBorderThickness: 3,
        fillAlphas: 0,
        lineAlpha: 1,
        title: "Ventes",
        valueField: "sales",
        type: "smoothedLine",
        valueAxis: "sales",
        dashLengthField: "dashLengthLine"
      }],
      categoryField: "date",
      categoryAxis: {
        gridPosition: "start",
        axisAlpha: 0,
        tickLength: 0,
        guides: guides
      },
      language: "fr",
      "export": {
        enabled: true
      }
    });
  }
  /**
   * Make bar chart.
   *
   * @param {Array} data - Data.
   * @param {Array} guides - Guides.
   */


  function makeBarChart(data, guides) {
    $scope.barChart = AmCharts.makeChart("impactAnalysisBarChart", {
      type: "serial",
      theme: "light",
      categoryField: "date",
      thousandsSeparator: " ",
      precision: 2,
      rotate: false,
      marginLeft: 20,
      marginRight: 120,
      startDuration: 0,
      titles: [{
        text: "Impact comparatif groupe témoin",
        size: 15
      }],
      categoryAxis: {
        gridPosition: "start",
        axisAlpha: 0,
        tickLength: 0
      },
      legend: {
        enabled: true
      },
      graphs: [{
        balloonText: "<span style='font-size:12px;'>[[title]] la semaine [[category]]:<br>" + "<span style='font-size:20px;'>[[value]] %</span>" + "</span>",
        fillAlphas: 0.8,
        id: "marketShares",
        lineAlpha: 0.2,
        title: "Parts de marché",
        type: "column",
        valueField: "marketShares"
      }, {
        balloonText: "<span style='font-size:12px;'>[[title]] la semaine [[category]]:<br>" + "<span style='font-size:20px;'>[[value]] %</span>" + "</span>",
        fillAlphas: 0.8,
        id: "sales",
        lineAlpha: 0.2,
        title: "Parts de marché segment témoin",
        type: "column",
        valueField: "controlSegmentMarketShares"
      }],
      guides: guides,
      valueAxes: [{
        id: "ValueAxis-1",
        position: "top",
        axisAlpha: 0,
        minimum: 0,
        maximum: Math.max.apply(Math, _toConsumableArray(data.map(function (obj) {
          return obj.marketShares;
        })).concat(_toConsumableArray(data.map(function (obj) {
          return obj.controlSegmentMarketShares;
        })))),
        unit: " %"
      }],
      dataProvider: data,
      language: "fr",
      "export": {
        enabled: true
      }
    });
  }
  /**
   * Read analysis.
   *
   * @returns {Promise}
   */


  function readAnalysis() {
    return impactAnalysisService.readReport($stateParams).then(function (response) {
      response.impactAnalysis.data.start_date = moment(response.impactAnalysis.data.start_date).format("DD/MM/YYYY");
      response.impactAnalysis.data.end_date = moment(response.impactAnalysis.data.end_date).format("DD/MM/YYYY");
      $scope.impactAnalysis = response.impactAnalysis.data;
      return getFormattedData(response.report).then(function (data) {
        return getFormattedGuides(response.impactAnalysis.data).then(function (guides) {
          makeLineChart(data, guides);
          makeBarChart(data, guides);
        });
      });
    });
  }
  /**
   * Get formatted data.
   *
   * @param {Array} data - Data.
   *
   * @returns {Promise}
   */


  function getFormattedData(data) {
    var sales = data.aggregations.sales.buckets.map(function (weekSales) {
      return {
        date: moment(weekSales.key).week(),
        marketShares: weekSales.impactProducts.amount_excl_tax.value / (weekSales.amount_excl_tax.value + weekSales.impactProducts.amount_excl_tax.value) * 100,
        marketSharesUnit: weekSales.impactProducts.quantity.value / (weekSales.quantity.value + weekSales.impactProducts.quantity.value) * 100,
        controlSegmentMarketShares: weekSales.impactProducts.controlSegment.amount_excl_tax.value / (weekSales.impactProducts.amount_excl_tax.value + weekSales.impactProducts.controlSegment.amount_excl_tax.value) * 100,
        controlSegmentMarketSharesUnit: weekSales.impactProducts.controlSegment.quantity.value / (weekSales.impactProducts.quantity.value + weekSales.impactProducts.controlSegment.quantity.value) * 100,
        sales: weekSales.impactProducts.amount_excl_tax.value,
        unit: weekSales.impactProducts.quantity.value
      };
    });
    return $q.when(sales);
  }
  /**
   * Get formatted guides.
   *
   * @param {Array} impactAnalysis - Impact analysis.
   *
   * @returns {Promise}
   */


  function getFormattedGuides(impactAnalysis) {
    var guides = impactAnalysis.events.map(function (event) {
      return {
        category: event.startWeekOfYear,
        toCategory: event.endWeekOfYear,
        lineColor: "grey",
        lineAlpha: 1,
        fillAlpha: 0.2,
        fillColor: "grey",
        dashLength: 2,
        inside: true,
        label: event.name
      };
    });
    return $q.when(guides);
  }
  /**
   * Studied number change.
   *
   * @param {string} value - Value.
   */


  function studiedNumberChange(value) {
    if (value === "QT") {
      $scope.lineChart.getValueAxisById("sales").unit = "";
      $scope.lineChart.getGraphById("sales").valueField = "unit";
      $scope.lineChart.getGraphById("sales").unit = " unités";
      $scope.lineChart.getGraphById("marketShares").valueField = "marketSharesUnit";
    } else {
      $scope.lineChart.getValueAxisById("sales").unit = " €";
      $scope.lineChart.getGraphById("sales").valueField = "sales";
      $scope.lineChart.getGraphById("sales").unit = " €";
      $scope.lineChart.getGraphById("marketShares").valueField = "marketShares";
    }

    $scope.lineChart.validateData();
  }
}

angular.module("app.impactAnalysis").controller("impactAnalysisDetailsController", ImpactAnalysisDetailsController);