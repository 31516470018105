"use strict";
/**
 * Sellout report service.
 *
 * @param {object} apiClient -
 * @returns {object}
 */

SelloutReportService.$inject = ["apiClient"];

function SelloutReportService(apiClient) {
  return {
    /**
     * Read a sellout.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readSellout: function readSellout(params) {
      return apiClient.get("/sellout/".concat(params.id), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.data;
      });
    },

    /**
     * Count participant.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    countParticipants: function countParticipants(params) {
      return apiClient.get("/sellout/".concat(params.id, "/participants/count"), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.data;
      });
    },

    /**
     * Get participants that do not have answered.
     *
     * @param {object} params -
     * @param {number} params.id -
     * @param {number} params.limit -
     * @param {number} params.page -
     * @returns {Promise}
     */
    getPendingParticipants: function getPendingParticipants(_ref) {
      var id = _ref.id,
          limit = _ref.limit,
          page = _ref.page;
      return apiClient.get("/sellout/".concat(id, "/participants/pending"), {
        includes: ["organization.morphable"],
        parameters: [{
          name: "limit",
          value: limit
        }, {
          name: "page",
          value: page
        }]
      }).then(function (_ref2) {
        var data = _ref2.data,
            meta = _ref2.meta;
        return {
          data: data,
          meta: meta
        };
      });
    },

    /**
     * Get participants that have accepted the sellout.
     *
     * @param {object} params -
     * @param {number} params.id -
     * @param {number} params.limit -
     * @param {number} params.page -
     * @returns {Promise}
     */
    getAcceptedParticipants: function getAcceptedParticipants(_ref3) {
      var id = _ref3.id,
          limit = _ref3.limit,
          page = _ref3.page;
      return apiClient.get("/sellout/".concat(id, "/participants/accepted"), {
        includes: ["organization.morphable", "performance.children"],
        parameters: [{
          name: "limit",
          value: limit
        }, {
          name: "page",
          value: page
        }]
      }).then(function (_ref4) {
        var data = _ref4.data,
            meta = _ref4.meta;
        return {
          data: data,
          meta: meta
        };
      });
    },

    /**
     * Get participants that have refused the sellout.
     *
     * @param {object} params -
     * @param {number} params.id -
     * @param {number} params.limit -
     * @param {number} params.page -
     * @returns {Promise}
     */
    getRefusedParticipants: function getRefusedParticipants(_ref5) {
      var id = _ref5.id,
          limit = _ref5.limit,
          page = _ref5.page;
      return apiClient.get("/sellout/".concat(id, "/participants/refused"), {
        includes: ["organization.morphable"],
        parameters: [{
          name: "limit",
          value: limit
        }, {
          name: "page",
          value: page
        }]
      }).then(function (_ref6) {
        var data = _ref6.data,
            meta = _ref6.meta;
        return {
          data: data,
          meta: meta
        };
      });
    },

    /**
     * Read chart.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    readChart: function readChart(params) {
      return apiClient.get("/sellout/".concat(params.id, "/charts/sale")).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.sellout").service("selloutReportService", SelloutReportService);