"use strict";
/**
 * Next events directive.
 *
 * @returns {object}
 */

nextEventsDirectiveCtrl.$inject = ["$scope", "eventService"];

function NextEventsDirective() {
  return {
    restrict: "E",
    templateUrl: "app/main/events/directive/next-events/nextEvents.html",
    controller: nextEventsDirectiveCtrl
  };
}
/**
 * Next events directive controller.
 *
 * @param {object} $scope - $scope.
 * @param {object} eventService - Event service.
 */


function nextEventsDirectiveCtrl($scope, eventService) {
  loader();
  /**
   * The loader function
   */

  function loader() {
    $scope.today = new Date();
    getEvents();
  }
  /**
   * Retrieve the events
   */


  function getEvents() {
    eventService.getEvents().then(function (results) {
      $scope.events = results.events;
    });
  }
}

angular.module("app.events").directive("nextEvents", NextEventsDirective);