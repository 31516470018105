"use strict";
/**
 * Detail stocks tab controller.
 *
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} environmentService - Environment service.
 * @param {object} analysisService - Analysis service.
 */

DetailStocksTabController.$inject = ["$mdDialog", "$scope", "StatisticsQueryService", "environmentService", "analysisService"];
DetailStocksProductGraphTabController.$inject = ["$q", "$mdDialog", "$scope", "product", "currentTableConfig", "analysisService"];

function DetailStocksTabController($mdDialog, $scope, StatisticsQueryService, environmentService, analysisService) {
  var vm = this;
  vm.loading = true;
  vm.values = {};
  vm.totalPrice = {};
  vm.prices = {};
  $scope.changeProposal = changeProposal;
  $scope.changeRound = changeRound;
  vm.isLoaded = false;
  vm.launchSearch = launchSearch;
  vm.configSearch = {
    event: "stockReassortFilterChange",
    search: []
  };
  vm.usrquery = StatisticsQueryService.query;
  var currentEnvironment = environmentService.getEnvironment();
  currentEnvironment.then(function (env) {
    vm.currentEnv = env;

    if (vm.currentEnv.accessPointType === 1 && vm.usrquery.must.laboratory.selected.length === 0) {
      vm.usrquery.must.laboratory.selected.push(vm.currentEnv.entity);
    }
  });

  vm.removeFilter = function (obj, list) {
    var index = list.indexOf(obj);

    if (index !== -1) {
      list.splice(index, 1);
      vm.loading = true;
      vm.usrquery = StatisticsQueryService.query;
      StatisticsQueryService.buildQuery(vm.usrquery, vm.usrquery.date).then(function (query) {
        vm.query = query;
        launchSearch(vm.query, false);
      });
    }
  };

  vm.rawValues = StatisticsQueryService.values;
  /*
       ** fonctions générales de gestion des chekboxes
       */

  vm.toggleCb = toggleCb;
  vm.indexByLabel = indexByLabel;
  vm.existsFilter = existsFilter;
  /**
   * Check or uncheck the checkbox
   *
   * @param {object} item -
   * @param {Array} list -
   */

  function toggleCb(item, list) {
    var idx = vm.indexByLabel(item.label, list);

    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }

    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      vm.query = query;
      launchSearch();
      vm.loading = true;
    });
  }

  vm.getLimits = function (array) {
    return [Math.floor(array.length / 2), -Math.floor(array.length / 2)];
  }; // Methods


  vm.removeFilter = removeFilter;
  /**
   * Change period of proposal
   */

  function changeProposal() {
    angular.forEach(vm.detailStocksProducts, function (product) {
      var value = Math.ceil(product._source.needs.calcul.quantity_propal / 60 * (60 + product._source.product.laboratory_delivery || 0));

      if (product._source.product.packing && value % product._source.product.packing !== 0 && product._source.needs.calcul.order_needs.toFixed(0) !== 0) {
        value = value + (product._source.product.packing - value % product._source.product.packing);
      }

      var orderProposal = value / 60 * $scope.reassort.proposal;

      if (orderProposal > parseInt(orderProposal) + $scope.reassort.round) {
        orderProposal = Math.ceil(orderProposal);
      } else {
        orderProposal = Math.floor(orderProposal);
      }

      vm.values[product._source.product.presentation] = orderProposal;
    });
  }
  /**
   * Change round
   */


  function changeRound() {
    angular.forEach(vm.detailStocksProducts, function (product) {
      var value = Math.ceil(product._source.needs.calcul.quantity_propal / 60 * (60 + product._source.product.laboratory_delivery || 0));

      if (product._source.product.packing && value % product._source.product.packing !== 0 && product._source.needs.calcul.order_needs.toFixed(0) !== 0) {
        value = value + (product._source.product.packing - value % product._source.product.packing);
      }

      var orderProposal = value / 60 * $scope.reassort.proposal;

      if (orderProposal > parseInt(orderProposal) + $scope.reassort.round) {
        orderProposal = Math.ceil(orderProposal);
      } else {
        orderProposal = Math.floor(orderProposal);
      }

      vm.values[product._source.product.presentation] = orderProposal;
    });
  }
  /**
   * Index the filters by label
   *
   * @param {string} name -
   * @param {Array} list -
   * @returns {number}
   */


  function indexByLabel(name, list) {
    if (!name || !list) {
      return -1;
    }

    for (var index = 0, lemgth = list.length; index < lemgth; index++) {
      if (list[index].label === name) {
        return index;
      }
    }

    return -1;
  }
  /**
   * Check if filter exists
   *
   * @param {string} name -
   * @param {Array} list -
   * @returns {boolean}
   */


  function existsFilter(name, list) {
    return vm.indexByLabel(name, list) > -1;
  }
  /**
   * Filters Labels Management
   *
   * @param {object} obj - Object.
   * @param {Array} list - List.
   */


  function removeFilter(obj, list) {
    var index = list.indexOf(obj);

    if (index !== -1) {
      list.splice(index, 1);
      vm.loading = true;
      StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
        vm.query = query;
        launchSearch();
      });
    }
  }

  $scope.reassort = {
    proposal: 60,
    round: 0.5
  };
  $scope.currentTableConfig = {
    currentPage: 1,
    size: 10,
    order: "needs.calcul.quantity_propal"
  };
  vm.colors = ["#fab231", "#faeb4c", "#b7d787", "#89b74e", "#85caa0", "#9bd8df", "#218b9a"];
  vm.amChartOptionsPie0w90 = {
    data: [],
    type: "pie",
    theme: "light",
    startDuration: 1,
    marginTop: 5,
    marginBottom: 0,
    marginLeft: 5,
    marginRight: 5,
    pullOutRadius: 10,
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    unit: " EUR HT",
    categoryField: "title",
    valueField: "amount",
    colorField: "color",
    titleField: "title",
    precision: 0,
    balloon: {
      fixedPosition: true
    },
    legend: {
      position: "bottom",
      valueText: "[[value]] €"
    },
    "export": {
      enabled: true
    }
  };
  vm.amChartOptions = {
    data: [],
    type: "serial",
    theme: "light",
    startDuration: 1,
    autoMarginOffset: 5,
    categoryField: "range30days",
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    legend: {
      enabled: true
    },
    chartScrollbar: {
      enabled: true
    },
    categoryAxis: {
      gridPosition: "start",
      parseDates: false
    },
    graphs: [{
      type: "column",
      title: "Unités",
      valueField: "quantity",
      balloonText: "Unités :[[value]]",
      fillAlphas: 0.8,
      lineAlpha: 0.2
    }],
    "export": {
      enabled: true
    }
  };
  init();
  /**
   * The init function
   *
   * @returns {Promise}
   */

  function init() {
    return environmentService.getEnvironment().then(function (response) {
      vm.hc = response.entity;
      return StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
        vm.query = query;
        return launchSearch();
      });
    });
  }
  /**
   * Launch the request
   *
   * @returns {Promise}
   */


  function launchSearch() {
    vm.loading = true;
    vm.amChartOptions.data = [];
    vm.amChartOptionsPie0w90.data = [];
    vm.detailStocksGeneral = [];
    vm.query.tableConfig = $scope.currentTableConfig;
    return analysisService.getLaboratoryStockByHealthcenter(vm.hc.cip, vm.query).then(function (response) {
      vm.detailStocksProducts = response.hits.hits;
      $scope.totalNumber = response.hits.total;
      vm.detailStocksGeneral = response.aggregations;
      angular.forEach(vm.detailStocksProducts, function (product) {
        var value = Math.ceil(product._source.needs.calcul.quantity_propal / 60 * (60 + product._source.product.laboratory_delivery || 0));

        if (product._source.product.packing && value % product._source.product.packing !== 0 && product._source.needs.calcul.order_needs.toFixed(0) !== 0) {
          value = value + (product._source.product.packing - value % product._source.product.packing);
        }

        vm.values[product._source.product.presentation] = value / 60 * $scope.reassort.proposal;
        vm.prices[product._source.product.presentation] = product._source.product.price;
        vm.totalPrice[product._source.product.presentation] = (product._source.product.price * vm.values[product._source.product.presentation]).toFixed(2);
      });
      processDataForAmCharts();
      vm.isLoaded = true;
      vm.loading = false;
      return vm.detailStocksProducts;
    });
  }
  /**
   * Manage data for the chart
   */


  function processDataForAmCharts() {
    var colors = [{
      title: "< 10 jours",
      color: vm.colors[0]
    }, {
      title: "Entre 10 et 20 jours",
      color: vm.colors[1]
    }, {
      title: "Entre 20 et 30 jours",
      color: vm.colors[2]
    }, {
      title: "Entre 30 et 60 jours",
      color: vm.colors[3]
    }, {
      title: "Entre 60 et 90 jours",
      color: vm.colors[4]
    }, {
      title: "> 90 jours",
      color: vm.colors[5]
    }, {
      title: "Stock dormant",
      color: vm.colors[6]
    }];
    vm.stockIndicator = {
      needsIn60daysAmount: 0,
      needsIn60daysQuantity: 0,
      orderIn60daysQuantity: 0,
      orderIn6090daysAmount: 0,
      orderIn6090daysQuantity: 0,
      orderIn90daysAmount: 0,
      orderIn90daysQuantity: 0
    };
    var dataRange0w90 = [];
    angular.forEach(vm.detailStocksGeneral.range_days.buckets, function (dataStock) {
      var nbRange = dataStock.key;
      var data = {
        range: nbRange,
        amount: dataStock.order_amount.value,
        color: colors[nbRange].color,
        title: colors[nbRange].title
      };
      dataRange0w90.push(data);

      if (nbRange < 4) {
        vm.stockIndicator.needsIn60daysAmount += dataStock.needs_amount.value;
        vm.stockIndicator.needsIn60daysQuantity += dataStock.needs_quantity.value;
        vm.stockIndicator.orderIn60daysQuantity += dataStock.order_quantity.value;
      } else {
        if (nbRange === 4 || nbRange === 5) {
          vm.stockIndicator.orderIn6090daysAmount += dataStock.order_amount.value;
          vm.stockIndicator.orderIn6090daysQuantity += dataStock.order_quantity.value;
        }

        if (nbRange === 6) {
          vm.stockIndicator.orderIn90daysAmount += dataStock.order_amount.value;
          vm.stockIndicator.orderIn90daysQuantity += dataStock.order_quantity.value;
        }
      }
    });
    vm.amChartOptionsPie0w90.data = [];
    vm.amChartOptionsPie0w90.data = dataRange0w90;
    var lines = [];

    for (var index = 1; index <= 12; index++) {
      lines.push({
        range30days: "-" + index + " mois",
        quantity: vm.detailStocksGeneral["sales_volume_month_" + index].value
      });
    }

    lines.reverse();
    vm.amChartOptions.data = [];
    vm.amChartOptions.data = lines;
    vm.loading = false;
  }
  /**
   * Go to detail
   *
   * @param {object} ev -
   * @param {number} id -
   */


  vm.goDetail = function (ev, id) {
    var product = _.find(vm.detailStocksProducts, function (det) {
      return det._source.product.presentation === id.toString();
    })._source;

    $mdDialog.show({
      locals: {
        product: product,
        currentTableConfig: $scope.currentTableConfig
      },
      controller: DetailStocksProductGraphTabController,
      templateUrl: "app/main/healthcenters/detail/tabs/stocks-product-graph.tmpl.html",
      parent: angular.element(document.body),
      targetEvent: ev,
      clickOutsideToClose: true
    });
  };
  /**
   * Get the generic of the product
   *
   * @returns {object}
   */


  vm.getGeneric = function () {
    return sumAllProduct();
  };
  /**
   * Get category needs for a product
   *
   * @param {object} product -
   * @returns {number}
   */


  vm.getCategory = function (product) {
    var needsCategory = 0;
    angular.forEach(product.category_data.hits.hits, function (prod) {
      needsCategory += prod._source.product.prop.calcul.quantity_propal;
    });
    return needsCategory;
  };
  /**
   * Make a net sum
   *
   * @param {Array} products -
   * @returns {number}
   */


  vm.sumOrderNetMuliple = function (products) {
    var orderNetMuliple = 0;
    angular.forEach(products, function (data) {
      orderNetMuliple += data.calcul.orderNetMuliple;
    });
    return orderNetMuliple;
  };
  /**
   * Change the quantity
   *
   * @param {number} id -
   */


  vm.changePropalQuantities = function (id) {
    vm.totalPrice[id] = (vm.prices[id] * vm.values[id]).toFixed(2);
  };
  /*
       ** Events
       */


  $scope.$on("stockReassortFilterChange", function () {
    vm.loading = true;
    StatisticsQueryService.buildquery(vm.usrquery, vm.usrquery.date).then(function (query) {
      vm.query = query;
      launchSearch();
    });
  });
}
/**
 * Detail stocks product graph tab controller.
 *
 * @param {object} $q - $q.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} $scope - $scope.
 * @param {object} product - Product.
 * @param {object} currentTableConfig - Current table configuration.
 * @param {object} analysisService - Analysis service.
 */


function DetailStocksProductGraphTabController($q, $mdDialog, $scope, product, currentTableConfig, analysisService) {
  var vm = this;
  $scope.loading = true;
  $scope.product = product;
  $scope.typeGraph = "Générique";
  $scope.lines = {};
  $scope.sumAllProduct = sumAllProduct(product);
  vm.deferredChart = $q.defer();
  $scope.amChartOptions = {
    data: vm.deferredChart.promise,
    type: "serial",
    theme: "light",
    autoMarginOffset: 5,
    categoryField: "range30days",
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    titles: [{
      text: "Ventes sur 12 mois",
      size: 16
    }],
    legend: {
      enabled: true
    },
    chartScrollbar: {
      enabled: true
    },
    valueAxes: [{
      stackType: "regular",
      axisAlpha: 0.5,
      gridAlpha: 0
    }],
    categoryAxis: {
      gridPosition: "start",
      parseDates: false
    },
    graphs: [],
    "export": {
      enabled: true
    }
  };
  /**
   * Manage data for the chart
   *
   * @param {object} dataChart -
   */

  function processDataHistoForAmCharts(dataChart) {
    var lines = [];
    angular.forEach(dataChart.hits.hits, function (product) {
      if (product._source.needs && product._source.needs.calcul.sale_amount_average_month_weighting !== 0) {
        var addProduct = {
          type: "column",
          title: product._source.product.presentation_name,
          valueField: product._source.product.presentation,
          labelText: "[[value]]",
          balloonText: product._source.product.presentation_name + " :[[value]]",
          fillAlphas: 0.8,
          lineAlpha: 0.2
        };
        $scope.amChartOptions.graphs.push(addProduct);
      }
    });

    var _loop = function _loop(index) {
      var lineMonth = {};
      lineMonth.range30days = "-" + index + " mois";
      angular.forEach(dataChart.hits.hits, function (product) {
        if (product._source.needs && product._source.needs.calcul.sale_amount_average_month_weighting !== 0) {
          lineMonth[product._source.product.presentation] = product._source.needs.sales[index].volume;
        }
      });
      lines.push(lineMonth);
    };

    for (var index = 1; index < 12; index++) {
      _loop(index);
    }

    $scope.lines = lines;
    lines.reverse();
    vm.deferredChart.resolve(lines);
    $scope.loading = false;
  }

  var query = {};

  if (product.product.category && product.product.category.level5 !== 0) {
    var categoryId = product.product.category.level5;
    query.must = {
      categoryMulti: [categoryId]
    };
    $scope.typeGraph = "Catégories";

    if (product.product.presentation_gen_group) {
      $scope.typeGraph = "Génériques";
      query.must = {
        generic: [product.product.presentation_gen_group]
      };
    }

    $scope.valueWithDelivery = Math.ceil(product.needs.calcul.quantity_propal / 60 * (60 + product.product.laboratory_delivery) - product.needs.calcul.order_needs);
    $scope.productToOrdered = Math.ceil(product.needs.calcul.order_needs + (product.product.packing - $scope.valueWithDelivery % product.product.packing));
    query.tableConfig = currentTableConfig;
    analysisService.getLaboratoryStockByHealthcenter(product.organization.cip, query).then(function (response) {
      $scope.dataChart = response;
      processDataHistoForAmCharts(response);
    });
  }

  $scope.hide = function () {
    $mdDialog.hide();
  };

  $scope.cancel = function () {
    $mdDialog.cancel();
  };
}
/**
 * Make a sum
 *
 * @returns {object}
 */


function sumAllProduct() {
  return {
    orderNeedsNegative: 0,
    saleAmountAverageMonthWeighting: 0,
    stock: 0,
    stockAvailableDays: 0,
    stockAvailableDaysTrue: 0
  };
}

angular.module("app.healthcenters").controller("DetailStocksTabController", DetailStocksTabController);