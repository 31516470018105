"use strict";
/**
 * Terms administration controller.
 *
 * @param {object} $scope - The scope object.
 * @param {object} notifyUserService - The notify user service.
 * @param {object} administrationTermService - The administration term service.
 * @param {object} filemanagerDialogService - The filemanagerDialogService.
 *
 * @constructor
 */

AdministrationTermsController.$inject = ["$scope", "notifyUserService", "administrationTermService", "filemanagerDialogService"];

function AdministrationTermsController($scope, notifyUserService, administrationTermService, filemanagerDialogService) {
  $scope.tinymceOptions = {
    menubar: "edit insert view",
    plugins: "image",
    toolbar: "styleselect | bold italic | bullist | image",
    skin: "lightgray",
    theme: "modern",
    style_formats: [{
      title: "Heading 1",
      format: "h1"
    }, {
      title: "Heading 2",
      format: "h2"
    }, {
      title: "Heading 3",
      format: "h3"
    }],
    file_picker_types: "image",
    file_picker_callback: function file_picker_callback(callback) {
      filemanagerDialogService.openDialog(null, "image", function (idk, file) {
        callback(file.source, {
          title: file.name
        });
        return true;
      }, null, null);
    }
  };
  $scope.update = update;
  $scope.gdrp = null;
  $scope.loading = true;
  load();
  /**
   * Load the terms.
   */

  function load() {
    administrationTermService.read("gdrp").then(function (response) {
      $scope.gdrp = response;
    })["finally"](function () {
      $scope.loading = false;
    });
  }
  /**
   * Update the given content term.
   *
   * @param {object} term - The term object.
   */


  function update(term) {
    administrationTermService.update(term).then(function () {
      notifyUserService.showSuccessToast("Le RGPD a bien été mis à jour.");
    });
  }
}

angular.module("app.administration").controller("AdministrationTermsController", AdministrationTermsController);