"use strict";
/**
 * Grouping audit general informations controller.
 *
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} $state - $state.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $q - $q.
 * @param {object} $scope - $scope.
 * @param {object} statisticsService - Statistics service.
 * @param {object} groupmentAuditDetailService - Grouping audit detail service..
 * @param {object} groupmentAuditGeneralInfosService -
 *      Grouping audit general informations service.
 */

GroupmentAuditGeneralInfosController.$inject = ["StatisticsQueryService", "$state", "$stateParams", "$q", "$scope", "statisticsService", "groupmentAuditDetailService", "groupmentAuditGeneralInfosService"];

function GroupmentAuditGeneralInfosController(StatisticsQueryService, $state, $stateParams, $q, $scope, statisticsService, groupmentAuditDetailService, groupmentAuditGeneralInfosService) {
  $scope.goToHealthcenterAudit = goToHealthcenterAudit;
  /**
   * Get grouping director.
   *
   * @param {number} groupingId - Grouping ID.
   */

  function getGroupmentDirector(groupingId) {
    groupmentAuditDetailService.one(groupingId).then(function (groupment) {
      $scope.groupDirector = groupment;
    });
  }
  /**
   * Get healthcenters from grouping.
   *
   * @param {number} groupingId - Grouping ID.
   */


  function getGroupmentHealthcenters(groupingId) {
    groupmentAuditGeneralInfosService.allHealthcenters(groupingId).then(function (healthcenters) {
      $scope.groupHealthcenters = healthcenters;
      var ids = [];
      angular.forEach(healthcenters, function (healthcenter) {
        ids.push(healthcenter.id);
      });
      $scope.ids = ids;
    });
  }
  /**
   * Go to healthcenter audit
   *
   * @param {number} id - ID.
   */


  function goToHealthcenterAudit(id) {
    $state.go("app.healthcenterAudit.detail.general", {
      id: id
    });
  }

  $scope.cartTotal = 0;
  $scope.vatTotal = 0;
  $scope.goBack = goBack;
  $scope.vatSplitDatas = $q.defer();
  $scope.vatSplit = {
    type: "pie",
    theme: "light",
    decimalSeparator: ",",
    thousandsSeparator: " ",
    data: $scope.vatSplitDatas.promise,
    valueField: "sales",
    titleField: "vat",
    labelsEnabled: false,
    legend: {
      enabled: true,
      valueFunction: function valueFunction(data) {
        return Math.round(data.dataContext.roundedPercent * 100) / 100;
      }
    },
    balloon: {
      fixedPosition: true
    },
    "export": {
      enabled: true
    }
  };
  $scope.vatCorrespondTo = {
    5: "Tva à 2,10%",
    9: "Tva à 20%",
    8: "Tva à 10%",
    3: "Tva à 5,5%",
    2: "Tva à 19,6%"
  };
  /**
   * Loader.
   */

  function loader() {
    if ($stateParams.groupId) {
      getGroupmentDirector($stateParams);
      getGroupmentHealthcenters($stateParams);
      getStats();
    } else {
      goBack();
    }
  }
  /**
   * return to the general groupment audit page
   */


  function goBack() {
    $state.go("app.groupmentAudit");
  }
  /**
   * Get statistics.
   */


  function getStats() {
    StatisticsQueryService.queryRebase();
    var dateEnd = moment();
    var dateStart = moment().subtract(12, "month");
    var dateA = dateEnd;
    var dateB = dateStart;
    $scope.auditQuery = StatisticsQueryService.query;
    $scope.auditQuery.date = {
      from: dateStart,
      to: dateEnd
    };
    $scope.cartTotalForLabo = 0;
    $scope.numberofDays = dateA.diff(dateB, "days");
    $scope.auditQuery.must.laboratory.selected = [];
    statisticLoader();
  }
  /**
   * Init of the statistics
   */


  function statisticLoader() {
    StatisticsQueryService.buildquery($scope.auditQuery, $scope.auditQuery.date).then(function (query) {
      $scope.groupQuery = query;
      $scope.groupQuery.must.groups = [parseInt($stateParams.groupId)];
      statisticsService.sales($scope.groupQuery).then(function (sales) {
        $scope.CA12LastMonths = sales.aggregations.amount_excl_tax.value;
        angular.forEach(sales.aggregations.sales.buckets, function (value) {
          $scope.cartTotal += value.cartTotal.value;
        });

        if ($scope.currentEnv.accessPointType === 1) {
          $scope.groupQuery.must.laboratory = [$scope.currentEnv.entityId];
        }

        return $q.all([dailySalesLabo($scope.groupQuery), vatSplitSales($scope.groupQuery), top5PharmaSales($scope.groupQuery)]);
      });
    });
  }
  /**
   * The 5 best sell healthcenter of the groupment
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function top5PharmaSales(query) {
    query.id = $stateParams.groupId;
    query.limit = 5;
    return statisticsService.topPharma(query).then(function (top5PharmaSales) {
      $scope.top5PharmaSales = top5PharmaSales.healthcenters;
    });
  }
  /**
   * Average sales of the day.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function dailySalesLabo(query) {
    return statisticsService.sales(query).then(function (salesLabo) {
      $scope.CALaboratory12LastMonths = salesLabo.aggregations.amount_excl_tax.value;
      angular.forEach(salesLabo.aggregations.sales.buckets, function (valueLabo) {
        $scope.cartTotalForLabo += valueLabo.cartTotal.value;
      });
    });
  }
  /**
   * Sales splitted by vat.
   *
   * @param {object} query - Query.
   *
   * @returns {Promise}
   */


  function vatSplitSales(query) {
    $scope.groupQuery.must.groups = [parseInt($stateParams.groupId)];
    return statisticsService.vatSplit(query).then(function (sales) {
      var datas = [];
      angular.forEach(sales.aggregations.vat.buckets, function (ca) {
        datas.push({
          vat: $scope.vatCorrespondTo[ca.key],
          sales: ca.sales.value
        });
        $scope.vatTotal += ca.sales.value;
      });
      angular.forEach(datas, function (data) {
        data.roundedPercent = data.sales / $scope.vatTotal * 100;
      });
      $scope.vatSplitDatas.resolve(datas);
    });
  }

  loader();
}

angular.module("app.groupmentAudit").controller("GroupmentAuditGeneralInfosController", GroupmentAuditGeneralInfosController);