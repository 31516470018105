"use strict";
/**
 * Controller for wholesaler purchases analysis.
 *
 * @param {object} $scope - Scope
 * @param {object} $state - State
 * @param {object} wholesalerPurchasesAnalysisService - Wholesaler purchase analysis service
 * @param {object} StatisticsQueryService - Statistics query service
 * @param {object} environmentService - Environment service
 */

WholesalerPurchasesAnalysisController.$inject = ["$scope", "$state", "wholesalerPurchasesAnalysisService", "StatisticsQueryService", "environmentService"];

function WholesalerPurchasesAnalysisController($scope, $state, wholesalerPurchasesAnalysisService, StatisticsQueryService, environmentService) {
  $scope.rangeSelected = rangeSelected;
  $scope.deletePharmaFromFilter = deletePharmaFromFilter;
  $scope.getPharmaML = getPharmaML;
  $scope.getWholesalerPrice = getWholesalerPrice;
  $scope.reinitOptionsPaginate = reinitOptionsPaginate;
  $scope.statisticsQuery = StatisticsQueryService.query;
  var AUDIT_WHOLESALER_CONTRACT_SLUG = "wholesaler-audit";
  var endDate = moment().format("DD-MMM-YYYY");
  var startDate = moment().month(0).date(1).format("DD-MMM-YYYY");
  $scope.startDatetime = moment().month(0).date(1).format("YYYY-MM-DD");
  $scope.endDatetime = moment().format("YYYY-MM-DD");
  $scope.dateLabel = startDate + " Au " + endDate;
  $scope.statisticsQuery.date = {
    from: $scope.startDatetime,
    to: $scope.endDatetime
  };
  $scope.searchConfig = {
    event: "pharmaMLSearchChange",
    search: [],
    tag: "pharmaMLSearchChange"
  };
  $scope.loaderBuckets = {
    loading: true,
    loader: "Récupération des paniers grossiste...",
    empty: "Vous n'avez aucun panier grossiste."
  };
  $scope.loaderPharmaMls = {
    loading: true,
    loader: "Récupération des Pharma ML...",
    empty: "Vous n'avez aucun Pharma ML."
  };
  $scope.participantSearchConfig = {
    event: "healthcentersWholesalerAnalysisSearch",
    search: ["idOrgas"],
    searchPlaceHolder: "Rechercher une pharmacie",
    tag: "healthcentersWholesalerAnalysisSearch"
  };
  $scope.query = {
    order: "-datetime",
    limit: 10,
    page: 1,
    limitOptions: [5, 10, 15, 25, 50, 100, 250]
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.wholesalerPurchasesAnalysis = {
    title: {
      icon: "icon-chart-bar",
      text: "Analyses achats grossistes"
    }
  };
  $scope.rangeDateTitle = {
    icon: "icon-calendar-clock",
    text: "Période d'analyse",
    type: "2"
  };
  loader();
  /**
   * The loader function.
   *
   * @returns {Promise} The promise
   */

  function loader() {
    return environmentService.getEnvironment().then(function (env) {
      $scope.env = env;
      StatisticsQueryService.buildquery($scope.statisticsQuery, $scope.statisticsQuery.date).then(function (queryBuilt) {
        $scope.statisticsQueryBuilt = queryBuilt;
        getWholesalerPurchases(queryBuilt);
        getPharmaML();
      });
    });
  }
  /**
   * Get pharma MLs.
   *
   *
   * @returns {Promise} The promise
   */


  function getPharmaML() {
    $scope.loaderPharmaMls.loading = true;
    $scope.pharmaMLs = [];
    var request = $scope.query;
    request.must = $scope.statisticsQueryBuilt.must;
    request.should = $scope.statisticsQueryBuilt.should;
    request.startDatetime = $scope.startDatetime;
    request.endDatetime = $scope.endDatetime;
    return wholesalerPurchasesAnalysisService.getPharmaML(request).then(function (response) {
      $scope.loaderPharmaMls.loading = false;
      $scope.pharmaMLs = response.pharmaMLs;
      $scope.total = response.total;
      return response;
    }).then(function (error) {
      if (error.status === 402) {
        $state.go("app.packDetail", {
          packSlug: AUDIT_WHOLESALER_CONTRACT_SLUG
        });
      }
    });
  }
  /**
   * Initialize the paginate options
   */


  function reinitOptionsPaginate() {
    $scope.query = {
      order: "-datetime",
      limit: 10,
      page: 1
    };
  }
  /**
   * Get wholesaler purchases.
   *
   * @param {object} queryBuilt - The query built by the query service.
   * @returns {Promise} The promise
   */


  function getWholesalerPurchases(queryBuilt) {
    $scope.loaderBuckets.loading = true;
    var query = queryBuilt;
    query.startDatetime = $scope.startDatetime;
    query.endDatetime = $scope.endDatetime;
    return wholesalerPurchasesAnalysisService.getStats(query).then(function (response) {
      $scope.loaderBuckets.loading = false;
      $scope.stats = response;
      return response;
    });
  }
  /**
   * Get wholesaler price.
   *
   * @param {object} slice - The slice
   *
   * @returns {number} The wholesaler price
   */


  function getWholesalerPrice(slice) {
    var wholesalerPrice = 0;

    switch (slice.key_id) {
      case "1":
        wholesalerPrice = slice.marge.value * 2 + 0.3 * slice.quantity.value;
        break;

      case "2":
        wholesalerPrice = slice.marge.value + slice.marge.value * 0.0667 * slice.quantity.value;
        break;

      case "3":
        wholesalerPrice = slice.marge.value * 2 + 30 * slice.quantity.value;
        break;
    }

    return wholesalerPrice;
  }
  /**
   * On date range changed.
   *
   * @param {object} range - The date range
   */


  function rangeSelected(range) {
    $scope.dateLabel = range.startDate + " au " + range.endDate;
    $scope.startDatetime = moment(range.startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    $scope.endDatetime = moment(range.endDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    var query = {};
    query.date = {
      from: $scope.startDatetime,
      to: $scope.endDatetime
    };
    $scope.query.page = 1;
    StatisticsQueryService.buildquery($scope.statisticsQuery, query.date).then(function (query) {
      getWholesalerPurchases(query);
      getPharmaML();
    });
  }
  /**
   * On pharmaML search change
   *
   * @param {object} event - The event
   * @param {object} query - The query
   */


  $scope.$on("pharmaMLSearchChange", function (event, query) {
    StatisticsQueryService.buildquery(query, query.date).then(function (queryBuilt) {
      $scope.statisticsQueryBuilt = queryBuilt;
      getPharmaML();
    });
  });
  /**
   * On pharmaML search change
   *
   * @param {object} event - The event
   * @param {object} query - The query
   */

  $scope.$on("healthcentersWholesalerAnalysisSearch", function (event, query) {
    var queryPharma = {};
    queryPharma.date = {
      from: $scope.startDatetime,
      to: $scope.endDatetime
    };
    queryPharma.should = {};
    queryPharma.must = {};

    for (var type in query) {
      if ($scope.statisticsQuery.must[type]) {
        $scope.statisticsQuery.must[type].selected = query[type];
        $scope.filteredPharmas = query[type];
      }
    }

    StatisticsQueryService.buildquery($scope.statisticsQuery, queryPharma.date).then(function (query) {
      getWholesalerPurchases(query);
      getPharmaML();
    });
  });
  /**
   * Delete pharma from the filtered ones.
   *
   * @param {number} index - The index clicked.
   */

  function deletePharmaFromFilter(index) {
    $scope.statisticsQuery.must.pharmas.selected.splice(index, 1);
    $scope.filteredPharmas.splice(index, 1);
    $scope.statisticsQuery.date = {
      from: $scope.startDatetime,
      to: $scope.endDatetime
    };
    StatisticsQueryService.buildSubstituteQuery($scope.statisticsQuery, $scope.statisticsQuery.date).then(function (query) {
      getWholesalerPurchases(query);
      getPharmaML();
    });
  }
}

angular.module("app.statistics.salesAnalysis").controller("wholesalerPurchasesAnalysisController", WholesalerPurchasesAnalysisController);