/* eslint-disable id-length */
"use strict";
/**
 * Keyboard shortcut filter.
 *
 * @param {object} $window - $window.
 * @returns {Function}
 */

KeyboardShortcutFilter.$inject = ["$window"];

function KeyboardShortcutFilter($window) {
  return function (str) {
    if (!str) {
      return;
    }

    var keys = str.split("-");
    var isOSX = /Mac OS X/.test($window.navigator.userAgent);
    var seperator = !isOSX || keys.length > 2 ? "+" : "";
    var abbreviations = {
      M: isOSX ? "" : "Ctrl",
      A: isOSX ? "Option" : "Alt",
      S: "Shift"
    }; // eslint-disable-next-line consistent-return

    return keys.map(function (key, index) {
      return index === keys.length - 1 ? key : abbreviations[key];
    }).join(seperator);
  };
}

angular.module("app.generator").filter("keyboardShortcut", KeyboardShortcutFilter);