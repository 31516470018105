"use strict";
/**
 * Statistics generic potential controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} environmentService - Environment service.
 * @param {object} $mdSidenav - $mdSidenav.
 * @param {object} $scope - $scope.
 * @param {object} StatisticsQueryService - Statistics query service.
 * @param {object} analysisService - Analysis service.
 * @param {object} healthcenterService - Healthcenter service.
 */

StatisticsGenericPotentialController.$inject = ["$rootScope", "environmentService", "$mdSidenav", "$scope", "StatisticsQueryService", "analysisService", "healthcenterService"];

function StatisticsGenericPotentialController($rootScope, environmentService, $mdSidenav, $scope, StatisticsQueryService, analysisService, healthcenterService) {
  $scope.zentivaId = 6443;
  $scope.brutPercentPrestationError = false;
  $scope.canExport = false;
  environmentService.getEnvironment().then(function (env) {
    $scope.currentEnv = env;
    $scope.canExport = canExport();
  });
  $scope.potentialLoaderOptions = {
    loading: true,
    loader: "Récupération de votre potentiel d'achat générique...",
    empty: "Vous n'avez aucune données de ventes."
  };
  $scope.optionsTable = {
    order: "name",
    limit: 15,
    page: 1
  };
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.filteredPharmas = [];
  $scope.asc = 0;
  $scope.ascQuantity = 0;
  $scope.manualQuantitySorted = false;
  $scope.manualTotalPriceSorted = false;
  $scope.dtInstance = {};
  $scope.ascClass = "sorting";
  $scope.ascQuantityClass = "sorting";
  $scope.configPotentialGeneric = {
    event: "pharmacyPotentialSearched",
    search: ["idOrgas", "geo.region.id", "segmentHealthcenter", "geo.city.id", "geo.departement.number", "geo.uga", "group.id"],
    tag: "participant",
    searchPlaceHolder: "Rechercher des pharmacies"
  };
  init();
  /**
   *
   */

  function init() {
    $scope.usrquery = StatisticsQueryService.substituteQuery;
    $scope.usrquery.must.pharmas.selected = [];
    var dateStart = moment().subtract(1, "year");
    var dateEnd = moment();
    $scope.dateLabel = dateStart.format("DD-MMM-YYYY") + " Au " + dateEnd.format("DD-MMM-YYYY");
    $scope.usrquery.date = {
      from: new Date(dateStart),
      to: new Date(dateEnd)
    };
    $scope.usrquery.lab.selected = [];
    StatisticsQueryService.buildSubstituteQuery($scope.usrquery).then(function (query) {
      $scope.query = query;
      processQuery($scope.query);
      processCatalogQuery($scope.query);
    });
  }

  $scope.changeParacetamol = function () {
    $scope.moleculesToShow = [];
    $scope.potentialLoaderOptions.loading = true;
    processQuery($scope.query);
    processCatalogQuery($scope.query);
  }; // DATE FILTER


  $scope.rangeSelected = function (date) {
    $scope.usrquery.date.from = moment(date.startDate, "DD-MM-YYYY");
    $scope.usrquery.date.to = moment(date.endDate, "DD-MM-YYYY");
    StatisticsQueryService.buildSubstituteQuery($scope.usrquery).then(function (query) {
      $scope.query = query;
      processQuery($scope.query);
      processCatalogQuery($scope.query);
    });
  };

  $scope.prestationEuroChange = function (lab) {
    lab.prestationPercent = 0;

    if (lab.totalDiscountPrice) {
      lab.prestationPercent = parseFloat((parseFloat(lab.prestationEuro) / lab.totalDiscountPrice * 100).toFixed(2));
    }

    lab.remisePercent = 0;
    lab.remiseEuro = 0;

    if (lab.totalPrice) {
      lab.remisePercent = parseFloat(((1 - (lab.totalDiscountPrice - parseFloat(lab.prestationEuro)) / lab.totalPrice) * 100).toFixed(2));
      lab.remiseEuro = (lab.remisePercent / 100 * lab.totalPrice).toFixed(2);
    }

    lab.globalRemise = parseFloat(lab.totalPrice - lab.totalDiscountPrice + parseFloat(lab.prestationEuro)).toFixed(2);
  };

  $scope.prestationPercentChange = function (lab) {
    if (lab.prestationPercent > 100) {
      lab.prestationPercent = 100;
      $scope.prestationPercentChange(lab);
      return;
    }

    if (lab.prestationPercent < 0) {
      lab.prestationPercent = 0;
      $scope.prestationPercentChange(lab);
      return;
    }

    lab.prestationEuro = 0;

    if (lab.totalDiscountPrice) {
      lab.prestationEuro = parseFloat((lab.prestationPercent / 100 * lab.totalDiscountPrice).toFixed(2));
    }

    lab.remisePercent = 0;
    lab.remiseEuro = 0;

    if (lab.totalPrice) {
      lab.remisePercent = ((1 - (lab.totalDiscountPrice - lab.prestationEuro) / lab.totalPrice) * 100).toFixed(2);
      lab.remiseEuro = (lab.remisePercent / 100 * lab.totalPrice).toFixed(2);
    }

    lab.globalRemise = parseFloat(lab.totalPrice - lab.totalDiscountPrice + parseFloat(lab.prestationEuro)).toFixed(2);
  };

  $scope.prestationBrutPercentChange = function (lab) {
    $scope.errorRemisePercent = lab.remisePercent > 40;
    lab.errorBrut = lab.remisePercent < lab.discount;
    lab.prestationEuro = 0;

    if (lab.totalPrice) {
      lab.prestationEuro = parseFloat(parseFloat(lab.remisePercent) / 100 * lab.totalPrice - (lab.totalPrice - lab.totalDiscountPrice)).toFixed(2);
    }

    lab.remiseEuro = parseFloat(lab.totalPrice - lab.totalDiscountPrice + lab.prestationEuro).toFixed(2);
    lab.prestationPercent = 0;

    if (lab.totalDiscountPrice) {
      lab.prestationPercent = (lab.prestationEuro / lab.totalDiscountPrice * 100).toFixed(2);
    }

    lab.globalRemise = parseFloat(lab.totalPrice - lab.totalDiscountPrice + parseFloat(lab.prestationEuro)).toFixed(2);
  };
  /**
   * Add input models to laboratories.
   *
   * @param {Array} list -
   *
   * @returns {Array}
   */


  function addInputModelsToLabs(list) {
    return list.map(function (currentValue) {
      if (currentValue.quadri !== null) {
        currentValue.discount = parseFloat(currentValue.discount);
        currentValue.totalPrice = parseFloat(currentValue.totalPrice);
        currentValue.totalDiscountPrice = parseFloat(currentValue.totalDiscountPrice);
        currentValue.prestationPercent = 0;
        currentValue.prestationEuro = 0;
        currentValue.remisePercent = 0;
        currentValue.remiseEuro = 0;

        if (currentValue.totalPrice) {
          currentValue.remisePercent = ((1 - (currentValue.totalDiscountPrice - currentValue.prestationEuro) / currentValue.totalPrice) * 100).toFixed(2);
          currentValue.remiseEuro = (currentValue.remisePercent / 100 * currentValue.totalPrice).toFixed(2);
        }

        currentValue.globalRemise = parseFloat(currentValue.totalPrice - currentValue.totalDiscountPrice + currentValue.prestationEuro).toFixed(2);
      }

      return currentValue;
    });
  }
  /**
   * Process query.
   *
   * @param {object} query - Query.
   */


  function processQuery(query) {
    analysisService.searchPotentialGeneric(query).then(function (response) {
      $scope.potentialLoaderOptions.loading = false;
      $scope.datas = response;
      $scope.genericPotential = response.totalPrice;
      var products = [];

      _.each(response.product, function (product) {
        if (!products[product.key]) {
          products[product.key] = [];
        }

        products[product.key].push(product);
      });

      $scope.laboratories = addInputModelsToLabs(response.laboratory);

      _.each($scope.laboratories, function (labo) {
        var productsHandled = [];

        if (!labo.otherToBuy) {
          labo.otherToBuy = 0;
        }

        _.each(products, function (product) {
          if (product) {
            var bestProd = _.sortBy(product, function (prod) {
              return prod.discount;
            })[product.length - 1];

            var hasMolecule = _.find(product, function (prod) {
              return prod.laboId === labo.laboId;
            });

            if (bestProd && bestProd.laboId !== labo.laboId && productsHandled.indexOf(bestProd.key) === -1 && !hasMolecule) {
              productsHandled.push(bestProd.key);
              labo.otherToBuy = labo.otherToBuy + bestProd.quantity * bestProd.price - bestProd.quantity * bestProd.price * (bestProd.discount / 100);
            }
          }
        });
      });

      $scope.potentialLoaderOptions.loading = false;
    });
    analysisService.searchTheoricGeneric(query).then(function (response) {
      $scope.tmpData = response;
      $scope.potentialLoaderOptions.loading = false;
    });
  }
  /**
   * Process catalog query.
   *
   * @param {object} query - Query.
   */


  function processCatalogQuery(query) {
    analysisService.searchGeneric(query).then(function (response) {
      $scope.potentialLoaderOptions.loading = false;
      $scope.molecules = response.group;
    });
  }

  $scope.toggleSettingsSidenav = function () {
    $mdSidenav("settings-sidenav").toggle();
  };

  $scope.openSettingsTo = function (panel) {
    $rootScope.$broadcast("statistics:opento", panel);
  };

  $scope.removeFilter = function (obj, list) {
    var index = list.indexOf(obj);

    if (index !== -1) {
      list.splice(index, 1);
      $scope.potentialLoaderOptions.loading = true;
      StatisticsQueryService.buildSubstituteQuery($scope.usrquery).then(function (query) {
        $scope.query = query;
        processQuery($scope.query);
        processCatalogQuery($scope.query);
      });
    }
  };

  $scope.exportExcel = function () {
    $scope["export"] = [];
    $scope.exportHeaders = ["Nom", "Cip7", "Cip13", "Prix", "Quantité Totale", "Labo", "Quantité", "Remise"];

    _.each($scope.datas.product, function (molecule) {
      $scope["export"].push({
        name: molecule.name,
        cip7: molecule.cip7,
        cip13: molecule.cip13,
        price: toExcelFormat(molecule.price),
        totalQuantity: molecule.quantity,
        labo: molecule.laboName,
        discount: molecule.discount
      });
    });

    return $scope["export"];
  };
  /**
   * To excel format.
   *
   * @param {number} price - Price.
   *
   * @returns {string}
   */


  function toExcelFormat(price) {
    if (price) {
      return price.toString().replace(",", " ").replace(".", ",");
    }

    return "0";
  }

  $scope.filterByLaboratory = function (lab) {
    if ($scope.currentEnv.entityId !== $scope.zentivaId) {
      $scope.moleculesToShow = [];
      angular.forEach($scope.datas.product, function (mol) {
        if (mol.quadri === lab.quadri) {
          var tmpObj = mol;
          tmpObj.name = mol.name;
          tmpObj.quantity = mol.quantity;
          tmpObj.discountPrice = tmpObj.price - tmpObj.price * tmpObj.discount / 100;
          tmpObj.quadri = lab.quadri;
          tmpObj.color = lab.color;
          tmpObj.totalToSortOn = tmpObj.discountPrice * tmpObj.quantity;
          $scope.moleculesToShow.push(tmpObj);
        }
      });
      $scope.dataSorted = $scope.moleculesToShow;
    }
  };

  $scope.$on("pharmacyPotentialSearched", function (event, query) {
    var queryPharma = {};
    queryPharma.type = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(query, type)) {
        if (typeof queryPharma.type[type] === "undefined") {
          queryPharma.type[type] = [];
        }

        angular.forEach(query[type], function (obj) {
          if (type === "uga") {
            queryPharma.type[type].push(obj.name);
          } else {
            queryPharma.type[type].push(obj.id);
          }
        });
      }
    };

    for (var type in query) {
      _loop(type);
    }

    queryPharma.full = true; // getPharmas with query results

    healthcenterService.getHealthcentersByGeo(queryPharma).then(function (pharmas) {
      angular.forEach(pharmas, function (pharma) {
        $scope.usrquery.must.pharmas.selected.push(pharma._source);
        $scope.filteredPharmas.push(pharma._source);
      });
      StatisticsQueryService.buildSubstituteQuery($scope.usrquery).then(function (query) {
        $scope.query = query;
        processQuery(query);
      });
    });
  });

  $scope.deletePharmaFromFilter = function (index) {
    $scope.usrquery.must.pharmas.selected.splice(index, 1);
    $scope.filteredPharmas.splice(index, 1);
    StatisticsQueryService.buildSubstituteQuery($scope.usrquery).then(function (query) {
      $scope.query = query;
      processQuery(query);
    });
  };
  /**
   * Can export.
   *
   * @returns {boolean}
   */


  function canExport() {
    var authorizedIds = [63, 57, 43, 90];
    return authorizedIds.indexOf($scope.currentEnv.userId) > -1;
  }
}

angular.module("app.statistics.genericaudit").controller("StatisticsGenericPotentialController", StatisticsGenericPotentialController);