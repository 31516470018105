"use strict";
/**
 * Users admin service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

UsersAdminService.$inject = ["apiClient"];

function UsersAdminService(apiClient) {
  return {
    /**
     * Get.
     *
     * @returns {Promise}
     */
    get: function get() {
      return apiClient.get("/admin/users", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data.users;
      });
    },

    /**
     * Get by Id.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    getById: function getById(params) {
      return apiClient.get("/admin/users", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      }).then(function (result) {
        return result.users.find(function (user) {
          return user.id === parseInt(params.id);
        });
      });
    },

    /**
     * Put.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    put: function put(params) {
      return apiClient.put("/admin/users/".concat(params.id), params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Post.
     *
     * @param {object} params -
     * @returns {Promise}
     */
    post: function post(params) {
      return apiClient.post("/admin/users", params, {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.admin").service("usersAdminService", UsersAdminService);