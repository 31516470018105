"use strict";

StatsGenericAuditController.$inject = ["$state", "$scope", "environmentService"];
angular.module("app.statistics.genericaudit").controller("StatsGenericAuditController", StatsGenericAuditController);
/** @ngInject */

/**
 * Generic audit controller.
 *
 * @param {object} $state - The angular $state object.
 * @param {object} $scope - The angular $scope object.
 * @param {object} environmentService - The environment service.
 */

function StatsGenericAuditController($state, $scope, environmentService) {
  var vm = this;
  var currentState = $state.current.name;
  environmentService.getEnvironment().then(function (response) {
    $scope.environment = response; // TABS STATES

    switch (currentState) {
      case "app.statistics.genericaudit":
        vm.selectedIndex = 0;
        break;

      case "app.statistics.genericaudit.potential":
        vm.selectedIndex = 1;
        break;

      case "app.statistics.genericaudit.ideal":
        vm.selectedIndex = 2;
        break;

      case "app.statistics.genericaudit.substitution":
        vm.selectedIndex = 3;
        break;

      default:
        vm.selectedIndex = 0;
    }
  });
}