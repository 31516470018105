"use strict";
/**
 * Lab pie factory.
 *
 * @returns {object}
 */

function labPie() {
  return {
    data: [],
    type: "pie",
    theme: "light",
    startDuration: 0,
    marginTop: 5,
    marginBottom: 0,
    marginLeft: 5,
    marginRight: 5,
    pullOutRadius: 10,
    mouseWheelScrollEnabled: true,
    thousandsSeparator: " ",
    unit: " EUR HT",
    categoryField: "title",
    valueField: "amount",
    colorField: "color",
    titleField: "title",
    precision: 0,
    balloon: {
      fixedPosition: true
    },
    legend: {
      divId: "legenddivGamme"
    },
    "export": {
      enabled: true,
      menu: [{
        "class": "export-main",
        menu: [{
          format: "PNG",
          title: "Exporter le graphique au format PNG"
        }, {
          format: "JPG",
          title: "Exporter le graphique au format JPG"
        }]
      }]
    }
  };
}

angular.module("app.home").factory("labPie", labPie);