"use strict";
/**
 * Laboratory branding edit range controller.
 *
 * @param {object} $rootScope - $rootScope.
 * @param {object} $scope - $scope.
 * @param {object} $stateParams - $stateParams.
 * @param {object} $state - $state.
 * @param {object} $mdDialog - $mdDialog.
 * @param {object} sessionStorageService - Session storage service.
 * @param {string} apiurl - API URL.
 * @param {object} notifyUserService - Notify user service.
 * @param {object} environmentService - Environment service.
 * @param {object} laboratoryRangeService - Laboratory range service.
 * @param {object} productService - Product service.
 */

LaboratoryBrandingEditRangeController.$inject = ["$rootScope", "$scope", "$stateParams", "$state", "$mdDialog", "sessionStorageService", "apiurl", "notifyUserService", "environmentService", "laboratoryRangeService", "productService"];

function LaboratoryBrandingEditRangeController($rootScope, $scope, $stateParams, $state, $mdDialog, sessionStorageService, apiurl, notifyUserService, environmentService, laboratoryRangeService, productService) {
  /**
   * Declaration of public methods
   */
  $scope.loaderRange = loaderRange;
  $scope.openTab = openTab;
  $scope.goToBranding = goToBranding;
  $scope.goToUnivers = goToUnivers;
  $scope.updateRange = updateRange;
  $scope.deleteRange = deleteRange;
  $scope.addSlide = addSlide;
  $scope.updateSlide = updateSlide;
  $scope.addSlideImage = addSlideImage;
  $scope.addLogoImage = addLogoImage;
  $scope.addBackgroundImage = addBackgroundImage;
  $scope.deleteSlide = deleteSlide;
  $scope.editProduct = editProduct;
  $scope.deleteProduct = deleteProduct;
  $scope.save = save;
  $scope.updateBranding = updateBranding;
  $scope.deleteLogo = deleteLogo;
  $scope.deleteBackground = deleteBackground;
  /**
   * Declaration of public objects
   */

  $scope.tabs = [{
    id: 1,
    name: "Charte graphique",
    slug: "graphical-charter",
    active: true,
    isActive: true
  }, {
    id: 2,
    name: "Gestion du carrousel",
    slug: "slideshow",
    active: false,
    isActive: true
  }, {
    id: 3,
    name: "Gestion des produits",
    slug: "products",
    active: false,
    isActive: true
  }];
  $scope.ranges = [];
  $scope.options = {
    rowSelection: true,
    multiSelect: true,
    autoSelect: true,
    decapitate: false,
    largeEditDialog: false,
    boundaryLinks: false,
    limitSelect: true,
    pageSelect: true
  };
  $scope.query = {
    order: "-startDate",
    limit: 10,
    page: 1
  };
  $scope.brandingOptions = {
    loading: true,
    loader: "Récupération de la charte graphique"
  };
  $scope.productsOptions = {
    loading: true,
    loader: "Récupération des produits"
  };
  $scope.optionsPickerBg = {
    type: 2,
    history: false,
    genericPalette: false,
    palette: false
  };
  $scope.token = sessionStorageService.getToken();
  $scope.apiurl = apiurl;
  loader();
  /**
   * Loader.
   */

  function loader() {
    loadTabs();
    loaderEnvironment().then(function () {
      loaderUnivers();
      loaderRange();
    });
  }
  /**
   * LoadTabs
   * Init first tab and hide others
   */


  function loadTabs() {
    var mainTab = angular.element(document).find("#graphical-charter")[0];
    /**
     * To display block the first tab
     */

    $scope.tabs.forEach(function (tab) {
      mainTab.style.display = "block";

      if (tab.slug === mainTab.id && tab.method) {
        tab.active = true;
        tab.method();
      }
    });
  }
  /**
   * Load current environment
   *
   * @returns {Promise}
   */


  function loaderEnvironment() {
    return environmentService.getEnvironment().then(function (response) {
      $scope.currentAccess = response;
      $scope.config = {
        must: {
          "laboratory.id": [response.entity.id]
        },
        search: ["idProducts", "range.id", "laboratory.id", "generic.id", "segmentProduct"],
        event: "addProductRange",
        tag: "productRange",
        autoLaunchEvent: false
      };
      return response;
    });
  }
  /**
   *
   * Load parent univers
   * Set $scope.univers
   *
   * @function loaderUnivers
   * @returns {Promise}
   */


  function loaderUnivers() {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      id: $stateParams.universId
    };
    return laboratoryRangeService.read(query).then(function (response) {
      $scope.univers = response.range;
      return response;
    });
  }
  /**
   * Load current range
   * Cast event $broadcast -> "slideshow:refresh" with "response.sliders"
   *
   * @returns {Promise}
   */


  function loaderRange() {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      id: $stateParams.rangeId
    };
    return laboratoryRangeService.read(query).then(function (response) {
      $scope.range = response.range;
      $scope.branding = $scope.range.branding;
      $rootScope.$broadcast("slideshow:refresh", $scope.range.branding.sliders);
      $scope.ranges = response.range.childrenRanges;
      $scope.brandingOptions.loading = false;
      $scope.productsOptions.loading = false;

      if ($scope.range.products.length <= 0) {
        $scope.productsOptions = {
          loading: false,
          empty: "Aucun produit"
        };
      }

      return response;
    });
  }
  /**
   * Update range
   *
   * @returns {Promise}
   */


  function updateRange() {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      id: $scope.range.id,
      range: $scope.range
    };
    return laboratoryRangeService.update(query).then(function (response) {
      $scope.range = response.range;
      $scope.branding = response.range.branding;
      $scope.ranges = response.range.childrenRanges;
      notifyUserService.showSimpleToast("Gamme mise à jour", {
        color: "success",
        hideDelay: 1000
      });
      return response;
    });
  }
  /**
   * Delete target range
   *
   * @param {object} range -
   */


  function deleteRange(range) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression définitive de la gamme « " + range.name + " » ?").ariaLabel("Suppression de la gamme").ok("Oui").cancel("Non")).then(function () {
      var query = {
        laboratoryId: $scope.currentAccess.entityId,
        id: range.id
      };
      $scope.ranges.splice($scope.ranges.findIndex(function (item) {
        return item.id === range.id;
      }), 1);
      return laboratoryRangeService["delete"](query).then(function () {
        loaderRange();
        notifyUserService.showSimpleToast("La gamme «" + range.name + "» a été supprimée.", {
          color: "success",
          hideDelay: 1000
        });
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Add logo image
   *
   * @param {object} file -
   */


  function addLogoImage(file) {
    if (!_.isUndefined(file) && !_.isNull(file)) {
      $scope.branding.logoId = file.id;
      updateBranding();
    }
  }
  /**
   * Add background image
   *
   * @param {object} file -
   */


  function addBackgroundImage(file) {
    if (!_.isUndefined(file) && !_.isNull(file)) {
      $scope.branding.backgroundId = file.id;
      updateBranding();
    }
  }
  /**
   * Add slide
   *
   * @returns {Promise}
   */


  function addSlide() {
    var slide = {
      legend: "",
      position: $scope.branding.sliders ? $scope.branding.sliders.length + 1 : 0
    };
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      rangeId: $scope.range.id,
      slide: slide
    };
    return laboratoryRangeService.createSlider(query).then(function (response) {
      loaderRange();
      notifyUserService.showSimpleToast("Slide ajouté", {
        color: "success",
        hideDelay: 1000
      });
      return response;
    });
  }
  /**
   * Add file "image" to target slide
   *
   * @param {object} file -
   * @param {object} slide -
   */


  function addSlideImage(file, slide) {
    if (!_.isUndefined(file) && !_.isNull(file) && !_.isUndefined(slide) && !_.isNull(slide)) {
      slide.fileId = file.id;
      updateSlide(slide);
    }
  }
  /**
   *
   * Update target sldie
   *
   * @param {object} slide - Slide.
   *
   * @returns {Promise}
   */


  function updateSlide(slide) {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      rangeId: $stateParams.rangeId,
      id: slide.id,
      slide: slide
    };
    return laboratoryRangeService.updateSlider(query).then(function () {
      loaderRange();
      notifyUserService.showSimpleToast("Slide mis à jour", {
        color: "success",
        hideDelay: 1000
      });
    });
  }
  /**
   * Delete target slide
   *
   * @param {object} slide -
   * @param {number} index -
   */


  function deleteSlide(slide, index) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression définitive de la page du carrousel ?").ariaLabel("Suppression de la page du carrousel").ok("Oui").cancel("Non")).then(function () {
      var query = {
        laboratoryId: $scope.currentAccess.entityId,
        rangeId: $scope.range.id,
        id: slide.id
      };
      $scope.branding.sliders.splice(index, 1);
      return laboratoryRangeService.deleteSlider(query).then(function () {
        loaderRange();
        notifyUserService.showSimpleToast("Slide supprimé", {
          color: "success",
          hideDelay: 1000
        });
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Add product to range
   *
   * @param {object} productIds -
   *
   * @returns {Promise}
   */


  function addProduct(productIds) {
    var query = {
      id: $stateParams.rangeId,
      laboratoryId: $scope.currentAccess.entityId,
      productIds: productIds
    };
    return laboratoryRangeService.assignProduct(query).then(function (response) {
      loaderRange();
      notifyUserService.showSimpleToast("Produit ajouté", {
        color: "success",
        hideDelay: 1000
      });
      return response;
    });
  }
  /**
   * Go to "app.laboratory-products.edit"
   *
   * @param {object} product -
   */


  function editProduct(product) {
    $state.go("app.laboratory-products.edit", {
      id: product.id
    });
  }
  /**
   * Delete targetted products
   *
   * @param {object} event -
   * @param {object} product -
   * @param {number} index -
   */


  function deleteProduct(event, product, index) {
    $mdDialog.show($mdDialog.confirm().title("Suppression").textContent("Confirmez-vous la suppression de l'association du produit ?").ariaLabel("Suppression du produit de l'univers").ok("Oui").cancel("Non")).then(function () {
      var query = {
        id: $stateParams.rangeId,
        laboratoryId: $scope.currentAccess.entityId,
        product: product
      };
      $scope.range.products.splice(index, 1);
      return laboratoryRangeService.unassignProduct(query).then(function (response) {
        loaderRange();
        notifyUserService.showSimpleToast("Produit supprimé", {
          color: "success",
          hideDelay: 1000
        });
        return response;
      });
    }, function () {
      $mdDialog.cancel();
    });
  }
  /**
   * Sqve.
   */


  function save() {
    updateBranding();
    updateRange();
  }
  /**
   * Update range branding
   *
   * @returns {Promise}
   */


  function updateBranding() {
    var query = {
      laboratoryId: $scope.currentAccess.entityId,
      rangeId: $scope.range.id,
      brandingId: $scope.branding.id,
      branding: $scope.branding
    };
    return laboratoryRangeService.updateBranding(query).then(function (response) {
      $scope.branding = response;
      notifyUserService.showSimpleToast("La charte graphique de la vitrine a été mise à jour.", {
        color: "success",
        hideDelay: 1000
      });
      $rootScope.$broadcast("slideshow:refresh", response.sliders);
      return response;
    });
  }
  /**
   * Open target tab
   *
   * @param {object} myTab -
   */


  function openTab(myTab) {
    var tab = document.getElementsByClassName("tab");

    for (var index = 0; index < tab.length; index++) {
      tab[index].style.display = "none";
    }

    $scope.tabs.forEach(function (tab) {
      if (myTab.id === tab.id) {
        myTab.active = true;
        document.getElementById(myTab.slug).style.display = "block";

        if (tab.method) {
          tab.method();
        }
      } else {
        tab.active = false;
      }
    });
  }
  /**
   * Go to "app.laboratory-branding.univers"
   */


  function goToUnivers() {
    $state.go("app.laboratory-branding.univers");
  }
  /**
   * Go to "app.laboratory-branding"
   *
   * @function goToBranding
   */


  function goToBranding() {
    $state.go("app.laboratory-branding");
  }

  $scope.$on("addProductRange", function (event, args) {
    event.preventDefault();
    var productIds = [];
    var queryProduct = {};

    var _loop = function _loop(type) {
      if (Object.prototype.hasOwnProperty.call(args, type)) {
        if (typeof queryProduct[type] === "undefined") {
          queryProduct[type] = [];
        }

        args[type].forEach(function (myProduct) {
          var hasProduct = _.find($scope.range.products, function (product) {
            return product.id === myProduct.id;
          });

          if (!hasProduct) {
            queryProduct[type].push(myProduct.id);
          }
        });
      }
    };

    for (var type in args) {
      _loop(type);
    } // getProducts with query results


    productService.postPresentationByType(queryProduct).then(function (products) {
      products.forEach(function (product) {
        var hasProduct = _.find($scope.range.products, function (prod) {
          return prod.id === product.id;
        });

        if (!hasProduct) {
          productIds.push(product.id);
        }
      });
      return productIds;
    }).then(function (myProductsId) {
      if (myProductsId.length) {
        addProduct(myProductsId);
      }
    });
  });
  /**
   * Delete branding logo
   *
   * @returns {Promise}
   */

  function deleteLogo() {
    $scope.branding.logoId = null;
    notifyUserService.showSimpleToast("Le logo a bien été supprimé.", {
      color: "success",
      hideDelay: 1000
    });
    return save();
  }
  /**
   * Delete branding background
   *
   * @returns {Promise}
   */


  function deleteBackground() {
    $scope.branding.backgroundId = null;
    notifyUserService.showSimpleToast("Le fond de page a bien été supprimé.", {
      color: "success",
      hideDelay: 1000
    });
    return save();
  }
}

angular.module("app.laboratory").controller("laboratoryBrandingEditRangeController", LaboratoryBrandingEditRangeController);