"use strict";
/**
 * Filemanager service.
 *
 * @param {object} apiClient - API client.
 *
 * @returns {object}
 */

FilemanagerService.$inject = ["apiClient"];

function FilemanagerService(apiClient) {
  return {
    /**
     * Get folders.
     *
     * @returns {Promise}
     */
    getFolders: function getFolders() {
      return apiClient.get("/filemanager/folders").then(function (data) {
        return data.data;
      });
    },

    /**
     * Move a file.
     *
     * @param {number} fileId - The ID of the file to move.
     * @param {string} path - The place where to move the file.
     *
     * @returns {Promise}
     */
    moveFile: function moveFile(fileId, path) {
      return apiClient.post("/filemanager/files/".concat(fileId, "/move?path=").concat(path)).then(function (data) {
        return data.data;
      });
    },

    /**
     * Load sharers types.
     *
     * @returns {Promise}
     */
    loadSharersTypes: function loadSharersTypes() {
      var options = {
        wrappers: {
          response: "entityTypes"
        }
      };
      return apiClient.get("/filemanager/sharers/types", options).then(function (_ref) {
        var data = _ref.data;
        return data;
      });
    },

    /**
     * Load sharers.
     *
     * @param {object} params -
     *
     * @returns {Promise}
     */
    loadSharers: function loadSharers(params) {
      var options = {
        wrappers: {
          response: "entities"
        }
      };
      return apiClient.get("/filemanager/sharers/types/".concat(params.id, "/entities"), options).then(function (_ref2) {
        var data = _ref2.data;
        return data;
      });
    },

    /**
     * Load sharing files.
     *
     * @param {object} params -
     *
     * @returns {Promise}
     */
    loadSharingFiles: function loadSharingFiles(params) {
      var options = {
        includes: ["access"],
        wrappers: {
          response: "files"
        }
      };
      return apiClient.get("/filemanager/sharers/types/".concat(params.type_id, "/entities/").concat(params.entity_id, "/files"), options).then(function (_ref3) {
        var data = _ref3.data;
        return data;
      });
    },

    /**
     * Get file types.
     *
     * @returns {Promise}
     */
    getFileTypes: function getFileTypes() {
      return apiClient.get("/filemanager/type", {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get folders tree.
     *
     * @param {string} location - Location.
     *
     * @returns {Promise}
     */
    getFoldersTree: function getFoldersTree(location) {
      return apiClient.get("/filemanager/folder/tree", {
        parameters: {
          location: location
        },
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Get all files.
     *
     * @param {string} location - Location.
     * @param {string} type - Type.
     *
     * @returns {Promise}
     */
    getFiles: function getFiles(location, type) {
      return apiClient.get("/filemanager", {
        includes: ["access", "shares.entity", "shares.entityType"],
        parameters: {
          type: type,
          location: location
        },
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Update file.
     *
     * @param {string} path - Path.
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    updateFile: function updateFile(path, data) {
      return apiClient.put("/filemanager/file", data, {
        parameters: {
          path: path
        },
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete file.
     *
     * @param {string} path - Path.
     *
     * @returns {Promise}
     */
    deleteFile: function deleteFile(path) {
      return apiClient["delete"]("/filemanager/file", {
        parameters: {
          path: path
        },
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create directory.
     *
     * @param {string} location - Location.
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    createDirectory: function createDirectory(location, data) {
      return apiClient.post("/filemanager/folder", data, {
        parameters: {
          location: location
        },
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Create a file share.
     *
     * @param {number} fileId - File ID.
     * @param {object} data - Data.
     *
     * @returns {Promise}
     */
    createShare: function createShare(fileId, data) {
      return apiClient.post("/filemanager/".concat(fileId, "/shares"), data, {
        includes: ["entityType", "entity"],
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    },

    /**
     * Delete a file share.
     *
     * @param {number} shareId - Share ID.
     *
     * @returns {Promise}
     */
    deleteShare: function deleteShare(shareId) {
      return apiClient["delete"]("/filemanager/shares/".concat(shareId), {
        wrappers: {
          response: null
        }
      }).then(function (response) {
        return response.data;
      });
    }
  };
}

angular.module("app.file-manager").service("filemanagerService", FilemanagerService);