"use strict";
/**
 * Controller for wholesaler disruption rate.
 *
 * @param {object} $scope - The angular $scope object.
 * @param {object} disruptionService - The disruption service.
 */

WholesalerDisruptionRateController.$inject = ["$scope", "disruptionService"];

function WholesalerDisruptionRateController($scope, disruptionService) {
  loader();
  /**
   * The loading function.
   */

  function loader() {
    disruptionService.getDisruptionRateForWholesaler().then(function (disruptionRate) {
      $scope.wholesalers = disruptionRate.aggregations.months.wholesalers.buckets;
      $scope.wholesalersDisruptionRate = disruptionRate.aggregations.months.deliveredQuantity.value / disruptionRate.aggregations.months.orderedQuantity.value * 100;
    });
  }
}

angular.module("app.dashboard").controller("wholesalerDisruptionRateController", WholesalerDisruptionRateController);